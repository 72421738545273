import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { AnswerFormatType } from '@lib/web/apis';
import { useCreatorQuestionInfoData } from '@lib/web/editor';

import { EditorSolutionGeneral } from './components/EditorSolutionGeneral';
import { EditorSolutionLightbulb } from './components/EditorSolutionLightbulb';
import { EditorSolutionMultipleChoice } from './components/EditorSolutionMultipleChoice';
import { EditorSolutionMultipleResponse } from './components/EditorSolutionMultipleResponse';
import { EditorSolutionTrueFalseNotGiven } from './components/EditorSolutionTrueFalseNotGiven';

export type EditorSolutionProps = {
  questionId: string;
  readonly?: boolean;
};

export default function EditorSolution({
  questionId,
  readonly,
}: EditorSolutionProps) {
  const { t } = useTranslation('editor');
  const { infoData, isLoading } = useCreatorQuestionInfoData(questionId);

  if (isLoading || !infoData) {
    return <Skeleton width={200} />;
  }

  switch (infoData.answerFormatType) {
    case AnswerFormatType.MultipleChoice:
      return (
        <EditorSolutionMultipleChoice
          questionId={questionId}
          readonly={readonly}
        />
      );

    case AnswerFormatType.MultipleResponse:
      return (
        <EditorSolutionMultipleResponse
          questionId={questionId}
          readonly={readonly}
        />
      );

    case AnswerFormatType.TrueFalseNotGiven:
      return (
        <EditorSolutionTrueFalseNotGiven
          questionId={questionId}
          readonly={readonly}
        />
      );

    case AnswerFormatType.GridIn:
    case AnswerFormatType.FreeResponse:
    case AnswerFormatType.Unscramble:
      return (
        <EditorSolutionGeneral questionId={questionId} readonly={readonly} />
      );

    case AnswerFormatType.QuestionGroup:
      return null;

    case AnswerFormatType.Essay:
      return (
        <EditorSolutionLightbulb
          description={t(
            'No solution needed; AI will assess and offer feedback based on the example answer you provide'
          )}
        />
      );

    default:
      console.error(
        'not supported answer format type',
        infoData.answerFormatType
      );
      return null;
  }
}
