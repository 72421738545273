import React from 'react';

export default function TestScoreTally({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.9421 8.57073C21.7569 8.05576 21.1754 7.78956 20.6464 7.96991L19.0512 8.51918V5.98704C19.0512 5.43777 18.6017 5 18.0376 5C17.4736 5 17.0239 5.43774 17.0239 5.98704V9.21455L15.173 9.84968V5.98704C15.173 5.43777 14.7235 5 14.1595 5C13.5953 5 13.1458 5.43774 13.1458 5.98704V10.5536L11.2949 11.1888V5.98709C11.2949 5.43781 10.8454 5.00005 10.2813 5.00005C9.71714 5.00005 9.26763 5.43779 9.26763 5.98709V11.8928L7.41674 12.5279V5.98718C7.41674 5.4379 6.96725 5.00014 6.4032 5.00014C5.839 5.00014 5.38949 5.43788 5.38949 5.98718V13.2232L2.67484 14.1589C2.14605 14.3392 1.8727 14.9055 2.05789 15.4207C2.20766 15.8241 2.59556 16.0817 3.0098 16.0817C3.12434 16.0817 3.23888 16.0645 3.34475 16.0215L5.38083 15.3176L5.38065 18.0044C5.38065 18.5537 5.83014 18.9914 6.39418 18.9914C6.95839 18.9914 7.41673 18.5537 7.41673 18.0044V14.6224L9.26762 13.9873V18.0044C9.26762 18.5537 9.71711 18.9914 10.2812 18.9914C10.8454 18.9914 11.2949 18.5537 11.2949 18.0044V13.2833L13.1458 12.6481V18.0043C13.1458 18.5536 13.5953 18.9914 14.1593 18.9914C14.7235 18.9914 15.173 18.5536 15.173 18.0043V11.9529L17.0239 11.3177V18.013C17.0239 18.5622 17.4734 19 18.0374 19C18.6016 19 19.0512 18.5536 19.0512 18.0044V10.6139L21.3252 9.83268C21.854 9.65234 22.1273 9.08581 21.9421 8.57084L21.9421 8.57073Z"
        fill="currentColor"
      />
    </svg>
  );
}
