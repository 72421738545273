import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { TableLayoutConfig } from '@lib/ia/src/layouts/TableLayout/types';

const isCreating = (id: string) => id.startsWith('temp-');

export const useEditorRubricLayoutConfig = ({
  rubric,
  readonly,
  sx,
}: {
  rubric?: GetCreatorQuestionRubricRes;
  readonly?: boolean;
  sx?: SxProps;
}) => {
  const { t } = useTranslation('editor');
  const isEnableDescriptor = rubric?.isEnableDescriptor;
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return useMemo(() => {
    const config: TableLayoutConfig = {
      layout: 'table-layout',
      table: {
        columnOrder: isEnableDescriptor
          ? ['criteria', 'descriptor']
          : ['criteria', 'score'],
        gridTemplateColumns: isEnableDescriptor
          ? mdUp
            ? '1fr 277px'
            : '1fr 187px'
          : '1fr 32px',
        head: {
          cells: {
            criteria: {
              type: 'default',
              label: 'Criteria',
              icon: 'EditorParaphrase',
            },
            ...(isEnableDescriptor
              ? {
                  descriptor: {
                    type: 'group',
                    gridTemplateColumns: '1fr 32px',
                    cells: [
                      {
                        type: 'default',
                        label: t('Descriptor'),
                        icon: 'EditorParaphrase',
                      },
                      {
                        type: 'default',
                        label: '',
                        icon: 'ChatHashtag',
                      },
                    ],
                  },
                }
              : {
                  score: {
                    type: 'default',
                    label: '',
                    icon: 'ChatHashtag',
                  },
                }),
          },
        },
        rows:
          rubric?.criteria?.map((criterion) => ({
            id: criterion.id,
            cells: {
              criteria: {
                type: 'text',
                text: criterion.description,
                editable: !readonly && !isCreating(criterion.id),
                actionMap: {
                  inputBlur: 'updateCriteriaDescription',
                },
                placeholder: 'Untitled',
              },
              ...(isEnableDescriptor
                ? {
                    descriptor: {
                      type: 'group',
                      gridTemplateColumns: '1fr 32px',
                      rows:
                        criterion.descriptors?.map((desc) => ({
                          id: desc.id,
                          cells: [
                            {
                              type: 'text',
                              value: desc.description,
                            },
                            {
                              type: 'number',
                              value: desc.score,
                              wrap: false,
                            },
                          ],
                        })) || [],
                    },
                  }
                : {
                    score: {
                      type: 'number',
                      value: criterion.score || 0,
                      editable: !readonly && !isCreating(criterion.id),
                      actionMap: {
                        inputBlur: 'updateCriteriaScore',
                      },
                      placeholder: '1',
                    },
                  }),
            },
            draggable: !readonly && !isCreating(criterion.id),
            moreActions:
              !readonly && !isCreating(criterion.id)
                ? [
                    {
                      type: 'event',
                      icon: 'ActionOpenInRightPane',
                      text: t('Open in Side Peek'),
                      value: 'onCriteriaSidePeelClick',
                    },
                    {
                      type: 'event',
                      icon: 'OtherCopy',
                      value: 'duplicateCriteria',
                      text: t('Duplicate'),
                    },
                    {
                      icon: 'OtherDelete',
                      iconSxProps: {
                        color: 'error.dark',
                      },
                      type: 'event',
                      value: 'deleteCriteria',
                      text: t('Delete'),
                      textSxProps: {
                        color: 'error.dark',
                      },
                    },
                  ]
                : undefined,
          })) || [],
        footer: {
          gridTemplateColumns: mdUp ? '1fr 207px 32px' : '1fr 87px 32px',
          cells: [
            { type: 'text', value: '' },
            { type: 'text', value: t('Total Score') },
            {
              type: 'number',
              value:
                rubric?.criteria?.reduce((sum, c) => {
                  if (isEnableDescriptor) {
                    return (
                      sum +
                      (c.descriptors?.reduce(
                        (descSum, d) => descSum + (d.score || 0),
                        0
                      ) || 0)
                    );
                  }
                  return sum + (c.score || 0);
                }, 0) || 0,
            },
          ],
        },
      },
      data: {
        pageSize: rubric?.criteria?.length || 0,
        totalCount: rubric?.criteria?.length || 0,
        state: !rubric
          ? 'loading'
          : rubric.criteria.length === 0
          ? 'empty'
          : 'reachedEnd',
        emptyRow: {
          text: t('rubric.criteria.empty'),
        },
      },
      settings: {
        disableCellFocus: readonly,
        layoutSetting: {
          position: 'rhs',
          showColumnBorder: true,
          showRowBorder: true,
          minWidth: '100%',
          maxWidth: '100%',
          sx,
        },
        bottomButtons: !readonly
          ? {
              addNewRow: {
                text: t('rubric.criteria.create.new'),
                action: 'addNewCriteria',
              },
            }
          : undefined,
        wrap: true,
        sortableConfig: {
          dropAction: 'sortItem',
        },
        sidePeek: {
          enabled: !readonly,
          clickAction: {
            type: 'event',
            value: 'onCriteriaSidePeelClick',
          },
        },
        sideFloatingMenuAddActions: [
          {
            value: 'addNewCriteriaBelow',
            hint: '**Click** to add below',
          },
          {
            value: 'addNewCriteriaAbove',
            hint: '**Option-Click** to add above',
            addKey: 'altKey',
          },
        ],
      },
    };

    return [config];
  }, [isEnableDescriptor, mdUp, t, rubric, readonly, sx]);
};
