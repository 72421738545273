import React, { ReactNode, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Link from 'next/link';
import Router from 'next/router';
import {
  alpha,
  Box,
  ButtonBase,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  BaseLayoutRightPanelPortal,
  BottomSheet,
  Button,
  replaceComponent,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import AvatarGridFolder from '@lib/ia/src/components/AvatarGridFolder';
import Icon from '@lib/ia/src/components/Icon';
import ImageUploader, {
  ImageUploaderHandler,
} from '@lib/ia/src/components/ImageUploader';
import UploadAvatarPanel from '@lib/ia/src/components/RightPanels/UploadAvatarPanel';
import { IaPanelKeys, IaPanelParams } from '@lib/ia/src/types/panels';

import useInputEvent from '../hooks/useInputEvent';
import useSuccessState from '../hooks/useSuccessState';
import { FormLayoutAvatarItem } from '../types';

type AvatarInputProps = {
  item: FormLayoutAvatarItem;
};

const styles = {
  avatarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    alignItems: 'flex-start',
  },
  avatarDescription: {
    fontSize: 12,
    letterSpacing: '0.4px',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    '& span': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 1),
    },
  },
  sheet: {
    display: 'grid',
    '& .MuiButtonBase-root': {
      px: 2.5,
      height: 45,
      justifyContent: 'flex-start',
      typography: 'body1',
      gap: 1,
    },
  },
};

function getNameAbbr(name: string) {
  const nameArr = name.split(' ');
  return (
    nameArr.length < 2
      ? nameArr[0].slice(0, 1)
      : `${nameArr[0][0]}${nameArr[1]?.[0] || ''}`
  ).toUpperCase();
}

function WatchField<T>({
  field,
  children,
}: {
  field: string;
  children: (value: T) => ReactNode;
}) {
  const { watch } = useFormContext();
  const value = watch(field) || '';
  return children(value);
}

function AvatarDisplayName({ item }: { item: FormLayoutAvatarItem }) {
  if (item.avatarName?.type === 'static') {
    return getNameAbbr(item.avatarName.value);
  }
  if (item.avatarName?.type === 'watchName') {
    const defaultName = item.avatarName.defaultName || '';
    return (
      <WatchField field={item.avatarName.nameField}>
        {(name: string) => getNameAbbr(name || defaultName)}
      </WatchField>
    );
  }
  return null;
}

function AvatarDisplay({
  item,
  src,
}: {
  item: FormLayoutAvatarItem;
  src: string;
}) {
  if (src) {
    return <SquareAvatar src={src} size={120} />;
  }
  if (item.subAvatars) {
    return <AvatarGridFolder avatars={item.subAvatars} size={120} />;
  }
  if (item.avatarStyle?.type === 'static') {
    return (
      <SquareAvatar size={120} sx={item.avatarStyle.value}>
        <AvatarDisplayName item={item} />
      </SquareAvatar>
    );
  }
  if (item.avatarStyle?.type === 'watchName') {
    const avatarStyles = item.avatarStyle.styleChoices;
    return (
      <WatchField field={item.avatarStyle.nameField}>
        {(name: string) => {
          const avatarStyleIndex =
            (getNameAbbr(name).charCodeAt(0) || 0) % avatarStyles.length;
          return (
            <SquareAvatar size={120} sx={[avatarStyles[avatarStyleIndex]]}>
              <AvatarDisplayName item={item} />
            </SquareAvatar>
          );
        }}
      </WatchField>
    );
  }
  return (
    <SquareAvatar size={120} blackAndWhite>
      <AvatarDisplayName item={item} />
    </SquareAvatar>
  );
}

export default function AvatarInput({ item }: AvatarInputProps) {
  const uploadConfig = item.avatarUploadConfig;
  const { control, setValue } = useFormContext();
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(uploadConfig?.previewUrl || '');
  const [progress, setProgress] = useState<number | null>(null);
  const bottomSheetImageUploaderRef = useRef<ImageUploaderHandler>(null);

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { rightPanelTarget, openRightPanel } =
    useBaseRightPanel<IaPanelParams>();

  const {
    field: { onChange, onBlur, value },
    fieldState: { error, isDirty },
  } = useController({
    control,
    name: item.name,
    rules: { ...item.rules },
  });

  const successState = useSuccessState(item.name, isDirty);

  const { handleInputBlur, handleInputChange } = useInputEvent({
    name: item.name,
    actionMap: item.actionMap,
    onChange,
    onBlur,
  });

  const handleBlur = () => {
    handleInputBlur();
    void successState.handleBlur();
  };

  const handleChange = (newValue: string) => {
    void handleInputChange(newValue);
    void successState.handleChange();
  };

  const handleImageKeyChange = (newValue: string) => {
    handleChange(newValue);
    setTimeout(() => {
      handleBlur();
    });
  };

  const handleImageFileChange = () => {
    setValue(item.name, value, { shouldDirty: true });
  };

  const handleUploadClick = () => {
    if (!uploadConfig) return;

    if (item.targetType === 'link') {
      void Router.push(uploadConfig.targetUrl);
    } else if (mdUp) {
      openRightPanel(IaPanelKeys.UploadAvatar, {
        config: uploadConfig,
        photoLibrary: item.photoLibrary,
        onImageKeyChange: handleImageKeyChange,
        onFileChange: handleImageFileChange,
        onPreviewUrlChange: setPreviewUrl,
        onProgressChange: setProgress,
      });
    } else {
      setShowBottomSheet(true);
    }
  };

  const handleBottomSheetUploadClick = () => {
    bottomSheetImageUploaderRef.current?.openFileSelector();
  };

  return (
    <Box
      sx={styles.avatarWrapper}
      className={`ia-form-layout_avatar ${item.className || ''}`}
    >
      <AvatarDisplay
        item={item}
        src={previewUrl || value || item.defaultValue || ''}
      />
      <Typography sx={styles.avatarDescription}>
        {!!item.helperText &&
          replaceComponent(item.helperText, /\*\*(.*?)\*\*/g, (match, i) => {
            return <span key={match[0] + i}>{match[1]}</span>;
          })}
      </Typography>
      <Button
        onClick={handleUploadClick}
        prefixIcon={<Icon name="ProfileCamera" />}
        loading={progress !== null && progress !== 100}
        disabled={item.disabled}
      >
        {item.label}
      </Button>
      {!!error?.message && (
        <Typography variant="caption" color="error.dark">
          {error.message}
        </Typography>
      )}
      {item.targetType === 'panel' && (
        <BaseLayoutRightPanelPortal>
          {rightPanelTarget === IaPanelKeys.UploadAvatar && (
            <UploadAvatarPanel />
          )}
        </BaseLayoutRightPanelPortal>
      )}
      {!mdUp && uploadConfig && (
        <BottomSheet
          open={showBottomSheet}
          onClose={() => setShowBottomSheet(false)}
          fixedHeight
        >
          <ImageUploader
            ref={bottomSheetImageUploaderRef}
            config={uploadConfig}
            onImageKeyChange={handleImageKeyChange}
            onFileChange={handleImageFileChange}
            onPreviewUrlChange={setPreviewUrl}
            onProgressChange={setProgress}
          />
          <Box sx={styles.sheet}>
            {!!item.photoLibrary?.url && (
              <ButtonBase component={Link} href={item.photoLibrary.url}>
                <Icon
                  name={
                    item.bottomSheet?.photoLibraryIcon || item.photoLibrary.icon
                  }
                  width={16}
                  height={16}
                />
                {item.bottomSheet?.photoLibraryTitle || item.photoLibrary.title}
              </ButtonBase>
            )}
            <ButtonBase onClick={handleBottomSheetUploadClick}>
              <Icon
                name={
                  item.bottomSheet?.uploadIcon ||
                  uploadConfig?.uploadIcon ||
                  'OtherLink'
                }
                width={16}
                height={16}
              />
              {item.bottomSheet?.uploadTitle || uploadConfig?.uploadTitle}
            </ButtonBase>
          </Box>
        </BottomSheet>
      )}
    </Box>
  );
}
