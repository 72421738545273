import React from 'react';

export default function ProfileDreamCollege({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21 7.42858V8.00001C21 8.07578 20.9684 8.14846 20.9121 8.20204C20.8559 8.25562 20.7796 8.28572 20.7 8.28572H19.8V8.71429C19.8 8.95097 19.5985 9.14286 19.35 9.14286H4.65C4.40149 9.14286 4.2 8.95097 4.2 8.71429V8.28572H3.3C3.22044 8.28572 3.14413 8.25562 3.08787 8.20204C3.03161 8.14846 3 8.07578 3 8.00001V7.42858C3 7.37205 3.01761 7.3168 3.05059 7.26981C3.08357 7.22281 3.13045 7.18619 3.18529 7.16458L11.8853 4.02172C11.9587 3.99276 12.0413 3.99276 12.1147 4.02172L20.8147 7.16458C20.8696 7.18619 20.9164 7.22281 20.9494 7.26981C20.9824 7.3168 21 7.37205 21 7.42858ZM20.1 18.2857H3.9C3.40294 18.2857 3 18.6695 3 19.1429V19.7143C3 19.7901 3.03161 19.8627 3.08787 19.9163C3.14413 19.9699 3.22044 20 3.3 20H20.7C20.7796 20 20.8559 19.9699 20.9121 19.9163C20.9684 19.8627 21 19.7901 21 19.7143V19.1429C21 18.6695 20.5971 18.2857 20.1 18.2857ZM6 9.71429V16.5714H4.65C4.40149 16.5714 4.2 16.7633 4.2 17V17.7143H19.8V17C19.8 16.7633 19.5985 16.5714 19.35 16.5714H18V9.71429H15.6V16.5714H13.2V9.71429H10.8V16.5714H8.4V9.71429H6Z"
        fill="currentColor"
      />
    </svg>
  );
}
