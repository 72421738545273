import { useIaRender } from '@lib/ia/src/core/IaRender/useIaRender';

import { CtaCustomize } from '../../types';

type NotificationCtaButtonProps = CtaCustomize;

export default function NotificationCustomize({
  value,
  metadata,
}: NotificationCtaButtonProps) {
  const { getIaRender } = useIaRender();

  const render = getIaRender(value);

  if (render) {
    return render(metadata || {});
  }

  return null;
}
