import React from 'react';

export default function EmojiFood2({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8788 11.75C19.6988 11.75 19.5205 11.7854 19.3541 11.8543C19.1878 11.9231 19.0367 12.0241 18.9093 12.1513C18.9093 12.1514 18.9094 12.1513 18.9093 12.1513L16.8107 14.25H20C20.3315 14.25 20.6495 14.1183 20.8839 13.8839C21.1183 13.6495 21.25 13.3315 21.25 13C21.25 12.6685 21.1183 12.3505 20.8839 12.1161C20.6495 11.8817 20.3315 11.75 20 11.75H19.8788ZM20.25 15.75H3.75V17C3.75 17.5967 3.98705 18.169 4.40901 18.591C4.83097 19.0129 5.40326 19.25 6 19.25H18C18.5967 19.25 19.169 19.0129 19.591 18.591C20.0129 18.169 20.25 17.5967 20.25 17V15.75ZM2.25 15.1213V17C2.25 17.9946 2.64509 18.9484 3.34835 19.6517C4.05161 20.3549 5.00544 20.75 6 20.75H18C18.9946 20.75 19.9484 20.3549 20.6517 19.6517C21.3549 18.9484 21.75 17.9946 21.75 17V15.1213C21.8173 15.0658 21.8823 15.0068 21.9445 14.9445C22.4603 14.4288 22.75 13.7293 22.75 13C22.75 12.2707 22.4603 11.5712 21.9445 11.0555C21.4288 10.5397 20.7293 10.25 20 10.25H19.8792C19.8791 10.25 19.8792 10.25 19.8792 10.25C19.5022 10.2499 19.1287 10.3241 18.7803 10.4683C18.432 10.6126 18.1154 10.824 17.8488 11.0906L15 13.9393L13.2873 12.2267C12.0216 10.9611 10.3049 10.2501 8.51504 10.25H4C3.27065 10.25 2.57118 10.5397 2.05546 11.0555C1.53973 11.5712 1.25 12.2707 1.25 13C1.25 13.7293 1.53973 14.4288 2.05546 14.9445C2.11772 15.0068 2.18265 15.0658 2.25 15.1213ZM4 14.25H13.1893L12.2267 13.2874C12.2267 13.2874 12.2267 13.2874 12.2267 13.2874C11.2422 12.3031 9.90711 11.7501 8.515 11.75C8.51499 11.75 8.51501 11.75 8.515 11.75H4C3.66848 11.75 3.35054 11.8817 3.11612 12.1161C2.8817 12.3505 2.75 12.6685 2.75 13C2.75 13.3315 2.8817 13.6495 3.11612 13.8839C3.35054 14.1183 3.66848 14.25 4 14.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5966 4.76773C7.34799 3.70241 9.60675 3.25 11.9997 3.25C14.3927 3.25 16.6515 3.70241 18.4029 4.76773C20.1837 5.85096 21.4026 7.54907 21.6864 9.91052L21.6864 9.91064C21.8133 10.9679 20.9378 11.75 19.9997 11.75H3.99973C3.06169 11.75 2.1862 10.9679 2.31308 9.91064L2.31309 9.91052C2.59686 7.54907 3.81575 5.85096 5.5966 4.76773ZM3.80238 10.0895C3.79817 10.1247 3.8071 10.1548 3.83691 10.1857C3.87065 10.2207 3.92791 10.25 3.99973 10.25H19.9997C20.0716 10.25 20.1288 10.2207 20.1626 10.1857C20.1924 10.1547 20.2013 10.1247 20.1971 10.0894C19.9708 8.20687 19.0302 6.90502 17.6233 6.04927C16.187 5.17559 14.2298 4.75 11.9997 4.75C9.76971 4.75 7.81247 5.17559 6.37612 6.04927C4.96924 6.90502 4.02864 8.20687 3.80239 10.0894"
        fill="currentColor"
      />
    </svg>
  );
}
