import { ReactNode } from 'react';
import {
  alpha,
  Box,
  ButtonBase,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { ActionClose as ActionCloseIcon } from '@front/icon';
import { IconButton, SquareAvatar } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaItemStatus } from '@lib/ia/src/core/IaItemStatus/useIaItemStatus';

import {
  WorkflowFieldConfig,
  WorkflowFieldValue,
  WorkflowItem,
  WorkflowLayoutSettings,
} from '../types';

const styles = {
  card: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 1,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    backdropFilter: 'blur(25px)',
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 3,
    pt: 1.5,
    pb: 1,
    px: 1.5,
    position: 'relative',
    width: '100%',
  },
  hover: (theme: Theme) => ({
    '@media (hover: hover)': {
      '&:hover': {
        boxShadow: `inset 0 0 0 1px ${alpha(
          theme.palette.text.primary,
          0.3
        )}, 0px 8px 16px ${alpha(theme.palette.text.primary, 0.15)}`,
        backgroundColor: alpha(theme.palette.text.primary, 0.1),
      },
    },
  }),
  clickable: {
    cursor: 'pointer',
  },
  error: {
    borderColor: 'error.dark',
  },
  disabled: {
    opacity: 0.5,
  },
  selected: {
    bgcolor: 'alpha.primaryLightA10',
    borderColor: 'alpha.primaryLightA50',
  },
  numberContainer: {
    position: 'relative',
    width: 32,
    height: 32,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bigNumber: {
    typography: 'body1',
    fontWeight: 700,
  },
  smallNumber: {
    fontSize: '10px',
    fontWeight: 700,
  },
  content: {
    display: 'grid',
    flex: 1,
  },
  numberBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mask: 'url(/mask-avatar.svg) center center/contain no-repeat',
  },
  outlineNumberBox: {
    position: 'relative',
    backgroundColor: 'transparent',
  },
  solidNumberBox: {
    backgroundColor: 'text.primary',
    color: 'background.default',
  },
  outlineMask: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    mask: 'url(/mask-avatar-progress.svg) center center/contain no-repeat',
    backgroundColor: 'white',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    py: '3.5px',
    height: 28,
  },
  numberTag: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    opacity: 0.5,
    px: 1,
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    height: 16,
    mb: 0.5,
  },
  title: {
    px: 1,
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    opacity: 0.5,
    overflow: 'hidden',
    px: 1,
  },
  tagInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '4px',
    px: '6px',
    py: '2px',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    borderRadius: '4px',
    p: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    px: 1,
  },
};

type NumberBoxProps = {
  value: number;
  size?: number;
  outline?: boolean;
  sx?: SxProps;
};

const NumberBox = ({ value, size = 16, outline, sx }: NumberBoxProps) => {
  if (typeof value !== 'number') {
    return null;
  }

  const sxProps = Array.isArray(sx) ? sx : [sx];

  if (outline) {
    return (
      <Box
        sx={[
          styles.numberBox,
          styles.outlineNumberBox,
          { width: size, height: size },
          ...sxProps,
        ]}
      >
        {value}
        <Box sx={styles.outlineMask} />
      </Box>
    );
  }

  return (
    <Box
      sx={[
        styles.numberBox,
        styles.solidNumberBox,
        { width: size, height: size },
        ...sxProps,
      ]}
    >
      {value}
    </Box>
  );
};

interface WorkflowCardProps {
  item: WorkflowItem;
  index: number;
  mode?: 'edit' | 'view';
  settings: WorkflowLayoutSettings;
  onDelete?: (item: WorkflowItem) => void;
  onSelect?: (item: WorkflowItem) => void;
}

export default function WorkflowCard({
  item,
  index,
  mode = 'view',
  settings,
  onDelete,
  onSelect,
}: WorkflowCardProps) {
  const renderFieldIcon = (icon: WorkflowFieldConfig['icon']): ReactNode => {
    if (icon.type === 'icon') {
      return <Icon name={icon.value as string} width={16} height={16} />;
    }

    if (icon.type === 'number') {
      return (
        <NumberBox
          value={icon.value as number}
          outline={icon.outline}
          sx={styles.smallNumber}
        />
      );
    }

    return null;
  };

  const renderFieldContent = (
    type: WorkflowFieldConfig['type'],
    content: WorkflowFieldValue['content']
  ): ReactNode => {
    if (type === 'tag') {
      return (
        <Box sx={styles.tag}>
          <Box sx={styles.tagInner}>
            {content.prefix && (
              <Icon
                name={content.prefix.value}
                width={content.prefix.iconSize || 16}
                height={content.prefix.iconSize || 16}
                text={content.prefix.text}
              />
            )}
            {typeof content.text === 'string' && (
              <Typography variant="caption">{content.text}</Typography>
            )}
          </Box>
        </Box>
      );
    }

    if (type === 'user' || type === 'agent') {
      return (
        <>
          <Box sx={styles.user}>
            {content.avatar && <SquareAvatar src={content.avatar} size={16} />}
            <Typography variant="body2">
              {typeof content.text === 'string'
                ? content.text
                : content.text.map((textItem, textIndex) => {
                    if (textItem.type === 'text') {
                      return (
                        <Typography component="span" key={textIndex}>
                          {textItem.value}
                        </Typography>
                      );
                    }
                    return null;
                  })}
            </Typography>
          </Box>
        </>
      );
    }

    return (
      <Typography variant="body2">
        {typeof content.text === 'string'
          ? content.text
          : content.text.map((textItem, textIndex) => {
              if (textItem.type === 'text') {
                return (
                  <Typography component="span" key={textIndex}>
                    {textItem.value}
                  </Typography>
                );
              }
              return null;
            })}
      </Typography>
    );
  };

  const renderField = (fieldConfig: WorkflowFieldConfig): ReactNode => {
    const fieldValue = item.fieldValues.find(
      (v) => v.fieldId === fieldConfig.id
    );
    const fieldContent = fieldValue?.content;

    return (
      <Box key={fieldConfig.id} sx={styles.row}>
        {renderFieldIcon(fieldConfig.icon)}
        {fieldContent && renderFieldContent(fieldConfig.type, fieldContent)}
      </Box>
    );
  };

  const { getItemStatus } = useIaItemStatus();

  const itemStatus = getItemStatus?.({ item });

  const handleDeleteClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    onDelete?.(item);
  };

  return (
    <ButtonBase
      sx={[
        styles.card,
        !itemStatus?.selected && styles.hover,
        !!itemStatus?.selected && styles.selected,
        !!item.isError && styles.error,
        !!(itemStatus?.loading || itemStatus?.disabled) && styles.disabled,
        !!onSelect && styles.clickable,
      ]}
      onClick={() => onSelect?.(item)}
      disabled={itemStatus?.loading || itemStatus?.disabled}
      disableRipple
    >
      <Box sx={styles.numberContainer}>
        <NumberBox value={index + 1} outline size={32} sx={styles.bigNumber} />
      </Box>

      <Box sx={styles.content}>
        <Box sx={styles.titleRow}>
          {item.titlePrefix && renderFieldIcon(item.titlePrefix)}
          <Typography variant="subtitle2" sx={styles.title}>
            {item.title}
          </Typography>
        </Box>

        {settings.fields.map(renderField)}
      </Box>

      {mode === 'edit' && (
        <Box>
          <IconButton
            customSize={20}
            onClick={handleDeleteClick}
            disabled={itemStatus?.loading || itemStatus?.disabled}
          >
            <ActionCloseIcon />
          </IconButton>
        </Box>
      )}
    </ButtonBase>
  );
}
