import React from 'react';

export default function NFTFire({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.1217 3.19249L9.94245 2.5L9.71782 3.82904C9.36029 5.92761 7.89743 7.8776 6.28388 9.24791C3.1251 11.9317 2.43344 14.8484 3.41149 17.2561C4.34743 19.5601 6.72283 21.1377 9.1338 21.4009L9.69162 21.4615C8.31205 20.6351 7.42665 18.7043 7.73551 17.2616C8.04063 15.8436 9.08139 14.5154 11.0805 13.2901L12.0885 12.6737L12.4648 13.7789C12.6866 14.432 13.0703 14.9566 13.4606 15.4895C13.6478 15.7463 13.8378 16.0059 14.0119 16.282C14.6137 17.2405 14.7728 18.3063 14.3844 19.3638C14.0306 20.3251 13.4466 21.0808 12.6436 21.5L13.5514 21.4009C15.8145 21.1542 17.4758 20.3957 18.5549 19.1272C19.6247 17.8697 20 16.2508 20 14.5274C20 12.9223 19.3271 11.2676 18.5334 9.89088C17.6031 8.27842 16.3892 6.93929 15.0639 5.64144C14.8346 6.09088 14.8524 6.27248 14.3806 6.99433C13.7683 5.40003 12.6185 4.05864 11.1217 3.19249Z"
        fill="currentColor"
      />
    </svg>
  );
}
