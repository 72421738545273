import React from 'react';

export default function MainTrade({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.51875 6.81588V3.66478H6.53125V6.81588H8.05V19.0084H6.53125V21H5.51875V19.0084H4V6.81588H5.51875Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M11.5938 12.141V9.99324H12.6063V12.141H14.125V19.7382H12.6063V21H11.5938V19.7382H10.075V12.141H11.5938Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M17.4688 4.78978V3H18.4812V4.78978H20V9.99324H18.4812V12.5625H17.4688V9.99324H15.95V4.78978H17.4688Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
