import React, { MouseEvent, useRef } from 'react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { FloatingMenu, ResponsiveMenu } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { MessageItemEvent } from '@lib/ia/src/layouts/ChannelLayout/types';

export type MessageItemSideMenuProps = {
  id?: string;
  show: boolean;
  setShow: (value: boolean) => void;
  dropdownOpen: boolean;
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  boxRef: React.RefObject<HTMLElement | null>;
  events?: MessageItemEvent[];
  eventMenuClassName?: string;
};

export default function SideMenu({
  id,
  show,
  setShow,
  dropdownOpen,
  setDropdownOpen,
  boxRef,
  events = [],
  eventMenuClassName,
}: MessageItemSideMenuProps) {
  const { getIaAction, iaRoutingAction } = useIaAction();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const menuRef = useRef<Element | null>(null);

  const handleMoreButtonClick = (ev: MouseEvent<HTMLButtonElement>) => {
    setDropdownOpen(true);
    ev.stopPropagation();
  };

  const handleMenuClose = (ev?: any) => {
    setDropdownOpen(false);
    setShow(false);

    ev?.stopPropagation();
  };

  const handleMenuClick = (ev: MouseEvent, event: MessageItemEvent) => {
    ev.stopPropagation();

    const { type, value, metadata } = event;

    if (type === 'link') {
      iaRoutingAction(value);
    } else {
      const onClickAction = getIaAction<string>(value);
      onClickAction?.action?.(id, metadata);
    }

    setTimeout(() => {
      setDropdownOpen(false);
    }, metadata?.closeMenuDelay ?? 0);
    setShow(false);
  };

  if (events.length <= 0) return null;

  return (
    <>
      {mdUp && (
        <FloatingMenu
          ref={menuRef}
          open={show || dropdownOpen}
          anchorEl={boxRef.current}
          className={eventMenuClassName}
          transition={false} // disable animation so when the menu is out of view, it will not make browser default scrollbar appear
          disablePortal // disable portal otherwise the menu will on the top of rhs
          placement="top-end"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [
                  -1, // 1px for the buffer to right, otherwise in some resolution it will be put into left (we want it to be on the right)
                  -13.5, // floating menu height 27px, we put it in the middle of the box
                ],
              },
            },
          ]}
        >
          <FloatingMenu.Button
            onClick={handleMoreButtonClick}
            selected={dropdownOpen}
          >
            <Icon name="ActionMore" />
          </FloatingMenu.Button>
        </FloatingMenu>
      )}
      <ResponsiveMenu
        open={dropdownOpen}
        onClose={handleMenuClose}
        menuProps={{
          anchorEl: menuRef.current,
          anchorOrigin: {
            vertical: 'center',
            horizontal: -12, // 8px mx in ResponsiveMenu mx:1 + 4px spacing
          },
          transformOrigin: {
            vertical: 'center',
            horizontal: 'right',
          },
        }}
        sheetProps={{
          defaultContentHeight: events.length * 45,
        }}
      >
        {events.map((event) => (
          <MenuItem
            key={`${event.type}-${event.value}`}
            value={event.value}
            onClick={(ev) => handleMenuClick(ev, event)}
          >
            {event.icon && (
              <ListItemIcon sx={event.iconSxProps}>
                <Icon name={event.icon} width={16} height={16} />
              </ListItemIcon>
            )}
            <ListItemText sx={event.textSxProps}>{event.text}</ListItemText>
          </MenuItem>
        ))}
      </ResponsiveMenu>
    </>
  );
}
