import { useMemo } from 'react';
import {
  AgentViewSlug,
  ClubBotType,
  useAgents,
  useClubBots,
  useSuggestionClubMembers,
} from '@lib/web/apis';
import { useIaClub } from '@lib/web/hooks';

const MAX_SHOW_MEMBERS = 15;

export const useAskForHelpMembers = ({
  clubSlug,
  clubId,
  enable = true,
}: {
  clubSlug?: string;
  clubId?: string;
  enable?: boolean;
}) => {
  const { club } = useIaClub(clubSlug);
  const clubIdToUse = clubId || club?.clubId;

  const { data: helpBots } = useClubBots({
    clubSlug,
    agentType: ClubBotType.Help,
  });

  const { dataset: clubAgents } = useAgents(
    {
      viewSlug: AgentViewSlug.ThreadViewAgentDefault,
      search: `agentClub:${clubIdToUse}`,
      searchFields: 'agentClub:eq',
      orderBy: 'agentName',
      sortBy: 'asc',
      limit: MAX_SHOW_MEMBERS,
    },
    {
      enable: enable && !!clubIdToUse,
    }
  );

  const { data: suggestionMembers } = useSuggestionClubMembers(
    clubSlug,
    MAX_SHOW_MEMBERS
  );

  return useMemo(() => {
    const helpBot = helpBots?.data.items.find((item) => item.isDefault);

    return {
      helpBot,
      clubAgents: clubAgents?.filter((agent) => agent.agentId !== helpBot?.id),
      suggestionMembers: suggestionMembers?.data || [],
    };
  }, [helpBots, clubAgents, suggestionMembers]);
};
