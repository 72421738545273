import React from 'react';

export default function PropertyTypeInput({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.78947 20C5.29737 20 4.87625 19.8261 4.52611 19.4782C4.17596 19.1304 4.0006 18.7117 4 18.2222V5.77778C4 5.28889 4.17537 4.87052 4.52611 4.52267C4.87684 4.17481 5.29796 4.00059 5.78947 4H18.3158C18.8079 4 19.2293 4.17422 19.5801 4.52267C19.9308 4.87111 20.1059 5.28948 20.1053 5.77778V7.55556H18.3158V5.77778H5.78947V18.2222H18.3158V16.4444H20.1053V18.2222C20.1053 18.7111 19.9302 19.1298 19.5801 19.4782C19.2299 19.8267 18.8085 20.0006 18.3158 20H5.78947ZM16.5263 16.4444L15.2737 15.2L17.5776 12.8889H9.36842V11.1111H17.5776L15.2737 8.8L16.5263 7.55556L21 12L16.5263 16.4444Z"
        fill="currentColor"
      />
    </svg>
  );
}
