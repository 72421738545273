import TrueFalseNotGiven from './components/TrueFalseNotGiven';
import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export type EditorAnswerTrueFalseNotGivenProps = {
  questionId: string;
  readonly?: boolean;
};

export default function EditorAnswerTrueFalseNotGiven({
  questionId,
  readonly,
}: EditorAnswerTrueFalseNotGivenProps) {
  const { choiceValues } = useData(questionId);
  const { handleSelect } = useEventHandle(questionId);

  return (
    <TrueFalseNotGiven
      choiceValues={choiceValues}
      onSelect={handleSelect}
      readonly={readonly}
    />
  );
}
