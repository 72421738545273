import { useContext } from 'react';
import { ThreadComposerCustomContext } from '@lib/web/thread/contexts/threadComposerCustomContext';
import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';
import { isTextExceedSizeLimit } from '@lib/web/thread/hooks/message/useBuildMessageData';

export const useSendMessageDisabled = () => {
  const { text, latexActionState } = useThreadComposer();
  const { isBlockHtmlEmpty } = useContext(ThreadComposerCustomContext);

  const hasAnyErrorLatexBlock = Object.entries(
    latexActionState.latexBlocksMap
  ).some(
    ([blockId, latexData]) =>
      text.includes(blockId) && latexData.errors.length > 0
  );

  const hasAnyInvalidImageOrFileBlock = [
    'status="added-by-insert"',
    'status="added-by-file-drop"',
    'status="ready-for-upload"',
    'status="upload-fail"',
  ].some((keyword) => text.includes(keyword));

  const isTextTooLarge = isTextExceedSizeLimit(text);

  return (
    isBlockHtmlEmpty(text) ||
    hasAnyErrorLatexBlock ||
    hasAnyInvalidImageOrFileBlock ||
    isTextTooLarge
  );
};
