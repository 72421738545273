import React from 'react';
import { alpha, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export type IaTableEmptyRowProps = {
  text: string;
};

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'max-content', // for position:sticky
    alignItems: 'center',
    height: '32px',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
  },
  content: {
    position: 'sticky',
    left: '24px',
    px: 1,
  },
};
export default function IaTableEmptyRow({ text }: IaTableEmptyRowProps) {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <Typography variant="body2">{text}</Typography>
      </Box>
    </Box>
  );
}
