import { useMemo } from 'react';
import {
  ThreadViewStatus,
  ThreadViewType,
  ThreadViewUserStatus,
  useAuth,
} from '@lib/web/apis';
import { v4 } from 'uuid';

import { generateDmViewConfig } from '../../utils/viewUtils';

export const DM_EVERYONE_VIEW_ID = 'everyone';

export const useDefaultDmEveryoneThreadView = () => {
  const { member } = useAuth();

  const defaultEveryoneView = useMemo(() => {
    const myMemberId = member?.memberId || '';
    const now = new Date().toISOString();
    const { filterConfig, sortConfig } = generateDmViewConfig({
      memberIds: [myMemberId],
    });
    return {
      type: ThreadViewType.DmEveryone,
      id: DM_EVERYONE_VIEW_ID,
      status: ThreadViewStatus.Active,
      createdAt: now,
      updatedAt: now,
      referenceId: null,
      name: null,
      emoji: null,
      sourceId: null,
      clubId: null,
      hideAt: null,
      threadViewUsers: [
        {
          id: v4(),
          createdAt: now,
          customEmoji: null,
          customName: null,
          filterConfig: filterConfig || '',
          sortConfig: sortConfig || '',
          memberId: myMemberId,
          status: ThreadViewUserStatus.Active,
          updatedAt: now,
        },
      ],
    };
  }, [member?.memberId]);

  return {
    defaultEveryoneView,
  };
};
