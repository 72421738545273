import React from 'react';

export default function EditorEssay({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#FF4E8E"
        fillRule="evenodd"
      />
      <path
        d="M17.0794 4.72742C16.8091 4.42704 16.3466 4.4028 16.0464 4.67328L7.5475 12.3307C7.50295 12.3708 7.46987 12.4208 7.45128 12.4762L6.75508 14.5487C6.66816 14.8075 6.91666 15.0579 7.19803 14.9951L9.28534 14.529C9.34934 14.5147 9.40803 14.4847 9.4554 14.442L17.9498 6.78865C18.25 6.51816 18.2743 6.05539 18.004 5.75501L17.0794 4.72742Z"
        fill="#080808"
      />
      <path
        d="M1.93701 16.5C1.93701 16.2239 2.17265 16 2.46333 16H21.4109C21.7015 16 21.9372 16.2239 21.9372 16.5V17.5C21.9372 17.7761 21.7015 18 21.4109 18H2.46333C2.17265 18 1.93701 17.7761 1.93701 17.5V16.5Z"
        fill="#080808"
      />
    </svg>
  );
}
