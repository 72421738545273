import generateHook from './swr.helper';

export const useVerifiedPhoneNumberList = () =>
  generateHook<VerifiedPhoneNumberListRes[]>('v2/phone/verify/info/list', {
    auth: true,
    immutable: true,
  });

export const useVerifiedPhoneNumber = () =>
  generateHook<VerifiedPhoneNumberRes>('v2/phone/verify/info', {
    auth: true,
    immutable: true,
  });
