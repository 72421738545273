import { Fragment, MouseEvent } from 'react';
import Link from 'next/link';
import {
  alpha,
  Box,
  ButtonBase,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import {
  IndicatorGroup,
  InternalButton,
  Mention,
  SimpleTooltip,
  SquareAvatar,
} from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { IaAction, IaHoverEvent, IaRichText } from '@lib/ia/src/core/types';
import { sanitize } from 'dompurify';

import { useIaRender } from '../../core/IaRender/useIaRender';
import { HtmlRenderer } from '../HtmlRenderer';
import Icon from '../Icon';

const styles = {
  root: (gap: number) => ({
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap,
  }),
  inline: (gap: number) => ({
    display: 'inline',
    '& .rt-text, .rt-user, .rt-user-text, .rt-icon, .rt-url, .rt-highlight': {
      display: 'inline',
      m: 0,
      verticalAlign: 'middle',
      lineHeight: '120%',
      '&:not(:last-child):after': {
        content: '" "',
        display: 'inline-block',
        width: gap ? gap : '4px',
      },
    },
    '& .rt-text': {
      wordBreak: 'break-word',
    },
    '& .rt-tag': {
      m: 0,
    },
    '& .rt-mention': {
      m: 0,
      '& .mention-text': {
        maxWidth: '120px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  }),
  tag: {
    mt: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    color: 'grey.300',
  },
  tagInline: {
    mt: '4px',
    mr: 1,
    display: 'inline-flex',
    justifyContent: 'flex-start',
  },
  plainText: {
    display: 'inline',
    verticalAlign: 'middle',
    alignItems: 'center',
    color: 'grey.300',
  },
  url: {
    textDecoration: 'underline',
    verticalAlign: 'middle',
    alignItems: 'center',
    color: 'white',
  },
  userText: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    color: 'grey.300',
  },
  userTextName: {
    fontWeight: 700,
    cursor: 'default',
    color: 'grey.300',
  },
  userTextIndicator: {
    pr: 0,
    verticalAlign: 'middle',
  },
  user: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    mx: 0.5,
    px: '2px',
    gap: '2px',
    height: '17px',
    borderRadius: '2px',
    backgroundColor: 'alpha.primaryLightA10',
    span: {
      color: 'primary.main',
    },
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: 'alpha.primaryLightA30',
      },
    },
  },
  userSkeleton: {
    mx: 0.5,
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  highlight: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    mx: 0.5,
    px: '2px',
    gap: '2px',
    height: '17px',
    borderRadius: '2px',
    backgroundColor: 'alpha.primaryLightA10',
    border: 'none',
    span: {
      color: 'primary.main',
    },
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: 'alpha.primaryLightA30',
      },
    },
  },
  tagButton: {
    fontFamily: 'inherit',
    bgcolor: 'transparent',
    border: 'none',
    p: 0,
  },
  linkButton: {
    mx: 0.25,
    px: '2px',
    pb: '2px',
    pt: 0,
    borderRadius: 1,
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  badge: {
    position: 'absolute',
    right: -1,
    bottom: -1,
  },
  cornerBadgeIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
  },
};

export type RichTextProps<M = Record<string, any>> = {
  text: IaRichText<M>;
  variant?: TypographyProps['variant'];
  gap?: number;
  inline?: boolean;
  className?: string;
  sx?: SxProps;
};

function RichTextTag<M = Record<string, any>>({
  textObj,
}: {
  textObj: {
    type: 'tag' | 'link';
    text: string;
    src?: string;
    badgeSrc?: string;
    cornerBadgeIcon?: string;
    prefix?: string;
    alt?: string;
    action?: IaAction;
    hoverAction?: Pick<IaAction, 'value'>;
    tooltip?: string;
    className?: string;
    metadata?: M;
  };
}) {
  const { getIaAction } = useIaAction();

  const commonProps = {
    sx: textObj.type === 'tag' ? styles.tagButton : styles.linkButton,
    rel: 'noopener noreferrer',
    onClick: (e: MouseEvent) => {
      e.stopPropagation();
    },
    onMouseDown: (e: MouseEvent) => {
      e.stopPropagation();
    },
  };
  const isLink = textObj.action?.type === 'link';
  const actionProps = isLink
    ? {
        href: textObj.action?.value,
      }
    : {
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          getIaAction<M>(textObj.action?.value)?.action(textObj.metadata);
        },
      };
  const hoverActionProps = textObj.hoverAction
    ? {
        onMouseEnter: (ev: MouseEvent) => {
          getIaAction<IaHoverEvent<M | undefined>>(
            textObj.hoverAction?.value
          )?.action({
            anchorEl: ev.currentTarget,
            target: textObj.metadata,
          });
        },
      }
    : undefined;

  const renderInner =
    textObj.type === 'tag' ? (
      <Box
        component={isLink ? Link : 'button'}
        {...commonProps}
        {...actionProps}
        {...hoverActionProps}
        className={`${textObj.className || ''} rt-tag`}
      >
        <Mention
          src={textObj.src}
          alt={textObj.alt}
          display={textObj.text}
          prefix={textObj.prefix}
        />
      </Box>
    ) : (
      <InternalButton
        component={isLink ? Link : 'button'}
        {...commonProps}
        {...actionProps}
        {...hoverActionProps}
        className={`${textObj.className || ''} rt-tag`}
        prefixIcon={
          textObj.src ? (
            <Box position="relative" className="rt-tag-avatar">
              <SquareAvatar src={textObj.src} size={16}>
                {textObj.text}
              </SquareAvatar>
              {textObj.badgeSrc && (
                <SquareAvatar sx={styles.badge} src={textObj.badgeSrc} size={9}>
                  {textObj.text}
                </SquareAvatar>
              )}
              {textObj.cornerBadgeIcon && (
                <Box sx={styles.cornerBadgeIcon}>
                  <Icon
                    name={textObj.cornerBadgeIcon}
                    width={3.2}
                    height={3.2}
                  />
                </Box>
              )}
            </Box>
          ) : undefined
        }
      >
        {textObj.text}
      </InternalButton>
    );
  return textObj.tooltip ? (
    <SimpleTooltip title={textObj.tooltip}>
      <span>{renderInner}</span>
    </SimpleTooltip>
  ) : (
    renderInner
  );
}

function CustomizeRenderer<M = Record<string, any>>({
  value,
  metadata,
}: {
  value: string;
  metadata?: M;
}) {
  const { getIaRender } = useIaRender();

  const render = getIaRender(value);

  if (render) {
    return render(metadata || {});
  }

  return null;
}

export default function RichText<M = Record<string, any>>({
  text,
  variant = 'body1',
  gap = 0,
  inline = false,
  className,
  sx,
}: RichTextProps<M>) {
  const { getIaAction } = useIaAction();
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <Box
      sx={[styles.root(gap || 0), inline && styles.inline(gap), ...sxProps]}
      className={className}
    >
      {typeof text === 'string' && (
        <Typography sx={styles.plainText} component="div" variant={variant}>
          <HtmlRenderer htmlString={sanitize(text)} />
        </Typography>
      )}

      {Array.isArray(text) &&
        text.map((textObject, index) => (
          <Fragment key={index}>
            {textObject.type === 'html' && (
              <Typography
                sx={styles.plainText}
                component="div"
                variant={variant}
              >
                <HtmlRenderer htmlString={sanitize(textObject.value)} />
              </Typography>
            )}
            {textObject.type === 'icon' && (
              <Icon
                name={textObject.value}
                width={textObject.width || 16}
                height={textObject.height || 16}
                className="rt-icon"
              />
            )}
            {textObject.type === 'text' && (
              <Typography
                sx={[
                  styles.plainText,
                  ...(Array.isArray(textObject.sx)
                    ? textObject.sx
                    : [textObject.sx]),
                ]}
                variant={variant}
                className="rt-text"
              >
                {textObject.value}
              </Typography>
            )}
            {textObject.type === 'url' && (
              <Typography
                sx={styles.url}
                component="a"
                target="_blank"
                href={textObject.url}
                variant={variant}
                className="rt-url"
              >
                {textObject.text}
              </Typography>
            )}
            {textObject.type === 'userText' && (
              <Box
                sx={styles.userText}
                onMouseEnter={(ev: MouseEvent) => {
                  getIaAction<IaHoverEvent<{ userId: string }>>(
                    textObject.hoverAction?.value
                  )?.action({
                    anchorEl: ev.currentTarget,
                    target: { userId: textObject.id },
                  });
                }}
                component="span"
                className={`${textObject.className || ''} rt-user-text`}
              >
                <Typography variant="chatBody" sx={styles.userTextName}>
                  {textObject.name}
                </Typography>
                {!!textObject.indicators?.length && (
                  <IndicatorGroup
                    sx={styles.userTextIndicator}
                    indicators={textObject.indicators}
                    size={16}
                  />
                )}
              </Box>
            )}
            {textObject.type === 'user' && !textObject.name && (
              <Typography sx={styles.userSkeleton} variant={variant}>
                <Skeleton width={100} />
              </Typography>
            )}
            {textObject.type === 'user' && !!textObject.name && (
              <ButtonBase
                sx={styles.tagButton}
                onClick={(ev: MouseEvent) => {
                  ev.stopPropagation();
                  getIaAction<{ userId: string }>(
                    textObject.action?.value
                  )?.action({ userId: textObject.id });
                }}
                onMouseEnter={(ev: MouseEvent) => {
                  ev.stopPropagation();
                  getIaAction<IaHoverEvent<{ userId: string }>>(
                    textObject.hoverAction?.value
                  )?.action({
                    anchorEl: ev.currentTarget,
                    target: { userId: textObject.id },
                  });
                }}
                className={`${textObject.className || ''} rt-user`}
              >
                <Mention
                  src={textObject.src}
                  alt={textObject.name}
                  display={textObject.name}
                  indicators={textObject.indicators}
                  className="rt-mention"
                />
              </ButtonBase>
            )}
            {textObject.type === 'highlight' &&
              (textObject.tooltip ? (
                <SimpleTooltip title={textObject.tooltip}>
                  <Box sx={styles.highlight} className="rt-highlight">
                    <Typography component="span" variant={variant}>
                      {textObject.text}
                    </Typography>
                  </Box>
                </SimpleTooltip>
              ) : (
                <Box sx={styles.highlight} className="rt-highlight">
                  <Typography component="span" variant={variant}>
                    {textObject.text}
                  </Typography>
                </Box>
              ))}
            {textObject.type === 'tag' && (
              <Box sx={[styles.tag, !!textObject.inline && styles.tagInline]}>
                <RichTextTag textObj={textObject} />
              </Box>
            )}
            {textObject.type === 'link' && (
              <Box sx={[styles.tag, !!textObject.inline && styles.tagInline]}>
                <RichTextTag textObj={textObject} />
              </Box>
            )}
            {textObject.type === 'customize' && (
              <CustomizeRenderer {...textObject} />
            )}
          </Fragment>
        ))}
    </Box>
  );
}
