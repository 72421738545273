import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { VariableSubType, VariableType } from './type';
import VariableComponent from './VariableComponent';

export default Node.create({
  name: 'variable-component',

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      name: {
        default: '',
      },
      type: {
        default: VariableType.Number,
      },
      subType: {
        default: VariableSubType.NumberInteger,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'variable-component',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes)];
  },

  renderText({ node }) {
    return `[[${node.attrs.name}]]`;
  },

  addNodeView() {
    return ReactNodeViewRenderer(VariableComponent);
  },
});
