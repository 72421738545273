import React from 'react';

export default function OtherIndicatorDown({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.0411 19.4344C11.4131 20.1885 12.5869 20.1885 12.9589 19.4344L19.9021 5.35849C20.3401 4.47063 19.2173 3.62106 18.3418 4.17787L12.6014 7.82885C12.24 8.05867 11.76 8.05867 11.3986 7.82885L5.65821 4.17787C4.78274 3.62106 3.65994 4.47063 4.09789 5.35849L11.0411 19.4344Z"
        fill="#FF2951"
      />
    </svg>
  );
}
