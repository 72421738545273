import { useMemo, useRef, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { alpha, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import {
  BlockNoteEditor,
  BlockSchema,
  DefaultBlockSchema,
} from '@blocknote/core';
import { useLocalStorage } from '@front/helper';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionChevronUp as ActionChevronUpIcon,
} from '@front/icon';
import { Icon, MaskIcon, ResponsiveDropdown, TipButton } from '@front/ui';
import ThemeProvider from '@lib/web/composer/components/ThemeProvider';

import { textColorMap } from '../../../extensions/TextColor';

const styles = {
  selectedColor: {
    width: '14px',
    height: '14px',
  },
  buttonTip: {
    display: 'flex',
    flexDirection: 'column',
    typography: 'caption',
    '& .hint': {
      opacity: 0.64,
    },
    color: 'background.darker',
  },
  menuItem: {
    typography: 'body2',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: '36px',
    svg: {
      flexShrink: 0,
    },
    gap: '2px',
  },
  popper: {
    zIndex: 1,
    '& .popper-content': {
      mt: 1,
    },
  },
  hint: {
    flex: 'unset',
    ml: 'auto',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
  },
};

type ColorOption = {
  display: string;
  value: string;
  section: string;
  hint?: string;
};

const colorValues = [
  'aha-default',
  'aha-red',
  'aha-orange',
  'aha-yellow',
  'aha-green',
  'aha-blue',
  'aha-purple',
  'aha-pink',
];
const defaultColor = colorValues[0];

const useColorOptions = ({
  lastUsedColor,
}: {
  lastUsedColor: string;
}): ColorOption[] => {
  const { t } = useTranslation('editor');
  const metaKeyName = isMacOs ? 'Cmd' : 'Ctrl';
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return useMemo(
    () =>
      [lastUsedColor, ...colorValues.filter((c) => c !== lastUsedColor)].map(
        (color) => ({
          display: t('composer.formattingToolbar.color', {
            context: color,
          }),
          value: color,
          section:
            lastUsedColor === color
              ? t('composer.formattingToolbar.color section.Last Used')
              : t('composer.formattingToolbar.color section.Color'),
          hint:
            isDesktop && lastUsedColor === color
              ? `${metaKeyName} + Shift + H`
              : undefined,
        })
      ),
    [isDesktop, lastUsedColor, metaKeyName, t]
  );
};

export type ChangeColorDropdownProps<
  BSchema extends BlockSchema = DefaultBlockSchema
> = {
  editor: BlockNoteEditor<BSchema>;
};

function RenderOption(option: ColorOption) {
  return (
    <Box sx={styles.menuItem}>
      <Box
        sx={[
          styles.icon,
          { color: textColorMap[option.value as keyof typeof textColorMap] },
        ]}
      >
        <Icon name="EditorTextVariable" width={16} height={16} />
      </Box>
      <Typography variant="body2">{option.display}</Typography>
      {option.hint && (
        <Typography variant="numberBody3" sx={styles.hint}>
          {option.hint}
        </Typography>
      )}
    </Box>
  );
}

export default function ChangeColorDropdown<T extends BlockSchema>({
  editor,
}: ChangeColorDropdownProps<T>) {
  const { t } = useTranslation('editor');
  const metaKeyName = isMacOs ? 'cmd' : 'ctrl';
  const blockTextColor = editor.getActiveStyles().textColor;
  const [currentColor, setCurrentColor] = useState<string>(
    blockTextColor || defaultColor
  );

  const [lastUsedColor, setLastUsedColor] = useLocalStorage<string>(
    'composer-last-used-color',
    defaultColor
  );

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    editor.focus();
  };

  const colorOptions = useColorOptions({ lastUsedColor });

  const handleColorClick = (color: string) => {
    editor.focus();
    editor.toggleStyles({ textColor: color });
    setCurrentColor(color);
    setLastUsedColor(color);
    handleClose();
  };

  useHotkeys('mod+shift+h', () => {
    handleColorClick(lastUsedColor);
  });

  return (
    <ThemeProvider mode="dark">
      <TipButton
        title={
          <Box sx={styles.buttonTip}>
            <span>{t('composer.formattingToolbar.Text color')}</span>
            <span className="hint">{`${metaKeyName}+shift+H`}</span>
          </Box>
        }
        onClick={handleOpen}
        ref={buttonRef}
        sx={styles.button}
        tooltipProps={{
          placement: 'top-start',
          PopperProps: {
            modifiers: [{ name: 'offset', options: { offset: [0, -8] } }],
          },
        }}
      >
        <MaskIcon>
          <Box
            sx={[
              styles.selectedColor,
              {
                bgcolor:
                  textColorMap[currentColor as keyof typeof textColorMap],
              },
            ]}
          />
        </MaskIcon>
        {open && <ActionChevronDownIcon width={16} height={16} />}
        {!open && <ActionChevronUpIcon width={16} height={16} />}
      </TipButton>

      <ResponsiveDropdown
        open={open}
        options={colorOptions}
        onClick={(option) => handleColorClick(option.value)}
        onClose={handleClose}
        menuDropdownProps={{
          anchorEl: buttonRef.current,
          popperProps: {
            className: 'preventOverflow-enabled',
            sx: styles.popper,
            modifiers: [
              {
                name: 'preventOverflow',
              },
            ],
          },
        }}
        renderOption={RenderOption}
      />
    </ThemeProvider>
  );
}
