import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import IaTableReactionPicker from '../components/IaTablePlugin/IaTableReactionPlugin/IaTableReactionPicker';
import { TableLayoutPlugin, TableLayoutReactionCell } from '../types';

export type ReactionTarget = {
  id?: string;
  cell?: TableLayoutReactionCell;
};

export interface TableLayoutPluginValue {
  reaction: {
    open?: boolean;
    anchorEl?: Element | null;
    target: ReactionTarget[];
    action?: string; // from toolbar
    options?: {
      menuContainer?: Element | null | undefined;
      closeOnBlur?: boolean;
    };
  };
}

const initialValue: TableLayoutPluginValue = {
  reaction: {
    anchorEl: null,
    open: false,
    target: [],
    action: '',
  },
};

type TableLayoutPluginContextValue = [
  TableLayoutPluginValue,
  Dispatch<SetStateAction<TableLayoutPluginValue>>
];

type TableLayoutProviderProps = {
  children: ReactNode;
  plugins?: TableLayoutPlugin[];
};

export const TableLayoutPluginContext =
  createContext<TableLayoutPluginContextValue>([initialValue, () => {}]);

export function TableLayoutPluginContextProvider({
  children,
  plugins,
}: TableLayoutProviderProps) {
  const values = useState<TableLayoutPluginValue>(initialValue);
  return (
    <TableLayoutPluginContext.Provider value={values}>
      {children}
      {plugins?.includes(TableLayoutPlugin.Reaction) && (
        <IaTableReactionPicker />
      )}
    </TableLayoutPluginContext.Provider>
  );
}
