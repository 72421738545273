import { BlockNoteEditor, BlockSchema } from '@blocknote/core';

export const CUSTOM_BR = '#!#!#!#!';

/**
 * get selected texts with <br /> inside
 */
export const getSelectedTextWithLineBreak = <BSchema extends BlockSchema>(
  editor: BlockNoteEditor<BSchema>,
  { lineBreak = CUSTOM_BR } = {}
) => {
  const editorState = editor._tiptapEditor.state;
  return editorState.doc.textBetween(
    editorState.selection.from,
    editorState.selection.to,

    /**
     * block separator, used to separate different blocks
     */
    lineBreak,

    /**
     * left node separator, used to separate different lines inside a block
     */
    (leafNode) => (leafNode.type.name === 'hardBreak' ? lineBreak : '')
  );
};
