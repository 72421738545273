import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import useBaseRightPanel from './hooks/useBaseRightPanel';

export type BaseLayoutRightPanelPortalProps = {
  children: ReactNode;
};

export default function BaseLayoutRightPanelPortal({
  children,
}: BaseLayoutRightPanelPortalProps) {
  const { rightPanelRef } = useBaseRightPanel();

  if (!rightPanelRef?.current) return null;

  return ReactDOM.createPortal(children, rightPanelRef.current);
}
