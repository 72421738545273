import React from 'react';

export default function EmojiTravel2({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6729 10.9737L19.2875 17.2014C19.5467 18.2011 19.2574 19.2633 18.5272 19.9936C17.1514 21.3694 14.8396 21.0364 13.9079 19.3283L12.288 16.3586L11.8563 16.7904L12.0635 17.6193C12.2953 18.5464 12.0236 19.5272 11.3479 20.2029C10.0963 21.4545 8.00178 21.1966 7.09114 19.6789L6.05238 17.9476L4.32111 16.9089C2.80337 15.9982 2.54552 13.9037 3.79708 12.6521C4.47284 11.9764 5.4536 11.7047 6.38073 11.9365L7.20963 12.1437L7.64139 11.712L4.67165 10.0921C2.96357 9.16044 2.63065 6.84862 4.00643 5.47284C4.7367 4.74257 5.79891 4.45332 6.79861 4.71251L13.0263 6.32709L15.3903 3.96303C16.6744 2.67899 18.7529 2.67899 20.037 3.96303C21.321 5.24708 21.321 7.32562 20.037 8.60967L17.6729 10.9737ZM13.0794 8.46229C13.4316 8.5536 13.8058 8.45169 14.0631 8.19441L16.8424 5.41511C17.3245 4.93302 18.1028 4.93302 18.5849 5.41511C19.067 5.8972 19.067 6.67551 18.5849 7.15759L15.8056 9.9369C15.5483 10.1942 15.4464 10.5684 15.5377 10.9206L17.2997 17.7168C17.3762 18.012 17.2908 18.3258 17.0751 18.5415C16.6687 18.9479 15.9859 18.8495 15.7107 18.345L13.4337 14.1706C13.1055 13.5688 12.291 13.4515 11.8063 13.9362L9.99312 15.7494C9.88362 15.8589 9.80223 15.9896 9.75193 16.1308C9.68508 16.3185 9.67312 16.5248 9.72304 16.7244L10.0713 18.1173C10.1281 18.3447 10.0615 18.5851 9.89579 18.7508C9.5889 19.0577 9.07533 18.9945 8.85204 18.6223L7.68121 16.671C7.66874 16.6502 7.65557 16.6299 7.64172 16.6102C7.57308 16.5122 7.48784 16.4269 7.38985 16.3583C7.37008 16.3444 7.3498 16.3313 7.32903 16.3188L5.37765 15.148C5.0055 14.9247 4.94227 14.4111 5.24916 14.1042C5.41485 13.9385 5.65534 13.8719 5.88267 13.9287L7.27556 14.277C7.47523 14.3269 7.68149 14.3149 7.86919 14.2481C8.01041 14.1978 8.14113 14.1164 8.25063 14.0069L10.0638 12.1937C10.5485 11.709 10.4312 10.8945 9.82942 10.5663L5.655 8.28933C5.15047 8.01414 5.05213 7.33128 5.45851 6.92491C5.67421 6.70921 5.98796 6.62377 6.28325 6.70033L13.0794 8.46229Z"
        fill="currentColor"
      />
    </svg>
  );
}
