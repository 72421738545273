import { Box, Typography } from '@mui/material';
import { Icon } from '@front/ui';

import { AlbumLayoutTitleExtensionConfig } from './types';

const styles = {
  root: {
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
  },
  content: {
    fontWeight: 700,
  },
};

type AlbumLayoutTitleExtensionProps = {
  config: AlbumLayoutTitleExtensionConfig['config'];
};
export default function AlbumLayoutTitleExtension({
  config,
}: AlbumLayoutTitleExtensionProps) {
  const { content, prefixIcon } = config;
  return (
    <Box sx={styles.root}>
      {prefixIcon && <Icon name={prefixIcon} width={16} height={16} />}
      <Typography variant="h5" sx={styles.content}>
        {content}
      </Typography>
    </Box>
  );
}
