import Box from '@mui/material/Box';

import FooterActions from './FooterActions';
import Timer from './Timer';

const styles = {
  // MobileFooter
  root: {
    pt: 0,
    pb: 'env(safe-area-inset-bottom)',
    zIndex: 1,
    background: 'rgba(8, 8, 8, 0.75)',
    backdropFilter: 'blur(25px)',
    boxShadow: 4,
  },
};

export default function MobileFooter() {
  return (
    <Box sx={styles.root}>
      <Timer />
      <FooterActions />
    </Box>
  );
}
