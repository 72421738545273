import { useTranslation } from 'next-i18next';
import { Theme, useMediaQuery } from '@mui/material';
import { TipButton } from '@front/ui';

import { ToolbarButtonItem, ToolbarButtonResponsiveConfig } from '../types';

const styles = {
  button: {
    '&:hover .main-color': {
      color: 'highlight.red',
    },
  },
  selected: {
    '& .main-color': {
      color: 'highlight.red',
    },
  },
};

type DifficultyLevelToolbarItemsProps = ToolbarButtonItem & {
  config: ToolbarButtonResponsiveConfig;
};

export default function DifficultyLevelToolbarItems({
  onClick,
  config,
  selected = false,
}: DifficultyLevelToolbarItemsProps) {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <TipButton
      sx={[styles.button, selected && styles.selected]}
      title={t(config.titleKey)}
      customSize={24}
      onClick={onClick}
    >
      {mdUp ? config.icon.desktop : config.icon.mobile}
    </TipButton>
  );
}
