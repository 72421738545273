import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

/**
 * states of a table cell when selected
 * active: open cell inline editing
 * focused: highlight the selected cell
 */
export type CellSelectedState = 'active' | 'focused';

export interface CellId {
  rowId: string;
  colKey: string;
}

export interface CellPosition {
  rowIndex: number;
  colIndex: number;
}

export interface CellSelected {
  cellId: CellId;
  pos: CellPosition;
  state: CellSelectedState;
}

export interface TableLayoutSelectionValue {
  flattenRowIds: string[];
  columnOrder: string[];
  cellSelected: CellSelected | null;
  disableCellFocus?: boolean;
}

const initialValue: TableLayoutSelectionValue = {
  flattenRowIds: [],
  columnOrder: [],
  cellSelected: null,
};

type TableLayoutSelectionContextValue = [
  TableLayoutSelectionValue,
  Dispatch<SetStateAction<TableLayoutSelectionValue>>
];

type TableLayoutProviderProps = {
  children: ReactNode;
};

export const TableLayoutSelectionContext =
  createContext<TableLayoutSelectionContextValue>([initialValue, () => {}]);

export function TableLayoutContextProvider({
  children,
}: TableLayoutProviderProps) {
  const [value, setValue] = useState<TableLayoutSelectionValue>(initialValue);

  return (
    <TableLayoutSelectionContext.Provider value={[value, setValue]}>
      {children}
    </TableLayoutSelectionContext.Provider>
  );
}
