import React from 'react';

export default function ActionPopUp({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4 4C2.75 4 2 4.75 2 6V18C2 19.25 2.75 20 4 20H20C21.25 20 22 19.25 22 18V6C22 4.75 21.25 4 20.25 4H4ZM4 5C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5H4Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M7 9.6C7 9.3 7.35714 9 7.71429 9H16.2857C16.6429 9 17 9.3 17 9.6V14.4C17 14.7 16.6429 15 16.2857 15H7.71429C7.35714 15 7 14.7 7 14.4V9.6Z"
        fill="currentColor"
      />
    </svg>
  );
}
