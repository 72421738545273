const getNameAbbr = (name: string) => {
  const nameArr = name.split(' ');
  return (
    nameArr.length < 2
      ? nameArr[0].slice(0, 1)
      : `${nameArr[0][0]}${nameArr[1]?.[0] || ''}`
  ).toUpperCase();
};

export const getClubGroupIconPath = (displayName: string) => {
  const nameAbbr = getNameAbbr(displayName);

  return `${
    process.env.APP_URL
  }/api/image/icon/${nameAbbr}?size=160&fontSize=60&background=${encodeURIComponent(
    '#FFFFFF'
  )}&color=${encodeURIComponent('#080808')}`;
};
