import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import { nonNullable } from '@front/helper';
import { IaRichText, IaRichTextObj } from '@lib/ia/src/core/types';
import { NotificationCtaConfig } from '@lib/ia/src/layouts/ChannelLayout/types';
import { NotificationTag, SharedNotificationTag } from '@lib/web/apis';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { isHumanThreadUser } from '@lib/web/thread/typeGuard';
import { AgentThreadUser, HumanThreadUser } from '@lib/web/thread/types';
import { getClubIconPath, getTagIconPath } from '@lib/web/utils';
import { TFunction } from 'i18next';

type NotificationMessageContent = {
  text: IaRichText;
  cta?: NotificationCtaConfig[];
};

const getOriginalSharedPath = (
  payload: GetThreadMessageNotificationPayloadRes
) => {
  const { sharedPathname, sharedPathQuery } = payload;
  let query = {};

  try {
    query = JSON.parse(sharedPathQuery || '{}');
  } catch (error) {
    /* do nothing */
  }

  let path = sharedPathname || '';

  Object.entries(query).forEach(([key, value]) => {
    path = path.replace(`[${key}]`, String(value));
  });

  return path;
};

const itemMentionUser = (user: HumanThreadUser): IaRichTextObj => ({
  type: 'user',
  id: user.userId || '',
  name: `@${user.distinctName}`,
  src: user.image || '',
  action: {
    type: 'event' as const,
    value: 'viewUser',
  },
  indicators: user.indicators,
});

const itemClubLink = (
  club: GetThreadMessageNotificationPayloadRes['club']
) => ({
  type: 'link' as const,
  text: club?.clubName || '',
  src: club?.clubIcon || getClubIconPath(club?.clubName || ''),
  action: {
    type: 'link' as const,
    value: `/club/${club?.clubSlug}`,
  },
});

const messageEmpty: NotificationMessageContent = {
  text: '',
};

const messageLoading: NotificationMessageContent = {
  text: '', // XXX: to support loading skeleton
};

const messageError = (info: string): NotificationMessageContent => ({
  text: `🔴 ${info}`,
});

const messageNotSupported = (
  notificationTag: NotificationTag
): NotificationMessageContent => ({
  text: `🚧 not supported notification type: [${notificationTag}]`,
});

const messageSomeoneFollowYou = (
  someone: HumanThreadUser
): NotificationMessageContent => ({
  text: [
    itemMentionUser(someone),
    {
      type: 'text',
      value: 'followed you.',
    },
  ],
  cta: [
    {
      type: 'customize',
      value: 'cta.follow.followback',
      metadata: {
        userId: someone.userId,
      },
    },
  ],
});

const messageReceiveAChallengeInvitation = (
  t: TFunction,
  sender: HumanThreadUser,
  payload: GetThreadMessageNotificationPayloadRes
): NotificationMessageContent => ({
  text: [
    itemMentionUser(sender),
    {
      type: 'text',
      value: 'sent you a challenge invitation to',
    },
    {
      type: 'highlight',
      text: payload.challenge?.challengeName || '',
    },
    {
      type: 'text',
      value: '. You have 5 days to join the challenge.',
    },
  ],
  cta: [
    {
      type: 'customize' as const,
      value: 'cta.challenge.invitation',
      metadata: {
        challengers: [],
        link: `/club/${payload.club?.clubSlug}/challenge/${payload.quiz?.quizShortId}`,
      },
    },
    payload.challenge && {
      type: 'bigCardNumber' as const,
      title: t('Remaining'),
      titleIcon: 'TestClock',
      value: {
        content: payload.challenge.challengeDaysLeft || '',
      },
      description: {
        content: payload.challenge.challengeDaysLeft <= 1 ? 'Day' : 'Days',
      },
    },
  ].filter(nonNullable),
});

const messageReceiveAPromoCode = (
  payload: GetThreadMessageNotificationPayloadRes
): NotificationMessageContent => ({
  text: [
    {
      type: 'text',
      value: `I am sending you my discount code ‘${payload.discount?.discountCode}’!🎉 Use code for 10% off.`,
    },
  ],
  cta: [
    {
      type: 'bigCardNumber',
      title: payload.discount?.discountCode,
      titleIcon: 'OtherDiscountNoOuter',
      borderLeft: 'text.primary',
      actionIcon: 'ActionArrowRightUp',
      value: {
        content: payload.discount?.discountPercentage || '',
        suffix: '% off',
      },
      description: {
        content: 'Click here to open marketplace',
      },
      action: {
        type: 'link',
        value: '/marketplace/available',
      },
    },
  ],
});

const messageFriendshipMade = (): NotificationMessageContent => ({
  text: 'I have followed you back. We are friends now! 🎉',
});

const messageSharedClubAgent = (
  agent: AgentThreadUser | null
): NotificationMessageContent => {
  return {
    text: [
      {
        type: 'text',
        value:
          'Take a look at this page. This AI could be the helping hand you need ✨',
      },
    ],
    cta: [
      {
        type: 'richText',
        value: [
          agent
            ? {
                type: 'link',
                text: agent.name || '',
                src: agent.image || '',
                action: {
                  type: 'event',
                  value: 'viewAgent',
                },
              }
            : {
                type: 'text',
                value: '',
              },
        ],
      },
    ],
  };
};

const messageSharedProfile = (
  profileOwner: HumanThreadUser | null,
  sid?: string
): NotificationMessageContent => ({
  text: [
    {
      type: 'text',
      value: 'Take a look at ',
    },
    profileOwner
      ? itemMentionUser(profileOwner)
      : {
          type: 'text',
          value: '',
        },
    {
      type: 'text',
      value: "'s page. Connect and explore opportunities together 🤝",
    },
  ],
  cta: [
    {
      type: 'richText',
      value: [
        profileOwner && sid
          ? {
              type: 'link',
              text: 'My Profile',
              src: getTagIconPath('MainProfileSolid'),
              badgeSrc: profileOwner.image || '',
              action: {
                type: 'link',
                value: `/shared/${sid}/profile/account`,
              },
            }
          : {
              type: 'text',
              value: '',
            },
      ],
    },
  ],
});

const messageGeneralPage = ({
  payload,
  owner,
  sid,
  title,
  icon,
  link,
}: {
  payload: GetThreadMessageNotificationPayloadRes;
  owner: HumanThreadUser | null;
  sid?: string;
  title?: string;
  icon?: string;
  link?: string;
}): NotificationMessageContent => {
  return {
    text: [
      {
        type: 'text',
        value: 'Take a look at this ',
      },
      itemClubLink(payload.club),
      {
        type: 'text',
        value: " 's page",
      },
    ],
    cta: [
      {
        type: 'richText',
        value: [
          owner && sid
            ? {
                type: 'link',
                text: title || '',
                src: icon ? getTagIconPath(icon) : '',
                badgeSrc: owner.image || '',
                action: {
                  type: 'link',
                  value: link || '',
                },
              }
            : {
                type: 'text',
                value: '',
              },
        ],
      },
    ],
  };
};

export const useNotificationMessageContent = () => {
  const { t } = useTranslation('notification');

  const { getThreadNotificationMessagePayload, getThreadUser } = useThread();

  const getNotificationMessageContent = useCallback(
    ({
      notificationSenderId,
      notificationId,
    }: {
      notificationSenderId?: string;
      notificationId?: string;
    }): NotificationMessageContent => {
      if (!notificationId) return messageEmpty;

      const notificationPayload =
        getThreadNotificationMessagePayload(notificationId);

      if (notificationPayload === undefined) {
        return messageLoading;
      }

      if (notificationPayload === null) {
        return messageError(
          `cannot find notification payload [${notificationId}]`
        );
      }

      const notificationSender = getThreadUser(notificationSenderId);
      const sharedObjectOwner = getThreadUser(
        notificationPayload.ownerMemberId
      ) as HumanThreadUser | null;
      const originalSharedPath = getOriginalSharedPath(notificationPayload);

      if (!notificationSender) return messageLoading;
      if (!isHumanThreadUser(notificationSender)) {
        return messageError('not support non-human sender of notification');
      }

      switch (notificationPayload.tag) {
        case NotificationTag.SomeoneFollowedYou:
          return messageSomeoneFollowYou(notificationSender);
        case NotificationTag.ReceiveAChallengeInvitation:
          return messageReceiveAChallengeInvitation(
            t,
            notificationSender,
            notificationPayload
          );
        case NotificationTag.ReceiveAPromoCode:
          return messageReceiveAPromoCode(notificationPayload);
        case NotificationTag.FriendshipMade:
          return messageFriendshipMade();
        case SharedNotificationTag.ShareProfile:
          return messageSharedProfile(
            sharedObjectOwner,
            notificationPayload.sid
          );
        case SharedNotificationTag.SharedClubAgent: {
          const agentMemberId = notificationPayload.agent?.agentId
            ? `agent_${notificationPayload.agent?.agentId}`
            : undefined;
          const agent = getThreadUser(agentMemberId) as AgentThreadUser | null;
          return messageSharedClubAgent(agent);
        }
        case SharedNotificationTag.SharePracticeQuestion:
          return messageGeneralPage({
            payload: notificationPayload,
            owner: sharedObjectOwner,
            sid: notificationPayload.sid,
            title: `${notificationPayload.quiz?.quizName || ''} Q${
              notificationPayload.quiz?.questionIndex || ''
            }`,
            icon: 'ProfilePlaylist',
            link: `/shared/${notificationPayload.sid}/${originalSharedPath}#${notificationPayload.quiz?.questionIndex}`,
          });
        default:
          return messageNotSupported(notificationPayload.tag);
      }
    },
    [getThreadNotificationMessagePayload, getThreadUser, t]
  );

  return {
    getNotificationMessageContent,
  };
};
