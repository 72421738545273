import React, {
  Dispatch,
  MouseEvent,
  MutableRefObject,
  SetStateAction,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
} from '@mui/material';
import { ResponsiveMenu } from '@front/ui';
import { CreatorQuestionConfigContext } from '@lib/web/editor/contexts';

import AnswerFormatIcon from '../../AnswerFormatIcon';
import { useAddableAnswerFormat, useAddQuestion } from '../../hooks';

export type AddQuestionDropdownProps = {
  target: MutableRefObject<HTMLElement | null>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  anchorQuestion?: {
    addAboveOrBelow: 'above' | 'below';
    id: string;
    questionGroupId?: string;
  };
};

export default function AddQuestionDropdown({
  target,
  open,
  setOpen,
  anchorQuestion,
}: AddQuestionDropdownProps) {
  const { t, ready } = useTranslation('editor');
  const { getQuestionPath } = useContext(CreatorQuestionConfigContext);
  const { handleAddNewQuestionClick, handleAddNewQuestionGroupClick } =
    useAddQuestion({ getQuestionPath });

  const { answerFormats, questionGroups } = useAddableAnswerFormat({
    anchorQuestion,
  });

  const handleMenuClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (onClick: () => void) => {
    return (ev: MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      setOpen(false);
      onClick();
    };
  };

  if (!ready) {
    return null;
  }

  return (
    <ResponsiveMenu
      open={open}
      onClose={handleMenuClose}
      menuProps={{
        anchorEl: target.current,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 4,
        },
      }}
    >
      <ListSubheader>{t('Answer Formats')}</ListSubheader>
      {answerFormats?.map(({ name, answerFormatType }) => (
        <MenuItem
          key={name}
          onClick={handleMenuItemClick(() =>
            handleAddNewQuestionClick(answerFormatType, anchorQuestion)
          )}
        >
          <ListItemIcon>
            <AnswerFormatIcon
              answerFormatType={answerFormatType}
              width={16}
              height={16}
            />
          </ListItemIcon>
          <ListItemText>{name}</ListItemText>
        </MenuItem>
      ))}

      {questionGroups.length > 0 && (
        <ListSubheader>{t('Question Structure')}</ListSubheader>
      )}
      {questionGroups?.map(({ name, answerFormatType }) => (
        <MenuItem
          key={name}
          onClick={handleMenuItemClick(() =>
            handleAddNewQuestionGroupClick(anchorQuestion)
          )}
        >
          <ListItemIcon>
            <AnswerFormatIcon
              answerFormatType={answerFormatType}
              width={16}
              height={16}
            />
          </ListItemIcon>
          <ListItemText>{name}</ListItemText>
        </MenuItem>
      ))}
    </ResponsiveMenu>
  );
}
