import { useEffect, useMemo, useRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { useDimension } from '@front/helper';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import * as d3Hierarchy from 'd3-hierarchy';

import BubbleChartItem from './BubbleChartItem';
import { BubbleChartItemNode } from './types';

const styles = {
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  inner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

type BubbleItem = d3Hierarchy.HierarchyCircularNode<
  BubbleChartItemNode['data']
>;

type BubbleChartProps = {
  sx?: BoxProps['sx'];
  dataset: BubbleChartItemNode['data'][];
  colors: [string, string];
  borderColor: string;
  showRate?: boolean;
  keyword?: string;
  selectedCode?: string;
  onClick?: (node: BubbleItem) => void;
};

const MIN_BUBBLE_R = 30;

export default function BubbleChart({
  sx,
  dataset = [],
  colors,
  borderColor,
  keyword,
  selectedCode,
  onClick,
  ...rest
}: BubbleChartProps) {
  const bubbleRef = useRef<HTMLDivElement>();
  const zoomRef = useRef<PanzoomObject | null>(null);
  const lastTouchEnd = useRef(0);

  const sxProps = Array.isArray(sx) ? sx : [sx];

  const boxRef = useRef<HTMLDivElement>();
  const { height, width } = useDimension(boxRef);

  useEffect(() => {
    const touchStart = (event: TouchEvent) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };
    const touchEnd = (event: TouchEvent) => {
      const now = new Date().getTime();
      if (now - lastTouchEnd.current <= 300) {
        event.preventDefault();
      }
      lastTouchEnd.current = now;
    };

    document.addEventListener('touchstart', touchStart);
    document.addEventListener('touchend', touchEnd);
    return () => {
      zoomRef.current = null;
      document.removeEventListener('touchstart', touchStart);
      document.removeEventListener('touchend', touchEnd);
    };
  }, []);

  useEffect(() => {
    let parent: HTMLElement | null = null;

    if (!zoomRef.current && bubbleRef.current) {
      zoomRef.current = Panzoom(bubbleRef.current, {
        minScale: 1,
        maxScale: 2,
        onTouch() {
          return false;
        },
      });
    }
    const zoomWithWheel = zoomRef.current
      ? zoomRef.current.zoomWithWheel
      : () => {};

    parent = bubbleRef.current ? bubbleRef.current.parentElement : null;

    if (parent) {
      parent.addEventListener('wheel', zoomWithWheel);
    }

    return () => {
      if (parent) {
        parent.removeEventListener('wheel', zoomWithWheel);
      }
    };
  }, []);

  const bubbleItems = useMemo(() => {
    if (!width || !height) return [] as BubbleItem[];
    const maxSize = Math.min(width, height);

    const data = {
      name: 'WeaknessBubble',
      children: dataset,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bub = d3Hierarchy.hierarchy<BubbleChartItemNode['data']>(data as any);
    bub.sum((d) => d.size);
    bub.sort((a, b) => b.data.size - a.data.size);

    const bubble = d3Hierarchy
      .pack<BubbleChartItemNode['data']>()
      .size([width, height])
      .padding(maxSize < 960 ? 20 : 40);

    return bubble(bub).children || ([] as BubbleItem[]);
  }, [dataset, height, width]);

  const searchBubbleItem = useMemo(() => {
    if (!keyword) return undefined;
    return bubbleItems.find((data) => data.data.isMatchKeyword(keyword));
  }, [bubbleItems, keyword]);

  useEffect(() => {
    if (bubbleItems.length && zoomRef.current) {
      let minR = bubbleItems[0].r;
      let maxR = bubbleItems[0].r;

      bubbleItems.forEach((node) => {
        const r = node.r as number;
        minR = Math.min(minR, r);
        maxR = Math.max(maxR, r);
      });

      if (minR < MIN_BUBBLE_R) {
        const rate = MIN_BUBBLE_R / minR;
        zoomRef.current.setOptions({ minScale: rate, maxScale: rate * 5 });
        zoomRef.current.zoom(rate);
      }
    }
  }, [bubbleItems]);

  useEffect(() => {
    if (!!searchBubbleItem && zoomRef.current && bubbleRef.current) {
      const { x, y } = searchBubbleItem;
      zoomRef.current.pan(width / 2 - x, height / 2 - y);
      zoomRef.current.zoom(1.5);
    }
  }, [height, searchBubbleItem, width]);

  const bubbleSettings = useMemo(() => {
    if (!bubbleItems?.length) return null;

    let leftNode = bubbleItems[0];
    let rightNode = bubbleItems[0];
    let topNode = bubbleItems[0];
    let bottomNode = bubbleItems[0];

    bubbleItems.forEach((node) => {
      if (node.x < leftNode.x) leftNode = node;
      if (node.x > rightNode.x) rightNode = node;
      if (node.y < topNode.y) topNode = node;
      if (node.y > bottomNode.y) bottomNode = node;
    });

    return {
      leftNode,
      rightNode,
      topNode,
      bottomNode,
    };
  }, [bubbleItems]);

  /**
   * Can not use PanOptions contain https://github.com/timmywil/panzoom#contain
   * because the bubble chart is the same size as the wrapper.
   * Note: the bubble chart is a svg with space around it.
   * The idea of this function is to calculate the coordinates of the rectangle
   * containing the bubbles without any surrounding space,
   * then check if the user drags the chart out of the wrapper,
   * this function will pull it back
   */

  useEffect(() => {
    function handlePanZoomEnd() {
      if (
        !zoomRef.current ||
        !bubbleRef.current ||
        !boxRef.current ||
        !bubbleSettings
      ) {
        return;
      }

      const containerRect = boxRef.current.getBoundingClientRect();
      const pan = zoomRef.current.getPan();
      const scale = zoomRef.current.getScale();
      const scaleNum = Math.sqrt(scale);
      const quarterWidth = containerRect.width / 4;
      const quarterHeight = containerRect.height / 4;
      const minX =
        (quarterWidth -
          (bubbleSettings.rightNode.x + bubbleSettings.rightNode.r)) /
        scaleNum;

      const maxX = (quarterWidth * 3 - bubbleSettings.leftNode.x) / scaleNum;

      const minY =
        (quarterHeight -
          (bubbleSettings.bottomNode.y + bubbleSettings.bottomNode.r)) /
        scaleNum;

      const maxY = (quarterHeight * 3 - bubbleSettings.topNode.y) / scaleNum;

      let newX = pan.x;
      let newY = pan.y;

      if (newX < minX) {
        newX = minX;
      } else if (newX > maxX) {
        newX = maxX;
      }

      if (newY < minY) {
        newY = minY;
      } else if (newY > maxY) {
        newY = maxY;
      }

      if (newX !== pan.x || newY !== pan.y) {
        zoomRef.current.pan(newX, newY, { animate: true });
      }
    }
    const bubbleTarget = bubbleRef.current;
    bubbleTarget?.addEventListener('panzoomend', handlePanZoomEnd);
    return () => {
      bubbleTarget?.removeEventListener('panzoomend', handlePanZoomEnd);
    };
  }, [bubbleSettings]);

  return (
    <Box ref={boxRef} sx={[styles.root, ...sxProps]} {...rest}>
      <Box
        sx={[
          styles.inner,
          {
            height,
            width,
          },
        ]}
      >
        <Box ref={bubbleRef} width="100%" height="100%" position="relative">
          {bubbleItems.map((item) => (
            <BubbleChartItem
              key={item.data.code}
              data={item}
              colors={colors}
              borderColor={borderColor}
              onClick={() => onClick?.(item)}
              selected={selectedCode === item.data.code}
              centerX={width / 2}
              centerY={height / 2}
              opacity={
                searchBubbleItem
                  ? searchBubbleItem.data.code === item.data.code
                    ? 1
                    : 0
                  : undefined
              }
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
