import { useFilter } from './useFilter';
import { useSearch } from './useSearch';
import { useSort } from './useSort';

type Type =
  | 'club'
  | 'agent'
  | 'playlist'
  | 'marketplace'
  | 'quiz'
  | 'creatorQuiz'
  | 'emojiQuiz'
  | 'hashtagQuiz'
  | 'followingQuiz'
  | 'historyQuiz'
  | 'challengeQuiz'
  | 'bookmarkQuiz'
  | 'playlistQuiz'
  | 'discountCodeManagement'
  | 'threadChannel'
  | 'threadMessage';

type Options = {
  scope?: string;
  /**
   * when readonly = true, sort/filter will not be automatically reset
   */
  readonly?: boolean;
};

/**
 * @deprecated
 * use usePageSearchFilterSort instead of this
 * important: this hook will be remove soon
 */
export function useSearchFilterSort(
  type: Type,
  { scope = 'global', readonly = false }: Options = {}
) {
  const { sortQuery, rawQueryParams, resetSort } = useSort(
    scope,
    type,
    readonly
  );
  const { searchQuery, resetFilter } = useFilter(scope, type, readonly);
  const { debouncedSearch } = useSearch();

  return {
    keyword: debouncedSearch,
    sort: sortQuery,
    resetSort,
    filter: searchQuery,
    resetFilter,
    rawQueryParams,
  };
}
