import React, { SVGAttributes } from 'react';

export default function NFTEmojiCryingFace({
  ...rest
}: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width="59"
      height="100"
      viewBox="0 0 59 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M32.6411 86.9497V81.9498L42.741 75.3457V80.3556L32.6411 86.9497Z"
        fill="#CA6C04"
      />
      <path
        d="M32.6411 86.9497V81.9498L42.741 75.3457V80.3556L32.6411 86.9497Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M38.9949 85.3172V80.3174L49.0948 73.7133V78.7232L38.9949 85.3172Z"
        fill="#D17203"
      />
      <path
        d="M38.9949 85.3172V80.3174L49.0948 73.7133V78.7232L38.9949 85.3172Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M42.1651 77.0302V72.0303L52.265 65.4262V70.4361L42.1651 77.0302Z"
        fill="#E9980F"
      />
      <path
        d="M42.1651 77.0302V72.0303L52.265 65.4262V70.4361L42.1651 77.0302Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M42.1651 82.0355V77.0357L52.265 70.4316V75.4415L42.1651 82.0355Z"
        fill="#D17203"
      />
      <path
        d="M42.1651 82.0355V77.0357L52.265 70.4316V75.4415L42.1651 82.0355Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M32.6315 31.8679V26.8681L42.7314 20.264V25.2739L32.6315 31.8679Z"
        fill="#FFBB15"
      />
      <path
        d="M32.6315 31.8679V26.8681L42.7314 20.264V25.2739L32.6315 31.8679Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M38.9972 40.2514V35.2516L49.0971 28.6475V33.6574L38.9972 40.2514Z"
        fill="#FFA508"
      />
      <path
        d="M38.9972 40.2514V35.2516L49.0971 28.6475V33.6574L38.9972 40.2514Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M42.1803 46.9471V41.9472L52.2802 35.3431V40.353L42.1803 46.9471Z"
        fill="#FF9D05"
      />
      <path
        d="M42.1803 46.9471V41.9472L52.2802 35.3431V40.353L42.1803 46.9471Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M42.1803 51.9526V46.9527L52.2802 40.3486V45.3585L42.1803 51.9526Z"
        fill="#FFD559"
      />
      <path
        d="M42.1803 51.9526V46.9527L52.2802 40.3486V45.3585L42.1803 51.9526Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M45.3622 58.6446V53.6447L55.4621 47.0406V52.0505L45.3622 58.6446Z"
        fill="#EF8702"
      />
      <path
        d="M45.3622 58.6446V53.6447L55.4621 47.0406V52.0505L45.3622 58.6446Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M45.3622 68.6613V63.6614L55.4621 57.0573V62.0672L45.3622 68.6613Z"
        fill="#E48706"
      />
      <path
        d="M45.3622 68.6613V63.6614L55.4621 57.0573V62.0672L45.3622 68.6613Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M45.3622 73.6668V68.6669L55.4621 62.0628V67.0727L45.3622 73.6668Z"
        fill="#D27203"
      />
      <path
        d="M45.3622 73.6668V68.6669L55.4621 62.0628V67.0727L45.3622 73.6668Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M45.3622 63.6501V58.6502L55.4621 52.0461V57.056L45.3622 63.6501Z"
        fill="#EC8E05"
      />
      <path
        d="M45.3622 63.6501V58.6502L55.4621 52.0461V57.056L45.3622 63.6501Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M7.18774 28.3878L10.3733 30.0615L20.4655 23.4655L17.2939 21.809L7.18774 28.3878Z"
        fill="#F49B43"
      />
      <path
        d="M7.18774 28.3878L10.3733 30.0615L20.4655 23.4655L17.2939 21.809L7.18774 28.3878Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M29.4395 25.1851L32.625 26.8587L42.7172 20.2627L39.5456 18.6062L29.4395 25.1851Z"
        fill="#FFBB15"
      />
      <path
        d="M29.4395 25.1851L32.625 26.8587L42.7172 20.2627L39.5456 18.6062L29.4395 25.1851Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M32.6276 31.8795L35.8131 33.5531L45.9053 26.9572L42.7337 25.3007L32.6276 31.8795Z"
        fill="#F49B43"
      />
      <path
        d="M32.6276 31.8795L35.8131 33.5531L45.9053 26.9572L42.7337 25.3007L32.6276 31.8795Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M35.8217 33.5648L38.9955 35.248L49.0877 28.652L45.9161 26.9955L35.8217 33.5648Z"
        fill="#FFA508"
      />
      <path
        d="M35.8217 33.5648L38.9955 35.248L49.0877 28.652L45.9161 26.9955L35.8217 33.5648Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M39.0087 40.2618L42.1942 41.9355L52.2864 35.3395L49.1148 33.683L39.0087 40.2618Z"
        fill="#FF9D05"
      />
      <path
        d="M39.0087 40.2618L42.1942 41.9355L52.2864 35.3395L49.1148 33.683L39.0087 40.2618Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M42.1736 51.957L45.3592 53.6307L55.4514 47.0347L52.2798 45.3782L42.1736 51.957Z"
        fill="#EF8702"
      />
      <path
        d="M42.1736 51.957L45.3592 53.6307L55.4514 47.0347L52.2798 45.3782L42.1736 51.957Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M10.3856 25.0915L13.5711 26.7651L23.6633 20.1691L20.4917 18.5126L10.3856 25.0915Z"
        fill="#FF9C04"
      />
      <path
        d="M10.3856 25.0915L13.5711 26.7651L23.6633 20.1691L20.4917 18.5126L10.3856 25.0915Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M13.5604 21.7804L16.7459 23.454L26.8382 16.858L23.6665 15.2015L13.5604 21.7804Z"
        fill="#FFA609"
      />
      <path
        d="M13.5604 21.7804L16.7459 23.454L26.8382 16.858L23.6665 15.2015L13.5604 21.7804Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M16.7352 18.4352L19.9208 20.1088L30.013 13.5128L26.8413 11.8563L16.7352 18.4352Z"
        fill="#F49B43"
      />
      <path
        d="M16.7352 18.4352L19.9208 20.1088L30.013 13.5128L26.8413 11.8563L16.7352 18.4352Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M19.9222 20.1363L23.1078 21.81L33.2 15.214L30.0284 13.5575L19.9222 20.1363Z"
        fill="#FFBF16"
      />
      <path
        d="M19.9222 20.1363L23.1078 21.81L33.2 15.214L30.0284 13.5575L19.9222 20.1363Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M23.086 21.8215L26.2716 23.4952L36.3638 16.8992L33.1921 15.2427L23.086 21.8215Z"
        fill="#FFDD19"
      />
      <path
        d="M23.086 21.8215L26.2716 23.4952L36.3638 16.8992L33.1921 15.2427L23.086 21.8215Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M26.2498 23.5067L29.4354 25.1803L39.5276 18.5843L36.356 16.9278L26.2498 23.5067Z"
        fill="#FFDD19"
      />
      <path
        d="M26.2498 23.5067L29.4354 25.1803L39.5276 18.5843L36.356 16.9278L26.2498 23.5067Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M32.6317 26.8683L29.4512 25.1831V30.1913L32.6317 31.8765V26.8683Z"
        fill="#FFBB15"
      />
      <path
        d="M29.4512 25.1831L26.2708 23.4979V28.5062L29.4512 30.1913V25.1831Z"
        fill="#FFDD19"
      />
      <path
        d="M23.0903 21.8127L19.9098 20.1276V25.1358L23.0903 26.821V21.8127Z"
        fill="#FFBF16"
      />
      <path
        d="M26.2707 23.4979L23.0903 21.8127V26.821L26.2707 28.5062V23.4979Z"
        fill="#FFDD19"
      />
      <path
        d="M19.9098 20.1276L16.7293 18.4424V23.4506L19.9098 25.1358V20.1276Z"
        fill="#F49B43"
      />
      <path
        d="M16.7293 23.4506L13.5488 21.7654V26.7737L16.7293 28.4588V23.4506Z"
        fill="#FFA609"
      />
      <path
        d="M16.7293 38.4753L13.5488 36.7901V41.7983L16.7293 43.4835V38.4753Z"
        fill="#804604"
      />
      <path
        d="M16.7293 53.5L13.5488 51.8148V56.823L16.7293 58.5082V53.5Z"
        fill="#FFBF33"
      />
      <path
        d="M16.7293 68.5247L13.5488 66.8395V71.8477L16.7293 73.5329V68.5247Z"
        fill="#22B1FB"
      />
      <path
        d="M13.5489 36.7901L10.3684 35.1049V40.1132L13.5489 41.7983V36.7901Z"
        fill="#FFCA39"
      />
      <path
        d="M13.5489 51.8148L10.3684 50.1296V55.1379L13.5489 56.823V51.8148Z"
        fill="#FBAD12"
      />
      <path
        d="M10.3684 35.1049L7.1879 33.4197V38.428L10.3684 40.1132V35.1049Z"
        fill="#F28B03"
      />
      <path
        d="M10.3684 50.1296L7.1879 48.4444V53.4527L10.3684 55.1379V50.1296Z"
        fill="#D47503"
      />
      <path
        d="M35.8122 33.5617L32.6317 31.8765V36.8847L35.8122 38.5699V33.5617Z"
        fill="#F49B43"
      />
      <path
        d="M35.8122 48.5864L32.6317 46.9012V51.9094L35.8122 53.5946V48.5864Z"
        fill="#FFE544"
      />
      <path
        d="M35.8122 63.6111L32.6317 61.9259V66.9341L35.8122 68.6193V63.6111Z"
        fill="#FFC936"
      />
      <path
        d="M35.8122 78.6358L32.6317 76.9506V81.9588L35.8122 83.644V78.6358Z"
        fill="#E5930F"
      />
      <path
        d="M32.6317 31.8765L29.4512 30.1913V35.1996L32.6317 36.8848V31.8765Z"
        fill="#FFFCCB"
      />
      <path
        d="M32.6317 46.9012L29.4512 45.216V50.2243L32.6317 51.9094V46.9012Z"
        fill="#FAE340"
      />
      <path
        d="M32.6317 61.9259L29.4512 60.2407V65.2489L32.6317 66.9341V61.9259Z"
        fill="#FFCE35"
      />
      <path
        d="M32.6317 76.9506L29.4512 75.2654V80.2736L32.6317 81.9588V76.9506Z"
        fill="#F3A214"
      />
      <path
        d="M29.4512 30.1913L26.2708 28.5062V33.5144L29.4512 35.1996V30.1913Z"
        fill="#FFFDD5"
      />
      <path
        d="M29.4512 45.216L26.2708 43.5308V48.5391L29.4512 50.2243V45.216Z"
        fill="#FFF048"
      />
      <path
        d="M29.4512 60.2407L26.2708 58.5555V63.5638L29.4512 65.2489V60.2407Z"
        fill="#FFD137"
      />
      <path
        d="M29.4512 75.2654L26.2708 73.5802V78.5885L29.4512 80.2736V75.2654Z"
        fill="#F9A716"
      />
      <path
        d="M42.1731 51.9568L38.9926 50.2716V55.2798L42.1731 56.965V51.9568Z"
        fill="#FFCA39"
      />
      <path
        d="M42.1731 66.9814L38.9926 65.2963V70.3045L42.1731 71.9897V66.9814Z"
        fill="#FBAD12"
      />
      <path
        d="M23.0903 26.821L19.9098 25.1358V30.144L23.0903 31.8292V26.821Z"
        fill="#FFFCCC"
      />
      <path
        d="M23.0903 41.8457L19.9098 40.1605V45.1687L23.0903 46.8539V41.8457Z"
        fill="#FDE843"
      />
      <path
        d="M23.0903 56.8704L19.9098 55.1852V60.1934L23.0903 61.8786V56.8704Z"
        fill="#FECD35"
      />
      <path
        d="M23.0903 71.895L19.9098 70.2099V75.2181L23.0903 76.9033V71.895Z"
        fill="#1BA9F1"
      />
      <path
        d="M45.3536 53.6419L42.1731 51.9568V56.965L45.3536 58.6502V53.6419Z"
        fill="#EF8702"
      />
      <path
        d="M45.3536 68.6666L42.1731 66.9814V71.9897L45.3536 73.6749V68.6666Z"
        fill="#D27203"
      />
      <path
        d="M26.2707 28.5062L23.0903 26.821V31.8292L26.2707 33.5144V28.5062Z"
        fill="#FFFDD5"
      />
      <path
        d="M26.2707 43.5308L23.0903 41.8457V46.8539L26.2707 48.5391V43.5308Z"
        fill="#FFF047"
      />
      <path
        d="M26.2707 58.5555L23.0903 56.8704V61.8786L26.2707 63.5638V58.5555Z"
        fill="#FFD137"
      />
      <path
        d="M26.2707 73.5802L23.0903 71.895V76.9033L26.2707 78.5885V73.5802Z"
        fill="#F8A616"
      />
      <path
        d="M19.9098 25.1358L16.7293 23.4506V28.4588L19.9098 30.144V25.1358Z"
        fill="#FFFCC1"
      />
      <path
        d="M19.9098 40.1605L16.7293 38.4753V43.4835L19.9098 45.1687V40.1605Z"
        fill="#FFE843"
      />
      <path
        d="M19.9098 55.1852L16.7293 53.5V58.5082L19.9098 60.1934V55.1852Z"
        fill="#D4F9FF"
      />
      <path
        d="M19.9098 70.2099L16.7293 68.5247V73.5329L19.9098 75.2181V70.2099Z"
        fill="#22B3FF"
      />
      <path
        d="M38.9926 35.2469L35.8122 33.5617V38.5699L38.9926 40.2551V35.2469Z"
        fill="#FFA508"
      />
      <path
        d="M38.9926 50.2716L35.8122 48.5864V53.5946L38.9926 55.2798V50.2716Z"
        fill="#804604"
      />
      <path
        d="M38.9926 65.2963L35.8122 63.6111V68.6193L38.9926 70.3045V65.2963Z"
        fill="#FFBE33"
      />
      <path
        d="M38.9926 80.321L35.8122 78.6358V83.644L38.9926 85.3292V80.321Z"
        fill="#D17203"
      />
      <path
        d="M16.7293 28.4588L13.5488 26.7737V31.7819L16.7293 33.4671V28.4588Z"
        fill="#FFF18D"
      />
      <path
        d="M16.7293 43.4835L13.5488 41.7983V46.8066L16.7293 48.4918V43.4835Z"
        fill="#FFD02C"
      />
      <path
        d="M16.7293 58.5082L13.5488 56.823V61.8313L16.7293 63.5164V58.5082Z"
        fill="#D8A92C"
      />
      <path
        d="M13.5489 26.7737L10.3684 25.0885V30.0967L13.5489 31.7819V26.7737Z"
        fill="#FF9C04"
      />
      <path
        d="M13.5489 41.7983L10.3684 40.1132V45.1214L13.5489 46.8066V41.7983Z"
        fill="#FFBF27"
      />
      <path
        d="M13.5489 56.823L10.3684 55.1379V60.1461L13.5489 61.8313V56.823Z"
        fill="#E8970E"
      />
      <path
        d="M10.3684 40.1132L7.1879 38.428V43.4362L10.3684 45.1214V40.1132Z"
        fill="#EE9106"
      />
      <path
        d="M35.8122 38.5699L32.6317 36.8848V41.893L35.8122 43.5782V38.5699Z"
        fill="#FFF995"
      />
      <path
        d="M35.8122 53.5946L32.6317 51.9094V56.9177L35.8122 58.6029V53.5946Z"
        fill="#FFDA2E"
      />
      <path
        d="M35.8122 68.6193L32.6317 66.9341V71.9424L35.8122 73.6275V68.6193Z"
        fill="#FFBC33"
      />
      <path
        d="M32.6317 36.8847L29.4512 35.1996V40.2078L32.6317 41.893V36.8847Z"
        fill="#FFFA9E"
      />
      <path
        d="M32.6317 51.9094L29.4512 50.2243V55.2325L32.6317 56.9177V51.9094Z"
        fill="#804604"
      />
      <path
        d="M32.6317 66.9341L29.4512 65.2489V70.2572L32.6317 71.9424V66.9341Z"
        fill="#FFC438"
      />
      <path
        d="M32.6317 81.9588L29.4512 80.2736V85.2819L32.6317 86.967V81.9588Z"
        fill="#CA6C04"
      />
      <path
        d="M29.4512 35.1996L26.2708 33.5144V38.5226L29.4512 40.2078V35.1996Z"
        fill="#FFFAA7"
      />
      <path
        d="M29.4512 50.2243L26.2708 48.5391V53.5473L29.4512 55.2325V50.2243Z"
        fill="#FFE331"
      />
      <path
        d="M29.4512 65.2489L26.2708 63.5638V68.572L29.4512 70.2572V65.2489Z"
        fill="#FFC639"
      />
      <path
        d="M29.4512 80.2736L26.2708 78.5885V83.5967L29.4512 85.2819V80.2736Z"
        fill="#D77C06"
      />
      <path
        d="M42.1731 41.9403L38.9926 40.2551V45.2633L42.1731 46.9485V41.9403Z"
        fill="#FF9D05"
      />
      <path
        d="M42.1731 56.965L38.9926 55.2798V60.288L42.1731 61.9732V56.965Z"
        fill="#FFC027"
      />
      <path
        d="M42.1731 71.9897L38.9926 70.3045V75.3127L42.1731 76.9979V71.9897Z"
        fill="#E9980F"
      />
      <path
        d="M23.0903 31.8292L19.9098 30.144V35.1523L23.0903 36.8374V31.8292Z"
        fill="#FFFA9F"
      />
      <path
        d="M23.0903 46.8539L19.9098 45.1687V50.1769L23.0903 51.8621V46.8539Z"
        fill="#804604"
      />
      <path
        d="M23.0903 61.8786L19.9098 60.1934V65.2016L23.0903 66.8868V61.8786Z"
        fill="#407E92"
      />
      <path
        d="M23.0903 76.9033L19.9098 75.2181V80.2263L23.0903 81.9115V76.9033Z"
        fill="#CA6C03"
      />
      <path
        d="M45.3536 58.6502L42.1731 56.965V61.9732L45.3536 63.6584V58.6502Z"
        fill="#EC8E05"
      />
      <path
        d="M26.2707 33.5144L23.0903 31.8292V36.8374L26.2707 38.5226V33.5144Z"
        fill="#FFFAA7"
      />
      <path
        d="M26.2707 48.5391L23.0903 46.8539V51.8621L26.2707 53.5473V48.5391Z"
        fill="#FFE331"
      />
      <path
        d="M26.2707 63.5638L23.0903 61.8786V66.8868L26.2707 68.572V63.5638Z"
        fill="#FEC538"
      />
      <path
        d="M26.2707 78.5885L23.0903 76.9033V81.9115L26.2707 83.5967V78.5885Z"
        fill="#D77C06"
      />
      <path
        d="M19.9098 30.144L16.7293 28.4588V33.4671L19.9098 35.1522V30.144Z"
        fill="#FFF995"
      />
      <path
        d="M19.9098 45.1687L16.7293 43.4835V48.4918L19.9098 50.1769V45.1687Z"
        fill="#F8D12B"
      />
      <path
        d="M19.9098 60.1934L16.7293 58.5082V63.5164L19.9098 65.2016V60.1934Z"
        fill="#CEFBFF"
      />
      <path
        d="M38.9926 40.2551L35.8122 38.5699V43.5782L38.9926 45.2633V40.2551Z"
        fill="#FFF08D"
      />
      <path
        d="M38.9926 55.2798L35.8122 53.5946V58.6029L38.9926 60.288V55.2798Z"
        fill="#FFCF2C"
      />
      <path
        d="M38.9926 70.3045L35.8122 68.6193V73.6275L38.9926 75.3127V70.3045Z"
        fill="#FEB218"
      />
      <path
        d="M16.7293 33.4671L13.5488 31.7819V36.7901L16.7293 38.4753V33.4671Z"
        fill="#FFE861"
      />
      <path
        d="M16.7293 48.4918L13.5488 46.8066V51.8148L16.7293 53.5V48.4918Z"
        fill="#FFC92E"
      />
      <path
        d="M16.7293 63.5164L13.5488 61.8313V66.8395L16.7293 68.5247V63.5164Z"
        fill="#029CE6"
      />
      <path
        d="M13.5489 31.7819L10.3684 30.0967V35.1049L13.5489 36.7901V31.7819Z"
        fill="#FFD55A"
      />
      <path
        d="M13.5489 46.8066L10.3684 45.1214V50.1296L13.5489 51.8148V46.8066Z"
        fill="#FEB826"
      />
      <path
        d="M13.5489 61.8313L10.3684 60.1461V65.1543L13.5489 66.8395V61.8313Z"
        fill="#D07103"
      />
      <path
        d="M10.3684 30.0967L7.1879 28.4115V33.4197L10.3684 35.1049V30.0967Z"
        fill="#F49B43"
      />
      <path
        d="M10.3684 45.1214L7.1879 43.4362V48.4444L10.3684 50.1296V45.1214Z"
        fill="#E48906"
      />
      <path
        d="M35.8122 43.5782L32.6317 41.893V46.9012L35.8122 48.5864V43.5782Z"
        fill="#804604"
      />
      <path
        d="M35.8122 58.6029L32.6317 56.9177V61.9259L35.8122 63.6111V58.6029Z"
        fill="#FFD02F"
      />
      <path
        d="M35.8122 73.6275L32.6317 71.9424V76.9506L35.8122 78.6358V73.6275Z"
        fill="#FCAC18"
      />
      <path
        d="M32.6317 41.893L29.4512 40.2078V45.216L32.6317 46.9012V41.893Z"
        fill="#FFF76C"
      />
      <path
        d="M32.6317 56.9177L29.4512 55.2325V60.2407L32.6317 61.9259V56.9177Z"
        fill="#804604"
      />
      <path
        d="M32.6317 71.9424L29.4512 70.2572V75.2654L32.6317 76.9506V71.9424Z"
        fill="#F8AA1C"
      />
      <path
        d="M29.4512 40.2078L26.2708 38.5226V43.5308L29.4512 45.216V40.2078Z"
        fill="#FFF771"
      />
      <path
        d="M29.4512 55.2325L26.2708 53.5473V58.5555L29.4512 60.2407V55.2325Z"
        fill="#FFDA32"
      />
      <path
        d="M29.4512 70.2572L26.2708 68.572V73.5802L29.4512 75.2654V70.2572Z"
        fill="#804604"
      />
      <path
        d="M42.1731 46.9485L38.9926 45.2633V50.2716L42.1731 51.9568V46.9485Z"
        fill="#FFD559"
      />
      <path
        d="M42.1731 61.9732L38.9926 60.288V65.2963L42.1731 66.9814V61.9732Z"
        fill="#FEB826"
      />
      <path
        d="M42.1731 76.9979L38.9926 75.3127V80.321L42.1731 82.0061V76.9979Z"
        fill="#D17203"
      />
      <path
        d="M23.0903 36.8374L19.9098 35.1522V40.1605L23.0903 41.8457V36.8374Z"
        fill="#FFF76C"
      />
      <path
        d="M23.0903 51.8621L19.9098 50.1769V55.1852L23.0903 56.8704V51.8621Z"
        fill="#804604"
      />
      <path
        d="M23.0903 66.8868L19.9098 65.2016V70.2099L23.0903 71.895V66.8868Z"
        fill="#00A4ED"
      />
      <path
        d="M45.3536 63.6584L42.1731 61.9732V66.9814L45.3536 68.6666V63.6584Z"
        fill="#E48706"
      />
      <path
        d="M26.2707 38.5226L23.0903 36.8374V41.8457L26.2707 43.5308V38.5226Z"
        fill="#FFF771"
      />
      <path
        d="M26.2707 53.5473L23.0903 51.8621V56.8704L26.2707 58.5555V53.5473Z"
        fill="#FFD932"
      />
      <path
        d="M26.2707 68.572L23.0903 66.8868V71.895L26.2707 73.5802V68.572Z"
        fill="#804604"
      />
      <path
        d="M19.9098 35.1522L16.7293 33.4671V38.4753L19.9098 40.1605V35.1522Z"
        fill="#804604"
      />
      <path
        d="M19.9098 50.1769L16.7293 48.4918V53.5L19.9098 55.1852V50.1769Z"
        fill="#FFD130"
      />
      <path
        d="M19.9098 65.2016L16.7293 63.5164V68.5247L19.9098 70.2099V65.2016Z"
        fill="#9FEFFF"
      />
      <path
        d="M38.9926 45.2633L35.8122 43.5782V48.5864L38.9926 50.2716V45.2633Z"
        fill="#FFE762"
      />
      <path
        d="M38.9926 60.288L35.8122 58.6029V63.6111L38.9926 65.2963V60.288Z"
        fill="#FFC82E"
      />
      <path
        d="M38.9926 75.3127L35.8122 73.6275V78.6358L38.9926 80.321V75.3127Z"
        fill="#ED9D12"
      />
    </svg>
  );
}
