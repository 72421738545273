import React, { useEffect, useMemo, useRef } from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';

import Scrollbar from '../../../atoms/Scrollbar';

const MIN_YEAR = 1960;
const MAX_YEAR = new Date().getFullYear() + 20;

const ButtonWrap = styled('div')<{ selected: boolean }>(
  ({ theme, selected }) => ({
    width: `${100 / 7}%`,
    padding: '2px 2.5px',
    selected,
    '& .MuiButtonBase-root': {
      width: '100%',
      padding: 0,
      height: 36,
      borderRadius: 2,
      backgroundColor: selected ? theme.palette.primary.main : '',
      ...theme.typography.body2,

      '@media (hover: hover)': {
        '&:not(:disabled):hover': {
          color: theme.palette.background.default,
          backgroundColor: theme.palette.text.primary,
        },
      },
    },
  })
);

type YearPickerProps = {
  value: number;
  height?: number | string;
  minYear?: number;
  maxYear?: number;
  onChange: (value: number) => void;
};

export default function YearPicker({
  value,
  onChange,
  minYear = MIN_YEAR,
  maxYear = MAX_YEAR,
  height = 300,
}: YearPickerProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const checkTopRef = useRef<boolean>(false);
  const years = useMemo(() => {
    let startYear = minYear || MIN_YEAR;
    let endYear = maxYear ? maxYear + 1 : MAX_YEAR;

    if (startYear > endYear) {
      const temp = endYear;
      endYear = startYear;
      startYear = temp;
    }
    return [...new Array(endYear - startYear)].map((_, i) => startYear + i);
  }, [minYear, maxYear]);

  useEffect(() => {
    if (scrollRef.current) {
      const el = scrollRef.current;
      if (el && !checkTopRef.current) {
        const target: HTMLElement = el.querySelector(
          `[data-year="${value}"]`
        ) as HTMLElement;
        if (target) {
          el.scrollTo({ top: target.offsetTop - 80 || 0 });
        }

        checkTopRef.current = true;
      }
    }
  }, [value]);

  return (
    <Scrollbar sx={{ height }} scrollableNodeProps={{ ref: scrollRef }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {years.map((year) => (
          <ButtonWrap key={year} selected={value === year}>
            <ButtonBase onClick={() => onChange(year)} data-year={year}>
              {year}
            </ButtonBase>
          </ButtonWrap>
        ))}
      </Box>
    </Scrollbar>
  );
}
