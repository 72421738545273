import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Box, Chip } from '@mui/material';

import useInputEvent from '../hooks/useInputEvent';
import useSuccessState from '../hooks/useSuccessState';
import { FormLayoutChipOptionGroupItem } from '../types';

type ChipOptionGroupProps = {
  item: FormLayoutChipOptionGroupItem;
};

const styles = {
  root: {
    display: 'flex',
    gap: 1,
  },
};

export default function ChipOptionGroup({ item }: ChipOptionGroupProps) {
  const { control } = useFormContext();

  const {
    field: { onChange, onBlur, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: item.name,
    rules: item.rules,
  });

  const successState = useSuccessState(item.name, isDirty);

  const { handleInputBlur, handleInputChange } = useInputEvent({
    name: item.name,
    actionMap: item.actionMap,
    onChange,
    onBlur,
  });

  const handleBlur = () => {
    handleInputBlur();
    void successState.handleBlur();
  };

  const handleChange = (newValue: any) => {
    void handleInputChange(newValue);
    void successState.handleChange();
  };

  const handleClick = (newValue: any) => {
    handleChange(newValue);
    handleBlur();
  };

  const isSelected = (optionValue: any) => {
    return (value ?? item.defaultValue) === optionValue;
  };

  return (
    <Box
      className={`ia-form-layout_chip-options ${item.className || ''}`}
      sx={styles.root}
    >
      {item.options.map((option) => (
        <Chip
          key={option.value}
          label={option.label}
          disabled={item.disabled}
          clickable
          onClick={() => handleClick(option.value)}
          variant={isSelected(option.value) ? 'filled' : 'outlined'}
        />
      ))}
    </Box>
  );
}
