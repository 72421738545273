import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { HighlightData } from '../types';
import { getNodeFromPath, getRangeRects } from '../utils';

const styles = {
  line: {
    position: 'absolute',
    opacity: 0.4,
    borderRadius: 0.5,
    zIndex: -1,
    pointerEvents: 'auto',
  },
};

type Rect = {
  width: number;
  height: number;
  left: number;
  top: number;
  right: number;
  bottom: number;
};

const LINE_MIN_HEIGHT = 24;
const areRectsOverlapping = (rect1: Rect, rect2: Rect) => {
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
};

const mergeRects = (rect1: Rect, rect2: Rect) => {
  const x = Math.min(rect1.left, rect2.left);
  const y = Math.min(rect1.top, rect2.top);
  const right = Math.max(rect1.right, rect2.right);
  const bottom = Math.max(rect1.bottom, rect2.bottom);
  return {
    x: x,
    y: y,
    width: right - x,
    height: bottom - y,
    top: y,
    right: right,
    bottom: bottom,
    left: x,
  };
};

export function getClosestHeadingOrPElementFromRect(
  rect: Rect
): HTMLElement | null {
  const elements = document.elementsFromPoint(
    rect.left + rect.width / 2,
    rect.top + rect.height / 2
  );
  for (const element of elements) {
    if (element.tagName.match(/^H[1-6]$/) || element.tagName === 'P') {
      return element as HTMLElement;
    }
  }

  return null;
}

export function getLineHeight(element: HTMLElement): number {
  const computedStyle = window.getComputedStyle(element);
  const lineHeight = computedStyle.lineHeight;
  if (lineHeight === 'normal') {
    const fontSize = parseFloat(computedStyle.fontSize);
    return fontSize * 1.2;
  }
  return parseFloat(lineHeight);
}

type RectGroup = { color: string; rects: Rect[] };
export default function Highlights({
  data,
  targetAnchorEl,
  onClick,
}: {
  data: HighlightData[];
  targetAnchorEl: HTMLDivElement;
  onClick: (index: number) => void;
}) {
  const [rectGroup, setRectGroup] = useState<RectGroup[]>([]);
  const handleResize = useCallback(() => {
    if (!data.length || !targetAnchorEl) return;
    const groupData: RectGroup[] = [];
    data.forEach((highlight) => {
      if (!targetAnchorEl) return;

      const startContainer = getNodeFromPath(
        targetAnchorEl,
        highlight.startPath
      );
      const endContainer = getNodeFromPath(targetAnchorEl, highlight.endPath);

      const startOffset = highlight.startOffset;
      const endOffset = highlight.endOffset;

      if (startContainer && endContainer) {
        const rects = getRangeRects({
          startContainer,
          endContainer,
          startOffset,
          endOffset,
        });

        const targetRect = targetAnchorEl.getBoundingClientRect();

        const mergedRects: Rect[] = [];
        for (let i = 0; i < rects.length; i++) {
          const rect = rects[i];
          let hasMerged = false;
          for (let j = 0; j < mergedRects.length; j++) {
            if (areRectsOverlapping(mergedRects[j], rect)) {
              mergedRects[j] = mergeRects(mergedRects[j], rect);
              hasMerged = true;
              break;
            }
          }
          if (!hasMerged) {
            mergedRects.push(rect);
          }
        }

        groupData.push({
          color: highlight.color,
          rects: mergedRects.map((rect) => {
            const element = getClosestHeadingOrPElementFromRect(rect);
            const lineHeight = element
              ? getLineHeight(element)
              : LINE_MIN_HEIGHT;
            const rectHeight = Math.max(lineHeight, rect.height);
            const topOffset =
              rectHeight > rect.height ? (rectHeight - rect.height) / 2 : 0;

            return {
              height: rectHeight,
              width: rect.width,
              left: rect.left - targetRect.left,
              top: rect.top - targetRect.top - topOffset,
              right: rect.right - targetRect.left,
              bottom: rect.bottom - targetRect.top,
            };
          }),
        });
      }
      setRectGroup(groupData);
    });
  }, [data, targetAnchorEl]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <>
      {rectGroup.map((group, index) => (
        <React.Fragment key={index}>
          {group.rects.map((rect, rectIndex) => (
            <Box
              key={`${index}-${rectIndex}`}
              className="highlight-line"
              onClick={() => onClick(index)}
              sx={[
                styles.line,
                {
                  top: rect.top,
                  left: rect.left,
                  height: rect.height,
                  width: rect.width,
                  bgcolor: group.color,
                },
              ]}
            />
          ))}
        </React.Fragment>
      ))}
    </>
  );
}
