import React from 'react';
import nextId from 'react-id-generator';

export default function PinViewedIcon({ ...rest }) {
  const id = nextId('pin-viewed-icon');

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <mask id={`${id}-path`} fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 16C0 3.2 3.2 0 16 0C28.8 0 32 3.2 32 16C32 28.1177 29.1321 31.6316 17.9661 31.9718L18 32H16H0V17L0.00697906 17.0058C0.00228563 16.6773 0 16.3421 0 16Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16C0 3.2 3.2 0 16 0C28.8 0 32 3.2 32 16C32 28.1177 29.1321 31.6316 17.9661 31.9718L18 32H16H0V17L0.00697906 17.0058C0.00228563 16.6773 0 16.3421 0 16Z"
        fill="#151A28"
      />
      <path
        d="M17.9661 31.9718L17.9356 30.9722L15.3008 31.0525L17.3259 32.74L17.9661 31.9718ZM18 32V33H20.7622L18.6402 31.2318L18 32ZM0 32H-1V33H0V32ZM0 17L0.64016 16.2318L-1 14.865V17H0ZM0.00697906 17.0058L-0.633181 17.7741L1.03795 19.1666L1.00688 16.9915L0.00697906 17.0058ZM16 -1C9.60715 -1 5.2109 -0.225114 2.49289 2.49289C-0.225114 5.2109 -1 9.60715 -1 16H1C1 9.59285 1.82511 5.9891 3.90711 3.90711C5.9891 1.82511 9.59285 1 16 1V-1ZM33 16C33 9.60715 32.2251 5.2109 29.5071 2.49289C26.7891 -0.225114 22.3929 -1 16 -1V1C22.4071 1 26.0109 1.82511 28.0929 3.90711C30.1749 5.9891 31 9.59285 31 16H33ZM17.9966 32.9713C23.6145 32.8001 27.5153 31.8365 29.9257 29.0577C32.3036 26.3162 33 22.0576 33 16H31C31 22.0601 30.2624 25.6172 28.4149 27.7472C26.5998 29.8397 23.4837 30.8032 17.9356 30.9722L17.9966 32.9713ZM17.3259 32.74L17.3598 32.7682L18.6402 31.2318L18.6063 31.2035L17.3259 32.74ZM18 31H16V33H18V31ZM16 31H0V33H16V31ZM1 32V17H-1V32H1ZM-0.64016 17.7682L-0.633181 17.7741L0.647139 16.2376L0.64016 16.2318L-0.64016 17.7682ZM-1 16C-1 16.3465 -0.997685 16.6865 -0.992919 17.0201L1.00688 16.9915C1.00226 16.6681 1 16.3376 1 16H-1Z"
        fill="white"
        fillOpacity="0.1"
        mask={`url(#${id}-path)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4C6.4 4 4 6.4 4 16C4 25.6 6.4 28 16 28C25.6 28 28 25.6 28 16C28 6.4 25.6 4 16 4Z"
        fill="white"
      />
    </svg>
  );
}
