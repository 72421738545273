import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import {
  alpha,
  createTheme,
  Theme,
  ThemeProvider,
  useTheme,
} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { OtherLightbulb as OtherLightbulbIcon } from '@front/icon';

import base from '../../themes/base';
import exam from '../../themes/exam';

export type LightbulbCardProps = {
  title?: string;
  children?: ReactNode;
};

const styles = {
  root: {
    p: 2.5,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    bgcolor: 'background.darker',
    color: 'text.primary',
    borderRadius: 2,
  },
  title: {
    display: 'flex',
    gap: '6px',
  },
  content: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
};
export default function LightbulbCard({ title, children }: LightbulbCardProps) {
  const theme = useTheme();
  const updateTheme = theme.palette.mode === 'dark' ? exam : base;

  return (
    <ThemeProvider theme={createTheme(updateTheme)}>
      <Box sx={styles.root}>
        <Typography
          sx={styles.title}
          variant="body1"
          component="h6"
          fontWeight={700}
        >
          <OtherLightbulbIcon width="24" height="24" />
          {title}
        </Typography>
        <Box sx={styles.content}>{children}</Box>
      </Box>
    </ThemeProvider>
  );
}
