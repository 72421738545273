import React from 'react';

export default function AnalyticsSummary({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.166 12.5922H11.4065V4.83349C11.4065 4.73802 11.3284 4.65992 11.2329 4.65992H10.6687C9.53013 4.65875 8.40253 4.8824 7.3506 5.31802C6.29867 5.75365 5.34312 6.39267 4.53877 7.19843C3.74683 7.98784 3.11598 8.9237 2.68135 9.95391C2.23001 11.0236 2 12.1583 2 13.3299C2 14.5015 2.23001 15.6341 2.68135 16.7038C3.11749 17.7344 3.74242 18.663 4.53877 19.4592C5.33512 20.2555 6.26166 20.8804 7.29453 21.3165C8.36242 21.7695 9.51087 22.002 10.6709 21.9999C11.8095 22.0011 12.9371 21.7774 13.989 21.3418C15.0409 20.9062 15.9965 20.2672 16.8008 19.4614C17.5972 18.6651 18.2221 17.7387 18.6582 16.7059C19.1113 15.6382 19.3438 14.4898 19.3418 13.3299V12.7658C19.3396 12.6703 19.2615 12.5922 19.166 12.5922Z"
        fill="currentColor"
        opacity="0.6"
      />
      <path
        d="M21.9426 9.85053L21.999 10.4624C22.0077 10.5622 21.9274 10.6468 21.8254 10.649L13.5017 10.6707C13.4062 10.6707 13.3281 10.5926 13.3281 10.4971V2.17206C13.3281 2.07009 13.4149 1.99198 13.5169 2.00066L14.131 2.05707C16.1389 2.24308 18.0186 3.1249 19.445 4.55002C20.8728 5.97116 21.7582 7.85443 21.9426 9.85053Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
