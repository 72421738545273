import React, { ReactElement, useState } from 'react';
import { alpha, ButtonBase, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { InfoTooltip, SimpleTooltip } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';

export type IaTableAddNewRowProps = {
  text: string;
  icon?: string;
  onClick: () => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  disabledAfterClick?: boolean;
  tooltip?:
    | string
    | {
        icon: string;
        title: string;
        content: string;
      };
};

const styles = {
  root: {
    display: 'grid',
    width: '100%',
    justifyContent: 'start',
    gridTemplateColumns: 'max-content', // for position:sticky

    '@media (hover: hover)': {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
      },
    },
    '&.Mui-disabled': {
      opacity: '0.5',
    },
  },
  content: {
    position: 'sticky',
    left: '24px',
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    gap: 1,
    px: 1,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
  },
  icon: {
    width: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

type TooltipWrapperProps = {
  tooltip?: IaTableAddNewRowProps['tooltip'];
  children: ReactElement;
};

function TooltipWrapper({ tooltip, children }: TooltipWrapperProps) {
  if (!tooltip) return children;
  if (typeof tooltip === 'string') {
    return (
      <SimpleTooltip title={tooltip} followCursor>
        {children}
      </SimpleTooltip>
    );
  }
  return (
    <InfoTooltip
      titleIcon={<Icon name={tooltip.icon} width={16} height={16} />}
      title={tooltip.title}
      content={tooltip.content}
      followCursor
    >
      {children}
    </InfoTooltip>
  );
}

export default function IaTableActionRow({
  text,
  icon = 'TestAdd',
  onClick,
  sx,
  disabled = false,
  disabledAfterClick = false,
  tooltip,
}: IaTableAddNewRowProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const [isDisabled, setIsDisabled] = useState(disabled);

  const handleClick = () => {
    if (disabledAfterClick) {
      setIsDisabled(true);
    }
    onClick?.();
  };

  return (
    <TooltipWrapper tooltip={tooltip}>
      <ButtonBase sx={styles.root} onClick={handleClick} disabled={isDisabled}>
        <Box sx={[styles.content, ...sxProps]}>
          <Box sx={styles.icon}>
            <Icon name={icon} width={16} height={16} />
          </Box>
          <Typography variant="body2">{text}</Typography>
        </Box>
      </ButtonBase>
    </TooltipWrapper>
  );
}
