import { useState } from 'react';
import { alpha, Box, Theme } from '@mui/material';
import {
  OtherMute as OtherMuteIcon,
  OtherVolume as OtherVolumeIcon,
} from '@front/icon';
import { animated, useSpring } from '@react-spring/web';

import { BarSlider, TipButton } from '../../../atoms';

const AnimatedBox = animated(Box);

const styles = {
  root: {
    px: 1,
    mx: -1,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    transition: 'background 0.3s',
  },
  expand: {
    bgcolor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.3)
        : alpha(theme.palette.text.primary, 0.1),
    ml: 0,
  },
  button: {
    ml: 'auto',
    flex: '0 0 24px',
  },
  bar: {
    flex: 1,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    height: 8,
    borderRadius: 2,
    overflow: 'hidden',
    cursor: 'pointer',
    userSelect: 'none',
    touchAction: 'none',
  },
};
export default function VolumeControl({
  muted,
  volume,
  disabled,
  onToggleMute,
  onVolumeChange,
}: {
  muted: boolean;
  volume: number;
  disabled: boolean;
  onToggleMute: (muted: boolean) => void;
  onVolumeChange: (volume: number) => void;
}) {
  const [expand, setExpand] = useState(false);
  const styleProps = useSpring({ width: expand ? 145 : 40 });

  return (
    <AnimatedBox
      style={styleProps}
      sx={[styles.root, expand && styles.expand]}
      onMouseEnter={disabled ? undefined : () => setExpand(true)}
      onMouseLeave={disabled ? undefined : () => setExpand(false)}
    >
      {expand && (
        <BarSlider
          value={muted ? 0 : volume}
          onChange={onVolumeChange}
          disabled={disabled}
        />
      )}
      {muted ? (
        <TipButton
          sx={styles.button}
          title="Unmute"
          customSize={24}
          disabled={disabled}
          onClick={() => onToggleMute(false)}
        >
          <OtherMuteIcon />
        </TipButton>
      ) : (
        <TipButton
          sx={styles.button}
          title="Mute"
          customSize={24}
          disabled={disabled}
          onClick={() => onToggleMute(true)}
        >
          <OtherVolumeIcon />
        </TipButton>
      )}
    </AnimatedBox>
  );
}
