import {
  ComposerBlock,
  persistentStringToComposerBlock,
} from '@lib/web/composer';
import { BlockContainerBlockForGenerateHtml } from '@lib/web/composer/TextComposer/components/blocks/BlockContainerBlock';
import { HeadingBlock } from '@lib/web/composer/TextComposer/components/blocks/HeadingBlock';
import { InlineBlank } from '@lib/web/composer/TextComposer/components/blocks/InlineBlank';
import { InlineMention } from '@lib/web/composer/TextComposer/components/blocks/InlineMention';
import { ParagraphBlockForGenerateHtml } from '@lib/web/composer/TextComposer/components/blocks/ParagraphBlock';
import StepBlock from '@lib/web/composer/TextComposer/components/blocks/StepBlock';
import { SubtitleBlock } from '@lib/web/composer/TextComposer/components/blocks/SubtitleBlock';
import { UnsupportedBlock } from '@lib/web/composer/TextComposer/components/blocks/UnsupportedBlock';
import {
  TextColorExtension,
  TextColorMark,
} from '@lib/web/composer/TextComposer/extensions/TextColor';
import FileBlock from '@lib/web/thread/ThreadTextComposer/components/blocks/FileBlock';
import ImageBlock from '@lib/web/thread/ThreadTextComposer/components/blocks/ImageBlock';
import { InlineHighlight } from '@lib/web/thread/ThreadTextComposer/components/blocks/InlineHighlight';
import { InlineLatex } from '@lib/web/thread/ThreadTextComposer/components/blocks/InlineLatex';
import { InlineLineMarker } from '@lib/web/thread/ThreadTextComposer/components/blocks/InlineLineMarker';
import { InlineVariable } from '@lib/web/thread/ThreadTextComposer/components/blocks/InlineVariable';
import { generateHTML } from '@tiptap/core';
import Bold from '@tiptap/extension-bold';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Italic from '@tiptap/extension-italic';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import memoize from 'lodash/memoize';

const composerBlockToHtml = (composerBlock: ComposerBlock): string => {
  try {
    return generateHTML(composerBlock, [
      Document,
      BlockContainerBlockForGenerateHtml,
      ParagraphBlockForGenerateHtml,
      Text,
      HeadingBlock.node,
      SubtitleBlock.node,
      HardBreak,
      InlineBlank,
      Underline,
      Bold,
      Italic,
      TextColorMark,
      TextColorExtension,
      StepBlock.node,
      InlineLatex,
      ImageBlock,
      FileBlock,
      UnsupportedBlock,
      InlineMention,
      InlineHighlight,
      InlineLineMarker,
      InlineVariable,
    ]);
  } catch (e) {
    if (e instanceof RangeError) {
      console.warn('not supported type to generate html : ', composerBlock, e);
      return '';
    }
    console.error(e);
    return '';
  }
};

/**
 * !!!!!!Caution!!!!!!:
 * If you attempt to render HTML in our application, use the useRenderThreadMessageToHtml hook instead.
 * That hook ensures that unsigned URLs are properly handled.
 */
export const composerBlocksToHtml = memoize(
  (composerBlocks: ComposerBlock[]): string => {
    const htmls = composerBlocks.map(composerBlockToHtml);
    return htmls.join('');
  },
  (composerBlocks) => JSON.stringify(composerBlocks)
);

/**
 * Be caution, this one use dom creation to check if content is empty, so it might be heavy during large number of elements
 * @param html
 */
export const isBlockHtmlEmpty = memoize((html: string): boolean => {
  const dom = document.createElement('div');
  dom.innerHTML = html;
  const hasNoText = dom.innerText.trim() === '';
  const hasNoImage = dom.querySelector('img') === null;
  const hasNoFile = dom.querySelector('[data-content-type="file"]') === null;

  return hasNoText && hasNoImage && hasNoFile;
});

export const persistentStringToHtml = memoize(
  (content: string | null): string => {
    if (!content) {
      return '';
    }

    const rootBlock = persistentStringToComposerBlock(content);

    if (rootBlock.type === 'text') {
      return rootBlock.text || '';
    }

    if (rootBlock.type === 'blockContainer') {
      if (rootBlock.content?.length !== 1) {
        console.warn(
          'rootBlock.content.length is not 1, which is not expected',
          rootBlock
        );
        return composerBlockToHtml(rootBlock);
      }

      return composerBlockToHtml(rootBlock);
    }

    console.warn('not supported type to transform html : ', rootBlock);
    return '';
  }
);
