import React from 'react';
import { v4 } from 'uuid';

export default function Award238({ ...rest }) {
  const id = v4();
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#clip0_${id})`}>
        <g clipPath={`url(#clip1_${id})`}>
          <g filter={`url(#filter0_d_${id})`}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M74.8284 23.4853L60.5147 9.17158C59.7646 8.42143 58.7472 8 57.6863 8L26.3137 8C25.2528 8 24.2354 8.42143 23.4853 9.17157L9.17157 23.4853C8.42143 24.2354 8 25.2528 8 26.3137V57.6863C8 58.7472 8.42143 59.7646 9.17157 60.5147L23.4853 74.8284C24.2354 75.5786 25.2528 76 26.3137 76H57.6863C58.7472 76 59.7646 75.5786 60.5147 74.8284L74.8284 60.5147C75.5786 59.7646 76 58.7472 76 57.6863V26.3137C76 25.2528 75.5786 24.2354 74.8284 23.4853ZM63.3431 6.34315C61.8429 4.84286 59.808 4 57.6863 4H26.3137C24.192 4 22.1571 4.84285 20.6569 6.34315L6.34315 20.6569C4.84286 22.1571 4 24.192 4 26.3137V57.6863C4 59.808 4.84285 61.8429 6.34315 63.3431L20.6569 77.6569C22.1571 79.1571 24.192 80 26.3137 80H57.6863C59.808 80 61.8429 79.1572 63.3431 77.6569L77.6569 63.3431C79.1571 61.8429 80 59.808 80 57.6863V26.3137C80 24.192 79.1571 22.1571 77.6569 20.6569L63.3431 6.34315Z"
              fill={`url(#paint0_linear_${id})`}
            />
          </g>
          <g style={{ mixBlendMode: 'screen' }}>
            <mask
              id={`mask0_${id}`}
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="4"
              y="4"
              width="76"
              height="76"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.8284 23.4853L60.5147 9.17158C59.7646 8.42143 58.7472 8 57.6863 8L26.3137 8C25.2528 8 24.2354 8.42143 23.4853 9.17157L9.17157 23.4853C8.42143 24.2354 8 25.2528 8 26.3137V57.6863C8 58.7472 8.42143 59.7646 9.17157 60.5147L23.4853 74.8284C24.2354 75.5786 25.2528 76 26.3137 76H57.6863C58.7472 76 59.7646 75.5786 60.5147 74.8284L74.8284 60.5147C75.5786 59.7646 76 58.7472 76 57.6863V26.3137C76 25.2528 75.5786 24.2354 74.8284 23.4853ZM63.3431 6.34315C61.8429 4.84286 59.808 4 57.6863 4H26.3137C24.192 4 22.1571 4.84285 20.6569 6.34315L6.34315 20.6569C4.84286 22.1571 4 24.192 4 26.3137V57.6863C4 59.808 4.84285 61.8429 6.34315 63.3431L20.6569 77.6569C22.1571 79.1571 24.192 80 26.3137 80H57.6863C59.808 80 61.8429 79.1572 63.3431 77.6569L77.6569 63.3431C79.1571 61.8429 80 59.808 80 57.6863V26.3137C80 24.192 79.1571 22.1571 77.6569 20.6569L63.3431 6.34315Z"
                fill={`url(#paint1_linear_${id})`}
              />
            </mask>
            <g mask={`url(#mask0_${id})`}>
              <g filter={`url(#filter1_f_${id})`}>
                <rect
                  x="62.208"
                  y="-1.53613"
                  width="31.4975"
                  height="90.1483"
                  transform="rotate(45 62.208 -1.53613)"
                  fill="#FFA6B5"
                />
              </g>
            </g>
          </g>
          <g filter={`url(#filter2_d_${id})`}>
            <path
              d="M62 18C62.9338 20.8393 65.1607 23.0662 68 24C65.1607 24.9338 62.9338 27.1607 62 30C61.0662 27.1607 58.8393 24.9338 56 24C58.8393 23.0662 61.0662 20.8393 62 18Z"
              fill="#FF8298"
            />
          </g>
          <g filter={`url(#filter3_d_${id})`}>
            <path
              d="M20.5 50C21.2003 52.1295 22.8705 53.7997 25 54.5C22.8705 55.2003 21.2003 56.8705 20.5 59C19.7997 56.8705 18.1295 55.2003 16 54.5C18.1295 53.7997 19.7997 52.1295 20.5 50Z"
              fill="#FF8298"
            />
          </g>
          <g filter={`url(#filter4_d_${id})`}>
            <rect
              x="46.502"
              y="20.2393"
              width="13.7596"
              height="41.2789"
              rx="6.87981"
              transform="rotate(30 46.502 20.2393)"
              fill="#FF4B6C"
            />
            <ellipse
              cx="35.2604"
              cy="53.4698"
              rx="6.87981"
              ry="6.87982"
              transform="rotate(30 35.2604 53.4698)"
              fill="#FF8298"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id={`filter0_d_${id}`}
          x="-2"
          y="-2"
          width="88"
          height="88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.976471 0 0 0 0 0.32549 0 0 0 0 0.447059 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result={`effect1_dropShadow_${id}`}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={`effect1_dropShadow_${id}`}
            result="shape"
          />
        </filter>
        <filter
          id={`filter1_f_${id}`}
          x="-5.53662"
          y="-5.53613"
          width="94.0166"
          height="94.0166"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result={`effect1_foregroundBlur_${id}`}
          />
        </filter>
        <filter
          id={`filter2_d_${id}`}
          x="51"
          y="13"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.976471 0 0 0 0 0.32549 0 0 0 0 0.447059 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result={`effect1_dropShadow_${id}`}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={`effect1_dropShadow_${id}`}
            result="shape"
          />
        </filter>
        <filter
          id={`filter3_d_${id}`}
          x="11"
          y="45"
          width="19"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.976471 0 0 0 0 0.32549 0 0 0 0 0.447059 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result={`effect1_dropShadow_${id}`}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={`effect1_dropShadow_${id}`}
            result="shape"
          />
        </filter>
        <filter
          id={`filter4_d_${id}`}
          x="2"
          y="2"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.976471 0 0 0 0 0.32549 0 0 0 0 0.447059 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result={`effect1_dropShadow_${id}`}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={`effect1_dropShadow_${id}`}
            result="shape"
          />
        </filter>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="60"
          y1="5.5"
          x2="24.5"
          y2="72.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4769" />
          <stop offset="0.795399" stopColor="#FF8298" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_${id}`}
          x1="60"
          y1="5.5"
          x2="24.5"
          y2="72.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4D00CA" />
          <stop offset="1" stopColor="#9D54FC" />
        </linearGradient>
        <clipPath id={`clip0_${id}`}>
          <rect width="84" height="84" fill="white" />
        </clipPath>
        <clipPath id={`clip1_${id}`}>
          <rect width="84" height="84" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
