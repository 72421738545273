export default function ProfileManageAIBot({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.3452 12.5435C13.3668 12.3693 13.3811 12.1881 13.3811 12C13.3811 11.8119 13.3668 11.6307 13.3381 11.4565L14.5501 10.5368C14.6577 10.4532 14.6864 10.3 14.6218 10.1815L13.4743 8.25154C13.4026 8.12613 13.252 8.08433 13.1229 8.12613L11.6957 8.68352C11.3945 8.46056 11.0789 8.27941 10.7275 8.14006L10.5124 6.66298C10.4908 6.52363 10.3689 6.42609 10.2255 6.42609H7.93049C7.78705 6.42609 7.6723 6.52363 7.65078 6.66298L7.43563 8.14006C7.08421 8.27941 6.76147 8.46753 6.46743 8.68352L5.04023 8.12613C4.91113 8.07736 4.76052 8.12613 4.6888 8.25154L3.54131 10.1815C3.46959 10.3069 3.49828 10.4532 3.61303 10.5368L4.82507 11.4565C4.79638 11.6307 4.77487 11.8188 4.77487 12C4.77487 12.1812 4.78921 12.3693 4.8179 12.5435L3.60585 13.4632C3.49828 13.5468 3.46959 13.7 3.53413 13.8185L4.68163 15.7485C4.75335 15.8739 4.90396 15.9157 5.03305 15.8739L6.46025 15.3165C6.76147 15.5394 7.07703 15.7206 7.42846 15.8599L7.64361 17.337C7.6723 17.4764 7.78705 17.5739 7.93049 17.5739H10.2255C10.3689 17.5739 10.4908 17.4764 10.5052 17.337L10.7203 15.8599C11.0718 15.7206 11.3945 15.5325 11.6885 15.3165L13.1157 15.8739C13.2448 15.9226 13.3954 15.8739 13.4672 15.7485L14.6147 13.8185C14.6864 13.6931 14.6577 13.5468 14.5429 13.4632L13.3452 12.5435ZM9.07798 14.0902C7.89463 14.0902 6.92643 13.1496 6.92643 12C6.92643 10.8504 7.89463 9.90978 9.07798 9.90978C10.2613 9.90978 11.2295 10.8504 11.2295 12C11.2295 13.1496 10.2613 14.0902 9.07798 14.0902Z"
        fill="currentColor"
      />
      <path
        d="M16.1932 5.25L17.2249 3L18.2484 5.25L20.5 6.27273L18.2484 7.30364L17.2249 9.54545L16.1932 7.30364L13.9498 6.27273L16.1932 5.25Z"
        fill="currentColor"
      />
      <path
        d="M16.1932 16.6964L17.2249 14.4545L18.2484 16.6964L20.5 17.7273L18.2484 18.75L17.2249 21L16.1932 18.75L13.9498 17.7273L16.1932 16.6964Z"
        fill="currentColor"
      />
    </svg>
  );
}
