import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

import ErrorMessageItem from './components/ErrorMessageItem';
import { ErrorMessageValue } from './types';

export type ErrorMessageProps = {
  sx?: BoxProps['sx'];
  messages?: ErrorMessageValue[];
  /**
   * to combine all messages into only one section, each message has sequence number prefix
   */
  merged?: boolean;
};

function formatMessage(
  message: string,
  merged: boolean,
  index: number,
  messagesCount: number
) {
  if (!merged || messagesCount === 1) return message;
  return `${index + 1}. ${message}`;
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  merged: {
    gap: 0,
  },
};
export default function ErrorMessage({
  messages,
  merged = false,
  sx,
}: ErrorMessageProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <Box sx={[styles.root, merged && styles.merged, ...sxProps]}>
      {messages?.map(({ id, type, message }, index) => (
        <ErrorMessageItem
          key={id}
          type={type}
          message={formatMessage(message, merged, index, messages.length)}
          messageOnly={messages.length > 1 && merged && index > 0}
        />
      ))}
    </Box>
  );
}
