import React from 'react';
import { alpha, Divider, Theme } from '@mui/material';

const styles = {
  root: {
    typography: 'caption',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    width: '100%',
    px: 2.5,
    '.base-layout-right-panel &': {
      px: 1.5,
    },
    py: 0.5,

    '::before': {
      width: '100%',
      borderTop: (theme: Theme) =>
        `thin solid ${alpha(theme.palette.text.primary, 0.1)}`,
    },
  },
};

export type ReplyDividerProps = {
  replies: number;
};

// XXX: this component might look like DividerItem, we might be able to consolidate these two components when apply notification layout
export default function ReplyDivider({ replies }: ReplyDividerProps) {
  return (
    <Divider sx={styles.root} textAlign="left">
      {/* XXX: use i18n to handle plural */}
      {replies} {replies === 1 ? 'reply' : 'replies'}
    </Divider>
  );
}
