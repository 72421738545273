import React, { ReactElement } from 'react';
import { alpha, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { InfoTooltip, SimpleTooltip } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';

import useTableSettings from '../../hooks/useTableSettings';
import { TableLayoutFooter, TableLayoutFooterRow } from '../../types';

import IaTableFooterCell from './IaTableFooterCell';

const styles = {
  root: {
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
  },
  rowBorder: {
    ':not(:first-child)': {
      boxShadow: (theme: Theme) =>
        `inset 0 1px 0 0 ${alpha(theme.palette.text.primary, 0.1)}`,
    },
  },
  rootCheckboxPadding: {
    ml: '-24px',
  },
};

type TooltipWrapperProps = {
  tooltip?: TableLayoutFooterRow['tooltip'];
  children: ReactElement;
};

function TooltipWrapper({ tooltip, children }: TooltipWrapperProps) {
  if (!tooltip) return children;
  if (typeof tooltip === 'string') {
    return (
      <SimpleTooltip title={tooltip} followCursor>
        {children}
      </SimpleTooltip>
    );
  }
  return (
    <InfoTooltip
      titleIcon={<Icon name={tooltip.icon} width={16} height={16} />}
      title={tooltip.title}
      content={tooltip.content}
      followCursor
    >
      {children}
    </InfoTooltip>
  );
}

export type IaTableFooterRowProps = {
  row?: TableLayoutFooterRow;
  hasBottomButtons?: boolean;
};

function IaTableFooterRow({
  row,
  hasBottomButtons = false,
}: IaTableFooterRowProps) {
  const { layoutSetting } = useTableSettings();

  if (!row) return null;

  const { gridTemplateColumns, cells, sx } = row;
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <TooltipWrapper tooltip={row.tooltip}>
      <Box
        sx={[
          styles.root,
          !!layoutSetting?.showRowBorder &&
            hasBottomButtons &&
            styles.rowBorder,
          { gridTemplateColumns },
          ...sxProps,
        ]}
      >
        {cells.map((cell, index) => (
          <IaTableFooterCell key={index} cell={cell} />
        ))}
      </Box>
    </TooltipWrapper>
  );
}

export type IaTableFooterProps = {
  footer?: TableLayoutFooter | TableLayoutFooterRow;
  showCheckboxBackground?: boolean;
  hasBottomButtons?: boolean;
};

export default function IaTableFooter({
  footer,
  hasBottomButtons = false,
}: IaTableFooterProps) {
  if (!footer) return null;
  const rows = 'rows' in footer ? footer.rows : [footer];
  if (rows.length === 0) return null;

  return (
    <Box>
      {rows.map((row, index) => (
        <IaTableFooterRow
          key={index}
          row={row}
          hasBottomButtons={hasBottomButtons}
        />
      ))}
    </Box>
  );
}
