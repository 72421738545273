import matchAll from 'string.prototype.matchall';

export const figurePattern = /{%(.+)%}/g;

const getDataObj = (material: GetQuizMaterialRes) => {
  if (!material) return null;
  try {
    const result = JSON.parse(material.content);
    return result;
  } catch (err) {
    return null;
  }
};

export function translateFigure(tex: string, references: any, setId = 0) {
  if (!tex || typeof tex !== 'string') return tex;

  let txt = tex;
  const matchArr = [...matchAll(txt, figurePattern)];

  matchArr.forEach((match) => {
    let filename: string = match[1].split('/').pop() || '';
    if (setId) {
      filename = `${filename}_${setId}`;
    }

    const referenceObj = getDataObj(references[filename]);

    if (referenceObj && referenceObj.html) {
      txt = txt.replace(
        match[0],
        `<div data-table="${filename}" class="cus-scrollbar">${referenceObj.html}</div>`
      );
    } else if (referenceObj) {
      txt = txt.replace(match[0], `<figure>${filename}</figure>`);
    } else {
      txt = txt.replace(
        match[0],
        `<img src="${match[1]}" onError="this.src='https://avl-images.s3.ap-south-1.amazonaws.com/PerfectScore/general/ChartMissing.svg';" />`
      );
    }
  });

  return txt;
}
