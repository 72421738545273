import { format, parseISO } from 'date-fns';

/**
 * @param {*} value seconds
 */
const timeFormat = (value: number) => {
  const seconds = Math.abs(value);
  if (seconds !== 0) {
    const minutes = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${sec
      .toString()
      .padStart(2, '0')}`;
  }
  return '00:00';
};

// convert a date string to RFC3339 format
// "2024-01-01" -> 2024-01-01T00:00:00+08:00 (depending on the input time zone)
const convertToRFC3339 = (dateString: string): string => {
  return format(parseISO(dateString), "yyyy-MM-dd'T'HH:mm:ssXXX");
};

export { convertToRFC3339, timeFormat };
