import React, { DragEvent, useRef, useState } from 'react';
import Link from 'next/link';
import { alpha, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {
  BaseLayoutRightPanel,
  EmphasizeButton,
  MaskIcon,
  useBaseRightPanel,
} from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import ImageUploader, {
  ImageUploaderHandler,
} from '@lib/ia/src/components/ImageUploader';
import { IaPanelKeys, IaPanelParams } from '@lib/ia/src/types/panels';

const styles = {
  uploadArea: {
    p: '12px',
    display: 'grid',
    gap: 1,
    borderRadius: 2,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
  uploadIcon: {
    width: 40,
    height: 40,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  uploadHint: {
    opacity: 0.64,
  },
  dragging: {
    bgcolor: (theme: Theme) => alpha(theme.palette.primary.main, 0.1),
  },
  or: {
    opacity: 0.5,
    height: 27,
    display: 'flex',
    alignItems: 'center',
  },
  photoLibrary: {
    mt: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
};

export default function UploadAvatarPanel() {
  const { getRightParams } = useBaseRightPanel<IaPanelParams>();
  const {
    config,
    photoLibrary,
    onImageKeyChange,
    onFileChange,
    onPreviewUrlChange,
    onProgressChange,
  } = getRightParams(IaPanelKeys.UploadAvatar);
  const imageUploaderRef = useRef<ImageUploaderHandler>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    imageUploaderRef.current?.handleFileUpload(e.dataTransfer.files[0]);
  };

  const handleClickUploadButton = () => {
    imageUploaderRef.current?.openFileSelector();
  };

  return (
    <BaseLayoutRightPanel
      titleIcon={
        <Icon name={config.uploadIcon || 'OtherLink'} width={16} height={16} />
      }
      title={config.uploadTitle || 'Upload Photo'}
      sx={[isDragging && styles.dragging]}
    >
      <BaseLayoutRightPanel.ScrollArea
        onClick={(ev) => {
          /**
           * XXX: This panel might be inside a table layout cell,
           * which could cause any click on an item inside the panel to trigger table layout behavior.
           * To prevent this, we add stopPropagation.
           */
          ev.stopPropagation();
        }}
      >
        <BaseLayoutRightPanel.Content>
          <ImageUploader
            ref={imageUploaderRef}
            config={config}
            onImageKeyChange={onImageKeyChange}
            onFileChange={onFileChange}
            onPreviewUrlChange={onPreviewUrlChange}
            onProgressChange={onProgressChange}
          />

          <Box
            sx={styles.uploadArea}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClickUploadButton}
          >
            <MaskIcon sx={styles.uploadIcon}>
              <Icon name={config.uploadIcon || 'ActionArrowDown'} />
            </MaskIcon>

            <Typography>
              {config.uploadLabel ??
                'Click to upload a photo or drag and drop it here'}
            </Typography>
            {!!config.targetUploadHint && (
              <Typography sx={styles.uploadHint} variant="caption">
                {config.targetUploadHint}
              </Typography>
            )}
          </Box>
          {!!photoLibrary && (
            <Box sx={styles.photoLibrary}>
              <Typography sx={styles.or} variant="caption">
                Or
              </Typography>

              <EmphasizeButton
                component={Link}
                href={photoLibrary.url}
                prefixIcon={<Icon name={photoLibrary.icon} />}
              >
                {photoLibrary.title}
              </EmphasizeButton>
            </Box>
          )}
        </BaseLayoutRightPanel.Content>
      </BaseLayoutRightPanel.ScrollArea>
    </BaseLayoutRightPanel>
  );
}
