import React from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { alpha, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BottomSheet from '../BottomSheet';
import DatePicker, { DatePickerProps } from '../DatePicker';

export type DatePickerPopupProps = DatePickerProps & {
  anchorEl: Element | null;
  onClose: () => void;
};

const styles = {
  paper: {
    py: 2,
    px: '14px',
    borderRadius: 2,
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.05)
        : alpha(theme.palette.background.default, 0.75),
    backgroundImage: 'unset',
    backdropFilter: 'blur(25px)',
    boxShadow: 'unset',
  },
  mobilePicker: {
    display: 'flex',
    justifyContent: 'center',
  },
};

type DesktopDatePickerProps = DatePickerPopupProps;

type MobileDatePickerProps = {
  open: boolean;
} & Omit<DesktopDatePickerProps, 'anchorEl'>;

function DesktopDatePicker({
  anchorEl,
  onClose,
  ...rest
}: DesktopDatePickerProps) {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{ sx: styles.paper }}
    >
      <DatePicker {...rest} />
    </Popover>
  );
}

function MobileDatePicker({ open, onClose, ...rest }: MobileDatePickerProps) {
  return (
    <BottomSheet open={open} onClose={onClose} fixedHeight>
      <Box sx={styles.mobilePicker}>
        <DatePicker {...rest} />
      </Box>
    </BottomSheet>
  );
}

export default function DatePickerPopup({
  value,
  anchorEl,
  onChange,
  onClose,
  ...rest
}: DatePickerPopupProps) {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (newValue: any) => {
    onChange?.(newValue);
  };

  const datePickerProps = {
    value,
    onClose,
    onChange: handleChange,
  };
  return smUp ? (
    <DesktopDatePicker anchorEl={anchorEl} {...datePickerProps} {...rest} />
  ) : (
    <MobileDatePicker open={Boolean(anchorEl)} {...datePickerProps} {...rest} />
  );
}
