import React from 'react';

export default function TestHistory({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.4769 8.00006V13L16.553 15.5401L17.2387 14.3251L13.9055 12.25V8.00006H12.4769Z"
        fill="currentColor"
      />
      <path
        d="M13.4238 3C8.68571 3 4.85714 7.02998 4.85714 12H2L5.70951 15.895L5.77616 16.04L9.61906 12H6.76192C6.76192 8.13502 9.74763 5.00002 13.4286 5.00002C17.1095 5.00002 20.0952 8.13502 20.0952 12C20.0952 15.865 17.1095 19 13.4286 19C11.5857 19 9.9238 18.21 8.71906 16.945L7.37143 18.36C8.91906 19.99 11.0571 21 13.4238 21C18.1619 21 22 16.97 22 12C22 7.02998 18.1619 3 13.4238 3Z"
        fill="currentColor"
      />
    </svg>
  );
}
