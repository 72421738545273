import {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import { useInView } from '@react-spring/web';

import ContentItem from './components/ContentItem';
import MessageItem from './components/ContentItem/MessageItem';
import NotificationItem from './components/ContentItem/NotificationItem';
import SkeletonItem from './components/ContentItem/SkeletonItem';
import MessageActionArea from './components/MessageActionArea';
import { ChannelLayoutContext } from './contexts/channelLayoutContext';
import { ChannelLayoutConfig } from './types';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export type ChannelLayoutProps = ChannelLayoutConfig;

function LazyLoadContentItem({ children }: PropsWithChildren) {
  /**
   * We use a hide state with a small delay to prevent UI flickering when rendering.
   * Without this, the content would frequently switch between actual content and skeleton,
   * causing a jarring visual effect. The delay ensures a smoother transition and better UX.
   */
  const [ref, inView] = useInView({
    rootMargin: '25% 0% 25% 0%',
  });

  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    if (inView) {
      setHide(false);
      return;
    }

    const timeout = setTimeout(() => {
      setHide(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, [inView, ref]);

  return (
    <Box
      ref={ref}
      sx={{
        /**
         * XXX: content item height avoid jumping on ui
         * but actually the content height is dynamic (based on content)
         */
        minHeight: '65px',
      }}
    >
      {hide ? <SkeletonItem /> : children}
    </Box>
  );
}

export default function ChannelLayout({ items, settings }: ChannelLayoutProps) {
  const { pinMessageItems } = useContext(ChannelLayoutContext);

  const displayItems = useMemo(() => {
    return [...(pinMessageItems || []), ...items];
  }, [pinMessageItems, items]);

  return (
    <Box sx={styles.root}>
      {displayItems.map((_, index) => {
        let itemData = _;
        if (settings?.reverseItems) {
          const revertIndex = displayItems.length - 1 - index;
          itemData = displayItems[revertIndex];
        }
        const { key, ...rest } = itemData;
        return (
          <LazyLoadContentItem key={key || itemData.id || `${index}`}>
            <ContentItem
              {...rest}
              hoverDisabled={settings?.hoverDisabled}
              size={settings?.size || 'md'}
            />
          </LazyLoadContentItem>
        );
      })}
    </Box>
  );
}

ChannelLayout.MessageActionArea = MessageActionArea;
ChannelLayout.MessageItem = MessageItem;
ChannelLayout.NotificationItem = NotificationItem;
