import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

export type BadgeNameTypes =
  | 'SevenDayStreak'
  | 'CompleteChallenge'
  | 'DailyGoal'
  | 'IvyLeage'
  | 'Math'
  | 'Nerd'
  | 'PerfectDreamWeek'
  | 'Poopoo'
  | 'Verbal'
  | 'Winner';

export type BadgeProps = {
  name: BadgeNameTypes;
  sx?: BoxProps['sx'];
  active?: boolean;
};

const BADGE_MAP = {
  SevenDayStreak: '7-day-streak',
  SevenDayStreakActive: '7-day-streak-active',
  CompleteChallenge: 'complete-challenge',
  CompleteChallengeActive: 'complete-challenge-active',
  DailyGoal: 'daily-goal',
  DailyGoalActive: 'daily-goal-active',
  IvyLeage: 'ivy-leage',
  IvyLeageActive: 'ivy-leage-active',
  Math: 'math',
  MathActive: 'math-active',
  Nerd: 'nerd',
  NerdActive: 'nerd-active',
  PerfectDreamWeek: 'perfect-dream-week',
  PerfectDreamWeekActive: 'perfect-dream-week-active',
  Poopoo: 'poopoo',
  PoopooActive: 'poopoo-active',
  Verbal: 'verbal',
  VerbalActive: 'verbal-active',
  Winner: 'winner',
  WinnerActive: 'winner-active',
};

const styles = {
  badge: {
    width: 52,
    height: 52,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
};
export default function Badge({ name, active, sx, ...rest }: BadgeProps) {
  const badgeKey = active ? `${name}Active` : name;
  const imagePath = `https://img-lp.earnaha.com/badge/${
    BADGE_MAP[badgeKey as keyof typeof BADGE_MAP]
  }.svg`;
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box
      sx={[styles.badge, { backgroundImage: `url(${imagePath})` }, ...sxProps]}
      {...rest}
    />
  );
}
