import { Box, Typography } from '@mui/material';
import { SquareAvatarGroup } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useMemberNftAvatarMultiple } from '@lib/ia/src/hooks/useMemberNftAvatarMultiple';

import { AlbumLayoutDefaultItem } from '../../types';

import IaAlbumBaseItem from './IaAlbumBaseItem';

const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  contentWrapper: {
    display: 'grid',
  },
  content: {
    fontSize: 30,
    fontWeight: 700,
  },
  contentSuffix: {
    fontSize: 16,
    fontWeight: 700,
  },
  avatarsWrapper: {
    pb: '4px',
  },
  userIdAvatars: {
    display: 'flex',
    alignItems: 'center',
    '& > div:not(:first-of-type)': {
      ml: -1,
    },
  },
  avatar: {
    mask: `url(/mask-avatar.svg) no-repeat center center`,
    maskSize: 'contain',
    backgroundColor: 'background.darker',
    p: '2px',
  },
};

type UserIdAvatarsProps = {
  userIds: { userId: string }[];
  size: number;
  max: number;
};

function UserIdAvatars({
  userIds: userIdObjects,
  size,
  max,
}: UserIdAvatarsProps) {
  const userIds = userIdObjects.flatMap((item) => item.userId);

  // calculate the displayed to reduce the data retrieved
  let displayed = Math.min(userIds.length, max);
  if (userIds.length > max) {
    displayed -= 1; // Leave the last place for "+x"
  }
  const avatarUrls = useMemberNftAvatarMultiple({
    userIds: userIds.slice(0, displayed),
  });

  return (
    <SquareAvatarGroup
      items={avatarUrls || []}
      max={max}
      maxIncludedMore
      size={size}
      spacing="-8px"
      moreType="avatar"
      moreTypographyVariant="body1"
      totalCount={userIds.length}
    />
  );
}

type IaAlbumDefaultItemProps = AlbumLayoutDefaultItem;

export default function IaAlbumDefaultItem(item: IaAlbumDefaultItemProps) {
  const {
    icon,
    content,
    contentSuffix,
    avatars = [],
    avatarSize = 30,
    maxDisplayNumber = 2,
    ...rest
  } = item;

  const avatarUrls: { name: string; avatarUrl: string }[] = [];
  const userIds: { userId: string }[] = [];

  avatars.forEach((avatar) => {
    if ('avatarUrl' in avatar) {
      avatarUrls.push(avatar);
    } else if ('userId' in avatar) {
      userIds.push(avatar);
    }
  });

  return (
    <IaAlbumBaseItem {...rest} item={item}>
      <Box sx={styles.root}>
        <Box sx={styles.contentWrapper}>
          {icon && <Icon name={icon} width={24} height={24} />}
          {content && (
            <Typography variant="h5" sx={styles.content}>
              {content}
              {contentSuffix && (
                <Typography component="span" sx={styles.contentSuffix}>
                  {contentSuffix}
                </Typography>
              )}
            </Typography>
          )}
        </Box>
        <Box sx={styles.avatarsWrapper}>
          {avatarUrls.length > 0 ? (
            <SquareAvatarGroup
              items={avatarUrls}
              max={maxDisplayNumber}
              maxIncludedMore
              size={avatarSize}
              spacing="-8px"
              moreType="avatar"
              moreTypographyVariant="body1"
            />
          ) : (
            <UserIdAvatars
              userIds={userIds}
              size={avatarSize}
              max={maxDisplayNumber}
            />
          )}
        </Box>
      </Box>
    </IaAlbumBaseItem>
  );
}
