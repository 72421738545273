import { ReactNode } from 'react';
import { alpha, Theme } from '@mui/material';
import Box from '@mui/material/Box';

import useTableSettings from '../../../hooks/useTableSettings';
import { GroupCell, TableLayoutGroupCell } from '../../../types';

import IaNumberGroupCell from './IaNumberGroupCell';
import IaTextGroupCell from './IaTextGroupCell';

export type IaGroupCellProps = {
  cell: TableLayoutGroupCell;
};

const styles = {
  root: {
    display: 'grid',
    minHeight: '32px',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'stretch',
  },
  nowrap: {
    height: '32px',
  },
  row: {
    display: 'grid',
    alignItems: 'center',
    minHeight: '32px',
  },
  rowNowrap: {
    height: '32px',
  },
  rowBorder: {
    ':not(:first-child)': {
      boxShadow: (theme: Theme) =>
        `inset 0 1px 0 0 ${alpha(theme.palette.text.primary, 0.1)}`,
    },
  },
  rowCellBorder: {
    '& .group-cell:not(:first-child)': {
      boxShadow: (theme: Theme) =>
        `inset 1px 0 0 0 ${alpha(theme.palette.text.primary, 0.1)}`,
    },
  },
};

type CellProps = {
  cell: GroupCell;
};

function Cell({ cell }: CellProps) {
  switch (cell.type) {
    case 'text':
      return <IaTextGroupCell cell={cell} />;
    case 'number':
      return <IaNumberGroupCell cell={cell} />;
    default:
      console.warn('Unknown body cell type:', cell);
      return null;
  }
}

type RowProps = {
  children: ReactNode;
  gridTemplateColumns: string;
};

function Row({ gridTemplateColumns, children }: RowProps) {
  const { wrap, layoutSetting } = useTableSettings();
  return (
    <Box
      sx={[
        styles.row,
        !wrap && styles.rowNowrap,
        !!layoutSetting?.showRowBorder && styles.rowBorder,
        !!layoutSetting?.showColumnBorder && styles.rowCellBorder,
        { gridTemplateColumns },
      ]}
    >
      {children}
    </Box>
  );
}

export default function IaGroupCell({ cell }: IaGroupCellProps) {
  const { gridTemplateColumns, rows } = cell;
  const { wrap } = useTableSettings();

  return (
    <Box sx={[styles.root, !wrap && styles.nowrap]}>
      {rows.map((row) => (
        <Row key={row.id} gridTemplateColumns={gridTemplateColumns}>
          {row.cells.map((groupCell, index) => (
            <Cell cell={groupCell} key={index} />
          ))}
        </Row>
      ))}
    </Box>
  );
}
