import React, { ReactNode } from 'react';

import { PracticeFlowEventContextProvider } from './contexts/practice-flow-event';
import { PracticeLayoutContextProvider } from './contexts/practice-layout';
import { PracticeQuestionContextProvider } from './contexts/practice-question';
import { PracticeQuestionAnswerContextProvider } from './contexts/practice-question-answer';
import { PracticeQuestionEventContextProvider } from './contexts/practice-question-event';
import { PracticeQuestionResultContextProvider } from './contexts/practice-question-result';
import { PracticeQuestionStatusContextProvider } from './contexts/practice-question-status';
import { PracticeStaticDataContextProvider } from './contexts/practice-static-data';
import { PracticeStatusContextProvider } from './contexts/practice-status';
import {
  PracticeFlowEventContextValue,
  PracticeLayoutContextValue,
  PracticeQuestionAnswerContextValue,
  PracticeQuestionContextValue,
  PracticeQuestionEventContextValue,
  PracticeQuestionResultContextValue,
  PracticeQuestionStatusContextValue,
  PracticeStaticDataContextValue,
  PracticeStatusContextValue,
} from './types';

type PracticeContextProviderProps = {
  children: ReactNode;
  status: PracticeStatusContextValue;
  question: PracticeQuestionContextValue;
  questionResult: Omit<PracticeQuestionResultContextValue, 'statistic'>;
  questionAnswers: PracticeQuestionAnswerContextValue['answers'];
  questionStatus: PracticeQuestionStatusContextValue;
  questionStatistic: PracticeQuestionResultContextValue['statistic'];
  eliminatedAnswers: PracticeQuestionAnswerContextValue['eliminatedAnswers'];
  questionEvents: PracticeQuestionEventContextValue;
  events: PracticeFlowEventContextValue;
  section: PracticeStaticDataContextValue['section'];
  mappedMaterials: PracticeStaticDataContextValue['mappedMaterials'];
  panelKeys: PracticeStaticDataContextValue['panelKeys'];
  preference: PracticeStaticDataContextValue['preference'];
  mode: PracticeStaticDataContextValue['mode'];
  layout: PracticeLayoutContextValue;
};

export default function PracticeContextProvider({
  children,
  status,
  question,
  questionResult,
  questionStatus,
  questionAnswers,
  questionStatistic,
  questionEvents,
  eliminatedAnswers,
  mappedMaterials,
  events,
  section,
  preference,
  panelKeys,
  mode,
  layout,
}: PracticeContextProviderProps) {
  return (
    <PracticeLayoutContextProvider layout={layout}>
      <PracticeStaticDataContextProvider
        section={section}
        mappedMaterials={mappedMaterials}
        preference={preference}
        panelKeys={panelKeys}
        mode={mode}
      >
        <PracticeStatusContextProvider status={status}>
          <PracticeQuestionContextProvider question={question}>
            <PracticeQuestionStatusContextProvider status={questionStatus}>
              <PracticeQuestionResultContextProvider
                result={questionResult}
                statistic={questionStatistic}
              >
                <PracticeQuestionAnswerContextProvider
                  answers={questionAnswers}
                  eliminatedAnswers={eliminatedAnswers}
                >
                  <PracticeFlowEventContextProvider events={events}>
                    <PracticeQuestionEventContextProvider
                      events={questionEvents}
                    >
                      {children}
                    </PracticeQuestionEventContextProvider>
                  </PracticeFlowEventContextProvider>
                </PracticeQuestionAnswerContextProvider>
              </PracticeQuestionResultContextProvider>
            </PracticeQuestionStatusContextProvider>
          </PracticeQuestionContextProvider>
        </PracticeStatusContextProvider>
      </PracticeStaticDataContextProvider>
    </PracticeLayoutContextProvider>
  );
}
