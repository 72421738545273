import React, { SVGAttributes } from 'react';

export default function EmojiMoneyBag({
  ...rest
}: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width="100"
      height="181"
      viewBox="0 0 100 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.8774 69.1346L20.1558 71.8163L36.8756 61.3348L31.5988 58.667L14.8774 69.1346Z"
        fill="#AD9875"
      />
      <path
        d="M14.8774 69.1346L20.1558 71.8163L36.8756 61.3348L31.5988 58.667L14.8774 69.1346Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M25.3843 66.5706L30.6627 69.2524L47.3824 58.7709L42.1056 56.103L25.3843 66.5706Z"
        fill="#B2883C"
      />
      <path
        d="M25.3843 66.5706L30.6627 69.2524L47.3824 58.7709L42.1056 56.103L25.3843 66.5706Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M20.1196 63.8558L25.398 66.5375L42.1178 56.056L36.841 53.3882L20.1196 63.8558Z"
        fill="#A1845D"
      />
      <path
        d="M20.1196 63.8558L25.398 66.5375L42.1178 56.056L36.841 53.3882L20.1196 63.8558Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M67.5024 167.657V159.702L84.2135 149.194V157.166L67.5024 167.657Z"
        fill="#79521F"
      />
      <path
        d="M67.5024 167.657V159.702L84.2135 149.194V157.166L67.5024 167.657Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M56.9731 82.5915V74.6362L73.6842 64.1284V72.0997L56.9731 82.5915Z"
        fill="#4E3116"
      />
      <path
        d="M56.9731 82.5915V74.6362L73.6842 64.1284V72.0997L56.9731 82.5915Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M56.9731 74.6544V66.6992L73.6842 56.1914V64.1627L56.9731 74.6544Z"
        fill="#8F6026"
      />
      <path
        d="M56.9731 74.6544V66.6992L73.6842 56.1914V64.1627L56.9731 74.6544Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M62.2378 53.4176V45.4624L78.9489 34.9546V42.9258L62.2378 53.4176Z"
        fill="#D4C2B0"
      />
      <path
        d="M62.2378 53.4176V45.4624L78.9489 34.9546V42.9258L62.2378 53.4176Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M62.2378 61.3547V53.3994L78.9489 42.8916V50.8629L62.2378 61.3547Z"
        fill="#B39865"
      />
      <path
        d="M62.2378 61.3547V53.3994L78.9489 42.8916V50.8629L62.2378 61.3547Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M62.2378 69.2912V61.3359L78.9489 50.8281V58.7994L62.2378 69.2912Z"
        fill="#AC854B"
      />
      <path
        d="M62.2378 69.2912V61.3359L78.9489 50.8281V58.7994L62.2378 69.2912Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M56.9731 42.7698L62.2515 45.4516L78.9713 34.9701L73.6945 32.3022L56.9731 42.7698Z"
        fill="#D4C2B0"
      />
      <path
        d="M56.9731 42.7698L62.2515 45.4516L78.9713 34.9701L73.6945 32.3022L56.9731 42.7698Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M56.9731 82.6048L62.2515 85.2866L78.9713 74.8051L73.6945 72.1372L56.9731 82.6048Z"
        fill="#B2883C"
      />
      <path
        d="M56.9731 82.6048L62.2515 85.2866L78.9713 74.8051L73.6945 72.1372L56.9731 82.6048Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M62.2378 85.3196L67.5162 88.0014L84.2359 77.5199L78.9591 74.8521L62.2378 85.3196Z"
        fill="#A1845D"
      />
      <path
        d="M62.2378 85.3196L67.5162 88.0014L84.2359 77.5199L78.9591 74.8521L62.2378 85.3196Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M46.4346 37.3758L51.713 40.0576L68.4327 29.5761L63.1559 26.9082L46.4346 37.3758Z"
        fill="#D8C79C"
      />
      <path
        d="M46.4346 37.3758L51.713 40.0576L68.4327 29.5761L63.1559 26.9082L46.4346 37.3758Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M51.6992 40.0906L56.9776 42.7724L73.6974 32.2909L68.4206 29.623L51.6992 40.0906Z"
        fill="#CBB68B"
      />
      <path
        d="M51.6992 40.0906L56.9776 42.7724L73.6974 32.2909L68.4206 29.623L51.6992 40.0906Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M35.8911 32.0443L41.1695 34.726L57.8893 24.2445L52.6125 21.5767L35.8911 32.0443Z"
        fill="#D5CBBD"
      />
      <path
        d="M35.8911 32.0443L41.1695 34.726L57.8893 24.2445L52.6125 21.5767L35.8911 32.0443Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M25.4043 26.6795L30.6827 29.3613L47.4025 18.8798L42.1256 16.2119L25.4043 26.6795Z"
        fill="#D4C2B0"
      />
      <path
        d="M25.4043 26.6795L30.6827 29.3613L47.4025 18.8798L42.1256 16.2119L25.4043 26.6795Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M41.1333 34.7034L46.4117 37.3852L63.1315 26.9037L57.8546 24.2358L41.1333 34.7034Z"
        fill="#D8C79C"
      />
      <path
        d="M41.1333 34.7034L46.4117 37.3852L63.1315 26.9037L57.8546 24.2358L41.1333 34.7034Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M30.6689 29.3944L35.9473 32.0761L52.6671 21.5946L47.3903 18.9268L30.6689 29.3944Z"
        fill="#DCD1BD"
      />
      <path
        d="M30.6689 29.3944L35.9473 32.0761L52.6671 21.5946L47.3903 18.9268L30.6689 29.3944Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M67.5024 95.967V88.0117L84.2135 77.5039V85.4752L67.5024 95.967Z"
        fill="#A1845D"
      />
      <path
        d="M67.5024 95.967V88.0117L84.2135 77.5039V85.4752L67.5024 95.967Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M72.751 106.577V98.6216L89.462 88.1138V96.085L72.751 106.577Z"
        fill="#AD9875"
      />
      <path
        d="M72.751 106.577V98.6216L89.462 88.1138V96.085L72.751 106.577Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M72.751 114.557V106.602L89.462 96.0942V104.065L72.751 114.557Z"
        fill="#8F6E31"
      />
      <path
        d="M72.751 114.557V106.602L89.462 96.0942V104.065L72.751 114.557Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M72.751 122.551V114.596L89.462 104.088V112.059L72.751 122.551Z"
        fill="#9C772F"
      />
      <path
        d="M72.751 122.551V114.596L89.462 104.088V112.059L72.751 122.551Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M72.751 130.51V122.555L89.462 112.047V120.018L72.751 130.51Z"
        fill="#B28C3B"
      />
      <path
        d="M72.751 130.51V122.555L89.462 112.047V120.018L72.751 130.51Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M72.751 138.5V130.544L89.462 120.037V128.008L72.751 138.5Z"
        fill="#BD9740"
      />
      <path
        d="M72.751 138.5V130.544L89.462 120.037V128.008L72.751 138.5Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M72.751 146.489V138.534L89.462 128.026V135.998L72.751 146.489Z"
        fill="#B38F3B"
      />
      <path
        d="M72.751 146.489V138.534L89.462 128.026V135.998L72.751 146.489Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M72.751 154.479V146.524L89.462 136.016V143.987L72.751 154.479Z"
        fill="#9C772F"
      />
      <path
        d="M72.751 154.479V146.524L89.462 136.016V143.987L72.751 154.479Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M72.751 162.468V154.513L89.462 144.005V151.977L72.751 162.468Z"
        fill="#7C5B27"
      />
      <path
        d="M72.751 162.468V154.513L89.462 144.005V151.977L72.751 162.468Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M67.5024 95.9764L72.7808 98.6581L89.5006 88.1766L84.2238 85.5088L67.5024 95.9764Z"
        fill="#AD9875"
      />
      <path
        d="M67.5024 95.9764L72.7808 98.6581L89.5006 88.1766L84.2238 85.5088L67.5024 95.9764Z"
        fill="black"
        fillOpacity="0.3"
      />
      <g clipPath="url(#clip0_3351_237912)">
        <path
          d="M72.7609 154.413L67.4985 151.732V159.701L72.7609 162.382V154.413Z"
          fill="#7C5B27"
        />
        <path
          d="M67.4982 151.732L62.2358 149.05V157.019L67.4982 159.7V151.732Z"
          fill="#B28839"
        />
        <path
          d="M67.4982 159.7L62.2358 157.019V164.988L67.4982 167.669V159.7Z"
          fill="#79521F"
        />
        <path
          d="M62.2365 149.05L56.9741 146.369V154.338L62.2365 157.019V149.05Z"
          fill="#E0C053"
        />
        <path
          d="M62.2365 157.019L56.9741 154.338V162.306L62.2365 164.988V157.019Z"
          fill="#99702D"
        />
        <path
          d="M56.9738 146.369L51.7114 143.688V151.657L56.9738 154.338V146.369Z"
          fill="#E7CA60"
        />
        <path
          d="M56.9738 154.338L51.7114 151.656V159.625L56.9738 162.306V154.338Z"
          fill="#B2893A"
        />
        <path
          d="M51.7116 143.688L46.4492 141.006V148.975L51.7116 151.656V143.688Z"
          fill="#F2DA78"
        />
        <path
          d="M51.7116 151.656L46.4492 148.975V156.944L51.7116 159.625V151.656Z"
          fill="#AD8536"
        />
        <path
          d="M41.1867 138.325L35.9243 135.644V143.613L41.1867 146.294V138.325Z"
          fill="#F2DA78"
        />
        <path
          d="M41.1867 146.294L35.9243 143.612V151.581L41.1867 154.262V146.294Z"
          fill="#AD8536"
        />
        <path
          d="M35.924 135.644L30.6616 132.962V140.931L35.924 143.612V135.644Z"
          fill="#E7CA60"
        />
        <path
          d="M35.924 143.612L30.6616 140.931V148.9L35.924 151.581V143.612Z"
          fill="#B2893A"
        />
        <path
          d="M30.6622 132.963L25.3999 130.281V138.25L30.6622 140.931V132.963Z"
          fill="#E0C053"
        />
        <path
          d="M30.6622 140.931L25.3999 138.25V146.219L30.6622 148.9V140.931Z"
          fill="#99702D"
        />
        <path
          d="M25.3995 130.281L20.1372 127.6V135.569L25.3995 138.25V130.281Z"
          fill="#B28839"
        />
        <path
          d="M25.3995 138.25L20.1372 135.569V143.537L25.3995 146.219V138.25Z"
          fill="#79521F"
        />
        <path
          d="M20.1373 127.6L14.875 124.919V132.888L20.1373 135.569V127.6Z"
          fill="#7C5B27"
        />
        <path
          d="M72.7609 146.444L67.4985 143.763V151.732L72.7609 154.413V146.444Z"
          fill="#9C772F"
        />
        <path
          d="M67.4982 143.763L62.2358 141.082V149.051L67.4982 151.732V143.763Z"
          fill="#CEAB43"
        />
        <path
          d="M62.2365 141.082L56.9741 138.4V146.369L62.2365 149.05V141.082Z"
          fill="#E5CB62"
        />
        <path
          d="M56.9738 138.401L51.7114 135.719V143.688L56.9738 146.369V138.401Z"
          fill="#3E3638"
        />
        <path
          d="M51.7116 135.719L46.4492 133.038V141.007L51.7116 143.688V135.719Z"
          fill="#4C413F"
        />
        <path
          d="M41.1867 130.357L35.9243 127.675V135.644L41.1867 138.325V130.357Z"
          fill="#473D3C"
        />
        <path
          d="M35.924 127.675L30.6616 124.994V132.963L35.924 135.644V127.675Z"
          fill="#3E3738"
        />
        <path
          d="M30.6622 124.994L25.3999 122.312V130.281L30.6622 132.962V124.994Z"
          fill="#E5CB62"
        />
        <path
          d="M25.3995 122.313L20.1372 119.631V127.6L25.3995 130.281V122.313Z"
          fill="#CEAB43"
        />
        <path
          d="M20.1373 119.631L14.875 116.95V124.919L20.1373 127.6V119.631Z"
          fill="#9C772F"
        />
        <path
          d="M72.7609 138.476L67.4985 135.794V143.763L72.7609 146.444V138.476Z"
          fill="#B38F3B"
        />
        <path
          d="M67.4982 135.795L62.2358 133.113V141.082L67.4982 143.763V135.795Z"
          fill="#D7B750"
        />
        <path
          d="M62.2365 133.113L56.9741 130.432V138.401L62.2365 141.082V133.113Z"
          fill="#EFD674"
        />
        <path
          d="M56.9738 130.432L51.7114 127.75V135.719L56.9738 138.4V130.432Z"
          fill="#42383A"
        />
        <path
          d="M51.7116 127.751L46.4492 125.069V133.038L51.7116 135.719V127.751Z"
          fill="#EFD674"
        />
        <path
          d="M41.1867 122.388L35.9243 119.707V127.675L41.1867 130.356V122.388Z"
          fill="#F1D578"
        />
        <path
          d="M35.924 119.707L30.6616 117.025V124.994L35.924 127.675V119.707Z"
          fill="#3E3738"
        />
        <path
          d="M30.6622 117.026L25.3999 114.344V122.313L30.6622 124.994V117.026Z"
          fill="#EFD674"
        />
        <path
          d="M25.3995 114.344L20.1372 111.663V119.631L25.3995 122.312V114.344Z"
          fill="#D7B750"
        />
        <path
          d="M20.1373 111.663L14.875 108.981V116.95L20.1373 119.631V111.663Z"
          fill="#B38F3B"
        />
        <path
          d="M72.7609 130.507L67.4985 127.826V135.795L72.7609 138.476V130.507Z"
          fill="#BD9740"
        />
        <path
          d="M67.4982 127.826L62.2358 125.145V133.113L67.4982 135.794V127.826Z"
          fill="#D3B051"
        />
        <path
          d="M62.2365 125.145L56.9741 122.463V130.432L62.2365 133.113V125.145Z"
          fill="#ECD473"
        />
        <path
          d="M56.9738 122.464L51.7114 119.782V127.751L56.9738 130.432V122.464Z"
          fill="#473F40"
        />
        <path
          d="M51.7116 119.782L46.4492 117.101V125.069L51.7116 127.75V119.782Z"
          fill="#493F42"
        />
        <path
          d="M41.1867 114.42L35.9243 111.738V119.707L41.1867 122.388V114.42Z"
          fill="#EDD377"
        />
        <path
          d="M35.924 111.738L30.6616 109.057V117.025L35.924 119.707V111.738Z"
          fill="#EDD377"
        />
        <path
          d="M30.6622 109.057L25.3999 106.375V114.344L30.6622 117.025V109.057Z"
          fill="#ECD473"
        />
        <path
          d="M25.3995 106.376L20.1372 103.694V111.663L25.3995 114.344V106.376Z"
          fill="#D3B051"
        />
        <path
          d="M20.1373 103.694L14.875 101.013V108.982L20.1373 111.663V103.694Z"
          fill="#BD9740"
        />
        <path
          d="M72.7609 122.539L67.4985 119.857V127.826L72.7609 130.507V122.539Z"
          fill="#B28C3B"
        />
        <path
          d="M67.4982 119.858L62.2358 117.176V125.145L67.4982 127.826V119.858Z"
          fill="#CFAB4D"
        />
        <path
          d="M62.2365 117.176L56.9741 114.495V122.463L62.2365 125.145V117.176Z"
          fill="#EBD375"
        />
        <path
          d="M56.9738 114.495L51.7114 111.813V119.782L56.9738 122.463V114.495Z"
          fill="#ECD375"
        />
        <path
          d="M51.7116 111.814L46.4492 109.132V117.101L51.7116 119.782V111.814Z"
          fill="#E3CB72"
        />
        <path
          d="M41.1867 106.451L35.9243 103.77V111.738L41.1867 114.419V106.451Z"
          fill="#463E43"
        />
        <path
          d="M35.924 103.77L30.6616 101.088V109.057L35.924 111.738V103.77Z"
          fill="#484041"
        />
        <path
          d="M30.6622 101.088L25.3999 98.4067V106.375L30.6622 109.057V101.088Z"
          fill="#EBD375"
        />
        <path
          d="M25.3995 98.4069L20.1372 95.7256V103.694L25.3995 106.375V98.4069Z"
          fill="#CFAB4D"
        />
        <path
          d="M20.1373 95.7257L14.875 93.0444V101.013L20.1373 103.694V95.7257Z"
          fill="#B28C3B"
        />
        <path
          d="M72.7609 114.57L67.4985 111.889V119.857L72.7609 122.539V114.57Z"
          fill="#9C772F"
        />
        <path
          d="M67.4982 111.889L62.2358 109.208V117.176L67.4982 119.857V111.889Z"
          fill="#CBA74A"
        />
        <path
          d="M62.2365 109.208L56.9741 106.526V114.495L62.2365 117.176V109.208Z"
          fill="#EAD270"
        />
        <path
          d="M56.9738 106.526L51.7114 103.845V111.813L56.9738 114.495V106.526Z"
          fill="#42383D"
        />
        <path
          d="M51.7116 103.845L46.4492 101.164V109.132L51.7116 111.813V103.845Z"
          fill="#EED674"
        />
        <path
          d="M41.1867 98.4821L35.9243 95.8008V103.769L41.1867 106.451V98.4821Z"
          fill="#F2D579"
        />
        <path
          d="M35.924 95.8009L30.6616 93.1196V101.088L35.924 103.77V95.8009Z"
          fill="#453D3F"
        />
        <path
          d="M30.6622 93.1198L25.3999 90.4385V98.4071L30.6622 101.088V93.1198Z"
          fill="#EAD270"
        />
        <path
          d="M25.3995 90.4381L20.1372 87.7568V95.7254L25.3995 98.4067V90.4381Z"
          fill="#CBA74A"
        />
        <path
          d="M20.1373 87.757L14.875 85.0757V93.0443L20.1373 95.7256V87.757Z"
          fill="#9C772F"
        />
        <path
          d="M72.7609 106.601L67.4985 103.92V111.889L72.7609 114.57V106.601Z"
          fill="#8F6E31"
        />
        <path
          d="M67.4982 103.92L62.2358 101.239V109.207L67.4982 111.889V103.92Z"
          fill="#CCA748"
        />
        <path
          d="M62.2365 101.239L56.9741 98.5576V106.526L62.2365 109.208V101.239Z"
          fill="#E7D16D"
        />
        <path
          d="M56.9738 98.5578L51.7114 95.8765V103.845L56.9738 106.526V98.5578Z"
          fill="#3F3738"
        />
        <path
          d="M51.7116 95.8761L46.4492 93.1948V101.163L51.7116 103.845V95.8761Z"
          fill="#41393B"
        />
        <path
          d="M41.1867 90.5138L35.9243 87.8325V95.8011L41.1867 98.4824V90.5138Z"
          fill="#43373B"
        />
        <path
          d="M35.924 87.8322L30.6616 85.1509V93.1195L35.924 95.8008V87.8322Z"
          fill="#423839"
        />
        <path
          d="M30.6622 85.151L25.3999 82.4697V90.4383L30.6622 93.1196V85.151Z"
          fill="#E7D16D"
        />
        <path
          d="M25.3995 82.4699L20.1372 79.7886V87.7572L25.3995 90.4385V82.4699Z"
          fill="#CCA748"
        />
        <path
          d="M20.1373 79.7882L14.875 77.1069V85.0755L20.1373 87.7568V79.7882Z"
          fill="#8F6E31"
        />
        <path
          d="M72.7609 98.633L67.4985 95.9517V103.92L72.7609 106.602V98.633Z"
          fill="#AD9875"
        />
        <path
          d="M67.4982 95.9513L62.2358 93.27V101.239L67.4982 103.92V95.9513Z"
          fill="#AD8B35"
        />
        <path
          d="M62.2365 93.2702L56.9741 90.5889V98.5575L62.2365 101.239V93.2702Z"
          fill="#D7BF59"
        />
        <path
          d="M56.9738 90.589L51.7114 87.9077V95.8763L56.9738 98.5576V90.589Z"
          fill="#E9D26F"
        />
        <path
          d="M51.7116 87.9079L46.4492 85.2266V93.1952L51.7116 95.8765V87.9079Z"
          fill="#D6B158"
        />
        <path
          d="M41.1867 82.5451L35.9243 79.8638V87.8324L41.1867 90.5137V82.5451Z"
          fill="#EFD677"
        />
        <path
          d="M35.924 79.8639L30.6616 77.1826V85.1512L35.924 87.8325V79.8639Z"
          fill="#E6C961"
        />
        <path
          d="M30.6622 77.1823L25.3999 74.501V82.4696L30.6622 85.1509V77.1823Z"
          fill="#DABC53"
        />
        <path
          d="M25.3995 74.5011L20.1372 71.8198V79.7884L25.3995 82.4697V74.5011Z"
          fill="#B69137"
        />
        <path
          d="M20.1373 71.82L14.875 69.1387V77.1073L20.1373 79.7886V71.82Z"
          fill="#AD9875"
        />
        <path
          d="M67.4982 87.9831L62.2358 85.3018V93.2704L67.4982 95.9517V87.9831Z"
          fill="#A1845D"
        />
        <path
          d="M62.2365 85.3014L56.9741 82.6201V90.5887L62.2365 93.27V85.3014Z"
          fill="#B2883C"
        />
        <path
          d="M56.9738 82.6203L51.7114 79.939V87.9076L56.9738 90.5889V82.6203Z"
          fill="#B68D38"
        />
        <path
          d="M51.7116 79.9391L46.4492 77.2578V85.2264L51.7116 87.9077V79.9391Z"
          fill="#D8B44B"
        />
        <path
          d="M41.1867 74.5763L35.9243 71.895V79.8636L41.1867 82.5449V74.5763Z"
          fill="#D8B44B"
        />
        <path
          d="M35.924 71.8952L30.6616 69.2139V77.1825L35.924 79.8638V71.8952Z"
          fill="#B68D38"
        />
        <path
          d="M30.6622 69.214L25.3999 66.5327V74.5013L30.6622 77.1826V69.214Z"
          fill="#B2883C"
        />
        <path
          d="M25.3995 66.5324L20.1372 63.8511V71.8197L25.3995 74.501V66.5324Z"
          fill="#A1845D"
        />
        <path
          d="M56.9738 74.6515L51.7114 71.9702V79.9388L56.9738 82.6201V74.6515Z"
          fill="#4E3116"
        />
        <path
          d="M51.7116 71.9704L46.4492 69.2891V77.2577L51.7116 79.939V71.9704Z"
          fill="#5B3C1F"
        />
        <path
          d="M41.1867 66.6081L35.9243 63.9268V71.8954L41.1867 74.5767V66.6081Z"
          fill="#5B3C1F"
        />
        <path
          d="M35.924 63.9264L30.6616 61.2451V69.2137L35.924 71.895V63.9264Z"
          fill="#4E3116"
        />
        <path
          d="M56.9738 66.6832L51.7114 64.002V71.9706L56.9738 74.6519V66.6832Z"
          fill="#8F6026"
        />
        <path
          d="M51.7116 64.0021L46.4492 61.3208V69.2894L51.7116 71.9707V64.0021Z"
          fill="#B37F3B"
        />
        <path
          d="M41.1867 58.6393L35.9243 55.958V63.9266L41.1867 66.6079V58.6393Z"
          fill="#996935"
        />
        <path
          d="M35.924 55.9582L30.6616 53.2769V61.2455L35.924 63.9268V55.9582Z"
          fill="#885A24"
        />
        <path
          d="M62.2365 61.3957L56.9741 58.7144V66.683L62.2365 69.3643V61.3957Z"
          fill="#AC854B"
        />
        <path
          d="M56.9738 58.7145L51.7114 56.0332V64.0018L56.9738 66.6831V58.7145Z"
          fill="#B48537"
        />
        <path
          d="M51.7116 56.0333L46.4492 53.3521V61.3207L51.7116 64.0019V56.0333Z"
          fill="#D6A952"
        />
        <path
          d="M41.1867 50.6706L35.9243 47.9893V55.9579L41.1867 58.6392V50.6706Z"
          fill="#A17134"
        />
        <path
          d="M35.924 47.9894L30.6616 45.3081V53.2767L35.924 55.958V47.9894Z"
          fill="#BC8C41"
        />
        <path
          d="M30.6622 45.3082L25.3999 42.627V50.5956L30.6622 53.2768V45.3082Z"
          fill="#AC854B"
        />
        <path
          d="M62.2365 53.4274L56.9741 50.7461V58.7147L62.2365 61.396V53.4274Z"
          fill="#B39865"
        />
        <path
          d="M56.9738 50.7457L51.7114 48.0645V56.0331L56.9738 58.7144V50.7457Z"
          fill="#C5A05C"
        />
        <path
          d="M51.7116 48.0646L46.4492 45.3833V53.3519L51.7116 56.0332V48.0646Z"
          fill="#D9C16E"
        />
        <path
          d="M41.1867 42.7023L35.9243 40.021V47.9896L41.1867 50.6709V42.7023Z"
          fill="#9E833E"
        />
        <path
          d="M35.924 40.0207L30.6616 37.3394V45.308L35.924 47.9893V40.0207Z"
          fill="#D4BE6A"
        />
        <path
          d="M30.6622 37.3395L25.3999 34.6582V42.6268L30.6622 45.3081V37.3395Z"
          fill="#B59560"
        />
        <path
          d="M62.2365 45.4586L56.9741 42.7773V50.7459L62.2365 53.4272V45.4586Z"
          fill="#D4C2B0"
        />
        <path
          d="M56.9738 42.7775L51.7114 40.0962V48.0648L56.9738 50.7461V42.7775Z"
          fill="#CBB68B"
        />
        <path
          d="M51.7116 40.0958L46.4492 37.4146V45.3832L51.7116 48.0644V40.0958Z"
          fill="#D8C79C"
        />
        <path
          d="M41.1867 34.7335L35.9243 32.0522V40.0208L41.1867 42.7021V34.7335Z"
          fill="#D5CBBD"
        />
        <path
          d="M35.924 32.0524L30.6616 29.3711V37.3397L35.924 40.021V32.0524Z"
          fill="#DCD1BD"
        />
        <path
          d="M30.6622 29.3707L25.3999 26.6895V34.6581L30.6622 37.3394V29.3707Z"
          fill="#D4C2B0"
        />
        <path
          d="M46.4489 141.006L41.1865 138.325V146.294L46.4489 148.975V141.006Z"
          fill="#4C413F"
        />
        <path
          d="M46.4489 148.975L41.1865 146.294V154.263L46.4489 156.944V148.975Z"
          fill="#AD8536"
        />
        <path
          d="M46.4489 133.038L41.1865 130.356V138.325L46.4489 141.006V133.038Z"
          fill="#4C413F"
        />
        <path
          d="M46.4489 125.069L41.1865 122.388V130.357L46.4489 133.038V125.069Z"
          fill="#463E43"
        />
        <path
          d="M46.4489 117.101L41.1865 114.419V122.388L46.4489 125.069V117.101Z"
          fill="#463E43"
        />
        <path
          d="M46.4489 109.132L41.1865 106.451V114.419L46.4489 117.101V109.132Z"
          fill="#463E43"
        />
        <path
          d="M46.4489 101.164L41.1865 98.4824V106.451L46.4489 109.132V101.164Z"
          fill="#463E43"
        />
        <path
          d="M46.4489 93.195L41.1865 90.5137V98.4823L46.4489 101.164V93.195Z"
          fill="#41393B"
        />
        <path
          d="M46.4489 85.2262L41.1865 82.5449V90.5135L46.4489 93.1948V85.2262Z"
          fill="#41393B"
        />
        <path
          d="M46.4489 77.258L41.1865 74.5767V82.5453L46.4489 85.2266V77.258Z"
          fill="#D8B44B"
        />
        <path
          d="M46.4489 69.2892L41.1865 66.6079V74.5765L46.4489 77.2578V69.2892Z"
          fill="#5B3C1F"
        />
        <path
          d="M46.4489 61.3205L41.1865 58.6392V66.6078L46.4489 69.2891V61.3205Z"
          fill="#B37F3B"
        />
        <path
          d="M46.4489 53.3522L41.1865 50.6709V58.6395L46.4489 61.3208V53.3522Z"
          fill="#D6A952"
        />
        <path
          d="M46.4489 45.3834L41.1865 42.7021V50.6708L46.4489 53.352V45.3834Z"
          fill="#D9C16E"
        />
        <path
          d="M46.4489 37.4147L41.1865 34.7334V42.702L46.4489 45.3833V37.4147Z"
          fill="#D8C79C"
        />
      </g>
      <defs>
        <clipPath id="clip0_3351_237912">
          <rect
            width="88.5909"
            height="119.529"
            fill="white"
            transform="matrix(0.891007 0.453991 0 1 4.3501 15.9644)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
