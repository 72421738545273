import { MutableRefObject, useEffect } from 'react';
import { silentCaptureException } from '@front/helper';
import { WatchingEvent, WatchingView } from '@lib/web/thread';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { isChannelInTheSameFilter } from '@lib/web/thread/utils/channelUtils';
import { Channel, Event, StreamChat } from 'stream-chat';

const safeGetEventChannel = async (
  event: Event,
  chatClient: StreamChat<StreamChatGenerics>
) => {
  if (!event.channel_type || !event.channel_id) return null;

  try {
    const eventChannel = chatClient.channel(
      event.channel_type,
      event.channel_id
    );
    return eventChannel;
  } catch (e) {
    silentCaptureException(`failed to query event channel in ${event}`, e);
    return null;
  }
};

const updateViewChannelsWhenNewEventChannelComesIn = ({
  eventChannel,
  watchingViewsRef,
}: {
  /**
   * XXX:
   * be careful, event channel might not be a full channel data,
   * we can query it again by eventChannel.query({}),
   * but that will increase the risk of 429 too many request,
   * so now we prevent it by not querying the channel again
   */
  eventChannel: Channel<StreamChatGenerics>;
  watchingViewsRef: MutableRefObject<WatchingView[]>;
}) => {
  /**
   * every channel supposed to have at least one message,
   * when we receive a channel without any message, which means the channel is still in the progress of creating,
   * so we don't need to reload the channel
   * another event (message.new) will be triggered when the channel is fully created
   */
  if (eventChannel.state.messages.length === 0) return;

  watchingViewsRef.current.forEach((watchingView) => {
    if (!isChannelInTheSameFilter(eventChannel, watchingView.filters)) return;

    watchingView.setChannels((channels) => {
      const hasEventChannelInside = channels.some(
        (c) => c.cid === eventChannel.cid
      );

      return [
        ...(hasEventChannelInside ? [] : [eventChannel]),
        ...channels.map((c) => (c.cid !== eventChannel.cid ? c : eventChannel)),
      ];
    });
  });
};

const useWatchNotificationAddedToChannel = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const eventChannel = await safeGetEventChannel(event, chatClient);
      if (!eventChannel) return;

      updateViewChannelsWhenNewEventChannelComesIn({
        eventChannel,
        watchingViewsRef,
      });

      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('notification.added_to_channel', handleEvent);

    return () => {
      chatClient.off('notification.added_to_channel', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};

const useWatchNotificationRemovedFromChannel = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const eventChannel = await safeGetEventChannel(event, chatClient);
      if (!eventChannel) return;

      watchingViewsRef.current.forEach((watchingView) => {
        if (!isChannelInTheSameFilter(eventChannel, watchingView.filters))
          return;

        watchingView.setChannels((channels) => [
          ...channels.filter((c) => c.cid !== eventChannel.cid),
        ]);
      });

      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('notification.removed_from_channel', handleEvent);

    return () => {
      chatClient.off('notification.removed_from_channel', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};

const useWatchNotificationMarkRead = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const eventChannel = await safeGetEventChannel(event, chatClient);

      if (!eventChannel) return;

      watchingViewsRef.current.forEach((watchingView) => {
        if (!isChannelInTheSameFilter(eventChannel, watchingView.filters))
          return;

        watchingView.setChannels((channels) => {
          const hasEventChannelInside = channels.some(
            (c) => c.cid === eventChannel.cid
          );

          return [
            /**
             * XXX:
             * when a new channel is created by ourselves, the channel is new,
             * and the most convenient way to listen to it is to add it is this 'markRead' event
             * because we immediately markRead when create new channel
             */
            ...(hasEventChannelInside ? [] : [eventChannel]),

            ...channels.map((c) =>
              c.cid !== eventChannel.cid ? c : eventChannel
            ),
          ];
        });
      });

      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('notification.mark_read', handleEvent);

    return () => {
      chatClient.off('notification.mark_read', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};

const useWatchNotificationChannelDeleted = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const eventChannel = await safeGetEventChannel(event, chatClient);

      if (!eventChannel) return;

      watchingViewsRef.current.forEach((watchingView) => {
        if (!isChannelInTheSameFilter(eventChannel, watchingView.filters))
          return;

        watchingView.setChannels((channels) => [
          ...channels.filter((c) => c.cid !== eventChannel.cid),
        ]);
      });

      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('notification.channel_deleted', handleEvent);

    return () => {
      chatClient.off('notification.channel_deleted', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};

const useWatchMessageNew = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const eventChannel = await safeGetEventChannel(event, chatClient);
      if (!eventChannel) return;
      updateViewChannelsWhenNewEventChannelComesIn({
        eventChannel,
        watchingViewsRef,
      });

      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('message.new', handleEvent);

    return () => {
      chatClient.off('message.new', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};

const useWatchMessageUpdated = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('message.updated', handleEvent);

    return () => {
      chatClient.off('message.updated', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};

const useWatchNotificationMessageNew = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const eventChannel = await safeGetEventChannel(event, chatClient);
      if (!eventChannel) return;

      updateViewChannelsWhenNewEventChannelComesIn({
        eventChannel,
        watchingViewsRef,
      });

      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('notification.message_new', handleEvent);

    return () => {
      chatClient.off('notification.message_new', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};

const useWatchChannelUpdated = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const eventChannel = await safeGetEventChannel(event, chatClient);
      if (!eventChannel) return;

      updateViewChannelsWhenNewEventChannelComesIn({
        eventChannel,
        watchingViewsRef,
      });

      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('channel.updated', handleEvent);

    return () => {
      chatClient.off('channel.updated', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};

const useWatchMemberAdded = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const eventChannel = await safeGetEventChannel(event, chatClient);
      if (!eventChannel) return;

      updateViewChannelsWhenNewEventChannelComesIn({
        eventChannel,
        watchingViewsRef,
      });

      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('member.added', handleEvent);

    return () => {
      chatClient.off('member.added', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};
const useWatchMemberRemoved = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const eventChannel = await safeGetEventChannel(event, chatClient);

      if (!eventChannel) return;

      watchingViewsRef.current.forEach((watchingView) => {
        const isInTheSameFilter = isChannelInTheSameFilter(
          eventChannel,
          watchingView.filters
        );
        const hasEventChannelInside = watchingView.channels.some(
          (c) => c.cid === eventChannel.cid
        );

        if (isInTheSameFilter && !hasEventChannelInside) {
          watchingView.reloadChannels();
        }
      });

      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('member.removed', handleEvent);

    return () => {
      chatClient.off('member.removed', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};

const useWatchChannelDeleted = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const eventChannel = await safeGetEventChannel(event, chatClient);

      if (!eventChannel) return;

      watchingViewsRef.current.forEach((watchingView) => {
        const isInTheSameFilter = isChannelInTheSameFilter(
          eventChannel,
          watchingView.filters
        );
        const hasEventChannelInside = watchingView.channels.some(
          (c) => c.cid === eventChannel.cid
        );

        if (isInTheSameFilter && !hasEventChannelInside) {
          watchingView.setChannels((channels) => [
            ...channels.filter((c) => c.cid !== eventChannel.cid),
          ]);
        }
      });

      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('channel.deleted', handleEvent);

    return () => {
      chatClient.off('channel.deleted', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};

const useWatchMessageDeleted = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat<StreamChatGenerics> | null
) => {
  useEffect(() => {
    if (!chatClient) return;

    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const eventChannel = await safeGetEventChannel(event, chatClient);
      if (!eventChannel) return;

      updateViewChannelsWhenNewEventChannelComesIn({
        eventChannel,
        watchingViewsRef,
      });

      watchingEventsRef.current.forEach((watchingEvent) => {
        watchingEvent.callback(event);
      });
    };

    chatClient.on('message.deleted', handleEvent);

    return () => {
      chatClient.off('message.deleted', handleEvent);
    };
  }, [chatClient, watchingEventsRef, watchingViewsRef]);
};

export const useWatchingThreadEvents = (
  watchingViewsRef: MutableRefObject<WatchingView[]>,
  watchingEventsRef: MutableRefObject<WatchingEvent[]>,
  chatClient?: StreamChat | null
) => {
  useWatchNotificationAddedToChannel(
    watchingViewsRef,
    watchingEventsRef,
    chatClient
  );

  useWatchNotificationRemovedFromChannel(
    watchingViewsRef,
    watchingEventsRef,
    chatClient
  );

  useWatchNotificationMessageNew(
    watchingViewsRef,
    watchingEventsRef,
    chatClient
  );

  useWatchNotificationChannelDeleted(
    watchingViewsRef,
    watchingEventsRef,
    chatClient
  );

  useWatchNotificationMarkRead(watchingViewsRef, watchingEventsRef, chatClient);
  useWatchMessageNew(watchingViewsRef, watchingEventsRef, chatClient);
  useWatchMessageUpdated(watchingViewsRef, watchingEventsRef, chatClient);
  useWatchChannelUpdated(watchingViewsRef, watchingEventsRef, chatClient);
  useWatchMemberAdded(watchingViewsRef, watchingEventsRef, chatClient);
  useWatchMemberRemoved(watchingViewsRef, watchingEventsRef, chatClient);
  useWatchChannelDeleted(watchingViewsRef, watchingEventsRef, chatClient);
  useWatchMessageDeleted(watchingViewsRef, watchingEventsRef, chatClient);
};
