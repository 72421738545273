import React from 'react';

export default function OtherLightbulb({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.6 17.8333H10.4C10.1792 17.8333 10 18.02 10 18.25C10 18.48 10.1792 18.6667 10.4 18.6667H13.6C13.8208 18.6667 14 18.48 14 18.25C14 18.02 13.8208 17.8333 13.6 17.8333ZM13.6 19.5H10.4C10.1792 19.5 10 19.6867 10 19.9167C10 20.1467 10.1792 20.3333 10.4 20.3333H13.6C13.8208 20.3333 14 20.1467 14 19.9167C14 19.6867 13.8208 19.5 13.6 19.5ZM13.8 21.1667H10.2L11.1504 21.8183C11.2736 21.9333 11.4544 22 11.6424 22H12.3584C12.5456 22 12.7272 21.9333 12.8504 21.8183L13.8 21.1667ZM16.8 9.6675C16.8 12.6417 14.2288 14.6533 14.2288 17H9.7712C9.7712 14.6533 7.2 12.6417 7.2 9.6675C7.2 6.565 9.5984 4.8575 11.9976 4.8575C14.3984 4.8575 16.8 6.56667 16.8 9.6675ZM20 9.09167V10.235H17.9696C17.9856 10.0492 18 9.86167 18 9.6675C18 9.47 17.9864 9.28083 17.972 9.0925H20V9.09167ZM11.4816 3.63083V2H12.5784V3.63667C12.3848 3.61833 12.1912 3.6075 11.9976 3.6075C11.8256 3.6075 11.6536 3.61583 11.4816 3.63083ZM15.8984 4.9175L17.1544 3.465L17.9696 4.23L16.6872 5.71083C16.4872 5.46333 16.2128 5.15083 15.8984 4.9175ZM7.308 5.71083L6.0256 4.23L6.8408 3.465L8.0968 4.9175C7.7832 5.15083 7.508 5.46333 7.308 5.71083ZM6.0304 10.235H4V9.09167H6.028C6.0136 9.28 6 9.47 6 9.6675C6 9.86167 6.0144 10.0492 6.0304 10.235Z"
        fill="currentColor"
      />
    </svg>
  );
}
