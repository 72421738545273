import React from 'react';

export default function ActionDoubleLeftChevron({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.504 4L2 12L10.504 20L12.3322 18.2802L5.67277 12.0154L12.3508 5.73517L10.504 4Z"
        fill="currentColor"
      />
      <path
        d="M20.1532 4L11.6492 12L20.1532 20L21.9814 18.2802L15.322 12.0154L22 5.73517L20.1532 4Z"
        fill="currentColor"
      />
    </svg>
  );
}
