import { useRef } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IaTextBasedEditableCell from '@lib/ia/src/layouts/DisplayTableLayout/components/IaDisplayTableEditable/IaTextBasedEditableCell';
import {
  DisplayTableLayoutNumberCell,
  DisplayTableLayoutRow,
} from '@lib/ia/src/layouts/DisplayTableLayout/types';
import { extractNumber } from '@lib/ia/src/utils/number';

export type IaDisplayTableNumberCellProps = {
  row: DisplayTableLayoutRow;
  cell: DisplayTableLayoutNumberCell;
};

const styles = {
  root: {
    px: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
export default function IaDisplayTableNumberCell({
  row,
  cell,
}: IaDisplayTableNumberCellProps) {
  const {
    value,
    fractionDigits,
    keepFractionDigitsWhenZero = false,
    asSequence = false,
    editable,
    actionMap,
    placeholder,
  } = cell;

  let displayValue;

  if (value === null) {
    displayValue = '';
  } else if (asSequence) {
    displayValue = value.toString();
  } else if (value === 0 && !keepFractionDigitsWhenZero) {
    displayValue = 0;
  } else {
    displayValue = value.toFixed(fractionDigits);
  }

  const ref = useRef<HTMLDivElement>();

  return (
    <>
      <Box sx={styles.root} ref={ref}>
        <Typography variant="body2" sx={styles.text}>
          {displayValue}
        </Typography>
      </Box>
      {editable && (
        <IaTextBasedEditableCell<number | null>
          containerRef={ref}
          actionMap={actionMap}
          editable={editable}
          placeholder={placeholder}
          row={row}
          initValue={value}
          filterValue={(val: string) => extractNumber(val)}
          inputSx={{
            '& .MuiInputBase-input': {
              textAlign: 'left',
            },
          }}
        />
      )}
    </>
  );
}
