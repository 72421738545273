import { PropsWithChildren, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

import NumberCardIcon from './NumberCardIcon';

const styles = {
  title: {
    flex: 1,
    display: '-webkit-box',
    maxHeight: 48,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  subtitle: {
    opacity: 0.64,
  },
};
export default function NumberCardTitle({
  icon,
  children,
  actionIcon,
  subtitle,
}: PropsWithChildren<{
  icon: ReactNode;
  subtitle?: string;
  actionIcon?: ReactNode;
}>) {
  return (
    <Box gridArea="title">
      <Box display="flex" gap={0.5}>
        <NumberCardIcon flex="0 0 18px">{icon}</NumberCardIcon>
        <Typography sx={styles.title} variant="subtitle2">
          {children}
        </Typography>
        {!!actionIcon && (
          <NumberCardIcon flex="0 0 24px" size={24} minWidth={24}>
            {actionIcon}
          </NumberCardIcon>
        )}
      </Box>
      {!!subtitle && (
        <Box sx={styles.subtitle} typography="body2">
          {subtitle}
        </Box>
      )}
    </Box>
  );
}
