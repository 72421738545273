import generateHook from './swr.helper';

export const useQuestionEvaluation = (quizQuestionId: NullableString) =>
  generateHook<GetQuizQuestionEvaluationRes>(
    quizQuestionId ? `v2/quiz/question/evaluation/${quizQuestionId}` : null,
    {
      auth: true,
      immutable: true,
    }
  );

export const useQuestionRatingInfo = (quizQuestionId: NullableString) =>
  generateHook<GetQuizQuestionRatingInfoRes>(
    quizQuestionId ? `v2/quiz/question/${quizQuestionId}/rating-info` : null,
    {
      auth: true,
      immutable: true,
    }
  );

export const useQuestionStatistic = (quizQuestionId: NullableString) =>
  generateHook<GetQuizQuestionStatisticRes>(
    quizQuestionId ? `v2/quiz/question/${quizQuestionId}/statistic` : null,
    {
      auth: true,
    }
  );

export const useQuestionOptionStatistic = (
  quizQuestionId: NullableString,
  roundNo: number
) =>
  generateHook<GetQuizQuestionOptionStatisticRes[]>(
    quizQuestionId
      ? [`v2/quiz/question/${quizQuestionId}/option/statistic`, roundNo]
      : null,
    {
      auth: true,
    }
  );

export const useQuestionTags = (quizId: string, roundNo: number) =>
  generateHook<GetQuizQuestionTagRes>(
    `v2/quiz/question/${quizId}/tags/me?roundNo=${roundNo}`,
    {
      auth: true,
      paginate: true,
    }
  );

export const useQuestionCreator = (userId: NullableString) =>
  generateHook<GetQuizQuestionCreatorRes>(
    userId ? `v2/quiz/question/creator/${userId}` : null,
    {
      auth: true,
      immutable: true,
    }
  );

export const useQuestionBookmark = (quizQuestionId: NullableString) =>
  generateHook<GetQuizQuestionBookmarkRes>(
    quizQuestionId ? `v2/quiz/question/${quizQuestionId}/mark/bookmark` : null,
    {
      auth: true,
      immutable: true,
    }
  );

export const useQuestionEmoji = (quizQuestionId: NullableString) =>
  generateHook<GetQuizQuestionEmojiRes>(
    quizQuestionId ? `v2/quiz/question/${quizQuestionId}/mark/emoji` : null,
    {
      auth: true,
      immutable: true,
    }
  );

export const useQuizQuestionEmojiList = (quizId?: string) =>
  generateHook<GetQuizQuestionEmojiListRes[]>(
    quizId ? `v2/quiz/question/mark/emoji/list?quizId=${quizId}` : null,
    {
      auth: true,
    }
  );

export const useQuestionStatistics = (
  quizQuestionId: NullableString,
  { isCurrentCorrect, currentDurationSec }: GetQuizQuestionStatisticsReq = {}
) => {
  const params = new URLSearchParams();

  if (isCurrentCorrect !== undefined) {
    params.set('isCurrentCorrect', isCurrentCorrect.toString());
    params.set('currentDurationSec', `${currentDurationSec || 0}`);
  }
  return generateHook<GetQuizQuestionStatisticsRes>(
    quizQuestionId
      ? `v2/quiz/question/${quizQuestionId}/statistic?${params.toString()}`
      : null,
    {
      auth: true,
    }
  );
};
