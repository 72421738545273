import React from 'react';

export default function ChatNotificationNumber({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6ZM11.332 9.044C10.9 9.316 10.456 9.54 10 9.716L10.348 10.556C10.516 10.5 10.688 10.432 10.864 10.352C11.048 10.272 11.22 10.188 11.38 10.1C11.548 10.012 11.7 9.92 11.836 9.824C11.98 9.728 12.1 9.636 12.196 9.548V16.316H13.312V8H12.52C12.16 8.416 11.764 8.764 11.332 9.044Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
