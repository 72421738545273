export const enum SummaryTrendRecordType {
  Mistake = 4,
  Overtime = 5,
  Score = 6,
  Ranking = 7,
}

export const enum IaStreakViewSlug {
  CalendarRhsStreakDefault = 'calendar_rhs_streak_default',
}
