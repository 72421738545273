export default function MathLogicAndTheory({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.5C12.299 4.5 12.5724 4.66895 12.7061 4.93641L19.4165 18.3573C19.6115 18.7473 19.4534 19.2215 19.0634 19.4165C18.6735 19.6115 18.1992 19.4534 18.0043 19.0634L12 7.05476L5.99574 19.0634C5.80075 19.4534 5.32655 19.6115 4.93657 19.4165C4.5466 19.2215 4.38853 18.7473 4.58352 18.3573L11.2939 4.93641C11.4276 4.66895 11.701 4.5 12 4.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
