/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import { compareAsc, compareDesc, setYear, startOfDay } from 'date-fns';

import DatePickerHeader from './components/DatePickerHeader';
import DayPicker, { DayPickerProps } from './components/DayPicker';
import YearPicker from './components/YearPicker';
import { DatePickerTypes, DateValue } from './types/dateTypes';
import { getBaseDateValue } from './datePicker.helper';

export type DatePickerProps = {
  value: DateValue;
  width?: number | string;
  type?: DatePickerTypes;
  initDate?: Date | string | number | null;
  minDate?: Date | string | number | null;
  maxDate?: Date | string | number | null;
  sx?: BoxProps['sx'];
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  renderDayButton?: DayPickerProps['renderDayButton'];
  onChange?: (newValue: any) => void;
  prevIcon?: ReactNode;
  nextIcon?: ReactNode;
};

const Card = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
}));

const styles = {
  hide: {
    opacity: 0,
  },
};
export default function DatePicker({
  value,
  initDate,
  minDate,
  maxDate,
  sx,
  weekStartsOn = 0,
  width = 320,
  type = 'default',
  renderDayButton,
  onChange,
  prevIcon,
  nextIcon,
}: DatePickerProps) {
  const [focusedDate, setFocusedDate] = useState<Date>(
    getBaseDateValue(value || initDate, minDate, maxDate)
  );
  const [showYear, setShowYear] = useState<boolean>(false);

  const handleYearClick = (year: number) => {
    setShowYear(false);
    setFocusedDate(setYear(focusedDate, year));
  };

  const minYear = minDate ? new Date(minDate).getFullYear() : undefined;
  const maxYear = maxDate ? new Date(maxDate).getFullYear() : undefined;

  const sxProps = Array.isArray(sx) ? sx : [sx];

  const handleTodayClick = () => {
    setShowYear(false);
    setFocusedDate(new Date());
  };

  const checkTodayDisabled = (day: Date): boolean => {
    if (minDate && compareAsc(day, startOfDay(new Date(minDate))) <= 0)
      return true;
    if (maxDate && compareDesc(day, startOfDay(new Date(maxDate))) === -1)
      return true;
    return false;
  };

  useEffect(() => {
    if (value) {
      setFocusedDate(getBaseDateValue(value));
    }
  }, [value]);

  return (
    <Box sx={[{ width }, ...sxProps]}>
      <DatePickerHeader
        minDate={minDate}
        maxDate={maxDate}
        value={focusedDate}
        onChange={setFocusedDate}
        onTitleClick={() => setShowYear((st) => !st)}
        onTodayClick={handleTodayClick}
        showGoToToday={showYear && !checkTodayDisabled(startOfDay(new Date()))}
        prevIcon={prevIcon}
        nextIcon={nextIcon}
      />
      <Box position="relative" mt={2}>
        <Box position="relative" sx={[showYear && styles.hide]}>
          <DayPicker
            weekStartsOn={weekStartsOn}
            focusedDate={focusedDate}
            value={value}
            type={type}
            initDate={initDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            renderDayButton={renderDayButton}
          />
        </Box>
        <Fade in={showYear}>
          <Card>
            {!!showYear && (
              <YearPicker
                height="100%"
                value={focusedDate.getFullYear()}
                onChange={handleYearClick}
                minYear={minYear}
                maxYear={maxYear}
              />
            )}
          </Card>
        </Fade>
      </Box>
    </Box>
  );
}
