import React from 'react';

export default function SocialMediaInstagram({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.9976 8.99872C10.345 8.99872 8.99636 10.3474 8.99636 12C8.99636 13.6526 10.345 15.0013 11.9976 15.0013C13.6502 15.0013 14.9988 13.6526 14.9988 12C14.9988 10.3474 13.6502 8.99872 11.9976 8.99872ZM20.999 12C20.999 10.7572 21.0103 9.52558 20.9405 8.28499C20.8707 6.84402 20.5419 5.56515 19.4883 4.51144C18.4323 3.45548 17.1557 3.12901 15.7148 3.05921C14.472 2.98941 13.2404 3.00067 11.9998 3.00067C10.757 3.00067 9.52546 2.98941 8.28489 3.05921C6.84395 3.12901 5.5651 3.45773 4.51141 4.51144C3.45547 5.5674 3.129 6.84402 3.05921 8.28499C2.98941 9.52783 3.00067 10.7594 3.00067 12C3.00067 13.2406 2.98941 14.4744 3.05921 15.715C3.129 17.156 3.45772 18.4348 4.51141 19.4886C5.56736 20.5445 6.84395 20.871 8.28489 20.9408C9.52771 21.0106 10.7593 20.9993 11.9998 20.9993C13.2426 20.9993 14.4742 21.0106 15.7148 20.9408C17.1557 20.871 18.4346 20.5423 19.4883 19.4886C20.5442 18.4326 20.8707 17.156 20.9405 15.715C21.0125 14.4744 20.999 13.2428 20.999 12ZM11.9976 16.6179C9.44215 16.6179 7.3798 14.5555 7.3798 12C7.3798 9.44452 9.44215 7.38213 11.9976 7.38213C14.553 7.38213 16.6154 9.44452 16.6154 12C16.6154 14.5555 14.553 16.6179 11.9976 16.6179ZM16.8045 8.27148C16.2078 8.27148 15.726 7.78966 15.726 7.193C15.726 6.59635 16.2078 6.11452 16.8045 6.11452C17.4011 6.11452 17.8829 6.59635 17.8829 7.193C17.8831 7.33468 17.8554 7.475 17.8012 7.60593C17.7471 7.73686 17.6677 7.85582 17.5675 7.956C17.4673 8.05618 17.3483 8.13561 17.2174 8.18975C17.0865 8.24388 16.9462 8.27166 16.8045 8.27148Z"
        fill="currentColor"
      />
    </svg>
  );
}
