import React, { MouseEvent, useRef } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ActionChildLevel as ActionChildLevelIcon } from '@front/icon';
import { IconButton } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import useTableSettings from '../../hooks/useTableSettings';
import { TableLayoutIconTextCell, TableLayoutRow } from '../../types';
import IaTextBasedEditableCell from '../IaTableEditable/IaTextBasedEditableCell';

export type IaIconTextCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutIconTextCell;
  columnKey: string;
};

const styles = {
  root: {
    display: 'grid',
    overflow: 'hidden',
    minHeight: '32px',
  },
  nowrap: {
    height: '32px',
    alignItems: 'center',
  },
  rootDefault: {
    gridTemplateColumns: '24px 1fr',
  },
  rootChild: {
    gridTemplateColumns: '36px 1fr',
  },
  icon: {
    pl: 1,
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    pl: 1,
    pr: 1,
    display: 'grid',
    alignItems: 'center',
    minHeight: '32px',
  },
  nowrapContainer: {
    height: '32px',
  },
  text: {
    br: {
      // make html element render on one line
      content: '" "',
      display: 'none',
    },
  },
  nowrapText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '& [data-content-type="paragraph"] > p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  iconButton: {
    width: 'unset',
    height: 'unset',
  },
};

type IconContentProps = {
  row: TableLayoutRow;
  editable: boolean;
  icon: TableLayoutIconTextCell['icon'];
  actionMap: TableLayoutIconTextCell['actionMap'];
};

function IconContent({ row, editable, icon, actionMap }: IconContentProps) {
  const { getIaAction } = useIaAction();

  const handlePickEmoji = (e: MouseEvent) => {
    e.stopPropagation();
    if (!actionMap || !actionMap.pickEmoji) return;
    const action = getIaAction<TableLayoutRow>(actionMap.pickEmoji);
    action?.action(row);
  };

  if (!editable) {
    return (
      <>
        {icon && icon.type === 'icon' && (
          <Icon name={icon.value} width={16} height={16} text={icon.text} />
        )}
        {icon && icon.type === 'emoji' && (
          <Typography variant="body2">{icon.value}</Typography>
        )}
      </>
    );
  }
  // editable support emoji only
  return (
    <IconButton sx={styles.iconButton} onClick={(e) => handlePickEmoji(e)}>
      {icon && icon.type === 'emoji' && (
        <Typography variant="body2">{icon.value}</Typography>
      )}
    </IconButton>
  );
}

export default function IaIconTextCell({
  row,
  columnKey,
  cell,
}: IaIconTextCellProps) {
  const {
    icon,
    text,
    html,
    isChild,
    editable,
    actionMap,
    placeholder,
    emptyText,
    className,
  } = cell;
  if (html && text) {
    console.warn('Both text and html are provided, only html will be used');
  }
  const ref = useRef<HTMLDivElement>();
  const { wrap } = useTableSettings();

  return (
    <>
      <Box
        sx={[
          styles.root,
          !wrap && styles.nowrap,
          isChild ? styles.rootChild : styles.rootDefault,
        ]}
        className={className}
      >
        <Box sx={styles.icon}>
          {isChild && <ActionChildLevelIcon width={16} height={16} />}
          <IconContent
            row={row}
            icon={icon}
            editable={!!editable}
            actionMap={actionMap}
          />
        </Box>
        <Box sx={[styles.container, !wrap && styles.nowrapContainer]} ref={ref}>
          {html ? (
            <Typography
              variant="body2"
              sx={[styles.text, !wrap && styles.nowrapText]}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          ) : (
            <Typography
              variant="body2"
              sx={[
                styles.text,
                !wrap && styles.nowrapText,
                !text && { color: 'alpha.lightA50' },
              ]}
            >
              {text || emptyText}
            </Typography>
          )}
        </Box>
      </Box>
      <IaTextBasedEditableCell
        containerRef={ref}
        actionMap={actionMap}
        editable={editable}
        placeholder={placeholder}
        columnKey={columnKey}
        row={row}
        initValue={text}
        filterValue={(val: string) => val}
      />
    </>
  );
}
