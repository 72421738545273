import {
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  useRef,
  useState,
} from 'react';
import { Box, useForkRef } from '@mui/material';
import { OtherError as OtherErrorIcon } from '@front/icon';

import { FieldTooltip } from '../../atoms';
import NumberInput, { NumberInputProps } from '../NumberInput';

export type NumberFieldProps = NumberInputProps & {
  error?: string;
};

const NumberField = forwardRef<HTMLDivElement, NumberFieldProps>(
  ({ inputRef: inputRefProp, error = '', onFocus, onBlur, ...rest }, ref) => {
    const [focused, setFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>();
    const handleInputRef = useForkRef(inputRef, inputRefProp);
    const handleFocus: FocusEventHandler<HTMLInputElement> = (ev) => {
      setFocused(true);
      onFocus?.(ev);
    };
    const handleBlur: FocusEventHandler<HTMLInputElement> = (ev) => {
      setFocused(false);
      onBlur?.(ev);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (ev) => {
      if (ev.code === 'Escape') {
        inputRef.current?.blur();
      }
    };
    return (
      <Box ref={ref}>
        <FieldTooltip
          open={focused && !!error}
          color="error"
          title="Error"
          titleIcon={<OtherErrorIcon width={16} height={16} />}
          content={error}
        >
          <NumberInput
            ref={handleInputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            {...rest}
          />
        </FieldTooltip>
      </Box>
    );
  }
);

NumberField.displayName = 'NumberField';
export default NumberField;
