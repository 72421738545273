import React, {
  FunctionComponent,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  alpha,
  Box,
  BoxProps,
  ButtonBase,
  Theme,
  Typography,
} from '@mui/material';
import { randNumber } from '@ngneat/falso';
import { result } from 'lodash';

import { ReverseTheme } from '../../components';
import { ResponsiveTooltip } from '../index';
import ProgressLoading from '../ProgressLoading';

import NumberCardIcon from './components/NumberCardIcon';
import NumberCardTitle from './components/NumberCardTitle';

export type NumberCardProps = Omit<BoxProps, 'title'> & {
  title?: string;
  subtitle?: string;
  titleIcon?: ReactNode;
  actionIcon?: ReactNode;
  backgroundIcon?: ReactNode;
  borderLeft?: string;
  disabled?: boolean;
  selected?: boolean;
  gradient?: boolean;
  loading?: boolean;
  singleValue?: boolean;

  // show the tooltip directly with the title and titleIcon
  tooltipContent?: ReactNode;
};

const styles = {
  root: {
    position: 'relative',
    p: 2.5,
    minHeight: 160,
    borderRadius: 2,
    overflow: 'hidden',
    textAlign: 'left',
    alignItem: 'flex-start',
    justifyContent: 'stretch',
    display: 'grid',
    gridTemplateAreas: `
      "title"
      "progress"
      "value"
      "description"
    `,
    gridTemplateRows: `
      minmax(24px, min-content)
      min-content
      minmax(54px, 1fr)
      minmax(16px, min-content)
    `,
    boxShadow: (theme: Theme) => (theme.palette.mode === 'dark' ? 1 : 'none'),
  },
  background: (gradient: boolean) => ({
    background: (theme: Theme) =>
      gradient
        ? theme.palette.gradient.primaryVerticalDark
        : theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.05)
        : '#F2F2F2',
  }),
  hoverBackground: (gradient: boolean) => ({
    cursor: 'pointer',
    transitionDuration: '0.3s',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        background: (theme: Theme) =>
          gradient
            ? theme.palette.gradient.primaryVerticalDarker
            : alpha(theme.palette.text.primary, 0.1),
      },
    },
  }),
  backgroundIcon: {
    position: 'absolute',
    top: 10,
    right: -18,
    opacity: 0.05,
    userSelect: 'none',
  },
  value: {
    display: 'flex',
    width: '100%',
    pt: { xs: 0, md: '1px' },
    pb: { xs: 0, md: 1 },
    alignItems: 'flex-end',
    height: '100%',
    minWidth: 0,
    '& > div': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
  valueContent: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selected: {
    background: (theme: Theme) => theme.palette.text.primary,
    color: 'background.default',
  },

  disabled: {
    opacity: 0.5,
  },

  border: (color: string) => ({
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: 8,
      background: (theme: Theme) => result(theme.palette, color, color),
    },
  }),
  description: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  progress: {
    mt: { xs: 2, md: 1 },
    position: 'relative',
    width: '100%',
    height: 8,
    borderRadius: 1,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    overflow: 'hidden',
  },
  barProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    bgcolor: 'text.primary',
    borderRadius: 1,
  },
  highlightBarProgress: {
    background: (theme: Theme) => theme.palette.gradient.primary,

    '& + div': {
      borderRadius: 0,
    },
  },

  singleValueCard: {
    gridTemplateRows: `
      minmax(24px, min-content)
      0px
      minmax(54px, 1fr)
      0px
    `,

    '& .number-card-value': {
      pb: { md: 0 },
    },
  },
  loading: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  tooltipTitle: {
    mb: 1,
    fontWeight: 700,
  },
};

function Value({
  sx,
  suffix,
  prefix,
  children,
  variant = 'number',
  loading,
  ...rest
}: Omit<BoxProps, 'prefix'> & {
  prefix?: ReactNode;
  suffix?: ReactNode;
  variant?: 'number' | 'currency';
  loading?: boolean;
}) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  const variantProps =
    variant === 'number'
      ? { typography: 'h3', fontWeight: 300 }
      : { typography: 'numberH3', fontWeight: 500 };

  if (loading) {
    return (
      <Box
        className="number-card-value"
        sx={[styles.value, ...sxProps]}
        gridArea="value"
      >
        <Box {...variantProps} {...rest}>
          &nbsp;
        </Box>
      </Box>
    );
  }

  return (
    <Box
      className="number-card-value"
      sx={[styles.value, ...sxProps]}
      gridArea="value"
    >
      <Box {...variantProps} {...rest}>
        {!!prefix && prefix}
        <Box sx={styles.valueContent}>
          {children}
          {!!suffix && <Typography variant="caption">{suffix}</Typography>}
        </Box>
      </Box>
    </Box>
  );
}

function Description({
  sx,
  loading,
  ...rest
}: BoxProps & { loading?: boolean }) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  if (loading) {
    return (
      <Box
        sx={[styles.description, ...sxProps]}
        gridArea="description"
        typography="caption"
        {...rest}
      >
        &nbsp;
      </Box>
    );
  }
  return (
    <Box
      sx={[styles.description, ...sxProps]}
      gridArea="description"
      typography="caption"
      {...rest}
    />
  );
}

function Progress({
  rate = 0,
  highlightRate,
}: {
  rate?: number;
  highlightRate?: number;
}) {
  return (
    <Box sx={styles.progress} gridArea="progress">
      {highlightRate !== undefined && highlightRate > rate && (
        <Box
          sx={[
            styles.barProgress,
            styles.highlightBarProgress,
            { width: `${Math.min(highlightRate, 1) * 100}%` },
          ]}
        />
      )}
      <Box
        sx={[styles.barProgress, { width: `${Math.min(rate, 1) * 100}%` }]}
      />
    </Box>
  );
}

function Tooltip({
  title,
  titleIcon,
  content,
}: {
  title?: string;
  titleIcon?: ReactNode;
  content?: ReactNode;
}) {
  if (!title && !content) return null;

  return (
    <ResponsiveTooltip
      title={title}
      titleIcon={
        titleIcon ? (
          <NumberCardIcon size={16} boxHeight={16}>
            {titleIcon}
          </NumberCardIcon>
        ) : undefined
      }
      titleSx={styles.tooltipTitle}
      tooltipProps={{
        followCursor: true,
      }}
      content={content}
    >
      <Box
        sx={{ userSelect: 'none' }}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
      />
    </ResponsiveTooltip>
  );
}

function Loading({
  loading,
  onLoaded,
}: {
  loading?: boolean;
  onLoaded: () => void;
}) {
  const [progress, setProgress] = useState(0);
  const timerRef = useRef<NodeJS.Timeout>();

  const randomProgress = useCallback((progressValue: number) => {
    const ms = randNumber({ min: 1000, max: 3000 });
    clearTimeout(timerRef.current);

    if (progressValue >= 90) return;

    timerRef.current = setTimeout(() => {
      const p = randNumber({ min: progressValue, max: 90 });
      setProgress(p);
    }, ms);
  }, []);

  useEffect(() => {
    randomProgress(0);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [randomProgress]);

  useEffect(() => {
    if (loading === false) {
      setProgress(100);
      clearTimeout(timerRef.current);
    }
  }, [loading]);

  const handleAnimated = (p: number) => {
    randomProgress(p);
    if (p === 100) {
      onLoaded();
    } else {
      randomProgress(p);
    }
  };

  return (
    <ProgressLoading
      sx={styles.loading}
      progress={progress}
      onAnimated={handleAnimated}
    />
  );
}

export default function NumberCard({
  sx,
  title,
  subtitle,
  titleIcon,
  actionIcon,
  children,
  borderLeft,
  disabled = false,
  selected = false,
  gradient = false,
  singleValue = false,
  loading = false,
  backgroundIcon,
  tooltipContent,
  onClick,
  ...rest
}: NumberCardProps) {
  const [loaded, setLoaded] = useState(loading ? false : true);
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const cardActionIcon =
    actionIcon === undefined
      ? onClick
        ? 'ActionArrowRight'
        : undefined
      : actionIcon;

  const props = onClick ? { component: ButtonBase, disabled, onClick } : {};

  if (!loaded || loading) {
    return (
      <Box
        sx={[
          styles.root,
          styles.background(gradient),
          singleValue && styles.singleValueCard,
          disabled && styles.disabled,
          !!borderLeft && styles.border(borderLeft),
          ...sxProps,
        ]}
        className="number-card"
        {...rest}
      >
        {!!(title || actionIcon) && (
          <NumberCardTitle
            subtitle={subtitle}
            icon={titleIcon}
            actionIcon={cardActionIcon}
          >
            {title}
          </NumberCardTitle>
        )}

        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return null;
          }

          if (typeof child?.type === 'function') {
            // Keep components spacing and height
            if (
              (child.type as FunctionComponent).displayName ===
                'NumberCardValue' ||
              (child.type as FunctionComponent).displayName ===
                'NumberCardDescription'
            ) {
              return React.cloneElement(child, {
                ...child.props,
                loading: true,
              });
            } else if (
              (child.type as FunctionComponent).displayName ===
              'NumberCardProgress'
            ) {
              return child;
            }
          }

          return null;
        })}

        <Loading loading={loading} onLoaded={() => setLoaded(true)} />
      </Box>
    );
  }
  return (
    <Box
      sx={[
        styles.root,
        styles.background(gradient),
        singleValue && styles.singleValueCard,
        !!onClick && !disabled && !selected && styles.hoverBackground(gradient),
        disabled && styles.disabled,
        selected && styles.selected,
        !!borderLeft && styles.border(borderLeft),
        ...sxProps,
      ]}
      className="number-card"
      {...props}
      {...rest}
    >
      {!!tooltipContent && (
        <Tooltip title={title} titleIcon={titleIcon} content={tooltipContent} />
      )}
      {!!backgroundIcon && (
        <NumberCardIcon sx={styles.backgroundIcon} height="auto" size={146}>
          {backgroundIcon}
        </NumberCardIcon>
      )}
      {!!(title || actionIcon) && (
        <NumberCardTitle
          subtitle={subtitle}
          icon={titleIcon}
          actionIcon={cardActionIcon}
        >
          {title}
        </NumberCardTitle>
      )}
      {selected ? <ReverseTheme>{children}</ReverseTheme> : children}
    </Box>
  );
}

Value.displayName = 'NumberCardValue';
Description.displayName = 'NumberCardDescription';
Progress.displayName = 'NumberCardProgress';

NumberCard.Value = Value;
NumberCard.Description = Description;
NumberCard.Progress = Progress;
NumberCard.Tooltip = Tooltip;
