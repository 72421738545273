import { MouseEvent, useRef } from 'react';
import { ActionChevronDown as ActionChevronDownIcon } from '@front/icon';
import {
  Button,
  ButtonProps,
  DropdownButton,
  DropdownButtonOption,
  EmphasizeButton,
  Icon,
  TipButton,
} from '@front/ui';

import ProfileFollowButton from './ProfileFollowButton';
import ProfileSendButton from './ProfileSendButton';

const styles = {
  button: {
    width: '100%',
    minWidth: 0,
    flex: 1,
    px: 'unset',
  },
  buttonSm: {
    minWidth: '105px',
    minHeight: '24px',
    px: 0,
    fontSize: '12px',
    borderWidth: '2px',
  },
};

export type ProfileButtonProps = {
  icon?: string;
  label?: string;
  dropdownOptions?: DropdownButtonOption[];
  buttonType?: 'iconButton' | 'emphasizeButton' | 'button';
  size?: 'sm' | 'md';
  loading?: boolean;
  disabled?: boolean;
  variant?: ButtonProps['variant'];
  sx?: ButtonProps['sx'];
  onClick?: () => void;
};

export default function ProfileButton({
  icon,
  label,
  dropdownOptions = [],
  buttonType = 'button',
  variant = 'outlined',
  loading = false,
  disabled = false,
  size = 'md',
  sx,
  onClick,
}: ProfileButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (dropdownOptions.length > 0) {
      return;
    }
    onClick?.();
  };

  const handleMouseDown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  if (buttonType === 'iconButton') {
    return (
      <TipButton
        customSize={24}
        loading={loading}
        disabled={disabled}
        onClick={handleClick}
        title={label || ''}
      >
        {icon && <Icon name={icon} width={16} height={16} />}
      </TipButton>
    );
  }

  const ButtonComponent = buttonType === 'button' ? Button : EmphasizeButton;
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <DropdownButton
      options={dropdownOptions}
      dropdownProps={{
        sx: { minWidth: ref.current?.clientWidth || 148, width: 'max-content' },
      }}
      buttonRef={ref}
    >
      <ButtonComponent
        prefixIcon={icon && <Icon name={icon} width={16} height={16} />}
        suffixIcon={dropdownOptions.length > 0 && <ActionChevronDownIcon />}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        variant={variant}
        loading={loading}
        sx={[styles.button, size === 'sm' && styles.buttonSm, ...sxProps]}
        disabled={disabled}
      >
        {label}
      </ButtonComponent>
    </DropdownButton>
  );
}

ProfileButton.Follow = ProfileFollowButton;
ProfileButton.Send = ProfileSendButton;
