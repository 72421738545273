import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { DisplayTableLayoutButtonFooterCell } from '../../types';

export type IaDisplayTableButtonFooterCellProps =
  DisplayTableLayoutButtonFooterCell;

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  button: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 1,
    pl: 1,
  },
  fullWidth: {
    width: '100%',
    height: '100%',
  },
  hoverable: {
    '@media (hover: hover)': {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  text: {
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
export default function IaDisplayTableButtonFooterCell({
  text,
  action,
  icon,
  sx,
  fullWidth = false,
  hoverable = false,
}: IaDisplayTableButtonFooterCellProps) {
  const { getIaAction } = useIaAction();

  const eventActionProps = {
    onClick: () => getIaAction(action)?.action(),
  };

  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={styles.root}>
      <ButtonBase
        sx={[
          styles.button,
          fullWidth && styles.fullWidth,
          hoverable && styles.hoverable,
          ...sxProps,
        ]}
        disableRipple={!hoverable}
        onMouseDown={(e) => e.stopPropagation()}
        {...eventActionProps}
      >
        {icon && <Icon name={icon} width={16} height={16} />}
        <Typography variant="body2" sx={styles.text}>
          {text}
        </Typography>
      </ButtonBase>
    </Box>
  );
}
