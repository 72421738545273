import { useContext, useMemo } from 'react';
import { buildHookMutate } from '@lib/web/apis';
import { CreatorQuestionDetailContext } from '@lib/web/editor/contexts';

export const useCreatorQuestionCorrectAnswerData = (questionId: string) => {
  const {
    data: correctAnswerRes,
    mutate: correctAnswerMutate,
    isLoading,
  } = useContext(CreatorQuestionDetailContext).useCorrectAnswer(questionId);

  const reloadCorrectAnswer = useMemo(
    () => buildHookMutate(correctAnswerMutate),
    [correctAnswerMutate]
  );

  const correctAnswerIdSet = useMemo(
    () => new Set(correctAnswerRes?.data.map((item) => item.componentId)),
    [correctAnswerRes?.data]
  );

  return useMemo(
    () => ({
      isLoadingInitialData: !correctAnswerRes,
      isInitializingOrLoading: !correctAnswerRes || isLoading,
      correctAnswers: correctAnswerRes?.data || [],
      reloadCorrectAnswer,
      correctAnswerIdSet,
    }),
    [correctAnswerRes, isLoading, correctAnswerIdSet, reloadCorrectAnswer]
  );
};
