import { useContext } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { appConfig } from '@front/config';
import IaDataContext from '@lib/ia/src/core/IaData/IaDataContext';

const enum MarkViewSlug {
  IconRhsIAMarkRecently = 'icon_rhs_ia-mark_recently',
}
interface GetRecentMarksRes {
  id: string;
  name: string;
  code: string;
}

export const useRecentMarks = () => {
  const { token, sid, apiUrl = '' } = useContext(IaDataContext);

  const { data, mutate } = useSWR<GetRecentMarksRes[]>(
    token || sid ? 'recentMarks' : null,
    {
      fetcher: async () => {
        try {
          const response = await axios.get(
            `v2/ia/mark?viewSlug=${MarkViewSlug.IconRhsIAMarkRecently}&limit=${appConfig.maxRecentMarksDisplay}`,
            {
              baseURL: apiUrl,
              headers: {
                Authorization: token ? `Bearer ${token}` : undefined,
                Sid: sid || undefined,
              },
            }
          );
          return response.data.data.items;
        } catch (error) {
          console.warn('Failed to fetch recent marks:', error);
          return [];
        }
      },
    }
  );

  return {
    recentMarks: data,
    mutateRecentMarksWithNewMark: (newMark: GetRecentMarksRes) => {
      // move newMark to the top of the list
      const remainingMarks = (data || []).filter(
        (mark) => mark.id !== newMark.id
      );
      const newMarks = [newMark, ...remainingMarks].slice(
        0,
        appConfig.maxRecentMarksDisplay
      );

      return mutate(newMarks, {
        optimisticData: newMarks,
        populateCache: false,
        revalidate: false,
        rollbackOnError: false,
      });
    },
  };
};
