import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Icon from '@lib/ia/src/components/Icon';

import { DisplayTableLayoutHeaderIconTextCell } from '../../types';

export type IaDisplayTableIconTextHeaderCellProps =
  DisplayTableLayoutHeaderIconTextCell;

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '20px 1fr',
    height: '100%',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
};
export default function IaDisplayTableIconTextHeaderCell({
  leftIcon,
  textIcon,
  text,
}: IaDisplayTableIconTextHeaderCellProps) {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.content} pr={0.5}>
        <Icon name={leftIcon} width={16} height={16} />
      </Box>
      <Box sx={styles.content} pl={1}>
        <Icon name={textIcon} width={16} height={16} />
        <Typography variant="body2">{text}</Typography>
      </Box>
    </Box>
  );
}
