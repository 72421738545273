import { useCallback, useMemo } from 'react';
import { PlanProductName, useSubscriptionPlan } from '@lib/web/apis';

type PlansType = {
  basicMonthly: SubscriptionPlanRes | null;
  basicYearly: SubscriptionPlanRes | null;
  proMonthly: SubscriptionPlanRes | null;
  proYearly: SubscriptionPlanRes | null;
  unlimitedMonthly: SubscriptionPlanRes | null;
  unlimitedYearly: SubscriptionPlanRes | null;
};

export default function useSubscriptionPlans() {
  const { data } = useSubscriptionPlan();

  const plans = useMemo(() => {
    const plansType: PlansType = {
      basicMonthly: null,
      basicYearly: null,
      proMonthly: null,
      proYearly: null,
      unlimitedMonthly: null,
      unlimitedYearly: null,
    };

    if (!data) return plansType;

    data.data.forEach((plan) => {
      switch (plan.name) {
        case PlanProductName.BasicMonthly:
          plansType.basicMonthly = plan;
          break;
        case PlanProductName.BasicYearly:
          plansType.basicYearly = plan;
          break;
        case PlanProductName.ProMonthly:
          plansType.proMonthly = plan;
          break;
        case PlanProductName.ProYearly:
          plansType.proYearly = plan;
          break;
        case PlanProductName.UnlimitedMonthly:
          plansType.unlimitedMonthly = plan;
          break;
        case PlanProductName.UnlimitedYearly:
          plansType.unlimitedYearly = plan;
          break;
      }
    });

    return plansType;
  }, [data]);

  const getPlanByName = useCallback(
    (planName: string) => {
      if (!data?.data) return null;
      return data?.data.find((plan) => plan.name === planName);
    },
    [data?.data]
  );

  const plansDisplayAmountByMonth = useMemo(() => {
    /**
     * XXX: Currently, we only support USD. All amounts are in cents, so we divide by 100 to get the dollar value.
     * When we support other currencies in the future, this logic will need to be updated accordingly.
     */
    return {
      [PlanProductName.BasicMonthly]: plans.basicMonthly?.amount
        ? plans.basicMonthly.amount / 100
        : null,
      [PlanProductName.BasicYearly]: plans.basicYearly?.amount
        ? plans.basicYearly.amount / 100 / 12
        : null,
      [PlanProductName.ProMonthly]: plans.proMonthly?.amount
        ? plans.proMonthly.amount / 100
        : null,
      [PlanProductName.ProYearly]: plans.proYearly?.amount
        ? plans.proYearly.amount / 100 / 12
        : null,
      [PlanProductName.UnlimitedMonthly]: plans.unlimitedMonthly?.amount
        ? plans.unlimitedMonthly.amount / 100
        : null,
      [PlanProductName.UnlimitedYearly]: plans.unlimitedYearly?.amount
        ? plans.unlimitedYearly.amount / 100 / 12
        : null,
    };
  }, [plans]);

  return {
    plans,
    getPlanByName,
    isLoading: !data,
    plansDisplayAmountByMonth,
  };
}
