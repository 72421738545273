import React from 'react';

export default function NFTSuperRare({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6651 3.08614C18.5262 2.87624 18.2913 2.75 18.0396 2.75H5.96039C5.7087 2.75 5.4738 2.87624 5.33491 3.08614L1.37452 9.07165C1.1894 9.35143 1.21259 9.72012 1.43132 9.9745L11.3323 21.489C11.474 21.6537 11.6801 21.7489 11.8973 21.75C12.1146 21.7511 12.3216 21.6579 12.4648 21.4945L22.5638 9.98005C22.7869 9.72578 22.8121 9.35371 22.6255 9.07165L18.6651 3.08614ZM18.1615 5.04337L16.4819 8.73551H20.6044L18.1615 5.04337ZM20.3446 10.2355L13.593 17.9334L15.8766 10.2355H20.3446ZM14.312 10.2355L11.9197 18.2997L9.64879 10.2355H14.312ZM8.09045 10.2355L10.2607 17.942L3.63404 10.2355H8.09045ZM3.39556 8.73551H7.49767L5.83548 5.04796L3.39556 8.73551ZM7.12113 4.25H10.7223L8.72986 7.81893L7.12113 4.25ZM9.93608 8.73551L11.9976 5.04296L14.0438 8.73551H9.93608ZM15.2509 7.81916L13.2731 4.25H16.8745L15.2509 7.81916Z"
        fill="currentColor"
      />
    </svg>
  );
}
