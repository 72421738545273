import { forwardRef } from 'react';
import {
  IconButtonProps as MuiIconButtonProps,
  IconButtonTypeMap,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { InfoTooltipProps } from '../../Tooltip/InfoTooltip';
import SimpleTooltip from '../../Tooltip/SimpleTooltip';
import IconButton from '../IconButton';

const styles = {
  hideTooltip: {
    display: 'none',
  },
};

type TipButtonProps<
  D extends React.ElementType = IconButtonTypeMap['defaultComponent'],
  P = {
    component?: D;
    title?: React.ReactNode;
    customSize?: number;
    selected?: boolean;
    loading?: boolean;
    hideTooltip?: boolean;
    tooltipProps?: Omit<
      InfoTooltipProps,
      'open' | 'title' | 'content' | 'children' | 'onClose' | 'onOpen'
    >;
  }
> = MuiIconButtonProps<D, P>;

function TipButtonInner<D extends 'button'>(
  {
    sx,
    title,
    children,
    disabled,
    customSize,
    hideTooltip,
    tooltipProps,
    ...rest
  }: TipButtonProps<D>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const button = (
    <IconButton
      ref={ref}
      sx={sx}
      customSize={customSize || (mdDown ? 28 : 24)}
      disabled={disabled}
      {...rest}
    >
      {children}
    </IconButton>
  );

  if (mdDown || disabled) return button;
  return (
    <SimpleTooltip
      title={title}
      slotProps={{
        popper: {
          sx: [!!hideTooltip && styles.hideTooltip],
        },
      }}
      {...tooltipProps}
    >
      {button}
    </SimpleTooltip>
  );
}

const TipButton = forwardRef(TipButtonInner) as <
  D extends React.ElementType = IconButtonTypeMap['defaultComponent']
>(
  props: TipButtonProps<D> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof TipButtonInner>;

export default TipButton;
