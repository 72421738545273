import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography';
import { SystemStyleObject } from '@mui/system';

import { basicBlockHtmlStyles } from './config/basicComposerBlockStyles';
import useTextComposerRender from './hooks/useTextComposerRender';

const styles = {
  styleProvider: {
    width: '100%',
    height: '100%',
  },
  root: {
    '&.render-one-line, & .render-one-line': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      alignItems: 'center',
      flexWrap: 'nowrap',

      /**
       * only show the first block, otherwise the content will be squashed
       */
      '& [data-content-type]:not(:first-of-type), & [data-content-type]:not(:first-of-type) *':
        {
          display: 'none',
        },

      '& *:not(.markdown-message)': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexWrap: 'nowrap',

        '[data-content-type="inline-latex"], [data-content-type="inline-latex"] *':
          {
            overflow: 'unset',
          },
      },

      '& .html-renderer .markdown-message': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',

        '& *': {
          display: 'inline',
          verticalAlign: 'middle',
        },

        '& pre': {
          display: 'none',
        },
      },

      '& br': {
        // make html element render on one line
        content: '" "',
        width: '4px',
        display: 'inline-block',
      },

      'span:first-of-type:not(:only-child)': {
        // For "You:" prefix
        overflow: 'visible',
      },
    },
  },
};

export type ComposerRendererProps = TypographyProps & {
  html?: string;
  oneLine?: boolean;
  styleProvider?: boolean;
  htmlStyles?: (
    | SystemStyleObject<Theme>
    | ((theme: Theme) => SystemStyleObject<Theme>)
  )[];
};

/**
 * There are 2 ways to use this component
 *
 * 1. render a html string
 * <ComposerRenderer html={renderHtml} />
 *
 * 2. style provider (useful when the html itself is in very deep nesting, such as ia layout)
 * <ComposerRenderer styleProvider>
 *   ...
 *    ...
 *      this will render as one line
 *      <div dangerouslySetInnerHTML={{ __html: renderHtml }} className='render-one-line' />
 *      this will render normal
 *      <div dangerouslySetInnerHTML={{ __html: renderHtml }} />
 * </ComposerRenderer>
 */
export default function ComposerRenderer({
  html,
  oneLine = false,
  styleProvider = false,
  htmlStyles = basicBlockHtmlStyles,
  sx,
  className = '',
  children,
  ...rest
}: ComposerRendererProps) {
  const { render } = useTextComposerRender();

  if (styleProvider) {
    return (
      <Box
        className={className}
        sx={[styles.styleProvider, styles.root, ...htmlStyles]}
        {...rest}
      >
        {children}
      </Box>
    );
  }

  const sxProps = Array.isArray(sx) ? sx : [sx];

  if (html) {
    return (
      <Box
        className={`${oneLine ? 'render-one-line ' : ''}${className}`}
        sx={[styles.root, ...sxProps, ...htmlStyles]}
        {...rest}
      >
        {render(html)}
      </Box>
    );
  }

  return null;
}
