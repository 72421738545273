import { useCallback } from 'react';
import { toast } from '@front/ui';
import { apis, UploadResourceType } from '@lib/web/apis';
import { call, downloadFile } from '@lib/web/utils';

export function useFileBlockMoreActions({
  fileKey,
  filename,
  fileType,
}: {
  fileKey: string;
  filename: string;
  fileType: string;
}) {
  const download = useCallback(async () => {
    const [res, err] = await call(
      apis.file.getSignedUrl(UploadResourceType.AhaFile, fileKey)
    );

    if (!res) {
      toast.error('cannot get download url');
      console.warn(err);
      return;
    }
    const ext = filename.split('.').pop()?.toLowerCase() || '';

    try {
      await downloadFile({
        url: res.data.signedUrl,
        fileExt: ext,
        filename,
        blobType: fileType,
      });
    } catch (e) {
      toast.error('cannot download file');
      console.warn(err);
    }
  }, [fileKey, fileType, filename]);

  return {
    download,
  };
}
