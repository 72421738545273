import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import PaidPlanLabel from '../components/PaidPlanLabel';
import { PaidFeatureBlockerType } from '../types';

type ReturnValue = {
  icon: string;
  title: string;
  description: ReactNode;
  actions: {
    icon: string;
    title: string;
    description: ReactNode;
    button: {
      href: string;
      prefixIcon: string;
      text: string;
    };
  }[];
};
export const usePaidFeatureBlockConfig = ({
  type,
}: {
  type: PaidFeatureBlockerType;
}): ReturnValue => {
  const { t } = useTranslation('quiz');

  return {
    icon: 'TestSolution',
    title: {
      [PaidFeatureBlockerType.SelectSuperLevelDifficulty]: t(
        'blocker.selectSuperLevelDifficulty.title'
      ),
      [PaidFeatureBlockerType.ViewSolution]: t('blocker.viewSolution.title'),
      [PaidFeatureBlockerType.SelectIncognitoMode]: t(
        'blocker.selectIncognitoMode.title'
      ),
    }[type],
    description: {
      [PaidFeatureBlockerType.SelectSuperLevelDifficulty]: (
        <Trans
          i18nKey="quiz::blocker.selectSuperLevelDifficulty.description"
          components={{ span: <span />, br: <br /> }}
        />
      ),
      [PaidFeatureBlockerType.ViewSolution]: (
        <Trans
          i18nKey="quiz::blocker.viewSolution.description"
          components={{ span: <span />, br: <br /> }}
        />
      ),
      [PaidFeatureBlockerType.SelectIncognitoMode]: t(
        'blocker.selectIncognitoMode.description'
      ),
    }[type],
    actions: [
      {
        icon: 'ProfileUpgrade',
        title: t('blocker.action.upgrade.title'),
        description: {
          [PaidFeatureBlockerType.SelectSuperLevelDifficulty]: (
            <Trans
              i18nKey="quiz::blocker.action.upgrade.description.selectSuperLevelDifficulty"
              components={{
                premium: <PaidPlanLabel plan="premium" />,
                pro: <PaidPlanLabel plan="pro" />,
                unlimited: <PaidPlanLabel plan="unlimited" />,
              }}
            />
          ),
          [PaidFeatureBlockerType.ViewSolution]: (
            <Trans
              i18nKey="quiz::blocker.action.upgrade.description.viewSolution"
              components={{
                premium: <PaidPlanLabel plan="premium" />,
                pro: <PaidPlanLabel plan="pro" />,
                unlimited: <PaidPlanLabel plan="unlimited" />,
              }}
            />
          ),
          [PaidFeatureBlockerType.SelectIncognitoMode]: (
            <Trans
              i18nKey="quiz::blocker.action.upgrade.description.selectIncognitoMode"
              components={{
                premium: <PaidPlanLabel plan="premium" />,
                pro: <PaidPlanLabel plan="pro" />,
                unlimited: <PaidPlanLabel plan="unlimited" />,
              }}
            />
          ),
        }[type],
        button: {
          href: '/profile/my-plan',
          prefixIcon: 'ProfileUpgrade',
          text: t('blocker.action.upgrade.button.text'),
        },
      },
      {
        icon: 'OtherAddFriend',
        title: t('blocker.action.invite.title'),
        description: t('blocker.action.invite.description'),
        button: {
          href: '/profile/invite-friends',
          prefixIcon: 'OtherAddFriend',
          text: t('blocker.action.invite.button.text'),
        },
      },
    ],
  };
};
