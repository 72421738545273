export const enum UserViewSlug {
  TableCenterIaUserDefault = 'table_center_ia_user_default',
  TableCenterIaUserInternalAccount = 'table_center_ia_user_internal_account',
  ApiInternalIaUserOnlineStatus = 'api_internal_ia_user_online_status',
}

export const enum UserType {
  GENERAL = 0,
  UNCLAIMED = 1,
  INTERNAL = 2,
}

// update user status
export const enum UserOnlineStatusUpdate {
  AWAY = -1,
  ONLINE = 1,
  PRACTICING = 21,
}

// get user status
export const enum UserOnlineStatusGet {
  AWAY = -1,
  ONLINE = 1,
  BUSY = 2,
}
