interface CountryCode {
  IN: string;
  US: string;
}

export const DEFAULT_CURRENCY = 'USD';
export const COUNTRY_CURRENCY_MAP: CountryCode = {
  IN: 'INR',
  US: 'USD',
};

export const getCurrencyCode = (
  countryCode: string | null | undefined
): string => {
  if (!countryCode) return DEFAULT_CURRENCY;

  return (
    COUNTRY_CURRENCY_MAP[countryCode.toUpperCase() as keyof CountryCode] ||
    DEFAULT_CURRENCY
  );
};
