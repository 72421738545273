import React from 'react';

export default function MainExplore({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_14_413)">
        <path
          d="M8.77748 3C8.02277 3 7.41095 3.60331 7.41095 4.34753C7.41095 5.09175 8.02277 5.69506 8.77748 5.69506H15.267C16.9238 5.69506 18.267 7.03821 18.267 8.69507V14.1606C18.267 14.9048 18.8788 15.5081 19.6335 15.5081C20.3882 15.5081 21 14.9048 21 14.1606C21 13.4262 21 9.89527 21 6.99885C21 4.78971 19.2091 3 17 3H8.77748Z"
          fill="currentColor"
        />
        <path
          d="M4.82203 7.41512H14.9543C15.9606 7.41512 16.7763 8.21953 16.7763 9.21183V19.2033C16.7763 20.1956 15.9606 21 14.9543 21H4.82203C3.81575 21 3 20.1956 3 19.2033V9.21183C3 8.21953 3.81575 7.41512 4.82203 7.41512Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_14_413">
          <rect height="24" width="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
