import { useCallback, useContext } from 'react';
import { questionDetailToVariables } from '@lib/web/editor/EditorTextComposer';
import { call } from '@lib/web/utils';
import { uniq } from 'lodash';

import { CreatorQuestionDetailContext } from '../../contexts';

import { ValidationData } from './types';

const getAllVariableIds = (validationData: ValidationData) => {
  let variables: { id: string; variableId: string }[] = [];

  Object.values(validationData).forEach((data) => {
    variables = variables.concat(
      questionDetailToVariables(data.questionDetail)
    );
  });

  return uniq(
    variables
      .filter((variable) => Boolean(variable.variableId))
      .map((variable) => variable.variableId)
  );
};
export function useSyncVariablesBeforePublish() {
  const {
    getLinkedCreatorQuestionVariable,
    linkCreatorQuestionVariable,
    unlinkCreatorQuestionVariable,
  } = useContext(CreatorQuestionDetailContext);
  return useCallback(async (id: string, validationData: ValidationData) => {
    const [variablesData] = await call(getLinkedCreatorQuestionVariable(id));

    if (!variablesData) {
      return;
    }
    const allVariableIds = getAllVariableIds(validationData);

    const linkVariableIds = allVariableIds.filter((variableId) =>
      variablesData.data.every(
        (d) => d.creatorQuestionVariableId !== variableId
      )
    );
    const unlinkVariables = variablesData.data.filter((d) =>
      allVariableIds.every(
        (variableId) => d.creatorQuestionVariableId !== variableId
      )
    );

    const apiCalls = [
      ...linkVariableIds.map(
        (variableId) => () =>
          linkCreatorQuestionVariable({
            creatorQuestionVariableId: variableId,
            name: variableId,
            creatorQuestionId: id,
          })
      ),
      ...unlinkVariables.map(
        (linkVariable) => () =>
          unlinkCreatorQuestionVariable({
            creatorQuestionVariableLinkId: linkVariable.id,
          })
      ),
    ];

    if (apiCalls.length) {
      await call(apiCalls);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
