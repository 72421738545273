import { useContext } from 'react';
import { VariableType } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

import { CreatorQuestionDetailContext } from '../contexts';

import { useCurrentQuestion } from '.';

export function useGetAllVariablesFromType(type: VariableType) {
  const { sectionId } = useCurrentIaClub();
  const { questionId } = useCurrentQuestion();

  const { data, mutate } = useContext(
    CreatorQuestionDetailContext
  ).useCreatorQuestionVariable({
    sectionId,
    creatorQuestionId: questionId,
    type,
  });

  return {
    data,
    mutate,
    variables: data?.data.items,
  };
}
