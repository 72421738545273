import { VariableSubType, VariableType } from '@lib/web/apis';

import { VariableDefaultValues } from './components/VariablePanel/types';

type ReturnPanelParams = {
  returnPanel?: string;
  returnParams?: Record<string, any>;
};

export enum TextComposerPanelKeys {
  TextComposerLineMarker = 'TextComposerLineMarker',
  TextComposerHighlight = 'TextComposerHighlight',
  TextComposerVariable = 'TextComposerVariable',
}

export type TextComposerPanelParams = {
  [TextComposerPanelKeys.TextComposerLineMarker]: ReturnPanelParams & {
    id?: string;
    activeOptionId?: string;
    hoverAnchorId?: string | null;
    activeAnchorId?: string | null;
    selectAnchorId?: string | null;
    deleteAnchorId?: string | null;
    pendingUpdated?: Record<string, string | null>;
  };
  [TextComposerPanelKeys.TextComposerHighlight]: ReturnPanelParams & {
    id?: string;
    activeOptionId?: string;
    hoverAnchorId?: string | null;
    activeAnchorId?: string | null;
    selectAnchorId?: string | null;
    deleteAnchorId?: string | null;
    pendingUpdated?: Record<string, string | null>;
  };
  [TextComposerPanelKeys.TextComposerVariable]: ReturnPanelParams & {
    type: VariableType;
    subType?: VariableSubType;
    page: 'list' | 'form';
    targetId?: string;
    variableId?: string;
    isNewCreated?: boolean;
    hasError?: boolean;
    defaultValue?: VariableDefaultValues;
    onVariableCreated?: (variableId: string) => void;
  };
};
