export default function MathMeasurements({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2 2H12V12H22V22H2V2ZM4 4V6.5H6.004V8.5H4V11H6.004V13H4V15.5H6.004V17.5H4V20H6.5V18.033H8.5V20H11V18.033H13V20H15.5V18.033H17.5V20H20V14H10V4H4Z"
        fill="currentColor"
      />
    </svg>
  );
}
