import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import {
  NFTEmojiAlien,
  NFTEmojiExpressionless,
  NFTEmojiRocket,
} from '@front/icon';

export type EmojiVariant = 'alien' | 'rocket' | 'expressionless';
export type EmojiProps = Omit<BoxProps, 'children'> & {
  variant: EmojiVariant;
};

const EMOJI_MAP = {
  alien: NFTEmojiAlien,
  rocket: NFTEmojiRocket,
  expressionless: NFTEmojiExpressionless,
};
export default function Emoji({ variant, ...rest }: EmojiProps) {
  const Icon = EMOJI_MAP[variant];

  return (
    <Box {...rest}>
      <Icon />
    </Box>
  );
}
