import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MuiPopper from '@mui/material/Popper';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';

const styles = {
  blockHighlight: {
    width: '100%',
    height: '100%',
    backgroundColor: 'alpha.lightA10',
    borderRadius: '4px',
  },
};

export default function BlockSelection() {
  const { editor, selectedBlockId } = useContext(TextComposerContext);
  const [blockEl, setBlockEl] = useState<Element | null>(null);
  const [blockRect, setBlockRect] = useState<DOMRect | null>(null);

  useEffect(() => {
    if (!selectedBlockId) return;

    const blockDom = editor.domElement.querySelector(
      `[data-id='${selectedBlockId}']`
    );

    if (!blockDom) return;

    setBlockEl(blockDom);
    setBlockRect(blockDom.getBoundingClientRect());
  }, [editor.domElement, selectedBlockId]);

  if (!selectedBlockId || !blockRect) return;

  return (
    <MuiPopper
      open={!!blockEl}
      anchorEl={blockEl}
      disablePortal
      placement="top-start"
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              // make it overlap whe whole block
              offset: [0, -blockRect.height],
            },
          },
        ],
      }}
    >
      <Box
        sx={[
          styles.blockHighlight,
          { width: blockRect.width, height: blockRect.height },
        ]}
      />
    </MuiPopper>
  );
}
