import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  OtherCheckedCircleSolid as OtherCheckedCircleSolidIcon,
  OtherCloseCircleSolid as OtherCloseCircleSolidIcon,
} from '@front/icon';
import { ComposerBlock } from '@lib/web/composer';
import EditorComposer from '@lib/web/editor/EditorComposer';

import { McqSolutionValue } from '../types';

export type SolutionProps = {
  questionId: string;
  solutionValues: McqSolutionValue[];
  handleBlocksChange: (id: string, blocks: ComposerBlock[]) => void;
  readonly?: boolean;
};

const styles = {
  root: {
    my: '8px',
  },
  item: {
    mb: '16px',
  },
  label: {
    typography: 'subtitle2',
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    gap: 1,
  },
  labelSelected: {
    color: 'success.light',
  },
  labelUnselected: {
    color: 'error.dark',
  },
  textComposer: {
    wordBreak: 'break-word',
  },
};

const getChoiceAlphabet = (index: number) => {
  const charCodeA = 65;
  return String.fromCharCode(charCodeA + index);
};

export default function MultipleChoices({
  questionId,
  solutionValues,
  handleBlocksChange,
  readonly,
}: SolutionProps) {
  const { t } = useTranslation('editor');

  return (
    <Box sx={styles.root}>
      {solutionValues.map(({ id, selected, blocks: defaultBlocks }, index) => (
        <Box key={id} sx={styles.item}>
          <Box
            sx={[
              styles.label,
              selected && styles.labelSelected,
              !selected && styles.labelUnselected,
            ]}
          >
            {t('Choice ##', { alphabet: getChoiceAlphabet(index) })}
            {selected && <OtherCheckedCircleSolidIcon />}
            {!selected && <OtherCloseCircleSolidIcon />}
          </Box>
          <EditorComposer
            key={id}
            questionId={questionId}
            sx={styles.textComposer}
            defaultBlocks={defaultBlocks}
            onBlocksChange={(blocks) => handleBlocksChange(id, blocks)}
            placeholder={t('Write solution here')}
            disabled={readonly}
          />
        </Box>
      ))}
    </Box>
  );
}
