import { service } from '../../constants';

import { mockNotifications } from './mock/notifications';
import generateHook, {
  generateInfiniteHook,
  generateMockInfiniteHook,
  SwrHelperParams,
} from './swr.helper';

type NotificationListParams = {
  time?: number;
  topicSlug?: string;
  key?: string;
  isIncludeAllTopics?: boolean;
  isGetTopicDetail?: boolean;
};

export const useNotificationList = (
  {
    time,
    topicSlug,
    key,
    isIncludeAllTopics,
    isGetTopicDetail,
  }: NotificationListParams = {},
  pageSize = 10,
  config: SwrHelperParams = {}
) => {
  const params = new URLSearchParams();
  if (time) params.set('time', `${time}`);
  if (topicSlug) params.set('topicSlug', `${topicSlug}-topic`);
  if (isIncludeAllTopics) params.set('isIncludeAllTopics', 'true');
  if (isGetTopicDetail) params.set('isGetTopicDetail', 'true');

  return generateInfiniteHook<GetNotificationRes>(
    (index) => [
      `notification/v1/me?service=${service}&page=${
        index + 1
      }&limit=${pageSize}&${params.toString()}`,
      ...(key ? [key] : []),
    ],
    pageSize,
    {
      auth: true,
      immutable: true,
      revalidateFirstPage: false,
      errorRetryCount: 0,
      ...config,
    }
  );
};

export const useMockNotificationList = () => {
  return generateMockInfiniteHook<GetNotificationRes>(mockNotifications);
};

export const useLatestNotifications = ({
  time,
  topicSlug,
  key,
  enable,
}: NotificationListParams & { enable?: boolean } = {}) => {
  const params = new URLSearchParams();
  if (time) params.set('time', `${time}`);
  if (topicSlug) params.set('topicSlug', `${topicSlug}-topic`);

  return generateHook<GetNotificationRes[]>(
    [
      `notification/v1/after-time/me?service=${service}&${params.toString()}`,
      ...(key ? [key] : []),
    ],
    {
      auth: true,
      errorRetryCount: 0,
      enable,
    }
  );
};

export const useNotificationRead = ({
  topicSlug,
}: { topicSlug?: string } = {}) =>
  generateHook<{ lastReadTime: string }>(
    topicSlug
      ? `notification/v1/channel/topic/read/me?topicSlug=${topicSlug}`
      : 'notification/v1/me/read',
    {
      auth: true,
    }
  );

export const useUnreadNotificationCount = (pageSize = 100) =>
  generateHook<GetUnreadNotificationCountRes>(
    `notification/v1/me/count/by-topic?limit=${pageSize}`,
    {
      auth: true,
      paginate: true,
    }
  );

export const useUnreadNotificationClubs = () =>
  generateHook<GetUnreadNotificationClubRes[]>('v2/notification/unread/clubs', {
    auth: true,
  });
