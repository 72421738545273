import { TFunction } from 'i18next';

const getPlaylistsPageConfig = (t: TFunction) => ({
  history: {
    title: t('playlists.history.title'),
    icon: 'TestHistory',
  },
  historyMistakes: {
    title: t('playlists.history_mistakes.title'),
    icon: 'ActionCloseThick',
  },
  historyOvertime: {
    title: t('playlists.history_overtime.title'),
    icon: 'TestClockSolid',
  },
  historyDetail: {
    title: t('playlists.history_detail.title'),
    icon: 'ProfilePlaylist',
  },
  challenges: {
    title: t('playlists.challenges.title'),
    icon: 'MainChallenge',
  },
  challengesChallengers: {
    title: t('playlists.challenges_challengers.title'),
    icon: 'PrivacyFriends',
  },
  challengesDetail: {
    title: t('playlists.challenges_detail.title'),
    icon: 'MainChallenge',
  },
  savedSessions: {
    title: t('playlists.savedSessions.title'),
    icon: 'TestBookmarkFilled',
  },
  reaction: {
    title: t('playlists.reaction.title'),
    icon: 'NFTFavorite',
  },
  following: {
    title: t('playlists.following.title'),
    icon: 'OtherFriendsPlaylist',
  },
  topics: {
    title: t('playlists.topics.title'),
    icon: 'ChatHashtag',
  },
  creators: {
    title: t('playlists.creators.title'),
    icon: 'TestWriting',
  },
  myQuestions: {
    title: t('playlists.myQuestions.title'),
    icon: 'ChatQuestionList',
  },
  reviewQuestions: {
    title: t('playlists.reviewQuestions.title'),
    icon: 'ChatQuestionList',
  },
  reviewQuestionsAll: {
    title: t('playlists.reviewQuestions_all.title'),
    icon: 'ChatQuestionList',
  },
});

export default getPlaylistsPageConfig;
