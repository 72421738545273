import { useAuth } from '@lib/web/apis';

import useAuthLinks from './useAuthLinks';

export default function useClaimSignUpUrl() {
  const { member, isGuest } = useAuth();
  const email = member?.userMetadata.reservedEmail || member?.email;

  const { signUpUrl } = useAuthLinks({
    signUpQueries:
      isGuest && email
        ? [
            `email=${encodeURIComponent(email)}`,
            `username=${encodeURIComponent(member.distinctName)}`,
          ]
        : [],
  });
  return isGuest && email ? signUpUrl : '';
}
