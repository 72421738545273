import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import Router from 'next/router';
import {
  CreatorQuestionReviewStatus,
  CreatorQuestionStatus,
  StructureType,
} from '@lib/web/apis';
import {
  useCreatorQuestionListData,
  useCurrentQuestion,
} from '@lib/web/editor';
import { useClubSlug } from '@lib/web/hooks';
import { callWithToast } from '@lib/web/utils';

export const useDeleteQuestion = () => {
  const { t } = useTranslation('editor');
  const clubSlug = useClubSlug();

  const { questionId } = useCurrentQuestion();

  const {
    selectQuestion,
    selectNextQuestion,
    selectSubQuestionsFromGroup,
    selectGroupIdFromSubQuestion,
    reloadQuestions,
  } = useCreatorQuestionListData();

  const getDeleteConfirmMessage = useCallback(
    (ids: string[]) => {
      for (const id of ids) {
        const question = selectQuestion(id);
        if (question?.structureType === StructureType.QuestionGroup) {
          return t(
            'Deleting group will remove all questions that is in this group'
          );
        }
        if (question?.status === CreatorQuestionStatus.Published) {
          return t(
            'Once you delete a published question, the question will be removed everywhere'
          );
        }
      }
      return t('Deleting question will remove all its content');
    },
    [selectQuestion, t]
  );

  const getDeleteQuestionFunction = useCallback(
    ({
        deleteApi,
      }: {
        deleteApi: ({ id }: { id: string }) => Promise<AxiosResponse>;
      }) =>
      async (id: string) => {
        const [res] = await callWithToast(() => deleteApi({ id }), {
          confirmMsg: getDeleteConfirmMessage([id]),
          confirmText: 'Delete',
          confirmOptions: {
            toastOptions: {
              duration: Infinity,
            },
            type: 'warning',
            desktopSx: { maxWidth: 335 },
          },
        });

        if (!res) return;

        void reloadQuestions();

        const groupItems = selectSubQuestionsFromGroup(id);

        const isCurrentQuestionDeleted = questionId === id;
        const isCurrentGroupDeleted = groupItems.some(
          (item) => item.id === questionId
        );

        if (isCurrentQuestionDeleted || isCurrentGroupDeleted) {
          const nextQuestion = selectNextQuestion(id, {
            loopBackToFirst: true,
            skipSubQuestion: true,
          });

          void Router.push(
            nextQuestion?.id
              ? `/club/${clubSlug}/editor/${nextQuestion.id}`
              : `/club/${clubSlug}/create-questions`
          );
        }
      },
    [
      getDeleteConfirmMessage,
      reloadQuestions,
      selectSubQuestionsFromGroup,
      questionId,
      selectNextQuestion,
      clubSlug,
    ]
  );

  const getBatchDeleteQuestionFunction = useCallback(
    ({
        deleteApi,
      }: {
        deleteApi: ({ id }: { id: string }) => Promise<AxiosResponse>;
      }) =>
      async (ids: string[]) => {
        const [res] = await callWithToast(
          ids.map((id) => () => deleteApi({ id })),
          {
            confirmMsg: getDeleteConfirmMessage(ids),
            confirmText: 'Delete',
            confirmOptions: {
              toastOptions: {
                duration: Infinity,
              },
              type: 'warning',
              desktopSx: { maxWidth: 335 },
            },
          }
        );

        if (res) {
          void reloadQuestions();
        }
      },
    [getDeleteConfirmMessage, reloadQuestions]
  );

  const getIsDeletable = useCallback(
    (id: string) => {
      const question = selectQuestion(id);
      const groupId = selectGroupIdFromSubQuestion(id);
      const groupItems = groupId ? selectSubQuestionsFromGroup(groupId) : [];

      if (!question) {
        return false;
      }

      if (
        question.structureType === StructureType.SubQuestion &&
        groupItems.length === 1
      ) {
        return false;
      }

      if (question.reviewStatus === CreatorQuestionReviewStatus.Approved) {
        return false;
      }

      return true;
    },
    [selectGroupIdFromSubQuestion, selectQuestion, selectSubQuestionsFromGroup]
  );

  return {
    getDeleteQuestionFunction,
    getBatchDeleteQuestionFunction,
    getIsDeletable,
  };
};
