import { Box, Skeleton } from '@mui/material';

const styles = {
  profileContainer: {
    display: 'grid',
    overflow: 'hidden',
  },
  userData: {
    display: 'flex',
    gap: '4px',
    mt: 2,
  },
  bioContainer: {
    mt: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
};

export default function ProfileSkeleton() {
  return (
    <Box sx={styles.profileContainer}>
      <Box>
        <Skeleton variant="circular" width={80} height={80} />
      </Box>
      <Box sx={styles.userData}>
        <Skeleton width={65} />
        <Skeleton width={75} />
        <Skeleton width={75} />
      </Box>
      <Box sx={styles.bioContainer}>
        <Skeleton width={120} />
        <Skeleton width={220} />
      </Box>
    </Box>
  );
}
