import { useMemo } from 'react';
import {
  CampaignDiscountCodeViewSlug,
  useIaCampaignDiscountCodeList,
} from '@lib/web/apis';

export default function useIaDiscountCode(id?: string) {
  const { dataset, isEmpty, isLoading, mutate } = useIaCampaignDiscountCodeList(
    {
      viewSlug:
        CampaignDiscountCodeViewSlug.TableCenterIaCampaignDiscountCodeDefault,
      search: `id:${id}`,
      searchFields: 'id:eq',
      limit: 1,
    },
    {
      enable: !!id,
    }
  );

  return useMemo(
    () => ({
      discountCode: dataset?.[0],
      isLoading,
      isEmpty,
      mutate,
    }),
    [dataset, isEmpty, isLoading, mutate]
  );
}
