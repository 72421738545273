import { useEffect } from 'react';
import { WatchingEvent } from '@lib/web/thread';
import { useThread } from '@lib/web/thread/hooks/core/useThread';

export const useAddWatchingEvent = (watchingEvent: WatchingEvent) => {
  const { watchingEventsRef } = useThread();

  useEffect(() => {
    if (
      watchingEventsRef.current.some(
        (v) => v.scope === watchingEvent.scope && v.key === watchingEvent.key
      )
    ) {
      return;
    }

    watchingEventsRef.current = [...watchingEventsRef.current, watchingEvent];

    return () => {
      watchingEventsRef.current = watchingEventsRef.current.filter(
        (v) => !(v.scope === watchingEvent.scope && v.key === watchingEvent.key)
      );
    };
  }, [watchingEvent, watchingEventsRef]);
};
