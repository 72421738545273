import { useMemo } from 'react';
import { ComposerBlock } from '@lib/web/composer';
import {
  useCreatorQuestionCorrectAnswerData,
  useCreatorQuestionDetailData,
} from '@lib/web/editor';
import useDisableEditAnswerFormat from '@lib/web/editor/hooks/useDisableEditAnswerFormat';
import { keyBy } from 'lodash';

import { UnscrambleValue } from '../types';

/**
 * Data example
 * {
 *   "answerComponents": [
 *     {
 *       "id": "aaa",
 *       "editorBlocks": [... the content user typed]
 *     },
 *     {
 *       "id": "bbb",
 *        "editorBlocks": [... the content user typed]
 *     },
 *     {
 *       "id": "ccc",
 *       "editorBlocks": [... the content user typed]
 *     },
 *   ],
 *   "correctAnswers": [
 *     {
 *       "componentId": "ccc",
 *       "order": 0  // now we know "ccc" is the first one
 *     },
 *     {
 *       "componentId": "aaa",
 *       "order": 1  // "aaa" is the second one
 *     },
 *     {
 *       "componentId": "bbb",
 *       "order": 2
 *     },
 *   ]
 * }
 * @param questionId
 */
export const useUnscrambleAnswers = ({
  answerComponents,
  correctAnswers,
}: {
  answerComponents: { id: string; editorBlocks: ComposerBlock[] }[];
  correctAnswers: { componentId: string; order: number }[];
}) => {
  const answerIdToComponent = useMemo(
    () => keyBy(answerComponents, (component) => component.id),
    [answerComponents]
  );

  return useMemo(() => {
    // build unscrambleValues based on correct answer's order
    let result: UnscrambleValue[] = [];

    correctAnswers.forEach(({ componentId: answerId, order }) => {
      if (answerId in answerIdToComponent) {
        result[order] = {
          id: answerId,
          blocks: answerIdToComponent[answerId].editorBlocks,
        };
      }
    });

    // check if there is hole between 1 ~ n order
    result = result.filter((v) => {
      if (v === undefined) {
        console.warn('unscramble correct answers is not follow 1 ~ n order', v);
        return false;
      }
      return true;
    });

    return result;
  }, [answerIdToComponent, correctAnswers]);
};

export const useData = (questionId: string) => {
  const { answerComponents } = useCreatorQuestionDetailData(questionId);
  const { correctAnswers } = useCreatorQuestionCorrectAnswerData(questionId);
  const unscrambleValues = useUnscrambleAnswers({
    answerComponents,
    correctAnswers,
  });

  const { disableStatus } = useDisableEditAnswerFormat(questionId);

  return {
    unscrambleValues,
    enableAddRemoveOptions: !disableStatus.noAllowUserEdit,
    answerComponents,
  };
};
