export const composerConfig = {
  supportedImageTypes: ['image/png', 'image/jpeg'],
  supportedImageTypesMessage: 'png, jpeg',
  supportedFileTypes: ['text/plain', 'application/pdf'],
  supportedFileTypesMessage: 'txt, pdf',
  supportedAudioTypes: ['audio/mpeg', 'audio/ogg', 'audio/wav'],
  supportedAudioTypesMessage: 'mp3, ogg, wav',
  fileSizeLimitMb: 10,
  maximumElementsInOneThread: 10,
};
