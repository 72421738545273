import React from 'react';

export default function EditorTable({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        height="1.8"
        width="16.2"
        fill="currentColor"
        rx="0.9"
        x="3.8999"
        y="13.8"
      />
      <rect
        height="1.8"
        width="16.2"
        fill="currentColor"
        rx="0.9"
        transform="rotate(-90 11.1001 20.1001)"
        x="11.1001"
        y="20.1001"
      />
      <rect
        height="1.8"
        width="16.2"
        fill="currentColor"
        rx="0.9"
        x="3.8999"
        y="8.3999"
      />
      <path
        d="M17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5ZM7 3C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3H7Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
