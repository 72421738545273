import React from 'react';

export default function MainApps({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.7008 3H19.5847C20.3663 3 20.9998 3.78161 20.9998 4.41514V9.29906C20.9998 10.0807 20.3663 10.7142 19.5847 10.7142H14.7008C13.9192 10.7142 13.2856 9.93259 13.2856 9.29906V4.41514C13.2856 3.63353 13.9192 3 14.7008 3Z"
        fill="currentColor"
      />
      <path
        d="M4.41514 3H9.29906C10.0807 3 10.7142 3.78161 10.7142 4.41514V9.29906C10.7142 10.0807 10.0807 10.7142 9.29906 10.7142H4.41514C3.63353 10.7142 3 9.93259 3 9.29906V4.41514C3 3.63353 3.63353 3 4.41514 3Z"
        fill="currentColor"
      />
      <path
        d="M4.41514 13.2859H9.29906C10.0807 13.2859 10.7142 14.0675 10.7142 14.701V19.5849C10.7142 20.3666 10.0807 21.0001 9.29906 21.0001H4.41514C3.63353 21.0001 3 20.2185 3 19.5849V14.701C3 13.9194 3.63353 13.2859 4.41514 13.2859Z"
        fill="currentColor"
      />
      <path
        d="M14.7008 13.2859H19.5847C20.3663 13.2859 20.9998 14.0675 20.9998 14.701V19.5849C20.9998 20.3666 20.3663 21.0001 19.5847 21.0001H14.7008C13.9192 21.0001 13.2856 20.2185 13.2856 19.5849V14.701C13.2856 13.9194 13.9192 13.2859 14.7008 13.2859Z"
        fill="currentColor"
      />
    </svg>
  );
}
