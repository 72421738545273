import { formatDateString } from '@front/helper';
import { format, isSameYear } from 'date-fns';
import { capitalize } from 'lodash';

import {
  DateFilterValue,
  DateRangeFilterValue,
  FilterOperator,
  RelativeToTodayDateFilterValue,
  SingleDateFilterValue,
} from './types';

const DEFAULT_DATE_FORMAT = 'MMM dd, ‘yy';
const DEFAULT_DATE_SAME_YEAR_FORMAT = 'MMM dd';

function formatDate(date: Date) {
  const today = new Date();
  if (isSameYear(date, today)) {
    return format(date, DEFAULT_DATE_SAME_YEAR_FORMAT);
  }
  return format(date, DEFAULT_DATE_FORMAT);
}

export const singleDateToString = (
  operator: FilterOperator,
  value: SingleDateFilterValue
) => {
  if (!value.value) return '';
  const dateValue = new Date(value.value);

  switch (operator) {
    case 'IsBeforeTime':
      return `Before ${formatDate(dateValue)}`;
    case 'IsAfterTime':
      return `After ${formatDate(dateValue)}`;
    case 'IsEqualOrBeforeTime':
      return `Equal or before ${formatDate(dateValue)}`;
    case 'IsEqualOrAfterTime':
      return `Equal or after ${formatDate(dateValue)}`;
  }
  return formatDate(dateValue);
};

export const dateRangeToString = (value: DateRangeFilterValue) => {
  if (!value.value || value.value.length < 2) return '';
  const [start, end] = value.value;
  const startDate = new Date(start);
  const endDate = new Date(end);

  const prefix = formatDate(startDate);
  const suffix = formatDate(endDate);

  return `${prefix} → ${suffix}`;
};

export const relativeToTodayToString = (
  value: RelativeToTodayDateFilterValue
) => {
  if (!value.value) return '';
  const { orientation, period, amount } = value.options;
  if (orientation === 'this') {
    return capitalize(`${orientation} ${period}`);
  }
  if (!amount || amount === 1) {
    return capitalize(`${orientation} ${period}`);
  }
  return capitalize(`${orientation} ${amount} ${period}s`);
};

export const dateValueToString = (
  operator: FilterOperator,
  value: DateFilterValue
) => {
  if (value.dateType === 'dateRange') return dateRangeToString(value);
  if (value.dateType === 'relativeToToday')
    return relativeToTodayToString(value);
  return singleDateToString(operator, value);
};

export const dateValueToQueryString = (value: DateFilterValue) => {
  if (!value.value) return '';

  if (value.dateType === 'dateRange') {
    const [start, end] = value.value || [];
    const startDate = new Date(start);
    const endDate = new Date(end);
    return `${formatDateString(startDate)},${formatDateString(endDate)}`;
  }

  if (value.dateType === 'relativeToToday') {
    const values = value.value || [];
    if (values.length === 1) {
      const dateValue = new Date(values[0]);
      return formatDateString(dateValue);
    }
    if (values.length === 2) {
      const [start, end] = values;
      const startDate = new Date(start);
      const endDate = new Date(end);
      return `${formatDateString(startDate)},${formatDateString(endDate)}`;
    }
    return '';
  }

  const dateValue = new Date(value.value);
  return formatDateString(dateValue);
};

const DateFilterHelper = {
  stringify: dateValueToString,
  queryStringify: dateValueToQueryString,
};

export default DateFilterHelper;
