import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Checkbox, SimpleTooltip } from '@front/ui';

import useInputEvent from '../hooks/useInputEvent';
import useSuccessState from '../hooks/useSuccessState';
import { FormLayoutCheckboxItem } from '../types';

type CheckboxInputProps = {
  item: FormLayoutCheckboxItem;
};

const styles = {
  checkbox: {
    py: 0,
    px: 0,
  },
  label: {
    py: '3.5px',
  },
};
export default function CheckboxInput({ item }: CheckboxInputProps) {
  const { control } = useFormContext();

  const {
    field: { onChange, onBlur, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: item.name,
    rules: item.rules,
  });

  const successState = useSuccessState(item.name, isDirty);

  const { handleInputBlur, handleInputChange } = useInputEvent({
    name: item.name,
    actionMap: item.actionMap,
    onChange,
    onBlur,
  });

  const handleBlur = () => {
    handleInputBlur();
    void successState.handleBlur();
  };

  const handleChange = (newValue: boolean) => {
    void handleInputChange(newValue);
    void successState.handleChange();
  };

  const checkbox = (
    <Checkbox
      className={`ia-form-layout_checkbox ${item.className || ''}`}
      sx={styles.checkbox}
      labelContainerSx={styles.label}
      label={item.label}
      labelPosition={item.labelPosition}
      checked={Boolean(value ?? item.defaultValue)}
      disabled={item.disabled}
      onBlur={handleBlur}
      onChange={(ev) => handleChange(ev.target.checked)}
    />
  );

  if (item.helperText) {
    return (
      <SimpleTooltip title={item.helperText}>
        <span>{checkbox}</span>
      </SimpleTooltip>
    );
  }
  return checkbox;
}
