import { createContext, ReactNode } from 'react';

export interface TableLayoutSettingsValue {
  wrap?: boolean;
  layoutSetting?: {
    showRowBorder: boolean;
    showColumnBorder: boolean;
  };
}

const initialValue: TableLayoutSettingsValue = {
  wrap: false,
};

type TableLayoutProviderProps = {
  children: ReactNode;
  value: TableLayoutSettingsValue;
};

export const TableLayoutSettingsContext =
  createContext<TableLayoutSettingsValue>(initialValue);

export function TableLayoutSettingsContextProvider({
  children,
  value,
}: TableLayoutProviderProps) {
  return (
    <TableLayoutSettingsContext.Provider value={value}>
      {children}
    </TableLayoutSettingsContext.Provider>
  );
}
