import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';

interface GuestAccount {
  memberId: string;
  username: string;
  accessToken: string;
  accessTokenExpiredIn: number;
  refreshToken: string;
  refreshTokenExpiredIn: number;
}

type GuestContextValue = {
  guest: GuestAccount;
  isLoading: boolean;
  login: (account: GuestAccount) => void;
  logout: (ev?: { clearCookie?: boolean }) => void;
};

interface GuestProviderProps {
  children?: ReactNode;
}

const initialGuest = {
  memberId: '',
  username: '',
  accessToken: '',
  accessTokenExpiredIn: 0,
  refreshToken: '',
  refreshTokenExpiredIn: 0,
};

export const GuestContext = createContext<GuestContextValue>({
  guest: initialGuest,
  isLoading: true,
  login: () => {},
  logout: () => {},
});

export const GuestProvider: FC<GuestProviderProps> = (props) => {
  const { children } = props;
  const [guest, setGuest] = useState(initialGuest);
  const [isLoading, setIsLoading] = useState(true);

  const memoizedValue = useMemo(() => {
    return {
      guest,
      isLoading,
      login: (account: GuestAccount) => {
        setGuest(account);
      },
      logout: async ({
        clearCookie = false,
      }: { clearCookie?: boolean } = {}) => {
        if (clearCookie) {
          try {
            await fetch('/api/auth/guest/logout', {
              method: 'POST',
            });
          } catch (e) {
            console.warn(e);
          }
        }
        setGuest(initialGuest);
      },
    };
  }, [guest, isLoading]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await fetch('/api/auth/guest', {
          method: 'GET',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
        });
        if (res.ok && res.status === 200) {
          const data = await res.json();
          setGuest(data);
        } else {
          setGuest(initialGuest);
        }
      } catch (e) {
        console.warn(e);
      }

      setIsLoading(false);
    };

    if (!guest.accessToken) fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GuestContext.Provider value={memoizedValue}>
      {children}
    </GuestContext.Provider>
  );
};
