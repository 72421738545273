import { useIaRender } from '@lib/ia/src/core/IaRender/useIaRender';

import { CtaCustomize } from '../types';

type FeedCtaCustomizeProps = CtaCustomize;

export default function FeedCtaCustomize({
  value,
  metadata,
  clubSlug,
}: FeedCtaCustomizeProps) {
  const { getIaRender } = useIaRender();

  const render = getIaRender(value);

  if (render) {
    const props = metadata ? { clubSlug, ...metadata } : { clubSlug };
    return render(props);
  }

  return null;
}
