import { basicTiptapExtensions } from '@lib/web/composer';

import { InlineAI } from '../components/blocks/InlineAI';
import { InlineHighlightAnchor } from '../components/blocks/InlineAnchor/InlineHighlightAnchor';
import { InlineLineAnchor } from '../components/blocks/InlineAnchor/InlineLineAnchor';
import { InlineHighlight } from '../components/blocks/InlineHighlight';
import { InlineLatex } from '../components/blocks/InlineLatex';
import { InlineLineMarker } from '../components/blocks/InlineLineMarker';
import { InlineVariable } from '../components/blocks/InlineVariable';

export const editorTiptapExtensions = [
  ...basicTiptapExtensions,
  InlineAI,
  InlineLatex,
  InlineHighlight,
  InlineLineMarker,
  InlineLineAnchor,
  InlineHighlightAnchor,
  InlineVariable,
];
