export const enum AnswerFormatType {
  Unknown = 0,
  MultipleChoice = 1,
  TrueFalseNotGiven = 2,
  Unscramble = 3,
  GridIn = 5,
  FillInTheBlank = 9,
  FreeResponse = 10,
  MultipleResponse = 11,
  Essay = 12,
  QuestionGroup = 51,
}

export const enum StructureType {
  Unknown = 0,
  SingleQuestion = 1,
  QuestionGroup = 2,
  SubQuestion = 3,
}

export const enum Difficulty {
  Easy = 1,
  Medium = 2,
  Hard = 3,
  Super = 4,
}

export const enum ValidationType {
  Mcq = 0,
}

export const enum CreatorQuestionStatus {
  Unknown = 0,
  Draft = 1,
  Publishing = 2,
  Published = 3,
  ChangeUnpublished = 11,
  Archived = -8,
}

export const enum CreatorQuestionReviewStatus {
  Removed = -4,
  Unknown = 0, // draft
  PendingReview = 1, // published
  Reviewing = 2, // published
  PendingReapprove = 11, // approved
  ReapproveReviewing = 12,
  Approved = 3,
  Rejected = -3,
}
export const enum PublishReleaseStatus {
  Unknown = 0,
  Ready = 1,
  ReleaseProcessing = 3,
  Released = 4,
  TakedownProcessing = -3,
  TakenDown = -4,
}

export const enum BlockType {
  Unknown = 0,
  Text = 11,
  LaTeX = 12,
  Image = 21,
  Chart = 31,
  Table = 41,
  Blank = 51,
  LineMarker = 62,
  Header3 = 73,
  Audio = 81,
  LegacyMaterial = 901,
  LegacyParagraph = 902,
}

export const enum QuestionDetailComponentType {
  LeftQuestionArea = 11,
  RightQuestionArea = 12,
  GeneralSolution = 21,
  AnswerSolution = 22,
  Answer = 31,
  ReferenceFormula = 41,
}

export const enum QuestionDetailComponentSubType {
  Unknown = 0,
  MultipleChoiceItem = 1,
  TrueFalseNotGivenT = 21,
  TrueFalseNotGivenF = 22,
  TrueFalseNotGivenN = 23,
  UnscrambleItem = 3,
  GridInItem = 5,
  FillInTheBlankItem = 9,
  FreeResponseItem = 10,
  MultipleResponseItem = 11,
}

export const enum UpdateCreatorQuestionDetailAction {
  CreateBlock = 1,
  UpdateBlock = 2,
  DuplicateBlock = 3,
  DeleteBlock = 4,
  ClearAllBlock = 5,
}

export const enum AnswerContentType {
  Unknown = 0,
  Textual = 1,
  Mathematical = 2,
}

export const enum ReviewGroupType {
  All = 1,
  ByUser = 2,
}

export const enum PassageLineNumType {
  EveryLine = 1,
  EveryFifthLine = 5,
  EveryTenLine = 10,
}

export const enum VariableType {
  Text = 1,
  Number = 2,
  NumberFormula = 3,
}

export const enum VariableSubType {
  Unknown = 0,
  Text = 11,
  NumberInteger = 21,
  NumberDecimal = 22,
  NumberPrime = 23,
  NumberFactor = 24,
  NumberFree = 25,
  NumberFormula = 31,
  NestedNumberFormula = 32,
}

export const enum VariableStatus {
  Unknown = 0,
  Active = 1,
  Inactive = -1,
}

export const enum ComponentStatus {
  Unknown = 0,
  Active = 1,
  Inactive = -1,
}
