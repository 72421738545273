import {
  getLeftQuestionComponents,
  getLeftQuestionContentHtml,
} from '@lib/web/editor';
import EditorComposerRenderer from '@lib/web/editor/EditorTextComposer/EditorComposerRenderer';
import { usePassageEl } from '@lib/web/ui';

import usePracticeQuestion from '../../../../hooks/usePracticeQuestion';

import useRenderPassageWrap from './hooks/useRenderPassageWrap';
import Highlighter from './Highlighter';

export default function QuizPassage() {
  const { creatorQuestionInfo, creatorQuestionGroups, id, contentEnricher } =
    usePracticeQuestion();

  const { setPassageEl } = usePassageEl();

  const content = getLeftQuestionContentHtml(
    getLeftQuestionComponents(creatorQuestionInfo, creatorQuestionGroups)
  );

  const passageWrap = useRenderPassageWrap(content || '', creatorQuestionInfo);

  const renderContent = (
    <Highlighter key={creatorQuestionInfo?.id} examQuestionId={id}>
      <EditorComposerRenderer html={contentEnricher(content)} />
    </Highlighter>
  );
  return (
    <div
      ref={(ref) => {
        setPassageEl(ref);
      }}
    >
      {passageWrap?.(renderContent) || renderContent}
    </div>
  );
}
