import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';
import {
  OtherLink as OtherLinkIcon,
  TextEditorCheck as TextEditorCheckIcon,
} from '@front/icon';
import {
  PopperProps,
  ResponsivePopper,
  ResponsivePopperProps,
  Scrollbar,
  SearchBar,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import {
  useCopyToClipboard,
  useShareToDirect,
  useTempState,
} from '@lib/web/hooks';
import { trackEvent } from '@lib/web/utils';

import useSuggestionMembersLayout from './hooks/useSuggestionMembersLayout';

enum GlobalPanelKeys {
  GlobalAskForHelp = 'GlobalAskForHelp',
}

const styles = {
  menuDropdown: {
    '& .MuiList-root': {
      p: 0,
    },
  },
  bottomSheet: {
    '& .bottom-sheet-content-wrapper': {
      height: '100%',
      pb: '20px',
    },
  },
  container: (suggestionVisible: boolean) => ({
    py: { md: '6px' },
    minWidth: suggestionVisible ? { md: 320 } : { md: 170 },
    height: '100%',
    display: 'grid',
    gridTemplateRows: suggestionVisible
      ? {
          xs: 'max-content 1fr max-content',
          md: 'max-content 244px max-content',
        }
      : 'max-content',
  }),
  scrollWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .ia-icon-list-layout': {
      py: 0,
    },
    '& .ia-icon-list-layout_item-title': {
      typography: 'body1',
      fontWeight: 700,
      fontSize: 16,
    },
  },
  searchBox: {
    px: '12px',
    '& .search-input-wrap': {
      py: '12px',
    },
  },
  sharedLink: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    px: {
      xs: '20px',
      md: '12px',
    },
    height: 61,
    width: '100%',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  sharedLinkActive: {
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  linkIconAvatar: {
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    color: 'text.primary',
  },
  sharedLinkOnly: {
    height: 28,
    '& .MuiAvatar-root': {
      background: 'transparent',
    },
  },
};

export default function AskForHelpDropdown({
  open,
  onClose,
  menuAnchorEl,
  virtualRouter,
  shareLink: shareLinkProp,
  menuProps,
}: {
  open: boolean;
  onClose: () => void;
  menuAnchorEl: PopperProps['anchorEl'];
  menuProps?: ResponsivePopperProps['menuProps'];
  virtualRouter?: VirtualRouter;
  shareLink?: string;
}) {
  const { t } = useTranslation();
  const { openRightPanel } = useBaseRightPanel();

  const [, copy] = useCopyToClipboard();
  const nextRouter = useRouter();
  const router = virtualRouter || nextRouter;
  const { pageSettings, userShareSid } = useShareToDirect(virtualRouter);
  const [layouts, availableActions] = useSuggestionMembersLayout({
    onClose,
  });
  const [copied, setCopied] = useTempState<boolean>(false, { delay: 3000 });
  const shareLink =
    shareLinkProp ??
    (userShareSid
      ? `${process.env.APP_URL}/shared/${userShareSid}${router.asPath}`
      : '');

  const handleCopyLink = async () => {
    await copy(shareLink);
    setCopied(true);
    trackEvent('click', {
      elementName: 'copy-link-button',
      target: 'summary',
    });
  };

  const handleSearchClick = () => {
    openRightPanel(GlobalPanelKeys.GlobalAskForHelp);
    onClose();
  };

  useEffect(() => {
    setCopied(false);
  }, [setCopied, shareLink]);

  const suggestionVisible = !!pageSettings;
  return (
    <ResponsivePopper
      open={open && !!menuAnchorEl}
      onClose={onClose}
      menuProps={{
        anchorEl: menuAnchorEl,
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 16,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ],
        sx: styles.menuDropdown,
        ...menuProps,
      }}
      sheetProps={
        suggestionVisible
          ? {
              sx: styles.bottomSheet,
              defaultContentHeight: 475,
            }
          : {
              fixedHeight: true,
            }
      }
    >
      <Box sx={styles.container(suggestionVisible)}>
        {suggestionVisible && (
          <>
            <Box sx={styles.searchBox}>
              <SearchBar
                placeholder={t('quiz::askForHelp.searchPlaceholder')}
                onClick={handleSearchClick}
              />
            </Box>
            <Box sx={styles.scrollWrapper}>
              <Scrollbar sx={styles.scroll}>
                <IaActionContextProvider availableActions={availableActions}>
                  <IaLayouts layouts={layouts} />
                </IaActionContextProvider>
              </Scrollbar>
            </Box>
          </>
        )}
        <ButtonBase
          sx={[
            styles.sharedLink,
            !!copied && styles.sharedLinkActive,
            !suggestionVisible && styles.sharedLinkOnly,
          ]}
          onClick={handleCopyLink}
          disabled={!shareLink}
        >
          <SquareAvatar
            size={suggestionVisible ? 32 : 16}
            sx={styles.linkIconAvatar}
          >
            {copied ? (
              <TextEditorCheckIcon width={16} height={16} />
            ) : (
              <OtherLinkIcon width={16} height={16} />
            )}
          </SquareAvatar>
          <Typography variant="body1">
            {copied ? t('Link Copied!') : t('Copy Link')}
          </Typography>
        </ButtonBase>
      </Box>
    </ResponsivePopper>
  );
}
