import {
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import nextId from 'react-id-generator';
import { Box } from '@mui/material';
import { apis, buildHookMutate, useFuncHighlights } from '@lib/web/apis';
import { call } from '@lib/web/utils';

import Highlights from './components/Highlights';
import HighlightToolbar from './components/HighlightToolbar';
import { HighlightData, SelectionData } from './types';
import { adjustRangeToIncludeMath, getNodePath } from './utils';

const styles = {
  content: {
    userSelect: 'text',
  },
};

// wait for the component to load correctly
function HighlightTarget({
  children,
  onRendered,
}: PropsWithChildren<{ onRendered: (id: string) => void }>) {
  useEffect(() => {
    onRendered(nextId());
  }, [onRendered]);

  return children;
}

function Highlighter({
  children,
  examQuestionId,
}: PropsWithChildren<{ examQuestionId: string }>) {
  const rootRef = useRef<HTMLDivElement>();
  const targetRef = useRef<HTMLDivElement>();

  const { data, mutate } = useFuncHighlights(examQuestionId);
  const [uniqueId, setUniqueId] = useState('');
  const highlights: HighlightData[] = useMemo(() => {
    if (!data) return [];

    return data.data.highlight;
  }, [data]);

  const [toolbarOpened, setToolbarOpened] = useState(false);
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState(-1);
  const [selectionData, setSelectionData] = useState<SelectionData | null>(
    null
  );

  const currentSelectionData = useMemo(
    () => selectionData || highlights[currentHighlightIndex] || undefined,
    [currentHighlightIndex, highlights, selectionData]
  );

  const mutateHighlight = useMemo(() => buildHookMutate(mutate), [mutate]);

  const handleUpdate = useCallback(
    (value: HighlightData[]) => {
      mutateHighlight(
        call(
          apis.func.updateFuncHighlights({
            examQuestionId,
            highlight: value,
          })
        ),
        {
          optimisticData: {
            highlight: value,
          },
        }
      );
    },
    [examQuestionId, mutateHighlight]
  );

  useEffect(() => {
    const selectionChange = () => {
      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0 || !targetRef.current) {
        setSelectionData(null);
        return;
      }
      const range = selection.getRangeAt(0);

      adjustRangeToIncludeMath(range);

      const startContainer = range.startContainer;
      const endContainer = range.endContainer;
      const startOffset = range.startOffset;
      const endOffset = range.endOffset;
      const targetNode = targetRef.current;
      const selectedText = range.toString();
      if (
        targetNode.contains(range.startContainer) &&
        targetNode.contains(range.endContainer) &&
        selectedText.length > 0
      ) {
        const startPath = getNodePath(targetRef.current, startContainer);
        const endPath = getNodePath(targetRef.current, endContainer);

        setSelectionData({
          startPath,
          startOffset,
          endPath,
          endOffset,
        });
      } else {
        setSelectionData(null);
      }
    };
    document.addEventListener('selectionchange', selectionChange);

    return () => {
      document.removeEventListener('selectionchange', selectionChange);
    };
  });

  const handleColorClick = useCallback(
    (color: string) => {
      if (selectionData) {
        handleUpdate([...highlights, { ...selectionData, color }]);

        const selection = window.getSelection();
        if (selection) {
          selection.empty();
        }
      } else if (highlights[currentHighlightIndex]) {
        handleUpdate(
          highlights.map((highlight, index) =>
            index === currentHighlightIndex
              ? { ...highlight, color }
              : highlight
          )
        );
      }
      setToolbarOpened(false);
      setCurrentHighlightIndex(-1);

      setSelectionData(null);
    },
    [currentHighlightIndex, handleUpdate, highlights, selectionData]
  );
  const handleMouseUp = (ev: MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (currentSelectionData) {
      setToolbarOpened(true);
    } else if (rootRef.current) {
      const rect = rootRef.current.getBoundingClientRect();

      const { clientX, clientY } = ev;
      const x = clientX - rect.left;
      const y = clientY - rect.top;

      const lines = rootRef.current.querySelectorAll('.highlight-line');
      for (let i = lines.length - 1; i >= 0; i--) {
        const child = lines[i];
        const childRect = child.getBoundingClientRect();

        if (
          x >= childRect.left - rect.left &&
          x <= childRect.right - rect.left &&
          y >= childRect.top - rect.top &&
          y <= childRect.bottom - rect.top
        ) {
          (child as HTMLButtonElement).click();
          break;
        }
      }
    }
    return false;
  };

  const handleMouseDown = () => {
    setToolbarOpened(false);
    setCurrentHighlightIndex(-1);
  };

  const handleHighlightClick = (index: number) => {
    setCurrentHighlightIndex(index);
    setToolbarOpened(true);
  };

  const handleRemove = () => {
    handleUpdate(
      highlights.filter((_, index) => index !== currentHighlightIndex)
    );

    setToolbarOpened(false);
    setCurrentHighlightIndex(-1);
  };

  return (
    <Box
      ref={rootRef}
      position="relative"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {highlights.length > 0 && !!targetRef.current && uniqueId && (
        <Highlights
          key={uniqueId}
          targetAnchorEl={targetRef.current}
          data={highlights}
          onClick={handleHighlightClick}
        />
      )}
      <Box ref={targetRef} sx={styles.content}>
        <HighlightTarget key={examQuestionId} onRendered={setUniqueId}>
          {children}
        </HighlightTarget>
      </Box>
      {!!currentSelectionData && !!targetRef.current && toolbarOpened && (
        <HighlightToolbar
          targetAnchorEl={targetRef.current}
          data={currentSelectionData}
          onColorClick={handleColorClick}
          onRemove={handleRemove}
        />
      )}
    </Box>
  );
}

export default function HighlighterRoot({
  children,
  examQuestionId,
  ...rest
}: PropsWithChildren<{ examQuestionId: string }>) {
  const { data } = useFuncHighlights(examQuestionId);

  if (!data?.data) return children;
  return (
    <Highlighter examQuestionId={examQuestionId} {...rest}>
      {children}
    </Highlighter>
  );
}
