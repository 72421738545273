import React from 'react';

export default function EditorContent({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.9139 4.176C19.7994 4.06691 19.6467 4.00403 19.4863 4.00017C19.3261 3.99647 19.1703 4.05194 19.0506 4.15561L9.55526 12.2716C9.42353 12.3847 9.34642 12.5461 9.34246 12.7168C9.33835 12.8876 9.40796 13.0522 9.53411 13.1711L11.5342 15.1132C11.6508 15.2287 11.8099 15.2949 11.9764 15.2971H11.9974C12.1745 15.295 12.3425 15.2208 12.4606 15.0926L20.8401 5.87272C20.9467 5.7565 21.004 5.6052 21 5.44962C20.996 5.2939 20.9314 5.14559 20.8189 5.03451L19.9139 4.176ZM11.9555 13.7844L10.9028 12.7622L12.3555 11.5151L13.2398 12.3738L11.9555 13.7844Z"
        fill="currentColor"
      />
      <path
        d="M8.88137 13.9276C8.76476 13.8121 8.60586 13.7459 8.43931 13.7437C7.68137 13.7437 6.27075 14.0708 5.30223 16.3195C4.73373 17.6482 3.80732 18.2003 3.59689 18.2615C3.4026 18.272 3.22416 18.3686 3.11314 18.5237C3.00212 18.6787 2.97069 18.8747 3.02826 19.0551C3.08568 19.2357 3.22549 19.3803 3.4073 19.4473C4.31477 19.7898 5.27641 19.9766 6.24967 19.9993C7.01938 20.0141 7.78025 19.8383 8.46037 19.4882C9.61838 18.8749 10.3763 17.7301 10.7552 16.0946C10.8046 15.8884 10.741 15.6719 10.5868 15.5222L8.88137 13.9276Z"
        fill="currentColor"
      />
    </svg>
  );
}
