import generateHook, { generateInfiniteHook } from './swr.helper';

export const useHashtags = (
  param: Pick<GetHashtagReq, 'sectionId' | 'keyword' | 'type'>,
  pageSize = 10
) => {
  if (!param.sectionId) {
    return generateInfiniteHook<GetHashtagRes>(() => null, pageSize, {
      auth: true,
    });
  }
  const urlParam = new URLSearchParams();
  urlParam.set('sectionId', param.sectionId);
  if (param.keyword) urlParam.set('keyword', param.keyword);
  if (param.type) urlParam.set('type', param.type);

  return generateInfiniteHook<GetHashtagRes>(
    (index) =>
      `v2/hashtags?page=${index + 1}&limit=${pageSize}&${urlParam.toString()}`,
    pageSize,
    {
      auth: true,
    }
  );
};

export const useHashtagDetail = (param: GetHashtagDetailReq) => {
  const urlParam = new URLSearchParams();
  if (param.tagId) urlParam.set('tagId', param.tagId);
  if (param.sectionId) urlParam.set('sectionId', param.sectionId);

  return generateHook<GetHashtagDetailRes>(
    `v2/hashtags/statistics/me?${urlParam.toString()}`,
    {
      auth: true,
    }
  );
};
