import { useEffect, useMemo } from 'react';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { uniqBy } from 'lodash';
import { Channel } from 'stream-chat';

export const useChannelsHierarchyDisplay = ({
  channels,
}: {
  channels: Channel<StreamChatGenerics>[];
}) => {
  const { parentCidToChildChannels, checkChannelsHierarchy } = useThread();

  const channelCids = useMemo(
    () => new Set(channels.map((c) => c.cid)),
    [channels]
  );

  const channelsAndTheirDescendants = useMemo(
    () =>
      uniqBy(
        [
          ...channels,
          ...Object.values(parentCidToChildChannels)
            .flat()
            .filter((c) =>
              c.data?.ancestorChannelCids?.some((cid) => channelCids.has(cid))
            ),
        ],
        (c) => c.cid
      ),
    [channelCids, channels, parentCidToChildChannels]
  );

  const firstLevelChannels = useMemo(
    () =>
      channels.filter(
        (c) =>
          /**
           * no parent means it is first level channel
           */
          !c.data?.parentChannelCid ||
          /**
           * even if this channel has parent, if we didn't have the parent inside original channels
           * means the parent is not visible to this view, so it is first level channels
           */
          !channelCids.has(c.data.parentChannelCid)
      ),
    [channels, channelCids]
  );

  const messageIdToChildChannel = useMemo(() => {
    return channelsAndTheirDescendants
      .filter((channel) => !!channel.data?.parentMessageId)
      .reduce(
        (acc, channel) => ({
          ...acc,
          [channel.data?.parentMessageId as string]: channel,
        }),
        {}
      );
  }, [channelsAndTheirDescendants]);

  useEffect(() => {
    checkChannelsHierarchy(channels);
  }, [channels, checkChannelsHierarchy]);

  return {
    parentCidToChildChannels,
    firstLevelChannels,
    channelsAndTheirDescendants,
    messageIdToChildChannel,
  };
};
