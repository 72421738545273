import React from 'react';
import { Box, Chip } from '@mui/material';

import { TableLayoutChipCell, TableLayoutRow } from '../../types';

const styles = {
  root: {
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  chip: {
    '& .MuiChip-label': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};
export type IaChipCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutChipCell;
  columnKey: string;
};

export default function IaChipCell({ cell }: IaChipCellProps) {
  return (
    <Box sx={styles.root}>
      <Chip
        sx={styles.chip}
        label={cell.text}
        color={cell.color}
        variant={cell.variant || 'outlined'}
        size="small"
      />
    </Box>
  );
}
