import { ReactNode, useEffect, useState } from 'react';

const findAllImageSrcInsideHtml = (html: string) => {
  const regex = /<img[^>]+src="([^">]+)"/gi;
  const matches = [...html.matchAll(regex)];
  return matches.map((match) => match[1]?.replaceAll(/&amp;/g, '&'));
};

const loadImage = (src: string) => {
  return new Promise<void>((resolve) => {
    const img = new Image();
    img.onload = () => resolve();
    img.src = src;
  });
};

export default function DeferRenderAfterHtmlImageLoad({
  html,
  children,
}: {
  html: string;
  children: ReactNode;
}) {
  const [loadedHtml, setLoadedHtml] = useState<string | null>(null);
  const [loadedChildren, setLoadedChildren] = useState<ReactNode | null>(null);

  useEffect(() => {
    if (html === loadedHtml) return;

    const loadImageFromHtml = async () => {
      const srcs = findAllImageSrcInsideHtml(html);
      await Promise.all(srcs.map(loadImage));
      setLoadedHtml(html);
      setLoadedChildren(children);
    };

    void loadImageFromHtml();
  }, [children, html, loadedHtml]);

  return loadedChildren;
}
