import { createContext, Dispatch, SetStateAction } from 'react';

export type LineNumberValue = {
  num: number;
  top: number;
  lineHeight: number;
};
export type LineNumberWrapValue = LineNumberValue[];

const defaultValue: LineNumberWrapValue = [];
type LineNumberWrapContextValue = [
  LineNumberWrapValue,
  Dispatch<SetStateAction<LineNumberWrapValue>>
];

const LineNumberWrapContext = createContext<LineNumberWrapContextValue>([
  defaultValue,
  () => {},
]);

export default LineNumberWrapContext;
