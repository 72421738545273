import { useMemo } from 'react';
import Link from 'next/link';
import Box from '@mui/material/Box';
import {
  Button,
  DropdownButton,
  DropdownButtonOption,
  Icon,
  IconButton,
  TextButton,
} from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { TableLayoutFooterButtonCell } from '../../types';

export type IaFooterButtonCellProps = {
  cell: TableLayoutFooterButtonCell;
};

const styles = {
  root: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    overflow: 'hidden',
  },
  button: {
    minWidth: 0,
    minHeight: '24px',
    px: 0,
    fontSize: '12px',
    borderWidth: '2px',
  },
};
export default function IaFooterButtonCell({ cell }: IaFooterButtonCellProps) {
  const {
    buttonType,
    text,
    icon,
    prefixIcon,
    suffixIcon,
    action,
    buttonSx,
    dropdownOptions = [],
  } = cell;
  const { getIaAction } = useIaAction();
  const ButtonComponent =
    buttonType === 'iconButton'
      ? IconButton
      : buttonType === 'textButton'
      ? TextButton
      : Button;

  const linkProps =
    action?.type === 'link' ? { LinkComponent: Link, href: action.value } : {};

  const handleClick = () => {
    if (action?.type !== 'event') return;
    const clickAction = getIaAction(action.value);
    clickAction?.action();
  };

  const buttonSxProps = Array.isArray(buttonSx) ? buttonSx : [buttonSx];

  const options = useMemo<DropdownButtonOption[]>(() => {
    if (dropdownOptions.length === 0) return [];
    return dropdownOptions.map((option) => ({
      display: option.text,
      icon: <Icon name={option.icon} width={16} height={16} />,
      onClick: () => {
        if (!option.clickAction) return;
        const clickAction = getIaAction(option.clickAction);
        clickAction?.action();
      },
    }));
  }, [dropdownOptions, getIaAction]);

  const iconButtonProps = buttonType === 'iconButton' ? { customSize: 24 } : {};
  const buttonProps =
    buttonType !== 'iconButton'
      ? {
          prefixIcon: prefixIcon ? <Icon name={prefixIcon} /> : undefined,
          suffixIcon: suffixIcon ? <Icon name={suffixIcon} /> : undefined,
        }
      : {};
  return (
    <Box sx={styles.root}>
      <DropdownButton suffixIconDisabled options={options}>
        <ButtonComponent
          {...linkProps}
          {...iconButtonProps}
          {...buttonProps}
          onClick={handleClick}
          sx={[styles.button, ...buttonSxProps]}
        >
          {text}
          {icon && <Icon name={icon} />}
        </ButtonComponent>
      </DropdownButton>
    </Box>
  );
}
