import React from 'react';

export default function OtherFollowing({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10 17L13 14.06C12.61 14.02 12.32 14 12 14C9.33 14 4 15.34 4 18V20H13L10 17ZM12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M15.47 20.5L12 17L13.4 15.59L15.47 17.67L20.6 12.5L22 13.91L15.47 20.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
