import useSWRImmutable from 'swr/immutable';

export default async function fetcher<JSON = unknown>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const res = await fetch(input, init);
  if (!res.ok) {
    const error = await res.json();
    throw error;
  }

  return res.json();
}

export const useHeaders = () =>
  useSWRImmutable<Response<GetHeadersRes>>('/api/get-headers', fetcher, {
    refreshInterval: 30000,
  });
