import React from 'react';

export default function TextEditorClearFormat({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.73 20L19 18.73L4.55 4.27L4.27 4L3 5.27L9.97 12.24L7.5 18H10.5L12.07 14.34L17.73 20ZM11.5 8.68L13.6 10.78L15.21 7H21V4H7V4.18L9.82 7H12.22L11.5 8.68Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
