import React from 'react';

export default function OtherFriendsChallenge({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.94426 14.0002C10.0447 14.2244 10.1893 14.4329 10.3782 14.6123C10.1938 14.7508 10.0241 14.9148 9.87422 15.1031C8.94667 16.2685 9.07011 17.9995 10.2051 19.0018C11.3872 20.0456 13.155 19.8563 14.1181 18.6463C14.2389 18.4945 14.3416 18.3338 14.4265 18.1669C15.2524 18.7232 16.3658 18.543 16.9861 17.7637C17.2684 17.409 17.4101 16.9782 17.4116 16.5477C17.7818 16.9776 18 17.4618 18 18V20H2V18C2 15.3584 7.25657 14.0186 9.94426 14.0002ZM12.2275 11.3229C11.5017 11.1128 10.6936 11.3533 10.199 11.9747C10.1934 11.9817 10.1878 11.9888 10.1823 11.9959C10.1219 11.9986 10.0611 12 10 12C7.79086 12 6 10.2091 6 8C6 5.79086 7.79086 4 10 4C12.2091 4 14 5.79086 14 8C14 9.38457 13.2965 10.6048 12.2275 11.3229Z"
        fill="currentColor"
      />
      <path
        d="M21.5745 7.25301C21.491 7.69515 21.3029 8.1083 21.0277 8.45407L15.4176 15.5026L16.0046 16.021C16.1832 16.1787 16.2061 16.4596 16.0558 16.6484C15.9055 16.8372 15.6389 16.8625 15.4604 16.7048L13.8869 15.3153L13.3133 16.036C13.5666 16.4977 13.533 17.0972 13.1878 17.531C12.747 18.0848 11.9651 18.1588 11.4414 17.6963C10.9176 17.2338 10.8504 16.4099 11.2912 15.8561C11.6364 15.4224 12.1908 15.2829 12.6666 15.465L13.2403 14.7442L11.6672 13.3551C11.4886 13.1974 11.4657 12.9164 11.616 12.7276C11.7663 12.5388 12.0329 12.5136 12.2114 12.6713L13.3306 13.6596L18.9407 6.61107C19.216 6.2653 19.5694 5.99813 19.9681 5.83439L22 5L21.5745 7.25301Z"
        fill="currentColor"
      />
    </svg>
  );
}
