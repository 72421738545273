import React from 'react';

export default function SocialMediaLinkedin({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.75 3H17.25C19.3215 3 21 4.67925 21 6.75V17.25C21 19.3207 19.3215 21 17.25 21H6.75C4.67925 21 3 19.3207 3 17.25V6.75C3 4.67925 4.67925 3 6.75 3ZM9 17.25V9H6.75V17.25H9ZM7.875 8.04932C7.1505 8.04932 6.5625 7.45682 6.5625 6.72632C6.5625 5.99582 7.1505 5.40332 7.875 5.40332C8.5995 5.40332 9.1875 5.99582 9.1875 6.72632C9.1875 7.45682 8.60025 8.04932 7.875 8.04932ZM15.75 17.2499H18V12.1806C18 8.24086 13.797 8.38411 12.75 10.3236V8.99986H10.5V17.2499H12.75V13.0469C12.75 10.7121 15.75 10.5209 15.75 13.0469V17.2499Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
