import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

export interface QuestionSideMenuValue {
  openedItemId?: string;
  refMap?: Record<string, HTMLElement>;
}

const defaultValue: QuestionSideMenuValue = {};

type QuestionSideMenuContextValue = [
  QuestionSideMenuValue,
  Dispatch<SetStateAction<QuestionSideMenuValue>>
];

type QuestionSideMenuProviderProps = {
  children: ReactNode;
  initialValue?: QuestionSideMenuValue;
};

export const QuestionSideMenuContext =
  createContext<QuestionSideMenuContextValue>([defaultValue, () => {}]);

export default function QuestionSideMenuProvider({
  initialValue = defaultValue,
  children,
}: QuestionSideMenuProviderProps) {
  const providerValue = useState<QuestionSideMenuValue>(initialValue);

  return (
    <QuestionSideMenuContext.Provider value={providerValue}>
      {children}
    </QuestionSideMenuContext.Provider>
  );
}
