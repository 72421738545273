import { useContext } from 'react';
import axios from 'axios';
import useSWR from 'swr';

import IaDataContext from '../core/IaData/IaDataContext';

interface GetMemberInfoRes {
  avatar: string;
  nftAvatar: string | null;
  displayName: string;
  distinctName: string;
  userId: string;
  memberId: string;
  isFriend: boolean;
  indicator?: string;
}

export const useMemberNftAvatarMultiple = ({
  userIds = [],
}: {
  userIds?: string[];
}) => {
  const { token, apiUrl = '', sid } = useContext(IaDataContext);
  const keys = [...userIds].sort();
  const { data } = useSWR<{ data: GetMemberInfoRes[] }>(
    keys.length > 0 ? keys.join(',') : null,
    async () => {
      if (!token && !sid) return null;
      if (keys.length === 0) return null;

      return axios
        .post(
          'v2/member/info',
          {
            userIds,
            isGetNft: true,
            sid: sid || undefined,
          },
          {
            baseURL: apiUrl,
            headers: token ? { Authorization: `Bearer ${token}` } : undefined,
          }
        )
        .then((response) => response.data);
    }
  );

  return data?.data
    .map((item) => ({
      avatarUrl: item.nftAvatar || item.avatar,
      name: item.displayName,
      userId: item.userId,
    }))
    .sort((a, b) => userIds.indexOf(a.userId) - userIds.indexOf(b.userId));
};
