import {
  rand,
  randEmoji,
  randNumber,
  randPastDate,
  randText,
} from '@ngneat/falso';
import { v4 } from 'uuid';

export const mockAgents: any[] = [
  {
    id: v4(),
    aiModelId: '',
    userName: '@agggasd',
    displayName: 'Almighty Math Pro',
    photoPath: 'https://picsum.photos/100',
    shortDesc: 'Your Ultimate Academic Excellence Partner for CBSE.',
    longDesc:
      'Meet Math Maestro SAT: Your AI ally for crafting SAT Math calculator questions that challenge and inspire. Elevate your prep with precision and innovation.',
    permission: 2,
    visibility: 1,
    userCount: 241,
    threadCount: 6500000,
    joinStatus: 1,
    clubId: '',
    createdAt: randPastDate().toUTCString(),
    creatorId: '573fb24e-b708-41be-846d-cef059e09af1',
    displayOrder: 1,
    status: 1,
    updatedAt: '',
  },
  {
    id: v4(),
    aiModelId: '',
    userName: '@mathmaestro',
    displayName: 'BrainWaveAI',
    photoPath: 'https://picsum.photos/200',
    shortDesc: 'Your Ultimate Academic Excellence Partner for CBSE.',
    longDesc:
      'Meet Math Maestro SAT: Your AI ally for crafting SAT Math calculator questions that challenge and inspire. Elevate your prep with precision and innovation.',
    permission: 2,
    visibility: 1,
    userCount: 241,
    threadCount: 6500000,
    joinStatus: 1,
    clubId: '',
    createdAt: randPastDate().toUTCString(),
    creatorId: '573fb24e-b708-41be-846d-cef059e09af1',
    displayOrder: 2,
    status: 1,
    updatedAt: '',
  },
  {
    id: v4(),
    aiModelId: '',
    userName: '@taestro',
    displayName: 'Essay Eagle',
    photoPath: 'https://picsum.photos/201',
    shortDesc: 'Your Ultimate Academic Excellence Partner for CBSE.',
    longDesc:
      'Meet Math Maestro SAT: Your AI ally for crafting SAT Math calculator questions that challenge and inspire. Elevate your prep with precision and innovation.',
    permission: 2,
    visibility: 1,
    userCount: 1,
    threadCount: 0,
    joinStatus: 1,
    clubId: '',
    createdAt: randPastDate().toUTCString(),
    creatorId: '573fb24e-b708-41be-846d-cef059e09af1',
    displayOrder: 3,
    status: 1,
    updatedAt: '',
  },
];

export const mockAgentPrompts: any[] = [
  {
    id: v4(),
    agentId: rand(mockAgents.map((a) => a.id)),
    charCount: randNumber({ min: 10, max: 4000 }),
    createdAt: randPastDate().toUTCString(),
    displayOrder: 1,
    icon: randEmoji(),
    name: 'Improve Writing',
    prompt: randText({ charCount: 100 }),
    status: 1,
    updatedAt: '',
  },
  {
    id: v4(),
    agentId: rand(mockAgents.map((a) => a.id)),
    charCount: randNumber({ min: 10, max: 4000 }),
    createdAt: randPastDate().toUTCString(),
    displayOrder: 1,
    icon: randEmoji(),
    name: 'Improve Writing',
    prompt: randText({ charCount: 100 }),
    status: 1,
    updatedAt: '',
  },
  {
    id: v4(),
    agentId: rand(mockAgents.map((a) => a.id)),
    charCount: randNumber({ min: 10, max: 4000 }),
    createdAt: randPastDate().toUTCString(),
    displayOrder: 1,
    icon: randEmoji(),
    name: 'Improve Writing',
    prompt: randText({ charCount: 100 }),
    status: 1,
    updatedAt: '',
  },
];
