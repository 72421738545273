import { ReactNode } from 'react';
import { ThemeProvider } from '@mui/material/styles';

import createTheme from './theme';

type ThemeProps = {
  children: ReactNode;
  mode?: 'light' | 'dark';
};
export default function CustomThemeProvider({
  children,
  mode = 'dark',
}: ThemeProps) {
  const theme = createTheme({
    mode,
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
