import {
  AnswerFormatType,
  apis,
  useCreatorQuestionPreference,
} from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { call } from '@lib/web/utils';

type GetCreatorQuestionPreferenceResKey = keyof GetCreatorQuestionPreferenceRes;
const preferenceFieldToAnswerFormat: Record<
  GetCreatorQuestionPreferenceResKey,
  AnswerFormatType | null
> = {
  isOfferFormula: null,
  isOfferCalculator: null,
  mcqMinAnswerNum: AnswerFormatType.MultipleChoice,
  mcqMaxAnswerNum: AnswerFormatType.MultipleChoice,
  mcqMinCorrectAnswerNum: AnswerFormatType.MultipleChoice,
  mcqMaxCorrectAnswerNum: AnswerFormatType.MultipleChoice,
  mrqMinAnswerNum: AnswerFormatType.MultipleResponse,
  mrqMaxAnswerNum: AnswerFormatType.MultipleResponse,
  mrqMinCorrectAnswerNum: AnswerFormatType.MultipleResponse,
  mrqMaxCorrectAnswerNum: AnswerFormatType.MultipleResponse,
  unscrambleMinAnswerNum: AnswerFormatType.Unscramble,
  unscrambleMaxAnswerNum: AnswerFormatType.Unscramble,
  essayAnswerWordLimitMin: AnswerFormatType.Essay,
  essayAnswerWordLimitMax: AnswerFormatType.Essay,
};

// we have these answerFormats, find all possible fields need to be validated
const collectFieldsNeedToBeValidated = (
  questionAnswerFormats: AnswerFormatType[]
) => {
  const toValidateFields: GetCreatorQuestionPreferenceResKey[] = [];

  questionAnswerFormats.forEach((f) => {
    for (const [filed, format] of Object.entries(
      preferenceFieldToAnswerFormat
    )) {
      if (format === f) {
        toValidateFields.push(filed as GetCreatorQuestionPreferenceResKey);
      }
    }
  });

  return toValidateFields;
};

const findSectionIdFromClubDetail = (
  clubSlug: string,
  clubDetail: GetClubDetailRes
) => {
  return (
    clubDetail.apps.find((app) => app.slug === clubSlug)?.referenceId || null
  );
};

const queryPreferenceDataFromClubSlug = async (clubSlug: string) => {
  const [clubRes] = await call(apis.club.getClubDetail(clubSlug));
  if (!clubRes) {
    console.warn('club detail not found');
    return null;
  }

  const sectionId = findSectionIdFromClubDetail(clubSlug, clubRes.data);
  const [preferenceRes] = await call(
    apis.editor.getCreatorQuestionPreference(sectionId)
  );
  if (!preferenceRes) {
    console.warn('preference not found');
    return null;
  }

  return preferenceRes.data;
};

export const useQueryClubCompatible = () => {
  const { sectionId: currentClubSectionId } = useCurrentIaClub();
  const { data: currentClubPreferenceData } =
    useCreatorQuestionPreference(currentClubSectionId);

  return async ({
    questionAnswerFormats,
    clubSlugs,
  }: {
    questionAnswerFormats: AnswerFormatType[];
    clubSlugs: string[];
  }) => {
    const toValidateFields = collectFieldsNeedToBeValidated(
      questionAnswerFormats
    );

    if (toValidateFields.length === 0) {
      return true;
    }

    const preferenceDatas = await Promise.all(
      clubSlugs.map(queryPreferenceDataFromClubSlug)
    );

    for (const preferenceData of preferenceDatas) {
      for (const field of toValidateFields) {
        if (
          preferenceData?.[field] !== currentClubPreferenceData?.data[field]
        ) {
          return false;
        }
      }
    }

    return true;
  };
};
