import React from 'react';

export default function OtherReset({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.35147 6.35C7.80238 4.9 9.79362 4 12.005 4C16.4278 4 20 7.58 20 12C20 16.42 16.4278 20 12.005 20C8.27267 20 5.16073 17.45 4.27017 14H6.35147C7.17198 16.33 9.39337 18 12.005 18C15.3171 18 18.0088 15.31 18.0088 12C18.0088 8.69 15.3171 6 12.005 6C10.344 6 8.86304 6.69 7.78236 7.78L11.0044 11H4V4L6.35147 6.35Z"
        fill="currentColor"
      />
    </svg>
  );
}
