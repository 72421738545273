import { Box, Skeleton, Typography } from '@mui/material';

import { DisplayTableLayoutConfig } from '../types';

const styles = {
  root: {
    width: '100%',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    px: 1,
  },
  content: {
    opacity: 0.75,
  },
};

type IaDisplayTableDataStateRowProps = {
  data?: DisplayTableLayoutConfig['table']['data'];
};

export default function IaDisplayTableDataStateRow({
  data,
}: IaDisplayTableDataStateRowProps) {
  if (!data) return null;

  const { state, emptyRow } = data;

  if (state === 'loaded') return null;
  if (state === 'loading') {
    return (
      <Box sx={styles.root}>
        <Skeleton width={400} />
      </Box>
    );
  }
  if (state === 'empty') {
    return (
      <Box sx={styles.root}>
        <Typography variant="body2" sx={styles.content}>
          {emptyRow?.text || 'No data'}
        </Typography>
      </Box>
    );
  }
}
