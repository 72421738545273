import { useEffect } from 'react';
import { alpha, Box, Theme } from '@mui/material';
import { CUSTOM_BR } from '@lib/web/composer';
import { EditorBlockTypes } from '@lib/web/editor/configs';
import { useEditorSelector } from '@lib/web/editor/redux';
import { selectOptionIndexById } from '@lib/web/editor/redux/reducers/settingReducer/selector';
import { TextComposerPanelKeys } from '@lib/web/editor/TextComposerPanels/panel';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { v4 } from 'uuid';

import ThemeProvider from '../../../../../theme/ThemeProvider';
import Anchor from '../Anchor';
import Content from '../Content';

const styles = {
  label: {
    typography: 'subtitle2',
    verticalAlign: 'text-bottom',
    lineHeight: '18px',
    minWidth: 22,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'background.darker',
    bgcolor: 'text.primary',
    cursor: 'pointer',
  },
  showing: {
    position: 'relative',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      bgcolor: (theme: Theme) => alpha(theme.palette.background.darker, 0.3),
    },
  },
};
export type InlineHighlightAnchorOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineHighlightAnchorOptions;
    node: ProseMirrorNode;
  }) => string;
};

type HighlightAnchorProps = {
  editor: Editor;
  node: ProseMirrorNode;
  getPos: (() => number) | boolean;
  updateAttributes: (attributes: Record<string, any>) => void;
  deleteNode: () => void;
};

const HighlightAnchor = ({
  editor,
  node,
  getPos,
  deleteNode,
  updateAttributes,
}: HighlightAnchorProps) => {
  const orderIndex = useEditorSelector((st) =>
    selectOptionIndexById(st.setting)(node.attrs.id)
  );

  const pos = typeof getPos === 'function' ? getPos() : null;
  const onDelete = () => {
    if (!pos) return;
    editor
      .chain()
      .insertContentAt(pos, [
        {
          type: 'text',
          text: node.attrs.value,
        },
      ])
      .run();

    deleteNode();
  };

  useEffect(() => {
    if (orderIndex !== node.attrs.orderIndex) {
      setTimeout(() => {
        try {
          updateAttributes({
            orderIndex,
          });
        } catch (e) {
          console.warn('updateAttributes fail', e);
        }
      });
    }
  }, [node.attrs.orderIndex, orderIndex, updateAttributes]);

  return (
    <NodeViewWrapper
      style={{ display: 'inline' }}
      data-anchor-id={node.attrs.id}
      data-anchor-value={node.attrs.value}
      className="inline-anchor inline-highlight"
      contentEditable={false}
    >
      <ThemeProvider mode="dark">
        <Anchor
          id={node.attrs.id}
          value={node.attrs.value}
          updateAttributes={updateAttributes}
          panelKey={TextComposerPanelKeys.TextComposerHighlight}
          placeholder="Write highlight content here..."
        >
          {({ isShow, isHover }) => (
            <Box
              sx={[
                styles.label,
                isShow && styles.showing,
                isHover && styles.showing,
              ]}
              component="span"
            >
              {orderIndex > -1 ? orderIndex + 1 : '-'}
            </Box>
          )}
        </Anchor>
        <Content
          id={node.attrs.id}
          onDelete={onDelete}
          updateAttributes={updateAttributes}
          text={node.attrs.value}
        />
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export const InlineHighlightAnchor = Node.create<InlineHighlightAnchorOptions>({
  name: EditorBlockTypes.InlineHighlightAnchor,
  group: 'inline',
  inline: true,
  content: 'inline*',
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: v4,
        parseHTML: () => v4(), // when copy and paste, generate a new id
      },
      value: {
        default: '',
      },
      orderIndex: {
        default: 0,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.name,
      },
    ];
  },

  renderHTML({ node }) {
    const content: (string | string[])[] = [];
    const textSplit = (node.attrs.value as string).split(CUSTOM_BR);
    textSplit.forEach((text, index) => {
      content.push(text);
      if (index !== textSplit.length - 1) content.push(['br']);
    });

    return [
      'span',
      mergeAttributes({
        class: this.name,
        'data-render-id': node.attrs.id,
        'data-content-type': this.name,
      }),
      [
        'span',
        {
          class: 'highlight-order',
          'data-target-id': node.attrs.id,
          style:
            'display: inline-flex; align-items: center; justify-content: center; margin-right: 4px; color: var(--inline-highlight-order-color); background-color: var(--inline-highlight-order-bgcolor);',
        },
        `${node.attrs.orderIndex + 1}`,
      ],
      [
        'span',
        {
          class: 'highlight-text',
          style: 'text-decoration: underline',
        },
        ...content,
      ],
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(HighlightAnchor);
  },
});
