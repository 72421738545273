import React, { SVGAttributes } from 'react';

export default function NFTEmojiExpressionless({
  ...rest
}: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width="55"
      height="100"
      viewBox="0 0 55 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.86665 23.1849L3.7821 21.3678L8.15059 19.1903L11.2351 21.0073L6.86665 23.1849Z"
        fill="#E27C04"
      />
      <path
        d="M9.77034 20.1778L6.68579 18.3608L11.0543 16.1832L14.1388 18.0002L9.77034 20.1778Z"
        fill="#EC941D"
      />
      <path
        d="M12.6927 17.1099L9.56055 15.2648L13.8946 13.1044L17.0268 14.9495L12.6927 17.1099Z"
        fill="#EC941D"
      />
      <path
        d="M15.5231 14.0701L12.4386 12.2531L25.1079 5.93774L28.1925 7.75476L15.5231 14.0701Z"
        fill="#EC941D"
      />
      <path
        d="M18.6212 15.9303L15.4908 14.0863L28.2811 7.71062L31.4114 9.5546L18.6212 15.9303Z"
        fill="#EEBB10"
      />
      <path
        d="M21.6657 17.731L18.5778 15.912L31.3116 9.56444L34.3995 11.3834L21.6657 17.731Z"
        fill="#E8CC11"
      />
      <path
        d="M37.4165 13.1843L24.66 19.5431L23.1161 18.6336L21.5721 17.7241L34.3286 11.3653L37.4165 13.1843Z"
        fill="#E8CC11"
      />
      <path
        d="M40.465 15.0704L27.7763 21.3954L26.1941 20.4633L24.6118 19.5313L37.3005 13.2062L40.465 15.0704Z"
        fill="#E8CC11"
      />
      <path
        d="M43.5348 16.9156L30.8347 23.2463L29.2692 22.3241L27.7037 21.4019L40.4038 15.0712L43.5348 16.9156Z"
        fill="#EEBB10"
      />
      <path
        d="M46.5836 18.7706L33.9378 25.0742L32.3855 24.1598L30.8333 23.2454L43.4791 16.9418L46.5836 18.7706Z"
        fill="#EC941D"
      />
      <path
        d="M33.913 25.0714L46.4793 18.6656L46.5449 23.618L34.1667 29.9731L33.913 25.0714Z"
        fill="#E28C18"
      />
      <path
        d="M37.229 31.7338L49.3518 25.2318L49.4143 29.9092L37.3617 36.6734L37.229 31.7338Z"
        fill="#E28B18"
      />
      <path
        d="M40.4978 38.4409L52.1217 31.7997L52.1907 37.0273L40.5651 43.5107L40.4978 38.4409Z"
        fill="#E28B18"
      />
      <path
        d="M43.7355 45.1538L55.5509 38.6338L55.6129 43.3111L43.8007 50.0722L43.7355 45.1538Z"
        fill="#E47C01"
      />
      <path
        d="M43.8445 50.0904L55.6127 43.3112L55.6774 48.2637L44.0824 54.9593L43.8445 50.0904Z"
        fill="#EE9808"
      />
      <path
        d="M43.9999 54.7257L55.7439 47.9656L55.5375 53.1968L44.2577 59.9105L43.9999 54.7257Z"
        fill="#E8980A"
      />
      <path
        d="M44.067 59.9513L55.4684 53.2198L55.5375 58.4474L44.2974 64.7606L44.067 59.9513Z"
        fill="#E38D07"
      />
      <path
        d="M44.1715 64.8209L55.526 58.4475L55.7709 63.6729L44.6825 69.874L44.1715 64.8209Z"
        fill="#E79000"
      />
      <path
        d="M44.2838 69.7725L55.7431 63.6732L55.8105 68.7219L44.5154 74.5931L44.2838 69.7725Z"
        fill="#DA8609"
      />
      <path
        d="M36.1561 82.9948L47.4346 76.2971L47.6877 82.5137L36.2282 88.4397L36.1561 82.9948Z"
        fill="#D3850C"
      />
      <path
        d="M38.8881 80.6539L50.0279 73.9746L50.2758 79.6689L39.1092 85.3524L38.8881 80.6539Z"
        fill="#BD6C02"
      />
      <path
        d="M41.8715 77.5519L52.6675 71.0943L52.9105 76.9197L42.095 82.2916L41.8715 77.5519Z"
        fill="#C47104"
      />
      <path
        d="M44.7397 74.2761L55.8043 68.6257L56.0511 73.851L44.8062 79.2286L44.7397 74.2761Z"
        fill="#BA6505"
      />
      <path
        d="M49.3179 25.2323L37.2043 31.7667L35.6767 30.8669L34.1491 29.967L46.5447 23.618L49.3179 25.2323Z"
        fill="#EC941D"
      />
      <path
        d="M52.1575 31.7992L40.4266 38.502L38.8918 37.5979L37.357 36.6938L49.3806 29.9097L52.1575 31.7992Z"
        fill="#EC941D"
      />
      <path
        d="M55.55 38.6339L43.7203 45.1815L42.1968 44.2841L40.6733 43.3866L52.2265 37.0268L55.55 38.6339Z"
        fill="#ED8102"
      />
      <path
        d="M36.1758 83.5772L33.1027 81.7408L33.2851 86.616L36.3582 88.4524L36.1758 83.5772Z"
        fill="#E28F10"
      />
      <path
        d="M33.1026 81.7408L30.0295 79.9045L30.212 84.7797L33.2851 86.616L33.1026 81.7408Z"
        fill="#DF8909"
      />
      <path
        d="M30.0296 79.9045L26.9565 78.0682L27.139 82.9434L30.2121 84.7797L30.0296 79.9045Z"
        fill="#E7940E"
      />
      <path
        d="M26.9565 78.0682L23.8834 76.2319L24.0659 81.1071L27.139 82.9434L26.9565 78.0682Z"
        fill="#E7940E"
      />
      <path
        d="M23.8835 76.2319L20.8104 74.3956L20.9929 79.2707L24.066 81.1071L23.8835 76.2319Z"
        fill="#E7940E"
      />
      <path
        d="M20.8104 74.3956L17.7373 72.5592L17.9198 77.4344L20.9928 79.2708L20.8104 74.3956Z"
        fill="#DF8909"
      />
      <path
        d="M17.7372 72.5592L14.6641 70.7228L14.8465 75.598L17.9196 77.4344L17.7372 72.5592Z"
        fill="#E28F10"
      />
      <path
        d="M39.0664 80.5383L35.9933 78.702L36.1757 83.5772L39.2488 85.4135L39.0664 80.5383Z"
        fill="#D67C05"
      />
      <path
        d="M35.9933 78.702L32.9202 76.8656L33.1026 81.7408L36.1757 83.5772L35.9933 78.702Z"
        fill="#EC9C12"
      />
      <path
        d="M32.9203 76.8656L29.8472 75.0293L30.0296 79.9045L33.1027 81.7408L32.9203 76.8656Z"
        fill="#F5A115"
      />
      <path
        d="M29.8471 75.0293L26.774 73.193L26.9565 78.0682L30.0296 79.9045L29.8471 75.0293Z"
        fill="#FBA916"
      />
      <path
        d="M26.7741 73.193L23.701 71.3567L23.8835 76.2319L26.9566 78.0682L26.7741 73.193Z"
        fill="#F6A319"
      />
      <path
        d="M23.701 71.3567L20.6279 69.5204L20.8104 74.3956L23.8835 76.2319L23.701 71.3567Z"
        fill="#FBA916"
      />
      <path
        d="M20.6279 69.5204L17.5548 67.684L17.7373 72.5592L20.8103 74.3956L20.6279 69.5204Z"
        fill="#F6A418"
      />
      <path
        d="M17.5548 67.684L14.4817 65.8476L14.6641 70.7228L17.7372 72.5592L17.5548 67.684Z"
        fill="#EC9C12"
      />
      <path
        d="M14.4817 65.8476L11.4086 64.0113L11.591 68.8865L14.6641 70.7228L14.4817 65.8476Z"
        fill="#E6960E"
      />
      <path
        d="M41.957 77.4994L38.8839 75.6631L39.0664 80.5383L42.1394 82.3746L41.957 77.4994Z"
        fill="#D57C05"
      />
      <path
        d="M38.884 75.6631L35.8109 73.8268L35.9934 78.702L39.0665 80.5383L38.884 75.6631Z"
        fill="#EA9C12"
      />
      <path
        d="M35.8109 73.8268L32.7378 71.9905L32.9202 76.8657L35.9933 78.702L35.8109 73.8268Z"
        fill="#FAA917"
      />
      <path
        d="M32.7378 71.9905L29.6647 70.1541L29.8471 75.0293L32.9202 76.8657L32.7378 71.9905Z"
        fill="#FFC03B"
      />
      <path
        d="M29.6648 70.1542L26.5917 68.3178L26.7741 73.193L29.8472 75.0294L29.6648 70.1542Z"
        fill="#FFC438"
      />
      <path
        d="M26.5916 68.3178L23.5186 66.4815L23.701 71.3567L26.7741 73.193L26.5916 68.3178Z"
        fill="#FFC53B"
      />
      <path
        d="M23.5185 66.4815L20.4454 64.6452L20.6279 69.5204L23.701 71.3567L23.5185 66.4815Z"
        fill="#FFC438"
      />
      <path
        d="M20.4455 64.6452L17.3724 62.8089L17.5549 67.6841L20.628 69.5204L20.4455 64.6452Z"
        fill="#FFC03B"
      />
      <path
        d="M17.3723 62.8088L14.2992 60.9725L14.4816 65.8477L17.5547 67.684L17.3723 62.8088Z"
        fill="#F6A813"
      />
      <path
        d="M14.2992 60.9725L11.2261 59.1361L11.4085 64.0113L14.4816 65.8477L14.2992 60.9725Z"
        fill="#FCAF18"
      />
      <path
        d="M11.2262 59.1362L8.15308 57.2998L8.33552 62.175L11.4086 64.0114L11.2262 59.1362Z"
        fill="#E28F0E"
      />
      <path
        d="M44.8476 74.4606L41.7745 72.6242L41.957 77.4994L45.0301 79.3358L44.8476 74.4606Z"
        fill="#CF6E01"
      />
      <path
        d="M41.7746 72.6242L38.7015 70.7879L38.884 75.6631L41.9571 77.4994L41.7746 72.6242Z"
        fill="#EC9A0F"
      />
      <path
        d="M38.7015 70.7879L35.6284 68.9516L35.8109 73.8268L38.884 75.6631L38.7015 70.7879Z"
        fill="#FCAE16"
      />
      <path
        d="M35.6284 68.9516L32.5553 67.1153L32.7377 71.9905L35.8108 73.8268L35.6284 68.9516Z"
        fill="#804604"
      />
      <path
        d="M32.5554 67.1153L29.4823 65.2789L29.6647 70.1541L32.7378 71.9905L32.5554 67.1153Z"
        fill="#804604"
      />
      <path
        d="M29.4823 65.279L26.4092 63.4426L26.5916 68.3178L29.6647 70.1542L29.4823 65.279Z"
        fill="#804604"
      />
      <path
        d="M26.4091 63.4426L23.3361 61.6063L23.5185 66.4815L26.5916 68.3178L26.4091 63.4426Z"
        fill="#804604"
      />
      <path
        d="M23.3362 61.6063L20.2631 59.77L20.4455 64.6452L23.5186 66.4815L23.3362 61.6063Z"
        fill="#804604"
      />
      <path
        d="M20.263 59.77L17.1899 57.9337L17.3724 62.8089L20.4455 64.6452L20.263 59.77Z"
        fill="#804604"
      />
      <path
        d="M17.1898 57.9336L14.1167 56.0973L14.2991 60.9725L17.3722 62.8088L17.1898 57.9336Z"
        fill="#804604"
      />
      <path
        d="M14.1168 56.0973L11.0437 54.2609L11.2261 59.1361L14.2992 60.9725L14.1168 56.0973Z"
        fill="#FAAE13"
      />
      <path
        d="M11.0437 54.261L7.97058 52.4246L8.15303 57.2998L11.2261 59.1361L11.0437 54.261Z"
        fill="#F4A60F"
      />
      <path
        d="M7.97067 52.4246L4.89758 50.5883L5.08003 55.4635L8.15312 57.2998L7.97067 52.4246Z"
        fill="#D37604"
      />
      <path
        d="M44.6653 69.5854L41.5922 67.749L41.7746 72.6242L44.8477 74.4606L44.6653 69.5854Z"
        fill="#E18B0A"
      />
      <path
        d="M41.5921 67.749L38.519 65.9127L38.7015 70.7879L41.7746 72.6242L41.5921 67.749Z"
        fill="#FDAD13"
      />
      <path
        d="M38.519 65.9127L35.4459 64.0764L35.6284 68.9516L38.7015 70.7879L38.519 65.9127Z"
        fill="#FFBC29"
      />
      <path
        d="M35.446 64.0764L32.3729 62.2401L32.5554 67.1153L35.6285 68.9516L35.446 64.0764Z"
        fill="#FFBC29"
      />
      <path
        d="M32.3729 62.2401L29.2998 60.4037L29.4823 65.2789L32.5553 67.1153L32.3729 62.2401Z"
        fill="#FFD238"
      />
      <path
        d="M29.2998 60.4038L26.2267 58.5674L26.4091 63.4426L29.4822 65.279L29.2998 60.4038Z"
        fill="#FFD238"
      />
      <path
        d="M26.2268 58.5674L23.1537 56.7311L23.3361 61.6063L26.4092 63.4426L26.2268 58.5674Z"
        fill="#FFD238"
      />
      <path
        d="M23.1537 56.7311L20.0806 54.8948L20.263 59.77L23.3361 61.6063L23.1537 56.7311Z"
        fill="#FFD238"
      />
      <path
        d="M20.0807 54.8948L17.0076 53.0585L17.19 57.9337L20.2631 59.77L20.0807 54.8948Z"
        fill="#FFD238"
      />
      <path
        d="M17.0074 53.0584L13.9343 51.2221L14.1168 56.0973L17.1899 57.9336L17.0074 53.0584Z"
        fill="#FFBC29"
      />
      <path
        d="M13.9343 51.2221L10.8612 49.3857L11.0437 54.2609L14.1167 56.0973L13.9343 51.2221Z"
        fill="#FEBA2D"
      />
      <path
        d="M10.8613 49.3858L7.78821 47.5494L7.97065 52.4246L11.0437 54.2609L10.8613 49.3858Z"
        fill="#FDB31B"
      />
      <path
        d="M7.78818 47.5494L4.71509 45.7131L4.89753 50.5883L7.97062 52.4246L7.78818 47.5494Z"
        fill="#E38B0B"
      />
      <path
        d="M44.4828 64.7102L41.4097 62.8738L41.5921 67.749L44.6652 69.5854L44.4828 64.7102Z"
        fill="#ED9708"
      />
      <path
        d="M41.4096 62.8738L38.3365 61.0375L38.519 65.9127L41.5921 67.749L41.4096 62.8738Z"
        fill="#FEBB27"
      />
      <path
        d="M38.3366 61.0375L35.2635 59.2012L35.446 64.0764L38.5191 65.9127L38.3366 61.0375Z"
        fill="#FFC82D"
      />
      <path
        d="M35.2635 59.2012L32.1904 57.3649L32.3729 62.2401L35.446 64.0764L35.2635 59.2012Z"
        fill="#FFCA34"
      />
      <path
        d="M32.1905 57.3649L29.1174 55.5285L29.2999 60.4037L32.373 62.2401L32.1905 57.3649Z"
        fill="#FFD732"
      />
      <path
        d="M29.1174 55.5286L26.0443 53.6922L26.2268 58.5674L29.2998 60.4038L29.1174 55.5286Z"
        fill="#FFDC31"
      />
      <path
        d="M26.0443 53.6922L22.9712 51.8559L23.1536 56.7311L26.2267 58.5674L26.0443 53.6922Z"
        fill="#FFDC31"
      />
      <path
        d="M22.9713 51.8559L19.8982 50.0196L20.0806 54.8948L23.1537 56.7311L22.9713 51.8559Z"
        fill="#FFDC31"
      />
      <path
        d="M19.8982 50.0196L16.8251 48.1833L17.0075 53.0585L20.0806 54.8948L19.8982 50.0196Z"
        fill="#FFD732"
      />
      <path
        d="M16.8249 48.1832L13.7518 46.3469L13.9343 51.2221L17.0074 53.0584L16.8249 48.1832Z"
        fill="#FFC82D"
      />
      <path
        d="M13.7519 46.3469L10.6788 44.5105L10.8613 49.3857L13.9344 51.2221L13.7519 46.3469Z"
        fill="#FFC82D"
      />
      <path
        d="M10.6788 44.5105L7.60571 42.6742L7.78816 47.5494L10.8612 49.3857L10.6788 44.5105Z"
        fill="#FEBB27"
      />
      <path
        d="M7.60568 42.6742L4.53259 40.8379L4.71504 45.7131L7.78813 47.5494L7.60568 42.6742Z"
        fill="#ED9708"
      />
      <path
        d="M44.3003 59.835L41.2272 57.9986L41.4096 62.8738L44.4827 64.7102L44.3003 59.835Z"
        fill="#F4990C"
      />
      <path
        d="M41.2273 57.9986L38.1542 56.1623L38.3366 61.0375L41.4097 62.8738L41.2273 57.9986Z"
        fill="#FFBF29"
      />
      <path
        d="M38.1541 56.1623L35.0811 54.326L35.2635 59.2012L38.3366 61.0375L38.1541 56.1623Z"
        fill="#FFC729"
      />
      <path
        d="M35.0811 54.326L32.0081 52.4897L32.1905 57.3649L35.2636 59.2012L35.0811 54.326Z"
        fill="#FFCC31"
      />
      <path
        d="M32.008 52.4897L28.9349 50.6534L29.1174 55.5285L32.1905 57.3649L32.008 52.4897Z"
        fill="#804604"
      />
      <path
        d="M28.9349 50.6534L25.8618 48.817L26.0443 53.6922L29.1174 55.5286L28.9349 50.6534Z"
        fill="#FFE434"
      />
      <path
        d="M25.8619 48.817L22.7888 46.9807L22.9713 51.8559L26.0444 53.6922L25.8619 48.817Z"
        fill="#FFE434"
      />
      <path
        d="M22.7888 46.9807L19.7157 45.1444L19.8981 50.0196L22.9712 51.8559L22.7888 46.9807Z"
        fill="#FFE434"
      />
      <path
        d="M19.7157 45.1444L16.6426 43.3081L16.825 48.1833L19.8981 50.0196L19.7157 45.1444Z"
        fill="#804604"
      />
      <path
        d="M16.6425 43.308L13.5695 41.4716L13.7519 46.3468L16.825 48.1832L16.6425 43.308Z"
        fill="#FFCC31"
      />
      <path
        d="M13.5694 41.4717L10.4963 39.6353L10.6788 44.5105L13.7519 46.3469L13.5694 41.4717Z"
        fill="#FFC729"
      />
      <path
        d="M10.4963 39.6353L7.42322 37.799L7.60566 42.6742L10.6788 44.5105L10.4963 39.6353Z"
        fill="#FFBF29"
      />
      <path
        d="M7.42331 37.799L4.35022 35.9627L4.53267 40.8379L7.60575 42.6742L7.42331 37.799Z"
        fill="#F4990C"
      />
      <path
        d="M44.1179 54.9598L41.0448 53.1234L41.2272 57.9986L44.3003 59.835L44.1179 54.9598Z"
        fill="#F8A510"
      />
      <path
        d="M41.0448 53.1234L37.9717 51.2871L38.1541 56.1623L41.2272 57.9986L41.0448 53.1234Z"
        fill="#FFC837"
      />
      <path
        d="M37.9718 51.2871L34.8987 49.4508L35.0811 54.326L38.1542 56.1623L37.9718 51.2871Z"
        fill="#FFCF35"
      />
      <path
        d="M34.8987 49.4508L31.8256 47.6145L32.008 52.4897L35.0811 54.326L34.8987 49.4508Z"
        fill="#FFDA39"
      />
      <path
        d="M31.8255 47.6145L28.7524 45.7782L28.9349 50.6534L32.008 52.4897L31.8255 47.6145Z"
        fill="#804604"
      />
      <path
        d="M28.7525 45.7782L25.6794 43.9418L25.8619 48.817L28.935 50.6534L28.7525 45.7782Z"
        fill="#FFEF45"
      />
      <path
        d="M25.6794 43.9418L22.6063 42.1055L22.7888 46.9807L25.8619 48.817L25.6794 43.9418Z"
        fill="#FFEB3D"
      />
      <path
        d="M22.6063 42.1055L19.5332 40.2692L19.7156 45.1444L22.7887 46.9807L22.6063 42.1055Z"
        fill="#FFEF45"
      />
      <path
        d="M19.5333 40.2692L16.4602 38.4329L16.6427 43.3081L19.7157 45.1444L19.5333 40.2692Z"
        fill="#804604"
      />
      <path
        d="M16.4601 38.4328L13.387 36.5965L13.5694 41.4716L16.6425 43.308L16.4601 38.4328Z"
        fill="#FFDA39"
      />
      <path
        d="M13.3869 36.5965L10.3138 34.7601L10.4963 39.6353L13.5694 41.4717L13.3869 36.5965Z"
        fill="#FFCF35"
      />
      <path
        d="M10.3139 34.7601L7.24084 32.9238L7.42329 37.799L10.4964 39.6353L10.3139 34.7601Z"
        fill="#FFC837"
      />
      <path
        d="M7.24081 32.9238L4.16772 31.0875L4.35017 35.9627L7.42326 37.799L7.24081 32.9238Z"
        fill="#F8A510"
      />
      <path
        d="M43.9354 50.0846L40.8623 48.2482L41.0448 53.1234L44.1178 54.9598L43.9354 50.0846Z"
        fill="#FAA10B"
      />
      <path
        d="M40.8624 48.2482L37.7893 46.4119L37.9718 51.2871L41.0448 53.1234L40.8624 48.2482Z"
        fill="#FFCA37"
      />
      <path
        d="M37.7893 46.4119L34.7162 44.5756L34.8986 49.4508L37.9717 51.2871L37.7893 46.4119Z"
        fill="#FFD83D"
      />
      <path
        d="M34.7162 44.5756L31.6431 42.7393L31.8255 47.6145L34.8986 49.4508L34.7162 44.5756Z"
        fill="#FFEC4F"
      />
      <path
        d="M31.6432 42.7393L28.5701 40.9029L28.7525 45.7781L31.8256 47.6145L31.6432 42.7393Z"
        fill="#804604"
      />
      <path
        d="M28.57 40.9029L25.4969 39.0666L25.6794 43.9418L28.7525 45.7781L28.57 40.9029Z"
        fill="#FFF764"
      />
      <path
        d="M25.4969 39.0666L22.4238 37.2303L22.6063 42.1055L25.6794 43.9418L25.4969 39.0666Z"
        fill="#FFF659"
      />
      <path
        d="M22.4239 37.2303L19.3508 35.394L19.5333 40.2692L22.6064 42.1055L22.4239 37.2303Z"
        fill="#FFF764"
      />
      <path
        d="M19.3508 35.394L16.2777 33.5577L16.4602 38.4329L19.5332 40.2692L19.3508 35.394Z"
        fill="#804604"
      />
      <path
        d="M16.2776 33.5576L13.2045 31.7213L13.3869 36.5964L16.46 38.4328L16.2776 33.5576Z"
        fill="#FFEC4F"
      />
      <path
        d="M13.2046 31.7213L10.1315 29.8849L10.3139 34.7601L13.387 36.5965L13.2046 31.7213Z"
        fill="#FFEC4F"
      />
      <path
        d="M10.1314 29.8849L7.05835 28.0486L7.2408 32.9238L10.3139 34.7601L10.1314 29.8849Z"
        fill="#FFD24E"
      />
      <path
        d="M7.05844 28.0486L3.98535 26.2123L4.1678 31.0875L7.24089 32.9238L7.05844 28.0486Z"
        fill="#FAA10B"
      />
      <path
        d="M43.753 45.2094L40.6799 43.373L40.8624 48.2482L43.9355 50.0846L43.753 45.2094Z"
        fill="#F38503"
      />
      <path
        d="M40.6799 43.373L37.6068 41.5367L37.7893 46.4119L40.8623 48.2482L40.6799 43.373Z"
        fill="#FFEB76"
      />
      <path
        d="M37.6068 41.5367L34.5337 39.7004L34.7161 44.5756L37.7892 46.4119L37.6068 41.5367Z"
        fill="#FFE76E"
      />
      <path
        d="M34.5338 39.7004L31.4607 37.8641L31.6431 42.7393L34.7162 44.5756L34.5338 39.7004Z"
        fill="#FFF770"
      />
      <path
        d="M31.4607 37.8641L28.3876 36.0277L28.57 40.9029L31.6431 42.7393L31.4607 37.8641Z"
        fill="#FFF876"
      />
      <path
        d="M28.3877 36.0278L25.3146 34.1914L25.497 39.0666L28.5701 40.9029L28.3877 36.0278Z"
        fill="#FFF87A"
      />
      <path
        d="M25.3145 34.1914L22.2415 32.3551L22.4239 37.2303L25.497 39.0666L25.3145 34.1914Z"
        fill="#FFF87B"
      />
      <path
        d="M22.2414 32.3551L19.1683 30.5188L19.3508 35.394L22.4239 37.2303L22.2414 32.3551Z"
        fill="#FFF87A"
      />
      <path
        d="M19.1684 30.5188L16.0953 28.6825L16.2778 33.5577L19.3509 35.394L19.1684 30.5188Z"
        fill="#FFF876"
      />
      <path
        d="M16.0952 28.6824L13.0221 26.8461L13.2045 31.7213L16.2776 33.5576L16.0952 28.6824Z"
        fill="#FFF770"
      />
      <path
        d="M13.0221 26.8461L9.94897 25.0097L10.1314 29.8849L13.2045 31.7213L13.0221 26.8461Z"
        fill="#FFEB76"
      />
      <path
        d="M9.94907 25.0097L6.87598 23.1734L7.05842 28.0486L10.1315 29.8849L9.94907 25.0097Z"
        fill="#FFEB76"
      />
      <path
        d="M6.87595 23.1734L3.80286 21.3371L3.9853 26.2123L7.05839 28.0486L6.87595 23.1734Z"
        fill="#F38503"
      />
      <path
        d="M40.4974 38.4979L37.4243 36.6615L37.6068 41.5367L40.6799 43.3731L40.4974 38.4979Z"
        fill="#F49B43"
      />
      <path
        d="M37.4244 36.6615L34.3513 34.8252L34.5338 39.7004L37.6069 41.5367L37.4244 36.6615Z"
        fill="#FFFAA0"
      />
      <path
        d="M34.3513 34.8252L31.2782 32.9889L31.4606 37.8641L34.5337 39.7004L34.3513 34.8252Z"
        fill="#FFFAA0"
      />
      <path
        d="M31.2783 32.9889L28.2052 31.1526L28.3876 36.0278L31.4607 37.8641L31.2783 32.9889Z"
        fill="#FFFAA0"
      />
      <path
        d="M28.2052 31.1526L25.1321 29.3163L25.3145 34.1914L28.3876 36.0278L28.2052 31.1526Z"
        fill="#FFFAA0"
      />
      <path
        d="M25.132 29.3163L22.059 27.4799L22.2414 32.3551L25.3145 34.1915L25.132 29.3163Z"
        fill="#FFFAA0"
      />
      <path
        d="M22.0591 27.4799L18.986 25.6436L19.1684 30.5188L22.2415 32.3551L22.0591 27.4799Z"
        fill="#FFFAA0"
      />
      <path
        d="M18.9859 25.6436L15.9128 23.8073L16.0953 28.6825L19.1684 30.5188L18.9859 25.6436Z"
        fill="#FFFAA0"
      />
      <path
        d="M15.9127 23.8072L12.8396 21.9709L13.022 26.8461L16.0951 28.6824L15.9127 23.8072Z"
        fill="#FFFAA0"
      />
      <path
        d="M12.8397 21.9709L9.7666 20.1346L9.94905 25.0098L13.0221 26.8461L12.8397 21.9709Z"
        fill="#FFFAA0"
      />
      <path
        d="M9.76657 20.1346L6.69348 18.2982L6.87593 23.1734L9.94902 25.0098L9.76657 20.1346Z"
        fill="#F49B43"
      />
      <path
        d="M37.2419 31.7863L34.1688 29.95L34.3513 34.8252L37.4244 36.6615L37.2419 31.7863Z"
        fill="#F49B43"
      />
      <path
        d="M34.1689 29.95L31.0958 28.1137L31.2783 32.9889L34.3514 34.8252L34.1689 29.95Z"
        fill="#FFC80D"
      />
      <path
        d="M31.0958 28.1137L28.0227 26.2774L28.2052 31.1526L31.2782 32.9889L31.0958 28.1137Z"
        fill="#FFFED2"
      />
      <path
        d="M28.0227 26.2774L24.9496 24.4411L25.132 29.3163L28.2051 31.1526L28.0227 26.2774Z"
        fill="#FFFED2"
      />
      <path
        d="M24.9497 24.4411L21.8766 22.6047L22.059 27.4799L25.1321 29.3163L24.9497 24.4411Z"
        fill="#FFFED2"
      />
      <path
        d="M21.8766 22.6047L18.8035 20.7684L18.9859 25.6436L22.059 27.4799L21.8766 22.6047Z"
        fill="#FFFED2"
      />
      <path
        d="M18.8034 20.7684L15.7303 18.9321L15.9128 23.8073L18.9859 25.6436L18.8034 20.7684Z"
        fill="#FFFED2"
      />
      <path
        d="M15.7303 18.932L12.6572 17.0957L12.8397 21.9709L15.9128 23.8072L15.7303 18.932Z"
        fill="#FFC80D"
      />
      <path
        d="M12.6572 17.0957L9.58411 15.2594L9.76655 20.1346L12.8396 21.9709L12.6572 17.0957Z"
        fill="#F49B43"
      />
      <path
        d="M33.9864 25.0748L30.9133 23.2385L31.0958 28.1137L34.1689 29.95L33.9864 25.0748Z"
        fill="#F9A029"
      />
      <path
        d="M30.9133 23.2385L27.8402 21.4022L28.0227 26.2774L31.0957 28.1137L30.9133 23.2385Z"
        fill="#FFCB1B"
      />
      <path
        d="M27.8403 21.4022L24.7672 19.5659L24.9497 24.4411L28.0227 26.2774L27.8403 21.4022Z"
        fill="#FFE117"
      />
      <path
        d="M24.7672 19.5659L21.6941 17.7295L21.8765 22.6047L24.9496 24.4411L24.7672 19.5659Z"
        fill="#FFE117"
      />
      <path
        d="M21.6942 17.7296L18.6211 15.8932L18.8035 20.7684L21.8766 22.6047L21.6942 17.7296Z"
        fill="#FFE117"
      />
      <path
        d="M18.6211 15.8932L15.548 14.0569L15.7304 18.9321L18.8035 20.7684L18.6211 15.8932Z"
        fill="#FFCB1B"
      />
      <path
        d="M15.5478 14.0568L12.4747 12.2205L12.6572 17.0957L15.7303 18.932L15.5478 14.0568Z"
        fill="#F9A029"
      />
    </svg>
  );
}
