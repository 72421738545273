import React from 'react';

export default function EmojiFace({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.6 10.7833C15.9888 10.7833 16.3194 10.6472 16.5918 10.375C16.8639 10.1028 17 9.77225 17 9.38325C17 8.99442 16.8639 8.66392 16.5918 8.39175C16.3194 8.11942 15.9888 7.98325 15.6 7.98325C15.2112 7.98325 14.8806 8.11942 14.6083 8.39175C14.3361 8.66392 14.2 8.99442 14.2 9.38325C14.2 9.77225 14.3361 10.1028 14.6083 10.375C14.8806 10.6472 15.2112 10.7833 15.6 10.7833ZM8.4 10.7833C8.78883 10.7833 9.11942 10.6472 9.39175 10.375C9.66392 10.1028 9.8 9.77225 9.8 9.38325C9.8 8.99442 9.66392 8.66392 9.39175 8.39175C9.11942 8.11942 8.78883 7.98325 8.4 7.98325C8.01117 7.98325 7.68058 8.11942 7.40825 8.39175C7.13608 8.66392 7 8.99442 7 9.38325C7 9.77225 7.13608 10.1028 7.40825 10.375C7.68058 10.6472 8.01117 10.7833 8.4 10.7833ZM12 17.4832C13.1112 17.4832 14.1292 17.1791 15.0542 16.5707C15.9792 15.9624 16.6501 15.1444 17.0667 14.1168H6.93325C7.36108 15.1444 8.03475 15.9624 8.95425 16.5707C9.87358 17.1791 10.8888 17.4832 12 17.4832ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20.3333C14.3263 20.3333 16.2968 19.526 17.9115 17.9115C19.526 16.2968 20.3333 14.3263 20.3333 12C20.3333 9.67367 19.526 7.70317 17.9115 6.0885C16.2968 4.474 14.3263 3.66675 12 3.66675C9.67367 3.66675 7.70317 4.474 6.0885 6.0885C4.474 7.70317 3.66675 9.67367 3.66675 12C3.66675 14.3263 4.474 16.2968 6.0885 17.9115C7.70317 19.526 9.67367 20.3333 12 20.3333Z"
        fill="currentColor"
      />
    </svg>
  );
}
