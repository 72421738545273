import React from 'react';

export default function FlagChina({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_301_31640)">
        <g clipPath="url(#clip1_301_31640)">
          <path
            d="M-0.387695 2.80396H24.3882V20.446H-0.387695V2.80396Z"
            fill="#EE1C25"
          />
          <path
            d="M2.5856 9.33147L4.25795 4.56812L5.9303 9.33147L1.4707 6.42053H7.0452L2.5856 9.33147Z"
            fill="#FFFF00"
          />
          <path
            d="M9.83873 4.65955L8.11767 5.02166L9.26569 3.75155L9.15309 5.46095L8.19801 3.94762L9.83873 4.65955Z"
            fill="#FFFF00"
          />
          <path
            d="M11.5759 6.74945L9.84159 6.44947L11.4186 5.70151L10.6165 7.23564L10.3544 5.48908L11.5759 6.74945Z"
            fill="#FFFF00"
          />
          <path
            d="M11.3229 9.68158L9.86826 8.73613L11.6294 8.66385L10.2381 9.75401L10.749 8.05781L11.3229 9.68158Z"
            fill="#FFFF00"
          />
          <path
            d="M9.13495 11.597L8.17782 10.1915L9.83172 10.7707L8.10487 11.266L9.26617 9.8888L9.13495 11.597Z"
            fill="#FFFF00"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_301_31640">
          <rect height="24" width="24" fill="white" rx="4" />
        </clipPath>
        <clipPath id="clip1_301_31640">
          <rect
            height="17.642"
            width="24.7759"
            fill="white"
            rx="4"
            x="-0.387695"
            y="2.80396"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
