import React from 'react';

export default function EditorTFNG({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#A669F7"
        fillRule="evenodd"
      />
      <path
        d="M6.38597 17C8.80827 17 10.7719 14.9853 10.7719 12.5C10.7719 10.0147 8.80827 8 6.38597 8C3.96366 8 2 10.0147 2 12.5C2 14.9853 3.96366 17 6.38597 17ZM8.6902 11.5908C8.86148 11.4151 8.86148 11.1301 8.6902 10.9544C8.51891 10.7787 8.24121 10.7787 8.06993 10.9544L5.81313 13.2699L4.92519 12.207C4.76755 12.0183 4.49066 11.9965 4.30675 12.1582C4.12283 12.32 4.10153 12.604 4.25917 12.7927L5.45535 14.2246C5.53485 14.3197 5.64944 14.3766 5.77152 14.3814C5.8936 14.3862 6.0121 14.3385 6.09849 14.2499L8.6902 11.5908Z"
        fill="#080808"
        fillRule="evenodd"
      />
      <path
        d="M17.614 8C15.1886 8 13.2281 10.0115 13.2281 12.5C13.2281 14.9885 15.1886 17 17.614 17C20.0395 17 22 14.9885 22 12.5C22 10.0115 20.0395 8 17.614 8ZM19.807 14.1155L19.1886 14.75L17.614 13.1345L16.0395 14.75L15.4211 14.1155L16.9956 12.5L15.4211 10.8845L16.0395 10.25L17.614 11.8655L19.1886 10.25L19.807 10.8845L18.2325 12.5L19.807 14.1155Z"
        fill="#080808"
      />
    </svg>
  );
}
