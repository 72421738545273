import { isDesktop, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { OtherRotateDevice as OtherRotateDeviceIcon } from '@front/icon';

const styles = {
  root: (theme: Theme) => ({
    background: alpha(theme.palette.background.default, 0.65),
    backdropFilter: 'blur(20px)',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    padding: 6,
    zIndex: 5001,
    [theme.breakpoints.down('md')]: {
      '@media screen and (orientation:landscape)': {
        display: 'flex',
      },
    },
  }),

  center: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    '& > svg': {
      width: 120,
      height: 120,
    },
  },
  title: {
    fontSize: '36px',
    fontWeight: 700,
  },
  subtitle: {
    fontWeight: 400,
  },
};

const LandscapeBlocker = () => {
  const { t } = useTranslation('common');

  if (isDesktop || isTablet) return null;
  return (
    <Box sx={styles.root}>
      <Box sx={styles.center}>
        <OtherRotateDeviceIcon />
        <Typography variant="h3" sx={styles.title}>
          {t('Please Rotate Your Device')}
        </Typography>
        <Typography variant="subtitle2" sx={styles.subtitle}>
          {t('Oops, currently Aha cannot be used in landscape mode')}
        </Typography>
      </Box>
    </Box>
  );
};

export default LandscapeBlocker;
