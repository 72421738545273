import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';

import { ViewSlugInfiniteHookResponse } from '../../types/base/view';
import { AwardBadgeViewSlug } from '../../types/enums';

import { generateInfiniteHook, SwrHelperParams } from './swr.helper';

export function useIaAwardBadgeList(
  query: IaSearchReq<AwardBadgeViewSlug.ProfileCenterIaBadgePracticeResult>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaAwardBadgePracticeResultRes>;
export function useIaAwardBadgeList(
  query: IaSearchReq<AwardBadgeViewSlug.ProfileCenterIaBadgeCategory>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaAwardBadgeCategoryRes>;
export function useIaAwardBadgeList(
  query: IaSearchReq<AwardBadgeViewSlug.ProfileCenterIaBadgeAlmostThere>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaAwardBadgeAlmostThereRes>;
export function useIaAwardBadgeList(
  query: IaSearchReq<AwardBadgeViewSlug.CardCenterIaBadgeDefault>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaAwardBadgeDefaultRes>;
export function useIaAwardBadgeList(
  query: Omit<IaSearchReq<AwardBadgeViewSlug>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
): ViewSlugInfiniteHookResponse<any> {
  const limit = query.limit || 0;

  return generateInfiniteHook(
    (index) =>
      `v2/ia/award-badges?${getIaQueryString({
        ...query,
        page: index + 1,
        limit,
      })}`,
    limit,
    { auth: true, ...config }
  );
}
