import { useEffect, useState } from 'react';
import { cloneDeep, isEqual, isPlainObject } from 'lodash';

import { useLatestValueRef } from './index';

function isObject(value: unknown): value is Record<string, unknown> {
  return isPlainObject(value);
}
export default function useWatchDifferent<T, K = T>({
  watchValue,
  onDifferent,
  differentBy = (value) => value as unknown as K,
}: {
  watchValue: T;
  onDifferent: (params?: { differentKeys?: string[] }) => void;
  differentBy?: (value: T) => K;
}) {
  const onDifferentRef = useLatestValueRef(onDifferent);

  const [previousValue, setPreviousValue] = useState<K>(
    differentBy(watchValue)
  );

  const value = differentBy(watchValue);

  useEffect(() => {
    if (isEqual(previousValue, value)) return;

    if (isObject(value) && isObject(previousValue)) {
      const differentKeys = Object.keys(value).filter(
        (key) => !isEqual(value[key], previousValue[key])
      );
      onDifferentRef.current({
        differentKeys,
      });
    } else {
      onDifferentRef.current();
    }

    setPreviousValue(cloneDeep(value));
  }, [onDifferentRef, previousValue, value]);
}
