import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import { Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

import ThemeProvider from '../../../../components/ThemeProvider';

type BlockProps = {
  node: ProseMirrorNode;
  updateAttributes: (attributes: Record<string, any>) => void;
};

export const UnsupportedBlockDefaultProps = {
  content: {
    default: '',
  },
};

const Unsupported = ({ node }: BlockProps) => {
  return (
    <NodeViewWrapper>
      <ThemeProvider mode="dark">{node.attrs.content}</ThemeProvider>
    </NodeViewWrapper>
  );
};

export type UnsupportedOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: UnsupportedOptions;
    node: ProseMirrorNode;
  }) => string;
};

export const UnsupportedBlock = Node.create<UnsupportedOptions>({
  name: BasicBlockTypes.Unsupported,
  group: 'blockContent',
  inline: false,
  selectable: false,
  atom: true,

  addAttributes() {
    return UnsupportedBlockDefaultProps;
  },

  parseHTML() {
    return [
      {
        tag: 'unsupported',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Unsupported);
  },
});

export default UnsupportedBlock;
