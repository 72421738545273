import React from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import {
  ActionCloseThick as ActionCloseThickIcon,
  MainPractice as MainPracticeIcon,
} from '@front/icon';
import { ResponsiveTooltip } from '@front/ui';

const styles = {
  tooltip: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100%',
  },
};
export default function PracticeSelectedRatioTooltip({
  symbol,
  correct,
  value,
}: {
  symbol?: string;
  correct?: boolean;
  value: string | number;
}) {
  const { t } = useTranslation('quiz');
  const tooltipTitle = symbol
    ? t('practice.tooltip.option.title', {
        symbol,
        context: correct ? 'correct' : 'mistake',
      })
    : undefined;

  const tooltipContent = t('practice.tooltip.option.content', {
    value,
  });

  const titleIcon = symbol ? (
    correct ? (
      <MainPracticeIcon width={16} height={16} />
    ) : (
      <ActionCloseThickIcon width={16} height={16} />
    )
  ) : undefined;
  return (
    <ResponsiveTooltip
      titleIcon={titleIcon}
      title={tooltipTitle}
      content={tooltipContent}
      tooltipProps={{ followCursor: true }}
      bottomSheetProps={{ simpleBackdrop: false }}
    >
      <Box sx={styles.tooltip} />
    </ResponsiveTooltip>
  );
}
