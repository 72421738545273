import React, {
  cloneElement,
  MouseEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import MenuItem, {
  MenuItemProps,
  MenuItemTypeMap,
} from '@mui/material/MenuItem';
import { SxProps } from '@mui/material/styles';

import ContextMenu from './ContextMenu';

const styles = {
  nestedMenu: {
    mx: 1,
  },
};

function ContextMenuItem<T extends MenuItemTypeMap['defaultComponent']>({
  children,
  onMouseDown,
  onMouseOver,
  onMouseLeave,
  nestedItems,
  sx,
  nestedSx,
  closeOnClick,
  ...restProps
}: MenuItemProps<T, { component?: T }> & {
  nestedItems?: JSX.Element | JSX.Element[];
  nestedSx?: SxProps;
  closeOnClick?: boolean;
}) {
  const menuItemRef = useRef<HTMLLIElement>(null);
  const [menuOpened, setMenuOpened] = useState(false);

  const handleMouseDown = useCallback((e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    onMouseDown?.(e);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHovered = useCallback((e: MouseEvent<HTMLLIElement>) => {
    setMenuOpened(true);
    onMouseOver?.(e);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLeft = useCallback((e: MouseEvent<HTMLLIElement>) => {
    setMenuOpened(false);
    onMouseLeave?.(e);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nestedSxProps = Array.isArray(nestedSx) ? nestedSx : [nestedSx];

  const handleOnItemClick = () => {
    if (!closeOnClick) return;
    setMenuOpened(false);
  };

  return (
    <MenuItem
      sx={sx}
      disableRipple={!!nestedItems}
      tabIndex={-1}
      role="menuitem"
      onMouseDown={handleMouseDown}
      onMouseOver={handleHovered}
      onMouseLeave={handleLeft}
      ref={menuItemRef}
      {...restProps}
    >
      {nestedItems && (
        <ContextMenu
          active={menuOpened}
          target={menuItemRef}
          sx={[styles.nestedMenu, ...nestedSxProps]}
          isRootMenu={false}
        >
          {Array.isArray(nestedItems)
            ? nestedItems.map((item, index) =>
                cloneElement(item, {
                  key: `nested-menu-item-${index}`,
                  onClick: (e: MouseEvent<HTMLLIElement>) => {
                    item.props.onClick(e);
                    handleOnItemClick();
                  },
                })
              )
            : nestedItems}
        </ContextMenu>
      )}
      {children}
    </MenuItem>
  );
}

export default ContextMenuItem;
