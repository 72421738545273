import React from 'react';

export default function EditorDynamicVariable({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.02715 13.2162C4.96574 13.2162 3.29609 14.9578 3.29609 17.1081C3.29609 19.2584 4.96574 21 7.02715 21C9.08857 21 10.7582 19.2584 10.7582 17.1081C10.7582 14.9578 9.08857 13.2162 7.02715 13.2162ZM12.6051 14.1892V20.027C12.6051 20.5622 13.0248 21 13.5379 21H19.1345C19.6475 21 20.0672 20.5622 20.0672 20.027V14.1892C20.0672 13.6541 19.6475 13.2162 19.1345 13.2162H13.5379C13.0248 13.2162 12.6051 13.6541 12.6051 14.1892ZM6.19699 4.04108L3.11886 9.82054C2.77374 10.4724 3.22146 11.2703 3.93037 11.2703H10.0866C10.7955 11.2703 11.2433 10.4724 10.8981 9.82054L7.82 4.04108C7.46555 3.37946 6.55144 3.37946 6.19699 4.04108ZM18.4349 3C17.4462 3 16.7466 3.54486 16.3362 4.13838C15.9257 3.54486 15.2262 3 14.2374 3C12.7823 3 11.6723 4.24541 11.6723 5.67568C11.6723 7.45622 13.5658 8.76 15.7299 10.7157C16.075 11.027 16.588 11.027 16.9425 10.7157C19.1065 8.76 21 7.45622 21 5.67568C21 4.24541 19.89 3 18.4349 3Z"
        fill="currentColor"
      />
    </svg>
  );
}
