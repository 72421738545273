import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Scrollbar } from '@front/ui';
import useTextComposerRender from '@lib/web/composer/TextComposer/hooks/useTextComposerRender';
import EditorComposerRenderer from '@lib/web/editor/EditorTextComposer/EditorComposerRenderer';
import { ChartRenderBox } from '@lib/web/quiz';

export type QuizSolutionItem = {
  optionKeys: string[];
  content: string;
  isCorrect?: boolean;
  answerContent?: string;
};

export type QuizSolutionsProps = {
  solutions: QuizSolutionItem[];
  isSkipped: boolean;
};

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    '& svg': { ml: 1 },
    typography: (theme: Theme) =>
      theme.palette.mode === 'light' ? 'examBody' : 'subtitle2',
  },
  titleCorrect: {
    fontWeight: (theme: Theme) => (theme.palette.mode === 'light' ? 700 : 500),
  },
  titleIncorrect: {
    fontWeight: (theme: Theme) => (theme.palette.mode === 'light' ? 400 : 500),
  },
  solution: {
    '& > p': {
      mt: 0,
      mb: 3,
      textIndent: '40px',
      '&:last-child': {
        mb: 0,
      },
    },
    '& .break-line': {
      opacity: 0,
      visibility: 'hidden',
      width: 0,
      height: 0,
      display: 'inline-block',
      overflow: 'hidden',
    },
  },
  solutionContent: {
    my: 1,
    typography: (theme: Theme) =>
      theme.palette.mode === 'light' ? 'examBody' : 'body2',
    color: 'text.primary',
    '& .solution-step': {
      position: 'relative',
      pl: 5.75,
      minHeight: 30,
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
      '&:before': {
        position: 'absolute',
        content: '""',
        left: 28,
        top: 0,
        bottom: 0,
        width: 5,
        borderRadius: 0.5,
        backgroundColor: 'text.primary',
      },
      '& + .solution-step': {
        mt: 2.5,
      },
    },
    '& .solution-step-num': {
      position: 'absolute',
      typography: 'h6',
      left: 0,
      top: 0,
    },
  },
  solutionItem: {
    mb: 2,
  },
  chartRenderBox: {
    '& .chart-render-inner': {
      p: 0,
    },
  },
};

export default function QuizSolutions({
  solutions,
  isSkipped,
}: QuizSolutionsProps) {
  const { renderOptions } = useTextComposerRender();
  return (
    <Box sx={styles.solution}>
      <Box sx={styles.solutionContent}>
        <Scrollbar>
          {solutions.map((solution, i) => {
            if (!solution.content) return null;
            return (
              <Box key={i} sx={styles.solutionItem} className="solution-item">
                {isSkipped ? (
                  <Typography
                    sx={[
                      styles.title,
                      solution.isCorrect
                        ? styles.titleCorrect
                        : styles.titleIncorrect,
                    ]}
                  >
                    {solution.optionKeys.join(', ')}
                  </Typography>
                ) : (
                  <Typography
                    sx={[
                      styles.title,
                      solution.isCorrect
                        ? styles.titleCorrect
                        : styles.titleIncorrect,
                    ]}
                    color={solution.isCorrect ? 'success.main' : 'error.dark'}
                  >
                    {solution.optionKeys.join(', ')}
                    {solution.isCorrect ? <CheckCircleIcon /> : <CancelIcon />}
                  </Typography>
                )}
                {solution.answerContent && (
                  <Box
                    dangerouslySetInnerHTML={{ __html: solution.answerContent }}
                  />
                )}
                <EditorComposerRenderer styleProvider>
                  <ChartRenderBox
                    tex={solution.content}
                    sx={styles.chartRenderBox}
                    renderOptions={renderOptions}
                  />
                </EditorComposerRenderer>
              </Box>
            );
          })}
        </Scrollbar>
      </Box>
    </Box>
  );
}
