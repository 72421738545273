import { useEffect, useRef } from 'react';

export default function useAnimationFrame(
  onFrame: (elapsed: number) => void,
  duration?: number
) {
  const requestRef = useRef<number | null>(null);
  const startRef = useRef<number | null>(null);

  const animate = (timestamp: number) => {
    if (!startRef.current) {
      startRef.current = timestamp;
    }
    const elapsed = timestamp - startRef.current;
    onFrame(elapsed);
    requestRef.current = requestAnimationFrame(animate);

    if (duration && elapsed >= duration) {
      cancelAnimationFrame(requestRef.current);
    }
  };

  useEffect(() => {
    return () => {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const start = () => {
    requestRef.current = requestAnimationFrame(animate);
  };

  const cancel = () => {
    startRef.current = null;
    if (requestRef.current) cancelAnimationFrame(requestRef.current);
  };

  return {
    start,
    cancel,
  };
}
