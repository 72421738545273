import React from 'react';

export default function NFTCommon({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.9962 22.4001C11.527 22.4001 11.0578 22.2196 10.6969 21.8677L2.13422 13.304C1.42141 12.5912 1.42141 11.4271 2.13422 10.7052L10.6969 2.14153C11.0398 1.79862 11.5 1.6001 11.9962 1.6001C12.4925 1.6001 12.9436 1.7896 13.2955 2.14153L21.8582 10.7052C22.2011 11.0481 22.3996 11.5083 22.3996 12.0046C22.3996 12.4919 22.2101 12.9521 21.8582 13.304L13.2955 21.8677C12.9346 22.2286 12.4654 22.4001 11.9962 22.4001ZM3.28012 11.8512C3.19892 11.9324 3.19892 12.0678 3.28012 12.149L11.8428 20.7126C11.924 20.7939 12.0594 20.7939 12.1406 20.7126L20.7033 12.149C20.7574 12.0948 20.7665 12.0407 20.7665 11.9956C20.7665 11.9595 20.7574 11.8963 20.7033 11.8422L12.1406 3.27854C12.0865 3.22439 12.0323 3.21537 11.9872 3.21537C11.9421 3.21537 11.888 3.22439 11.8338 3.27854L3.28012 11.8512Z"
        fill="currentColor"
      />
    </svg>
  );
}
