import React from 'react';
import { alpha, Box, Theme, Typography } from '@mui/material';

import { TableLayoutProgressCell, TableLayoutRow } from '../../types';

const styles = {
  root: {
    display: 'grid',
    gap: 1,
    alignItems: 'center',
    gridTemplateColumns: '1fr 60px',
    height: '100%',
  },
  bar: {
    position: 'relative',
    height: 8,
    borderRadius: 1,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    overflow: 'hidden',
  },
  barInner: {
    bgcolor: 'currentColor',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    borderRadius: 1,
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'right',
  },
};
export type IaProgressCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutProgressCell;
  columnKey: string;
};

const getColor = (color?: string): string => {
  if (color === 'error') return 'error.dark';
  else if (color === 'warning') return 'warning.light';
  else if (color === 'success') return 'success.dark';
  else if (color === 'primary') return 'primary.main';
  return 'text.primary';
};
export default function IaProgressCell({ cell }: IaProgressCellProps) {
  const progress =
    cell.value && cell.total ? (cell.value / cell.total) * 100 : 0;
  return (
    <Box sx={styles.root}>
      <Typography sx={styles.text} variant="body2">
        {cell.value}
      </Typography>

      <Box sx={styles.bar}>
        <Box
          sx={[
            styles.barInner,
            { width: `${progress}%`, color: getColor(cell.color) },
          ]}
        />
      </Box>
    </Box>
  );
}
