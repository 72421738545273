import React from 'react';
import { v4 } from 'uuid';

export default function Award231Locked({ ...rest }) {
  const id = v4();
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#clip0_${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42 76C60.7777 76 76 60.7777 76 42C76 23.2223 60.7777 8 42 8C23.2223 8 8 23.2223 8 42C8 60.7777 23.2223 76 42 76ZM42 80C62.9868 80 80 62.9868 80 42C80 21.0132 62.9868 4 42 4C21.0132 4 4 21.0132 4 42C4 62.9868 21.0132 80 42 80Z"
          fill="#565656"
        />
        <g clipPath={`url(#clip1_${id})`}>
          <rect
            x="46.5017"
            y="20.2393"
            width="13.7596"
            height="41.2789"
            rx="6.87981"
            transform="rotate(30 46.5017 20.2393)"
            fill="#565656"
          />
          <ellipse
            cx="35.2604"
            cy="53.4698"
            rx="6.87981"
            ry="6.87982"
            transform="rotate(30 35.2604 53.4698)"
            fill="#B2B2B2"
          />
        </g>
      </g>
      <defs>
        <clipPath id={`clip0_${id}`}>
          <rect width="84" height="84" fill="white" />
        </clipPath>
        <clipPath id={`clip1_${id}`}>
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(17 17)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
