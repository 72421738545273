const htmlContent = (type: string) =>
  `[data-content-type="${type}"]:not(.block-content)`;

export const generatedHtmlFileBlockStyle = () => ({
  [`${htmlContent('file')}`]: {
    '.render-one-line&': {
      '&:after': {
        pr: '4px',
        fontStyle: 'italic',
        content: '"File: " attr(data-metadata-filename)',
      },
    },
  },
});
