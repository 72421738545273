import { Node } from '@tiptap/core';

const PatchBlockContainerEnter = Node.create({
  name: 'patch-block-container-enter',
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        /**
         *  default behavior in blockContainer is to create a new block,
         *  but in thread composer, we don't want this behavior,
         *  so we return true to ignore the default behavior
         */
        return true;
      },
    };
  },
});

export default PatchBlockContainerEnter;
