export const enum Country {
  US = 'US',
  IN = 'IN',
}

export const enum SchoolType {
  Institution = 'institution',
  HighSchool = 'high-school',
  University = 'university',
  College = 'college',
}
