import React from 'react';

export default function ActionClear({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.2961 11.9999L17.7399 10.5859C18.1321 10.189 18.1208 9.55585 17.7144 9.17198C17.3192 8.79798 16.6924 8.79798 16.2961 9.17198L14.8523 10.5859L13.4085 9.17198C13.0163 8.77505 12.37 8.764 11.9647 9.14804C11.5594 9.53208 11.5481 10.165 11.9402 10.562C11.9484 10.57 11.9566 10.5791 11.9647 10.5859L13.4083 11.9999L11.9645 13.4138C11.5592 13.7979 11.5479 14.4308 11.9401 14.8278C12.3322 15.2237 12.9785 15.2357 13.3839 14.8517C13.3931 14.8437 13.4013 14.8346 13.4083 14.8278L14.8521 13.4138L16.2959 14.8278C16.6881 15.2237 17.3344 15.2357 17.7397 14.8517C18.144 14.4677 18.1563 13.8347 17.7642 13.4378C17.756 13.4287 17.7467 13.4207 17.7397 13.4138L16.2961 11.9999ZM9.5707 5H19.9577C21.0851 5 22 5.89597 22 7.00006V16.9999C22 18.104 21.0851 19 19.9577 19L9.5707 18.9998C9.02949 18.9998 8.50982 18.7889 8.12689 18.4139L2.2995 12.707C1.90017 12.3159 1.90017 11.684 2.2995 11.2931L8.12689 5.58621C8.50982 5.2112 9.02967 5 9.5707 5Z"
        fill="currentColor"
      />
    </svg>
  );
}
