import React from 'react';

export default function TestClockSolid({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11.0371 6C11.5457 6 11.9581 6.41233 11.9581 6.92096V12.4468L16.7159 15.2697C17.1385 15.5205 17.2748 16.0682 17.019 16.4878C16.7666 16.9018 16.2274 17.0345 15.8117 16.7851L10.1161 13.3677V6.92097C10.1161 6.41233 10.5285 6 11.0371 6Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
