import React from 'react';

export default function EditorMRQ({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#779FF1"
        fillRule="evenodd"
      />
      <path
        d="M2 10.2C2 9.53726 2.52672 9 3.17647 9H6.70588C7.35563 9 7.88235 9.53726 7.88235 10.2V13.8C7.88235 14.4627 7.35563 15 6.70588 15H3.17647C2.52672 15 2 14.4627 2 13.8V10.2ZM6.48637 11.3939C6.60123 11.2767 6.60123 11.0868 6.48637 10.9696C6.37151 10.8524 6.18528 10.8524 6.07042 10.9696L4.55704 12.5133L3.9616 11.8047C3.85588 11.6789 3.67021 11.6643 3.54688 11.7721C3.42355 11.88 3.40926 12.0694 3.51498 12.1952L4.31712 13.1497C4.37043 13.2131 4.44727 13.251 4.52914 13.2542C4.611 13.2575 4.69047 13.2257 4.7484 13.1666L6.48637 11.3939Z"
        fill="#080808"
        fillRule="evenodd"
      />
      <path
        d="M9.05882 10.2C9.05882 9.53726 9.58555 9 10.2353 9H13.7647C14.4145 9 14.9412 9.53726 14.9412 10.2V13.8C14.9412 14.4627 14.4145 15 13.7647 15H10.2353C9.58555 15 9.05882 14.4627 9.05882 13.8V10.2ZM13.5452 11.3939C13.6601 11.2767 13.6601 11.0868 13.5452 10.9696C13.4303 10.8524 13.2441 10.8524 13.1292 10.9696L11.6159 12.5133L11.0204 11.8047C10.9147 11.6789 10.729 11.6643 10.6057 11.7721C10.4824 11.88 10.4681 12.0694 10.5738 12.1952L11.3759 13.1497C11.4293 13.2131 11.5061 13.251 11.588 13.2542C11.6698 13.2575 11.7493 13.2257 11.8072 13.1666L13.5452 11.3939Z"
        fill="#080808"
        fillRule="evenodd"
      />
      <path
        d="M16.1176 10.2C16.1176 9.53726 16.6444 9 17.2941 9H20.8235C21.4733 9 22 9.53726 22 10.2V13.8C22 14.4627 21.4733 15 20.8235 15H17.2941C16.6444 15 16.1176 14.4627 16.1176 13.8V10.2ZM20.604 11.3939C20.7189 11.2767 20.7189 11.0868 20.604 10.9696C20.4892 10.8524 20.3029 10.8524 20.1881 10.9696L18.6747 12.5133L18.0792 11.8047C17.9735 11.6789 17.7879 11.6643 17.6645 11.7721C17.5412 11.88 17.5269 12.0694 17.6326 12.1952L18.4348 13.1497C18.4881 13.2131 18.5649 13.251 18.6468 13.2542C18.7287 13.2575 18.8081 13.2257 18.866 13.1666L20.604 11.3939Z"
        fill="#080808"
        fillRule="evenodd"
      />
    </svg>
  );
}
