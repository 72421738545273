import api from './client';

const remoteRenderApis = {
  render: (params: AddRemoteRenderRes) => api.post('/v2/remote-render', params),
  getLink: (params: GetRemoteRenderLinkRes) =>
    api.get<Response<{ url: string | null }>>(
      `/v2/remote-render/link?type=${params.type}&subType=${params.subType}&sourceId=${params.sourceId}`
    ),
};

export default remoteRenderApis;
