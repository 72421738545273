import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Theme, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { MainPractice as MainPracticeIcon } from '@front/icon';
import { SimpleTooltip } from '@front/ui';
import { ComposerBlock } from '@lib/web/composer';
import EditorComposer from '@lib/web/editor/EditorComposer';

import { McqChoiceValue } from '../types';

export type ChoiceProps = {
  questionId: string;
  value: McqChoiceValue;
  onBlocksChange: (id: string, blocks: ComposerBlock[]) => void;
  onSelect: (id: string) => void;
  readonly?: boolean;
};

const styles = {
  root: (theme: Theme) => ({
    minHeight: 56,
    background: alpha(theme.palette.text.primary, 0.05),
    borderRadius: '12px',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    justifyContent: 'flex-start',
    boxShadow: `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.1)}`,
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  }),
  innerLayer: {
    position: 'absolute',
    top: 3,
    left: 3,
    right: 3,
    bottom: 3,
    background: 'rgba(255, 255, 255, 0.1)',
  },
  innerContent: {
    display: 'grid',
    gridTemplateColumns: '52px 1fr',
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    userSelect: 'none',
  },
  symbolContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    pr: '8px',
  },
  symbol: (theme: Theme) => ({
    margin: 'auto',
    ml: 1.5,
    height: 32,
    width: 32,
    backgroundColor: alpha(theme.palette.text.primary, 0.05),
    border: `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
  }),
  symbolSelected: {
    backgroundColor: 'success.light',
    color: 'background.darker',
  },
  rootSelected: (theme: Theme) => ({
    boxShadow: `inset 0 0 0 3px ${theme.palette.success.light}`,
    background: alpha(theme.palette.success.dark, 0.3),
  }),
  hover: (theme: Theme) => ({
    '@media (hover: hover)': {
      '&:hover': {
        boxShadow: `inset 0 0 0 1px ${alpha(
          theme.palette.text.primary,
          0.3
        )}, 0px 8px 16px ${alpha(theme.palette.text.primary, 0.15)}`,
        backgroundColor: alpha(theme.palette.text.primary, 0.1),
      },
    },
  }),

  textComposer: {
    textAlign: 'left',
    typography: 'body2',
    fontWeight: 400,

    paddingTop: '13px',
    paddingBottom: '13px',
    paddingRight: 1,

    hyphens: 'auto',
    cursor: 'text',
  },

  readonlyContent: {
    py: 2,
  },

  readonlyButton: {
    cursor: 'default',
  },
};

function ReadonlyChoice({ content }: { content: string }) {
  return (
    <ButtonBase disableRipple sx={styles.root} component="div" disabled>
      <Box sx={styles.innerContent}>
        <Box sx={styles.symbolContainer}>
          <Box sx={[styles.symbol]}></Box>
        </Box>
        <Box sx={styles.readonlyContent}>
          <Typography>{content}</Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
}

export default function Choice({
  questionId,
  value,
  onBlocksChange,
  onSelect,
  readonly,
}: ChoiceProps) {
  const { t } = useTranslation('editor');
  const selected = value.selected;

  return (
    <ButtonBase
      disableRipple
      sx={[
        styles.root,
        !selected && styles.hover,
        selected && styles.rootSelected,
        !!readonly && styles.readonlyButton,
      ]}
      component="div"
    >
      {selected && <Box sx={styles.innerLayer} />}
      <Box sx={styles.innerContent}>
        <SimpleTooltip
          title={
            readonly
              ? null
              : selected
              ? t('Unmark as correct answer')
              : t('Mark this as the correct answer')
          }
        >
          {/* create this symbol container to make clickable area bigger */}
          <Box
            sx={styles.symbolContainer}
            onClick={readonly ? undefined : () => onSelect(value.id)}
          >
            <Box sx={[styles.symbol, selected && styles.symbolSelected]}>
              {selected && <MainPracticeIcon width={30} height={30} />}
            </Box>
          </Box>
        </SimpleTooltip>

        <EditorComposer
          key={value.id}
          questionId={questionId}
          sx={styles.textComposer}
          defaultBlocks={value.blocks}
          onBlocksChange={(blocks) => onBlocksChange(value.id, blocks)}
          placeholder={t('Write an answer option here')}
          disabled={readonly}
        />
      </Box>
    </ButtonBase>
  );
}

Choice.LeaveBlank = ReadonlyChoice;
