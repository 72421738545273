import { TFunction } from 'i18next';

const getWorkflowPageConfig = (t: TFunction) => ({
  entry: {
    title: t('workflow.entry.title'),
    icon: 'AgentWorkflow',
  },
});

export default getWorkflowPageConfig;
