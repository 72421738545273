import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { ActionSlash as ActionSlashIcon } from '@front/icon';
import { BottomSheet } from '@front/ui';

import BreadcrumbsItem from '../components/BreadcrumbsItem';
import BreadcrumbList from '../components/BreadcrumbsList';
import { BreadcrumbsValue } from '../types';

export type MobileBreadcrumbsProps = {
  bottomSheetTitle: string;
  breadcrumbsValues: BreadcrumbsValue[];
};

const styles = {
  root: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  dot: {
    minWidth: '26px',
  },
};

const getBreadcrumbsBottomSheetDefaultHeight = (numOfItems: number): number => {
  // XXX: Maybe use dynamic calculation instead of hardcode
  const BOTTOM_SHEET_HEADER_HEIGHT = 40;
  const BOTTOM_SHEET_TITLE_HEIGHT = 24;
  const BOTTOM_SHEET_FOOTER_HEIGHT = 20;
  const BOTTOM_SHEET_ITEM_HEIGHT = 36;
  return (
    BOTTOM_SHEET_ITEM_HEIGHT * numOfItems +
    BOTTOM_SHEET_HEADER_HEIGHT +
    BOTTOM_SHEET_TITLE_HEIGHT +
    BOTTOM_SHEET_FOOTER_HEIGHT
  );
};

export default function MobileBreadcrumbs({
  bottomSheetTitle,
  breadcrumbsValues,
}: MobileBreadcrumbsProps) {
  const [open, setOpen] = useState(false);
  const lastItem = breadcrumbsValues[breadcrumbsValues.length - 1];
  const hasMultipleItems = breadcrumbsValues.length > 1;
  const { text, icon, customizeComponent } = lastItem;

  const handleClickItems = () => {
    if (hasMultipleItems) {
      setOpen(true);
    }
  };

  return (
    <>
      <Box sx={styles.root} onClick={handleClickItems}>
        {hasMultipleItems && (
          <>
            <BreadcrumbsItem text="..." sx={styles.dot} />
            <ActionSlashIcon height={20} width={24} />
          </>
        )}
        <BreadcrumbsItem
          text={text}
          icon={icon}
          customizeComponent={customizeComponent}
        />
      </Box>
      <BottomSheet
        open={open}
        onClose={() => setOpen(false)}
        defaultHeight={getBreadcrumbsBottomSheetDefaultHeight(
          breadcrumbsValues.length
        )}
      >
        <BreadcrumbList
          title={bottomSheetTitle}
          breadcrumbsValues={breadcrumbsValues}
          onLinkClick={() => setOpen(false)}
        />
      </BottomSheet>
    </>
  );
}
