import { useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useLocalStorage } from '@front/helper';

import { ReactionSource } from './types';

const useEmojiSource = (): ReactionSource[] => {
  const [emojiSource, setEmojiSource] = useLocalStorage<ReactionSource[]>(
    'emoji-source',
    []
  );

  useEffect(() => {
    const fetchEmojis = async () => {
      try {
        const res = await axios.get<AxiosResponse<{ items: ReactionSource[] }>>(
          'v2/mark/emoji-static?page=1&limit=1000',
          { baseURL: process.env.API_URL, withCredentials: true }
        );
        setEmojiSource(res.data.data.items);
      } catch (e) {
        console.error(e);
      }
    };
    if (emojiSource.length === 0) {
      fetchEmojis();
    }
  }, [emojiSource?.length, setEmojiSource]);

  return emojiSource;
};

export default useEmojiSource;
