import React from 'react';

export default function TestStar({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.52819 8.68797C9.43872 8.93864 9.21489 9.109 8.95968 9.12068L3.60987 9.36558C3.0216 9.39251 2.78052 10.1698 3.24133 10.5538L7.43202 14.0463C7.63194 14.2129 7.71743 14.4885 7.64917 14.7464L6.21831 20.1524C6.06098 20.7468 6.69212 21.2272 7.18315 20.8867L11.6486 17.7906C11.8617 17.6429 12.1383 17.6429 12.3514 17.7906L16.8169 20.8868C17.3079 21.2272 17.939 20.7468 17.7817 20.1524L16.3508 14.7464C16.2826 14.4885 16.3681 14.2129 16.568 14.0463L20.7587 10.5538C21.2195 10.1698 20.9784 9.39251 20.3901 9.36558L15.0403 9.12068C14.7851 9.109 14.5613 8.93863 14.4718 8.68797L12.5963 3.43335C12.3901 2.85555 11.6099 2.85555 11.4037 3.43335L9.52819 8.68797ZM12 8.11016L11.5159 9.4666C11.1281 10.5528 10.1582 11.2911 9.05232 11.3417L7.67131 11.4049L8.7531 12.3065C9.6194 13.0284 9.98987 14.2229 9.69409 15.3404L9.32472 16.7359L10.4775 15.9367C11.4006 15.2967 12.5994 15.2967 13.5225 15.9367L14.6753 16.7359L14.3059 15.3404C14.0101 14.2229 14.3806 13.0284 15.2469 12.3065L16.3287 11.4049L14.9477 11.3417C13.8418 11.2911 12.8718 10.5528 12.4841 9.4666L12 8.11016Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
