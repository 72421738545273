import { alpha, Box, Theme, Typography } from '@mui/material';
import { appConfig } from '@front/config';
import { UserAvatar } from '@front/ui';
import combineSearch from '@lib/ia/src/utils/combineSearch';
import { ClubViewSlug, useIaClubsList } from '@lib/web/apis';

import { FloatingProfileExtension } from '../types';

import ProfileExtension from './ProfileExtension';

const styles = {
  profileContainer: {
    display: 'grid',
    overflow: 'hidden',
  },
  avatar: {
    gap: 2,
    alignItems: 'flex-end',
    '& .MuiTypography-body1': {
      fontSize: 20,
      fontWeight: 700,
    },
    '& .MuiTypography-caption': {
      fontSize: 16,
      mt: 0.5,
      mb: 0.5,
    },
  },
  userData: {
    display: 'flex',
    gap: '4px',
    mt: 2,
  },
  userDataItem: {
    px: '4px',
    py: '2px',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    '& span': {
      fontWeight: 500,
      mr: '5px',
      color: (theme: Theme) => theme.palette.text.primary,
    },
  },
};

type AhaProfileProps = {
  extension?: FloatingProfileExtension;
};

export default function AhaProfile({ extension }: AhaProfileProps) {
  const { totalCount } = useIaClubsList({
    viewSlug: ClubViewSlug.CardCenterDefault,
    ...combineSearch({
      expandSearch: `clubCreator:clubCreatorSourceId:${appConfig.ahaUserId}`,
      expandSearchFields: 'clubCreator:clubCreatorSourceId:eq',
    }),
    limit: 1, // because we only need totalCount, so make it limit 1 to make api faster
  });

  return (
    <Box sx={styles.profileContainer}>
      <Box>
        <UserAvatar
          sx={styles.avatar}
          src="/logo.png"
          title="Aha"
          subTitle="@aha"
          size="xl"
        />
      </Box>
      <Box sx={styles.userData}>
        <Typography variant="body1" sx={styles.userDataItem}>
          <span>{totalCount}</span>Clubs
        </Typography>
      </Box>
      <ProfileExtension extension={extension} />
    </Box>
  );
}
