import React from 'react';

export default function OtherCircleOutline({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 3.5C7.30843 3.5 3.5 7.30843 3.5 12C3.5 16.6916 7.30843 20.5 12 20.5C16.6916 20.5 20.5 16.6916 20.5 12C20.5 7.30843 16.6916 3.5 12 3.5ZM2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
