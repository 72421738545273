import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Theme, useMediaQuery } from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  LoginHide as LoginHideIcon,
  LoginSee as LoginSeeIcon,
} from '@front/icon';
import { InfoTooltip } from '@front/ui';
import { ExamMode } from '@lib/web/apis';
import { TFunction } from 'i18next';

const styles = {
  tooltipTitle: {
    display: 'flex',
    gap: 0.5,
  },
  eliminationTooltip: {
    '& .MuiTooltip-tooltip': {
      ml: '20px !important',
      mt: '20px !important',
    },
  },
  hideEliminationTooltip: {
    '& .MuiTooltip-tooltip': {
      display: 'none',
    },
  },
  elimination: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 12px 12px 0',
    cursor: 'pointer',
    '&.Mui-disabled': { pointerEvents: 'unset', opacity: 0.5 },
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  hideEliminationIcon: {
    svg: {
      display: 'none',
    },
    '&:hover': {
      svg: {
        display: 'block',
      },
    },
  },
};

const TooltipTitle = ({
  mode,
  eliminated,
  t,
}: {
  mode?: ExamMode;
  eliminated: boolean;
  t: TFunction;
}): JSX.Element => {
  return (
    <Box sx={styles.tooltipTitle}>
      {eliminated ? (
        <LoginSeeIcon width={16} height={16} />
      ) : (
        <LoginHideIcon width={16} height={16} />
      )}
      {mode === ExamMode.MockExam
        ? eliminated
          ? t(
              'practice.tooltip.mock.show.option.title',
              'Show option that might be the answer'
            )
          : t(
              'practice.tooltip.mock.hide.option.title',
              'Hide option that not the answer'
            )
        : eliminated
        ? t('practice.tooltip.practice.show.option.title', 'Show option')
        : t('practice.tooltip.practice.hide.option.title', 'Hide option')}
    </Box>
  );
};

const getTooltipContent = (eliminated: boolean, t: TFunction): string => {
  return eliminated
    ? t(
        'practice.tooltip.show.option.content',
        'Click to show this option if you believe it may be correct.'
      )
    : t(
        'practice.tooltip.hide.option.content',
        'Click to hide this option and reduce distractions to help you narrow down the correct answer.'
      );
};

export default function PracticeEliminateToggle({
  mode,
  selected,
  eliminated,
  onEliminate,
}: {
  mode?: ExamMode;
  selected: boolean;
  eliminated: boolean;
  onEliminate: (ev: MouseEvent<HTMLButtonElement>) => void;
}) {
  const { t } = useTranslation('quiz');
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <InfoTooltip
      title={<TooltipTitle mode={mode} eliminated={eliminated} t={t} />}
      content={getTooltipContent(eliminated, t)}
      placement="right-start"
      slotProps={{
        popper: {
          sx: [
            styles.eliminationTooltip,
            (selected || mdDown) && styles.hideEliminationTooltip,
          ],
        },
      }}
      followCursor
    >
      <span>
        <ButtonBase
          onClick={(e) => onEliminate(e)}
          sx={[styles.elimination, !mdDown && styles.hideEliminationIcon]}
          disableRipple
          disabled={selected}
        >
          {!eliminated && <LoginHideIcon width={20} height={20} />}
          {eliminated && <LoginSeeIcon width={20} height={20} />}
        </ButtonBase>
      </span>
    </InfoTooltip>
  );
}
