import React from 'react';

export default function FlagIndonesia({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_476_210932)">
        <g clipPath="url(#clip1_476_210932)">
          <path
            d="M-0.387695 2.80396H24.3882V11.625H-0.387695V2.80396Z"
            fill="#E70011"
          />
          <path
            d="M-0.387695 11.625H24.3882V20.446H-0.387695V11.625Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_476_210932">
          <rect height="24" width="24" fill="white" rx="4" />
        </clipPath>
        <clipPath id="clip1_476_210932">
          <rect
            height="17.642"
            width="24.7759"
            fill="white"
            rx="4"
            x="-0.387695"
            y="2.80396"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
