import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';

import { CardLayoutLoadingConfig } from './types';

const styles = {
  root: {
    display: 'grid',
    gap: 2.5,
  },
  skeleton: {
    borderRadius: 2,
  },
};

const getGridTemplateColumns = (columns: number[], cardWidth?: number) => {
  const colWidth = cardWidth ? `${cardWidth}px` : 'minmax(0, 1fr)';
  return columns.map((column) => `repeat(${column}, ${colWidth})`);
};

export default function CardLayoutSkeleton({
  settings,
}: Omit<CardLayoutLoadingConfig, 'layout'>) {
  const gridTemplateColumns = getGridTemplateColumns(
    settings.columns,
    settings.cardWidth
  );
  const skeletonSx = [styles.skeleton, { height: settings.cardHeight || 180 }];
  const cardCount = settings.cardCount || 4;
  return (
    <Box sx={[styles.root, { gridTemplateColumns }]}>
      {[...Array(cardCount)].map((_, index) => (
        <Skeleton sx={skeletonSx} variant="rectangular" key={index} />
      ))}
    </Box>
  );
}
