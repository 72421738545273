import React from 'react';

export default function OtherBlock({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.4332 5.46289C15.9601 4.23719 14.0661 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 13.801 4.06009 15.4711 5.0156 16.8457L17.4332 5.46289ZM5.97778 17.9986L18.4988 6.52101C19.7474 8.00056 20.5 9.91239 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C9.64677 20.5 7.5169 19.5437 5.97778 17.9986ZM4.87124 19.0129C6.68525 20.8567 9.20911 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 14.192 2.70525 16.2192 3.90123 17.8672C4.19585 18.2732 4.52026 18.6562 4.87124 19.0129Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
