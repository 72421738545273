import {
  BlockSpec,
  createTipTapBlock,
  defaultProps,
  mergeCSSClasses,
  PropSchema,
} from '@blocknote/core';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import { InputRule, mergeAttributes } from '@tiptap/core';

import { StepIndexingPlugin } from './StepIndexingPlugin';
import { handleEnter } from './StepKeyboardShortcuts';

/**
 * This reference to
 * @blocknote/core/src/extensions/Blocks/nodes/BlockContent/ListItemBlockContent/NumberedListItemBlockContent/NumberedListItemBlockContent.ts
 */

export const stepPropSchema = {
  ...defaultProps,
} satisfies PropSchema;

const Step = createTipTapBlock<'step', true>({
  name: BasicBlockTypes.Step,
  content: 'inline*',

  addAttributes() {
    return {
      index: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-index'),
        renderHTML: (attributes) => {
          return {
            'data-index': attributes.index,
          };
        },
      },
    };
  },

  addInputRules() {
    return [
      // Creates an ordered list when starting with "1.".
      new InputRule({
        find: new RegExp(`^1\\.\\s$`),
        handler: ({ state, chain, range }) => {
          chain()
            .BNUpdateBlock(state.selection.from, {
              type: 'step',
              props: {},
            })
            // Removes the "1." characters used to set the list.
            .deleteRange({ from: range.from, to: range.to });
        },
      }),
    ];
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => handleEnter(this.editor),
      'Mod-Shift-8': () =>
        this.editor.commands.BNUpdateBlock<{
          step: BlockSpec<'step', typeof stepPropSchema, true>;
        }>(this.editor.state.selection.anchor, {
          type: 'step',
          props: {},
        }),
    };
  },

  addProseMirrorPlugins() {
    return [StepIndexingPlugin()];
  },

  renderHTML({ HTMLAttributes }) {
    const blockContentDOMAttributes =
      this.options.domAttributes?.blockContent || {};
    const inlineContentDOMAttributes =
      this.options.domAttributes?.inlineContent || {};

    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        ...blockContentDOMAttributes,
        class: mergeCSSClasses('block-step', blockContentDOMAttributes.class),
        'data-content-type': this.name,
      }),
      // we use a <p> tag, because for <li> tags we'd need to add a <ul> parent for around siblings to be semantically correct,
      // which would be quite cumbersome
      [
        'p',
        {
          ...inlineContentDOMAttributes,
          class: mergeCSSClasses(
            'block-step',
            inlineContentDOMAttributes.class
          ),
        },
        0,
      ],
    ];
  },
});

export const StepBlock = {
  node: Step,
  propSchema: stepPropSchema,
} satisfies BlockSpec<'step', typeof stepPropSchema, true>;

export default StepBlock;
