/* eslint-disable @typescript-eslint/no-explicit-any */
export const isEmpty = (value: any) =>
  typeof value === 'undefined' ||
  value === null ||
  value === false ||
  value === '';

type AnyFunction = (...args: any[]) => any;

export function isAsyncFunction<T extends AnyFunction>(fn: T): boolean {
  return fn.constructor.name === 'AsyncFunction';
}

function isPromise<T extends AnyFunction>(fn: T): boolean {
  const result = fn();
  return result instanceof Promise;
}

export function isAsyncOrPromise<T extends AnyFunction>(fn: T): boolean {
  return isAsyncFunction(fn) || isPromise(fn);
}
