import { Theme } from '@mui/material/styles';

const legacyParagraphContent =
  '.block-content[data-content-type="legacy-paragraph"]';

const questionNoStyle = {
  cursor: 'default',
  display: 'inline-block',
  backgroundColor: (theme: Theme) =>
    theme.palette.mode === 'dark' ? 'grey.700' : 'grey.500',
  color: (theme: Theme) =>
    theme.palette.mode === 'dark' ? 'text.primary' : 'background.default',
  textAlign: 'center',
  width: 22,
  height: 22,
  lineHeight: '22px',
  textIndent: 0,
  pointerEvents: 'none',
  userSelect: 'none',
  mr: 0.2,
};

export const composerLegacyParagraphBlockStyle = {
  [legacyParagraphContent]: {
    '& .question-no': questionNoStyle,
    color: 'text.primary',
  },
};

const legacyParagraphHtmlContent =
  '[data-content-type="legacy-paragraph"]:not(.block-content)';

export const generatedHtmlLegacyParagraphBlockStyle = {
  [legacyParagraphHtmlContent]: {
    '& .question-no': questionNoStyle,
    color: 'text.primary',
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
  },
};
