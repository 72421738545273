import Box from '@mui/material/Box';
import { LoadingIcon } from '@front/ui';

import { TableLayoutFooterLoadingCell } from '../../types';

export type IaFooterLoadingCellProps = {
  cell: TableLayoutFooterLoadingCell;
};

const styles = {
  root: {
    py: '5.5px',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    overflow: 'hidden',
  },
};
export default function IaFooterLoadingCell({
  cell,
}: IaFooterLoadingCellProps) {
  const { color } = cell;

  return (
    <Box sx={[styles.root, { color }]}>
      <LoadingIcon />
    </Box>
  );
}
