import React, { FocusEvent, ReactNode, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type FixedTextProps = {
  text?: ReactNode;
  isPrefix?: boolean;
};

type InputRenderProps = {
  disabled: InputBaseProps['disabled'];
  onBlur: InputBaseProps['onBlur'];
  onFocus: InputBaseProps['onFocus'];
};
export type TextFormProps = Omit<
  InputBaseProps,
  'sx' | 'prefix' | 'suffix' | 'size'
> & {
  label?: ReactNode;
  note?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  helperText?: ReactNode;
  focused?: boolean;
  success?: boolean;
  sx?: BoxProps['sx'];
  inputSx?: InputBaseProps['sx'];
  activeText?: ReactNode;
  renderInput?: (ev: InputRenderProps) => ReactNode;
  onClick?: BoxProps['onClick'];
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },
  main: {
    position: 'relative',
    display: 'flex',
    borderBottom: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.3)}`,
  },
  hover: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '& .textform-main': {
          borderBottom: (theme: Theme) =>
            `1px solid ${alpha(theme.palette.text.primary, 0.5)}`,
        },
      },
    },
  },
  input: {
    flex: 1,
    height: '100%',
    typography: 'body1',
    '& input, & textarea': {
      py: '12px',
      '&::placeholder': {
        color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
      },
    },
  },
  fixedText: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    typography: 'subtitle2',
    fontWeight: 400,
    '&.textform-prefix': { px: '12px' },
    '&.textform-suffix': { px: '12px' },
  },
  otherMessage: {
    minHeight: 18,
    typography: 'caption',
    color: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.64)
        : alpha(theme.palette.text.primary, 0.5),
  },
  disabled: {
    '& .textform-main': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      borderBottom: (theme: Theme) =>
        `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    },
  },
  focused: {
    '& .textform-main': {
      borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 1),
    },
  },
  error: {
    '& .textform-main': {
      borderColor: 'error.dark',
    },
    '& .textform-message': {
      color: 'error.dark',
    },
  },
  success: {
    '& .textform-main': {
      borderColor: (theme: Theme) =>
        theme.palette.mode === 'dark' ? 'success.light' : 'success.dark',
    },
  },
};

function FixedText({ text, isPrefix }: FixedTextProps) {
  if (!text) return null;

  return (
    <Box
      className={`textform-fixed ${
        isPrefix ? 'textform-prefix' : 'textform-suffix'
      }`}
      sx={styles.fixedText}
    >
      {text}
    </Box>
  );
}

const TextForm = React.forwardRef(
  (
    {
      label,
      prefix,
      suffix,
      note,
      helperText,
      focused = false,
      error = false,
      success = false,
      disabled = false,
      sx,
      inputSx,
      activeText,
      onBlur,
      onFocus,
      onClick,
      renderInput,
      ...rest
    }: TextFormProps,
    ref
  ) => {
    const [active, setActive] = useState(false);

    const handleBlur = (
      ev: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      setActive(false);
      onBlur?.(ev);
    };

    const handleFocus = (
      ev: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      setActive(true);
      onFocus?.(ev);
    };

    const inputFocused = focused || active;
    const sxProps = Array.isArray(sx) ? sx : [sx];
    const inputSxProps = Array.isArray(inputSx) ? inputSx : [inputSx];
    return (
      <Box
        sx={[
          styles.root,
          !disabled && !error && !success && !inputFocused && styles.hover,
          disabled && styles.disabled,
          inputFocused && styles.focused,
          error && styles.error,
          success && styles.success,
          ...sxProps,
        ]}
        ref={ref}
        onClick={onClick}
      >
        {label && (
          <Typography className="textform-label" variant="caption">
            {label}
          </Typography>
        )}
        <Box sx={styles.main} className="textform-main">
          <FixedText text={prefix} isPrefix />
          {renderInput ? (
            renderInput({
              onBlur: handleBlur,
              onFocus: handleFocus,
              disabled,
            })
          ) : (
            <InputBase
              sx={[styles.input, ...inputSxProps]}
              onBlur={handleBlur}
              onFocus={handleFocus}
              disabled={disabled}
              {...rest}
            />
          )}
          <FixedText text={suffix} />
        </Box>
        {inputFocused && activeText ? (
          <Box sx={styles.otherMessage} className="textform-message">
            {activeText}
          </Box>
        ) : (
          <Box sx={styles.otherMessage} className="textform-message">
            {!!note && !error && note}
            {!!helperText && helperText}
          </Box>
        )}
      </Box>
    );
  }
);

export default TextForm;
