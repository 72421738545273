import { useEffect, useMemo, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import useSWR from 'swr';
import { Theme, useMediaQuery } from '@mui/material';
import { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { useDebounce } from '@front/helper';
import { BottomSheetProps, PropertyType } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import {
  FilterOptionsField,
  FilterPersonField,
  FilterStatusField,
  Option,
  PersonOption,
  StatusOption,
} from '@lib/ia/src/filter/types';
import useSuccessState from '@lib/ia/src/layouts/FormLayout/hooks/useSuccessState';
import { FormLayoutItemRule } from '@lib/ia/src/layouts/FormLayout/types';

type AutoCompeteProps = Omit<
  MuiAutocompleteProps<any, any, any, any>,
  'renderInput'
>;

const MAX_SHOW_ITEMS = 8;
const BOTTOM_SHEET_INPUT_HEIGHT = 68;

type SearchUserRes = PersonOption & { memberId: string };

const useSearchUsers = ({
  enable,
  keyword,
  useMemberId = false,
}: {
  enable: boolean;
  keyword: string;
  useMemberId?: boolean;
}) => {
  const debouncedKeyword = useDebounce(keyword.trim());
  const { getIaAction } = useIaAction();
  const { data, isLoading } = useSWR<SearchUserRes[]>(
    enable ? debouncedKeyword : null,
    {
      fetcher: (key) =>
        getIaAction<{ keyword: string }>('searchUsers')?.action({
          keyword: key,
        }) || [],
    }
  );
  let usersData = data || [];
  if (useMemberId) {
    // by default value is userId
    // => when useMemberId = true => need to assign memberId as option value
    usersData =
      data?.map((item) => ({
        ...item,
        value: item.memberId,
      })) || [];
  }
  return {
    data: usersData || [],
    isLoading: !data || isLoading,
  };
};

export const useSelectFilter = ({
  item,
  multiple,
  customRules,
}: {
  item: FilterOptionsField | FilterStatusField | FilterPersonField;
  multiple?: boolean;
  customRules?: Partial<FormLayoutItemRule>;
}) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [open, setOpen] = useState(false);
  const bottomSheetInput = useRef<HTMLElement>(null);
  const [inputValue, setInputValue] = useState('');

  const { control } = useFormContext();
  const {
    field: { onBlur, onChange, value },
    fieldState: { error, isDirty },
  } = useController({
    control,
    name: item.name,
    rules: customRules ?? { required: true },
  });

  const successState = useSuccessState(item.name, isDirty);

  const selectedOptions = Array.isArray(value) ? value : value ? [value] : [];
  const currentValue = useMemo(() => {
    // multiple mode should be like -> [{ label: xxx, value: xxx }]
    // single mode should be like -> { label: xxx, value: xxx }
    if (value) {
      if (multiple) {
        return value;
      } else {
        return Array.isArray(value) ? value[0] : value;
      }
    } else {
      return multiple ? [] : null;
    }
  }, [value, multiple]);

  function handleInputChange(inputVal: string) {
    setInputValue(inputVal);
  }

  const handleOptionClick = (newOption: Option | StatusOption) => {
    setInputValue('');
    if (!multiple) {
      if (value.value === newOption.value) {
        onChange(undefined);
      } else {
        onChange(newOption);
      }
      return;
    }

    const existed = selectedOptions.some(
      (option) => option.value === newOption.value
    );
    if (existed) {
      const newValues = selectedOptions.filter(
        (option) => option.value !== newOption.value
      );
      onChange(newValues);
      return;
    }
    if (multiple) {
      const newValues = [...selectedOptions, newOption];
      onChange(newValues);
    } else {
      const newValues = [newOption];
      onChange(newValues);
    }
  };

  const closeBottomSheet = () => {
    setInputValue('');
    setOpen(false);
  };

  const isPerson = item.type === PropertyType.Person;

  const { data: filteredUserOptions, isLoading: isSearchUsersLoading } =
    useSearchUsers({
      enable: isPerson,
      keyword: inputValue,
      useMemberId: isPerson && item.useMemberId,
    });

  const filteredOptions = useMemo(() => {
    if (isPerson) return filteredUserOptions;

    const options = item.options.filter((option) =>
      option.label.toLowerCase().includes((inputValue || '').toLowerCase())
    );
    return options || [];
  }, [filteredUserOptions, inputValue, isPerson, item]);

  const [headerHeight, setHeaderHeight] = useState(0);
  const scrollHeight = Math.min(filteredOptions.length, MAX_SHOW_ITEMS) * 45;
  const noOptionHeight = filteredOptions.length === 0 ? 21 : 0;
  const defaultHeight = scrollHeight + headerHeight + 40 + noOptionHeight + 12; // padding bottom

  useEffect(() => {
    setHeaderHeight(
      bottomSheetInput.current?.offsetHeight || BOTTOM_SHEET_INPUT_HEIGHT
    );
  }, [value, bottomSheetInput]);

  const sharedAutocompleteProps: AutoCompeteProps = {
    multiple,
    isOptionEqualToValue: (option, optionValue) =>
      optionValue ? option.value === optionValue.value : false,
    onChange: (e, newValue) => onChange(newValue),
    value: currentValue,
    getOptionLabel: (option) => option.label,
    options: filteredOptions,
    disableCloseOnSelect: true,
    disableClearable: true,
    clearOnBlur: false,
    inputValue,
    onInputChange: (_, val) => handleInputChange(val),
  };

  const desktopAutocompleteProps: AutoCompeteProps = {
    ...sharedAutocompleteProps,
    disableCloseOnSelect: true,
    disablePortal: true,
    open: mdUp ? open : false,
    onOpen: () => setOpen(true),
    onClose: closeBottomSheet,
  };

  const mobileAutocompleteProps: AutoCompeteProps = {
    ...sharedAutocompleteProps,
    open: false,
    ref: bottomSheetInput,
  };

  const mobileBottomSheetProps: Omit<BottomSheetProps, 'children'> = {
    disableDrag: true,
    open: !mdUp ? open : false,
    onClose: closeBottomSheet,
    defaultHeight,
    maxHeight: defaultHeight,
  };

  return {
    desktopAutocompleteProps,
    mobileAutocompleteProps,
    mobileBottomSheetProps,
    scrollHeight,
    filteredOptions,
    selectedOptions,
    handleOptionClick,
    inputValue,
    isLoadingOptions: isSearchUsersLoading,
    successState,
    onBlur,
    error,
  };
};
