import { useMemo, useRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Scrollbar } from '@front/ui';
import {
  ExamQuestionMaterialSubType,
  ExamQuestionMaterialType,
} from '@lib/web/apis';
import { useDimension } from '@lib/web/hooks';
import { sanitize } from 'dompurify';
import parse, { HTMLReactParserOptions } from 'html-react-parser';

import AreaChart from './AreaChart';
import BarChart from './BarChart';
import LineChart from './LineChart';
import ScatterChart from './ScatterChart';

const CHART_WIDTH = 450;
const CHART_HEIGHT = 300;
const OPTION_PY = 20;

const styles = {
  root: {
    position: 'relative',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'hidden',
  },
  inner: {
    py: `${OPTION_PY}px`,
    pr: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  normal: {},
};
const getDataObj = (material: GetQuizMaterialRes) => {
  if (!material) return null;
  try {
    const result = JSON.parse(material.content);
    return result;
  } catch (err) {
    return null;
  }
};

const FIGURE_REGEX = /<figure>(.*?)<\/figure>/gm;

type ChartRenderBoxProps = BoxProps & {
  tex: string;
  scrollable?: boolean;
  mappedMaterials?: Record<string, GetQuizMaterialRes>;
  renderOptions?: HTMLReactParserOptions;
};

type ChartBoxProps = {
  material: GetQuizMaterialRes;
  width: number;
};

function ChartBox({ material, width }: ChartBoxProps) {
  const theme = useTheme();
  const chartRef = useRef<Element>();

  const content = getDataObj(material);

  const Chart = useMemo(() => {
    if (!width || !content) return null;

    if (content?.chart) {
      if (material && material.type === ExamQuestionMaterialType.Chart) {
        if (material.subType === ExamQuestionMaterialSubType.BarChart) {
          return BarChart;
        }
        if (material.subType === ExamQuestionMaterialSubType.LineChart) {
          return LineChart;
        }
        if (material.subType === ExamQuestionMaterialSubType.AreaChart) {
          return AreaChart;
        }
        if (material.subType === ExamQuestionMaterialSubType.RadarChart) {
          return ScatterChart;
        }
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [material, content, width]);

  return (
    <Box ref={chartRef}>
      {Chart ? (
        <Chart
          data={content.chart}
          width={content.chart.width ? +content.chart.width : CHART_WIDTH}
          height={content.chart.height ? +content.chart.height : CHART_HEIGHT}
          mode={theme.palette.mode}
        />
      ) : null}
    </Box>
  );
}

export default function ChartRenderBox({
  tex,
  sx,
  scrollable,
  mappedMaterials,
  renderOptions,
  ...rest
}: ChartRenderBoxProps) {
  const innerRef = useRef<Element>();
  const innerRefDim = useDimension(innerRef);
  const contentArr: string[] = useMemo(() => {
    if (!tex) return [];

    const texArr = tex.split(FIGURE_REGEX);
    return texArr;
  }, [tex]);

  const sxProps = Array.isArray(sx) ? sx : [sx];

  const Wrap = scrollable ? Scrollbar : Box;

  return (
    <Box
      sx={[
        styles.root,
        scrollable && { height: innerRefDim.height + OPTION_PY * 2 },
        ...sxProps,
      ]}
      {...rest}
    >
      <Wrap sx={scrollable ? styles.scroll : styles.normal}>
        <Box ref={innerRef} sx={styles.inner} className="chart-render-inner">
          {contentArr.map((content, i: number) => {
            if (mappedMaterials && mappedMaterials[content])
              return (
                <ChartBox
                  key={content}
                  material={mappedMaterials[content]}
                  width={innerRefDim.width}
                />
              );
            return <Box key={i}>{parse(sanitize(content), renderOptions)}</Box>;
          })}
        </Box>
      </Wrap>
    </Box>
  );
}
