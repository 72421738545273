import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import { alpha, Box, Skeleton, Theme, useForkRef } from '@mui/material';

import { LoadingIcon } from '../../components';

import PlayControl from './ components/PlayControl';
import ProgressControl from './ components/ProgressControl';
import VolumeControl from './ components/VolumeControl';

export type AudioPlayerProps = {
  src?: string;
  actionComponent?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onlyOnce?: boolean;
  options?: {
    muted?: boolean;
    volume?: number;
    autoPlay?: boolean;
    progress?: number;
  };
  onCanPlay?: () => void;
  onPlaying?: () => void;
  onPause?: () => void;
  onPlayComplete?: () => void;
  onDestroy?: (value: number) => void;
};
const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    px: 3,
    height: 48,
    borderRadius: 8,
    bgcolor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.1)
        : theme.palette.grey[50],
    transitionDuration: '0.3s',
    userSelect: 'none',
  },
  hovered: {
    bgcolor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.3)
        : theme.palette.grey[100],
  },
  loading: {
    flex: 1,
  },
};

const AudioPlayer = forwardRef<HTMLAudioElement, AudioPlayerProps>(
  (
    {
      src,
      loading = false,
      disabled = false,
      onlyOnce = false,
      actionComponent,
      options = {},
      onCanPlay,
      onPlaying,
      onPause,
      onDestroy,
      onPlayComplete,
    },
    ref
  ) => {
    const audioPlayer = useRef<HTMLAudioElement>();
    const handleAudioRef = useForkRef(audioPlayer, ref);
    const [isPlaying, setIsPlaying] = useState(options.autoPlay || false);
    const [muted, setMuted] = useState(options.muted || false);
    const [hovered, setHovered] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [played, setPlayed] = useState(false);
    const [volume, setVolume] = useState(
      options.muted ? 0 : options.volume || 0.3
    );

    const handleVolumeChange = (value: number) => {
      setVolume(value);
      setMuted(false);
    };

    useEffect(() => {
      if (audioPlayer.current) {
        audioPlayer.current.volume = volume;
      }
    }, [volume]);

    const handleTogglePlay = () => {
      if (!audioPlayer.current) return;

      if (!isPlaying) {
        audioPlayer.current.play();
      } else {
        audioPlayer.current.pause();
      }
    };

    const handleCanPlay = () => {
      setLoaded(true);
      onCanPlay?.();
    };

    const handlePlaying = () => {
      setIsPlaying(true);
      setPlayed(true);
      onPlaying?.();
    };

    const handlePause = () => {
      setIsPlaying(false);
      if (
        audioPlayer.current &&
        audioPlayer.current.currentTime === audioPlayer.current.duration
      ) {
        onPlayComplete?.();
      } else {
        onPause?.();
      }
    };

    const audioDisabled = loading || disabled;

    return (
      <Box
        sx={[styles.root, hovered && loaded && styles.hovered]}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <audio
          ref={handleAudioRef}
          src={src}
          muted={muted}
          onCanPlay={handleCanPlay}
          onPlaying={handlePlaying}
          onPause={handlePause}
          autoPlay={options.autoPlay}
        />
        {loading ? (
          <LoadingIcon />
        ) : (
          <PlayControl
            disabled={!loaded || audioDisabled}
            played={played || (onlyOnce && options.progress === 1)}
            onlyOnce={onlyOnce}
            isPlaying={isPlaying}
            onTogglePlay={handleTogglePlay}
          />
        )}
        {loaded && audioPlayer.current ? (
          <>
            <ProgressControl
              defaultProgress={options.progress}
              isPlaying={isPlaying}
              audioRef={audioPlayer}
              onDestroy={onDestroy}
              audioDisabled={audioDisabled}
              onlyOnce={onlyOnce}
              hovered={hovered}
            />
            <VolumeControl
              disabled={!loaded || audioDisabled}
              muted={muted}
              onToggleMute={setMuted}
              volume={volume}
              onVolumeChange={handleVolumeChange}
            />

            {actionComponent}
          </>
        ) : (
          <Skeleton sx={styles.loading} />
        )}
      </Box>
    );
  }
);

export default AudioPlayer;
