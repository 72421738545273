import React from 'react';

export default function AnalyticsProgress({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.9211 3C18.3135 3 18.6316 3.31713 18.6316 3.70833V6.77775L20.2895 6.77775C20.6819 6.77775 21 7.09488 21 7.48608C21 7.87727 20.6819 8.1944 20.2895 8.1944H18.6316L18.6316 20.2915C18.6316 20.6827 18.3135 20.9999 17.9211 20.9999C17.5286 20.9999 17.2105 20.6827 17.2105 20.2915L17.2105 8.1944L3.71053 8.1944C3.31811 8.1944 3 7.87727 3 7.48607C3 7.09488 3.31811 6.77775 3.71053 6.77775L17.2105 6.77775V3.70833C17.2105 3.31713 17.5286 3 17.9211 3ZM9.39494 10.5555C9.78735 10.5555 10.1055 10.8726 10.1055 11.2638L10.1055 20.2915C10.1055 20.6827 9.78735 20.9999 9.39494 20.9999C9.00252 20.9999 8.68441 20.6827 8.68441 20.2915L8.68441 11.2638C8.68441 10.8726 9.00252 10.5555 9.39494 10.5555ZM13.5913 11.9048C13.9837 11.9048 14.3018 12.222 14.3018 12.6132V20.2917C14.3018 20.6829 13.9837 21 13.5913 21C13.1989 21 12.8808 20.6829 12.8808 20.2917V12.6132C12.8808 12.222 13.1989 11.9048 13.5913 11.9048ZM5.60546 14.603C5.99787 14.603 6.31599 14.9201 6.31599 15.3113L6.31599 20.2915C6.31599 20.6827 5.99788 20.9998 5.60546 20.9998C5.21305 20.9998 4.89494 20.6827 4.89494 20.2915L4.89494 15.3113C4.89494 14.9201 5.21305 14.603 5.60546 14.603Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
