import { useMemo } from 'react';
import { nonNullable } from '@front/helper';
import { basicSlashMenu } from '@lib/web/composer';
import { threadSlashMenu } from '@lib/web/thread/ThreadTextComposer/config/threadSlashMenuItems';

import { editorSlashMenu } from '../config/editorSlashMenuItems';

import { useEnableBlockType } from './useEnableBlockType';

export const useEditorThreadSlashMenu = () => {
  const { isEnableLineMarker, isEnableHighlight, isEnableLatex } =
    useEnableBlockType();

  return useMemo(() => {
    return [
      isEnableLatex ? threadSlashMenu.insertLatex : null,
      basicSlashMenu.insertText,
      basicSlashMenu.insertHeader3,
      basicSlashMenu.insertSubtitle,
      isEnableHighlight ? editorSlashMenu.insertHighlight : null,
      isEnableLineMarker ? editorSlashMenu.insertLineMarker : null,
      basicSlashMenu.insertStep,
      threadSlashMenu.insertImage,
      editorSlashMenu.insertAudio,
      basicSlashMenu.insertBlank,
      editorSlashMenu.insertVariable,
    ].filter(nonNullable);
  }, [isEnableHighlight, isEnableLatex, isEnableLineMarker]);
};
