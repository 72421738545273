import { useCallback, useMemo, useState } from 'react';
import { alpha, SxProps, Theme } from '@mui/material';
import { useAsyncQueryPool } from '@front/helper';
import { AgentViewSlug, apis } from '@lib/web/apis';
import { ThreadUser, ThreadUserGetter } from '@lib/web/thread/types';
import { call, getAgentIconPath, getIndicators } from '@lib/web/utils';

const emptyUser = (id: string): ThreadUser => ({
  type: 'user',
  id,
  accountStatus: 'unknown',
});

const deletedUser = (id: string): ThreadUser => ({
  type: 'user',
  id,
  name: 'Deleted user',
  accountStatus: 'deleted',
});

const emptyAgent = (id: string): ThreadUser => ({
  type: 'agent',
  id,
  accountStatus: 'unknown',
});

const deletedAgent = (id: string): ThreadUser => ({
  type: 'agent',
  id,
  name: 'Deleted AI',
  accountStatus: 'deleted',
});

const isValidUserMemberId = (memberId: string): boolean => {
  return memberId.length <= 36; // back-end api do not allow more than 36 characters
};

const isValidAgentMemberId = (memberId: string): boolean => {
  // agent_<36 characters>
  return memberId.length <= 42; // back-end api do not allow more than 36 characters
};

export const useThreadUsers = () => {
  const [userMap, setUserMap] = useState<Record<string, ThreadUser>>({});
  const [agentMap, setAgentMap] = useState<Record<string, ThreadUser>>({});

  const { asyncGet: asyncGetUser } = useAsyncQueryPool({
    poolKey: 'useThreadUsers-user',
    map: userMap,
    setMap: setUserMap,
    queryApi: async (keys) => {
      const [res] = await call(apis.member.getInfos({ memberIds: keys }));
      if (!res) return;

      return keys.map((key) => {
        const item = res.data.find((d) => d.memberId === key);
        return item
          ? ({
              type: 'user',
              id: key,
              memberId: item.memberId,
              userId: item.userId,
              name: item.displayName || item.distinctName,
              image: item.nftAvatar || item.avatar,
              distinctName: item.distinctName,
              indicators: getIndicators(item.indicator),
              accountStatus: 'active',
            } as ThreadUser)
          : deletedUser(key);
      });
    },
  });

  const { asyncGet: asyncGetAgent } = useAsyncQueryPool({
    poolKey: 'useThreadUsers-agent',
    map: agentMap,
    setMap: setAgentMap,
    queryApi: async (keys) => {
      const [res] = await call(
        apis.ia.agents({
          viewSlug: AgentViewSlug.ThreadViewAgentDefault,
          search: `agentId:${keys
            .map((k) => k.replace('agent_', ''))
            .join(',')}`,
          searchFields: 'agentId:in',
          limit: 0,
        })
      );
      if (!res) return;

      return keys.map((key) => {
        const agentId = key.replace('agent_', '');
        const item = res.data.items.find((d) => d.agentId === agentId);
        return item
          ? ({
              type: 'agent',
              id: key,
              agentId: item.agentId,
              agentClub: item.agentClub,
              name: item.agentName,
              distinctName: item.agentUserName,
              image: getAgentIconPath(item),
              indicators: [
                {
                  icon: 'ProfileClubAgent',
                  name: 'AI',
                  sx: {
                    display: 'flex',
                    alignItems: 'center',
                    color: (theme: Theme) =>
                      alpha(theme.palette.text.primary, 0.64),
                  } as SxProps,
                },
              ],
              accountStatus: 'active',
            } as ThreadUser)
          : deletedAgent(key);
      });
    },
  });

  const getThreadUser: ThreadUserGetter = useCallback(
    (threadMemberId) => {
      if (!threadMemberId) return null;

      if (threadMemberId.startsWith('agent_')) {
        if (!isValidAgentMemberId(threadMemberId)) return null;
        return asyncGetAgent(threadMemberId) || emptyAgent(threadMemberId);
      } else {
        if (!isValidUserMemberId(threadMemberId)) return null;
        return asyncGetUser(threadMemberId) || emptyUser(threadMemberId);
      }
    },
    [asyncGetAgent, asyncGetUser]
  );

  return useMemo(
    () => ({
      getThreadUser,
    }),
    [getThreadUser]
  );
};
