import { defaultBlockSchema, mergeCSSClasses } from '@blocknote/core';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import { mergeAttributes, Node } from '@tiptap/core';

const ParagraphBlock = defaultBlockSchema.paragraph;

/**
 * This reference to
 * @tiptap/extension-paragraph
 * @blocknote/core/src/extensions/Blocks/nodes/BlockContent/ParagraphBlockContent/ParagraphBlockContent.ts
 *
 */
export const ParagraphBlockForGenerateHtml = Node.create({
  name: BasicBlockTypes.Paragraph,
  priority: 1000,
  group: 'block',
  content: 'inline*',

  renderHTML({ HTMLAttributes }) {
    const blockContentDOMAttributes =
      this.options.domAttributes?.blockContent || {};
    const inlineContentDOMAttributes =
      this.options.domAttributes?.inlineContent || {};

    return [
      'div',
      mergeAttributes(
        {
          ...blockContentDOMAttributes,
          class: mergeCSSClasses(blockContentDOMAttributes.class),
          'data-content-type': this.name,
        },
        HTMLAttributes
      ),
      [
        'p',
        {
          ...inlineContentDOMAttributes,
          class: mergeCSSClasses(inlineContentDOMAttributes.class),
        },
        0,
      ],
    ];
  },
});

export default ParagraphBlock;
