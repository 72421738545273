import React from 'react';

export default function NFTRare({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.8584 10.7102L13.2985 2.14129C12.9557 1.79854 12.4957 1.6001 11.9996 1.6001C11.5125 1.6001 11.0525 1.78952 10.7007 2.13228L2.14081 10.6922C1.79805 11.035 1.59961 11.495 1.59961 11.9911C1.59961 12.4872 1.78903 12.9382 2.13179 13.2899L10.6917 21.8589C11.0525 22.2197 11.5216 22.4001 11.9906 22.4001C12.4596 22.4001 12.9287 22.2197 13.2895 21.8679L21.8584 13.308C22.2012 12.9652 22.3996 12.5052 22.3996 12.0091C22.3996 11.513 22.2012 11.053 21.8584 10.7102ZM20.1446 11.2875H17.7543C17.7183 11.2334 17.6732 11.1793 17.619 11.1252L12.8745 6.37165C12.8294 6.32655 12.7753 6.28145 12.7212 6.24537V3.85508L20.1446 11.2875ZM12.0267 16.4199C12.0176 16.4199 12.0086 16.4199 11.9906 16.4199C11.9816 16.4199 11.9635 16.4199 11.9545 16.4199L7.57983 12.0452C7.57983 12.0362 7.57983 12.0272 7.57983 12.0091C7.57983 11.9911 7.57983 11.964 7.57983 11.946L11.9365 7.59836C11.9545 7.59836 11.9725 7.59836 11.9906 7.59836C12.0086 7.59836 12.0357 7.59836 12.0537 7.59836L16.4013 11.955C16.4013 11.973 16.4013 11.9911 16.4013 12.0091C16.4013 12.0181 16.4013 12.0362 16.4013 12.0452L12.0267 16.4199ZM11.278 3.8641V6.24537C11.2239 6.28145 11.1788 6.32655 11.1337 6.37165L6.37116 11.1252C6.31704 11.1793 6.27194 11.2334 6.23586 11.2875H3.83656L11.278 3.8641ZM3.87264 12.7307H6.2539C6.28998 12.7758 6.32606 12.8209 6.37116 12.866L11.1247 17.6285C11.1698 17.6736 11.2239 17.7187 11.278 17.7548V20.1451L3.87264 12.7307ZM12.7212 20.1361V17.7458C12.7753 17.7097 12.8204 17.6646 12.8655 17.6195L17.6281 12.866C17.6732 12.8209 17.7092 12.7758 17.7453 12.7217H20.1356L12.7212 20.1361Z"
        fill="currentColor"
      />
    </svg>
  );
}
