import React from 'react';

export default function ProfileSetting({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.8852 12.8775C18.9199 12.5962 18.943 12.3038 18.943 12C18.943 11.6962 18.9199 11.4038 18.8736 11.1225L20.8292 9.6375C21.0028 9.5025 21.0491 9.255 20.9449 9.06375L19.0935 5.9475C18.9777 5.745 18.7347 5.6775 18.5264 5.745L16.2237 6.645C15.7377 6.285 15.2285 5.9925 14.6615 5.7675L14.3143 3.3825C14.2796 3.1575 14.0829 3 13.8515 3H10.1485C9.91709 3 9.73195 3.1575 9.69723 3.3825L9.35008 5.7675C8.78307 5.9925 8.26234 6.29625 7.7879 6.645L5.48513 5.745C5.27684 5.66625 5.03384 5.745 4.91812 5.9475L3.06665 9.06375C2.95093 9.26625 2.99722 9.5025 3.18236 9.6375L5.13798 11.1225C5.0917 11.4038 5.05698 11.7075 5.05698 12C5.05698 12.2925 5.08012 12.5962 5.12641 12.8775L3.17079 14.3625C2.99722 14.4975 2.95093 14.745 3.05508 14.9362L4.90655 18.0525C5.02226 18.255 5.26527 18.3225 5.47356 18.255L7.77633 17.355C8.26234 17.715 8.7715 18.0075 9.33851 18.2325L9.68566 20.6175C9.73195 20.8425 9.91709 21 10.1485 21H13.8515C14.0829 21 14.2796 20.8425 14.3028 20.6175L14.6499 18.2325C15.2169 18.0075 15.7377 17.7038 16.2121 17.355L18.5149 18.255C18.7232 18.3337 18.9662 18.255 19.0819 18.0525L20.9334 14.9362C21.0491 14.7337 21.0028 14.4975 20.8176 14.3625L18.8852 12.8775ZM12 15.375C10.0907 15.375 8.52849 13.8562 8.52849 12C8.52849 10.1437 10.0907 8.625 12 8.625C13.9093 8.625 15.4715 10.1437 15.4715 12C15.4715 13.8562 13.9093 15.375 12 15.375Z"
        fill="currentColor"
      />
    </svg>
  );
}
