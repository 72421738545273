export const valuesRegex = [/[0-9.-]/, /[0-9.\-/]/, /[0-9.\-/]/, /[0-9]/];
export const acceptedRegex =
  /(^-?\d*\.?\d*$)|(^-?\d+$)|(^-?\d?\/\d*$)|(^.?\d?\/\d?$)/;
export const hints = [
  'Number 0-9 in each box',
  'Point (.) on box 1-3',
  'Negative (-) on box 1-3',
  'Slash (/) on box 2-3',
];
export const rules = [
  {
    required: true,
    display: 'At least 1 grid-in box should be filled in',
  },
  {
    regex: /^[/]/,
    display: 'Answer should not start in slash (/)',
  },
  {
    regex: /[/|.|-]$/,
    display: 'Answer should not end in point (.), slash (/) or negative (-)',
  },
  {
    regex: /\s/,
    display: 'Answer should not includes spaces',
  },
];
