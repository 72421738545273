/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useRef, useState } from 'react';

export type Dispatch<A> = (action: A) => any;
export type MiddlewareAPI<S, A> = { getState: () => S; dispatch: Dispatch<A> };
export type Middleware<S, A> = (
  api: MiddlewareAPI<S, A>
) => (next: Dispatch<A>) => Dispatch<A>;

function enhanceDispatch<State, Action>({
  getState,
  stateDispatch,
}: {
  getState: () => State;
  stateDispatch: (action: Action) => Action;
}) {
  return (...middlewares: Middleware<State, Action>[]) => {
    const dispatch: Dispatch<Action> = middlewares
      .map((m) =>
        m({
          getState,
          dispatch: (action: Action) => dispatch(action),
        })
      )
      .reduceRight((next, mw) => mw(next), stateDispatch);
    return dispatch;
  };
}

function useMiddlewareReducer<State, Action>(
  middlewares: Middleware<State, Action>[],
  reducer: any,
  initState: State,
  initializer = (s: State) => s
): [State, Dispatch<Action>] {
  const [state, setState] = useState(initializer(initState));
  const stateRef = useRef(state); // stores most recent state
  const dispatch = useMemo(
    () =>
      enhanceDispatch({
        getState: () => stateRef.current, // access most recent state
        stateDispatch: (action: Action) => {
          if (typeof reducer === 'function') {
            stateRef.current = reducer(stateRef.current, action); // makes getState() possible
            setState(stateRef.current); // trigger re-render
          }
          return action;
        },
      })(...middlewares),
    [middlewares, reducer]
  );

  return [state, dispatch];
}

export default useMiddlewareReducer;
