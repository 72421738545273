import React, { KeyboardEvent, useEffect, useRef } from 'react';
import { Switch } from '@mui/material';
import Box from '@mui/material/Box';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import useTableSelectionContext from '../../hooks/useTableSelectionContext';
import { TableLayoutRow, TableLayoutSwitchCell } from '../../types';

export type IaSwitchCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutSwitchCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    height: '32px',
  },
  switch: {
    width: 28,
    height: 16,
    '& .MuiSwitch-switchBase': {
      width: 16,
      height: 16,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
      },
    },
    '& .MuiSwitch-thumb': {
      width: 14,
      height: 14,
    },
  },
};
export default function IaSwitchCell({
  row,
  columnKey,
  cell,
}: IaSwitchCellProps) {
  const switchRef = useRef<HTMLButtonElement>(null);
  const { getIaAction } = useIaAction();

  const handleChange = (checked: boolean) => {
    getIaAction<TableLayoutRow>(cell.changeAction)?.action(row, checked);
  };

  const { getCellSelectedState } = useTableSelectionContext();
  const selectedState = getCellSelectedState(row.id, columnKey);

  useEffect(() => {
    if (selectedState === 'focused') {
      switchRef.current?.focus({
        preventScroll: true,
      });
    } else {
      switchRef.current?.blur();
    }
  }, [selectedState]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return;
    switchRef.current?.click();
  };

  return (
    <Box sx={styles.root}>
      <Switch
        sx={styles.switch}
        defaultChecked={cell.defaultChecked}
        checked={cell.checked}
        disabled={cell.disabled}
        onChange={(ev) => handleChange(ev.target.checked)}
        onClick={(ev) => ev.stopPropagation()}
        inputRef={switchRef}
        onKeyDown={handleKeyDown}
        autoFocus
      />
    </Box>
  );
}
