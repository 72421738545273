import { useCallback } from 'react';
import { IaAvailableAction } from '@lib/ia/src/core/IaAction';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import {
  IaCellValueChangedEvent,
  TableLayoutIconTextCell,
  TableLayoutNumberCell,
  TableLayoutRow,
  TableLayoutStatusCell,
  TableLayoutTextCell,
  TableLayoutURLCell,
} from '../../types';
import { SidePeekItem } from '../types';

export default function useCellActionGenerator() {
  const { getIaAction } = useIaAction();

  const generateCellAction = useCallback(
    <T = string>(
      item: SidePeekItem<
        | TableLayoutTextCell
        | TableLayoutNumberCell
        | TableLayoutStatusCell
        | TableLayoutURLCell
      >,
      row: TableLayoutRow
    ): Record<string, IaAvailableAction> => {
      const actionMap = item.cell.actionMap;
      if (!actionMap) return {};
      if (!actionMap?.valueChange) return {};
      const valueChangeAction = getIaAction<IaCellValueChangedEvent<T>>(
        actionMap.valueChange
      );
      if (!valueChangeAction) return {};

      return {
        [`${item.columnKey}Change`]: {
          action: ({ value: newValue }: { value: T }) => {
            valueChangeAction.action({
              value: newValue,
              row,
              columnKey: item.columnKey,
            });
          },
        },
      };
    },
    [getIaAction]
  );

  const generateIconTextCellAction = useCallback(
    (
      item: SidePeekItem<TableLayoutIconTextCell>,
      row: TableLayoutRow
    ): Record<string, IaAvailableAction> => {
      const actionMap = item.cell.actionMap;
      if (!actionMap) return {};

      const valueChangeAction = getIaAction<
        IaCellValueChangedEvent<string | null>
      >(actionMap.valueChange);

      const pickEmojiAction = getIaAction<TableLayoutRow>(actionMap.pickEmoji);

      return {
        [`${item.columnKey}Change`]: {
          action: ({ value: newValue }: { value: string | null }) => {
            valueChangeAction?.action({
              value: newValue,
              row,
              columnKey: item.columnKey,
            });
          },
        },
        [`${item.columnKey}PickEmoji`]: {
          action: () => {
            pickEmojiAction?.action(row);
          },
        },
      };
    },
    [getIaAction]
  );

  const generateCellActions = useCallback(
    (
      items: SidePeekItem[],
      row: TableLayoutRow
    ): Record<string, IaAvailableAction> => {
      let actions: Record<string, IaAvailableAction> = {};
      for (const item of items) {
        if (item.cell.type === 'text') {
          const action = generateCellAction<string>(
            item as SidePeekItem<TableLayoutTextCell>,
            row
          );
          actions = {
            ...actions,
            ...action,
          };
        } else if (item.cell.type === 'number') {
          const action = generateCellAction<number | null>(
            item as SidePeekItem<TableLayoutNumberCell>,
            row
          );
          actions = {
            ...actions,
            ...action,
          };
        } else if (item.cell.type === 'status') {
          const action = generateCellAction<string | null>(
            item as SidePeekItem<TableLayoutStatusCell>,
            row
          );
          actions = {
            ...actions,
            ...action,
          };
        } else if (item.cell.type === 'url') {
          const action = generateCellAction<string | null>(
            item as SidePeekItem<TableLayoutURLCell>,
            row
          );
          actions = {
            ...actions,
            ...action,
          };
        } else if (item.cell.type === 'iconText') {
          const action = generateIconTextCellAction(
            item as SidePeekItem<TableLayoutIconTextCell>,
            row
          );
          actions = {
            ...actions,
            ...action,
          };
        }
      }
      return actions;
    },
    [generateCellAction, generateIconTextCellAction]
  );

  return generateCellActions;
}
