import React from 'react';

export default function MainAnalytics({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_14_420)">
        <path
          d="M3 12.9C3 11.9059 3.80589 11.1 4.8 11.1H5.7C6.69411 11.1 7.5 11.9059 7.5 12.9V19.2C7.5 20.1941 6.69411 21 5.7 21H4.8C3.80589 21 3 20.1941 3 19.2V12.9Z"
          fill="currentColor"
        />
        <path
          d="M9.3 9.3C9.3 8.30589 10.1059 7.5 11.1 7.5H12C12.9941 7.5 13.8 8.30589 13.8 9.3V19.2C13.8 20.1941 12.9941 21 12 21H11.1C10.1059 21 9.3 20.1941 9.3 19.2V9.3Z"
          fill="currentColor"
        />
        <path
          d="M16.5 4.8C16.5 3.80589 17.3059 3 18.3 3H19.2C20.1941 3 21 3.80589 21 4.8V19.2C21 20.1941 20.1941 21 19.2 21H18.3C17.3059 21 16.5 20.1941 16.5 19.2V4.8Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_14_420">
          <rect height="24" width="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
