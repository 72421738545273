import React from 'react';

export default function TestEmoji({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.1842 17C9.49774 17 8.08314 15.8643 7.69976 14.3339C7.58662 13.8823 7.98354 13.4998 8.45999 13.4998H13.9083C14.3848 13.4998 14.7817 13.8823 14.6686 14.3339C14.2852 15.8643 12.8706 17 11.1842 17Z"
        fill="currentColor"
      />
      <path
        d="M9.85245 10.3917C9.85245 11.1604 9.21391 11.7835 8.42622 11.7835C7.63854 11.7835 7 11.1604 7 10.3917C7 9.62311 7.63854 9 8.42622 9C9.21391 9 9.85245 9.62311 9.85245 10.3917Z"
        fill="currentColor"
      />
      <path
        d="M15 10.5249C15 11.2936 14.3615 11.9167 13.5738 11.9167C12.7861 11.9167 12.1476 11.2936 12.1476 10.5249C12.1476 9.75629 12.7861 9.13318 13.5738 9.13318C14.3615 9.13318 15 9.75629 15 10.5249Z"
        fill="currentColor"
      />
      <path
        d="M4.25151 12.2647C4.25151 8.67342 7.20699 5.76213 10.8528 5.76213C11.6917 5.76213 12.4926 5.91598 13.2291 6.19603C13.5516 6.31862 13.9138 6.16055 14.0383 5.84296C14.1627 5.52537 14.0022 5.16854 13.6798 5.04595C12.8018 4.71209 11.8482 4.52934 10.8528 4.52934C6.5158 4.52934 3 7.99256 3 12.2647C3 16.5368 6.5158 20 10.8528 20C15.1897 20 18.7055 16.5368 18.7055 12.2647C18.7055 11.9242 18.4254 11.6483 18.0798 11.6483C17.7342 11.6483 17.454 11.9242 17.454 12.2647C17.454 15.8559 14.4985 18.7672 10.8528 18.7672C7.20699 18.7672 4.25151 15.8559 4.25151 12.2647Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M18.5213 4.6164C18.5213 4.27597 18.2411 4 17.8955 4C17.5499 4 17.2698 4.27597 17.2698 4.6164V6.44102L15.4186 6.44102C15.073 6.44102 14.7928 6.71699 14.7928 7.05742C14.7928 7.39785 15.073 7.67382 15.4186 7.67382L17.2698 7.67382V9.49795C17.2698 9.83838 17.5499 10.1143 17.8955 10.1143C18.2411 10.1143 18.5213 9.83838 18.5213 9.49795V7.67382H20.3742C20.7198 7.67382 21 7.39785 21 7.05742C21 6.71699 20.7198 6.44102 20.3742 6.44102L18.5213 6.44102V4.6164Z"
        fill="currentColor"
      />
    </svg>
  );
}
