import React from 'react';

export default function OtherShopping({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V8C4 7.45 4.196 6.979 4.588 6.587C4.98 6.195 5.45067 5.99933 6 6H8C8 4.9 8.39167 3.95833 9.175 3.175C9.95834 2.39167 10.9 2 12 2C13.1 2 14.0417 2.39167 14.825 3.175C15.6083 3.95833 16 4.9 16 6H18C18.55 6 19.021 6.196 19.413 6.588C19.805 6.98 20.0007 7.45067 20 8V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM10 6H14C14 5.45 13.804 4.979 13.412 4.587C13.02 4.195 12.5493 3.99933 12 4C11.45 4 10.979 4.196 10.587 4.588C10.195 4.98 9.99934 5.45067 10 6ZM15 11C15.2833 11 15.521 10.904 15.713 10.712C15.905 10.52 16.0007 10.2827 16 10V8H14V10C14 10.2833 14.096 10.521 14.288 10.713C14.48 10.905 14.7173 11.0007 15 11ZM9 11C9.28334 11 9.521 10.904 9.713 10.712C9.905 10.52 10.0007 10.2827 10 10V8H8V10C8 10.2833 8.096 10.521 8.288 10.713C8.48 10.905 8.71734 11.0007 9 11Z"
        fill="currentColor"
      />
    </svg>
  );
}
