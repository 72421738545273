import React, { ReactNode } from 'react';
import ButtonBase, {
  ButtonBaseProps,
  ButtonBaseTypeMap,
} from '@mui/material/ButtonBase';
import { useTheme } from '@mui/material/styles';

import LoadingIcon from '../../../components/LoadingIcon';

import { ButtonColorType, ButtonSizeType, ButtonVariantType } from './types';
import {
  getDefaultStyle,
  getHoverStyle,
  getLoadingStyle,
  getSize,
  getTheme,
} from './utils';

export type ShadowButtonProps<
  D extends React.ElementType = ButtonBaseTypeMap['defaultComponent'],
  P = {
    color?: ButtonColorType;
    variant?: ButtonVariantType;
    loading?: boolean;
    suffixIcon?: ReactNode;
    prefixIcon?: ReactNode;
    icon?: boolean;
    size?: ButtonSizeType;
    component?: D;
  }
> = ButtonBaseProps<D, P>;

const styles = {
  root: {
    position: 'relative',
    minHeight: 36,
    typography: 'button',
    fontWeight: 700,
    transform: 'translateY(-3px)',

    px: 2.5,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 0.5,
    borderRadius: 1,
    transitionDuration: '0.2s',
    transitionProperty: 'transform, color',

    '& svg': {
      display: 'block',
      width: 16,
      height: 16,
    },

    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        transform: 'translateY(0)',
        boxShadow: 'none',
      },
    },
    '&:active': {
      transform: 'translateY(0)',
      boxShadow: 'none',
    },
  },
  icon: {
    minWidth: 36,
    px: 0,
  },
  disabled: {
    opacity: 0.5,
    transform: 'translateY(0)',
    boxShadow: 'none',
  },
  loading: {
    transform: 'translateY(0)',
    boxShadow: 'none',
  },
};

function ShadowButtonInner<D extends React.ElementType>(
  {
    sx,
    children,
    icon = false,
    color = 'default',
    variant = 'filled',
    size,
    disabled,
    loading,
    prefixIcon,
    suffixIcon,
    ...rest
  }: ShadowButtonProps<D>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const { palette } = useTheme();
  const buttonTheme = getTheme(palette, color, variant);
  const defaultStyle = getDefaultStyle(buttonTheme);
  const hoverStyle = disabled || loading ? false : getHoverStyle(buttonTheme);
  const loadingStyle = loading ? getLoadingStyle(buttonTheme) : false;
  const minWidth = getSize(size);

  return (
    <ButtonBase
      ref={ref}
      sx={[
        styles.root,
        defaultStyle,
        hoverStyle,
        loadingStyle,
        { minWidth },
        icon && styles.icon,
        loading && styles.loading,
        disabled && !loading && styles.disabled,
        ...sxProps,
      ]}
      disableTouchRipple
      disableRipple
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <LoadingIcon />
      ) : (
        <>
          {!!prefixIcon && prefixIcon}
          {children}
          {!!suffixIcon && suffixIcon}
        </>
      )}
    </ButtonBase>
  );
}

const ShadowButton = React.forwardRef(ShadowButtonInner) as <
  D extends React.ElementType = 'button'
>(
  props: ShadowButtonProps<D> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof ShadowButtonInner<D>>;

export default ShadowButton;
