import React from 'react';

export default function EditorReplace({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.09791 9.92059C2.8989 9.44016 3.0089 8.88715 3.37661 8.51944L8.51945 3.37658C9.02155 2.87447 9.83562 2.87447 10.3377 3.37658C10.8398 3.87868 10.8398 4.69275 10.3377 5.19485L7.38972 8.14286H19.7143C20.4243 8.14286 21 8.71849 21 9.42857C21 10.1387 20.4243 10.7143 19.7143 10.7143L4.28575 10.7143C3.76573 10.7143 3.29691 10.401 3.09791 9.92059ZM3.00004 14.5714C3.00004 13.8613 3.57567 13.2857 4.28575 13.2857L19.7143 13.2857C20.2343 13.2857 20.7031 13.599 20.9021 14.0794C21.1011 14.5598 20.9911 15.1129 20.6234 15.4806L15.4806 20.6234C14.9785 21.1255 14.1644 21.1255 13.6623 20.6234C13.1602 20.1213 13.1602 19.3073 13.6623 18.8051L16.6103 15.8571L4.28575 15.8571C3.57567 15.8571 3.00004 15.2815 3.00004 14.5714Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
