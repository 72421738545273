import { createContext, ReactNode } from 'react';
import { ComposerBlock } from '@lib/web/composer';
import { ThreadComposerProps } from '@lib/web/thread/ThreadComposer';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel } from 'stream-chat';

export type ThreadComposerCustomContextValue = {
  getThreadComposerCustomProps: (props?: {
    channel?: Channel<StreamChatGenerics>;
    view?: GetThreadViewRes;
  }) => Partial<ThreadComposerProps>;
  composerBlocksToHtml: (composerBlocks: ComposerBlock[]) => string;
  isBlockHtmlEmpty: (html: string) => boolean;
  contentEnricher?: (content: string) => string;
};

export const ThreadComposerCustomContext =
  createContext<ThreadComposerCustomContextValue>({
    getThreadComposerCustomProps: () => ({}),
    composerBlocksToHtml: () => '',
    isBlockHtmlEmpty: () => false,
    contentEnricher: () => '',
  });

type ThreadComposerCustomPropsContextProviderProps =
  ThreadComposerCustomContextValue & {
    children: ReactNode;
  };

export function ThreadComposerCustomContextProvider({
  children,
  ...value
}: ThreadComposerCustomPropsContextProviderProps) {
  return (
    <ThreadComposerCustomContext.Provider value={value}>
      {children}
    </ThreadComposerCustomContext.Provider>
  );
}
