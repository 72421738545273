import React from 'react';

export default function ProfileMyPlan({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.5003 17.5765C15.4904 17.5524 15.4757 17.5331 15.4559 17.5139C15.4362 17.4946 15.4214 17.4753 15.4017 17.4609C15.382 17.4464 15.3623 17.432 15.3376 17.4175C15.2834 17.3886 15.2193 17.3693 15.1503 17.3693H8.84538C8.62848 17.3693 8.45101 17.5428 8.45101 17.7548C8.45101 17.9668 8.62848 18.1402 8.84538 18.1402H15.1552C15.2243 18.1402 15.2883 18.1209 15.3426 18.092C15.4116 18.0583 15.4658 18.0005 15.5003 17.9379C15.52 17.9041 15.5299 17.8704 15.5397 17.8319C15.5447 17.8078 15.5496 17.7789 15.5496 17.7548C15.5496 17.7259 15.5447 17.7018 15.5397 17.6777C15.5348 17.6488 15.52 17.6102 15.5003 17.5765Z"
        fill="currentColor"
      />
      <path
        d="M14.7116 19.1184C14.7018 19.0943 14.687 19.075 14.6673 19.0558C14.6475 19.0365 14.6328 19.0172 14.613 19.0028C14.5933 18.9883 14.5736 18.9739 14.549 18.9594C14.4947 18.9305 14.4306 18.9112 14.3616 18.9112H9.63416C9.41726 18.9112 9.2398 19.0847 9.2398 19.2967C9.2398 19.5087 9.41726 19.6821 9.63416 19.6821H14.3666C14.4356 19.6821 14.4997 19.6628 14.5539 19.6339C14.6229 19.6002 14.6771 19.5424 14.7116 19.4798C14.7313 19.446 14.7412 19.4123 14.7511 19.3738C14.756 19.3497 14.7609 19.3208 14.7609 19.2967C14.7609 19.2678 14.756 19.2437 14.7511 19.2196C14.7461 19.1907 14.7313 19.1521 14.7116 19.1184Z"
        fill="currentColor"
      />
      <path
        d="M12.2366 2H11.7585C8.19437 2 5.26127 4.71739 5.01479 8.14303L5 8.15748V8.61038C5 8.98137 5.02958 9.35236 5.09366 9.71372C5.36479 11.2796 6.19789 12.7009 7.47958 13.7272L8.85 14.8209C8.85 14.8209 9.25423 15.0955 9.25423 15.5532V16.5891H14.7606V15.4761C14.7704 15.3412 14.8296 15.057 15.15 14.8257L16.5204 13.732C17.8021 12.7057 18.6401 11.2844 18.9063 9.71854C18.9704 9.35718 19 8.98619 19 8.6152V8.1623H18.9852C18.7486 4.72221 15.8106 2 12.2366 2Z"
        fill="currentColor"
      />
      <path
        d="M11.9994 22C12.9508 22 13.7494 21.3351 13.9318 20.4582H10.067C10.2494 21.3399 11.048 22 11.9994 22Z"
        fill="currentColor"
      />
    </svg>
  );
}
