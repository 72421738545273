import { useCallback } from 'react';
import { useRouter } from 'next/router';

export default function useClearRoute() {
  const { pathname, replace, push, query, asPath } = useRouter();

  const clearRouteParams = useCallback(
    (clearParams: string[], replaceQueries = {}) => {
      const hashIndex = asPath.indexOf('#');
      const currentHash =
        hashIndex !== -1 ? asPath.substring(hashIndex) : undefined;

      const newQuery = Object.keys(query).reduce((acc, key) => {
        if (!clearParams.includes(key)) {
          acc[key] = query[key] as string;
        }
        return acc;
      }, {} as Record<string, string>);

      return replace(
        {
          pathname,
          hash: currentHash,
          query: { ...newQuery, ...replaceQueries },
        },
        undefined,
        {
          shallow: true,
        }
      );
    },
    [asPath, pathname, query, replace]
  );

  const forceRoute = useCallback(
    async (url: string) => {
      await push(`${url}${url.includes('?') ? '&' : '?'}forceRoute=true`);
      void clearRouteParams(['forceRoute']);
    },
    [clearRouteParams, push]
  );

  return {
    clearRouteParams,
    forceRoute,
  };
}
