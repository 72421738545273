import { createContext, ReactNode, useMemo } from 'react';

export type AnswerOptionsContextValue = {
  horizontal?: boolean;
  pinned?: boolean;
};

const initialValue: AnswerOptionsContextValue = {
  horizontal: false,
  pinned: false,
};

export const AnswerOptionsContext =
  createContext<AnswerOptionsContextValue>(initialValue);

export type AnswerOptionsContextProviderProps = {
  children: ReactNode;
} & AnswerOptionsContextValue;

export const AnswerOptionsContextProvider = ({
  children,
  horizontal,
  pinned,
}: AnswerOptionsContextProviderProps) => {
  const memoizedValue = useMemo(() => {
    return {
      horizontal,
      pinned,
    };
  }, [horizontal, pinned]);

  return (
    <AnswerOptionsContext.Provider value={memoizedValue}>
      {children}
    </AnswerOptionsContext.Provider>
  );
};
