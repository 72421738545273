import { FieldValues, UseFormGetValues } from 'react-hook-form';
import axios, { AxiosRequestConfig } from 'axios';
import { isEmpty, isNumber, number, PropertyType } from '@front/ui';
import { compareAsc, compareDesc, isSameDay } from 'date-fns';

import LabelIcon from './components/LabelIcon';
import { FormLayoutBaseItem, FormLayoutItemCustomRule } from './types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FormulaParser = require('hot-formula-parser').Parser;
const formulaParser = new FormulaParser();
const formulaRegex = /\[\[(.*?)\]\]/g;
const formulaMessageMap = {
  // this messages comes from the pacakge, we might want to refine it when needed
  '#ERROR!': 'Formula error',
  '#DIV/0!': 'Divide by zero error',
  '#NAME?': 'Not recognized function name or variable name',
  '#N/A': 'Value not available to a formula',
  '#NUM!': 'Invalid number encountered in formula',
  '#VALUE!': 'One of formula arguments is of the wrong type',
};

export const getLabelIcon = (
  type:
    | 'email'
    | 'text'
    | 'number'
    | 'textarea'
    | 'date'
    | 'year'
    | 'autoComplete'
    | 'select',
  customIcon?: FormLayoutBaseItem['icon']
) => {
  if (customIcon) return <LabelIcon icon={customIcon} />;
  const labelIconType = {
    email: PropertyType.Email,
    text: PropertyType.ShortText,
    number: PropertyType.Number,
    textarea: PropertyType.LongText,
    date: PropertyType.DateOrTime,
    year: PropertyType.DateOrTime,
    autoComplete: PropertyType.Select,
    dropdown: PropertyType.Select,
    select: PropertyType.Select,
  }[type];

  if (labelIconType) return labelIconType;

  return undefined;
};

const instance = axios.create({
  withCredentials: true,
});

const checkAPI = async (
  apiUrl: string,
  config?: AxiosRequestConfig | undefined
) => {
  try {
    const response = await instance.get(apiUrl, config);
    if (response.data.data?.items?.length) return false; // ia format response
    return true; // old response (check user name)
  } catch (err) {
    return false;
  }
};

const checkUnique = async (
  value: string,
  rule: { api: string; message: string },
  token?: string
) => {
  const checked = await checkAPI(
    rule.api.replace(/\{(.*?)\}/, encodeURIComponent(value)),
    {
      headers: { Authorization: token ? `Bearer ${token}` : undefined },
    }
  );

  return checked ? true : rule.message;
};
const checkTextInRegex = (
  value: string,
  rule: { value: RegExp; message: string; separator: string | RegExp }
) => {
  const allValues = value.split(rule.separator).map((text) => text.trim());

  const errorFormatValue = allValues.filter((text) => !rule.value.test(text));

  return errorFormatValue.length === 0
    ? true
    : rule.message.replaceAll(
        '{value}',
        errorFormatValue.map((text) => `‘${text}’`).join(', ')
      );
};

type SyncValidate = (v: string) => boolean | string;
type AsyncValidate = (v: string) => Promise<boolean | string>;
export const getCustomValidate = (
  rules: Partial<FormLayoutItemCustomRule>,
  {
    getValues,
    token,
  }: {
    getValues?: UseFormGetValues<FieldValues>;
    token?: string;
  }
) => {
  const validateResult: Record<string, SyncValidate | AsyncValidate> = {};
  const {
    syncUnique,
    notInArray,
    regex,
    textInRegex,
    differentAs,
    maxValueWithField,
    minValueWithField,
    validate,
    formula,
    minDate,
    maxDate,
  } = rules;
  if (syncUnique) {
    validateResult.syncUnique = (value: string) =>
      checkUnique(value || syncUnique?.defaultValue || '', syncUnique, token);
  }
  if (notInArray) {
    validateResult.notInArray = (value: string) =>
      notInArray.values.includes(value) ? notInArray.message : true;
  }
  if (regex) {
    validateResult.regex = (value: string) =>
      !isEmpty(value) && !regex.value.test(value)
        ? regex.message.replaceAll('{value}', value)
        : true;
  }
  if (textInRegex) {
    validateResult.textInRegex = (value?: string) =>
      typeof value === 'string' ? checkTextInRegex(value, textInRegex) : true;
  }
  if (differentAs) {
    validateResult.differentAs = (value: string | number) =>
      !isEmpty(value) && `${value}` === `${differentAs.value}`
        ? differentAs.message
        : true;
  }
  if (maxValueWithField) {
    validateResult.maxValueWithField = (value: string | number) => {
      if (!getValues) {
        console.warn(
          'maxValueWithField validation requires getValues function'
        );
        return true;
      }
      let compareValue = getValues(maxValueWithField.field);
      if (maxValueWithField.defaultValue !== undefined) {
        compareValue = compareValue ?? maxValueWithField.defaultValue;
      }

      if (
        isNumber(compareValue) &&
        isNumber(value) &&
        number(compareValue) < number(value)
      ) {
        return maxValueWithField.message;
      }

      return true;
    };
  }
  if (minValueWithField) {
    validateResult.minValueWithField = (value: string | number) => {
      if (!getValues) {
        console.warn(
          'minValueWithField validation requires getValues function'
        );
        return true;
      }
      let compareValue = getValues(minValueWithField.field);
      if (minValueWithField.defaultValue !== undefined) {
        compareValue = compareValue ?? minValueWithField.defaultValue;
      }

      if (
        isNumber(compareValue) &&
        isNumber(value) &&
        number(compareValue) > number(value)
      ) {
        return minValueWithField.message;
      }

      return true;
    };
  }
  if (minDate) {
    validateResult.minDate = (value: string | Date) => {
      if (!value) return true;
      const currentDate = value instanceof Date ? value : new Date(value);
      return compareDesc(currentDate, minDate.value) === 1 &&
        !isSameDay(currentDate, minDate.value)
        ? minDate.message
        : true;
    };
  }
  if (maxDate) {
    validateResult.maxDate = (value: string | Date) => {
      if (!value) return true;
      const currentDate = value instanceof Date ? value : new Date(value);
      return compareAsc(maxDate.value, currentDate) === 1 &&
        !isSameDay(currentDate, maxDate.value)
        ? maxDate.message
        : true;
    };
  }
  if (validate) {
    validateResult.validate = (value: string | number) => {
      if (validate.field && !!getValues) {
        const compareValue = getValues(validate.field);

        return validate.handler(value, compareValue);
      }

      return validate.handler(value);
    };
  }

  if (formula) {
    validateResult.formula = (value: string) => {
      if (value) {
        const parser = formulaParser.parse(value.replaceAll(formulaRegex, '1'));

        if (parser.error)
          return (
            formulaMessageMap[parser.error as keyof typeof formulaMessageMap] ||
            formula.message
          );
      }

      return true;
    };
  }
  return validateResult;
};
