export const extractNumber = (s?: string): number | null => {
  if (!s) return null;
  const chars = s.split('');
  let result = '';
  for (const c of chars) {
    if (c.match(/\d/)) result += c;
    if (c === '.' && !result.includes('.')) result += '.';
    if (c === '-') {
      if (result.length === 0) {
        result += '-';
      } else {
        // follow Notion behavior
        break;
      }
    }
  }
  if (!result || result === '-') return null;
  return Number(result);
};
