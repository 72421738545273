import { Trans } from 'next-i18next';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { AnswerFormatType } from '@lib/web/apis';

export default function AnswerHintCommon({
  answerFormat,
}: {
  answerFormat: AnswerFormatType;
}) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Typography variant="caption">
      <Trans
        i18nKey={`quiz::practice.keyboard.hint.${answerFormat}`}
        components={{
          1: <span />,
        }}
        context={mdDown ? 'mobile' : undefined}
      />
    </Typography>
  );
}
