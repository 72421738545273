import React from 'react';

export default function TestDislikeFilled({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21 4H19.2C18.705 4 18.3 4.4 18.3 4.88889V12.8889C18.3 13.3778 18.705 13.7778 19.2 13.7778H21V4ZM3.153 10.3289C3.054 10.5511 3 10.7911 3 11.04V12C3 12.9778 3.81 13.7778 4.8 13.7778H9.75L8.922 17.9111C8.877 18.1067 8.904 18.32 8.994 18.4978C9.201 18.8978 9.462 19.2622 9.786 19.5822L10.2 20L15.969 14.3022C16.311 13.9644 16.5 13.5111 16.5 13.04V6.08C16.5 4.93333 15.555 4 14.394 4H7.104C6.465 4 5.88 4.32889 5.556 4.86222L3.153 10.3289Z"
        fill="currentColor"
      />
    </svg>
  );
}
