import React from 'react';

export default function ProfileLeague({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.904 4.00024H6.38472L3 7.88279L7.13098 8.61269L9.904 4.00024Z"
        fill="currentColor"
      />
      <path
        d="M3.13477 8.56494L10.8028 18.7649L6.95145 9.23909L3.13477 8.56494Z"
        fill="currentColor"
      />
      <path
        d="M11.987 8.63942H16.1009L13.3128 4.00024H10.6875L7.89941 8.63942H11.987Z"
        fill="currentColor"
      />
      <path
        d="M12.0005 9.28638H7.7002L12.0311 19.9999L12.2372 19.4968L16.3031 9.2866L12.0005 9.28638Z"
        fill="currentColor"
      />
      <path
        d="M13.2974 18.6313L20.8654 8.56543L17.0489 9.23966L13.2974 18.6313Z"
        fill="currentColor"
      />
      <path
        d="M14.0962 4.00015L16.869 8.61245L21 7.88255L17.6153 4L14.0962 4.00015Z"
        fill="currentColor"
      />
    </svg>
  );
}
