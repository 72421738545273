import React from 'react';

export default function EditorXAxis({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.1929 21C15.6956 19.9784 15.0697 18.8528 14.3151 17.6234C13.5606 16.3939 12.7717 15.2078 11.9486 14.0649C11.6056 14.5152 11.2283 15.0519 10.8167 15.6753C10.4223 16.2987 10.0193 16.9394 9.60772 17.5974C9.21329 18.2381 8.84459 18.8615 8.50161 19.4675C8.15863 20.0736 7.88424 20.5844 7.67846 21H4C4.7717 19.5281 5.66345 18.0216 6.67524 16.4805C7.68703 14.9221 8.78457 13.2944 9.96785 11.5974L4.25723 3H8.11576L12.0514 9.15584L15.9357 3H19.6399L14.0064 11.5714C15.3098 13.355 16.4587 15.0433 17.4534 16.6364C18.4652 18.2294 19.314 19.684 20 21H16.1929Z"
        fill="currentColor"
      />
    </svg>
  );
}
