import { useCallback, useContext } from 'react';
import { useAsyncQueryPool, useLatestValueRef } from '@front/helper';
import {
  apis,
  DynamicSourceItemType,
  QuizGroupType,
  useAuth,
} from '@lib/web/apis';
import { SharedUserContext, ShortIdPoolContext } from '@lib/web/contexts';
import { call } from '@lib/web/utils';

export const QUIZ_SHORT_ID_LENGTH = 12;

const useQuizShortIdPool = () => {
  const { sid } = useContext(SharedUserContext);
  const { isLogged } = useAuth();
  const { quizShortIdToFullId, setQuizShortIdToFullId } =
    useContext(ShortIdPoolContext);
  const readyRef = useLatestValueRef(isLogged || !!sid);

  const { asyncGet } = useAsyncQueryPool({
    poolKey: 'shortIdPool-quiz',
    map: quizShortIdToFullId,
    setMap: setQuizShortIdToFullId,
    readyRef,
    queryApi: async (keys) => {
      const [res] = await call(
        apis.tool.getToolShortIdUuid({
          quizShortIdAry: keys,
        })
      );
      return res?.data.quizIdAry;
    },
  });

  const getQuizFullId = useCallback(
    (shortIdOrFullId: string | undefined) => {
      if (!shortIdOrFullId) return;

      if (shortIdOrFullId.length > QUIZ_SHORT_ID_LENGTH) {
        return shortIdOrFullId; // full id
      }

      return asyncGet(shortIdOrFullId) || undefined;
    },
    [asyncGet]
  );

  const getQuizShortId = (shortIdOrFullId: string | undefined) => {
    if (!shortIdOrFullId) return;

    if (shortIdOrFullId.length === QUIZ_SHORT_ID_LENGTH) {
      return shortIdOrFullId;
    }

    return Object.keys(quizShortIdToFullId).find(
      (key) => quizShortIdToFullId[key] === shortIdOrFullId
    );
  };

  return {
    getQuizFullId,
    getQuizShortId,
  };
};

const useDynamicSourceIdPool = () => {
  const SOURCE_KEY_SEPARATOR = ':';
  const { sid } = useContext(SharedUserContext);
  const { isLogged } = useAuth();
  const { dynamicSourceIdToFullId, setDynamicSourceIdToFullId } =
    useContext(ShortIdPoolContext);
  const readyRef = useLatestValueRef(isLogged || !!sid);

  const { asyncGet } = useAsyncQueryPool({
    poolKey: 'shortIdPool-sourceId',
    map: dynamicSourceIdToFullId,
    setMap: setDynamicSourceIdToFullId,
    readyRef,
    queryApi: async (keys) => {
      const [res] = await call(
        apis.tool.getToolDynamicSourceId({
          items: keys.map((key) => {
            const [type, sourceId] = key.split(SOURCE_KEY_SEPARATOR);
            if (sourceId) return { type, codeAry: [sourceId] };
            return { type };
          }),
        })
      );
      return res?.data.map((item) => item.sourceIdAry[0]);
    },
  });

  const getSourceIdFullId = useCallback(
    (sourceIdKey: string) => {
      return asyncGet(sourceIdKey) || undefined;
    },
    [asyncGet]
  );

  const getEmojiFullId = useCallback(
    (emojiCode: string | undefined) => {
      return (
        emojiCode &&
        getSourceIdFullId(
          `${DynamicSourceItemType.Emoji}${SOURCE_KEY_SEPARATOR}${emojiCode}`
        )
      );
    },
    [getSourceIdFullId]
  );

  const getHashtagFullId = useCallback(
    (hashtag: string | undefined) => {
      return (
        hashtag &&
        getSourceIdFullId(
          `${DynamicSourceItemType.Hashtags}${SOURCE_KEY_SEPARATOR}${hashtag}`
        )
      );
    },
    [getSourceIdFullId]
  );

  const getOvertimeFullId = useCallback(() => {
    return getSourceIdFullId(`${DynamicSourceItemType.Overtime}`);
  }, [getSourceIdFullId]);

  const getMistakeFullId = useCallback(() => {
    return getSourceIdFullId(`${DynamicSourceItemType.Mistake}`);
  }, [getSourceIdFullId]);

  const getMarkFullId = useCallback(
    (groupType: QuizGroupType | string | undefined, markId?: string) => {
      switch (groupType) {
        case QuizGroupType.Overtime:
        case 'overtime':
          return getOvertimeFullId();
        case QuizGroupType.Mistake:
        case 'mistakes':
          return getMistakeFullId();
        case QuizGroupType.Emoji:
        case 'emoji':
          return getEmojiFullId(markId);
        case QuizGroupType.Hashtags:
        case 'hashtags':
          return getHashtagFullId(markId);
        default:
          return markId;
      }
    },
    [getEmojiFullId, getHashtagFullId, getMistakeFullId, getOvertimeFullId]
  );

  const getMarkShortId = useCallback(
    (groupType: QuizGroupType | undefined, sourceId: string) => {
      const sourceKey =
        Object.keys(dynamicSourceIdToFullId).find(
          (key) => dynamicSourceIdToFullId[key] === sourceId
        ) || '';
      return sourceKey.split(SOURCE_KEY_SEPARATOR)[1] || '';
    },
    [dynamicSourceIdToFullId]
  );

  return {
    getEmojiFullId,
    getHashtagFullId,
    getOvertimeFullId,
    getMistakeFullId,
    getMarkFullId,
    getMarkShortId,
  };
};

export default function useShortIdPool() {
  return {
    ...useQuizShortIdPool(),
    ...useDynamicSourceIdPool(),
  };
}
