import React from 'react';

export default function ChatThumbsUpOutline({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.2002 10H18.9642C19.3051 10 19.6403 10.0871 19.938 10.2531C20.2357 10.4191 20.4861 10.6584 20.6653 10.9484C20.8446 11.2383 20.9467 11.5692 20.9621 11.9098C20.9775 12.2503 20.9056 12.5891 20.7532 12.894L17.2532 19.894C17.0871 20.2265 16.8316 20.5061 16.5154 20.7014C16.1992 20.8968 15.8349 21.0002 15.4632 21H11.4462C11.2832 21 11.1202 20.98 10.9612 20.94L7.2002 20M14.2002 10V5C14.2002 4.46957 13.9895 3.96086 13.6144 3.58579C13.2393 3.21071 12.7306 3 12.2002 3H12.1052C11.6052 3 11.2002 3.405 11.2002 3.905C11.2002 4.619 10.9892 5.317 10.5922 5.911L7.2002 11V20M14.2002 10H12.2002M7.2002 20H5.2002C4.66976 20 4.16105 19.7893 3.78598 19.4142C3.41091 19.0391 3.2002 18.5304 3.2002 18V12C3.2002 11.4696 3.41091 10.9609 3.78598 10.5858C4.16105 10.2107 4.66976 10 5.2002 10H7.7002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
