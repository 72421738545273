import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  ControlledTooltip,
  SquareAvatar,
  SquareAvatarProps,
} from '../../../atoms';
import { IndicatorGroup } from '../../../components';

export type UserAvatarSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type UserAvatarProps = Omit<
  SquareAvatarProps,
  'children' | 'sx' | 'size'
> & {
  title: string;
  titleSuffix?: ReactNode;
  subTitle?: string;
  sx?: BoxProps['sx'];
  avatarSx?: UserAvatarProps['sx'];
  size?: UserAvatarSize;
  indicators?: { name: string; icon: string }[];
  titleClassName?: string;
  onTitleMouseEnter?: (ev: MouseEvent) => void;
};

const SIZE_MAP = {
  xxs: { avatarSize: 16, fontSize: 12, gap: 0.5, indicatorSize: 16 },
  xs: { avatarSize: 24, fontSize: 14, gap: 1, indicatorSize: 16 },
  sm: { avatarSize: 32, gap: 1, fontSize: 14, indicatorSize: 16 },
  md: { avatarSize: 40, gap: 1, fontSize: 16, indicatorSize: 16 },
  lg: { avatarSize: 64, gap: 2, fontSize: 20, indicatorSize: 16 },
  xl: { avatarSize: 80, gap: 4, fontSize: 30, indicatorSize: 24 },
};

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  subTitle: {
    fontWeight: 400,
    color: 'grey.500',
  },
  label: {
    flex: 1,
    display: 'grid',
    position: 'relative',
    minWidth: 0,
    '& > h6, & > span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  },
  tooltip: {
    maxWidth: '200px',
  },
  titleIndicatorWrapper: {
    display: 'grid',
  },
  titleIndicator: {
    gridTemplateColumns: 'minmax(0, max-content) 16px',
    gap: 0.5,
    alignItems: 'center',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const gridTemplateColumns = (indicatorSize: number) => {
  return {
    gridTemplateColumns: `minmax(0, max-content) ${indicatorSize}px`,
  };
};

export default function UserAvatar({
  title,
  titleSuffix,
  subTitle,
  size = 'md',
  sx,
  avatarSx,
  indicators,
  titleClassName,
  onTitleMouseEnter,
  ...rest
}: UserAvatarProps) {
  const [showTip, setShowTip] = useState(false);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const rootSxProps = Array.isArray(sx) ? sx : [sx];
  const { avatarSize, gap, fontSize, indicatorSize } = SIZE_MAP[size];
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [shouldShowTip, setShouldShowTip] = useState(false);

  const handleMouseEnter = shouldShowTip ? () => setShowTip(true) : undefined;
  const handleMouseLeave = shouldShowTip ? () => setShowTip(false) : undefined;

  useEffect(() => {
    if (!titleRef.current) return;
    setShouldShowTip(
      mdUp && titleRef.current.offsetWidth < titleRef.current.scrollWidth
    );
  }, [titleRef, mdUp]);

  const indicatorGroupSize = indicators?.length
    ? indicators.length * indicatorSize + indicators.length * 4
    : 0;
  return (
    <Box sx={[styles.root, { gap }, ...rootSxProps]}>
      <SquareAvatar sx={avatarSx} size={avatarSize} {...rest}>
        {title}
      </SquareAvatar>
      <Box
        sx={styles.label}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          sx={[
            styles.titleIndicatorWrapper,
            !!indicators?.length && styles.titleIndicator,
            !!indicators?.length && gridTemplateColumns(indicatorGroupSize),
          ]}
        >
          <Typography
            ref={titleRef}
            sx={[{ fontSize }, styles.title]}
            component="h6"
            fontWeight={500}
            className={titleClassName}
            onMouseEnter={onTitleMouseEnter}
          >
            {title} {titleSuffix}
          </Typography>
          {indicators && (
            <IndicatorGroup indicators={indicators} size={indicatorSize} />
          )}
        </Box>

        {shouldShowTip && (
          <ControlledTooltip
            open={showTip}
            anchorEl={titleRef.current}
            title={title}
            placement="bottom-start"
            sx={styles.tooltip}
          />
        )}
        {!!subTitle && (
          <Typography sx={styles.subTitle} variant="caption">
            {subTitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
