import React from 'react';

export default function OtherUnsync({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_8298_657576)">
        <path
          d="M16.6033 4.25314C18.3105 5.28743 19.6302 6.85432 20.3593 8.71246C21.0883 10.5706 21.1862 12.6169 20.6378 14.5361C20.4861 15.0672 19.9326 15.3747 19.4016 15.2229C18.8705 15.0712 18.5631 14.5177 18.7148 13.9867C19.1426 12.4893 19.0662 10.8927 18.4974 9.44295C17.9286 7.99319 16.8989 6.77067 15.567 5.9637C14.6906 5.43273 13.7153 5.10105 12.7096 4.98387L13.5803 6.56935C13.8462 7.05344 13.6693 7.66139 13.1852 7.92724C12.7011 8.1931 12.0931 8.01618 11.8273 7.53209L10.1533 4.48389C10.1457 4.47078 10.1384 4.45747 10.1313 4.44397L9.90181 4.02602C9.63596 3.54194 9.81287 2.93399 10.297 2.66814L13.803 0.742657C14.2871 0.476804 14.8951 0.653718 15.1609 1.1378C15.4268 1.62189 15.2499 2.22984 14.7658 2.49569L13.6501 3.10841C14.687 3.31308 15.6873 3.69818 16.6033 4.25314ZM11.2899 19.0179C10.2842 18.9008 9.30892 18.5691 8.43251 18.0381C7.10055 17.2311 6.07085 16.0086 5.50204 14.5589C4.93323 13.1091 4.85686 11.5125 5.2847 10.0151C5.43642 9.48409 5.12893 8.9306 4.59789 8.77888C4.06686 8.62715 3.51337 8.93465 3.36165 9.46568C2.81329 11.3849 2.91118 13.4312 3.64022 15.2894C4.36925 17.1475 5.68901 18.7144 7.39617 19.7487C8.31217 20.3036 9.31244 20.6887 10.3494 20.8934L9.2337 21.5061C8.74962 21.772 8.5727 22.3799 8.83856 22.864C9.10441 23.3481 9.71236 23.525 10.1964 23.2592L13.7025 21.3337C14.1866 21.0678 14.3635 20.4599 14.0977 19.9758L13.8681 19.5578C13.8611 19.5443 13.8538 19.531 13.8462 19.5179L12.1722 16.4697C11.9063 15.9856 11.2984 15.8087 10.8143 16.0746C10.3302 16.3404 10.1533 16.9484 10.4191 17.4325L11.2899 19.0179Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          d="M4.29289 4.29304C4.68342 3.90252 5.31658 3.90252 5.70711 4.29304L19.8492 18.4352C20.2398 18.8257 20.2398 19.4589 19.8492 19.8494C19.4587 20.2399 18.8256 20.2399 18.435 19.8494L4.29289 5.70726C3.90237 5.31673 3.90237 4.68357 4.29289 4.29304Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_8298_657576">
          <rect height="24" width="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
