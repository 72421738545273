import generateHook from './swr.helper';

export const useMyRelationship = () =>
  generateHook<GetRelationshipRes>('relation/v1/relationship/me?type=friend', {
    auth: true,
    immutable: true,
    list: true,
  });

export const useFriendRelationship = (id: NullableString) =>
  generateHook<GetRelationshipRes>(
    id ? `/relation/v1/relationship/detail/${id}` : undefined,
    {
      immutable: true,
    }
  );
