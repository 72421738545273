import React from 'react';

export default function TestStarFilled({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.0937 3.67809L13.8613 8.4053C14.0159 8.81873 14.4026 9.09972 14.8436 9.11899L19.8857 9.33931C19.9169 9.34067 19.9332 9.34986 19.9434 9.35811C19.9562 9.36837 19.969 9.38537 19.9764 9.40831C19.9839 9.43124 19.9835 9.4525 19.9792 9.46831C19.9758 9.48101 19.968 9.498 19.9436 9.51747L15.9939 12.6594C15.6485 12.9341 15.5008 13.3888 15.6187 13.8141L16.9673 18.6775C16.9756 18.7077 16.9719 18.7259 16.9672 18.7383C16.9614 18.7536 16.9492 18.771 16.9297 18.7851C16.9102 18.7993 16.8899 18.8056 16.8735 18.8064C16.8603 18.807 16.8418 18.8049 16.8157 18.7876L12.6071 16.0022C12.239 15.7586 11.761 15.7586 11.3929 16.0022L7.18428 18.7876C7.15819 18.8049 7.13965 18.807 7.1265 18.8064C7.11014 18.8056 7.08982 18.7993 7.07031 18.7851C7.0508 18.771 7.03857 18.7536 7.03276 18.7383C7.02808 18.7259 7.02437 18.7077 7.03272 18.6775L8.38128 13.8141C8.49922 13.3888 8.35149 12.9341 8.00607 12.6594L4.05645 9.51747C4.03197 9.498 4.02422 9.48101 4.02077 9.46831C4.01648 9.4525 4.01614 9.43124 4.02359 9.40831C4.03105 9.38537 4.04381 9.36837 4.05658 9.35811C4.06684 9.34986 4.08308 9.34067 4.11433 9.33931L9.15641 9.11899C9.59737 9.09972 9.98412 8.81873 10.1387 8.4053L11.9063 3.67809C11.9173 3.64879 11.931 3.63617 11.9421 3.62897C11.9558 3.62 11.9759 3.61311 12 3.61311C12.0241 3.61311 12.0442 3.62 12.0579 3.62896C12.069 3.63617 12.0827 3.64879 12.0937 3.67809Z"
        fill="currentColor"
      />
    </svg>
  );
}
