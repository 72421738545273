import React from 'react';

export default function EmojiFire({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.644 3.65605L10.5341 3L10.3227 4.25909C9.98616 6.24721 8.60934 8.09457 7.09071 9.39276C4.11774 11.9353 3.46677 14.6985 4.38729 16.9794C5.26817 19.1622 7.50384 20.6568 9.77299 20.9062L10.298 20.9635C8.99957 20.1806 8.16626 18.3515 8.45695 16.9847C8.74412 15.6413 9.72366 14.3831 11.6052 13.2222L12.5539 12.6382L12.908 13.6853C13.1168 14.304 13.478 14.801 13.8453 15.3059C14.0215 15.5492 14.2003 15.7951 14.3641 16.0566C14.9305 16.9647 15.0803 17.9744 14.7147 18.9762C14.3817 19.8869 13.8321 20.6029 13.0763 21L13.9307 20.9062C16.0607 20.6724 17.6243 19.9538 18.6399 18.7521C19.6468 17.5608 20 16.0271 20 14.3944C20 12.8737 19.3666 11.3062 18.6197 10.0019C17.7441 8.47429 16.6016 7.20565 15.3542 5.9761C15.1384 6.40188 15.1552 6.57393 14.7112 7.25778C14.1349 5.7474 13.0527 4.47661 11.644 3.65605Z"
        fill="currentColor"
      />
    </svg>
  );
}
