import React from 'react';

export default function OtherTurnOn({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.9375 17H8.0625C5.27109 17 3 14.7569 3 12C3 9.24306 5.27109 7 8.0625 7H15.9375C18.7289 7 21 9.24306 21 12C21 14.7569 18.7289 17 15.9375 17ZM15.9375 8.11111C15.1587 8.11111 14.3975 8.33919 13.7499 8.76651C13.1024 9.19382 12.5977 9.80119 12.2997 10.5118C12.0017 11.2224 11.9237 12.0043 12.0757 12.7587C12.2276 13.5131 12.6026 14.206 13.1533 14.7499C13.7039 15.2937 14.4055 15.6641 15.1693 15.8142C15.9331 15.9642 16.7248 15.8872 17.4443 15.5929C18.1638 15.2985 18.7788 14.8001 19.2114 14.1606C19.6441 13.521 19.875 12.7691 19.875 12C19.8739 10.9689 19.4587 9.98043 18.7205 9.25136C17.9823 8.52229 16.9814 8.11221 15.9375 8.11111Z"
        fill="currentColor"
      />
    </svg>
  );
}
