import React, { useState } from 'react';
import { alpha, ButtonBase, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useDateFormat } from '@front/helper';
import { ActionChevronRightSmall as ActionChevronRightSmallIcon } from '@front/icon';
import { SquareAvatarGroup } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { MessageItemAction } from '@lib/ia/src/layouts/ChannelLayout/types';

const styles = {
  replyButton: {
    mt: '2px',
    ml: '-3px',
    pl: '1px',
    pr: '4px',
    py: '2px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderRadius: 1,
    border: '1px solid transparent',
    maxWidth: '500px',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.05),
        borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  repliesWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  replyMembers: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  replies: {
    whiteSpace: 'nowrap',
  },
  avatarActive: {
    '& .avatar-wrapper': {
      backgroundColor: '#383838',
    },
  },
  lastActivity: {
    pt: '2px',
    opacity: 0.64,
    whiteSpace: 'nowrap',
  },
  viewThread: {
    pt: '2px',
    opacity: 0.64,
  },
};

export type MessageItemRepliesProps = {
  id?: string;
  replies?: number;
  viewThreadAction?: MessageItemAction;
  lastActivityTime?: string;
  members?: {
    name: string;
    avatarUrl: string;
    indicator?: string;
  }[];
};

export default function MessageItemReplies({
  id,
  replies = 0,
  viewThreadAction,
  lastActivityTime,
  members,
}: MessageItemRepliesProps) {
  const { getIaAction, iaRoutingAction } = useIaAction();
  const { displayMessageTimeFormat } = useDateFormat();

  const [replyButtonHovered, setReplyButtonHovered] = useState(false);

  const handleViewThreadClick = () => {
    if (!viewThreadAction) return;

    if (viewThreadAction.type === 'link') {
      void iaRoutingAction(viewThreadAction.value);
    } else {
      const onClickAction = getIaAction<string>(viewThreadAction.value);
      onClickAction?.action?.(id, viewThreadAction.metadata);
    }
  };

  if (replies <= 0) return null;

  return (
    <ButtonBase
      sx={styles.replyButton}
      disableRipple
      onMouseEnter={() => setReplyButtonHovered(true)}
      onMouseLeave={() => setReplyButtonHovered(false)}
      onClick={handleViewThreadClick}
    >
      <Box sx={styles.repliesWrapper}>
        <Box sx={styles.replyMembers}>
          <SquareAvatarGroup
            items={members || []}
            max={2}
            size={16}
            spacing="-4px"
            hideMore
            sx={[replyButtonHovered && styles.avatarActive]}
          />
          <Typography variant="body2" color="primary.main" sx={styles.replies}>
            {replies} replies
          </Typography>
        </Box>
        {!replyButtonHovered && lastActivityTime && (
          <Typography variant="caption" sx={styles.lastActivity}>
            Last activity on {displayMessageTimeFormat(lastActivityTime)}
          </Typography>
        )}
        {replyButtonHovered && (
          <Typography variant="caption" sx={styles.viewThread}>
            View thread
          </Typography>
        )}
      </Box>
      {replyButtonHovered && (
        <ActionChevronRightSmallIcon width={16} height={16} />
      )}
    </ButtonBase>
  );
}
