import { IaSearchReq } from '../types/query';

export function getIaQueryString<V>(ev: IaSearchReq<V>) {
  const params = new URLSearchParams();
  if (ev.filter) params.set('filter', ev.filter);

  if (ev.search) params.set('search', ev.search);
  if (ev.searchFields) params.set('searchFields', ev.searchFields);
  if (ev.expandSearch) params.set('expandSearch', ev.expandSearch);
  if (ev.expandSearchFields)
    params.set('expandSearchFields', ev.expandSearchFields);

  if (ev.searchJoin) params.set('searchJoin', ev.searchJoin);

  if (ev.expandOrderBy) params.set('expandOrderBy', ev.expandOrderBy);
  if (ev.expandSortBy) params.set('expandSortBy', ev.expandSortBy);

  if (ev.orderBy) params.set('orderBy', ev.orderBy);
  if (ev.sortBy) params.set('sortBy', ev.sortBy);
  if (ev.expandGlobalOrderBy)
    params.set('expandGlobalOrderBy', ev.expandGlobalOrderBy);
  if (ev.expandGlobalSortBy)
    params.set('expandGlobalSortBy', ev.expandGlobalSortBy);

  if (ev.userId) params.set('userId', ev.userId);
  if (ev.page !== undefined) params.set('page', ev.page.toString());
  if (ev.limit !== undefined) params.set('limit', ev.limit.toString());
  if (ev.viewSlug !== undefined) params.set('viewSlug', `${ev.viewSlug}`);
  if (ev.expand !== undefined) params.set('expand', ev.expand);
  if (ev.display !== undefined) params.set('display', ev.display);
  if (ev.keyword) params.set('keyword', ev.keyword);
  if (ev.keywordFuzzy) params.set('keywordFuzzy', ev.keywordFuzzy);

  if (ev.rawQueryParams) {
    for (const key of Object.keys(ev.rawQueryParams)) {
      const value = ev.rawQueryParams[key];
      if (params.has(key)) {
        params.set(key, params.get(key) + ';' + value);
      } else {
        params.set(key, value);
      }
    }
  }

  return params.toString();
}
