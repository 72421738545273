import React, { useCallback } from 'react';

export const useToggleMenuDropdown = <
  T extends { onClick: (option: T) => void }
>() => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const toggleMenu = useCallback((event: React.MouseEvent<Element>) => {
    setAnchorEl((el) => (el ? null : event.currentTarget));
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuOptionClick = useCallback((option: T) => {
    option.onClick(option);
    setAnchorEl(null);
  }, []);

  return {
    menuAnchorEl: anchorEl,
    toggleMenu,
    handleMenuOptionClick,
    handleMenuClose,
  };
};
