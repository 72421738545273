import React from 'react';

export default function TestRanking({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.05 5C7.64674 5 8.21903 5.24693 8.64099 5.68647C9.06295 6.12601 9.3 6.72215 9.3 7.34375C9.3 7.96535 9.06295 8.56149 8.64099 9.00103C8.21903 9.44057 7.64674 9.6875 7.05 9.6875C6.45326 9.6875 5.88097 9.44057 5.45901 9.00103C5.03705 8.56149 4.8 7.96535 4.8 7.34375C4.8 6.72215 5.03705 6.12601 5.45901 5.68647C5.88097 5.24693 6.45326 5 7.05 5ZM17.4 5C17.9967 5 18.569 5.24693 18.991 5.68647C19.4129 6.12601 19.65 6.72215 19.65 7.34375C19.65 7.96535 19.4129 8.56149 18.991 9.00103C18.569 9.44057 17.9967 9.6875 17.4 9.6875C16.8033 9.6875 16.231 9.44057 15.809 9.00103C15.3871 8.56149 15.15 7.96535 15.15 7.34375C15.15 6.72215 15.3871 6.12601 15.809 5.68647C16.231 5.24693 16.8033 5 17.4 5ZM3 13.751C3 12.0254 4.34437 10.625 6.00094 10.625H7.20187C7.64906 10.625 8.07375 10.7275 8.45625 10.9092C8.41969 11.1201 8.40281 11.3398 8.40281 11.5625C8.40281 12.6816 8.87531 13.6865 9.62062 14.375H3.59906C3.27 14.375 3 14.0938 3 13.751ZM14.3991 14.375H14.3794C15.1275 13.6865 15.5972 12.6816 15.5972 11.5625C15.5972 11.3398 15.5775 11.123 15.5437 10.9092C15.9262 10.7246 16.3509 10.625 16.7981 10.625H17.9991C19.6556 10.625 21 12.0254 21 13.751C21 14.0967 20.73 14.375 20.4009 14.375H14.3991ZM9.3 11.5625C9.3 10.8166 9.58446 10.1012 10.0908 9.57376C10.5972 9.04632 11.2839 8.75 12 8.75C12.7161 8.75 13.4028 9.04632 13.9092 9.57376C14.4155 10.1012 14.7 10.8166 14.7 11.5625C14.7 12.3084 14.4155 13.0238 13.9092 13.5512C13.4028 14.0787 12.7161 14.375 12 14.375C11.2839 14.375 10.5972 14.0787 10.0908 13.5512C9.58446 13.0238 9.3 12.3084 9.3 11.5625ZM6.6 19.2178C6.6 17.0615 8.27906 15.3125 10.3491 15.3125H13.6509C15.7209 15.3125 17.4 17.0615 17.4 19.2178C17.4 19.6484 17.0653 20 16.6491 20H7.35094C6.9375 20 6.6 19.6514 6.6 19.2178Z"
        fill="currentColor"
      />
    </svg>
  );
}
