import { useCallback, useEffect, useRef } from 'react';
import Router from 'next/router';
import { Theme, useMediaQuery } from '@mui/material';
import { useBaseLayout } from '@front/ui';

/**
 * The rule of thumb:
 * Desktop will always have both left and right panel (if any).
 *
 * iPad or smaller desktop size will always prioritize right panel first.
 * - If the page changes, and there is a right panel, right panel will be opened by default (collapsing left nav)
 * - If the page changes, and there is no right panel, open left panel.
 *
 * Mobile is similar to our current one, only difference is on left nav there will be the >> icon
 *
 * If the user has closed the panel, the panel should remain closed when the route changes
 */

export default function PanelControl() {
  const prevUrlRef = useRef<string | undefined>(undefined);

  const isPad = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('md', 'lg')
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const isDesktop = !isPad && !isMobile;

  const { closeLeftPanel, openLeftPanel, closeRightPanel, rightPanelOpened } =
    useBaseLayout();

  // close panels before history change
  // ps: why we don't use 'routerChangeStart'?
  // 1. better ux, after next url is loaded, then we can close the panel
  // 2. in the case of quizInterrupt, we might cancel the route change, in that case, we don't need to close panel
  const beforeHistoryChange = useCallback(
    (ev: string) => {
      const routeUrl = ev.split('?')[0];
      if (routeUrl === prevUrlRef.current) return;

      if (isMobile) closeLeftPanel();

      closeRightPanel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMobile, isDesktop]
  );

  // open panels after route change complete
  const routeChangeComplete = useCallback(
    (ev: string) => {
      const routeUrl = ev.split('?')[0];
      if (routeUrl === prevUrlRef.current) return;

      prevUrlRef.current = routeUrl;

      if (isPad && !rightPanelOpened) {
        openLeftPanel();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPad, rightPanelOpened]
  );

  useEffect(() => {
    Router.events.on('beforeHistoryChange', beforeHistoryChange);
    Router.events.on('routeChangeComplete', routeChangeComplete);
    return () => {
      Router.events.off('beforeHistoryChange', beforeHistoryChange);
      Router.events.off('routeChangeComplete', routeChangeComplete);
    };
  }, [beforeHistoryChange, routeChangeComplete]);

  useEffect(() => {
    // only apply for first time
    routeChangeComplete(Router.asPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPad && rightPanelOpened) {
      closeLeftPanel();
    }
  }, [closeLeftPanel, isPad, rightPanelOpened]);

  return null;
}
