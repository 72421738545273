/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Icons from '@front/icon';

type IconProps = {
  name: keyof typeof Icons | string;
  [x: string]: any;
};

export default function Icon({ name, size, ...rest }: IconProps) {
  const CustomIcon =
    (name ? (Icons as any)[name] : null) || Icons.ActionCloseSmall;
  const sizeProps = size ? { width: size, height: size } : {};

  return <CustomIcon {...sizeProps} {...rest} />;
}
