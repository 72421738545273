import { useMemo } from 'react';
import { buildHookMutate, useCreatorQuestionRubric } from '@lib/web/apis';

import { useEditorAreaSetting } from '../..';

export const useEditorRubricData = (questionId: string) => {
  const { rubricEnable } = useEditorAreaSetting(questionId);
  const { data: rubricData, mutate } = useCreatorQuestionRubric(questionId, {
    enable: rubricEnable,
  });
  const mutateRubric = useMemo(() => buildHookMutate(mutate), [mutate]);

  return {
    rubric: rubricData?.data,
    mutateRubric,
  };
};
