import { TableLayoutFooterCell } from '../../types';

import IaFooterButtonCell from './IaFooterButtonCell';
import IaFooterEmptyCell from './IaFooterEmptyCell';
import IaFooterIconCell from './IaFooterIconCell';
import IaFooterLoadingCell from './IaFooterLoadingCell';
import IaFooterNumberCell from './IaFooterNumberCell';
import IaFooterTextCell from './IaFooterTextCell';

export type IaTableFooterCellProps = {
  cell: TableLayoutFooterCell;
};

export default function IaTableFooterCell({ cell }: IaTableFooterCellProps) {
  switch (cell.type) {
    case 'text':
      return <IaFooterTextCell cell={cell} />;
    case 'number':
      return <IaFooterNumberCell cell={cell} />;
    case 'loading':
      return <IaFooterLoadingCell cell={cell} />;
    case 'button':
      return <IaFooterButtonCell cell={cell} />;
    case 'icon':
      return <IaFooterIconCell cell={cell} />;
    case 'empty':
      return <IaFooterEmptyCell />;
    default:
      console.warn('Unknown body cell type:', cell);
      return null;
  }
}
