import { alpha, Box, Theme, Typography } from '@mui/material';
import { Icon } from '@front/ui';
import {
  AnswerFormatType,
  useQuizPracticeQuestions,
  useQuizResultSummary,
} from '@lib/web/apis';
import { persistentStringToHtml } from '@lib/web/thread/ThreadTextComposer';
import ThreadComposerRenderer from '@lib/web/thread/ThreadTextComposer/ThreadComposerRenderer';
import { LocationDetail } from '@lib/web/thread/types';

const styles = {
  question: {
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    gap: 1,
    px: 1,
    pt: 1,
    pb: '12px',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    borderRadius: 2,
  },
  questionDetail: {
    display: 'grid',
    gap: 1,
  },
  content: {
    typography: 'chatBody',
    display: 'grid',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  questionTitle: {
    py: 0.5,
  },
};

const getFormatTypeIcon = (formatType: AnswerFormatType) => {
  switch (formatType) {
    case AnswerFormatType.Essay:
      return 'EditorEssay';
    case AnswerFormatType.FreeResponse:
      return 'EditorFreeResponse';
    case AnswerFormatType.GridIn:
      return 'EditorGridIn';
    case AnswerFormatType.MultipleChoice:
      return 'EditorMCQ';
    case AnswerFormatType.MultipleResponse:
      return 'EditorMRQ';
    case AnswerFormatType.TrueFalseNotGiven:
      return 'EditorTFNG';
    case AnswerFormatType.Unscramble:
      return 'EditorUnscramble';
    default:
      return 'EditorQuestionGroup';
  }
};

type ThreadEmbeddedQuestionDetailProps = {
  quizId?: string;
  questionNo?: number;
};

function ThreadEmbeddedQuestionDetail({
  quizId,
  questionNo,
}: ThreadEmbeddedQuestionDetailProps) {
  const { data: quizData } = useQuizResultSummary({ quizId });

  const questionIdxAry = questionNo ? [questionNo - 1] : undefined;
  const { data: questionData } = useQuizPracticeQuestions({
    quizId,
    questionIdxAry,
  });

  const questions = questionData?.data.quizQuestions || [];
  if (!quizData || questions.length === 0) return null;
  const question = questions[0];
  const answerFormatType = question.creatorQuestionInfo.answerFormatType;
  const quiz = quizData.data;

  return (
    <Box sx={styles.question}>
      <Box>
        <Icon
          name={getFormatTypeIcon(answerFormatType)}
          width={32}
          height={32}
        />
      </Box>
      <Box sx={styles.questionDetail}>
        <Typography variant="chatHeaderBold" sx={styles.questionTitle}>
          {quiz.quiz.quizCustomName} Q{questionNo}
        </Typography>
        <Box sx={styles.content}>
          <ThreadComposerRenderer
            html={persistentStringToHtml(question.question || '')}
            oneLine
          />
        </Box>
      </Box>
    </Box>
  );
}

type ThreadEmbeddedQuestionProps = {
  locationDetail?: LocationDetail;
};

export default function ThreadEmbeddedQuestion({
  locationDetail,
}: ThreadEmbeddedQuestionProps) {
  if (!locationDetail) return null;
  if (
    !locationDetail.quizId ||
    !locationDetail.questionNo ||
    !locationDetail.questionId
  ) {
    return null;
  }

  return (
    <ThreadEmbeddedQuestionDetail
      quizId={locationDetail?.quizId}
      questionNo={locationDetail?.questionNo}
    />
  );
}
