import { VictoryBar } from 'victory';

import ChartContainer from './ChartContainer';

// TODO: 等新版的 editor 在制定 chart 格式。
export default function AreaChart({
  data,
  ...rest
}: {
  data: any;
  width?: number;
  height?: number;
  mode?: string;
}) {
  return (
    <ChartContainer
      {...rest}
      data={data}
      ChartComp={VictoryBar}
      chartProps={{ barWidth: data?.barWidth || 10 }}
    />
  );
}
