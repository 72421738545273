import { MouseEvent } from 'react';
import { alpha, Box, Theme } from '@mui/material';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { TableLayoutFileCell, TableLayoutRow } from '../../types';

export type IaFileCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutFileCell;
  columnKey: string;
};

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    px: '8px',
    width: '100%',
    height: '100%',
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.primary.main, 0.1),
    },
  },
  fileName: {
    px: '2px',
    color: 'primary.main',
    typography: 'body2',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default function IaFileCell({ row, cell }: IaFileCellProps) {
  const { fileName, actionMap } = cell;

  const { getIaAction } = useIaAction();

  const handleClick = (ev: MouseEvent): void => {
    if (!actionMap?.click) {
      return;
    }

    ev.preventDefault();
    ev.stopPropagation();

    getIaAction<{ cell: TableLayoutFileCell; row: TableLayoutRow }>(
      actionMap.click
    )?.action({
      cell,
      row,
    });
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.fileContainer} onClick={handleClick}>
        <Box component="span" sx={styles.fileName}>
          {fileName}
        </Box>
      </Box>
    </Box>
  );
}
