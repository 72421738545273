import {
  composerHeadingBlockStyle,
  generatedHtmlHeadingBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/HeadingBlock';
import {
  composerParagraphBlockStyle,
  generatedHtmlParagraphBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/ParagraphBlock';
import {
  composerStepBlockStyle,
  generatedHtmlStepBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/StepBlock';
import {
  composerSubtitleBlockStyle,
  generatedHtmlSubtitleBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/SubtitleBlock';
import { textColorStyles } from '@lib/web/composer/TextComposer/extensions/TextColor';
import { generatedHtmlFileBlockStyle } from '@lib/web/thread/ThreadTextComposer/components/blocks/FileBlock';
import { generatedHtmlImageBlockStyle } from '@lib/web/thread/ThreadTextComposer/components/blocks/ImageBlock';

export const threadBlockEditingStyles = [
  composerHeadingBlockStyle,
  composerSubtitleBlockStyle('chatBody'),
  composerParagraphBlockStyle('chatBody'),
  composerStepBlockStyle('chatBody'),
  textColorStyles(),
];

export const threadBlockHtmlStyles = [
  generatedHtmlHeadingBlockStyle,
  generatedHtmlSubtitleBlockStyle({
    typography: 'chatBody',
    oneLineTypography: 'chatBody',
  }),
  generatedHtmlParagraphBlockStyle({
    typography: 'chatBody',
    oneLineTypography: 'chatBody',
  }),
  generatedHtmlStepBlockStyle({
    typography: 'chatBody',
    oneLineTypography: 'chatBody',
  }),
  generatedHtmlImageBlockStyle(),
  generatedHtmlFileBlockStyle(),
  textColorStyles(),
];
