export default function EditorDifficultyEasy({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        opacity="0.3"
        d="M16.4999 16.4923C16.4999 15.4073 16.1159 14.4119 15.4763 13.6348L18.6658 10.4453C20.1161 12.0431 20.9999 14.1645 20.9999 16.4923H16.4999Z"
        fill="currentColor"
      />
      <path
        className="main-color"
        d="M5.33663 10.4425C3.8848 12.0406 3 14.1631 3 16.4923H7.5C7.5 15.4059 7.88499 14.4094 8.52599 13.6318L5.33663 10.4425Z"
        fill="currentColor"
      />
      <path
        opacity="0.3"
        d="M9.04942 13.0946L5.86353 9.9087C7.38601 8.489 9.40147 7.59113 11.625 7.5V12.0077C10.6433 12.0887 9.75103 12.4848 9.04942 13.0946Z"
        fill="currentColor"
      />
      <path
        opacity="0.3"
        d="M12.375 12.0077C13.358 12.0888 14.2514 12.4859 14.9535 13.0971L18.1393 9.91129C16.6164 8.49009 14.5999 7.59119 12.375 7.5V12.0077Z"
        fill="currentColor"
      />
    </svg>
  );
}
