import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

export default function useOnScreen(ref: RefObject<Element> | null) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observerRef = useRef(
    new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))
  );

  useEffect(() => {
    const self = observerRef.current;

    if (ref?.current) {
      self.observe(ref.current);
    }

    return () => {
      self.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}
