import React from 'react';

export default function EditorCut({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.5369 3.00006C15.7012 3.00567 15.8451 3.09811 15.9126 3.24098C17.3629 6.4735 15.1434 11.017 14.3566 12.4568L12.4601 8.64159L15.1523 3.22696C15.2227 3.0869 15.3754 2.99726 15.5369 3.00006Z"
        fill="currentColor"
      />
      <path
        d="M9.89997 12.905C9.9264 12.947 9.95575 12.9806 9.99391 13.0114L11.4559 14.1711L13.8281 18.9442C14.0189 19.3224 14.2685 19.6613 14.5679 19.947C14.9584 20.3196 15.434 20.6053 15.9683 20.7902C16.9166 21.1123 17.9382 21.0619 18.8454 20.6501C19.7555 20.2411 20.4425 19.5129 20.7801 18.6081C21.1177 17.7033 21.0649 16.7285 20.6333 15.863C20.2047 14.9946 19.4443 14.3419 18.496 14.0198C17.5477 13.6977 16.5261 13.7425 15.616 14.1571C15.2461 14.3251 14.9055 14.5492 14.6119 14.8237L8.84602 3.22696C8.77261 3.08412 8.62288 2.99727 8.46143 3.00007C8.297 3.00568 8.15315 3.0981 8.08563 3.24096C6.37699 7.04493 9.7561 12.6668 9.89997 12.905ZM16.3353 15.5996C16.8373 15.3727 17.4068 15.3447 17.9353 15.524C18.4579 15.7033 18.8806 16.0674 19.1214 16.5492C19.3592 17.0282 19.3885 17.5717 19.2006 18.0703C19.0127 18.5745 18.6311 18.9778 18.129 19.2047C17.6241 19.4344 17.0575 19.4596 16.529 19.2804C16.2325 19.1823 15.9683 19.0199 15.754 18.8154C15.5866 18.6557 15.4486 18.468 15.343 18.2607C15.1022 17.7789 15.0758 17.2383 15.2637 16.7341C15.4516 16.2299 15.8303 15.8293 16.3353 15.5996Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M3.36216 15.8597C2.66344 17.2715 3.00986 18.9018 4.10785 19.9494C4.40144 20.2295 4.75376 20.4704 5.15302 20.6497C7.029 21.4984 9.27784 20.7309 10.1644 18.9438L11.5355 16.1846L10.7839 14.6664L9.83857 13.9157L9.38646 14.8233C9.0958 14.5516 8.75819 14.3247 8.37946 14.1538C6.50349 13.3051 4.25171 14.0698 3.36216 15.8597ZM8.65251 18.2575C8.41469 18.7365 7.99195 19.1007 7.46645 19.2827C6.94092 19.4592 6.37431 19.434 5.86934 19.2043C5.64622 19.1034 5.44954 18.9718 5.28512 18.8149C4.67742 18.2351 4.48659 17.3303 4.87704 16.5488C5.11779 16.067 5.53467 15.7029 6.06312 15.5236C6.59156 15.3443 7.15523 15.3723 7.6602 15.602C8.70535 16.0726 9.14866 17.2631 8.65251 18.2575Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
