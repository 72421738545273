import { useMemo } from 'react';
import { ClubViewSlug, useIaClubs } from '@lib/web/apis';

export default function useIaClub(clubSlug?: string) {
  const shouldFetchClub = !!clubSlug;
  const { data, error, mutate } = useIaClubs(
    {
      viewSlug: ClubViewSlug.ProfileCenterDefault,
      search: `clubSlug:${clubSlug}`,
      searchFields: 'clubSlug:eq',
      limit: 1,
    },
    {
      enable: shouldFetchClub,
    }
  );

  const isLoading = !data && !error;

  return useMemo(
    () => ({
      club: shouldFetchClub ? data?.data.items[0] : null,
      sectionId: data?.data.items[0]?.sectionId || null,
      isLoading,
      isEmpty: data?.data.items.length === 0,
      mutate,
    }),
    [data, isLoading, mutate, shouldFetchClub]
  );
}
