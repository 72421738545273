export default function ProfilePaidUser({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.5471 2.52128C11.7863 2.24598 12.2137 2.24598 12.4529 2.52128L14.3469 4.7011C14.4827 4.85736 14.6894 4.9326 14.8938 4.90017L17.7458 4.44775C18.106 4.39061 18.4335 4.66537 18.4398 5.03001L18.4895 7.91727C18.493 8.12424 18.603 8.31475 18.7805 8.42132L21.2561 9.908C21.5687 10.0958 21.6429 10.5167 21.4134 10.8001L19.5955 13.0438C19.4652 13.2046 19.427 13.4213 19.4945 13.617L20.4353 16.3471C20.5541 16.6919 20.3404 17.0621 19.9824 17.1316L17.1476 17.6819C16.9444 17.7214 16.7759 17.8628 16.7017 18.056L15.6675 20.7522C15.5369 21.0927 15.1352 21.2389 14.8163 21.062L12.291 19.6614C12.11 19.561 11.89 19.561 11.709 19.6614L9.18369 21.062C8.86476 21.2389 8.46309 21.0927 8.33248 20.7522L7.29827 18.056C7.22414 17.8628 7.05562 17.7214 6.85242 17.6819L4.01765 17.1316C3.65963 17.0621 3.44591 16.6919 3.56473 16.3471L4.50553 13.617C4.57296 13.4213 4.53476 13.2046 4.40446 13.0438L2.58663 10.8001C2.35705 10.5167 2.43128 10.0958 2.74393 9.908L5.21952 8.42132C5.39698 8.31475 5.50697 8.12424 5.51054 7.91727L5.56024 5.03001C5.56652 4.66537 5.89396 4.39061 6.25415 4.44775L9.10618 4.90017C9.31062 4.9326 9.51734 4.85736 9.65311 4.7011L11.5471 2.52128Z"
        fill="url(#paint0_linear_9346_41894)"
      />
      <rect
        x="13.2607"
        y="5.90674"
        width="3.8527"
        height="11.5581"
        rx="1.92635"
        transform="rotate(30 13.2607 5.90674)"
        fill="white"
        fillOpacity="0.5"
      />
      <ellipse
        cx="10.1133"
        cy="15.211"
        rx="1.92635"
        ry="1.92635"
        transform="rotate(30 10.1133 15.211)"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9346_41894"
          x1="2"
          y1="2"
          x2="22.867"
          y2="2.94968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D1FF" />
          <stop offset="0.484375" stopColor="#0051F9" />
          <stop offset="1" stopColor="#7000FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
