// eslint-disable-next-line import/prefer-default-export
export const enum ReferRationStatus {
  // InvitedAccepted = 102,
  // InvitedPending = 101,
  // InvitedExpired = 109,
  NotInvited = -1,

  Pending = 1,
  Accepted = 2,
  PeriodAccepted = 3,
  ReflectAccepted = 4,
  MutualFollow = 5, // must having both-sides corresponding 300 follow records

  // for type='refer' ==> from shared public link (go to 102 directly)
  ReferPending = 101,
  ReferAccepted = 102,
  ReferExpired = 109,
  ReferReflectAccepted = 104,

  // for type='refer' ==> from email link (already has auth0 pre-created account)
  EmailPending = 401,
  EmailAccepted = 402,
  // EmailReferExpired = 409, (no api updates to this status)
  Skipped = 410,
  // EmailReferReflectAccepted = 404, (never happen)

  // for type='club'
  ClubPending = 201,
  ClubAccepted = 202,
  ClubReflectAccepted = 204,
  ClubPendingArchived = 219,

  // for type='follow'
  Follow = 300,
  UnFollow = 309,
}

export const enum ReferInvitationStatus {
  Accepted = 2,
  Invited = 1,
  Unknown = 0,
  Canceled = -1,
  Archived = -2,
}
