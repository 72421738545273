import React from 'react';

export default function TextEditorReference({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 13.3895C3 12.0543 3.47147 10.6371 4.41442 9.13808C5.37029 7.62645 7.08827 6.24709 9.56835 5L10.0334 5.85029C8.79333 6.70688 7.87621 7.4249 7.28202 8.00436C6.23574 9.0499 5.71259 10.0514 5.71259 11.0087C5.71259 11.3362 5.80947 11.5504 6.00323 11.6512C6.19699 11.7645 6.36491 11.8212 6.507 11.8212C6.99785 11.7582 7.32078 11.7267 7.47578 11.7267C8.30248 11.7267 8.98062 12.0228 9.51023 12.6148C10.0398 13.1943 10.3046 13.8997 10.3046 14.7311C10.3046 15.6633 10.014 16.4443 9.43272 17.0741C8.85145 17.6914 8.05059 18 7.03014 18C5.82885 18 4.85361 17.5843 4.10441 16.7529C3.36814 15.9089 3 14.7878 3 13.3895ZM13.6954 13.3895C13.6954 11.9787 14.1991 10.5237 15.2067 9.02471C16.2142 7.52568 17.8999 6.18411 20.2637 5L20.7287 5.85029C19.5662 6.6439 18.6749 7.34302 18.0549 7.94767C16.9569 9.01841 16.408 10.0136 16.408 10.9331C16.408 11.1977 16.479 11.4118 16.6211 11.5756C16.7632 11.7393 16.9569 11.8212 17.2024 11.8212C17.6932 11.7582 18.0161 11.7267 18.1712 11.7267C18.9849 11.7267 19.6566 12.0165 20.1862 12.5959C20.7287 13.1754 21 13.8871 21 14.7311C21 15.7137 20.7029 16.5073 20.1087 17.1119C19.5145 17.704 18.7072 18 17.6868 18C16.5242 18 15.5684 17.5843 14.8192 16.7529C14.07 15.9089 13.6954 14.7878 13.6954 13.3895Z"
        fill="currentColor"
      />
    </svg>
  );
}
