import React from 'react';

export default function OtherCountdownYouSure({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
        fill="#080808"
        opacity="0.1"
      />
      <path
        d="M12 2.75C12 2.33579 12.3363 1.99707 12.7493 2.02811C14.359 2.14906 15.9195 2.6583 17.2951 3.51696C18.8833 4.50833 20.1613 5.92565 20.9837 7.60762C21.8061 9.28958 22.1397 11.1686 21.9467 13.0309C21.7796 14.6439 21.2231 16.1881 20.33 17.5328C20.1008 17.8778 19.627 17.9352 19.3001 17.6808C18.9732 17.4264 18.9175 16.9568 19.1421 16.6087C19.8652 15.4881 20.3165 14.2098 20.4547 12.8763C20.6188 11.2933 20.3352 9.69614 19.6362 8.26648C18.9371 6.83681 17.8508 5.63208 16.5008 4.78942C15.3635 4.07951 14.0775 3.65059 12.749 3.53306C12.3364 3.49656 12 3.16421 12 2.75Z"
        fill="#080808"
      />
      <path
        d="M11.3254 17.2102C11.0734 17.2102 10.812 17.1916 10.5414 17.1542C10.28 17.1262 10.028 17.0842 9.78536 17.0282C9.55203 16.9816 9.33736 16.9302 9.14136 16.8742C8.94536 16.8089 8.79603 16.7482 8.69336 16.6922L9.02936 15.2502C9.22536 15.3436 9.51003 15.4509 9.88336 15.5722C10.2567 15.6842 10.7187 15.7402 11.2694 15.7402C12.0067 15.7402 12.5387 15.6002 12.8654 15.3202C13.192 15.0309 13.3554 14.6482 13.3554 14.1722C13.3554 13.8642 13.29 13.6076 13.1594 13.4022C13.038 13.1969 12.8654 13.0336 12.6414 12.9122C12.4267 12.7816 12.1747 12.6929 11.8854 12.6462C11.6054 12.5902 11.3067 12.5622 10.9894 12.5622H10.4154V11.1902H11.1154C11.33 11.1902 11.5447 11.1716 11.7594 11.1342C11.9834 11.0876 12.184 11.0129 12.3614 10.9102C12.5387 10.7982 12.6834 10.6536 12.7954 10.4762C12.9074 10.2989 12.9634 10.0702 12.9634 9.79022C12.9634 9.56622 12.9214 9.37489 12.8374 9.21622C12.7534 9.05755 12.6414 8.92689 12.5014 8.82422C12.3707 8.72155 12.2167 8.64689 12.0394 8.60022C11.862 8.55355 11.6754 8.53022 11.4794 8.53022C11.0594 8.53022 10.686 8.59555 10.3594 8.72622C10.0327 8.84755 9.74336 8.98755 9.49136 9.14622L8.87536 7.88622C9.00603 7.80222 9.16469 7.71355 9.35136 7.62022C9.53803 7.52689 9.74336 7.43822 9.96736 7.35422C10.2007 7.27022 10.448 7.20489 10.7094 7.15822C10.9707 7.10222 11.246 7.07422 11.5354 7.07422C12.0767 7.07422 12.5434 7.13955 12.9354 7.27022C13.3274 7.40089 13.6494 7.58755 13.9014 7.83022C14.1627 8.06355 14.354 8.34355 14.4754 8.67022C14.606 8.98755 14.6714 9.33755 14.6714 9.72022C14.6714 10.1682 14.5454 10.5696 14.2934 10.9242C14.0507 11.2789 13.7334 11.5496 13.3414 11.7362C13.8454 11.9136 14.2607 12.2076 14.5874 12.6182C14.9234 13.0289 15.0914 13.5562 15.0914 14.2002C15.0914 14.6296 15.0167 15.0309 14.8674 15.4042C14.718 15.7682 14.4894 16.0856 14.1814 16.3562C13.8734 16.6176 13.4814 16.8276 13.0054 16.9862C12.5387 17.1356 11.9787 17.2102 11.3254 17.2102Z"
        fill="#080808"
      />
    </svg>
  );
}
