import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

import TitleBar, { TitleBarProps } from '../../../molecules/TitleBar';
import { modalPaperStyle } from '../styles';

const styles = {
  root: {
    ...modalPaperStyle,
    maxWidth: { md: 600 },
    maxHeight: { md: 740 },
  },
  content: {
    flex: 1,
    position: 'relative',
  },
  action: {
    p: 2.5,
  },
};

export type ModalBaseContentProps = BoxProps & {
  onClose?: () => void;
  title?: string;
  titleBackIcon?: TitleBarProps['backIcon'];
  disableHeader?: boolean;
  actionComponent?: ReactNode;
};

const ModalBaseContent = React.forwardRef<
  HTMLDivElement,
  ModalBaseContentProps
>(
  (
    {
      sx,
      title,
      children,
      onClose,
      actionComponent,
      titleBackIcon = 'close',
      disableHeader,
      ...rest
    },
    ref
  ) => {
    const sxProps = Array.isArray(sx) ? sx : [sx];
    return (
      <Box ref={ref} sx={[styles.root, ...sxProps]} {...rest}>
        {!disableHeader && (
          <TitleBar
            backIcon={titleBackIcon}
            title={title}
            onBackClick={onClose}
          />
        )}
        <Box sx={styles.content}>{children}</Box>
        {!!actionComponent && <Box sx={styles.action}>{actionComponent}</Box>}
      </Box>
    );
  }
);

export default ModalBaseContent;
