import React from 'react';

export default function OtherTurnOff({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.0625 17H15.9375C18.7334 17 21 14.7614 21 12C21 9.23858 18.7334 7 15.9375 7H8.0625C5.26656 7 3 9.23858 3 12C3 14.7614 5.26656 17 8.0625 17ZM19.875 12C19.875 9.85223 18.1121 8.11111 15.9375 8.11111H11.2448C12.3917 9.02775 13.125 10.4293 13.125 12C13.125 13.5707 12.3917 14.9722 11.2448 15.8889H15.9375C18.1121 15.8889 19.875 14.1478 19.875 12ZM8.0625 15.8889C5.88788 15.8889 4.125 14.1478 4.125 12C4.125 9.85223 5.88788 8.11111 8.0625 8.11111C10.2371 8.11111 12 9.85223 12 12C12 14.1478 10.2371 15.8889 8.0625 15.8889Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
