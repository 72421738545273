import React from 'react';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';

const styles = {
  buttonTabs: {
    minHeight: 34,
  },
};

export type TabsProps = MuiTabsProps & {
  tabVariant?: 'default' | 'button';
};

export default function Tabs({
  children,
  sx,
  tabVariant = 'default',
  value,
  TabIndicatorProps = {},
  ...rest
}: TabsProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <MuiTabs
      sx={[tabVariant === 'button' && styles.buttonTabs, ...sxProps]}
      TabIndicatorProps={{
        ...(tabVariant === 'button'
          ? {
              style: {
                display: 'none',
              },
            }
          : {}),
        ...TabIndicatorProps,
      }}
      value={value}
      {...rest}
    >
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          variant: tabVariant,
        });
      })}
    </MuiTabs>
  );
}
