import React from 'react';

export default function TextEditorBullet({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 12.5C3 11.67 3.67 11 4.5 11C5.33 11 6 11.67 6 12.5C6 13.33 5.33 14 4.5 14C3.67 14 3 13.33 3 12.5Z"
        fill="currentColor"
      />
      <path
        d="M3 6.5C3 5.67 3.67 5 4.5 5C5.33 5 6 5.67 6 6.5C6 7.33 5.33 8 4.5 8C3.67 8 3 7.33 3 6.5Z"
        fill="currentColor"
      />
      <path
        d="M3 18.5C3 17.68 3.67 17 4.5 17C5.33 17 6 17.68 6 18.5C6 19.32 5.32 20 4.5 20C3.68 20 3 19.32 3 18.5Z"
        fill="currentColor"
      />
      <path d="M7.5 17.5H21V19.5H7.5V17.5Z" fill="currentColor" />
      <path d="M7.5 11.5H21V13.5H7.5V11.5Z" fill="currentColor" />
      <path d="M21 7.5H7.5V5.5H21V7.5Z" fill="currentColor" />
    </svg>
  );
}
