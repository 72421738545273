export default function MathCalculus({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.4999 18.3896C11.2999 19.3809 10.8999 20.1019 10.1999 20.5525C9.49988 21.0031 8.59989 21.0932 7.49989 20.913C7.28358 20.8642 6.9503 20.8155 6.64237 20.7525C6.25849 20.674 6.09831 20.2864 6.23424 19.9189C6.38356 19.5152 6.81859 19.3077 7.2384 19.4028C7.37027 19.4326 7.49667 19.4561 7.60185 19.4659C7.667 19.4721 7.73234 19.4769 7.7972 19.4855C8.8354 19.6238 9.40586 19.1683 9.59989 18.1192L11.9999 5.86281C12.1999 4.78136 12.6999 4.0604 13.3999 3.51967C14.0999 3.06907 15.0999 2.88883 16.1999 3.06907C16.473 3.13061 16.9327 3.19215 17.3878 3.31109C17.7383 3.40267 17.8948 3.78472 17.7614 4.12148C17.619 4.4809 17.1891 4.69378 16.8092 4.62197C16.6526 4.59236 16.4993 4.56276 16.3812 4.53316C16.3266 4.51948 16.2715 4.50676 16.2156 4.50023C14.9695 4.35475 14.2933 4.90098 13.9999 6.22329L11.4999 18.3896Z"
        fill="currentColor"
      />
    </svg>
  );
}
