import React, { ReactElement } from 'react';
import { Box } from '@mui/material';

import {
  DisplayTableLayoutHeaderCell,
  DisplayTableLayoutHeaderRow,
} from '../types';

import IaDisplayTableHeaderCell from './IaDisplayTableHeaderCell';

const styles = {
  root: {
    display: 'grid',
    width: '100%',
    textAlign: 'left',
    opacity: 0.64,
  },
};

const ROW_HEIGH_DEFAULT = '32px';

type IaDisplayTableHeaderRowProps = {
  row?: DisplayTableLayoutHeaderRow;
  children: (
    cell: DisplayTableLayoutHeaderCell
  ) => ReactElement<typeof IaDisplayTableHeaderCell>;
};

export default function IaDisplayTableHeaderRow({
  row,
  children,
}: IaDisplayTableHeaderRowProps) {
  if (!row || row.cells.length === 0) return null;
  const sxProps = Array.isArray(row.sx) ? row.sx : [row.sx];

  return (
    <Box
      sx={[
        styles.root,
        {
          gridTemplateColumns: row.gridTemplateColumns,
          gridAutoRows: row.rowHeight || ROW_HEIGH_DEFAULT,
        },
        ...sxProps,
      ]}
    >
      {row.cells.map((cell, index) => (
        <React.Fragment key={index}>{children(cell)}</React.Fragment>
      ))}
    </Box>
  );
}
