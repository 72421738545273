import { useCallback, useContext } from 'react';
import { ThreadComposerCustomContext } from '@lib/web/thread/contexts/threadComposerCustomContext';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { extractTextFromHtml } from '@lib/web/utils';
import { StreamMessage } from 'stream-chat-react';

export const useGetMessagePlainText = () => {
  const { composerBlocksToHtml } = useContext(ThreadComposerCustomContext);

  const getMessagePlainText = useCallback(
    (
      message: Pick<StreamMessage<StreamChatGenerics>, 'text' | 'customBlocks'>
    ) => {
      if (message.text) {
        return extractTextFromHtml(message.text);
      }
      /**
       * If the message content is too large, there will be no message.text.
       * In this case, we need to use message.customBlocks to render the content.
       * For more details, see useBuildMessageData.
       */
      return message.customBlocks
        ? extractTextFromHtml(composerBlocksToHtml(message.customBlocks))
        : '';
    },
    [composerBlocksToHtml]
  );

  return {
    getMessagePlainText,
  };
};
