import React, { ReactNode } from 'react';

export type BreadcrumbsValue = {
  text?: string;
  icon?: React.ReactNode | string;
  customizeHeader?: React.ReactNode;
  customizeComponent?: React.ReactNode;
  href?: string;
  tooltip?: {
    icon: string;
    title: string;
    content: ReactNode;
  };
};
