import { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ActionArrowLeft,
  ActionChevronLeft,
  ActionClose,
  ActionDoubleRightChevron,
  OtherMenu,
} from '@front/icon';

import { SimpleTooltip, TipButton } from '../../atoms';
import { IconButtonProps } from '../../atoms/Button/IconButton';
import { useTruncated } from '../../hooks';

const ICON_MAP = {
  back: <ActionChevronLeft />,
  arrow: <ActionArrowLeft />,
  close: <ActionClose />,
  collapse: <ActionDoubleRightChevron />,
  menu: <OtherMenu />,
};

const TOOLTIP_MAP = {
  back: 'Back',
  arrow: 'Back',
  close: 'Close',
  collapse: 'Collapse',
  menu: 'Menu',
};

const styles = {
  root: {
    px: 2.5,
    height: 48,
    flex: '0 0 48px',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    flex: 1,
    minWidth: 0,
  },
  titleText: {
    flex: 1,
    minWidth: 0,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export type BackIconType = 'back' | 'arrow' | 'close' | 'collapse' | 'menu';

function TitleContent({ title }: { title: ReactNode }) {
  const { containerRef, isTruncated } = useTruncated<HTMLDivElement>();

  const content = (
    <Typography
      sx={styles.titleText}
      component="div"
      variant="subtitle2"
      ref={containerRef}
    >
      {title}
    </Typography>
  );

  if (!isTruncated) return content;
  return <SimpleTooltip title={title}>{content}</SimpleTooltip>;
}

export type TitleBarProps = {
  title?: ReactNode;
  titleSuffix?: ReactNode;
  sx?: BoxProps['sx'];
  toolComponent?: ReactNode;
  backIcon?: BackIconType;
  className?: string;
  customBackIcon?: ReactNode;
  customBackTooltip?: string;
  customAction?: ReactNode;
  disableBack?: IconButtonProps['disabled'];
  onBackClick?: IconButtonProps['onClick'];
};

export default function TitleBar({
  sx,
  className,
  backIcon,
  customBackIcon,
  customBackTooltip,
  customAction,
  onBackClick,
  disableBack,
  toolComponent,
  title,
  titleSuffix,
}: TitleBarProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const backIconComp = customBackIcon ?? (backIcon ? ICON_MAP[backIcon] : null);
  const backTooltipTitle =
    customBackTooltip ?? (backIcon ? TOOLTIP_MAP[backIcon] : '');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Box sx={[styles.root, ...sxProps]} className={className}>
      <Box sx={styles.title}>
        {backIconComp && (
          <TipButton
            className="title-bar-button"
            customSize={mdUp ? 24 : 28}
            onClick={onBackClick}
            disabled={disableBack}
            title={backTooltipTitle}
          >
            {backIconComp}
          </TipButton>
        )}
        {customAction}
        {!!title && <TitleContent title={title} />}
        {titleSuffix}
      </Box>
      {toolComponent}
    </Box>
  );
}
