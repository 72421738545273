import React from 'react';

export default function OtherCheckedCircleOutline({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M16.7371 10.2106C17.0874 9.86024 17.0874 9.29214 16.7371 8.94175C16.3867 8.59136 15.8186 8.59136 15.4682 8.94175L10.8515 13.5585L9.03502 11.4393C8.71254 11.063 8.14611 11.0195 7.76988 11.342C7.39365 11.6644 7.35008 12.2309 7.67256 12.6071L10.1196 15.4619C10.2822 15.6517 10.5166 15.765 10.7664 15.7746C11.0161 15.7842 11.2585 15.6892 11.4352 15.5125L16.7371 10.2106Z"
        fill="currentColor"
      />
    </svg>
  );
}
