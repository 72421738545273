import { useRef } from 'react';
import { Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { TableLayoutRow, TableLayoutURLCell } from '../../types';
import IaTextBasedEditableCell from '../IaTableEditable/IaTextBasedEditableCell';

export type IaURLCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutURLCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
  },
  link: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '& a': {
      textDecoration: 'underline',
      display: 'inline',
      color: 'text.primary',
    },
  },
};

const SIMPLE_URL_REGEX = /^.*\.[a-z]{2,}.*$/;
const httpRequired = (url: string) => {
  if (url.startsWith('http')) return url;
  return 'http://' + url;
};

export default function IaURLCell({ row, columnKey, cell }: IaURLCellProps) {
  const { url, editable, actionMap, placeholder } = cell;
  const ref = useRef<HTMLDivElement>();

  const isValidURL = url && url.match(SIMPLE_URL_REGEX);

  return (
    <>
      <Box sx={styles.root} ref={ref}>
        {url && (
          <Typography variant="body2" sx={styles.link}>
            {isValidURL ? (
              <Link
                href={httpRequired(url)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {url}
              </Link>
            ) : (
              <>{url}</>
            )}
          </Typography>
        )}
      </Box>
      <IaTextBasedEditableCell
        containerRef={ref}
        actionMap={actionMap}
        editable={editable}
        placeholder={placeholder}
        columnKey={columnKey}
        row={row}
        initValue={url}
        filterValue={(val: string) => val}
      />
    </>
  );
}
