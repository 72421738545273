import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { MaskIcon } from '@front/ui';

import { IconListLayoutLoadingConfig } from './types';

type IconListLayoutSkeletonProps = Omit<IconListLayoutLoadingConfig, 'layout'>;

const styles = {
  root: {
    py: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  item: {
    px: { xs: 2.5, md: '12px' },
    py: 1,
    display: 'flex',
    gap: 1,
  },
};

function SkeletonItem({ avatarSize = 32 }: { avatarSize?: number }) {
  return (
    <Box sx={styles.item}>
      <MaskIcon>
        <Skeleton
          width={avatarSize}
          height={avatarSize}
          variant="rectangular"
        />
      </MaskIcon>
      <Box flex={1}>
        <Typography>
          <Skeleton />
        </Typography>
        <Typography variant="body2">
          <Skeleton width="50%" />
        </Typography>
      </Box>
    </Box>
  );
}
export default function IconListSkeletonLayout({
  settings,
}: IconListLayoutSkeletonProps) {
  return (
    <Box sx={styles.root}>
      <SkeletonItem avatarSize={settings?.avatarSize} />
      <SkeletonItem avatarSize={settings?.avatarSize} />
    </Box>
  );
}
