import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Block, BlockNoteEditor, BlockSchema } from '@blocknote/core';
import { nonNullable } from '@front/helper';
import { ComposerBlock } from '@lib/web/composer';
import { useFileBlockMoreActions } from '@lib/web/composer/TextComposer/components/SideMenu/hooks/useFileBlockMoreActions';

import { BlockMoreActionDropdownItem } from '../components/BlockMoreActionDropdown';

import { useGeneralBlockMoreActions } from './useGeneralBlockMoreActions';

export const useFileBlockMoreActionItems = <BSchema extends BlockSchema>({
  editor,
  block,
}: {
  editor: BlockNoteEditor<BSchema>;
  block: Block<BSchema> | ComposerBlock | null;
}): BlockMoreActionDropdownItem[] => {
  const { t } = useTranslation('editor');
  const { deleteBlock } = useGeneralBlockMoreActions({ editor, block });

  const { fileKey, filename, fileType } =
    block && 'props' in block // block from click DragHandle
      ? {
          fileKey: block.props.key as string,
          filename: block.props.filename as string,
          fileType: block.props.filetype as string,
        }
      : block && block.content // block from click Block more menu
      ? {
          fileKey: block.content[0]?.attrs.key,
          filename: block.content[0]?.attrs.filename,
          fileType: block.content[0]?.attrs.filetype,
        }
      : {
          fileKey: '',
          filename: '',
          fileType: '',
        };

  const { download } = useFileBlockMoreActions({ fileKey, filename, fileType });

  return useMemo(
    () =>
      [
        fileKey
          ? {
              label: t('Download'),
              icon: 'TestExport',
              onClick: download,
            }
          : null,
        {
          label: t('Delete File'),
          icon: 'OtherDelete',
          onClick: deleteBlock,
          sx: {
            color: 'error.dark',
          },
        },
      ].filter(nonNullable),
    [deleteBlock, download, fileKey, t]
  );
};
