import { PropsWithChildren, ReactNode } from 'react';
import { Box, Theme } from '@mui/material';
import { ActionEnter as ActionEnterIcon } from '@front/icon';
import { Scrollbar, TipButton } from '@front/ui';
import { ErrorMessage, ErrorMessageValue } from '@lib/web/ui';

const styles = {
  root: {
    width: 400,
    bgcolor: 'background.menu',
    borderRadius: 1,
    overflow: 'hidden',
    boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.15)',
    border: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? '1px solid rgba(8, 8, 8, 0.05)'
        : '1px solid rgba(255, 255, 255, 0.1)',
  },
  top: {
    px: '12px',
    py: 1,
  },
  content: {
    height: 'min-content',
    whiteSpace: 'pre-wrap',
    '& [contenteditable][placeholder]:empty:before': {
      content: 'attr(placeholder)',
      position: 'absolute',
      opacity: 0.3,
      backgroundColor: 'transparent',
    },
    '& textarea::placeholder': {
      opacity: 0.3,
    },
  },
  errors: {
    mt: 1,
  },
  action: {
    mt: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
};

type PopupWrapProps = {
  maxContentHeight: number;
  errors?: ErrorMessageValue[];
  onDone: () => void;
  actionComponent?: ReactNode;
  bottomComponent?: ReactNode;
};
export default function PopupWrap({
  children,
  maxContentHeight,
  errors,
  actionComponent,
  bottomComponent,
  onDone,
}: PropsWithChildren<PopupWrapProps>) {
  const hasError = !!errors && errors.length > 0;
  return (
    <Box sx={styles.root}>
      <Box sx={styles.top}>
        <Scrollbar sx={{ maxHeight: maxContentHeight }}>
          <Box sx={styles.content}>{children}</Box>
        </Scrollbar>

        {hasError && <ErrorMessage sx={styles.errors} messages={errors} />}
        <Box sx={styles.action}>
          <Box>{actionComponent}</Box>
          <TipButton
            onClick={onDone}
            disabled={hasError}
            customSize={24}
            title="Enter"
          >
            <ActionEnterIcon />
          </TipButton>
        </Box>
      </Box>
      {bottomComponent}
    </Box>
  );
}
