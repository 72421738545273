import React, { ReactNode } from 'react';
import { Sortable, SortableOnDragOverOtherItem } from '@front/helper';

import { useIaAction } from '../../core/IaAction/useIaAction';

import { IaSortableConfig } from './types';

export default function IaSortable<T extends { id: string }>({
  items,
  config,
  children,
}: {
  items: T[];
  config?: IaSortableConfig;
  children: ReactNode;
}) {
  const { getIaAction } = useIaAction();

  if (!config) {
    return children;
  }

  const handleDragOverOtherItem: SortableOnDragOverOtherItem<T> = (props) => {
    getIaAction<typeof props>(config.dropAction)?.action(props);
  };

  return (
    <Sortable<T> items={items} onDragOverOtherItem={handleDragOverOtherItem}>
      {children}
    </Sortable>
  );
}
