import React from 'react';

export default function EditorLocate({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.3145 11.1818C18.9382 7.77 16.23 5.06182 12.8182 4.68545V3.81818C12.8182 3.36818 12.45 3 12 3C11.55 3 11.1818 3.36818 11.1818 3.81818V4.68545C7.77 5.06182 5.06182 7.77 4.68545 11.1818H3.81818C3.36818 11.1818 3 11.55 3 12C3 12.45 3.36818 12.8182 3.81818 12.8182H4.68545C5.06182 16.23 7.77 18.9382 11.1818 19.3145V20.1818C11.1818 20.6318 11.55 21 12 21C12.45 21 12.8182 20.6318 12.8182 20.1818V19.3145C16.23 18.9382 18.9382 16.23 19.3145 12.8182H20.1818C20.6318 12.8182 21 12.45 21 12C21 11.55 20.6318 11.1818 20.1818 11.1818H19.3145ZM12 17.7273C8.83364 17.7273 6.27273 15.1664 6.27273 12C6.27273 8.83364 8.83364 6.27273 12 6.27273C15.1664 6.27273 17.7273 8.83364 17.7273 12C17.7273 15.1664 15.1664 17.7273 12 17.7273Z"
        fill="currentColor"
      />
    </svg>
  );
}
