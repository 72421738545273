import { useMemo } from 'react';
import { nonNullable } from '@front/helper';
import { TableLayoutRow } from '@lib/ia/src/layouts/TableLayout/types';
import { apis } from '@lib/web/apis';
import { callWithToast } from '@lib/web/utils';

import { useEditorRubricCriteriaApis } from './useEditorRubricCriteriaApis';
import { useEditorRubricData } from './useEditorRubricData';
import { useEditorRubricDescriptorApis } from './useEditorRubricDescriptorApis';

export const useEditorRubricCriteriaActions = ({
  questionId,
  criteriaId,
}: {
  questionId: string;
  criteriaId: string;
}) => {
  const { updateCriteriaDescription, updateCriteriaScore } =
    useEditorRubricCriteriaApis(questionId);
  const {
    addNewDescriptor,
    updateDescriptorDescription,
    updateDescriptorScore,
    duplicateDescriptor,
    deleteDescriptor,
  } = useEditorRubricDescriptorApis(questionId);
  const { rubric, mutateRubric } = useEditorRubricData(questionId);

  return useMemo(() => {
    return {
      onCriteriaFormBlur: {
        action: ({ name, value }: { name: string; value: string | number }) => {
          if (name === 'description') {
            updateCriteriaDescription({
              criteriaId,
              value: (value as string) || 'Untitled',
            });
          }
          if (name === 'score') {
            updateCriteriaScore({
              criteriaId,
              value: value === '' ? 1 : Number(value),
            });
          }
        },
      },
      addNewDescriptor: {
        action: () => {
          addNewDescriptor({ criteriaId });
        },
      },
      addNewDescriptorAbove: {
        action: ({ id }: { id: string }) => {
          addNewDescriptor({ criteriaId, position: 'above', anchorId: id });
        },
      },
      addNewDescriptorBelow: {
        action: ({ id }: { id: string }) => {
          addNewDescriptor({ criteriaId, position: 'below', anchorId: id });
        },
      },
      updateDescriptorDescription: {
        action: ({ row, value }: { row: TableLayoutRow; value: string }) => {
          updateDescriptorDescription({
            id: row.id,
            value,
          });
        },
      },
      updateDescriptorScore: {
        action: ({ row, value }: { row: TableLayoutRow; value: number }) => {
          updateDescriptorScore({
            id: row.id,
            value: value === null ? 1 : value,
          });
        },
      },
      duplicateDescriptor: {
        action: ({ id }: { id: string }) => {
          duplicateDescriptor({ id, criteriaId });
        },
      },
      deleteDescriptor: {
        action: ({ id }: { id: string }) => {
          deleteDescriptor([id]);
        },
        batchAction: async (items?: { id: string }[]) => {
          const ids = items?.map((item) => item.id) || [];
          deleteDescriptor(ids);
        },
      },
      sortItem: {
        action: ({
          activeItem,
          overItem,
          newItems,
        }: {
          activeItem: TableLayoutRow;
          overItem: TableLayoutRow;
          newItems: TableLayoutRow[];
        }) => {
          if (!rubric) return;

          const activeIndex = newItems.findIndex(
            (item) => item.id === activeItem.id
          );
          const overIndex = newItems.findIndex(
            (item) => item.id === overItem.id
          );
          const isMovingDown = overIndex > activeIndex;

          mutateRubric(
            callWithToast(
              apis.editor.updateCreatorQuestionDescriptorOrder({
                id: activeItem.id,
                ...(isMovingDown
                  ? { beforeDescriptorId: overItem.id }
                  : { afterDescriptorId: overItem.id }),
              }),
              {
                showLoading: false,
              }
            ),
            {
              optimisticData: {
                ...rubric,
                criteria: rubric.criteria.map((c) => {
                  if (c.id === criteriaId) {
                    return {
                      ...c,
                      descriptors: newItems
                        .map((item) =>
                          c.descriptors?.find((d) => d.id === item.id)
                        )
                        .filter(nonNullable),
                    };
                  }
                  return c;
                }),
              },
            }
          );
        },
      },
    };
  }, [
    updateCriteriaDescription,
    criteriaId,
    updateCriteriaScore,
    addNewDescriptor,
    updateDescriptorDescription,
    updateDescriptorScore,
    duplicateDescriptor,
    deleteDescriptor,
    rubric,
    mutateRubric,
  ]);
};
