import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { TruncatedTooltip } from '@front/ui';

import { TableLayoutFooterNumberCell } from '../../types';

export type IaFooterNumberCellProps = {
  cell: TableLayoutFooterNumberCell;
};

const styles = {
  root: {
    px: 1,
    py: '5.5px',
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
  },
  text: {
    textAlign: 'right',
    typography: 'numberBody3',
  },
  textNowrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
export default function IaFooterNumberCell({ cell }: IaFooterNumberCellProps) {
  const { value } = cell;

  return (
    <Box sx={[styles.root]}>
      <TruncatedTooltip tooltip={value}>
        {(props) => (
          <Typography {...props} sx={[styles.text, styles.textNowrap]}>
            {value}
          </Typography>
        )}
      </TruncatedTooltip>
    </Box>
  );
}
