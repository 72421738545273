import { useMemo } from 'react';
import { apis } from '@lib/web/apis';
import { callWithToast } from '@lib/web/utils';
import { v4 } from 'uuid';

import { useEditorRubricData } from './useEditorRubricData';

const generateTempId = () => `temp-${v4()}`;

export const useEditorRubricDescriptorApis = (questionId: string) => {
  const { rubric, mutateRubric } = useEditorRubricData(questionId);

  return useMemo(() => {
    const findCriteria = (criteriaId: string) =>
      rubric?.criteria.find((c) => c.id === criteriaId);

    const findDescriptor = (descriptorId: string) => {
      for (const criteria of rubric?.criteria || []) {
        for (const descriptor of criteria.descriptors) {
          if (descriptor.id === descriptorId) {
            return {
              criteria,
              descriptor,
            };
          }
        }
      }
      return { criteria: undefined, descriptor: undefined };
    };

    return {
      addNewDescriptor: ({
        criteriaId,
        position,
        anchorId,
      }: {
        criteriaId: string;
        position?: 'above' | 'below';
        anchorId?: string;
      }) => {
        if (!rubric) return;

        const criteria = findCriteria(criteriaId);
        if (!criteria) return;

        const newDescriptor = {
          description: 'Untitled',
          score: 1,
        };

        mutateRubric(
          callWithToast(
            apis.editor.addCreatorQuestionDescriptor({
              criteriaId: criteria.id,
              ...newDescriptor,
              ...(position === 'above' && anchorId
                ? {
                    beforeDescriptorId: anchorId,
                  }
                : {}),
              ...(position === 'below' && anchorId
                ? {
                    afterDescriptorId: anchorId,
                  }
                : {}),
            }),
            {
              showLoading: false,
            }
          ),
          {
            optimisticData: {
              ...rubric,
              criteria: rubric.criteria.map((c) =>
                c.id === criteriaId
                  ? {
                      ...c,
                      descriptors:
                        position && anchorId
                          ? c.descriptors.reduce((acc, d) => {
                              if (d.id === anchorId) {
                                const newDesc = {
                                  id: generateTempId(),
                                  ...newDescriptor,
                                  order:
                                    position === 'above'
                                      ? d.order - 0.001
                                      : d.order + 0.001,
                                };
                                return [
                                  ...acc,
                                  ...(position === 'above'
                                    ? [newDesc, d]
                                    : [d, newDesc]),
                                ];
                              }
                              acc.push(d);
                              return acc;
                            }, [] as typeof c.descriptors)
                          : [
                              ...c.descriptors,
                              {
                                id: generateTempId(),
                                ...newDescriptor,
                                order: c.descriptors.length,
                              },
                            ],
                    }
                  : c
              ),
            },
          }
        );
      },
      updateDescriptorDescription: ({
        id,
        value,
      }: {
        id: string;
        value: string;
      }) => {
        if (!rubric) return;

        const { criteria, descriptor } = findDescriptor(id);
        if (!criteria || !descriptor) return;
        if (!descriptor || descriptor.description === value) return;

        mutateRubric(
          callWithToast(
            apis.editor.updateCreatorQuestionDescriptor({
              id,
              description: value || 'Untitled',
            }),
            {
              showLoading: false,
            }
          ),
          {
            optimisticData: {
              ...rubric,
              criteria: rubric.criteria.map((c) =>
                c.id === criteria.id
                  ? {
                      ...c,
                      descriptors: c.descriptors.map((d) =>
                        d.id === id
                          ? {
                              ...d,
                              description: value,
                            }
                          : d
                      ),
                    }
                  : c
              ),
            },
          }
        );
      },
      updateDescriptorScore: ({ id, value }: { id: string; value: number }) => {
        if (!rubric) return;

        const { criteria, descriptor } = findDescriptor(id);
        if (!criteria || !descriptor) return;
        if (!descriptor || descriptor.score === value) return;

        mutateRubric(
          callWithToast(
            apis.editor.updateCreatorQuestionDescriptor({
              id,
              score: value,
            }),
            {
              showLoading: false,
            }
          ),
          {
            optimisticData: {
              ...rubric,
              criteria: rubric.criteria.map((c) =>
                c.id === criteria.id
                  ? {
                      ...c,
                      descriptors: c.descriptors.map((d) =>
                        d.id === id
                          ? {
                              ...d,
                              score: value,
                            }
                          : d
                      ),
                    }
                  : c
              ),
            },
          }
        );
      },
      duplicateDescriptor: ({
        id,
        criteriaId,
      }: {
        id: string;
        criteriaId: string;
      }) => {
        if (!rubric) return;

        return mutateRubric(
          callWithToast(
            apis.editor.duplicateCreatorQuestionDescriptor({
              id,
              criteriaId,
            }),
            {
              showLoading: false,
            }
          ),
          {
            optimisticData: {
              ...rubric,
              criteria: rubric.criteria.map((criteria) => {
                if (criteria.id === criteriaId) {
                  const descriptor = criteria.descriptors.find(
                    (d) => d.id === id
                  );
                  if (!descriptor) return criteria;

                  return {
                    ...criteria,
                    descriptors: [
                      ...criteria.descriptors,
                      {
                        ...descriptor,
                        id: generateTempId(),
                        order: criteria.descriptors.length,
                      },
                    ],
                  };
                }
                return criteria;
              }),
            },
          }
        );
      },
      deleteDescriptor: (ids: string[]) => {
        if (!rubric) return;

        return mutateRubric(
          callWithToast(apis.editor.deleteCreatorQuestionDescriptor(ids), {
            showLoading: false,
          }),
          {
            optimisticData: {
              ...rubric,
              criteria: rubric.criteria.map((criteria) => ({
                ...criteria,
                descriptors: criteria.descriptors.filter(
                  (d) => !ids.includes(d.id)
                ),
              })),
            },
          }
        );
      },
    };
  }, [mutateRubric, rubric]);
};
