import React from 'react';

export default function EditorEqualLessThan({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.9371 16.9999C16.2544 16.9999 16.5681 16.8503 16.7775 16.5651C17.1362 16.0758 17.0513 15.3721 16.5875 14.9936L10.4705 9.99972L16.5879 5.00631C17.0517 4.62786 17.1368 3.92407 16.7779 3.43474C16.4187 2.94541 15.7516 2.85571 15.2883 3.23432L7 9.99985L15.2878 16.7658C15.4813 16.9238 15.7102 17 15.9371 17V16.9999Z"
        fill="currentColor"
      />
      <path
        d="M16.0052 19H7.99476C7.44538 19 7 19.4479 7 20C7 20.5521 7.44554 21 7.99476 21H16.0052C16.5546 21 17 20.5521 17 20C17 19.4479 16.5546 19 16.0052 19Z"
        fill="currentColor"
      />
    </svg>
  );
}
