import useMeasure from 'react-use-measure';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { SimpleTooltip } from '@front/ui';
import { ResizeObserver } from '@juggle/resize-observer';
import * as d3Shape from 'd3-shape';

const styles = {
  root: {
    height: '100%',
    width: '100%',

    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
};

export type DataItem = {
  name: string;
  ratio: number;
};

export type PieChartProps = Omit<BoxProps, 'children'> & {
  data: DataItem[];
  color: 'error' | 'warning';
};

type Data = {
  name: string;
  ratio: number;
  value: number;
};

type PieDataProps = {
  data: Data;
  index: number;
  value: number;
  startAngle: number;
  endAngle: number;
  padAngle: number;
};

const PADDING = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const NAME_MAX_LENGTH = 15;
function isLongName(name: string) {
  return name.length > NAME_MAX_LENGTH;
}

function substringIfNeeded(name: string) {
  if (!isLongName(name)) return name;
  return name.substring(0, NAME_MAX_LENGTH).trim() + '...';
}

export default function PieChart({ sx, data, color, ...rest }: PieChartProps) {
  const { palette } = useTheme();
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const [ref, { width, height }] = useMeasure({ polyfill: ResizeObserver });

  const chartWidth = width - PADDING.left - PADDING.right;
  const chartHeight = height - PADDING.top - PADDING.bottom;
  const size = Math.min(chartWidth, chartHeight);
  const valuedData = data.filter((d) => d.ratio > 0).map((d) => d.ratio);
  const maxRatio = Math.max(...valuedData);
  const outerRadius = size / 2;
  const innerRadius = size / 6;

  const arcLabel = d3Shape
    .arc<PieDataProps>()
    .innerRadius(innerRadius)
    .outerRadius(innerRadius + 80 * (size / 212));

  const pie = d3Shape.pie<Data>().value((d) => d.value);
  const arc = d3Shape
    .arc<PieDataProps>()
    .innerRadius(innerRadius)
    .outerRadius((d) => outerRadius - (maxRatio - d.value) * 100)
    .padAngle(0.01)
    .padRadius(innerRadius);

  const dataset: Data[] = data
    .filter((d) => d.ratio > 0)
    .map((d) => ({
      ...d,
      value: d.ratio,
    })) as Data[];

  const pieData = pie(dataset);
  const picColor = color === 'warning' ? '#FF3701' : '#FF4769';

  return (
    <Box ref={ref} sx={[styles.root, ...sxProps]} {...rest}>
      {size > 0 && (
        <svg>
          <g
            transform={`translate(${PADDING.left + chartWidth / 2}, ${
              PADDING.top + chartHeight / 2
            })`}
          >
            {pieData.map((item, i) => {
              const value = Math.round(item.data.ratio * 100);
              return (
                <g key={item.index}>
                  <path
                    fillOpacity={(dataset.length - i) / dataset.length}
                    fill={picColor}
                    d={arc(item) as string}
                  />

                  {value !== 0 && (
                    <g transform={`translate(${arcLabel.centroid(item)})`}>
                      <text textAnchor="middle">
                        <tspan
                          fill={palette.text.primary}
                          fontWeight="bold"
                          fontSize={30}
                          x={0}
                          dy={-10}
                        >{`${value}%`}</tspan>
                        {isLongName(item.data.name) && (
                          <SimpleTooltip
                            title={item.data.name}
                            slotProps={{
                              popper: {
                                sx: { maxWidth: 200 },
                              },
                            }}
                            followCursor
                          >
                            <tspan
                              x={0}
                              dy={20}
                              fill={palette.text.primary}
                              fontSize={14}
                            >
                              {substringIfNeeded(item.data.name)}
                            </tspan>
                          </SimpleTooltip>
                        )}
                        {!isLongName(item.data.name) && (
                          <tspan
                            x={0}
                            dy={20}
                            fill={palette.text.primary}
                            fontSize={14}
                          >
                            {item.data.name}
                          </tspan>
                        )}
                      </text>
                    </g>
                  )}
                </g>
              );
            })}
          </g>
        </svg>
      )}
    </Box>
  );
}
