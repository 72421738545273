import { Box, Typography } from '@mui/material';

import { AlbumLayoutTextExtensionConfig } from './types';

const styles = {
  root: {
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
  },
  content: (lines: number) => ({
    display: '-webkit-box',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    color: 'grey.500',
  }),
};

type AlbumLayoutTextExtensionProps = {
  config: AlbumLayoutTextExtensionConfig['config'];
};
export default function AlbumLayoutTextExtension({
  config,
}: AlbumLayoutTextExtensionProps) {
  const { content, lines = 3, sx } = config;
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={styles.root}>
      <Typography variant="body1" sx={[styles.content(lines), ...sxProps]}>
        {content}
      </Typography>
    </Box>
  );
}
