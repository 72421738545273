import React from 'react';

export default function EditorValue({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.2499 7.49996H11.2499C10.4999 7.49996 10.4999 7.49996 10.4999 8.25001V9.00006C10.4999 9.75011 10.4999 9.75011 11.2499 9.75011H20.2499C21 9.75011 21 9.75011 21 9.00006V8.25001C21 7.49996 21 7.49996 20.2499 7.49996ZM20.2499 14.2499H11.2499C10.4999 14.2499 10.4999 14.2499 10.4999 14.9999V15.75C10.4999 16.5 10.4999 16.5 11.2499 16.5H20.2499C21 16.4999 21 16.4999 21 15.7498V14.9998C21 14.2499 21 14.2499 20.2499 14.2499ZM20.2499 18.7498H11.2499C10.4999 18.7498 10.4999 18.7498 10.4999 19.4999V20.2499C10.4999 21 10.4999 21 11.2499 21H20.2499C21 20.9998 21 20.9998 21 20.2498V19.4997C21 18.7498 21 18.7498 20.2499 18.7498ZM20.2499 3H11.2499C10.4999 3 10.4999 3 10.4999 3.75005V4.50011C10.4999 5.25016 10.4999 5.25016 11.2499 5.25016H20.2499C21 5.25016 21 5.25016 21 4.50011V3.75005C21 3 21 3 20.2499 3ZM7.12506 3H4.125L3.75006 4.87504H5.25V9.74993H7.12506V3ZM5.62494 14.1149C4.125 14.1149 3 15.1198 3 16.6199H4.65006C4.65006 16.0949 4.95012 15.615 5.62512 15.615C6.23262 15.615 6.55518 16.0013 6.54762 16.4738C6.54384 16.7812 6.33378 17.0663 5.46762 17.7937L3 19.8748V20.9998H8.25006V19.2898H6.24L7.15872 18.3673C8.00256 17.5235 8.24988 17.0361 8.24988 16.3199C8.25006 15.1799 7.12506 14.1149 5.62494 14.1149Z"
        fill="currentColor"
      />
    </svg>
  );
}
