import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';

export type EssayAnswerResultProps = {
  variant: 'correct' | 'incorrect';
  title: string;
  value: string;
};

const styles = {
  indicator: {
    position: 'absolute',
    marginLeft: '-12px', // 4px width + 8px space in figma
    height: '24px',
    width: '4px',
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
  correctIndicator: {
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'success.light' : 'success.dark',
  },
  correctTitle: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'subtitle2' : 'examBody',
    color: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'success.light' : 'success.dark',
    fontWeight: 700,
  },
  correctValue: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
    fontWeight: 700,
  },
  incorrectIndicator: {
    backgroundColor: 'error.dark',
  },
  incorrectTitle: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'subtitle2' : 'examBody',
    color: 'error.dark',
    fontWeight: 700,
  },
  incorrectValue: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
  },
};
export default function EssayAnswerResult({
  variant,
  title,
  value,
}: EssayAnswerResultProps) {
  return (
    <>
      <Box
        sx={[
          styles.indicator,
          {
            correct: styles.correctIndicator,
            incorrect: styles.incorrectIndicator,
          }[variant],
        ]}
      />
      <Typography
        sx={
          {
            correct: styles.correctTitle,
            incorrect: styles.incorrectTitle,
          }[variant]
        }
      >
        {title}
      </Typography>
      <Typography
        mt={0.5}
        sx={
          {
            correct: styles.correctValue,
            incorrect: styles.incorrectValue,
          }[variant]
        }
      >
        {value}
      </Typography>
    </>
  );
}
