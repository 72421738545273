import useMeasure from 'react-use-measure';
import { Box } from '@mui/material';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';

import { useEditorRubricCriteriaActions } from './hooks/useEditorRubricCriteriaActions';
import { useEditorRubricCriteriaLayoutConfig } from './hooks/useEditorRubricCriteriaLayoutConfig';

type EditorRubricCriteriaProps = {
  questionId: string;
  criteriaId: string;
};

export default function EditorRubricCriteria({
  questionId,
  criteriaId,
}: EditorRubricCriteriaProps) {
  const [ref, { width }] = useMeasure({ polyfill: ResizeObserver });

  const availableActions = useEditorRubricCriteriaActions({
    questionId,
    criteriaId,
  });

  const config = useEditorRubricCriteriaLayoutConfig({
    questionId,
    criteriaId,
    containerWidth: width,
  });

  return (
    <Box ref={ref}>
      <IaActionContextProvider availableActions={availableActions}>
        <IaLayouts layouts={config} />
      </IaActionContextProvider>
    </Box>
  );
}
