import { Box } from '@mui/material';
import Icon from '@lib/ia/src/components/Icon';

import { AlbumLayoutIconItem } from '../../types';

import IaAlbumBaseItem from './IaAlbumBaseItem';

const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
};

type IaAlbumIconItemProps = AlbumLayoutIconItem;

export default function IaAlbumIconItem(item: IaAlbumIconItemProps) {
  const { icon, ...rest } = item;
  return (
    <IaAlbumBaseItem {...rest} item={item}>
      <Box sx={styles.root}>
        <Icon name={icon} width={40} height={40} />
      </Box>
    </IaAlbumBaseItem>
  );
}
