import { createAction } from '@reduxjs/toolkit';

const types = {
  RESEND_EMAIL: 'LAYOUT/RESEND_EMAIL',
  RESET_RESEND_EMAIL: 'LAYOUT/RESET_RESEND_EMAIL',
  UPDATE_BLOCKER_OFFSET: 'LAYOUT/UPDATE_BLOCKER_OFFSET',
  UPDATE_PRACTICE_INCOGNITO: 'LAYOUT/UPDATE_PRACTICE_INCOGNITO',
  UPDATE_VIEWED_SLUG: 'LAYOUT/UPDATE_VIEWED_SLUG',
  CLEAR_SPECIFIC_VIEWED_SLUGS: 'LAYOUT/CLEAR_SPECIFIC_VIEWED_SLUGS',
};

export const resendEmail = createAction(types.RESEND_EMAIL);
export const resetResendEmail = createAction(types.RESET_RESEND_EMAIL);
export const updateBlockerOffset = createAction(
  types.UPDATE_BLOCKER_OFFSET,
  (ev: { top?: number; left?: number }) => ({
    payload: ev,
  })
);
export const updatePracticeIncognito = createAction(
  types.UPDATE_PRACTICE_INCOGNITO,
  (ev: boolean) => ({
    payload: ev,
  })
);
export const updateViewedSlug = createAction(
  types.UPDATE_VIEWED_SLUG,
  (ev: string) => ({
    payload: ev,
  })
);
export const clearSpecificViewedSlugs = createAction(
  types.CLEAR_SPECIFIC_VIEWED_SLUGS,
  (ev: string[]) => ({
    payload: ev,
  })
);
