import { createContext, Dispatch, SetStateAction } from 'react';

export interface RouteHistoryValue {
  prevRoutes: string[];
  shouldInterrupt: boolean;
  showBackConfirm: boolean;
}

export const initialValue = {
  prevRoutes: [],
  shouldInterrupt: false,
  showBackConfirm: false,
};

export type RouteHistoryContextValue = [
  RouteHistoryValue,
  Dispatch<SetStateAction<RouteHistoryValue>>
];

const RouteHistoryContext = createContext<RouteHistoryContextValue>([
  initialValue,
  () => {},
]);

export default RouteHistoryContext;
