import React from 'react';

export default function PropertyTypeLineColourStatus({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7 6.59123V12.9331C7.6678 12.7022 8.52835 12.5 9.5 12.5C10.769 12.5 11.8472 13.1172 12.6658 13.5858C12.6928 13.6013 12.7196 13.6166 12.7461 13.6318C13.6683 14.1587 14.3109 14.5 15 14.5C15.8487 14.5 16.7337 14.2847 17.4338 14.0513C17.6471 13.9802 17.8383 13.9091 18 13.8451V7.47056C17.2054 7.73066 16.1198 8 15 8C13.731 8 12.6528 7.38281 11.8342 6.91423C11.8072 6.89873 11.7804 6.88339 11.7539 6.86824C10.8317 6.3413 10.1891 6 9.5 6C8.66295 6 7.91673 6.21034 7.37139 6.42848C7.2319 6.48427 7.10742 6.53983 7 6.59123ZM18.5531 5.10541C18.863 4.95059 19.231 4.96721 19.5257 5.14935C19.8205 5.33156 20 5.65342 20 6V14.5C20 14.8788 19.786 15.225 19.4472 15.3944L19 14.5C19.4472 15.3944 19.4472 15.3944 19.4472 15.3944L19.4454 15.3953L19.4429 15.3966L19.4357 15.4001L19.4126 15.4114C19.3934 15.4207 19.3667 15.4334 19.333 15.4492C19.2657 15.4806 19.1701 15.524 19.0502 15.5754C18.8109 15.6779 18.4722 15.8134 18.0662 15.9487C17.2663 16.2153 16.1513 16.5 15 16.5C13.731 16.5 12.6528 15.8828 11.8342 15.4142C11.8072 15.3987 11.7804 15.3834 11.7539 15.3682C10.8317 14.8413 10.1891 14.5 9.5 14.5C8.66295 14.5 7.91673 14.7103 7.37139 14.9285C7.2319 14.9843 7.10742 15.0398 7 15.0912V20C7 20.5523 6.55228 21 6 21C5.44772 21 5 20.5523 5 20V6C5 5.66565 5.1671 5.35342 5.4453 5.16795L6 6C5.4453 5.16795 5.4453 5.16795 5.4453 5.16795L5.44758 5.16643L5.45044 5.16455L5.45783 5.1597L5.4793 5.14585C5.49655 5.13483 5.51967 5.12033 5.54841 5.10283C5.60586 5.06787 5.68597 5.02084 5.78677 4.96585C5.98797 4.85611 6.27396 4.71338 6.62861 4.57152C7.33327 4.28966 8.33705 4 9.5 4C10.769 4 11.8472 4.61719 12.6658 5.08577C12.6928 5.10127 12.7196 5.11661 12.7461 5.13176C13.6683 5.6587 14.3109 6 15 6C15.8487 6 16.7337 5.78468 17.4338 5.55132C17.7778 5.43663 18.0641 5.32206 18.2623 5.23711C18.3612 5.19474 18.4374 5.16004 18.4873 5.13679C18.5122 5.12517 18.5304 5.11644 18.5415 5.11107L18.5531 5.10541Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
