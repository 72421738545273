import React from 'react';

export default function EmojiSuperRare({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.5801 4.28307C17.4638 4.10631 17.2671 4 17.0564 4H6.94359C6.73287 4 6.53621 4.10631 6.41993 4.28307L3.10425 9.3235C2.94927 9.5591 2.96869 9.86958 3.15181 10.0838L11.441 19.7802C11.5596 19.9189 11.7322 19.9991 11.914 20C12.0959 20.0009 12.2692 19.9224 12.3892 19.7849L20.8442 10.0885C21.0309 9.87434 21.052 9.56102 20.8957 9.3235L17.5801 4.28307ZM17.1585 5.93126L15.7523 9.04043H19.2037L17.1585 5.93126ZM18.9862 10.3036L13.3337 16.786L15.2456 10.3036H18.9862ZM13.9357 10.3036L11.9328 17.0945L10.0315 10.3036H13.9357ZM8.72689 10.3036L10.5438 16.7933L4.99595 10.3036H8.72689ZM4.79629 9.04043H8.23061L6.83901 5.93512L4.79629 9.04043ZM7.91537 5.26316H10.9303L9.26221 8.26858L7.91537 5.26316ZM10.2721 9.04043L11.998 5.93091L13.7111 9.04043H10.2721ZM14.7217 8.26877L13.0658 5.26316H16.081L14.7217 8.26877Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
