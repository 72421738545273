import Text from '@tiptap/extension-text';
import { v4 } from 'uuid';

import ParagraphBlock from '../TextComposer/components/blocks/ParagraphBlock';
import { ComposerBlock } from '../TextComposer/types';

export const basicBlockGenerator = {
  // base blocks
  blockContainer: (overrides: Partial<ComposerBlock> = {}): ComposerBlock => {
    return {
      id: v4(),
      type: 'blockContainer',
      attrs: {},
      content: [],
      ...overrides,
    };
  },
  paragraph: (overrides: Partial<ComposerBlock> = {}): ComposerBlock => {
    return {
      id: v4(),
      type: ParagraphBlock.node.name,
      attrs: {},
      content: [],
      ...overrides,
    };
  },
  text: (overrides: Partial<ComposerBlock> = {}): ComposerBlock => {
    return {
      id: v4(),
      type: Text.name,
      attrs: {},
      text: '',
      content: [],
      ...overrides,
    };
  },

  // use cases
  emptyBlock: () =>
    basicBlockGenerator.blockContainer({
      content: [basicBlockGenerator.paragraph()],
    }),

  textBlock: (text: string) =>
    basicBlockGenerator.blockContainer({
      content: [
        basicBlockGenerator.paragraph({
          content: [basicBlockGenerator.text({ text })],
        }),
      ],
    }),
};
