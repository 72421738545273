import React from 'react';
import { v4 } from 'uuid';

export default function Award300({ ...rest }) {
  const id = v4();
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9209 49.5407C0.0263653 45.6066 0.026366 39.228 3.9209 35.2938L34.9483 3.95063C38.8429 0.0164549 45.1572 0.0164574 49.0517 3.95063L80.0791 35.2938C83.9736 39.228 83.9736 45.6066 80.0791 49.5407L50.462 79.4593C45.7886 84.1803 38.2114 84.1803 33.538 79.4593L3.9209 49.5407ZM6.74158 38.1432C4.40485 40.5037 4.40485 44.3309 6.74158 46.6914L36.3587 76.6099C39.4743 79.7572 44.5257 79.7572 47.6413 76.6099L77.2584 46.6914C79.5951 44.3309 79.5951 40.5037 77.2584 38.1432L46.231 6.80001C43.8943 4.43951 40.1057 4.43951 37.769 6.80001L6.74158 38.1432Z"
        fill={`url(#paint0_linear_${id})`}
      />
      <g style={{ mixBlendMode: 'screen' }}>
        <mask
          id={`mask0_${id}`}
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="1"
          width="82"
          height="82"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.9209 49.5407C0.0263653 45.6066 0.026366 39.228 3.9209 35.2938L34.9483 3.95063C38.8429 0.0164549 45.1572 0.0164574 49.0517 3.95063L80.0791 35.2938C83.9736 39.228 83.9736 45.6066 80.0791 49.5407L50.462 79.4593C45.7886 84.1803 38.2114 84.1803 33.538 79.4593L3.9209 49.5407ZM6.74158 38.1432C4.40485 40.5037 4.40485 44.3309 6.74158 46.6914L36.3587 76.6099C39.4743 79.7572 44.5257 79.7572 47.6413 76.6099L77.2584 46.6914C79.5951 44.3309 79.5951 40.5037 77.2584 38.1432L46.231 6.80001C43.8943 4.43951 40.1057 4.43951 37.769 6.80001L6.74158 38.1432Z"
            fill={`url(#paint1_linear_${id})`}
          />
        </mask>
        <g mask={`url(#mask0_${id})`}>
          <g filter={`url(#filter0_f_${id})`}>
            <rect
              x="62.2083"
              y="-1.53613"
              width="31.4975"
              height="90.1483"
              transform="rotate(45 62.2083 -1.53613)"
              fill="#BAA854"
            />
          </g>
        </g>
      </g>
      <g clipPath={`url(#clip0_${id})`}>
        <rect
          x="46.5018"
          y="20.2393"
          width="13.7596"
          height="41.2789"
          rx="6.87981"
          transform="rotate(30 46.5018 20.2393)"
          fill="#A36911"
        />
        <ellipse
          cx="35.2604"
          cy="53.4697"
          rx="6.87981"
          ry="6.87982"
          transform="rotate(30 35.2604 53.4697)"
          fill="#FFD25F"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.6447 70.1195L51.658 73.1632L52.3738 77.4853C52.9653 81.0583 49.2515 84.4182 45.5925 82.418L42.0158 80.4624L38.4437 82.4269M38.4437 82.4269C34.7894 84.4368 31.067 81.0854 31.6503 77.5108L32.3562 73.1866L29.3632 70.1505C26.7893 67.5396 28.0155 62.7553 31.954 62.1535L35.9831 61.5378L37.8041 57.6775C39.4854 54.1138 44.4845 54.1045 46.1752 57.6676L48.0047 61.5228L52.0349 62.1287C55.9759 62.721 57.2115 67.5033 54.6447 70.1195"
        fill="#463211"
      />
      <path
        d="M46.8344 64.3807L51.5891 65.0954C52.9373 65.298 53.4775 67.0255 52.5034 68.0184L49.0673 71.52C48.6804 71.9144 48.5044 72.4819 48.5963 73.0379L49.414 77.9755C49.6458 79.3756 48.238 80.4453 47.0314 79.7857L42.7769 77.4595C42.2977 77.1976 41.7257 77.1981 41.2473 77.4613L36.998 79.7983C35.7931 80.461 34.3826 79.3947 34.6111 77.994L35.4175 73.0544C35.5081 72.4982 35.331 71.9308 34.943 71.5375L31.4995 68.0444C30.5229 67.0538 31.0592 65.3251 32.4072 65.1191L37.1601 64.3928C37.6953 64.3111 38.1577 63.9597 38.3964 63.4535L40.5173 58.9575C41.1188 57.6826 42.8607 57.6803 43.4649 58.9539L45.5959 63.4445C45.8359 63.9499 46.299 64.3002 46.8344 64.3807Z"
        fill="#FFD25F"
      />
      <defs>
        <filter
          id={`filter0_f_${id}`}
          x="-5.53625"
          y="-5.53613"
          width="94.0166"
          height="94.0166"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result={`effect1_foregroundBlur_${id}`}
          />
        </filter>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="84.4569"
          y1="0.106219"
          x2="-17.2111"
          y2="50.899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A36911" />
          <stop offset="1" stopColor="#FFD25F" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_${id}`}
          x1="84.4569"
          y1="0.106219"
          x2="-17.2111"
          y2="50.899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D54FC" />
          <stop offset="1" stopColor="#B6AAFF" />
        </linearGradient>
        <clipPath id={`clip0_${id}`}>
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(17 17)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
