import api from './client';

const clubGroupApi = {
  createClubGroup: (params: CreateClubGroupReq) =>
    api.post<Response<CreateClubGroupRes>>('/v2/club-group', params),
  updateClubGroup: (params: UpdateClubGroupReq) =>
    api.put<Response<UpdateClubGroupRes>>('/v2/club-group', params),
  addClubsIntoGroup: (params: AddClubsIntoGroupReq) =>
    api.post<Response<AddClubsIntoGroupRes>>('/v2/club-group/club', params),
  deleteClubsFromGroup: (params: DeleteClubsFromGroupReq) =>
    api.delete<Response<{ count: number }>>('/v2/club-group/club', {
      data: params,
    }),
  deleteClubGroup: (id: string) =>
    api.delete<Response<{ count: number }>>('/v2/club-group', { data: { id } }),
  joinClubGroup: (id: string) =>
    api.post<Response<{ id: string }>>('/v2/club-group/join', { id }),
  leaveClubGroup: (params: LeaveClubGroupReq) =>
    api.post<Response<{ id: string }>>('/v2/club-group/leave', params),
};

export default clubGroupApi;
