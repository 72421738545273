import { useCallback, useContext } from 'react';
import { useCollectUniqueKeys } from '@front/helper';
import { UploadResourceType, useSignedUrlMultiple } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { ThreadComposerCustomContext } from '@lib/web/thread/contexts/threadComposerCustomContext';

/**
 * This hook is used for generating HTML to render thread messages in our application.
 * Because some blocks contain unsigned URLs, we need to sign them before rendering.
 * In some cases, we don't need this and can simply use composerBlocksToHtml,
 * such as when providing GPT message.
 */
export const useRenderThreadMessageToHtml = () => {
  const { composerBlocksToHtml, contentEnricher } = useContext(
    ThreadComposerCustomContext
  );
  const [threadImgKeys, collectThreadImgKeys] = useCollectUniqueKeys<string>();

  // HACK: to support legacy solution transform into thread, we need to use another resource type
  const [editorImgKeys, collectEditorImgKeys] = useCollectUniqueKeys<string>();

  const threadSignedUrls = useSignedUrlMultiple({
    keys: threadImgKeys,
    resourceType: UploadResourceType.AhaImages,
  });

  const editorSignedUrls = useSignedUrlMultiple({
    keys: editorImgKeys,
    resourceType: UploadResourceType.AhaEditorImage,
  });

  const renderThreadMessageToHtml = useCallback(
    (text?: string, composerBlocks?: ComposerBlock[]): string => {
      /**
       * we always use composerBlocks (which is the original data structure of composer) to render message content,
       * but also make 'text' as the fallback value
       */
      let composerBlocksHtml = composerBlocks
        ? composerBlocksToHtml(composerBlocks)
        : '';
      composerBlocksHtml = contentEnricher
        ? contentEnricher(composerBlocksHtml)
        : composerBlocksHtml;

      const html = composerBlocksHtml || String(text || '');

      [...html.matchAll(/<img[^>]+key="([^">]+)"/g)].forEach((match) => {
        if (match[1].includes('/thread/')) {
          collectThreadImgKeys(match[1]);
        } else {
          collectEditorImgKeys(match[1]);
        }
      });

      const signedUrls = {
        ...threadSignedUrls,
        ...editorSignedUrls,
      };

      return html.replace(
        /(<img[^>]+key=")([^">]+)("[^>]+src=")([^">]+)(")/g,
        (match, p1, key, p3, src, p5) => {
          return signedUrls?.[key]
            ? p1 + key + p3 + signedUrls[key] + p5
            : match;
        }
      );
    },
    [
      collectEditorImgKeys,
      collectThreadImgKeys,
      composerBlocksToHtml,
      contentEnricher,
      editorSignedUrls,
      threadSignedUrls,
    ]
  );
  return {
    renderThreadMessageToHtml,
  };
};
