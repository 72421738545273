import {
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Popover, SxProps, TextField } from '@mui/material';
import { Scrollbar } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import useTableSelectionContext from '../../hooks/useTableSelectionContext';
import useTableSettings from '../../hooks/useTableSettings';
import {
  IaCellValueChangedEvent,
  TableLayoutRow,
  TableLayoutTextBasedEditableCell,
} from '../../types';

const styles = {
  scroll: {
    bgcolor: '#151A28',
    borderRadius: 1,
  },
  input: {
    '& .MuiInputBase-input': {
      typography: 'body2',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
    },
    px: 1,
    py: '5.5px',
    width: '100%',
    minHeight: '32px',
    bgcolor: '#151A28',
    borderRadius: 1,
  },
};

type IaTextBasedEditableCellProps<V = string> =
  TableLayoutTextBasedEditableCell & {
    initValue?: string | number | null;
    containerRef: MutableRefObject<HTMLElement | undefined>;
    row: TableLayoutRow;
    columnKey: string;
    inputSx?: SxProps;

    /**
     * Use to filter the raw input value before passing through the changeValue event
     */
    filterValue: (val: string) => V;
  };

export default function IaTextBasedEditableCell<V = string>({
  editable,
  actionMap,
  placeholder,
  initValue,
  containerRef,
  row,
  columnKey,
  inputSx,
  filterValue,
}: IaTextBasedEditableCellProps<V>) {
  const [inputValue, setInputValue] = useState(initValue || '');
  const { getCellSelectedState, setSelectedState } = useTableSelectionContext();
  const selectedState = getCellSelectedState(row.id, columnKey);
  const [editOpen, setEditOpen] = useState(false);
  const scrollRef = useRef<HTMLDivElement>();
  const { wrap } = useTableSettings();

  const { getIaAction } = useIaAction();

  useEffect(() => {
    const open = !!editable && selectedState === 'active';
    if (open !== editOpen) {
      setEditOpen(open);
    }
  }, [editOpen, editable, selectedState]);

  const handleEditClose = () => {
    setSelectedState('focused');
    setEditOpen(false);
  };

  const handlePopoverClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const handleValueChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (!actionMap || !editable) return;
    if (!actionMap?.valueChange) return;
    const action = getIaAction<IaCellValueChangedEvent<V>>(
      actionMap.valueChange
    );
    const value = filterValue(newValue);
    action?.action({ value, row, columnKey });
  };

  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    // reset input field value
    setInputValue(initValue || '');

    // set the cursor to the end
    e.currentTarget.setSelectionRange(
      e.currentTarget.value.length,
      e.currentTarget.value.length
    );

    // scroll to the bottom
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    });
  };

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    // reset input field value
    setInputValue(initValue || '');

    const newValue = e.target.value;
    if (!actionMap || !editable) return;
    if (!actionMap?.inputBlur) return;

    const action = getIaAction<IaCellValueChangedEvent<V>>(actionMap.inputBlur);
    const value = filterValue(newValue);
    action?.action({ value, row, columnKey });
  };

  const width = containerRef.current?.clientWidth;

  const inputSxProps = Array.isArray(inputSx) ? inputSx : [inputSx];

  return (
    <Popover
      open={editOpen}
      onClose={handleEditClose}
      anchorEl={containerRef.current}
      onClick={handlePopoverClick}
      transitionDuration={0}
    >
      <Scrollbar
        sx={[
          {
            maxHeight: !wrap ? 230 : undefined,
            minHeight: !wrap ? 0 : containerRef.current?.clientHeight,
            width,
          },
          styles.scroll,
        ]}
        scrollableNodeProps={{
          ref: scrollRef,
        }}
      >
        <TextField
          autoFocus
          sx={[styles.input, ...inputSxProps]}
          value={inputValue}
          multiline
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          onFocus={handleFocus}
          onChange={handleValueChange}
          onBlur={handleBlur}
          placeholder={placeholder}
        />
      </Scrollbar>
    </Popover>
  );
}
