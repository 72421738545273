import React from 'react';

export default function AgentWorkflow({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M20.6205 17.7496C20.8002 17.8682 20.8002 18.1318 20.6205 18.2504L16.2152 21.1571C16.0158 21.2887 15.75 21.1457 15.75 20.9067V15.0933C15.75 14.8543 16.0158 14.7113 16.2152 14.8429L20.6205 17.7496Z"
        fill="currentColor"
      />
      <path
        d="M6.95461 8.99785C7.13136 8.98639 7.29798 8.90883 7.42321 8.7797L9.39197 6.74961H16.3317L16.4611 6.75336C17.0244 6.78832 17.5535 7.04369 17.9404 7.46733C18.3273 7.89097 18.5427 8.45092 18.5427 9.03281C18.5427 9.6147 18.3273 10.1746 17.9404 10.5983C17.5535 11.0219 17.0244 11.2773 16.4611 11.3123L16.3317 11.3153H14.5477L12.5142 9.21917L12.4458 9.15692C12.3059 9.04499 12.1312 8.98956 11.9544 9.00102C11.7777 9.01248 11.611 9.09005 11.4858 9.21917L9.4516 11.3153L7.60431 11.316L7.44794 11.319C6.52095 11.362 5.64575 11.7719 5.00451 12.4635C4.36327 13.1552 4.0054 14.0751 4.0054 15.032C4.0054 15.9888 4.36327 16.9088 5.00451 17.6004C5.64575 18.292 6.52095 18.702 7.44794 18.7449L7.60431 18.7487H16V17.2488H7.60431L7.47776 17.2451C6.9313 17.2106 6.41816 16.9625 6.04302 16.5513C5.66787 16.1402 5.459 15.5969 5.459 15.0323C5.459 14.4678 5.66787 13.9245 6.04302 13.5134C6.41816 13.1022 6.9313 12.8541 7.47776 12.8196L7.60431 12.8151H9.58033L11.4858 14.7792L11.5542 14.8415C11.694 14.9535 11.8687 15.0091 12.0454 14.9978C12.2222 14.9864 12.3889 14.909 12.5142 14.78L14.4189 12.8151L16.331 12.8159L16.491 12.8129C17.4349 12.7695 18.3262 12.3524 18.9792 11.6483C19.6323 10.9442 19.9968 10.0075 19.9968 9.03318C19.9968 8.0589 19.6323 7.12218 18.9792 6.41809C18.3262 5.71401 17.4349 5.29684 16.491 5.25347L16.331 5.24972H9.39269L7.42321 3.21963L7.35485 3.15738C7.21492 3.04545 7.04019 2.99002 6.86344 3.00148C6.68669 3.01294 6.52007 3.09051 6.39484 3.21963L4.21299 5.46946L4.15263 5.53995C4.04408 5.68424 3.99032 5.86442 4.00143 6.04667C4.01255 6.22893 4.08777 6.40074 4.21299 6.52988L6.39484 8.7797L6.4632 8.84195C6.60313 8.95388 6.77787 9.00931 6.95461 8.99785Z"
        fill="currentColor"
      />
    </svg>
  );
}
