import React from 'react';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { MaskIcon } from '@front/ui';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
    gap: 1,
    px: '20px',
    py: '8px',
    width: '100%',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  content: {
    width: '100%',
  },
  skeleton: {
    borderRadius: '2px',
  },
};

export default function SkeletonItem() {
  return (
    <Box sx={styles.item}>
      <MaskIcon>
        <Skeleton width={32} height={32} variant="rectangular" />
      </MaskIcon>
      <Box sx={styles.main} pt={0.5}>
        <Box sx={styles.header} mb={1}>
          <Skeleton
            width={62}
            height={16}
            variant="rounded"
            sx={styles.skeleton}
          />
          <Skeleton
            width={30}
            height={16}
            variant="rounded"
            sx={styles.skeleton}
          />
        </Box>
        <Box mb={1}>
          <Skeleton
            width={40}
            height={16}
            variant="rounded"
            sx={styles.skeleton}
          />
        </Box>
        <Box>
          <Skeleton
            width={308}
            height={24}
            variant="rounded"
            sx={styles.skeleton}
          />
        </Box>
      </Box>
    </Box>
  );
}
