import React, { ReactNode } from 'react';
import Link from 'next/link';
import Box, { BoxProps } from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/system';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { TableLayoutRow } from '@lib/ia/src/layouts/TableLayout/types';

import useTableSettings from '../../hooks/useTableSettings';

export type IaTableRowProps = {
  sx?: BoxProps['sx'];
  hovered?: boolean;
  active?: boolean;
  row?: TableLayoutRow;
  showCheckboxBackground?: boolean;
  sidePeekEnabled?: boolean;
  isEmpty?: boolean;
  children: ReactNode;
};

const styles = {
  root: {
    display: 'grid',
    alignItems: 'center',
    position: 'relative',
  },
  nowrap: {
    height: '32px',
  },
  rootCheckboxPadding: {
    ml: '-24px',
  },
  hovered: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
  clickable: {
    cursor: 'pointer',
  },
  active: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  selected: {
    boxShadow: (theme: Theme) =>
      `inset 0 0 0 1px ${alpha(theme.palette.primary.light, 0.5)}`,
    background: 'rgba(0, 209, 255, 0.05)',
  },
};

function getRootPl(
  showCheckboxBackground?: boolean,
  sidePeekEnabled?: boolean,
  mdUp?: boolean
) {
  let pl = 0;
  if (showCheckboxBackground) pl += 24; // px
  if (sidePeekEnabled && !mdUp) pl += 67; // 67px side peek button width
  return `${pl}px`;
}

export default function IaTableRow({
  sx,
  hovered,
  active,
  row,
  showCheckboxBackground,
  sidePeekEnabled,
  isEmpty,
  children,
}: IaTableRowProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const sxProps = Array.isArray(sx) ? sx : [sx];

  const { wrap } = useTableSettings();

  const { getIaAction } = useIaAction();

  const eventActionProps = {
    onClick: () =>
      getIaAction<TableLayoutRow>(row?.clickAction?.value)?.action(row),
  };

  const linkActionProps = {
    component: Link,
    href: row?.clickAction?.value,
  };

  const clickActionType = row?.clickAction?.type;
  const mouseEnterAction = row?.mouseEnterAction?.value;
  const mouseLeaveAction = row?.mouseLeaveAction?.value;

  return (
    <Box
      sx={[
        styles.root,
        !wrap && styles.nowrap,
        hovered && styles.hovered,
        active && styles.active,
        clickActionType && styles.clickable,
        showCheckboxBackground && styles.rootCheckboxPadding,
        row?.selected && styles.selected,
        !isEmpty && {
          pl: getRootPl(showCheckboxBackground, sidePeekEnabled, mdUp),
        },
        ...sxProps,
      ]}
      {...(clickActionType === 'event'
        ? eventActionProps
        : clickActionType === 'link'
        ? linkActionProps
        : {})}
      {...(mouseEnterAction
        ? {
            onMouseEnter: () =>
              getIaAction<TableLayoutRow>(mouseEnterAction)?.action(row),
          }
        : {})}
      {...(mouseLeaveAction
        ? {
            onMouseLeave: () =>
              getIaAction<TableLayoutRow>(mouseLeaveAction)?.action(row),
          }
        : {})}
    >
      {children}
    </Box>
  );
}
