import React from 'react';

export default function ProfileFindTutor({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1004 5.09997V2.3999H18.9004V5.09997L21.6004 5.09997V6.89997H18.9004V9.5999H17.1004V6.89997H14.4004V5.09997H17.1004Z"
        fill="currentColor"
      />
      <path
        d="M13.2004 6.06595L12.0476 5.6295C11.944 5.5903 11.8305 5.5903 11.727 5.6295L2.70916 9.04358C2.30607 9.19619 2.29488 9.78547 2.69186 9.95455L11.7097 13.7954C11.8235 13.8439 11.951 13.8439 12.0649 13.7954L19.0974 10.8001H16.7004C16.2586 10.8001 15.9004 10.4419 15.9004 10.0001V8.90017C15.9004 8.45834 15.5422 8.10017 15.1004 8.10017H14.0004C13.5586 8.10017 13.2004 7.742 13.2004 7.30017V6.06595Z"
        fill="currentColor"
      />
      <path
        d="M11.7025 15.2537L5.9741 12.6977C5.66458 12.5596 5.32025 12.796 5.32025 13.1466V15.9908C5.32025 16.1862 5.43208 16.3628 5.60455 16.4397L11.7025 19.1606C11.8205 19.2133 11.954 19.2133 12.072 19.1606L18.17 16.4397C18.3424 16.3628 18.4543 16.1862 18.4543 15.9908V13.1466C18.4543 12.796 18.11 12.5596 17.8004 12.6977L12.072 15.2537C11.954 15.3063 11.8205 15.3063 11.7025 15.2537Z"
        fill="currentColor"
      />
      <path
        d="M21.2687 11.4292C21.2687 11.1595 21.0587 10.9408 20.7996 10.9408C20.5406 10.9408 20.3306 11.1595 20.3306 11.4292V16.5989L20.2334 16.7001C19.9207 17.0257 19.9207 17.5535 20.2334 17.8791C20.5461 18.2047 21.0531 18.2047 21.3659 17.8791C21.6786 17.5535 21.6786 17.0257 21.3659 16.7001L21.2687 16.5989V11.4292Z"
        fill="currentColor"
      />
    </svg>
  );
}
