interface StepConfig {
  name: string;
  isDirty?: boolean;
  isValid?: boolean;
  hasError?: boolean;
}

export default function useStepValidator(steps: StepConfig[]) {
  const validateStep = (currentStep: string) => {
    const stepConfig = steps.find((step) => step.name === currentStep);
    if (!stepConfig) return false;

    return (
      stepConfig.isValid ?? Boolean(stepConfig.isDirty && !stepConfig.hasError)
    );
  };

  const validateCurrentStep = (currentStep: string) => {
    const currentStepIndex = steps.findIndex(
      (step) => step.name === currentStep
    );
    if (currentStepIndex < 0) return false;

    return steps
      .slice(0, currentStepIndex + 1)
      .every((step) => validateStep(step.name));
  };

  const validatePrevStep = (currentStep: string) => {
    const currentStepIndex = steps.findIndex(
      (step) => step.name === currentStep
    );
    if (currentStepIndex <= 0) return true;

    const prevStep = steps[currentStepIndex - 1];
    return validateCurrentStep(prevStep.name);
  };

  const getNextStep = (currentStep: string) => {
    const currentStepIndex = steps.findIndex(
      (step) => step.name === currentStep
    );
    return currentStepIndex >= 0 && currentStepIndex < steps.length - 1
      ? steps[currentStepIndex + 1].name
      : undefined;
  };

  const getPrevStep = (currentStep: string) => {
    const currentStepIndex = steps.findIndex(
      (step) => step.name === currentStep
    );
    return currentStepIndex > 0 ? steps[currentStepIndex - 1].name : undefined;
  };

  return {
    validateStep,
    validateCurrentStep,
    validatePrevStep,
    getNextStep,
    getPrevStep,
  };
}
