import { Box } from '@mui/material';
import { useIaRender } from '@lib/ia/src/core/IaRender/useIaRender';

import {
  DisplayTableLayoutCustomCell,
  DisplayTableLayoutRow,
} from '../../types';

export type IaDisplayTableCustomCellProps = {
  row: DisplayTableLayoutRow;
  cell: DisplayTableLayoutCustomCell;
};

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    pl: 1,
  },
};
export default function IaDisplayTableCustomCell({
  cell,
}: IaDisplayTableCustomCellProps) {
  const { getIaRender } = useIaRender();
  const render = getIaRender(cell.renderKey);
  return <Box sx={styles.root}>{render?.(cell.metadata)}</Box>;
}
