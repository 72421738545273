import { FeedLayoutItem } from '../types';

import Divider from './Divider';
import Message from './Message';
import Skeleton from './Skeleton';

type FeedItemProps = FeedLayoutItem;

export default function FeedItem(props: FeedItemProps) {
  if (props.type === 'message') {
    return <Message {...props} />;
  }
  if (props.type === 'divider') {
    return <Divider {...props} />;
  }
  if (props.type === 'skeleton') {
    return <Skeleton />;
  }
  return null;
}
