import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import { FormLayoutMethodsContext } from '@lib/ia/src/layouts/FormLayout';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { VariableSubType, VariableType } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

import { useCurrentQuestion, useGetAllVariablesFromType } from '../../../hooks';
import { TextComposerPanelKeys, TextComposerPanelParams } from '../../panel';

import {
  combineWithNumberVariableDefaultValue,
  formParamsToApiParams,
  getNumberVariableSettingsBySubType,
  getUniqueVariableName,
} from './utils';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& .ia-form-layout': {
      py: 2,
      px: { xs: 2.5, md: '12px' },
    },
    '& .ia-form-layout_checkbox': {
      px: { xs: 2.5, md: '12px' },
      mx: { xs: -2.5, md: '-12px' },

      '& .MuiCheckbox-root': {
        mr: '-2px',
      },
    },
  },
  form: {
    flex: 1,
  },
  note: {
    px: 2.5,
    py: '12px',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    span: {
      color: 'text.primary',
    },
  },
};

const subTypeOptions = [
  {
    label: 'Integer and Decimal',
    value: VariableSubType.NumberDecimal,
  },
  { label: 'Prime Number', value: VariableSubType.NumberPrime },
  {
    label: 'Factor (Factors of a number)',
    value: VariableSubType.NumberFactor,
  },
  { label: 'Free Input', value: VariableSubType.NumberFree },
];

const useVariableData = ({ variableId }: { variableId?: string }) => {
  const { getRightParams } = useBaseRightPanel<TextComposerPanelParams>();

  const { data, variables } = useGetAllVariablesFromType(VariableType.Number);
  const otherVariableNames = useMemo(
    () =>
      variables?.filter((v) => v.id !== variableId).map((v) => v.name) || [],
    [variables, variableId]
  );

  const { defaultValue } = getRightParams(
    TextComposerPanelKeys.TextComposerVariable
  );

  //  integer and decimal are merged into the same option on Figma
  const checkedSubType =
    defaultValue?.subType === VariableSubType.NumberInteger
      ? VariableSubType.NumberDecimal
      : defaultValue?.subType;

  const placeholderName = useMemo(
    () =>
      getUniqueVariableName(
        `Number${otherVariableNames.length + 1}`,
        otherVariableNames
      ),
    [otherVariableNames]
  );

  return {
    dataIsLoading: !data,
    defaultValue: {
      name: defaultValue?.name,
      subType: checkedSubType,
      ...combineWithNumberVariableDefaultValue({
        subType: defaultValue?.subType,
        currentValue: defaultValue,
      }),
    },
    placeholderName,
    otherVariableNames,
  };
};

type NumberVariableFormProps = {
  variableId?: string;
  createOrUpdateVariable: (
    params: AddCreatorQuestionVariableReq | UpdateCreatorQuestionVariableReq
  ) => void;
};

export default function NumberVariableForm({
  variableId,
  createOrUpdateVariable,
}: NumberVariableFormProps) {
  const { t } = useTranslation('editor');
  const { sectionId } = useCurrentIaClub();
  const { questionId } = useCurrentQuestion();
  const { methods } = useContext(FormLayoutMethodsContext);

  const { dataIsLoading, defaultValue, otherVariableNames, placeholderName } =
    useVariableData({ variableId });

  const { getRightParams, setRightParams } =
    useBaseRightPanel<TextComposerPanelParams>();
  const rightParams = getRightParams(
    TextComposerPanelKeys.TextComposerVariable
  );

  const { variables } = useGetAllVariablesFromType(VariableType.Number);

  const currentVariable =
    variables && defaultValue
      ? variables.find((variable) => variable.id === variableId)
      : undefined;

  const layouts = useMemo(() => {
    const hintLayout = {
      layout: 'hint-layout' as const,
      text: t('These text variables can be used anywhere in this editor'),
    };
    if (dataIsLoading)
      return [
        hintLayout,
        {
          layout: 'list-layout' as const,
          areas: [
            {
              key: 'loading',
              areaType: 'loading' as const,
            },
          ],
        },
      ];

    const disabled = !variableId;

    return [
      hintLayout,
      {
        layout: 'form-layout' as const,
        settings: {
          mode: 'all' as const,
          triggerSubmitMode: 'onChange' as const,
        },
        formAction: {
          type: 'event' as const,
          value: 'submitNumberVariables',
        },
        defaultValues: defaultValue,
        mainItems: [
          {
            type: 'group' as const,
            items: [
              {
                type: 'text' as const,
                label: 'Variable Name',
                name: 'name',
                placeholder: placeholderName,
                helperText: 'Used to help identify different variables',
                actionMap: {
                  change: { value: 'removeSpaces' },
                },
                customRules: {
                  notInArray: {
                    values: otherVariableNames,
                    message: 'Variable Name should be unique',
                  },
                  regex: {
                    value: /^[a-zA-Z0-9]*$/,
                    message: '‘{value}’ is not a valid value',
                  },
                },
              },
              {
                type: 'select' as const,
                label: 'Variable Type',
                name: 'subType',
                placeholder: 'Select Variable Type',
                options: subTypeOptions,
                actionMap: {
                  change: {
                    value: 'changeSubType',
                  },
                },
              },
              ...getNumberVariableSettingsBySubType(
                defaultValue.subType,
                disabled
              ),
            ],
          },
        ],
      },
    ];
  }, [
    dataIsLoading,
    defaultValue,
    otherVariableNames,
    placeholderName,
    t,
    variableId,
  ]);

  const availableActions = {
    submitNumberVariables: {
      action: (
        params: Omit<
          UpdateCreatorQuestionVariableReq,
          'sectionId' | 'creatorQuestionId' | 'type'
        >
      ) => {
        if (!variableId) return;

        const formParams = {
          name: params.name || placeholderName,
          subType: params.subType,
          ...combineWithNumberVariableDefaultValue({
            subType: params.subType,
            currentValue: params,
          }),
        };

        createOrUpdateVariable({
          id: variableId,
          ...formParamsToApiParams(formParams),
        });
      },
    },
    changeSubType: {
      action: ({ value }: { name: string; value: VariableSubType }) => {
        if (!sectionId) return;

        setRightParams({
          ...rightParams,
          subType: value,
          isNewCreated: false,
          defaultValue: {
            ...rightParams.defaultValue,
            subType: value,
          },
        });

        if (!variableId) {
          const formParams = {
            name: methods.getValues('name') || placeholderName,
            subType: value,
            ...combineWithNumberVariableDefaultValue({
              subType: value,
              currentValue: {},
            }),
          };

          createOrUpdateVariable({
            sectionId,
            type: VariableType.Number,
            creatorQuestionId: questionId,
            ...formParamsToApiParams(formParams),
          });
        }
      },
    },
    removeSpaces: {
      action: ({ value }: { value: string }) => {
        if (/\s/g.test(value)) return value.replace(/\s/g, '');

        return undefined;
      },
    },
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.form}>
        <IaActionContextProvider availableActions={availableActions}>
          <IaLayouts layouts={layouts} />
        </IaActionContextProvider>
      </Box>

      <Typography sx={styles.note}>
        {t('Total possibilities that can be generated based on specifications')}
        : <span>{currentVariable?.possibilities || 0}</span>
      </Typography>
    </Box>
  );
}
