import React from 'react';

export default function OtherLatestListed({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.0002 18H22V16H14.0002V18ZM14 6V8H22V6H14ZM14.0001 13H22V11H14.0001V13Z"
        fill="currentColor"
      />
      <path
        d="M6.39603 5.22102C6.74048 4.92633 7.25952 4.92633 7.60397 5.22102L11.6949 8.72102C12.0701 9.04207 12.1039 9.59513 11.7704 9.95632C11.4368 10.3175 10.8622 10.35 10.4869 10.029L7.90909 7.82349V18.125C7.90909 18.6082 7.50208 19 7 19C6.49792 19 6.09091 18.6082 6.09091 18.125V7.82349L3.51306 10.029C3.13781 10.35 2.5632 10.3175 2.22964 9.95632C1.89608 9.59513 1.92988 9.04207 2.30513 8.72102L6.39603 5.22102Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
