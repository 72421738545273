import React, { PropsWithChildren, useRef } from 'react';

import TableLayoutActionsContext, {
  TableLayoutActions,
} from './TableLayoutActionsContext';

const TableLayoutActionsProvider = ({ children }: PropsWithChildren) => {
  const actionsRef = useRef<TableLayoutActions>();

  return (
    <TableLayoutActionsContext.Provider value={{ actionsRef }}>
      {children}
    </TableLayoutActionsContext.Provider>
  );
};

export default TableLayoutActionsProvider;
