import React from 'react';

export default function CursorText({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.4509 11.0218H12.9509V6.50348C13.1257 6.1211 13.382 5.78102 13.7025 5.5074C13.9063 5.3603 14.1546 5.22649 14.4201 5.1272C14.7001 5.07985 15.0493 5.05799 15.3982 5.07988L15.8972 5.11121L15.9286 4.61219L15.9912 3.61415L16.0225 3.11513L15.5235 3.08381C14.9884 3.05022 14.4512 3.08631 13.9253 3.19115L13.8916 3.19788L13.8591 3.20916C13.3217 3.39578 12.8798 3.63034 12.4775 3.92692L12.4646 3.93643L12.4523 3.94675C12.2774 4.09393 12.1143 4.25049 11.9637 4.41572C11.8306 4.26118 11.6884 4.11416 11.5375 3.97551L11.5211 3.96048L11.5035 3.94696C11.0603 3.60701 10.5999 3.36914 10.1064 3.2165L10.083 3.20928L10.0591 3.20437C9.4803 3.08565 8.93801 3.049 8.39651 3.08392L7.89754 3.1161L7.92972 3.61506L7.99408 4.61299L8.02626 5.11195L8.52523 5.07977C8.88035 5.05687 9.2369 5.07941 9.58624 5.14682C9.79055 5.21557 10.0241 5.33535 10.2363 5.49334C10.5265 5.76718 10.7828 6.12859 10.9609 6.53427V11.0218H10.4609H9.96087V11.5218V12.5018V13.0018H10.4609H10.9609V16.5292C10.7832 16.933 10.5254 17.2968 10.2027 17.5984C10.0258 17.7274 9.79317 17.8477 9.54183 17.9282C9.23871 17.9842 8.88223 18.0071 8.52573 17.984L8.02679 17.9515L7.99435 18.4505L7.92948 19.4484L7.89705 19.9473L8.396 19.9797C8.93559 20.0148 9.47746 19.9787 10.0076 19.8723L10.0311 19.8676L10.0541 19.8606C10.593 19.6975 11.0552 19.4609 11.4669 19.1473L11.4848 19.1337L11.5014 19.1185C11.6686 18.9655 11.8241 18.8054 11.9678 18.6384C12.1145 18.7986 12.2722 18.9494 12.4399 19.0895L12.4509 19.0986L12.4623 19.107C12.884 19.4203 13.325 19.656 13.7947 19.8236L13.8252 19.8345L13.8569 19.8414C14.4449 19.9688 14.9802 20.0097 15.5156 19.9803L16.0149 19.953L15.9875 19.4537L15.9327 18.4552L15.9053 17.956L15.4061 17.9833C15.0601 18.0023 14.7131 17.9779 14.3733 17.9107C14.1656 17.8284 13.9168 17.6946 13.6879 17.527C13.3861 17.2712 13.1285 16.9331 12.9509 16.5487V13.0018H13.4509H13.9509V12.5018V11.5218V11.0218H13.4509Z"
        fill="#080808"
        stroke="white"
      />
    </svg>
  );
}
