import { useMemo } from 'react';
import { useAuth, useClubGroupUnreadNotificationCount } from '@lib/web/apis';
import { uniqBy } from 'lodash';

const isGlobalNotification = (club: ClubUnreadNotification) =>
  club.name === 'Global';

export default function useNotifications(clubSlug?: string) {
  const { data, mutate } = useClubGroupUnreadNotificationCount();
  const { member } = useAuth();

  const clubGroupNotifications = useMemo(
    () => data?.data.clubGroups || [],
    [data?.data.clubGroups]
  );
  const clubNotifications = useMemo(
    () => data?.data.clubs.filter((club) => !isGlobalNotification(club)) || [],
    [data?.data.clubs]
  );
  const allClubNotifications = useMemo(() => {
    const clubGroupClubNotifications = clubGroupNotifications
      .map((group) => group.clubs)
      .flat();
    return uniqBy(
      [...clubGroupClubNotifications, ...clubNotifications],
      (clubNotification) => clubNotification.slug
    );
  }, [clubGroupNotifications, clubNotifications]);

  const globalNotificationCount = useMemo(
    () => data?.data.clubs.find(isGlobalNotification)?.count ?? 0,
    [data?.data.clubs]
  );

  const clubNotificationCount = useMemo(
    () =>
      allClubNotifications.find(
        (club) => club.slug === clubSlug && club.isSubscribed
      )?.count ?? 0,
    [allClubNotifications, clubSlug]
  );

  const hasNotifications = useMemo(
    () =>
      member?.emailVerified === false ||
      globalNotificationCount > 0 ||
      allClubNotifications.some((club) => club.isSubscribed && club.count > 0),
    [allClubNotifications, globalNotificationCount, member?.emailVerified]
  );

  return {
    clubGroupNotifications,
    clubNotifications,
    allClubNotifications,
    globalNotificationCount,
    clubNotificationCount,
    hasNotifications,
    mutate,
  };
}
