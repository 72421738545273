import React from 'react';

export default function OtherCoinbaseGrey({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2 6.22535C2 3.89175 3.89175 2 6.22535 2H17.7746C20.1082 2 22 3.89175 22 6.22535V17.7746C22 20.1082 20.1082 22 17.7746 22H6.22535C3.89175 22 2 20.1082 2 17.7746V6.22535Z"
        fill="#0052FF"
      />
      <path
        d="M19.1365 12.0238C19.1365 15.952 15.952 19.1365 12.0238 19.1365C8.09559 19.1365 4.91113 15.952 4.91113 12.0238C4.91113 8.09559 8.09559 4.91113 12.0238 4.91113C15.952 4.91113 19.1365 8.09559 19.1365 12.0238Z"
        fill="white"
      />
      <path
        d="M9.69922 10.1687C9.69922 9.90941 9.90941 9.69922 10.1687 9.69922H13.8307C14.09 9.69922 14.3002 9.90941 14.3002 10.1687V13.8307C14.3002 14.09 14.09 14.3002 13.8307 14.3002H10.1687C9.90941 14.3002 9.69922 14.09 9.69922 13.8307V10.1687Z"
        fill="#0052FF"
      />
      <rect
        height="20"
        style={{ mixBlendMode: 'saturation' }}
        width="20"
        fill="black"
        x="2"
        y="2"
      />
    </svg>
  );
}
