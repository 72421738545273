import { useContext, useMemo } from 'react';
import { useAuth } from '@lib/web/apis';

import { SharedUserContext } from '../contexts';

export default function useCurrentUser() {
  const { member } = useAuth();
  const sharerData = useContext(SharedUserContext);

  return useMemo(
    () =>
      !member && !sharerData
        ? undefined
        : {
            userId: sharerData.userId || member?.userId,
            name:
              sharerData.name ||
              member?.displayName ||
              member?.distinctName ||
              '',
            avatar: sharerData.avatar || member?.avatarUrl || member?.photoUrl,
            username: sharerData?.username || member?.distinctName || '',
            indicator: sharerData?.indicator || member?.indicator,
          },
    [member, sharerData]
  );
}
