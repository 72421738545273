import { useCallback, useMemo } from 'react';
import { buildHookMutate, useUserProfileSocial } from '@lib/web/apis';

import { call } from '../utils';

import useClubSlug from './useClubSlug';

type Options = {
  userId: string;
  initData: {
    isFollowing: boolean;
    isFollower: boolean;
  };
  disabled?: boolean;
};

export default function useFollowStatus({
  userId,
  initData,
  disabled,
}: Options) {
  const clubSlug = useClubSlug();
  const { data, mutate } = useUserProfileSocial(userId, {
    clubSlug,
    isGetLeagueInfo: true,
    disabled,
  });
  const socialData = data?.data;

  const reloadSocialData = useMemo(() => buildHookMutate(mutate), [mutate]);

  const reloadFollowStatus = useCallback(
    (promise: Promise<any>) => {
      if (!socialData) return;
      call(
        reloadSocialData(promise, {
          optimisticData: {
            ...socialData,
            isFollowing: !socialData.isFollowing,
          },
        })
      );
    },
    [reloadSocialData, socialData]
  );

  if (!socialData) {
    return {
      status: initData,
      reloadFollowStatus,
    };
  }

  return {
    status: {
      isFollowing: socialData.isFollowing,
      isFollower: socialData.isFollower,
    },
    reloadFollowStatus,
  };
}
