import generateHook from './swr.helper';

export const useExams = () =>
  generateHook<GetExamRes>('v2/exam', {
    paginate: true,
  });

export const useExamSectionTags = (sectionExamId: NullableString) =>
  generateHook<GetExamSectionTagsRes>(
    sectionExamId ? `v2/exams/sections/${sectionExamId}/tags/me` : null,
    {
      auth: true,
      revalidateOnFocus: false,
      paginate: true,
    }
  );

export const useExamBySection = (sectionExamId: NullableString) =>
  generateHook<GetExamSectionsRes>(
    sectionExamId ? `v2/exam/section?sectionExamId=${sectionExamId}` : null,
    {
      immutable: false,
      errorRetryCount: 0,
    }
  );
