import useSWR from 'swr';

import { client } from '../../index';

import generateHook from './swr.helper';

export const useBackendVersionInfo = () =>
  generateHook<BackendVersionInfo>('info', {
    immutable: true,
  });

export const useFrontendVersionInfo = () =>
  useSWR<{ data: FrontendVersionInfo | null }>('/api/info', {
    fetcher: async (url) => {
      try {
        return {
          data: (await client.get(url, { baseURL: '' })).data,
        };
      } catch {
        return { data: null };
      }
    },
  });

export const useLocalesInfo = () => {
  return generateHook<LocaleInfo[]>('/v2/locale', {
    immutable: true,
    auth: true,
  });
};
