import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import { SupportedActions } from '@lib/web/thread/ThreadComposer/components/Actions';

import {
  AiActionState,
  useAiAction,
} from '../ThreadComposer/hooks/useAiAction';
import {
  LatexActionState,
  useLatexAction,
} from '../ThreadComposer/hooks/useLatexAction';

export type ThreadComposerContextValue = {
  text: string;
  setText: (newText: string) => void;
  selectedAction: SupportedActions;
  setSelectedAction: (action: SupportedActions) => void;
  aiActionState: AiActionState;
  latexActionState: LatexActionState;
  actionAiEnable: boolean;
  sendPublicly: boolean;
  setSendPublicly: Dispatch<SetStateAction<boolean>>;
  sendPubliclyCheckboxEnable: boolean;
  sendPubliclyDisabled: boolean;
  threadMemberIds?: string[];
};

export const ThreadComposerContext = createContext<ThreadComposerContextValue>(
  null as any
);

type ThreadComposerProviderProps = {
  children: ReactNode | ((props: ThreadComposerContextValue) => ReactNode);
  text: string;
  setText: (newText: string) => void;
  threadMemberIds?: string[];
  defaultAction?: SupportedActions;
  actionChangeable?: boolean;
  actionAiEnable?: boolean;
  sendPubliclyCheckboxEnable?: boolean;
  sendPubliclyDefault?: boolean;
  sendPubliclyDisabled?: boolean;
};

export function ThreadComposerProvider({
  children,
  text,
  setText,
  threadMemberIds,
  defaultAction,
  actionChangeable = true,
  actionAiEnable = true,
  sendPubliclyCheckboxEnable = true,
  sendPubliclyDefault = false,
  sendPubliclyDisabled = false,
}: ThreadComposerProviderProps) {
  const [sendPublicly, setSendPublicly] = useState(sendPubliclyDefault);

  const [rawSelectedAction, setRawSelectedAction] =
    useState<SupportedActions>(defaultAction);

  const selectedAction = actionChangeable ? rawSelectedAction : defaultAction;
  const aiActionState = useAiAction({ enable: selectedAction === 'ai' });
  const latexActionState = useLatexAction({
    setSelectedAction: setRawSelectedAction,
  });

  const contextValue = {
    text,
    setText,
    threadMemberIds,
    aiActionState,
    latexActionState,
    selectedAction,
    setSelectedAction: setRawSelectedAction,
    sendPublicly,
    setSendPublicly,
    actionAiEnable,
    sendPubliclyCheckboxEnable,
    sendPubliclyDisabled,
  };

  return (
    <ThreadComposerContext.Provider value={contextValue}>
      {typeof children === 'function' ? children(contextValue) : children}
    </ThreadComposerContext.Provider>
  );
}
