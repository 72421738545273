import React from 'react';

export default function EditorYAxis({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.0905 10.974C12.8659 9.67532 13.6241 8.35931 14.3651 7.02597C15.1061 5.69264 15.7781 4.35065 16.3813 3H20C19.035 4.8355 18.0269 6.66234 16.9758 8.48052C15.9418 10.2814 14.8304 12.0996 13.6414 13.9351V21H10.3845V13.987C9.19548 12.1342 8.07539 10.2987 7.02423 8.48052C5.97307 6.66234 4.965 4.8355 4 3H7.82552C8.42865 4.35065 9.09208 5.69264 9.81583 7.02597C10.5396 8.35931 11.2978 9.67532 12.0905 10.974Z"
        fill="currentColor"
      />
    </svg>
  );
}
