import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab';

const styles = {
  defaultTab: {
    p: 0,
    minWidth: 'unset',
    opacity: 0.5,
    transitionDuration: '0.2s',
    fontWeight: 400,
    '&.Mui-selected': {
      opacity: 1,
      fontWeight: 500,
    },
    '@media (hover: hover)': {
      '&:not(:disabled):not(.Mui-selected):hover': {
        opacity: 1,
      },
    },
  },
  buttonTab: {
    p: 0,
    px: '14px',
    minHeight: 34,
    minWidth: 48,
    borderRadius: '6px',
    opacity: 0.5,
    transitionDuration: '0.2s',
    '&.Mui-selected': {
      opacity: 1,
      backgroundColor: 'text.primary',
      color: 'background.default',
    },
    '@media (hover: hover)': {
      '&:not(:disabled):not(.Mui-selected):hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  iconLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
};

export type TabProps = MuiTabProps & {
  variant?: 'default' | 'button';
  suffixIcon?: ReactNode;
  prefixIcon?: ReactNode;
};

export default function Tab({
  label,
  sx,
  variant = 'default',
  prefixIcon,
  suffixIcon,
  ...rest
}: TabProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  if (prefixIcon || suffixIcon) {
    return (
      <MuiTab
        sx={[
          variant === 'default' ? styles.defaultTab : styles.buttonTab,
          ...sxProps,
        ]}
        label={
          <Box sx={styles.iconLabel}>
            {!!prefixIcon && prefixIcon}
            {label}
            {!!suffixIcon && suffixIcon}
          </Box>
        }
        {...rest}
      />
    );
  }

  return (
    <MuiTab
      sx={[
        variant === 'default' ? styles.defaultTab : styles.buttonTab,
        ...sxProps,
      ]}
      label={label}
      {...rest}
    />
  );
}
