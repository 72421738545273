import { alpha, Theme } from '@mui/material/styles';

/**
 * We're not using this styles in Subtitle because Subtitle is not react component
 * so we'll add this style into TextComposer
 */

const node = '[data-node-type="block-outer"]';
const content = (type: string) => `.block-content[data-content-type="${type}"]`;

const oneLineStyle = {
  mt: '0 !important',
  mb: '0 !important',
};

export const composerSubtitleBlockStyle = (typography = 'body1') => ({
  // placeholder
  [`${content('subtitle')}[class*=isEmpty] p:before`]: {
    content: '"Empty subtitle"',
    position: 'absolute',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
  [`.ProseMirror-focused ${content(
    'subtitle'
  )}[class*=isEmpty][class*=hasAnchor] p:before`]: {
    content: '"Write here or press ‘/’ for commands..."',
  },

  // container
  [`${content('subtitle')}`]: {
    mt: 0,
    mb: 1,
    position: 'relative',

    // indicator
    [`&:before`]: {
      content: '""',
      width: '4px',
      background: (theme: Theme) => theme.palette.text.primary,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
    },

    // text
    [`& p`]: {
      typography,
      color: 'text.primary',
      ml: '12px', // 4px indicator width + 8px padding
      my: 0,
    },
  },

  // if there is anything before subtitle, add basic margin top
  [`${node} + ${node}:has(${content('subtitle')})`]: {
    mt: 1,
  },

  // case of paragraph before subtitle
  [`${node}:has(${content('paragraph')}) +
  ${node}:has(${content('subtitle')})`]: {
    mt: 3,
  },
});

const htmlContent = (type: string) =>
  `[data-content-type="${type}"]:not(.block-content)`;

export const generatedHtmlSubtitleBlockStyle = ({
  typography = 'body1',
  oneLineTypography = 'body2',
}: {
  typography?: string;
  oneLineTypography?: string;
} = {}) => ({
  // container
  [`${htmlContent('subtitle')}`]: {
    mt: 0,
    mb: 1,
    position: 'relative',

    '.render-one-line&': oneLineStyle,

    // indicator
    [`&:before`]: {
      content: '""',
      width: '4px',
      background: (theme: Theme) => theme.palette.text.primary,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
    },

    // text
    [`& p`]: {
      typography: (theme: Theme) =>
        theme.palette.mode === 'dark' ? typography : 'examBody',

      '.render-one-line&': {
        typography: (theme: Theme) =>
          theme.palette.mode === 'dark' ? oneLineTypography : 'examBody',
      },

      color: 'text.primary',
      // XXX: add !important here, otherwise it will be overwritten by stream-chat css
      ml: '12px !important', // 4px indicator width + 8px padding
      my: 0,
    },
  },

  // if there is anything before subtitle, add basic margin top
  [`* + ${htmlContent('subtitle')}`]: {
    mt: 1,

    '.render-one-line&': oneLineStyle,
  },

  // case of paragraph before subtitle
  [`${htmlContent('paragraph')} + ${htmlContent('subtitle')}`]: {
    mt: 3,

    '.render-one-line&': oneLineStyle,
  },

  [`.render-one-line ${htmlContent('subtitle')} `]: oneLineStyle,
});
