import { InlineLineMarker as EditorInlineLineMarker } from '@lib/web/editor/EditorTextComposer/components/blocks/InlineLineMarker';
import { mergeAttributes } from '@tiptap/core';

export const InlineLineMarker = EditorInlineLineMarker.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes({
        class: this.name,
        'data-content-type': this.name,
        'data-render-ui': 'inlineLineMarkerBlock',
        'data-metadata-target-id': HTMLAttributes.targetId,
        'data-metadata-display-text': HTMLAttributes.displayText,
        'data-metadata-display-line-text': HTMLAttributes.displayLineText,
      }),
    ];
  },
});
