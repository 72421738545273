import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Theme, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { TestAdd as TestAddIcon } from '@front/icon';

const styles = {
  root: (theme: Theme) => ({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    justifyContent: 'flex-start',
    color: alpha(theme.palette.text.primary, 0.5),
    // dashed border by https://kovart.github.io/dashed-border-generator/
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(255,255,255,0.5)' stroke-width='2' stroke-dasharray='4%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  }),
  innerContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 56,
    paddingTop: '12px',
    paddingBottom: '12px',
    typography: 'body1',
  },
  hover: (theme: Theme) => ({
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.text.primary,
        // dashed border by https://kovart.github.io/dashed-border-generator/
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(255,255,255,1)' stroke-width='2' stroke-dasharray='4%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      },
    },
  }),
};

export type NewChoiceProps = {
  onClick: () => void;
};

export default function NewChoice({ onClick }: NewChoiceProps) {
  const { t } = useTranslation('editor');
  return (
    <ButtonBase
      disableRipple
      sx={[styles.root, styles.hover]}
      onClick={onClick}
    >
      <Box sx={styles.innerContent}>
        <TestAddIcon width={16} height={16} />
        <Typography>{t('Add new answer option')}</Typography>
      </Box>
    </ButtonBase>
  );
}
