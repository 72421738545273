import React from 'react';
import { Typography } from '@mui/material';
import { BlockSchema, DefaultBlockSchema } from '@blocknote/core';
import { SideMenuProps } from '@blocknote/react/src/SideMenu/components/SideMenuPositioner';
import { TestAdd as TestAddIcon } from '@front/icon';
import { FloatingMenu } from '@front/ui';

const styles = {
  tooltipInner: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default function AddBlockButton<
  BSchema extends BlockSchema = DefaultBlockSchema
>({ addBlock }: SideMenuProps<BSchema>) {
  return (
    <FloatingMenu.TipButton
      className="composer-side-menu-button"
      title={
        <Typography sx={styles.tooltipInner} variant="caption">
          <span>
            <b>Click</b> to add below
          </span>
          <span>
            <b>Option-click</b> to add above
          </span>
        </Typography>
      }
      onClick={addBlock}
    >
      <TestAddIcon />
    </FloatingMenu.TipButton>
  );
}
