import React from 'react';
import { v4 } from 'uuid';

export default function Award251Locked({ ...rest }) {
  const id = v4();
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#clip0_${id})`}>
        <path
          d="M57.6428 30.5827C56.2762 29.1407 54.0586 29.1388 52.6898 30.5785L36.3619 47.7517L31.3118 42.4324C29.944 40.9916 27.7265 40.9918 26.3589 42.4328C24.9912 43.8737 24.9914 46.2098 26.3592 47.6506L33.3734 55.0387C33.5772 55.2533 33.7998 55.4359 34.0356 55.5866C35.4076 56.9421 37.5633 56.9153 38.9044 55.5048L57.6388 35.8005C59.0076 34.3608 59.0094 32.0248 57.6428 30.5827Z"
          fill="#565656"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54 11.9592L71.0718 21.8062C72.9092 22.866 74.041 24.8246 74.041 26.9442V57.0562C74.041 59.1757 72.9092 61.1343 71.0718 62.1941L44.9692 77.2501C43.1319 78.3099 40.8681 78.3099 39.0308 77.2501L12.9282 62.1941C11.0908 61.1343 9.95897 59.1757 9.95897 57.0562V26.9442C9.95897 24.8246 11.0908 22.866 12.9282 21.8062L30 11.9592V7.39209L10.9487 18.3809C7.88644 20.1472 6 23.4115 6 26.9442V57.0562C6 60.5888 7.88644 63.8531 10.9487 65.6194L37.0513 80.6754C40.1136 82.4417 43.8864 82.4417 46.9487 80.6754L73.0513 65.6194C76.1136 63.8531 78 60.5888 78 57.0562V26.9442C78 23.4115 76.1136 20.1472 73.0513 18.3809L54 7.39209V11.9592Z"
          fill="#565656"
        />
        <mask
          id={`path-3-outside-1_${id}`}
          maskUnits="userSpaceOnUse"
          x="31"
          y="56"
          width="18"
          height="27"
          fill="black"
        >
          <rect fill="white" x="31" y="56" width="18" height="27" />
          <path d="M35.5746 63.53C36.1546 63.29 36.7546 63.02 37.3746 62.72C38.0146 62.4 38.6346 62.06 39.2346 61.7C39.8346 61.32 40.4046 60.93 40.9446 60.53C41.5046 60.11 42.0046 59.67 42.4446 59.21H45.5646V80H41.0946V65.03C40.4946 65.43 39.8246 65.8 39.0846 66.14C38.3446 66.46 37.6246 66.74 36.9246 66.98L35.5746 63.53Z" />
        </mask>
        <path
          d="M35.5746 63.53C36.1546 63.29 36.7546 63.02 37.3746 62.72C38.0146 62.4 38.6346 62.06 39.2346 61.7C39.8346 61.32 40.4046 60.93 40.9446 60.53C41.5046 60.11 42.0046 59.67 42.4446 59.21H45.5646V80H41.0946V65.03C40.4946 65.43 39.8246 65.8 39.0846 66.14C38.3446 66.46 37.6246 66.74 36.9246 66.98L35.5746 63.53Z"
          fill="#565656"
        />
        <path
          d="M35.5746 63.53L34.4276 60.7579L31.7086 61.883L32.7809 64.6232L35.5746 63.53ZM37.3746 62.72L38.6813 65.4205L38.6988 65.412L38.7162 65.4033L37.3746 62.72ZM39.2346 61.7L40.7781 64.2725L40.8092 64.2538L40.8398 64.2345L39.2346 61.7ZM40.9446 60.53L42.7303 62.9407L42.7375 62.9354L42.7446 62.93L40.9446 60.53ZM42.4446 59.21V56.21H41.1627L40.2767 57.1363L42.4446 59.21ZM45.5646 59.21H48.5646V56.21H45.5646V59.21ZM45.5646 80V83H48.5646V80H45.5646ZM41.0946 80H38.0946V83H41.0946V80ZM41.0946 65.03H44.0946V59.4245L39.4305 62.5338L41.0946 65.03ZM39.0846 66.14L40.2753 68.8936L40.3064 68.8801L40.3371 68.866L39.0846 66.14ZM36.9246 66.98L34.1309 68.0732L35.1784 70.7501L37.8976 69.8178L36.9246 66.98ZM36.7217 66.302C37.3604 66.0377 38.0138 65.7435 38.6813 65.4205L36.0679 60.0195C35.4954 60.2965 34.9488 60.5423 34.4276 60.7579L36.7217 66.302ZM38.7162 65.4033C39.4234 65.0497 40.1108 64.6729 40.7781 64.2725L37.6911 59.1275C37.1584 59.4471 36.6058 59.7503 36.033 60.0367L38.7162 65.4033ZM40.8398 64.2345C41.498 63.8176 42.1284 63.3865 42.7303 62.9407L39.1589 58.1193C38.6808 58.4735 38.1713 58.8224 37.6295 59.1655L40.8398 64.2345ZM42.7446 62.93C43.4231 62.4211 44.0485 61.8734 44.6125 61.2837L40.2767 57.1363C39.9608 57.4666 39.5861 57.7989 39.1446 58.13L42.7446 62.93ZM42.4446 62.21H45.5646V56.21H42.4446V62.21ZM42.5646 59.21V80H48.5646V59.21H42.5646ZM45.5646 77H41.0946V83H45.5646V77ZM44.0946 80V65.03H38.0946V80H44.0946ZM39.4305 62.5338C38.977 62.8362 38.4471 63.1314 37.8321 63.414L40.3371 68.866C41.2021 68.4686 42.0123 68.0238 42.7587 67.5262L39.4305 62.5338ZM37.8939 63.3864C37.2191 63.6782 36.5718 63.9295 35.9516 64.1422L37.8976 69.8178C38.6774 69.5505 39.4701 69.2418 40.2753 68.8936L37.8939 63.3864ZM39.7183 65.8868L38.3683 62.4368L32.7809 64.6232L34.1309 68.0732L39.7183 65.8868Z"
          fill="#2F2F2F"
          mask={`url(#path-3-outside-1_${id})`}
        />
        <path
          d="M45.9555 6.22054L49.8456 6.80534C50.9487 6.9711 51.3907 8.38449 50.5937 9.19685L47.7824 12.0618C47.4658 12.3845 47.3218 12.8489 47.397 13.3037L48.066 17.3436C48.2557 18.4891 47.1038 19.3643 46.1166 18.8247L42.6356 16.9214C42.2436 16.7071 41.7756 16.7075 41.3841 16.9229L37.9074 18.835C36.9216 19.3772 35.7675 18.5047 35.9546 17.3588L36.6143 13.3172C36.6885 12.8621 36.5435 12.398 36.2261 12.0761L33.4087 9.21815C32.6097 8.40769 33.0484 6.99324 34.1513 6.82474L38.0401 6.23045C38.478 6.1636 38.8563 5.87615 39.0516 5.46196L40.7869 1.78338C41.279 0.740315 42.7042 0.738417 43.1985 1.78043L44.9421 5.45458C45.1384 5.86814 45.5173 6.15474 45.9555 6.22054Z"
          fill="#565656"
        />
      </g>
      <defs>
        <clipPath id={`clip0_${id}`}>
          <rect width="84" height="84" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
