import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { alpha, Box, BoxProps, Theme } from '@mui/material';
import {
  ActionChevronLeft as ActionChevronLeftIcon,
  ActionChevronRight as ActionChevronRightIcon,
} from '@front/icon';
import { useDrag } from '@use-gesture/react';

const styles = {
  carousel: {
    overflow: 'hidden',
  },
  container: {
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 2,
  },
  items: {
    transition: 'transform 0.4s',
    display: 'grid',
    alignItems: 'center',
  },
  item: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50px',
  },
  navigation: {
    position: 'absolute',
    height: '100%',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    width: { xs: 48, md: 72 },
    cursor: 'pointer',
    '& svg': (theme: Theme) => ({
      [theme.breakpoints.down('md')]: {
        width: 16,
        height: 16,
      },
    }),
  },
  navigationLeft: {
    left: 0,
    background:
      'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
    pl: { xs: 0.5, md: 1.5 },
  },
  navigationRight: {
    right: 0,
    background:
      'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
    justifyContent: 'flex-end',
    pr: { xs: 0.5, md: 1.5 },
  },
  dots: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    py: 1,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    cursor: 'pointer',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  dotActive: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
  },
};

const getColumnTemplate = (totalItems: number) => {
  return { gridTemplateColumns: `repeat(${totalItems}, 100%)` };
};

const getTransform = (activeIndex: number) => {
  return { transform: `translateX(-${activeIndex * 100}%)` };
};

type NavigationProps = {
  icon: ReactNode;
  direction: 'left' | 'right';
  onClick?: () => void;
};

function Navigation({ icon, direction, onClick }: NavigationProps) {
  return (
    <Box
      sx={[
        styles.navigation,
        direction === 'left' ? styles.navigationLeft : styles.navigationRight,
      ]}
      onClick={onClick}
    >
      {icon}
    </Box>
  );
}

export type SimpleCarouselProps = BoxProps & {
  autoplay?: boolean;
  autoplaySpeed?: number;
  hideDots?: boolean;
};

export default function SimpleCarousel({
  autoplay = true,
  autoplaySpeed = 3000,
  hideDots = false,
  sx,
  children,
  ...rest
}: SimpleCarouselProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const totalItems = React.Children.count(children);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAutoplay, setIsAutoplay] = useState(autoplay);
  const intervalRef = useRef<NodeJS.Timeout>();

  const setAutoplay = useCallback(() => {
    if (!isAutoplay) {
      return;
    }
    intervalRef.current = setInterval(() => {
      setActiveIndex((prev) => {
        if (prev < totalItems - 1) {
          return prev + 1;
        }
        return 0;
      });
    }, autoplaySpeed);
  }, [isAutoplay, autoplaySpeed, totalItems]);

  const resetAutoplay = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    setAutoplay();
    return () => {
      resetAutoplay();
    };
  }, [setAutoplay]);

  const toLeft = () => {
    resetAutoplay();
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    } else {
      setActiveIndex(totalItems - 1);
    }
    setAutoplay();
  };

  const toRight = () => {
    resetAutoplay();
    if (activeIndex < totalItems - 1) {
      setActiveIndex(activeIndex + 1);
    } else {
      setActiveIndex(0);
    }
    setAutoplay();
  };

  const handleDotClick = (index: number) => {
    resetAutoplay();
    setActiveIndex(index);
    setAutoplay();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bind: any = useDrag(({ down, movement: [mx] }) => {
    setIsAutoplay(!down);

    if (!down && mx < 0) {
      toRight();
    } else if (!down && mx > 0) {
      toLeft();
    }
  });

  return (
    <Box sx={[styles.carousel, ...sxProps]} {...bind()} {...rest}>
      <Box sx={styles.container}>
        <Box
          sx={[
            styles.items,
            getColumnTemplate(totalItems),
            getTransform(activeIndex),
          ]}
        >
          {React.Children.map(children, (child, index) => (
            <Box key={index} sx={styles.item}>
              {child}
            </Box>
          ))}
        </Box>
        <Navigation
          direction="left"
          icon={<ActionChevronLeftIcon />}
          onClick={toLeft}
        />
        <Navigation
          direction="right"
          icon={<ActionChevronRightIcon />}
          onClick={toRight}
        />
      </Box>
      {!hideDots && (
        <Box sx={styles.dots}>
          {React.Children.map(children, (child, index) => (
            <Box
              sx={[styles.dot, activeIndex === index && styles.dotActive]}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
