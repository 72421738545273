import React from 'react';

export default function TestChemistry({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.9805 20.448C12.3717 20.7904 11.6283 20.7904 11.0195 20.448L5.01949 17.073C4.38973 16.7188 4 16.0524 4 15.3299L4 8.66943C4 7.94688 4.38973 7.28051 5.01949 6.92628L11.0195 3.55133C11.6283 3.20887 12.3717 3.20888 12.9805 3.55133L18.9805 6.92628C19.6103 7.28051 20 7.94688 20 8.66944L20 15.3299C20 16.0524 19.6103 16.7188 18.9805 17.073L12.9805 20.448Z"
        fill="currentColor"
      />
      <line
        stroke="#080808"
        strokeLinecap="round"
        x1="17.6763"
        x2="17.6763"
        y1="8.90039"
        y2="15.1003"
      />
      <line
        stroke="#080808"
        strokeLinecap="round"
        transform="matrix(-0.875445 -0.483318 0.51687 -0.856064 12.6719 18.0864)"
        x1="0.5"
        x2="6.9484"
        y1="-0.5"
        y2="-0.5"
      />
      <line
        stroke="#080808"
        strokeLinecap="round"
        transform="matrix(0.875445 -0.483318 0.51687 0.856064 6.15137 9.51318)"
        x1="0.5"
        x2="6.9484"
        y1="-0.5"
        y2="-0.5"
      />
    </svg>
  );
}
