import React from 'react';

export default function EditorFreeResponse({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#F6C427"
        fillRule="evenodd"
      />
      <path
        d="M3.45068 9.2823C3.45068 9.03214 3.65348 8.82935 3.90364 8.82935H14.3215C14.5716 8.82935 14.7744 9.03214 14.7744 9.2823V11.0941C14.7744 11.3443 14.5716 11.5471 14.3215 11.5471H3.90364C3.65348 11.5471 3.45068 11.3443 3.45068 11.0941V9.2823Z"
        fill="#080808"
      />
      <path
        d="M9.22568 12.9058C9.22568 12.6557 9.42848 12.4529 9.67864 12.4529H20.0965C20.3466 12.4529 20.5494 12.6557 20.5494 12.9058V14.7177C20.5494 14.9678 20.3466 15.1706 20.0965 15.1706H9.67864C9.42848 15.1706 9.22568 14.9678 9.22568 14.7177V12.9058Z"
        fill="#080808"
      />
      <path
        d="M16.1333 9.28235C16.1333 9.03219 16.3361 8.8294 16.5863 8.8294H20.0966C20.3468 8.8294 20.5496 9.03219 20.5496 9.28235V11.0942C20.5496 11.3443 20.3468 11.5471 20.0966 11.5471H16.5863C16.3361 11.5471 16.1333 11.3443 16.1333 11.0942V9.28235Z"
        fill="#080808"
      />
      <path
        d="M3.45068 12.9058C3.45068 12.6557 3.65348 12.4529 3.90364 12.4529H7.41398C7.66414 12.4529 7.86693 12.6557 7.86693 12.9058V14.7177C7.86693 14.9678 7.66414 15.1706 7.41398 15.1706H3.90364C3.65348 15.1706 3.45068 14.9678 3.45068 14.7177V12.9058Z"
        fill="#080808"
      />
    </svg>
  );
}
