import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@front/ui';
import { AnswerFormatType, apis } from '@lib/web/apis';
import { callWithToast } from '@lib/web/utils';

import { useQueryClubCompatible } from './useQueryClubCompatible';

export const useCopyToQuestion = ({
  questionAnswerFormats,
}: {
  questionAnswerFormats: AnswerFormatType[];
}) => {
  const { t } = useTranslation('editor');
  const [copyInProgress, setCopyInProgress] = useState(false);
  const queryClubCompatible = useQueryClubCompatible();

  return {
    copyInProgress,
    copyQuestionToClubs: async ({
      questionIds,
      clubSlugs,
    }: {
      questionIds: string[];
      clubSlugs: string[];
    }) => {
      setCopyInProgress(true);

      if (
        !(await queryClubCompatible({
          questionAnswerFormats,
          clubSlugs,
        }))
      ) {
        const confirmed = await toast.confirm(
          t(
            'Copying these questions might require you to update the question content Are you sure you want to proceed with the copy?'
          ),
          {
            confirmText: t('Confirm'),
            type: 'warning',
            desktopSx: { maxWidth: 335 },
            anchorEl: document.querySelector(
              '[data-testid="right-panel-action-button"]'
            ) as Element,
          }
        );

        if (!confirmed) {
          setCopyInProgress(false);
          return;
        }
      }

      const [res] = await callWithToast(
        questionIds.map((questionId) =>
          apis.editor.copyCreatorQuestionToClubs({
            creatorQuestionId: questionId,
            clubSlugAry: clubSlugs,
          })
        ),
        {
          errorMsg: t('Copied Failed'),
          showLoading: false,
          anchorEl: document.querySelector(
            '[data-testid="right-panel-action-button"]'
          ) as Element,
        }
      );

      setCopyInProgress(false);

      return res;
    },
  };
};
