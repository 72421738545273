export const enum EditorBlockTypes {
  InlineHighlightAnchor = 'inline-highlight-anchor',
  InlineHighlight = 'inline-highlight',
  InlineLineAnchor = 'inline-line-anchor',
  InlineLineMarker = 'inline-line-marker',
  InlineVariable = 'inline-variable',
  InlineLatex = 'inline-latex',
  InlineAi = 'inline-ai',
  Image = 'image',
  Audio = 'audio',
}
