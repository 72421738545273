import React from 'react';

export default function MainNotificationNew({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.7997 14.3588C18.3812 14.4513 17.9463 14.5 17.5 14.5C14.1863 14.5 11.5 11.8137 11.5 8.5C11.5 6.58728 12.395 4.8836 13.7891 3.78491C13.3159 3.29172 12.6816 3 12.0001 3C10.8835 3 9.89396 3.78308 9.53218 4.95108C9.52253 4.96154 9.51113 4.97036 9.49846 4.9772C7.15128 5.90974 6.01057 7.6989 6.01057 10.4471C6.01057 13.475 5.45729 14.1203 4.54056 15.1882L4.51583 15.217L4.5156 15.2173L4.51537 15.2175C4.45686 15.2856 4.3974 15.3548 4.33698 15.4265C4.15847 15.6343 4.04536 15.887 4.01106 16.1549C3.97675 16.4228 4.02267 16.6945 4.1434 16.938C4.40026 17.4603 4.94772 17.7846 5.57261 17.7846H18.4322C19.0542 17.7846 19.5979 17.4607 19.8556 16.9408C19.9768 16.6973 20.0232 16.4253 19.9892 16.1571C19.9551 15.889 19.8422 15.6358 19.6636 15.4277C19.6276 15.3858 19.5919 15.3439 19.5566 15.3024C19.5241 15.2643 19.492 15.2266 19.4601 15.1894L19.4586 15.1878C19.2113 14.8991 18.9904 14.6412 18.7997 14.3588ZM11.999 21C12.6006 20.9995 13.1908 20.8419 13.7071 20.5439C14.2234 20.2459 14.6465 19.8186 14.9315 19.3073C14.9449 19.2828 14.9515 19.2553 14.9507 19.2276C14.9499 19.1999 14.9417 19.1729 14.9269 19.1491C14.9121 19.1254 14.8911 19.1058 14.8661 19.0922C14.8411 19.0785 14.8128 19.0714 14.7841 19.0714H9.21465C9.18589 19.0713 9.1576 19.0784 9.13252 19.092C9.10745 19.1056 9.08645 19.1252 9.07157 19.149C9.05669 19.1727 9.04843 19.1998 9.0476 19.2275C9.04678 19.2553 9.05341 19.2827 9.06685 19.3073C9.35184 19.8185 9.77486 20.2458 10.2911 20.5438C10.8073 20.8418 11.3974 20.9995 11.999 21Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <circle cx="17.5" cy="8.5" fill="#FF3701" r="4.5" />
    </svg>
  );
}
