import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { MainPractice as MainPracticeIcon } from '@front/icon';
import { SimpleTooltip } from '@front/ui';

import { TrueFalseNotGivenValue } from '../types';

export type ChoiceProps = {
  value: TrueFalseNotGivenValue;
  label: string;
  onSelect: (id: string) => void;
  readonly?: boolean;
};

const styles = {
  root: (theme: Theme) => ({
    background: alpha(theme.palette.text.primary, 0.05),
    borderRadius: '12px',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 56,
    justifyContent: 'flex-start',
    boxShadow: `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.1)}`,
  }),
  innerLayer: {
    position: 'absolute',
    top: 3,
    left: 3,
    right: 3,
    bottom: 3,
    background: 'rgba(255, 255, 255, 0.1)',
  },
  innerContent: {
    display: 'grid',
    gridTemplateColumns: '52px 1fr',
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    userSelect: 'none',
  },
  symbolContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    pr: '8px',
  },
  symbol: (theme: Theme) => ({
    margin: 'auto',
    ml: 1.5,
    height: 32,
    width: 32,
    backgroundColor: alpha(theme.palette.text.primary, 0.05),
    border: `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  }),
  symbolSelected: {
    backgroundColor: 'success.light',
    color: 'background.darker',
  },
  rootSelected: (theme: Theme) => ({
    boxShadow: `inset 0 0 0 3px ${theme.palette.success.light}`,
    background: alpha(theme.palette.success.dark, 0.3),
  }),
  hover: (theme: Theme) => ({
    '@media (hover: hover)': {
      '&:hover': {
        boxShadow: `inset 0 0 0 1px ${alpha(
          theme.palette.text.primary,
          0.3
        )}, 0px 8px 16px ${alpha(theme.palette.text.primary, 0.15)}`,
        backgroundColor: alpha(theme.palette.text.primary, 0.1),
      },
    },
  }),

  label: {
    typography: 'body1',
    textAlign: 'left',
  },

  readonlyButton: {
    cursor: 'default',
  },
};

export default function Choice({
  value,
  label,
  onSelect,
  readonly,
}: ChoiceProps) {
  const { t } = useTranslation('editor');
  const selected = value.selected;

  return (
    <ButtonBase
      disableRipple
      sx={[
        styles.root,
        !selected && styles.hover,
        selected && styles.rootSelected,
        !!readonly && styles.readonlyButton,
      ]}
      type="button"
    >
      {selected && <Box sx={styles.innerLayer} />}
      <Box sx={styles.innerContent}>
        <SimpleTooltip
          title={
            readonly
              ? null
              : selected
              ? t('Unmark as correct answer')
              : t('Mark this as the correct answer')
          }
        >
          {/* create this symbol container to make clickable area bigger */}
          <Box
            sx={styles.symbolContainer}
            onClick={readonly ? undefined : () => onSelect(value.id)}
          >
            <Box sx={[styles.symbol, selected && styles.symbolSelected]}>
              {selected && <MainPracticeIcon width={30} height={30} />}
            </Box>
          </Box>
        </SimpleTooltip>

        <Box sx={styles.label}>{label}</Box>
      </Box>
    </ButtonBase>
  );
}
