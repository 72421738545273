interface CurrencyCode {
  INR: string;
  USD: string;
}

export const DEFAULT_LOCALE = 'en-US';
export const COUNTRY_LOCALE_MAP: CurrencyCode = {
  INR: 'en-IN',
  USD: 'en-US',
};

export const getLocaleCode = (currency: string): string => {
  if (!currency) return DEFAULT_LOCALE;

  return (
    COUNTRY_LOCALE_MAP[currency.toUpperCase() as keyof CurrencyCode] ||
    DEFAULT_LOCALE
  );
};
