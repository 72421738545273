import { useEffect, useRef, useState } from 'react';

type QuizCountdownSubmitParams = {
  defaultSeconds: number;
  isPendingSubmit: boolean;
  interval?: number;
  lazySubmit: () => void;
  cancelSubmit: () => void;
  submitAnswer: () => void;
};

export default function useQuizCountdownSubmit({
  defaultSeconds,
  isPendingSubmit,
  lazySubmit,
  cancelSubmit,
  submitAnswer,
  interval = 1000,
}: QuizCountdownSubmitParams) {
  const [seconds, setSeconds] = useState<number | null>(null);

  const it = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (isPendingSubmit) {
      setSeconds(defaultSeconds);
      it.current = setInterval(() => {
        setSeconds((st) => (st === null ? null : st - 1));
      }, interval);
    } else {
      setSeconds(null);
      clearInterval(it.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPendingSubmit, interval]);

  const shouldSubmit = seconds === 0 && isPendingSubmit;

  useEffect(() => {
    if (shouldSubmit) {
      submitAnswer();
      clearInterval(it.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSubmit]);

  useEffect(
    () => () => {
      clearInterval(it.current);
    },
    []
  );

  const toggleSubmit = () => {
    if (isPendingSubmit) {
      cancelSubmit();
    } else {
      setSeconds(defaultSeconds);
      lazySubmit();
    }
  };

  return { seconds, toggleSubmit };
}
