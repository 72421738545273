import { EditorState, Plugin, PluginKey } from 'prosemirror-state';
import { dropPoint } from 'prosemirror-transform';
import { EditorView } from 'prosemirror-view';

export const DropFileEventKey = 'aha-drop-file';
export type DropFileEventDetail = {
  insertPos: number | null;
  file?: File;
};

interface DropCursorOptions {
  /// The color of the cursor. Defaults to `black`. Use `false` to apply no color and rely only on class.
  color?: string | false;

  /// The precise width of the cursor in pixels. Defaults to 1.
  width?: number;

  /// A CSS class name to add to the cursor element.
  class?: string;
}

class DropCursorView {
  width: number;

  color: string | undefined;

  class: string | undefined;

  cursorPos: number | null = null;

  insertPos: number | null = null;

  element: HTMLElement | null = null;

  timeout: number | NodeJS.Timeout = -1;

  handlers: { name: string; handler: (event: Event) => void }[];

  constructor(readonly editorView: EditorView, options: DropCursorOptions) {
    this.width = options.width ?? 1;
    this.color = options.color === false ? undefined : options.color || 'black';
    this.class = options.class;

    this.handlers = ['dragover', 'dragend', 'drop', 'dragleave'].map((name) => {
      const handler = (e: Event) => {
        (this as any)[name](e);
      };
      editorView.dom.addEventListener(name, handler);
      return { name, handler };
    });
  }

  destroy() {
    this.handlers.forEach(({ name, handler }) =>
      this.editorView.dom.removeEventListener(name, handler)
    );
  }

  update(editorView: EditorView, prevState: EditorState) {
    if (this.cursorPos !== null && prevState.doc !== editorView.state.doc) {
      if (this.cursorPos > editorView.state.doc.content.size)
        this.setCursor(null);
      else this.updateOverlay();
    }
  }

  setCursor(pos: number | null) {
    if (pos === this.cursorPos) return;
    this.cursorPos = pos;
    if (pos === null) {
      this.element?.parentNode?.removeChild(this.element);
      this.element = null;
    } else {
      this.updateOverlay();
    }
  }

  /**
   * node structure looks like
   * <pos> : <type>
   * 0: doc
   * 1: blockGroup
   * 2: blockContainer *  ---> overlayPos
   * 3: paragraph
   * 4: ...
   * 5: blockContainer *  ---> insertPos
   * 6: blockGroup
   * 7: blockContainer
   * 8: ....
   *
   * when drag and drop something onto the row, the cusorPos might start from any of them
   * we need to find the previous 'blockGroup' as our 'overlayPos'
   * and the next 'blockGroup' as our 'insertPos'
   * we can use 'blockContainerSize' as the length between each blockGroup
   */
  private calculateOverlayPosition(cursorPos: number) {
    let blockContainerSize;
    let overlayPos;
    let currentPos = cursorPos;

    if (cursorPos <= 3) {
      // the case of drag image on the top block
      return {
        overlayPos: 1,
        insertPos: 1,
      };
    }

    while (currentPos >= 0) {
      const node = this.editorView.state.doc.resolve(currentPos).node();

      if (node.type.name === 'blockContainer' && !blockContainerSize) {
        blockContainerSize = node.nodeSize;
        currentPos -= 1;
        continue;
      }

      if (node.type.name === 'blockGroup' && blockContainerSize) {
        overlayPos = currentPos;
        break;
      }
      currentPos -= 1;
    }

    if (!overlayPos || !blockContainerSize) {
      console.warn('cannot find overlayPos or blockContainerSize');
      return {
        overlayPos: 1,
        insertPos: 1,
      };
    }

    return {
      overlayPos,
      insertPos: overlayPos + blockContainerSize,
    };
  }

  updateOverlay() {
    if (!this.cursorPos) {
      return;
    }

    const { insertPos, overlayPos } = this.calculateOverlayPosition(
      this.cursorPos
    );
    const overlayNode = overlayPos && this.editorView.nodeDOM(overlayPos);

    if (!overlayNode) {
      return;
    }

    const insertBeforeTopBlock =
      this.editorView.state.doc.resolve(insertPos).nodeBefore === null;

    this.insertPos = insertPos;

    const nodeRect = (overlayNode as HTMLElement).getBoundingClientRect();
    const top = insertBeforeTopBlock ? nodeRect.top : nodeRect.bottom;
    const rect = {
      left: nodeRect.left,
      right: nodeRect.right,
      top: top - this.width / 2,
      bottom: top + this.width / 2,
    };

    const parent = this.editorView.dom.offsetParent;

    if (!parent) {
      return;
    }

    if (!this.element) {
      this.element = parent.appendChild(document.createElement('div'));
      if (this.class) this.element.className = this.class;
      this.element.style.cssText =
        'position: absolute; z-index: 50; pointer-events: none;';
      if (this.color) {
        this.element.style.backgroundColor = this.color;
      }
    }
    this.element.classList.toggle('aha-prosemirror-dropcursor-block', true);
    let parentLeft, parentTop;
    if (
      !parent ||
      (parent === document.body &&
        getComputedStyle(parent).position === 'static')
    ) {
      parentLeft = -window.pageXOffset;
      parentTop = -window.pageYOffset;
    } else {
      const parentRect = parent.getBoundingClientRect();
      parentLeft = parentRect.left - parent.scrollLeft;
      parentTop = parentRect.top - parent.scrollTop;
    }

    if (!rect) {
      return;
    }

    this.element.style.left = rect.left - parentLeft + 'px';
    this.element.style.top = rect.top - parentTop + 'px';
    this.element.style.width = rect.right - rect.left + 'px';
    this.element.style.height = rect.bottom - rect.top + 'px';
  }

  scheduleRemoval(timeout: number) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.setCursor(null), timeout);
  }

  dragover(event: DragEvent) {
    if (!this.editorView.editable) return;
    const pos = this.editorView.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    });

    const node =
      pos && pos.inside >= 0 && this.editorView.state.doc.nodeAt(pos.inside);
    const disableDropCursor = node && node.type.spec.disableDropCursor;
    const disabled =
      typeof disableDropCursor == 'function'
        ? disableDropCursor(this.editorView, pos, event)
        : disableDropCursor;

    if (pos && !disabled) {
      let target: number | null = pos.pos;
      if (this.editorView.dragging && this.editorView.dragging.slice) {
        const point = dropPoint(
          this.editorView.state.doc,
          target,
          this.editorView.dragging.slice
        );
        if (point != null) target = point;
      }
      this.setCursor(target);
      this.scheduleRemoval(5000);
    }
  }

  dragend() {
    this.scheduleRemoval(20);
  }

  drop(event: DragEvent) {
    this.scheduleRemoval(20);

    // XXX: maybe the ideal way to pass the event is through ProseMirror API, but currently we're not familiar with that
    for (const file of (event.dataTransfer?.files || []) as File[]) {
      this.editorView.dom.dispatchEvent(
        new CustomEvent<DropFileEventDetail>(DropFileEventKey, {
          detail: {
            insertPos: this.insertPos,
            file,
          },
        })
      );
    }
  }

  dragleave(event: DragEvent) {
    if (
      event.target === this.editorView.dom ||
      !this.editorView.dom.contains((event as any).relatedTarget)
    )
      this.setCursor(null);
  }
}

const PLUGIN_KEY = new PluginKey('drop-cursor');
/// Create a plugin that, when added to a ProseMirror instance,
/// causes a decoration to show up at the drop position when something
/// is dragged over the editor.
///
/// Nodes may add a `disableDropCursor` property to their spec to
/// control the showing of a drop cursor inside them. This may be a
/// boolean or a function, which will be called with a view and a
/// position, and should return a boolean.
export function dropCursor(options: DropCursorOptions = {}): Plugin {
  return new Plugin({
    key: PLUGIN_KEY,
    view(editorView) {
      return new DropCursorView(editorView, options);
    },
  });
}
