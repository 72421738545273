import { useMemo } from 'react';

interface InfiniteState {
  data: any[] | undefined;
  error: any | undefined;
  size: number;
  pageSize: number;
}

export function useInfiniteState<T>({
  data,
  error,
  size,
  pageSize,
}: { data: PageResponse<T>[] | undefined } & InfiniteState) {
  const memoizedValue = useMemo(() => {
    const dataset = data
      ? ([] as T[]).concat(...(data.map((d) => d.data.items) || []))
      : [];

    const totalCount = data?.[0]?.data.totalCount;
    const pageCount = data?.[0]?.data.pageCount;

    const isLoadingInitialData = !data && !error;
    const isLoadingMore =
      isLoadingInitialData ||
      (size > 0 && !!data && typeof data[size - 1] === 'undefined');
    const isEmpty = totalCount === 0;
    const isReachingEnd =
      isEmpty || (totalCount && size * pageSize >= totalCount) || !!error;

    return {
      dataset,
      totalCount,
      pageCount,
      isLoadingInitialData,
      isLoadingMore,
      isEmpty,
      isReachingEnd,
      pageSize,
    };
  }, [data, error, size, pageSize]);

  return memoizedValue;
}
