import React from 'react';

export default function OtherSend({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21 12C21 12.3552 20.8033 12.6791 20.4939 12.8332L4.28167 20.9084C3.95145 21.0729 3.55764 21.0122 3.28813 20.7553C3.01863 20.4983 2.92805 20.0972 3.05961 19.7433L5.93847 12L3.05962 4.25668C2.92805 3.9028 3.01863 3.5017 3.28813 3.24475C3.55764 2.9878 3.95145 2.92708 4.28167 3.09156L20.4939 11.1668C20.8033 11.3209 21 11.6448 21 12ZM5.53754 5.76973L7.73045 11.668C7.80989 11.8817 7.80989 12.1183 7.73045 12.332L5.53754 18.2303L18.0457 12L5.53754 5.76973Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
