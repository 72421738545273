import React from 'react';

export default function TradingPattern({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.01714 3.65536C5.21382 3.13687 5.80787 2.87075 6.34398 3.06097L19.3219 7.66565C19.858 7.85587 20.1331 8.43039 19.9365 8.94888C19.7398 9.46738 19.1457 9.7335 18.6096 9.54328L5.63173 4.9386C5.09562 4.74838 4.82046 4.17386 5.01714 3.65536ZM4.06354 15.0507C4.26022 14.5322 4.85427 14.2661 5.39038 14.4563L18.3683 19.061C18.9044 19.2512 19.1795 19.8257 18.9829 20.3442C18.7862 20.8627 18.1921 21.1288 17.656 20.9386L4.67814 16.3339C4.14203 16.1437 3.86686 15.5692 4.06354 15.0507Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M7.64648 9.05225L7.64648 12.677H8.98682L8.98682 9.05225L7.64648 9.05225Z"
        fill="currentColor"
      />
      <path
        d="M10.5228 10.9195V14.5443H11.8631V10.9195L10.5228 10.9195Z"
        fill="currentColor"
      />
      <path
        d="M13.204 9.74784L13.204 15.7891H14.5443L14.5443 9.74784H13.204Z"
        fill="currentColor"
      />
      <path
        d="M15.884 11.5419V15.1666H17.2244L17.2244 11.5419L15.884 11.5419Z"
        fill="currentColor"
      />
    </svg>
  );
}
