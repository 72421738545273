import { useCallback } from 'react';
import { ComposerBlock } from '@lib/web/composer';
import { useCreatorQuestionDetailHandle } from '@lib/web/editor';

export const useEventHandle = (questionId: string) => {
  const { handleCreatorQuestionDetailChange } =
    useCreatorQuestionDetailHandle(questionId);

  const handleBlocksChange = useCallback(
    async (component: QuestionDetailComponent, blocks: ComposerBlock[]) => {
      void handleCreatorQuestionDetailChange(component, blocks);
    },
    [handleCreatorQuestionDetailChange]
  );
  return {
    handleBlocksChange,
  };
};
