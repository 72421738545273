import React from 'react';

export default function NFTUncommon({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.8672 10.7052L13.3036 2.14153C12.9607 1.79862 12.5004 1.6001 12.0041 1.6001C11.5078 1.6001 11.0566 1.7896 10.7047 2.14153L2.14104 10.7052C1.79813 11.0481 1.59961 11.5083 1.59961 12.0046C1.59961 12.5009 1.78911 12.9521 2.14104 13.304L10.7047 21.8677C11.0656 22.2286 11.5349 22.4001 12.0041 22.4001C12.4734 22.4001 12.9426 22.2196 13.3036 21.8677L21.8672 13.304C22.5801 12.5821 22.5801 11.4181 21.8672 10.7052ZM20.7212 12.149L12.1575 20.7126C12.0763 20.7938 11.941 20.7938 11.8597 20.7126L3.2961 12.149C3.24195 12.0948 3.23293 12.0407 3.23293 11.9956C3.23293 11.9505 3.24195 11.8963 3.2961 11.8422L11.8597 3.27854C11.9049 3.23342 11.959 3.21537 12.0131 3.21537C12.0673 3.21537 12.1214 3.23342 12.1666 3.27854L20.7302 11.8422C20.8024 11.9324 20.8024 12.0678 20.7212 12.149Z"
        fill="currentColor"
      />
      <path
        d="M12.8711 6.37365C12.6364 6.13903 12.3296 6.0127 11.9957 6.0127C11.6619 6.0127 11.3551 6.13903 11.1204 6.37365L6.36486 11.1292C6.13024 11.3638 6.00391 11.6707 6.00391 12.0045C6.00391 12.3384 6.13024 12.6452 6.36486 12.8799L11.1204 17.6354C11.3551 17.8701 11.6619 17.9964 11.9957 17.9964C12.3296 17.9964 12.6364 17.8701 12.8711 17.6354L17.6266 12.8799C17.8613 12.6452 17.9876 12.3384 17.9876 12.0045C17.9876 11.6707 17.8613 11.3638 17.6266 11.1292L12.8711 6.37365ZM12.0048 16.4623L7.54699 12.0045L12.0048 7.54675L16.4626 12.0045L12.0048 16.4623Z"
        fill="currentColor"
      />
    </svg>
  );
}
