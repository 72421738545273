import { useMemo } from 'react';
import { useAuth, useUserFollowing } from '@lib/web/apis';

import { generateEmptyDmView } from '../../utils/viewUtils';

const SUGGESTION_COUNT = 5;
export const useSuggestionThreadViews = ({
  showSuggestions,
}: {
  showSuggestions: boolean;
}) => {
  const { member } = useAuth();

  const { dataset: followings } = useUserFollowing(
    showSuggestions && member?.userId ? member.userId : '',
    '',
    SUGGESTION_COUNT
  );

  return useMemo(() => {
    const myMemberId = member?.memberId || '';

    return followings.map((user) => {
      return generateEmptyDmView({ myMemberId, memberId: user.memberId });
    });
  }, [followings, member?.memberId]);
};
