// eslint-disable-next-line import/prefer-default-export
export const enum SubscriptionAction {
  Create = 'createSubscription',
  Update = 'updateSubscription',
  Cancel = 'cancelSubscription',
}

export const enum StripeSubscriptionOrderStatus {
  Unknown = 0,
  Open = 1,
  Completed = 2,
  Expired = -1,
  Error = -2,
}

export const enum AhaSubscriptionOrderStatus {
  Expired = -2,
  Cancelled = -1,
  Unknown = 0,
  Pending = 1,
  Finished = 2,
}

export const enum SubscriptionOrderDiscountType {
  Unknown = 0,
  DiscountCode = 1,
  AhaPoint = 2,
}

/**
 * @deprecated using PlanProductName instead
 */
export const enum SubscriptionType {
  ProMonthly = 'earnaha_pro_plan_monthly',
  ProAnnually = 'earnaha_pro_plan_yearly',
}

export const enum PlanProductName {
  BasicMonthly = 'earnaha_basic_plan_monthly',
  BasicYearly = 'earnaha_basic_plan_yearly',
  ProMonthly = 'earnaha_pro_plan_monthly',
  ProYearly = 'earnaha_pro_plan_yearly',
  UnlimitedMonthly = 'earnaha_unlimit_plan_monthly',
  UnlimitedYearly = 'earnaha_unlimit_plan_yearly',
}

export const enum ChargeType {
  Unknown = 0,
  Once = 1,
  RecurringMonthly = 2,
  RecurringYearly = 3,
}

export const enum SubscriptionSourceType {
  Unknown = 0,
  Stripe = 1,
  RevenueCat = 2,
}

export const enum SubscriptionOrderType {
  Unknown = 0,
  StripeCheckoutSession = 1,
  StripePaymentIntent = 2,
  StripeAutoPayment = 3,
  RevenueCatPayment = 11,
}
