import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import IconListItem from '@lib/ia/src/layouts/SmallIconListLayout/components/IconListItem';
import { SmallIconListLayoutAreaIconList } from '@lib/ia/src/layouts/SmallIconListLayout/types';

export type AreaIconListProps = SmallIconListLayoutAreaIconList;

const styles = {
  title: {
    px: { xs: 2.5, md: 1.5 },
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
};
export default function AreaIconList({ title, items }: AreaIconListProps) {
  return (
    <Box>
      <Typography variant="caption" sx={styles.title}>
        {title}
      </Typography>
      {items.map(({ id, icon, iconType, text }) => (
        <IconListItem key={id} icon={icon} iconType={iconType} text={text} />
      ))}
    </Box>
  );
}
