import { useEffect, useRef } from 'react';
import Router from 'next/router';

type ShouldInterruptFunc = (params?: { nextUrl?: string }) => boolean;

type UseRouterInterruptParams = {
  shouldInterrupt: boolean | ShouldInterruptFunc;
  onCancelInterrupt?: () => void;
  onInterrupt: (() => Promise<boolean>) | (() => void);
};
export default function useRouterInterrupt({
  shouldInterrupt,
  onCancelInterrupt,
  onInterrupt,
}: UseRouterInterruptParams) {
  const forceRouteRef = useRef(false);
  const funcRef = useRef({
    onCancelInterrupt,
    onInterrupt,
  });

  funcRef.current = {
    onCancelInterrupt,
    onInterrupt,
  };

  useEffect(() => {
    const getShouldInterrupt: ShouldInterruptFunc = (params) => {
      if (
        forceRouteRef.current ||
        params?.nextUrl?.includes('forceRoute=true')
      ) {
        funcRef.current.onCancelInterrupt?.();
        return false;
      }

      if (typeof shouldInterrupt === 'boolean') {
        return (
          shouldInterrupt || (funcRef.current.onCancelInterrupt?.(), false)
        );
      }

      const status = shouldInterrupt(params);
      if (!status) {
        funcRef.current.onCancelInterrupt?.();
      }
      return status;
    };

    const routeChange = (ev: BeforeUnloadEvent) => {
      if (getShouldInterrupt()) {
        ev.preventDefault();

        // eslint-disable-next-line no-return-assign, no-param-reassign
        return (ev.returnValue =
          'You have unsaved changes - are you sure you wish to close?');
      }
      return '';
    };

    const routeChangeStart = (nextUrl: string) => {
      if (getShouldInterrupt({ nextUrl })) {
        funcRef.current.onInterrupt()?.then((result) => {
          if (result) {
            forceRouteRef.current = true;
            void Router.push(nextUrl);
          }
        });

        Router.events.emit('routeChangeError');
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw 'Please ignore this error';
      }
    };

    window.addEventListener('beforeunload', routeChange);
    Router.events.on('routeChangeStart', routeChangeStart);

    Router.beforePopState((ev) => {
      if (getShouldInterrupt({ nextUrl: ev.url })) {
        funcRef.current.onInterrupt()?.then((result) => {
          if (result) {
            forceRouteRef.current = true;
            Router.back();
          }
        });

        window.history.pushState(null, document.title, Router.asPath);
        // Have SSR render bad routes as a 404.
        return false;
      }

      return true;
    });

    return () => {
      window.removeEventListener('beforeunload', routeChange);
      Router.events.off('routeChangeStart', routeChangeStart);
      // cleanup beforePopState to avoid old callback function is called
      Router.beforePopState(() => true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldInterrupt]);
}
