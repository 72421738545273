import { toSnakeCase } from './string';

function transSnakeCase(obj: { [key: string]: any }): { [key: string]: any } {
  if (typeof obj !== 'object' || !obj) return obj;
  if (Array.isArray(obj)) {
    return obj.map((item) => transSnakeCase(item));
  }
  const result: { [key: string]: any } = {} as { [key: string]: any };
  Object.keys(obj).forEach((key) => {
    const newKey = toSnakeCase(key);
    if (typeof obj[key] === 'object') {
      result[newKey] = transSnakeCase(obj[key]);
    } else {
      result[newKey] = obj[key];
    }
  });
  return result;
}

const objectUtils = {
  transSnakeCase,
};

export default objectUtils;
