import React from 'react';

export default function ProfileNFTs({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 4.80951L5.73205 8.40476V15.5952L12 19.1905L18.268 15.5952V8.40476L12 4.80951ZM12.5773 3.15369C12.2201 2.94877 11.7799 2.94877 11.4226 3.15369L4.57735 7.0801C4.22008 7.28503 4 7.66374 4 8.07359V15.9264C4 16.3363 4.22008 16.715 4.57735 16.9199L11.4226 20.8463C11.7799 21.0512 12.2201 21.0512 12.5773 20.8463L19.4227 16.9199C19.7799 16.715 20 16.3363 20 15.9264V8.07359C20 7.66374 19.7799 7.28503 19.4227 7.0801L12.5773 3.15369Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
