import React from 'react';

export default function OtherProcessLight({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
        fill="#080808"
        opacity="0.1"
      />
      <path
        d="M12 2.75C12 2.33579 12.3363 1.99707 12.7493 2.02811C14.359 2.14906 15.9195 2.6583 17.2951 3.51696C18.8833 4.50833 20.1613 5.92565 20.9837 7.60762C21.8061 9.28958 22.1397 11.1686 21.9467 13.0309C21.7796 14.6439 21.2231 16.1881 20.33 17.5328C20.1008 17.8778 19.627 17.9352 19.3001 17.6808C18.9732 17.4264 18.9175 16.9568 19.1421 16.6087C19.8652 15.4881 20.3165 14.2098 20.4547 12.8763C20.6188 11.2933 20.3352 9.69614 19.6362 8.26648C18.9371 6.83681 17.8508 5.63208 16.5008 4.78942C15.3635 4.07951 14.0775 3.65059 12.749 3.53306C12.3364 3.49656 12 3.16421 12 2.75Z"
        fill="#080808"
      />
    </svg>
  );
}
