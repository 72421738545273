import { basicTiptapExtensions } from '@lib/web/composer';
import { InlineMention } from '@lib/web/composer/TextComposer/components/blocks/InlineMention';
import { InlineHighlight } from '@lib/web/thread/ThreadTextComposer/components/blocks/InlineHighlight';
import { InlineLatex } from '@lib/web/thread/ThreadTextComposer/components/blocks/InlineLatex';
import { InlineLineMarker } from '@lib/web/thread/ThreadTextComposer/components/blocks/InlineLineMarker';
import { InlineVariable } from '@lib/web/thread/ThreadTextComposer/components/blocks/InlineVariable';

export const threadTiptapExtensions = [
  ...basicTiptapExtensions,
  InlineLatex,
  InlineMention,
  InlineLineMarker,
  InlineVariable,
  InlineHighlight,
];
