import { createContext, MutableRefObject, ReactNode, useRef } from 'react';

export interface QueueItem {
  timestamp: Date;
  task: () => void | Promise<void>;
  shouldInterrupt?: boolean;
}

type QueueWorkerValue = {
  queueMap: MutableRefObject<Record<string, QueueItem[] | undefined>> | null;
  timerMap: MutableRefObject<Record<string, NodeJS.Timeout>> | null;
  workerIsProgressMap: MutableRefObject<
    Record<string, QueueItem | undefined>
  > | null;
};

const initialValue: QueueWorkerValue = {
  queueMap: null,
  timerMap: null,
  workerIsProgressMap: null,
};

export const QueueWorkerContext = createContext<QueueWorkerValue>(initialValue);

export const QueueWorkerContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // use useRef because in queue worker, we would like to access the latest value to prevent race condition
  const timerMap = useRef<Record<string, NodeJS.Timeout>>({});
  const queueMap = useRef<Record<string, QueueItem[] | undefined>>({});
  const workerIsProgressMap = useRef<Record<string, QueueItem | undefined>>({});

  return (
    <QueueWorkerContext.Provider
      value={{ queueMap, timerMap, workerIsProgressMap }}
    >
      {children}
    </QueueWorkerContext.Provider>
  );
};
