import { createAction } from '@reduxjs/toolkit';

const types = {
  SYNC_HIGHLIGHT_OPTIONS: 'SETTING/SYNC_HIGHLIGHT_OPTIONS',
  SYNC_LINE_MARKER_OPTIONS: 'SETTING/SYNC_LINE_MARKER_OPTIONS',
  ANSWER_FORMAT_CHANGING: 'SETTING/ANSWER_FORMAT_CHANGING',
  ANSWER_FORMAT_CHANGED: 'SETTING/ANSWER_FORMAT_CHANGED',
  RELOAD_SETTING: 'SETTING/RELOAD_SETTING',
  RELOAD_SETTING_FINISH: 'SETTING/RELOAD_SETTING_FINISH',
};

export const syncLineMarkerOptions = createAction(
  types.SYNC_LINE_MARKER_OPTIONS,
  (items: { id: string; display: string; value: string }[]) => ({
    payload: items,
  })
);

export const syncHighlightOptions = createAction(
  types.SYNC_HIGHLIGHT_OPTIONS,
  (items: { id: string; display: string; value: string }[]) => ({
    payload: items,
  })
);

export const answerFormatChanging = createAction(types.ANSWER_FORMAT_CHANGING);
export const answerFormatChanged = createAction(types.ANSWER_FORMAT_CHANGED);

export const reloadSetting = createAction(types.RELOAD_SETTING);
export const reloadSettingFinish = createAction(types.RELOAD_SETTING_FINISH);
