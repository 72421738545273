import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { alpha, Box, Theme } from '@mui/material';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import Button from '../../../Button/Button';
import TextButton from '../../../Button/TextButton';

import PayoffMessage, { PayoffMessageProps } from './PayoffMessage';
import StepDot from './StepDot';

import 'swiper/css';

const styles = {
  root: {
    width: '100%',
    color: (theme: Theme) => theme.palette.text.primary,
    borderRadius: 1,
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '& .payoff-message': {
          bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
        },
      },
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: '12px',
    py: 1,
    minHeight: '52px',
  },
  dots: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  nextButton: {
    px: 2,
    minWidth: 'unset',
  },
};

type StepsSliderRef = {
  slidePrev: () => void;
  slideNext: () => void;
  slideTo: (index: number) => void;
};

type StepsSliderProps = {
  steps: PayoffMessageProps[];
  onChange: (index: number) => void;
};

const StepsSlider = forwardRef<StepsSliderRef, StepsSliderProps>(
  ({ steps, onChange }, ref) => {
    const [swiper, setSwiper] = useState<SwiperClass>();

    const slidePrev = () => {
      swiper?.slidePrev();
    };
    const slideNext = () => {
      swiper?.slideNext();
    };
    const slideTo = (index: number) => {
      swiper?.slideTo(index);
    };

    useImperativeHandle(ref, () => ({
      slidePrev,
      slideNext,
      slideTo,
    }));

    const handleSlideChange = (sw: SwiperClass) => {
      onChange(sw.activeIndex);
    };

    return (
      <Swiper
        slidesPerView={1}
        autoplay={{ delay: 5000 }}
        modules={[Autoplay]}
        onSwiper={setSwiper}
        onSlideChange={handleSlideChange}
        autoHeight
      >
        {steps.map((item, index) => (
          <SwiperSlide key={index}>
            <PayoffMessage {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }
);

export type PayoffStepsProps = {
  steps: PayoffMessageProps[];
};

export default function PayoffSteps({ steps }: PayoffStepsProps) {
  const sliderRef = useRef<StepsSliderRef>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = steps.length;

  const handleDotClick = (index: number) => {
    sliderRef.current?.slideTo(index);
  };

  const handlePrevClick = () => {
    if (currentStep > 0) {
      sliderRef.current?.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (currentStep < totalSteps - 1) {
      sliderRef.current?.slideNext();
    }
  };

  return (
    <Box sx={styles.root}>
      <StepsSlider steps={steps} onChange={setCurrentStep} ref={sliderRef} />
      <Box sx={styles.footer}>
        <Box sx={styles.dots}>
          {[...Array(totalSteps)].map((_, index) => (
            <StepDot
              key={index}
              active={index === currentStep}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </Box>
        <Box sx={styles.actions}>
          {currentStep > 0 && (
            <TextButton onClick={handlePrevClick}>Prev</TextButton>
          )}
          {currentStep < totalSteps - 1 && (
            <Button sx={styles.nextButton} onClick={handleNextClick}>
              Next
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
