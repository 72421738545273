import { createContext, ReactNode, useMemo } from 'react';
import { ExamMode } from '@lib/web/apis';

import { PracticeStaticDataContextValue } from '../types';

const initialValue: PracticeStaticDataContextValue = {
  section: {
    sectionExamId: '',
    examinationId: '',
    viewType: undefined,
    display: '',
    displayShorter: '',
    displayMain: '',
    allowCalculator: false,
    fullScore: 0,
    bottomScore: 0,
    actualFullScore: 0,
    questionCount: 0,
    pickingQuestionCount: 0,
    pickingOptionCount: 0,
    timeMinutes: 0,
    tpqSec: 0,
    directionOne: '',
    previewDirection: '',
    previewGroupStart: 0,
    previewGroupEnd: 0,
    previewGroupInterval: 0,
    status: 0,
  },
  mappedMaterials: {},
  panelKeys: { solution: '' },
  mode: ExamMode.Unknown,
};

export const PracticeStaticDataContext =
  createContext<PracticeStaticDataContextValue>(initialValue);

export type PracticeStaticDataContextProviderProps = {
  children: ReactNode;
} & PracticeStaticDataContextValue;

export const PracticeStaticDataContextProvider = ({
  children,
  section,
  mappedMaterials,
  panelKeys,
  mode,
  preference,
}: PracticeStaticDataContextProviderProps) => {
  const memoizedValue = useMemo(() => {
    return {
      section,
      mappedMaterials,
      panelKeys,
      mode,
      preference,
    };
  }, [section, mappedMaterials, panelKeys, mode, preference]);

  return (
    <PracticeStaticDataContext.Provider value={memoizedValue}>
      {children}
    </PracticeStaticDataContext.Provider>
  );
};
