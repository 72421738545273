import { format } from 'date-fns';

import { AhaPointSourceType } from '../../types/enums';

import generateHook, { generateInfiniteHook } from './swr.helper';

export const useAhaPointBalance = ({
  customDate,
}: {
  customDate?: Date;
} = {}) => {
  const params = new URLSearchParams();
  if (customDate) {
    params.set('customDate', format(customDate, 'yyyy-MM-dd'));
  }
  return generateHook<GetAhaPointBalanceRes>(
    `marketplace/v1/aha-point/balance?${params.toString()}`,
    {
      auth: true,
    }
  );
};
export const useAhaPointEarned = ({
  type,
  customDate,
}: {
  type?: AhaPointSourceType;
  customDate?: Date;
} = {}) => {
  const params = new URLSearchParams();
  if (type) {
    params.set('type', type.toString());
  }
  if (customDate) {
    params.set('customDate', format(customDate, 'yyyy-MM-dd'));
  }
  return generateHook<GetAhaPointEarnedRes>(
    `marketplace/v1/aha-point/earned?${params.toString()}`,
    {
      auth: true,
    }
  );
};
export const useAhaPointActivity = ({
  customDate,
  pageSize = 10,
}: {
  customDate?: Date;
  pageSize?: number;
} = {}) =>
  generateInfiniteHook<GetAhaPointActivityRes>(
    (index) => {
      const params = new URLSearchParams();

      params.set('page', String(index + 1));
      params.set('limit', String(pageSize));

      if (customDate) {
        params.set('customDate', format(customDate, 'yyyy-MM-dd'));
      }
      return `marketplace/v1/aha-point/activity?${params.toString()}`;
    },
    pageSize,
    {
      auth: true,
    }
  );
export const useAhaPointRewardPlan = () =>
  generateHook<GetAhaPointRewardPointRes[]>(
    'marketplace/v1/aha-point/reward/plan',
    {
      auth: true,
      immutable: true,
    }
  );
