import React from 'react';

export default function ThreadsThread({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.975 15.6911L5.92426 16.2819C5.84434 17.2124 5.70594 18.0781 5.50476 18.8474C6.19862 18.6216 6.94166 18.3215 7.58054 17.9333L8.21095 17.5503L8.88553 17.8368C9.86297 18.2519 10.9078 18.4644 12 18.4644C16.2954 18.4644 19.5 15.1891 19.5 11.5263C19.5 7.86352 16.2954 4.58824 12 4.58824C7.70459 4.58824 4.50002 7.86352 4.50002 11.5263C4.50002 12.8251 4.88844 14.0942 5.64747 15.2097L5.975 15.6911ZM4.79088 20.6967C4.68932 20.7222 4.59132 20.7459 4.49747 20.7679C4.08932 20.8634 3.7598 20.926 3.55776 20.9613C3.5577 20.9614 3.55782 20.9613 3.55776 20.9613C3.43526 20.9828 3.3595 20.9942 3.34172 20.9968C3.32762 20.9991 3.31382 21 3.30002 21C3.18752 21 3.08252 20.9334 3.03152 20.8251C2.97392 20.7038 2.99672 20.5569 3.08792 20.4609C3.14043 20.4057 3.19169 20.3458 3.24167 20.2813C3.24324 20.2793 3.2448 20.2773 3.24637 20.2752C3.26442 20.2518 3.28231 20.2278 3.30002 20.2032C3.44487 20.002 3.57839 19.7614 3.69963 19.4845C3.72513 19.4263 3.75009 19.3665 3.77449 19.3051C4.09492 18.4989 4.32022 17.4212 4.43042 16.1381C3.49382 14.7616 3.00002 13.1697 3.00002 11.5263C3.00002 6.82484 7.03742 3 12 3C16.9626 3 21 6.82484 21 11.5263C21 16.2278 16.9626 20.0526 12 20.0526C10.7211 20.0526 9.48632 19.8032 8.32712 19.3108C7.19009 20.0017 5.81083 20.4404 4.79088 20.6967ZM4.37396 20.1164L4.37159 20.1114C4.37238 20.1131 4.37317 20.1148 4.37396 20.1164Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
