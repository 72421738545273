import React, { forwardRef, ReactNode } from 'react';
import { Typography, TypographyProps } from '@mui/material';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    color: 'alpha.lightA75',
  },
};

type TitleProps = TypographyProps & {
  children: ReactNode;
};

const Subtitle = forwardRef<HTMLElement, TitleProps>(
  ({ sx, variant = 'appBody', children, ...rest }, ref) => {
    const sxProps = Array.isArray(sx) ? sx : [sx];
    return (
      <Typography
        component="div"
        ref={ref}
        sx={[styles.root, ...sxProps]}
        variant={variant}
        {...rest}
      >
        {children}
      </Typography>
    );
  }
);

Subtitle.displayName = 'Subtitle';

export default Subtitle;
