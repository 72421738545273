import useRenderPassageWrap from './Practice/components/QuestionContent/components/QuizPassage/hooks/useRenderPassageWrap';

export { default as GridInOption } from './GridInOption';
export * from './Practice';
export { default as Practice } from './Practice';
export { default as QuizAudioRender } from './QuizAudioRender';
export * from './QuizAudioRender';
export { default as QuizSolutions } from './QuizSolutions';
export * from './QuizSolutions';
export { useRenderPassageWrap };
