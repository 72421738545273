import {
  ChallengeExpired as ChallengeExpiredIcon,
  ChallengeInvited as ChallengeInvitedIcon,
  ChallengeOngoing as ChallengeOngoingIcon,
  ChallengeOpen as ChallengeOpenIcon,
} from '@front/icon';
import { ChallengeStatus, useQuizResultSummary } from '@lib/web/apis';

type ChallengeIconProps = {
  quizId?: string;
  size?: number;
};

export default function ChallengeIcon({ quizId, size }: ChallengeIconProps) {
  const { data } = useQuizResultSummary({
    quizId,
  });
  const challenge = data?.data.challenge;
  if (!challenge) return null;

  if (challenge.status === ChallengeStatus.NotInvited) {
    return <ChallengeOpenIcon width={size} height={size} />;
  }
  if (challenge.status === ChallengeStatus.Expired) {
    return <ChallengeExpiredIcon width={size} height={size} />;
  }
  if (challenge.status === ChallengeStatus.Ongoing) {
    return <ChallengeOngoingIcon width={size} height={size} />;
  }
  if (challenge.status === ChallengeStatus.Invited) {
    return <ChallengeInvitedIcon width={size} height={size} />;
  }
  return null;
}
