import {
  BasicComposerSchema,
  basicToolbarMenuItems,
  TextComposer,
} from '@lib/web/composer';
import { TextComposerProps } from '@lib/web/composer/TextComposer/TextComposer';

import { threadBlockEditingStyles } from './config/threadComposerBlockStyles';
import { threadComposerSchema } from './config/threadComposerSchema';
import { threadSlashMenuItems } from './config/threadSlashMenuItems';
import { threadTiptapExtensions } from './config/threadTiptapExtensions';

const styles = {
  sideMenuPositioner: {
    '.floating-menu-root': {
      mt: '-6px',
    },
  },
};

export type ThreadTextComposerProps = TextComposerProps<BasicComposerSchema>;

export default function ThreadTextComposer(props: ThreadTextComposerProps) {
  return (
    <TextComposer
      blockStyles={threadBlockEditingStyles}
      sideMenuPositionerSx={styles.sideMenuPositioner}
      blockSchema={threadComposerSchema}
      slashMenuItems={threadSlashMenuItems}
      toolbarItems={basicToolbarMenuItems}
      customTiptapExtensions={threadTiptapExtensions}
      {...props}
    />
  );
}
