import React from 'react';

export default function ProfileUserPlaceholder({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75355 2.75355C1.11256 4.39455 0.5 7.19643 0.5 12C0.5 16.8036 1.11256 19.6054 2.75355 21.2464C4.39455 22.8874 7.19643 23.5 12 23.5C16.8036 23.5 19.6054 22.8874 21.2464 21.2464C22.8874 19.6054 23.5 16.8036 23.5 12C23.5 7.19643 22.8874 4.39455 21.2464 2.75355C19.6054 1.11256 16.8036 0.5 12 0.5C7.19643 0.5 4.39455 1.11256 2.75355 2.75355ZM0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="currentColor"
      />
    </svg>
  );
}
