import { useContext } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from '@lib/web/apis';
import { SharedUserContext } from '@lib/web/contexts';

/**
 * in shared pages this hook will return userId that associated with the sid
 * otherwise return logged in user id
 */
export default function useCurrentUserId() {
  const router = useRouter();
  const sid = router.query.sid as string;
  const { userId: loggedInUserId } = useAuth();
  const { userId: shareUserId } = useContext(SharedUserContext);
  return !sid ? loggedInUserId : shareUserId;
}
