import { ReactNode } from 'react';

import IaActionContext, { IaActionContextValue } from './IaActionContext';

export default function IaActionContextProvider({
  availableActions,
  children,
}: IaActionContextValue & {
  children: ReactNode;
}) {
  return (
    <IaActionContext.Provider value={{ availableActions }}>
      {children}
    </IaActionContext.Provider>
  );
}
