import React from 'react';

export default function ActionChevronRight({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6 20.2121L7.82005 22L18 12L7.82005 2L6 3.78788L14.3599 12L6 20.2121Z"
        fill="currentColor"
      />
    </svg>
  );
}
