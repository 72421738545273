import React from 'react';

export default function SocialMediaDiscord({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.2283 6.55253C17.4976 5.23364 14.7598 5.01007 14.6427 5.00202C14.4609 4.98755 14.2877 5.08405 14.213 5.24329C14.2062 5.25294 14.1467 5.38322 14.0805 5.58588C15.2252 5.76924 16.6315 6.13756 17.9035 6.88547C18.1073 7.00449 18.1702 7.25862 18.0445 7.45163C17.9613 7.57869 17.8203 7.64786 17.6743 7.64786C17.5961 7.64786 17.5163 7.62695 17.445 7.58513C15.2575 6.30001 12.5265 6.23567 12 6.23567C11.4735 6.23567 8.74083 6.30001 6.55503 7.58513C6.35122 7.70576 6.08288 7.64625 5.9572 7.45324C5.82982 7.25862 5.89266 7.0061 6.09647 6.88547C7.36855 6.13917 8.7748 5.76924 9.9195 5.58749C9.85326 5.38322 9.79382 5.25455 9.78872 5.24329C9.7123 5.08405 9.54076 4.98433 9.35734 5.00202C9.24015 5.01007 6.50238 5.23364 4.74796 6.57022C3.83254 7.37282 2 12.0629 2 16.1177C2 16.1901 2.02038 16.2593 2.05774 16.322C3.32133 18.4258 6.77072 18.9759 7.55707 19C7.56046 19 7.56556 19 7.57065 19C7.70992 19 7.84069 18.9373 7.92221 18.8311L8.71705 17.7953C6.57201 17.271 5.47656 16.3799 5.41372 16.3268C5.2337 16.1772 5.21671 15.9167 5.37636 15.7462C5.53431 15.5757 5.80944 15.5596 5.98947 15.7092C6.01495 15.7317 8.03261 17.353 12 17.353C15.9742 17.353 17.9918 15.7253 18.0122 15.7092C18.1923 15.5612 18.4657 15.5757 18.6253 15.7478C18.7833 15.9183 18.7663 16.1772 18.5863 16.3268C18.5234 16.3799 17.428 17.271 15.2829 17.7953L16.0778 18.8311C16.1593 18.9373 16.2901 19 16.4293 19C16.4344 19 16.4395 19 16.4429 19C17.2293 18.9759 20.6787 18.4258 21.9423 16.322C21.9796 16.2593 22 16.1901 22 16.1177C22 12.0629 20.1675 7.37282 19.2283 6.55253ZM9.17391 14.4707C8.33322 14.4707 7.65217 13.7341 7.65217 12.8237C7.65217 11.9134 8.33322 11.1767 9.17391 11.1767C10.0146 11.1767 10.6957 11.9134 10.6957 12.8237C10.6957 13.7341 10.0146 14.4707 9.17391 14.4707ZM14.8261 14.4707C13.9854 14.4707 13.3043 13.7341 13.3043 12.8237C13.3043 11.9134 13.9854 11.1767 14.8261 11.1767C15.6668 11.1767 16.3478 11.9134 16.3478 12.8237C16.3478 13.7341 15.6668 14.4707 14.8261 14.4707Z"
        fill="currentColor"
      />
    </svg>
  );
}
