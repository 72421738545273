import React from 'react';

export default function MainChallenge({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.58874 4.81522C9.13489 4.9752 9.63212 5.27195 10.0342 5.6779L18.2313 13.9532L18.9532 13.2244C19.1728 13.0027 19.5288 13.0027 19.7484 13.2244C19.968 13.4461 19.968 13.8055 19.7484 14.0272L17.8135 15.9805L18.6517 16.8267C19.2626 16.5382 20.0125 16.6485 20.517 17.1578C21.161 17.808 21.161 18.8622 20.517 19.5124C19.8729 20.1625 18.8287 20.1625 18.1847 19.5124C17.6803 19.0032 17.5709 18.2462 17.8565 17.6296L17.0183 16.7834L15.0839 18.7363C14.8643 18.958 14.5082 18.958 14.2886 18.7363C14.069 18.5146 14.069 18.1552 14.2886 17.9335L15.6649 16.5441L7.46781 8.26881C7.06569 7.86286 6.77175 7.36089 6.61328 6.80953L5.80576 4L8.58874 4.81522Z"
        fill="currentColor"
      />
      <path
        d="M15.8016 4.85937C15.217 5.01122 14.6834 5.31863 14.2566 5.74955L13.3719 6.64267L16.1178 9.41477L17.0025 8.52164C17.4293 8.09072 17.7338 7.55208 17.8843 6.9619L18.6057 4.13103L15.8016 4.85937Z"
        fill="currentColor"
      />
      <path
        d="M11.1172 14.4631L8.37131 11.691L5.94961 14.1358L5.04679 13.2244C4.82719 13.0027 4.47116 13.0027 4.25157 13.2244C4.03197 13.4461 4.03197 13.8055 4.25157 14.0272L6.18646 15.9805L5.34832 16.8267C4.73745 16.5382 3.98749 16.6485 3.48303 17.1578C2.83899 17.808 2.83899 18.8622 3.48303 19.5124C4.12708 20.1625 5.17128 20.1625 5.81532 19.5124C6.3197 19.0032 6.42908 18.2462 6.14346 17.6296L6.98168 16.7834L8.91615 18.7363C9.13574 18.958 9.49178 18.958 9.71137 18.7363C9.93096 18.5146 9.93096 18.1552 9.71137 17.9335L8.6955 16.9079L11.1172 14.4631Z"
        fill="currentColor"
      />
    </svg>
  );
}
