import React from 'react';

export default function TextEditorNumbering({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8 11L3 13.1V14H6V13H4.2L6 10.9V10H3V11H4.8ZM3 17V16H6V20H3V19H5V18.5H4V17.5H5V17H3ZM8 7V5H21V7H8ZM21 11V13H8V11H21ZM5 8H4V5H3V4H5V8ZM21 17V19H8V17H21Z"
        fill="currentColor"
      />
    </svg>
  );
}
