import { useEffect, useState } from 'react';
import { useLatestValueRef } from '@front/helper';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import {
  TableLayoutConfig,
  TableLayoutRow,
} from '@lib/ia/src/layouts/TableLayout/types';

const DEFAULT_PAGES = 2;
const PRELOAD_ROWS = 10;

/**
 * We'll maintain two different pages
 * 1. remote pages
 * 2. local pages
 * to get better user experience
 */
export const usePageControl = ({
  data,
  rows,
}: {
  data: TableLayoutConfig['data'];
  rows: TableLayoutRow[];
}) => {
  const [pages, setPages] = useState(data.defaultPages || DEFAULT_PAGES);
  const { getIaAction } = useIaAction();
  const numberOfRowsDisplay = pages * data.pageSize;
  const hasMore = numberOfRowsDisplay < data.totalCount;
  const dataRef = useLatestValueRef(data);

  const loadMore = () => {
    const nextPages = pages + 1;
    setPages(nextPages);
  };

  useEffect(() => {
    const numberOfRowsShouldLoad =
      pages * dataRef.current.pageSize + PRELOAD_ROWS;
    const numberOfRowsLoaded = rows.length;

    if (
      numberOfRowsShouldLoad > numberOfRowsLoaded &&
      dataRef.current.state === 'notReachedEnd'
    ) {
      getIaAction(dataRef.current.loadMorePagesAction)?.action();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, rows.length]);

  return {
    numberOfRowsDisplay,
    hasMore,
    loadMore,
  };
};
