import React from 'react';

export default function EmojiBadge({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_14_1704)">
        <path
          d="M12 3L4 6.45455V11.6364C4 16.4295 7.41333 20.9118 12 22C16.5867 20.9118 20 16.4295 20 11.6364V6.45455L12 3ZM4.88889 8.18182H19.1111V10.7727H4.88889V8.18182Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_14_1704">
          <rect height="24" width="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
