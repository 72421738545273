import { replaceComponent } from '@front/ui';

type EmphasisProps = {
  content: string;
  regex?: RegExp;
};
export default function Emphasis({
  content,
  regex = /\*(.*?)\*/g,
}: EmphasisProps) {
  return (
    <>
      {replaceComponent(content, regex, (match, i) => {
        return <em key={match[0] + i}>{match[1]}</em>;
      })}
    </>
  );
}
