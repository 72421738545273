export enum PropertyType {
  ShortText = 'ShortText',
  LongText = 'LongText',
  Number = 'Number',
  Select = 'Select',
  MultiSelect = 'MultiSelect',
  DateOrTime = 'DateOrTime',
  Status = 'Status',
  Person = 'Person',
  FilesMedia = 'FilesMedia',
  Boolean = 'Boolean',
  Url = 'Url',
  Email = 'Email',
  Phone = 'Phone',
  ButtonCta = 'ButtonCta',
  LineColourStatus = 'LineColourStatus',
  Icon = 'Icon',
  Role = 'Role',
  Relation = 'Relation',
  UUID = 'UUID',
  ShortId = 'ShortId',
  Hashtag = 'Hashtag',
}
