import React from 'react';

export default function ChatThumbsDownSolid({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.7999 11.3999C21.7999 11.8773 21.6103 12.3351 21.2727 12.6727C20.9351 13.0103 20.4773 13.1999 19.9999 13.1999C19.5225 13.1999 19.0647 13.0103 18.7271 12.6727C18.3895 12.3351 18.1999 11.8773 18.1999 11.3999V4.1999C18.1999 3.72251 18.3895 3.26468 18.7271 2.92711C19.0647 2.58955 19.5225 2.3999 19.9999 2.3999C20.4773 2.3999 20.9351 2.58955 21.2727 2.92711C21.6103 3.26468 21.7999 3.72251 21.7999 4.1999V11.3999ZM16.9999 11.6003V5.0843C17.0002 4.63843 16.8763 4.20129 16.6421 3.82189C16.4079 3.44249 16.0726 3.13583 15.6739 2.9363L15.6139 2.9063C14.947 2.57296 14.2115 2.39958 13.4659 2.3999H6.9679C6.41287 2.39968 5.87492 2.59184 5.44564 2.94367C5.01636 3.2955 4.72229 3.78523 4.6135 4.3295L3.1735 11.5295C3.1039 11.8778 3.11244 12.2371 3.19853 12.5817C3.28462 12.9262 3.4461 13.2474 3.67132 13.522C3.89655 13.7965 4.17991 14.0177 4.50096 14.1695C4.82202 14.3214 5.17276 14.4 5.5279 14.3999H9.7999V19.1999C9.7999 19.8364 10.0528 20.4469 10.5028 20.897C10.9529 21.347 11.5634 21.5999 12.1999 21.5999C12.5182 21.5999 12.8234 21.4735 13.0484 21.2484C13.2735 21.0234 13.3999 20.7182 13.3999 20.3999V19.5995C13.3999 18.5609 13.7368 17.5504 14.3599 16.7195L16.0399 14.4803C16.663 13.6494 16.9999 12.6389 16.9999 11.6003Z"
        fill="currentColor"
      />
    </svg>
  );
}
