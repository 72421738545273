import { isPrime, number } from '@front/ui';
import { VariableSubType, VariableType } from '@lib/web/apis';

import { VariableDefaultValues, VariableValues } from './types';

export const DEFAULT_INTEGER_DECIMAL_MIN = 1;
export const DEFAULT_INTEGER_DECIMAL_MAX = 2;
export const DEFAULT_INTEGER_DECIMAL_DECIMAL = 0;
export const DEFAULT_INTEGER_DECIMAL_INCREMENT_STEP = 1;
export const DEFAULT_PRIME_LOWER_BOUND = 2;
export const DEFAULT_PRIME_UPPER_BOUND = 3;
export const DEFAULT_FACTOR_UPPER_BOUND = 1;
export const DEFAULT_FREE_INPUT_CONTENT = '1, 2, 3';

const numberVariableAttributes = [
  'id',
  'name',
  'subType',
  'value',
  'content',
  'minValue',
  'maxValue',
  'isEqualMinValue',
  'isEqualMaxValue',
  'decimalPlace',
  'incrementStep',
  'isAllowNegative',
  'isAllowZero',
];
// same as variable formula attributes
const textVariableAttributes = ['id', 'name', 'content'];

export const getUpdateValueByType = (
  value: GetCreatorQuestionVariableRes,
  type: VariableType
) => {
  const res: VariableDefaultValues = {};
  const attributes =
    type === VariableType.Number
      ? numberVariableAttributes
      : textVariableAttributes;
  attributes.forEach((key) => {
    if (value[key as keyof GetCreatorQuestionVariableRes] !== undefined) {
      res[key as keyof VariableDefaultValues] =
        value[key as keyof GetCreatorQuestionVariableRes];
    }
  });

  return res;
};

export const formParamsToApiParams = (
  params: VariableValues | Omit<VariableValues, 'id'>
) => {
  const updatedParams = { ...params };

  if (
    updatedParams.subType === VariableSubType.NumberDecimal &&
    (!updatedParams.decimalPlace || number(updatedParams.decimalPlace) === 0)
  ) {
    // integer and decimal are merged into the same option on Figma
    // an empty decimalPlace means that the sub type is an integer
    updatedParams.subType = VariableSubType.NumberInteger;
  }

  if (!updatedParams.value) updatedParams.value = undefined;

  updatedParams.decimalPlace = number(updatedParams.decimalPlace, 0);

  updatedParams.incrementStep = number(
    updatedParams.incrementStep,
    Math.pow(10, -updatedParams.decimalPlace)
  );

  // change zero value to boolean
  if (!updatedParams.isAllowNegative !== undefined)
    updatedParams.isAllowNegative = Boolean(updatedParams.isAllowNegative);
  if (!updatedParams.isAllowZero !== undefined)
    updatedParams.isAllowZero = Boolean(updatedParams.isAllowZero);

  updatedParams.isEqualMaxValue =
    updatedParams.isEqualMaxValue === undefined
      ? true
      : Boolean(updatedParams.isEqualMaxValue);
  updatedParams.isEqualMinValue =
    updatedParams.isEqualMinValue === undefined
      ? true
      : Boolean(updatedParams.isEqualMinValue);

  // special case for backend
  if (updatedParams.subType === VariableSubType.NumberPrime) {
    delete updatedParams.decimalPlace;
    delete updatedParams.incrementStep;
  }
  if (updatedParams.subType === VariableSubType.NumberFactor) {
    delete updatedParams.decimalPlace;
    delete updatedParams.incrementStep;
    delete updatedParams.isAllowZero;
  }
  if (updatedParams.subType === VariableSubType.NumberFree) {
    delete updatedParams.isEqualMaxValue;
    delete updatedParams.isEqualMinValue;
    delete updatedParams.decimalPlace;
    delete updatedParams.incrementStep;
  }

  return updatedParams;
};

export const getUniqueVariableName = (name: string, existNames: string[]) => {
  let lastCount = number(name.match(/\d+$/)?.[0], 1);
  const prefixName = name.replace(/\d+$/, '');

  let updatedName = `${prefixName}${lastCount}`;
  while (existNames.includes(updatedName)) {
    lastCount += 1;
    updatedName = `${prefixName}${lastCount}`;
  }

  return updatedName;
};

const getNumberMinMaxRules = (name: string) => {
  return {
    min: {
      value: -10000000000,
      message: `${name} cannot be lower than -10,000,000,000`,
    },
    max: {
      value: 10000000000,
      message: `${name} cannot exceed 10,000,000,000`,
    },
  };
};
export const getNumberVariableSettingsBySubType = (
  subType: VariableSubType,
  disabled: boolean
) => {
  if (
    subType === VariableSubType.NumberDecimal ||
    subType === VariableSubType.NumberInteger
  ) {
    return [
      {
        type: 'number' as const,
        label: 'Minimum Value',
        name: 'minValue',
        disabled,
        placeholder: String(DEFAULT_INTEGER_DECIMAL_MIN),
        rules: {
          ...getNumberMinMaxRules('Minimum Value'),
        },
        customRules: {
          regex: {
            value: /^-?(0|[1-9]\d*)(\.\d+)?$/,
            message: '‘{value}’ is not a valid value',
          },
          maxValueWithField: {
            field: 'maxValue',
            message: 'Lower Bound cannot exceed Upper Bound',
          },
        },
      },
      {
        type: 'number' as const,
        label: 'Maximum Value',
        name: 'maxValue',
        disabled,
        placeholder: String(DEFAULT_INTEGER_DECIMAL_MAX),
        rules: {
          ...getNumberMinMaxRules('Maximum Value'),
        },
        customRules: {
          regex: {
            value: /^-?(0|[1-9]\d*)(\.\d+)?$/,
            message: '‘{value}’ is not a valid value',
          },
          minValueWithField: {
            field: 'minValue',
            message: 'Upper Bound cannot be lower than Lower Bound',
          },
        },
      },
      {
        type: 'number' as const,
        label: 'Decimal Places (Optional)',
        name: 'decimalPlace',
        disabled,
        placeholder: String(DEFAULT_INTEGER_DECIMAL_DECIMAL),
        rules: {
          max: {
            value: 8,
            message: 'Decimal Places cannot exceed 8',
          },
        },
        customRules: {
          regex: {
            value: /^(0|[1-9]\d*)(\.\d+)?$/,
            message: '‘{value}’ is not a valid value',
          },
        },
      },
      {
        type: 'number' as const,
        label: 'Increment Step (Optional)',
        name: 'incrementStep',
        disabled,
        placeholder: String(DEFAULT_INTEGER_DECIMAL_INCREMENT_STEP),
        helperText: 'Determine how much value changes with each step',
        rules: {
          max: {
            value: 8,
            message: 'Increment Step cannot exceed 8',
          },
        },
        customRules: {
          regex: {
            value:
              subType === VariableSubType.NumberInteger
                ? /^[1-9]\d*$/
                : /^(0\.\d+|[1-9]\d*(\.\d+)?)$/,
            message: '‘{value}’ is not a valid value',
          },
        },
      },
      {
        type: 'gap' as const,
        value: 0,
      },
      {
        type: 'group' as const,
        gap: 0,
        items: [
          {
            type: 'checkbox' as const,
            label: 'Can be negative',
            labelPosition: 'start' as const,
            name: 'isAllowNegative',
            disabled,
            helperText: 'Allow negative numbers as possible values',
          },
          {
            type: 'checkbox' as const,
            label: 'Can be zero',
            labelPosition: 'start' as const,
            name: 'isAllowZero',
            disabled,
            helperText: 'Allow ‘0’ as possible value',
          },
        ],
      },
    ];
  }

  if (subType === VariableSubType.NumberPrime) {
    return [
      {
        type: 'number' as const,
        label: 'Integral Lower Bound',
        name: 'minValue',
        disabled,
        placeholder: String(DEFAULT_PRIME_LOWER_BOUND),
        rules: {
          ...getNumberMinMaxRules('Integral Lower Bound'),
        },
        customRules: {
          regex: {
            value: /^\d+$/,
            message: '‘{value}’, is not a valid value',
          },
          maxValueWithField: {
            field: 'maxValue',
            message: 'Integral Lower Bound cannot exceed Integral Upper Bound',
          },
        },
      },
      {
        type: 'number' as const,
        label: 'Integral Upper Bound',
        name: 'maxValue',
        disabled,
        placeholder: String(DEFAULT_PRIME_UPPER_BOUND),
        rules: {
          ...getNumberMinMaxRules('Integral Upper Bound'),
        },
        customRules: {
          regex: {
            value: /^\d+$/,
            message: '‘{value}’, is not a valid value',
          },
          minValueWithField: {
            field: 'minValue',
            message:
              'Integral Upper Bound cannot be lower than Integral Lower Bound',
          },
        },
      },
    ];
  }
  if (subType === VariableSubType.NumberFactor) {
    return [
      {
        type: 'group' as const,
        items: [
          {
            type: 'number' as const,
            label: 'Integral Upper Bound',
            name: 'value',
            disabled,
            placeholder: String(DEFAULT_FACTOR_UPPER_BOUND),
            rules: {
              ...getNumberMinMaxRules('Integral Upper Bound'),
            },
            customRules: {
              regex: {
                value: /^-?(0|[1-9]\d*)(\.\d+)?$/,
                message: '‘{value}’ is not a valid value',
              },
              differentAs: {
                value: 0,
                message: 'Integral Upper Bound cannot be ‘0’',
              },
              validate: {
                handler: (value: any) => {
                  if (isPrime(value))
                    return 'Integral Upper Bound cannot be a prime number';

                  return true;
                },
              },
            },
          },
        ],
      },
      {
        type: 'gap' as const,
        value: 0,
      },
      {
        type: 'group' as const,
        gap: 0,
        items: [
          {
            type: 'checkbox' as const,
            label: 'Can be negative',
            labelPosition: 'start' as const,
            name: 'isAllowNegative',
            disabled,
            helperText: 'Allow negative numbers as possible values',
          },
        ],
      },
    ];
  }

  if (subType === VariableSubType.NumberFree)
    return [
      {
        type: 'textarea' as const,
        label: 'Value',
        name: 'content', // Text Variable
        disabled,
        placeholder: DEFAULT_FREE_INPUT_CONTENT,
        helperText: 'Separate each value with a comma ( , )',
        minRows: 8,
        customRules: {
          textInRegex: {
            value: /^(-?(0|[1-9]\d*)(\.\d+)?$)|^$/,
            message: '‘{value}’ is not a valid value',
            separator: ',',
          },
        },
      },
    ];

  return [];
};

export const combineWithNumberVariableDefaultValue = ({
  subType,
  currentValue,
}: {
  subType: VariableSubType;
  currentValue?: Partial<VariableValues>;
}) => {
  if (
    subType === VariableSubType.NumberDecimal ||
    subType === VariableSubType.NumberInteger
  ) {
    return {
      minValue: number(currentValue?.minValue, DEFAULT_INTEGER_DECIMAL_MIN),
      maxValue: number(currentValue?.maxValue, DEFAULT_INTEGER_DECIMAL_MAX),
      decimalPlace: number(
        currentValue?.decimalPlace,
        DEFAULT_INTEGER_DECIMAL_DECIMAL
      ),
      incrementStep: number(
        currentValue?.incrementStep,
        DEFAULT_INTEGER_DECIMAL_INCREMENT_STEP
      ),
      isAllowNegative: currentValue?.isAllowNegative ?? false,
      isAllowZero: currentValue?.isAllowZero ?? false,
    };
  }
  if (subType === VariableSubType.NumberPrime) {
    return {
      minValue: number(currentValue?.minValue, DEFAULT_PRIME_LOWER_BOUND),
      maxValue: number(currentValue?.maxValue, DEFAULT_PRIME_UPPER_BOUND),
    };
  }
  if (subType === VariableSubType.NumberFactor) {
    return {
      value: number(currentValue?.value, DEFAULT_FACTOR_UPPER_BOUND),
      isAllowNegative: currentValue?.isAllowNegative ?? false,
    };
  }
  if (subType === VariableSubType.NumberFree) {
    return {
      content: currentValue?.content || DEFAULT_FREE_INPUT_CONTENT,
    };
  }

  return {
    content: '',
  };
};
