import {
  EditorValue as EditorValueIcon,
  TextEditorTypeHeader3 as TextEditorTypeHeader3Icon,
  TextEditorTypeText as TextEditorTypeTextIcon,
} from '@front/icon';
import {
  BasicComposerSchema,
  ComposerToolbarMenuItem,
} from '@lib/web/composer';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';

const turnIntoText: ComposerToolbarMenuItem<BasicComposerSchema> = {
  nodeType: BasicBlockTypes.Paragraph,
  type: 'turnInto',
  icon: <TextEditorTypeTextIcon width={16} height={16} />,
  display: 'Text',
  hint: '/text',
  onClick: (editor, onConvertBlockType) => {
    onConvertBlockType?.(BasicBlockTypes.Paragraph);

    editor.getSelection()?.blocks.forEach((block) => {
      editor.updateBlock(block, {
        type: BasicBlockTypes.Paragraph,
      });
    });
  },
  isSelected: (block) => block.type === BasicBlockTypes.Paragraph,
};

const turnIntoHeader: ComposerToolbarMenuItem<BasicComposerSchema> = {
  nodeType: BasicBlockTypes.Heading,
  type: 'turnInto',
  icon: <TextEditorTypeHeader3Icon width={16} height={16} />,
  display: 'Header 3',
  hint: '/h3',
  onClick: (editor, onConvertBlockType) => {
    onConvertBlockType?.(BasicBlockTypes.Heading);

    editor.getSelection()?.blocks.forEach((block) => {
      editor.updateBlock(block, {
        type: BasicBlockTypes.Heading,
        props: {
          level: 3,
        },
      });
    });
  },
  isSelected: (block) =>
    block.type === BasicBlockTypes.Heading && block.props.level === 3,
};

const turnIntoSubtitle: ComposerToolbarMenuItem<BasicComposerSchema> = {
  nodeType: BasicBlockTypes.Subtitle,
  type: 'turnInto',
  icon: <TextEditorTypeTextIcon width={16} height={16} />,
  display: 'Subtitle',
  hint: '/subtitle',
  onClick: (editor, onConvertBlockType) => {
    onConvertBlockType?.(BasicBlockTypes.Subtitle);

    editor.getSelection()?.blocks.forEach((block) => {
      editor.updateBlock(block, {
        type: BasicBlockTypes.Subtitle,
      });
    });
  },
  isSelected: (block) => block.type === BasicBlockTypes.Subtitle,
};

const turnIntoStep: ComposerToolbarMenuItem<BasicComposerSchema> = {
  nodeType: BasicBlockTypes.Step,
  type: 'turnInto',
  icon: <EditorValueIcon width={16} height={16} />,
  display: 'Step',
  hint: '/step',
  onClick: (editor, onConvertBlockType) => {
    onConvertBlockType?.(BasicBlockTypes.Step);

    editor.getSelection()?.blocks.forEach((block) => {
      editor.updateBlock(block, {
        type: BasicBlockTypes.Step,
      });
    });
  },
  isSelected: (block) => block.type === BasicBlockTypes.Step,
};

export const basicToolbarMenu = {
  turnIntoText,
  turnIntoHeader,
  turnIntoSubtitle,
  turnIntoStep,
};

export const basicToolbarMenuItems = [
  turnIntoText,
  turnIntoHeader,
  turnIntoSubtitle,
  turnIntoStep,
];
