import { useMemo } from 'react';
import { nonNullable } from '@front/helper';
import {
  ChannelLayoutConfig,
  ChannelLayoutLoadingConfig,
} from '@lib/ia/src/layouts/ChannelLayout/types';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

export const useThreadMessageChannelLayoutConfig = ({
  channel,
  messages,
  isLoading,
  messageIdToChildChannel,
  availableEventNames = [],
}: {
  channel: Channel;
  messages: StreamMessage<StreamChatGenerics>[] | undefined;
  isLoading: boolean;
  messageIdToChildChannel: Record<string, Channel>;
  availableEventNames?: string[];
}) => {
  return useMemo(() => {
    const items =
      messages
        ?.map((message, index) => {
          const childChannel = messageIdToChildChannel[message.id];

          if (index !== 0 && message.type === 'deleted' && !childChannel) {
            return null;
          }

          return {
            type: 'customize',
            value: 'threadMessageItem',
            metadata: {
              channel,
              message,
              messages,
              childChannel,
              availableEventNames,
            },
          };
        })
        .filter(nonNullable) || [];

    return [
      {
        layout: 'channel-layout',
        items,
      } as ChannelLayoutConfig,
      ...(isLoading
        ? [
            {
              layout: 'channel-layout-skeleton',
            } as ChannelLayoutLoadingConfig,
          ]
        : []),
    ];
  }, [
    availableEventNames,
    channel,
    isLoading,
    messageIdToChildChannel,
    messages,
  ]);
};
