import { useCallback, useRef, useState } from 'react';

type Options = {
  delay?: number;
};
export default function useTempState<T>(
  initValue?: T,
  { delay = 1000 }: Options = {}
): [T | undefined, (val: T) => void] {
  const [currentVal, setCurrentVal] = useState(initValue);
  const copyTimeRef = useRef<NodeJS.Timeout>();

  const setValue = useCallback(
    (nextVal: T): void => {
      setCurrentVal(nextVal);
      if (copyTimeRef.current) {
        clearTimeout(copyTimeRef.current);
      }
      copyTimeRef.current = setTimeout(() => {
        setCurrentVal(initValue);
      }, delay);
    },
    [initValue, delay]
  );

  return [currentVal, setValue];
}
