import { SortCriteriaConfig, SortField } from '@lib/ia/src/sort/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  criteria: Record<string, SortCriteriaConfig[]>;
  fields: Record<string, SortField[]>;
} = {
  criteria: { global: [] },
  fields: { global: [] },
};
type Scope = { scope: string };

const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    setCriteria: (
      state,
      { payload }: PayloadAction<{ criteria: SortCriteriaConfig[] } & Scope>
    ) => {
      state.criteria[payload.scope] = payload.criteria;
    },
    resetSort: (state, { payload }: PayloadAction<Scope>) => {
      if (state.criteria[payload.scope]) {
        state.criteria[payload.scope] = state.criteria[payload.scope].filter(
          (item) => item.disabled
        );
      } else {
        state.criteria[payload.scope] = [];
      }
    },
    setFields: (
      state,
      { payload }: PayloadAction<{ fields: SortField[] } & Scope>
    ) => {
      state.fields[payload.scope] = payload.fields;
    },
    upsertCriteria: (
      state,
      { payload }: PayloadAction<{ criteria: SortCriteriaConfig } & Scope>
    ) => {
      if (!state.criteria[payload.scope]) {
        state.criteria[payload.scope] = [];
      }
      const existingIndex = state.criteria[payload.scope].findIndex(
        (item) => item.id === payload.criteria.id
      );
      if (existingIndex === -1) {
        state.criteria[payload.scope] = [
          ...(state.criteria[payload.scope] || []),
          payload.criteria,
        ];
        return;
      }

      const criteria = [...(state.criteria[payload.scope] || [])];
      criteria.splice(existingIndex, 1, payload.criteria);
      state.criteria[payload.scope] = criteria;
    },
    removeCriteria: (
      state,
      { payload: { id, scope } }: PayloadAction<{ id: string } & Scope>
    ) => {
      state.criteria[scope] =
        state.criteria[scope]?.filter((item) => item.id !== id) || [];
    },
  },
});

export const {
  setCriteria,
  resetSort,
  setFields,
  upsertCriteria,
  removeCriteria,
} = sortSlice.actions;
export default sortSlice.reducer;
