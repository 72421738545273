import { basicTiptapExtensions } from '@lib/web/composer';
import { InlineMention } from '@lib/web/composer/TextComposer/components/blocks/InlineMention';
import { InlineLatex } from '@lib/web/thread/ThreadTextComposer/components/blocks/InlineLatex';

import { InlineHighlight } from '../../EditorTextComposer/components/blocks/InlineHighlight';
import { InlineVariable } from '../../EditorTextComposer/components/blocks/InlineVariable';
import { InlineLineMarker } from '../components/blocks/InlineLineMarker';

export const editorThreadTiptapExtensions = [
  ...basicTiptapExtensions,
  InlineLatex,
  InlineMention,
  InlineHighlight,
  InlineLineMarker,
  InlineVariable,
];
