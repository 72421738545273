import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

export type BaseLayoutComponentProps = {
  sx?: BoxProps['sx'];
  children: BoxProps['children'];
};

export default function BaseLayoutComponent({
  sx,
  children,
}: BaseLayoutComponentProps) {
  if (children === undefined) return null;

  return <Box sx={sx}>{children}</Box>;
}
