import getWorkflowPageConfig from '@app/web/src/layouts/pageConfig/workflow';
import { ParsedUrlQuery } from 'querystring';

export const getAllBreadcrumbsValues = (
  _pathname: string,
  query: ParsedUrlQuery,
  t: any
) => {
  const clubSlug = query.slug;
  const workflowId = query.workflowId;
  const prefix = `/club/${clubSlug}/workflow/${workflowId}`;
  const workflowConfig = getWorkflowPageConfig(t);
  return {
    entry: {
      text: workflowConfig.entry.title,
      icon: workflowConfig.entry.icon,
      href: `${prefix}`,
    },
  };
};
