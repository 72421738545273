/* eslint-disable @typescript-eslint/no-explicit-any */
import { MouseEvent, useEffect, useState } from 'react';
import { Box, BoxProps, ButtonBase, Theme, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useLatestValueRef } from '@front/helper';
import { numberFormat } from '@front/ui';
import { ExamMode } from '@lib/web/apis';
import useTextComposerRender from '@lib/web/composer/TextComposer/hooks/useTextComposerRender';
import EditorComposerRenderer from '@lib/web/editor/EditorTextComposer/EditorComposerRenderer';
import { ChartRenderBox } from '@lib/web/quiz';
import { floatMul } from '@lib/web/utils';

import { BLANK_OPTION_VALUE } from '../../../../../types';

import PracticeEliminateToggle from './components/PracticeEliminateToggle';
import PracticeSelectedRatio from './components/PracticeSelectedRatio';
import PracticeSelectedRatioTooltip from './components/PracticeSelectedRatioTooltip';
import useKeyboardShortcutSelect from './hooks/useKeyboardShortcutSelect';
import { reorderOptions } from './utils';

const styles = {
  horizontalWrapper: {
    overflow: 'hidden',
  },
  verticalBox: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    userSelect: 'none',
  },
  horizontalBox: {
    display: 'grid',
    gap: 1,
    gridAutoFlow: 'column',
    overflowX: 'auto',
    px: '20px',
    scrollSnapType: 'x mandatory',
    justifyContent: 'left',
  },
  nowrapBox: {
    overflowX: 'auto',
    display: 'grid',
    px: '20px',
  },
  itemWrapper: {
    position: 'relative',
    mb: 2,
  },
  submittedItem: {
    '& .option-ratio': {
      display: 'none',
    },
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '& .option-ratio': {
          display: 'flex',
        },
        '& .option-symbol': {
          display: 'none',
        },
      },
    },
  },
  horizontalItem: {
    width: 'max-content',
    maxWidth: '335px',
    scrollSnapAlign: 'center',
    mb: 0,
  },
  nowrapItem: {
    maxWidth: 'unset',
    scrollSnapAlign: 'unset',
  },
  root: (theme: Theme) => {
    const isDark = theme.palette.mode === 'dark';

    return {
      background: isDark ? alpha(theme.palette.text.primary, 0.05) : '',
      borderRadius: '12px',
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      justifyContent: 'flex-start',
      boxShadow: isDark
        ? `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.1)}`
        : `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.3)}`,
      '&.Mui-disabled': { pointerEvents: 'unset' },
      bgcolor: isDark ? 'unset' : theme.palette.background.body,
    };
  },
  blankOption: {
    minHeight: '56px',
  },
  innerLayer: {
    position: 'absolute',
    top: 3,
    left: 3,
    right: 3,
    bottom: 3,
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '12px',
  },
  innerContent: {
    display: 'grid',
    gridTemplateColumns: '54px 1fr',
    position: 'relative',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    userSelect: 'none',
    gap: 1,
  },
  innerContentWithElimination: {
    gridTemplateColumns: '54px 1fr 44px',
  },
  text: (theme: Theme) => ({
    flex: 1,
    textAlign: 'left',
    opacity: 0.8,
    typography: 'body2',
    fontWeight: 400,
    alignSelf: 'stretch',
    fontFamily: theme.palette.mode === 'light' ? 'Georgia' : '',
    minHeight: '100%',
  }),
  textItemsCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  textNowrap: {
    whiteSpace: 'nowrap',
  },
  symbol: (theme: Theme) => ({
    margin: 'auto',
    ml: 2,
    height: 32,
    width: 32,
    flex: '0 0 32px',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.05)
        : alpha(theme.palette.text.primary, 0.1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiTypography-root': {
      fontFamily: theme.palette.mode === 'light' ? 'Georgia' : '',
    },
  }),
  symbolCircle: {
    borderRadius: '100%',
  },
  symbolSquare: {
    borderRadius: '6px',
  },
  symbolSelected: {
    bgcolor: 'text.primary',
    color: 'background.default',
  },

  submitted: (theme: Theme) => {
    const isDark = theme.palette.mode === 'dark';

    return {
      cursor: 'default',
      pointerEvents: 'none',
      userSelect: 'none',
      boxShadow: isDark
        ? `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.1)}`
        : `inset 0 0 0 3px ${theme.palette.grey[200]}`,
      '& .option-ratio': {
        backgroundColor: alpha(theme.palette.text.primary, 0.1),
      },
      pr: isDark ? '9px' : '11px',
      '& .chart-render-inner': {
        pr: 0,
      },
    };
  },
  answer: (theme: Theme) => {
    const isDark = theme.palette.mode === 'dark';
    const mainColor = isDark
      ? theme.palette.success.light
      : theme.palette.success.dark;
    return {
      boxShadow: `inset 0 0 0 3px ${mainColor}`,
      '& .option-ratio': {
        backgroundColor: alpha(mainColor, 0.3),
      },
      '& .option-symbol': {
        backgroundColor: mainColor,
        color: theme.palette.background.darker,
      },
      '& .option-text, & .katex': {
        fontWeight: 'bold',
      },
      '& .chart-render-inner': {
        fontWeight: 700,
      },
      pr: '11px',
    };
  },
  correct: (theme: Theme) => {
    const isDark = theme.palette.mode === 'dark';
    const mainColor = isDark
      ? theme.palette.success.light
      : theme.palette.success.dark;
    return {
      boxShadow: `inset 0 0 0 3px ${mainColor}`,
      backgroundColor: alpha(mainColor, 0.3),
      '& .option-ratio': {
        backgroundColor: mainColor,
        color: theme.palette.background.darker,
      },
      '& .option-symbol': {
        backgroundColor: mainColor,
        color: theme.palette.background.darker,
      },
      '& .option-text, & .katex': {
        fontWeight: 'bold',
      },
      '& .chart-render-inner': {
        fontWeight: 700,
      },
      pr: '11px',
    };
  },
  incorrect: (theme: Theme) => ({
    boxShadow: `inset 0 0 0 3px ${theme.palette.error.dark}`,
    backgroundColor: alpha(theme.palette.error.dark, 0.2),
    '& .option-ratio': {
      backgroundColor: 'error.dark',
      color: 'white',
    },
    '& .option-symbol': {
      backgroundColor: 'error.dark',
      color: 'white',
    },
    pr: '11px',
  }),
  selected: (theme: Theme) => ({
    boxShadow: `inset 0 0 0 3px ${theme.palette.text.primary}`,
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : theme.palette.background.body,
  }),
  eliminated: {
    '& .option-text, & .chart-render-inner, & .katex span': {
      textDecorationLine: 'line-through',
    },
    '&.Mui-disabled': { opacity: 0.5 },
  },
  horizontal: {
    height: '100%',
  },
  hover: (theme: Theme) => ({
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        boxShadow:
          theme.palette.mode === 'light'
            ? `inset 0 0 0 1px ${alpha(
                theme.palette.text.primary,
                0.3
              )}, 0px 8px 16px ${alpha(theme.palette.text.primary, 0.15)}`
            : `inset 0 0 0 1px ${alpha(
                theme.palette.text.primary,
                0.3
              )}, 0px 8px 16px rgba(0, 0, 0, 0.15)`,
        bgcolor:
          theme.palette.mode === 'dark'
            ? alpha(theme.palette.text.primary, 0.1)
            : theme.palette.grey[100],
      },
    },
  }),
  disabled: {
    cursor: 'default',
  },

  blankOptionContent: {
    py: '16px',
    lineHeight: '24px',
  },

  selectionHint: {
    my: '1px',
    textAlign: 'right',
    position: 'relative',
    typography: 'body2',
    fontFamily: (theme: Theme) =>
      theme.palette.mode === 'light' ? 'Georgia' : '',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    span: {
      color: 'text.primary',
    },
  },
};

interface PracticeMultipleChoicesProps {
  questionId?: string;
  sx?: BoxProps['sx'];
  variant?: 'circle' | 'square';
  options: {
    id: string;
    key: string;
    value: string;
    selectedRatio?: number;
  }[];
  minSelectedCount?: number;
  maxSelectedCount?: number;
  onChange?: (value: string[]) => void;
  value?: string[];
  disabled?: boolean;
  submitted?: boolean;
  incorrect?: boolean;
  scrollable?: boolean;
  disableSortedByKey?: boolean;
  disableSortedByEliminatedAnswers?: boolean;
  readonly?: boolean;
  correctAnswers?: string[];
  mode?: ExamMode;
  onEliminationChange?: (value: string[]) => void;
  eliminatedAnswers?: string[];
  horizontal?: boolean;
  nowrap?: boolean;
  mappedMaterials?: Record<string, GetQuizMaterialRes>;
  blankOptionEnabled?: boolean;
  blankOptionStatistic?: GetQuizQuestionOptionStatisticRes;
}

interface PracticeMultipleChoicesBlankOptionProps {
  disabled?: boolean;
  selected?: boolean;
  onClick?: (ev: MouseEvent<HTMLDivElement>) => void;
  submitted?: boolean;
  selectedRatio?: number;
  value: string;
  mode?: ExamMode;
  horizontal?: boolean;
  nowrap?: boolean;
}

function PracticeMultipleChoicesBlankOption({
  disabled = false,
  selected = false,
  value,
  submitted = false,
  selectedRatio = 0,
  onClick = () => null,
  mode,
  horizontal = false,
  nowrap = false,
  ...rest
}: PracticeMultipleChoicesBlankOptionProps) {
  const selectedRatioString = numberFormat(floatMul(selectedRatio, 100), 1);

  const contentRender = (
    <Typography
      sx={[
        styles.text,
        styles.blankOptionContent,
        horizontal && styles.textItemsCenter,
        nowrap && styles.textNowrap,
      ]}
      className="option-text"
      variant="body1"
    >
      Leave blank
    </Typography>
  );

  const symbolRender = (
    <Box
      sx={[
        styles.symbol,
        styles.symbolCircle,
        selected && styles.symbolSelected,
      ]}
      className="option-symbol"
    >
      <Typography variant="body2"></Typography>
    </Box>
  );

  if (submitted)
    return (
      <Box
        sx={[
          styles.itemWrapper,
          styles.submittedItem,
          horizontal && styles.horizontalItem,
          nowrap && styles.nowrapItem,
        ]}
        className="multiple-choices-item"
      >
        <PracticeSelectedRatioTooltip value={selectedRatioString} />
        <ButtonBase
          sx={[
            styles.root,
            styles.blankOption,
            styles.submitted,
            selected && styles.selected,
            horizontal && styles.horizontal,
          ]}
          component="div"
          disableRipple
          {...rest}
        >
          <Box sx={styles.innerContent}>
            <PracticeSelectedRatio display={selectedRatioString} />
            {symbolRender}
            {contentRender}
          </Box>
        </ButtonBase>
      </Box>
    );
  return (
    <Box
      sx={[
        styles.itemWrapper,
        horizontal && styles.horizontalItem,
        nowrap && styles.nowrapItem,
      ]}
      className="multiple-choices-item"
    >
      <ButtonBase
        sx={[
          styles.root,
          styles.blankOption,
          !selected && styles.hover,
          selected && styles.selected,
          horizontal && styles.horizontal,
          disabled && styles.disabled,
        ]}
        component="div"
        onClick={disabled ? undefined : onClick}
        disableRipple={disabled}
        {...rest}
      >
        {selected && <Box sx={styles.innerLayer} />}
        <Box sx={[styles.innerContent, styles.innerContentWithElimination]}>
          {symbolRender}
          {contentRender}
        </Box>
      </ButtonBase>
    </Box>
  );
}

interface PracticeMultipleChoicesItemProps {
  disabled?: boolean;
  selected?: boolean;
  scrollable?: boolean;
  onClick?: (ev: MouseEvent<HTMLDivElement>) => void;
  correct?: boolean;
  incorrect?: boolean;
  submitted?: boolean;
  isAnswer?: boolean;
  selectedRatio?: number;
  symbol: string;
  value: string;
  variant?: 'circle' | 'square';
  mode?: ExamMode;
  onEliminate?: (ev: MouseEvent<HTMLButtonElement>) => void;
  eliminated?: boolean;
  horizontal?: boolean;
  nowrap?: boolean;
}

function PracticeMultipleChoicesItem({
  disabled = false,
  selected = false,
  correct = false,
  incorrect = false,
  isAnswer = false,
  scrollable = false,
  value,
  submitted = false,
  selectedRatio = 0,
  symbol = '-',
  variant = 'circle',
  onClick = () => null,
  mode,
  onEliminate = () => null,
  eliminated = false,
  horizontal = false,
  nowrap = false,
  ...rest
}: PracticeMultipleChoicesItemProps) {
  const clickDisabled = disabled || (!submitted && eliminated);
  const selectedRatioString = numberFormat(floatMul(selectedRatio, 100), 1);
  const { renderOptions } = useTextComposerRender();
  const contentRender = (
    <EditorComposerRenderer styleProvider>
      <ChartRenderBox
        sx={[
          styles.text,
          horizontal && styles.textItemsCenter,
          nowrap && styles.textNowrap,
        ]}
        className="option-text"
        tex={value}
        scrollable={scrollable}
        renderOptions={renderOptions}
      />
    </EditorComposerRenderer>
  );

  const symbolRender = (
    <Box
      sx={[
        styles.symbol,
        variant === 'circle' ? styles.symbolCircle : styles.symbolSquare,
        selected && styles.symbolSelected,
      ]}
      className="option-symbol"
    >
      <Typography variant="body2">{symbol}</Typography>
    </Box>
  );

  if (submitted) {
    return (
      <Box
        sx={[
          styles.itemWrapper,
          styles.submittedItem,
          horizontal && styles.horizontalItem,
          nowrap && styles.nowrapItem,
        ]}
        className="multiple-choices-item"
        data-symbol={symbol}
      >
        <PracticeSelectedRatioTooltip
          symbol={symbol}
          correct={correct || isAnswer}
          value={selectedRatioString}
        />

        <ButtonBase
          sx={[
            styles.root,
            styles.submitted,
            isAnswer && styles.answer,
            incorrect && styles.incorrect,
            correct && styles.correct,
            horizontal && styles.horizontal,
          ]}
          component="div"
          disableRipple
          {...rest}
        >
          <Box sx={styles.innerContent}>
            <PracticeSelectedRatio display={selectedRatioString} />
            {symbolRender}
            {contentRender}
          </Box>
        </ButtonBase>
      </Box>
    );
  }
  return (
    <Box
      sx={[
        styles.itemWrapper,
        horizontal && styles.horizontalItem,
        nowrap && styles.nowrapItem,
      ]}
      className="multiple-choices-item"
      data-symbol={symbol}
    >
      <ButtonBase
        sx={[
          styles.root,
          !selected && !disabled && styles.hover,
          selected && styles.selected,
          eliminated && styles.eliminated,
          horizontal && styles.horizontal,
          disabled && styles.disabled,
        ]}
        component="div"
        onClick={clickDisabled ? undefined : onClick}
        disableRipple={clickDisabled}
        {...rest}
      >
        {selected && <Box sx={styles.innerLayer} />}
        <Box sx={[styles.innerContent, styles.innerContentWithElimination]}>
          {symbolRender}
          {contentRender}
        </Box>
      </ButtonBase>
      {!disabled && (
        <PracticeEliminateToggle
          mode={mode}
          eliminated={eliminated}
          selected={selected}
          onEliminate={onEliminate}
        />
      )}
    </Box>
  );
}

export default function PracticeMultipleChoices({
  questionId,
  sx,
  variant,
  options = [],
  correctAnswers = [],
  maxSelectedCount = 1,
  incorrect,
  onChange,
  value = [],
  submitted = false,
  disabled = false,
  scrollable = false,
  disableSortedByKey = false,
  disableSortedByEliminatedAnswers = false,
  readonly = false,
  mode,
  eliminatedAnswers = [],
  onEliminationChange,
  horizontal = false,
  nowrap = false,
  blankOptionEnabled = false,
  blankOptionStatistic,
}: PracticeMultipleChoicesProps) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const handleChange = (id: string) => {
    if (onChange) {
      if (value.includes(id)) onChange(value.filter((v) => v !== id));
      else if (maxSelectedCount === 1) {
        onChange([id]);
      } else if (!maxSelectedCount || value.length < maxSelectedCount) {
        onChange([...value, id]);
      }
    }
  };

  const handleClick = (
    ev: MouseEvent<HTMLDivElement>,
    id: string,
    index: number
  ) => {
    ev.currentTarget.blur();
    handleChange(id);
    setActiveIndex(index);
  };

  const handleEliminate = (id: string) => {
    if (onEliminationChange) {
      if (eliminatedAnswers.includes(id)) {
        onEliminationChange(eliminatedAnswers.filter((v) => v !== id));
      } else {
        onEliminationChange([...eliminatedAnswers, id]);
      }
    }
  };
  const handleEliminateClick = (
    ev: MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    ev.currentTarget.blur();
    handleEliminate(id);
  };

  const orderedOptions = submitted
    ? options
    : reorderOptions(
        options,
        eliminatedAnswers,
        disableSortedByKey,
        disableSortedByEliminatedAnswers
      );

  const latestValueRef = useLatestValueRef(value);
  const latestOptionsRef = useLatestValueRef(orderedOptions);

  useKeyboardShortcutSelect({
    options,
    onSelect: handleChange,
    activeIndex,
    setActiveIndex,
    disabled: disabled || readonly,
  });

  useEffect(() => {
    if (questionId) {
      const currentIndex = latestOptionsRef.current.findIndex((option) =>
        latestValueRef.current.includes(option.id)
      );
      setActiveIndex(currentIndex);
    } else {
      setActiveIndex(-1);
    }
  }, [questionId, latestValueRef, latestOptionsRef]);

  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={[...sxProps, horizontal && styles.horizontalWrapper]}>
      <Box
        sx={[
          horizontal ? styles.horizontalBox : styles.verticalBox,
          nowrap && styles.nowrapBox,
        ]}
        className="multiple-choices-wrapper"
      >
        {orderedOptions.map((item: Record<string, any>, i) => {
          const selected = value?.includes(item.id);
          const eliminated = eliminatedAnswers?.includes(item.id);
          return (
            <PracticeMultipleChoicesItem
              selected={selected}
              onClick={(ev) => handleClick(ev, item.id, i)}
              selectedRatio={item.selectedRatio}
              submitted={submitted}
              disabled={disabled || readonly}
              isAnswer={
                submitted && !selected && correctAnswers.includes(item.id)
              }
              variant={variant}
              correct={
                submitted && selected && correctAnswers.includes(item.id)
              }
              incorrect={submitted && selected && incorrect}
              symbol={item.key}
              value={item.value}
              key={item.id}
              scrollable={scrollable}
              mode={mode}
              onEliminate={(ev) => handleEliminateClick(ev, item.id)}
              eliminated={eliminated}
              horizontal={horizontal}
              nowrap={nowrap}
            />
          );
        })}
        {blankOptionEnabled && (
          <PracticeMultipleChoicesBlankOption
            selected={value?.includes(BLANK_OPTION_VALUE)}
            onClick={(ev) =>
              handleClick(ev, BLANK_OPTION_VALUE, options.length)
            }
            selectedRatio={blankOptionStatistic?.selectedRatio}
            submitted={submitted}
            disabled={disabled || readonly}
            value={BLANK_OPTION_VALUE}
            mode={mode}
            horizontal={horizontal}
            nowrap={nowrap}
          />
        )}
      </Box>
    </Box>
  );
}
