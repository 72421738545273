import React from 'react';

export default function TradingLine({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.46412 19.8443C2.99787 19.5483 2.85989 18.9304 3.15591 18.4641L8.37801 10.2393L13.9854 13.5103L19.1319 4.50399C19.4059 4.02447 20.0167 3.85787 20.4963 4.13188C20.9758 4.40589 21.1424 5.01675 20.8684 5.49626L14.7207 16.2546L9.03401 12.9374L4.84434 19.5361C4.54831 20.0024 3.93036 20.1404 3.46412 19.8443Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
