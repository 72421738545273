import { BlockNoteEditor, BlockSchema } from '@blocknote/core';

export const getLastSelectionPos: <BSchema extends BlockSchema>(
  editor: BlockNoteEditor<BSchema>
) => [number, number] = (editor) => {
  const { to, from } = editor._tiptapEditor.state.selection;
  const startPos = Math.min(to, from);
  const endPos = Math.max(to, from);

  return [startPos, endPos];
};
