import { TFunction } from 'next-i18next';

const getPracticePageConfig = (t: TFunction) => ({
  practiceResults: {
    title: t('practice.results.title'),
    icon: 'TestScoreTally',
  },
  practiceResultsAwards: {
    title: t('practice.results_awards.title'),
    icon: 'ProfileAwards',
  },
});

export default getPracticePageConfig;
