import { Box } from '@mui/material';
import { Button, Icon } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { TableLayoutHeadAction } from '../../types';

type IaTableHeadActionProps = TableLayoutHeadAction;

const styles = {
  root: {
    py: 0.5,
    px: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    minHeight: 24,
    minWidth: 'unset',
    whiteSpace: 'nowrap',
  },
};
export default function IaTableHeadAction({
  type,
  value,
  disabled,
  ...rest
}: IaTableHeadActionProps) {
  const { getIaAction } = useIaAction();
  const iaAction = getIaAction(value);

  const handleClick = () => {
    iaAction?.action();
  };

  let renderContent = null;
  if (type === 'button')
    renderContent = (
      <Button
        sx={styles.button}
        prefixIcon={rest.icon ? <Icon name={rest.icon} /> : undefined}
        variant={rest.variant}
        onClick={handleClick}
        disabled={disabled}
      >
        {rest.text}
      </Button>
    );

  return <Box sx={styles.root}>{renderContent}</Box>;
}
