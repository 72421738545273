import React from 'react';
import { TextComposerProvider } from '@lib/web/composer/TextComposer/context/TextComposerContext';
import EditorTextComposer, {
  EditorTextComposerProps,
} from '@lib/web/editor/EditorTextComposer';

export type EditorComposerProps = EditorTextComposerProps;

export default function EditorComposer(props: EditorComposerProps) {
  return (
    <TextComposerProvider>
      <EditorTextComposer {...props} />
    </TextComposerProvider>
  );
}
