import React from 'react';

export default function ProfileMastercard({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.5677 16.9986C11.5168 16.9598 11.465 16.9199 11.4171 16.8801C9.96627 15.6499 9.13564 13.8716 9.13564 12.001C9.13598 11.0756 9.34082 10.1611 9.73622 9.31965C10.1316 8.47825 10.7083 7.72968 11.4271 7.12486C11.4571 7.09105 11.4945 7.06028 11.5335 7.02822C11.5438 7.01974 11.5543 7.01116 11.5647 7.00242C10.6371 6.40186 9.55963 6.05772 8.44717 6.00665C7.3347 5.95558 6.22885 6.19949 5.24737 6.71242C4.2659 7.22535 3.44556 7.98807 2.8737 8.91938C2.30185 9.85069 1.9999 10.9157 2 12.001C1.99961 12.789 2.15862 13.5694 2.46794 14.2974C2.77727 15.0255 3.23084 15.687 3.80271 16.2441C4.37459 16.8012 5.05355 17.243 5.80077 17.5442C6.54799 17.8454 7.34881 18.0001 8.15743 17.9995C9.37056 18.0007 10.557 17.6524 11.5677 16.9986ZM12.3594 7.00242C13.3682 6.34758 14.5537 5.99924 15.7657 6.00154C16.5807 5.9915 17.3897 6.13926 18.1456 6.43626C18.9015 6.73326 19.5894 7.17359 20.1694 7.7317C20.7493 8.2898 21.2098 8.95458 21.524 9.68746C21.8383 10.4203 22.0001 11.2067 22 12.001C21.9999 12.7953 21.838 13.5817 21.5237 14.3145C21.2093 15.0473 20.7487 15.712 20.1687 16.27C19.5886 16.8281 18.9007 17.2683 18.1447 17.5652C17.3887 17.862 16.5797 18.0097 15.7647 17.9995C14.5522 18.0009 13.3663 17.6527 12.3564 16.9986C12.3664 16.9909 12.3764 16.9831 12.3864 16.9753C12.4268 16.9439 12.4679 16.912 12.507 16.8801C13.9559 15.6479 14.7885 13.8706 14.7885 12.001C14.7885 10.1314 13.9539 8.35216 12.496 7.12486C12.4673 7.09243 12.4317 7.0628 12.3948 7.03214C12.3831 7.02238 12.3712 7.01251 12.3594 7.00242Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M11.9616 7.28811C11.2285 7.84828 10.6359 8.56379 10.2289 9.38038C9.82181 10.197 9.61095 11.0932 9.61228 12.001C9.61035 12.9094 9.82093 13.8062 10.228 14.6233C10.6351 15.4405 11.228 16.1565 11.9616 16.7168C12.695 16.1562 13.2878 15.4402 13.695 14.6231C14.1022 13.8061 14.3132 12.9094 14.3119 12.001C14.3132 11.0931 14.1023 10.1968 13.695 9.38015C13.2878 8.56354 12.695 7.84808 11.9616 7.28811Z"
        fill="currentColor"
      />
    </svg>
  );
}
