export const enum HashtagType {
  QuestionSet = 1,
  Question = 2,
  CreatorQuestion = 3,
}

export const enum HashtagAppearing {
  Rare = 'poa_rare',
  Half = 'poa_50_50',
  Likely = 'poa_likely',
}
