import { ReactElement, useMemo } from 'react';
import Link from 'next/link';
import Router from 'next/router';
import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';
import { Icon, IconButton, LoadingIcon, SimpleTooltip } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { AlbumLayoutActionItem } from '../../types';

const styles = {
  root: {
    display: 'grid',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: '1 / 1',
    borderRadius: '10px',
    px: '20px',
    py: 1,
    border: '1px dashed',
    borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    '&:disabled': {
      opacity: 0.5,
    },
  },
  hoverable: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        borderColor: 'transparent',
        color: (theme: Theme) => theme.palette.text.primary,
        outline: (theme: Theme) => `3px dashed ${theme.palette.text.primary}`,
        outlineOffset: '-3px',
      },
    },
  },
  success: {
    borderColor: (theme: Theme) => alpha(theme.palette.primary.light, 0.3),
    outline: (theme: Theme) =>
      `3px dashed ${alpha(theme.palette.primary.light, 0.3)}`,
    outlineOffset: '-1px',
    '&:disabled': {
      opacity: 1,
    },
  },
  error: {
    borderColor: (theme: Theme) => theme.palette.error.dark,
    color: (theme: Theme) => theme.palette.error.dark,
    '&:disabled': {
      opacity: 1,
    },
  },
  defaultContainer: {
    display: 'grid',
    gridTemplateColumns: '16px 1fr',
    gap: 0.5,
    justifyContent: 'center',
    alignItems: 'start',
    '& svg': {
      mt: '4px',
    },
  },
  content: {
    textAlign: 'left',
  },
  loadingIcon: {
    width: 16,
    height: 16,
  },
  errorContainer: {
    display: 'grid',
    alignItems: 'center',
    gap: 0.5,
  },
  errorMessage: {
    textAlign: 'left',
  },
  errorActions: {
    display: 'flex',
    gap: 0.5,
    color: (theme: Theme) => theme.palette.text.primary,
  },
};

type TooltipWrapperProps = {
  tooltip?: string;
  children: ReactElement;
};
function TooltipWrapper({ tooltip, children }: TooltipWrapperProps) {
  if (!tooltip) return children;
  return (
    <SimpleTooltip title={tooltip} followCursor>
      {children}
    </SimpleTooltip>
  );
}
type IaAlbumActionItemProps = AlbumLayoutActionItem;

export default function IaAlbumActionItem({
  content,
  state = 'default',
  errorMessage,
  errorActions,
  disabled,
  tooltip,
  action,
}: IaAlbumActionItemProps) {
  const { getIaAction } = useIaAction();

  const isDisabled = disabled || state === 'loading';

  const linkProps =
    action?.type === 'link' ? { LinkComponent: Link, href: action.value } : {};

  const handleClick = () => {
    if (action?.type !== 'event') return;
    const clickAction = getIaAction(action.value);
    clickAction?.action();
  };

  const errorActionsData = useMemo(() => {
    if (!errorActions) return;
    return errorActions.map((item) => {
      let onClick;
      if (item.type === 'link') {
        onClick = () => Router.push(item.value);
      } else {
        const onClickAction = getIaAction(item.value);
        onClick = () => onClickAction?.action?.();
      }
      return {
        icon: item.icon || '',
        text: item.text || '',
        onClick: onClick,
      };
    });
  }, [errorActions, getIaAction]);

  const buttonProps =
    state === 'default'
      ? {
          component: ButtonBase,
          disableRipple: true,
          disableTouchRipple: true,
          disabled: isDisabled,
          onClick: handleClick,
          ...linkProps,
        }
      : {};

  return (
    <TooltipWrapper tooltip={state === 'default' ? tooltip : undefined}>
      <Box
        sx={[
          styles.root,
          state === 'default' && styles.hoverable,
          state === 'success' && styles.success,
          state === 'error' && styles.error,
        ]}
        {...buttonProps}
      >
        {state === 'default' && (
          <Box sx={styles.defaultContainer}>
            <Icon name="TestAdd" width={16} height={16} />
            <Typography variant="body1" sx={styles.content}>
              {content}
            </Typography>
          </Box>
        )}
        {state === 'loading' && <LoadingIcon sx={styles.loadingIcon} />}
        {state === 'error' && (
          <Box sx={styles.errorContainer}>
            <Box sx={styles.errorMessage}>
              <Icon name="OtherError" width={16} height={16} />
              <Typography variant="body1">{errorMessage}</Typography>
            </Box>
            {errorActionsData && (
              <Box sx={styles.errorActions}>
                {errorActionsData.map((item, index) => (
                  <IconButton
                    key={index}
                    onClick={item.onClick}
                    customSize={24}
                  >
                    <Icon name={item.icon} />
                  </IconButton>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </TooltipWrapper>
  );
}
