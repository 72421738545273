import React from 'react';

export default function OtherLowestPrice({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.3065 11.0222C16.5226 10.4978 15.9489 9.95556 15.9489 9.11111C15.9489 8.14222 16.7426 7.46667 18.0707 7.46667C19.4695 7.46667 19.9882 8.22222 20.0354 9.33333H21.7721C21.7171 7.80444 20.8919 6.4 19.2495 5.94667V4H16.8919V5.92C15.3674 6.29333 14.1415 7.41333 14.1415 9.12889C14.1415 11.1822 15.6424 12.2044 17.835 12.8C19.7996 13.3333 20.1925 14.1156 20.1925 14.9422C20.1925 15.5556 19.8075 16.5333 18.0707 16.5333C16.4519 16.5333 15.8153 15.7156 15.7289 14.6667H14C14.0943 16.6133 15.3831 17.7067 16.8919 18.0711V20H19.2495V18.0889C20.7819 17.76 22 16.7556 22 14.9333C22 12.4089 20.0904 11.5467 18.3065 11.0222Z"
        fill="currentColor"
      />
      <path
        d="M6.39603 18.779C6.74048 19.0737 7.25952 19.0737 7.60397 18.779L11.6949 15.279C12.0701 14.9579 12.1039 14.4049 11.7704 14.0437C11.4368 13.6825 10.8622 13.65 10.4869 13.971L7.90909 16.1765V5.875C7.90909 5.39175 7.50208 5 7 5C6.49792 5 6.09091 5.39175 6.09091 5.875V16.1765L3.51306 13.971C3.13781 13.65 2.5632 13.6825 2.22964 14.0437C1.89608 14.4049 1.92988 14.9579 2.30513 15.279L6.39603 18.779Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
