import matchAll from 'string.prototype.matchall';

const HIGHLIGH_ANCHOR_REGEX =
  /<span [^>]*?class="inline-highlight-anchor"*.?data-render-id="([^"]+)"[^>]*?>/gm;

export const getHighlights = (text: string) => {
  const multipleMatchArr = [...matchAll(text, HIGHLIGH_ANCHOR_REGEX)];

  return multipleMatchArr.map((match) => match[1]);
};
