import { TableLayoutHeadCell } from '../../types';

import IaGroupHeadCell from './IaGroupHeadCell';
import IaTableHeadSimpleCell from './IaTableHeadSimpleCell';

export type IaTableHeadCellProps = TableLayoutHeadCell;

export default function IaTableHeadCell(headCell: IaTableHeadCellProps) {
  switch (headCell.type) {
    case 'default':
    case 'iconText':
      return <IaTableHeadSimpleCell {...headCell} />;
    case 'group':
      return <IaGroupHeadCell {...headCell} />;
    default:
      console.warn('Unknown head cell type:', headCell);
      return null;
  }
}
