import React from 'react';

export default function OtherWarning({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.0008 15.649C11.4417 15.649 10.973 16.1105 10.973 16.661C10.973 17.2114 11.4417 17.6729 12.0008 17.6729C12.5394 17.6729 13.0286 17.2114 13.004 16.6852C13.0286 16.1064 12.564 15.649 12.0008 15.649Z"
        fill="currentColor"
      />
      <path
        d="M21.5133 19.2352C22.1588 18.1383 22.1629 16.8309 21.5215 15.738L15.0832 4.76074C14.446 3.65572 13.2948 3 12.0039 3C10.7129 3 9.56174 3.65977 8.92449 4.75669L2.47796 15.7461C1.8366 16.8511 1.84071 18.1666 2.49029 19.2635C3.13166 20.3483 4.27871 21 5.56144 21H18.4216C19.7084 21 20.8637 20.3402 21.5133 19.2352ZM20.1155 18.4419C19.7578 19.049 19.1246 19.4093 18.4175 19.4093H5.55733C4.85841 19.4093 4.22938 19.0571 3.87991 18.4621C3.52634 17.859 3.52223 17.1385 3.8758 16.5314L10.3223 5.54599C10.6718 4.94288 11.2967 4.58669 12.0039 4.58669C12.7069 4.58669 13.3359 4.94693 13.6854 5.55003L20.1278 16.5354C20.4732 17.1264 20.469 17.8388 20.1155 18.4419Z"
        fill="currentColor"
      />
      <path
        d="M11.7453 8.54527C11.2561 8.68289 10.9518 9.12004 10.9518 9.65028C10.9765 9.97005 10.997 10.2939 11.0217 10.6136C11.0916 11.832 11.1615 13.026 11.2314 14.2444C11.2561 14.6573 11.5808 14.9568 12.0002 14.9568C12.4196 14.9568 12.7485 14.637 12.769 14.2201C12.769 13.9692 12.769 13.7384 12.7937 13.4834C12.8389 12.7022 12.8882 11.921 12.9335 11.1398C12.9581 10.6339 13.0034 10.1279 13.028 9.62195C13.028 9.43981 13.0034 9.2779 12.9335 9.11599C12.7238 8.66265 12.2345 8.43193 11.7453 8.54527Z"
        fill="currentColor"
      />
    </svg>
  );
}
