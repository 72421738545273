import React from 'react';

export default function OtherQuestions({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="12" cy="12" fill="currentColor" fillOpacity="0.1" r="10" />
      <path
        d="M11.7576 8.9349C11.392 8.9349 11.0466 8.98061 10.7216 9.07202C10.3966 9.16344 10.036 9.31071 9.63989 9.51385L9 7.79224C9.39612 7.53832 9.86334 7.34534 10.4017 7.2133C10.94 7.0711 11.4681 7 11.9861 7C12.6362 7 13.1694 7.08633 13.5859 7.259C14.0125 7.43167 14.3476 7.65005 14.5914 7.91413C14.8352 8.17821 15.0028 8.46768 15.0942 8.78255C15.1958 9.09741 15.2465 9.39705 15.2465 9.68144C15.2465 9.96584 15.2008 10.2299 15.1094 10.4737C15.0282 10.7073 14.9215 10.9257 14.7895 11.1288C14.6574 11.3319 14.5051 11.5249 14.3324 11.7078C14.1597 11.8906 13.9922 12.0633 13.8296 12.2258C13.7078 12.3476 13.5808 12.4797 13.4488 12.6219C13.3167 12.7539 13.1948 12.8961 13.0831 13.0485C12.9815 13.2008 12.8952 13.3633 12.8241 13.536C12.753 13.6985 12.7175 13.8661 12.7175 14.0388C12.7175 14.0794 12.7175 14.1404 12.7175 14.2216C12.7175 14.3029 12.7225 14.374 12.7327 14.4349H10.7216C10.7013 14.3333 10.6861 14.2216 10.6759 14.0997C10.6759 13.9677 10.6759 13.856 10.6759 13.7645C10.6759 13.4801 10.7114 13.2262 10.7825 13.0028C10.8536 12.7692 10.9451 12.5559 11.0568 12.3629C11.1787 12.1597 11.3158 11.9718 11.4681 11.7992C11.6205 11.6265 11.7779 11.4589 11.9404 11.2964C12.1741 11.0628 12.3873 10.8343 12.5803 10.6108C12.7733 10.3772 12.8698 10.1131 12.8698 9.81856C12.8698 9.57479 12.7733 9.36657 12.5803 9.19391C12.3975 9.02124 12.1233 8.9349 11.7576 8.9349ZM13.1745 16.5983C13.1745 17.0452 13.0272 17.3906 12.7327 17.6343C12.4483 17.8781 12.1233 18 11.7576 18C11.392 18 11.0619 17.8781 10.7673 17.6343C10.4829 17.3906 10.3407 17.0452 10.3407 16.5983C10.3407 16.1514 10.4829 15.8061 10.7673 15.5623C11.0619 15.3186 11.392 15.1967 11.7576 15.1967C12.1233 15.1967 12.4483 15.3186 12.7327 15.5623C13.0272 15.8061 13.1745 16.1514 13.1745 16.5983Z"
        fill="currentColor"
      />
    </svg>
  );
}
