/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from './common';

export const isNumber = (value: any): value is number =>
  !isEmpty(value) && !Number.isNaN(Number(value));

export const number = (value: any, defaultValue = 0): number => {
  if (!isNumber(value)) return defaultValue;

  return +value;
};

export function decimal(val: number | string, pos = 2) {
  if (!isNumber(val)) {
    return null;
  }
  const floatVal = parseFloat(`${val}`);
  const pow = 10 ** pos;

  const f = Math.round(floatVal * pow) / pow;
  return f;
}

export function isPrime(value: any, allowOne?: boolean): boolean {
  if (!isNumber(value)) {
    return false;
  }

  const numberVal = number(value);

  if (allowOne && numberVal === 1) return true;
  if (numberVal < 2) return false;

  let result = true;
  for (let i = 2; i < numberVal; i++) {
    if (numberVal % i === 0) {
      result = false;
      break;
    }
  }

  return result;
}
