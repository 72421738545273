import { useCallback, useMemo } from 'react';

export type SessionForm<T> = Partial<T> & {
  extra?: Record<string, any>; // for store extra data
};

export type UseSessionFormProps = {
  sessionKey: string;
};

export type UseSessionFormReturn<T> = {
  sessionFormData: SessionForm<T>;
  setSessionFormData: (formData: SessionForm<T>) => void;
  removeSessionFormData: () => void;
};

export default function useSessionForm<T>({
  sessionKey,
}: UseSessionFormProps): UseSessionFormReturn<T> {
  const sessionFormString = window.sessionStorage.getItem(sessionKey);
  const sessionFormData = useMemo<SessionForm<T>>(
    () => (sessionFormString ? JSON.parse(sessionFormString) : {}),
    [sessionFormString]
  );

  const setSessionFormData = useCallback(
    (formData: SessionForm<T>): void => {
      try {
        window.sessionStorage.setItem(sessionKey, JSON.stringify(formData));
      } catch (error) {
        // console.log(error);
      }
    },
    [sessionKey]
  );

  const removeSessionFormData = useCallback((): void => {
    window.sessionStorage.removeItem(sessionKey);
  }, [sessionKey]);

  return { sessionFormData, setSessionFormData, removeSessionFormData };
}
