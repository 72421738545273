import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { BasicComposerSchema } from '@lib/web/composer';
import { TextComposerProps } from '@lib/web/composer/TextComposer/TextComposer';
import { useEditorThreadSlashMenu } from '@lib/web/editor/EditorTextComposer/hooks/useEditorThreadSlashMenu';
import EditorThreadTextComposer from '@lib/web/editor/EditorThreadTextComposer';
import { ThreadComposerCustomContextValue } from '@lib/web/thread/contexts/threadComposerCustomContext';
import { useThreadViewDetails } from '@lib/web/thread/hooks/view/useThreadViewDetails';
import ThreadTextComposer from '@lib/web/thread/ThreadTextComposer';

const EDITOR_PATHS = [
  '/club/[slug]/review/all/[questionId]',
  '/club/[slug]/review/[userId]/[questionId]',
  '/club/[slug]/editor/[questionId]',
];

const renderEditorThreadTextComposer = (
  props: TextComposerProps<BasicComposerSchema>
) => <EditorThreadTextComposer {...props} />;

const renderNormalThreadTextComposer = (
  props: TextComposerProps<BasicComposerSchema>
) => <ThreadTextComposer {...props} />;

export default function useGetThreadComposerCustomProps() {
  const { pathname } = useRouter();
  const { getThreadViewDetail } = useThreadViewDetails();
  const editorThreadSlashMenuItems = useEditorThreadSlashMenu();

  const getThreadComposerCustomProps = useCallback<
    ThreadComposerCustomContextValue['getThreadComposerCustomProps']
  >(
    ({ channel, view } = {}) => {
      const isInEditorPage = EDITOR_PATHS.includes(pathname);
      const isSolutionThread =
        getThreadViewDetail(view).viewTag === 'solution' ||
        channel?.data?.tag === 'solution';

      const useEditor = isInEditorPage && isSolutionThread;

      return {
        renderTextComposer: useEditor
          ? renderEditorThreadTextComposer
          : renderNormalThreadTextComposer,
        addElementItems: useEditor ? editorThreadSlashMenuItems : undefined,
      };
    },
    [editorThreadSlashMenuItems, getThreadViewDetail, pathname]
  );

  return {
    getThreadComposerCustomProps,
  };
}
