import { Box } from '@mui/material';

const styles = {
  root: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    bgcolor: 'text.primary',
    cursor: 'pointer',
    opacity: 0.5,
  },
  active: {
    width: 8,
    height: 8,
    opacity: 1,
  },
};

export type StepDotProps = {
  active?: boolean;
  onClick?: () => void;
};

export default function StepDot({ active = false, onClick }: StepDotProps) {
  return <Box sx={[styles.root, active && styles.active]} onClick={onClick} />;
}
