import React from 'react';

export default function ActionChevronFilledUp({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M17 14L12 9L7 14H17Z" fill="currentColor" />
    </svg>
  );
}
