import React from 'react';

export default function ProfileCoupon({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.25751 10.5945C7.35495 10.4884 7.38721 10.3562 7.38721 10.1978V9.83218C7.38721 9.67394 7.35401 9.54018 7.25475 9.43103C7.15556 9.32164 7.02222 9.26709 6.83825 9.26709C6.65778 9.26709 6.50404 9.32164 6.40478 9.43103C6.30572 9.54018 6.24249 9.67394 6.24249 9.83218V10.1978C6.24249 10.3562 6.30983 10.4884 6.40882 10.5945C6.50815 10.7005 6.65212 10.7535 6.83993 10.7535C7.02034 10.7535 7.16013 10.7005 7.25751 10.5945Z"
        fill="currentColor"
      />
      <path
        d="M10.5267 13.1862C10.3426 13.1862 10.1973 13.2408 10.0982 13.35C9.99906 13.4593 9.94613 13.5915 9.94613 13.7467V14.1122C9.94613 14.2643 10.0062 14.3956 10.1196 14.5065C10.2329 14.6173 10.3727 14.6725 10.5355 14.6725C10.7549 14.6725 10.9001 14.6235 10.9779 14.5253C11.0556 14.4273 11.0909 14.2895 11.0909 14.1122V13.7467C11.0909 13.5915 11.0453 13.4593 10.9442 13.35C10.8434 13.2409 10.707 13.1862 10.5267 13.1862Z"
        fill="currentColor"
      />
      <path
        d="M3.66667 6C2.74619 6 2 6.67157 2 7.5V16.5C2 17.3284 2.74619 18 3.66667 18H20.3333C21.2538 18 22 17.3284 22 16.5V7.5C22 6.67157 21.2538 6 20.3333 6H3.66667ZM5.09764 10.199V9.83097C5.09764 9.41679 5.26296 9.07352 5.56047 8.80103C5.85785 8.52855 6.28929 8.39243 6.83805 8.39243C7.39374 8.39243 7.80458 8.52855 8.10182 8.80103C8.39933 9.07352 8.53205 9.41679 8.53205 9.83097V10.199C8.53205 10.6133 8.4035 10.955 8.10599 11.2242C7.80875 11.4936 7.39569 11.6281 6.847 11.6281C6.29475 11.6281 5.85731 11.4927 5.55798 11.2218C5.25879 10.951 5.09764 10.6101 5.09764 10.199ZM6.30478 14.5532L10.0804 9.11412L10.9144 9.4917L7.13838 14.9307L6.30478 14.5532ZM12.2357 14.1135C12.2357 14.5309 12.0936 14.8742 11.798 15.1435C11.5025 15.4126 11.083 15.5472 10.5343 15.5472C9.98209 15.5472 9.55643 15.4119 9.25353 15.1411C8.95104 14.8701 8.80135 14.5278 8.80135 14.1135V13.7455C8.80135 13.3344 8.95151 12.9927 9.25246 12.7202C9.5534 12.4478 9.97805 12.3116 10.5269 12.3116C11.0824 12.3116 11.5026 12.4471 11.7982 12.7178C12.0937 12.9887 12.2357 13.3312 12.2357 13.7455V14.1135ZM16.4781 15.5456H15.3333V13.788H16.4781V15.5456ZM16.4781 12.8789H15.3333V11.1213H16.4781V12.8789ZM16.4781 10.1516H15.3333V8.39406H16.4781V10.1516Z"
        fill="currentColor"
      />
    </svg>
  );
}
