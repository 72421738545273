import { useCallback, useContext, useMemo } from 'react';
import { camelize } from '@front/helper';
import { sanitize } from 'dompurify';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { memoize } from 'lodash';

import {
  TextComposerRenderContext,
  TextComposerRenderContextValue,
} from '../context/TextComposerRenderContext';

export default function useTextComposerRender() {
  const renders = useContext<TextComposerRenderContextValue>(
    TextComposerRenderContext
  );

  const getRender = useCallback(
    (renderKey: string) => {
      if (!(renderKey in renders)) {
        console.warn(
          `Unknown action : ${renderKey}, please provide it in TextComposerRenderProvider`
        );
        return;
      }
      return renders[renderKey];
    },
    [renders]
  );

  const renderOptions = useMemo(() => {
    return {
      replace: (domNode) => {
        if (
          domNode.type === 'tag' &&
          domNode.name === 'span' &&
          !!domNode.attribs['data-render-ui']
        ) {
          const render = getRender(domNode.attribs['data-render-ui']);

          if (render) {
            const metadata = Object.keys(domNode.attribs)
              .filter((key) => key.includes('data-metadata'))
              .reduce((acc, cur) => {
                const curKey = camelize(cur.replace('data-metadata-', ''));
                return {
                  ...acc,
                  [curKey]: domNode.attribs[cur],
                };
              }, {});
            return render(metadata || {}) as any as Element;
          }
        }
      },
    } as HTMLReactParserOptions;
  }, [getRender]);

  const render = useMemo(
    () => memoize((html: string) => parse(sanitize(html), renderOptions)),
    [renderOptions]
  );

  return { getRender, render, renderOptions };
}
