import { ReactNode } from 'react';
import { UserOnlineStatusGet } from '@lib/web/apis';
import { useAgentOnlineStatus, useUserOnlineStatus } from '@lib/web/hooks';
import { createContext } from 'use-context-selector';

export type UsersOnlineStatusContextValue = {
  getUserOnlineStatus: (userId?: string) => UserOnlineStatusGet | undefined;
  getAgentOnlineStatus: () =>
    | GetAgentStatusRes['status']['indicator']
    | undefined;
};

type UsersOnlineStatusProviderProps = {
  children: ReactNode;
};

export const UsersOnlineStatusContext =
  createContext<UsersOnlineStatusContextValue>(
    null as any as UsersOnlineStatusContextValue
  );

export const UsersOnlineStatusProvider = ({
  children,
}: UsersOnlineStatusProviderProps) => {
  const { getUserOnlineStatus } = useUserOnlineStatus();
  const { getAgentOnlineStatus } = useAgentOnlineStatus();

  return (
    <UsersOnlineStatusContext.Provider
      value={{ getUserOnlineStatus, getAgentOnlineStatus }}
    >
      {children}
    </UsersOnlineStatusContext.Provider>
  );
};
