import React, { SVGAttributes } from 'react';

export default function EmojiPizza({ ...rest }: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width="105"
      height="190"
      viewBox="0 0 105 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.6494 59.845L6.55322 57.1849L25.9702 47.0527L32.0805 49.6937L12.6494 59.845Z"
        fill="#DFAB50"
      />
      <path
        d="M12.6494 59.845L6.55322 57.1849L25.9702 47.0527L32.0805 49.6937L12.6494 59.845Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M18.7182 54.7805L12.6221 52.1205L32.039 41.9883L38.1494 44.6293L18.7182 54.7805Z"
        fill="#DAA04A"
      />
      <path
        d="M18.7182 54.7805L12.6221 52.1205L32.039 41.9883L38.1494 44.6293L18.7182 54.7805Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M24.8041 49.7356L18.708 47.0755L38.125 36.9434L44.2353 39.5843L24.8041 49.7356Z"
        fill="#DDAE58"
      />
      <path
        d="M24.8041 49.7356L18.708 47.0755L38.125 36.9434L44.2353 39.5843L24.8041 49.7356Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M30.9052 44.6907L24.8091 42.0306L44.226 31.8984L50.3364 34.5394L30.9052 44.6907Z"
        fill="#D3A450"
      />
      <path
        d="M30.9052 44.6907L24.8091 42.0306L44.226 31.8984L50.3364 34.5394L30.9052 44.6907Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M36.9702 47.3469L30.874 44.6869L50.291 34.5547L56.4013 37.1957L36.9702 47.3469Z"
        fill="#D9A144"
      />
      <path
        d="M36.9702 47.3469L30.874 44.6869L50.291 34.5547L56.4013 37.1957L36.9702 47.3469Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M43.0585 42.3157L36.9624 39.6556L56.3794 29.5234L62.4897 32.1644L43.0585 42.3157Z"
        fill="#DDB160"
      />
      <path
        d="M43.0585 42.3157L36.9624 39.6556L56.3794 29.5234L62.4897 32.1644L43.0585 42.3157Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M49.1235 44.9719L43.0273 42.3119L62.4443 32.1797L68.5547 34.8207L49.1235 44.9719Z"
        fill="#DCA94F"
      />
      <path
        d="M49.1235 44.9719L43.0273 42.3119L62.4443 32.1797L68.5547 34.8207L49.1235 44.9719Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M79.5649 58.2139V50.5192L99.0109 40.3555V48.0657L79.5649 58.2139Z"
        fill="#D38E2B"
      />
      <path
        d="M79.5649 58.2139V50.5192L99.0109 40.3555V48.0657L79.5649 58.2139Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 65.9405V58.2458L99.0109 48.082V55.7923L79.5649 65.9405Z"
        fill="#984A1D"
      />
      <path
        d="M79.5649 65.9405V58.2458L99.0109 48.082V55.7923L79.5649 65.9405Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 73.6671V65.9723L99.0109 55.8086V63.5188L79.5649 73.6671Z"
        fill="#D8A781"
      />
      <path
        d="M79.5649 73.6671V65.9723L99.0109 55.8086V63.5188L79.5649 73.6671Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 81.3917V73.6969L99.0109 63.5332V71.2434L79.5649 81.3917Z"
        fill="#E77823"
      />
      <path
        d="M79.5649 81.3917V73.6969L99.0109 63.5332V71.2434L79.5649 81.3917Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 89.1182V81.4235L99.0109 71.2598V78.97L79.5649 89.1182Z"
        fill="#B73B0E"
      />
      <path
        d="M79.5649 89.1182V81.4235L99.0109 71.2598V78.97L79.5649 89.1182Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 96.8448V89.1501L99.0109 78.9863V86.6966L79.5649 96.8448Z"
        fill="#E99431"
      />
      <path
        d="M79.5649 96.8448V89.1501L99.0109 78.9863V86.6966L79.5649 96.8448Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 104.569V96.8747L99.0109 86.7109V94.4212L79.5649 104.569Z"
        fill="#E8952F"
      />
      <path
        d="M79.5649 104.569V96.8747L99.0109 86.7109V94.4212L79.5649 104.569Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 112.296V104.601L99.0109 94.4375V102.148L79.5649 112.296Z"
        fill="#E6912F"
      />
      <path
        d="M79.5649 112.296V104.601L99.0109 94.4375V102.148L79.5649 112.296Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 120.023V112.328L99.0109 102.164V109.874L79.5649 120.023Z"
        fill="#C5290E"
      />
      <path
        d="M79.5649 120.023V112.328L99.0109 102.164V109.874L79.5649 120.023Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 127.747V120.052L99.0109 109.889V117.599L79.5649 127.747Z"
        fill="#CC2711"
      />
      <path
        d="M79.5649 127.747V120.052L99.0109 109.889V117.599L79.5649 127.747Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 135.474V127.779L99.0109 117.615V125.325L79.5649 135.474Z"
        fill="#E27B18"
      />
      <path
        d="M79.5649 135.474V127.779L99.0109 117.615V125.325L79.5649 135.474Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 143.2V135.506L99.0109 125.342V133.052L79.5649 143.2Z"
        fill="#DA9334"
      />
      <path
        d="M79.5649 143.2V135.506L99.0109 125.342V133.052L79.5649 143.2Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 150.925V143.23L99.0109 133.066V140.777L79.5649 150.925Z"
        fill="#DC9C42"
      />
      <path
        d="M79.5649 150.925V143.23L99.0109 133.066V140.777L79.5649 150.925Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 158.651V150.957L99.0109 140.793V148.503L79.5649 158.651Z"
        fill="#D7551F"
      />
      <path
        d="M79.5649 158.651V150.957L99.0109 140.793V148.503L79.5649 158.651Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5649 166.376V158.681L99.0109 148.518V156.228L79.5649 166.376Z"
        fill="#C97629"
      />
      <path
        d="M79.5649 166.376V158.681L99.0109 148.518V156.228L79.5649 166.376Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M79.5756 50.5305L73.4795 47.8705L92.8965 37.7383L99.0068 40.3793L79.5756 50.5305Z"
        fill="#D38E2B"
      />
      <path
        d="M79.5756 50.5305L73.4795 47.8705L92.8965 37.7383L99.0068 40.3793L79.5756 50.5305Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M73.4907 47.8782L67.3945 45.2181L86.8115 35.0859L92.9219 37.7269L73.4907 47.8782Z"
        fill="#D0953B"
      />
      <path
        d="M73.4907 47.8782L67.3945 45.2181L86.8115 35.0859L92.9219 37.7269L73.4907 47.8782Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M67.4291 45.2219L61.333 42.5619L80.75 32.4297L86.8603 35.0707L67.4291 45.2219Z"
        fill="#DDAF5C"
      />
      <path
        d="M67.4291 45.2219L61.333 42.5619L80.75 32.4297L86.8603 35.0707L67.4291 45.2219Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M61.31 42.5774L55.2139 39.9173L74.6308 29.7852L80.7412 32.4261L61.31 42.5774Z"
        fill="#DFAD56"
      />
      <path
        d="M61.31 42.5774L55.2139 39.9173L74.6308 29.7852L80.7412 32.4261L61.31 42.5774Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M55.2451 39.9211L49.1489 37.2611L68.5659 27.1289L74.6763 29.7699L55.2451 39.9211Z"
        fill="#D6B173"
      />
      <path
        d="M55.2451 39.9211L49.1489 37.2611L68.5659 27.1289L74.6763 29.7699L55.2451 39.9211Z"
        fill="black"
        fillOpacity="0.3"
      />
      <g clipPath="url(#clip0_3334_186775)">
        <path
          d="M79.5611 150.727L73.4771 148.07V155.778L79.5611 158.434V150.727Z"
          fill="#D7551F"
        />
        <path
          d="M79.5611 158.434L73.4771 155.777V163.485L79.5611 166.141V158.434Z"
          fill="#C97629"
        />
        <path
          d="M73.4771 148.069L67.3931 145.412V153.119L73.4771 155.776V148.069Z"
          fill="#C72810"
        />
        <path
          d="M73.4771 155.776L67.3931 153.119V160.826L73.4771 163.483V155.776Z"
          fill="#DDA85F"
        />
        <path
          d="M67.3936 145.412L61.3096 142.756V150.463L67.3936 153.12V145.412Z"
          fill="#B92810"
        />
        <path
          d="M79.5611 143.018L73.4771 140.361V148.069L79.5611 150.725V143.018Z"
          fill="#DC9C42"
        />
        <path
          d="M73.4771 140.362L67.3931 137.705V145.412L73.4771 148.069V140.362Z"
          fill="#B3130E"
        />
        <path
          d="M67.3936 137.705L61.3096 135.049V142.756L67.3936 145.413V137.705Z"
          fill="#A81109"
        />
        <path
          d="M61.3096 135.049L55.2256 132.393V140.1L61.3096 142.756V135.049Z"
          fill="#DDA85F"
        />
        <path
          d="M79.5611 135.311L73.4771 132.654V140.362L79.5611 143.018V135.311Z"
          fill="#DA9334"
        />
        <path
          d="M73.4771 132.655L67.3931 129.998V137.705L73.4771 140.362V132.655Z"
          fill="#DFA753"
        />
        <path
          d="M67.3936 129.998L61.3096 127.342V135.049L67.3936 137.706V129.998Z"
          fill="#E3B56C"
        />
        <path
          d="M61.3096 127.342L55.2256 124.686V132.393L61.3096 135.049V127.342Z"
          fill="#E8BF83"
        />
        <path
          d="M55.2252 124.686L49.1411 122.029V129.737L55.2252 132.393V124.686Z"
          fill="#DD8D34"
        />
        <path
          d="M79.5611 127.604L73.4771 124.947V132.655L79.5611 135.311V127.604Z"
          fill="#E27B18"
        />
        <path
          d="M73.4771 124.948L67.3931 122.291V129.998L73.4771 132.655V124.948Z"
          fill="#D83815"
        />
        <path
          d="M67.3936 122.291L61.3096 119.635V127.342L67.3936 129.999V122.291Z"
          fill="#D68E3D"
        />
        <path
          d="M61.3096 119.635L55.2256 116.979V124.686L61.3096 127.342V119.635Z"
          fill="#E9B466"
        />
        <path
          d="M55.2252 116.977L49.1411 114.32V122.028L55.2252 124.684V116.977Z"
          fill="#EEBC70"
        />
        <path
          d="M49.1412 114.321L43.0571 111.664V119.371L49.1412 122.028V114.321Z"
          fill="#ECBD69"
        />
        <path
          d="M79.5611 119.897L73.4771 117.24V124.947L79.5611 127.604V119.897Z"
          fill="#CC2711"
        />
        <path
          d="M73.4771 117.241L67.3931 114.584V122.291L73.4771 124.948V117.241Z"
          fill="#C71E0D"
        />
        <path
          d="M67.3936 114.584L61.3096 111.928V119.635L67.3936 122.292V114.584Z"
          fill="#CB1D0E"
        />
        <path
          d="M61.3096 111.926L55.2256 109.27V116.977L61.3096 119.633V111.926Z"
          fill="#E79938"
        />
        <path
          d="M55.2252 109.27L49.1411 106.613V114.321L55.2252 116.977V109.27Z"
          fill="#ECAC4C"
        />
        <path
          d="M49.1412 106.614L43.0571 103.957V111.664L49.1412 114.321V106.614Z"
          fill="#F2C17A"
        />
        <path
          d="M43.0572 103.957L36.9731 101.301V109.008L43.0572 111.665V103.957Z"
          fill="#EFB35A"
        />
        <path
          d="M79.5611 112.19L73.4771 109.533V117.24L79.5611 119.897V112.19Z"
          fill="#C5290E"
        />
        <path
          d="M73.4771 109.534L67.3931 106.877V114.584L73.4771 117.241V109.534Z"
          fill="#A9130B"
        />
        <path
          d="M67.3936 106.877L61.3096 104.221V111.928L67.3936 114.585V106.877Z"
          fill="#B5120C"
        />
        <path
          d="M61.3096 104.219L55.2256 101.562V109.27L61.3096 111.926V104.219Z"
          fill="#EBAF59"
        />
        <path
          d="M55.2252 101.563L49.1411 98.9062V106.613L55.2252 109.27V101.563Z"
          fill="#E4A250"
        />
        <path
          d="M49.1412 98.9066L43.0571 96.25V103.957L49.1412 106.614V98.9066Z"
          fill="#DA4E10"
        />
        <path
          d="M43.0572 96.2504L36.9731 93.5938V101.301L43.0572 103.958V96.2504Z"
          fill="#D85318"
        />
        <path
          d="M36.9727 93.5941L30.8887 90.9375V98.6447L36.9727 101.301V93.5941Z"
          fill="#F2B262"
        />
        <path
          d="M79.5611 104.483L73.4771 101.826V109.533L79.5611 112.19V104.483Z"
          fill="#E6912F"
        />
        <path
          d="M73.4771 101.827L67.3931 99.1699V106.877L73.4771 109.534V101.827Z"
          fill="#D54D1A"
        />
        <path
          d="M67.3936 99.1683L61.3096 96.5117V104.219L67.3936 106.876V99.1683Z"
          fill="#EA9432"
        />
        <path
          d="M61.3096 96.5121L55.2256 93.8555V101.563L61.3096 104.219V96.5121Z"
          fill="#EFB462"
        />
        <path
          d="M55.2252 93.8558L49.1411 91.1992V98.9065L55.2252 101.563V93.8558Z"
          fill="#CA5117"
        />
        <path
          d="M49.1412 91.1996L43.0571 88.543V96.2502L49.1412 98.9068V91.1996Z"
          fill="#D14613"
        />
        <path
          d="M43.0572 88.5433L36.9731 85.8867V93.594L43.0572 96.2506V88.5433Z"
          fill="#C72710"
        />
        <path
          d="M36.9727 85.8871L30.8887 83.2305V90.9377L36.9727 93.5943V85.8871Z"
          fill="#CE1A0B"
        />
        <path
          d="M30.8892 83.2289L24.8052 80.5723V88.2795L30.8892 90.9361V83.2289Z"
          fill="#F2C891"
        />
        <path
          d="M24.8052 80.5726L18.7212 77.916V85.6233L24.8052 88.2799V80.5726Z"
          fill="#E6CFAF"
        />
        <path
          d="M79.5611 96.7758L73.4771 94.1191V101.826L79.5611 104.483V96.7758Z"
          fill="#E8952F"
        />
        <path
          d="M73.4771 94.1195L67.3931 91.4629V99.1701L73.4771 101.827V94.1195Z"
          fill="#EB9834"
        />
        <path
          d="M67.3936 91.4613L61.3096 88.8047V96.5119L67.3936 99.1686V91.4613Z"
          fill="#ECA03F"
        />
        <path
          d="M61.3096 88.8051L55.2256 86.1484V93.8557L61.3096 96.5123V88.8051Z"
          fill="#EFAF52"
        />
        <path
          d="M55.2252 86.1488L49.1411 83.4922V91.1994L55.2252 93.8561V86.1488Z"
          fill="#D17B27"
        />
        <path
          d="M49.1412 83.4926L43.0571 80.8359V88.5432L49.1412 91.1998V83.4926Z"
          fill="#B8190C"
        />
        <path
          d="M43.0572 80.8363L36.9731 78.1797V85.8869L43.0572 88.5436V80.8363Z"
          fill="#B4170C"
        />
        <path
          d="M36.9727 78.1781L30.8887 75.5215V83.2287L36.9727 85.8853V78.1781Z"
          fill="#D22515"
        />
        <path
          d="M30.8892 75.5219L24.8052 72.8652V80.5725L30.8892 83.2291V75.5219Z"
          fill="#E1B67A"
        />
        <path
          d="M24.8052 72.8656L18.7212 70.209V77.9162L24.8052 80.5728V72.8656Z"
          fill="#BF4E15"
        />
        <path
          d="M18.7212 70.2094L12.6372 67.5527V75.26L18.7212 77.9166V70.2094Z"
          fill="#96420B"
        />
        <path
          d="M79.5611 89.0687L73.4771 86.4121V94.1193L79.5611 96.776V89.0687Z"
          fill="#E99431"
        />
        <path
          d="M73.4771 86.4105L67.3931 83.7539V91.4611L73.4771 94.1178V86.4105Z"
          fill="#DA4313"
        />
        <path
          d="M67.3936 83.7543L61.3096 81.0977V88.8049L67.3936 91.4615V83.7543Z"
          fill="#D63711"
        />
        <path
          d="M61.3096 81.098L55.2256 78.4414V86.1486L61.3096 88.8053V81.098Z"
          fill="#EB984E"
        />
        <path
          d="M55.2252 78.4418L49.1411 75.7852V83.4924L55.2252 86.149V78.4418Z"
          fill="#F2B75B"
        />
        <path
          d="M49.1412 75.7855L43.0571 73.1289V80.8361L49.1412 83.4928V75.7855Z"
          fill="#F5C06F"
        />
        <path
          d="M43.0572 73.1293L36.9731 70.4727V78.1799L43.0572 80.8365V73.1293Z"
          fill="#F0B769"
        />
        <path
          d="M36.9727 70.4711L30.8887 67.8145V75.5217L36.9727 78.1783V70.4711Z"
          fill="#E4AE62"
        />
        <path
          d="M30.8892 67.8148L24.8052 65.1582V72.8654L30.8892 75.5221V67.8148Z"
          fill="#BF6B26"
        />
        <path
          d="M24.8052 65.1586L18.7212 62.502V70.2092L24.8052 72.8658V65.1586Z"
          fill="#88370A"
        />
        <path
          d="M18.7212 62.5023L12.6372 59.8457V67.5529L18.7212 70.2096V62.5023Z"
          fill="#C3721E"
        />
        <path
          d="M12.6368 59.8461L6.55273 57.1895V64.8967L12.6368 67.5533V59.8461Z"
          fill="#DFAB50"
        />
        <path
          d="M79.5611 81.3617L73.4771 78.7051V86.4123L79.5611 89.0689V81.3617Z"
          fill="#B73B0E"
        />
        <path
          d="M73.4771 78.7035L67.3931 76.0469V83.7541L73.4771 86.4107V78.7035Z"
          fill="#CD3111"
        />
        <path
          d="M67.3936 76.0473L61.3096 73.3906V81.0979L67.3936 83.7545V76.0473Z"
          fill="#C8260E"
        />
        <path
          d="M61.3096 73.391L55.2256 70.7344V78.4416L61.3096 81.0982V73.391Z"
          fill="#C9170E"
        />
        <path
          d="M55.2252 70.7348L49.1411 68.0781V75.7854L55.2252 78.442V70.7348Z"
          fill="#F3B95E"
        />
        <path
          d="M49.1412 68.0785L43.0571 65.4219V73.1291L49.1412 75.7857V68.0785Z"
          fill="#F0BD67"
        />
        <path
          d="M43.0572 65.4203L36.9731 62.7637V70.4709L43.0572 73.1275V65.4203Z"
          fill="#DFA044"
        />
        <path
          d="M36.9727 62.7641L30.8887 60.1074V67.8147L36.9727 70.4713V62.7641Z"
          fill="#B6400D"
        />
        <path
          d="M30.8892 60.1078L24.8052 57.4512V65.1584L30.8892 67.815V60.1078Z"
          fill="#88380C"
        />
        <path
          d="M24.8052 57.4516L18.7212 54.7949V62.5022L24.8052 65.1588V57.4516Z"
          fill="#B96618"
        />
        <path
          d="M18.7212 54.7953L12.6372 52.1387V59.8459L18.7212 62.5025V54.7953Z"
          fill="#DAA04A"
        />
        <path
          d="M79.5611 73.6527L73.4771 70.9961V78.7033L79.5611 81.36V73.6527Z"
          fill="#E77823"
        />
        <path
          d="M73.4771 70.9965L67.3931 68.3398V76.0471L73.4771 78.7037V70.9965Z"
          fill="#BF1C0E"
        />
        <path
          d="M67.3936 68.3402L61.3096 65.6836V73.3908L67.3936 76.0475V68.3402Z"
          fill="#B2150C"
        />
        <path
          d="M61.3096 65.684L55.2256 63.0273V70.7346L61.3096 73.3912V65.684Z"
          fill="#C4180F"
        />
        <path
          d="M55.2252 63.0277L49.1411 60.3711V68.0783L55.2252 70.735V63.0277Z"
          fill="#E18925"
        />
        <path
          d="M49.1412 60.3695L43.0571 57.7129V65.4201L49.1412 68.0768V60.3695Z"
          fill="#C36819"
        />
        <path
          d="M43.0572 57.7133L36.9731 55.0566V62.7639L43.0572 65.4205V57.7133Z"
          fill="#95470C"
        />
        <path
          d="M36.9727 55.057L30.8887 52.4004V60.1076L36.9727 62.7643V55.057Z"
          fill="#AB5311"
        />
        <path
          d="M30.8892 52.4008L24.8052 49.7441V57.4514L30.8892 60.108V52.4008Z"
          fill="#C2781E"
        />
        <path
          d="M24.8052 49.7445L18.7212 47.0879V54.7951L24.8052 57.4518V49.7445Z"
          fill="#DDAE58"
        />
        <path
          d="M79.5611 65.9457L73.4771 63.2891V70.9963L79.5611 73.6529V65.9457Z"
          fill="#D8A781"
        />
        <path
          d="M73.4771 63.2894L67.3931 60.6328V68.34L73.4771 70.9967V63.2894Z"
          fill="#D27318"
        />
        <path
          d="M67.3936 60.6332L61.3096 57.9766V65.6838L67.3936 68.3404V60.6332Z"
          fill="#B54E0F"
        />
        <path
          d="M61.3096 57.9769L55.2256 55.3203V63.0275L61.3096 65.6842V57.9769Z"
          fill="#A8320E"
        />
        <path
          d="M55.2252 55.3207L49.1411 52.6641V60.3713L55.2252 63.0279V55.3207Z"
          fill="#91410D"
        />
        <path
          d="M49.1412 52.6625L43.0571 50.0059V57.7131L49.1412 60.3697V52.6625Z"
          fill="#BB6117"
        />
        <path
          d="M43.0572 50.0062L36.9731 47.3496V55.0568L43.0572 57.7135V50.0062Z"
          fill="#CB8F29"
        />
        <path
          d="M36.9727 47.35L30.8887 44.6934V52.4006L36.9727 55.0572V47.35Z"
          fill="#D9A144"
        />
        <path
          d="M30.8892 44.6937L24.8052 42.0371V49.7443L30.8892 52.401V44.6937Z"
          fill="#D3A450"
        />
        <path
          d="M79.5611 58.2387L73.4771 55.582V63.2893L79.5611 65.9459V58.2387Z"
          fill="#984A1D"
        />
        <path
          d="M73.4771 55.5824L67.3931 52.9258V60.633L73.4771 63.2896V55.5824Z"
          fill="#89390B"
        />
        <path
          d="M67.3936 52.9262L61.3096 50.2695V57.9768L67.3936 60.6334V52.9262Z"
          fill="#A64D13"
        />
        <path
          d="M61.3096 50.2699L55.2256 47.6133V55.3205L61.3096 57.9771V50.2699Z"
          fill="#BE6717"
        />
        <path
          d="M55.2252 47.6117L49.1411 44.9551V52.6623L55.2252 55.3189V47.6117Z"
          fill="#D18D2D"
        />
        <path
          d="M49.1412 44.9555L43.0571 42.2988V50.0061L49.1412 52.6627V44.9555Z"
          fill="#DCA94F"
        />
        <path
          d="M43.0572 42.2992L36.9731 39.6426V47.3498L43.0572 50.0064V42.2992Z"
          fill="#DDB160"
        />
        <path
          d="M79.5611 50.5316L73.4771 47.875V55.5822L79.5611 58.2389V50.5316Z"
          fill="#D38E2B"
        />
        <path
          d="M73.4771 47.8754L67.3931 45.2188V52.926L73.4771 55.5826V47.8754Z"
          fill="#D0953B"
        />
        <path
          d="M67.3936 45.2191L61.3096 42.5625V50.2697L67.3936 52.9264V45.2191Z"
          fill="#DDAF5C"
        />
        <path
          d="M61.3096 42.5629L55.2256 39.9062V47.6135L61.3096 50.2701V42.5629Z"
          fill="#DFAD56"
        />
        <path
          d="M55.2252 39.9047L49.1411 37.248V44.9553L55.2252 47.6119V39.9047Z"
          fill="#D6B173"
        />
      </g>
      <defs>
        <clipPath id="clip0_3334_186775">
          <rect
            width="99.5815"
            height="115.609"
            fill="white"
            transform="matrix(0.916441 0.400169 0 1 6.55273 18.6523)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
