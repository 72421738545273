import React from 'react';

export default function OtherOpenInMainScreen({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.3658 7.27734C12.7328 7.60757 12.7658 8.17643 12.4397 8.54794L10.1992 11.1L19.1111 11.1002C19.602 11.1002 20 11.5031 20 12.0002C20 12.4972 19.602 12.9002 19.1111 12.9002L10.1992 12.9L12.4397 15.4521C12.7658 15.8236 12.7328 16.3925 12.3658 16.7227C11.9989 17.0529 11.4371 17.0194 11.111 16.6479L7.55548 12.5979C7.25612 12.2569 7.25612 11.7431 7.55548 11.4021L11.111 7.35208C11.4371 6.98058 11.9989 6.94712 12.3658 7.27734Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M4.88887 3C5.37978 3 5.77774 3.40294 5.77774 3.9V20.1C5.77774 20.5971 5.37978 21 4.88887 21C4.39796 21 4 20.5971 4 20.1V3.9C4 3.40294 4.39796 3 4.88887 3Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
