import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

function WatchField<T>({
  field,
  children,
}: {
  field: string;
  children: (value: T) => ReactNode;
}) {
  const { watch } = useFormContext();
  const value = watch(field) || '';
  return children(value);
}

export default function MaybeWatchField<T>({
  setting,
  children,
}: {
  setting?:
    | {
        type: 'static';
        value: T;
      }
    | {
        type: 'watchField';
        field: string;
      };
  children: (value: T) => ReactNode;
}) {
  if (!setting) return null;

  if (setting.type === 'static') {
    return children(setting.value as T);
  }
  if (setting.type === 'watchField') {
    return <WatchField field={setting.field}>{children}</WatchField>;
  }
  return null;
}
