import React from 'react';
import { v4 } from 'uuid';

export default function Award235({ ...rest }) {
  const id = v4();
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#clip0_${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.0718 21.806L44.9692 6.75005C43.1319 5.69026 40.8681 5.69026 39.0308 6.75005L12.9282 21.806C11.0908 22.8658 9.95897 24.8244 9.95897 26.944V57.056C9.95897 59.1756 11.0908 61.1342 12.9282 62.194L39.0308 77.2499C40.8681 78.3097 43.1319 78.3097 44.9692 77.2499L71.0718 62.194C72.9092 61.1342 74.041 59.1756 74.041 57.056V26.944C74.041 24.8244 72.9092 22.8658 71.0718 21.806ZM46.9487 3.32474C43.8864 1.55842 40.1136 1.55842 37.0513 3.32474L10.9487 18.3807C7.88644 20.1471 6 23.4114 6 26.944V57.056C6 60.5886 7.88644 63.8529 10.9487 65.6193L37.0513 80.6753C40.1136 82.4416 43.8864 82.4416 46.9487 80.6753L73.0513 65.6193C76.1136 63.8529 78 60.5886 78 57.056V26.944C78 23.4114 76.1136 20.1471 73.0513 18.3807L46.9487 3.32474Z"
          fill={`url(#paint0_linear_${id})`}
        />
        <g style={{ mixBlendMode: 'screen' }}>
          <mask
            id={`mask0_${id}`}
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="6"
            y="2"
            width="72"
            height="80"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M71.0718 21.806L44.9692 6.75005C43.1319 5.69026 40.8681 5.69026 39.0308 6.75005L12.9282 21.806C11.0908 22.8658 9.95897 24.8244 9.95897 26.944V57.056C9.95897 59.1756 11.0908 61.1342 12.9282 62.194L39.0308 77.2499C40.8681 78.3097 43.1319 78.3097 44.9692 77.2499L71.0718 62.194C72.9092 61.1342 74.041 59.1756 74.041 57.056V26.944C74.041 24.8244 72.9092 22.8658 71.0718 21.806ZM46.9487 3.32474C43.8864 1.55842 40.1136 1.55842 37.0513 3.32474L10.9487 18.3807C7.88644 20.1471 6 23.4114 6 26.944V57.056C6 60.5886 7.88644 63.8529 10.9487 65.6193L37.0513 80.6753C40.1136 82.4416 43.8864 82.4416 46.9487 80.6753L73.0513 65.6193C76.1136 63.8529 78 60.5886 78 57.056V26.944C78 23.4114 76.1136 20.1471 73.0513 18.3807L46.9487 3.32474Z"
              fill={`url(#paint1_linear_${id})`}
            />
          </mask>
          <g mask={`url(#mask0_${id})`}>
            <g filter={`url(#filter0_f_${id})`}>
              <rect
                x="62.2083"
                y="-1.53613"
                width="31.4975"
                height="90.1483"
                transform="rotate(45 62.2083 -1.53613)"
                fill="#FF5029"
              />
            </g>
          </g>
        </g>
        <g clipPath={`url(#clip1_${id})`}>
          <rect
            x="46.5017"
            y="20.2393"
            width="13.7596"
            height="41.2789"
            rx="6.87981"
            transform="rotate(30 46.5017 20.2393)"
            fill="#83182C"
          />
          <ellipse
            cx="35.2604"
            cy="53.4698"
            rx="6.87981"
            ry="6.87982"
            transform="rotate(30 35.2604 53.4698)"
            fill="#FF2951"
          />
        </g>
      </g>
      <defs>
        <filter
          id={`filter0_f_${id}`}
          x="-5.53613"
          y="-5.53613"
          width="94.0166"
          height="94.0166"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result={`effect1_foregroundBlur_${id}`}
          />
        </filter>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="60.5"
          y1="8"
          x2="24"
          y2="73.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#83182C" />
          <stop offset="1" stopColor="#FF2951" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_${id}`}
          x1="60.5"
          y1="8"
          x2="24"
          y2="73.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#043D72" />
          <stop offset="1" stopColor="#00A3FF" />
        </linearGradient>
        <clipPath id={`clip0_${id}`}>
          <rect width="84" height="84" fill="white" />
        </clipPath>
        <clipPath id={`clip1_${id}`}>
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(17 17)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
