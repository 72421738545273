import { MouseEvent, useContext, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { EditorHighlight as EditorHighlightIcon } from '@front/icon';
import { useBaseRightPanel } from '@front/ui';
import BlockTag from '@lib/web/composer/BlockTag';
import ThemeProvider from '@lib/web/composer/components/ThemeProvider';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';
import { EditorBlockTypes } from '@lib/web/editor/configs';
import { useEditorSelector } from '@lib/web/editor/redux';
import { selectOptionIndexById } from '@lib/web/editor/redux/reducers/settingReducer/selector';
import {
  TextComposerPanelKeys,
  TextComposerPanelParams,
} from '@lib/web/editor/TextComposerPanels/panel';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { v4 } from 'uuid';

export type InlineHighlightOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineHighlightOptions;
    node: ProseMirrorNode;
  }) => string;
};

const styles = {
  label: {
    typography: 'subtitle2',
    verticalAlign: 'text-bottom',
    lineHeight: '18px',

    minWidth: 22,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'background.darker',
    bgcolor: 'text.primary',
  },
  labelHoverable: {
    cursor: 'pointer',
  },
};

type HighlightProps = {
  node: ProseMirrorNode;
  updateAttributes: (attributes: Record<string, any>) => void;
};

type HighlightStatusTagProps = {
  node: ProseMirrorNode;
  id: string;
  targetId: string;
  isNewCreated: boolean;
  updateAttributes: (attributes: Record<string, any>) => void;
};
const HighlightStatusTag = ({
  node,
  id,
  targetId,
  isNewCreated,
  updateAttributes,
}: HighlightStatusTagProps) => {
  const { editor } = useContext(TextComposerContext);
  const orderIndex = useEditorSelector((st) =>
    selectOptionIndexById(st.setting)(targetId)
  );

  const { rightPanelTarget, getRightParams, openRightPanel, setRightParams } =
    useBaseRightPanel<TextComposerPanelParams>();

  const rightParams = getRightParams(
    TextComposerPanelKeys.TextComposerHighlight
  );

  const active = rightParams.activeOptionId === id;
  const selectAnchorId = active ? rightParams.selectAnchorId : null;

  const handleOpenMenu = (ev?: MouseEvent) => {
    const clickInPanel =
      'keepMounted' in rightParams &&
      rightParams.keepMounted &&
      !!ev?.currentTarget.closest('.base-right-panel-container');

    openRightPanel(TextComposerPanelKeys.TextComposerHighlight, {
      activeOptionId: id,
      selectAnchorId: targetId,
      returnPanel: clickInPanel ? rightPanelTarget : undefined,
      returnParams: clickInPanel ? rightParams : undefined,
    });
  };

  useEffect(() => {
    if (isNewCreated) {
      setTimeout(() => {
        updateAttributes({
          isNewCreated: false,
        });
        handleOpenMenu();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewCreated]);

  useEffect(() => {
    if (selectAnchorId && active) {
      setTimeout(() => {
        updateAttributes({
          targetId: selectAnchorId,
        });
      });
    }
  }, [active, selectAnchorId, targetId, updateAttributes]);

  useEffect(() => {
    setTimeout(() => {
      if (orderIndex !== node.attrs.orderIndex) {
        updateAttributes({
          orderIndex,
        });
      }
    });
  }, [node.attrs.orderIndex, orderIndex, updateAttributes]);

  const onMouseEnter = () => {
    setRightParams((st) => ({ ...st, hoverAnchorId: targetId }));
  };

  const onMouseLeave = () => {
    setRightParams((st) => ({ ...st, hoverAnchorId: null }));
  };

  if (orderIndex > -1) {
    return (
      <>
        &nbsp;
        <Box
          sx={[styles.label, editor.isEditable && styles.labelHoverable]}
          component="span"
          onClick={editor.isEditable ? handleOpenMenu : undefined}
          onMouseEnter={editor.isEditable ? onMouseEnter : undefined}
          onMouseLeave={editor.isEditable ? onMouseLeave : undefined}
        >
          {orderIndex + 1}
        </Box>
        &nbsp;
      </>
    );
  }

  return (
    <BlockTag
      active={active}
      error={!!targetId}
      icon={<EditorHighlightIcon />}
      onClick={editor.isEditable ? handleOpenMenu : undefined}
    >
      Highlight
    </BlockTag>
  );
};

const Highlight = ({ node, updateAttributes }: HighlightProps) => {
  const anchorRef = useRef<HTMLElement | null>(null);

  return (
    <NodeViewWrapper
      style={{ display: 'inline-block', userSelect: 'none' }}
      className="inline-highlight"
      contentEditable={false}
    >
      <ThemeProvider mode="dark">
        <Box ref={anchorRef}>
          <HighlightStatusTag
            node={node}
            id={node.attrs.id}
            targetId={node.attrs.targetId}
            isNewCreated={node.attrs.isNewCreated}
            updateAttributes={updateAttributes}
          />
        </Box>
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export const InlineHighlight = Node.create<InlineHighlightOptions>({
  name: EditorBlockTypes.InlineHighlight,
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: v4,
        parseHTML: () => v4(), // when copy and paste, generate a new id
      },
      targetId: {
        default: '',
      },
      isNewCreated: {
        default: false,
      },
      orderIndex: {
        default: 0,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.name,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes({
        class: this.name,
        'data-render-target-id': HTMLAttributes.targetId,
        'data-content-type': this.name,
      }),
      [
        'span',
        {
          class: 'highlight-order',
          'data-target-id': HTMLAttributes.targetId,
          style:
            'display: inline-flex; align-items: center; justify-content: center; margin-right: 4px; color: var(--inline-highlight-order-color); background-color: var(--inline-highlight-order-bgcolor);',
        },
        `${HTMLAttributes.orderIndex + 1}`,
      ],
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Highlight);
  },
});
