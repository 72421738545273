import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { composerConfig } from '@front/config';
import { useDebounce, useUploadFile } from '@front/helper';
import {
  ActionMore as ActionMoreIcon,
  OtherDocument as OtherDocumentIcon,
} from '@front/icon';
import { BottomSheet, Icon, LoadingIcon, toast } from '@front/ui';
import { apis } from '@lib/web/apis';
import { blockLevelProseMirrorNodeToComposerBlocks } from '@lib/web/composer';
import ThemeProvider from '@lib/web/composer/components/ThemeProvider';
import { notionLikeFileImageKeyboardShortcuts } from '@lib/web/composer/TextComposer/components/blocks/shared/notionLikeFileImageKeyboardShortcuts';
import { useFileBlockMoreActionItems } from '@lib/web/composer/TextComposer/components/SideMenu/hooks/useFileBlockMoreActionItems';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';
import { getBlockInfoFromPos } from '@lib/web/composer/utils/getBlockInfoFromPos';
import { ThreadBlockTypes } from '@lib/web/thread/ThreadTextComposer/config/threadBlockTypes';
import { VisuallyHiddenInput } from '@lib/web/ui';
import { mergeAttributes, Node } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { Node as ProseMirrorNode } from 'prosemirror-model';

const styles = {
  placeholder: {
    display: 'flex',
    width: '100%',
    height: '44px',
    padding: '8px 20px',
    gap: '8px',
    alignItems: 'center',
    borderRadius: '4px',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    typography: 'body1',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover': {
        background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  fileContainer: {
    display: 'flex',
    padding: '3.5px 0px',
    alignItems: 'center',

    svg: {
      flexShrink: 0,
    },

    '.file-name': {
      pl: '8px',
      color: 'text.primary',
      typography: 'body2',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.file-size': {
      pl: '4px',
      pt: '2px',
      color: 'alpha.lightA30',
      typography: 'caption',
      flexShrink: 0,
    },
    '.file-more-menu-icon': {
      height: '21px',
      ml: 'auto',
      pl: '4px',
      display: { sm: 'inline-flex', md: 'none' },
      alignItems: 'center',
    },
  },
  bottomSheet: {
    '& .MuiMenuItem-root': {
      minHeight: { xs: 45, md: 28 },
    },
    '& .MuiTypography-body1': {
      fontSize: { xs: 16, md: 14 },
    },
    '.MuiListSubheader-root, .MuiMenuItem-root': {
      px: '20px',
    },
    '& svg': {
      height: 16,
      width: 16,
    },
  },
};

type BlockProps = {
  node: ProseMirrorNode;
  getPos: (() => number) | boolean;
  updateAttributes: (attributes: Record<string, any>) => void;
};

const FILE_INPUT_ACCEPT = composerConfig.supportedFileTypes.join(',');

function File({ node, getPos, updateAttributes }: BlockProps) {
  const { t } = useTranslation('editor');
  const fileInputRef = useRef<HTMLInputElement>();
  const status = useRef<string>(node.attrs.status); // useRef to make sure we always get the latest status value to prevent triggering the logic inside useEffect multiple times
  const pos = typeof getPos === 'function' ? getPos() : null;
  const { editor } = useContext(TextComposerContext);

  const blockContainerBlock = useMemo(() => {
    const blockContainerNode = pos
      ? getBlockInfoFromPos(editor._tiptapEditor.state.doc, pos).node
      : null;
    return blockContainerNode
      ? blockLevelProseMirrorNodeToComposerBlocks(blockContainerNode)[0]
      : null;
  }, [editor._tiptapEditor.state.doc, pos]);

  const fileBlockMenuItems = useFileBlockMoreActionItems({
    editor,
    block: blockContainerBlock,
  });

  const [moreMenuOpen, setMoreMenuOpen] = useState(false);

  const updateStatus = useCallback(
    (newStatus: string) => {
      status.current = newStatus;
      // prevent "flushSync was called" error
      setTimeout(() => {
        updateAttributes({
          status: newStatus,
        });
      });
    },
    [updateAttributes]
  );

  const validateFile = useCallback(
    (file: File) => {
      if (!composerConfig.supportedFileTypes.includes(file.type)) {
        toast.error(
          t('Supported file types: ##', {
            types: composerConfig.supportedFileTypesMessage,
          })
        );
        return false;
      }

      const fileSizeInMb = file.size / (1024 * 1024);

      if (fileSizeInMb > composerConfig.fileSizeLimitMb) {
        toast.error(
          t('Up to ##MB in size', {
            limit: composerConfig.fileSizeLimitMb,
          })
        );
        return false;
      }
      return true;
    },
    [t]
  );

  const { handleFileChange, progress, changeFile } = useUploadFile({
    getUploadKeyAndUrl: async (file: File) => {
      const ext = file.name.split('.').pop()?.toLowerCase() || '';
      const { data } = await apis.file.getAhaFileUploadUrl(ext);
      return data;
    },
    onFileChange: ({ file }) => {
      if (!validateFile(file)) {
        updateStatus('upload-fail');
        return;
      }

      updateAttributes({
        filename: file.name,
        filesize: file.size,
        filetype: file.type,
      });
    },
    onSuccess: (key) => {
      updateAttributes({
        key,
        status: 'upload-success',
      });
    },
    onFail: (err) => {
      toast.error(t('Fail to upload file'));
      console.warn('upload fail', err);
      try {
        updateAttributes({
          status: 'upload-fail',
        });
        updateAttributes({
          filename: '', // clear filename so the file block ui will change back to placeholder
        });
      } catch (e) {
        // it's possible that user will leave this textComposer, make updateAttributes fail
        console.warn('updateAttributes fail', e);
      }
    },
  });

  useEffect(() => {
    if (status.current === 'added-by-insert') {
      fileInputRef.current?.click();
      updateStatus('ready-for-upload');
    }
  }, [updateStatus]);

  useEffect(() => {
    if (status.current === 'added-by-file-drop') {
      if (!validateFile(node.attrs.file)) {
        updateStatus('upload-fail');
        return;
      }

      // prevent "flushSync was called" error
      setTimeout(() => {
        changeFile(node.attrs.file);
      });
      updateStatus('ready-for-upload');
    }
  }, [changeFile, node.attrs.file, t, updateStatus, validateFile]);

  const debounceIsUploadFinish = useDebounce(progress === 100); // even when progress is 100, we need to show it before disappear

  return (
    <NodeViewWrapper>
      <ThemeProvider mode="dark">
        <Box>
          {node.attrs.filename ? (
            <Box sx={styles.fileContainer}>
              {progress !== null && !debounceIsUploadFinish ? (
                <LoadingIcon sx={{ display: 'inline-block' }} />
              ) : (
                <OtherDocumentIcon width={16} height={16} />
              )}

              <span className="file-name">{node.attrs.filename}</span>
              <span className="file-size">
                {(node.attrs.filesize / 1024).toFixed(1)}KB
              </span>
              <Typography
                className="file-more-menu-icon"
                onClick={() => setMoreMenuOpen(true)}
              >
                <ActionMoreIcon width={16} height={16} />
              </Typography>
            </Box>
          ) : (
            <Box sx={styles.placeholder} component="label">
              <OtherDocumentIcon />
              {t('File')}
              <VisuallyHiddenInput
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                inputProps={{ accept: FILE_INPUT_ACCEPT }}
              />
            </Box>
          )}
        </Box>
        <BottomSheet
          open={moreMenuOpen}
          onClose={() => setMoreMenuOpen(false)}
          sx={styles.bottomSheet}
          defaultContentHeight={fileBlockMenuItems.length * 45 + 40}
        >
          <ListSubheader>{node.attrs.filename}</ListSubheader>
          {fileBlockMenuItems.map(({ label, icon, onClick, sx }) => (
            <MenuItem
              key={label}
              value={label}
              onClick={() => {
                onClick();
                setMoreMenuOpen(false);
              }}
              sx={sx}
            >
              <ListItemIcon>
                <Icon name={icon} />
              </ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          ))}
        </BottomSheet>
      </ThemeProvider>
    </NodeViewWrapper>
  );
}

type FileBlockOptions = {
  HTMLAttributes: Record<string, any>;
};

export const fileBlockDefaultProps = {
  key: {
    default: '',
  },
  filename: {
    default: '',
  },
  filesize: {
    default: 0,
  },
  filetype: {
    default: '',
  },
  status: {
    default: '', // added-by-insert, added-by-file-drop, ready-for-upload, upload-success, upload-fail
  },
  file: {
    default: '',
  },
};
const FileBlock = Node.create<FileBlockOptions>({
  name: ThreadBlockTypes.File,
  group: 'blockContent', // to combine tiptap node with blocknote, this one should be blockContent
  inline: false,
  selectable: false,
  atom: true,

  addAttributes() {
    return fileBlockDefaultProps;
  },

  addKeyboardShortcuts: notionLikeFileImageKeyboardShortcuts,

  parseHTML() {
    return [
      {
        tag: 'image',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        'data-content-type': this.name,
        'data-render-ui': 'fileBlock',
        'data-metadata-filename': HTMLAttributes.filename || '',
        'data-metadata-file-key': HTMLAttributes.key || '',
        'data-metadata-file-size': HTMLAttributes.filesize || '',
        'data-metadata-file-type': HTMLAttributes.filetype || '',
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(File);
  },
});

export default FileBlock;
