import { ExamViewType } from '@lib/web/apis';

import useCurrentExam from './useCurrentExam';

export default function useCurrentSection(clubSlug?: string) {
  const { section } = useCurrentExam(clubSlug);

  return {
    section,
    isMath: section?.viewType === ExamViewType.Math,
    isVerbal: section?.viewType === ExamViewType.Verbal,
  };
}
