import React from 'react';

export default function PropertyTypeRecipeTrigger({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.144 21.7309C11.0033 21.9682 10.8055 22.0486 10.5507 21.972C10.2952 21.896 10.1674 21.7309 10.1674 21.4766V14.2047H8.05581C7.75666 14.2047 7.50573 14.107 7.30302 13.9118C7.10101 13.7172 7 13.4758 7 13.1876V3.01706C7 2.72889 7.10101 2.48717 7.30302 2.2919C7.50573 2.0973 7.75666 2 8.05581 2H15.9216C16.3087 2 16.6078 2.15256 16.819 2.45768C17.0302 2.76279 17.0566 3.08486 16.8982 3.42388L13.3348 11.1535H15.7104C16.1151 11.1535 16.4231 11.323 16.6342 11.662C16.8454 12.0011 16.8454 12.3401 16.6342 12.6791L11.144 21.7309Z"
        fill="currentColor"
      />
    </svg>
  );
}
