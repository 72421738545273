import React from 'react';

export default function ProfileDowngrade({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.9998 16.755L7.16527 12L5.01489 14.115L11.9998 21L19 14.115L16.8344 12L11.9998 16.755Z"
        fill="currentColor"
      />
      <path
        d="M16.8347 3L12.0002 7.755L7.16563 3L5 5.115L12.0002 12L18.9851 5.115L16.8347 3Z"
        fill="currentColor"
      />
    </svg>
  );
}
