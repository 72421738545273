import matchAll from 'string.prototype.matchall';

const VARIABLE_REGEX =
  /<span [^>]*?variableid="([^"]+)"[^>]*?>([^<]+)<\/span>/gm;
export const replaceVariables = (
  content: string,
  materials?: {
    id: string;
    content?: NullableString;
    value?: NullableNumber;
    creatorQuestionVariableId: string;
    name: string;
  }[]
) => {
  if (!materials?.length || !content) return content;

  let txt = content;
  const multipleMatchArr = [...matchAll(txt, VARIABLE_REGEX)];
  multipleMatchArr.forEach((match) => {
    const variableId = match[1];

    const currentVariable = materials.find(
      (material) => material.creatorQuestionVariableId === variableId
    );

    if (currentVariable) {
      let replaceValue: string | number = '';

      if (typeof currentVariable.value === 'number') {
        replaceValue = currentVariable.value;
      } else if (typeof currentVariable.content === 'string') {
        replaceValue = currentVariable.content;
      }

      txt = txt.replace(match[0], `<span>${replaceValue}</span>`);
    }
  });

  return txt;
};
