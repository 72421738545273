import { ReactNode } from 'react';
import { PassageLineNumType } from '@lib/web/apis';
import { LineNumberWrap, useRenderLineMarker } from '@lib/web/ui';

import usePracticeQuestion from '../../../../../hooks/usePracticeQuestion';

type RenderLineMarkerProps = {
  children: ReactNode;
  passageLineNumType: PassageLineNumType;
  isShowPassageLineNum?: boolean;
};

export default function RenderLineMarker({
  passageLineNumType,
  isShowPassageLineNum,
  children,
}: RenderLineMarkerProps) {
  const { id } = usePracticeQuestion();

  useRenderLineMarker(id);

  return (
    <LineNumberWrap
      lineNumberGroup={passageLineNumType}
      isShowPassageLineNum={isShowPassageLineNum}
      targetSelector="[data-content-type=paragraph]"
    >
      {children}
    </LineNumberWrap>
  );
}
