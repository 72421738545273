import React from 'react';

export default function OtherLiveChat({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21 16.663C20.9996 17.9085 20.5789 19.1165 19.8075 20.0871C19.0362 21.0576 17.9604 21.7325 16.7583 22L16.1841 20.2568C16.7099 20.1692 17.21 19.9646 17.6482 19.6578C18.0865 19.351 18.452 18.9497 18.7185 18.4827H16.5C16.0226 18.4827 15.5648 18.2908 15.2272 17.9492C14.8896 17.6076 14.7 17.1443 14.7 16.6612V13.0182C14.7 12.5351 14.8896 12.0718 15.2272 11.7302C15.5648 11.3886 16.0226 11.1967 16.5 11.1967H19.1442C18.9246 9.43615 18.0776 7.81719 16.7623 6.6436C15.4469 5.47002 13.7536 4.82247 12 4.82247C10.2464 4.82247 8.55306 5.47002 7.23772 6.6436C5.92237 7.81719 5.07543 9.43615 4.8558 11.1967H7.5C7.97739 11.1967 8.43523 11.3886 8.77279 11.7302C9.11036 12.0718 9.3 12.5351 9.3 13.0182V16.6612C9.3 17.1443 9.11036 17.6076 8.77279 17.9492C8.43523 18.2908 7.97739 18.4827 7.5 18.4827H4.8C4.32261 18.4827 3.86477 18.2908 3.52721 17.9492C3.18964 17.6076 3 17.1443 3 16.6612V12.1075C3 7.07741 7.0293 3 12 3C16.9707 3 21 7.07741 21 12.1075V16.663Z"
        fill="currentColor"
      />
    </svg>
  );
}
