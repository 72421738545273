import React from 'react';

export default function EditorRowChart({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        height="1.8"
        width="16"
        fill="currentColor"
        rx="0.9"
        x="4"
        y="19.2"
      />
      <rect
        height="6.22222"
        width="3.6"
        fill="currentColor"
        rx="1.8"
        transform="rotate(90 10.2222 3)"
        x="10.2222"
        y="3"
      />
      <rect
        height="9.77778"
        width="3.6"
        fill="currentColor"
        rx="1.8"
        transform="rotate(90 13.7778 8.3999)"
        x="13.7778"
        y="8.3999"
      />
      <rect
        height="13.3333"
        width="3.6"
        fill="currentColor"
        rx="1.8"
        transform="rotate(90 17.3335 13.7998)"
        x="17.3335"
        y="13.7998"
      />
    </svg>
  );
}
