import React, {
  ClipboardEvent,
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Highlight from 'react-highlight';
import { alpha, Collapse, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import {
  EditorPreamble as EditorPreambleIcon,
  OtherLaTex as OtherLaTexIcon,
} from '@front/icon';
import { Scrollbar, TipButton } from '@front/ui';
import { parseChildNodesForValueAndLines } from '@lib/web/composer';
import BlockTag from '@lib/web/composer/BlockTag';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';
import { EditorBlockTypes } from '@lib/web/editor/configs';
import { ErrorMessageValue } from '@lib/web/ui';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import katex, { KatexOptions } from 'katex';

import ThemeProvider from '../../../theme/ThemeProvider';

import PopupWrap from './components/PopupWrap';

export type InlineLatexOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineLatexOptions;
    node: ProseMirrorNode;
  }) => string;
};

const styles = {
  preambleContent: {
    borderTop: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? '1px solid rgba(8, 8, 8, 0.05)'
        : '1px solid rgba(255, 255, 255, 0.1)',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    py: 1,
    px: '12px',
    display: 'grid',
    gap: 1,
    pre: {
      m: 0,
      typography: 'body1',
      '& .xml': {
        color: 'primary.light',
      },
    },
    code: {
      typography: 'body1',
    },
  },
  display: {
    whiteSpace: 'pre-wrap',
  },
  displayHoverable: {
    '&:hover': {
      borderRadius: 1,
      cursor: 'pointer',
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    },
  },
  content: {
    typography: 'numberBody3',
    '&:empty::before': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },
  },
  scroll: {
    maxHeight: 159,
  },
};

const LATEX_DEFAULT_OPTIONS: KatexOptions = {
  leqno: true,
  fleqn: true,
  macros: { '\\f': '#1f(#2)' },
};

type LatexProps = {
  node: ProseMirrorNode;
  updateAttributes: (attributes: Record<string, any>) => void;
};

const Latex = ({ node, updateAttributes }: LatexProps) => {
  const { editor } = useContext(TextComposerContext);
  const [show, setShow] = useState(!!node.attrs.defaultShow);
  const [showPreamble, setShowPreamble] = useState(false);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [errors, setErrors] = useState<ErrorMessageValue[]>([]);
  const [defaultValue, setDefaultValue] = useState(node.attrs.value);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const displayRef = useRef<HTMLDivElement | null>(null);
  const checkedValueRef = useRef(defaultValue);
  const popperContentRef = useRef<HTMLDivElement>();

  const isContentEmpty = !node.attrs.value || node.attrs.value === '\n';

  const handleInput = (ev: FormEvent<Element>) => {
    const updatedValue =
      parseChildNodesForValueAndLines(
        (ev.target as HTMLDivElement).childNodes
      ) || '\n';

    updateAttributes({
      value: updatedValue,
    });
  };

  useEffect(() => {
    if (displayRef.current) {
      try {
        katex.render(node.attrs.value, displayRef.current, {
          ...LATEX_DEFAULT_OPTIONS,
          throwOnError: true,
        });

        setErrors([]);
      } catch (err) {
        setErrors([
          {
            id: '',
            type: 'error',
            message:
              err instanceof katex.ParseError ? err.message : 'Error in LaTeX',
          },
        ]);
      }
    }
  }, [node.attrs.value]);

  const handlePaste = (ev: ClipboardEvent) => {
    ev.preventDefault();
    const copyData = ev.clipboardData?.getData('text/plain') || '';

    // TODO: use execCommand to keep cursor at the end, but execCommand will be deprecated in the future
    document.execCommand('insertHTML', false, copyData);
  };

  const openPopper = () => {
    if (!editor.isEditable) {
      return;
    }

    setShow(true);
    setTimeout(() => {
      if (!popperContentRef.current) {
        return;
      }
      const selectionObject = window.getSelection
        ? window.getSelection()
        : document.getSelection();

      selectionObject?.selectAllChildren(popperContentRef.current);
    });
  };

  const closePopper = () => {
    setShow(false);

    if (errors.length) {
      checkedValueRef.current = node.attrs.value;
    }

    if (!errors.length) {
      updateAttributes({
        value: checkedValueRef.current,
      });
    }

    setDefaultValue(checkedValueRef.current);
  };

  const handleDone = () => {
    checkedValueRef.current = node.attrs.value;

    closePopper();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();

      checkedValueRef.current = node.attrs.value;
      closePopper();
    }
  };

  useEffect(() => {
    if (!firstLoaded && displayRef.current) {
      setDefaultValue(node.attrs.value);

      katex.render(node.attrs.value, displayRef.current, {
        ...LATEX_DEFAULT_OPTIONS,
        throwOnError: false,
      });

      setFirstLoaded(true);
    }
  }, [node.attrs.value, firstLoaded]);

  useEffect(() => {
    if (node.attrs.defaultShow) {
      setTimeout(() => {
        popperContentRef.current?.focus();

        updateAttributes({
          defaultShow: false,
        });
      });
    }
  }, [node.attrs.defaultShow, updateAttributes]);

  return (
    <NodeViewWrapper
      style={{ display: 'inline-block' }}
      className={EditorBlockTypes.InlineLatex}
    >
      <ThemeProvider mode="dark">
        <>
          <div ref={anchorRef} contentEditable={false}>
            <Box
              ref={displayRef}
              sx={[
                styles.display,
                editor.isEditable && styles.displayHoverable,
              ]}
              display={
                isContentEmpty || errors.length > 0 ? 'none' : 'inline-flex'
              }
              onClick={openPopper}
            />
            {(isContentEmpty || errors.length > 0) && (
              <BlockTag
                active={show}
                error={errors.length > 0}
                icon={<OtherLaTexIcon />}
                onClick={openPopper}
              >
                LaTeX
              </BlockTag>
            )}
          </div>

          <Popover
            open={show && Boolean(anchorRef.current)}
            anchorEl={anchorRef.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={closePopper}
          >
            <PopupWrap
              maxContentHeight={177}
              errors={errors}
              onDone={handleDone}
              actionComponent={
                <TipButton
                  onClick={() => setShowPreamble((st) => !st)}
                  customSize={24}
                  selected={showPreamble}
                  title="Show Preamble"
                >
                  <EditorPreambleIcon />
                </TipButton>
              }
              bottomComponent={
                <Collapse in={showPreamble}>
                  <Scrollbar sx={styles.scroll}>
                    <Box sx={styles.preambleContent}>
                      <Highlight>{`\\usepackage{mathtools}`}</Highlight>
                      <Highlight>{`\\usepackage{bm}`}</Highlight>
                    </Box>
                  </Scrollbar>
                </Collapse>
              }
            >
              <Box
                ref={popperContentRef}
                sx={styles.content}
                contentEditable
                data-placeholder="E = MC^2 "
                suppressContentEditableWarning
                onInput={handleInput}
                onPaste={handlePaste}
                onKeyDown={handleKeyDown}
              >
                {defaultValue}
              </Box>
            </PopupWrap>
          </Popover>
        </>
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export const InlineLatex = Node.create<InlineLatexOptions>({
  name: EditorBlockTypes.InlineLatex,

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      value: {
        default: '',
      },
      defaultShow: {
        default: true,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.name,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const elem = document.createElement('span');
    katex.render(HTMLAttributes.value, elem, {
      ...LATEX_DEFAULT_OPTIONS,
      throwOnError: false,
    });
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        'data-content-type': this.name,
      }),
      elem,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Latex);
  },
});
