import React from 'react';

export default function TestDislikeOutline({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.4545 4H7.09091C6.41182 4 5.83091 4.4 5.58545 4.976L3.11455 10.616C3.04091 10.8 3 10.992 3 11.2V12.8C3 13.68 3.73636 14.4 4.63636 14.4H9.79909L9.02182 18.056L8.99727 18.312C8.99727 18.64 9.13636 18.944 9.35727 19.16L10.2245 20L15.6164 14.728C15.9109 14.44 16.0909 14.04 16.0909 13.6V5.6C16.0909 4.72 15.3545 4 14.4545 4ZM14.4545 13.6L10.9036 17.072L12 12.8H4.63636V11.2L7.09091 5.6H14.4545V13.6ZM17.7273 4H21V13.6H17.7273V4Z"
        fill="currentColor"
      />
    </svg>
  );
}
