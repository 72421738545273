import { ReactNode } from 'react';
import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';

import { numberFormat } from '../../utils';

import Arrow from './Icons/Arrow';

interface DeltaValueProps {
  value: number | string;
  reverseColor?: boolean;
  reverseArrow?: boolean;
  color?: string;
  suffix?: string;
  fontVariant?: TypographyProps['variant'];
  fontWeight?: TypographyProps['fontWeight'];
  sx?: BoxProps['sx'];
  textSx?: TypographyProps['sx'];
  arrowIcon?: ReactNode;
  arrowIconSize?: number;
  showIconOnly?: boolean;
}

const getColor = (
  value: number,
  reverseColor?: boolean,
  color?: string,
  isDark?: boolean
) => {
  if (color) return color;

  if (value === 0) return 'grey.500';

  const positiveColor = isDark ? 'success.light' : 'success.dark';
  const negativeColor = isDark ? 'error.dark' : 'error.dark';

  if (reverseColor) return value > 0 ? negativeColor : positiveColor;
  return value > 0 ? positiveColor : negativeColor;
};

const getTransform = (value: number, reverseArrow?: boolean) => {
  if (value === 0) return 'rotate(90deg)';

  // assume our arrow is up ↑
  const upArrowTransform = '';
  const downArrowTransform = 'rotate(180deg)';

  if (reverseArrow) return value > 0 ? downArrowTransform : upArrowTransform;
  return value > 0 ? upArrowTransform : downArrowTransform;
};

const styles = {
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  arrow: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    transformOrigin: '50% 50%',
  },
  text: { color: 'inherit', ml: 0.5 },
};
export default function DeltaValue({
  sx,
  value,
  reverseColor,
  reverseArrow,
  color,
  suffix,
  textSx,
  fontVariant = 'body1',
  fontWeight = 400,
  arrowIcon,
  arrowIconSize,
  showIconOnly = false,
}: DeltaValueProps) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const checkValue = numberFormat(+value) as number;
  const mainColor: string = getColor(checkValue, reverseColor, color, isDark);
  const transform: string = getTransform(checkValue, reverseArrow);
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const textSxProps = Array.isArray(textSx) ? textSx : [textSx];

  return (
    <Box
      sx={[
        styles.root,
        {
          color: mainColor,
        },
        ...sxProps,
      ]}
    >
      <Box sx={[styles.arrow, { transform }]}>
        {arrowIcon || <Arrow width={arrowIconSize} height={arrowIconSize} />}
      </Box>
      {!showIconOnly && (
        <Typography
          sx={[styles.text, ...textSxProps]}
          variant={fontVariant}
          fontWeight={fontWeight}
        >
          {typeof checkValue === 'number' ? Math.abs(checkValue) : checkValue}
          {suffix}
        </Typography>
      )}
    </Box>
  );
}
