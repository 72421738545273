import React from 'react';

export default function EditorContinueWriting({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.1754 2.26627C18.5304 1.91124 19.106 1.91124 19.461 2.26627L21.7337 4.53899C22.0888 4.89402 22.0888 5.46962 21.7337 5.82464L14.9156 12.6428C14.7451 12.8133 14.5138 12.9091 14.2727 12.9091H12C11.4979 12.9091 11.0909 12.5021 11.0909 12V9.72727C11.0909 9.48617 11.1867 9.25494 11.3572 9.08445L18.1754 2.26627ZM2.93193 12.0228C3.52864 11.4261 4.33795 11.0909 5.18182 11.0909H8.36364C8.86571 11.0909 9.27273 11.4979 9.27273 12C9.27273 12.5021 8.86571 12.9091 8.36364 12.9091H5.18182C4.82016 12.9091 4.47331 13.0528 4.21758 13.3085C3.96185 13.5642 3.81818 13.9111 3.81818 14.2727C3.81818 14.6344 3.96185 14.9812 4.21758 15.237C4.47331 15.4927 4.82016 15.6364 5.18182 15.6364H18.8182C19.6621 15.6364 20.4714 15.9716 21.0681 16.5683C21.6648 17.165 22 17.9743 22 18.8182C22 19.6621 21.6648 20.4714 21.0681 21.0681C20.4714 21.6648 19.6621 22 18.8182 22H9.27273C8.77065 22 8.36364 21.593 8.36364 21.0909C8.36364 20.5888 8.77065 20.1818 9.27273 20.1818H18.8182C19.1798 20.1818 19.5267 20.0382 19.7824 19.7824C20.0382 19.5267 20.1818 19.1798 20.1818 18.8182C20.1818 18.4565 20.0382 18.1097 19.7824 17.8539C19.5267 17.5982 19.1798 17.4545 18.8182 17.4545H5.18182C4.33795 17.4545 3.52864 17.1193 2.93193 16.5226C2.33523 15.9259 2 15.1166 2 14.2727C2 13.4289 2.33523 12.6195 2.93193 12.0228Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
