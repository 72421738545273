import { replaceComponent } from '@front/ui';

type HintProps = {
  hint: string;
  regex?: RegExp;
};
export default function Hint({ hint, regex = /\*\*(.*?)\*\*/g }: HintProps) {
  return (
    <span>
      {replaceComponent(hint, regex, (match, i) => {
        return <b key={match[0] + i}>{match[1]}</b>;
      })}
    </span>
  );
}
