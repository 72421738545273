import React, { useMemo } from 'react';
import Router from 'next/router';
import { Box, Typography } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import EmojiText from '@lib/ia/src/components/EmojiMessage/EmojiMessage';
import IaSortable from '@lib/ia/src/components/IaSortable';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import {
  ListLayoutAreaList,
  ListLayoutItem,
  ListLayoutItemEvent,
} from '@lib/ia/src/layouts/ListLayout/types';

import ListItem from './ListItem';

const styles = {
  title: {
    px: { xs: 2.5, md: 1.5 },
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
  content: { px: { sx: 2.5, md: 1.5 } },
};

export type AreaListProps = ListLayoutAreaList;

export default function AreaList({
  items,
  areaHintActions,
  settings,
}: AreaListProps) {
  const { getIaAction } = useIaAction();

  const displayItems = useMemo(() => {
    const nonHiddenItems = items.filter((item) => !item.hidden);
    const hiddenItems = items.filter((item) => item.hidden);
    return [...nonHiddenItems, ...hiddenItems];
  }, [items]);

  const handleEventClick = (
    item: ListLayoutItem,
    event: ListLayoutItemEvent
  ) => {
    if (event.type === 'link') {
      Router.push(event.value);
    } else {
      const onClickAction = getIaAction<ListLayoutItem>(event.value);

      onClickAction?.action?.(item);
    }
  };

  return (
    <Box>
      {settings?.title && (
        <Typography variant="caption" sx={styles.title}>
          {settings.title}
        </Typography>
      )}
      <IaSortable items={items} config={settings?.sortableConfig}>
        <Box>
          {displayItems.map((item, i) => (
            <ListItem
              key={item.id}
              index={i}
              item={item}
              areaHintActions={areaHintActions}
              order={settings?.showOrder ? i + 1 : undefined}
              itemEventHint={settings?.itemEventHint}
              onEventClick={handleEventClick}
            />
          ))}
          {settings?.emptyText && items.length === 0 && (
            <Box sx={styles.content}>
              <EmojiText text={settings.emptyText} />
            </Box>
          )}
        </Box>
      </IaSortable>
    </Box>
  );
}
