import { Fragment, ReactNode } from 'react';
import { Box, ButtonBase } from '@mui/material';
import { Scrollbar } from '@front/ui';
import IaDisplayTableSection from '@lib/ia/src/layouts/DisplayTableLayout/components/IaDisplayTableSection';

import IaDisplayTableBody from './components/IaDisplayTableBody';
import IaDisplayTableCell from './components/IaDisplayTableCell';
import IaDisplayTableDataStateRow from './components/IaDisplayTableDataStateRow';
import IaDisplayTableFooter from './components/IaDisplayTableFooter';
import IaDisplayTableFooterCell from './components/IaDisplayTableFooterCell';
import IaDisplayTableFooterRow from './components/IaDisplayTableFooterRow';
import IaDisplayTableHeader from './components/IaDisplayTableHeader';
import IaDisplayTableHeaderCell from './components/IaDisplayTableHeaderCell';
import IaDisplayTableHeaderRow from './components/IaDisplayTableHeaderRow';
import IaDisplayTableRow from './components/IaDisplayTableRow';
import { DisplayTableLayoutConfig, DisplayTableLayoutRow } from './types';

type DisplayTableLayoutProps = DisplayTableLayoutConfig;

const INNER_MAX_WIDTH = 796; // base width from the figma
const ROW_HEIGHT_DEFAULT = '32px';

const styles = {
  root: {
    maxWidth: INNER_MAX_WIDTH,
    pb: '20px',
    width: '100%',
  },
};

type WrapperProps = {
  disableScroll?: boolean;
  children: ReactNode;
};

function Wrapper({ disableScroll, children }: WrapperProps) {
  if (disableScroll) return children;
  return <Scrollbar>{children}</Scrollbar>;
}

export default function DisplayTableLayout({
  table: {
    columnOrder,
    gridTemplateColumns,
    rows,
    childRows = {},
    headerRow,
    footerRow,
    sections,
    data,
  },
  settings: {
    rowHeight,
    rowComponent,
    rowStates,
    hoverable,
    disableScroll = false,
  } = {},
}: DisplayTableLayoutProps) {
  const rowSx = {
    gridTemplateColumns,
    gridAutoRows: rowHeight || ROW_HEIGHT_DEFAULT,
  };

  const rowComponentType = rowComponent === 'button' ? ButtonBase : Box;

  const renderRow = (row: DisplayTableLayoutRow) => {
    const children = childRows[row.id] || [];
    return (
      <Fragment key={row.id}>
        <IaDisplayTableRow
          key={row.id}
          row={row}
          columnOrder={columnOrder}
          sx={rowSx}
          component={rowComponentType}
          states={rowStates}
          currentState={row.state}
          hoverable={hoverable}
        >
          {(cell) => <IaDisplayTableCell row={row} cell={cell} />}
        </IaDisplayTableRow>
        {children.map((childRow) => (
          <IaDisplayTableRow
            key={childRow.id}
            row={childRow}
            columnOrder={columnOrder}
            sx={rowSx}
            component={rowComponentType}
            states={rowStates}
            currentState={childRow.state}
            hoverable={hoverable}
          >
            {(cell) => (
              <IaDisplayTableCell row={childRow} cell={cell} isChild={true} />
            )}
          </IaDisplayTableRow>
        ))}
      </Fragment>
    );
  };

  return (
    <Wrapper disableScroll={disableScroll}>
      <Box sx={styles.root} className="display-table-layout">
        <IaDisplayTableHeader>
          <IaDisplayTableHeaderRow row={headerRow}>
            {(cell) => <IaDisplayTableHeaderCell cell={cell} />}
          </IaDisplayTableHeaderRow>
        </IaDisplayTableHeader>
        <IaDisplayTableBody>
          {sections?.map((section) => (
            <IaDisplayTableSection key={section.id} section={section}>
              {section.rows?.map(renderRow)}
            </IaDisplayTableSection>
          ))}
          {rows?.map(renderRow)}
        </IaDisplayTableBody>
        <IaDisplayTableDataStateRow data={data} />
        <IaDisplayTableFooter>
          <IaDisplayTableFooterRow row={footerRow}>
            {(cell) => <IaDisplayTableFooterCell cell={cell} />}
          </IaDisplayTableFooterRow>
        </IaDisplayTableFooter>
      </Box>
    </Wrapper>
  );
}
