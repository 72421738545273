import { useMemo } from 'react';
import { nonNullable } from '@front/helper';
import { TableLayoutRow } from '@lib/ia/src/layouts/TableLayout/types';
import { apis } from '@lib/web/apis';
import { callWithToast } from '@lib/web/utils';

import { useEditorRubricCriteriaApis } from './useEditorRubricCriteriaApis';
import { useEditorRubricData } from './useEditorRubricData';

export const useEditorRubricActions = ({
  questionId,
  readonly,
  onCriteriaSidePeelClick,
}: {
  questionId: string;
  readonly?: boolean;
  onCriteriaSidePeelClick?: (id: string) => void;
}) => {
  const { rubric, mutateRubric } = useEditorRubricData(questionId);
  const {
    addNewCriteria,
    updateCriteriaDescription,
    updateCriteriaScore,
    deleteCriteria,
    duplicateCriteria,
  } = useEditorRubricCriteriaApis(questionId);

  return useMemo(() => {
    return {
      addNewCriteria: {
        action: () => {
          if (readonly) return;
          addNewCriteria();
        },
      },
      addNewCriteriaAbove: {
        action: ({ id }: { id: string }) => {
          if (readonly) return;
          addNewCriteria({ position: 'above', anchorId: id });
        },
      },
      addNewCriteriaBelow: {
        action: ({ id }: { id: string }) => {
          if (readonly) return;
          addNewCriteria({ position: 'below', anchorId: id });
        },
      },
      updateCriteriaDescription: {
        action: ({ row, value }: { row: TableLayoutRow; value: string }) => {
          if (readonly) return;
          updateCriteriaDescription({ criteriaId: row.id, value });
        },
      },
      updateCriteriaScore: {
        action: ({ row, value }: { row: TableLayoutRow; value: number }) => {
          if (readonly) return;
          updateCriteriaScore({
            criteriaId: row.id,
            value: value === null ? 1 : value,
          });
        },
      },
      sortItem: {
        action: ({
          activeItem,
          overItem,
          newItems,
        }: {
          activeItem: TableLayoutRow;
          overItem: TableLayoutRow;
          newItems: TableLayoutRow[];
        }) => {
          if (readonly || !rubric) return;

          const activeIndex = newItems.findIndex(
            (item) => item.id === activeItem.id
          );
          const overIndex = newItems.findIndex(
            (item) => item.id === overItem.id
          );
          const isMovingDown = overIndex > activeIndex;

          mutateRubric(
            callWithToast(
              apis.editor.updateCreatorQuestionCriteriaOrder({
                id: activeItem.id,
                ...(isMovingDown
                  ? { beforeCriteriaId: overItem.id }
                  : { afterCriteriaId: overItem.id }),
              }),
              {
                showLoading: false,
              }
            ),
            {
              optimisticData: {
                ...rubric,
                criteria: newItems
                  .map((item) => rubric.criteria.find((c) => c.id === item.id))
                  .filter(nonNullable),
              },
            }
          );
        },
      },
      deleteCriteria: {
        action: ({ id }: { id: string }) => {
          if (readonly) return;
          deleteCriteria([id]);
        },
        batchAction: async (items?: { id: string }[]) => {
          if (readonly) return;
          const ids = items?.map((item) => item.id) || [];
          deleteCriteria(ids);
        },
      },
      duplicateCriteria: {
        action: ({ id }: { id: string }) => {
          if (readonly) return;
          duplicateCriteria(id);
        },
      },
      onCriteriaSidePeelClick: {
        action: ({ id }: { id: string }) => {
          if (readonly) return;
          onCriteriaSidePeelClick?.(id);
        },
      },
    };
  }, [
    readonly,
    rubric,
    addNewCriteria,
    updateCriteriaDescription,
    updateCriteriaScore,
    deleteCriteria,
    duplicateCriteria,
    mutateRubric,
    onCriteriaSidePeelClick,
  ]);
};
