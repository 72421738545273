import { countries } from 'countries-list';

/**
 * Get the flag emoji for a given country code
 * @param countryCode - The two-letter country code
 * @returns The flag emoji for the country
 */
export const countryCodeToFlagEmoji = (countryCode?: string | null): string => {
  if (!countryCode) {
    return '';
  }

  // Ensure the country code is in uppercase
  countryCode = countryCode.toUpperCase();

  // Convert each letter to a regional indicator symbol
  const flagEmoji = countryCode
    .split('')
    .map((char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    .join('');

  return flagEmoji;
};

/**
 * Get the flag emoji based on various forms of country input
 * @param input - The country input (can be code, name, or abbreviation)
 * @returns The flag emoji for the country or an error message
 */
export const getFlagFromCountryInput = (input: string): string => {
  // Convert input to lowercase and trim whitespace
  input = input.toLowerCase().trim();

  // Check if input is a direct country code
  if (countries[input.toUpperCase() as keyof typeof countries]) {
    return countryCodeToFlagEmoji(input.toUpperCase());
  }

  // Search for full country name
  const countryEntry = Object.entries(countries).find(
    ([, country]) =>
      country.name.toLowerCase() === input ||
      country.native.toLowerCase() === input
  );

  if (countryEntry) {
    return countryCodeToFlagEmoji(countryEntry[0]);
  }

  // If no exact match, perform a fuzzy search
  const possibleMatch = Object.entries(countries).find(
    ([, country]) =>
      country.name.toLowerCase().includes(input) ||
      country.native.toLowerCase().includes(input)
  );

  if (possibleMatch) {
    return countryCodeToFlagEmoji(possibleMatch[0]);
  }

  return '';
};
