import { useClubSetting } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

export const useEnableBlockType = (isPassageEditor?: boolean) => {
  const clubSlug = useClubSlug();
  const { data } = useClubSetting(clubSlug);
  const settingEnableLatex = data?.data.isEnableLatex !== false;
  const settingEnableLineMarker = data?.data.isEnableLineMarker !== false;
  const settingEnableHighlight = data?.data.isEnableHighlight !== false;

  return {
    isEnableLatex: settingEnableLatex,
    isEnableLineMarker: !isPassageEditor && settingEnableLineMarker,
    isEnableLineAnchor: !!isPassageEditor && settingEnableLineMarker,
    isEnableHighlight: !isPassageEditor && settingEnableHighlight,
    isEnableHighlightAnchor: !!isPassageEditor && settingEnableHighlight,
  };
};
