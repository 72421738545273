import React from 'react';

export default function PropertyTypeShortText({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.496 8.51137C17.2892 8.51137 16.2093 8.82036 15.2692 9.44614L15.1049 9.55545V12.0981L15.7156 11.5575C16.5169 10.848 17.3993 10.5036 18.3744 10.5036C18.9001 10.5036 19.2385 10.669 19.4588 10.9543C19.6584 11.2127 19.7949 11.6127 19.8308 12.1923L17.4875 12.536C16.4601 12.6856 15.6388 13.0331 15.0739 13.6175C14.5032 14.208 14.2361 14.9966 14.2361 15.9389C14.2361 16.8332 14.5134 17.5858 15.0931 18.1574L15.0946 18.1589C15.6839 18.7333 16.4803 19 17.436 19C18.0971 19 18.6943 18.8462 19.2152 18.5277C19.4415 18.3892 19.6497 18.222 19.8402 18.0276V18.7817H22V12.3548C22 11.1482 21.7285 10.1746 21.1208 9.50131C20.5069 8.82109 19.6081 8.51137 18.496 8.51137ZM18.1143 14.375C17.4819 14.468 17.052 14.628 16.7853 14.8253L16.7819 14.8279C16.583 14.9715 16.4306 15.2612 16.4306 15.8298C16.4306 16.1902 16.5474 16.4629 16.7735 16.6785C17.0043 16.8875 17.3227 17.0078 17.7662 17.0078C18.3712 17.0078 18.8523 16.7926 19.2381 16.358C19.6333 15.9133 19.8402 15.3463 19.8402 14.629V14.1263L18.1143 14.375Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M5.65661 15.1248H10.4186L11.7392 18.7817H14.1401L9.07307 5H7.04864L2 18.7817H4.41415L5.65661 15.1248ZM8.0312 8.32875L6.39784 13.0143H9.67922L8.0312 8.32875Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
