import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  alpha,
  Box,
  debounce,
  Portal,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ActionClose as ActionCloseIcon,
  OtherDrag as OtherDragIcon,
  OtherKeyboard as OtherKeyboardIcon,
} from '@front/icon';
import { animated, useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';

import { TipButton } from '../../atoms';
import BottomSheet from '../BottomSheet';

import MathKeyboard, { MathKeyboardProps } from './MathKeyboard';

const AnimatedBox = animated(Box);

export type MathKeyboardPopupProps = {
  open: boolean;
  marginThreshold?: number;
  anchorEl?: HTMLButtonElement | null;
  onClose: () => void;
} & MathKeyboardProps;

const styles = {
  popper: {
    pb: 2,
    pt: 1,
    px: '12px',
    width: 305,
    bgcolor: 'background.menu',
    border: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? '1px solid #F2F2F2'
        : '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: 2,
    position: 'fixed',
    zIndex: 'modal',
  },
  dragging: {
    background: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? 'linear-gradient(0deg, rgba(0, 114, 221, 0.05), rgba(0, 114, 221, 0.05)), #F8F8F8'
        : `linear-gradient(0deg,${alpha(
            theme.palette.primary.light,
            0.05
          )},${alpha(theme.palette.primary.light, 0.05)}), #151A28`,
    borderColor: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? 'alpha.primaryDarkA50'
        : alpha(theme.palette.primary.light, 0.5),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  closeBtn: {
    ml: 'auto',
  },
  dragHandle: {
    touchAction: 'none',
  },
  sheet: {
    px: 2.5,
    pb: 1,
  },
};

const MathKeyboardPopup = forwardRef<
  HTMLDivElement,
  Omit<MathKeyboardPopupProps, 'open'>
>(({ onClose, anchorEl, marginThreshold = 4, ...rest }, ref) => {
  const [isDragging, setIsDragging] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLButtonElement>(null);
  const settingRef = useRef({ maxX: 0, maxY: 0, minX: 0, minY: 0 });
  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
  }));

  useImperativeHandle(ref, () => boxRef.current as HTMLDivElement);

  useEffect(() => {
    const initSetting = debounce(() => {
      const containerRect = boxRef.current?.getBoundingClientRect() || {
        height: 0,
        width: 0,
        left: 0,
        top: 0,
      };

      const ox = style.x.get();
      const oy = style.y.get();
      const maxX =
        window.innerWidth - containerRect.width - containerRect.left + ox;
      const maxY =
        window.innerHeight - containerRect.height - containerRect.top + oy;
      const minX = -containerRect.left + ox;
      const minY = -containerRect.top + oy;

      settingRef.current = { maxX, maxY, minX, minY };

      let x = ox;
      let y = oy;

      if (ox > settingRef.current.maxX) x = settingRef.current.maxX;
      else if (ox < settingRef.current.minX) x = settingRef.current.minX;

      if (oy > settingRef.current.maxY) y = settingRef.current.maxY;
      else if (oy < settingRef.current.minY) y = settingRef.current.minY;

      if ((x !== ox || y !== oy) && ox !== 0 && oy !== 0) {
        api.start({ x, y, immediate: true });
      }
    });
    window.addEventListener('resize', initSetting);
    initSetting();
    return () => window.removeEventListener('resize', initSetting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGesture(
    {
      onDrag: ({ pinching, cancel, first, last, offset: [ox, oy] }) => {
        if (first) {
          setIsDragging(true);
        } else if (last) {
          setIsDragging(false);
        }
        if (pinching) {
          setIsDragging(false);
          return cancel();
        }

        let x = ox;
        let y = oy;

        if (ox > settingRef.current.maxX) x = settingRef.current.maxX;
        else if (ox < settingRef.current.minX) x = settingRef.current.minX;

        if (oy > settingRef.current.maxY) y = settingRef.current.maxY;
        else if (oy < settingRef.current.minY) y = settingRef.current.minY;

        api.start({ x, y, immediate: true });
      },
    },
    {
      target: dragRef,
      drag: { from: () => [style.x.get(), style.y.get()] },
    }
  );

  const getPositioningStyle = useCallback(
    (anchor: HTMLButtonElement, element: HTMLDivElement) => {
      const elemRect = {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };

      const anchorRect = anchor.getBoundingClientRect();

      let top = anchorRect.top + marginThreshold + anchorRect.height;
      let left = anchorRect.left;

      if (top + elemRect.height > window.innerHeight) {
        top = window.innerHeight - elemRect.height;
      } else if (top < 0) {
        top = 0;
      }

      if (left + elemRect.width > window.innerWidth) {
        left = window.innerWidth - elemRect.width;
      } else if (left < 0) {
        left = 0;
      }
      return {
        top,
        left,
      };
    },
    [marginThreshold]
  );

  const setPositioningStyles = useCallback(() => {
    const boxElement = boxRef.current;
    const anchorElement = anchorEl;

    if (!boxElement || !anchorElement) {
      return;
    }

    const positioning = getPositioningStyle(anchorElement, boxElement);

    api.start({ x: positioning.left, y: positioning.top, immediate: true });
  }, [anchorEl, api, getPositioningStyle]);

  useEffect(() => {
    if (!anchorEl) {
      return undefined;
    }
    setPositioningStyles();
  }, [anchorEl, setPositioningStyles]);

  return (
    <AnimatedBox
      ref={boxRef}
      sx={[styles.popper, isDragging && styles.dragging]}
      style={style}
    >
      <Box sx={styles.header}>
        <TipButton
          ref={dragRef}
          sx={styles.dragHandle}
          customSize={24}
          title="Drag"
        >
          <OtherDragIcon />
        </TipButton>
        <OtherKeyboardIcon width={16} height={16} />
        <Typography variant="subtitle2">Math Keyboard</Typography>

        <TipButton
          onClick={onClose}
          sx={styles.closeBtn}
          customSize={24}
          title="Close"
        >
          <ActionCloseIcon />
        </TipButton>
      </Box>
      <MathKeyboard {...rest} />
    </AnimatedBox>
  );
});

function MathKeyboardSheet({
  open,
  onClose,
  ...rest
}: Omit<MathKeyboardPopupProps, 'anchorEl' | 'marginThreshold'>) {
  return (
    <BottomSheet open={open} onClose={onClose} fixedHeight disableBackdrop>
      <Box sx={styles.sheet}>
        <MathKeyboard {...rest} />
      </Box>
    </BottomSheet>
  );
}

export default function MathKeyboardPopupRoot({
  open,
  anchorEl,
  ...rest
}: MathKeyboardPopupProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (mdUp)
    return open ? (
      <Portal>
        <MathKeyboardPopup anchorEl={anchorEl} {...rest} />
      </Portal>
    ) : null;
  return <MathKeyboardSheet open={open} {...rest} />;
}
