import React from 'react';

export default function CryptoCurrencyEthereumAlt({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="12" cy="12" fill="white" fillOpacity="0.1" r="12" />
      <path
        d="M11.9675 4.80005L11.8721 5.12431V14.5326L11.9675 14.6279L16.3347 12.0464L11.9675 4.80005Z"
        fill="#D5D5D5"
      />
      <path
        d="M11.9674 4.80005L7.6001 12.0464L11.9674 14.6279V10.0613V4.80005Z"
        fill="white"
      />
      <path
        d="M11.9674 15.4549L11.9136 15.5205V18.8719L11.9674 19.0289L16.3372 12.8748L11.9674 15.4549Z"
        fill="#D5D5D5"
      />
      <path
        d="M11.9674 19.0289V15.4549L7.6001 12.8748L11.9674 19.0289Z"
        fill="white"
      />
      <path
        d="M11.9673 14.6278L16.3344 12.0464L11.9673 10.0613V14.6278Z"
        fill="#ABABAB"
      />
      <path
        d="M7.6001 12.0464L11.9674 14.6278V10.0613L7.6001 12.0464Z"
        fill="#D5D5D5"
      />
    </svg>
  );
}
