export const testIdConfig = {
  // editor & practice
  freeResponseTextArea: 'free-response-textarea',
  freeResponsePrefix: 'free-response-',
  essayAnswerTextArea: 'essay-answer-textarea',
  essayAnswerPrefix: 'essay-answer-',
  gridInPrefix: 'grid-input-',

  // practice note
  practiceNote: 'practice-note',

  // start practice
  startPractice: 'start-practice',
  startChallenge: 'start-challenge',
};
