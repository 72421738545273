import React from 'react';

export default function EditorGridIn({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#34AB6A"
        fillRule="evenodd"
      />
      <path
        d="M3.81552 10.4713V13.5287H6.87293V10.4713H3.81552ZM10.4398 10.4713V13.5287H13.4972V10.4713H10.4398ZM17.0643 10.4713V13.5287H20.1217V10.4713H17.0643ZM2.79639 9.96172C2.79639 9.68029 3.02453 9.45215 3.30595 9.45215H7.38249C7.66392 9.45215 7.89206 9.68029 7.89206 9.96172V14.0383C7.89206 14.3197 7.66392 14.5478 7.38249 14.5478H3.30595C3.02453 14.5478 2.79639 14.3197 2.79639 14.0383V9.96172ZM9.42065 9.96172C9.42065 9.68029 9.6488 9.45215 9.93022 9.45215H14.0068C14.2882 9.45215 14.5163 9.68029 14.5163 9.96172V14.0383C14.5163 14.3197 14.2882 14.5478 14.0068 14.5478H9.93022C9.6488 14.5478 9.42065 14.3197 9.42065 14.0383V9.96172ZM16.5547 9.45215C16.2733 9.45215 16.0452 9.68029 16.0452 9.96172V14.0383C16.0452 14.3197 16.2733 14.5478 16.5547 14.5478H20.6313C20.9127 14.5478 21.1408 14.3197 21.1408 14.0383V9.96172C21.1408 9.68029 20.9127 9.45215 20.6313 9.45215H16.5547Z"
        fill="#080808"
        fillRule="evenodd"
      />
    </svg>
  );
}
