import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useLatestValueRef } from '@front/helper';
import { useBaseRightPanel } from '@front/ui';
import { clearRouteParams } from '@lib/web/utils';
import { ParsedUrlQuery } from 'querystring';

export const AUTO_OPEN_PANEL_QUERY_KEY = 'panel';

export function useRouteChangeOpenRightPanel(props: {
  whenUrlPanelKey: string;
  panelParamsGetter?: (query: ParsedUrlQuery) => any;
  clearParams?: string[];
}) {
  const router = useRouter();
  const propsRef = useLatestValueRef(props);
  const { openRightPanel, closeRightPanel, rightPanelOpened } =
    useBaseRightPanel();

  useEffect(() => {
    const maybeOpenRightPanel = async () => {
      const {
        whenUrlPanelKey,
        panelParamsGetter,
        clearParams = [],
      } = propsRef.current;

      const { query } = router;
      const urlPanelKey = query[AUTO_OPEN_PANEL_QUERY_KEY];

      if (!whenUrlPanelKey || urlPanelKey !== whenUrlPanelKey) return;

      if (rightPanelOpened) closeRightPanel();

      await clearRouteParams([AUTO_OPEN_PANEL_QUERY_KEY, ...clearParams]);

      openRightPanel(whenUrlPanelKey, panelParamsGetter?.(query));
    };

    void maybeOpenRightPanel();
  }, [closeRightPanel, openRightPanel, propsRef, rightPanelOpened, router]);
}
