import { MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { ActionChevronRightSmall as ActionChevronRightSmallIcon } from '@front/icon';
import { Icon, ResponsiveDropdown, TipButton } from '@front/ui';
import { useShareLink } from '@lib/web/hooks';

import SharedDropdown from '../../../SharedDropdown';
import { ToolbarButtonItem, ToolbarButtonResponsiveConfig } from '../../types';

const styles = {
  menuDropdown: {
    '& .MuiList-root': {
      p: 0,
    },
  },
  option: {
    display: 'grid',
    gridTemplateColumns: '1fr 20px',
    gap: 0.5,
    alignItems: 'center',
    width: '100%',
  },
  optionInner: {
    display: 'grid',
    gridTemplateColumns: '16px 1fr',
    gap: 1,
    alignItems: 'center',
    textAlign: 'left',
    fontSize: 14,
  },
  popper: {
    '& .popper-content': {
      mt: 1,
      minWidth: 186,
      width: 186,
    },
  },
};
export type ShareLink = {
  display: string;
  displayIcon: string;
  value: string;
  tooltip?: string;
  disabled?: boolean;
};

type ShareToolbarItemProps = ToolbarButtonItem & {
  config: ToolbarButtonResponsiveConfig;
  shareLinks?: ShareLink[];
};

function RenderOption({ display, displayIcon }: ShareLink) {
  return (
    <Box sx={styles.option}>
      <Box sx={styles.optionInner}>
        <Icon name={displayIcon} width={16} height={16} />
        {display}
      </Box>
      <ActionChevronRightSmallIcon width={16} height={16} />
    </Box>
  );
}

export default function ShareToolbarItem({
  config,
  shareVirtualRouter,
  shareLinks,
}: ShareToolbarItemProps) {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [subMenuRef, setSubMenuRef] = useState<Element>();
  const [open, setOpen] = useState(false);
  const [subMenuOpen, setSubOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const baseLink = useShareLink();
  const handleClose = () => {
    setOpen(false);
    if (mdUp) setSubOpen(false);
  };
  const handleOpen = () => {
    if (!shareLinks || shareLinks.length === 0) {
      setShareLink('');
      setOpen(true);
    } else if (shareLinks.length === 1) {
      setShareLink(shareLinks[0].value);
      setOpen(true);
    } else {
      setSubOpen(true);
    }
  };

  const handleSubMenuChange = (
    option: ShareLink,
    ev?: MouseEvent<HTMLButtonElement>
  ) => {
    setShareLink(option.value);
    setOpen(true);
    if (ev)
      if (option.value === shareLinks?.[0].value) {
        setSubMenuRef(
          ev.currentTarget.closest('.popper-content') || ev.currentTarget
        );
      } else {
        setSubMenuRef(ev.currentTarget);
      }
  };

  const handleSubMenuClose = () => {
    if (!open) setSubOpen(false);
  };

  return (
    <>
      <SharedDropdown
        menuAnchorEl={subMenuRef || buttonRef.current}
        open={open}
        onClose={handleClose}
        virtualRouter={shareVirtualRouter}
        shareLink={shareLink || baseLink}
        menuProps={
          shareLinks && shareLinks.length > 1
            ? {
                popperOptions: {
                  placement: 'left-start',
                },
              }
            : undefined
        }
      />
      {!!shareLinks && shareLinks.length > 1 && (
        <ResponsiveDropdown<ShareLink>
          open={subMenuOpen}
          onClick={handleSubMenuChange}
          renderOption={RenderOption}
          options={shareLinks}
          onClose={handleSubMenuClose}
          isOptionDisabled={(option) => option.disabled || false}
          optionTooltip={(option) => option.tooltip || ''}
          menuDropdownProps={{
            anchorEl: buttonRef.current,
            popperProps: {
              sx: styles.popper,
            },
          }}
        />
      )}

      <TipButton
        ref={buttonRef}
        title={open || subMenuOpen ? undefined : t(config.titleKey)}
        customSize={24}
        selected={open || subMenuOpen}
        onClick={handleOpen}
      >
        {mdUp ? config.icon.desktop : config.icon.mobile}
      </TipButton>
    </>
  );
}
