import type { AxiosPromise, AxiosRequestConfig } from 'axios';

import api from './client';

const qbankApi = {
  getQuestionByShortId: (
    questionShortId: string,
    config: AxiosRequestConfig = {}
  ): AxiosPromise<Response<GetQBankQuestionByShortIdRes>> =>
    api.get(`v2/qbank/quiz-question/by-shortId/${questionShortId}`, {
      _withPublicAccessKey: true,
      ...config,
    }),
  getAllClubs: (
    requestParams: GetAllClubsReq,
    config: AxiosRequestConfig = {}
  ): AxiosPromise<PageResponse<GetClubRes>> => {
    const params = new URLSearchParams();
    if (requestParams.isProvideQbankQuizShortId)
      params.set('isProvideQbankQuizShortId', 'true');

    return api.get(`v2/club?page=1&limit=100&${params.toString()}`, {
      _withPublicAccessKey: true,
      ...config,
    });
  },
  submitResult: (params: PostQBankQuizResultReq) =>
    api.post<PageResponse<GetQBankQuizResultRes>>(
      'v2/qbank/quiz-question/result',
      params,
      { _withPublicAccessKey: true }
    ),
  addQuestionComment: (params: QBankQuestionCommentReq) =>
    api.post<GetQBankQuestionCommentRes>(
      '/v2/qbank/quiz-question/comment',
      params
    ),
  unPublishQuestion: (params: QBankQuestionCommentReq) =>
    api.post('/v2/qbank/quiz-question/unpublish', params),
  updateQuestionVariable: (params: QBankQuestionVariableReq) =>
    api.post('/v2/qbank/quiz-question/review-update-variable', params),
};

export default qbankApi;
