import {
  createContext,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { toast } from '@front/ui';
import combineSearch from '@lib/ia/src/utils/combineSearch';
import { apis, AsyncTaskStatus } from '@lib/web/apis';
import { uniq } from 'lodash';

import { call } from '../utils';

const POLLING_INTERVAL_MS = 2000;

const COMPLETED_STATUS = [
  AsyncTaskStatus.Finished,
  AsyncTaskStatus.Failed,
  AsyncTaskStatus.Cancelled,
];

const ERROR_STATUS = [AsyncTaskStatus.Failed, AsyncTaskStatus.Cancelled];

export type WatchingConfig = {
  taskId: string;
  callbackKey: string;
  watchStatus?: AsyncTaskStatus[];
  onStatusChange?: (status: AsyncTaskStatus) => void;
};

type AsyncTaskContextValue = {
  watchingConfigsRef: MutableRefObject<WatchingConfig[]>;
  watchingTaskStatusRef: MutableRefObject<Record<string, AsyncTaskStatus>>;
};

const initialContextValue: AsyncTaskContextValue = {
  watchingConfigsRef: {
    current: [],
  },
  watchingTaskStatusRef: {
    current: {},
  },
};

export const AsyncTaskContext =
  createContext<AsyncTaskContextValue>(initialContextValue);

export const AsyncTaskContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const watchingConfigsRef = useRef<WatchingConfig[]>([]);
  const watchingTaskStatusRef = useRef<Record<string, AsyncTaskStatus>>({});

  useEffect(() => {
    const pollingTimer = setInterval(async () => {
      // If there are no tasks to watch, exit early
      if (watchingConfigsRef.current.length === 0) {
        return;
      }

      // Get unique task IDs from watching configs
      const taskIds = uniq(
        watchingConfigsRef.current.map((config) => config.taskId)
      );

      // Fetch async task statuses from API
      const [res] = await call(
        apis.ia.asyncTasks({
          ...combineSearch([`id:${taskIds.join(',')}`, 'id:in']),
          limit: 0,
        })
      );

      if (!res) return;

      const statusChangesTasks = [];

      // Check for status changes and update watchingTaskStatusRef
      for (const task of res.data.items) {
        const prevStatus = watchingTaskStatusRef.current[task.id];

        if (prevStatus !== task.status) {
          watchingTaskStatusRef.current[task.id] = task.status;
          statusChangesTasks.push(task);
        }
      }

      const executedCallbackKeys = new Set<string>();

      // Process tasks with status changes
      for (const task of statusChangesTasks) {
        const watchingConfig = watchingConfigsRef.current.find(
          (config) => config.taskId === task.id
        );

        if (!watchingConfig) continue;

        const isWatchingAllStatus = !watchingConfig.watchStatus;
        const isWatchingStatus = watchingConfig.watchStatus?.includes(
          task.status
        );

        // Execute onStatusChange callback if not already executed for this key
        if (
          (isWatchingAllStatus || isWatchingStatus) &&
          !executedCallbackKeys.has(watchingConfig.callbackKey)
        ) {
          watchingConfig.onStatusChange?.(task.status);
          executedCallbackKeys.add(watchingConfig.callbackKey);
        }

        // Remove completed tasks from watchingConfigsRef
        if (COMPLETED_STATUS.includes(task.status)) {
          watchingConfigsRef.current = watchingConfigsRef.current.filter(
            (config) => config.taskId !== task.id
          );
        }

        if (ERROR_STATUS.includes(task.status)) {
          toast.error(
            `Failed to process the async task: ${task.id} - ${task.status}`,
            task
          );
        }
      }
    }, POLLING_INTERVAL_MS);

    // Clean up interval on component unmount
    return () => {
      clearInterval(pollingTimer);
    };
  }, []);

  return (
    <AsyncTaskContext.Provider
      value={{ watchingConfigsRef, watchingTaskStatusRef }}
    >
      {children}
    </AsyncTaskContext.Provider>
  );
};
