import { isNumberic } from './common';

export function currencyFormat(value: string | number) {
  if (!isNumberic(value)) {
    return value;
  }

  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function numberFormat(value: number, offset?: number): number;
export function numberFormat(value: string, offset?: number): string;
export function numberFormat(
  value: number | string,
  offset = 10
): number | string {
  if (!isNumberic(value)) {
    return value;
  }

  // eslint-disable-next-line no-bitwise
  return ~~(+value * offset) / offset;
}
