import { useEffect, useRef } from 'react';
import { Box, Popover, PopoverProps, TextareaAutosize } from '@mui/material';

import PopupWrap from '../components/PopupWrap';

const styles = {
  textarea: {
    border: 'none',
    typography: 'body2',
    resize: 'none',
    width: '100%',
    height: '100%',
    p: 0,
    bgcolor: 'transparent',
    color: 'text.primary',
  },
};

type BlockEditableContentPopupProps = {
  show: boolean;
  anchorEl: PopoverProps['anchorEl'];
  defaultValue: string;
  placeholder?: string;
  onClose: PopoverProps['onClose'];
  onDone: (newValue: string) => void;
};
export default function BlockEditableContentPopup({
  show,
  anchorEl,
  onClose,
  defaultValue,
  placeholder,
  onDone,
}: BlockEditableContentPopupProps) {
  const editBoxRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        if (editBoxRef.current) {
          editBoxRef.current.focus();
          editBoxRef.current.selectionStart = defaultValue.length;
          editBoxRef.current.selectionEnd = defaultValue.length;
        }
      });
    }
  }, [show, defaultValue]);

  if (!show) return null;

  const handleDone = () => {
    onDone(editBoxRef.current?.value || '');
  };

  return (
    <Popover
      open
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
    >
      <PopupWrap maxContentHeight={351} onDone={handleDone}>
        <Box
          ref={editBoxRef}
          sx={styles.textarea}
          component={TextareaAutosize}
          defaultValue={defaultValue}
          placeholder={placeholder}
        />
      </PopupWrap>
    </Popover>
  );
}
