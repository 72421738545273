import { Box, Chip } from '@mui/material';
import { Icon } from '@front/ui';

import { AlbumLayoutChipExtensionConfig } from './types';

const styles = {
  root: {
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
  },
  chip: {
    height: 27,
  },
};

type AlbumLayoutChipExtensionProps = {
  config: AlbumLayoutChipExtensionConfig['config'];
};
export default function AlbumLayoutChipExtension({
  config,
}: AlbumLayoutChipExtensionProps) {
  const { label, icon, variant = 'filled' } = config;
  return (
    <Box sx={styles.root}>
      <Chip
        label={label}
        icon={icon ? <Icon name={icon} width={16} height={16} /> : undefined}
        variant={variant}
        sx={styles.chip}
      />
    </Box>
  );
}
