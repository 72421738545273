import { useCallback } from 'react';
import { Editor, Range } from '@tiptap/react';

import { VariableOption } from './type';

export default function useVariableItems(options: VariableOption[]) {
  return useCallback(
    ({ query }: { query: string }) => {
      return options
        .filter((option) =>
          option.name.toLowerCase().startsWith(query.toLowerCase())
        )
        .slice(0, 10)
        .map((option) => ({
          title: option.name,
          command: ({ editor, range }: { editor: Editor; range: Range }) => {
            const nodeAfter = editor.view.state.selection.$to.nodeAfter;
            const overrideSpace = nodeAfter?.text?.startsWith(' ');

            if (overrideSpace) {
              range.to += 1;
            }

            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'variable-component',
                  attrs: {
                    name: option.name,
                    type: option.type,
                    subType: option.subType,
                  },
                },
                {
                  type: 'text',
                  text: ' ',
                },
              ])
              .run();

            window.getSelection()?.collapseToEnd();
          },
        }));
    },
    [options]
  );
}
