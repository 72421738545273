import { MouseEvent, ReactNode, useRef, useState } from 'react';

import DropdownField, { DropdownFieldProps } from '../DropdownField';
import ResponsiveDropdown, {
  ResponsiveDropdownProps,
} from '../ResponsiveDropdown';

type BaseOption<T> = {
  display: ReactNode;
  options?: T[];
};

export type DropdownTextFieldProps<T extends BaseOption<T>> = Omit<
  DropdownFieldProps,
  'ref' | 'onClick' | 'active' | 'onChange'
> & {
  // dropdown
  options: ResponsiveDropdownProps<T>['options'];
  menuDropdownProps?: ResponsiveDropdownProps<T>['menuDropdownProps'];
  bottomDropdownProps?: ResponsiveDropdownProps<T>['bottomDropdownProps'];
  onClick: ResponsiveDropdownProps<T>['onClick'];
  isOptionDisabled?: ResponsiveDropdownProps<T>['isOptionDisabled'];
  isOptionEqualToValue?: ResponsiveDropdownProps<T>['isOptionEqualToValue'];
  renderOption?: ResponsiveDropdownProps<T>['renderOption'];
};

export default function DropdownTextField<T extends BaseOption<T>>({
  label,
  options,
  onClick,
  renderOption,
  isOptionDisabled,
  isOptionEqualToValue,
  menuDropdownProps = {},
  bottomDropdownProps = {},
  ...rest
}: DropdownTextFieldProps<T>) {
  const [open, setOpen] = useState(false);

  const fieldRef = useRef(null);

  const handleToggle = () => {
    setOpen((st) => !st);
  };

  const handleClick = (item: T, ev: MouseEvent<HTMLButtonElement>) => {
    onClick?.(item, ev);
    setOpen(false);
  };

  return (
    <>
      <DropdownField
        ref={fieldRef}
        label={label}
        active={open}
        onClick={handleToggle}
        {...rest}
      />

      <ResponsiveDropdown<T>
        open={open}
        onClick={handleClick}
        onClose={handleToggle}
        options={options}
        renderOption={renderOption}
        isOptionDisabled={isOptionDisabled}
        isOptionEqualToValue={isOptionEqualToValue}
        menuDropdownProps={{
          anchorEl: fieldRef.current,
          ...menuDropdownProps,
        }}
        bottomDropdownProps={{
          label: typeof label === 'string' ? label : undefined,
          ...bottomDropdownProps,
        }}
      />
    </>
  );
}
