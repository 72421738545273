import { useContext, useMemo } from 'react';

import LineNumberWrapContext, {
  LineNumberWrapValue,
} from './LineNumberWrapContext';

export function useLineNumbers() {
  const [value, setValue] = useContext(LineNumberWrapContext);

  const memoizedFunction = useMemo(() => {
    const setLineNumbers = (lineNumbers: LineNumberWrapValue) => {
      setValue(lineNumbers);
    };
    const getLineNumberFromTop = (top: number) => {
      return value.find(
        (lineNumber) =>
          lineNumber.top <= top && top < lineNumber.top + lineNumber.lineHeight
      );
    };

    return {
      setLineNumbers,
      getLineNumberFromTop,
    };
  }, [value, setValue]);

  return memoizedFunction;
}
