import React from 'react';
import BlockTag from '@lib/ia/src/components/BlockTag';
import Icon from '@lib/ia/src/components/Icon';
import ThemeProvider from '@lib/ia/src/components/ThemeProvider';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { Editor, NodeViewWrapper } from '@tiptap/react';

import { VariableType } from './type';

type VariableComponentProps = {
  editor: Editor;
  node: ProseMirrorNode;
  getPos: (() => number) | boolean;
  updateAttributes: (attributes: Record<string, any>) => void;
  deleteNode: () => void;
};

const VARIABLE_TYPE_MAPPING = {
  [VariableType.Text]: 'EditorTextVariable',
  [VariableType.Number]: 'EditorNumberVariable',
  [VariableType.NumberFormula]: 'TestFormula',
};

export default function VariableComponent({ node }: VariableComponentProps) {
  const icon =
    VARIABLE_TYPE_MAPPING[
      (node.attrs.type as VariableType) || VariableType.NumberFormula
    ];
  return (
    <NodeViewWrapper
      style={{ display: 'inline-block' }}
      className="variable-component"
      contentEditable={false}
    >
      <ThemeProvider mode="light">
        <BlockTag icon={<Icon name={icon} />} disabled>
          {node.attrs.name || 'Variable Formula'}
        </BlockTag>
      </ThemeProvider>
    </NodeViewWrapper>
  );
}
