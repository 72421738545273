import { Box } from '@mui/material';
import { SquareAvatar } from '@front/ui';
import { useIaMemberNftAvatar } from '@lib/ia/src/hooks/useMemberNftAvatar';

import { AlbumLayoutAvatarItem } from '../../types';

import IaAlbumBaseItem from './IaAlbumBaseItem';

const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
};

type IaAlbumAvatarItemProps = AlbumLayoutAvatarItem;

export default function IaAlbumAvatarItem(item: IaAlbumAvatarItemProps) {
  const { avatarUrl, name, userId, ...rest } = item;
  const { avatar: displayAvatar, name: displayName } = useIaMemberNftAvatar({
    userId,
    avatar: avatarUrl,
    name,
  });

  return (
    <IaAlbumBaseItem {...rest} item={item}>
      <Box sx={styles.root}>
        <SquareAvatar src={displayAvatar} size={32}>
          {displayName}
        </SquareAvatar>
      </Box>
    </IaAlbumBaseItem>
  );
}
