import React from 'react';

export default function PropertyTypeFilesMedia({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.6061 18.5202L19.0315 10.9758L20 11.9598L12.5747 19.5042C10.6118 21.4986 7.43503 21.4986 5.47215 19.5042C3.50928 17.5099 3.50928 14.2822 5.47215 12.2878L13.5432 4.08738C14.9701 2.63754 17.2817 2.63754 18.7086 4.08738C20.1356 5.53722 20.1356 7.88583 18.7086 9.33567L11.929 16.2241C11.0379 17.1294 9.59161 17.1294 8.70056 16.2241C7.80952 15.3187 7.80952 13.8492 8.70056 12.9439L14.8345 6.71153L15.8031 7.69558L9.66909 13.9279C9.31396 14.2887 9.31396 14.8792 9.66909 15.24C10.0242 15.6008 10.6053 15.6008 10.9605 15.24L17.7401 8.35162C18.6312 7.44629 18.6312 5.97677 17.7401 5.07144C16.8491 4.1661 15.4027 4.1661 14.5117 5.07144L6.44068 13.2719C5.01372 14.7217 5.01372 17.0703 6.44068 18.5202C7.86764 19.97 10.1792 19.97 11.6061 18.5202Z"
        fill="currentColor"
      />
    </svg>
  );
}
