import { useEffect } from 'react';

import useQueueWorkerInProgress from './useQueueWorkerInProgress';

export default function useQueueWorkerInterrupt() {
  const { getIsAnyTaskInProgress } = useQueueWorkerInProgress();

  useEffect(() => {
    const routeChange = (ev: BeforeUnloadEvent) => {
      if (getIsAnyTaskInProgress()) {
        ev.preventDefault();

        return (ev.returnValue =
          'You have unsaved changes - are you sure you wish to close?');
      }
      return '';
    };

    window.addEventListener('beforeunload', routeChange);

    return () => {
      window.removeEventListener('beforeunload', routeChange);
    };
    // ignore it because eslint does not recognize queues is a ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
