import React from 'react';

export default function EditorAddBot({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.3182 14V16.5909H20.9091V18.3182H18.3182V20.9091H16.5909V18.3182H14V16.5909H16.5909V14H18.3182Z"
        fill="currentColor"
      />
      <path
        d="M18.2261 9.04C17.9261 9.04 17.6861 8.83 17.6461 8.53C17.4161 6.91 17.0961 6.58 15.4661 6.34C15.1761 6.3 14.9561 6.05 14.9561 5.75C14.9561 5.45 15.1661 5.21 15.4661 5.17C17.0761 4.94 17.4061 4.62 17.6361 3.01C17.6761 2.72 17.9261 2.5 18.2261 2.5C18.5261 2.5 18.7761 2.71 18.8161 3.01C19.0461 4.61 19.3761 4.93 20.9961 5.17C21.2861 5.21 21.5061 5.46 21.5061 5.76C21.5061 6.06 21.2961 6.3 20.9961 6.34C19.3761 6.57 19.0561 6.9 18.8161 8.53C18.7761 8.82 18.5261 9.04 18.2361 9.04H18.2261ZM17.1261 5.76C17.6361 6 17.9861 6.35 18.2261 6.86C18.4661 6.35 18.8161 6 19.3261 5.76C18.8161 5.52 18.4661 5.17 18.2261 4.67C17.9861 5.18 17.6361 5.53 17.1261 5.77V5.76Z"
        fill="currentColor"
      />
      <path
        d="M8.53611 9.04C8.23611 9.04 7.99611 8.83 7.95611 8.53C7.72611 6.91 7.40611 6.58 5.77611 6.34C5.48611 6.3 5.26611 6.05 5.26611 5.75C5.26611 5.45 5.47611 5.21 5.77611 5.17C7.38611 4.94 7.71611 4.62 7.94611 3.01C7.98611 2.72 8.23611 2.5 8.53611 2.5C8.83611 2.5 9.08611 2.71 9.12611 3.01C9.35611 4.61 9.68611 4.93 11.3061 5.17C11.5961 5.21 11.8161 5.46 11.8161 5.76C11.8161 6.06 11.6061 6.3 11.3061 6.34C9.68611 6.57 9.36611 6.9 9.12611 8.53C9.08611 8.82 8.83611 9.04 8.54611 9.04H8.53611ZM7.43611 5.76C7.94611 6 8.29611 6.35 8.53611 6.86C8.77611 6.35 9.12611 6 9.63611 5.76C9.12611 5.52 8.77611 5.17 8.53611 4.67C8.29611 5.18 7.94611 5.53 7.43611 5.77V5.76Z"
        fill="currentColor"
      />
      <path
        d="M4.64609 21.5C4.54609 21.5 4.45609 21.49 4.36609 21.46C3.55609 21.2 2.80609 20.44 2.53609 19.63C2.41609 19.26 2.55609 18.8 2.88609 18.46L12.9161 8.43004C13.2461 8.10004 13.7061 7.96004 14.0861 8.08004C14.4561 8.20004 14.8461 8.46004 15.1961 8.80004C15.5461 9.15004 15.7961 9.54004 15.9161 9.91004C16.0361 10.28 15.8961 10.74 15.5661 11.08L5.53609 21.11C5.28609 21.36 4.95609 21.5 4.65609 21.5H4.64609ZM3.59609 19.17C3.52609 19.25 3.49609 19.32 3.49609 19.34C3.65609 19.82 4.17609 20.34 4.67609 20.5C4.67609 20.5 4.75609 20.47 4.82609 20.39L14.8561 10.37C14.9361 10.29 14.9561 10.22 14.9561 10.2C14.8861 10.01 14.7161 9.74004 14.4861 9.51004C14.2561 9.28004 13.9961 9.11004 13.7761 9.03004C13.7761 9.03004 13.6961 9.06004 13.6261 9.14004L3.59609 19.17Z"
        fill="currentColor"
      />
      <path
        d="M13.5162 9.83008C13.6262 9.90008 13.7462 10.0001 13.8662 10.1201C13.9862 10.2401 14.0862 10.3601 14.1562 10.4701L12.2762 12.3501C12.1662 12.2801 12.0462 12.1801 11.9262 12.0601C11.8062 11.9401 11.7062 11.8201 11.6362 11.7101L13.5162 9.83008Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
