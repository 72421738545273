import { useMemberInfo } from '@lib/web/apis';
import { getIndicators } from '@lib/web/utils';

export const useMemberNftAvatar = ({
  userId,
  memberId,
}: {
  userId?: string | null;
  memberId?: string | null;
}) => {
  const { data: userData, mutate } = useMemberInfo(userId, memberId);
  const nftAvatar = userData?.data.nftAvatar || userData?.data.avatar;
  const nftDisplay = userData?.data.displayName || userData?.data.distinctName;

  return {
    isLoading: userData === undefined,
    nftAvatar,
    nftDisplay,
    data: userData,
    indicators: getIndicators(userData?.data.indicator),
    mutate,
  };
};
