import React from 'react';

export default function ChatReply({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.975 15.986L5.92426 16.5441C5.84434 17.4228 5.70594 18.2404 5.50476 18.967C6.19862 18.7537 6.94166 18.4703 7.58054 18.1037L8.21095 17.7419L8.88553 18.0125C9.86297 18.4046 10.9078 18.6053 12 18.6053C16.2954 18.6053 19.5 15.5119 19.5 12.0526C19.5 8.59332 16.2954 5.5 12 5.5C7.70459 5.5 4.50002 8.59332 4.50002 12.0526C4.50002 13.2792 4.88844 14.4778 5.64747 15.5314L5.975 15.986ZM4.79088 20.7135C4.68932 20.7376 4.59132 20.76 4.49747 20.7807C4.08932 20.871 3.7598 20.9301 3.55776 20.9635C3.5577 20.9635 3.55765 20.9635 3.55759 20.9635C3.43509 20.9838 3.3595 20.9945 3.34172 20.997C3.32762 20.9991 3.31382 21 3.30002 21C3.18752 21 3.08252 20.9371 3.03152 20.8348C2.97392 20.7202 2.99672 20.5816 3.08792 20.4909C3.14043 20.4387 3.19169 20.3821 3.24167 20.3213C3.24324 20.3193 3.2448 20.3174 3.24637 20.3155C3.26442 20.2934 3.28231 20.2707 3.30002 20.2475C3.44487 20.0574 3.57839 19.8302 3.69963 19.5687C3.72513 19.5137 3.75009 19.4572 3.77449 19.3993C4.09492 18.6378 4.32022 17.62 4.43042 16.4082C3.49382 15.1082 3.00002 13.6047 3.00002 12.0526C3.00002 7.61235 7.03742 4 12 4C16.9626 4 21 7.61235 21 12.0526C21 16.4929 16.9626 20.1053 12 20.1053C10.7211 20.1053 9.48632 19.8696 8.32712 19.4047C7.19009 20.0572 5.81083 20.4715 4.79088 20.7135ZM4.37396 20.1655L4.37159 20.1608C4.37238 20.1624 4.37317 20.1639 4.37396 20.1655Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
