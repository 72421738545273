import React from 'react';

export default function TestMinus({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21 14L3 14L3 11L21 11L21 14Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
