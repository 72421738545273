import { createContext, ReactNode } from 'react';

export type IaRenderUi = (payload: any) => ReactNode;

export type IaRenderContextValue = Record<string, IaRenderUi>;

export const iaRenderContextInitialValue: IaRenderContextValue = {};

const IaRenderContext = createContext<IaRenderContextValue>(
  iaRenderContextInitialValue
);

export default IaRenderContext;
