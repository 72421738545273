import { BlockNoteEditor, BlockSchema, PartialBlock } from '@blocknote/core';
import { Content } from '@tiptap/core';
import { v4 } from 'uuid';

/**
 * This is reference from blocknote's default slash menu items
 * extensions/SlashMenu/defaultSlashMenuItems.ts
 * the purpose is to make sure that new block will not overrided current block
 */
export const insertOrUpdateBlock = <BSchema extends BlockSchema>(
  editor: BlockNoteEditor<BSchema>,
  blocks: PartialBlock<BSchema>[]
) => {
  const currentBlock = editor.getTextCursorPosition().block;
  const content = currentBlock.content;

  // replace current block when user typing inside an empty inline block (such as paragraph)
  const shouldReplaceCurrentBlock =
    content && // if content is undefined, this is not a block which support inline content
    (content.length === 0 || // an empty inline block
      (content.length === 1 && // user opening slash menu
        content[0].type === 'text' &&
        content[0].text === '/'));

  if (shouldReplaceCurrentBlock) {
    editor.updateBlock(currentBlock, blocks[0]);
  }

  const toInsertBlocks = shouldReplaceCurrentBlock ? blocks.slice(1) : blocks;
  if (toInsertBlocks.length) {
    const lastInsertBlock = toInsertBlocks[toInsertBlocks.length - 1];

    if (!lastInsertBlock.id) {
      lastInsertBlock.id = v4(); // then we can locate the block to set the cursor position
    }

    editor.insertBlocks(toInsertBlocks, currentBlock, 'after');
    editor.setTextCursorPosition(lastInsertBlock.id);
  }
};

export const insertInlineBlock = <BSchema extends BlockSchema>(
  editor: BlockNoteEditor<BSchema>,
  blocks: Content
) => {
  const currentBlock = editor.getTextCursorPosition().block;
  // if currentBlock does not support inline, we create a new paragraph on the next line
  if (!currentBlock.content) {
    const paragraphBlock = {
      id: v4(),
      type: 'paragraph' as const,
    };

    editor.insertBlocks([paragraphBlock], currentBlock, 'after');
    editor.setTextCursorPosition(paragraphBlock.id, 'start');
  } else {
    // XXX: fix "it always appears at the end of the block when inserting inline block"
    // editor.setTextCursorPosition(currentBlock.id, 'end');
  }

  editor._tiptapEditor
    .chain()
    .focus()
    .insertContentAt(editor._tiptapEditor.state.selection.head, blocks)
    .run();
};
