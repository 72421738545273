import React from 'react';

export default function LoginSeeAlternative({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.06731 12C5.22528 14.8746 8.30337 17 12 17C15.6975 17 18.7748 14.8746 19.9327 12C18.7748 9.12536 15.6975 7 12 7C8.30337 7 5.22528 9.12541 4.06731 12ZM2.05484 11.6734C3.40806 7.75728 7.3899 5 12 5C16.6111 5 20.592 7.75733 21.9452 11.6734C22.0183 11.885 22.0183 12.115 21.9452 12.3266C20.592 16.2427 16.6111 19 12 19C7.3899 19 3.40806 16.2427 2.05484 12.3266C1.98172 12.115 1.98172 11.885 2.05484 11.6734ZM11.9998 10.4286C11.4853 10.4286 11.0089 10.6152 10.6715 10.9218C10.3373 11.2255 10.1702 11.6158 10.1702 12C10.1702 12.3842 10.3373 12.7745 10.6715 13.0782C11.0089 13.3848 11.4853 13.5714 11.9998 13.5714C12.5143 13.5714 12.9907 13.3848 13.3281 13.0782C13.6623 12.7745 13.8294 12.3842 13.8294 12C13.8294 11.6158 13.6623 11.2255 13.3281 10.9218C12.9907 10.6152 12.5143 10.4286 11.9998 10.4286ZM9.32645 9.44166C10.0504 8.7838 11.0134 8.42857 11.9998 8.42857C12.9863 8.42857 13.9493 8.7838 14.6732 9.44166C15.4003 10.1024 15.8294 11.0202 15.8294 12C15.8294 12.9798 15.4003 13.8976 14.6732 14.5583C13.9493 15.2162 12.9863 15.5714 11.9998 15.5714C11.0134 15.5714 10.0504 15.2162 9.32645 14.5583C8.59936 13.8976 8.17022 12.9798 8.17022 12C8.17022 11.0202 8.59936 10.1024 9.32645 9.44166Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
