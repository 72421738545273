import { useContext } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { GuestContext } from '@front/helper';

export function useToken() {
  const { user } = useUser();
  const { guest } = useContext(GuestContext);
  return (user?.accessToken || guest.accessToken) as string;
}

export default useToken;
