import React from 'react';

export default function TextEditorBold({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.8 11.79C16.7926 11.12 17.4884 10.02 17.4884 9C17.4884 6.74 15.6977 5 13.3953 5H7V19H14.2037C16.3423 19 18 17.3 18 15.21C18 13.69 17.12 12.39 15.8 11.79ZM10.0698 7.5H13.1395C13.9888 7.5 14.6744 8.17 14.6744 9C14.6744 9.83 13.9888 10.5 13.1395 10.5H10.0698V7.5ZM13.6512 16.5H10.0698V13.5H13.6512C14.5005 13.5 15.186 14.17 15.186 15C15.186 15.83 14.5005 16.5 13.6512 16.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
