import React from 'react';

export default function AgentCommand({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.12868 3.87868C3.69129 3.31607 4.45435 3 5.25 3H18.75C19.5457 3 20.3087 3.31607 20.8713 3.87868C21.4339 4.44129 21.75 5.20435 21.75 6V18C21.75 18.7956 21.4339 19.5587 20.8713 20.1213C20.3087 20.6839 19.5457 21 18.75 21H5.25C4.45435 21 3.69129 20.6839 3.12868 20.1213C2.56607 19.5587 2.25 18.7956 2.25 18V6C2.25 5.20435 2.56607 4.44129 3.12868 3.87868ZM5.25 4.5C4.85218 4.5 4.47064 4.65804 4.18934 4.93934C3.90804 5.22064 3.75 5.60218 3.75 6V18C3.75 18.3978 3.90804 18.7794 4.18934 19.0607C4.47064 19.342 4.85218 19.5 5.25 19.5H18.75C19.1478 19.5 19.5294 19.342 19.8107 19.0607C20.092 18.7794 20.25 18.3978 20.25 18V6C20.25 5.60218 20.092 5.22064 19.8107 4.93934C19.5294 4.65804 19.1478 4.5 18.75 4.5H5.25ZM6.15 7.05C6.39853 6.71863 6.86863 6.65147 7.2 6.9L10.2 9.15C10.3889 9.29164 10.5 9.51393 10.5 9.75C10.5 9.98607 10.3889 10.2084 10.2 10.35L7.2 12.6C6.86863 12.8485 6.39853 12.7814 6.15 12.45C5.90147 12.1186 5.96863 11.6485 6.3 11.4L8.5 9.75L6.3 8.1C5.96863 7.85147 5.90147 7.38137 6.15 7.05ZM10.5 12C10.5 11.5858 10.8358 11.25 11.25 11.25H14.25C14.6642 11.25 15 11.5858 15 12C15 12.4142 14.6642 12.75 14.25 12.75H11.25C10.8358 12.75 10.5 12.4142 10.5 12Z"
        fill="currentColor"
      />
    </svg>
  );
}
