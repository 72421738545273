import React from 'react';

export default function ProfilePiggyBank({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.98017 7.16894C3.49528 8.25704 4.28155 8.91551 5.25842 8.91542C5.51551 8.91539 5.7239 8.6953 5.72388 8.42382C5.72385 8.15234 5.51543 7.93228 5.25834 7.9323C4.79832 7.93235 4.3309 7.6684 3.93869 6.97301C4.05608 6.98289 4.17272 6.97986 4.28709 6.96453C4.66913 6.91334 5.03907 6.71513 5.24726 6.38059C5.35464 6.20805 5.41856 5.99667 5.40174 5.76637C5.38489 5.53569 5.29003 5.32595 5.14422 5.15041C4.87446 4.82563 4.52505 4.67278 4.16039 4.71015C3.81765 4.74527 3.52404 4.94134 3.31132 5.17911C3.25939 5.23717 3.20986 5.30063 3.16363 5.36867C3.07005 5.21843 2.98113 5.0345 2.90147 4.8102C2.81118 4.55602 2.54286 4.42724 2.30215 4.52258C2.06144 4.61792 1.93949 4.90127 2.02978 5.15546C2.23223 5.72546 2.50904 6.15022 2.82179 6.44568C2.82028 6.68545 2.86776 6.9315 2.98017 7.16894ZM3.89878 5.97242C3.92519 5.93084 3.95501 5.8912 3.98807 5.85425C4.09257 5.73744 4.19047 5.6948 4.25031 5.68867C4.28824 5.68478 4.35251 5.68815 4.44431 5.79867C4.45942 5.81687 4.46711 5.82999 4.47089 5.83792L4.46923 5.84068C4.445 5.87962 4.35128 5.96493 4.1699 5.98924C4.09438 5.99935 4.00151 5.99737 3.89878 5.97242ZM4.47428 5.84694L4.47388 5.84525L4.47428 5.84694Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M19.4274 14.0224C19.0192 14.875 18.4383 15.6277 17.624 16.207L16.9412 18.6207C16.8775 18.8458 16.6815 19 16.459 19H15.1018C14.8464 19 14.6316 18.7981 14.6019 18.5303L14.4707 17.3449C14.0444 17.3977 13.5882 17.4253 13.1001 17.4254C12.0072 17.4256 11.0614 17.4086 10.2352 17.3417L10.1037 18.5303C10.074 18.7981 9.85918 19 9.60385 19H8.24659C8.02413 19 7.82807 18.8458 7.7644 18.6207L7.1837 16.5678C6.00653 15.9037 5.22228 14.7369 4.52054 12.6985C3.02843 8.36441 6.38568 4.45579 11.6081 4.03153C13.3363 3.89113 14.9019 4.22864 16.211 4.865C17.3562 3.84036 18.9557 3.77941 20.2289 4.93525C19.5444 5.67435 19.025 6.2647 18.6583 6.69642C19.438 7.56713 19.9607 8.56897 20.1608 9.57662L21.6703 10.1626C21.9087 10.2551 22.0444 10.5207 21.9868 10.7819L21.7004 12.0809C21.4722 13.116 20.6484 13.8852 19.6475 13.9977L19.4274 14.0224ZM11.3221 4.99006C12.0204 4.93332 12.7184 4.98941 13.2638 5.10456C13.5158 5.1578 13.6793 5.41675 13.6289 5.68296C13.5785 5.94917 13.3333 6.12183 13.0812 6.06859C12.6184 5.97086 12.0058 5.92052 11.3935 5.97027C10.7756 6.02047 10.2006 6.16916 9.78256 6.43401C9.56211 6.57369 9.27617 6.49821 9.1439 6.26542C9.01163 6.03263 9.0831 5.73069 9.30355 5.59101C9.89372 5.21706 10.6293 5.04634 11.3221 4.99006ZM17.3619 9.89866C17.8761 9.89866 18.2929 9.4585 18.2929 8.91554C18.2929 8.37258 17.8761 7.93243 17.3619 7.93243C16.8477 7.93243 16.4309 8.37258 16.4309 8.91554C16.4309 9.4585 16.8477 9.89866 17.3619 9.89866Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
