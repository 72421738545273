import React from 'react';

export default function ActionOpenInRightPane({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 7C14.3333 7 15.6667 7 18 7C18.5 7 19 7.5 19 8V16C19 16.5 18.5 17 18 17C15.6667 17 14.3333 17 12 17V7Z"
        fill="currentColor"
      />
      <path
        d="M4 4C2.75 4 2 4.75 2 6V18C2 19.25 2.75 20 4 20H20C21.25 20 22 19.25 22 18V6C22 4.75 21.25 4 20.25 4H4ZM4 5C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5H4Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
