import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { DisplayTableLayoutTextFooterCell } from '../../types';

export type IaDisplayTableTextFooterCellProps =
  DisplayTableLayoutTextFooterCell;

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    overflow: 'hidden',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
export default function IaDisplayTableTextFooterCell({
  text,
}: IaDisplayTableTextFooterCellProps) {
  return (
    <Box sx={styles.root}>
      <Typography variant="body2" sx={[styles.text]}>
        {text}
      </Typography>
    </Box>
  );
}
