import { IaAvailableAction } from '@lib/ia/src/core/IaAction';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';

import useSuggestionMembersLayoutAction from './useSuggestionMembersLayoutAction';
import useSuggestionMembersLayoutConfig from './useSuggestionMembersLayoutConfig';

export default function useSuggestionMembersLayout({
  onClose,
}: {
  onClose: () => void;
}): [IaLayoutConfig[], Record<string, IaAvailableAction>] {
  const layouts = useSuggestionMembersLayoutConfig();
  const availableActions = useSuggestionMembersLayoutAction({ onClose });

  return [layouts, availableActions];
}
