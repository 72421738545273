import { PropsWithChildren } from 'react';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { BoxProps } from '@mui/system';

const styles = {
  root: {
    border: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    borderRadius: 2,
    width: '100%',
    p: 0,
    m: 0,
  },
  title: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    px: 1,
    py: '2px',
    lineHeight: '20px',
  },
};

function Title({ children }: PropsWithChildren) {
  return (
    <Typography variant="caption" sx={styles.title} component="legend">
      {children}
    </Typography>
  );
}

type CardBorderProps = BoxProps;

export default function CardBorder({ sx, ...rest }: CardBorderProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return <Box component="fieldset" sx={[styles.root, ...sxProps]} {...rest} />;
}

CardBorder.Title = Title;
