import React from 'react';

export default function OtherDiscountNoOuter({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.6653 5.95098C20.1116 5.50467 20.1116 4.78105 19.6653 4.33474C19.219 3.88842 18.4953 3.88842 18.049 4.33474L4.33474 18.049C3.88842 18.4953 3.88842 19.219 4.33474 19.6653C4.78105 20.1116 5.50467 20.1116 5.95098 19.6653L19.6653 5.95098Z"
        fill="currentColor"
      />
      <path
        d="M7.42857 4C5.53502 4 4 5.53502 4 7.42857V7.44682C4 9.34037 5.53502 10.8754 7.42857 10.8754H7.44682C9.34037 10.8754 10.8754 9.34037 10.8754 7.44682V7.42857C10.8754 5.53502 9.34037 4 7.44682 4H7.42857ZM7.42857 8.57143C8.05975 8.57143 8.57143 8.05975 8.57143 7.42857C8.57143 6.79739 8.05975 6.28571 7.42857 6.28571C6.79739 6.28571 6.28571 6.79739 6.28571 7.42857C6.28571 8.05975 6.79739 8.57143 7.42857 8.57143Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M20 16.5532C20 14.6596 18.465 13.1246 16.5714 13.1246L16.5532 13.1246C14.6596 13.1246 13.1246 14.6596 13.1246 16.5532L13.1246 16.5714C13.1246 18.465 14.6596 20 16.5532 20H16.5714C18.465 20 20 18.465 20 16.5714V16.5532ZM17.7143 16.5714C17.7143 17.2026 17.2026 17.7143 16.5714 17.7143C15.9402 17.7143 15.4286 17.2026 15.4286 16.5714C15.4286 15.9402 15.9402 15.4286 16.5714 15.4286C17.2026 15.4286 17.7143 15.9402 17.7143 16.5714Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
