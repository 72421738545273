import React, { forwardRef, ReactNode } from 'react';
import { TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';

import { Subtitle, Title } from '../index';

import useResponsivePageTitle from './useResponsivePageTitle';

export type ResponsivePageTitleProps = TypographyProps & {
  icon?: ReactNode;
  titleText?: ReactNode;
  subtitleText?: ReactNode;
  children?: ReactNode;
};

const ResponsivePageTitle = forwardRef<HTMLElement, ResponsivePageTitleProps>(
  ({ icon, titleText, subtitleText, children, ...rest }, ref) => {
    const { isPageTitleShow } = useResponsivePageTitle();

    if (!titleText && !children) {
      return null;
    }

    if (!isPageTitleShow) {
      return null;
    }

    return (
      <Box>
        <Title ref={ref} {...rest}>
          <Box display="flex">{icon}</Box>
          {titleText}
          {children}
        </Title>
        {subtitleText && <Subtitle mt={1}>{subtitleText}</Subtitle>}
      </Box>
    );
  }
);

ResponsivePageTitle.displayName = 'ResponsivePageTitle';
export default ResponsivePageTitle;
