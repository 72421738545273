import React from 'react';

export default function EmojiRare({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.5317 10.8838L13.124 3.46834C12.8274 3.17173 12.4293 3 12 3C11.5785 3 11.1804 3.16392 10.876 3.46054L3.46834 10.8682C3.17173 11.1648 3 11.5629 3 11.9922C3 12.4215 3.16392 12.8118 3.46054 13.1162L10.8682 20.5317C11.1804 20.8439 11.5863 21 11.9922 21C12.3981 21 12.804 20.8439 13.1162 20.5395L20.5317 13.1318C20.8283 12.8352 21 12.4371 21 12.0078C21 11.5785 20.8283 11.1804 20.5317 10.8838ZM19.0486 11.3834H16.9801C16.9488 11.3365 16.9098 11.2897 16.863 11.2428L12.7572 7.12923C12.7181 7.0902 12.6713 7.05117 12.6245 7.01995V4.95143L19.0486 11.3834ZM12.0234 15.8248C12.0156 15.8248 12.0078 15.8248 11.9922 15.8248C11.9844 15.8248 11.9688 15.8248 11.961 15.8248L8.1752 12.039C8.1752 12.0312 8.1752 12.0234 8.1752 12.0078C8.1752 11.9922 8.1752 11.9688 8.1752 11.9532L11.9454 8.19081C11.961 8.19081 11.9766 8.19081 11.9922 8.19081C12.0078 8.19081 12.0312 8.19081 12.0468 8.19081L15.8092 11.961C15.8092 11.9766 15.8092 11.9922 15.8092 12.0078C15.8092 12.0156 15.8092 12.0312 15.8092 12.039L12.0234 15.8248ZM11.3755 4.95923V7.01995C11.3287 7.05117 11.2897 7.0902 11.2507 7.12923L7.12923 11.2428C7.08239 11.2897 7.04337 11.3365 7.01214 11.3834H4.93582L11.3755 4.95923ZM4.96704 12.6323H7.02775C7.05898 12.6713 7.0902 12.7103 7.12923 12.7494L11.2428 16.8708C11.2819 16.9098 11.3287 16.9488 11.3755 16.9801V19.0486L4.96704 12.6323ZM12.6245 19.0408V16.9722C12.6713 16.941 12.7103 16.902 12.7493 16.863L16.8708 12.7494C16.9098 12.7103 16.941 12.6713 16.9722 12.6245H19.0408L12.6245 19.0408Z"
        fill="currentColor"
      />
    </svg>
  );
}
