import React from 'react';

export default function CursorArrow({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g>
        <path
          d="M15.9231 18.0296C16.0985 18.4505 15.9299 20.0447 15 20.4142C14.0701 20.7837 12.882 20.4142 12.882 20.4142L10.726 16.1024L7 19.8284V3L18.4142 14.4142H14.1615C14.3702 14.8144 15.7003 17.4948 15.9231 18.0296Z"
          fill="white"
        />
        <path
          d="M8 5.41431V17.4143L11 14.4143L13.5 19.4143C13.5 19.4143 14.1763 19.6301 14.5 19.4143C14.8237 19.1985 15.1457 18.7639 15 18.4143C14.3123 16.7639 12.5 13.4143 12.5 13.4143H16L8 5.41431Z"
          fill="#080808"
          fillRule="evenodd"
        />
      </g>
      <defs />
    </svg>
  );
}
