import { useState } from 'react';
import { useElementPresence } from '@front/helper';
import { MessageItemConfig } from '@lib/ia/src/layouts/ChannelLayout/types';
import { compareAsc } from 'date-fns';

/**
 * What we want is slack-like read state presentation,
 * which means
 *
 * 1. when enter a page, we'll immediately mark as read, BUT keep the 'new message' ui on screen
 * 2. when user is already in the page, do not show 'new message' ui
 */
export const useReadStateControl = ({
  sentTime,
  readState,
  previousMessageReadState,
  hasReplyThread,
}: {
  sentTime: string;
  readState: MessageItemConfig['readState'];
  previousMessageReadState: MessageItemConfig['readState'];
  hasReplyThread: boolean;
}) => {
  const { presenceOnScreenAt } = useElementPresence();

  // use useState to keep the 'new message state'
  const [savedReadState] = useState(readState);
  const [savedPreviousMessageReadState] = useState(previousMessageReadState);
  const [savedPresenceOnScreenAt] = useState(presenceOnScreenAt);

  // sentTime > presenceOnScreenAt
  const isMessageAppearAfterPresenceOnScreen =
    savedPresenceOnScreenAt &&
    compareAsc(new Date(sentTime), savedPresenceOnScreenAt) === 1;

  const showIsNewThread =
    savedReadState?.isNewThread && !isMessageAppearAfterPresenceOnScreen;

  // when user is already in the page, do not show unread state, unless there are replies inside thread
  const showIsUnread = hasReplyThread
    ? savedReadState?.isUnread
    : savedReadState?.isUnread && !isMessageAppearAfterPresenceOnScreen;

  return {
    showIsNewThread,
    showIsUnread,
    isPreviousNewThread: savedPreviousMessageReadState?.isNewThread,
  };
};
