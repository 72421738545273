import { createContext, ReactNode, RefObject, useMemo } from 'react';
import { useOnScreen, useVisibilityChange } from '@front/helper';

type ElementPresenceValue = {
  presenceOnScreen: boolean;
  presenceOnScreenAt: Date | null;
};

export const ElementPresenceContext = createContext<ElementPresenceValue>({
  presenceOnScreen: false,
  presenceOnScreenAt: null,
});

export function ElementPresenceProvider({
  elementRef,
  children,
}: {
  elementRef: RefObject<HTMLElement>;
  children: ReactNode;
}) {
  const onScreen = useOnScreen(elementRef);
  const { isVisible } = useVisibilityChange();

  /**
   * onScreen means the ref component is in viewport
   * isVisible means user is watching the browser
   * presenceOnScreen means user is seeing this component
   */
  const presenceOnScreen = onScreen && isVisible;

  const presenceOnScreenAt = useMemo(
    () => (presenceOnScreen ? new Date() : null),
    [presenceOnScreen]
  );

  return (
    <ElementPresenceContext.Provider
      value={{ presenceOnScreen, presenceOnScreenAt }}
    >
      {children}
    </ElementPresenceContext.Provider>
  );
}
