import generateHook from './swr.helper';

export const useReferCheckEmail = (email: NullableString) =>
  generateHook<GetReferEmailCheckRes>(
    email ? `v2/refer/email-check/${email}` : undefined,
    {
      auth: true,
    }
  );

export const useReferInfo = () =>
  generateHook<GetReferInfoRes>('v2/refer/info/me', {
    auth: true,
  });

export const useReferrer = () =>
  generateHook<GetReferrerRes>('v2/refer/referrer/me', {
    auth: true,
    immutable: true,
  });

// 0: All, 1: JoinAha, 2:JoinClub, 3:JoinChallenge
export const useReferInvitation = (type = 0) =>
  generateHook<GetReferInvitationRes>(
    `v2/refer/invitation/external?type=${type}`,
    {
      auth: true,
    }
  );
