import React from 'react';

export default function EmojiNature({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 21C10.95 21 9.96075 20.8358 9.03225 20.5072C8.10375 20.1788 7.2555 19.7149 6.4875 19.1156L4.9125 20.6625C4.70625 20.8688 4.44375 20.9719 4.125 20.9719C3.80625 20.9719 3.54375 20.8688 3.3375 20.6625C3.13125 20.4563 3.02813 20.1938 3.02813 19.875C3.02813 19.5562 3.13125 19.2937 3.3375 19.0875L4.88438 17.5406C4.28438 16.7719 3.82013 15.9187 3.49163 14.9812C3.16313 14.0437 2.99925 13.05 3 12C3 9.4875 3.87188 7.35937 5.61563 5.61562C7.35938 3.87187 9.4875 3 12 3H21V12C21 14.5125 20.1281 16.6406 18.3844 18.3844C16.6406 20.1281 14.5125 21 12 21ZM12 18.75C13.875 18.75 15.4688 18.0938 16.7813 16.7812C18.0938 15.4688 18.75 13.875 18.75 12V5.25H12C10.125 5.25 8.53125 5.90625 7.21875 7.21875C5.90625 8.53125 5.25 10.125 5.25 12C5.25 12.7312 5.3625 13.4299 5.5875 14.0959C5.8125 14.7619 6.12188 15.3664 6.51563 15.9094L12.3375 10.0875C12.5438 9.88125 12.8063 9.77812 13.125 9.77812C13.4438 9.77812 13.7063 9.88125 13.9125 10.0875C14.1375 10.3125 14.25 10.5799 14.25 10.8896C14.25 11.1994 14.1375 11.4664 13.9125 11.6906L8.09063 17.5125C8.63438 17.9062 9.23925 18.2111 9.90525 18.4271C10.5713 18.6431 11.2695 18.7507 12 18.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
