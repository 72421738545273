import { createContext, ReactNode, useMemo } from 'react';

import { PracticeFlowEventContextValue } from '../types';

const initialValue: PracticeFlowEventContextValue = {
  onNextQuestion: () => {},
  onPreviousQuestion: () => {},
  onSubmitQuestion: () => {},
  onLazySubmitQuestion: () => {},
  onCancelSubmitQuestion: () => {},
  onResumeQuestion: () => {},
  onPauseQuestion: () => {},
  onQuizResult: () => {},
};

export const PracticeFlowEventContext =
  createContext<PracticeFlowEventContextValue>(initialValue);

export type PracticeFlowEventContextProviderProps = {
  children: ReactNode;
} & { events: PracticeFlowEventContextValue };

export const PracticeFlowEventContextProvider = ({
  children,
  events,
}: PracticeFlowEventContextProviderProps) => {
  const memoizedValue = useMemo(() => {
    return {
      ...events,
    };
  }, [events]);

  return (
    <PracticeFlowEventContext.Provider value={memoizedValue}>
      {children}
    </PracticeFlowEventContext.Provider>
  );
};
