import { TFunction } from 'i18next';

const getMarketplacePageConfig = (t: TFunction) => ({
  available: {
    title: t('marketplace.available.title'),
    icon: 'TextEditorCheck',
  },
  unavailable: {
    title: t('marketplace.unavailable.title'),
    icon: 'ActionCloseSmall',
  },
  comingSoon: {
    title: t('marketplace.comingSoon.title'),
    icon: 'TestClockSolid',
  },
  myFavorite: {
    title: t('marketplace.myFavorite.title'),
    icon: 'NFTFavoriteSolid',
  },
  myAvatars: {
    title: t('marketplace.myAvatars.title'),
    icon: 'ProfileNFTs',
  },
});

export default getMarketplacePageConfig;
