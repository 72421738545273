import React, { useContext, useMemo, useRef } from 'react';
import { isDesktop } from 'react-device-detect';
import { Box, Fade } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import {
  OtherCheckedSquareSolid as OtherCheckedSquareSolidIcon,
  OtherCheckSquareDash as OtherCheckSquareDashIcon,
  OtherCheckSquareOutline as OtherCheckSquareOutlineIcon,
} from '@front/icon';
import TableLayoutPaneContext from '@lib/ia/src/layouts/TableLayout/contexts/tableLayoutPaneContext';

type LayoutSetting = {
  showRowBorder: boolean;
  showColumnBorder: boolean;
};

export type SideFloatingCheckboxProps = {
  active: boolean;
  disable?: boolean;
  state: 'checked' | 'partial' | 'unchecked';
  onSelect: () => void;
  disableBackgroundStyle?: boolean;
  layoutSetting?: LayoutSetting;
};

const styles = {
  root: {
    position: 'sticky',
    left: 0,
    width: '24px',
    ml: '-24px',
    cursor: 'pointer',
    zIndex: 3001,
  },
  frozenRoot: {
    width: '28px',
    ml: '-28px',
  },
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '32px',
    width: '24px',
  },
  frozenContainer: {
    width: '28px',
    boxShadow: (theme: Theme) =>
      `inset -1px 0 0 0 ${alpha(theme.palette.text.primary, 0.5)}`,
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  frozenContent: {
    pr: 0.5,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backgroundActive: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
  backgroundChecked: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  backgroundPartial: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  backgroundDefault: {
    background: (theme: Theme) => theme.palette.background.body,
  },
  rowBorder: {
    boxShadow: (theme: Theme) =>
      `inset 0 -1px 0 0 ${alpha(theme.palette.text.primary, 0.1)}`,
  },
};

export default function SideFloatingCheckbox({
  active,
  disable,
  state,
  onSelect,
  disableBackgroundStyle,
  layoutSetting,
}: SideFloatingCheckboxProps) {
  const ref = useRef<HTMLDivElement>();
  const handleOnClick = () => {
    if (active && !disable) {
      onSelect();
    }
  };

  const frozen = useContext(TableLayoutPaneContext).horizontalScrolled;

  const checked = state === 'checked';
  const partial = state === 'partial';
  const unchecked = state === 'unchecked';

  const frozenContainerSxProps = useMemo(() => {
    if (!frozen || !ref.current) return [];
    const rect = ref.current.getBoundingClientRect();
    return [
      styles.backgroundDefault,
      {
        backgroundPosition: `left -${rect.left}px top -${rect.top}px`,
        backgroundSize: '100vw 100vh',
      },
      styles.frozenContainer,
    ];
  }, [frozen]);

  const frozenContentSxProps = useMemo(() => {
    if (!frozen) return [];

    return [
      !!layoutSetting?.showRowBorder && styles.rowBorder,
      !disableBackgroundStyle && active && styles.backgroundActive,
      !disableBackgroundStyle && partial && styles.backgroundPartial,
      !disableBackgroundStyle && checked && styles.backgroundChecked,
      styles.frozenContent,
    ];
  }, [
    active,
    checked,
    disableBackgroundStyle,
    frozen,
    layoutSetting?.showRowBorder,
    partial,
  ]);

  if (!isDesktop) {
    return null;
  }

  return (
    <Box sx={[styles.root, frozen && styles.frozenRoot]} ref={ref}>
      <Box
        sx={[styles.container, ...frozenContainerSxProps]}
        onClick={handleOnClick}
      >
        <Box sx={[styles.content, ...frozenContentSxProps]}>
          <Fade in={active || checked || partial || frozen} timeout={100}>
            <Box sx={styles.icon}>
              {checked && (
                <OtherCheckedSquareSolidIcon width={16} height={16} />
              )}
              {partial && <OtherCheckSquareDashIcon width={16} height={16} />}
              {unchecked && (
                <OtherCheckSquareOutlineIcon width={16} height={16} />
              )}
            </Box>
          </Fade>
        </Box>
      </Box>
    </Box>
  );
}
