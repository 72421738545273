import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLayoutMethodsContext } from '@lib/ia/src/layouts/FormLayout';
import { FormLayoutConfig } from '@lib/ia/src/layouts/FormLayout/types';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { AnswerFormatType, useClubSetting } from '@lib/web/apis';
import {
  reloadSettingFinish,
  useEditorDispatch,
  useEditorSelector,
} from '@lib/web/editor/redux';
import { selectReloadSetting } from '@lib/web/editor/redux/reducers/settingReducer/selector';
import { useClubSlug } from '@lib/web/hooks';

import { getValuesByAnswerFormat } from '../utils/answerFormats';

import { useCurrentQuestion } from './useCurrentQuestion';
import { useChangeableAnswerFormat } from './useSupportedAnswerFormatGroup';

const getFormatTypeIcon = (formatType: AnswerFormatType) => {
  switch (formatType) {
    case AnswerFormatType.Essay:
      return 'EditorEssay';
    case AnswerFormatType.FreeResponse:
      return 'EditorFreeResponse';
    case AnswerFormatType.GridIn:
      return 'EditorGridIn';
    case AnswerFormatType.MultipleChoice:
      return 'EditorMCQ';
    case AnswerFormatType.MultipleResponse:
      return 'EditorMRQ';
    case AnswerFormatType.TrueFalseNotGiven:
      return 'EditorTFNG';
    case AnswerFormatType.Unscramble:
      return 'EditorUnscramble';
    default:
      return 'EditorQuestionGroup';
  }
};
const baseFields = [
  'isShowPassageLineNum',
  'passageLineNumType',
  'isOfferFormula',
  'isOfferCalculator',
];
const getDefaultValueFields = (formatType: AnswerFormatType) => {
  switch (formatType) {
    case AnswerFormatType.Essay:
      return [
        ...baseFields,
        'isOfferWordLimit',
        'isOfferKeyboard',
        'essayAnswerWordLimitMin',
        'essayAnswerWordLimitMax',
      ];
    case AnswerFormatType.FreeResponse:
      return [
        ...baseFields,
        'answerContentType',
        'isOfferWordLimit',
        'isOfferKeyboard',
        'freeResponseAnswerWordLimitMin',
        'freeResponseAnswerWordLimitMax',
      ];
    case AnswerFormatType.GridIn:
      return [...baseFields, 'gridInBoxNum'];
    case AnswerFormatType.MultipleChoice:
      return [
        ...baseFields,
        'mcqMinAnswerNum',
        'mcqMaxAnswerNum',
        'mcqMinCorrectAnswerNum',
        'mcqMaxCorrectAnswerNum',
        'isOfferBlankOption',
      ];
    case AnswerFormatType.MultipleResponse:
      return [
        ...baseFields,
        'mrqMinAnswerNum',
        'mrqMaxAnswerNum',
        'mrqMinCorrectAnswerNum',
        'mrqMaxCorrectAnswerNum',
      ];
    case AnswerFormatType.TrueFalseNotGiven:
      return baseFields;
    case AnswerFormatType.Unscramble:
      return [
        ...baseFields,
        'unscrambleMinAnswerNum',
        'unscrambleMaxAnswerNum',
      ];
    default:
      return [];
  }
};

export const useEditorAnswerFormatSettings = (
  settings: GetCreatorQuestionRes | null,
  isAllowUserEdit: boolean,
  formDisabled: boolean
): IaLayoutConfig[] => {
  const { methods } = useContext(FormLayoutMethodsContext);
  const reloadSetting = useEditorSelector((st) =>
    selectReloadSetting(st.setting)
  );
  const dispatch = useEditorDispatch();

  const { t } = useTranslation('editor');
  const { questionId } = useCurrentQuestion();

  const { answerFormats, questionGroups } =
    useChangeableAnswerFormat(questionId);

  const clubSlug = useClubSlug();
  const { data: clubSettingData } = useClubSetting(clubSlug);
  const clubSetting = clubSettingData?.data;

  const answerFormatTypesGroupOptions = useMemo(() => {
    let options: Array<string | GetSupportedQuestionAnswerFormatTypeRes> = [];
    if (answerFormats.length > 0) {
      options = options.concat(['Answer Formats', ...answerFormats]);
    }
    if (questionGroups.length > 0) {
      options = options.concat(['Question Structure', ...questionGroups]);
    }
    return options;
  }, [answerFormats, questionGroups]);

  const defaultValues = useMemo(() => {
    if (!settings) return {};

    const defaultValueFields = getDefaultValueFields(settings.answerFormatType);

    return {
      answerFormatType: settings.answerFormatType,
      isAllowUserEdit,
      ...defaultValueFields.reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: settings[cur as keyof GetCreatorQuestionRes] ?? '',
        }),
        {}
      ),
    };
  }, [isAllowUserEdit, settings]);

  useEffect(() => {
    const maybeReload = async () => {
      if (reloadSetting && methods.reset) {
        methods.reset(defaultValues);
        dispatch(reloadSettingFinish());
      }
    };

    void maybeReload();
  }, [reloadSetting, defaultValues, methods, dispatch]);

  return useMemo(() => {
    if (!settings) return [];

    const disabled = formDisabled || !isAllowUserEdit;
    return [
      {
        layout: 'form-layout',
        settings: {
          mode: 'onBlur',
          triggerSubmitMode: 'onBlur',
        },
        formStateChanged: 'stateChanged',
        formAction: {
          type: 'event',
          value: 'syncSetting',
        },
        defaultValues,
        mainItems: [
          {
            type: 'group',
            gap: 2,
            items: [
              {
                type: 'select',
                name: 'answerFormatType',
                label: t('edit.RHS.format.label'),
                disabled: formDisabled,
                icon: 'ChatQuestionList',
                options: answerFormatTypesGroupOptions.map((answerType) => {
                  if (typeof answerType === 'string') {
                    return {
                      label: t(answerType),
                      type: 'subheader',
                    };
                  }

                  return {
                    label: t(answerType.name),
                    value: answerType.answerFormatType,
                    icon: {
                      type: 'icon',
                      value: getFormatTypeIcon(answerType.answerFormatType),
                    },
                  };
                }),
                actionMap: {
                  change: {
                    value: 'valueChange',
                  },
                },
              },
              {
                type: 'switch',
                name: 'isAllowUserEdit',
                icon: 'ProfileSetting',
                label: t('edit.RHS.editable.label'),
                helperText: t('edit.RHS.editable.hint'),
                disabled: true,
              },
              ...getValuesByAnswerFormat(t, settings, clubSetting).map(
                (input) => (disabled ? { ...input, disabled } : input)
              ),
            ],
          },
        ],
      } as FormLayoutConfig,
    ];
  }, [
    settings,
    formDisabled,
    isAllowUserEdit,
    defaultValues,
    t,
    answerFormatTypesGroupOptions,
    clubSetting,
  ]);
};
