import React from 'react';

export default function OtherDraft({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.4837 8.22222C14.1189 8.22222 13.8235 7.92448 13.8235 7.55719V2H5.80861C5.36203 2 5 2.36421 5 2.81504V21.185C5 21.6351 5.36336 22 5.81178 22H19.1882C19.6366 22 20 21.6327 20 21.1827V8.22222H14.4837ZM20 7.33333H15.1482C14.9039 7.33333 14.7059 7.13399 14.7059 6.88779V2L20 7.33333Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
