import React from 'react';

export default function ActionSubcategoryArrow({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.21967 2.21967C8.51256 1.92678 8.98744 1.92678 9.28033 2.21967L13.2803 6.21967C13.5732 6.51256 13.5732 6.98744 13.2803 7.28033C12.9874 7.57322 12.5126 7.57322 12.2197 7.28033L9.5 4.56066V12.4998H19.2498C19.664 12.4998 19.9998 12.8355 19.9998 13.2498C19.9998 13.664 19.664 13.9998 19.2498 13.9998H8.75C8.33579 13.9998 8 13.664 8 13.2498V4.56066L5.28033 7.28033C4.98744 7.57322 4.51256 7.57322 4.21967 7.28033C3.92678 6.98744 3.92678 6.51256 4.21967 6.21967L8.21967 2.21967Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
