import React from 'react';

export default function OtherAhaPoints({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.5 3.76159V3.53846C10.5 2.68879 11.1716 2 12 2C12.8284 2 13.5 2.68879 13.5 3.53846L13.5 3.76159C15.1449 4.18544 16.4222 5.28947 16.8475 6.66985C17.014 7.20996 16.5523 7.67954 16 7.67954C15.4477 7.67954 14.9974 7.18132 14.6529 6.73865C14.3876 6.39787 13.9855 6.11014 13.5 5.91301L13.5 10.105C15.2789 10.6465 17 11.5312 17 13.8334C17 15.6693 15.53 17.2283 13.5 17.7513C12.3153 18.0462 11.6742 18.0492 10.5 17.7513C8.85507 17.3275 7.57784 16.2235 7.15247 14.8431C6.98604 14.303 7.44772 13.8334 8 13.8334C8.55229 13.8334 9.00255 14.3316 9.34714 14.7743C9.61241 15.115 10.0145 15.4028 10.5 15.5999V11.4079C8.72115 10.8664 7 9.98176 7 7.67954C7 5.84364 8.47 4.28467 10.5 3.76159ZM10.5 5.91301C9.6099 6.27444 9 6.94046 9 7.67954C9 8.45171 9.48718 8.88757 10.5 9.26004V5.91301ZM13.5 12.2529L13.5 15.5999C14.3901 15.2385 15 14.5725 15 13.8334C15 13.0612 14.5128 12.6254 13.5 12.2529Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M13.5 20.4615C13.5 21.3112 12.8284 22 12 22C11.1716 22 10.5 21.3112 10.5 20.4615C10.5 19.6119 11.1716 18.9231 12 18.9231C12.8284 18.9231 13.5 19.6119 13.5 20.4615Z"
        fill="currentColor"
      />
    </svg>
  );
}
