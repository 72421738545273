import React, { useEffect } from 'react';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const TOAST_LIMIT = 1;

export default function CustomToaster() {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { toasts } = useToasterStore();
  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((t, i) => i >= TOAST_LIMIT && t.duration !== Infinity)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  return (
    <Toaster
      position={mdUp ? 'bottom-right' : 'bottom-center'}
      toastOptions={{
        duration: 3000,
      }}
      containerStyle={
        {
          top: 'var(--toaster-container-inset-y)',
          left: 'var(--toaster-container-inset-x)',
          right: 'var(--toaster-container-inset-x)',
          bottom:
            'calc(env(safe-area-inset-bottom) + var(--toaster-container-inset-y))',
        } as React.CSSProperties
      }
    />
  );
}
