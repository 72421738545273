import React from 'react';

export default function CryptoCurrencyTetherAlt({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M23.6383 14.9029C22.0356 21.3315 15.5244 25.2438 9.09503 23.6407C2.66833 22.0381 -1.24401 15.5265 0.359382 9.09837C1.96137 2.66903 8.47253 -1.24361 14.8999 0.359081C21.3289 1.96177 25.241 8.47405 23.6381 14.903L23.6382 14.9029H23.6383Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M13.5184 13.3603C13.4336 13.3667 12.9952 13.3929 12.0173 13.3929C11.2395 13.3929 10.6872 13.3695 10.4935 13.3603C7.48764 13.2281 5.24404 12.7049 5.24404 12.0784C5.24404 11.4519 7.48764 10.9294 10.4935 10.795V12.8392C10.6901 12.8534 11.2529 12.8866 12.0307 12.8866C12.9641 12.8866 13.4314 12.8477 13.5156 12.8399V10.7964C16.5151 10.9301 18.7537 11.4533 18.7537 12.0784C18.7537 12.7035 16.5158 13.2267 13.5156 13.3596L13.5184 13.3603ZM13.5184 10.585V8.75578H17.7044V5.96631H6.30751V8.75578H10.4928V10.5843C7.09097 10.7406 4.53271 11.4144 4.53271 12.2219C4.53271 13.0294 7.09097 13.7026 10.4928 13.8596V19.7213H13.5177V13.8574C16.9117 13.7012 19.4658 13.028 19.4658 12.2212C19.4658 11.4144 16.9139 10.7413 13.5177 10.5843L13.5184 10.585Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
}
