import { useMemo } from 'react';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { ProfileUserPlaceholder as ProfileUserPlaceholderIcon } from '@front/icon';
import { IndicatorGroup, SquareAvatar } from '@front/ui';
import { HtmlRenderer } from '@lib/ia/src/components/HtmlRenderer';
import { useIaMemberNftAvatar } from '@lib/ia/src/hooks/useMemberNftAvatar';
import { sanitize } from 'dompurify';

import { DisplayTableLayoutAvatarTextCell } from '../../types';

export type IaDisplayTableAvatarTextCellProps =
  DisplayTableLayoutAvatarTextCell;

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
  },
  rootDefault: {
    gridTemplateColumns: '20px 1fr',
  },
  icon: {
    pr: 0.5,
    display: 'flex',
    alignItems: 'center',
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    overflow: 'hidden',
  },
  text: {
    flex: 1,
    pl: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',

    '& span': {
      fontWeight: 'normal',
    },

    br: {
      // make html element render on one line
      content: '" "',
      width: '2px',
      display: 'inline-block',
    },

    div: {
      display: 'inline-flex',
      alignItems: 'center',
    },

    '& [data-content-type="paragraph"] > p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  selfIndicator: {
    px: 1,
    borderRadius: 1,
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
};

export default function IaDisplayTableAvatarTextCell({
  avatar,
  avatarCount = 1,
  text,
  emphasisText,
  textSuffix,
  textSuffixHtml,
  userId,
  meId,
  className,
}: IaDisplayTableAvatarTextCellProps) {
  const {
    avatar: displayAvatar,
    name: displayName,
    indicators,
  } = useIaMemberNftAvatar({
    userId,
    avatar,
    name: text,
  });

  const avatarBackgroundCount = Math.min(avatarCount - 1, 3);

  const showMeTag = useMemo(
    () => !!userId && !!meId && userId === meId,
    [meId, userId]
  );

  return (
    <Box sx={[styles.root, styles.rootDefault]} className={className}>
      {new Array(avatarBackgroundCount).fill(null).map((_, index) => (
        <Box
          key={index}
          sx={[
            styles.icon,
            { position: 'absolute', left: 8 - (index + 1) * 2 }, // padding left 8px , each background 2px spacing
          ]}
        >
          <ProfileUserPlaceholderIcon width={16} height={16} />
        </Box>
      ))}
      <Box sx={styles.icon}>
        <SquareAvatar src={displayAvatar} size={16}>
          {displayName}
        </SquareAvatar>
      </Box>
      <Box sx={styles.textWrapper}>
        <Typography variant="body2" sx={styles.text}>
          {emphasisText ? <strong>{displayName}</strong> : displayName}
          {textSuffixHtml ? (
            <HtmlRenderer htmlString={sanitize(textSuffixHtml)} />
          ) : (
            textSuffix && <span>{textSuffix}</span>
          )}
        </Typography>
        <IndicatorGroup indicators={indicators} size={16} />
        {showMeTag && (
          <Typography variant="body2" sx={styles.selfIndicator}>
            Me
          </Typography>
        )}
      </Box>
    </Box>
  );
}
