import { ChatPin as ChatPinIcon } from '@front/icon';
import { CUSTOM_BR } from '@lib/web/composer';
import ThemeProvider from '@lib/web/composer/components/ThemeProvider';
import { EditorBlockTypes } from '@lib/web/editor/configs';
import { TextComposerPanelKeys } from '@lib/web/editor/TextComposerPanels/panel';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { v4 } from 'uuid';

import Anchor from './Anchor';
import Content from './Content';

export type InlineLineAnchorOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineLineAnchorOptions;
    node: ProseMirrorNode;
  }) => string;
};

type LineAnchorProps = {
  editor: Editor;
  node: ProseMirrorNode;
  getPos: (() => number) | boolean;
  deleteNode: () => void;
  updateAttributes: (attributes: Record<string, any>) => void;
};

const LineAnchor = ({
  node,
  editor,
  updateAttributes,
  getPos,
  deleteNode,
}: LineAnchorProps) => {
  const pos = typeof getPos === 'function' ? getPos() : null;
  const onDelete = () => {
    if (!pos) return;
    editor
      .chain()
      .insertContentAt(pos, [
        {
          type: 'text',
          text: node.attrs.value,
        },
      ])
      .run();

    deleteNode();
  };
  return (
    <NodeViewWrapper
      style={{ display: 'inline' }}
      data-anchor-id={node.attrs.id}
      data-anchor-value={node.attrs.value}
      className="inline-anchor inline-line-anchor"
      contentEditable={false}
    >
      <ThemeProvider mode="dark">
        <Anchor
          id={node.attrs.id}
          value={node.attrs.value}
          updateAttributes={updateAttributes}
          panelKey={TextComposerPanelKeys.TextComposerLineMarker}
          placeholder="Write line marker content here..."
        >
          <ChatPinIcon width={16} height={16} />
        </Anchor>
        <Content
          id={node.attrs.id}
          onDelete={onDelete}
          updateAttributes={updateAttributes}
          text={node.attrs.value}
        />
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export const InlineLineAnchor = Node.create<InlineLineAnchorOptions>({
  name: EditorBlockTypes.InlineLineAnchor,
  group: 'inline',
  inline: true,
  content: 'inline*',
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: v4,
        parseHTML: () => v4(), // when copy and paste, generate a new id
      },
      value: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.name,
      },
    ];
  },

  renderHTML({ node }) {
    const content: (string | string[])[] = [];
    const textSplit = (node.attrs.value as string).split(CUSTOM_BR);
    textSplit.forEach((text, index) => {
      content.push(text);
      if (index !== textSplit.length - 1) content.push(['br']);
    });

    return [
      'span',
      mergeAttributes({
        class: this.name,
        'data-render-id': node.attrs.id,
        'data-content-type': this.name,
      }),
      ...content,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(LineAnchor);
  },
});
