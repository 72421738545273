import React from 'react';

export default function AnalyticsMistake({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.9371 7.95422V4.48096C8.62543 4.48096 5.8151 6.61091 4.82129 9.56565L8.22783 10.4351C8.82122 8.9804 10.2584 7.95422 11.9371 7.95422Z"
        fill="currentColor"
        opacity="0.2"
      />
      <path
        d="M7.9375 11.9235C7.9375 12.6657 8.14275 13.3603 8.5 13.9545L5.7842 16.9972C4.63103 15.6215 3.9375 13.8529 3.9375 11.9235C3.9375 11.0571 4.07735 10.2232 4.33588 9.44263L8.22826 10.435C8.04075 10.8947 7.9375 11.3971 7.9375 11.9235Z"
        fill="currentColor"
        opacity="0.4"
      />
      <path
        d="M12.7988 20.3157C12.5158 20.344 12.2286 20.3585 11.938 20.3585C9.34108 20.3585 7.01617 19.2028 5.45703 17.3814L8.5005 13.9543C9.1985 15.1153 10.4767 15.8928 11.938 15.8928L12.7988 20.3157Z"
        fill="currentColor"
        opacity="0.6"
      />
      <path
        d="M11.9375 15.893C14.1466 15.893 15.9375 14.1158 15.9375 11.9236H21.1375C21.1375 16.6305 17.5481 20.5054 12.9375 21L11.9375 15.893Z"
        fill="currentColor"
        opacity="0.8"
      />
      <path
        d="M11.9375 2C17.4603 2 21.9375 6.44295 21.9375 11.9236C21.9375 13.5298 21.5529 15.047 20.8702 16.3892L15.6467 13.4121C15.8342 12.9525 15.9375 12.45 15.9375 11.9236C15.9375 9.73134 14.1466 7.95416 11.9375 7.95416V2Z"
        fill="currentColor"
      />
    </svg>
  );
}
