import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { MaskIcon } from '@front/ui';

import { ListLayoutAreaListSkeleton } from '../types';

const styles = {
  root: {
    px: { sx: 2.5, md: '12px' },
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },
  item: {
    display: 'flex',
    gap: 1,
  },
};

export type AreaListSkeletonProps = ListLayoutAreaListSkeleton;
export default function AreaListSkeleton({
  showIcon = false,
}: AreaListSkeletonProps) {
  const rowCount = 3;

  return (
    <Box sx={styles.root}>
      {Array.from({ length: rowCount }).map((_, index) => (
        <Box key={index} sx={styles.item}>
          {showIcon && (
            <MaskIcon>
              <Skeleton width={16} height={16} variant="rectangular" />
            </MaskIcon>
          )}
          <Box flex={1}>
            <Skeleton key={index} width="100%" />
          </Box>
        </Box>
      ))}
    </Box>
  );
}
