import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'next-i18next';
import { ClickAwayListener } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { alpha, Theme } from '@mui/material/styles';
import useThreadViewChangeEmoji from '@app/web/src/widgets/ThreadPage/hooks/useThreadViewChangeEmoji';
import useThreadViewRename from '@app/web/src/widgets/ThreadPage/hooks/useThreadViewRename';
import {
  Popper,
  ReactionPicker,
  ReactionPickerTrigger,
  ResponsiveTooltip,
  TextField,
} from '@front/ui';
import { ThreadViewType } from '@lib/web/apis';
import { useRecentMarks } from '@lib/web/hooks';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import ThreadMemberTitle from '@lib/web/thread/ThreadMemberTitle';
import ThreadViewIcon from '@lib/web/thread/ui/ThreadViewIcon';

const styles = {
  breadcrumb: {
    px: '2px',
    borderRadius: 1,
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
      },
    },
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
    minWidth: 0,
  },
  popper: {
    zIndex: 10,
    '& .popper-content': {
      p: 0,
      width: '265px',
    },
  },
  popperInner: {
    px: '12px',
    py: '6px',
  },
  textField: {
    '& .textfield-message': {
      display: 'none',
    },
  },
  textFieldPrefix: {
    pt: '2px',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    '& .MuiButtonBase-root': {
      fontSize: 16,
    },
  },
  name: {
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const EDITABLE_THREAD_VIEW_TYPES = [
  ThreadViewType.DmGroup,
  ThreadViewType.Club,
];

type GetTooltipContentProps = {
  t: TFunction;
  viewType: ThreadViewType;
};

const getChangeIconTooltipProps = ({ t, viewType }: GetTooltipContentProps) => {
  switch (viewType) {
    case ThreadViewType.DmOneOnOne:
      return t(
        'dm.view.changeIcon.description',
        'click to change icon, this change will only visible to you.'
      );

    case ThreadViewType.DmGroup:
      return t(
        'group.view.changeIcon.description',
        'click to change icon, this change will visible to all group members.'
      );

    case ThreadViewType.Club:
      return t('club.view.changeIcon.description', 'click to change icon.');

    default:
      return null;
  }
};

function TitleEmojiPicker({
  open,
  anchorEl,
  onClose,
  onChange,
  onBlurClose,
  onMoreClick,
  emoji,
}: {
  open: boolean;
  anchorEl?: Element;
  onClose: () => void;
  onChange?: (emoji?: GetMarkEmojiRes | null) => void;
  onBlurClose?: () => void;
  onMoreClick?: (e: MouseEvent) => void;
  clubId?: string;
  emoji?: GetMarkEmojiRes | null;
}) {
  const { t } = useTranslation('thread');
  const { recentMarks, mutateRecentMarksWithNewMark } = useRecentMarks();

  const handleChange = async (ev: GetMarkEmojiRes | null, e: MouseEvent) => {
    e.stopPropagation();
    if (ev) {
      void mutateRecentMarksWithNewMark(ev);
    }
    onChange?.(ev);
    onClose();
  };

  return (
    <ReactionPicker
      anchorEl={anchorEl}
      open={open}
      selected={emoji ? [emoji] : undefined}
      onClose={onClose}
      onChange={handleChange}
      onBlurClose={onBlurClose}
      onMoreClick={onMoreClick}
      recentEmojis={recentMarks}
      customMoreLabel={t('thread.view.changeIcon.label', 'Change Icon')}
    />
  );
}

type ThreadViewBreadcrumbEditableProps = {
  view: GetThreadViewRes;
};

function ThreadViewBreadcrumbEditable({
  view,
}: ThreadViewBreadcrumbEditableProps) {
  const { t } = useTranslation('thread');
  const ref = useRef<HTMLElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [editing, setEditing] = useState(false);

  const [reactionMenuOpened, setReactionMenuOpened] = useState(false);
  const [reactionMenuAnchorEl, setReactionMenuAnchorEl] = useState<Element>();

  const { viewType } = useThreadViewDetail(view);
  const { emoji, handleOpenEmojiPanel, handleChangeEmoji } =
    useThreadViewChangeEmoji({
      view,
    });
  const { viewName, handleChangeViewName } = useThreadViewRename({
    view,
  });
  const [currentName, setCurrentName] = useState<string>(viewName);

  const handleOpen = () => {
    setEditing(true);
    setTimeout(() => {
      setCurrentName(viewName);
    });
  };

  const handleClose = () => {
    setEditing(false);
  };

  const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
    handleChangeViewName(ev);
    setCurrentName(ev.target.value);
  };

  const handleKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleClose();
    }
  };

  const handleHoverReaction = (el?: Element) => {
    setReactionMenuOpened(true);
    setReactionMenuAnchorEl(el);
  };

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current && editing) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(
          inputRef.current.value.length,
          inputRef.current.value.length
        );
      }
    });
  }, [editing]);

  return (
    <>
      <Box
        sx={styles.breadcrumb}
        ref={ref}
        onClick={handleOpen}
        className="hello"
      >
        <Box>
          <ThreadViewIcon view={view} size="sm" />
        </Box>
        <Box sx={styles.name}>{viewName || 'Untitled'}</Box>
      </Box>
      <Popper
        open={editing}
        anchorEl={ref.current}
        sx={styles.popper}
        placement="bottom-start"
        popperOptions={{
          modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box sx={styles.popperInner}>
            <TextField
              placeholder="Untitled"
              prefix={
                <ResponsiveTooltip
                  title="Change Icon"
                  content={getChangeIconTooltipProps({ t, viewType })}
                  tooltipProps={{
                    followCursor: true,
                    disableInteractive: true,
                    slotProps: {
                      popper: {
                        sx: { zIndex: 9999 }, // above the reaction popover
                      },
                    },
                  }}
                >
                  <Box sx={styles.textFieldPrefix}>
                    <ReactionPickerTrigger
                      value={undefined}
                      onChangeReaction={() => {}}
                      onHover={handleHoverReaction}
                      onPress={handleHoverReaction}
                    >
                      <ThreadViewIcon
                        view={view}
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenEmojiPanel();
                        }}
                      />
                    </ReactionPickerTrigger>
                  </Box>
                </ResponsiveTooltip>
              }
              multiline
              sx={styles.textField}
              value={currentName}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
              inputRef={inputRef}
            />
            <TitleEmojiPicker
              open={reactionMenuOpened}
              anchorEl={reactionMenuAnchorEl}
              onClose={() => {
                setReactionMenuOpened(false);
              }}
              onBlurClose={() => setReactionMenuOpened(false)}
              onMoreClick={(e) => {
                e.stopPropagation();
                handleClose();
                setReactionMenuOpened(false);
                handleOpenEmojiPanel();
              }}
              onChange={(ev) => {
                handleClose();
                handleChangeEmoji({ changedEmoji: ev });
              }}
              emoji={emoji}
            />
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export function ThreadViewBreadcrumb({ viewId }: { viewId: string }) {
  const { findDmView } = useDirectMessageViews();
  const view = findDmView(viewId);
  const { viewName, viewNameMembers, viewType } = useThreadViewDetail(view);

  if (!view) {
    return (
      <Box>
        <Skeleton width={100} />
      </Box>
    );
  }

  if (EDITABLE_THREAD_VIEW_TYPES.includes(viewType)) {
    return <ThreadViewBreadcrumbEditable view={view} />;
  }

  if (viewNameMembers) {
    return (
      <>
        <Box>
          <ThreadViewIcon view={view} size="sm" />
        </Box>
        <Box>
          <ThreadMemberTitle members={viewNameMembers} badgeSize={16} />
        </Box>
      </>
    );
  }

  return (
    <>
      <Box>
        <ThreadViewIcon view={view} size="sm" />
      </Box>
      <Box>{viewName}</Box>
    </>
  );
}
