import React, { useMemo } from 'react';
import { alpha, Skeleton, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { useIaItemStatus } from '@lib/ia/src/core/IaItemStatus/useIaItemStatus';
import IconListItem from '@lib/ia/src/layouts/SmallIconListLayout/components/IconListItem';
import { SmallIconListLayoutItem } from '@lib/ia/src/layouts/SmallIconListLayout/types';

export type AreaSelectableIconListProps = {
  title?: string;
  items: SmallIconListLayoutItem[];
  isLoading?: boolean;
};

const styles = {
  title: {
    px: { xs: 2.5, md: 1.5 },
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
  loading: {
    py: '2px',
    px: { xs: 2.5, md: 1.5 },
    width: '100%',
  },
};
export default function AreaSelectableIconList({
  title,
  items,
  isLoading,
}: AreaSelectableIconListProps) {
  const { getIaAction } = useIaAction();
  const { getItemStatus } = useIaItemStatus<{ id: string }>();
  const orderedItems = useMemo(() => {
    const isSelected = ({ id }: { id: string }) =>
      getItemStatus?.({ id }).selected || false;
    return [
      ...items.filter((item) => isSelected(item)),
      ...items.filter((item) => !isSelected(item)),
    ];
  }, [getItemStatus, items]);

  return (
    <Box>
      {!!title && (
        <Typography variant="caption" sx={styles.title}>
          {title}
        </Typography>
      )}
      {isLoading ? (
        <Box sx={styles.loading}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        orderedItems.map(({ id, icon, iconType, text, actionMap }) => (
          <IconListItem
            key={id}
            icon={icon}
            iconType={iconType}
            text={text}
            selectable={{
              selected: getItemStatus?.({ id }).selected || false,
              onSelect: () =>
                getIaAction<{ id: string }>(actionMap?.select?.value)?.action({
                  id,
                }),
            }}
          />
        ))
      )}
    </Box>
  );
}
