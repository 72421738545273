export default function MathFractions({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.74823 14.0777V8.1912C4.58982 8.32737 4.42291 8.44807 4.24752 8.5533C4.07214 8.65853 3.9024 8.74828 3.73833 8.82256C3.57992 8.89684 3.43564 8.95873 3.30552 9.00825C3.17539 9.05777 3.07355 9.09182 3 9.11039V7.41128C3.23762 7.35557 3.47808 7.27201 3.72136 7.16059C3.96464 7.04917 4.19943 6.92847 4.42574 6.79849C4.65205 6.66231 4.86139 6.52613 5.05375 6.38996C5.25177 6.24759 5.41867 6.11761 5.55446 6H6.57284V14.0777H4.74823Z"
        fill="currentColor"
      />
      <path
        d="M16.6803 6.18569L9.11881 19.5H7.04809L14.6096 6.18569H16.6803Z"
        fill="currentColor"
      />
      <path
        d="M21 19.4907H15.8741V18.7108C15.8741 18.2651 15.9505 17.8752 16.1033 17.5409C16.256 17.2067 16.4455 16.9127 16.6719 16.6589C16.8982 16.3989 17.1443 16.1637 17.4102 15.9532C17.6761 15.7366 17.9222 15.5261 18.1485 15.3219C18.3748 15.1114 18.5644 14.8948 18.7171 14.6719C18.8699 14.4429 18.9463 14.1798 18.9463 13.8827C18.9463 13.6228 18.867 13.4247 18.7086 13.2885C18.5502 13.1461 18.3352 13.075 18.0637 13.075C17.9279 13.075 17.7779 13.0935 17.6139 13.1307C17.4498 13.1678 17.2857 13.2204 17.1216 13.2885C16.9632 13.3566 16.8076 13.4371 16.6549 13.5299C16.5078 13.6228 16.3805 13.728 16.273 13.8456V12.0444C16.6124 11.803 16.9576 11.6389 17.3083 11.5523C17.6591 11.4594 18.0212 11.413 18.3946 11.413C18.7454 11.413 19.0736 11.4656 19.3791 11.5708C19.6846 11.6699 19.9477 11.8215 20.1683 12.0258C20.3946 12.2301 20.5728 12.4807 20.703 12.7779C20.8331 13.075 20.8982 13.4185 20.8982 13.8085C20.8982 14.1551 20.8246 14.4832 20.6775 14.7926C20.5361 15.1021 20.3579 15.3931 20.1429 15.6654C19.9279 15.9378 19.6959 16.1884 19.447 16.4175C19.198 16.6465 18.9661 16.8508 18.7511 17.0303C18.5361 17.2098 18.355 17.3645 18.2079 17.4945C18.0665 17.6245 17.9958 17.7266 17.9958 17.8009V17.838H21V19.4907Z"
        fill="currentColor"
      />
    </svg>
  );
}
