import React from 'react';
import { Theme } from '@mui/material/styles';
import { SystemCssProperties } from '@mui/system';

export type HeatMapNode = {
  color: SystemCssProperties<Theme>['color'];
  outlineColor?: SystemCssProperties<Theme>['outlineColor'];
  renderTooltip?: (
    index: number,
    children: React.ReactElement
  ) => React.ReactNode;
  href?: string;
  hoverable?: boolean;
};
