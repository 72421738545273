import { TFunction } from 'i18next';

const getAgentPageConfig = (t: TFunction) => ({
  settings: {
    title: t('agent.create_settings.title'),
    icon: 'ProfileSetting',
  },
  prompts: {
    title: t('agent.create_shortcut.title'),
    icon: 'ProfileModifier',
  },
  dataSourcePlaylists: {
    title: t('agent.create_playlists.title'),
    shortTitle: t('agent.create_playlists.shortTitle'),
    icon: 'AIEditorPlaylist',
  },
  dataSourceQuestions: {
    title: t('agent.create_questions.title'),
    shortTitle: t('agent.create_questions.shortTitle'),
    icon: 'AIEditorQuestion',
  },
  dataSourceLinks: {
    title: t('agent.create_links.title'),
    shortTitle: t('agent.create_links.shortTitle'),
    icon: 'AIEditorLink',
  },
  dataSourceFiles: {
    title: t('agent.create_files.title'),
    shortTitle: t('agent.create_files.shortTitle'),
    icon: 'AIEditorFile',
  },
});

export default getAgentPageConfig;
