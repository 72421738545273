/* eslint-disable @typescript-eslint/no-explicit-any */
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  EmojiActivity as EmojiActivityIcon,
  EmojiAnimal as EmojiAnimalIcon,
  EmojiFace as EmojiFaceIcon,
  EmojiFlag as EmojiFlagIcon,
  EmojiFood2 as EmojiFood2Icon,
  EmojiObject as EmojiObjectIcon,
  EmojiTravel2 as EmojiTravel2Icon,
  TestClock as TestClockIcon,
} from '@front/icon';
import {
  ControlledTooltip,
  InfoTooltip,
  Scrollbar,
  SearchBar,
  Tab,
  Tabs,
  useControlledTooltip,
} from '@front/ui';
import slugify from 'slugify';

import { EmojiValue, MarkGroupEnum } from './types';
import useEmojiSource from './useEmojiSource';

export const EMOJI_GROUP_MAP = {
  0: {
    code: 0,
    title: 'Frequently used',
    icon: <TestClockIcon width={16} height={16} />,
  },
  [MarkGroupEnum.EmojiSmileysAndPeople]: {
    code: MarkGroupEnum.EmojiSmileysAndPeople,
    title: 'Smileys & People',
    icon: <EmojiFaceIcon width={16} height={16} />,
  },
  [MarkGroupEnum.EmojiAnimalsAndNature]: {
    code: MarkGroupEnum.EmojiAnimalsAndNature,
    title: 'Animals & Nature',
    icon: <EmojiAnimalIcon width={16} height={16} />,
  },
  [MarkGroupEnum.EmojiFoodAndDrink]: {
    code: MarkGroupEnum.EmojiFoodAndDrink,
    title: 'Food & Drink',
    icon: <EmojiFood2Icon width={16} height={16} />,
  },
  [MarkGroupEnum.EmojiActivity]: {
    code: MarkGroupEnum.EmojiActivity,
    title: 'Activity',
    icon: <EmojiActivityIcon width={16} height={16} />,
  },
  [MarkGroupEnum.EmojiTravelAndPlaces]: {
    code: MarkGroupEnum.EmojiTravelAndPlaces,
    title: 'Travel & Places',
    icon: <EmojiTravel2Icon width={16} height={16} />,
  },
  [MarkGroupEnum.EmojiObjects]: {
    code: MarkGroupEnum.EmojiObjects,
    title: 'Objects',
    icon: <EmojiObjectIcon width={16} height={16} />,
  },
  [MarkGroupEnum.EmojiFlags]: {
    code: MarkGroupEnum.EmojiFlags,
    title: 'Flags',
    icon: <EmojiFlagIcon width={16} height={16} />,
  },
};

const GROUP_TITLE_HEIGHT = 26;
const styles = {
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  main: { position: 'relative', flex: 1 },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  content: {
    px: { xs: 2.5, md: '12px' },
    pb: 2.5,
  },
  top: {
    px: { xs: 2.5, md: '12px' },
    display: 'grid',
  },
  tabs: {
    minHeight: 36,
    mx: { xs: -2.5, md: '-12px' },

    '& .MuiTabs-flexContainer': {
      gap: 0,
      px: { xs: 2.5, md: '12px' },
      width: 'min-content',
    },
    button: { flex: 1, minHeight: 36, minWidth: 36 },
  },
  container: {
    display: 'grid',
    gap: 2,
    pt: 1,
  },
  groups: {
    display: 'grid',
    gap: 2,
  },
  group: {
    h6: {
      m: 0,
      mb: '2px',
      typography: 'caption',
      opacity: 0.5,
      lineHeight: `${GROUP_TITLE_HEIGHT}px`,
    },
  },
  groupContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    columnGap: 0.5,
    rowGap: 1,
    button: {
      p: 0,
      m: 0,
      minWidth: 32,
      height: 32,
      bgcolor: 'transparent',
      border: 'unset',
      fontSize: 28,
      lineHeight: 1,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '6px',
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
      },
    },
  },
  hide: {
    visibility: 'hidden',
  },
  searchItems: {
    py: '12px',
  },
  popper: {
    '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom': {
      ml: 4.5,
      mt: -1,
    },
  },
  tooltipText: {
    opacity: 0.64,
  },
  skeleton: {
    minHeight: 36,
    borderRadius: 0.5,
  },
};

const groups = Object.values(EMOJI_GROUP_MAP);

type EmojiGroupType = Record<MarkGroupEnum, EmojiValue[]>;

export type ReactionPanelInnerProps = {
  selected?: EmojiValue[];
  onReactionClick?: (ev: EmojiValue | null, e: MouseEvent) => void;
  enableRemove?: boolean;
  recentEmojis?: EmojiValue[];
};
export default function ReactionPanelInner({
  selected,
  onReactionClick,
  enableRemove = true,
  recentEmojis = [],
}: ReactionPanelInnerProps) {
  const { tooltipState, pointerEnter, pointerLeave } = useControlledTooltip();
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const groupRef = useRef<HTMLDivElement[]>([]);
  const groupTopRef = useRef<number[]>([]);
  const emojiSource = useEmojiSource();

  const handleClick = (emoji: EmojiValue | null, e: MouseEvent) => {
    if (emoji) {
      setSearch('');
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: 0,
        });
      }
    }

    onReactionClick?.(emoji, e);
  };
  const handleTabChange = (_: any, index: number) => {
    setSearch('');
    const currentTarget = groupRef.current[index];

    if (currentTarget && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: currentTarget.offsetTop + 1,
      });
    }
    setTab(index);
  };

  const emojis: EmojiGroupType = useMemo(() => {
    if (!emojiSource.length) return {} as EmojiGroupType;

    return emojiSource.reduce(
      (acc: EmojiGroupType, cur: EmojiValue) => ({
        ...acc,
        [cur.type as any]: acc[cur.type as MarkGroupEnum]
          ? (acc[cur.type as MarkGroupEnum] as EmojiValue[]).concat(cur)
          : [cur],
      }),
      {} as EmojiGroupType
    );
  }, [emojiSource]);

  const searchItems: EmojiValue[] = useMemo(() => {
    if (!search || !emojiSource.length) return [];
    return emojiSource.filter(
      (d: EmojiValue) =>
        d.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        d.code.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  }, [emojiSource, search]);

  useEffect(() => {
    const scrollTarget = scrollRef.current;
    const handleScroll = (ev: Event) => {
      const target = ev.target as HTMLDivElement;
      if (target) {
        const activeIndex = groupTopRef.current.findIndex(
          (number, i) =>
            target.scrollTop >= number &&
            (!groupTopRef.current[i + 1] ||
              groupTopRef.current[i + 1] > target.scrollTop)
        );

        setTab(activeIndex === -1 ? 0 : activeIndex);
      }
    };

    if (scrollTarget) {
      scrollTarget.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollTarget)
        scrollTarget.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const itemLength = emojiSource.length + recentEmojis.length;

  useEffect(() => {
    groupTopRef.current = groupRef.current.map(
      (groupDom) => groupDom.offsetTop
    );
  }, [itemLength, selected]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.top}>
        <Tabs
          sx={styles.tabs}
          value={tab}
          onChange={handleTabChange}
          textColor="inherit"
          variant="scrollable"
          scrollButtons={false}
        >
          {groups.map((group) => (
            <Tab key={group.code} label={group.icon} />
          ))}
        </Tabs>
        <SearchBar
          placeholder={t('common::Search reactions')}
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />
      </Box>
      <Box sx={styles.main}>
        {!!search && (
          <Scrollbar sx={styles.scroll}>
            <Box sx={[styles.content, styles.searchItems]}>
              <Box sx={styles.groupContent}>
                {searchItems.map((emoji) => (
                  <button
                    key={emoji.name}
                    type="button"
                    onClick={(e) => handleClick(emoji, e)}
                  >
                    {emoji.code}
                  </button>
                ))}
              </Box>
            </Box>
          </Scrollbar>
        )}
        <Scrollbar
          sx={[styles.scroll, !!search && styles.hide]}
          scrollableNodeProps={{ ref: scrollRef }}
        >
          <Box sx={styles.content}>
            {emojiSource.length === 0 && (
              <Box sx={styles.container}>
                <Box sx={styles.groupContent}>
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                </Box>
              </Box>
            )}
            {emojiSource.length > 0 && (
              <Box sx={styles.container}>
                {!!selected?.length && (
                  <Box sx={styles.group}>
                    <h6>Selected Emoji</h6>

                    <Box sx={styles.groupContent}>
                      {selected.map((item) => (
                        <InfoTooltip
                          key={item.id}
                          slotProps={{
                            popper: {
                              sx: styles.popper,
                            },
                          }}
                          title={
                            <Box>
                              <Typography variant="caption" display="block">
                                {item.name}
                              </Typography>
                              {enableRemove && selected.length === 1 && (
                                <Typography
                                  sx={styles.tooltipText}
                                  variant="numberCaption"
                                >
                                  Click to remove emoji
                                </Typography>
                              )}
                            </Box>
                          }
                        >
                          <button
                            type="button"
                            onClick={(e) =>
                              handleClick(
                                enableRemove && selected.length === 1
                                  ? null
                                  : item,
                                e
                              )
                            }
                          >
                            {item.code}
                          </button>
                        </InfoTooltip>
                      ))}
                    </Box>
                  </Box>
                )}
                <Box sx={[styles.groups, !!search && styles.hide]}>
                  {groups.map((group, i) => (
                    <Box
                      key={group.code}
                      ref={(el: HTMLDivElement) => {
                        groupRef.current[i] = el;
                      }}
                      sx={styles.group}
                    >
                      {(group.code === 0 ||
                        !!emojis[group.code as MarkGroupEnum]) && (
                        <>
                          <h6>{group.title}</h6>
                          <Box
                            sx={styles.groupContent}
                            data-testid={`emoji-picker-group-${slugify(
                              group.title,
                              '_'
                            )}`}
                          >
                            {(group.code === 0
                              ? recentEmojis
                              : emojis[group.code as MarkGroupEnum]
                            ).map((emoji) => (
                              <button
                                key={emoji.name}
                                type="button"
                                onClick={(e) => handleClick(emoji, e)}
                                onPointerEnter={(ev) =>
                                  pointerEnter(ev, emoji.name)
                                }
                                onPointerLeave={pointerLeave}
                              >
                                {emoji.code}
                              </button>
                            ))}
                          </Box>
                        </>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Scrollbar>
      </Box>
      <ControlledTooltip
        open={!!tooltipState.open && !!tooltipState.anchorEl}
        anchorEl={tooltipState.anchorEl}
        title={tooltipState.title}
      />
    </Box>
  );
}
