import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@front/ui';
import {
  AsyncTaskStatus,
  CreatorQuestionStatus,
  StructureType,
} from '@lib/web/apis';
import {
  CreatorQuestionDetailContext,
  getQuestionAsyncTaskStatusText,
  getQuestionAsyncTaskTooltip,
  useCreatorQuestionListData,
  useErrorMessage,
  useValidateBeforePublish,
} from '@lib/web/editor';
import { useSyncVariablesBeforePublish } from '@lib/web/editor/hooks';
import { useWatchAsyncTask } from '@lib/web/hooks';
import { PositionalErrorMessages } from '@lib/web/ui';
import { callWithToast } from '@lib/web/utils';

export const useHandlePublishClick = () => {
  const { validateBeforePublish } = useValidateBeforePublish();
  const syncVariablesBeforePublish = useSyncVariablesBeforePublish();
  const { publishCreatorQuestion } = useContext(CreatorQuestionDetailContext);
  const { watchAsyncTask } = useWatchAsyncTask();

  const {
    questions,
    reloadQuestions,
    selectGroupIdFromSubQuestion,
    selectQuestion,
  } = useCreatorQuestionListData();

  return useCallback(
    async (
      triggerQuestion:
        | GetCreatorQuestionListRes
        | GetCreatorQuestionGroupItemRes
    ) => {
      const question =
        triggerQuestion.structureType === StructureType.SubQuestion
          ? selectQuestion(selectGroupIdFromSubQuestion(triggerQuestion.id)) // use its group question
          : triggerQuestion;

      if (!question) {
        console.warn('cannot find question when calling handlePublishClick');
        return;
      }

      if (question.structureType === StructureType.QuestionGroup) {
        const checked = await toast.confirm(
          'Publish group will publish all questions that are inside of this group.',
          {
            confirmText: 'Publish',
            type: 'warning',
            desktopSx: { maxWidth: 335 },
            anchorEl: document.querySelector(
              '[data-testid="publish-button"]'
            ) as Element,
          }
        );

        if (!checked) {
          return;
        }
      }

      if (question.status === CreatorQuestionStatus.ChangeUnpublished) {
        const checked = await toast.confirm(
          'Once you publish edits on a published question, the changes will be applied everywhere.',
          {
            confirmText: 'Publish Changes',
            type: 'warning',
            desktopSx: { maxWidth: 335 },
            anchorEl: document.querySelector(
              '[data-testid="publish-button"]'
            ) as Element,
          }
        );

        if (!checked) {
          return;
        }
      }

      const { success, errorMessages, validationData } =
        await validateBeforePublish(question.id);

      if (!success) {
        toast.error('Publish Failed');
        return { errorMessages };
      }

      await syncVariablesBeforePublish(question.id, validationData);

      const [res] = await callWithToast(
        () => publishCreatorQuestion({ ids: [question.id], isAsyncTask: true }),
        {
          errorMsg: 'Publish Failed',
          anchorEl: document.querySelector(
            '[data-testid="publish-button"]'
          ) as Element,
        }
      );
      if (res) {
        void reloadQuestions(undefined, {
          optimisticData: questions.map((q) =>
            q.id === question.id
              ? {
                  ...q,
                  asyncTaskStatus: AsyncTaskStatus.Created,
                  subQuestions: q.subQuestions?.map((subQuestion) => ({
                    ...subQuestion,
                    asyncTaskStatus: AsyncTaskStatus.Created,
                  })),
                }
              : q
          ),
          revalidate: false,
        });

        watchAsyncTask({
          taskId: res.data.asyncTaskId,
          callbackKey: 'reload-questions',
          watchStatus: [AsyncTaskStatus.Processing, AsyncTaskStatus.Finished],
          onStatusChange: () => {
            void reloadQuestions();
          },
        });
      }
    },
    [
      publishCreatorQuestion,
      questions,
      reloadQuestions,
      selectGroupIdFromSubQuestion,
      selectQuestion,
      syncVariablesBeforePublish,
      validateBeforePublish,
      watchAsyncTask,
    ]
  );
};

const useHandleBatchPublishClick = () => {
  const { validateBeforePublish } = useValidateBeforePublish();
  const syncVariablesBeforePublish = useSyncVariablesBeforePublish();
  const { questions, reloadQuestions, selectQuestion, selectQuestionOrder } =
    useCreatorQuestionListData();
  const { publishCreatorQuestion } = useContext(CreatorQuestionDetailContext);
  const { watchAsyncTask } = useWatchAsyncTask();

  const getConfirmMessage = (ids: string[]) => {
    for (const id of ids) {
      const question = selectQuestion(id);
      if (question?.structureType === StructureType.QuestionGroup) {
        return 'Publish group will publish all questions that are inside of this group.';
      }
      if (question?.status === CreatorQuestionStatus.ChangeUnpublished) {
        return 'Once you publish edits on a published question, the changes will be applied everywhere.';
      }
    }
    return null;
  };

  return async (ids: string[]) => {
    const confirmMsg = getConfirmMessage(ids);

    if (confirmMsg) {
      const checked = await toast.confirm(confirmMsg, {
        confirmText: 'Publish',
        type: 'warning',
        desktopSx: { maxWidth: 335 },
        anchorEl: document.querySelector(
          '[data-testid="publish-button"]'
        ) as Element,
      });

      if (!checked) {
        return;
      }
    }

    const validIds: string[] = [];
    const failedIds: string[] = [];
    for (const id of ids) {
      const { success, validationData } = await validateBeforePublish(id);
      if (success) {
        void syncVariablesBeforePublish(id, validationData);
        validIds.push(id);
      } else {
        failedIds.push(id);
      }
    }

    const getFailedMsg = (values: string[]) =>
      `Publish Question ${values
        .map((id) => selectQuestionOrder(id))
        .join(',')} Failed`;

    if (failedIds.length > 0) {
      toast.error(getFailedMsg(failedIds));
    }
    if (validIds.length === 0) {
      return;
    }

    const [res] = await callWithToast(
      () => publishCreatorQuestion({ ids: validIds, isAsyncTask: true }),
      {
        errorMsg: getFailedMsg(validIds),
        anchorEl: document.querySelector(
          '[data-testid="publish-button"]'
        ) as Element,
      }
    );
    if (res) {
      void reloadQuestions(undefined, {
        optimisticData: questions.map((q) =>
          validIds.includes(q.id)
            ? {
                ...q,
                asyncTaskStatus: AsyncTaskStatus.Created,
                subQuestions: q.subQuestions?.map((subQuestion) => ({
                  ...subQuestion,
                  asyncTaskStatus: AsyncTaskStatus.Created,
                })),
              }
            : q
        ),
        revalidate: false,
      });

      watchAsyncTask({
        taskId: res.data.asyncTaskId,
        callbackKey: 'reload-questions',
        watchStatus: [AsyncTaskStatus.Processing, AsyncTaskStatus.Finished],
        onStatusChange: () => {
          void reloadQuestions();
        },
      });
    }
  };
};

export const usePeriodicValidateQuestionPublish = (
  triggerQuestionId: string
) => {
  const { validateBeforePublish } = useValidateBeforePublish();
  const { hasErrorOrWarning } = useErrorMessage();
  const { selectGroupIdFromSubQuestion, selectQuestion } =
    useCreatorQuestionListData();

  // HACK: this is a temporary solution for revalidating the question when there is errors on the page
  useEffect(() => {
    let timer: ReturnType<typeof setInterval> | null = null;

    const triggerQuestion = selectQuestion(triggerQuestionId);

    if (!triggerQuestion) {
      return;
    }

    const questionGroupId = selectGroupIdFromSubQuestion(triggerQuestionId);

    if (
      hasErrorOrWarning(triggerQuestionId) ||
      (questionGroupId && hasErrorOrWarning(questionGroupId))
    ) {
      timer = setInterval(() => {
        void validateBeforePublish(
          questionGroupId ? questionGroupId : triggerQuestionId
        );
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [
    hasErrorOrWarning,
    selectGroupIdFromSubQuestion,
    selectQuestion,
    triggerQuestionId,
    validateBeforePublish,
  ]);
};

export const usePublishQuestion = () => {
  const { t } = useTranslation('editor');
  const { selectQuestion } = useCreatorQuestionListData();
  const handlePublishClick = useHandlePublishClick();
  const handleBatchPublishClick = useHandleBatchPublishClick();

  const getPublishButtonAttrs = useCallback(
    (
      id: string
    ): {
      handlePublishClick: () => Promise<
        | undefined
        | { errorMessages: Record<string, Partial<PositionalErrorMessages>> }
      >;
      publishButtonText: string;
      publishable: boolean;
      publishButtonTooltip: string | null;
    } => {
      const question = selectQuestion(id);

      if (!question) {
        return {
          handlePublishClick: () => Promise.resolve(undefined),
          publishButtonText: '',
          publishable: false,
          publishButtonTooltip: null,
        };
      }

      const isPublishableStatus = [
        CreatorQuestionStatus.Draft,
        CreatorQuestionStatus.ChangeUnpublished,
      ].includes(question.status);

      const isAsyncTaskInProgress = [
        AsyncTaskStatus.Created,
        AsyncTaskStatus.Pending,
        AsyncTaskStatus.Processing,
      ].includes(question.asyncTaskStatus);

      const isQuestionGroup = [
        StructureType.QuestionGroup,
        StructureType.SubQuestion,
      ].includes(question.structureType);

      return {
        handlePublishClick: () => handlePublishClick(question),
        publishButtonText: isAsyncTaskInProgress
          ? getQuestionAsyncTaskStatusText(t, question.asyncTaskStatus)
          : isQuestionGroup
          ? t('Publish Group')
          : t('Publish'),
        publishButtonTooltip: getQuestionAsyncTaskTooltip(
          t,
          question.asyncTaskStatus
        ),
        publishable: isPublishableStatus && !isAsyncTaskInProgress,
      };
    },
    [handlePublishClick, selectQuestion, t]
  );

  return {
    getPublishButtonAttrs,
    handleBatchPublishClick,
  };
};
