import {
  ChatPin as ChatPinIcon,
  EditorAI as EditorAIIcon,
  EditorHighlight as EditorHighlightIcon,
  EditorTextVariable as EditorTextVariableIcon,
  NFTPicture as NFTPictureIcon,
  OtherAudio as OtherAudioIcon,
  OtherLaTex as OtherLaTexIcon,
} from '@front/icon';
import { VariableType } from '@lib/web/apis';
import {
  ComposerSlashMenuItem,
  getIsCursorOnLastBlock,
} from '@lib/web/composer';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import {
  insertInlineBlock,
  insertOrUpdateBlock,
} from '@lib/web/composer/utils/blocksOperation';
import { EditorBlockTypes } from '@lib/web/editor';
import { EditorComposerSchema } from '@lib/web/editor/EditorTextComposer/config/editorComposerSchema';
import { v4 } from 'uuid';

const insertAI: ComposerSlashMenuItem<EditorComposerSchema> = {
  name: 'AI',
  nodeType: EditorBlockTypes.InlineAi,

  execute: (editor) => {
    insertInlineBlock(editor, [
      {
        type: 'inline-ai',
        attrs: {},
      },
      {
        type: 'text',
        text: ' ',
      },
    ]);
  },
  aliases: ['ai', 'inline-ai'],
  hint: '/ai',
  group: 'Advanced Text',
  icon: <EditorAIIcon width={16} height={16} />,
};

const insertLatex: ComposerSlashMenuItem<EditorComposerSchema> = {
  name: 'LaTeX',
  nodeType: EditorBlockTypes.InlineLatex,

  execute: (editor) => {
    insertInlineBlock(editor, [
      {
        type: EditorBlockTypes.InlineLatex,
        attrs: {},
      },
      {
        type: 'text',
        text: ' ',
      },
    ]);
  },
  aliases: ['latex', EditorBlockTypes.InlineLatex],
  hint: '/latex',
  group: 'Advanced Text',
  icon: <OtherLaTexIcon width={16} height={16} />,
};

const insertImage: ComposerSlashMenuItem<EditorComposerSchema> = {
  name: 'Image',
  nodeType: EditorBlockTypes.Image,

  execute: (editor) => {
    const isLastBlock = getIsCursorOnLastBlock(editor._tiptapEditor);
    insertOrUpdateBlock<EditorComposerSchema>(editor, [
      {
        type: EditorBlockTypes.Image,
        props: {
          status: 'added-by-insert',
        },
      },
      ...(isLastBlock
        ? [
            {
              type: BasicBlockTypes.Paragraph as const, // add one line for better ux, otherwise user cannot add new text in the next line
            },
          ]
        : []),
    ]);
  },
  aliases: ['image'],
  hint: '/image',
  group: 'Elements',
  icon: <NFTPictureIcon width={16} height={16} />,
};

const insertAudio: ComposerSlashMenuItem<EditorComposerSchema> = {
  name: 'Audio',
  nodeType: EditorBlockTypes.Audio,

  execute: (editor) => {
    const isLastBlock = getIsCursorOnLastBlock(editor._tiptapEditor);
    insertOrUpdateBlock<EditorComposerSchema>(editor, [
      {
        type: EditorBlockTypes.Audio,
        props: {
          status: 'added-by-insert',
        },
      },
      ...(isLastBlock
        ? [
            {
              type: BasicBlockTypes.Paragraph as const, // add one line for better ux, otherwise user cannot add new text in the next line
            },
          ]
        : []),
    ]);
  },
  aliases: ['audio'],
  hint: '/audio',
  group: 'Elements',
  icon: <OtherAudioIcon width={16} height={16} />,
};

const insertLineMarker: ComposerSlashMenuItem<EditorComposerSchema> = {
  name: 'Line Marker',
  nodeType: EditorBlockTypes.InlineLineMarker,

  execute: (editor) => {
    insertInlineBlock(editor, [
      {
        type: EditorBlockTypes.InlineLineMarker,
        attrs: {
          id: v4(),
          isNewCreated: true,
        },
      },
      {
        type: 'text',
        text: ' ',
      },
    ]);
  },
  aliases: ['linemarker', EditorBlockTypes.InlineLineMarker],
  hint: '/linemarker',
  group: 'Elements',
  icon: <ChatPinIcon width={16} height={16} />,
};

const insertHighlight: ComposerSlashMenuItem<EditorComposerSchema> = {
  name: 'Highlight',
  nodeType: EditorBlockTypes.InlineHighlight,

  execute: (editor) => {
    insertInlineBlock(editor, [
      {
        type: EditorBlockTypes.InlineHighlight,
        attrs: {
          id: v4(),
          isNewCreated: true,
        },
      },
      {
        type: 'text',
        text: ' ',
      },
    ]);
  },
  aliases: ['highlight', EditorBlockTypes.InlineHighlight],
  hint: '/highlight',
  group: 'Elements',
  icon: <EditorHighlightIcon width={16} height={16} />,
};

const insertVariable: ComposerSlashMenuItem<EditorComposerSchema> = {
  name: 'Variable',
  nodeType: EditorBlockTypes.InlineVariable,

  execute: (editor) => {
    insertInlineBlock(editor, [
      {
        type: EditorBlockTypes.InlineVariable,
        attrs: {
          id: v4(),
          type: VariableType.Text,
          isNewCreated: true,
        },
      },
      {
        type: 'text',
        text: ' ',
      },
    ]);
  },
  aliases: ['variable', EditorBlockTypes.InlineVariable],
  hint: '/variable',
  group: 'Elements',
  icon: <EditorTextVariableIcon width={16} height={16} />,
};

export const editorSlashMenu = {
  insertImage,
  insertAudio,
  insertVariable,
  insertLineMarker,
  insertHighlight,
  insertAI,
  insertLatex,
};
