import React from 'react';

export default function AnalyticsWeakness({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M6.95508 6.5C6.95508 5.11929 8.07437 4 9.45508 4C10.8358 4 11.9551 5.11929 11.9551 6.5C11.9551 7.88071 10.8358 9 9.45508 9C8.07437 9 6.95508 7.88071 6.95508 6.5Z"
        fill="currentColor"
      />
      <path
        d="M5.92383 14.4884C5.92383 12.1142 7.84956 10.1895 10.2238 10.1895C12.5981 10.1895 14.5238 12.1142 14.5238 14.4884C14.5238 16.8627 12.5981 18.7874 10.2238 18.7874C7.84956 18.7874 5.92383 16.8627 5.92383 14.4884Z"
        fill="currentColor"
      />
      <path
        d="M13.3917 7.50039C13.3917 5.5674 14.9587 4 16.8917 4C18.8247 4 20.3917 5.5674 20.3917 7.50039C20.3917 9.43339 18.8247 11.0008 16.8917 11.0008C14.9587 11.0008 13.3917 9.43339 13.3917 7.50039Z"
        fill="currentColor"
      />
      <path
        d="M14.6953 19C14.6953 18.4477 15.143 18 15.6953 18C16.2476 18 16.6953 18.4477 16.6953 19C16.6953 19.5523 16.2476 20 15.6953 20C15.143 20 14.6953 19.5523 14.6953 19Z"
        fill="currentColor"
      />
      <path
        d="M16 15.1975C16 13.8168 17.1193 12.6975 18.5 12.6975C19.8807 12.6975 21 13.8168 21 15.1975C21 16.5782 19.8807 17.6975 18.5 17.6975C17.1193 17.6975 16 16.5782 16 15.1975Z"
        fill="currentColor"
      />
      <path
        d="M3 10.0991C3 9.13262 3.7835 8.34912 4.75 8.34912C5.7165 8.34912 6.5 9.13262 6.5 10.0991C6.5 11.0656 5.7165 11.8491 4.75 11.8491C3.7835 11.8491 3 11.0656 3 10.0991Z"
        fill="currentColor"
      />
    </svg>
  );
}
