import { Box } from '@mui/material';
import { Icon, SquareAvatar } from '@front/ui';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import { Editor, mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

import ThemeProvider from '../../../components/ThemeProvider';

const styles = {
  avatarLink: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    gap: '2px',
    px: 0.25,
    mt: '-3px', // XXX: use margin top -3px here to make it vertical align with the text nearby
    color: 'primary.main',
    backgroundColor: 'alpha.primaryLightA10',
    borderRadius: '4px',
    cursor: 'pointer',
    typography: 'chatBody',
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: 'alpha.primaryLightA30',
      },
    },
  },
};

type MentionProps = {
  editor: Editor;
  node: ProseMirrorNode;
  getPos: (() => number) | boolean;
  deleteNode: () => void;
  updateAttributes: (attributes: Record<string, any>) => void;
};

const Mention = ({ node }: MentionProps) => {
  return (
    <ThemeProvider>
      <Box
        sx={styles.avatarLink}
        contentEditable={false}
        component={NodeViewWrapper}
      >
        {/* without using contentEditable: false, the user is able to delete each underscore */}
        {node.attrs.icon?.type === 'icon' && (
          <Icon name={node.attrs.icon.value} width={16} height={16} />
        )}
        {node.attrs.icon?.type === 'image' && (
          <SquareAvatar src={node.attrs.icon.value} size={16}>
            {node.attrs.value}
          </SquareAvatar>
        )}
        @{node.attrs.value}
      </Box>
    </ThemeProvider>
  );
};

export type InlineMentionOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineMentionOptions;
    node: ProseMirrorNode;
  }) => string;
};

export const InlineMention = Node.create<InlineMentionOptions>({
  name: BasicBlockTypes.InlineMention,
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      value: {
        default: '',
      },
      icon: {
        default: null,
      },
      display: {
        default: '',
      },
      type: {
        default: 'default',
      },
      metadata: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.name,
      },
    ];
  },

  renderHTML({ node }) {
    return [
      'span',
      mergeAttributes({
        class: this.name,
        'data-content-type': this.name,
        'data-mention-type': node.attrs.type,
        'data-metadata-user-id': node.attrs.metadata?.userId,
        'data-metadata-agent-id': node.attrs.metadata?.agentId,
        'data-metadata-member-id': node.attrs.metadata?.memberId,
        'data-render-ui':
          node.attrs.type === 'profile' ? 'mentionTag' : undefined,
      }),
      [
        'span',
        {
          class: 'mention-icon',
        },
        node.attrs.icon
          ? [
              'img',
              {
                src:
                  node.attrs.icon.type === 'image'
                    ? node.attrs.icon.value
                    : `/api/icon/${
                        node.attrs.icon.value
                      }?size=32&color=${encodeURIComponent('#00A3FF')}`,
                width: 16,
                height: 16,
                style: 'vertical-align: middle',
              },
            ]
          : '',
      ],
      [
        'span',
        {
          class: 'mention-value',
        },
        `@${node.attrs.value}`,
      ],
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Mention);
  },
});
