import { MouseEvent, useCallback, useContext, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { useSortable } from '@dnd-kit/sortable';
import {
  OtherDrag as OtherDragIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import { FloatingMenu, floatingMenuStyles } from '@front/ui';
import { CreatorQuestionConfigContext } from '@lib/web/editor/contexts';
import { AddQuestionDropdown } from '@lib/web/editor/menu';

import useQuestionSideMenu from './hooks/useQuestionSideMenu';

const styles = {
  tooltipInner: {
    display: 'flex',
    flexDirection: 'column',
  },
  draggableButton: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
};

export type QuestionSideMenuProps = {
  id: string;
  questionGroupId?: string;
  sx?: BoxProps['sx'];
};

export default function QuestionSideMenu({
  id,
  questionGroupId,
  sx,
}: QuestionSideMenuProps) {
  const { listeners, setActivatorNodeRef, isDragging } = useSortable({
    id,
  });

  const { enableFeature } = useContext(CreatorQuestionConfigContext);

  const [addNewMenuOpen, setAddNewMenuOpen] = useState(false);
  const addNewButtonRef = useRef<HTMLButtonElement>(null);
  const [addAboveOrBelow, setAddAboveOrBelow] = useState<'above' | 'below'>(
    'below'
  );

  const { sideMenuShowHideClassName, setRefMap, openMenu, openedItemId } =
    useQuestionSideMenu({ id, frozen: addNewMenuOpen });

  const handleAddButtonClick = useCallback((ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    setAddAboveOrBelow(ev.altKey ? 'above' : 'below');
    setAddNewMenuOpen(true);
  }, []);

  const handleMenuIconClick = useCallback(
    (ev: MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      openMenu(id);
    },
    [id, openMenu]
  );

  const setRef = useCallback(
    (el: HTMLElement) => {
      setRefMap(id, el);
    },
    [id, setRefMap]
  );

  const menuIconButtonProps = {
    selected: openedItemId === id,
    ref: setActivatorNodeRef,
    ...listeners,
    onClick: handleMenuIconClick,
  };

  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <Box
      sx={[floatingMenuStyles.menu, ...sxProps]}
      ref={setRef}
      className={sideMenuShowHideClassName}
    >
      {enableFeature?.createNewQuestion && (
        <FloatingMenu.TipButton
          title={
            <Typography sx={styles.tooltipInner} variant="caption">
              <span>
                <b>Click</b> to add below
              </span>
              <span>
                <b>Option-click</b> to add above
              </span>
            </Typography>
          }
          onClick={handleAddButtonClick}
          ref={addNewButtonRef}
          selected={addNewMenuOpen}
        >
          <TestAddIcon />
        </FloatingMenu.TipButton>
      )}
      <FloatingMenu.TipButton
        sx={styles.draggableButton}
        title={
          isDragging ? undefined : (
            <Typography sx={styles.tooltipInner} variant="caption">
              <span>
                <b>Drag</b> to move
              </span>
              <span>
                <b>Click</b> to open menu
              </span>
            </Typography>
          )
        }
        {...menuIconButtonProps}
      >
        <OtherDragIcon />
      </FloatingMenu.TipButton>
      {addNewMenuOpen && (
        <AddQuestionDropdown
          target={addNewButtonRef}
          open={addNewMenuOpen}
          setOpen={setAddNewMenuOpen}
          anchorQuestion={{
            id,
            questionGroupId,
            addAboveOrBelow,
          }}
        />
      )}
    </Box>
  );
}
