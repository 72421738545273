import React from 'react';

export default function NFTSolid({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.9059 5.83659C14.0425 3.67034 12.2069 2.2607 12.1297 2.20174L11.8652 2L11.5978 2.19776C11.5205 2.25495 9.68264 3.62333 7.81773 5.7694C5.28447 8.68455 4 11.5005 4 14.139C4 18.4736 7.5264 22 11.861 22C16.1956 22 19.7221 18.4736 19.7221 14.139C19.7221 11.5737 18.4381 8.78033 15.9059 5.83659Z"
        fill="currentColor"
      />
    </svg>
  );
}
