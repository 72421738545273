import { mergeAttributes, Node } from '@tiptap/core';

/**
 * This reference to
 * @blocknote/core/src/extensions/Blocks/nodes/BlockContainer.ts
 *
 * we cannot directly use above file because @blocknote does not export that
 */
export const BlockContainerBlockForGenerateHtml = Node.create({
  name: 'blockContainer',
  group: 'blockContainer',
  content: 'inline*',
  priority: 50,
  defining: true,

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        'data-node-type': 'block-outer',
      }),
      [
        'div',
        mergeAttributes(HTMLAttributes, {
          'data-node-type': this.name,
        }),
        0,
      ],
    ];
  },
});
