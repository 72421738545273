import React from 'react';

export default function EmojiTwoTone({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.5 3H4.5C4.10233 3.0005 3.72109 3.15869 3.43989 3.43989C3.15869 3.72109 3.0005 4.10233 3 4.5V19.5C3.0005 19.8977 3.15869 20.2789 3.43989 20.5601C3.72109 20.8413 4.10233 20.9995 4.5 21H19.5C19.8977 20.9995 20.2789 20.8413 20.5601 20.5601C20.8413 20.2789 20.9995 19.8977 21 19.5V4.5C20.9995 4.10233 20.8413 3.72109 20.5601 3.43989C20.2789 3.15869 19.8977 3.0005 19.5 3ZM4.5 19.5L19.5 4.5V19.5H4.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
