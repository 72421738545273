import { useCallback, useEffect, useRef, useState } from 'react';
import { useLatestValueRef, useQueueWorker } from '@front/helper';
import { useAddWatchingEvent } from '@lib/web/thread/hooks/core/useAddWatchingEvent';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { queryAllChannels } from '@lib/web/thread/utils/channelUtils';
import { Channel, StreamChat } from 'stream-chat';

import { useChannelInformation } from '../channel/useChannelInformation';
import { hashDmViewReferenceId } from '../view/utils';
import { useDirectMessageViews } from '../views/useDirectMessageViews';

/**
 * FIXME: currently we only support latest 30 unread channels
 * because stream sdk only give us 30 channels at one time,
 * if we want to get more of than, we can use pagination to do this,
 * but it increase the number of query to stream sdk,
 * which increase the risk of hitting rate limit,
 * before doing this, we need to have some cache method
 *
 */
export const useUnreadChannels = (chatClient?: StreamChat | null) => {
  const [unreadChannels, setUnreadChannels] = useState<Channel[]>([]);
  const isFetching = useRef(false);

  const { getChannelInformation } = useChannelInformation();
  const { views, reloadViews } = useDirectMessageViews();
  const unreadChannelsLengthRef = useLatestValueRef(unreadChannels.length);

  const fetchUnreadChannels = useCallback(async () => {
    if (!chatClient || isFetching.current) return;

    isFetching.current = true;

    const unreadResp = await chatClient.getUnreadCount();

    const channelCids = unreadResp.channels.map(
      (channel) => channel.channel_id
    );

    if (channelCids.length === 0) {
      if (unreadChannelsLengthRef.current > 0) {
        setUnreadChannels([]);
      }
      isFetching.current = false;
      return;
    }

    const channels = await queryAllChannels(chatClient, {
      cid: {
        $in: channelCids,
      },
      muted: {
        $eq: false,
      },
    });
    setUnreadChannels(channels);

    isFetching.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatClient]);

  useEffect(() => {
    try {
      void fetchUnreadChannels();
    } catch (e) {
      console.error('cannot fetch unread channels', e);
    }
  }, [fetchUnreadChannels]);

  useEffect(() => {
    const needReloadViews = unreadChannels.some((unreadChannel) => {
      const { channelMemberIds } = getChannelInformation(unreadChannel);
      const unreadChannelReferenceId = hashDmViewReferenceId(channelMemberIds);
      return !views.some(
        (view) => view.referenceId === unreadChannelReferenceId
      );
    });

    if (needReloadViews) {
      setTimeout(() => {
        reloadViews();
      }, 1000); // prevent too fast query (timing issue)
    }
  }, [
    fetchUnreadChannels,
    getChannelInformation,
    reloadViews,
    unreadChannels,
    views,
  ]);

  return {
    unreadChannels,
    reloadUnreadChannels: fetchUnreadChannels,
  };
};

export const useWatchUnreadChannels = () => {
  const { reloadUnreadChannels } = useThread();
  const { addTask } = useQueueWorker();

  useAddWatchingEvent({
    scope: 'unread-channels',
    key: 'all',
    callback: (event) => {
      if (
        event.type === 'notification.mark_read' ||
        event.type === 'notification.message_new' ||
        event.type === 'message.new'
      ) {
        addTask({
          scope: 'threadReloadUnread',
          taskKey: 'threadReloadUnread',
          debounceTime: 2000,
          task: async () => {
            reloadUnreadChannels();
          },
        });
      }
    },
  });
};
