import { useCallback } from 'react';
import { ComposerBlock } from '@lib/web/composer';
import { extractTextFromHtml } from '@lib/web/utils';
import { v4 } from 'uuid';

import { AgentMaterial, StreamChatGenerics } from '../../types';

/**
 * stream SDK only allows a maximum of 5KB for a message and a channel's custom data,
 * we use 4KB as a buffer
 */
const MAX_MESSAGE_KB = 4;

const maybeTruncateTooLongAgentMaterials = (
  agentMaterials?: AgentMaterial[]
): AgentMaterial[] | undefined => {
  if (!agentMaterials) return agentMaterials;

  let totalSizeKB = 0;
  const truncatedMaterials: AgentMaterial[] = [];

  for (const material of agentMaterials) {
    const materialSizeKB = new Blob([JSON.stringify(material)]).size / 1024;
    if (totalSizeKB + materialSizeKB > MAX_MESSAGE_KB) {
      const remainingKB = MAX_MESSAGE_KB - totalSizeKB;

      const sliceSize = Math.floor((remainingKB * 1024) / 2); // Convert KB to bytes and divide by 2 for UTF-16 characters
      material.value = material.value.slice(0, sliceSize);
      truncatedMaterials.push(material);
      break;
    }
    totalSizeKB += materialSizeKB;
    truncatedMaterials.push(material);
  }

  return truncatedMaterials;
};

export const useBuildMessageData = () => {
  const buildMessageData = useCallback(
    ({
      text,
      blocks,
      agentMaterials,
      disableExternalPayload,
    }: {
      text: string;
      blocks: ComposerBlock[];
      agentMaterials?: AgentMaterial[];
      disableExternalPayload?: boolean;
    }): StreamChatGenerics['messageType'] => {
      /**
       * Transforming from HTML to text helps us save tokens when querying GPT.
       * It's said that GPT reads text just as well as HTML,
       * but if we find it more reasonable to use HTML, we can change it back.
       */
      const plainText = extractTextFromHtml(text);

      /**
       * blocks and materials are the most large data in a message, so we only use this to determine
       * whether we should use external payload
       */
      const blocksKB = new Blob([JSON.stringify(blocks)]).size / 1024;
      const materialsKB =
        new Blob([JSON.stringify(agentMaterials)]).size / 1024;
      const totalDataKB = blocksKB + materialsKB;
      const useExternalMessagePayload =
        !disableExternalPayload && totalDataKB > MAX_MESSAGE_KB;

      return {
        text: plainText,
        customBlocks: useExternalMessagePayload ? [] : blocks,
        agentMaterials: maybeTruncateTooLongAgentMaterials(agentMaterials),
        externalPayloadId: useExternalMessagePayload ? v4() : undefined,
      };
    },
    []
  );

  return {
    buildMessageData,
  };
};

export const isTextExceedSizeLimit = (html: string): boolean => {
  const plainText = extractTextFromHtml(html);
  const textKB = new Blob([plainText]).size / 1024;
  return textKB > MAX_MESSAGE_KB;
};
