import WorkflowEditMode from './components/WorkflowEditMode';
import WorkflowViewMode from './components/WorkflowViewMode';
import { WorkflowLayoutConfig } from './types';

export type WorkflowLayoutProps = WorkflowLayoutConfig;

export default function WorkflowLayout({
  mode = 'view',
  items = [],
  settings,
}: WorkflowLayoutProps) {
  const processedItems = items.map((item) => ({
    ...item,
    fieldValues: item.fieldValues || [],
  }));

  const props = {
    items: processedItems,
    settings,
  };
  return mode === 'edit' ? (
    <WorkflowEditMode {...props} layout="workflow-layout" />
  ) : (
    <WorkflowViewMode {...props} layout="workflow-layout" />
  );
}
