import { Box, Theme } from '@mui/material';
import { ActionChevronLeft as ActionChevronLeftIcon } from '@front/icon';
import { useSwiper } from 'swiper/react';

const styles = {
  root: {
    position: 'absolute',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    width: { xs: 48, md: 72 },
    cursor: 'pointer',
    '& svg': (theme: Theme) => ({
      [theme.breakpoints.down('md')]: {
        width: 16,
        height: 16,
      },
    }),
    top: 0,
    left: 0,
    zIndex: 10,
    background:
      'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
    pl: { xs: 0.5, md: 1.5 },
  },
};

export default function IaCarouselPrev() {
  const swiper = useSwiper();

  return (
    <Box sx={styles.root} onClick={() => swiper.slidePrev()}>
      <ActionChevronLeftIcon />
    </Box>
  );
}
