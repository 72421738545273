import { useContext, useMemo } from 'react';
import { buildHookMutate, QuestionDetailComponentType } from '@lib/web/apis';
import { CreatorQuestionDetailContext } from '@lib/web/editor/contexts';
import { apiBlocksToComposerBlocks } from '@lib/web/editor/EditorTextComposer';

export const useCreatorQuestionDetailData = (questionId: string) => {
  const {
    data: detailRes,
    isLoading,
    mutate,
  } = useContext(CreatorQuestionDetailContext).useDetail(questionId);

  const leftQuestionComponents = useMemo(() => {
    const components =
      detailRes?.data[QuestionDetailComponentType.LeftQuestionArea] || [];

    return components.map((component) => ({
      ...component,
      editorBlocks: apiBlocksToComposerBlocks(component.blocks),
    }));
  }, [detailRes?.data]);

  const rightQuestionComponents = useMemo(() => {
    const components =
      detailRes?.data[QuestionDetailComponentType.RightQuestionArea] || [];

    return components.map((component) => ({
      ...component,
      editorBlocks: apiBlocksToComposerBlocks(component.blocks),
    }));
  }, [detailRes?.data]);

  const answerComponents = useMemo(() => {
    const components =
      detailRes?.data[QuestionDetailComponentType.Answer] || [];

    return components.map((component) => ({
      ...component,
      editorBlocks: apiBlocksToComposerBlocks(component.blocks),
    }));
  }, [detailRes?.data]);

  const answerSolutionComponents = useMemo(() => {
    const components =
      detailRes?.data[QuestionDetailComponentType.AnswerSolution] || [];

    return components.map((component) => ({
      ...component,
      editorBlocks: apiBlocksToComposerBlocks(component.blocks),
    }));
  }, [detailRes?.data]);

  const generalSolutionComponents = useMemo(() => {
    const components =
      detailRes?.data[QuestionDetailComponentType.GeneralSolution] || [];

    return components.map((component) => ({
      ...component,
      editorBlocks: apiBlocksToComposerBlocks(component.blocks),
    }));
  }, [detailRes?.data]);

  const reloadQuestionDetail = useMemo(() => buildHookMutate(mutate), [mutate]);

  return {
    leftQuestionComponents,
    rightQuestionComponents,
    answerComponents,
    answerSolutionComponents,
    generalSolutionComponents,
    isInitializingOrLoading: !detailRes || isLoading,
    detailData: detailRes?.data,
    reloadQuestionDetail,
  };
};
