import { BlockType } from '@lib/web/apis';
import {
  ComposerBlock,
  persistentStringToComposerBlock,
} from '@lib/web/composer';
import { BlockContainerBlockForGenerateHtml } from '@lib/web/composer/TextComposer/components/blocks/BlockContainerBlock';
import { HeadingBlock } from '@lib/web/composer/TextComposer/components/blocks/HeadingBlock';
import { InlineBlank } from '@lib/web/composer/TextComposer/components/blocks/InlineBlank';
import { LegacyMaterialBlock } from '@lib/web/composer/TextComposer/components/blocks/LegacyMaterialBlock';
import { LegacyParagraphBlock } from '@lib/web/composer/TextComposer/components/blocks/LegacyParagraphBlock';
import { ParagraphBlockForGenerateHtml } from '@lib/web/composer/TextComposer/components/blocks/ParagraphBlock';
import StepBlock from '@lib/web/composer/TextComposer/components/blocks/StepBlock';
import { SubtitleBlock } from '@lib/web/composer/TextComposer/components/blocks/SubtitleBlock';
import { UnsupportedBlock } from '@lib/web/composer/TextComposer/components/blocks/UnsupportedBlock';
import {
  TextColorExtension,
  TextColorMark,
} from '@lib/web/composer/TextComposer/extensions/TextColor';
import { EditorBlockTypes } from '@lib/web/editor';
import { generateHTML } from '@tiptap/core';
import Bold from '@tiptap/extension-bold';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Italic from '@tiptap/extension-italic';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import memoize from 'lodash/memoize';
import { v4 } from 'uuid';

import AudioBlock from '../components/blocks/AudioBlock';
import { ImageBlock } from '../components/blocks/ImageBlock';
import { InlineAI } from '../components/blocks/InlineAI';
import { InlineHighlightAnchor } from '../components/blocks/InlineAnchor/InlineHighlightAnchor';
import { InlineLineAnchor } from '../components/blocks/InlineAnchor/InlineLineAnchor';
import { InlineHighlight } from '../components/blocks/InlineHighlight';
import { InlineLatex } from '../components/blocks/InlineLatex';
import { InlineLineMarker } from '../components/blocks/InlineLineMarker';
import { InlineVariable } from '../components/blocks/InlineVariable';

const sortApiBlocks = (
  apiBlocks: CreatorQuestionDetailBlock[]
): CreatorQuestionDetailBlock[] => {
  const sortedApiBlocks = [...apiBlocks];
  sortedApiBlocks.sort((a, b) => a.order - b.order);
  return sortedApiBlocks;
};

export const apiBlockToComposerBlock = (
  apiBlock: CreatorQuestionDetailBlock
): ComposerBlock => {
  const rootBlock = persistentStringToComposerBlock(apiBlock.content);
  // In some cases, we use plain text to store data, such as essay answer block
  if (rootBlock.type === 'text') {
    return rootBlock;
  }

  // Most of the time, we use blocknote format to store data, there must be a blockContainer block wrap the content
  if (rootBlock.type !== 'blockContainer') {
    console.warn(
      'api returned block has unknown formats to transform into blocknote editor : ',
      rootBlock
    );
    return rootBlock;
  }

  if (rootBlock.content?.length !== 1) {
    console.warn('blockContainer has no content', rootBlock);
    return rootBlock;
  }

  const contentBlock = rootBlock.content[0];

  if (contentBlock.type === 'paragraph') {
    return rootBlock;
  }

  if (contentBlock.type === 'heading') {
    return rootBlock;
  }

  if (contentBlock.type === 'subtitle') {
    return rootBlock;
  }

  if (contentBlock.type === 'image') {
    contentBlock.attrs.src =
      apiBlock.metadata && JSON.parse(apiBlock.metadata).url;
    return rootBlock;
  }

  if (contentBlock.type === 'legacy-paragraph') {
    return rootBlock;
  }

  if (contentBlock.type === 'legacy-material') {
    return rootBlock;
  }

  if (contentBlock.type === 'step') {
    return rootBlock;
  }

  if (contentBlock.type === 'audio') {
    return rootBlock;
  }

  return {
    ...rootBlock,
    content: [
      {
        id: v4(),
        type: 'unsupported',
        attrs: {
          content: JSON.stringify(contentBlock),
        },
      },
    ],
  };
};
export const apiBlocksToComposerBlocks = (
  apiBlocks?: CreatorQuestionDetailBlock[]
): ComposerBlock[] => {
  if (!apiBlocks) {
    return [];
  }
  return sortApiBlocks(apiBlocks).map(apiBlockToComposerBlock);
};

/**
 * Be carefully to use this function outside this file, most case you only need 'apiToBlock',
 * to use this function, you need to make sure the order and meta is correct
 * (see function sortApiBlocks and apiBlockToComposerBlock)
 * @param composerBlock
 */
export const composerBlockToHtml = (composerBlock: ComposerBlock): string => {
  try {
    return generateHTML(composerBlock, [
      Document,
      BlockContainerBlockForGenerateHtml,
      ParagraphBlockForGenerateHtml,
      Text,
      HeadingBlock.node,
      SubtitleBlock.node,
      HardBreak,
      InlineAI,
      InlineLatex,
      ImageBlock,
      AudioBlock,
      InlineBlank,
      InlineHighlightAnchor,
      InlineHighlight,
      InlineLineMarker,
      InlineLineAnchor,
      InlineVariable,
      Underline,
      Bold,
      Italic,
      TextColorMark,
      TextColorExtension,
      StepBlock.node,
      UnsupportedBlock,
      LegacyParagraphBlock,
      LegacyMaterialBlock,
    ]);
  } catch (e) {
    if (e instanceof RangeError) {
      console.warn('not supported type to generate html : ', composerBlock, e);
      return '';
    }
    console.error(e);
    return '';
  }
};
export const apiBlocksToHtml = (
  apiBlocks?: CreatorQuestionDetailBlock[]
): string => {
  if (!apiBlocks) {
    return '';
  }

  return sortApiBlocks(apiBlocks)
    .map((apiBlock) => composerBlockToHtml(apiBlockToComposerBlock(apiBlock)))
    .join('');
};

const getVariableIds = (blocks: ComposerBlock[]) => {
  let variables: { id: string; variableId: string }[] = [];
  blocks.forEach((block) => {
    if (block.type === EditorBlockTypes.InlineVariable) {
      variables.push({
        id: block.attrs.id,
        variableId: block.attrs.variableId,
      });
    }
    if (block.content) {
      variables = variables.concat(getVariableIds(block.content));
    }
  });

  return variables;
};

export const questionDetailToVariables = (
  questionDetail?: GetCreatorQuestionDetailRes
): { id: string; variableId: string }[] => {
  if (!questionDetail) {
    return [];
  }
  let variables: { id: string; variableId: string }[] = [];

  Object.values(questionDetail).forEach((detailComponent) => {
    detailComponent.forEach((detail) => {
      detail.blocks.map(apiBlockToComposerBlock).forEach((block) => {
        variables = variables.concat(getVariableIds([block]));
      });
    });
  });

  return variables;
};

export const composerBlockToUpdateApiParams = (
  rootBlock: ComposerBlock
): UpdateCreatorQuestionDetailBlock => {
  const content = JSON.stringify(rootBlock);

  // use plain text to store data
  if (rootBlock.type === 'text') {
    return {
      type: BlockType.Text,
      content,
    };
  }

  // use blocknote format to store data
  if (rootBlock.type === 'blockContainer') {
    if (rootBlock.content?.length !== 1) {
      console.warn(
        'block.content.length is not 1, which is not expected',
        rootBlock
      );
      return {
        type: BlockType.Unknown,
        content,
      };
    }

    const contentBlock = rootBlock.content[0]; // this is the blocknote format, there is a blockContainer block wrap the content

    if (contentBlock.type === 'paragraph') {
      // This one is for normal text, the text type will be wrapped here,
      // also, inline-latex is an inline block, so it will be wrapped inside this, too
      return {
        type: BlockType.Text,
        content,
      };
    }

    if (contentBlock.type === 'heading' && +contentBlock.attrs.level === 3) {
      return {
        type: BlockType.Header3,
        content,
      };
    }

    if (contentBlock.type === 'subtitle') {
      return {
        type: BlockType.Text, // need backend add new type
        content,
      };
    }

    if (contentBlock.type === 'image') {
      return {
        type: BlockType.Image,
        content,
        metadata: JSON.stringify({
          key: contentBlock.attrs.key,
          url: contentBlock.attrs.src, // XXX: although update api doesn't need this, we pass it because this function is reused in  buildOptimisticData (useCreatorQuestionDetailHandle.ts)
        }),
      };
    }

    if (contentBlock.type === 'audio') {
      return {
        type: BlockType.Audio,
        content,
        metadata: JSON.stringify({
          key: contentBlock.attrs.key,
        }),
      };
    }

    if (contentBlock.type === 'legacy-material') {
      return {
        type: BlockType.LegacyMaterial,
        content,
      };
    }

    if (contentBlock.type === 'legacy-paragraph') {
      return {
        type: BlockType.LegacyParagraph,
        content,
      };
    }

    if (contentBlock.type === 'step') {
      return {
        type: BlockType.Text,
        content,
      };
    }
  }

  console.warn(
    'not supported type to transform update api parameters : ',
    rootBlock
  );
  return {
    type: BlockType.Unknown,
    content,
  };
};

/**
 * Comparing to composerBlockToHtml, this function mainly used in the question title,
 * where we cannot display full information because lack of space
 */
export const persistentStringToHtml = memoize(
  (content: string | null): string => {
    if (!content) {
      return '';
    }

    const rootBlock = persistentStringToComposerBlock(content);

    if (rootBlock.type === 'text') {
      return rootBlock.text || '';
    }

    if (rootBlock.type === 'blockContainer') {
      if (rootBlock.content?.length !== 1) {
        console.warn(
          'rootBlock.content.length is not 1, which is not expected',
          rootBlock
        );
        return composerBlockToHtml(rootBlock);
      }

      return composerBlockToHtml(rootBlock);
    }

    console.warn('not supported type to transform html : ', rootBlock);
    return '';
  }
);

/**
 * Be caution, this one use dom creation to check if content is empty, so it might be heavy during large number of elements
 * @param html
 */
export const isBlockHtmlEmpty = memoize((html: string): boolean => {
  const dom = document.createElement('div');
  dom.innerHTML = html;
  const hasNoText = dom.innerText.trim() === '';
  const hasNoImage = dom.querySelector('img') === null;
  const hasNoInlineMarker =
    dom.querySelector(`.${EditorBlockTypes.InlineLineMarker}`) === null;
  const hasNoInlineHighlight =
    dom.querySelector(`.${EditorBlockTypes.InlineHighlight}`) === null;
  const hasNoVariables =
    dom.querySelector(`.${EditorBlockTypes.InlineVariable}`) === null;

  return (
    hasNoText &&
    hasNoImage &&
    hasNoInlineMarker &&
    hasNoInlineHighlight &&
    hasNoVariables
  );
});
