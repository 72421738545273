import React from 'react';

export default function TestLikeFilled({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 20H4.8C5.295 20 5.7 19.6 5.7 19.1111V11.1111C5.7 10.6222 5.295 10.2222 4.8 10.2222H3V20ZM20.847 13.6711C20.946 13.4489 21 13.2089 21 12.96V12C21 11.0222 20.19 10.2222 19.2 10.2222H14.25L15.078 6.08889C15.123 5.89333 15.096 5.68 15.006 5.50222C14.799 5.10222 14.538 4.73778 14.214 4.41778L13.8 4L8.031 9.69778C7.689 10.0356 7.5 10.4889 7.5 10.96V17.9289C7.5 19.0667 8.445 20 9.606 20H16.905C17.535 20 18.129 19.6711 18.453 19.1378L20.847 13.6711Z"
        fill="currentColor"
      />
    </svg>
  );
}
