import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import { Button } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';

import useInputEvent from '../hooks/useInputEvent';
import useSuccessState from '../hooks/useSuccessState';
import { FormLayoutSquareCheckboxGroup } from '../types';

const styles = {
  buttons: {
    display: 'flex',
    gap: 1,
  },
};
type SquareCheckboxGroupProps = {
  item: FormLayoutSquareCheckboxGroup;
};
export default function SquareCheckboxGroup({
  item,
}: SquareCheckboxGroupProps) {
  const { control } = useFormContext();

  const {
    field: { onChange, onBlur, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: item.name,
    rules: item.rules,
  });

  const successState = useSuccessState(item.name, isDirty);

  const { handleInputBlur, handleInputChange } = useInputEvent({
    name: item.name,
    actionMap: item.actionMap,
    onChange,
    onBlur,
  });

  const handleBlur = () => {
    handleInputBlur();
    void successState.handleBlur();
  };

  const handleChange = (newValue: any) => {
    void handleInputChange(newValue);
    void successState.handleChange();
  };

  const handleClick = (newValue: any) => {
    handleChange(newValue);
    handleBlur();
  };
  return (
    <Box
      className={`ia-form-layout_square-checkbox-group ${item.className || ''}`}
      sx={styles.buttons}
    >
      {item.items.map((option, i) => (
        <Button
          key={i}
          icon
          variant={
            (value ?? item.defaultValue) === option.value
              ? 'filled'
              : 'outlined'
          }
          onClick={() => handleClick(option.value)}
          size="sm"
          disabled={item.disabled}
        >
          <Icon name={option.icon} />
        </Button>
      ))}
    </Box>
  );
}
