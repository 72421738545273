import { Box, Typography } from '@mui/material';
import { SquareAvatar } from '@front/ui';
import AvatarGridFolder from '@lib/ia/src/components/AvatarGridFolder';

import { FloatingProfileExtension } from '../types';

const styles = {
  creatorContainer: {
    mt: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  creator: {
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
  },
};

type FloatingProfileExtensionProps = {
  extension?: FloatingProfileExtension;
};

export default function ProfileExtension({
  extension,
}: FloatingProfileExtensionProps) {
  if (!extension) return null;

  if (extension.type === 'club') {
    return (
      <Box sx={styles.creator}>
        <SquareAvatar src={extension.data.clubLogo} size={16}>
          {extension.data.clubName}
        </SquareAvatar>
        <Typography>Club creator</Typography>
      </Box>
    );
  }

  if (extension.type === 'clubGroup') {
    return (
      <Box sx={styles.creator}>
        {extension.data.logo ? (
          <SquareAvatar src={extension.data.logo} size={16} showStacked>
            {extension.data.name}
          </SquareAvatar>
        ) : (
          <AvatarGridFolder
            size={20}
            avatars={extension.data.subAvatars || []}
          />
        )}
        <Typography>Club group creator</Typography>
      </Box>
    );
  }

  return null;
}
