import React from 'react';

export default function EditorNumberVariable({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.71429 7V16.8389H4.09756V8.90604C3.80488 9.10738 3.48897 9.27964 3.14983 9.42282C2.81533 9.56152 2.43206 9.68456 2 9.79195V8.46309C2.26945 8.37808 2.52962 8.28859 2.78049 8.19463C3.03136 8.10067 3.27758 7.99553 3.51916 7.87919C3.76539 7.76286 4.01161 7.63311 4.25784 7.48993C4.50871 7.34676 4.76655 7.18345 5.03136 7H5.71429Z"
        fill="currentColor"
      />
      <path
        d="M14.1254 16.8389H7.85366V16.094C7.85366 15.7897 7.89779 15.5056 7.98606 15.2416C8.07433 14.9732 8.1928 14.7226 8.34146 14.4899C8.49013 14.2528 8.66434 14.0313 8.86411 13.8255C9.06388 13.6152 9.27758 13.4139 9.50523 13.2215C9.73287 13.0246 9.96516 12.8367 10.2021 12.6577C10.439 12.4743 10.6713 12.2908 10.899 12.1074C11.1127 11.9329 11.3078 11.7629 11.4843 11.5973C11.6609 11.4273 11.8118 11.2528 11.9373 11.0738C12.0627 10.8949 12.1603 10.7069 12.23 10.5101C12.2997 10.3087 12.3345 10.0917 12.3345 9.85906C12.3345 9.60403 12.2927 9.38255 12.2091 9.19463C12.1301 9.00224 12.0163 8.8434 11.8676 8.71812C11.7236 8.58837 11.5517 8.49217 11.3519 8.42953C11.1521 8.36242 10.9338 8.32886 10.6969 8.32886C10.288 8.32886 9.87224 8.42058 9.44948 8.60403C9.02671 8.78747 8.62021 9.06264 8.22996 9.42953V7.93289C8.43438 7.78523 8.64111 7.65772 8.85017 7.55034C9.05923 7.43848 9.27526 7.34676 9.49826 7.27517C9.72125 7.1991 9.95587 7.14318 10.2021 7.10738C10.4483 7.07159 10.7108 7.05369 10.9895 7.05369C11.4402 7.05369 11.849 7.11186 12.216 7.22819C12.583 7.34004 12.8966 7.50783 13.1568 7.73154C13.417 7.95078 13.6167 8.22595 13.7561 8.55705C13.9001 8.88814 13.9721 9.26846 13.9721 9.69799C13.9721 10.0872 13.921 10.4385 13.8188 10.7517C13.7213 11.0604 13.5796 11.349 13.3937 11.6174C13.2125 11.8859 12.9919 12.1409 12.7317 12.3826C12.4715 12.6242 12.1812 12.8725 11.8606 13.1275C11.5912 13.3378 11.3217 13.5436 11.0523 13.745C10.7875 13.9418 10.5482 14.1365 10.3345 14.3289C10.1208 14.5168 9.94657 14.7069 9.81185 14.8993C9.68177 15.0872 9.61672 15.2774 9.61672 15.4698V15.5034H14.1254V16.8389Z"
        fill="currentColor"
      />
      <path
        d="M15.9582 16.4832V15.0201C16.6086 15.5168 17.3426 15.7651 18.1603 15.7651C18.8246 15.7651 19.3542 15.6197 19.7491 15.3289C20.1487 15.038 20.3484 14.6443 20.3484 14.1477C20.3484 13.0559 19.482 12.5101 17.7491 12.5101H16.9756V11.2617H17.7143C19.252 11.2617 20.0209 10.7472 20.0209 9.71812C20.0209 8.76957 19.4216 8.2953 18.223 8.2953C17.554 8.2953 16.9245 8.52573 16.3345 8.98658V7.60403C17.0128 7.23714 17.8142 7.05369 18.7387 7.05369C19.626 7.05369 20.3368 7.26846 20.8711 7.69799C21.4053 8.12752 21.6725 8.67785 21.6725 9.34899C21.6725 10.6152 21.0035 11.4295 19.6655 11.7919V11.8188C20.3856 11.8859 20.9547 12.1298 21.3728 12.5503C21.7909 12.9664 22 13.4877 22 14.1141C22 14.9776 21.6632 15.6756 20.9895 16.2081C20.3159 16.736 19.4286 17 18.3275 17C17.338 17 16.5482 16.8277 15.9582 16.4832Z"
        fill="currentColor"
      />
    </svg>
  );
}
