import { alpha, Theme } from '@mui/material/styles';

/**
 * 1. We're not using this styles in HeadingBlock because HeadingBlock is not react component
 *    so we'll add this style into TextComposer
 *
 * 2. These styles are written for HeadingBlock, but in figma design these styles only apply h3
 *    we might need to see whether we need different cases for h1 and h2 or others
 */

const nodeOuter = '[data-node-type="block-outer"]';
const nodeContainer = '[data-node-type="blockContainer"]';
const content = (type: string) => `.block-content[data-content-type="${type}"]`;

const oneLineStyle = {
  mt: '0 !important',
  mb: '0 !important',
};

export const composerHeadingBlockStyle = {
  // placeholder
  [`${content('heading')}[class*=isEmpty] h3:before`]: {
    content: '"Header 3"',
    position: 'absolute',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },

  // base style
  [`${content('heading')} h3`]: {
    fontFamily: 'Ubuntu',
    typography: 'appH3',
    fontSize: '20px !important', // force mobile using same font size on editor instead of 16 which is defined in typography
    color: 'text.primary',
    mt: 0,
    mb: 1,
  },

  // if there is anything before heading, add basic margin top
  [`${nodeOuter} + ${nodeOuter}:has(${content(
    'heading'
  )}) ${nodeContainer}:has(h3)`]: {
    mt: 1,
  },

  // case of paragraph before heading
  [`${nodeOuter}:has(${content('paragraph')}) +
  ${nodeOuter}:has(${content('heading')}) ${nodeContainer}:has(h3)`]: {
    mt: 3,
  },

  // case of heading before heading
  [`${nodeOuter}:has(${content('heading')}) +
  ${nodeOuter}:has(${content('heading')}) ${nodeContainer}:has(h3)`]: {
    mt: 2,
  },

  // case of subtitle before heading
  [`${nodeOuter}:has(${content('subtitle')}) +
  ${nodeOuter}:has(${content('heading')}) ${nodeContainer}:has(h3)`]: {
    mt: 2,
  },
};

const htmlContent = (type: string) =>
  `[data-content-type="${type}"]:not(.block-content)`;

export const generatedHtmlHeadingBlockStyle = {
  // base style
  [`${htmlContent('heading')} h3`]: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'appH3' : 'examHeader3',
    fontSize: '20px !important', // force mobile using same font size on editor instead of 16 which is defined in typography
    mt: 0,
    mb: 1,

    '.render-one-line&': oneLineStyle,
  },

  // if there is anything before heading, add basic margin top
  [`* + ${htmlContent('heading')} h3`]: {
    mt: 1,

    '.render-one-line&': oneLineStyle,
  },

  // case of paragraph before heading
  [`${htmlContent('paragraph')} + ${htmlContent('heading')} h3`]: {
    mt: 3,

    '.render-one-line&': oneLineStyle,
  },

  // case of heading before heading
  [`${htmlContent('heading')} + ${htmlContent('heading')} h3`]: {
    mt: 2,

    '.render-one-line&': oneLineStyle,
  },

  // case of subtitle before heading
  [`${htmlContent('subtitle')} + ${htmlContent('heading')} h3`]: {
    mt: 2,

    '.render-one-line&': oneLineStyle,
  },

  [`.render-one-line ${htmlContent('heading')} h3`]: oneLineStyle,
};
