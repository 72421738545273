import { useMemo } from 'react';
import { useCreatorQuestionVariable, VariableType } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

import { useCurrentQuestion } from '.';

export function useGetAllVariableMaterials() {
  const { sectionId } = useCurrentIaClub();
  const { questionId } = useCurrentQuestion();

  const { data: textData } = useCreatorQuestionVariable({
    sectionId,
    creatorQuestionId: questionId,
    type: VariableType.Text,
  });

  const { data: numberData } = useCreatorQuestionVariable({
    sectionId,
    creatorQuestionId: questionId,
    type: VariableType.Number,
  });

  const { data: numberFormulaData } = useCreatorQuestionVariable({
    sectionId,
    creatorQuestionId: questionId,
    type: VariableType.NumberFormula,
  });

  const variableMaterials: {
    id: string;
    content: string;
    name: string;
  }[] = useMemo(() => {
    return ([] as GetCreatorQuestionVariableRes[])
      .concat(textData?.data.items || [])
      .concat(numberData?.data.items || [])
      .concat(numberFormulaData?.data.items || [])
      .map((variable) => ({
        id: variable.id,
        content: variable.name,
        name: variable.id,
      }));
  }, [textData, numberData, numberFormulaData]);
  return {
    variableMaterials,
  };
}
