import React from 'react';
import { Box } from '@mui/material';
import AreaHint from '@lib/ia/src/layouts/SmallIconListLayout/components/AreaHint';
import AreaIconList from '@lib/ia/src/layouts/SmallIconListLayout/components/AreaIconList';
import AreaSelectableIconList from '@lib/ia/src/layouts/SmallIconListLayout/components/AreaSelectableIconList';

import AreaDetailedIconList from './components/AreaDetailedIconList';
import { SmallIconListLayoutConfig } from './types';

const styles = {
  root: {
    pb: 2.5,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

type SmallIconListLayoutProps = SmallIconListLayoutConfig;

export default function SmallIconListLayout({
  areas,
}: SmallIconListLayoutProps) {
  return (
    <Box sx={styles.root}>
      {areas.map(({ key, ...rest }) => {
        if (rest.areaType === 'hint') {
          return <AreaHint key={key} {...rest} />;
        }
        if (rest.areaType === 'iconList') {
          return <AreaIconList key={key} {...rest} />;
        }
        if (rest.areaType === 'selectableIconList') {
          return <AreaSelectableIconList key={key} {...rest} />;
        }
        if (rest.areaType === 'detailedIconList') {
          return <AreaDetailedIconList key={key} {...rest} />;
        }
        return null;
      })}
    </Box>
  );
}
