import React from 'react';

export default function OtherOldestListed({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.0002 18H22V16H14.0002V18ZM14 6V8H22V6H14ZM14.0001 13H22V11H14.0001V13Z"
        fill="currentColor"
      />
      <path
        d="M6.39603 18.779C6.74048 19.0737 7.25952 19.0737 7.60397 18.779L11.6949 15.279C12.0701 14.9579 12.1039 14.4049 11.7704 14.0437C11.4368 13.6825 10.8622 13.65 10.4869 13.971L7.90909 16.1765V5.875C7.90909 5.39175 7.50208 5 7 5C6.49792 5 6.09091 5.39175 6.09091 5.875V16.1765L3.51306 13.971C3.13781 13.65 2.5632 13.6825 2.22964 14.0437C1.89608 14.4049 1.92988 14.9579 2.30513 15.279L6.39603 18.779Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
