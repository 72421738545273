import React from 'react';

export default function AIEditorQuestion({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#6C95E5"
        fillRule="evenodd"
      />
      <path
        d="M10.4875 11.9266C10.5731 11.6264 10.7228 11.3411 10.9368 11.0709C11.1508 10.8007 11.4477 10.5305 11.8275 10.2603C12.1912 10.002 12.4587 9.77986 12.6299 9.59371C12.8777 9.31557 13.0471 8.99569 13.0471 8.5849C13.0471 8.33269 12.9455 8.13453 12.7422 7.99042C12.539 7.8403 12.2742 7.76524 11.9478 7.76524C11.5413 7.76524 11.1 7.8463 10.6239 8.00843C10.1531 8.17056 9.65028 8.41076 9.11535 8.72901L8 6.27903C8.60448 5.88872 9.24908 5.57947 9.9338 5.35128C10.6185 5.11709 11.3648 5 12.1725 5C13.3868 5 14.3283 5.30325 14.997 5.90974C15.6657 6.51022 16 7.30286 16 8.28766C16 8.75604 15.9358 9.18538 15.8074 9.5757C15.679 9.96601 15.4758 10.3323 15.1976 10.6746C14.9194 11.0169 14.553 11.3531 14.0983 11.6834C13.7773 11.9116 13.5232 12.1067 13.336 12.2689C13.1541 12.431 13.0231 12.5871 12.9428 12.7373C12.7959 13.0319 12.8786 14.3038 12.8786 14.631H10.4067C10.4067 14.0349 10.3333 12.5033 10.4875 11.9266Z"
        fill="#080808"
      />
      <path
        d="M10.0221 17.2796C10.0221 16.6431 10.1852 16.1957 10.5115 15.9375C10.8432 15.6793 11.2444 15.5502 11.7151 15.5502C12.1645 15.5502 12.5523 15.6793 12.8786 15.9375C13.2103 16.1957 13.3761 16.6431 13.3761 17.2796C13.3761 17.8861 13.2103 18.3245 12.8786 18.5947C12.5523 18.8649 12.1645 19 11.7151 19C11.2444 19 10.8432 18.8649 10.5115 18.5947C10.1852 18.3245 10.0221 17.8861 10.0221 17.2796Z"
        fill="#080808"
      />
    </svg>
  );
}
