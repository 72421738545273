import React from 'react';
import Box from '@mui/material/Box';

import AreaAvatar from './components/AreaAvatar';
import AreaDetail from './components/AreaDetail';
import AreaIntro from './components/AreaIntro';
import { ProfileLayoutConfig } from './types';

const styles = {
  root: {
    pb: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
};

export type ProfileLayoutProps = ProfileLayoutConfig;

export default function ProfileLayout({ areas }: ProfileLayoutProps) {
  return (
    <Box sx={styles.root}>
      {areas.map(({ key, ...rest }) => {
        if (rest.areaType === 'avatar') {
          return <AreaAvatar key={key} {...rest} />;
        }
        if (rest.areaType === 'intro') {
          return <AreaIntro key={key} {...rest} />;
        }
        if (rest.areaType === 'detail') {
          return <AreaDetail key={key} {...rest} />;
        }
        return null;
      })}
    </Box>
  );
}
