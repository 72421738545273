export const enum ThreadViewType {
  DmOneOnOne = 1,
  DmGroup = 2,
  ClubAllThread = 3,
  Club = 4,
  Location = 6,
  DmEveryone = 11, // TODO: currently this is front-end only, when we need to save view setting (such as filter) into back-end, ask back-end to create this type
}

export const enum QueryThreadViewType {
  DmOneOnOne = 1,
  DmGroup = 2,
  ClubAllThread = 3,
  Club = 4,
  DmAll = 5,
  Location = 6,
}

export const enum ThreadViewStatus {
  Unknown = 0,
  Active = 1,
  Inactive = -1,
}

export const enum ThreadViewUserStatus {
  Unknown = 0,
  Active = 1,
  Inactive = -1,
}

export const enum ThreadViewSortBy {
  Asc = 'asc',
  Desc = 'desc',
}

export const enum ThreadViewOrderBy {
  CreatedAt = 'createdAt',
}
