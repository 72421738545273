import React, { MouseEvent } from 'react';
import Box from '@mui/material/Box';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import { alpha, Theme, useTheme } from '@mui/material/styles';
import { ActionCloseSmall as ActionCloseSmallIcon } from '@front/icon';

export type TagProps = {
  label: string;
  color?: 'primary' | 'error' | 'warning' | 'default' | 'yellow';
  sx?: ButtonBaseProps['sx'];
  disableRipple?: ButtonBaseProps['disableRipple'];
  onClick?: ButtonBaseProps['onClick'];
  onRemove?: () => void;
  disabled?: boolean;
  selected?: boolean;
  variant?: 'outlined' | 'filled';
  component?: React.ElementType;
};

const styles = {
  tag: {
    px: '12px',
    py: 0.5,
    minHeight: 32,
    borderRadius: 1,
    border: '1px solid',
    typography: 'body2',
    lineHeight: 1.2,
    transitionDuration: '0.2s',
    transitionProperty: 'background, color',
    textAlign: 'left',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  disabled: {
    opacity: 0.5,
  },
  hover: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  remove: {
    pr: '28px',
  },
  removeButton: {
    p: 0,
    position: 'absolute',
    right: 8,
    top: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translateY(-50%)',
    opacity: 0.5,
    cursor: 'pointer',
  },
};

const getMainColor = (
  theme: Theme,
  color: 'primary' | 'error' | 'warning' | 'default' | 'yellow'
) => {
  switch (color) {
    case 'primary':
      return theme.palette.primary.main;
    case 'error':
      return theme.palette.error.dark;
    case 'warning':
      return theme.palette.warning.light;
    case 'yellow':
      return theme.palette.highlight.yellow;
    default:
      return theme.palette.text.primary;
  }
};

const getStyles = (color: string, variant: 'filled' | 'outlined') => {
  if (variant === 'filled') {
    return {
      borderColor: 'transparent',
      bgcolor: alpha(color, 0.1),
      color: color,
    };
  }
  return {
    borderColor: color,
    color: color,
  };
};
export default function Tag({
  label,
  sx,
  color = 'default',
  selected = false,
  disabled = false,
  variant = 'outlined',
  onClick,
  onRemove,
  ...rest
}: TagProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const theme = useTheme();

  const handleRemoveClick = (ev: MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    onRemove?.();
  };
  const mainColor = getMainColor(theme, color);
  const buttonStyle = getStyles(mainColor, variant);

  return (
    <ButtonBase
      sx={[
        styles.tag,
        buttonStyle,
        !selected && !!onClick && styles.hover,
        !!onClick && disabled && styles.disabled,
        !!onRemove && styles.remove,
        selected && {
          bgcolor: mainColor,
          color: color === 'default' ? 'background.default' : '#181818',
        },
        ...sxProps,
      ]}
      disabled={(!onClick && !onRemove) || disabled}
      onClick={onClick}
      {...rest}
    >
      {label}

      {!!onRemove && (
        <Box
          sx={styles.removeButton}
          onClick={handleRemoveClick}
          component="div"
        >
          <ActionCloseSmallIcon width={16} height={16} />
        </Box>
      )}
    </ButtonBase>
  );
}
