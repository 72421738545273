import React from 'react';

export default function OtherPause({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9 5H7.5C7.10218 5 6.72064 5.1475 6.43934 5.41005C6.15804 5.6726 6 6.0287 6 6.4V17.6C6 17.9713 6.15804 18.3274 6.43934 18.5899C6.72064 18.8525 7.10218 19 7.5 19H9C9.39782 19 9.77936 18.8525 10.0607 18.5899C10.342 18.3274 10.5 17.9713 10.5 17.6V6.4C10.5 6.0287 10.342 5.6726 10.0607 5.41005C9.77936 5.1475 9.39782 5 9 5ZM16.5 5H15C14.6022 5 14.2206 5.1475 13.9393 5.41005C13.658 5.6726 13.5 6.0287 13.5 6.4V17.6C13.5 17.9713 13.658 18.3274 13.9393 18.5899C14.2206 18.8525 14.6022 19 15 19H16.5C16.8978 19 17.2794 18.8525 17.5607 18.5899C17.842 18.3274 18 17.9713 18 17.6V6.4C18 6.0287 17.842 5.6726 17.5607 5.41005C17.2794 5.1475 16.8978 5 16.5 5Z"
        fill="currentColor"
      />
    </svg>
  );
}
