import React from 'react';

export default function TradingCharting({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 5C3 4.44772 3.44772 4 4 4H20C20.5523 4 21 4.44772 21 5C21 5.55228 20.5523 6 20 6H4C3.44772 6 3 5.55228 3 5ZM19.2259 8.53822C19.5914 8.95227 19.552 9.58421 19.138 9.9497L14.1308 14.3696L10.0322 11.7384L6.15284 14.8774C5.7235 15.2248 5.09383 15.1584 4.74643 14.729C4.39902 14.2997 4.46545 13.67 4.89478 13.3226L9.89707 9.27496L13.9399 11.8704L17.8144 8.4503C18.2285 8.08481 18.8604 8.12418 19.2259 8.53822ZM3 19C3 18.4477 3.44772 18 4 18H20C20.5523 18 21 18.4477 21 19C21 19.5523 20.5523 20 20 20H4C3.44772 20 3 19.5523 3 19Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
