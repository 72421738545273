// Get the path of a node relative to root
export function getNodePath(root: HTMLDivElement, node: Node) {
  const path = [];
  while (node !== root) {
    if (node.parentNode) {
      const index = Array.prototype.indexOf.call(
        node.parentNode.childNodes,
        node
      );
      path.unshift(index);
      node = node.parentNode;
    } else {
      break;
    }
  }
  return path;
}

// Get nodes based on path
export function getNodeFromPath(root: HTMLDivElement, path: number[]) {
  let node = root as Node;
  for (let i = 0; i < path.length; i++) {
    node = node.childNodes[path[i]];
    if (!node) {
      return null;
    }
  }
  return node;
}

export function getRangeRects({
  startContainer,
  endContainer,
  startOffset,
  endOffset,
}: {
  startContainer: Node;
  endContainer: Node;
  startOffset: number;
  endOffset: number;
}) {
  const range = document.createRange();
  range.setStart(startContainer, startOffset);
  range.setEnd(endContainer, endOffset);
  return range.getClientRects();
}

export function getClosestUnTextNode(node: Node) {
  let currentNode: Node | null = node;

  while (currentNode && currentNode.nodeType === Node.TEXT_NODE) {
    currentNode = currentNode.parentNode;
  }

  return currentNode;
}

export function adjustRangeToIncludeMath(range: Range) {
  const startContainer = getClosestUnTextNode(range.startContainer);
  const endContainer = getClosestUnTextNode(range.endContainer);

  if (startContainer && startContainer.nodeType === Node.ELEMENT_NODE) {
    const mathStartContainer = (startContainer as Element).closest(
      '[data-content-type="inline-latex"]'
    );
    if (mathStartContainer) {
      range.setStartBefore(mathStartContainer);
    }
  }

  if (endContainer && endContainer.nodeType === Node.ELEMENT_NODE) {
    const mathEndContainer = (endContainer as Element).closest(
      '[data-content-type="inline-latex"]'
    );
    if (mathEndContainer) {
      range.setEndAfter(mathEndContainer);
    }
  }
}
