import { textColorStyles } from '@lib/web/composer/TextComposer/extensions/TextColor';

import {
  composerHeadingBlockStyle,
  generatedHtmlHeadingBlockStyle,
} from '../components/blocks/HeadingBlock';
import { generatedHtmlLegacyMaterialBlockStyle } from '../components/blocks/LegacyMaterialBlock/styles';
import {
  composerLegacyParagraphBlockStyle,
  generatedHtmlLegacyParagraphBlockStyle,
} from '../components/blocks/LegacyParagraphBlock/styles';
import {
  composerParagraphBlockStyle,
  generatedHtmlParagraphBlockStyle,
} from '../components/blocks/ParagraphBlock';
import {
  composerStepBlockStyle,
  generatedHtmlStepBlockStyle,
} from '../components/blocks/StepBlock';
import {
  composerSubtitleBlockStyle,
  generatedHtmlSubtitleBlockStyle,
} from '../components/blocks/SubtitleBlock';

export const basicBlockEditingStyles = [
  composerHeadingBlockStyle,
  composerSubtitleBlockStyle(),
  composerParagraphBlockStyle(),
  composerLegacyParagraphBlockStyle,
  composerStepBlockStyle(),
  textColorStyles(),
];

export const basicBlockHtmlStyles = [
  generatedHtmlHeadingBlockStyle,
  generatedHtmlSubtitleBlockStyle(),
  generatedHtmlParagraphBlockStyle(),
  generatedHtmlLegacyParagraphBlockStyle,
  generatedHtmlLegacyMaterialBlockStyle(),
  generatedHtmlStepBlockStyle(),
  textColorStyles(),
];
