import React, { ReactNode } from 'react';
import { IaTableButtonsRowBorder } from '@lib/ia/src/layouts/TableLayout/components/IaTableCellBorder/IaTableCellBorder';

import { useIaAction } from '../../../core/IaAction/useIaAction';
import { TableLayoutConfig } from '../types';

import IaTableRow from './IaTableRow/IaTableRow';
import DetectUseInfiniteScroll from './DetectUseInfiniteScroll';
import {
  IaTableActionRow,
  IaTableEmptyRow,
  IaTableLoadMoreRow,
} from './IaTableRow';

export type IaTableActionRowsProps = {
  data: TableLayoutConfig['data'];
  settings: TableLayoutConfig['settings'];
  hasMore: boolean;
  loadMore: () => void;
};

export default function IaTableActionRows({
  data,
  settings,
  hasMore,
  loadMore,
}: IaTableActionRowsProps) {
  const { getIaAction } = useIaAction();
  const disableLoadMore = data.state === 'loading' || !hasMore;
  const enableInfiniteScroll = !settings.bottomButtons; // from design aspect, if there are bottomButtons, we don't want user need to scroll to the end and click those buttons

  const tableButtons: ReactNode[] = [];

  if (data.state === 'empty') {
    tableButtons.push(<IaTableEmptyRow text={data.emptyRow.text} />);
  }

  if (!enableInfiniteScroll && !disableLoadMore && data.loadMoreRow) {
    tableButtons.push(
      <IaTableLoadMoreRow text={data.loadMoreRow.text} onClick={loadMore} />
    );
  }

  if (settings.bottomButtons) {
    const buttons = Object.values(settings.bottomButtons);
    buttons.forEach((button) => {
      if (!button) return;
      tableButtons.push(
        <IaTableActionRow
          text={button.text}
          icon={button.icon}
          onClick={() => getIaAction(button.action)?.action()}
          sx={button.sx}
          disabled={button.disabled}
          disabledAfterClick={button.disabledAfterClick}
          tooltip={button.tooltip}
        />
      );
    });
  }

  return (
    <>
      {enableInfiniteScroll && !disableLoadMore && (
        <DetectUseInfiniteScroll loadMore={loadMore} />
      )}

      {tableButtons.map((button, index) =>
        index !== tableButtons.length - 1 ? (
          <IaTableRow key={index}>
            <IaTableButtonsRowBorder
              key={index}
              layoutSetting={settings.layoutSetting}
            >
              {button}
            </IaTableButtonsRowBorder>
          </IaTableRow>
        ) : (
          <IaTableRow key={index}>{button}</IaTableRow>
        )
      )}
    </>
  );
}
