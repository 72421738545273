import React from 'react';

export default function EditorRubric({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M5.8 13.4129V10.2998H11.2V13.1029C11.8066 13.0149 12.4333 13.1023 13 13.3652L13 10.2998H18.2316L18.5394 9.98107C19.0085 9.49525 19.5898 9.1899 20.2 9.06493V5.8C20.2 4.80589 19.3941 4 18.4 4H5.8C4.80589 4 4 4.80589 4 5.8V18.4C4 19.3941 4.80589 20.2 5.8 20.2H10.8782L9.35558 18.6209C9.28682 18.5495 9.22192 18.4758 9.16089 18.4H5.8V15.2129H8.63328C8.78535 14.791 9.026 14.3945 9.35524 14.0529C9.61379 13.7847 9.90653 13.5713 10.2195 13.4129H5.8Z"
        fill="currentColor"
      />
      <path
        d="M15.2255 13.4129H13.0984C13.4112 13.5713 13.7038 13.7845 13.9623 14.0526L14.2848 14.3871L15.2255 13.4129Z"
        fill="currentColor"
      />
      <path
        d="M21.7055 11.3718C21.2288 10.8766 20.4555 10.876 19.9781 11.3704L14.2837 17.2673L12.5225 15.4408C12.0455 14.9461 11.2721 14.9461 10.7952 15.4409C10.3182 15.9357 10.3183 16.7379 10.7953 17.2326L13.2415 19.7696C13.3126 19.8433 13.3902 19.906 13.4724 19.9577C13.9509 20.4232 14.7027 20.414 15.1704 19.9296L21.7041 13.1635C22.1814 12.6691 22.1821 11.867 21.7055 11.3718Z"
        fill="currentColor"
      />
    </svg>
  );
}
