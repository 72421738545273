import { TableLayoutCell } from './types';

export const isCellEditable = (cell?: TableLayoutCell) => {
  if (!cell) return false;
  if (
    cell.type === 'status' ||
    cell.type === 'autoComplete' ||
    cell.type === 'select'
  ) {
    return true;
  }
  if ('editable' in cell) {
    return !!cell.editable;
  }
  return false;
};
