import { alpha, Box, Theme, Typography } from '@mui/material';

import { Icon, MaskIcon } from '../../../../components';

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    gap: 1,
    alignItems: 'start',
    px: '12px',
    py: 2,
    transition: 'background-color 100ms linear',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
  title: {
    fontSize: 16,
    lineHeight: 1,
    fontWeight: 700,
  },
  main: {
    display: 'grid',
    gap: 0.5,
  },
  content: {},
};

export type PayoffMessageProps = {
  icon: string;
  title: string;
  content: string;
};

export default function PayoffMessage({
  icon,
  title,
  content,
}: PayoffMessageProps) {
  return (
    <Box sx={styles.root} className="payoff-message">
      <MaskIcon>
        <Box sx={styles.icon}>
          <Icon name={icon} width={16} height={16} />
        </Box>
      </MaskIcon>
      <Box sx={styles.main}>
        <Typography sx={styles.title}>{title}</Typography>
        <Typography variant="chatBody" sx={styles.content}>
          {content}
        </Typography>
      </Box>
    </Box>
  );
}
