import React from 'react';

export default function ActionArrowLeftDown({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18 19V17H9.41L19 7.41L17.59 6L8 15.59V7H6V19H18Z"
        fill="currentColor"
      />
    </svg>
  );
}
