import React from 'react';

export default function OtherScholarship({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.1729 12C14.9649 12 13.1729 13.792 13.1729 16C13.1729 18.208 14.9649 20 17.1729 20C19.3809 20 21.1729 18.208 21.1729 16C21.1729 13.792 19.3809 12 17.1729 12ZM17.5249 18.304V18.8H16.8249V18.284C16.5289 18.212 15.8689 17.976 15.6169 17.1L16.2769 16.832C16.3009 16.92 16.5089 17.668 17.2369 17.668C17.6089 17.668 18.0289 17.476 18.0289 17.024C18.0289 16.64 17.7489 16.44 17.1169 16.212C16.6769 16.056 15.7769 15.8 15.7769 14.888C15.7769 14.848 15.7809 13.928 16.8249 13.704V13.2H17.5249V13.696C18.2609 13.824 18.5289 14.412 18.5889 14.588L17.9569 14.856C17.9129 14.716 17.7209 14.32 17.1969 14.32C16.9169 14.32 16.4729 14.468 16.4729 14.876C16.4729 15.256 16.8169 15.4 17.5289 15.636C18.4889 15.968 18.7329 16.456 18.7329 17.016C18.7329 18.068 17.7329 18.268 17.5249 18.304Z"
        fill="currentColor"
      />
      <path
        d="M11.831 4.03099L2.32547 7.62973C1.90058 7.7906 1.88878 8.41175 2.30723 8.58998L11.8128 12.6386C11.9328 12.6897 12.0672 12.6897 12.1872 12.6386L14.3246 11.7282C14.9388 11.2885 15.6514 10.9774 16.4231 10.8344L21.6928 8.58998C22.1112 8.41175 22.0994 7.7906 21.6745 7.62973L12.169 4.03099C12.0598 3.98967 11.9402 3.98967 11.831 4.03099Z"
        fill="currentColor"
      />
      <path
        d="M21.8889 13.2707C21.6184 12.8285 21.2846 12.4292 20.9 12.0855V10.1444C20.9 9.86009 21.1213 9.62961 21.3944 9.62961C21.6675 9.62961 21.8889 9.86009 21.8889 10.1444V13.2707Z"
        fill="currentColor"
      />
      <path
        d="M12.5026 14.0384C12.2551 14.6492 12.1188 15.3169 12.1188 16.0165C12.1188 16.7685 12.2763 17.4837 12.5601 18.131L12.1948 18.294C12.0704 18.3495 11.9296 18.3495 11.8052 18.294L5.37747 15.4259C5.19567 15.3448 5.07779 15.1587 5.07779 14.9528V11.9546C5.07779 11.5851 5.44074 11.3359 5.76701 11.4815L11.8052 14.1757C11.9296 14.2312 12.0704 14.2312 12.1948 14.1757L12.5026 14.0384Z"
        fill="currentColor"
      />
    </svg>
  );
}
