import React from 'react';
import Box from '@mui/material/Box';
import { ChannelLayoutLoadingConfig } from '@lib/ia/src/layouts/ChannelLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { ThreadComposerProvider } from '@lib/web/thread';
import ThreadComposer from '@lib/web/thread/ThreadComposer';

const styles = {
  container: {
    display: 'grid',
    gridTemplateRows: '1fr max-content',
    height: '100%',
  },
  messageList: {
    minHeight: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
};

export default function ThreadChatSkeleton() {
  const config = [
    {
      layout: 'channel-layout-skeleton',
    } as ChannelLayoutLoadingConfig,
  ];

  return (
    <Box sx={styles.container}>
      <Box sx={styles.messageList}>
        <IaLayouts layouts={config} />
      </Box>
      <ThreadComposerProvider
        text=""
        setText={() => {}}
        sendPubliclyCheckboxEnable={false}
      >
        <ThreadComposer disabled />
      </ThreadComposerProvider>
    </Box>
  );
}
