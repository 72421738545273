import React from 'react';

export default function ActionNewTab({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.6665 12.0001V18.6665H5.33342V5.33342H11.9998V4H5.33342C4.60017 4 4 4.60003 4 5.33342V18.6669C4 19.3998 4.60017 20 5.33342 20H18.6665C19.3998 20 19.9999 19.3998 19.9999 18.6666V12.0002L18.6665 12.0001Z"
        fill="currentColor"
      />
      <path
        d="M19.9998 9.33372L19.9993 4.66662C19.9998 4.29873 19.701 4.0002 19.3333 4.0002H14.6665V5.33318H17.7232L12 11.0571L12.9428 12L18.6667 6.27717V9.33336L19.9998 9.33372Z"
        fill="currentColor"
      />
    </svg>
  );
}
