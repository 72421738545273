export const enum AhaPointActionType {
  Earn = 1,
  Use = 2,
}
export enum AhaPointSourceType {
  EarnRefer = 11,
  EarnByAvatar = 12,
  EarnDiscountCode = 13,
  EarnPhoneVerify = 14,

  UsePaidAvatar = 21,
  UsePaidSubscription = 22,
}
