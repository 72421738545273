import { TableLayoutHeadSimpleCell } from '../../types';

import { IaHeadDefaultCell, IaHeadIconTextCell } from './index';

export type IaTableHeadSimpleCellProps = TableLayoutHeadSimpleCell;

export default function IaTableHeadSimpleCell(
  headSimpleCell: IaTableHeadSimpleCellProps
) {
  switch (headSimpleCell.type) {
    case 'default':
      return <IaHeadDefaultCell {...headSimpleCell} />;
    case 'iconText':
      return <IaHeadIconTextCell {...headSimpleCell} />;
    default:
      console.warn('Unknown head cell type:', headSimpleCell);
      return null;
  }
}
