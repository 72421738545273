// eslint-disable-next-line import/prefer-default-export
export const enum SanityProductType {
  Available = 1,
  Unavailable = -1,
  ComingSoon = 2,
}

export const enum SanityProductSortType {
  LatestDateListed = 1,
  OldestDateListed = 2,
  HighestPrice = 3,
  LowestPrice = 4,
}

export const enum MyOwnProductSource {
  Unknown = 0,
  AhaMarketplace = 1,
}

export const enum MyOwnProductStatus {
  Unknown = 0,
  Active = 1,
  Inactive = -1,
}

export const enum ProductOrderStatus {
  Unknown = 0,
  Open = 1,
  Completed = 2,
  Expired = -1,
  Error = -2,
}

export const enum ProductOrderDiscountType {
  Unknown = 0,
  DiscountCode = 1,
  AhaPoint = 2,
}

export const enum PromoCodeQueryType {
  Available = 1,
  UsedOrInvalid = 2,
}

export const enum PromoCodeType {
  SiteWideDiscountCode = 1,
  UserDiscountCode = 2,
  CampaignDiscountCode = 3,
}

export const enum WalletActionType {
  Earn = 1,
  Use = 2,
  Add = 3,
  Spend = 4,
  Claim = 6,
  Burn = 7,
}

export const enum WalletSourceType {
  EarnRefer = 11,
  EarnBuyAvatar = 12,
  EarnDiscountCode = 13,
  UsePaidAvatar = 21,
  UsePaidSubscription = 22,
  AddAllocate = 31,
  MonthlyBurn = 71,
  SpendAgentChat = 41,
  ClaimAgentChat = 61,
}

export const enum DiscountCodeDiscountType {
  Unknown = 0,
  PercentOff = 1,
  AmountOff = 2,
}

export const enum DiscountCodePurchaseType {
  All = 0,
  AvatarOnly = 1,
  SubscriptionOnly = 2,
}

export const enum DiscountCodeDeployStatus {
  Unknown = 0,
  Active = 1,
  Scheduled = 2,
  Expired = -1,
}

export const enum DiscountCodeStatus {
  Unknown = 0,
  Active = 1,
  Inactive = -1,
  Deleted = -4,
}

export const enum CampaignDiscountCodeViewSlug {
  TableCenterIaCampaignDiscountCodeDefault = 'table_center_ia-campaigndiscountcode_default',
}
