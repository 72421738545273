import { getBlockInfoFromPos, getIsCursorOnLastBlock } from '@lib/web/composer';
import { Node } from '@tiptap/core';

/**
 * This "Delete" handler is copied and modified from
 * @blocknote/core/src/extensions/Blocks/nodes/BlockContainer.ts in @blocknote/core@0.9.6.
 * In this version, deleting at the start of a block will cause the application to crash with the following error:
 *
 *      TypeError: Cannot read properties of null (reading 'nodeSize')
 *      > 27 |   contentNode.firstChild!.nodeSize;
 *
 * This issue was fixed in a later version, but it is currently difficult to upgrade to that version.
 * (https://github.com/TypeCellOS/BlockNote/commit/ed772f12f907364020ad4fd52093f989cf683d12)
 * Therefore, we are attempting to solve it ourselves.
 */
const PatchBlockContainerDelete = Node.create({
  name: 'patch-block-container-delete',
  addKeyboardShortcuts() {
    return {
      Delete: () =>
        this.editor.commands.first(({ commands }) => [
          // Deletes the selection if it's not empty.
          () => commands.deleteSelection(),
          () => getIsCursorOnLastBlock(this.editor),
          () => {
            const nextBlockPos = this.editor.state.selection.$from.pos + 2;
            const nextBlockInfo = getBlockInfoFromPos(
              this.editor.state.doc,
              nextBlockPos
            );

            if (nextBlockInfo.contentType.name === 'image') return true; // Prevent deletion of the image block
            return false;
          },
          // Merges block with the next one (at the same nesting level or lower),
          // if one exists, the block has no children, and the selection is at the
          // end of the block.
          () =>
            commands.command(({ state }) => {
              const { node, contentNode, depth, endPos } = getBlockInfoFromPos(
                state.doc,
                state.selection.from
              );

              const blockAtDocEnd = false;
              const selectionAtBlockEnd =
                !contentNode.firstChild?.nodeSize ||
                state.selection.$anchor.parentOffset ===
                  contentNode.firstChild.nodeSize;
              const selectionEmpty =
                state.selection.anchor === state.selection.head;
              const hasChildBlocks = node.childCount === 2;

              if (
                !blockAtDocEnd &&
                selectionAtBlockEnd &&
                selectionEmpty &&
                !hasChildBlocks
              ) {
                let oldDepth = depth;
                let newPos = endPos + 2;
                let newDepth = state.doc.resolve(newPos).depth;

                while (newDepth < oldDepth) {
                  oldDepth = newDepth;
                  newPos += 2;
                  newDepth = state.doc.resolve(newPos).depth;
                }

                return commands.BNMergeBlocks(newPos - 1);
              }

              return false;
            }),
          () => {
            // Because we are preventing default behavior, we need to handle the normal delete operation here
            commands.deleteRange({
              from: this.editor.state.selection.head,
              to: this.editor.state.selection.head + 1,
            });

            return true; // return 'true' to prevent the execution of the actual 'BlockContainer' Delete handler
          },
        ]),
    };
  },
});

export default PatchBlockContainerDelete;
