import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Fade, Portal, useMediaQuery, useTheme } from '@mui/material';
import { useDimension } from '@front/helper';
import { OtherDelete as OtherDeleteIcon } from '@front/icon';

import { SelectionData } from '../types';
import { getNodeFromPath, getRangeRects } from '../utils';

const styles = {
  toolbar: {
    position: 'fixed',
    zIndex: 'modal',
  },
  toolbarInner: {
    px: { xs: '8.5px', md: '12px' },
    height: { xs: 45, md: 28 },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 0.5,
    bgcolor: '#151A28',
    borderRadius: 1,
    border: '1px solid',
    borderColor: '#343742',
  },
  triangle: {
    position: 'absolute',
    bottom: 1,
    '&:after, &:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
    },
    '&:before': {
      bottom: '-8px',
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderTop: '8px solid #343742',
    },
    '&:after': {
      bottom: '-7px',
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderTop: '7px solid #151A28',
    },
  },
  button: {
    bgcolor: 'transparent',
    p: 0,
    color: 'white',
    border: 0,
    width: { xs: 28, md: 20 },
    height: { xs: 28, md: 20 },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '50%',
    span: {
      minWidth: { xs: 20, md: 15 },
      minHeight: { xs: 20, md: 15 },
      borderRadius: '50%',
    },
  },
  selected: {
    boxShadow: `0px 0px 0px 1px #fff inset`,
  },
};
const SPACING = 12;

type HighlightToolbarProps = {
  data: SelectionData;
  targetAnchorEl: HTMLDivElement;
  onColorClick: (color: string) => void;
  onRemove: () => void;
};
function HighlightToolbar({
  data,
  targetAnchorEl,
  onColorClick,
  onRemove,
}: HighlightToolbarProps) {
  const boxRef = useRef<HTMLDivElement>();
  const { width: boxWidth, height: boxHeight } = useDimension(boxRef);

  const [clientRect, setClientRect] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const handleResize = useCallback(() => {
    if (targetAnchorEl) {
      const startContainer = getNodeFromPath(targetAnchorEl, data.startPath);
      const endContainer = getNodeFromPath(targetAnchorEl, data.endPath);
      const startOffset = data.startOffset;
      const endOffset = data.endOffset;

      if (startContainer && endContainer) {
        const rects = getRangeRects({
          startContainer,
          endContainer,
          startOffset,
          endOffset,
        });

        if (rects.length) {
          const left = rects[0].left;
          const top = rects[0].top;
          let width = rects[0].width;

          for (let i = 1; i < rects.length; i++) {
            const rect = rects[i];

            if (top === rect.top) {
              width += rect.width;
            } else {
              break;
            }
          }

          const rootRect = targetAnchorEl.getBoundingClientRect();
          width = Math.min(rootRect.width, width);

          setClientRect({
            top,
            left: left + width / 2,
          });
          return;
        }
      }
    }
    setClientRect(null);
  }, [data, targetAnchorEl]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handleColorClick = (ev: MouseEvent, color: string) => {
    ev.stopPropagation();
    onColorClick(color);
  };

  const handleRemove = (ev: MouseEvent) => {
    ev.stopPropagation();
    onRemove();
  };

  const toolbarStyles = useMemo(() => {
    if (!clientRect || !boxWidth) return {};

    const rectLeft = clientRect.left - boxWidth / 2;
    const rectTop = clientRect.top - boxHeight - SPACING;

    let left = rectLeft;
    let top = rectTop;
    if (left < SPACING) left = SPACING;
    else if (left + boxWidth > window.innerWidth)
      left = window.innerWidth - boxWidth - SPACING;

    if (top < SPACING) top = SPACING;
    else if (top + boxHeight > window.innerHeight)
      top = window.innerHeight - boxHeight - SPACING;

    const leftOffset = rectLeft - left;

    return {
      top,
      left,

      '& .triangle': {
        left: boxWidth / 2 + leftOffset,
      },
    };
  }, [clientRect, boxWidth, boxHeight]);

  return (
    <Fade in={!!clientRect && !!boxWidth}>
      <Box ref={boxRef} sx={[styles.toolbar, toolbarStyles]}>
        <Box sx={styles.toolbarInner}>
          <Box className="triangle" sx={styles.triangle} />
          {Object.values(theme.palette.highlight).map((color) => (
            <Box
              key={color}
              sx={[
                styles.button,
                data.color === color && {
                  boxShadow: `0px 0px 0px 1px ${color} inset`,
                },
              ]}
              type="button"
              component="button"
              onMouseDown={(ev) => handleColorClick(ev, color)}
            >
              <Box sx={{ bgcolor: color }} component="span" />
            </Box>
          ))}
          {!!data.color && (
            <Box
              sx={styles.button}
              type="button"
              component="button"
              onMouseDown={handleRemove}
            >
              <OtherDeleteIcon width={mdUp ? 16 : 20} height={mdUp ? 16 : 20} />
            </Box>
          )}
        </Box>
      </Box>
    </Fade>
  );
}

export default function HighlightToolbarRoot({
  ...rest
}: HighlightToolbarProps) {
  return (
    <Portal>
      <HighlightToolbar {...rest} />
    </Portal>
  );
}
