export function getStorageItem<T>(key: string, initialValue: T) {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
}

export function setStorageItem<T>(key: string, value: T) {
  const storedValue = getStorageItem(key, value);
  try {
    const valueToStore = (
      value instanceof Function ? value(storedValue) : value
    ) as T;

    window.localStorage.setItem(key, JSON.stringify(valueToStore));
    return valueToStore;
  } catch (error) {
    // console.log(error);
  }
  return storedValue;
}
