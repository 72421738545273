import { createContext, Dispatch, SetStateAction } from 'react';

/**
 * we use @dnd-kit to implement sortable feature,
 * but it lack of global state, it only provides per item state
 * so we create one global context for this purpose
 */

export type GlobalSortableValue = {
  isDragging: boolean;
  draggingId: string | null;
};
export const defaultValue: GlobalSortableValue = {
  isDragging: false,
  draggingId: null,
};

type GlobalSortableContextValue = [
  GlobalSortableValue,
  Dispatch<SetStateAction<GlobalSortableValue>>
];

export const GlobalSortableContext = createContext<GlobalSortableContextValue>([
  defaultValue,
  () => {},
]);
