import { useEffect } from 'react';
import { WatchingView } from '@lib/web/thread';

import { useThread } from './useThread';

export const useAddWatchingView = (watchingView: WatchingView) => {
  const { watchingViewsRef } = useThread();

  useEffect(() => {
    watchingViewsRef.current = [
      ...watchingViewsRef.current.filter(
        (v) => !(v.scope === watchingView.scope && v.key === watchingView.key)
      ),
      watchingView,
    ];
  }, [watchingView, watchingViewsRef]);
};
