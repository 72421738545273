import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IaColorVariant } from '@lib/ia/src/core/types';

import Icon from '../../../../components/Icon';
import { DisplayTableLayoutIconCell } from '../../types';

export type IaDisplayTableIconCellProps = DisplayTableLayoutIconCell;

const colorMap: Record<IaColorVariant, string> = {
  default: 'grey.500',
  success: 'success.light',
  error: 'error.dark',
  info: 'primary.light',
  warning: 'warning.light',
  text: 'text.primary',
  yellow: 'highlight.yellow',
  orange: 'highlight.orange',
  red: 'highlight.red',
  green: 'highlight.green',
  blue: 'highlight.blue',
};

const styles = {
  root: {
    height: '100%',
    display: 'grid',
    alignItems: 'center',
  },
  icon: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    display: 'grid',
    alignItems: 'center',
  },
  iconButton: {
    fontSize: 16,
  },
  disabled: {
    opacity: 0.5,
  },
};
export default function IaDisplayTableIconCell({
  icon,
  emoji,
  color,
  iconSize = 16,
  iconSxProps,
}: IaDisplayTableIconCellProps) {
  const iconSx = Array.isArray(iconSxProps) ? iconSxProps : [iconSxProps];

  if (icon && emoji) {
    console.warn('Both icon and emoji are provided, only icon will be used');
  }

  const iconColor =
    color && color in colorMap ? colorMap[color as IaColorVariant] : color;

  return (
    <Box sx={[styles.root]}>
      <Typography
        variant="body2"
        sx={[styles.icon, !!color && { color: iconColor }, ...iconSx]}
      >
        {icon ? (
          <Icon name={icon} width={iconSize} height={iconSize} />
        ) : emoji ? (
          emoji
        ) : null}
      </Typography>
    </Box>
  );
}
