import React, { MouseEvent, useState } from 'react';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import {
  OtherDocument as OtherDocumentIcon,
  TestExport as TestExportIcon,
} from '@front/icon';
import { LoadingIcon } from '@front/ui';
import { useFileBlockMoreActions } from '@lib/web/composer/TextComposer/components/SideMenu/hooks/useFileBlockMoreActions';

const styles = {
  fileContainer: {
    display: 'flex',
    padding: '3.5px 0px',
    alignItems: 'center',

    svg: {
      flexShrink: 0,
    },

    '.file-name': {
      pl: '8px',
      color: 'text.primary',
      typography: 'body2',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.file-size': {
      pl: '4px',
      pt: '2px',
      color: 'alpha.lightA30',
      typography: 'caption',
      flexShrink: 0,
    },
    '.file-download-icon': {
      height: '21px',
      ml: 'auto',
      pl: '4px',
      display: { sm: 'inline-flex', md: 'none' },
      alignItems: 'center',
    },
    '.file-downloading-icon': {
      height: '21px',
      ml: 'auto',
      pl: '4px',
      display: 'inline-flex',
      alignItems: 'center',
    },

    borderRadius: '4px',

    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover': {
        background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),

        '.file-download-icon': {
          display: 'inline-flex',
        },
      },
    },
  },
};

export type FileBlockRendererProps = {
  filename: string;
  fileKey: string;
  fileSize: number;
  fileType: string;
};

export default function FileBlockRender({
  filename,
  fileKey,
  fileSize,
  fileType,
}: FileBlockRendererProps) {
  const [downloading, setDownloading] = useState(false);
  const { download } = useFileBlockMoreActions({
    fileKey,
    filename,
    fileType,
  });

  const handleDownloadFileClick = async (ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setDownloading(true);
    await download();
    setDownloading(false);
  };

  return (
    <Box
      sx={styles.fileContainer}
      onClick={handleDownloadFileClick}
      data-content-type="file"
    >
      <OtherDocumentIcon width={16} height={16} />

      <span className="file-name">{filename}</span>
      <span className="file-size">{(fileSize / 1024).toFixed(1)}KB</span>
      {downloading ? (
        <span className="file-downloading-icon">
          <LoadingIcon />
        </span>
      ) : (
        <span className="file-download-icon">
          <TestExportIcon width={16} height={16} />
        </span>
      )}
    </Box>
  );
}
