import React from 'react';

export default function OtherTransferVertical({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.0794 3.09791C14.5598 2.8989 15.1129 3.0089 15.4806 3.37661L20.6234 8.51945C21.1255 9.02155 21.1255 9.83562 20.6234 10.3377C20.1213 10.8398 19.3073 10.8398 18.8051 10.3377L15.8571 7.38972L15.8571 19.7143C15.8571 20.4243 15.2815 21 14.5714 21C13.8613 21 13.2857 20.4243 13.2857 19.7143L13.2857 4.28575C13.2857 3.76573 13.599 3.29691 14.0794 3.09791ZM9.42857 3.00004C10.1387 3.00004 10.7143 3.57567 10.7143 4.28575L10.7143 19.7143C10.7143 20.2343 10.401 20.7031 9.92059 20.9021C9.44015 21.1011 8.88715 20.9911 8.51943 20.6234L3.37658 15.4806C2.87447 14.9785 2.87447 14.1644 3.37658 13.6623C3.87868 13.1602 4.69275 13.1602 5.19485 13.6623L8.14286 16.6103L8.14286 4.28575C8.14286 3.57567 8.71849 3.00004 9.42857 3.00004Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
