import axios from 'axios';
import { UploadResourceType } from '@lib/web/apis';

const fileApi = {
  getAhaEditorImageUploadUrl: (
    slug: string,
    ext: string
  ): Promise<Response<GetMemberUploadUrlRes>> => {
    const params = new URLSearchParams();
    params.set('resourceType', UploadResourceType.AhaEditorImage);
    params.set('scope', slug);
    params.set('ext', ext);

    return axios.get(`/api/file/member-upload-url?${params.toString()}`);
  },
  getAhaEditorAudioUploadUrl: (
    scope: string,
    sub: string,
    ext: string
  ): Promise<Response<GetMemberUploadUrlRes>> => {
    const params = new URLSearchParams();
    params.set('resourceType', UploadResourceType.AhaAudio);
    params.set('scope', scope);
    params.set('sub', sub);
    params.set('ext', ext);

    return axios.get(`/api/file/member-upload-url?${params.toString()}`);
  },
  getAhaThreadImageUploadUrl: (
    ext: string
  ): Promise<Response<GetMemberUploadUrlRes>> => {
    const params = new URLSearchParams();
    params.set('resourceType', UploadResourceType.AhaImages);
    params.set('scope', 'thread');
    params.set('ext', ext);

    return axios.get(`/api/file/member-upload-url?${params.toString()}`);
  },
  getAhaFileUploadUrl: (
    ext: string
  ): Promise<Response<GetMemberUploadUrlRes>> => {
    const params = new URLSearchParams();
    params.set('resourceType', UploadResourceType.AhaFile);
    params.set('scope', 'thread');
    params.set('ext', ext);

    return axios.get(`/api/file/member-upload-url?${params.toString()}`);
  },
  getSignedUrl: (resourceType: UploadResourceType, key: string) => {
    const params = new URLSearchParams();
    params.set('bucket', resourceType);
    params.set('key', key);
    return axios.get<Response<GetSignedUrlRes>>(
      `/api/file/signed-url?${params.toString()}`
    );
  },
};

export default fileApi;
