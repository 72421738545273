import React from 'react';

import MultipleResponse from './components/MultipleResponse';
import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export type EditorAnswerMultipleResponseProps = {
  questionId: string;
  readonly?: boolean;
};

export default function EditorAnswerMultipleResponse({
  questionId,
  readonly,
}: EditorAnswerMultipleResponseProps) {
  const { handleSelect, handleBlocksChange } = useEventHandle(questionId);
  const {
    responseValues,
    mrqMinCorrectAnswerNum,
    mrqMaxCorrectAnswerNum,
    answerComponents,
  } = useData(questionId);

  return (
    <MultipleResponse
      questionId={questionId}
      responseValues={responseValues}
      onSelect={handleSelect}
      handleBlocksChange={(id, blocks) => {
        const component = answerComponents.find((c) => c.id === id);
        if (component) {
          void handleBlocksChange(component, blocks);
        }
      }}
      mrqMinCorrectAnswerNum={mrqMinCorrectAnswerNum}
      mrqMaxCorrectAnswerNum={mrqMaxCorrectAnswerNum}
      readonly={readonly}
    />
  );
}
