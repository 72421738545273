import React from 'react';

export default function EmojiAnimal({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 19.9331C11.0385 19.9331 10.1328 19.7762 9.283 19.4624C8.43317 19.1485 7.66108 18.7083 6.96675 18.1416L5.46675 19.6166C5.30558 19.7776 5.11392 19.8581 4.89175 19.8581C4.66942 19.8581 4.47775 19.7776 4.31675 19.6166C4.15558 19.4554 4.075 19.2638 4.075 19.0416C4.075 18.8193 4.15558 18.6276 4.31675 18.4666L5.79175 16.9749C5.22125 16.2785 4.78 15.5059 4.468 14.6569C4.156 13.8077 4 12.8998 4 11.9331C4 9.69977 4.775 7.80811 6.325 6.25811C7.875 4.70811 9.76667 3.93311 12 3.93311H20V11.9331C20 14.1664 19.225 16.0581 17.675 17.6081C16.125 19.1581 14.2333 19.9331 12 19.9331ZM12 18.2666C13.7593 18.2666 15.2547 17.6509 16.486 16.4194C17.7175 15.1879 18.3333 13.6924 18.3333 11.9331V5.59986H12C10.2407 5.59986 8.74533 6.21561 7.514 7.44711C6.2825 8.67861 5.66675 10.1739 5.66675 11.9331C5.66675 12.6579 5.78342 13.3503 6.01675 14.0101C6.25008 14.6699 6.5695 15.2638 6.975 15.7916L12.3168 10.4666C12.4778 10.3054 12.6694 10.2249 12.8917 10.2249C13.1139 10.2249 13.3056 10.3054 13.4668 10.4666C13.6334 10.6309 13.7168 10.8262 13.7168 11.0524C13.7168 11.2785 13.6334 11.4749 13.4668 11.6416L8.14175 16.9666C8.66942 17.3721 9.26317 17.6901 9.923 17.9206C10.5828 18.1513 11.2752 18.2666 12 18.2666Z"
        fill="currentColor"
      />
    </svg>
  );
}
