import type { AxiosPromise } from 'axios';

import api from './client';

const examsApi = {
  getExamSections: (slug: string): AxiosPromise<Response<GetExamSectionsRes>> =>
    api.get(`v2/exam/section?slug=${slug}`),
};

export default examsApi;
