import React from 'react';

export default function ProfileBackpack({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.9661 3C10.2412 3 9.65356 3.60892 9.65356 4.36007V4.87434C6.98151 5.55714 5 8.05728 5 11.037V20.1866C5 21.1881 5.7835 22 6.75 22H7.1875V14.7873C7.1875 13.5354 8.16688 12.5205 9.375 12.5205H14.625C15.8331 12.5205 16.8125 13.5354 16.8125 14.7873V22H17.25C18.2165 22 19 21.1881 19 20.1866V11.037C19 8.05739 17.0186 5.55733 14.3467 4.87442V4.36007C14.3467 3.60892 13.7591 3 13.0342 3H10.9661ZM13.4717 4.71974V4.36007C13.4717 4.10969 13.2759 3.90671 13.0342 3.90671H10.9661C10.7244 3.90671 10.5286 4.10969 10.5286 4.36007V4.71971C10.7248 4.70006 10.9238 4.69 11.125 4.69H12.875C13.0763 4.69 13.2754 4.70007 13.4717 4.71974ZM15.1832 8.39911H8.81958V9.63554H15.1832V8.39911Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M8.0625 22H15.9375V14.7873C15.9375 14.0361 15.3499 13.4272 14.625 13.4272H9.375C8.65013 13.4272 8.0625 14.0361 8.0625 14.7873V22Z"
        fill="currentColor"
      />
    </svg>
  );
}
