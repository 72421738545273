export const NODE_WIDTH_MD = 26.6;
export const NODE_WIDTH_XS = 22.25;

export const NODE_WIDTH = {
  md: `${NODE_WIDTH_MD}px`,
  xs: `${NODE_WIDTH_XS}px`,
};

export const NODE_GAP_MD = 16;
export const NODE_GAP_XS = 13.39;

export const NODE_GAP = {
  md: `${NODE_GAP_MD}px`,
  xs: `${NODE_GAP_XS}px`,
};
