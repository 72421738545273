import { useCallback, useContext } from 'react';

import {
  CellSelectedState,
  TableLayoutSelectionContext,
} from '../contexts/tableLayoutSelectionContext';

export default function useTableSelectionContext() {
  const [value, setValue] = useContext(TableLayoutSelectionContext);

  const selectCell = useCallback(
    (rowId: string, colKey: string, state: CellSelectedState) => {
      if (value.disableCellFocus) return;
      const rowIndex = value.flattenRowIds.indexOf(rowId);
      const colIndex = value.columnOrder.indexOf(colKey);
      setValue((st) => ({
        ...st,
        cellSelected: {
          cellId: { rowId, colKey },
          state: state,
          pos: { rowIndex, colIndex },
        },
      }));
    },
    [setValue, value.columnOrder, value.flattenRowIds, value.disableCellFocus]
  );

  const setSelectedState = (state: CellSelectedState) => {
    setValue((st) => {
      if (!st.cellSelected) {
        return st;
      }
      return {
        ...st,
        cellSelected: {
          ...st.cellSelected,
          state,
        },
      };
    });
  };

  const focusOutCell = () => {
    setValue((st) => ({
      ...st,
      cellSelected: null,
    }));
  };

  const setFlattenRowIds = useCallback(
    (ids: string[]) => {
      setValue((st) => ({
        ...st,
        flattenRowIds: ids,
      }));
    },
    [setValue]
  );

  const setColumOrder = useCallback(
    (columnOrder: string[]) => {
      setValue((st) => ({
        ...st,
        columnOrder,
      }));
    },
    [setValue]
  );

  const setDisableCellFocus = useCallback(
    (disableCellFocus?: boolean) => {
      setValue((st) => ({
        ...st,
        disableCellFocus,
      }));
    },
    [setValue]
  );

  const getCellSelectedState = useCallback(
    (rowId: string, colKey: string): CellSelectedState | null => {
      const selected = value.cellSelected;
      if (
        !!selected &&
        selected.cellId.colKey === colKey &&
        selected.cellId.rowId === rowId
      ) {
        return selected.state;
      }
      return null;
    },
    [value.cellSelected]
  );

  return {
    ...value,
    selectCell,
    focusOutCell,
    setFlattenRowIds,
    setColumOrder,
    getCellSelectedState,
    setSelectedState,
    setDisableCellFocus,
  };
}
