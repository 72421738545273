import { Box } from '@mui/material';
import {
  OtherCheckedSquareSolid as OtherCheckedSquareSolidIcon,
  OtherCheckSquareDash as OtherCheckSquareDashIcon,
  OtherCheckSquareOutline as OtherCheckSquareOutlineIcon,
} from '@front/icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { TableLayoutCheckboxCell, TableLayoutRow } from '../../types';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    cursor: 'pointer',
  },
};
export type IaCheckboxCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutCheckboxCell;
  columnKey: string;
};

export default function IaCheckboxCell({ row, cell }: IaCheckboxCellProps) {
  const { getIaAction } = useIaAction();

  const handleOnClick = () => {
    getIaAction<{ cell: TableLayoutCheckboxCell; row: TableLayoutRow }>(
      cell.actionMap?.click
    )?.action({
      cell,
      row,
    });
  };

  const checked = cell.value === 'checked';
  const partial = cell.value === 'partial';
  const unchecked = cell.value === 'unchecked';

  return (
    <Box sx={styles.root} onClick={handleOnClick}>
      {checked && <OtherCheckedSquareSolidIcon width={16} height={16} />}
      {partial && <OtherCheckSquareDashIcon width={16} height={16} />}
      {unchecked && <OtherCheckSquareOutlineIcon width={16} height={16} />}
    </Box>
  );
}
