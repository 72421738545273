import { useCallback, useMemo, useState } from 'react';
import { useAsyncQueryPool } from '@front/helper';
import { apis } from '@lib/web/apis';
import {
  MessageExternalPayload,
  ThreadMessageExternalPayloadGetter,
} from '@lib/web/thread/types';
import { call } from '@lib/web/utils';

const responseToPayload = (
  res: GetThreadMessageStaticPayloadRes
): MessageExternalPayload => {
  try {
    return JSON.parse(res?.payload || '{}');
  } catch (e) {
    return {};
  }
};

export const useThreadMessageExternalPayload = () => {
  const [payloadMap, setPayloadMap] = useState<
    Record<string, GetThreadMessageStaticPayloadRes | null>
  >({});

  const { asyncGet } = useAsyncQueryPool({
    poolKey: 'useThreadMessageExternalPayload',
    map: payloadMap,
    setMap: setPayloadMap,
    queryApi: async (keys) => {
      const [res] = await call(
        apis.notification.getThreadMessageStaticPayload({ messageIds: keys }) // ask back-end to change the naming, use payload id instead of message id
      );
      return keys.map(
        (key) => res?.data?.find((d) => d.messageId === key) || null
      );
    },
  });

  const getThreadMessageExternalPayload: ThreadMessageExternalPayloadGetter =
    useCallback(
      (payloadId) => {
        if (!payloadId) return null;
        const res = asyncGet(payloadId);
        return res ? responseToPayload(res) : null;
      },
      [asyncGet]
    );

  const setThreadMessageExternalPayload = useCallback(
    ({
      payloadId,
      payload,
      threadMemberId,
      channelCid,
    }: {
      payloadId: string;
      payload: MessageExternalPayload;
      threadMemberId: string;
      channelCid?: string;
    }) => {
      return call(
        apis.notification.setThreadMessageStaticPayload({
          messages: [
            {
              messageId: payloadId, // ask back-end to change the naming, use payload id instead of message id
              payload: JSON.stringify(payload),
              threadMemberId,
              channelCid,
            },
          ],
        })
      );
    },
    []
  );

  return useMemo(
    () => ({
      getThreadMessageExternalPayload,
      setThreadMessageExternalPayload,
    }),
    [getThreadMessageExternalPayload, setThreadMessageExternalPayload]
  );
};
