import {
  AwardBadgeCategory,
  AwardBadgeGoalType,
  AwardBadgeLevelUserStatus,
} from '@lib/web/apis';

import { AwardBadgeDataItem } from '../ia/award/types';

type AwardState = 'Locked' | 'InProgress' | 'Archived';

export const getAwardState = (
  status?: AwardBadgeLevelUserStatus | null
): AwardState => {
  if (!status) return 'Locked';
  if (
    status === AwardBadgeLevelUserStatus.Archived ||
    status === AwardBadgeLevelUserStatus.Earned
  ) {
    return 'Archived';
  }
  return 'InProgress';
};

export const getAwardCurrentValue = (
  goalType: AwardBadgeGoalType,
  levelUser?: AwardBadgeDataItem['levelUser']
) => {
  if (!levelUser) return null;
  if (goalType === AwardBadgeGoalType.CountMatch) return levelUser.currentCount;
  if (goalType === AwardBadgeGoalType.ValueMatch) return levelUser.currentValue;
  return null;
};

export const getAwardGoalValue = (
  goalType: AwardBadgeGoalType,
  levelInfo?: AwardBadgeDataItem['levelInfo']
) => {
  if (!levelInfo) return null;
  if (goalType === AwardBadgeGoalType.CountMatch) return levelInfo.goalCount;
  if (goalType === AwardBadgeGoalType.ValueMatch) return levelInfo.goalValue;
  return null;
};

export const getAwardProgressRate = (
  goalType: AwardBadgeGoalType,
  levelUser?: AwardBadgeDataItem['levelUser'],
  levelInfo?: AwardBadgeDataItem['levelInfo']
): number => {
  const currentValue = getAwardCurrentValue(goalType, levelUser);
  const goalValue = getAwardGoalValue(goalType, levelInfo);
  if (!currentValue || !goalValue) return 0;
  return currentValue / goalValue;
};

export const getAwardPathByCategory = (category: AwardBadgeCategory) => {
  switch (category) {
    case AwardBadgeCategory.Challenges:
      return 'challenges';
    case AwardBadgeCategory.CloseYourRings:
      return 'close-your-rings';
    case AwardBadgeCategory.LimitedEdition:
      return 'limited-edition';
    case AwardBadgeCategory.OnFire:
      return 'on-fire';
    case AwardBadgeCategory.SocialButterfly:
      return 'social-butterfly';
    case AwardBadgeCategory.Unknown:
    default:
      return null;
  }
};

export const getAwardCategoryByPath = (path: string) => {
  switch (path) {
    case 'challenges':
      return AwardBadgeCategory.Challenges;
    case 'close-your-rings':
      return AwardBadgeCategory.CloseYourRings;
    case 'limited-edition':
      return AwardBadgeCategory.LimitedEdition;
    case 'on-fire':
      return AwardBadgeCategory.OnFire;
    case 'social-butterfly':
      return AwardBadgeCategory.SocialButterfly;
    default:
      return null;
  }
};
