import React, { useEffect, useRef, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';

const styles = {
  root: {
    position: 'sticky',
    width: '100%',
    zIndex: 10,
    pointerEvents: 'none',
    userSelect: 'none',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      opacity: 0,
      transition: 'opacity 0.3s',
      pointerEvents: 'none',
      userSelect: 'none',
      visible: 'hidden',
    },
    '&.is-top': {
      top: 0,
      '&:after': {
        maskImage:
          '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
      },
    },
    '&.is-bottom': {
      bottom: 0,
      '&:after': {
        bottom: 0,
        maskImage:
          '-webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
      },
    },
    '&.is-pinned': {
      '&:after': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
  setBackground: (background?: string, size?: number) => (theme: Theme) => ({
    '&:after': {
      height: size || 50,
      background: background || theme.palette.background.body,
      backgroundSize: '100vw',
      backgroundAttachment: 'fixed',
    },
  }),
};

export type ScrollShadowProps = {
  position?: 'bottom' | 'top';
  background?: string;
  size?: number;
  sx?: BoxProps['sx'];
};
function ScrollShadow({
  position = 'bottom',
  size = 50,
  background,
  sx,
}: ScrollShadowProps) {
  const detectRef = useRef<HTMLDivElement>(null);
  const [pinned, setPinned] = useState<boolean>(false);

  const observerRef = useRef(
    typeof window !== 'undefined'
      ? new IntersectionObserver(([entry]) => {
          setPinned(!entry.isIntersecting);
        })
      : null
  );

  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = new IntersectionObserver(([entry]) => {
        setPinned(!entry.isIntersecting);
      });
    }

    const self = observerRef.current;
    if (detectRef.current) {
      self.unobserve(detectRef.current);
      self.observe(detectRef.current);
    }

    return () => {
      self.disconnect();
    };
  }, [detectRef]);

  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <>
      <Box ref={detectRef} height="1px" width="100%" />
      <Box
        sx={[styles.root, styles.setBackground(background, size), ...sxProps]}
        className={`${pinned ? 'is-pinned' : ''} is-${position}`}
      />
    </>
  );
}

export default ScrollShadow;
