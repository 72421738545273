import { isMacOs } from 'react-device-detect';
import { alpha, Box, Theme } from '@mui/material';
import { BlockNoteEditor, BlockSchema } from '@blocknote/core';
import {
  TextEditorBold as TextEditorBoldIcon,
  TextEditorItalic as TextEditorItalicIcon,
} from '@front/icon';

import ThemeProvider from '../../../components/ThemeProvider';
import { ComposerToolbarMenuItem } from '../../types';

import ChangeColorDropdown from './components/ChangeColorDropdown';
import ToggledStyleButton from './components/ToggledStyleButton';
import TurnIntoOrTransformDropdown from './components/TurnIntoOrTransformDropdown';

const styles = {
  root: {
    display: 'flex',
    backgroundColor: 'background.menu',
    border: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    color: 'text.primary',
    borderRadius: '4px',
    boxShadow: 9,
  },
  styledToolbar: {
    px: 1.5,
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    gap: '3.5px',
  },
  styledButtonIcon: {
    typography: 'body2',
    fontWeight: 'bold',
  },
};

const FormattingToolbar = <T extends BlockSchema>({
  editor,
  toolbarItems,
  onConvertBlockType,
}: {
  editor: BlockNoteEditor<T>;
  toolbarItems?: ComposerToolbarMenuItem<T>[];
  onConvertBlockType?: (type: string) => void;
}) => {
  const metaKeyName = isMacOs ? 'cmd' : 'ctrl';

  return (
    <ThemeProvider mode="dark">
      <Box sx={styles.root}>
        {!!toolbarItems?.length && (
          <TurnIntoOrTransformDropdown
            editor={editor}
            toolbarItems={toolbarItems}
            onConvertBlockType={onConvertBlockType}
          />
        )}
        <Box sx={styles.styledToolbar}>
          <ToggledStyleButton
            editor={editor}
            toggledStyle="bold"
            name="Bold"
            hints={[`${metaKeyName}+B`, '**text**']}
          >
            <Box sx={styles.styledButtonIcon} component="span">
              <TextEditorBoldIcon />
            </Box>
          </ToggledStyleButton>
          <ToggledStyleButton
            editor={editor}
            toggledStyle="italic"
            name="Italicize"
            hints={[`${metaKeyName}+I`, '*text*', '_text_']}
          >
            <Box sx={styles.styledButtonIcon} component="span">
              <TextEditorItalicIcon />
            </Box>
          </ToggledStyleButton>
          <ChangeColorDropdown editor={editor} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FormattingToolbar;
