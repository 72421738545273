import React from 'react';

export default function ProfileNFT({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.2847 11.5236H15.3324V12.4759H16.2847V11.5236Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 11.5238H18.1902V12.4762H19.1425V11.5238Z"
        fill="currentColor"
      />
      <path d="M22 11.5238H21.0476V12.4762H22V11.5238Z" fill="currentColor" />
      <path
        d="M18.1902 11.5238L17.2382 11.5236V12.4759L18.1902 12.4762V11.5238Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 11.5238L20.0958 11.5236V12.4759L21.0476 12.4762V11.5238Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 11.5236H16.2847V12.4759H17.2366V11.5236Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 11.5236L19.1425 11.5238V12.4762L20.0943 12.4759V11.5236Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 11.5238L12.4768 11.5236V12.4759L13.4288 12.4762V11.5238Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 11.5236L6.7614 11.5238V12.4762L7.71328 12.4759V11.5236Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 11.5238L5.80954 11.5236V12.4759L6.7614 12.4762V11.5238Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 11.5236L4.85745 11.5238V12.4762L5.80954 12.4759V11.5236Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 11.5238L3.9058 11.5236V12.4759L4.85745 12.4762V11.5238Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 11.5236H2.95187V12.4759H3.90423V11.5236Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 11.5236L2 11.5238V12.4762L2.95187 12.4759V11.5236Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 10.5715H18.1902V11.5238H19.1425V10.5715Z"
        fill="currentColor"
      />
      <path d="M22 10.5715H21.0476V11.5238H22V10.5715Z" fill="currentColor" />
      <path
        d="M15.3324 10.5717L14.3803 10.5715V11.5238L15.3324 11.5236V10.5717Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 10.5715L17.2382 10.5717V11.5236L18.1902 11.5238V10.5715Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 10.5715L20.0958 10.5717V11.5236L21.0476 11.5238V10.5715Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 10.5715H13.4288V11.5238H14.3803V10.5715Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 10.5717H16.2847V11.5236H17.2366V10.5717Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 10.5717L19.1425 10.5715V11.5238L20.0943 11.5236V10.5717Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 10.5717L6.7614 10.5715V11.5238L7.71328 11.5236V10.5717Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 10.5715L5.80954 10.5717V11.5236L6.7614 11.5238V10.5715Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 10.5717L4.85745 10.5715V11.5238L5.80954 11.5236V10.5717Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 10.5715L3.9058 10.5717V11.5236L4.85745 11.5238V10.5715Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 10.5717H2.95187V11.5236H3.90423V10.5717Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 10.5717L2 10.5715V11.5238L2.95187 11.5236V10.5717Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 9.61902H18.1902V10.5715H19.1425V9.61902Z"
        fill="currentColor"
      />
      <path d="M22 9.61902H21.0476V10.5715H22V9.61902Z" fill="currentColor" />
      <path
        d="M15.3324 9.61925L14.3803 9.61902V10.5715L15.3324 10.5717V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 9.61902L17.2382 9.61925V10.5717L18.1902 10.5715V9.61902Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 9.61902L20.0958 9.61925V10.5717L21.0476 10.5715V9.61902Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 9.61902H13.4288V10.5715H14.3803V9.61902Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 9.61925H16.2847V10.5717H17.2366V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 9.61925L19.1425 9.61902V10.5715L20.0943 10.5717V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M10.571 9.61925L9.61885 9.61902V10.5715L10.571 10.5717V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 9.61925L6.7614 9.61902V10.5715L7.71328 10.5717V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 9.61902L5.80954 9.61925V10.5717L6.7614 10.5715V9.61902Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 9.61925L4.85745 9.61902V10.5715L5.80954 10.5717V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 9.61902L3.9058 9.61925V10.5717L4.85745 10.5715V9.61902Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 9.61925H2.95187V10.5717H3.90423V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 9.61925L2 9.61902V10.5715L2.95187 10.5717V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 8.66677H18.1902V9.61902H19.1425V8.66677Z"
        fill="currentColor"
      />
      <path d="M22 8.66677H21.0476V9.61902H22V8.66677Z" fill="currentColor" />
      <path
        d="M15.3324 8.66685L14.3803 8.66677V9.61902L15.3324 9.61925V8.66685Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 8.66677L17.2382 8.66685V9.61925L18.1902 9.61902V8.66677Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 8.66677L20.0958 8.66685V9.61925L21.0476 9.61902V8.66677Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 8.66677H13.4288V9.61902H14.3803V8.66677Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 8.66685H16.2847V9.61925H17.2366V8.66685Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 8.66685L19.1425 8.66677V9.61902L20.0943 9.61925V8.66685Z"
        fill="currentColor"
      />
      <path
        d="M10.571 8.66685L9.61885 8.66677V9.61902L10.571 9.61925V8.66685Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 8.66685L6.7614 8.66677V9.61902L7.71328 9.61925V8.66685Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 8.66677L5.80954 8.66685V9.61925L6.7614 9.61902V8.66677Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 8.66685L4.85745 8.66677V9.61902L5.80954 9.61925V8.66685Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 8.66677L3.9058 8.66685V9.61925L4.85745 9.61902V8.66677Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 8.66685H2.95187V9.61925H3.90423V8.66685Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 8.66685L2 8.66677V9.61902L2.95187 9.61925V8.66685Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 7.71427H18.1902V8.66677H19.1425V7.71427Z"
        fill="currentColor"
      />
      <path d="M22 7.71427H21.0476V8.66677H22V7.71427Z" fill="currentColor" />
      <path
        d="M18.1902 7.71427L17.2382 7.71444V8.66685L18.1902 8.66677V7.71427Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 7.71427L20.0958 7.71444V8.66685L21.0476 8.66677V7.71427Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 7.71427H13.4288V8.66677H14.3803V7.71427Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 7.71444H16.2847V8.66685H17.2366V7.71444Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 7.71444L19.1425 7.71427V8.66677L20.0943 8.66685V7.71444Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 7.71427L12.4768 7.71444V8.66685L13.4288 8.66677V7.71427Z"
        fill="currentColor"
      />
      <path
        d="M12.4752 7.71444H11.5228V8.66685H12.4752V7.71444Z"
        fill="currentColor"
      />
      <path
        d="M11.5228 7.71444H10.571V8.66685H11.5228V7.71444Z"
        fill="currentColor"
      />
      <path
        d="M10.571 7.71444L9.61885 7.71427V8.66677L10.571 8.66685V7.71444Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 7.71444L6.7614 7.71427V8.66677L7.71328 8.66685V7.71444Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 7.71427L5.80954 7.71444V8.66685L6.7614 8.66677V7.71427Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 7.71444L4.85745 7.71427V8.66677L5.80954 8.66685V7.71444Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 7.71427L3.9058 7.71444V8.66685L4.85745 8.66677V7.71427Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 7.71444H2.95187V8.66685H3.90423V7.71444Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 7.71444L2 7.71427V8.66677L2.95187 8.66685V7.71444Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 6.76202H18.1902V7.71427H19.1425V6.76202Z"
        fill="currentColor"
      />
      <path d="M22 6.76202H21.0476V7.71427H22V6.76202Z" fill="currentColor" />
      <path
        d="M18.1902 6.76202L17.2382 6.76203V7.71444L18.1902 7.71427V6.76202Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 6.76202L20.0958 6.76203V7.71444L21.0476 7.71427V6.76202Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 6.76203H16.2847V7.71444H17.2366V6.76203Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 6.76203L19.1425 6.76202V7.71427L20.0943 7.71444V6.76203Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 6.76203H7.71328V7.71444H8.66564V6.76203Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 6.76203L6.7614 6.76202V7.71427L7.71328 7.71444V6.76203Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 6.76202L5.80954 6.76203V7.71444L6.7614 7.71427V6.76202Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 6.76203L4.85745 6.76202V7.71427L5.80954 7.71444V6.76203Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 6.76202L3.9058 6.76203V7.71444L4.85745 7.71427V6.76202Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 6.76203H2.95187V7.71444H3.90423V6.76203Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 6.76203L2 6.76202V7.71427L2.95187 7.71444V6.76203Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 5.80963H15.3324V6.76203H16.2847V5.80963Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 5.80951H18.1902V6.76202H19.1425V5.80951Z"
        fill="currentColor"
      />
      <path d="M22 5.80951H21.0476V6.76202H22V5.80951Z" fill="currentColor" />
      <path
        d="M18.1902 5.80951L17.2382 5.80963V6.76203L18.1902 6.76202V5.80951Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 5.80951L20.0958 5.80963V6.76203L21.0476 6.76202V5.80951Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 5.80963H16.2847V6.76203H17.2366V5.80963Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 5.80963L19.1425 5.80951V6.76202L20.0943 6.76203V5.80963Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 5.80951L8.66721 5.80963V6.76203L9.61885 6.76202V5.80951Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 5.80963H7.71328V6.76203H8.66564V5.80963Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 5.80963L6.7614 5.80951V6.76202L7.71328 6.76203V5.80963Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 5.80951L5.80954 5.80963V6.76203L6.7614 6.76202V5.80951Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 5.80963L4.85745 5.80951V6.76202L5.80954 6.76203V5.80963Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 5.80951L3.9058 5.80963V6.76203L4.85745 6.76202V5.80951Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 5.80963L2 5.80951V6.76202L2.95187 6.76203V5.80963Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 4.85722H15.3324V5.80963H16.2847V4.85722Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 4.85726H18.1902V5.80951H19.1425V4.85726Z"
        fill="currentColor"
      />
      <path d="M22 4.85726H21.0476V5.80951H22V4.85726Z" fill="currentColor" />
      <path
        d="M15.3324 4.85722L14.3803 4.85726V5.80951L15.3324 5.80963V4.85722Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 4.85726L17.2382 4.85722V5.80963L18.1902 5.80951V4.85726Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 4.85726L20.0958 4.85722V5.80963L21.0476 5.80951V4.85726Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 4.85726H13.4288V5.80951H14.3803V4.85726Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 4.85722H16.2847V5.80963H17.2366V4.85722Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 4.85722L19.1425 4.85726V5.80951L20.0943 5.80963V4.85722Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 4.85726L12.4768 4.85722V5.80963L13.4288 5.80951V4.85726Z"
        fill="currentColor"
      />
      <path
        d="M12.4752 4.85722H11.5228V5.80963H12.4752V4.85722Z"
        fill="currentColor"
      />
      <path
        d="M11.5228 4.85722H10.571V5.80963H11.5228V4.85722Z"
        fill="currentColor"
      />
      <path
        d="M10.571 4.85722L9.61885 4.85726V5.80951L10.571 5.80963V4.85722Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 4.85726L8.66721 4.85722V5.80963L9.61885 5.80951V4.85726Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 4.85722H7.71328V5.80963H8.66564V4.85722Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 4.85722L6.7614 4.85726V5.80951L7.71328 5.80963V4.85722Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 4.85726L5.80954 4.85722V5.80963L6.7614 5.80951V4.85726Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 4.85722L4.85745 4.85726V5.80951L5.80954 5.80963V4.85722Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 4.85722L2 4.85726V5.80951L2.95187 5.80963V4.85722Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 3.90481H15.3324V4.85722H16.2847V3.90481Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 3.90476H18.1902V4.85726H19.1425V3.90476Z"
        fill="currentColor"
      />
      <path
        d="M15.3324 3.90481L14.3803 3.90476V4.85726L15.3324 4.85722V3.90481Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 3.90476L17.2382 3.90481V4.85722L18.1902 4.85726V3.90476Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 3.90476L20.0958 3.90481V4.85722L21.0476 4.85726V3.90476Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 3.90476H13.4288V4.85726H14.3803V3.90476Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 3.90481H16.2847V4.85722H17.2366V3.90481Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 3.90481L19.1425 3.90476V4.85726L20.0943 4.85722V3.90481Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 3.90476L12.4768 3.90481V4.85722L13.4288 4.85726V3.90476Z"
        fill="currentColor"
      />
      <path
        d="M12.4752 3.90481H11.5228V4.85722H12.4752V3.90481Z"
        fill="currentColor"
      />
      <path
        d="M11.5228 3.90481H10.571V4.85722H11.5228V3.90481Z"
        fill="currentColor"
      />
      <path
        d="M10.571 3.90481L9.61885 3.90476V4.85726L10.571 4.85722V3.90481Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 3.90476L8.66721 3.90481V4.85722L9.61885 4.85726V3.90476Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 3.90481H7.71328V4.85722H8.66564V3.90481Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 3.90481L6.7614 3.90476V4.85726L7.71328 4.85722V3.90481Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 3.90476L5.80954 3.90481V4.85722L6.7614 4.85726V3.90476Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 3.90481H2.95187V4.85722H3.90423V3.90481Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 2.95241H15.3324V3.90481H16.2847V2.95241Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 2.95251H18.1902V3.90476H19.1425V2.95251Z"
        fill="currentColor"
      />
      <path
        d="M15.3324 2.95241L14.3803 2.95251V3.90476L15.3324 3.90481V2.95241Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 2.95251L17.2382 2.95241V3.90481L18.1902 3.90476V2.95251Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 2.95251H13.4288V3.90476H14.3803V2.95251Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 2.95241H16.2847V3.90481H17.2366V2.95241Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 2.95241L19.1425 2.95251V3.90476L20.0943 3.90481V2.95241Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 2.95251L12.4768 2.95241V3.90481L13.4288 3.90476V2.95251Z"
        fill="currentColor"
      />
      <path
        d="M12.4752 2.95241H11.5228V3.90481H12.4752V2.95241Z"
        fill="currentColor"
      />
      <path
        d="M11.5228 2.95241H10.571V3.90481H11.5228V2.95241Z"
        fill="currentColor"
      />
      <path
        d="M10.571 2.95241L9.61885 2.95251V3.90476L10.571 3.90481V2.95241Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 2.95251L8.66721 2.95241V3.90481L9.61885 3.90476V2.95251Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 2.95241L6.7614 2.95251V3.90476L7.71328 3.90481V2.95241Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 2.95251L3.9058 2.95241V3.90481L4.85745 3.90476V2.95251Z"
        fill="currentColor"
      />
      <path d="M16.2847 2H15.3324V2.95241H16.2847V2Z" fill="currentColor" />
      <path d="M19.1425 2H18.1902V2.95251H19.1425V2Z" fill="currentColor" />
      <path
        d="M15.3324 2H14.3803V2.95251L15.3324 2.95241V2Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 2H17.2382V2.95241L18.1902 2.95251V2Z"
        fill="currentColor"
      />
      <path d="M14.3803 2H13.4288V2.95251H14.3803V2Z" fill="currentColor" />
      <path d="M17.2366 2H16.2847V2.95241H17.2366V2Z" fill="currentColor" />
      <path
        d="M13.4288 2H12.4768V2.95241L13.4288 2.95251V2Z"
        fill="currentColor"
      />
      <path d="M12.4752 2H11.5228V2.95241H12.4752V2Z" fill="currentColor" />
      <path d="M11.5228 2H10.571V2.95241H11.5228V2Z" fill="currentColor" />
      <path
        d="M10.571 2H9.61885V2.95251L10.571 2.95241V2Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 2H8.66721V2.95241L9.61885 2.95251V2Z"
        fill="currentColor"
      />
      <path d="M8.66564 2H7.71328V2.95241H8.66564V2Z" fill="currentColor" />
      <path
        d="M7.71328 2H6.7614V2.95251L7.71328 2.95241V2Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 2H5.80954V2.95241L6.7614 2.95251V2Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 2H4.85745V2.95251L5.80954 2.95241V2Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 15.3332H15.3324V16.2856H16.2847V15.3332Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 18.1904H15.3324V19.1428H16.2847V18.1904Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 21.0476H15.3324V22H16.2847V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 15.3333H18.1902V16.2857H19.1425V15.3333Z"
        fill="currentColor"
      />
      <path d="M22 15.3333H21.0476V16.2857H22V15.3333Z" fill="currentColor" />
      <path
        d="M19.1425 18.1905H18.1902V19.1429H19.1425V18.1905Z"
        fill="currentColor"
      />
      <path d="M22 18.1905H21.0476V19.1429H22V18.1905Z" fill="currentColor" />
      <path
        d="M19.1425 21.0476H18.1902V21.9999H19.1425V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M15.3324 15.3332L14.3803 15.3333V16.2857L15.3324 16.2856V15.3332Z"
        fill="currentColor"
      />
      <path
        d="M15.3324 18.1904L14.3803 18.1905V19.1429L15.3324 19.1428V18.1904Z"
        fill="currentColor"
      />
      <path
        d="M15.3324 21.0476L14.3803 21.0476V21.9999L15.3324 22V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 15.3333L17.2382 15.3332V16.2856L18.1902 16.2857V15.3333Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 15.3333L20.0958 15.3332V16.2856L21.0476 16.2857V15.3333Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 18.1905L17.2382 18.1904V19.1428L18.1902 19.1429V18.1905Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 21.0476L17.2382 21.0476V22L18.1902 21.9999V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 18.1905H13.4288V19.1429H14.3803V18.1905Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 21.0476H13.4288V21.9999H14.3803V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 15.3332H16.2847V16.2856H17.2366V15.3332Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 15.3332L19.1425 15.3333V16.2857L20.0943 16.2856V15.3332Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 18.1904H16.2847V19.1428H17.2366V18.1904Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 21.0476H16.2847V22H17.2366V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 18.1905L12.4768 18.1904V19.1428L13.4288 19.1429V18.1905Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 21.0476L12.4768 21.0476V22L13.4288 21.9999V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M12.4752 18.1904H11.5228V19.1428H12.4752V18.1904Z"
        fill="currentColor"
      />
      <path
        d="M12.4752 21.0476H11.5228V22H12.4752V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M11.5228 18.1904H10.571V19.1428H11.5228V18.1904Z"
        fill="currentColor"
      />
      <path
        d="M11.5228 21.0476H10.571V22H11.5228V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M10.571 15.3332L9.61885 15.3333V16.2857L10.571 16.2856V15.3332Z"
        fill="currentColor"
      />
      <path
        d="M10.571 18.1904L9.61885 18.1905V19.1429L10.571 19.1428V18.1904Z"
        fill="currentColor"
      />
      <path
        d="M10.571 21.0476L9.61885 21.0476V21.9999L10.571 22V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 15.3333L8.66721 15.3332V16.2856L9.61885 16.2857V15.3333Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 18.1905L8.66721 18.1904V19.1428L9.61885 19.1429V18.1905Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 21.0476L8.66721 21.0476V22L9.61885 21.9999V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 15.3332H7.71328V16.2856H8.66564V15.3332Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 18.1904H7.71328V19.1428H8.66564V18.1904Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 21.0476H7.71328V22H8.66564V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 15.3332L6.7614 15.3333V16.2857L7.71328 16.2856V15.3332Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 18.1904L6.7614 18.1905V19.1429L7.71328 19.1428V18.1904Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 21.0476L6.7614 21.0476V21.9999L7.71328 22V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 15.3333L5.80954 15.3332V16.2856L6.7614 16.2857V15.3333Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 18.1905L5.80954 18.1904V19.1428L6.7614 19.1429V18.1905Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 21.0476L5.80954 21.0476V22L6.7614 21.9999V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 15.3332L4.85745 15.3333V16.2857L5.80954 16.2856V15.3332Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 18.1904L4.85745 18.1905V19.1429L5.80954 19.1428V18.1904Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 21.0476L4.85745 21.0476V21.9999L5.80954 22V21.0476Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 15.3333L3.9058 15.3332V16.2856L4.85745 16.2857V15.3333Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 18.1905L3.9058 18.1904V19.1428L4.85745 19.1429V18.1905Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 15.3332H2.95187V16.2856H3.90423V15.3332Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 18.1904H2.95187V19.1428H3.90423V18.1904Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 15.3332L2 15.3333V16.2857L2.95187 16.2856V15.3332Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 18.1904L2 18.1905V19.1429L2.95187 19.1428V18.1904Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 14.3813H15.3324V15.3332H16.2847V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 17.238H15.3324V18.1904H16.2847V17.238Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 14.381H18.1902V15.3333H19.1425V14.381Z"
        fill="currentColor"
      />
      <path d="M22 14.381H21.0476V15.3333H22V14.381Z" fill="currentColor" />
      <path
        d="M19.1425 17.238H18.1902V18.1905H19.1425V17.238Z"
        fill="currentColor"
      />
      <path d="M22 17.238H21.0476V18.1905H22V17.238Z" fill="currentColor" />
      <path
        d="M15.3324 14.3813L14.3803 14.381V15.3333L15.3324 15.3332V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M15.3324 17.238L14.3803 17.238V18.1905L15.3324 18.1904V17.238Z"
        fill="currentColor"
      />
      <path
        d="M15.3324 20.0952L14.3803 20.0953V21.0476L15.3324 21.0476V20.0952Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 14.381L17.2382 14.3813V15.3332L18.1902 15.3333V14.381Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 14.381L20.0958 14.3813V15.3332L21.0476 15.3333V14.381Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 17.238L17.2382 17.238V18.1904L18.1902 18.1905V17.238Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 20.0953H13.4288V21.0476H14.3803V20.0953Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 14.3813H16.2847V15.3332H17.2366V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 14.3813L19.1425 14.381V15.3333L20.0943 15.3332V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 17.238H16.2847V18.1904H17.2366V17.238Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 17.238L19.1425 17.238V18.1905L20.0943 18.1904V17.238Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 20.0952H16.2847V21.0476H17.2366V20.0952Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 20.0952L19.1425 20.0953V21.0476L20.0943 21.0476V20.0952Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 20.0953L12.4768 20.0952V21.0476L13.4288 21.0476V20.0953Z"
        fill="currentColor"
      />
      <path
        d="M12.4752 20.0952H11.5228V21.0476H12.4752V20.0952Z"
        fill="currentColor"
      />
      <path
        d="M11.5228 20.0952H10.571V21.0476H11.5228V20.0952Z"
        fill="currentColor"
      />
      <path
        d="M10.571 14.3813L9.61885 14.381V15.3333L10.571 15.3332V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M10.571 17.238L9.61885 17.238V18.1905L10.571 18.1904V17.238Z"
        fill="currentColor"
      />
      <path
        d="M10.571 20.0952L9.61885 20.0953V21.0476L10.571 21.0476V20.0952Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 14.381L8.66721 14.3813V15.3332L9.61885 15.3333V14.381Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 17.238L8.66721 17.238V18.1904L9.61885 18.1905V17.238Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 20.0953L8.66721 20.0952V21.0476L9.61885 21.0476V20.0953Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 14.3813H7.71328V15.3332H8.66564V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 17.238H7.71328V18.1904H8.66564V17.238Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 20.0952H7.71328V21.0476H8.66564V20.0952Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 14.3813L6.7614 14.381V15.3333L7.71328 15.3332V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 17.238L6.7614 17.238V18.1905L7.71328 18.1904V17.238Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 20.0952L6.7614 20.0953V21.0476L7.71328 21.0476V20.0952Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 14.381L5.80954 14.3813V15.3332L6.7614 15.3333V14.381Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 17.238L5.80954 17.238V18.1904L6.7614 18.1905V17.238Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 20.0953L5.80954 20.0952V21.0476L6.7614 21.0476V20.0953Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 14.3813L4.85745 14.381V15.3333L5.80954 15.3332V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 17.238L4.85745 17.238V18.1905L5.80954 18.1904V17.238Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 20.0952L4.85745 20.0953V21.0476L5.80954 21.0476V20.0952Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 14.381L3.9058 14.3813V15.3332L4.85745 15.3333V14.381Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 17.238L3.9058 17.238V18.1904L4.85745 18.1905V17.238Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 20.0953L3.9058 20.0952V21.0476L4.85745 21.0476V20.0953Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 14.3813H2.95187V15.3332H3.90423V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 17.238H2.95187V18.1904H3.90423V17.238Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 14.3813L2 14.381V15.3333L2.95187 15.3332V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 17.238L2 17.238V18.1905L2.95187 18.1904V17.238Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 13.4284H15.3324V14.3813H16.2847V13.4284Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 16.2856H15.3324V17.238H16.2847V16.2856Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 19.1428H15.3324V20.0952H16.2847V19.1428Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 13.4285H18.1902V14.381H19.1425V13.4285Z"
        fill="currentColor"
      />
      <path d="M22 13.4285H21.0476V14.381H22V13.4285Z" fill="currentColor" />
      <path
        d="M19.1425 16.2857H18.1902V17.238H19.1425V16.2857Z"
        fill="currentColor"
      />
      <path d="M22 16.2857H21.0476V17.238H22V16.2857Z" fill="currentColor" />
      <path
        d="M15.3324 13.4284L14.3803 13.4285V14.381L15.3324 14.3813V13.4284Z"
        fill="currentColor"
      />
      <path
        d="M15.3324 16.2856L14.3803 16.2857V17.238L15.3324 17.238V16.2856Z"
        fill="currentColor"
      />
      <path
        d="M15.3324 19.1428L14.3803 19.1429V20.0953L15.3324 20.0952V19.1428Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 13.4285L17.2382 13.4284V14.3813L18.1902 14.381V13.4285Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 13.4285L20.0958 13.4284V14.3813L21.0476 14.381V13.4285Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 16.2857L17.2382 16.2856V17.238L18.1902 17.238V16.2857Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 16.2857L20.0958 16.2856V17.238L21.0476 17.238V16.2857Z"
        fill="currentColor"
      />
      <path
        d="M18.1902 19.1429L17.2382 19.1428V20.0952L18.1902 20.0953V19.1429Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 19.1429L20.0958 19.1428V20.0952L21.0476 20.0953V19.1429Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 19.1429H13.4288V20.0953H14.3803V19.1429Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 13.4284H16.2847V14.3813H17.2366V13.4284Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 13.4284L19.1425 13.4285V14.381L20.0943 14.3813V13.4284Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 16.2856H16.2847V17.238H17.2366V16.2856Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 16.2856L19.1425 16.2857V17.238L20.0943 17.238V16.2856Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 19.1428H16.2847V20.0952H17.2366V19.1428Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 19.1429L12.4768 19.1428V20.0952L13.4288 20.0953V19.1429Z"
        fill="currentColor"
      />
      <path
        d="M12.4752 19.1428H11.5228V20.0952H12.4752V19.1428Z"
        fill="currentColor"
      />
      <path
        d="M11.5228 19.1428H10.571V20.0952H11.5228V19.1428Z"
        fill="currentColor"
      />
      <path
        d="M10.571 13.4284L9.61885 13.4285V14.381L10.571 14.3813V13.4284Z"
        fill="currentColor"
      />
      <path
        d="M10.571 16.2856L9.61885 16.2857V17.238L10.571 17.238V16.2856Z"
        fill="currentColor"
      />
      <path
        d="M10.571 19.1428L9.61885 19.1429V20.0953L10.571 20.0952V19.1428Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 13.4285L8.66721 13.4284V14.3813L9.61885 14.381V13.4285Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 16.2857L8.66721 16.2856V17.238L9.61885 17.238V16.2857Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 19.1429L8.66721 19.1428V20.0952L9.61885 20.0953V19.1429Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 13.4284H7.71328V14.3813H8.66564V13.4284Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 16.2856H7.71328V17.238H8.66564V16.2856Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 19.1428H7.71328V20.0952H8.66564V19.1428Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 13.4284L6.7614 13.4285V14.381L7.71328 14.3813V13.4284Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 16.2856L6.7614 16.2857V17.238L7.71328 17.238V16.2856Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 19.1428L6.7614 19.1429V20.0953L7.71328 20.0952V19.1428Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 13.4285L5.80954 13.4284V14.3813L6.7614 14.381V13.4285Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 16.2857L5.80954 16.2856V17.238L6.7614 17.238V16.2857Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 19.1429L5.80954 19.1428V20.0952L6.7614 20.0953V19.1429Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 13.4284L4.85745 13.4285V14.381L5.80954 14.3813V13.4284Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 16.2856L4.85745 16.2857V17.238L5.80954 17.238V16.2856Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 19.1428L4.85745 19.1429V20.0953L5.80954 20.0952V19.1428Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 13.4285L3.9058 13.4284V14.3813L4.85745 14.381V13.4285Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 16.2857L3.9058 16.2856V17.238L4.85745 17.238V16.2857Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 19.1429L3.9058 19.1428V20.0952L4.85745 20.0953V19.1429Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 13.4284H2.95187V14.3813H3.90423V13.4284Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 16.2856H2.95187V17.238H3.90423V16.2856Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 19.1428H2.95187V20.0952H3.90423V19.1428Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 13.4284L2 13.4285V14.381L2.95187 14.3813V13.4284Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 16.2856L2 16.2857V17.238L2.95187 17.238V16.2856Z"
        fill="currentColor"
      />
      <path
        d="M16.2847 12.4759H15.3324V13.4284H16.2847V12.4759Z"
        fill="currentColor"
      />
      <path
        d="M19.1425 12.4762H18.1902V13.4285H19.1425V12.4762Z"
        fill="currentColor"
      />
      <path d="M22 12.4762H21.0476V13.4285H22V12.4762Z" fill="currentColor" />
      <path
        d="M18.1902 12.4762L17.2382 12.4759V13.4284L18.1902 13.4285V12.4762Z"
        fill="currentColor"
      />
      <path
        d="M21.0476 12.4762L20.0958 12.4759V13.4284L21.0476 13.4285V12.4762Z"
        fill="currentColor"
      />
      <path
        d="M17.2366 12.4759H16.2847V13.4284H17.2366V12.4759Z"
        fill="currentColor"
      />
      <path
        d="M20.0943 12.4759L19.1425 12.4762V13.4285L20.0943 13.4284V12.4759Z"
        fill="currentColor"
      />
      <path
        d="M10.571 12.4759L9.61885 12.4762V13.4285L10.571 13.4284V12.4759Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 12.4762L8.66721 12.4759V13.4284L9.61885 13.4285V12.4762Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 12.4759H7.71328V13.4284H8.66564V12.4759Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 12.4759L6.7614 12.4762V13.4285L7.71328 13.4284V12.4759Z"
        fill="currentColor"
      />
      <path
        d="M6.7614 12.4762L5.80954 12.4759V13.4284L6.7614 13.4285V12.4762Z"
        fill="currentColor"
      />
      <path
        d="M5.80954 12.4759L4.85745 12.4762V13.4285L5.80954 13.4284V12.4759Z"
        fill="currentColor"
      />
      <path
        d="M4.85745 12.4762L3.9058 12.4759V13.4284L4.85745 13.4285V12.4762Z"
        fill="currentColor"
      />
      <path
        d="M3.90423 12.4759H2.95187V13.4284H3.90423V12.4759Z"
        fill="currentColor"
      />
      <path
        d="M2.95187 12.4759L2 12.4762V13.4285L2.95187 13.4284V12.4759Z"
        fill="currentColor"
      />
      <path
        d="M12.4752 15.3332H11.5228V16.2856H12.4752V15.3332Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 16.2857V15.3333L12.4768 15.3332V16.2856L13.4288 16.2857Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 12.4762L12.4768 12.4759V13.4284L13.4288 13.4285V12.4762Z"
        fill="currentColor"
      />
      <path
        d="M10.571 9.61925H11.5228V8.66685H10.571V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M11.5228 9.61925H12.4752V8.66685H11.5228V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M12.4768 9.61925L13.4288 9.61902V8.66677L12.4768 8.66685V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 8.66685H8.66564V7.71444H7.71328V8.66685Z"
        fill="currentColor"
      />
      <path
        d="M8.66721 7.71444L9.61885 7.71427V6.76202L8.66721 6.76203V7.71444Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 6.76202L10.571 6.76203V5.80963L9.61885 5.80951V6.76202Z"
        fill="currentColor"
      />
      <path
        d="M10.571 6.76203H11.5228V5.80963H10.571V6.76203Z"
        fill="currentColor"
      />
      <path
        d="M11.5228 6.76203H12.4752V5.80963H11.5228V6.76203Z"
        fill="currentColor"
      />
      <path
        d="M12.4768 6.76203L13.4288 6.76202V5.80951L12.4768 5.80963V6.76203Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 6.76202H14.3803V5.80951H13.4288V6.76202Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 6.76202L15.3324 6.76203V5.80963L14.3803 5.80951V6.76202Z"
        fill="currentColor"
      />
      <path
        d="M15.3324 7.71444H16.2847V6.76203H15.3324V7.71444Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 12.4762V11.5238H13.4288V12.4762H14.3803Z"
        fill="currentColor"
      />
      <path
        d="M11.5228 14.3813H12.4752V13.4284H11.5228V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M12.4768 14.3813L13.4288 14.381V13.4285L12.4768 13.4284V14.3813Z"
        fill="currentColor"
      />
      <path
        d="M12.4752 16.2856H11.5228V17.238H12.4752V16.2856Z"
        fill="currentColor"
      />
      <path
        d="M13.4288 16.2857L12.4768 16.2856V17.238L13.4288 17.238V16.2857Z"
        fill="currentColor"
      />
      <path
        d="M14.3803 12.4762H13.4288V13.4285H14.3803V12.4762Z"
        fill="currentColor"
      />
      <path
        d="M12.4752 12.4759H11.5228V13.4284H12.4752V12.4759Z"
        fill="currentColor"
      />
      <path
        d="M9.61885 12.4762V11.5238L8.66721 11.5236V12.4759L9.61885 12.4762Z"
        fill="currentColor"
      />
      <path
        d="M10.571 12.4759V11.5236L9.61885 11.5238V12.4762L10.571 12.4759Z"
        fill="currentColor"
      />
      <path
        d="M8.66564 11.5236H7.71328V12.4759H8.66564V11.5236Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 9.61925H8.66564V8.66685H7.71328V9.61925Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 10.5717H8.66564V9.61925H7.71328V10.5717Z"
        fill="currentColor"
      />
      <path
        d="M7.71328 11.5236H8.66564V10.5717H7.71328V11.5236Z"
        fill="currentColor"
      />
      <path
        d="M10.571 10.5717H11.5228V9.61925H10.571V10.5717Z"
        fill="currentColor"
      />
    </svg>
  );
}
