export default function MathOtherSymbols({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.5 18.8438C10.5 19.2416 10.658 19.6231 10.9393 19.9044C11.2206 20.1857 11.6022 20.3438 12 20.3438C12.3978 20.3438 12.7794 20.1857 13.0607 19.9044C13.342 19.6231 13.5 19.2416 13.5 18.8438C13.5 18.4459 13.342 18.0644 13.0607 17.7831C12.7794 17.5018 12.3978 17.3438 12 17.3438C11.6022 17.3438 11.2206 17.5018 10.9393 17.7831C10.658 18.0644 10.5 18.4459 10.5 18.8438ZM11.25 14.9062H12.75C12.8531 14.9062 12.9375 14.8219 12.9375 14.7188V3.84375C12.9375 3.74062 12.8531 3.65625 12.75 3.65625H11.25C11.1469 3.65625 11.0625 3.74062 11.0625 3.84375V14.7188C11.0625 14.8219 11.1469 14.9062 11.25 14.9062Z"
        fill="currentColor"
      />
    </svg>
  );
}
