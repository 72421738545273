import useSWR from 'swr';
import { nonNullable } from '@front/helper';
import {
  QuestionDetailComponentType,
  ReviewGroupType,
  VariableType,
} from '@lib/web/apis';
import { isEmpty } from 'lodash';

import apis from '../../apis';

import generateHook, { generateInfiniteHook } from './swr.helper';

export const useCreatorQuestion = (id?: string) => {
  const params = new URLSearchParams();
  if (id) params.set('id', id);

  return generateHook<GetCreatorQuestionRes>(
    id ? `v2/creator-question?${params.toString()}` : null,
    {
      auth: true,
      immutable: true,
    }
  );
};

export const useCreatorQuestionMultiple = (questionIds: string[]) => {
  const key =
    questionIds.length > 0
      ? `useCreatorQuestionMultiple${questionIds.toString()}`
      : null;

  // XXX: better to make it generalize in our swr helper
  return useSWR<Record<string, GetCreatorQuestionRes> | null>(key, {
    fetcher: async () => {
      const res = await Promise.all(
        questionIds.map((id) => apis.editor.getCreatorQuestion(id))
      );

      const result: Record<string, GetCreatorQuestionRes> = {};

      res.forEach((r, index) => {
        if (r?.data.data) {
          result[questionIds[index]] = r.data.data;
        }
      });

      return result;
    },
  });
};

export const useCreatorQuestionGroupItemMultiple = (questionIds: string[]) => {
  const key =
    questionIds.length > 0
      ? `useCreatorQuestionGroupItemMultiple${questionIds.toString()}`
      : null;

  // XXX: better to make it generalize in our swr helper
  return useSWR<GetCreatorQuestionGroupItemRes[][] | undefined>(key, {
    fetcher: async () => {
      const res = await Promise.all(
        questionIds.map((id) => apis.editor.getCreatorQuestionGroupItem(id))
      );

      return res.map((r) => r?.data.data.items).filter(nonNullable);
    },
  });
};

export const useCreatorQuestionForReview = (id?: string) => {
  const params = new URLSearchParams();
  if (id) params.set('id', id);

  return generateHook<GetCreatorQuestionRes>(
    id ? `v2/creator-question/review/info?${params.toString()}` : null,
    {
      auth: true,
      immutable: true,
    }
  );
};

export const useCreatorQuestionList = ({
  sectionId,
  pageSize,
  isGetSubQuestion,
  enable = true,
}: {
  sectionId: string | null;
  pageSize: number;
  isGetSubQuestion?: boolean;
  enable?: boolean;
}) => {
  const params = new URLSearchParams();

  if (sectionId) params.set('sectionId', sectionId);
  if (isGetSubQuestion !== undefined)
    params.set('isGetSubQuestion', `${isGetSubQuestion}`);

  params.set('limit', `${pageSize}`);

  return generateInfiniteHook<GetCreatorQuestionListRes>(
    (index) => {
      if (!enable) return null;
      params.set('page', `${index + 1}`);
      return sectionId ? `v2/creator-question/list?${params.toString()}` : null;
    },
    pageSize,
    {
      auth: true,
      immutable: true,
    }
  );
};

/**
 *
 * @param sectionId
 * @param userId optional, without userId means all questions
 * @param pageSize
 * @param isGetSubQuestion
 * @param isUseGlobalSort
 */
export function useCreatorQuestionListForReview<
  T = GetCreatorQuestionListForReviewRes
>({
  sectionId,
  userId,
  pageSize,
  isGetSubQuestion,
  isUseGlobalSort,
  enable = true,
}: {
  sectionId: string | null;
  userId?: string;
  pageSize: number;
  isGetSubQuestion?: boolean;
  isUseGlobalSort?: boolean;
  enable?: boolean;
}) {
  const params = new URLSearchParams();

  if (sectionId) params.set('sectionId', sectionId);
  if (userId) params.set('userId', userId);
  if (isGetSubQuestion !== undefined)
    params.set('isGetSubQuestion', `${isGetSubQuestion}`);
  if (isUseGlobalSort !== undefined)
    params.set('isUseGlobalSort', `${isUseGlobalSort}`);

  params.set('limit', `${pageSize}`);

  return generateInfiniteHook<T>(
    (index) => {
      if (!enable) return null;
      params.set('page', `${index + 1}`);
      return sectionId
        ? `v2/creator-question/review/list?${params.toString()}`
        : null;
    },
    pageSize,
    {
      auth: true,
      immutable: true,
    }
  );
}

export const useSupportedQuestionAnswerFormatType = (
  sectionId: string | null
) => {
  const params = new URLSearchParams();
  if (sectionId) params.set('sectionId', sectionId);

  return generateHook<GetSupportedQuestionAnswerFormatTypeRes[]>(
    sectionId
      ? `v2/creator-question/answer-format?${params.toString()}`
      : sectionId,
    {
      auth: true,
    }
  );
};

/**
 * Currently we don't need back-end data to get supported question block type because basically every block is supported
 */
export const useSupportedQuestionBlockType = (sectionId: string | null) => {
  const params = new URLSearchParams();
  if (sectionId) params.set('sectionId', sectionId);

  return generateHook<GetSupportedQuestionBlockTypeRes>(
    sectionId ? `v2/creator-question/block?${params.toString()}` : null,
    {
      auth: true,
    }
  );
};

export const useCreatorQuestionDetail = (
  id?: string,
  componentType?: QuestionDetailComponentType
) => {
  const params = new URLSearchParams();
  if (id) params.set('id', id);
  if (componentType) params.set('componentType', `${componentType}`);

  return generateHook<GetCreatorQuestionDetailRes>(
    id ? `v2/creator-question/detail?${params.toString()}` : null,
    {
      auth: true,
    }
  );
};

export const useCreatorQuestionDetailForReview = (
  id?: string,
  componentType?: QuestionDetailComponentType
) => {
  const params = new URLSearchParams();
  if (id) params.set('id', id);
  if (componentType) params.set('componentType', `${componentType}`);

  return generateHook<GetCreatorQuestionDetailRes>(
    id ? `v2/creator-question/review/detail?${params.toString()}` : null,
    {
      auth: true,
    }
  );
};

export const useCreatorQuestionCorrectAnswer = (id?: string) => {
  const params = new URLSearchParams();
  if (id) params.set('id', id);

  return generateHook<GetCreatorQuestionCorrectAnswerRes>(
    id ? `v2/creator-question/correct-answer?${params.toString()}` : null,
    {
      auth: true,
    }
  );
};

export const useCreatorQuestionCorrectAnswerForReview = (id?: string) => {
  const params = new URLSearchParams();
  if (id) params.set('id', id);

  return generateHook<GetCreatorQuestionCorrectAnswerRes>(
    id
      ? `v2/creator-question/review/correct-answer?${params.toString()}`
      : null,
    {
      auth: true,
    }
  );
};

export const useCreatorQuestionOverview = (sectionId: string | null) => {
  const params = new URLSearchParams();
  if (sectionId) params.set('sectionId', sectionId);
  return generateHook<GetCreatorQuestionOverviewRes>(
    sectionId ? `v2/creator-question/overview?${params.toString()}` : null,
    {
      auth: true,
    }
  );
};

/**
 *
 * @param sectionId
 * @param userId optional, without userId means all questions
 */
export const useCreatorQuestionOverviewForReview = (
  sectionId: string | null,
  userId?: string
) => {
  const params = new URLSearchParams();
  if (sectionId) params.set('sectionId', sectionId);
  if (userId) params.set('userId', userId);
  return generateHook<GetCreatorQuestionOverviewForReviewRes>(
    sectionId
      ? `v2/creator-question/review/overview?${params.toString()}`
      : null,
    {
      auth: true,
    }
  );
};

export const useCreatorQuestionAnalytics = (questionId?: NullableString) => {
  const params = new URLSearchParams();
  if (questionId) params.set('id', questionId);
  return generateHook<GetCreatorQuestionAnalyticsRes>(
    questionId ? `v2/creator-question/analytic?${params.toString()}` : null,
    {
      auth: true,
    }
  );
};

export const useCreatorQuestionAnalyticsForReview = (
  questionId?: NullableString
) => {
  const params = new URLSearchParams();
  if (questionId) params.set('id', questionId);
  return generateHook<GetCreatorQuestionAnalyticsRes>(
    questionId
      ? `v2/creator-question/review/analytic?${params.toString()}`
      : null,
    {
      auth: true,
    }
  );
};

export const useCreatorQuestionHashtags = (id?: string) => {
  const params = new URLSearchParams();
  if (id) params.set('id', id);

  return generateHook<GetCreatorQuestionHashtagRes[]>(
    id ? `v2/creator-question/hashtags?${params.toString()}` : null,
    {
      auth: true,
      immutable: true,
    }
  );
};

export const useCreatorQuestionHashtagsForReview = (id?: string) => {
  const params = new URLSearchParams();
  if (id) params.set('id', id);

  return generateHook<GetCreatorQuestionHashtagRes[]>(
    id ? `v2/creator-question/review/hashtags?${params.toString()}` : null,
    {
      auth: true,
      immutable: true,
    }
  );
};

export const useCreatorQuestionPreference = (sectionId: string | null) => {
  const params = new URLSearchParams();
  if (sectionId) params.set('sectionId', sectionId);

  return generateHook<GetCreatorQuestionPreferenceRes>(
    sectionId ? `v2/creator-question/preference?${params.toString()}` : null,
    {
      auth: true,
      errorRetryCount: 0,
    }
  );
};
export const useCreatorQuestionReviewGroup = ({
  sectionId,
  pageSize,
  type,
}: {
  sectionId: string | null;
  pageSize: number;
  type: ReviewGroupType;
}) => {
  const params = new URLSearchParams();

  params.set('sectionId', `${sectionId}`);
  params.set('limit', `${pageSize}`);

  return generateInfiniteHook<GetCreatorQuestionReviewGroupRes>(
    (index) => {
      params.set('page', `${index + 1}`);
      params.set('type', `${type}`);
      return sectionId
        ? `v2/creator-question/review/group?${params.toString()}`
        : null;
    },
    pageSize,
    {
      auth: true,
      immutable: true,
      revalidateAll: true,
    }
  );
};
export const useCreatorQuestionVariable = ({
  sectionId,
  pageSize = 100,
  creatorQuestionId,
  type,
}: {
  sectionId: string | null;
  type: VariableType;
  creatorQuestionId?: string;
  pageSize?: number;
}) => {
  const params = new URLSearchParams();

  const isNumberType =
    type === VariableType.Number || type === VariableType.NumberFormula;

  if (!sectionId || (isNumberType && !creatorQuestionId))
    return generateHook<GetCreatorQuestionVariableRes>(null, {
      auth: true,
      immutable: true,
      paginate: true,
    });

  params.set('sectionId', `${sectionId}`);
  params.set('limit', `${pageSize}`);
  params.set('type', `${type}`);

  if (isNumberType && creatorQuestionId) {
    params.set('creatorQuestionId', `${creatorQuestionId}`);
  }

  return generateHook<GetCreatorQuestionVariableRes>(
    `v2/creator-question/variable/list?${params.toString()}`,
    {
      auth: true,
      immutable: true,
      paginate: true,
    }
  );
};
export const useCreatorQuestionVariableForReview = ({
  sectionId,
  pageSize = 100,
  creatorQuestionId,
  type,
}: {
  sectionId: string | null;
  type: VariableType;
  creatorQuestionId?: string;
  pageSize?: number;
}) => {
  const params = new URLSearchParams();

  const isNumberType =
    type === VariableType.Number || type === VariableType.NumberFormula;

  if (!sectionId || (isNumberType && !creatorQuestionId))
    return generateHook<GetCreatorQuestionVariableRes>(null, {
      auth: true,
      immutable: true,
      paginate: true,
    });

  params.set('sectionId', `${sectionId}`);
  params.set('limit', `${pageSize}`);
  params.set('type', `${type}`);

  if (isNumberType && creatorQuestionId) {
    params.set('creatorQuestionId', `${creatorQuestionId}`);
  }

  return generateHook<GetCreatorQuestionVariableRes>(
    `v2/creator-question/review/variable/list?${params.toString()}`,
    {
      auth: true,
      immutable: true,
      paginate: true,
    }
  );
};

export const useCreatorQuestionLinkedVariable = (ev: {
  creatorQuestionId?: string;
  creatorQuestionComponentId?: string;
}) => {
  const params = new URLSearchParams();

  if (!isEmpty(ev))
    return generateHook<GetCreatorQuestionLinkedVariableRes[]>(null, {
      auth: true,
      immutable: true,
    });

  const { creatorQuestionId, creatorQuestionComponentId } = ev;

  if (creatorQuestionId) {
    params.set('creatorQuestionId', `${creatorQuestionId}`);
  }

  if (creatorQuestionComponentId) {
    params.set('creatorQuestionComponentId', `${creatorQuestionComponentId}`);
  }

  return generateHook<GetCreatorQuestionLinkedVariableRes[]>(
    `v2/creator-question/variable/link?${params.toString()}`,
    {
      auth: true,
      immutable: true,
    }
  );
};
export const useCreatorQuestionLinkedVariableForReview = (ev: {
  creatorQuestionId?: string;
  creatorQuestionComponentId?: string;
}) => {
  const params = new URLSearchParams();

  if (!isEmpty(ev))
    return generateHook<GetCreatorQuestionLinkedVariableRes[]>(null, {
      auth: true,
      immutable: true,
    });

  const { creatorQuestionId, creatorQuestionComponentId } = ev;

  if (creatorQuestionId) {
    params.set('creatorQuestionId', `${creatorQuestionId}`);
  }

  if (creatorQuestionComponentId) {
    params.set('creatorQuestionComponentId', `${creatorQuestionComponentId}`);
  }

  return generateHook<GetCreatorQuestionLinkedVariableRes[]>(
    `v2/creator-question/review/variable/link?${params.toString()}`,
    {
      auth: true,
      immutable: true,
    }
  );
};

export const useCreatorQuestionRubric = (
  creatorQuestionId: string,
  { enable = true }: { enable?: boolean } = {}
) => {
  return generateHook<GetCreatorQuestionRubricRes>(
    `v2/creator-question/rubric/${creatorQuestionId}`,
    {
      auth: true,
      immutable: true,
      enable,
    }
  );
};
