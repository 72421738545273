import type { AxiosPromise } from 'axios';

import api from './client';

const product = {
  checkout: (
    params: ProductCheckoutReq
  ): AxiosPromise<Response<PostProductCheckoutRes>> =>
    api.post('marketplace/v1/product/checkout', params),
};

export default product;
