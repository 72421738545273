import { useCallback, useEffect, useState } from 'react';
import { apis } from '@lib/web/apis';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { useClubThreadViews } from '@lib/web/thread/hooks/views/useClubThreadViews';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import { call } from '@lib/web/utils';
import { debounce } from 'lodash';

const asyncViewName = debounce(
  async (payload: SetThreadViewReq, onSuccess: () => void) => {
    const [res] = await call(apis.thread.setThreadView(payload));
    if (res) {
      onSuccess();
    }
  },
  300
);

type UseThreadViewRenameProps = {
  view: GetThreadViewRes;
  clubId?: string;
};

type UseThreadViewRenameReturn = {
  viewName: string;
  handleChangeViewName: (ev: React.ChangeEvent<HTMLInputElement>) => void;
};

const useThreadViewRename = ({
  view,
  clubId,
}: UseThreadViewRenameProps): UseThreadViewRenameReturn => {
  const { viewName } = useThreadViewDetail(view);
  const [currViewName, setCurrViewName] = useState(viewName);

  const { reloadViews: reloadClubViews } = useClubThreadViews({ clubId });
  const { reloadViews: reloadDmViews } = useDirectMessageViews();

  const onSuccess = useCallback((): void => {
    if (clubId) {
      void reloadClubViews();
    } else {
      void reloadDmViews();
    }
  }, [clubId, reloadClubViews, reloadDmViews]);

  const handleChangeViewName = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>): void => {
      const name = ev.target.value;
      const payload: SetThreadViewReq = {
        type: view.type,
        threadViewUserId: view.threadViewUsers[0].id,
        name: name || 'Untitled',
      };
      void asyncViewName(payload, onSuccess);
      setCurrViewName(name);
    },
    [onSuccess, view.threadViewUsers, view.type]
  );

  useEffect(() => {
    if (viewName) {
      setCurrViewName(viewName);
    }
  }, [viewName]);

  return { viewName: currViewName, handleChangeViewName };
};

export default useThreadViewRename;
