import { FC, useEffect, useMemo, useState } from 'react';
import { useDeviceSelectors } from 'react-device-detect';
import { BoxProps } from '@mui/material/Box';
import {
  Block,
  BlockNoteEditor,
  BlockSchema,
  DefaultBlockSchema,
} from '@blocknote/core';
import { DefaultSideMenu } from '@blocknote/react';
import { FloatingMenu } from '@front/ui';

import ThemeProvider from '../../../components/ThemeProvider';

import { SideMenuProps } from './SideMenu';

const styles = {
  popper: {
    zIndex: 3, // should be equal or greater than editor left menu (zIndex: 3), then the sideMenu will not be covered
    '.mantine-Group-root span': {
      display: 'flex',
    },
    '.floating-menu-root': {
      mt: '-2px',
    },
  },
};

type SideMenuPositionerProps<BSchema extends BlockSchema = DefaultBlockSchema> =
  {
    editor: BlockNoteEditor<BSchema>;
    sideMenu?: FC<SideMenuProps<BSchema>>;
    active: boolean;
    sx?: BoxProps['sx'];
  };
const SideMenuPositioner = <BSchema extends BlockSchema = DefaultBlockSchema>({
  editor,
  sideMenu,
  active,
  sx,
}: SideMenuPositionerProps<BSchema>) => {
  const [{ isDesktop }] = useDeviceSelectors(window?.navigator.userAgent);
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const [show, setShow] = useState<boolean>(false);
  const [block, setBlock] = useState<Block<BSchema>>();
  const [menuFrozen, setMenuFrozen] = useState(false);
  const [sideMenuAnchorEl, setSideMenuAnchorEl] = useState<Element | null>(
    null
  );
  useEffect(() => {
    return editor.sideMenu.onUpdate((sideMenuState) => {
      if (menuFrozen) {
        return;
      }
      setShow(sideMenuState.show);
      setBlock(sideMenuState.block);
    });
  }, [editor, menuFrozen]);

  const sideMenuElement = useMemo(() => {
    if (!block) {
      return null;
    }

    const SideMenu = sideMenu || DefaultSideMenu;

    return (
      <SideMenu
        block={block}
        editor={editor}
        blockDragStart={editor.sideMenu.blockDragStart}
        blockDragEnd={() => {
          // We first remove MenuAnchorEl to prevent component flickering after dragging.
          setSideMenuAnchorEl(null);
          setTimeout(() => {
            editor.sideMenu.blockDragEnd();
          });
        }}
        addBlock={editor.sideMenu.addBlock}
        /**
         * XXX: Ideally, we should use editor.sideMenu.freezeMenu and unfreezeMenu,
         * but it doesn't work, we cannot find the reason, so we do freeze menu by ourselves
         */
        freezeMenu={() => {
          setMenuFrozen(true);
          return true;
        }}
        unfreezeMenu={() => {
          setSideMenuAnchorEl(null);
          setMenuFrozen(false);
          setShow(false);
          return false;
        }}
      />
    );
  }, [block, editor, sideMenu]);

  useEffect(() => {
    if (block) {
      const blockContentElement = document.querySelector(
        `[data-id='${block.id}'] .block-content`
      );
      const blockOuterElement = document.querySelector(
        `[data-id='${block.id}']`
      );
      setSideMenuAnchorEl(blockContentElement || blockOuterElement || null);
    } else {
      setSideMenuAnchorEl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block, active, show]);

  if (!sideMenuAnchorEl) return null;

  return (
    <ThemeProvider>
      <FloatingMenu
        sx={[styles.popper, ...sxProps]}
        open={isDesktop && show && !!sideMenuAnchorEl && active}
        anchorEl={sideMenuAnchorEl}
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altBoundary: true,
            },
          },
        ]}
        placement="left-start"
        transition={false} // disable animation so when the menu is out of view, it will not make browser default scrollbar appear
      >
        {sideMenuElement}
      </FloatingMenu>
    </ThemeProvider>
  );
};

export default SideMenuPositioner;
