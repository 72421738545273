import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  forceResetFilter as forceResetFilterFn,
  removeCondition as removeConditionFn,
  resetFilter as resetFilterFn,
  setConditions as setConditionsFn,
  setFields as setFieldsFn,
  upsertCondition as upsertConditionFn,
} from '@lib/ia/src/filter/reducers/filterReducer';
import {
  FilterConditionConfig,
  FilterOperator,
  FilterValues,
} from '@lib/ia/src/filter/types';
import { buildSearchQuery, compareCondition } from '@lib/ia/src/filter/utils';
import { v4 } from 'uuid';

import { FilterSearchQuery, FilterType } from '../../types/filter';
import useGlobalFilterConfigs from '../../widgets/CommonPanels/FilterPanel/hooks/useGlobalFilterConfigs';
import { useAppDispatch, useAppSelector } from '../redux';

/**
 * @deprecated
 * use useFilterActions, useFilterValues instead of this
 * important: this hook will be remove soon
 */
export function useFilter(
  scope = 'global',
  type?: FilterType,
  readonly?: boolean
) {
  const { t } = useTranslation();
  const configs = useGlobalFilterConfigs();
  const dispatch = useAppDispatch();
  const filter = useAppSelector((st) => st.filter);
  const [searchQuery, setSearchQuery] = useState<FilterSearchQuery | null>();

  useEffect(() => {
    const result = buildSearchQuery(t, filter.conditions[scope]);
    setSearchQuery(result);
  }, [t, filter, scope]);

  useEffect(() => {
    if (!type || readonly) return; // do nothing

    dispatch(resetFilterFn({ scope }));
    setSearchQuery(null);
    const filterConfig = configs[type];
    if (filterConfig) {
      dispatch(setFieldsFn({ fields: filterConfig.fields, scope }));
    } else {
      dispatch(setFieldsFn({ fields: [], scope }));
    }
  }, [type, scope, readonly, dispatch, configs]);

  const hasFilter =
    !!filter.conditions[scope] && filter.conditions[scope].length > 0;
  // not disabled filters
  const hasActiveFilter =
    !!filter.conditions[scope] &&
    filter.conditions[scope].some((item) => !item.disabled);

  const removeConditionByField = useCallback(
    (fieldName: string) => {
      if (!filter.conditions[scope] || filter.conditions[scope].length === 0) {
        return;
      }
      const condition = filter.conditions[scope].find(
        (item) => item.field.name === fieldName
      );
      if (!condition || !condition.id) return;
      dispatch(removeConditionFn({ id: condition.id, scope }));
    },
    [dispatch, filter.conditions, scope]
  );

  const getConditionByField = useCallback(
    (fieldName: string) => {
      if (!filter.conditions[scope] || filter.conditions[scope].length === 0) {
        return;
      }
      return filter.conditions[scope].find(
        (item) => item.field.name === fieldName
      );
    },
    [filter.conditions, scope]
  );

  const hasConditionByField = useCallback(
    (fieldName: string) => {
      if (!filter.conditions[scope] || filter.conditions[scope].length === 0) {
        return false;
      }
      return filter.conditions[scope].some(
        (item) => item.field.name === fieldName
      );
    },
    [filter.conditions, scope]
  );

  const replaceCondition = useCallback(
    (
      filterType: FilterType,
      fieldName: string,
      operator: FilterOperator,
      values?: FilterValues,
      options?: {
        disabled?: boolean;
        viewOnly?: boolean;
      }
    ) => {
      const field = configs?.[filterType]?.fields?.find(
        (f) => f.name === fieldName
      );
      if (!field) return;
      removeConditionByField(fieldName);

      const condition: FilterConditionConfig = {
        id: v4(),
        field,
        operator,
        values,
        ...options,
      };
      dispatch(upsertConditionFn({ condition, scope }));
    },
    [configs, dispatch, removeConditionByField, scope]
  );

  const isConditionExist = useCallback(
    (fieldName: string, operator: FilterOperator, values?: any) => {
      const con1 = getConditionByField(fieldName);
      const con2 = {
        field: { name: fieldName },
        operator,
        values,
      } as FilterConditionConfig;
      return compareCondition(t, con1, con2);
    },
    [t, getConditionByField]
  );

  const scopeFilter = useMemo(() => {
    return {
      conditions: filter.conditions[scope] || [],
      fields: filter.fields[scope] || [],
    };
  }, [filter.conditions, filter.fields, scope]);

  return {
    filter: scopeFilter,
    searchQuery,
    hasFilter,
    hasActiveFilter,
    resetFilterDisabled:
      filter.conditions[scope] &&
      filter.conditions[scope].filter((condition) => !condition.disabled)
        .length === 0,
    setConditions: useCallback(
      (conditions: FilterConditionConfig[]) =>
        dispatch(setConditionsFn({ conditions, scope })),
      [dispatch, scope]
    ),
    resetFilter: useCallback(
      () => dispatch(resetFilterFn({ scope })),
      [dispatch, scope]
    ),
    forceResetFilter: useCallback(
      () => dispatch(forceResetFilterFn({ scope })),
      [dispatch, scope]
    ),
    upsertCondition: useCallback(
      (condition: FilterConditionConfig) =>
        dispatch(upsertConditionFn({ condition, scope })),
      [dispatch, scope]
    ),
    removeCondition: useCallback(
      (id: string) => dispatch(removeConditionFn({ id, scope })),
      [dispatch, scope]
    ),
    removeConditionByField,
    getConditionByField,
    hasConditionByField,
    replaceCondition,
    isConditionExist,
  };
}
