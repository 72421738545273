import { ReactElement, useState } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Button } from '@mui/material';
import { appConfig } from '@front/config';
import { ThreadsThreadView as ThreadsThreadViewIcon } from '@front/icon';
import {
  Icon,
  ResponsiveTooltip,
  TextButton,
  TooltipList,
  TooltipListItem,
} from '@front/ui';
import { useMyAiTokens } from '@lib/web/hooks';
import { AskForHelpDropdown } from '@lib/web/ui';

const styles = {
  button: {
    width: '144px !important',
    typography: 'chatHeaderBold',
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  getTokensButton: {
    color: 'white',
    fontWeight: 'bold',
    px: 1.5,
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    background:
      'linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        boxShadow: 'inset 0px 0px 0px 3px rgba(255, 255, 255, 0.3)',
      },
      '&:not(:disabled):active': {
        color: 'white',
        background:
          'linear-gradient(92.61deg, rgba(0, 209, 255, 0.5) 0%, rgba(0, 81, 249, 0.5) 48.44%, rgba(112, 0, 255, 0.5) 100%)',
        boxShadow: 'inset 0px 0px 0px 3px rgba(0, 0, 0, 0.3)',
      },
    },
  },
};

function AskForHelpTooltip({ children }: { children: ReactElement }) {
  const { t } = useTranslation('quiz');
  const { renderDisplayTokens, hasEnoughTokens } = useMyAiTokens();

  return (
    <ResponsiveTooltip
      title={t('tooltip.askForHelp.title')}
      titleSx={{ fontWeight: 700 }}
      titleIcon={<ThreadsThreadViewIcon width={16} height={16} />}
      tooltipProps={{
        sx: {
          zIndex: 0, // avoid overlapping with dropdown
        },
      }}
      content={
        <TooltipList>
          <TooltipListItem
            value={{
              type: 'text',
              text: t('tooltip.askForHelp.description'),
              wrap: true,
              sxProps: { my: 1 },
            }}
          />
          <TooltipListItem
            name={{
              icon: 'ProfileAiTokensUsed',
              text: t('tooltip.askForHelp.line1.label'),
              wrap: true,
            }}
            value={{
              type: 'text',
              text: t('tooltip.askForHelp.line1.value', {
                count: appConfig.aiApproximatedTokensPerResponse,
              }),
              wrap: true,
              sxProps: { textAlign: 'right' },
            }}
            sx={{
              gridTemplateColumns: '1fr 71px',
            }}
          />
          <TooltipListItem
            name={{
              icon: 'ProfileClubAgent',
              text: t('tooltip.askForHelp.line2.label'),
              wrap: true,
            }}
            value={{
              type: 'element',
              value: (
                <>
                  {renderDisplayTokens} {t('tooltip.askForHelp.line2.value')}
                </>
              ),
              wrap: true,
              sxProps: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 0.5,
              },
            }}
            sx={{
              gridTemplateColumns: '1fr 71px',
            }}
          />
          {!hasEnoughTokens(appConfig.aiApproximatedTokensPerResponse) && (
            <TooltipListItem
              value={{
                type: 'element',
                value: (
                  <Button
                    sx={styles.getTokensButton}
                    component={Link}
                    href="/profile/my-plan"
                  >
                    <Icon name="ProfileClubAgent" size={16} />
                    {t('tooltip.askForHelp.getTokens')}
                  </Button>
                ),
                sxProps: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                },
              }}
            />
          )}
        </TooltipList>
      }
    >
      {children}
    </ResponsiveTooltip>
  );
}

export default function AskForHelpButton() {
  const { t } = useTranslation('quiz');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AskForHelpTooltip>
        <TextButton
          sx={styles.button}
          variant="filled"
          selected={Boolean(anchorEl)}
          prefixIcon={<ThreadsThreadViewIcon />}
          onClick={handleClick}
        >
          {t('button.AskForHelp')}
        </TextButton>
      </AskForHelpTooltip>

      <AskForHelpDropdown
        open={Boolean(anchorEl)}
        menuAnchorEl={anchorEl}
        onClose={handleClose}
      />
    </>
  );
}
