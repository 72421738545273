import { RefObject, useEffect, useRef } from 'react';

type BottomScrolledParams = {
  scrollRef: RefObject<HTMLDivElement>;
  onScrolledTop?: () => void;
  topOffset?: number;
};

export default function useTopScrolled({
  scrollRef,
  onScrolledTop,
  topOffset = 0,
}: BottomScrolledParams) {
  const lastScrollY = useRef(0);
  useEffect(() => {
    const scrollTarget = scrollRef.current;
    const handleScroll = (ev: Event) => {
      const target = ev.target as HTMLDivElement;
      if (target) {
        const isTop =
          target.scrollTop <= topOffset &&
          target.scrollTop < lastScrollY.current;
        if (isTop) {
          onScrolledTop?.();
        }

        lastScrollY.current = target.scrollTop;
      }
    };

    if (scrollTarget && onScrolledTop) {
      scrollTarget.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollTarget)
        scrollTarget.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScrolledTop]);
}
