/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import remarkGfm from 'remark-gfm';

const styles = {
  table: {
    my: 1,
    textAlign: 'left',
    bgcolor: 'background.paper',
    width: '100%',
    '& th': {
      typography: 'body2',
      fontWeight: 'bold',
    },
    '& td': {
      typography: 'caption',
    },
  },
  ul: {
    my: 1,
    pl: 2.5,
  },
  link: {
    color: 'text.primary',
  },
};

const components = {
  h1: ({ node, ...props }: any) => <Typography variant="h4" {...props} />,
  h2: ({ node, ...props }: any) => <Typography variant="h5" {...props} />,
  h3: ({ node, ...props }: any) => <Typography variant="h6" {...props} />,
  h4: ({ node, ...props }: any) => (
    <Typography variant="subtitle1" {...props} />
  ),
  h5: ({ node, ...props }: any) => (
    <Typography variant="subtitle2" {...props} />
  ),
  h6: ({ node, ...props }: any) => <Typography variant="body1" {...props} />,
  p: ({ node, ...props }: any) => <Typography variant="body2" {...props} />,
  a: ({ node, ...props }: any) => (
    <Typography variant="body2" component="a" sx={styles.link} {...props} />
  ),
  table: ({ node, ...props }: any) => (
    <Box sx={styles.table} component="table" {...props} />
  ),
  ul: ({ node, ordered, ...props }: any) => (
    <Box sx={styles.ul} component="ul" {...props} />
  ),
  code({ node, inline, className, children, ...props }: any) {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <SyntaxHighlighter
        style={materialDark}
        language={match[1]}
        PreTag="div"
        {...props}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
};

export type MarkdownProps = {
  children: string | null | undefined;
  className?: string;
};

export default function Markdown({ children, className }: MarkdownProps) {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={components}
      className={className}
    >
      {children}
    </ReactMarkdown>
  );
}
