import {
  useCreatorQuestionCorrectAnswerData,
  useCreatorQuestionDetailData,
} from '@lib/web/editor';

import { TrueFalseNotGivenValue } from '../types';

export const useData = (questionId: string) => {
  const { answerComponents } = useCreatorQuestionDetailData(questionId);

  const { correctAnswerIdSet } =
    useCreatorQuestionCorrectAnswerData(questionId);

  const choiceValues: TrueFalseNotGivenValue[] = answerComponents.map(
    (answerComponent) => ({
      id: answerComponent.id,
      selected: correctAnswerIdSet.has(answerComponent.id),
    })
  );

  return {
    choiceValues,
  };
};
