import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { IconListLayoutItemObj } from '../types';

export default function useSuggestIconListItem(
  dataset: IconListLayoutItemObj[],
  enabled: boolean
) {
  const [suggestedIndex, setSuggestedIndex] = useState(-1);

  useEffect(() => {
    // initial
    if (enabled && dataset.length) {
      setSuggestedIndex(0);
    } else {
      setSuggestedIndex(-1);
    }
  }, [dataset, enabled]);

  useHotkeys(
    'down',
    (ev) => {
      ev.preventDefault();
      if (dataset[suggestedIndex + 1]) {
        setSuggestedIndex(suggestedIndex + 1);
      }
    },
    [suggestedIndex, dataset],
    {
      enableOnFormTags: ['input'],
      enabled,
    }
  );

  useHotkeys(
    'up',
    (ev) => {
      ev.preventDefault();
      if (dataset[suggestedIndex - 1]) {
        setSuggestedIndex(suggestedIndex - 1);
      }
    },
    [suggestedIndex, dataset],
    {
      enableOnFormTags: ['input'],
      enabled,
    }
  );

  return {
    suggestedIndex,
    setSuggestedIndex,
    suggestedItem: dataset[suggestedIndex],
  };
}
