import React from 'react';

export default function ChatSendCoin({ ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.46967 15.4697C3.17678 15.7626 3.17678 16.2374 3.46967 16.5303C3.76256 16.8232 4.23744 16.8232 4.53033 16.5303L3.46967 15.4697ZM16.75 4C16.75 3.58579 16.4142 3.25 16 3.25H9.25C8.83579 3.25 8.5 3.58579 8.5 4C8.5 4.41421 8.83579 4.75 9.25 4.75H15.25V10.75C15.25 11.1642 15.5858 11.5 16 11.5C16.4142 11.5 16.75 11.1642 16.75 10.75V4ZM4.53033 16.5303L16.5303 4.53033L15.4697 3.46967L3.46967 15.4697L4.53033 16.5303Z"
        fill="currentColor"
      />
    </svg>
  );
}
