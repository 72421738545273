export const enum AsyncTaskType {
  Misc = 0,
  CreatorQuestionEditor = 1,
  CreatorQuestionAdmin = 2,
}

export const enum AsyncTaskSubType {
  Unknown = 0,
  CreatorQuestionPublish = 11,
  CreatorQuestionApprove = 12,
  ClubAnswerFormatPref = 21,
}

export const enum AsyncTaskPriority {
  Default = 0,
  High = 1,
  Urgent = 2,
}

export const enum AsyncTaskStatus {
  Unknown = 0,
  Created = 1,
  Pending = 2,
  Processing = 3,
  Finished = 4,
  Cancelled = -1,
  Failed = -2,
}
