import React from 'react';

export default function PropertyTypeIcon({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M10.75 8.94118C10.75 10.0133 9.91053 10.8824 8.875 10.8824C7.83947 10.8824 7 10.0133 7 8.94118C7 7.86909 7.83947 7 8.875 7C9.91053 7 10.75 7.86909 10.75 8.94118Z"
        fill="currentColor"
      />
      <path
        d="M17 8.94118C17 10.0133 16.1605 10.8824 15.125 10.8824C14.0895 10.8824 13.25 10.0133 13.25 8.94118C13.25 7.86909 14.0895 7 15.125 7C16.1605 7 17 7.86909 17 8.94118Z"
        fill="currentColor"
      />
      <path
        d="M15.75 12.8235C16.4404 12.8235 17.016 13.4119 16.8457 14.1046C16.2951 16.3438 14.3344 18 12 18C9.66565 18 7.70493 16.3438 7.15428 14.1046C6.98397 13.4119 7.55964 12.8235 8.25 12.8235H15.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
