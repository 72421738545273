import generateHook, { generateInfiniteHook } from './swr.helper';

export const useQBankQuestion = (quizQuestionId: string) =>
  generateHook<GetQBankQuizPracticeQuestionRes>(
    quizQuestionId
      ? `v2/qbank/quiz-question/practice/${quizQuestionId}`
      : undefined,
    {
      immutable: true,
      withPublicAccessKey: true,
    }
  );

export const useQBankQuestionByShortId = (questionShortId: string) =>
  generateHook<GetQBankQuestionByShortIdRes>(
    questionShortId
      ? `v2/qbank/quiz-question/by-shortId/${questionShortId}`
      : undefined,
    {
      immutable: true,
      withPublicAccessKey: true,
    }
  );

const getQuizQuestionsApiKey = ({
  sectionId,
  quizQuestionId,
  clubSlug,
  isHashTags,
  isDifficulty,
  isAnswerFormatType,
  isPhotoUrl,
  isCreatorInfo,
  isGetUserList,
  sortByMistakeCount,
  page,
  pageSize,
}: {
  sectionId?: string;
  quizQuestionId?: string;
  clubSlug?: string;
  isHashTags?: boolean;
  isDifficulty?: boolean;
  isAnswerFormatType?: boolean;
  isPhotoUrl?: boolean;
  isCreatorInfo?: boolean;
  isGetUserList?: boolean;
  sortByMistakeCount?: 'asc' | 'desc';
  page?: number;
  pageSize?: number;
}) => {
  if (!sectionId && !clubSlug) return undefined;

  const params = new URLSearchParams();
  if (sectionId) {
    params.set('sectionId', sectionId);
  }
  if (quizQuestionId) {
    params.set('qBankQuizQuestionId', quizQuestionId);
  }
  if (clubSlug) {
    params.set('clubSlug', clubSlug);
  }
  if (isHashTags) {
    params.set('isHashTags', 'true');
  }
  if (isDifficulty) {
    params.set('isDifficulty', 'true');
  }
  if (isAnswerFormatType) {
    params.set('isAnswerFormatType', 'true');
  }
  if (isPhotoUrl) {
    params.set('isPhotoUrl', 'true');
  }
  if (isCreatorInfo) {
    params.set('isCreatorInfo', 'true');
  }
  if (isGetUserList) {
    params.set('isGetUserList', 'true');
  }
  if (sortByMistakeCount) {
    params.set('sortByMistakeCount', sortByMistakeCount);
  }
  if (page) {
    params.set('page', `${page}`);
  }
  if (pageSize) {
    params.set('limit', `${pageSize}`);
  }
  return `v2/qbank/quiz-question?${params.toString()}`;
};

export const useQBankQuestions = ({
  sectionId,
  questionId,
  isGetUserList,
  page,
  pageSize = 30, // default page size
}: {
  sectionId?: string;
  questionId?: string;
  isGetUserList?: boolean;
  page?: number;
  pageSize?: number;
}) =>
  generateInfiniteHook<GetQBankQuizQuestionsRes>(
    (index) =>
      getQuizQuestionsApiKey({
        sectionId,
        quizQuestionId: questionId,
        page: page || index + 1,
        pageSize,
        isGetUserList,
      }),
    pageSize,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      paginate: true,
      withPublicAccessKey: true,
    }
  );

export const useQBankQuestionList = ({
  sectionId,
  questionId,
  clubSlug,
  isHashTags,
  isDifficulty,
  isAnswerFormatType,
  isPhotoUrl,
  isCreatorInfo,
  sortByMistakeCount,
  page,
  pageSize = 30,
}: {
  sectionId?: string;
  questionId?: string;
  clubSlug?: string;
  isHashTags?: boolean;
  isDifficulty?: boolean;
  isAnswerFormatType?: boolean;
  isPhotoUrl?: boolean;
  isCreatorInfo?: boolean;
  sortByMistakeCount?: 'asc' | 'desc';
  page?: number;
  pageSize?: number;
}) =>
  generateInfiniteHook<GetQBankQuizQuestionsRes>(
    (index) =>
      getQuizQuestionsApiKey({
        sectionId,
        quizQuestionId: questionId,
        clubSlug,
        isHashTags,
        isDifficulty,
        isAnswerFormatType,
        isPhotoUrl,
        isCreatorInfo,
        sortByMistakeCount,
        page: page || index + 1,
        pageSize,
      }),
    pageSize,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      paginate: true,
      auth: true,
    }
  );

export const useQBankQuestionStatistic = (quizQuestionId: NullableString) =>
  generateHook<GetQBankQuizQuestionStatisticRes>(
    quizQuestionId
      ? `v2/qbank/quiz-question/${quizQuestionId}/statistic`
      : null,
    {
      immutable: true,
      withPublicAccessKey: true,
    }
  );

export const useQBankQuestionTags = (quizQuestionId: string) =>
  generateHook<GetQBankQuizQuestionTagRes>(
    `v2/qbank/quiz-question/${quizQuestionId}/tags`,
    {
      immutable: true,
      paginate: true,
      withPublicAccessKey: true,
    }
  );

export const useQBankSectionSummary = (sectionId: string) =>
  generateHook<GetQBankSectionSummaryRes>(
    sectionId ? `v2/qbank/section/${sectionId}/summary` : undefined,
    {
      immutable: true,
      withPublicAccessKey: true,
    }
  );

const getQBankQuestionReviewDetailApiKey = (shortId?: string, id?: string) => {
  if (shortId)
    return `/v2/qbank/quiz-question/review-detail?shortId=${shortId}`;
  if (id) return `/v2/qbank/quiz-question/review-detail?id=${id}`;
  return undefined;
};

export const useQBankQuestionReviewDetail = (shortId?: string, id?: string) =>
  generateHook<GetQBankQuestionReviewDetailRes>(
    getQBankQuestionReviewDetailApiKey(shortId, id),
    {
      auth: true,
      immutable: true,
    }
  );

export const useQBankQuestionComments = (
  questionId?: string,
  page = 1,
  pageSize = 100
) =>
  generateHook<GetQBankQuestionCommentRes>(
    questionId
      ? `/v2/qbank/quiz-question/comment?id=${questionId}&page=${page}&limit=${pageSize}`
      : undefined,
    {
      auth: true,
      immutable: true,
      paginate: true,
    }
  );

export const useQBankQuestionUserList = (quizQuestionId: NullableString) => {
  return generateHook<GetQBankQuestionUserListRes>(
    quizQuestionId ? `/v2/qbank/quiz-question/${quizQuestionId}/user` : null,
    {
      immutable: true,
      withPublicAccessKey: true,
    }
  );
};

const getMemberInfoKey = (
  userId: NullableString = '',
  memberId: NullableString = ''
) => {
  if (userId) return `v2/qbank/member/info?userId=${userId}&isGetNft=true`;
  if (memberId)
    return `v2/qbank/member/info?memberId=${memberId}&isGetNft=true`;
  return null;
};

export const useQBankMemberInfo = (
  userId: NullableString = '',
  memberId: NullableString = ''
) =>
  generateHook<GetMemberInfoRes>(getMemberInfoKey(userId, memberId), {
    immutable: true,
    withPublicAccessKey: true,
  });
