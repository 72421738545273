import { formatDateString } from '@front/helper';
import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';

import { IaStreakViewSlug } from '../../types/enums';

import generateHook, { generateInfiniteHook } from './swr.helper';

export const useSummaryTrends = (sectionId: NullableString) =>
  generateHook<GetSummaryTrendsRes>(
    sectionId ? `v2/summary/trend?sectionId=${sectionId}` : undefined,
    {
      auth: true,
    }
  );

export const useSummaryInsight = (sectionId: NullableString) =>
  generateHook<GetSummaryInsightRes>(
    sectionId ? `v2/summary/insight?sectionId=${sectionId}` : undefined,
    {
      auth: true,
    }
  );

type SummaryTrendRecordParams = WithDate<
  WithNullable<GetSummaryTrendRecordReq, 'sectionId'>,
  'customMonth' | 'customDate' | 'startDate' | 'endDate'
>;

export const useSummaryTrendRecord = ({
  sectionId,
  type,
  customMonth,
  startDate,
  endDate,
  timeZoneOffset,
  customDate,
}: SummaryTrendRecordParams) => {
  const searchParams = new URLSearchParams();

  if (!sectionId || !type) {
    return generateHook<GetSummaryTrendRecordRes>(undefined, {
      auth: true,
      paginate: true,
    });
  }

  searchParams.set('sectionId', sectionId);
  searchParams.set('type', type);

  if (customMonth)
    searchParams.set('customMonth', formatDateString(customMonth));
  if (startDate) searchParams.set('startDate', formatDateString(startDate));
  if (endDate) searchParams.set('endDate', formatDateString(endDate));
  if (timeZoneOffset) searchParams.set('timeZoneOffset', timeZoneOffset);
  if (customDate) searchParams.set('customDate', formatDateString(customDate));

  return generateHook<GetSummaryTrendRecordRes>(
    `v2/summary/trend/record?${searchParams.toString()}`,
    {
      auth: true,
      paginate: true,
    }
  );
};

const getSummaryStreakAPIKey = (sectionId: string, customDate?: string) => {
  const searchParams = new URLSearchParams();
  if (sectionId) searchParams.set('sectionId', sectionId);
  if (customDate) searchParams.set('customDate', customDate);

  return `v2/summary/streak?${searchParams.toString()}`;
};

export const useSummaryStreak = ({
  sectionId,
  customDate,
}: {
  sectionId: NullableString;
  customDate?: string;
}) => {
  return generateHook<GetSummaryStreakRes>(
    sectionId ? getSummaryStreakAPIKey(sectionId, customDate) : undefined,
    {
      auth: true,
    }
  );
};

const getSummaryGoalAPIKey = (sectionId: string, customDate?: string) => {
  const searchParams = new URLSearchParams();
  if (sectionId) searchParams.set('sectionId', sectionId);
  if (customDate) searchParams.set('customDate', customDate);

  return `v2/summary/goal?${searchParams.toString()}`;
};

export const useSummaryGoal = ({
  sectionId,
  customDate,
}: {
  sectionId: NullableString;
  customDate?: string;
}) => {
  return generateHook<GetSummaryGoalRes>(
    sectionId ? getSummaryGoalAPIKey(sectionId, customDate) : undefined,
    {
      auth: true,
    }
  );
};

type GetIaStreakResMap = {
  [IaStreakViewSlug.CalendarRhsStreakDefault]: GetIaCalendarRhsStreakDefaultRes;
};

export function useIaStreaks<
  K = GetIaStreakDefaultRes,
  T extends IaStreakViewSlug | undefined = undefined
>(ev: Omit<IaSearchReq<T>, 'page' | 'pageSize'> = {}, limit = 0) {
  return generateInfiniteHook<
    T extends IaStreakViewSlug ? GetIaStreakResMap[T] : K
  >(
    (index) =>
      `v2/ia/streaks?${getIaQueryString({
        ...ev,
        page: index + 1,
        limit,
      })}`,
    limit,
    {
      auth: true,
    }
  );
}
