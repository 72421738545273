import React from 'react';

export default function EmojiFavoriteSolid({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.05 4C14.484 4 12.981 4.70627 12 5.81362C11.019 4.70627 9.516 4 7.95 4C5.178 4 3 6.10136 3 8.79564C3 12.0828 6.06 14.7771 10.695 18.849L12 20L13.305 18.849C17.94 14.7771 21 12.0828 21 8.79564C21 6.10136 18.822 4 16.05 4Z"
        fill="currentColor"
      />
    </svg>
  );
}
