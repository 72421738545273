import React from 'react';

export default function EmojiActionGenerateLaTeX({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_9788_42656)">
        <rect
          height="22"
          width="22"
          fill="url(#EmojiActionGenerateLaTeX)"
          x="1"
          y="1"
        />
      </g>
      <defs>
        <pattern
          height="1"
          id="EmojiActionGenerateLaTeX"
          width="1"
          patternContentUnits="objectBoundingBox"
        >
          <use xlinkHref="#image0_9788_42656" transform="scale(0.00625)" />
        </pattern>
        <clipPath id="clip0_9788_42656">
          <rect
            height="18"
            width="18"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
        <image
          height="160"
          id="image0_9788_42656"
          width="160"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAQAAAAhxq+mAAADx0lEQVR42u3d34oTTRAF8DPVmY0m+I8FxVUWxE+88gl8AB9AfErRW59DBEFQhKCrK4t+iom6k5n2Qrx2O9PVnVSdc50Jnd/U9JB016SJYMZESEBAAhKQgAwBCUhAAjIEJCABCcgQkIAEJCBDQALuQiZnfeEDvTEc4DEOsU47KE5mi/mjeKRRUz3+x7PcgIppcRf7aYcMmGL/fNvmXxJrMOAk4WxuA2DEMhUQCJDlEGP26lvjBEs09ufAiKhy8Z5gmYTCm8goPgKO5CPgSD4CjuQj4Eg+Ao7kcw84ls854Hg+14A5+BwD5uFzC5iLzylgPj6XgDn5HALm5XMHmJvPGWB+PleAGnyOAHX43ABq8TkB1ONzAajJ5wBQl888oDafcUB9PtOAJfgMA5bhMwtYis8oYDk+k4Al+QwCluUzB1iazxhgeT5TgDX4DAHW4TMDWIvPCGA9PhOANfkMANbl23nA2nw7DlifbzsAV5v1zITYr2rzpbR6HWAPg8IIOhyi3eTAX+3X29/b0GqUVTzFUW7Ax7iFTmGwA1pcTb9wOhxf7Z+ETqMAYzt/i/u5Af9L/5iaGRAnzQ2dd55iP+SfA1fbdgtp1N45QFb5AR0lpROUgPwqR0ACEpAhIAEJSECGgAQkIAEZAhKQgARkCEhAAhKQ+WfOvC4c0UPnbyQbyEbnsVfZKPFnpT/mB8TsMlqVITf4gZ8brPJewFRlPBFThFl2wJuv7/Shyz/gMHTt82urSUisknO4t75y/Etla0fTrt5mBzx4dH5vPUj26tvrPh5+eSoH6ZP350+ThxcWvcrmokun2QGHo/xzToMJFnjZDV3Y4EJbdu/eXD+5iN7rXbhBi/d4gdNZ2GibS2iG2Qd8Q/AJ+JevG/E0ekGP2oSyu3zbQSi7zLcNhLLbfPUJZdf5ahPK7vPVJRQLfDUJxQZfPUKxwleLUOzw1SEUS3w1CMUWX3lCscZXmlDs8ZUlFIt8JQnFJl85QrHKV4pQ7PKVIRTLfCUIxTafPqFY59MmFPt8uoTigU+TUHzw6RGKFz4tQvHDp0Monvg0CMUXX35C8caXm1D88eUlFI98OQnFJ18+QvHKl4tQ/PLlIRTPfDkIxTffeELxzjeWUMg3jlDIN45QyDeOcJLCt1DiS+8ETeuoTCMELmp0awa16osN5qmdoBFThHmv8CDfU7zDdVzJD3iM91hDo7Nv6G68unsY1mmdeDL5vvjWaTwKuccK87NPbBFMtV9jGAISkIAEJCBDQAISkIAMAQlIQAIyBCQgAQnIELBUfgNtru+EZ4XCVwAAAABJRU5ErkJggi5wbmc="
        />
      </defs>
    </svg>
  );
}
