import { Box, Theme, Typography } from '@mui/material';

const styles = {
  ratio: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    '& .MuiTypography-h6': {
      fontSize: 16,
      fontWeight: '700 !important',
    },
  },
  answerRate: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
  },
};
export default function PracticeSelectedRatio({
  display,
}: {
  display: string | number;
}) {
  return (
    <Box className="option-ratio" sx={styles.ratio}>
      <Typography variant="h6" sx={styles.answerRate}>
        {display}
        <Typography variant="caption" fontWeight="bold">
          %
        </Typography>
      </Typography>
    </Box>
  );
}
