import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Icon from '@lib/ia/src/components/Icon';

import { TableLayoutHeadDefaultCell } from '../../types';

export type IaHeadDefaultCellProps = TableLayoutHeadDefaultCell;

const styles = {
  root: {
    px: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    height: '100%',
  },
};
export default function IaDefaultHeadCell({
  icon,
  label,
}: IaHeadDefaultCellProps) {
  return (
    <Box sx={styles.root}>
      {icon && <Icon name={icon} width={16} height={16} />}
      {label && <Typography variant="body2">{label}</Typography>}
    </Box>
  );
}
