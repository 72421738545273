import React from 'react';

export default function EditorPolynomial({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect height="2" width="18" fill="currentColor" rx="1" x="3" y="19" />
      <path
        d="M6 3C6.82843 3 7.5 3.67157 7.5 4.5C7.5 6.66257 7.87811 9.46722 8.74036 11.6749C9.6465 13.9949 10.7923 15 12 15C13.2077 15 14.3535 13.9949 15.2596 11.6749C16.1219 9.46722 16.5 6.66257 16.5 4.5C16.5 3.67157 17.1716 3 18 3C18.8284 3 19.5 3.67157 19.5 4.5C19.5 6.92564 19.0872 10.121 18.0541 12.7663C17.0648 15.2992 15.2105 18 12 18C8.78945 18 6.93522 15.2992 5.94594 12.7663C4.91275 10.121 4.5 6.92564 4.5 4.5C4.5 3.67157 5.17157 3 6 3Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
