import React, { Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';

const FormLayoutMethodsContext = React.createContext<{
  methods: UseFormReturn;
  setMethods: Dispatch<SetStateAction<UseFormReturn>>;
}>({
  methods: {} as UseFormReturn,
  setMethods: () => ({} as UseFormReturn),
});
export default FormLayoutMethodsContext;
