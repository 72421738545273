export default function ProfilePlaylist({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5625 7.4898C12.5625 7.03191 12.8143 6.66071 13.125 6.66071H20.4375C20.7482 6.66071 21 7.03191 21 7.4898C21 7.94769 20.7482 8.31888 20.4375 8.31888H13.125C12.8143 8.31888 12.5625 7.94769 12.5625 7.4898Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4375 12.4643C11.4375 12.0064 11.6893 11.6352 12 11.6352H20.4375C20.7482 11.6352 21 12.0064 21 12.4643C21 12.9222 20.7482 13.2934 20.4375 13.2934H12C11.6893 13.2934 11.4375 12.9222 11.4375 12.4643Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3125 17.4388C10.3125 16.9809 10.5643 16.6097 10.875 16.6097H20.4375C20.7482 16.6097 21 16.9809 21 17.4388C21 17.8967 20.7482 18.2679 20.4375 18.2679H10.875C10.5643 18.2679 10.3125 17.8967 10.3125 17.4388Z"
        fill="currentColor"
      />
      <path
        d="M5.4486 11.9318C5.53285 11.653 5.68029 11.3882 5.89092 11.1373C6.10155 10.8864 6.39381 10.6354 6.76768 10.3845C7.12575 10.1448 7.38904 9.93844 7.55755 9.76559C7.80146 9.50732 7.96828 9.21028 7.96828 8.82883C7.96828 8.59464 7.86823 8.41064 7.66813 8.27682C7.46803 8.13742 7.20737 8.06772 6.88616 8.06772C6.48596 8.06772 6.05153 8.14299 5.58287 8.29354C5.11948 8.44409 4.6245 8.66713 4.09792 8.96266L3 6.68767C3.59504 6.32524 4.22956 6.03808 4.90359 5.82619C5.57761 5.60873 6.31219 5.5 7.10732 5.5C8.30266 5.5 9.22944 5.78158 9.88766 6.34475C10.5459 6.90235 10.875 7.63837 10.875 8.55282C10.875 8.98775 10.8118 9.38643 10.6854 9.74886C10.5591 10.1113 10.359 10.4514 10.0851 10.7693C9.81131 11.0871 9.4506 11.3993 9.00301 11.706C8.68706 11.9179 8.43694 12.0991 8.25263 12.2497C8.0736 12.4002 7.94458 12.5452 7.8656 12.6846C7.72092 12.9582 7.80241 14.1393 7.80241 14.4431H5.36912C5.36912 13.8895 5.29687 12.4674 5.4486 11.9318Z"
        fill="currentColor"
      />
      <path
        d="M4.99047 16.9025C4.99047 16.3114 5.15108 15.896 5.47229 15.6563C5.79877 15.4165 6.19371 15.2966 6.6571 15.2966C7.09942 15.2966 7.48119 15.4165 7.80241 15.6563C8.12889 15.896 8.29213 16.3114 8.29213 16.9025C8.29213 17.4657 8.12889 17.8727 7.80241 18.1236C7.48119 18.3745 7.09942 18.5 6.6571 18.5C6.19371 18.5 5.79877 18.3745 5.47229 18.1236C5.15108 17.8727 4.99047 17.4657 4.99047 16.9025Z"
        fill="currentColor"
      />
    </svg>
  );
}
