import { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';

import Icon from '../../../components/Icon';

export default function NumberCardIcon({
  sx,
  children,
  size = 16,
  boxHeight = 24,
  ...rest
}: PropsWithChildren<BoxProps & { size?: number; boxHeight?: number }>) {
  return (
    <Box
      sx={sx}
      height={boxHeight}
      display="flex"
      alignItems="center"
      {...rest}
    >
      {typeof children === 'string' ? (
        <Icon name={children} width={size} height={size} />
      ) : (
        children
      )}
    </Box>
  );
}
