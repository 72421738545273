import { v4 } from 'uuid';

import {
  ChallengeStatus,
  QuizChallengeDetailStatus,
  QuizChallengeStatus,
} from '../../../types/enums';

const leagueNotifications: GetNotificationRes[] = [
  {
    id: '108101a3-7a56-434b-bda8-3cb141297cc1',
    tag: 'league.enter.promotion.zone.club',
    title:
      'Congratulations! You’re in the Alpha League promotion zone. Keep up the good work by practicing 💪',
    body: 'Congratulations! You’re in the Alpha League promotion zone. Keep up the good work by practicing 💪',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        body: {
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          rank: 2,
          clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
          clubSlug: 'act-math',
          clubIcon: '',
          clubName: 'ACT Math',
        },
        cta: [
          {
            type: 'cta.league.rank',
            rank: 2,
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 1,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
        ],
      },
    },
    scheduledAt: '2024-01-09T02:03:07.351Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '0b8ed77f-55c1-4dca-af2c-c96740c7715f',
    tag: 'league.round.finish.stay.tier.club',
    title:
      'You achieved Rank 14 and kept your position in Alpha League. Keep practicing to get promoted! 💪',
    body: 'You achieved Rank 14 and kept your position in Alpha League. Keep practicing to get promoted! 💪',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        body: {
          prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          prevLeagueName: 'Alpha',
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          rank: 14,
          clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
          clubSlug: 'act-math',
          clubIcon: '',
          clubName: 'ACT Math',
        },
        cta: [
          {
            type: 'cta.league.final.rank',
            rank: 14,
            prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            prevLeagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 2,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
];

const challengeNotification: GetNotificationRes[] = [
  {
    id: 'dc824b4c-aa52-461d-bab1-96febb6c5b4a',
    tag: 'challenge.ending.scheduler.winner.club',
    title:
      "Congratulations! 🏆 A challenge has ended, and you are the champion in mitch.wu+test-editor's ACT Math Challenge #1",
    body: "Congratulations! 🏆 A challenge has ended, and you are the champion in <a href='/club/act-math/challenge/37e3c785-0a15-4478-862f-bdef8d7bb606'><img class='avatar-challenge' src='' />mitch.wu+test-editor's ACT Math Challenge #1</a>",
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
        clubName: 'ACT Math',
        clubIcon: '',
        body: {
          clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
          clubSlug: 'act-math',
          quizId: '00e319f6-8ae4-4286-adbd-a331b0fe71d2',
          quizShortId: 'eNmqSzUAzFBW',
          quizName: "Ray Howard's ACT Math Challenge#4",
          ownerUserId: 'bc6b84b8-8290-4f2c-93cd-3da19dfceb83',
          ownerName: 'TutorA',
          ownerAvatar: 'https://picsum.photos/id/64/200/200',
          ownerChallengeStatus: ChallengeStatus.Expired,
        },
        cta: [
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.user.challenge.status',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            userId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            quizId: '00e319f6-8ae4-4286-adbd-a331b0fe71d2',
            quizShortId: 'eNmqSzUAzFBW',
            userChallengeStatus: ChallengeStatus.Expired,
            quizChallengeId: 'eaf51b0b-3d53-401d-8e9f-626eca524061',
            quizChallengeStatus: QuizChallengeStatus.Expired,
            quizChallengeDetailId: 'a5636cee-b062-4457-8efa-7fc61392d4a0',
            quizChallengeDetailStatus: QuizChallengeDetailStatus.Completed,
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },

  {
    id: 'dc824b4c-aa52-461d-bab1-96febb6c5b4b',
    tag: 'challenge.acceptance.addressee.addressee.club',
    title: "You have joined Harry Trần's ACT Math Challenge #93",
    body: "You have joined <a href='/club/act-math/challenge/37e3c785-0a15-4478-862f-bdef8d7bb606'><img class='avatar-challenge' src='' />Harry Trần's ACT Math Challenge #93</a>",
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
        clubName: 'ACT Math',
        clubIcon: '',
        body: {
          clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
          clubSlug: 'act-math',
          quizId: '90768b86-0670-4cf7-b25c-dd3476280ac1',
          quizShortId: 'vVLuHGQwnhm1',
          quizName: "Harry Trần's ACT Math Challenge #93",
          ownerUserId: 'bc6b84b8-8290-4f2c-93cd-3da19dfceb83',
          ownerName: 'TutorA',
          ownerAvatar: 'https://picsum.photos/id/64/200/200',
          ownerChallengeStatus: ChallengeStatus.Ongoing,
        },
        cta: [
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.user.challenge.status',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            userId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            quizId: '90768b86-0670-4cf7-b25c-dd3476280ac1',
            quizShortId: '96G8czZqidvp',
            userChallengeStatus: ChallengeStatus.Ongoing,
            quizChallengeId: 'eaf51b0b-3d53-401d-8e9f-626eca524061',
            quizChallengeStatus: QuizChallengeStatus.Ongoing,
            quizChallengeDetailId: 'a5636cee-b062-4457-8efa-7fc61392d4a0',
            quizChallengeDetailStatus: QuizChallengeDetailStatus.Accepted,
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },

  {
    id: 'dc824b4c-aa52-461d-bab1-96febb6c5b4c',
    tag: 'challenge.ending.scheduler.challenger.club',
    title:
      "This challenge has ended 🏆 and Harry Trần's ACT Math Challenge #93",
    body: "This challenge has ended 🏆 and @kent.chen is the champion in <a href='/club/act-math/challenge/37e3c785-0a15-4478-862f-bdef8d7bb606'>Harry Trần's ACT Math Challenge #93</a> Feeling competitive? Challenge other competitors.",
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
        clubName: 'ACT Math',
        clubIcon: '',
        body: {
          clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
          clubSlug: 'act-math',
          winnerId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
          winnerNameDyna: 'kent.chen',
          winnerAvatarDyna:
            'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
          quizId: '00e319f6-8ae4-4286-adbd-a331b0fe71d2',
          quizShortId: 'eNmqSzUAzFBW',
          quizName: "Harry Trần's ACT Math Challenge #93",
          ownerUserId: 'bc6b84b8-8290-4f2c-93cd-3da19dfceb83',
          ownerName: 'TutorA',
          ownerAvatar: 'https://picsum.photos/id/64/200/200',
          ownerChallengeStatus: ChallengeStatus.Expired,
        },
        cta: [
          {
            type: 'cta.recommend.challenger.list',
            users: [
              {
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                clubSlug: 'act-math',
                clubName: 'Act Math',
                userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
                userName: 'Kent Chen',
                userAvatar:
                  'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueName: 'Incendium',
                leagueRank: 1,
                isFollower: false,
                isFollowing: true,
              },
              {
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                clubSlug: 'act-math',
                clubName: 'Act Math',
                userId: '5133e1f7-3db3-4e99-9229-889bc1ab647c',
                userName: 'Harry Tran',
                userAvatar: undefined,
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueName: 'Alpha',
                leagueRank: 2,
                isFollower: true,
                isFollowing: true,
              },
            ],
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.user.challenge.status',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            userId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            quizId: '00e319f6-8ae4-4286-adbd-a331b0fe71d2',
            quizShortId: 'eNmqSzUAzFBW',
            userChallengeStatus: ChallengeStatus.Expired,
            quizChallengeId: 'eaf51b0b-3d53-401d-8e9f-626eca524061',
            quizChallengeStatus: QuizChallengeStatus.Expired,
            quizChallengeDetailId: 'a5636cee-b062-4457-8efa-7fc61392d4a0',
            quizChallengeDetailStatus: QuizChallengeDetailStatus.Completed,
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },

  {
    id: 'dc824b4c-aa52-461d-bab1-96febb6c5b5a',
    tag: 'challenge.almost.end.scheduler.challenger.club',
    title:
      "Don’t miss out on the chance to prove your skills and claim your victory! You have 24 hours left to conquer Harry Trần's ACT Math Challenge #93",
    body: "Don’t miss out on the chance to prove your skills and claim your victory! You have 24 hours left to conquer <a href='/club/act-math/challenge/37e3c785-0a15-4478-862f-bdef8d7bb606'><img class='avatar-challenge' src='' />Harry Trần's ACT Math Challenge #93</a>",
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
        clubName: 'ACT Math',
        clubIcon: '',
        body: {
          clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
          clubSlug: 'act-math',
          quizId: '8377e2a1-3110-4942-a424-63c64eb0640e',
          quizShortId: 'NdGDDrSryKDn',
          quizName: "Harry Trần's ACT Math Challenge #95",
          ownerUserId: 'bc6b84b8-8290-4f2c-93cd-3da19dfceb83',
          ownerName: 'TutorA',
          ownerAvatar: 'https://picsum.photos/id/64/200/200',
          ownerChallengeStatus: ChallengeStatus.Ongoing,
        },
        cta: [
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.user.challenge.status',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            userId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            quizId: '8377e2a1-3110-4942-a424-63c64eb0640e',
            quizShortId: 'NdGDDrSryKDn',
            userChallengeStatus: ChallengeStatus.Ongoing,
            quizChallengeId: 'eaf51b0b-3d53-401d-8e9f-626eca524061',
            quizChallengeStatus: QuizChallengeStatus.Ongoing,
            quizChallengeDetailId: 'a5636cee-b062-4457-8efa-7fc61392d4a0',
            quizChallengeDetailStatus: QuizChallengeDetailStatus.Accepted,
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },

  {
    id: '66b312bc-5063-4431-a344-42c6c64e38aa',
    tag: 'challenge.good.result.member.club',
    title:
      "Impressive score on Harry Trần's ACT Math Challenge #93 Feeling competitive? Challenge other competitors.",
    body: "Impressive score on <a href='/club/act-math/challenge/37e3c785-0a15-4478-862f-bdef8d7bb606'><img class='avatar-challenge' src='' />Harry Trần's ACT Math Challenge #93</a> Feeling competitive? Challenge other competitors.",
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        clubId: 'bea62d9c-2a6d-476d-9a85-f664d745f96a',
        clubName: 'ACT Math',
        clubIcon: '',
        body: {
          clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
          clubSlug: 'act-math',
          quizId: '90768b86-0670-4cf7-b25c-dd3476280ac1',
          quizShortId: 'vVLuHGQwnhm1',
          quizName: "Harry Trần's ACT Math Challenge #93",
          ownerUserId: 'bc6b84b8-8290-4f2c-93cd-3da19dfceb83',
          ownerName: 'TutorA',
          ownerAvatar: 'https://picsum.photos/id/64/200/200',
          ownerChallengeStatus: ChallengeStatus.Ongoing,
        },
        cta: [
          {
            type: 'cta.recommend.challenger.list',
            users: [
              {
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                clubSlug: 'act-math',
                clubName: 'Act Math',
                userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
                userName: 'Kent Chen',
                userAvatar:
                  'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueName: 'Spectus',
                leagueRank: 1,
                isFollower: false,
                isFollowing: true,
              },
              {
                clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
                clubSlug: 'act-math',
                clubName: 'Act Math',
                userId: '5133e1f7-3db3-4e99-9229-889bc1ab647c',
                userName: 'Harry Tran',
                userAvatar: undefined,
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueName: 'Alpha',
                leagueRank: 2,
                isFollower: true,
                isFollowing: true,
              },
            ],
          },
          {
            type: 'cta.club.home',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            clubIcon: '',
            clubName: 'ACT Math',
          },
          {
            type: 'cta.user.challenge.status',
            clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
            clubSlug: 'act-math',
            userId: '8a9730b1-2e83-4576-8165-6b1187f3549f',
            quizId: '00e319f6-8ae4-4286-adbd-a331b0fe71d2',
            quizShortId: 'eNmqSzUAzFBW',
            userChallengeStatus: ChallengeStatus.Expired,
            quizChallengeId: 'eaf51b0b-3d53-401d-8e9f-626eca524061',
            quizChallengeStatus: QuizChallengeStatus.Expired,
            quizChallengeDetailId: 'a5636cee-b062-4457-8efa-7fc61392d4a0',
            quizChallengeDetailStatus: QuizChallengeDetailStatus.Completed,
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
];

const streakNotification: GetNotificationRes[] = [
  {
    id: '91c5a178-eda6-4330-b5ea-fd783b1032a3',
    tag: 'streak.reminder.daily.member.club',
    title: 'Complete at least 1 question to achieve your streak today 🔥',
    body: 'Complete at least 1 question to achieve your streak today 🔥',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
      },
    },
    scheduledAt: '2024-01-11T10:00:26.937Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '50bc7bb5-3126-4575-bf9c-59dd20905291',
    tag: 'streak.reminder.broken.member.club',
    title: 'Missed your practice yesterday? Streak resets to 0 🥶',
    body: 'Missed your practice yesterday? Streak resets to 0 🥶',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.streak.reminder',
            streakCount: 1,
            streakDateStart: null,
            streakDateEnd: '2024-01-09T23:59:59.999Z',
          },
        ],
      },
    },
    scheduledAt: '2024-01-10T01:00:30.265Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: 'c083849b-7714-4e14-a6b8-02db0336efc3',
    tag: 'streak.achieved.single.day.member.club',
    title:
      'Streak achieved today! ✅ Keep it up, set records, and own your learning journey! 🚀✨',
    body: 'Streak achieved today! ✅ Keep it up, set records, and own your learning journey! 🚀✨',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.streak.achieved',
            streakCount: 1,
            streakDateStart: '2024-01-08T00:00:00.000Z',
            streakDateEnd: '2024-01-08T23:59:59.999Z',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T03:09:16.073Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '4c60714a-acaf-44a9-ab6d-ab5c266e4e02',
    tag: 'streak.achieved.multiple.day.member.club',
    title:
      'Congratulations on 7 streaks! Keep the streak alive and set new records! 🎉',
    body: 'Congratulations on 7 streaks! Keep the streak alive and set new records! 🎉',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {
          streakCount: 7,
        },
        cta: [
          {
            type: 'cta.streak.reminder',
            streakCount: 7,
            streakDateStart: '2024-01-05T00:00:00.000Z',
            streakDateEnd: '2024-01-12T23:59:59.999Z',
          },
        ],
      },
    },
    scheduledAt: '2024-01-12T07:14:07.779Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
];

const emailNotification: GetNotificationRes[] = [
  {
    id: '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed',
    tag: 'email.verification.reminder.global',
    title:
      'Check and verify your email to continue full access or resend below.',
    body: 'Check and verify your email to continue full access or resend below.',
    data: {
      icon: 'email',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.email.verification.remind',
          },
        ],
      },
    },
    scheduledAt: '2024-01-11T10:00:26.937Z',
    isRead: false,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
  {
    id: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d',
    tag: 'email.verification.success.global',
    title:
      'Your email is successfully verified ✅ You now have full access. Get started by exploring and joining a club.',
    body: 'Your email is successfully verified ✅ You now have full access. Get started by exploring and joining a club.',
    data: {
      icon: 'email',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.discover.club',
          },
        ],
      },
    },
    scheduledAt: '2024-01-10T01:00:30.265Z',
    isRead: false,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
];

const reactQuestionNotification: GetNotificationRes[] = [
  {
    id: v4(),
    tag: 'someone.react.to.my.question.club',
    title: 'hsinyang reacted ❤️ to your question in',
    body: 'hsinyang reacted ❤️ to your question in',
    data: {
      icon: 'challenge',
      isBot: true,
      payload: {
        senderUserId: 'e6ccf644-5618-4400-96d2-26a764584b9b',
        senderName: 'hsinyang',
        senderAvatar:
          'https://s.gravatar.com/avatar/1072bc3f8acd571300c6013a168e9195?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fah.png',
        senderIndicator: null,
        body: {
          senderUserId: 'e6ccf644-5618-4400-96d2-26a764584b9b',
          senderName: 'hsinyang',
          senderAvatar:
            'https://s.gravatar.com/avatar/1072bc3f8acd571300c6013a168e9195?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fah.png',
          senderIndicator: null,
          markId: 'b1041fbe-be88-47ef-9b2e-44394a39fc24',
          markCode: '❤️',
          quizId: '9041932a-c65f-41c0-84cb-4ed32c8b9b2f',
          quizName: "Hsinyang's Warm Up",
          quizQuestionId: '3bbf6227-dfb5-404d-b9e9-cfd0a7f42c03',
          quizQuestionNo: 1,
          creatorUserId: '5da0e321-f045-4954-a1d5-2dd9249427fd',
          creatorName: 'Your Name',
          creatorAvatar:
            'https://aha-image.s3.ap-northeast-1.amazonaws.com/aha-beta/profile/5da0e321-f045-4954-a1d5-2dd9249427fd/195333a0-f561-11ee-9a76-9350a2105919.png?AWSAccessKeyId=AKIAWXNJ4LEY362XB5FB&Expires=1731028024&Signature=3207Q305KvITB%2BBmZ0xxlat7nDM%3D',
          clubId: 'e135fef7-7249-46ec-af99-6a30dd58ec40',
          clubSlug: 'hsin-test',
          clubName: 'Hsin Test',
          clubIcon: '',
        },
      },
    },
    scheduledAt: '2023-09-03T08:34:58.938Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
    topicSlug: 'act-math-topic',
  },
];

export const mockNotifications: GetNotificationRes[] = [
  ...emailNotification,
  ...challengeNotification,
  ...leagueNotifications,
  ...streakNotification,
  ...reactQuestionNotification,
];
