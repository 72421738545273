import type { ReactNode } from 'react';
import { useRef } from 'react';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { victoryDarkTheme, victoryLightTheme } from '@lib/web/theme';
import { isNumberic, numberFormat, translateFormula } from '@lib/web/utils';
import result from 'lodash/result';
import {
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryStack,
  VictoryZoomContainer,
} from 'victory';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FormulaParser = require('hot-formula-parser').Parser;

const REGEX_PARSER = /{#(.*?)#}/g;
const REGEX_DOLLAR = /(\$[0-9]{1,})/g;

const DataLabel = ({ ...rest }) => {
  const x = rest.scale.x(rest.x);
  const y = rest.scale.y(rest.y);
  return <VictoryLabel {...rest} x={x} y={y} />;
};

export default function ChartContainer({
  width = 300,
  height = 450,
  data,
  mode = 'light',
  children,
  valueAttr = 'y',
  indexAttr = 'x',
  ChartComp = null,
  chartProps = {},
}: {
  width?: number;
  height?: number;
  valueAttr?: string;
  indexAttr?: string;
  mode?: string;
  data: any;
  ChartComp?: any;
  chartProps?: any;
  children?: ReactNode;
}) {
  const theme = mode === 'dark' ? victoryLightTheme : victoryDarkTheme;

  const formulaRef = useRef(new FormulaParser());

  const Wrap = (data?.stack ? VictoryStack : VictoryGroup) as any;
  const wrapProp = data?.stack
    ? {}
    : { offset: data?.offset ? +data.offset : 0 };
  const xTickCount = data?.xTickCount || undefined;
  const yTickCount = data?.yTickCount || undefined;
  const xTickValues =
    data?.xType === 'number' && !!data?.xTickValues
      ? data.xTickValues
          .split(',')
          .filter((d: string) => isNumberic(d.trim()))
          .map((d: string) => +d.trim())
      : undefined;
  const yTickValues =
    data?.yType === 'number' && !!data?.yTickValues
      ? data.yTickValues
          .split(',')
          .filter((d: string) => isNumberic(d.trim()))
          .map((d: string) => +d.trim())
      : undefined;
  const tickNames =
    data?.xType === 'string' && !!data?.xNames
      ? data.xNames.split(',').map((d: string) => d.trim())
      : undefined;
  const xFormat = (data?.xType === 'number' && data?.xFormat) || undefined;
  const yFormat = (data?.yType === 'number' && data?.yFormat) || undefined;

  const formulaValue = (value: string | number) => {
    if (typeof value === 'number') return value;
    const parser = formulaRef.current.parse(
      value.replace('{#', '').replace('#}', '')
    );
    if (!parser.error) return parser.result;
    return value;
  };

  const formulaArrayValue = (valueArr: string[]) =>
    valueArr.map((v) => formulaValue(v));
  const getValues = (v: string, format: string) => {
    let txt = format.replaceAll('val', v);
    txt = txt.replace(REGEX_PARSER, (value: string) => formulaValue(value));
    return txt.replace(
      REGEX_DOLLAR,
      (value: string) => `${numberFormat(value)}`
    );
  };

  const allDataset =
    data?.dataset?.map((d: any) => {
      const dataset =
        d.data.map((v: any, j: number) => {
          const y =
            isNumberic(v[valueAttr]) && v[valueAttr] !== ''
              ? +v[valueAttr]
              : null;
          const x =
            isNumberic(v[indexAttr]) && v[indexAttr] !== '' ? +v[indexAttr] : j;
          return {
            ...v,
            [valueAttr]: y,
            [indexAttr]: x,
          };
        }) || [];

      return dataset;
    }) || [];

  const xGrid = data?.xGrid;
  const yGrid = data?.yGrid;
  const xHide = data?.xHide;
  const yHide = data?.yHide;
  const getFormulaValue = (d: any, formula: any, formulaType: string) => {
    const newFormula = formula.replaceAll('x', d.x).replaceAll('y', d.y);
    const parser = formulaRef.current.parse(newFormula);
    if (!parser.error) return parser.result;
    return d[formulaType];
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <VictoryChart
        theme={theme}
        domainPadding={{
          x: data?.xDomainPadding ? +data.xDomainPadding : 0,
          y: data?.yDomainPadding ? +data.yDomainPadding : 0,
        }}
        style={{ parent: { touchAction: 'inherit' } }}
        width={+width}
        height={+height}
        containerComponent={
          data?.zoomable ? (
            <VictoryZoomContainer
              allowPan={false}
              allowZoom={false}
              zoomDomain={{
                x: [data.xZoom1, data.xZoom2],
                y: [data.yZoom1, data.yZoom2],
              }}
            />
          ) : (
            <VictoryContainer />
          )
        }
      >
        <VictoryAxis
          crossAxis={data?.xCross}
          domain={[
            +result(data, 'xMinDomain', 0),
            +result(data, 'xMaxDomain', 0),
          ]}
          tickCount={xTickCount ? +xTickCount : undefined}
          tickValues={xTickValues ? formulaArrayValue(xTickValues) : tickNames}
          tickFormat={(t: any) => (xFormat ? getValues(t, xFormat) : t)}
          label={data?.xLabel}
          style={{
            grid: {
              ...(xGrid && {
                fill: theme.customize.colors[0],
                stroke: theme.customize.colors[3],
                strokeWidth: 0.5,
              }),
            },
            tickLabels: {
              ...(xHide && {
                fill: 'none',
              }),
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          crossAxis={data?.yCross}
          domain={[
            +result(data, 'yMinDomain', 0),
            +result(data, 'yMaxDomain', 0),
          ]}
          tickCount={yTickCount ? +yTickCount : undefined}
          label={data?.yLabel}
          tickValues={yTickValues ? formulaArrayValue(yTickValues) : []}
          tickFormat={(t: any) => (yFormat ? getValues(t, yFormat) : t)}
          style={{
            grid: {
              ...(yGrid && {
                fill: theme.customize.colors[0],
                stroke: theme.customize.colors[3],
                strokeWidth: 0.5,
              }),
            },
            tickLabels: {
              ...(yHide && {
                fill: 'none',
              }),
            },
          }}
        />
        {!!data?.topLabel && (
          <VictoryLabel
            x={width / 2}
            y={10}
            text={data.topLabel}
            textAnchor="middle"
            style={theme.customize.labels}
          />
        )}
        {!!data?.bottomLabel && (
          <VictoryLabel
            x={width / 2}
            y={height - 10}
            text={data.bottomLabel}
            textAnchor="middle"
            style={theme.customize.labels}
          />
        )}
        {!!data?.leftLabel && (
          <VictoryLabel
            angle={-90}
            x={10}
            y={height / 2}
            text={data.leftLabel}
            textAnchor="middle"
            style={theme.customize.labels}
          />
        )}
        {!!data?.rightLabel && (
          <VictoryLabel
            angle={90}
            x={width - 10}
            y={height / 2}
            text={data.rightLabel}
            textAnchor="middle"
            style={theme.customize.labels}
          />
        )}

        <Wrap {...wrapProp} horizontal={data?.horizontal}>
          {children}
          {!!ChartComp &&
            allDataset.map((d: any, i: number) => (
              <ChartComp
                key={i}
                data={d}
                y={valueAttr}
                x={indexAttr}
                {...chartProps}
              />
            ))}
          {!!data?.showPoints &&
            allDataset.map((d: any, i: number) => (
              <VictoryScatter
                key={i}
                data={d}
                y={valueAttr}
                x={indexAttr}
                symbol={data?.pointSymbol || 'circle'}
                size={data?.pointSize || 5}
              />
            ))}
        </Wrap>
        {!!data?.samples &&
          !!data?.formula &&
          !!data.formulaType &&
          data.formulaType !== 'unset' && (
            <VictoryLine
              interpolation={data.formulaInterpolation || 'linear'}
              samples={+data.samples}
              y={
                data.formulaType === 'y'
                  ? (d) => getFormulaValue(d, data.formula, data.formulaType)
                  : undefined
              }
              x={
                data.formulaType === 'x'
                  ? (d) => getFormulaValue(d, data.formula, data.formulaType)
                  : undefined
              }
            />
          )}
        {!!data?.formulas &&
          data.formulas
            .filter(
              (d: any) =>
                d.samples &&
                d.formula &&
                d.formulaType &&
                d.formulaType !== 'unset'
            )
            .map((d: any, i: number) => (
              <VictoryLine
                key={i}
                interpolation={d.formulaInterpolation || 'linear'}
                samples={+d.samples}
                y={
                  d.formulaType === 'y'
                    ? (f) => getFormulaValue(f, d.formula, d.formulaType)
                    : undefined
                }
                x={
                  d.formulaType === 'x'
                    ? (f) => getFormulaValue(f, d.formula, d.formulaType)
                    : undefined
                }
              />
            ))}
        {!!data?.labels &&
          data.labels.map((d: any, i: string) => {
            if (d.hidePoint) {
              return (
                <DataLabel
                  key={i}
                  verticalAnchor={d.verticalAnchor}
                  textAnchor={d.textAnchor}
                  x={+d[indexAttr]}
                  y={+d[valueAttr]}
                  dx={+d.dx}
                  dy={+d.dy}
                  text={() => d.labels.split(',') || []}
                  style={{
                    ...theme.customize.labels,
                    fontSize: d.fontSize || 12,
                  }}
                />
              );
            }
            return (
              <VictoryScatter
                key={i}
                data={[{ x: +d[indexAttr], y: +d[valueAttr] }]}
                labels={() => d.labels.split(',') || []}
                labelComponent={
                  <VictoryLabel
                    verticalAnchor={d.verticalAnchor}
                    textAnchor={d.textAnchor}
                    dx={+d.dx}
                    dy={+d.dy}
                    style={{
                      ...theme.customize.labels,
                      fontSize: d.fontSize || 12,
                    }}
                    backgroundPadding={2}
                    backgroundStyle={{
                      fill: theme.customize.background,
                      opacity: +d.bgOpacity,
                    }}
                  />
                }
              />
            );
          })}
      </VictoryChart>
      {!!data?.outerLabels &&
        data.outerLabels.map((d: any, i: number) => {
          const lables = d.labels.split(',') || [];
          return (
            <Box
              key={i}
              sx={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: d.textAnchor,
                left: d.x,
                top: d.y,
                fontSize: d.fontSize || 12,
                fontWeight: 300,
                letterSpacing: 0.4,
                lineHeight: 1.5,

                fontFamily: (muiTheme: Theme) =>
                  muiTheme.typography.body1.fontFamily,
                backgroundColor: alpha(
                  theme.customize.background,
                  +d.bgOpacity
                ),
              }}
            >
              {lables.map((label: string, j: number) => (
                <Box
                  key={`${i}-${j}`}
                  dangerouslySetInnerHTML={{ __html: translateFormula(label) }}
                />
              ))}
            </Box>
          );
        })}
    </Box>
  );
}
