import React, { MouseEvent, useContext, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { BlockSchema, DefaultBlockSchema } from '@blocknote/core';
import { SideMenuProps } from '@blocknote/react/src/SideMenu/components/SideMenuPositioner';
import { OtherDrag as OtherDragIcon } from '@front/icon';
import { FloatingMenu } from '@front/ui';

import { TextComposerContext } from '../../../context/TextComposerContext';

import BlockMoreActionDropdown from './BlockMoreActionDropdown';

const styles = {
  tooltipInner: {
    display: 'flex',
    flexDirection: 'column',
  },
  draggableButton: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
};

export default function DragHandle<
  BSchema extends BlockSchema = DefaultBlockSchema
>({
  editor,
  block,
  blockDragStart,
  blockDragEnd,
  freezeMenu,
  unfreezeMenu,
}: SideMenuProps<BSchema>) {
  const { setSelectedBlockId } = useContext(TextComposerContext);
  const [moreActionMenuOpen, setMoreActionMenuOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const openMenu = () => {
    setMoreActionMenuOpen(true);
    setSelectedBlockId(block.id);
    freezeMenu();
  };

  const closeMenu = () => {
    setMoreActionMenuOpen(false);
    setSelectedBlockId(null);
    unfreezeMenu();
  };

  const handleClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    openMenu();
  };

  return (
    <>
      <FloatingMenu.TipButton
        className="composer-side-menu-button"
        sx={styles.draggableButton}
        ref={buttonRef}
        draggable="true"
        onDragStart={blockDragStart}
        onDragEnd={blockDragEnd}
        onClick={handleClick}
        selected={moreActionMenuOpen}
        title={
          <Typography sx={styles.tooltipInner} variant="caption">
            <span>
              <b>Drag</b> to move
            </span>
            <span>
              <b>Click</b> to open menu
            </span>
          </Typography>
        }
      >
        <OtherDragIcon />
      </FloatingMenu.TipButton>
      <BlockMoreActionDropdown
        editor={editor}
        block={block}
        target={buttonRef}
        open={moreActionMenuOpen}
        onClose={() => closeMenu()}
        onItemClick={() => closeMenu()}
      />
    </>
  );
}
