import React from 'react';

export default function SocialMediaTiktok({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.9921 7.50616C18.9521 7.50616 17.9905 7.16468 17.2211 6.59085C16.3378 5.93252 15.7003 4.9644 15.4759 3.85195C15.4189 3.57735 15.3904 3.2922 15.3869 3H12.4128V11.0301L12.4093 15.4271C12.4093 16.603 11.6328 17.5993 10.5607 17.9513C10.2473 18.0534 9.9125 18.1027 9.56345 18.0816C9.11467 18.0569 8.69795 17.9231 8.33109 17.7084C7.55464 17.2507 7.03107 16.4199 7.01682 15.4659C6.99545 13.9802 8.21 12.7657 9.71304 12.7657C10.0087 12.7657 10.2936 12.815 10.5607 12.8995V10.7062V9.92118C10.2794 9.87894 9.99442 9.85781 9.70236 9.85781C8.05684 9.85781 6.51818 10.5337 5.41761 11.7518C4.58773 12.6706 4.08909 13.8465 4.01073 15.0786C3.91101 16.6945 4.50937 18.2329 5.67049 19.3665C5.84146 19.532 6.01954 19.6869 6.20831 19.8312C7.20203 20.5881 8.42014 21 9.70236 21C9.99085 21 10.2794 20.9789 10.5607 20.9366C11.7575 20.7606 12.8616 20.2185 13.7342 19.3665C14.8063 18.3209 15.3975 16.9304 15.4047 15.4518L15.3904 8.88265C15.8997 9.27342 16.4589 9.59378 17.0609 9.84373C17.994 10.2345 18.9842 10.4281 19.9993 10.4281V8.29474V7.50264C20.0028 7.50616 19.9921 7.50616 19.9921 7.50616Z"
        fill="currentColor"
      />
    </svg>
  );
}
