import { useMemo } from 'react';
import { Trans } from 'next-i18next';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { AnswerFormatType } from '@lib/web/apis';

import usePracticeQuestion from '../../../../../hooks/usePracticeQuestion';
import usePracticeStaticData from '../../../../../hooks/usePracticeStaticData';

function DesktopHint() {
  const { details, creatorQuestionInfo } = usePracticeQuestion();
  const { preference } = usePracticeStaticData();

  const options = useMemo(() => {
    const questionDetails = [...details].sort((a, b) => a.order - b.order);
    return questionDetails.map((option, index) => {
      return { id: option.id, key: String.fromCharCode(65 + index) };
    });
  }, [details]);

  return (
    <Typography variant="caption">
      <Trans
        i18nKey={`quiz::practice.keyboard.hint.${AnswerFormatType.MultipleResponse}`}
        components={{
          1: <span />,
          br: <br />,
        }}
        values={{
          keyboard: options
            .map((option) => option.key)
            .join(', ')
            .toLowerCase(),
          min:
            creatorQuestionInfo?.mrqMinCorrectAnswerNum ||
            preference?.mrqMinCorrectAnswerNum ||
            1,
          max:
            creatorQuestionInfo?.mrqMaxCorrectAnswerNum ||
            preference?.mrqMaxCorrectAnswerNum ||
            1,
        }}
      />
    </Typography>
  );
}

export default function AnswerHintMultipleResponse() {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (mdDown)
    return (
      <Typography variant="caption">
        <Trans
          i18nKey={`quiz::practice.keyboard.hint.${AnswerFormatType.MultipleResponse}`}
          components={{
            1: <span />,
          }}
          context="mobile"
        />
      </Typography>
    );

  return <DesktopHint />;
}
