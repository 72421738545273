import React from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import {
  OtherCheckedSquareSolid as OtherCheckedSquareSolidIcon,
  OtherCheckSquareOutline as OtherCheckSquareOutlineIcon,
} from '@front/icon';
import { SimpleTooltip, SquareAvatar, useTruncated } from '@front/ui';

const styles = {
  root: {
    py: '2px',
    px: { xs: 2.5, md: 1.5 },
    minHeight: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'left',
    gap: 1,
    borderRadius: 0,
    width: '100%',
  },
  clickable: {
    '@media (hover: hover)': {
      '&:hover': {
        bgcolor: 'alpha.lightA05',
      },
    },
  },
  main: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 700,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 14,
      height: 14,
    },
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      ml: '-25px !important',
    },
    maxWidth: '200px',
  },
};

type IconListItemProps = {
  icon: string;
  iconType: string;
  text: string;
  selectable?: {
    selected: boolean;
    onSelect: () => void;
  };
};

type CheckboxProps = { checked: boolean };
function Checkbox({ checked }: CheckboxProps) {
  return checked ? (
    <OtherCheckedSquareSolidIcon width={16} height={16} />
  ) : (
    <OtherCheckSquareOutlineIcon width={16} height={16} />
  );
}

export default function IconListItem({
  icon,
  text,
  selectable,
}: IconListItemProps) {
  const { containerRef, isTruncated } = useTruncated();

  return (
    <ButtonBase
      sx={[styles.root, !!selectable && styles.clickable]}
      onClick={() => selectable?.onSelect()}
      disableTouchRipple={true}
    >
      <SquareAvatar src={icon} size={16} blackAndWhite>
        {text}
      </SquareAvatar>
      <Typography sx={styles.main} variant="body2" ref={containerRef}>
        {isTruncated ? (
          <SimpleTooltip
            title={text}
            slotProps={{
              popper: {
                sx: styles.tooltip,
              },
            }}
          >
            <span>{text}</span>
          </SimpleTooltip>
        ) : (
          text
        )}
      </Typography>
      {!!selectable && (
        <Box sx={styles.action}>
          <Checkbox checked={selectable.selected} />
        </Box>
      )}
    </ButtonBase>
  );
}
