import { compareAsc, compareDesc, isSameDay, isValid } from 'date-fns';

import { DatePickerTypes, DateValue } from './types/dateTypes';

export const checkInRage = (day: Date, start: Date, end: Date) =>
  compareAsc(day, start) !== -1 && compareDesc(day, end) !== -1;

const getValidDate = (value: Date, minDate?: Date, maxDate?: Date) => {
  if (minDate && compareAsc(value, minDate) < 0) return minDate;
  if (maxDate && compareDesc(value, maxDate) === -1) return maxDate;
  return value;
};

export const getBaseDateValue = (
  value: DateValue,
  minDate?: Date | string | number | null | undefined,
  maxDate?: Date | string | number | null | undefined
): Date => {
  const validMinDate = minDate ? new Date(minDate) : undefined;
  const validMaxDate = maxDate ? new Date(maxDate) : undefined;

  if (!value) {
    return getValidDate(new Date(), validMinDate, validMaxDate);
  }

  let singleValue = Array.isArray(value) ? value[0] : value;
  if (!singleValue) return getValidDate(new Date(), validMinDate, validMaxDate);
  singleValue = new Date(singleValue);
  if (!isValid(singleValue))
    return getValidDate(new Date(), validMinDate, validMaxDate);

  return getValidDate(singleValue, validMinDate, validMaxDate);
};

export const getValidDateValue = (
  date: Date,
  compareDate: Date | Date[] | undefined,
  type: DatePickerTypes
) => {
  switch (type) {
    case 'range': {
      if (!compareDate || !Array.isArray(compareDate)) return [date];
      if (compareDate.length < 2) {
        if (compareAsc(date, compareDate[0]) === -1)
          return [date, compareDate[0]];
        if (compareDesc(date, compareDate[0]) === -1)
          return [compareDate[0], date];
      }

      return [date];
    }
    case 'multiple': {
      if (!compareDate || !Array.isArray(compareDate)) return [date];
      if (compareDate.some((validDate) => isSameDay(date, validDate)))
        return compareDate.filter((validDate) => !isSameDay(date, validDate));

      return [...compareDate, date];
    }

    default:
      if (!compareDate || Array.isArray(compareDate)) return date;
      return isSameDay(date, compareDate) ? null : date;
  }
};
