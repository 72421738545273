import React from 'react';

export default function ChatQuestionList({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.92026 11.9056C10.0379 11.5196 10.2439 11.1529 10.5381 10.8055C10.8323 10.458 11.2406 10.1106 11.7628 9.76318C12.263 9.4312 12.6307 9.14554 12.8661 8.9062C13.2068 8.54859 13.4398 8.13732 13.4398 7.60915C13.4398 7.28489 13.3001 7.03012 13.0206 6.84482C12.7411 6.65181 12.377 6.5553 11.9283 6.5553C11.3693 6.5553 10.7625 6.65953 10.1078 6.86798C9.46055 7.07644 8.76914 7.38526 8.0336 7.79445L6.5 4.64447C7.33116 4.14264 8.21749 3.74503 9.15898 3.45165C10.1005 3.15055 11.1265 3 12.2372 3C13.9069 3 15.2014 3.38989 16.1209 4.16966C17.0403 4.94171 17.5 5.96082 17.5 7.22699C17.5 7.82919 17.4117 8.38121 17.2352 8.88304C17.0587 9.38488 16.7792 9.85583 16.3967 10.2959C16.0142 10.736 15.5104 11.1683 14.8852 11.5929C14.4438 11.8863 14.0944 12.1372 13.837 12.3457C13.5869 12.5542 13.4067 12.7549 13.2964 12.9479C13.0943 13.3267 13.2081 14.9621 13.2081 15.3828H9.80924C9.80924 14.6163 9.70833 12.6471 9.92026 11.9056Z"
        fill="currentColor"
      />
      <path
        d="M9.28034 18.7881C9.28034 17.9697 9.50468 17.3945 9.95336 17.0625C10.4094 16.7305 10.9611 16.5646 11.6083 16.5646C12.2262 16.5646 12.7594 16.7305 13.2081 17.0625C13.6642 17.3945 13.8922 17.9697 13.8922 18.7881C13.8922 19.5678 13.6642 20.1314 13.2081 20.4789C12.7594 20.8263 12.2262 21 11.6083 21C10.9611 21 10.4094 20.8263 9.95336 20.4789C9.50468 20.1314 9.28034 19.5678 9.28034 18.7881Z"
        fill="currentColor"
      />
    </svg>
  );
}
