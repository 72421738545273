import { alpha, Theme } from '@mui/material/styles';

const content = (type: string) => `.block-content[data-content-type="${type}"]`;
export const composerParagraphBlockStyle = (typography = 'body1') => ({
  // placeholder
  [`${content('paragraph')}[class*=isEmpty][class*=hasAnchor] p:before`]: {
    content: 'var(--placeholder)',
    typography,
    fontStyle: 'normal',
    position: 'absolute',

    whiteSpace: 'nowrap',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },

  [content('paragraph')]: {
    padding: 0,
  },

  // base style
  [`${content('paragraph')} p`]: {
    color: (theme: Theme) => theme.palette.text.primary,
    typography,
    margin: 0,
    position: 'relative',
  },
  [`${content('legacy-material')} p`]: {
    color: (theme: Theme) => theme.palette.text.primary,
    typography,
    margin: 0,
    position: 'relative',
  },
});

const htmlContent = (type: string) =>
  `[data-content-type="${type}"]:not(.block-content)`;

export const generatedHtmlParagraphBlockStyle = ({
  typography = 'body1',
  oneLineTypography = 'body2',
}: {
  typography?: string;
  oneLineTypography?: string;
} = {}) => ({
  [htmlContent('paragraph')]: {
    minWidth: 0,
  },

  [`${htmlContent('paragraph')} p`]: {
    margin: 0,
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? typography : 'examBody',

    '.render-one-line&': {
      typography: (theme: Theme) =>
        theme.palette.mode === 'dark' ? oneLineTypography : 'examBody',
    },
  },
  [`${htmlContent('legacy-material')} p`]: {
    margin: 0,
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? typography : 'examBody',

    '.render-one-line&': {
      typography: (theme: Theme) =>
        theme.palette.mode === 'dark' ? oneLineTypography : 'examBody',
    },
  },

  // make empty paragraph (<p></p>) has a new line
  [`${htmlContent('paragraph')} p::after`]: {
    content: '""',
    display: 'inline-block',
    width: 0,
  },
  [`${htmlContent('legacy-material')} p::after`]: {
    content: '""',
    display: 'inline-block',
    width: 0,
  },

  [`${htmlContent('paragraph')}:has(${htmlContent(
    'inline-line-marker'
  )}:last-of-type) p::after`]: {
    display: 'inline',
  },
});
