import Box from '@mui/material/Box';

const styles = {
  root: {
    py: '5.5px',
    display: 'grid',
    height: '32px',
  },
};
export default function IaFooterEmptyCell() {
  return <Box sx={styles.root} />;
}
