import React from 'react';

export default function EmojiGift({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.4142 7.11608H15.6873C16.7377 6.81299 17.4851 6.01749 17.4851 5.07043C17.4851 4.35063 16.9801 3.61192 16.2327 3.23313C15.5055 2.87322 14.6369 2.93004 13.8894 3.40357C13.1016 3.89601 12.4349 4.72942 11.9905 5.37339C11.5461 4.72942 10.8794 3.89598 10.0916 3.40357C9.34417 2.93018 8.49577 2.87322 7.74823 3.23313C7.02101 3.59305 6.49582 4.35063 6.49582 5.07043C6.49582 6.01749 7.24321 6.81302 8.29369 7.11608H3.56671C3.24346 7.11608 2.98096 7.38125 3.00109 7.68435L3.2233 9.73C3.24345 9.99518 3.48595 10.2035 3.78892 10.2035H20.2122C20.495 10.2035 20.7373 9.99518 20.7778 9.73L21 7.68435C21 7.38126 20.7576 7.11608 20.4142 7.11608ZM14.6368 4.44534C14.9802 4.21808 15.3236 4.18017 15.6267 4.33171C15.9701 4.50215 16.1721 4.84316 16.1721 5.07042C16.1721 5.58186 15.546 6.01748 14.7783 6.01748H13.1217C13.5056 5.46825 14.0307 4.80522 14.6368 4.44534ZM7.82915 5.0704C7.82915 4.84314 8.03121 4.50213 8.3746 4.33169C8.49579 4.27488 8.61697 4.23696 8.75831 4.23696C8.96037 4.23696 9.16229 4.29377 9.36437 4.42642C9.95012 4.80521 10.4956 5.46822 10.8593 5.99854H9.20273C8.45533 6.01744 7.82915 5.58184 7.82915 5.0704Z"
        fill="currentColor"
      />
      <path
        d="M3.78899 12.306L4.37474 20.5075C4.39489 20.7727 4.63739 21 4.94036 21H11.1622V11.7377H4.35454C4.03129 11.7377 3.76871 12.0029 3.78899 12.306Z"
        fill="currentColor"
      />
      <path
        d="M19.6264 11.7377H12.8188V21H19.0406C19.3436 21 19.5861 20.7916 19.6062 20.5076L20.192 12.306C20.2123 12.0029 19.9497 11.7377 19.6264 11.7377Z"
        fill="currentColor"
      />
    </svg>
  );
}
