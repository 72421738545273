import { ReactNode } from 'react';

import IaItemStatusContext, {
  IaItemStatusContextValue,
} from './IaItemStatusContext';

export default function IaItemStatusProvider<T extends object>({
  getItemStatus,
  getItemStatusAsync,
  children,
}: IaItemStatusContextValue<T> & {
  children: ReactNode;
}) {
  return (
    <IaItemStatusContext.Provider value={{ getItemStatus, getItemStatusAsync }}>
      {children}
    </IaItemStatusContext.Provider>
  );
}
