export function downloadFile({
  url,
  fileExt,
  blobType,
  filename,
}: {
  url: string;
  fileExt: string;
  blobType?: string;
  filename?: string;
}) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onload = () => {
      const blob = new Blob([xhr.response], {
        type: blobType,
      });
      const link = (window.URL || window.webkitURL).createObjectURL(blob);
      const fileNameFromUrl = url.match(new RegExp(`[^/]+\\.${fileExt}`))?.[0];
      const a = document.createElement('a');
      a.setAttribute(
        'download',
        filename || fileNameFromUrl || `download.${fileExt}`
      );
      a.setAttribute('href', link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      resolve(xhr);
    };

    xhr.onerror = () => {
      reject(xhr);
    };

    xhr.send();
  });
}
