import React from 'react';

export default function TestChart({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.5 19L9.5 12.9807L13.5 16.9869L22 7.41217L20.59 6L13.5 13.9823L9.5 9.97612L2 17.4977L3.5 19Z"
        fill="currentColor"
      />
    </svg>
  );
}
