import Card from '@lib/ia/src/components/Card';

import ListTooltipLayout from '../../ListTooltipLayout';

import { CardLayoutListTooltipConfig } from './types';

const styles = {
  tooltip: {
    '& .MuiTooltip-tooltip': {
      p: 0,
      borderRadius: 1,
      maxWidth: 'unset',
    },
  },
};

type CardLayoutListTooltipProps = {
  config: CardLayoutListTooltipConfig['config'];
};
export default function CardLayoutListTooltip({
  config,
}: CardLayoutListTooltipProps) {
  return (
    <Card.Tooltip
      slotProps={{
        popper: {
          sx: styles.tooltip,
        },
      }}
      followCursor
    >
      <ListTooltipLayout {...config} />
    </Card.Tooltip>
  );
}
