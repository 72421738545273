import { createContext, ReactNode, useMemo } from 'react';

import { PracticeStatusContextValue } from '../types';

const initialValue: PracticeStatusContextValue = {
  isPaused: false,
  isFinished: false,
  questionCount: 0,
  questionIndex: 0,
  isQuestion: false,
};

export const PracticeStatusContext =
  createContext<PracticeStatusContextValue>(initialValue);

export type PracticeStatusContextProviderProps = {
  children: ReactNode;
} & { status: PracticeStatusContextValue };

export const PracticeStatusContextProvider = ({
  children,
  status,
}: PracticeStatusContextProviderProps) => {
  const memoizedValue = useMemo(() => {
    return { ...status };
  }, [status]);

  return (
    <PracticeStatusContext.Provider value={memoizedValue}>
      {children}
    </PracticeStatusContext.Provider>
  );
};
