import { useCallback, useEffect, useRef } from 'react';
import { useLatestValueRef, useLocalStorage } from '@front/helper';

export default function useExpiringLocalStorage<T, K extends object>({
  key,
  cachedSeconds,
  defaultValue,
  valueGetter,
  autoRefresh = true,
  ready = true,
}: {
  key: string;
  cachedSeconds: number;
  defaultValue: T;
  valueGetter: (params?: K) => Promise<T | undefined>;
  autoRefresh?: boolean;
  ready?: boolean;
}) {
  const [storage, setStorage] = useLocalStorage<
    Record<string, { expiredAt: number; value: T }>
  >(key, {
    default: {
      expiredAt: 0,
      value: defaultValue,
    },
  });
  const inProgressRef = useRef<Record<string, boolean>>({ default: false });
  const valueGetterRef = useLatestValueRef(valueGetter);

  const getParamsKey = useCallback(
    (params?: K) => (params ? JSON.stringify(params) : 'default'),
    []
  );

  const validate = useCallback(
    async (params?: K) => {
      const paramsKey = getParamsKey(params);
      const expiredAt = storage[paramsKey]?.expiredAt || 0;
      const inProgress = inProgressRef.current[paramsKey] || false;

      if (Date.now() < expiredAt || inProgress) return;

      if (!autoRefresh) return;

      inProgressRef.current[paramsKey] = true;
      const newData = await valueGetterRef.current(params);

      if (!newData) return;

      setStorage((prev) => ({
        ...prev,
        [paramsKey]: {
          expiredAt: Date.now() + cachedSeconds * 1000,
          value: newData,
        },
      }));
      inProgressRef.current[paramsKey] = false;
      return;
    },
    [
      autoRefresh,
      cachedSeconds,
      getParamsKey,
      setStorage,
      storage,
      valueGetterRef,
    ]
  );

  const getData = useCallback(
    (params?: K) => {
      void validate(params);
      return storage[getParamsKey(params)]?.value || defaultValue;
    },
    [defaultValue, getParamsKey, storage, validate]
  );

  useEffect(() => {
    if (!ready) return;
    void validate();
  }, [ready, validate]);

  return {
    getData,
  };
}
