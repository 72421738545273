import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

const OVERSEE_ROWS = 50;

function getRowPosition(
  row: { id: string },
  childRows: Record<string, { id: string }[]>,
  positionMap: Record<string, number>,
  position: number,
  rowHeight: number
) {
  positionMap[row.id] = position;
  position += rowHeight;
  if (childRows[row.id]) {
    childRows[row.id].forEach((childRow) => {
      position += rowHeight;
      return getRowPosition(
        childRow,
        childRows,
        positionMap,
        position,
        rowHeight
      );
    });
  }
  return position;
}

export const useTableVirtualList = ({
  tableBodyRef,
  rows,
  childRows,
  rowHeight,
}: {
  tableBodyRef: React.RefObject<HTMLDivElement>;
  rows: { id: string }[];
  childRows: Record<string, { id: string }[]>;
  rowHeight: number;
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(1000);

  useEffect(() => {
    const horizontalScrollEl = tableBodyRef.current?.closest(
      '.simplebar-content-wrapper'
    );
    const verticalScrollEl = horizontalScrollEl?.parentElement?.closest(
      '.simplebar-content-wrapper'
    );

    if (!verticalScrollEl) return;

    const handleScroll = debounce((ev: Event) => {
      const target = ev.target as HTMLDivElement;
      if (!target) return;

      setScrollTop(target.scrollTop);
      setOffsetHeight(target.offsetHeight);
    }, 100);

    verticalScrollEl.addEventListener('scroll', handleScroll);
    return () => {
      verticalScrollEl.removeEventListener('scroll', handleScroll);
    };
  }, [tableBodyRef]);

  const rowPositionMap = useMemo(() => {
    const map: Record<string, number> = {};
    let position = 0;

    rows.forEach((row) => {
      position = getRowPosition(row, childRows, map, position, rowHeight);
    });

    return map;
  }, [rows, rowHeight, childRows]);

  const showRow = useCallback(
    ({ id }: { id: string }) => {
      const position = rowPositionMap[id];

      if (position === undefined) return false;

      if (position + rowHeight * OVERSEE_ROWS < scrollTop) return false;

      if (position - rowHeight * OVERSEE_ROWS > scrollTop + offsetHeight)
        return false;

      return true;
    },
    [offsetHeight, rowHeight, rowPositionMap, scrollTop]
  );

  return {
    showRow,
  };
};
