import { forwardRef, RefObject } from 'react';

import BaseLayoutContainer, {
  BaseLayoutContainerProps,
} from './BaseLayoutContainer';
import BaseLayoutScrollArea from './BaseLayoutScrollArea';

export type BaseLayoutScrollContainerProps = BaseLayoutContainerProps & {
  scrollRef?: RefObject<HTMLDivElement>;
  showScrollDownIndicator?: boolean;
};

const BaseLayoutScrollContainer = forwardRef(
  (
    {
      scrollRef,
      showScrollDownIndicator,
      ...rest
    }: BaseLayoutScrollContainerProps,
    ref
  ) => (
    <BaseLayoutScrollArea
      scrollRef={scrollRef}
      showScrollDownIndicator={showScrollDownIndicator}
    >
      <BaseLayoutContainer ref={ref} {...rest} />
    </BaseLayoutScrollArea>
  )
);

export default BaseLayoutScrollContainer;
