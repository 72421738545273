import React, { ReactNode, useEffect, useRef } from 'react';
import { PassageLineNumType } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import {
  EditorBlockTypes,
  syncLineMarkerOptions,
  useEditorDispatch,
} from '@lib/web/editor';
import { EditorTextComposerProps } from '@lib/web/editor/EditorTextComposer';
import { LineNumberWrap } from '@lib/web/ui';

type LineNumberPassageProps = {
  enable: boolean;
  defaultBlocks: EditorTextComposerProps['defaultBlocks'];
  passageLineNumType: PassageLineNumType;
  isShowPassageLineNum: boolean;
  children: (params: {
    handleBlocksChange: (blocks: ComposerBlock[]) => void;
  }) => ReactNode;
};

const getDisplayText = (text: string) => {
  const contentArr = text.split(' ').filter((txt) => !!txt);

  return contentArr.length <= 1
    ? contentArr[0]
    : `${contentArr[0]}...${contentArr[contentArr.length - 1]}`;
};

const getLineMarkerOptionsFromAllAnchorBlock = (blocks: ComposerBlock[]) => {
  let result: { id: string; value: string; display: string }[] = [];

  blocks.forEach((block) => {
    if (block.type === EditorBlockTypes.InlineLineAnchor) {
      result.push({
        id: block.attrs.id,
        value: block.attrs.value,
        display:
          typeof block.attrs.value === 'string'
            ? getDisplayText(block.attrs.value)
            : '',
      });
    } else if (block.content?.length) {
      result = result.concat(
        getLineMarkerOptionsFromAllAnchorBlock(block.content)
      );
    }
  });

  return result;
};

export default function LineNumberPassage({
  enable,
  passageLineNumType,
  isShowPassageLineNum,
  defaultBlocks,
  children,
}: LineNumberPassageProps) {
  const dispatch = useEditorDispatch();
  const loaded = useRef(false);
  const handleBlocksChange = (blocks: ComposerBlock[]) => {
    dispatch(
      syncLineMarkerOptions(getLineMarkerOptionsFromAllAnchorBlock(blocks))
    );
  };

  useEffect(() => {
    if (!enable) return;

    if (defaultBlocks && !loaded.current) {
      loaded.current = true;

      // Preset sync on first rendered defaultBlocks
      dispatch(
        syncLineMarkerOptions(
          getLineMarkerOptionsFromAllAnchorBlock(defaultBlocks)
        )
      );
    }
  }, [defaultBlocks, dispatch, enable]);

  if (!enable) return children({ handleBlocksChange: () => {} });

  return (
    <LineNumberWrap
      lineNumberGroup={passageLineNumType}
      isShowPassageLineNum={isShowPassageLineNum}
      targetSelector="[data-content-type=paragraph]"
    >
      {children({ handleBlocksChange })}
    </LineNumberWrap>
  );
}
