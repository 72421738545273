import React from 'react';

export default function ProfileCamera({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 15.7333C13.5906 15.7333 14.88 14.4598 14.88 12.8889C14.88 11.3179 13.5906 10.0444 12 10.0444C10.4094 10.0444 9.12 11.3179 9.12 12.8889C9.12 14.4598 10.4094 15.7333 12 15.7333Z"
        fill="currentColor"
      />
      <path
        d="M9.3 4L7.653 5.77778H4.8C3.81 5.77778 3 6.57778 3 7.55556V18.2222C3 19.2 3.81 20 4.8 20H19.2C20.19 20 21 19.2 21 18.2222V7.55556C21 6.57778 20.19 5.77778 19.2 5.77778H16.347L14.7 4H9.3ZM12 17.3333C9.516 17.3333 7.5 15.3422 7.5 12.8889C7.5 10.4356 9.516 8.44444 12 8.44444C14.484 8.44444 16.5 10.4356 16.5 12.8889C16.5 15.3422 14.484 17.3333 12 17.3333Z"
        fill="currentColor"
      />
    </svg>
  );
}
