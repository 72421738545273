import { ReactNode } from 'react';
import { SortableItem } from '@front/helper';
import IaSortable, {
  IaSortableConfig,
} from '@lib/ia/src/components/IaSortable';

import { RowNestedLevel, TableLayoutConfig, TableLayoutRow } from '../types';

function getSortableConfigs(
  sortableConfig: TableLayoutConfig['settings']['sortableConfig']
): Record<RowNestedLevel, IaSortableConfig> {
  if (!sortableConfig) return {};
  if ('dropAction' in sortableConfig) {
    return {
      nestedLevel0: sortableConfig,
    };
  }
  return sortableConfig;
}

type RenderData = {
  row: TableLayoutRow;
  isDeepest: boolean;
  nestedLevel: number;
  sortableItemEl: HTMLDivElement | null;
  isGhostEl?: boolean;
};

type IaTableRowRecursiveProps = {
  rows: TableLayoutRow[];
  childRows?: Record<string, TableLayoutRow[]>;
  settings: TableLayoutConfig['settings'];
  showRow: ({ id }: { id: string }) => boolean;
  renderRow: (data: RenderData) => ReactNode;
  renderLoadingRow: () => ReactNode;
  nestedLevel?: number;
  expanded?: boolean;
  hasChild?: boolean;
};

export default function IaTableRowRecursive({
  rows,
  childRows = {},
  settings,
  showRow,
  renderRow,
  renderLoadingRow,
  nestedLevel = 0,
  expanded = false,
}: IaTableRowRecursiveProps) {
  // if expandable is not set => should expand all child rows by default
  const forceExpanded = !settings?.expandable;

  if (nestedLevel > 0 && !forceExpanded && !expanded) return null;
  const sortableConfig = getSortableConfigs(settings.sortableConfig)[
    `nestedLevel${nestedLevel}`
  ];
  return (
    <IaSortable items={rows} config={sortableConfig}>
      {rows.map((row) => (
        <SortableItem key={row.id} id={row.id}>
          {({ sortableItemEl, isGhostEl }) => (
            <>
              {showRow(row) ? (
                <>
                  {renderRow({
                    row,
                    isDeepest: (childRows[row.id] || []).length === 0,
                    nestedLevel,
                    sortableItemEl,
                    isGhostEl,
                  })}
                </>
              ) : (
                renderLoadingRow()
              )}
              <IaTableRowRecursive
                rows={childRows[row.id] || []}
                childRows={childRows}
                settings={settings}
                showRow={showRow}
                renderRow={renderRow}
                renderLoadingRow={renderLoadingRow}
                nestedLevel={nestedLevel + 1}
                expanded={row.expanded}
              />
            </>
          )}
        </SortableItem>
      ))}
    </IaSortable>
  );
}
