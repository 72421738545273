import { useContext, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';

import {
  FormLayoutMethodsContext,
  FormLayoutMethodsProvider,
} from '../../FormLayout';
import { FormLayoutConfig, FormLayoutItem } from '../../FormLayout/types';
import IaLayouts from '../../IaLayouts';
import { TableLayoutConfig, TableLayoutRow } from '../types';
import { isCellEditable } from '../utils';

import useCellActionGenerator from './hooks/useCellActionGenerator';
import useFormItemConverter from './hooks/useFormItemConverter';
import { SidePeekItem } from './types';

export type TableSidePeekProps = {
  rowId?: string;
  tableConfig?: TableLayoutConfig;
  sx?: BoxProps['sx'];
};

function TableSidePeekForm({ rowId, tableConfig, sx }: TableSidePeekProps) {
  const { methods } = useContext(FormLayoutMethodsContext);
  const convertFormItem = useFormItemConverter();
  const generateCellActions = useCellActionGenerator();

  const selectedRow = useMemo(() => {
    if (!rowId || !tableConfig) return null;
    // get latest selected row
    for (const r of tableConfig.table.rows) {
      if (r.id === rowId) return r;
      const childRows: TableLayoutRow[] =
        tableConfig.table.childRows?.[r.id] || [];
      for (const child of childRows) {
        if (child.id === rowId) return child;
      }
    }
  }, [rowId, tableConfig]);

  const sidePeekItems = useMemo(() => {
    if (!selectedRow || !tableConfig) return [];
    const items: SidePeekItem[] = [];
    const headCells = tableConfig.table.head.cells;
    for (const col of tableConfig.table.columnOrder) {
      const cell = selectedRow.cells[col];
      if (isCellEditable(cell)) {
        const item: SidePeekItem = {
          columnKey: col,
          cell,
          headCell: headCells[col],
        };
        items.push(item);
      }
    }
    return items;
  }, [selectedRow, tableConfig]);

  const defaultValues = useMemo(() => {
    const values: Record<string, string | number | null> = {};
    sidePeekItems.forEach((item) => {
      if (item.cell.type === 'text') {
        values[item.columnKey] = item.cell.text || null;
      } else if (item.cell.type === 'number') {
        values[item.columnKey] = item.cell.value || null;
      } else if (item.cell.type === 'status') {
        values[item.columnKey] = item.cell.value || null;
      } else if (item.cell.type === 'url') {
        values[item.columnKey] = item.cell.url || null;
      } else if (item.cell.type === 'iconText') {
        values[`${item.columnKey}_text`] = item.cell.text || null;
        if (item.cell.icon && item.cell.icon.type === 'emoji') {
          // support emoji only
          values[`${item.columnKey}_emoji`] = item.cell.icon.value;
        }
      }
    });
    return values;
  }, [sidePeekItems]);

  const layouts = useMemo<FormLayoutConfig[]>(() => {
    const formItems = sidePeekItems
      .map(convertFormItem)
      .filter((item): item is FormLayoutItem => item !== null);

    return [
      {
        layout: 'form-layout',
        settings: {
          gridTemplateColumns: [null, null, '1fr'],
        },
        mainItems: [
          {
            type: 'group',
            gap: 2,
            items: formItems,
          },
        ],
      },
    ];
  }, [convertFormItem, sidePeekItems]);

  const availableActions = useMemo(() => {
    if (!selectedRow) return {};
    return generateCellActions(sidePeekItems, selectedRow);
  }, [generateCellActions, selectedRow, sidePeekItems]);

  useEffect(() => {
    if (methods?.reset) {
      methods.reset(defaultValues);
    }
  }, [defaultValues, methods]);

  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <Box sx={[...sxProps]}>
        <IaLayouts layouts={layouts} />
      </Box>
    </IaActionContextProvider>
  );
}

export default function TableSidePeek({ ...rest }: TableSidePeekProps) {
  return (
    <FormLayoutMethodsProvider>
      <TableSidePeekForm {...rest} />
    </FormLayoutMethodsProvider>
  );
}
