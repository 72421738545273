import { BlockSchema, TipTapNode } from '@blocknote/core';
import { basicComposerSchema } from '@lib/web/composer';

import AudioBlock, {
  audioBlockDefaultProps,
} from '../components/blocks/AudioBlock';
import {
  ImageBlock,
  imageBlockDefaultProps,
} from '../components/blocks/ImageBlock';

export const editorComposerSchema = {
  ...basicComposerSchema,
  image: {
    propSchema: imageBlockDefaultProps,
    node: ImageBlock as TipTapNode<'image', false>,
  },
  audio: {
    propSchema: audioBlockDefaultProps,
    node: AudioBlock as TipTapNode<'audio', false>,
  },
} satisfies BlockSchema;

export type EditorComposerSchema = typeof editorComposerSchema;
