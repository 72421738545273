import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Typography } from '@mui/material';
import { timeFormat, useLatestValueRef } from '@front/helper';

import { BarSlider } from '../../../atoms';

const styles = {
  root: {
    minWidth: 0,
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

type ProgressControlProps = {
  defaultProgress?: number;
  isPlaying: boolean;
  audioRef: MutableRefObject<HTMLAudioElement | undefined>;
  audioDisabled: boolean;
  onlyOnce: boolean;
  hovered: boolean;
  onDestroy?: (value: number) => void;
};
export default function ProgressControl({
  defaultProgress,
  isPlaying,
  audioRef,
  audioDisabled,
  onlyOnce,
  hovered,
  onDestroy,
}: ProgressControlProps) {
  const [progress, setProgress] = useState(defaultProgress || 0);

  const animationRef = useRef<number | null>(null);
  const [duration, setDuration] = useState(0);

  const latestValueRef = useLatestValueRef({ isPlaying, onDestroy, progress });
  const whilePlaying = useCallback(() => {
    if (!audioRef.current && animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    if (audioRef.current) {
      setProgress(audioRef.current.currentTime / audioRef.current.duration);
    }

    if (latestValueRef.current.isPlaying)
      animationRef.current = requestAnimationFrame(whilePlaying);
    else if (animationRef.current) cancelAnimationFrame(animationRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      if (defaultProgress) {
        audioRef.current.currentTime =
          defaultProgress * audioRef.current.duration;
      }
      setDuration(audioRef.current.duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRef]);

  useEffect(() => {
    if (isPlaying) {
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  }, [audioRef, isPlaying, whilePlaying]);

  const handleChangeProgressStart = () => {
    if (!audioRef.current) return;

    audioRef.current.pause();
  };

  const handleChangeProgressEnd = (value: number) => {
    if (!audioRef.current) return;

    audioRef.current.play();
    audioRef.current.currentTime = value * audioRef.current.duration;
  };

  useEffect(() => {
    const progressRef = latestValueRef;
    return () => {
      progressRef.current?.onDestroy?.(progressRef.current?.progress || 0);
    };
    // only when component is unmounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box sx={styles.root}>
        <Typography sx={styles.text}>
          {timeFormat(duration * progress)} / {timeFormat(duration)}
        </Typography>
      </Box>
      <BarSlider
        value={progress}
        onChangeStart={handleChangeProgressStart}
        onChangeEnd={handleChangeProgressEnd}
        disabled={audioDisabled || onlyOnce}
        showThumb={hovered}
      />
    </>
  );
}
