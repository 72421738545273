import { alpha, Theme } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
export const modalPaperStyle = {
  width: { xs: '100%', md: '90%' },
  height: { xs: '100%', md: '90%' },
  backgroundColor: (theme: Theme) =>
    theme.palette.mode === 'dark'
      ? alpha(theme.palette.text.primary, 0.05)
      : alpha(theme.palette.background.default, 0.75),
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  backdropFilter: 'blur(50px)',
  borderRadius: { md: 4 },
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
};
