import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';

const styles = {
  root: {
    py: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  item: {
    px: { xs: 2.5, md: '12px' },
    py: 1,
    display: 'flex',
    gap: 1,
  },
};

function SkeletonItem() {
  return (
    <Box sx={styles.item}>
      <Box flex={1}>
        <Typography>
          <Skeleton />
        </Typography>
        <Typography variant="body2">
          <Skeleton width="50%" />
        </Typography>
      </Box>
    </Box>
  );
}
export default function FormLayoutSkeleton() {
  return (
    <Box sx={styles.root}>
      <SkeletonItem />
      <SkeletonItem />
    </Box>
  );
}
