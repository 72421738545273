import { ReactNode } from 'react';
import { Popover, PopoverProps } from '@mui/material';
import Box from '@mui/material/Box';

const styles = {
  popover: {
    pointerEvents: 'none',
    userSelect: 'none',
  },
  tooltip: {
    p: 1,
    boxShadow: 9,
    borderRadius: 1,
    color: 'background.darker',
    bgcolor: 'text.primary',
    typography: 'caption',
  },
};

export type PositionTooltipProps = {
  x: number;
  y: number;
  open: PopoverProps['open'];
  children?: ReactNode;
};

export default function PositionTooltip({
  x,
  y,
  open,
  children,
}: PositionTooltipProps) {
  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{
        top: y,
        left: x,
      }}
      open={open}
      sx={styles.popover}
      hideBackdrop
    >
      <Box sx={styles.tooltip}>{children}</Box>
    </Popover>
  );
}
