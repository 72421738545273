import React from 'react';

export default function ProfileBadge({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.94485 13.8196V5.16561H17.054V13.8196C17.054 14.5735 16.6882 15.2812 16.0714 15.7203L11.9994 18.6191L7.92748 15.7203C7.31071 15.2812 6.94485 14.5735 6.94485 13.8196Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2V15.0496C4 15.9726 4.45684 16.8365 5.22177 17.3601L12 22L18.7782 17.3601C19.5432 16.8365 20 15.9726 20 15.0496V2H4ZM6.00368 4.23036V13.8196C6.00368 14.8751 6.51587 15.8659 7.37935 16.4806L11.9994 19.7696L16.6195 16.4806C17.483 15.8659 17.9952 14.8751 17.9952 13.8196V4.23036H6.00368Z"
        fill="currentColor"
      />
    </svg>
  );
}
