import { TFunction } from 'i18next';

const getProfilePageConfig = (t: TFunction) => ({
  account: {
    title: t('profile.account.title'),
    shortTitle: t('profile.account.shortTitle'),
    icon: 'MainProfileSolid',
  },
  accountEdit: {
    title: t('profile.account_edit.title'),
    icon: 'OtherEdit',
  },
  discountCode: {
    title: t('profile.discountCode.title'),
    shortTitle: t('profile.discountCode.shortTitle'),
    icon: 'OtherDiscountNoOuter',
  },
  discountCodeManagement: {
    title: t('profile.discountCode_management.title'),
    icon: 'PropertyTypeTag',
  },
  myPlan: {
    title: t('profile.myPlan.title'),
    icon: 'CryptoCurrencyAha',
  },
  awards: {
    title: t('profile.awards.title'),
    icon: 'ProfileAwards',
  },
  awardsAlmostThere: {
    title: t('profile.awards_almostThere.title'),
    icon: 'ProfileAwards',
  },
  awardsByCategory: {
    title: t('profile.awards_byCategory.title'),
    icon: 'ProfileAwards',
  },
  myAvatars: {
    title: t('profile.myAvatars.title'),
    icon: 'ProfileNFTs',
  },
  settings: {
    title: t('profile.settings.title'),
    icon: 'ProfileSetting',
  },
  wallet: {
    title: t('profile.wallet.title'),
    icon: 'MainWallet',
  },
  walletAhaPoints: {
    title: t('profile.wallet_ahaPoints.title'),
    icon: 'OtherAhaPoints',
  },
  inviteFriends: {
    title: t('profile.inviteFriends.title'),
    shortTitle: t('profile.inviteFriends.shortTitle'),
    icon: 'OtherAddFriend',
  },
});

export default getProfilePageConfig;
