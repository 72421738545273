import { BlockNoteEditor, BlockSchema } from '@blocknote/core';
import { composerConfig } from '@front/config';
import { toast } from '@front/ui';
import { proseMirrorNodeToComposerBlocks } from '@lib/web/composer';
import { composerBlocksToHtml } from '@lib/web/thread/ThreadTextComposer';
import { ThreadBlockTypes } from '@lib/web/thread/ThreadTextComposer/config/threadBlockTypes';

function totalBlockTypesOccurrences(str: string, blockTypes: string[]) {
  const regex = new RegExp(
    blockTypes.map((type) => `data-content-type="${type}"`).join('|'),
    'gi'
  );
  const matches = str.match(regex);
  return matches ? matches.length : 0;
}

export const validateThreadElementsBeforeInserting = <
  BSchema extends BlockSchema
>(
  editor: BlockNoteEditor<BSchema>
) => {
  if (!editor._tiptapEditor.state.doc.firstChild) return false;

  const currentHtml = composerBlocksToHtml(
    proseMirrorNodeToComposerBlocks(editor._tiptapEditor.state.doc.firstChild)
  );

  /**
   * In design spec, only following block will be limited by elements upper bound
   * Image, Chart, Polynomial Curve, Latex, Table, File
   */
  const currentElementsOccurrences = totalBlockTypesOccurrences(currentHtml, [
    ThreadBlockTypes.InlineLatex,
    ThreadBlockTypes.Image,
    ThreadBlockTypes.File,
  ]);

  if (
    currentElementsOccurrences + 1 >
    composerConfig.maximumElementsInOneThread
  ) {
    toast.warning('Max element added reached');
    return false;
  }

  return true;
};
