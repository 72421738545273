import { useCallback } from 'react';
import Router, { useRouter } from 'next/router';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel } from 'stream-chat';

export const useMaybeRedirectUserToNewDmView = () => {
  const { pathname, query } = useRouter();
  const currentViewId = query.viewId as string;
  const { reloadViews: reloadDmViews } = useDirectMessageViews();

  const maybeRedirectUserToNewDmView = useCallback(
    async ({
      channel,
      newViewId,
    }: {
      channel: Channel<StreamChatGenerics>;
      newViewId: string;
    }) => {
      // if user inside dm pages, after the view member changed, we need to redirect them
      if (
        pathname === '/direct-messages/view/[viewId]' ||
        pathname === '/direct-messages/new-thread'
      ) {
        if (newViewId === currentViewId) return;

        await Router.push(
          `/direct-messages/view/${newViewId}?cid=${channel.cid}&open=true`
        );

        void reloadDmViews();
      }

      // if user inside a channel, after removing user, the view will be changed, so we need to redirect them
      if (pathname === '/direct-messages/channel/[cid]') {
        if (newViewId === currentViewId) return;

        await Router.push(
          `/direct-messages/channel/${channel.cid}?viewId=${newViewId}`
        );

        await reloadDmViews();
      }
    },
    [currentViewId, pathname, reloadDmViews]
  );

  return {
    maybeRedirectUserToNewDmView,
  };
};
