import React from 'react';

export default function SocialMediafacebook({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.00195 12.0636C2.00312 17.0133 5.58036 21.2277 10.439 22.0034V14.9714H7.90195V12.0636H10.442V9.85005C10.3284 8.80118 10.6845 7.75583 11.4136 6.99753C12.1427 6.23923 13.1693 5.84657 14.215 5.92605C14.9655 5.93824 15.7141 6.0055 16.455 6.12728V8.60141H15.191C14.7558 8.54407 14.3183 8.68867 14.0017 8.99449C13.6851 9.3003 13.5237 9.73423 13.563 10.174V12.0636H16.334L15.891 14.9724H13.563V22.0034C18.8174 21.1679 22.502 16.3395 21.9475 11.0163C21.3929 5.69299 16.7932 1.73835 11.4808 2.01731C6.16831 2.29626 2.0028 6.71116 2.00195 12.0636Z"
        fill="currentColor"
      />
    </svg>
  );
}
