export const enum BasicBlockTypes {
  Paragraph = 'paragraph',
  Heading = 'heading',
  Subtitle = 'subtitle',
  Step = 'step',
  InlineBlank = 'inline-blank',
  InlineMention = 'inline-mention',
  Unsupported = 'unsupported',
  LegacyParagraph = 'legacy-paragraph',
  LegacyMaterial = 'legacy-material',
}
