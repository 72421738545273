import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { ClickAwayListener } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import { Sortable, SortableItem, SortableProps } from '@front/helper';

import { PracticeUnscrambleValue } from '../types';

import Choice from './Choice';

const styles = {
  choiceContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  captionContainer: {
    mt: '16px',
    width: '100%',
    display: 'flex',
    textAlign: 'right',
  },
  caption: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
  },
  captionHighlight: {
    color: 'text.primary',
  },
};

const useKeyboardShortcutMove = (
  unscrambleValues: PracticeUnscrambleValue[],
  onMoveItem: (fromIndex: number, toIndex: number) => void
) => {
  const [activeId, setActiveId] = React.useState<string | null>(null);
  const currentIndex = unscrambleValues.findIndex((v) => v.id === activeId);

  useHotkeys(
    'down',
    (ev) => {
      ev.preventDefault();
      if (currentIndex === -1) {
        return;
      }
      if (currentIndex + 1 < unscrambleValues.length) {
        onMoveItem(currentIndex, currentIndex + 1);
      }
    },
    [currentIndex, unscrambleValues]
  );

  useHotkeys(
    'up',
    (ev) => {
      ev.preventDefault();
      if (currentIndex === -1) {
        return;
      }
      if (currentIndex - 1 >= 0) {
        onMoveItem(currentIndex, currentIndex - 1);
      }
    },
    [currentIndex]
  );

  useHotkeys('123456789'.split(''), (ev) => {
    ev.preventDefault();
    const index = unscrambleValues.find((v) => v.symbol === ev.key)?.id || null;
    setActiveId(index);
  });

  return {
    activeId,
    handleClick: (id: string) => setActiveId(id),
    handleClickAway: () => setActiveId(null),
  };
};

type UnscrambleProps = {
  unscrambleValues: PracticeUnscrambleValue[];
  onDragOverOtherItem: SortableProps<PracticeUnscrambleValue>['onDragOverOtherItem'];
  onMoveItem: (fromIndex: number, toIndex: number) => void;
  readonly?: boolean;
};
export default function Unscramble({
  unscrambleValues,
  onDragOverOtherItem,
  onMoveItem,
  readonly,
}: UnscrambleProps) {
  const { activeId, handleClick, handleClickAway } = useKeyboardShortcutMove(
    unscrambleValues,
    onMoveItem
  );

  return (
    <Box>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={styles.choiceContainer}>
          <Sortable<PracticeUnscrambleValue>
            items={unscrambleValues}
            onDragOverOtherItem={onDragOverOtherItem}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          >
            {unscrambleValues.map((unscrambleValue) => (
              <SortableItem key={unscrambleValue.id} id={unscrambleValue.id}>
                {({ isGhostEl }) => (
                  <Choice
                    symbol={unscrambleValue.symbol}
                    value={unscrambleValue}
                    active={activeId === unscrambleValue.id}
                    onClick={() => handleClick(unscrambleValue.id)}
                    disableSort={isGhostEl}
                    readonly={readonly}
                  />
                )}
              </SortableItem>
            ))}
          </Sortable>
        </Box>
      </ClickAwayListener>
    </Box>
  );
}
