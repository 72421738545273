import { createContext, PropsWithChildren, ReactNode } from 'react';

type RenderUi = (payload: any) => ReactNode;

export type TextComposerRenderContextValue = Record<string, RenderUi>;

export const TextComposerRenderContext =
  createContext<TextComposerRenderContextValue>({});

type TextComposerRenderProviderProps = PropsWithChildren & {
  value: TextComposerRenderContextValue;
};

export function TextComposerRenderProvider({
  value,
  children,
}: TextComposerRenderProviderProps) {
  return (
    <TextComposerRenderContext.Provider value={value}>
      {children}
    </TextComposerRenderContext.Provider>
  );
}
