import { useGetLineMarkerContent } from '@lib/web/ui';

export type InlineLineMarkerRendererProps = {
  targetId: string;
  displayText?: string;
  displayLineText?: string;
};

export default function InlineLineMarkerRenderer({
  targetId,
  displayText,
  displayLineText,
}: InlineLineMarkerRendererProps) {
  const { getLineMarkerContent } = useGetLineMarkerContent();

  return (
    getLineMarkerContent(targetId) ||
    `Line ${displayText} (“${displayLineText}”)`
  );
}
