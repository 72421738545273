import { alpha, Palette } from '@mui/material/styles';

import {
  ButtonColorType,
  ButtonSizeType,
  ButtonVariantType,
} from '../Button/types';

type ButtonTheme = {
  bgcolor: string;
  borderColor?: string;
  color: string;
  shadowBgcolor: string;
  activeBgcolor: string;
  activeColor: string;

  variant: ButtonVariantType;
};

export const getSize = (size?: ButtonSizeType) => {
  if (size === 'md') return 219;
  if (size === 'lg') return 329;
  return 149;
};

export const getDefaultStyle = (theme: ButtonTheme) =>
  theme.variant === 'outlined'
    ? {
        '& .em-button-base': {
          color: theme.color,
          background: theme.bgcolor,
          border: '1px solid',
          borderColor: theme.borderColor || theme.color,
        },
        '& .em-button-shadow': {
          background: theme.shadowBgcolor,
          border: '1px solid',
          borderColor: theme.borderColor || theme.color,
        },
      }
    : {
        '& .em-button-base': {
          color: theme.color,
          background: theme.bgcolor,
        },
        '& .em-button-shadow': {
          background: theme.shadowBgcolor,
          border: '1px solid',
          borderColor: theme.bgcolor,
        },
      };

export const getLoadingStyle = (theme: ButtonTheme) => ({
  '& .em-button-base': {
    color: theme.activeColor,
    background: theme.activeBgcolor,
  },
  '& .em-button-shadow': {
    border: 'unset',
  },
});

export const getActiveStyle = (theme: ButtonTheme) => ({
  '&:active': {
    '& .em-button-base': {
      color: theme.activeColor,
      background: theme.activeBgcolor,
    },
    '& .em-button-shadow': {
      border: 'unset',
    },
  },
});

export const getTheme = (
  palette: Palette,
  color: ButtonColorType,
  variant: ButtonVariantType
) => {
  const isDark = palette.mode === 'dark';

  if (variant === 'outlined') {
    switch (color) {
      case 'success': {
        const mainColor = isDark ? palette.success.light : palette.success.dark;
        return {
          variant,
          bgcolor: palette.background.darker,
          color: isDark ? mainColor : palette.text.primary,
          borderColor: mainColor,
          shadowBgcolor: alpha(mainColor, 0.3),

          activeBgcolor: isDark ? palette.grey[900] : palette.grey[400],
          activeColor: isDark ? mainColor : palette.text.primary,
        };
      }
      case 'error': {
        const mainColor = palette.error.dark;

        return {
          variant,
          bgcolor: palette.background.darker,
          color: isDark ? mainColor : palette.text.primary,
          borderColor: mainColor,
          shadowBgcolor: alpha(mainColor, 0.3),

          activeBgcolor: isDark ? palette.grey[900] : palette.grey[400],
          activeColor: isDark ? mainColor : palette.text.primary,
        };
      }
      default: {
        return {
          variant,
          bgcolor: palette.background.darker,
          color: palette.text.primary,
          borderColor: palette.text.primary,
          shadowBgcolor: alpha(palette.text.primary, 0.3),

          activeBgcolor: isDark ? palette.grey[900] : palette.grey[400],
          activeColor: palette.text.primary,
        };
      }
    }
  } else {
    switch (color) {
      case 'success': {
        const mainColor = isDark ? palette.success.light : palette.success.dark;
        return {
          variant,
          bgcolor: mainColor,
          color: '#080808',
          shadowBgcolor: alpha(mainColor, 0.3),

          activeBgcolor: isDark
            ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #01F89B'
            : 'linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #00C398',
          activeColor: '#080808',
        };
      }
      case 'error': {
        const mainColor = palette.error.dark;

        return {
          variant,
          bgcolor: mainColor,
          color: palette.background.darker,
          shadowBgcolor: alpha(mainColor, 0.3),

          activeBgcolor: isDark
            ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #FF2951'
            : 'linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #FF2951',
          activeColor: palette.background.darker,
        };
      }
      default: {
        return {
          variant,
          bgcolor: palette.text.primary,
          color: palette.background.darker,
          shadowBgcolor: alpha(palette.text.primary, 0.3),

          activeBgcolor: isDark ? palette.grey[400] : palette.grey[700],
          activeColor: palette.background.darker,
        };
      }
    }
  }
};
