import { alpha, Box, Theme } from '@mui/material';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/navigation';
import 'swiper/css/pagination';

import IaCarouselItem from './components/IaCarouselItem';
import IaCarouselNavigation from './components/IaCarouselNavigation';
import { CarouselLayoutConfig } from './types';

import 'swiper/css';

const styles = {
  root: {
    '& .swiper': {
      borderRadius: 2,
      overflow: 'hidden',
    },
  },
  pagination: {
    p: 1,
    display: 'flex',
    justifyContent: 'center',
    gap: 1,
    '& .swiper-pagination-bullet': {
      cursor: 'pointer',
      background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      margin: '0!important',
    },
    '& .swiper-pagination-bullet-active': {
      background: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    },
  },
  paginationHidden: {
    display: 'none',
  },
};

type CarouselLayoutProps = CarouselLayoutConfig;

export default function CarouselLayout({
  items,
  settings = {},
}: CarouselLayoutProps) {
  const { paginationEnabled = true } = settings;

  return (
    <Box sx={styles.root}>
      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        autoplay={{ pauseOnMouseEnter: true }}
        loop
        pagination={{
          enabled: paginationEnabled,
          el: '.carousel-pagination',
          clickable: true,
        }}
        spaceBetween={16}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <IaCarouselItem {...item} />
          </SwiperSlide>
        ))}
        <IaCarouselNavigation />
      </Swiper>
      <Box
        sx={[styles.pagination, !paginationEnabled && styles.paginationHidden]}
        className="carousel-pagination"
      ></Box>
    </Box>
  );
}
