import React from 'react';

export default function Otherresend({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.42509 4.23604C9.15344 3.23755 11.1621 2.83502 13.1418 3.09046C15.1214 3.34589 16.9621 4.24512 18.3804 5.64963C19.7987 7.05413 20.7159 8.886 20.9906 10.863C21.0666 11.4101 20.6848 11.9151 20.1378 11.9912C19.5907 12.0672 19.0857 11.6854 19.0096 11.1383C18.7953 9.59581 18.0797 8.16655 16.9731 7.07073C15.8665 5.97491 14.4304 5.27331 12.8858 5.07401C11.3413 4.87472 9.77405 5.18878 8.42556 5.96782C7.53828 6.48041 6.77809 7.1756 6.19127 8.00068H8.00012C8.55241 8.00068 9.00012 8.4484 9.00012 9.00068C9.00012 9.55297 8.55241 10.0007 8.00012 10.0007H4.52249C4.50734 10.001 4.49216 10.001 4.47695 10.0007H4.00012C3.44784 10.0007 3.00012 9.55297 3.00012 9.00068V5.00068C3.00012 4.4484 3.44784 4.00068 4.00012 4.00068C4.55241 4.00068 5.00012 4.4484 5.00012 5.00068V6.27355C5.67868 5.46316 6.49773 4.77179 7.42509 4.23604ZM3.86247 12.0102C4.4095 11.9342 4.91458 12.316 4.9906 12.863C5.20497 14.4056 5.92056 15.8348 7.02713 16.9306C8.13371 18.0265 9.56988 18.7281 11.1144 18.9274C12.659 19.1266 14.2262 18.8126 15.5747 18.0335C16.462 17.521 17.2222 16.8258 17.809 16.0007H16.0001C15.4478 16.0007 15.0001 15.553 15.0001 15.0007C15.0001 14.4484 15.4478 14.0007 16.0001 14.0007H19.4778C19.4929 14.0003 19.5081 14.0003 19.5233 14.0007H20.0001C20.5524 14.0007 21.0001 14.4484 21.0001 15.0007V19.0007C21.0001 19.553 20.5524 20.0007 20.0001 20.0007C19.4478 20.0007 19.0001 19.553 19.0001 19.0007V17.7278C18.3216 18.5382 17.5025 19.2296 16.5752 19.7653C14.8468 20.7638 12.8381 21.1663 10.8585 20.9109C8.87885 20.6555 7.03812 19.7562 5.61984 18.3517C4.20155 16.9472 3.28439 15.1154 3.00964 13.1383C2.93362 12.5913 3.31545 12.0862 3.86247 12.0102Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
