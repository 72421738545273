import { ReactNode } from 'react';

import FormSettingContext, {
  FormSettingContextValue,
} from './FormSettingContext';

type FormSettingContextProviderProps = FormSettingContextValue & {
  children: ReactNode;
};

export default function FormSettingContextProvider({
  children,
  ...rest
}: FormSettingContextProviderProps) {
  return (
    <FormSettingContext.Provider value={rest}>
      {children}
    </FormSettingContext.Provider>
  );
}
