import React from 'react';

export default function MainNotificationOff({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.5768 17.7846H18.4322C19.0542 17.7846 19.5979 17.4607 19.8556 16.9408C19.9768 16.6973 20.0232 16.4253 19.9892 16.1571C19.9551 15.889 19.8422 15.6358 19.6636 15.4277C19.628 15.3863 19.5928 15.3449 19.5578 15.3039L19.5566 15.3024C19.5241 15.2643 19.492 15.2266 19.4601 15.1894L19.4586 15.1878C18.5436 14.1196 17.9901 13.4734 17.9901 10.4483C17.9901 10.0946 17.9703 9.75518 17.9308 9.43057L9.5768 17.7846ZM17.5756 7.94724L7.73832 17.7846H7.73822L17.5756 7.94717C17.5756 7.94719 17.5756 7.94722 17.5756 7.94724ZM15.605 5.53387L4.1628 16.976C4.15616 16.9635 4.14969 16.9508 4.1434 16.938C4.02267 16.6945 3.97675 16.4228 4.01106 16.1549C4.04536 15.887 4.15847 15.6343 4.33698 15.4265C4.39746 15.3548 4.45698 15.2855 4.51555 15.2173L4.51572 15.2171L4.51583 15.217L4.54056 15.1882C5.45729 14.1203 6.01057 13.475 6.01057 10.4471C6.01057 7.6989 7.15128 5.90974 9.49846 4.9772C9.51113 4.97036 9.52253 4.96154 9.53219 4.95108C9.89396 3.78308 10.8835 3 12.0001 3C13.1167 3 14.1067 3.78308 14.4684 4.95228C14.4781 4.96314 14.4896 4.97226 14.5026 4.9792C14.9086 5.14043 15.2751 5.32464 15.605 5.53387ZM11.999 21C12.6006 20.9995 13.1908 20.8419 13.7071 20.5439C14.2234 20.2459 14.6465 19.8186 14.9315 19.3073C14.9449 19.2828 14.9516 19.2553 14.9507 19.2276C14.9499 19.1999 14.9417 19.1729 14.9269 19.1491C14.9121 19.1254 14.8911 19.1058 14.8661 19.0922C14.8411 19.0785 14.8128 19.0714 14.7841 19.0714H9.21465C9.18589 19.0713 9.1576 19.0784 9.13252 19.092C9.10745 19.1056 9.08645 19.1252 9.07157 19.149C9.05669 19.1727 9.04843 19.1998 9.04761 19.2275C9.04678 19.2553 9.05341 19.2827 9.06686 19.3073C9.35184 19.8185 9.77486 20.2458 10.2911 20.5438C10.8073 20.8418 11.3974 20.9995 11.999 21Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M20.6364 3.61369C20.9879 3.96516 20.9879 4.53501 20.6364 4.88648L4.6364 20.8865C4.28492 21.238 3.71508 21.238 3.3636 20.8865C3.01213 20.535 3.01213 19.9652 3.3636 19.6137L19.3636 3.61369C19.7151 3.26222 20.2849 3.26222 20.6364 3.61369Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
