import React from 'react';

export default function CursorGrabbed({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g>
        <path
          d="M7.99978 7.14995C8.47978 6.96995 9.42978 7.07995 9.67978 7.61995C9.92978 8.15995 10.0798 8.85995 10.0898 8.68995C10.0706 8.17325 10.1143 7.65613 10.2198 7.14995C10.3309 6.82577 10.5856 6.5711 10.9098 6.45995C11.2071 6.36592 11.5228 6.34533 11.8298 6.39995C12.1402 6.46385 12.4151 6.64238 12.5998 6.89995C12.8337 7.48308 12.9657 8.1021 12.9898 8.72995C13.0147 8.19421 13.1053 7.66355 13.2598 7.14995C13.4269 6.9145 13.6709 6.74474 13.9498 6.66995C14.2804 6.60951 14.6192 6.60951 14.9498 6.66995C15.2212 6.76001 15.4585 6.93101 15.6298 7.15995C15.8422 7.6901 15.9704 8.2502 16.0098 8.81995C16.0098 8.95995 16.0798 8.42995 16.2998 8.07995C16.4765 7.55528 17.0451 7.27322 17.5698 7.44995C18.0945 7.62668 18.3765 8.19528 18.1998 8.71995C18.1998 9.36995 18.1998 9.33995 18.1998 9.77995C18.1998 10.22 18.1998 10.61 18.1998 10.98C18.1638 11.5652 18.0835 12.1468 17.9598 12.72C17.7862 13.2273 17.544 13.7084 17.2398 14.15C16.7544 14.69 16.3532 15.3002 16.0498 15.96C15.9758 16.288 15.9422 16.6238 15.9498 16.96C15.9488 17.2706 15.9891 17.58 16.0698 17.88C15.6609 17.9236 15.2486 17.9236 14.8398 17.88C14.4498 17.82 13.9698 17.04 13.8398 16.8C13.7755 16.6711 13.6438 16.5897 13.4998 16.5897C13.3558 16.5897 13.2241 16.6711 13.1598 16.8C12.9398 17.18 12.4498 17.87 12.1598 17.91C11.4898 17.99 10.0998 17.91 9.01978 17.91C9.01978 17.91 9.20978 16.91 8.78978 16.55C8.36978 16.19 7.95978 15.7699 7.64978 15.49L6.81978 14.57C6.23447 14.0266 5.80616 13.3358 5.57978 12.57C5.36978 11.63 5.38978 11.18 5.57978 10.8C5.77357 10.4862 6.07617 10.2548 6.42978 10.15C6.72355 10.0967 7.02596 10.1173 7.30978 10.21C7.50605 10.2921 7.67567 10.4271 7.79978 10.6C8.02978 10.91 8.10978 11.06 8.00978 10.72C7.90978 10.38 7.68978 10.13 7.57978 9.71995C7.36563 9.23575 7.23706 8.71808 7.19978 8.18995C7.24076 7.71612 7.5716 7.31751 8.02978 7.18995"
          fill="white"
        />
        <path
          d="M7.99978 7.14995C8.47978 6.96995 9.42978 7.07995 9.67978 7.61995C9.92978 8.15995 10.0798 8.85995 10.0898 8.68995C10.0706 8.17325 10.1143 7.65613 10.2198 7.14995C10.3309 6.82577 10.5856 6.5711 10.9098 6.45995C11.2071 6.36592 11.5228 6.34533 11.8298 6.39995C12.1402 6.46385 12.4151 6.64238 12.5998 6.89995C12.8337 7.48308 12.9657 8.1021 12.9898 8.72995C13.0147 8.19421 13.1053 7.66355 13.2598 7.14995C13.4269 6.9145 13.6709 6.74474 13.9498 6.66995C14.2804 6.60951 14.6192 6.60951 14.9498 6.66995C15.2212 6.76001 15.4585 6.93101 15.6298 7.15995C15.8422 7.6901 15.9704 8.2502 16.0098 8.81995C16.0098 8.95995 16.0798 8.42995 16.2998 8.07995C16.4765 7.55528 17.0451 7.27322 17.5698 7.44995C18.0945 7.62668 18.3765 8.19528 18.1998 8.71995C18.1998 9.36995 18.1998 9.33995 18.1998 9.77995C18.1998 10.22 18.1998 10.61 18.1998 10.98C18.1638 11.5652 18.0835 12.1468 17.9598 12.72C17.7862 13.2273 17.544 13.7084 17.2398 14.15C16.7544 14.69 16.3532 15.3002 16.0498 15.96C15.9758 16.288 15.9422 16.6238 15.9498 16.96C15.9488 17.2706 15.9891 17.58 16.0698 17.88C15.6609 17.9236 15.2486 17.9236 14.8398 17.88C14.4498 17.82 13.9698 17.04 13.8398 16.8C13.7755 16.6711 13.6438 16.5897 13.4998 16.5897C13.3558 16.5897 13.2241 16.6711 13.1598 16.8C12.9398 17.18 12.4498 17.87 12.1598 17.91C11.4898 17.99 10.0998 17.91 9.01978 17.91C9.01978 17.91 9.20978 16.91 8.78978 16.55C8.36978 16.19 7.95978 15.77 7.64978 15.49L6.81978 14.57C6.23447 14.0266 5.80616 13.3358 5.57978 12.57C5.36978 11.63 5.38978 11.18 5.57978 10.8C5.77357 10.4862 6.07617 10.2548 6.42978 10.15C6.72355 10.0967 7.02596 10.1173 7.30978 10.21C7.50605 10.2921 7.67567 10.4271 7.79978 10.6C8.02978 10.91 8.10978 11.06 8.00978 10.72C7.90978 10.38 7.68978 10.13 7.57978 9.71995C7.36563 9.23575 7.23706 8.71808 7.19978 8.18995C7.22023 7.70919 7.54035 7.29303 7.99978 7.14995Z"
          fillRule="evenodd"
          stroke="black"
          strokeLinejoin="round"
          strokeWidth="0.75"
        />
        <path
          d="M15.75 14.8259V11.3741C15.75 11.1675 15.5821 11 15.375 11C15.1679 11 15 11.1675 15 11.3741V14.8259C15 15.0325 15.1679 15.2 15.375 15.2C15.5821 15.2 15.75 15.0325 15.75 14.8259Z"
          fill="black"
        />
        <path
          d="M13.77 14.8246L13.75 11.3711C13.7488 11.165 13.5799 10.9988 13.3728 11C13.1657 11.0012 12.9988 11.1693 13 11.3754L13.02 14.8289C13.0212 15.035 13.1901 15.2012 13.3972 15.2C13.6043 15.1988 13.7712 15.0307 13.77 14.8246Z"
          fill="black"
        />
        <path
          d="M11 11.3799L11.02 14.8245C11.0212 15.0331 11.1901 15.2012 11.3972 15.2C11.6043 15.1988 11.7712 15.0287 11.77 14.8201L11.75 11.3755C11.7488 11.1669 11.5799 10.9988 11.3728 11C11.1657 11.0012 10.9988 11.1713 11 11.3799Z"
          fill="black"
        />
      </g>
      <defs />
    </svg>
  );
}
