export default function OtherChallengeSettings({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.8241 16.4865C19.8434 16.3303 19.8563 16.1678 19.8563 15.999C19.8563 15.8303 19.8434 15.6678 19.8177 15.5115L20.9041 14.6865C21.0006 14.6115 21.0263 14.474 20.9684 14.3678L19.9398 12.6365C19.8755 12.524 19.7405 12.4865 19.6248 12.524L18.3455 13.024C18.0755 12.824 17.7926 12.6615 17.4776 12.5365L17.2848 11.2115C17.2655 11.0865 17.1562 10.999 17.0276 10.999H14.9704C14.8419 10.999 14.739 11.0865 14.7197 11.2115L14.5268 12.5365C14.2118 12.6615 13.9225 12.8303 13.659 13.024L12.3797 12.524C12.2639 12.4803 12.1289 12.524 12.0646 12.6365L11.036 14.3678C10.9718 14.4803 10.9975 14.6115 11.1003 14.6865L12.1868 15.5115C12.1611 15.6678 12.1418 15.8365 12.1418 15.999C12.1418 16.1615 12.1546 16.3303 12.1804 16.4865L11.0939 17.3115C10.9975 17.3865 10.9718 17.524 11.0296 17.6303L12.0582 19.3615C12.1225 19.474 12.2575 19.5115 12.3732 19.474L13.6525 18.974C13.9225 19.174 14.2054 19.3365 14.5204 19.4615L14.7133 20.7865C14.739 20.9115 14.8419 20.999 14.9704 20.999H17.0276C17.1562 20.999 17.2655 20.9115 17.2783 20.7865L17.4712 19.4615C17.7862 19.3365 18.0755 19.1678 18.3391 18.974L19.6184 19.474C19.7341 19.5178 19.8691 19.474 19.9334 19.3615L20.962 17.6303C21.0263 17.5178 21.0006 17.3865 20.8977 17.3115L19.8241 16.4865ZM15.999 17.874C14.9383 17.874 14.0704 17.0303 14.0704 15.999C14.0704 14.9678 14.9383 14.124 15.999 14.124C17.0598 14.124 17.9276 14.9678 17.9276 15.999C17.9276 17.0303 17.0598 17.874 15.999 17.874Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5907 4.81522C9.13684 4.9752 9.63407 5.27195 10.0362 5.6779L14.4359 10.1196C14.0722 10.2903 13.7996 10.6317 13.7335 11.0603L13.616 11.867C13.5926 11.8801 13.5692 11.8933 13.5461 11.9067L12.7458 11.5938L12.7354 11.5899C12.2008 11.3878 11.5706 11.5694 11.2396 12.0746L7.46976 8.26881C7.06765 7.86286 6.7737 7.36089 6.61523 6.80953L5.80772 4L8.5907 4.81522ZM10.3065 13.6427L8.37326 11.691L5.95156 14.1358L5.04874 13.2244C4.82915 13.0027 4.47312 13.0027 4.25352 13.2244C4.03393 13.4461 4.03393 13.8055 4.25352 14.0272L6.18842 15.9805L5.35027 16.8267C4.7394 16.5382 3.98944 16.6485 3.48499 17.1578C2.84094 17.808 2.84094 18.8622 3.48499 19.5124C4.12903 20.1625 5.17323 20.1625 5.81728 19.5124C6.32165 19.0032 6.43103 18.2462 6.14542 17.6296L6.98364 16.7833L8.9181 18.7363C9.1377 18.958 9.49373 18.958 9.71332 18.7363C9.93292 18.5146 9.93292 18.1552 9.71332 17.9335L8.69745 16.9079L10.2956 15.2945C9.95407 14.894 9.91206 14.3241 10.1699 13.8729L10.1741 13.8655L10.3065 13.6427ZM15.8036 4.85937C15.2189 5.01122 14.6854 5.31863 14.2586 5.74955L13.3739 6.64267L16.1198 9.41476L17.0044 8.52164C17.4313 8.09072 17.7358 7.55208 17.8862 6.9619L18.6077 4.13103L15.8036 4.85937Z"
        fill="currentColor"
      />
    </svg>
  );
}
