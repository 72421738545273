import React from 'react';
import Box from '@mui/material/Box';
import { alpha, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useTruncated } from '../../../hooks';
import { currencyFormat } from '../../../utils';
import SimpleTooltip from '../../Tooltip/SimpleTooltip';

export type ProgressTextBelowBarProps = {
  value: number;
  total: number;
  title: string;
  ratio?: number;
  description?: string;
  color?: 'primary' | 'success' | 'error' | 'warning';
};

const styles = {
  bar: {
    width: '100%',
    height: 8,
    borderRadius: 1,
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
  barInner: {
    height: '100%',
    borderRadius: 1,
  },
  numbers: {
    mt: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '5px',
  },
  span: {
    fontWeight: 400,
    opacity: 0.5,
  },
  titleWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 56px',
    gap: 1,
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      mt: '15px !important',
      ml: '15px !important',
    },
    maxWidth: '200px',
    zIndex: 2000,
  },
};

export default function ProgressTextBelowBar({
  value,
  total,
  ratio,
  title,
  description,
  color = 'primary',
}: ProgressTextBelowBarProps) {
  const theme = useTheme();
  const bgColor = theme.palette.gradient[color];
  const pct = (ratio === undefined ? value / total : ratio) * 100;
  const { containerRef, isTruncated } = useTruncated();

  return (
    <Box>
      <Box sx={styles.bar}>
        <Box
          sx={[styles.barInner, { background: bgColor, width: `${pct}%` }]}
        />
      </Box>
      <Box sx={styles.numbers}>
        <Typography
          sx={styles.titleWrapper}
          variant="subtitle2"
          fontWeight="bold"
        >
          <Typography
            sx={styles.title}
            variant="subtitle2"
            component="span"
            ref={containerRef}
            id="title"
          >
            {isTruncated ? (
              <SimpleTooltip
                title={title}
                slotProps={{
                  popper: {
                    sx: styles.tooltip,
                  },
                }}
                followCursor
              >
                <span>{title}</span>
              </SimpleTooltip>
            ) : (
              title
            )}
          </Typography>
          {!!description && (
            <Typography sx={styles.span} variant="subtitle2" component="span">
              {description}
            </Typography>
          )}
        </Typography>
        <Typography variant="subtitle2" fontWeight={500} whiteSpace="nowrap">
          {currencyFormat(value)}
          <Typography
            sx={styles.span}
            variant="subtitle2"
            component="span"
            fontWeight={400}
          >
            {' '}
            /{' '}
          </Typography>
          <Typography sx={styles.span} variant="subtitle2" component="span">
            {currencyFormat(total)}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}
