import { UploadImageConfig } from '@lib/ia/src/components/ImageUploader/types';

export const enum IaPanelKeys {
  UploadAvatar = 'UploadAvatar',
}

export type IaPanelParams = {
  [IaPanelKeys.UploadAvatar]: {
    config: UploadImageConfig;
    photoLibrary?: {
      title: string;
      url: string;
      icon: string;
    };
    onImageKeyChange?: (newValue: string) => void;
    onFileChange?: () => void;
    onPreviewUrlChange?: (newValue: string) => void;
    onProgressChange?: (newValue: number | null) => void;
  };
};
