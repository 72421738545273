import { useCallback } from 'react';
import { useSWRConfig } from 'swr';
import {
  getChallengeRankKey,
  getLeagueGroupListKey,
  getSearchClubMembersKey,
  getUserFollowKey,
  getUserProfileSocialKey,
} from '@lib/web/apis';

import { call } from '../utils';

import useClubSlug from './useClubSlug';
import useCurrentUserId from './useCurrentUserId';

type InfiniteData<T> = PageResponse<T> | PageResponse<T>[];

export default function useFollowingStatusMutate() {
  const userId = useCurrentUserId();
  const clubSlug = useClubSlug();
  const { mutate: globalMutate, cache } = useSWRConfig();

  const mutate = useCallback(
    <T>(
      targetPromise: Promise<any>,
      keyMatcher: (key: string) => boolean,
      optimisticData?: (data: T) => T,
      options?: {
        revalidate: boolean;
      }
    ) => {
      for (const key of cache.keys()) {
        if (keyMatcher(key)) {
          call(
            // should not use the global mutation with `matcher: (key?: Arguments) => boolean`
            // it will mutate all matched key with the same optimisticData (the data of the first matched key)
            globalMutate<unknown, T>(key, targetPromise, {
              optimisticData,
              populateCache: false, // don't need to use the response from the promise data
              revalidate: true, // need to get the updated response from server side
              rollbackOnError: true,
              ...options,
            })
          );
        }
      }
    },
    [cache, globalMutate]
  );

  const infiniteMutate = useCallback(
    <T>(
      targetPromise: Promise<any>,
      keyMatcher: (key: string) => boolean,
      optimisticData: (data: PageResponse<T>) => PageResponse<T>
    ) => {
      // infinite keys does not work with global mutate key matcher
      for (const key of cache.keys()) {
        if (keyMatcher(key)) {
          call(
            globalMutate<unknown, InfiniteData<T>>(key, targetPromise, {
              optimisticData: (data: InfiniteData<T>) => {
                if (Array.isArray(data)) {
                  return data.map((item) => optimisticData(item));
                }
                return optimisticData(data);
              },
              populateCache: false, // don't need to use the response from the promise data
              revalidate: true, // need to get the updated response from server side
              rollbackOnError: true,
            })
          );
        }
      }
    },
    [cache, globalMutate]
  );

  /**
   * To reload number of following/followers
   */
  const mutateCurrentUserSocialProfile = useCallback(
    (targetPromise: Promise<any>) => {
      const socialProfileKey = getUserProfileSocialKey({ userId });
      mutate(targetPromise, (key: string) => socialProfileKey === key);
    },
    [mutate, userId]
  );

  const mutateTargetUserSocialProfile = useCallback(
    (targetUserId: string, targetPromise: Promise<any>) => {
      const socialProfileKey = getUserProfileSocialKey({
        userId: targetUserId,
      });
      mutate(
        targetPromise,
        (key: string) => key.includes(socialProfileKey),
        (data: Response<GerProfileSocialRes>) => {
          return {
            ...data,
            data: {
              ...data.data,
              isFollowing: !data.data.isFollowing,
            },
          };
        }
      );
    },
    [mutate]
  );

  /**
   * Club rankings -> League tab
   */
  const mutateLeagueGroup = useCallback(
    (targetUserId: string, targetPromise: Promise<any>) => {
      const leagueGroupKey = getLeagueGroupListKey({ clubSlug: null });
      mutate(
        targetPromise,
        (key: string) => key.includes(leagueGroupKey),
        (data: Response<GetLeagueGroupItemRes[]>) => {
          return {
            ...data,
            data: data.data.map((item: GetLeagueGroupItemRes) => {
              if (item.userId !== targetUserId) return item;
              return {
                ...item,
                isFollowing: !item.isFollowing,
              };
            }),
          };
        }
      );
    },
    [mutate]
  );

  /**
   * Challenge Details page -> Challengers RHS panel
   */
  const mutateChallengeRank = useCallback(
    (targetUserId: string, targetPromise: Promise<any>) => {
      const leagueGroupKeyPattern = getChallengeRankKey('([^/]*)') as string;
      const leagueGroupKey = new RegExp(leagueGroupKeyPattern);
      mutate(
        targetPromise,
        (key: string) => leagueGroupKey.test(key),
        (data: PageResponse<GetChallengeRankingRes>) => {
          return {
            ...data,
            data: {
              ...data.data,
              items: data.data.items.map((item: GetChallengeRankingRes) => {
                if (item.floatingProfile.userId !== targetUserId) return item;
                return {
                  ...item,
                  floatingProfile: {
                    ...item.floatingProfile,
                    isFollowing: !item.floatingProfile.isFollowing,
                  },
                };
              }),
            },
          };
        }
      );
    },
    [mutate]
  );

  const mutateFollowList = useCallback(
    (
      targetUserId: string,
      targetPromise: Promise<any>,
      type: 'following' | 'follower'
    ) => {
      if (!userId) return;
      const followKey = getUserFollowKey({
        userId,
        type,
        userIdParamExcluded: true,
      });
      infiniteMutate(
        targetPromise,
        (key: string) => key.includes(followKey),
        (data: PageResponse<GetFollowerRes>) => {
          return {
            ...data,
            data: {
              ...data.data,
              items: data.data.items.map((item: GetFollowerRes) => {
                if (item.userId !== targetUserId) return item;
                return {
                  ...item,
                  isFollowing: !item.isFollowing,
                };
              }),
            },
          };
        }
      );
    },
    [userId, infiniteMutate]
  );

  /**
   * 1. Club rankings -> Following tab
   * 2. Profile -> Account -> Following RHS panel
   */
  const mutateFollowingList = useCallback(
    (targetUserId: string, targetPromise: Promise<any>) => {
      mutateFollowList(targetUserId, targetPromise, 'following');
    },
    [mutateFollowList]
  );

  /**
   * 1. Club rankings -> Followers tab
   * 2. Profile -> Account -> Followers RHS panel
   */
  const mutateFollowerList = useCallback(
    (targetUserId: string, targetPromise: Promise<any>) => {
      mutateFollowList(targetUserId, targetPromise, 'follower');
    },
    [mutateFollowList]
  );

  const mutateClubMembersList = useCallback(
    (targetUserId: string, targetPromise: Promise<any>) => {
      if (!clubSlug) return;
      const clubMembersKey = getSearchClubMembersKey({ clubSlug, keyword: '' });
      infiniteMutate(
        targetPromise,
        (key: string) => key.includes(clubMembersKey),
        (data: PageResponse<GetSearchClubMembersRes>) => {
          return {
            ...data,
            data: {
              ...data.data,
              items: data.data.items.map((item: GetSearchClubMembersRes) => {
                if (item.userId !== targetUserId) return item;
                return {
                  ...item,
                  isFollowing: !item.isFollowing,
                };
              }),
            },
          };
        }
      );
    },
    [clubSlug, infiniteMutate]
  );

  const mutateMemberList = useCallback(
    (targetUserId: string, targetPromise: Promise<any>) => {
      infiniteMutate(
        targetPromise,
        (key: string) => key.includes('v2/member?keywordFuzzy='),
        (data: PageResponse<GetFriendUserRes>) => {
          return {
            ...data,
            data: {
              ...data.data,
              items: data.data.items.map((item: GetFriendUserRes) => {
                if (item.userId !== targetUserId) return item;
                return {
                  ...item,
                  isFollowing: !item.isFollowing,
                };
              }),
            },
          };
        }
      );
    },
    [infiniteMutate]
  );

  const mutateAll = useCallback(
    (targetUserId: string, targetPromise: Promise<any>) => {
      mutateFollowingList(targetUserId, targetPromise);
      mutateFollowerList(targetUserId, targetPromise);
      mutateLeagueGroup(targetUserId, targetPromise);
      mutateClubMembersList(targetUserId, targetPromise);
      mutateChallengeRank(targetUserId, targetPromise);
      mutateTargetUserSocialProfile(targetUserId, targetPromise);
      mutateCurrentUserSocialProfile(targetPromise);
      mutateMemberList(targetUserId, targetPromise);
    },
    [
      mutateClubMembersList,
      mutateFollowerList,
      mutateFollowingList,
      mutateLeagueGroup,
      mutateChallengeRank,
      mutateTargetUserSocialProfile,
      mutateCurrentUserSocialProfile,
      mutateMemberList,
    ]
  );

  return {
    mutate: mutateAll,
  };
}
