import { useMemo } from 'react';
import { useShareToDirect } from '@lib/web/hooks';

interface VirtualRouter {
  pathname: string;
  asPath: string;
  query: Record<string, string>;
}

export default function useSuggestionMembersLayoutAction({
  virtualRouter,
  setState = () => {},
}: {
  virtualRouter?: VirtualRouter;
  setState?: (id: string, state?: 'loading' | 'sent') => void;
} = {}) {
  const { shareToDirect, pageSettings } = useShareToDirect(virtualRouter);

  return useMemo(
    () => ({
      selectChanged: {
        action: async ({ id }: { id: string }) => {
          if (!pageSettings) return;
          setState(id, 'loading');
          const userIds = [id];
          await shareToDirect({ userIds });
          setState(id, 'sent');
          setTimeout(() => {
            setState?.(id, undefined);
          }, 3000);
        },
      },
    }),
    [pageSettings, shareToDirect, setState]
  );
}
