import React from 'react';

export default function NFTGift({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.3429 6.57343H16.4182C17.5126 6.23666 18.2912 5.35277 18.2912 4.30048C18.2912 3.5007 17.7651 2.67991 16.9864 2.25904C16.2288 1.85913 15.3239 1.92227 14.5451 2.44841C13.7243 2.99557 13.0298 3.92158 12.5667 4.6371C12.1037 3.92158 11.4092 2.99553 10.5884 2.44841C9.80975 1.92242 8.92586 1.85913 8.14705 2.25904C7.3894 2.65894 6.84225 3.5007 6.84225 4.30048C6.84225 5.35277 7.62091 6.23669 8.71533 6.57343H3.79061C3.45384 6.57343 3.18036 6.86806 3.20133 7.20484L3.43284 9.47778C3.45383 9.77242 3.70648 10.0039 4.02212 10.0039H21.1324C21.427 10.0039 21.6795 9.77242 21.7216 9.47778L21.9532 7.20484C21.9532 6.86807 21.7005 6.57343 21.3428 6.57343H21.3429ZM15.3238 3.60593C15.6815 3.35343 16.0393 3.3113 16.3551 3.47968C16.7128 3.66906 16.9233 4.04795 16.9233 4.30046C16.9233 4.86874 16.271 5.35275 15.4711 5.35275H13.7453C14.1452 4.7425 14.6923 4.00582 15.3237 3.60595L15.3238 3.60593ZM8.23135 4.30044C8.23135 4.04794 8.44187 3.66903 8.79962 3.47966C8.92588 3.41653 9.05213 3.3744 9.19938 3.3744C9.4099 3.3744 9.62026 3.43752 9.83079 3.58491C10.441 4.00579 11.0093 4.74247 11.3882 5.33172H9.66239C8.88373 5.35271 8.23135 4.86871 8.23135 4.30043V4.30044Z"
        fill="currentColor"
      />
      <path
        d="M4.02222 12.3399L4.63247 21.4527C4.65346 21.7474 4.90611 21.9999 5.22175 21.9999H11.7039V11.7085H4.61142C4.27466 11.7085 4.00102 12.0031 4.02214 12.3399H4.02222Z"
        fill="currentColor"
      />
      <path
        d="M20.5221 11.7085H13.4297V21.9999H19.9118C20.2274 21.9999 20.4801 21.7684 20.5011 21.4527L21.1113 12.3399C21.1325 12.0031 20.8588 11.7085 20.522 11.7085H20.5221Z"
        fill="currentColor"
      />
    </svg>
  );
}
