import { useCallback } from 'react';
import { Block, BlockNoteEditor, BlockSchema } from '@blocknote/core';
import {
  blockLevelProseMirrorNodeToComposerBlocks,
  cloneComposerBlock,
  ComposerBlock,
} from '@lib/web/composer';
import { useCopyToClipboard } from '@lib/web/hooks';

export function useGeneralBlockMoreActions<BSchema extends BlockSchema>({
  editor,
  block,
}: {
  editor: BlockNoteEditor<BSchema>;
  block: Block<BSchema> | ComposerBlock | null;
}) {
  const [, copy, readText] = useCopyToClipboard();

  const copyBlockToClipboard = useCallback(() => {
    const blockDom = editor.domElement.querySelector(
      `[data-id='${block?.id}']`
    );

    if (!blockDom) return;

    const pos = editor._tiptapEditor.view.posAtDOM(blockDom, 0);
    const node = editor._tiptapEditor.state.doc.nodeAt(pos);

    if (!node) return;

    const blocks = blockLevelProseMirrorNodeToComposerBlocks(node);

    void copy(JSON.stringify(blocks));
  }, [
    block?.id,
    copy,
    editor._tiptapEditor.state.doc,
    editor._tiptapEditor.view,
    editor.domElement,
  ]);

  const pasteClipboardBlockToBelow = useCallback(async () => {
    const text = await readText();
    const blockDom = editor.domElement.querySelector(
      `[data-id='${block?.id}']`
    );
    if (!blockDom || !text) return;

    let node;

    try {
      node = JSON.parse(text);
    } catch (e) {
      return;
    }

    const newBlock = Array.isArray(node)
      ? node.map(cloneComposerBlock)
      : cloneComposerBlock(node);

    if (!newBlock) return;

    const pos = editor._tiptapEditor.view.posAtDOM(blockDom, 1);

    editor._tiptapEditor
      .chain()
      .insertContentAt(pos + 1, newBlock)
      .run();
  }, [block?.id, editor._tiptapEditor, editor.domElement, readText]);

  const duplicateBlock = useCallback(() => {
    const blockDom = editor.domElement.querySelector(
      `[data-id='${block?.id}']`
    );

    if (!blockDom) return;

    const copyPos = editor._tiptapEditor.view.posAtDOM(blockDom, 0);
    const pastePos = editor._tiptapEditor.view.posAtDOM(blockDom, 1);
    const node = editor._tiptapEditor.state.doc.nodeAt(copyPos);

    if (!node) return;

    const blocks = blockLevelProseMirrorNodeToComposerBlocks(node);
    const newBlocks = blocks.map(cloneComposerBlock);

    editor._tiptapEditor
      .chain()
      .insertContentAt(pastePos + 1, newBlocks)
      .run();
  }, [block?.id, editor._tiptapEditor, editor.domElement]);

  const cutBlock = useCallback(() => {
    if (!block) return;

    void copyBlockToClipboard();
    editor.removeBlocks([block]);
  }, [block, copyBlockToClipboard, editor]);

  const deleteBlock = useCallback(() => {
    if (!block) return;

    editor.removeBlocks([block]);
  }, [block, editor]);

  return {
    cutBlock,
    copyBlockToClipboard,
    pasteClipboardBlockToBelow,
    duplicateBlock,
    deleteBlock,
  };
}
