import React from 'react';

export default function CryptoCurrencyBinanceAlt({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M23.6383 14.9029C22.0356 21.3315 15.5244 25.2438 9.09503 23.6407C2.66833 22.0381 -1.24401 15.5265 0.359382 9.09837C1.96137 2.66903 8.47253 -1.24361 14.8999 0.359081C21.3289 1.96177 25.241 8.47405 23.6381 14.903L23.6382 14.9029H23.6383Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M12 7.616L8.89267 10.7233L8.89274 10.7232L7.0848 8.9152L12 4L16.9168 8.91667L15.1088 10.7247L12 7.616ZM5.80806 10.1917L4 12L5.80794 13.8077L7.616 11.9996L5.80806 10.1917ZM8.89285 13.2769L12.0002 16.384L15.1089 13.2754L16.9179 15.0824L16.917 15.0834L12.0002 20L7.08498 15.0848L7.08242 15.0822L8.89285 13.2769ZM18.192 10.1928L16.384 12.0008L18.192 13.8088L20 12.0007L18.192 10.1928Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M13.8339 11.9992H13.8346L12.0001 10.1646L10.6442 11.5201L10.4884 11.676L10.1671 11.9973L10.1646 11.9998L10.1671 12.0024L12.0001 13.8357L13.8348 12.0011L13.8357 12.0001L13.834 11.9992"
        fill="white"
      />
    </svg>
  );
}
