import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  blur: {
    position: 'relative',
    filter: 'blur(5px)',
  },
};
export default function IaBlurCell() {
  return (
    <Box sx={styles.root}>
      <Typography variant="body2" sx={[styles.text, styles.blur]}>
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout.
      </Typography>
    </Box>
  );
}
