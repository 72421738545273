import React from 'react';

export default function EditorLessThan({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.9371 18.9999C16.2544 18.9999 16.5681 18.8503 16.7775 18.5651C17.1362 18.0758 17.0513 17.3721 16.5875 16.9936L10.4705 11.9997L16.5879 7.00631C17.0517 6.62786 17.1368 5.92407 16.7779 5.43474C16.4187 4.94541 15.7516 4.85571 15.2883 5.23432L7 11.9998L15.2878 18.7658C15.4813 18.9238 15.7102 19 15.9371 19V18.9999Z"
        fill="currentColor"
      />
    </svg>
  );
}
