const config = {
  navWidth: 56,
  subNavWidth: 56,
  leftPanelWidth: 240,
  rightPanelMinWidth: 320,
  rightPanelMaxWidth: 572,
  rightPanelAnimationDurationInMs: 300,
};

export default config;
