import React from 'react';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import DesktopBreadcrumbs from '../DesktopBreadcrumbs';
import MobileBreadcrumbs from '../MobileBreadcrumbs';
import { BreadcrumbsValue } from '../types';

export type ResponsiveBreadcrumbsProps = {
  breadcrumbsValues: BreadcrumbsValue[];
  mobileBottomSheetTitle: string;
  breakpoint?: 'sm' | 'md' | 'lg';
};

export default function ResponsiveBreadcrumbs({
  breadcrumbsValues,
  mobileBottomSheetTitle,
  breakpoint = 'md',
}: ResponsiveBreadcrumbsProps) {
  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(breakpoint)
  );

  if (isDesktop) {
    return <DesktopBreadcrumbs breadcrumbsValues={breadcrumbsValues} />;
  }

  return (
    <MobileBreadcrumbs
      breadcrumbsValues={breadcrumbsValues}
      bottomSheetTitle={mobileBottomSheetTitle}
    />
  );
}
