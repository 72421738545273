import React from 'react';

export default function OtherBackspace({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.70679 4.87916C9.26924 4.31644 10.0322 4.00017 10.8278 4H19C19.7957 4 20.5588 4.3162 21.1214 4.87895C21.684 5.44169 22 6.20486 22 7.00057V17.0034C22 17.7991 21.684 18.5623 21.1214 19.1251C20.5588 19.6878 19.7957 20.004 19 20.004H10.828C10.0324 20.0038 9.26924 19.6876 8.70679 19.1248C8.70676 19.1248 8.70683 19.1249 8.70679 19.1248L2.29279 12.709C1.9024 12.3185 1.9024 11.6855 2.29279 11.295L8.70679 4.87916C8.70683 4.87913 8.70676 4.8792 8.70679 4.87916ZM10.828 6C10.563 6.00011 10.3088 6.10549 10.1213 6.29307L4.41401 12.002L10.1212 17.7108C10.3087 17.8984 10.563 18.0039 10.828 18.004C10.8279 18.004 10.8281 18.004 10.828 18.004H19C19.2651 18.004 19.5194 17.8987 19.707 17.711C19.8946 17.5234 20 17.2689 20 17.0034V7.00057C20 6.73511 19.8946 6.48058 19.707 6.29296C19.5194 6.10535 19.2651 6 19 6H10.828C10.8281 6 10.8279 6 10.828 6ZM11.293 9.29422C11.6836 8.90375 12.3167 8.90384 12.7072 9.29442L14 10.5876L15.2928 9.29442C15.6833 8.90384 16.3164 8.90375 16.707 9.29422C17.0976 9.68469 17.0977 10.3179 16.7072 10.7084L15.414 12.002L16.7072 13.2956C17.0977 13.6861 17.0976 14.3193 16.707 14.7098C16.3164 15.1002 15.6833 15.1002 15.2928 14.7096L14 13.4164L12.7072 14.7096C12.3167 15.1002 11.6836 15.1002 11.293 14.7098C10.9024 14.3193 10.9023 13.6861 11.2928 13.2956L12.586 12.002L11.2928 10.7084C10.9023 10.3179 10.9024 9.68469 11.293 9.29422Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
