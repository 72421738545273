import { useEffect } from 'react';

import { useGetLineMarkerContent } from './useGetLineMarkerContent';

export function useRenderLineMarker(
  triggerRender?: number | string | boolean,
  targetElement?: HTMLElement | null
) {
  const { getLineMarkerContent } = useGetLineMarkerContent();

  useEffect(() => {
    const allLineMarkTarget = (targetElement || document).querySelectorAll(
      '.inline-line-marker'
    );
    allLineMarkTarget.forEach((lineMarker) => {
      const targetId = lineMarker.getAttribute('data-render-target-id');
      const lineMarkerContent = getLineMarkerContent(targetId);

      if (lineMarkerContent) {
        lineMarker.innerHTML = lineMarkerContent;
      }
    });
  }, [getLineMarkerContent, targetElement]);
}
