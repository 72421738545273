import React from 'react';

export default function OtherNotes({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.6794 19.875C10.6794 19.2537 11.2456 18.75 11.9441 18.75H19.6728C20.3713 18.75 20.9375 19.2537 20.9375 19.875C20.9375 20.4963 20.3713 21 19.6728 21H11.9441C11.2456 21 10.6794 20.4963 10.6794 19.875Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M17.9716 3.3457C17.5338 2.88477 16.824 2.88476 16.3861 3.3457L3.99163 16.395C3.92666 16.4634 3.88004 16.5467 3.85602 16.6374L2.95664 20.034C2.84435 20.4581 3.24481 20.8403 3.67045 20.7154L6.82799 19.7887C6.92481 19.7603 7.01223 19.707 7.08131 19.6342L19.4694 6.59178C19.9072 6.13084 19.9072 5.38351 19.4694 4.92257L17.9716 3.3457Z"
        fill="currentColor"
      />
    </svg>
  );
}
