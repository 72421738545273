import React from 'react';

export default function CursorResize({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g>
        <path
          d="M10 4V10.01H8.98V6.54L3 12.01L8.98 17.47V13.99H10V20H10.02H13.98V13.99H15.04V17.46L21 11.99L15.04 6.53V10H13.98V4H10Z"
          fill="white"
        />
        <path
          d="M19.3402 11.99L16.0202 8.77004V11.02H13.0002V5.06004H12.9802V5.04004H11.0402V11.04H7.98016V8.76004L4.66016 12.01L7.98016 15.23V12.98H11.0402V18.98H11.0602H12.9802H13.0002V12.98H16.0202V15.24L19.3402 11.99Z"
          fill="black"
        />
      </g>
      <defs />
    </svg>
  );
}
