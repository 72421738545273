import { Box } from '@mui/material';

import FeedItem from './components/FeedItem';
import { FeedLayoutConfig } from './types';

type FeedLayoutProps = FeedLayoutConfig;

export default function FeedLayout({ items }: FeedLayoutProps) {
  return (
    <Box>
      {items.map((item, index) => (
        <FeedItem key={index} {...item} />
      ))}
    </Box>
  );
}
