import { useMemo } from 'react';
import {
  StructureType,
  useSupportedQuestionAnswerFormatType,
} from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

import { useCreatorQuestionListData } from './useCreatorQuestionListData';

const useGroupByAnswerFormat = (
  resList?: GetSupportedQuestionAnswerFormatTypeRes[]
) => {
  return useMemo(() => {
    if (!resList) {
      return { answerFormats: [], questionGroups: [] };
    }

    const answerFormats: GetSupportedQuestionAnswerFormatTypeRes[] = [];
    const questionGroups: GetSupportedQuestionAnswerFormatTypeRes[] = [];

    resList.forEach((answerFormatType) => {
      if (answerFormatType.structureType === StructureType.QuestionGroup) {
        questionGroups.push(answerFormatType);
      } else {
        answerFormats.push(answerFormatType);
      }
    });

    return {
      answerFormats,
      questionGroups,
    };
  }, [resList]);
};

export const useChangeableAnswerFormat = (currentQuestionId: string) => {
  const { sectionId } = useCurrentIaClub();
  const { data } = useSupportedQuestionAnswerFormatType(sectionId);
  const { selectQuestion } = useCreatorQuestionListData();

  const { answerFormats, questionGroups } = useGroupByAnswerFormat(data?.data);

  return useMemo(() => {
    const structureType = selectQuestion(currentQuestionId)?.structureType;
    const canChangeToAnswerFormat = true;
    const canChangeToQuestionGroup =
      structureType !== StructureType.SubQuestion;

    return {
      answerFormats: canChangeToAnswerFormat ? answerFormats : [],
      questionGroups: canChangeToQuestionGroup ? questionGroups : [],
    };
  }, [answerFormats, currentQuestionId, questionGroups, selectQuestion]);
};

export const useAddableAnswerFormat = ({
  anchorQuestion,
}: {
  anchorQuestion?: {
    id: string;
    questionGroupId?: string;
  };
} = {}) => {
  const { sectionId } = useCurrentIaClub();
  const { data } = useSupportedQuestionAnswerFormatType(sectionId);

  const { answerFormats, questionGroups } = useGroupByAnswerFormat(data?.data);

  const addUnderQuestionGroup = !!anchorQuestion?.questionGroupId;

  return {
    answerFormats,
    questionGroups: addUnderQuestionGroup ? [] : questionGroups,
    defaultAnswerFormatType:
      answerFormats.length > 0 ? answerFormats[0].answerFormatType : null,
  };
};
