import React from 'react';

export default function TradingForward({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18 14C18 17.31 15.31 20 12 20C8.69 20 6 17.31 6 14C6 10.69 8.69 8 12 8V12L17 7L12 2V6C7.58 6 4 9.58 4 14C4 18.42 7.58 22 12 22C16.42 22 20 18.42 20 14H18Z"
        fill="currentColor"
      />
      <path
        d="M10.86 16.94V12.67H10.77L9 13.3V13.99L10.01 13.68V16.94H10.86Z"
        fill="currentColor"
      />
      <path
        d="M12.25 14.44V15.18C12.25 17.08 13.56 17 13.69 17C13.83 17 15.13 17.09 15.13 15.18V14.44C15.13 12.54 13.82 12.62 13.69 12.62C13.55 12.62 12.25 12.53 12.25 14.44ZM14.29 14.32V15.29C14.29 16.06 14.08 16.32 13.7 16.32C13.32 16.32 13.1 16.06 13.1 15.29V14.32C13.1 13.57 13.32 13.31 13.69 13.31C14.07 13.3 14.29 13.57 14.29 14.32Z"
        fill="currentColor"
      />
    </svg>
  );
}
