import React from 'react';

export default function ProfileStudentDashboard({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.2222 4.8C9.98647 4.8 9.76038 4.89482 9.59368 5.0636C9.42698 5.23239 9.33333 5.46131 9.33333 5.7C9.33333 5.93869 9.42698 6.16761 9.59368 6.3364C9.76038 6.50518 9.98647 6.6 10.2222 6.6H13.7778C14.0135 6.6 14.2396 6.50518 14.4063 6.3364C14.573 6.16761 14.6667 5.93869 14.6667 5.7C14.6667 5.46131 14.573 5.23239 14.4063 5.0636C14.2396 4.89482 14.0135 4.8 13.7778 4.8H10.2222ZM10.2222 3C9.67056 2.99984 9.13242 3.17285 8.68191 3.49522C8.2314 3.81758 7.89068 4.27343 7.70667 4.8H6.66667C5.95942 4.8 5.28115 5.08446 4.78105 5.59081C4.28095 6.09716 4 6.78392 4 7.5V18.3C4 19.0161 4.28095 19.7028 4.78105 20.2092C5.28115 20.7155 5.95942 21 6.66667 21H17.3333C18.0406 21 18.7189 20.7155 19.219 20.2092C19.719 19.7028 20 19.0161 20 18.3V7.5C20 6.78392 19.719 6.09716 19.219 5.59081C18.7189 5.08446 18.0406 4.8 17.3333 4.8H16.2933C16.1093 4.27343 15.7686 3.81758 15.3181 3.49522C14.8676 3.17285 14.3294 2.99984 13.7778 3H10.2222ZM8.44444 10.2C8.2087 10.2 7.9826 10.2948 7.81591 10.4636C7.64921 10.6324 7.55556 10.8613 7.55556 11.1C7.55556 11.3387 7.64921 11.5676 7.81591 11.7364C7.9826 11.9052 8.2087 12 8.44444 12H15.5556C15.7913 12 16.0174 11.9052 16.1841 11.7364C16.3508 11.5676 16.4444 11.3387 16.4444 11.1C16.4444 10.8613 16.3508 10.6324 16.1841 10.4636C16.0174 10.2948 15.7913 10.2 15.5556 10.2H8.44444ZM8.44444 13.8C8.2087 13.8 7.9826 13.8948 7.81591 14.0636C7.64921 14.2324 7.55556 14.4613 7.55556 14.7C7.55556 14.9387 7.64921 15.1676 7.81591 15.3364C7.9826 15.5052 8.2087 15.6 8.44444 15.6H12C12.2357 15.6 12.4618 15.5052 12.6285 15.3364C12.7952 15.1676 12.8889 14.9387 12.8889 14.7C12.8889 14.4613 12.7952 14.2324 12.6285 14.0636C12.4618 13.8948 12.2357 13.8 12 13.8H8.44444Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
