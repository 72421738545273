import { MouseEvent } from 'react';
import { Icon, MaskIcon, SquareAvatar } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { IaHoverEvent } from '@lib/ia/src/core/types';

import { FeedLayoutMessageAvatar } from '../types';

const styles = {
  maskIcon: {
    width: '24px',
    height: '24px',
  },
};

type SenderAvatarProps = FeedLayoutMessageAvatar;

export default function SenderAvatar(props: SenderAvatarProps) {
  const { getIaAction } = useIaAction();

  if (props.type === 'club') {
    const { clubIcon, name } = props;
    return (
      <SquareAvatar size={24} src={clubIcon}>
        {name}
      </SquareAvatar>
    );
  }
  if (props.type === 'user') {
    const { avatarUrl, name, userId, hoverAction, className } = props;
    const hoverActionProps = hoverAction
      ? {
          onMouseEnter: (ev: MouseEvent) => {
            getIaAction<IaHoverEvent<{ userId: string }>>(
              hoverAction?.value
            )?.action({
              anchorEl: ev.currentTarget,
              target: {
                userId,
              },
            });
          },
        }
      : undefined;
    return (
      <SquareAvatar
        size={24}
        src={avatarUrl}
        className={className}
        {...hoverActionProps}
      >
        {name}
      </SquareAvatar>
    );
  }
  if (props.type === 'icon') {
    const { icon, bgColor, color } = props;
    return (
      <MaskIcon
        sx={[
          styles.maskIcon,
          {
            color,
            background: bgColor,
          },
        ]}
      >
        <Icon name={icon} width={14} height={14} />
      </MaskIcon>
    );
  }
  if (props.type === 'default') {
    const { avatarUrl, name } = props;
    return (
      <SquareAvatar size={24} src={avatarUrl}>
        {name}
      </SquareAvatar>
    );
  }
  return null;
}
