import React, { ReactNode, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { ComposerBlock } from '@lib/web/composer';
import {
  EditorBlockTypes,
  syncHighlightOptions,
  useEditorDispatch,
} from '@lib/web/editor';
import { EditorTextComposerProps } from '@lib/web/editor/EditorTextComposer';

type HighlightPassageProps = {
  enable: boolean;
  defaultBlocks: EditorTextComposerProps['defaultBlocks'];
  children: (params: {
    handleBlocksChange: (blocks: ComposerBlock[]) => void;
  }) => ReactNode;
};

const getDisplayText = (text: string) => {
  const contentArr = text.split(' ').filter((txt) => !!txt);

  return contentArr.length <= 1
    ? contentArr[0]
    : `${contentArr[0]}...${contentArr[contentArr.length - 1]}`;
};

const getHighlightOptionsFromAllAnchorBlock = (blocks: ComposerBlock[]) => {
  let result: { id: string; value: string; display: string }[] = [];

  blocks.forEach((block) => {
    if (block.type === EditorBlockTypes.InlineHighlightAnchor) {
      result.push({
        id: block.attrs.id,
        value: block.attrs.value,
        display:
          typeof block.attrs.value === 'string'
            ? getDisplayText(block.attrs.value)
            : '',
      });
    } else if (block.content?.length) {
      result = result.concat(
        getHighlightOptionsFromAllAnchorBlock(block.content)
      );
    }
  });

  return result;
};

export default function HighlightPassage({
  enable,
  defaultBlocks,
  children,
}: HighlightPassageProps) {
  const dispatch = useEditorDispatch();
  const loaded = useRef(false);
  const handleBlocksChange = (blocks: ComposerBlock[]) => {
    dispatch(
      syncHighlightOptions(getHighlightOptionsFromAllAnchorBlock(blocks))
    );
  };

  useEffect(() => {
    if (!enable) return;

    if (defaultBlocks && !loaded.current) {
      loaded.current = true;

      // Preset sync on first rendered defaultBlocks
      dispatch(
        syncHighlightOptions(
          getHighlightOptionsFromAllAnchorBlock(defaultBlocks)
        )
      );
    }
  }, [defaultBlocks, dispatch, enable]);

  if (!enable) return children({ handleBlocksChange: () => {} });

  return <Box>{children({ handleBlocksChange })}</Box>;
}
