import api from './client';

const analyticsApi = {
  generateAnalyticsLink: (params: GenerateAnalyticsLinkReq) =>
    api.post<Response<{ url: string }>>('v2/analytics/share/link', params),
  shareAnalyticsLink: (params: ShareAnalyticsLinkReq) =>
    api.post<Response<ShareAnalyticsLinkRes>>('v2/analytics/share', params),
};

export default analyticsApi;
