import React from 'react';

export default function SocialMediaMessenger({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.8081 20.5195C11.1766 20.4235 10.4232 20.2851 9.66404 20.2045C9.32352 20.1673 8.979 20.2 8.65152 20.3005C8.15341 20.4609 7.68602 20.7174 7.19751 20.9153C6.62167 21.1507 6.16867 20.8836 6.11013 20.2611C6.07474 19.8779 6.06864 19.4925 6.09189 19.1084C6.11973 18.6377 5.9393 18.3034 5.60051 17.9768C2.15408 14.6521 2.13297 8.89605 5.53908 5.55982C9.97308 1.21685 17.8237 2.59918 20.25 8.20056C22.4574 13.3053 19.6588 19.3437 13.6114 20.3351C13.0606 20.4235 12.5001 20.4513 11.8081 20.5195ZM10.5604 9.46569C9.8723 9.4801 9.47593 9.70969 9.19953 10.1487C8.3556 11.491 7.51359 12.8311 6.67349 14.1689C6.52857 14.3994 6.40189 14.6867 6.6831 14.8413C6.84721 14.9297 7.1812 14.8749 7.34819 14.7568C8.17997 14.165 8.99575 13.5483 9.79553 12.9066C10.1967 12.5839 10.5278 12.5512 10.9395 12.8893C11.4962 13.3437 12.0912 13.7539 12.6671 14.1775C13.5251 14.8067 14.2919 14.6723 14.8553 13.7827C15.6662 12.5003 16.4686 11.2111 17.2767 9.92583C17.4264 9.68855 17.5876 9.44552 17.3276 9.19576C17.0675 8.946 16.8333 9.11603 16.6068 9.29182C15.7824 9.92391 14.9464 10.5406 14.1307 11.1842C13.7717 11.4724 13.4636 11.4791 13.1028 11.1929C12.502 10.7126 11.8916 10.2505 11.263 9.81152C11.0144 9.62996 10.7054 9.53582 10.5566 9.46665L10.5604 9.46569Z"
        fill="currentColor"
      />
    </svg>
  );
}
