import { PointerEvent, useCallback, useState } from 'react';

type TooltipStateValue = { anchorEl?: Element; title?: string; open?: boolean };
export default function useControlledTooltip() {
  const [tooltipState, setToolState] = useState<TooltipStateValue>({
    open: false,
  });

  const pointerEnter = (ev: PointerEvent<HTMLButtonElement>, title: string) => {
    if (ev.pointerType === 'mouse') {
      setToolState({ anchorEl: ev.target as Element, open: true, title });
    }
  };

  const pointerLeave = () => {
    setToolState((st) => ({ ...st, open: false }));
  };

  const clearTooltipState = useCallback(() => {
    setToolState({ open: false });
  }, []);

  return {
    tooltipState,
    pointerEnter,
    pointerLeave,
    clearTooltipState,
  };
}
