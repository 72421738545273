import React from 'react';

export default function PropertyTypeSelect({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.9998 14.9999L7.49976 10.4999H16.4998L11.9998 14.9999Z"
        fill="currentColor"
      />
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM3.5 12C3.5 7.30843 7.30843 3.5 12 3.5C16.6916 3.5 20.5 7.30843 20.5 12C20.5 16.6916 16.6916 20.5 12 20.5C7.30843 20.5 3.5 16.6916 3.5 12Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
