import React, { SVGAttributes } from 'react';

export default function NFTEmojiAlien({
  ...rest
}: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width="55"
      height="100"
      viewBox="0 0 55 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.686 64.2158L14.3692 65.5715L22.8701 60.2287L20.1986 58.887L11.686 64.2158Z"
        fill="#302E30"
      />
      <path
        d="M11.686 64.2158L14.3692 65.5715L22.8701 60.2287L20.1986 58.887L11.686 64.2158Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M3.65601 31.741L6.33922 33.0966L14.84 27.7539L12.1685 26.4121L3.65601 31.741Z"
        fill="#1A191B"
      />
      <path
        d="M3.65601 31.741L6.33922 33.0966L14.84 27.7539L12.1685 26.4121L3.65601 31.741Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M6.33301 29.0364L9.01622 30.392L17.517 25.0493L14.8455 23.7075L6.33301 29.0364Z"
        fill="#363235"
      />
      <path
        d="M6.33301 29.0364L9.01622 30.392L17.517 25.0493L14.8455 23.7075L6.33301 29.0364Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M17.0503 75.0674V71.0175L25.5576 65.6682V69.7262L17.0503 75.0674Z"
        fill="#302E30"
      />
      <path
        d="M17.0503 75.0674V71.0175L25.5576 65.6682V69.7262L17.0503 75.0674Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M22.4014 69.6897V65.6399L30.9087 60.2905V64.3485L22.4014 69.6897Z"
        fill="#1D1C1D"
      />
      <path
        d="M22.4014 73.7385V69.6887L30.9087 64.3394V68.3974L22.4014 73.7385Z"
        fill="#302E30"
      />
      <path
        d="M22.4014 73.7385V69.6887L30.9087 64.3394V68.3974L22.4014 73.7385Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M33.1245 75.1501L35.8077 76.5058L44.3085 71.1631L41.637 69.8213L33.1245 75.1501Z"
        fill="#302E30"
      />
      <path
        d="M33.1245 75.1501L35.8077 76.5058L44.3085 71.1631L41.637 69.8213L33.1245 75.1501Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M22.4004 37.2403V33.1721L30.9077 27.8228V31.8991L22.4004 37.2403Z"
        fill="#363235"
      />
      <path
        d="M22.4004 37.2403V33.1721L30.9077 27.8228V31.8991L22.4004 37.2403Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M22.4004 37.228L25.0836 38.5837L33.5844 33.2409L30.9129 31.8992L22.4004 37.228Z"
        fill="#363235"
      />
      <path
        d="M22.4004 37.228L25.0836 38.5837L33.5844 33.2409L30.9129 31.8992L22.4004 37.228Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M25.0859 34.543L27.7691 35.8986L36.27 30.5559L33.5985 29.2141L25.0859 34.543Z"
        fill="#363235"
      />
      <path
        d="M25.0859 34.543L27.7691 35.8986L36.27 30.5559L33.5985 29.2141L25.0859 34.543Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M27.7659 35.9033L30.4491 37.259L38.9499 31.9162L36.2784 30.5745L27.7659 35.9033Z"
        fill="#9777C6"
      />
      <path
        d="M27.7659 35.9033L30.4491 37.259L38.9499 31.9162L36.2784 30.5745L27.7659 35.9033Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M33.1215 75.1455V71.0957L41.6288 65.7463V69.8044L33.1215 75.1455Z"
        fill="#302E30"
      />
      <path
        d="M33.1215 75.1455V71.0957L41.6288 65.7463V69.8044L33.1215 75.1455Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M38.4812 73.8023V69.7524L46.9885 64.4031V68.4611L38.4812 73.8023Z"
        fill="#1A191B"
      />
      <path
        d="M38.4812 73.8023V69.7524L46.9885 64.4031V68.4611L38.4812 73.8023Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M35.8047 84.6189V80.5691L44.312 75.2197V79.2777L35.8047 84.6189Z"
        fill="#302E30"
      />
      <path
        d="M35.8047 84.6189V80.5691L44.312 75.2197V79.2777L35.8047 84.6189Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M35.8047 80.5701V76.5202L44.312 71.1709V75.2289L35.8047 80.5701Z"
        fill="#302E30"
      />
      <path
        d="M35.8047 80.5701V76.5202L44.312 71.1709V75.2289L35.8047 80.5701Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M38.4812 69.8213V65.7715L46.9885 60.4221V64.4801L38.4812 69.8213Z"
        fill="#1A191B"
      />
      <path
        d="M38.4812 69.8213V65.7715L46.9885 60.4221V64.4801L38.4812 69.8213Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M38.4812 45.4209V41.3711L46.9885 36.0217V40.0797L38.4812 45.4209Z"
        fill="#302E30"
      />
      <path
        d="M38.4812 45.4209V41.3711L46.9885 36.0217V40.0797L38.4812 45.4209Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M41.1638 50.8391V46.7893L49.6711 41.4399V45.498L41.1638 50.8391Z"
        fill="#302E30"
      />
      <path
        d="M41.1638 50.8391V46.7893L49.6711 41.4399V45.498L41.1638 50.8391Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M43.8464 56.2573V52.2075L52.3537 46.8582V50.9162L43.8464 56.2573Z"
        fill="#1A191B"
      />
      <path
        d="M43.8464 56.2573V52.2075L52.3537 46.8582V50.9162L43.8464 56.2573Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M43.8464 60.2383V56.1884L52.3537 50.8391V54.8971L43.8464 60.2383Z"
        fill="#1A191B"
      />
      <path
        d="M43.8464 60.2383V56.1884L52.3537 50.8391V54.8971L43.8464 60.2383Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M43.8464 64.2193V60.1694L52.3537 54.8201V58.8781L43.8464 64.2193Z"
        fill="#1A191B"
      />
      <path
        d="M43.8464 64.2193V60.1694L52.3537 54.8201V58.8781L43.8464 64.2193Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M43.8464 68.2002V64.1504L52.3537 58.801V62.859L43.8464 68.2002Z"
        fill="#1A191B"
      />
      <path
        d="M43.8464 68.2002V64.1504L52.3537 58.801V62.859L43.8464 68.2002Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M38.4812 41.3741V37.3059L46.9885 31.9565V36.0329L38.4812 41.3741Z"
        fill="#363235"
      />
      <path
        d="M38.4812 41.3741V37.3059L46.9885 31.9565V36.0329L38.4812 41.3741Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M17.0466 30.4483V26.3801L25.5539 21.0308V25.1071L17.0466 30.4483Z"
        fill="#363235"
      />
      <path
        d="M17.0466 30.4483V26.3801L25.5539 21.0308V25.1071L17.0466 30.4483Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M35.8044 35.9487L38.4877 37.3044L46.9885 31.9616L44.317 30.6199L35.8044 35.9487Z"
        fill="#363235"
      />
      <path
        d="M35.8044 35.9487L38.4877 37.3044L46.9885 31.9616L44.317 30.6199L35.8044 35.9487Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M38.4812 45.4199L41.1644 46.7756L49.6652 41.4328L46.9937 40.0911L38.4812 45.4199Z"
        fill="#363235"
      />
      <path
        d="M38.4812 45.4199L41.1644 46.7756L49.6652 41.4328L46.9937 40.0911L38.4812 45.4199Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M41.1638 50.8394L43.847 52.195L52.3478 46.8523L49.6763 45.5105L41.1638 50.8394Z"
        fill="#1A191B"
      />
      <path
        d="M33.1245 34.5884L35.8077 35.944L44.3085 30.6013L41.637 29.2595L33.1245 34.5884Z"
        fill="#9777C6"
      />
      <path
        d="M33.1245 34.5884L35.8077 35.944L44.3085 30.6013L41.637 29.2595L33.1245 34.5884Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M30.4446 33.228L33.1278 34.5837L41.6286 29.2409L38.9571 27.8992L30.4446 33.228Z"
        fill="#363235"
      />
      <path
        d="M30.4446 33.228L33.1278 34.5837L41.6286 29.2409L38.9571 27.8992L30.4446 33.228Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M14.3633 25.0283L17.0465 26.384L25.5473 21.0412L22.8758 19.6995L14.3633 25.0283Z"
        fill="#363235"
      />
      <path
        d="M14.3633 25.0283L17.0465 26.384L25.5473 21.0412L22.8758 19.6995L14.3633 25.0283Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M11.6833 23.668L14.3666 25.0236L22.8674 19.6809L20.1959 18.3391L11.6833 23.668Z"
        fill="#9777C6"
      />
      <path
        d="M11.6833 23.668L14.3666 25.0236L22.8674 19.6809L20.1959 18.3391L11.6833 23.668Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M19.7266 31.8022L22.4098 33.1579L30.9106 27.8151L28.2391 26.4734L19.7266 31.8022Z"
        fill="#363235"
      />
      <path
        d="M19.7266 31.8022L22.4098 33.1579L30.9106 27.8151L28.2391 26.4734L19.7266 31.8022Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M17.0466 30.4419L19.7298 31.7976L28.2306 26.4548L25.5592 25.113L17.0466 30.4419Z"
        fill="#9777C6"
      />
      <path
        d="M17.0466 30.4419L19.7298 31.7976L28.2306 26.4548L25.5592 25.113L17.0466 30.4419Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M9.00342 22.3076L11.6866 23.6633L20.1874 18.3205L17.5159 16.9788L9.00342 22.3076Z"
        fill="#363235"
      />
      <path
        d="M9.00342 22.3076L11.6866 23.6633L20.1874 18.3205L17.5159 16.9788L9.00342 22.3076Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M35.8035 80.5622L33.1245 79.1972V83.2538L35.8035 84.6188V80.5622Z"
        fill="#302E30"
      />
      <path
        d="M33.1245 79.1972L30.4456 77.8322V81.8888L33.1245 83.2538V79.1972Z"
        fill="#302E30"
      />
      <path
        d="M17.0508 71.0072L14.3718 69.6422V73.6989L17.0508 75.0639V71.0072Z"
        fill="#302E30"
      />
      <path
        d="M14.3718 69.6422L11.6929 68.2772V72.3339L14.3718 73.6989V69.6422Z"
        fill="#302E30"
      />
      <path
        d="M35.8035 76.5055L33.1245 75.1405V79.1972L35.8035 80.5622V76.5055Z"
        fill="#302E30"
      />
      <path
        d="M33.1245 75.1405L30.4456 73.7755V77.8322L33.1245 79.1972V75.1405Z"
        fill="#302E30"
      />
      <path
        d="M30.4456 73.7755L27.7666 72.4105V76.4672L30.4456 77.8322V73.7755Z"
        fill="#302E30"
      />
      <path
        d="M27.7666 72.4105L25.0876 71.0455V75.1022L27.7666 76.4672V72.4105Z"
        fill="#302E30"
      />
      <path
        d="M22.4087 69.6805L19.7297 68.3155V72.3722L22.4087 73.7372V69.6805Z"
        fill="#302E30"
      />
      <path
        d="M19.7297 68.3155L17.0508 66.9505V71.0072L19.7297 72.3722V68.3155Z"
        fill="#302E30"
      />
      <path
        d="M17.0508 66.9505L14.3718 65.5855V69.6422L17.0508 71.0072V66.9505Z"
        fill="#302E30"
      />
      <path
        d="M14.3718 65.5855L11.6929 64.2205V68.2772L14.3718 69.6422V65.5855Z"
        fill="#302E30"
      />
      <path
        d="M33.1245 71.0838L30.4456 69.7188V73.7755L33.1245 75.1405V71.0838Z"
        fill="#302E30"
      />
      <path
        d="M30.4456 69.7188L27.7666 68.3538V72.4105L30.4456 73.7755V69.7188Z"
        fill="#7856A7"
      />
      <path
        d="M27.7666 68.3538L25.0876 66.9888V71.0455L27.7666 72.4105V68.3538Z"
        fill="#302E30"
      />
      <path
        d="M22.4087 65.6238L19.7297 64.2589V68.3155L22.4087 69.6805V65.6238Z"
        fill="#302E30"
      />
      <path
        d="M19.7297 64.2589L17.0508 62.8939V66.9505L19.7297 68.3155V64.2589Z"
        fill="#7856A7"
      />
      <path
        d="M17.0508 62.8939L14.3718 61.5289V65.5855L17.0508 66.9505V62.8939Z"
        fill="#302E30"
      />
      <path
        d="M38.4824 69.7572L35.8035 68.3922V72.4488L38.4824 73.8138V69.7572Z"
        fill="#1A191B"
      />
      <path
        d="M35.8035 68.3922L33.1245 67.0272V71.0838L35.8035 72.4488V68.3922Z"
        fill="#302E30"
      />
      <path
        d="M33.1245 67.0272L30.4456 65.6622V69.7188L33.1245 71.0838V67.0272Z"
        fill="#302E30"
      />
      <path
        d="M30.4456 65.6622L27.7666 64.2972V68.3538L30.4456 69.7188V65.6622Z"
        fill="#7958A9"
      />
      <path
        d="M27.7666 64.2972L25.0876 62.9322V66.9888L27.7666 68.3538V64.2972Z"
        fill="#302E30"
      />
      <path
        d="M25.0877 62.9322L22.4087 61.5672V65.6238L25.0877 66.9888V62.9322Z"
        fill="#302E30"
      />
      <path
        d="M22.4087 61.5672L19.7297 60.2022V64.2589L22.4087 65.6238V61.5672Z"
        fill="#302E30"
      />
      <path
        d="M19.7297 60.2022L17.0508 58.8372V62.8939L19.7297 64.2589V60.2022Z"
        fill="#7958A9"
      />
      <path
        d="M17.0508 58.8372L14.3718 57.4722V61.5289L17.0508 62.8939V58.8372Z"
        fill="#302E30"
      />
      <path
        d="M14.3718 57.4722L11.6929 56.1072V60.1639L14.3718 61.5289V57.4722Z"
        fill="#302E30"
      />
      <path
        d="M11.6929 56.1072L9.01392 54.7422V58.7989L11.6929 60.1639V56.1072Z"
        fill="#1A191B"
      />
      <path
        d="M38.4824 65.7005L35.8035 64.3355V68.3922L38.4824 69.7572V65.7005Z"
        fill="#1A191B"
      />
      <path
        d="M35.8035 64.3355L33.1245 62.9705V67.0272L35.8035 68.3922V64.3355Z"
        fill="#7D5CAC"
      />
      <path
        d="M33.1245 62.9705L30.4456 61.6055V65.6622L33.1245 67.0272V62.9705Z"
        fill="#7D5BAC"
      />
      <path
        d="M30.4456 61.6055L27.7666 60.2405V64.2972L30.4456 65.6622V61.6055Z"
        fill="#7D5CAB"
      />
      <path
        d="M27.7666 60.2405L25.0876 58.8755V62.9322L27.7666 64.2972V60.2405Z"
        fill="#7E5DAC"
      />
      <path
        d="M25.0877 58.8755L22.4087 57.5105V61.5672L25.0877 62.9322V58.8755Z"
        fill="#7D5AAB"
      />
      <path
        d="M22.4087 57.5105L19.7297 56.1455V60.2022L22.4087 61.5672V57.5105Z"
        fill="#7E5DAC"
      />
      <path
        d="M19.7297 56.1455L17.0508 54.7805V58.8372L19.7297 60.2022V56.1455Z"
        fill="#7D5CAB"
      />
      <path
        d="M17.0508 54.7805L14.3718 53.4155V57.4722L17.0508 58.8372V54.7805Z"
        fill="#7D5BAC"
      />
      <path
        d="M14.3718 53.4155L11.6929 52.0505V56.1072L14.3718 57.4722V53.4155Z"
        fill="#7D5CAC"
      />
      <path
        d="M11.6929 52.0505L9.01392 50.6855V54.7422L11.6929 56.1072V52.0505Z"
        fill="#1A191B"
      />
      <path
        d="M43.8405 64.3738L41.1615 63.0088V67.0655L43.8405 68.4305V64.3738Z"
        fill="#1A191B"
      />
      <path
        d="M41.1614 63.0088L38.4824 61.6438V65.7005L41.1614 67.0655V63.0088Z"
        fill="#1A191B"
      />
      <path
        d="M38.4824 61.6438L35.8035 60.2788V64.3355L38.4824 65.7005V61.6438Z"
        fill="#8261B1"
      />
      <path
        d="M35.8035 60.2788L33.1245 58.9138V62.9705L35.8035 64.3355V60.2788Z"
        fill="#8061B0"
      />
      <path
        d="M33.1245 58.9138L30.4456 57.5488V61.6055L33.1245 62.9705V58.9138Z"
        fill="#8261B2"
      />
      <path
        d="M30.4456 57.5488L27.7666 56.1838V60.2405L30.4456 61.6055V57.5488Z"
        fill="#1A191B"
      />
      <path
        d="M27.7666 56.1838L25.0876 54.8188V58.8755L27.7666 60.2405V56.1838Z"
        fill="#8160B0"
      />
      <path
        d="M25.0877 54.8188L22.4087 53.4538V57.5105L25.0877 58.8755V54.8188Z"
        fill="#815FB0"
      />
      <path
        d="M22.4087 53.4538L19.7297 52.0889V56.1455L22.4087 57.5105V53.4538Z"
        fill="#8160B0"
      />
      <path
        d="M19.7297 52.0889L17.0508 50.7239V54.7805L19.7297 56.1455V52.0889Z"
        fill="#1A191B"
      />
      <path
        d="M17.0508 50.7239L14.3718 49.3589V53.4155L17.0508 54.7805V50.7239Z"
        fill="#8261B2"
      />
      <path
        d="M14.3718 49.3589L11.6929 47.9939V52.0505L14.3718 53.4155V49.3589Z"
        fill="#8061B0"
      />
      <path
        d="M11.6929 47.9939L9.01392 46.6289V50.6855L11.6929 52.0505V47.9939Z"
        fill="#8261B1"
      />
      <path
        d="M9.01392 46.6289L6.33496 45.2639V49.3205L9.01392 50.6855V46.6289Z"
        fill="#1A191B"
      />
      <path
        d="M6.33497 45.2639L3.65601 43.8989V47.9555L6.33497 49.3205V45.2639Z"
        fill="#1A191B"
      />
      <path
        d="M43.8405 60.3172L41.1615 58.9522V63.0088L43.8405 64.3738V60.3172Z"
        fill="#1A191B"
      />
      <path
        d="M41.1614 58.9522L38.4824 57.5872V61.6438L41.1614 63.0088V58.9522Z"
        fill="#8767B6"
      />
      <path
        d="M38.4824 57.5872L35.8035 56.2222V60.2788L38.4824 61.6438V57.5872Z"
        fill="#242426"
      />
      <path
        d="M35.8035 56.2222L33.1245 54.8572V58.9138L35.8035 60.2788V56.2222Z"
        fill="#8766B6"
      />
      <path
        d="M33.1245 54.8572L30.4456 53.4922V57.5488L33.1245 58.9138V54.8572Z"
        fill="#8766B7"
      />
      <path
        d="M30.4456 53.4922L27.7666 52.1272V56.1838L30.4456 57.5488V53.4922Z"
        fill="#1A191B"
      />
      <path
        d="M27.7666 52.1272L25.0876 50.7622V54.8188L27.7666 56.1838V52.1272Z"
        fill="#8666B7"
      />
      <path
        d="M25.0877 50.7622L22.4087 49.3972V53.4538L25.0877 54.8188V50.7622Z"
        fill="#8667B6"
      />
      <path
        d="M22.4087 49.3972L19.7297 48.0322V52.0889L22.4087 53.4538V49.3972Z"
        fill="#8666B7"
      />
      <path
        d="M19.7297 48.0322L17.0508 46.6672V50.7239L19.7297 52.0889V48.0322Z"
        fill="#1A191B"
      />
      <path
        d="M17.0508 46.6672L14.3718 45.3022V49.3589L17.0508 50.7239V46.6672Z"
        fill="#8766B7"
      />
      <path
        d="M14.3718 45.3022L11.6929 43.9372V47.9939L14.3718 49.3589V45.3022Z"
        fill="#8766B6"
      />
      <path
        d="M11.6929 43.9372L9.01392 42.5722V46.6289L11.6929 47.9939V43.9372Z"
        fill="#242426"
      />
      <path
        d="M9.01392 42.5722L6.33496 41.2072V45.2639L9.01392 46.6289V42.5722Z"
        fill="#8767B6"
      />
      <path
        d="M6.33497 41.2072L3.65601 39.8422V43.8989L6.33497 45.2639V41.2072Z"
        fill="#1A191B"
      />
      <path
        d="M43.8405 56.2605L41.1615 54.8955V58.9522L43.8405 60.3172V56.2605Z"
        fill="#1A191B"
      />
      <path
        d="M41.1614 54.8955L38.4824 53.5305V57.5872L41.1614 58.9522V54.8955Z"
        fill="#8D6CBC"
      />
      <path
        d="M38.4824 53.5305L35.8035 52.1655V56.2222L38.4824 57.5872V53.5305Z"
        fill="#242426"
      />
      <path
        d="M35.8035 52.1655L33.1245 50.8005V54.8572L35.8035 56.2222V52.1655Z"
        fill="#8E6DBB"
      />
      <path
        d="M33.1245 50.8005L30.4456 49.4355V53.4922L33.1245 54.8572V50.8005Z"
        fill="#8D6CBB"
      />
      <path
        d="M30.4456 49.4355L27.7666 48.0705V52.1272L30.4456 53.4922V49.4355Z"
        fill="#8C6CBB"
      />
      <path
        d="M27.7666 48.0705L25.0876 46.7055V50.7622L27.7666 52.1272V48.0705Z"
        fill="#8C6CBB"
      />
      <path
        d="M25.0877 46.7055L22.4087 45.3405V49.3972L25.0877 50.7622V46.7055Z"
        fill="#8C6CBB"
      />
      <path
        d="M22.4087 45.3405L19.7297 43.9755V48.0322L22.4087 49.3972V45.3405Z"
        fill="#8C6CBB"
      />
      <path
        d="M19.7297 43.9755L17.0508 42.6105V46.6672L19.7297 48.0322V43.9755Z"
        fill="#8C6CBB"
      />
      <path
        d="M17.0508 42.6105L14.3718 41.2455V45.3022L17.0508 46.6672V42.6105Z"
        fill="#8D6CBB"
      />
      <path
        d="M14.3718 41.2455L11.6929 39.8805V43.9372L14.3718 45.3022V41.2455Z"
        fill="#8E6DBB"
      />
      <path
        d="M11.6929 39.8805L9.01392 38.5155V42.5722L11.6929 43.9372V39.8805Z"
        fill="#242426"
      />
      <path
        d="M9.01392 38.5155L6.33496 37.1505V41.2072L9.01392 42.5722V38.5155Z"
        fill="#8D6CBC"
      />
      <path
        d="M6.33497 37.1505L3.65601 35.7855V39.8422L6.33497 41.2072V37.1505Z"
        fill="#1A191B"
      />
      <path
        d="M43.8405 52.2038L41.1615 50.8388V54.8955L43.8405 56.2605V52.2038Z"
        fill="#1A191B"
      />
      <path
        d="M41.1614 50.8388L38.4824 49.4738V53.5305L41.1614 54.8955V50.8388Z"
        fill="#A281D4"
      />
      <path
        d="M38.4824 49.4738L35.8035 48.1088V52.1655L38.4824 53.5305V49.4738Z"
        fill="#242426"
      />
      <path
        d="M35.8035 48.1088L33.1245 46.7438V50.8005L35.8035 52.1655V48.1088Z"
        fill="#242426"
      />
      <path
        d="M33.1245 46.7438L30.4456 45.3788V49.4355L33.1245 50.8005V46.7438Z"
        fill="#A482D5"
      />
      <path
        d="M30.4456 45.3788L27.7666 44.0138V48.0705L30.4456 49.4355V45.3788Z"
        fill="#9271C1"
      />
      <path
        d="M27.7666 44.0138L25.0876 42.6488V46.7055L27.7666 48.0705V44.0138Z"
        fill="#9271C1"
      />
      <path
        d="M25.0877 42.6488L22.4087 41.2838V45.3405L25.0877 46.7055V42.6488Z"
        fill="#9071C1"
      />
      <path
        d="M22.4087 41.2838L19.7297 39.9189V43.9755L22.4087 45.3405V41.2838Z"
        fill="#9271C1"
      />
      <path
        d="M19.7297 39.9189L17.0508 38.5539V42.6105L19.7297 43.9755V39.9189Z"
        fill="#9271C1"
      />
      <path
        d="M17.0508 38.5539L14.3718 37.1889V41.2455L17.0508 42.6105V38.5539Z"
        fill="#A482D5"
      />
      <path
        d="M14.3718 37.1889L11.6929 35.8239V39.8805L14.3718 41.2455V37.1889Z"
        fill="#242426"
      />
      <path
        d="M11.6929 35.8239L9.01392 34.4589V38.5155L11.6929 39.8805V35.8239Z"
        fill="#242426"
      />
      <path
        d="M9.01392 34.4589L6.33496 33.0939V37.1505L9.01392 38.5155V34.4589Z"
        fill="#A281D4"
      />
      <path
        d="M6.33497 33.0939L3.65601 31.7289V35.7855L6.33497 37.1505V33.0939Z"
        fill="#1A191B"
      />
      <path
        d="M41.1614 46.7822L38.4824 45.4172V49.4738L41.1614 50.8388V46.7822Z"
        fill="#302E30"
      />
      <path
        d="M38.4824 45.4172L35.8035 44.0522V48.1088L38.4824 49.4738V45.4172Z"
        fill="#9075B7"
      />
      <path
        d="M35.8035 44.0522L33.1245 42.6872V46.7438L35.8035 48.1088V44.0522Z"
        fill="#9777C6"
      />
      <path
        d="M33.1245 42.6872L30.4456 41.3222V45.3788L33.1245 46.7438V42.6872Z"
        fill="#363235"
      />
      <path
        d="M30.4456 41.3222L27.7666 39.9572V44.0138L30.4456 45.3788V41.3222Z"
        fill="#9776C7"
      />
      <path
        d="M27.7666 39.9572L25.0876 38.5922V42.6488L27.7666 44.0138V39.9572Z"
        fill="#302E30"
      />
      <path
        d="M25.0877 38.5922L22.4087 37.2272V41.2838L25.0877 42.6488V38.5922Z"
        fill="#302E30"
      />
      <path
        d="M22.4087 37.2272L19.7297 35.8622V39.9189L22.4087 41.2838V37.2272Z"
        fill="#302E30"
      />
      <path
        d="M19.7297 35.8622L17.0508 34.4972V38.5539L19.7297 39.9189V35.8622Z"
        fill="#9776C7"
      />
      <path
        d="M17.0508 34.4972L14.3718 33.1322V37.1889L17.0508 38.5539V34.4972Z"
        fill="#363235"
      />
      <path
        d="M14.3718 33.1322L11.6929 31.7672V35.8239L14.3718 37.1889V33.1322Z"
        fill="#9777C6"
      />
      <path
        d="M11.6929 31.7672L9.01392 30.4022V34.4589L11.6929 35.8239V31.7672Z"
        fill="#9075B7"
      />
      <path
        d="M9.01392 30.4022L6.33496 29.0372V33.0939L9.01392 34.4589V30.4022Z"
        fill="#302E30"
      />
      <path
        d="M38.4824 41.3605L35.8035 39.9955V44.0522L38.4824 45.4172V41.3605Z"
        fill="#302E30"
      />
      <path
        d="M35.8035 39.9955L33.1245 38.6305V42.6872L35.8035 44.0522V39.9955Z"
        fill="#302E30"
      />
      <path
        d="M33.1245 38.6305L30.4456 37.2655V41.3222L33.1245 42.6872V38.6305Z"
        fill="#363235"
      />
      <path
        d="M30.4456 37.2655L27.7666 35.9005V39.9572L30.4456 41.3222V37.2655Z"
        fill="#9A79CB"
      />
      <path
        d="M27.7666 35.9005L25.0876 34.5355V38.5922L27.7666 39.9572V35.9005Z"
        fill="#302E30"
      />
      <path
        d="M22.4087 33.1705L19.7297 31.8055V35.8622L22.4087 37.2272V33.1705Z"
        fill="#302E30"
      />
      <path
        d="M19.7297 31.8055L17.0508 30.4405V34.4972L19.7297 35.8622V31.8055Z"
        fill="#9A79CB"
      />
      <path
        d="M17.0508 30.4405L14.3718 29.0755V33.1322L17.0508 34.4972V30.4405Z"
        fill="#363235"
      />
      <path
        d="M14.3718 29.0755L11.6929 27.7105V31.7672L14.3718 33.1322V29.0755Z"
        fill="#302E30"
      />
      <path
        d="M11.6929 27.7105L9.01392 26.3455V30.4022L11.6929 31.7672V27.7105Z"
        fill="#302E30"
      />
      <path
        d="M38.4824 37.3038L35.8035 35.9388V39.9955L38.4824 41.3605V37.3038Z"
        fill="#363235"
      />
      <path
        d="M35.8035 35.9388L33.1245 34.5738V38.6305L35.8035 39.9955V35.9388Z"
        fill="#9777C6"
      />
      <path
        d="M33.1245 34.5738L30.4456 33.2088V37.2655L33.1245 38.6305V34.5738Z"
        fill="#363235"
      />
      <path
        d="M17.0508 26.3839L14.3718 25.0189V29.0755L17.0508 30.4405V26.3839Z"
        fill="#363235"
      />
      <path
        d="M14.3718 25.0189L11.6929 23.6539V27.7105L14.3718 29.0755V25.0189Z"
        fill="#9777C6"
      />
      <path
        d="M11.6929 23.6539L9.01392 22.2889V26.3455L11.6929 27.7105V23.6539Z"
        fill="#363235"
      />
    </svg>
  );
}
