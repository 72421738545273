import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { SmallIconListLayoutAreaHint } from '@lib/ia/src/layouts/SmallIconListLayout/types';

export type AreaHintProps = SmallIconListLayoutAreaHint;

const styles = {
  root: {
    px: { xs: 2.5, md: 1.5 },
  },
  hint: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
};
export default function AreaHint({ text }: AreaHintProps) {
  return (
    <Box sx={styles.root}>
      <Typography variant="body1" sx={styles.hint}>
        {text}
      </Typography>
    </Box>
  );
}
