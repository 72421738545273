import React from 'react';

export default function OtherShareVia({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5 10C5 8.89543 5.89543 8 7 8H9C9.55228 8 10 8.44772 10 9C10 9.55228 9.55228 10 9 10L7 10V20H17V10H15C14.4477 10 14 9.55228 14 9C14 8.44772 14.4477 8 15 8H17C18.1046 8 19 8.89543 19 10V20C19 21.1046 18.1046 22 17 22H7C5.89543 22 5 21.1046 5 20V10Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M12 15.575C12.5049 15.575 12.9179 15.162 12.9179 14.6572V5.47866H14.5609C14.9739 5.47866 15.1758 4.98302 14.8821 4.69849L12.3213 2.13768C12.1377 1.95411 11.8532 1.95411 11.6696 2.13768L9.10881 4.69849C8.82428 4.98302 9.02621 5.47866 9.43924 5.47866H11.0822V14.6572C11.0822 15.162 11.4952 15.575 12 15.575Z"
        fill="currentColor"
      />
    </svg>
  );
}
