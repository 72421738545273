import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { alpha, Box, ButtonBase, Theme } from '@mui/material';
import {
  BlockNoteEditor,
  BlockSchema,
  DefaultBlockSchema,
} from '@blocknote/core';
import { Icon, Scrollbar, SquareAvatar } from '@front/ui';

export type SuggestMenuItem<M = Record<string, any>> = {
  display: string;
  value: string;
  icon?: {
    type: 'image' | 'icon';
    value: string;
  };
  suffixIcon?: {
    type: 'icon';
    value: string;
  };
  description?: string;
  type?: 'default' | 'profile';
  metadata?: M;
};

type SuggestionMenuProps<
  BSchema extends BlockSchema = DefaultBlockSchema,
  M = Record<string, any>
> = {
  editor: BlockNoteEditor<BSchema>;
  items: SuggestMenuItem<M>[];
  onClick: (item: SuggestMenuItem<M>) => void;
};

const styles = {
  scroll: {
    py: '6px',
    maxHeight: 270,
    borderRadius: 1,
  },
  list: {
    display: 'grid',
    width: '100%',
  },
  button: {
    px: '12px',
    height: 32,
    textAlign: 'left',
    typography: 'body2',
    lineHeight: 1.5,
    display: 'grid',
    gap: 1,
    gridTemplateColumns: '16px auto 1fr minmax(0px, max-content)',
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap',

    '& .suggestion-item-suffix-icon': {
      pl: 0.25,
      verticalAlign: 'middle',
      opacity: 0.5,
    },
    '& .suggestion-item-value': {
      opacity: 0.5,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .suggestion-item-description': {
      opacity: 0.5,
    },
  },
  hovered: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
};

export type SuggestionMenuHandler<M> = {
  getDefaultItem: () => SuggestMenuItem<M> | undefined;
};

function SuggestionMenuInside<
  BSchema extends BlockSchema = DefaultBlockSchema,
  M = Record<string, any>
>(
  { editor, items, onClick }: SuggestionMenuProps<BSchema, M>,
  ref: ForwardedRef<SuggestionMenuHandler<M>>
) {
  const [hoverIndex, setHoverIndex] = useState<number>(0);
  const scrollRef = useRef<HTMLDivElement>(null);

  const totalCount = items.length;
  const handleClick = useCallback((item: SuggestMenuItem<M>) => {
    onClick(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      getDefaultItem: () => items[hoverIndex],
    }),
    [hoverIndex, items]
  );

  useEffect(() => {
    setHoverIndex(0);
  }, [totalCount]);

  useEffect(() => {
    const scrollToItem = (newIndex: number) => {
      if (scrollRef.current) {
        const currentEl = scrollRef.current.querySelector(
          `button:nth-of-type(${newIndex})`
        );

        if (currentEl) {
          currentEl.scrollIntoView();
        }
      }
    };
    const handleMenuNavigationKeys = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        if (items.length) {
          event.preventDefault();
          const newIndex = (hoverIndex - 1 + totalCount) % totalCount;
          setHoverIndex(newIndex);
          scrollToItem(newIndex);
        }

        return true;
      }

      if (event.key === 'ArrowDown') {
        if (items.length) {
          event.preventDefault();
          const newIndex = (hoverIndex + 1) % totalCount;
          setHoverIndex(newIndex);
          scrollToItem(newIndex);
        }

        return true;
      }

      if (event.key === 'Enter' || event.key === 'Tab') {
        if (items.length && items[hoverIndex]) {
          event.preventDefault();
          handleClick(items[hoverIndex]);
        }

        return true;
      }

      return false;
    };

    editor.domElement.addEventListener(
      'keydown',
      handleMenuNavigationKeys,
      true
    );

    return () => {
      editor.domElement.removeEventListener(
        'keydown',
        handleMenuNavigationKeys,
        true
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.domElement, items, hoverIndex]);

  return (
    <Scrollbar sx={styles.scroll} scrollableNodeProps={{ ref: scrollRef }}>
      <Box sx={styles.list}>
        {items.map((item, index) => {
          return (
            <ButtonBase
              key={item.value}
              sx={[styles.button, hoverIndex === index && styles.hovered]}
              onClick={() => handleClick(item)}
              onMouseMove={() => setHoverIndex(index)}
            >
              {item.icon?.type === 'icon' && (
                <Icon name={item.icon.value} width={16} height={16} />
              )}
              {item.icon?.type === 'image' && (
                <SquareAvatar src={item.icon.value} size={16}>
                  {item.value}
                </SquareAvatar>
              )}
              {!item.icon && <span />}
              <span>
                {item.display}

                {item.suffixIcon && (
                  <Icon
                    name={item.suffixIcon.value}
                    width={16}
                    height={16}
                    className="suggestion-item-suffix-icon"
                  />
                )}
              </span>

              <span className="suggestion-item-value">@{item.value}</span>
              <span className="suggestion-item-description">
                {item.description}
              </span>
            </ButtonBase>
          );
        })}
      </Box>
    </Scrollbar>
  );
}

const SuggestionMenu = forwardRef(SuggestionMenuInside) as <
  BSchema extends BlockSchema = DefaultBlockSchema,
  M = Record<string, any>
>(
  props: SuggestionMenuProps<BSchema, M> & {
    ref: ForwardedRef<SuggestionMenuHandler<M>>;
  }
) => ReturnType<typeof SuggestionMenuInside>;

export default SuggestionMenu;
