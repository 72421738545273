import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { ActionChevronDown as ActionChevronDownIcon } from '@front/icon';

const styles = {
  root: {
    position: 'sticky',
    width: '100%',
    zIndex: 10,
    pointerEvents: 'none',
    userSelect: 'none',
    display: 'flex',
    justifyContent: 'center',
    bottom: '16px',
  },
  indicator: {
    borderRadius: '50%',
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    transition: 'opacity 0.1s',
  },
  visible: {
    opacity: 0.8,
  },
  hidden: {
    opacity: 0,
  },
};

export type ScrollDownIndicatorProps = {
  scrollRef: React.RefObject<HTMLDivElement>;
};

export default function ScrollDownIndicator({
  scrollRef,
}: ScrollDownIndicatorProps) {
  const detectRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [pinned, setPinned] = useState<boolean>(false);
  const [showIndicator, setShowIndicator] = useState<boolean>(false);

  const observerRef = useRef(
    typeof window !== 'undefined'
      ? new IntersectionObserver(([entry]) => {
          setPinned(!entry.isIntersecting);
        })
      : null
  );

  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = new IntersectionObserver(([entry]) => {
        setPinned(!entry.isIntersecting);
      });
    }

    const self = observerRef.current;
    if (detectRef.current) {
      self.unobserve(detectRef.current);
      self.observe(detectRef.current);
    }

    return () => {
      self.disconnect();
    };
  }, [detectRef]);

  useEffect(() => {
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const currentScrollRef = scrollRef.current;

    if (!currentScrollRef) return;

    currentScrollRef.addEventListener('mouseenter', handleMouseEnter);
    currentScrollRef.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      currentScrollRef.removeEventListener('mouseenter', handleMouseEnter);
      currentScrollRef.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [scrollRef]);

  const isHoveredAndPinned = isHovered && pinned;

  useEffect(() => {
    /**
     * Scroll down indicator appears after 2 seconds duration. this small interval lets user to digest the content.
     * The appearance will make the indicator more recognizable.
     */
    let timer: NodeJS.Timeout;

    if (isHoveredAndPinned) {
      timer = setTimeout(() => {
        setShowIndicator(true);
      }, 2000);
    } else {
      setShowIndicator(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isHoveredAndPinned]);

  return (
    <>
      <Box ref={detectRef} height="1px" width="100%" />
      <Box sx={styles.root}>
        <Box
          sx={[
            styles.indicator,
            showIndicator ? styles.visible : styles.hidden,
          ]}
        >
          <ActionChevronDownIcon />
        </Box>
      </Box>
    </>
  );
}
