import { useCallback } from 'react';
import { apis } from '@lib/web/apis';
import { useOnlineStatus } from '@lib/web/hooks';
import { call } from '@lib/web/utils';
import { Channel } from 'stream-chat';

export const useChannelSystemMessage = () => {
  const { getAgentOnlineStatus } = useOnlineStatus();

  const agentStatus = getAgentOnlineStatus();

  const maybeSendAgentStatusErrorMessage = useCallback(
    (channel: Channel): void => {
      if (agentStatus === 'none' || agentStatus === 'minor') {
        return;
      }
      void call(
        apis.thread.systemMessageAgentStatusError({
          channelType: channel.type as 'team' | 'public',
          channelId: channel.id as string,
        })
      );
    },
    [agentStatus]
  );

  return {
    maybeSendAgentStatusErrorMessage,
  };
};
