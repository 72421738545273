import React from 'react';

export default function MainWallet({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.2 7.55556V5.77778C19.2 4.79733 18.3927 4 17.4 4H5.7C4.2114 4 3 5.19644 3 6.66667V17.3333C3 19.2898 4.6146 20 5.7 20H19.2C20.1927 20 21 19.2027 21 18.2222V9.33333C21 8.35289 20.1927 7.55556 19.2 7.55556ZM17.4 15.5556H15.6V12H17.4V15.5556ZM5.7 7.55556C5.46827 7.54532 5.24946 7.44719 5.08915 7.28162C4.92883 7.11604 4.83936 6.89577 4.83936 6.66667C4.83936 6.43757 4.92883 6.2173 5.08915 6.05172C5.24946 5.88614 5.46827 5.78802 5.7 5.77778H17.4V7.55556H5.7Z"
        fill="currentColor"
      />
    </svg>
  );
}
