import React from 'react';

export default function ProfileTutor({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.7152 5.03027L2.32164 8.54476C1.90175 8.70186 1.89009 9.30847 2.30361 9.48252L11.6972 13.4363C11.8157 13.4862 11.9486 13.4862 12.0672 13.4363L21.4607 9.48252C21.8742 9.30847 21.8626 8.70186 21.4427 8.54476L12.0491 5.03027C11.9413 4.98991 11.823 4.98991 11.7152 5.03027Z"
        fill="currentColor"
      />
      <path
        d="M11.6897 14.9375L5.72262 12.3063C5.40019 12.1642 5.04152 12.4075 5.04152 12.7684V15.6963C5.04152 15.8975 5.15801 16.0792 5.33767 16.1584L11.6897 18.9594C11.8126 19.0135 11.9517 19.0135 12.0746 18.9594L18.4267 16.1584C18.6063 16.0792 18.7228 15.8975 18.7228 15.6963V12.7684C18.7228 12.4075 18.3641 12.1642 18.0417 12.3063L12.0746 14.9375C11.9517 14.9917 11.8126 14.9917 11.6897 14.9375Z"
        fill="currentColor"
      />
      <path
        d="M21.1659 10.4978C21.4357 10.4978 21.6545 10.7229 21.6545 11.0005V16.3223L21.7557 16.4265C22.0814 16.7616 22.0814 17.305 21.7557 17.6402C21.4299 17.9753 20.9018 17.9753 20.5761 17.6402C20.2503 17.305 20.2503 16.7616 20.5761 16.4265L20.6773 16.3223V11.0005C20.6773 10.7229 20.896 10.4978 21.1659 10.4978Z"
        fill="currentColor"
      />
    </svg>
  );
}
