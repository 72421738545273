import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { trueFalseNotGivenSettings } from '@lib/web/editor';

import { TrueFalseNotGivenValue } from '../types';

import Choice from './Choice';

const styles = {
  choiceContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  captionContainer: {
    mt: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  caption: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
  },
  captionNumber: {
    color: 'text.primary',
  },
};

type TrueFalseNotGivenProps = {
  choiceValues: TrueFalseNotGivenValue[];
  onSelect: (id: string) => void;
  readonly?: boolean;
};

export default function TrueFalseNotGiven({
  choiceValues,
  onSelect,
  readonly,
}: TrueFalseNotGivenProps) {
  const { t } = useTranslation('editor');

  return (
    <Box>
      <Box sx={styles.choiceContainer}>
        {choiceValues.map((choiceValue, i) => (
          <Choice
            key={choiceValue.id}
            value={choiceValue}
            label={trueFalseNotGivenSettings.labels[i]}
            onSelect={onSelect}
            readonly={readonly}
          />
        ))}
      </Box>
      {
        <Box sx={styles.captionContainer}>
          <Typography variant="caption" sx={styles.caption}>
            {t('select')}{' '}
            <Typography variant="caption" sx={styles.captionNumber}>
              1
            </Typography>{' '}
            {t('correct answers')}
          </Typography>
        </Box>
      }
    </Box>
  );
}
