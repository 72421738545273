import {
  EditorValue as EditorValueIcon,
  TextEditorBlankSpace as TextEditorBlankSpaceIcon,
  TextEditorTypeHeader3 as TextEditorTypeHeader3Icon,
  TextEditorTypeText as TextEditorTypeTextIcon,
} from '@front/icon';
import {
  BasicComposerSchema,
  ComposerSlashMenuItem,
  insertInlineBlock,
  insertOrUpdateBlock,
} from '@lib/web/composer';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';

const insertText: ComposerSlashMenuItem<BasicComposerSchema> = {
  name: 'Text',
  nodeType: BasicBlockTypes.Paragraph,

  execute: (editor) => {
    insertOrUpdateBlock(editor, [
      {
        type: BasicBlockTypes.Paragraph,
      },
    ]);
  },
  aliases: ['text'],
  hint: '/text',
  group: 'Elements',
  icon: <TextEditorTypeTextIcon width={16} height={16} />,
};

const insertHeader3: ComposerSlashMenuItem<BasicComposerSchema> = {
  name: 'Header 3',
  nodeType: BasicBlockTypes.Heading,

  execute: (editor) => {
    insertOrUpdateBlock(editor, [
      {
        type: BasicBlockTypes.Heading,
        props: {
          level: 3,
        },
      },
    ]);
  },
  aliases: ['header', 'h3'],
  hint: '/h3',
  group: 'Elements',
  icon: <TextEditorTypeHeader3Icon width={16} height={16} />,
};

const insertSubtitle: ComposerSlashMenuItem<BasicComposerSchema> = {
  name: 'Subtitle',
  nodeType: BasicBlockTypes.Subtitle,

  execute: (editor) => {
    insertOrUpdateBlock(editor, [
      {
        type: BasicBlockTypes.Subtitle,
      },
    ]);
  },
  aliases: ['subtitle'],
  hint: '/subtitle',
  group: 'Elements',
  icon: <TextEditorTypeTextIcon width={16} height={16} />,
};

const insertStep: ComposerSlashMenuItem<BasicComposerSchema> = {
  name: 'Step',
  nodeType: BasicBlockTypes.Step,

  execute: (editor) => {
    insertOrUpdateBlock(editor, [
      {
        type: BasicBlockTypes.Step,
      },
    ]);
  },
  aliases: ['step'],
  hint: '/step',
  group: 'Elements',
  icon: <EditorValueIcon width={16} height={16} />,
};

const insertBlank: ComposerSlashMenuItem<BasicComposerSchema> = {
  name: 'Blank',
  nodeType: BasicBlockTypes.InlineBlank,

  execute: (editor) => {
    insertInlineBlock(editor, [
      {
        type: 'inline-blank',
        attrs: {},
      },
    ]);
  },
  aliases: ['blank', 'inline-blank'],
  hint: '/blank',
  group: 'Elements',
  icon: <TextEditorBlankSpaceIcon width={16} height={16} />,
};

export const basicSlashMenu = {
  insertText,
  insertHeader3,
  insertSubtitle,
  insertStep,
  insertBlank,
};

export const basicSlashMenuItems = [
  insertText,
  insertHeader3,
  insertSubtitle,
  insertStep,
  insertBlank,
];
