import { useEffect, useRef, useState } from 'react';

export default function useCountdown(defaultSeconds?: number | null) {
  const [seconds, setSeconds] = useState<number | null>(null);
  const it = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (typeof defaultSeconds === 'number') {
      setSeconds(defaultSeconds);
      if (it.current) {
        clearInterval(it.current);
      }
      it.current = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === null || prevSeconds <= 1) {
            clearInterval(it.current as NodeJS.Timer);
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    } else {
      setSeconds(null);
    }

    return () => {
      if (it.current) {
        clearInterval(it.current);
      }
    };
  }, [defaultSeconds]);

  const finished = seconds === 0;

  return { seconds, finished };
}
