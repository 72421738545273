import { appConfig } from '@front/config';

export const agentIconStyles = [
  {
    background: 'linear-gradient(270deg, #41C2D0 0.01%, #06EA7F 99.99%)',
    color: 'white',
  },
  {
    background: 'linear-gradient(90deg, #68C6FA 0.01%, #CF4BF7 99.99%)',
    color: 'white',
  },
  {
    background: 'linear-gradient(90deg, #FEF373 0.01%, #FF6B00 99.99%)',
    color: 'white',
  },
  {
    background: 'linear-gradient(90deg, #ED9947 0.01%, #EB3B7A 99.99%)',
    color: 'white',
  },
  {
    background: 'linear-gradient(270deg, #595CFF 0.01%, #9BAFD9 99.99%)',
    color: 'white',
  },
];

export const aiModelIconStyles = [
  {
    nameContains: 'Aha AI',
    background: '#2c2e33',
    color: 'white',
  },
  {
    nameContains: 'Claude AI',
    background:
      'linear-gradient(90deg, #FA6868 0.01%, #F7C74B 99.99%), linear-gradient(90deg, #68C6FA 0.01%, #CF4BF7 99.99%), linear-gradient(90deg, #68FA77 0.01%, #4B7BF7 99.99%), linear-gradient(90deg, #FA68EB 0.01%, #F74B4B 99.99%)',
    color: 'white',
  },
  {
    nameContains: 'GPT-3.5',
    background:
      'linear-gradient(90deg, #68C6FA 0.01%, #CF4BF7 99.99%), linear-gradient(90deg, #68FA77 0.01%, #4B7BF7 99.99%), linear-gradient(90deg, #FA68EB 0.01%, #F74B4B 99.99%)',
    color: 'white',
  },
  {
    nameContains: 'GPT-4',
    background:
      'linear-gradient(90deg, #68FA77 0.01%, #4B7BF7 99.99%), linear-gradient(90deg, #FA68EB 0.01%, #F74B4B 99.99%)',
    color: 'white',
  },
  {
    nameContains: 'PaLM 2',
    background: 'linear-gradient(90deg, #FA68EB 0.01%, #F74B4B 99.99%)',
    color: 'white',
  },
];

const getNameAbbr = (name: string) => {
  const nameArr = name.split(' ');
  return (
    nameArr.length < 2
      ? nameArr[0].slice(0, 1)
      : `${nameArr[0][0]}${nameArr[1]?.[0] || ''}`
  ).toUpperCase();
};

const getAgentIconStyleFromName = (agentName: string) => {
  const nameAbbr = getNameAbbr(agentName);
  // a consistent hash that will make nameAbbr (1~2 char) evenly distributed across all background choices
  return agentIconStyles[
    (nameAbbr.charCodeAt(0) || 0) % agentIconStyles.length
  ];
};

export const isAhaDefaultAgent = (agent: { agentUserName?: string } = {}) => {
  return appConfig.ahaDefaultAiUserNames.includes(agent.agentUserName || '');
};

export const getAgentIconPath = (agent?: {
  agentName?: string;
  agentUserName?: string;
  agentPhoto?: string;
}) => {
  if (!agent) return '';

  const { agentName = '', agentPhoto = '' } = agent;

  if (agentPhoto) return agentPhoto;

  if (isAhaDefaultAgent(agent)) {
    return '/logo.png';
  }

  const nameAbbr = getNameAbbr(agentName);
  const style = getAgentIconStyleFromName(agentName);

  return `${
    process.env.APP_URL
  }/api/image/icon/${nameAbbr}?size=160&fontSize=60&background=${encodeURIComponent(
    style.background
  )}&color=${encodeURIComponent(style.color)}`;
};

export const getAiModelIconPath = (modelName: string) => {
  const style =
    aiModelIconStyles.find((s) => modelName.includes(s.nameContains)) ||
    aiModelIconStyles[
      (modelName.charCodeAt(0) || 0) % aiModelIconStyles.length
    ];

  return `${
    process.env.APP_URL
  }/api/image/ai-model?size=160&fontSize=96&background=${encodeURIComponent(
    style.background
  )}&color=${encodeURIComponent(style.color)}`;
};
