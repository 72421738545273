import { ReactNode } from 'react';
import {
  PropertyTypeButtonCTA as PropertyTypeButtonCTAIcon,
  PropertyTypeCheckbox as PropertyTypeCheckboxIcon,
  PropertyTypeDateOrTime as PropertyTypeDateOrTimeIcon,
  PropertyTypeEmail as PropertyTypeEmailIcon,
  PropertyTypeFilesMedia as PropertyTypeFilesMediaIcon,
  PropertyTypeIcon as PropertyTypeIconIcon,
  PropertyTypeLineColourStatus as PropertyTypeLineColourStatusIcon,
  PropertyTypeLongText as PropertyTypeLongTextIcon,
  PropertyTypeMultiSelect as PropertyTypeMultiSelectIcon,
  PropertyTypeNumber as PropertyTypeNumberIcon,
  PropertyTypePerson as PropertyTypePersonIcon,
  PropertyTypePhone as PropertyTypePhoneIcon,
  PropertyTypeSelect as PropertyTypeSelectIcon,
  PropertyTypeShortText as PropertyTypeShortTextIcon,
  PropertyTypeStatus as PropertyTypeStatusIcon,
  PropertyTypeURL as PropertyTypeURLIcon,
} from '@front/icon';

import { PropertyType } from '../types/enums';

const ICON_MAP = {
  [PropertyType.ShortText]: PropertyTypeShortTextIcon,
  [PropertyType.LongText]: PropertyTypeLongTextIcon,
  [PropertyType.Number]: PropertyTypeNumberIcon,
  [PropertyType.Select]: PropertyTypeSelectIcon,
  [PropertyType.MultiSelect]: PropertyTypeMultiSelectIcon,
  [PropertyType.DateOrTime]: PropertyTypeDateOrTimeIcon,
  [PropertyType.Status]: PropertyTypeStatusIcon,
  [PropertyType.Person]: PropertyTypePersonIcon,
  [PropertyType.FilesMedia]: PropertyTypeFilesMediaIcon,
  [PropertyType.Boolean]: PropertyTypeCheckboxIcon,
  [PropertyType.Url]: PropertyTypeURLIcon,
  [PropertyType.Email]: PropertyTypeEmailIcon,
  [PropertyType.Phone]: PropertyTypePhoneIcon,
  [PropertyType.ButtonCta]: PropertyTypeButtonCTAIcon,
  [PropertyType.LineColourStatus]: PropertyTypeLineColourStatusIcon,
  [PropertyType.Icon]: PropertyTypeIconIcon,
};

const DefaultIcon = PropertyTypeShortTextIcon;

export default function getPropertyIcon(
  type?: PropertyType | ReactNode,
  size = 12
) {
  if (type === undefined) return <DefaultIcon width={size} height={size} />;
  if (type === null) return null;

  if (typeof type === 'string' && Object.keys(ICON_MAP).includes(type)) {
    const Icon = ICON_MAP[type as keyof typeof ICON_MAP];
    return <Icon width={size} height={size} />;
  }

  return type;
}
