import React from 'react';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';

import HeatMapHeader from './components/HeatMapHeader';
import {
  NODE_GAP,
  NODE_WIDTH,
  NODE_WIDTH_MD,
  NODE_WIDTH_XS,
} from './constants';

const styles = {
  container: {
    gap: NODE_GAP,
  },
  header: {
    pb: 1,
  },
  node: {
    width: NODE_WIDTH,
    height: NODE_WIDTH,
  },
};

export type HeatMapSkeletonProps = {
  numOfNodes: number;
  nodesPerRow?: number;
};

export default function HeatMapSkeleton({
  numOfNodes,
  nodesPerRow,
}: HeatMapSkeletonProps) {
  const containerSxProps: SxProps =
    nodesPerRow === undefined
      ? {
          display: 'flex',
          flexWrap: 'wrap',
        }
      : {
          display: 'grid',
          gridTemplateColumns: {
            xs: `repeat(${nodesPerRow}, ${NODE_WIDTH_XS}px)`,
            md: `repeat(${nodesPerRow}, ${NODE_WIDTH_MD}px)`,
          },
        };

  return (
    <Box>
      <Box sx={[containerSxProps, styles.container, styles.header]}>
        <HeatMapHeader nodesPerRow={nodesPerRow} />
      </Box>
      <Box sx={[containerSxProps, styles.container]}>
        {[...new Array(numOfNodes)].map((_, i) => (
          <Skeleton key={i} variant="rounded" sx={styles.node} />
        ))}
      </Box>
    </Box>
  );
}
