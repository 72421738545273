import axios from 'axios';

interface GetMemberUploadUrlRes {
  uploadUrl: string;
  objectKey: string;
}

interface Response<T> {
  data: T;
  language: string;
  message: any;
  statusCode: number;
  statusKey: string;
}

export const getImageUploadUrl = ({
  targetUrl,
  ext,
  resourceType,
  scope,
  sub,
}: {
  targetUrl: string;
  ext: string;
  resourceType: string;
  scope: string;
  sub?: string;
}): Promise<Response<GetMemberUploadUrlRes>> => {
  const params = new URLSearchParams();
  params.set('resourceType', resourceType);
  params.set('scope', scope);
  params.set('ext', ext);

  if (sub) {
    params.set('sub', sub);
  }

  return axios.get(`${targetUrl}?${params.toString()}`);
};
