import {
  useContext,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import {
  ActionChevronDown as ActionChevronDownIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanel,
  compareString,
  EmphasizeButton,
  ResponsiveMenu,
  TipButton,
  useBaseRightPanel,
} from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import IaActionProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { ListLayoutItem } from '@lib/ia/src/layouts/ListLayout/types';
import { buildHookMutate, VariableType } from '@lib/web/apis';
import { CreatorQuestionDetailContext } from '@lib/web/editor/contexts';
import { VariableValues } from '@lib/web/editor/TextComposerPanels/components/VariablePanel/types';
import { callWithToast } from '@lib/web/utils';

import { useGetAllVariablesFromType } from '../../../hooks';
import { TextComposerPanelKeys, TextComposerPanelParams } from '../../panel';

import {
  formParamsToApiParams,
  getUniqueVariableName,
  getUpdateValueByType,
} from './utils';

const styles = {
  toggleButton: {
    ml: 'auto',
  },
  menu: {
    '& .MuiList-root': {
      minWidth: 170,
    },
  },
};

const VARIABLE_TYPE_MAPPING = {
  [VariableType.Text]: {
    title: 'Text Variable',
    icon: 'EditorTextVariable',
    prefix: 'Text',
    hint: 'These text variables can be used anywhere in this editor',
    empty: 'No text variables',
    create: 'Create Text Variable',
  },
  [VariableType.Number]: {
    title: 'Number Variable',
    icon: 'EditorNumberVariable',
    prefix: 'Number',
    hint: 'These number variables can be used anywhere in this editor',
    empty: 'No number variables',
    create: 'Create Number Variable',
  },
  [VariableType.NumberFormula]: {
    title: 'Variable Formula',
    icon: 'TestFormula',
    prefix: 'Formula',
    hint: 'This variable formula can be used anywhere in this editor',
    empty: 'No variable formula',
    create: 'Create Variable Formula',
  },
};

const updateNameByType = (name: string, type: VariableType) => {
  if (type === VariableType.Text) {
    return name.replace(
      /Number|Formula/,
      VARIABLE_TYPE_MAPPING[VariableType.Text].prefix
    );
  }
  if (type === VariableType.Number) {
    return name.replace(
      /Text|Formula/,
      VARIABLE_TYPE_MAPPING[VariableType.Number].prefix
    );
  }
  if (type === VariableType.NumberFormula) {
    return name.replace(
      /Text|Number/,
      VARIABLE_TYPE_MAPPING[VariableType.NumberFormula].prefix
    );
  }
  return '';
};

export default function VariableList() {
  const { t } = useTranslation('editor');
  const toggleButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showTypeMenu, setShowTypeMenu] = useState(false);
  const { openRightPanel, getRightParams, setRightParams, rightPanelTarget } =
    useBaseRightPanel<TextComposerPanelParams>();

  const rightParams = getRightParams(
    TextComposerPanelKeys.TextComposerVariable
  );

  const { type } = rightParams;
  const deferredType = useDeferredValue(type);

  const { data, mutate, variables } = useGetAllVariablesFromType(type);

  const mutateList = useMemo(() => buildHookMutate(mutate), [mutate]);
  const allItems = variables || [];

  const handleAddVariable = () => {
    openRightPanel(rightPanelTarget, {
      ...rightParams,
      variableId: undefined,
      subType: undefined,
      targetId: rightParams.variableId ? undefined : rightParams.targetId,
      page: 'form',
    });
  };

  const { duplicateCreatorQuestionVariable, deleteCreatorQuestionVariable } =
    useContext(CreatorQuestionDetailContext);

  const availableActions = {
    clickSingleItem: {
      action: (payload: ListLayoutItem) => {
        const newVariableId =
          rightParams.variableId && payload.id === rightParams.variableId
            ? undefined
            : payload.id;

        if (rightParams.returnPanel) {
          openRightPanel(rightParams.returnPanel as TextComposerPanelKeys, {
            ...rightParams.returnParams,
            ...rightParams,
            variableId: newVariableId,
          });
        } else {
          setRightParams({
            ...rightParams,
            variableId: newVariableId,
          });
        }
      },
    },
    add: {
      action: handleAddVariable,
    },
    edit: {
      action: (payload: ListLayoutItem) => {
        const currentItem = allItems.find((item) => item.id === payload.id);
        openRightPanel(TextComposerPanelKeys.TextComposerVariable, {
          ...rightParams,
          page: 'form',
          variableId: currentItem?.id,
          defaultValue: currentItem
            ? getUpdateValueByType(currentItem, rightParams.type)
            : undefined,
        });
      },
    },
    duplicate: {
      action: (payload: ListLayoutItem) => {
        const currentItem = allItems.find((item) => item.id === payload.id);

        if (!currentItem || !data) return;

        let params = getUpdateValueByType(currentItem, rightParams.type);
        if (rightParams.type === VariableType.Number) {
          params = formParamsToApiParams(params as VariableValues);
        }

        mutateList(
          callWithToast(duplicateCreatorQuestionVariable({ id: params.id }), {
            loadingMsg: 'Duplicating...',
          })
        );
      },
    },
    delete: {
      action: async (payload: ListLayoutItem) => {
        if (!data) return;

        if (payload.id === rightParams.variableId) {
          setRightParams({
            ...rightParams,
            variableId: undefined,
          });
        }

        const updatedItems = allItems.filter((d) => d.id !== payload.id);
        mutateList(
          callWithToast(
            deleteCreatorQuestionVariable({
              id: payload.id,
              isHardDelete: true,
            }),
            {
              errorMsg:
                'This variable has already been used and cannot be deleted.',
            }
          ),
          {
            optimisticData: {
              ...data.data,
              items: updatedItems.sort((a, b) => compareString(a.name, b.name)),
            },
          }
        );
      },
    },
  };

  const getItemStatus = ({ id }: { id: string }) => {
    return {
      selected: id === rightParams.variableId && !!rightParams.targetId,
    };
  };

  const layouts = useMemo(() => {
    const hintLayout = {
      layout: 'hint-layout' as const,
      text: t(VARIABLE_TYPE_MAPPING[rightParams.type].hint),
    };
    if (!data)
      return [
        hintLayout,
        {
          layout: 'list-layout' as const,
          areas: [
            {
              key: 'loading',
              areaType: 'loading' as const,
            },
          ],
        },
      ];

    return [
      hintLayout,
      {
        layout: 'list-layout' as const,
        areas: [
          {
            key: 'list',
            settings: {
              emptyText: t(VARIABLE_TYPE_MAPPING[rightParams.type].empty),
            },
            areaType: 'list' as const,
            areaHintActions: [
              {
                value: 'add',
                hint: '**Click** to add variable',
              },
            ],
            items: data.data.items.map((item) => ({
              id: item.id,
              text: `${item.name}(${item.possibilities})`,
              moreActions: [
                {
                  type: 'event' as const,
                  icon: 'OtherEdit',
                  value: 'edit',
                  text: 'Edit',
                },
                {
                  type: 'event' as const,
                  icon: 'OtherCopy',
                  value: 'duplicate',
                  text: 'Duplicate',
                },
                {
                  type: 'event' as const,
                  icon: 'OtherDelete',
                  value: 'delete',
                  text: 'Delete',
                },
              ],
              actionMap: {
                click: {
                  type: 'event' as const,
                  value: 'clickSingleItem',
                },
              },
            })),
          },
        ],
      },
    ];
  }, [t, rightParams.type, data]);

  const handleChangeType = (variableType: VariableType) => {
    if (variableType === rightParams?.type) {
      setShowTypeMenu(false);
      return;
    }
    // keep the current RHS opened and switch to the selected type => to push the previous type into the RHS history
    openRightPanel(rightPanelTarget, {
      ...rightParams,
      variableId: undefined,
      defaultValue: {
        ...rightParams.defaultValue,
        name:
          // Change new variable name based on type
          !rightParams.defaultValue?.id && rightParams.defaultValue?.name
            ? getUniqueVariableName(
                updateNameByType(rightParams.defaultValue.name, variableType),
                allItems.map((item) => item.name)
              )
            : rightParams.defaultValue?.name,
      },
      type: variableType,
    });

    setShowTypeMenu(false);
  };

  useEffect(() => {
    if (type !== deferredType) {
      /**
       * In some cases, the list data should be reloaded from the back-end.
       * For example, when the probability is calculated on the back-end, after we finish editing and return to the list, we need the new data.
       */
      void mutateList();
    }
  }, [deferredType, mutateList, type]);

  return (
    <IaActionProvider availableActions={availableActions}>
      <IaItemStatusProvider getItemStatus={getItemStatus}>
        <BaseLayoutRightPanel
          title={VARIABLE_TYPE_MAPPING[rightParams.type].title}
          titleIcon={
            <Icon
              name={VARIABLE_TYPE_MAPPING[rightParams.type].icon}
              width={16}
              height={16}
            />
          }
          toolComponent={
            <TipButton
              ref={toggleButtonRef}
              sx={styles.toggleButton}
              title={t('Change Variable Type')}
              onClick={() => setShowTypeMenu(true)}
              hideTooltip={showTypeMenu}
            >
              <ActionChevronDownIcon />
            </TipButton>
          }
          actionComponent={
            <EmphasizeButton
              prefixIcon={<TestAddIcon width={16} height={16} />}
              onClick={handleAddVariable}
              disabled={allItems.length >= 100}
            >
              {t(VARIABLE_TYPE_MAPPING[rightParams.type].create)}
            </EmphasizeButton>
          }
        >
          <BaseLayoutRightPanel.ScrollArea>
            <IaLayouts layouts={layouts} />
          </BaseLayoutRightPanel.ScrollArea>
        </BaseLayoutRightPanel>

        <ResponsiveMenu
          open={showTypeMenu}
          onClose={() => setShowTypeMenu(false)}
          menuProps={{
            sx: styles.menu,
            anchorEl: toggleButtonRef.current,
          }}
        >
          <MenuItem
            selected={rightParams.type === VariableType.Text}
            onClick={() => handleChangeType(VariableType.Text)}
          >
            <ListItemIcon>
              <Icon name="EditorTextVariable" width={16} height={16} />
            </ListItemIcon>

            <ListItemText>{t('Text Variable')}</ListItemText>
          </MenuItem>
          <MenuItem
            selected={rightParams.type === VariableType.Number}
            onClick={() => handleChangeType(VariableType.Number)}
          >
            <ListItemIcon>
              <Icon name="EditorNumberVariable" width={16} height={16} />
            </ListItemIcon>

            <ListItemText>{t('Number Variable')}</ListItemText>
          </MenuItem>
          <MenuItem
            selected={rightParams.type === VariableType.NumberFormula}
            onClick={() => handleChangeType(VariableType.NumberFormula)}
          >
            <ListItemIcon>
              <Icon name="TestFormula" width={16} height={16} />
            </ListItemIcon>

            <ListItemText>{t('Variable Formula')}</ListItemText>
          </MenuItem>
        </ResponsiveMenu>
      </IaItemStatusProvider>
    </IaActionProvider>
  );
}
