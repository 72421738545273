import React from 'react';

export default function PrivacyPrivate({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.25 8.33333H16.375V6.52381C16.375 4.02667 14.415 2 12 2C9.585 2 7.625 4.02667 7.625 6.52381V8.33333H6.75C5.7875 8.33333 5 9.14762 5 10.1429V19.1905C5 20.1857 5.7875 21 6.75 21H17.25C18.2125 21 19 20.1857 19 19.1905V10.1429C19 9.14762 18.2125 8.33333 17.25 8.33333ZM12 16.4762C11.0375 16.4762 10.25 15.6619 10.25 14.6667C10.25 13.6714 11.0375 12.8571 12 12.8571C12.9625 12.8571 13.75 13.6714 13.75 14.6667C13.75 15.6619 12.9625 16.4762 12 16.4762ZM14.7125 8.33333H9.2875V6.52381C9.2875 4.97667 10.5037 3.71905 12 3.71905C13.4963 3.71905 14.7125 4.97667 14.7125 6.52381V8.33333Z"
        fill="currentColor"
      />
    </svg>
  );
}
