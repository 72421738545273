import React from 'react';

export default function TestMinusSolid({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5.5 10.5L5.5 13.5L18.5 13.5V10.5L5.5 10.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
