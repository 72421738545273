import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { apis, setSid, useAuth, useSharedMemberInfo } from '@lib/web/apis';

import { call } from '../utils';

type SharedUserContextValue = {
  sid: string;
  userId: string;
  memberId: string;
  name: string;
  avatar: string;
  username: string;
  indicator?: GetMemberInfoRes['indicator'];
  userShareSid: string;
  sharePath: string;
};

interface SharedUserProviderProps {
  children?: ReactNode;
}

export const SharedUserContext = createContext<SharedUserContextValue>({
  sid: '',
  userId: '',
  memberId: '',
  name: '',
  avatar: '',
  indicator: undefined,
  userShareSid: '', // for share link
  sharePath: '',
  username: '',
});

export const SharedUserProvider: FC<SharedUserProviderProps> = (props) => {
  const { children } = props;
  const { member } = useAuth();
  const [userShareSid, setUserShareSid] = useState('');
  const router = useRouter();
  const userId = member?.userId || '';

  const { data: sharerData } = useSharedMemberInfo(router.query.sid as string);

  useEffect(() => {
    if (!router.isReady) return;

    const fetchSid = async () => {
      const [res] = await call(
        apis.shared.getSharedSid({ shareUserId: userId })
      );
      if (res) {
        setUserShareSid(res.data.sid);
      }
    };

    if (!router.query.sid && !!userId) fetchSid();
    else if (router.query.sid) {
      setSid(router.query.sid as string);
    }
  }, [router.isReady, router.query.sid, userId]);

  const sharedAvatar =
    sharerData?.data.nftAvatar || sharerData?.data.avatar || '';
  const sharedName =
    sharerData?.data.displayName || sharerData?.data.distinctName || '';
  return (
    <SharedUserContext.Provider
      value={{
        sid: router.query.sid as string,
        name: sharedName,
        avatar: sharedAvatar,
        username: sharerData?.data.distinctName || '',

        userId: sharerData?.data.userId || '',
        memberId: sharerData?.data.memberId || '',
        indicator: sharerData?.data.indicator,

        userShareSid,

        sharePath: router.query.sid ? `/shared/${router.query.sid}` : '',
      }}
    >
      {children}
    </SharedUserContext.Provider>
  );
};
