// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/no-explicit-any
export function triggerInput(input: any, enteredValue: any) {
  const el = input;
  const lastValue = el.value;
  el.value = enteredValue;
  const event = new Event('input', { bubbles: true });
  // eslint-disable-next-line no-underscore-dangle
  const tracker = el._valueTracker;
  if (tracker) {
    tracker.setValue(lastValue);
  }
  input.dispatchEvent(event);
}
