import React from 'react';

export default function EditorQuestionGroup({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#E98533"
        fillRule="evenodd"
      />
      <path
        d="M4.23886 12.2999C4.28149 12.1558 4.35609 12.0189 4.46266 11.8892C4.56923 11.7595 4.71709 11.6297 4.90625 11.5C5.08742 11.3761 5.22063 11.2695 5.30588 11.1801C5.42929 11.0466 5.51369 10.893 5.51369 10.6959C5.51369 10.5748 5.46307 10.4797 5.36183 10.4105C5.26059 10.3385 5.12871 10.3024 4.9662 10.3024C4.76372 10.3024 4.54392 10.3413 4.3068 10.4192C4.07235 10.497 3.82191 10.6123 3.55549 10.765L3 9.58904C3.30106 9.40169 3.6221 9.25325 3.96312 9.14372C4.30414 9.0313 4.6758 8.9751 5.07809 8.9751C5.68287 8.9751 6.15178 9.12066 6.4848 9.41177C6.81783 9.70001 6.98434 10.0805 6.98434 10.5532C6.98434 10.778 6.95237 10.9841 6.88843 11.1715C6.82449 11.3588 6.72325 11.5346 6.58471 11.6989C6.44617 11.8632 6.26367 12.0246 6.03721 12.1832C5.87736 12.2927 5.75081 12.3864 5.65756 12.4642C5.56698 12.542 5.5017 12.617 5.46174 12.689C5.38854 12.8304 5.42977 13.441 5.42977 13.598H4.19865C4.19865 13.3119 4.1621 12.5767 4.23886 12.2999Z"
        fill="#080808"
      />
      <path
        d="M4.00708 14.8694C4.00708 14.5638 4.08833 14.3491 4.25085 14.2252C4.41603 14.1012 4.61585 14.0392 4.8503 14.0392C5.0741 14.0392 5.26725 14.1012 5.42977 14.2252C5.59495 14.3491 5.67754 14.5638 5.67754 14.8694C5.67754 15.1605 5.59495 15.3709 5.42977 15.5006C5.26725 15.6303 5.0741 15.6952 4.8503 15.6952C4.61585 15.6952 4.41603 15.6303 4.25085 15.5006C4.08833 15.3709 4.00708 15.1605 4.00708 14.8694Z"
        fill="#080808"
      />
      <path
        d="M18.2545 12.2999C18.2971 12.1558 18.3717 12.0189 18.4783 11.8892C18.5849 11.7595 18.7327 11.6297 18.9219 11.5C19.103 11.3761 19.2363 11.2695 19.3215 11.1801C19.4449 11.0466 19.5293 10.893 19.5293 10.6959C19.5293 10.5748 19.4787 10.4797 19.3775 10.4105C19.2762 10.3385 19.1443 10.3024 18.9818 10.3024C18.7793 10.3024 18.5595 10.3413 18.3224 10.4192C18.088 10.497 17.8375 10.6123 17.5711 10.765L17.0156 9.58904C17.3167 9.40169 17.6377 9.25325 17.9787 9.14372C18.3198 9.0313 18.6914 8.9751 19.0937 8.9751C19.6985 8.9751 20.1674 9.12066 20.5004 9.41177C20.8335 9.70001 21 10.0805 21 10.5532C21 10.778 20.968 10.9841 20.9041 11.1715C20.8401 11.3588 20.7389 11.5346 20.6003 11.6989C20.4618 11.8632 20.2793 12.0246 20.0528 12.1832C19.893 12.2927 19.7664 12.3864 19.6732 12.4642C19.5826 12.542 19.5173 12.617 19.4774 12.689C19.4042 12.8304 19.4454 13.441 19.4454 13.598H18.2143C18.2143 13.3119 18.1777 12.5767 18.2545 12.2999Z"
        fill="#080808"
      />
      <path
        d="M18.0227 14.8694C18.0227 14.5638 18.104 14.3491 18.2665 14.2252C18.4317 14.1012 18.6315 14.0392 18.8659 14.0392C19.0897 14.0392 19.2829 14.1012 19.4454 14.2252C19.6106 14.3491 19.6932 14.5638 19.6932 14.8694C19.6932 15.1605 19.6106 15.3709 19.4454 15.5006C19.2829 15.6303 19.0897 15.6952 18.8659 15.6952C18.6315 15.6952 18.4317 15.6303 18.2665 15.5006C18.104 15.3709 18.0227 15.1605 18.0227 14.8694Z"
        fill="#080808"
      />
      <path
        d="M10.6656 11.8881C10.7411 11.6329 10.8732 11.3904 11.062 11.1607C11.2507 10.931 11.5125 10.7013 11.8475 10.4716C12.1684 10.2521 12.4043 10.0632 12.5553 9.90497C12.7738 9.66853 12.9233 9.39661 12.9233 9.04741C12.9233 8.83302 12.8336 8.66457 12.6543 8.54206C12.4751 8.41445 12.2415 8.35064 11.9537 8.35064C11.5951 8.35064 11.2059 8.41955 10.7859 8.55738C10.3707 8.6952 9.92724 8.89938 9.45542 9.16992L8.47168 7.08727C9.00483 6.75547 9.57338 6.49259 10.1773 6.29862C10.7812 6.09954 11.4394 6 12.1519 6C13.2229 6 14.0533 6.25778 14.6431 6.77334C15.2328 7.28379 15.5277 7.95759 15.5277 8.79474C15.5277 9.19289 15.4711 9.55787 15.3579 9.88966C15.2446 10.2215 15.0653 10.5328 14.82 10.8238C14.5746 11.1148 14.2515 11.4006 13.8504 11.6814C13.5673 11.8753 13.3432 12.0412 13.1781 12.179C13.0176 12.3169 12.9021 12.4496 12.8313 12.5772C12.7016 12.8276 12.7747 13.9089 12.7747 14.187H10.5944C10.5944 13.6803 10.5297 12.3784 10.6656 11.8881Z"
        fill="#080808"
      />
      <path
        d="M10.2552 16.4385C10.2552 15.8974 10.3991 15.5171 10.6869 15.2977C10.9794 15.0782 11.3333 14.9684 11.7485 14.9684C12.1448 14.9684 12.4869 15.0782 12.7747 15.2977C13.0672 15.5171 13.2135 15.8974 13.2135 16.4385C13.2135 16.9541 13.0672 17.3267 12.7747 17.5564C12.4869 17.7861 12.1448 17.901 11.7485 17.901C11.3333 17.901 10.9794 17.7861 10.6869 17.5564C10.3991 17.3267 10.2552 16.9541 10.2552 16.4385Z"
        fill="#080808"
      />
    </svg>
  );
}
