import { alpha, Box, SxProps, Theme, Typography } from '@mui/material';
import { compactNumberFormat, SquareAvatar } from '@front/ui';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    mask: `url(/mask-avatar.svg) no-repeat center center`,
    maskSize: 'contain',
    backgroundColor: 'background.darker',
    p: '2px',
  },
  avatarMore: {
    typography: 'caption',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    py: '2px',
    px: '6px',
  },
};

const getSpacing = (spacing: number | string) => {
  return {
    '& > div:not(:first-of-type)': {
      ml: spacing,
    },
  };
};

export type AvatarGroupProps = {
  sx?: SxProps;
  items: {
    avatarUrl: string;
    name: string;
  }[];
  size?: number;
  spacing?: number | string;
  max?: number;
  /**
   * max displays are included the more component "+x"
   */
  maxIncludedMore?: boolean;
  avatarSx?: SxProps;
  hideMore?: boolean;
  moreType?: 'default' | 'avatar';
  moreTypographyVariant?: string;
  /**
   * if undefined -> totalCount = items.length
   */
  totalCount?: number;
  moreTextFormatter?: (num: number) => string;
};

export default function SquareAvatarGroup({
  sx,
  items,
  size = 32,
  spacing = -1,
  max = 5,
  maxIncludedMore = false,
  avatarSx,
  hideMore,
  moreType = 'default',
  moreTypographyVariant = 'caption',
  totalCount: actualTotalItems,
  moreTextFormatter,
}: AvatarGroupProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const avatarSxProps = Array.isArray(avatarSx) ? avatarSx : [avatarSx];

  const totalCount = actualTotalItems || items.length;
  let avatarsDisplayed = Math.min(totalCount, max);
  if (maxIncludedMore && totalCount > max) {
    avatarsDisplayed -= 1; // Leave the last place for "+x"
  }
  const moreValue = totalCount - avatarsDisplayed;

  const showMore = !hideMore && moreValue > 0;
  const avatars = items.slice(0, avatarsDisplayed);
  return (
    <Box sx={[styles.root, getSpacing(spacing), ...sxProps]}>
      {avatars.map((item, index) => (
        <Box key={index} sx={styles.avatar} className="avatar-wrapper">
          <SquareAvatar size={size} src={item.avatarUrl} sx={avatarSxProps}>
            {item.name}
          </SquareAvatar>
        </Box>
      ))}
      {showMore && (
        <>
          {moreType === 'default' && (
            <Box
              sx={[styles.avatarMore, { typography: moreTypographyVariant }]}
              className="avatar-more-text"
            >
              +{compactNumberFormat(moreValue)}
            </Box>
          )}
          {moreType === 'avatar' && (
            <Box sx={styles.avatar} className="avatar-wrapper">
              <SquareAvatar blackAndWhite size={size}>
                <Typography
                  sx={{ typography: moreTypographyVariant }}
                  className="avatar-more-text"
                >
                  {moreTextFormatter
                    ? moreTextFormatter(moreValue)
                    : `+${compactNumberFormat(moreValue)}`}
                </Typography>
              </SquareAvatar>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
