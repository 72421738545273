import { FilterConditionConfig, FilterField } from '@lib/ia/src/filter/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  conditions: Record<string, FilterConditionConfig[]>;
  fields: Record<string, FilterField[]>;
} = {
  conditions: { global: [] },
  fields: { global: [] },
};

type Scope = { scope: string };
const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setConditions: (
      state,
      {
        payload,
      }: PayloadAction<{ conditions: FilterConditionConfig[] } & Scope>
    ) => {
      state.conditions[payload.scope] = payload.conditions;
    },
    resetFilter: (state, { payload }: PayloadAction<Scope>) => {
      if (state.conditions[payload.scope]) {
        state.conditions[payload.scope] = state.conditions[
          payload.scope
        ].filter((condition) => condition.disabled);
      } else {
        state.conditions[payload.scope] = [];
      }
    },
    forceResetFilter: (state, { payload }: PayloadAction<Scope>) => {
      state.conditions[payload.scope] = [];
    },
    setFields: (
      state,
      { payload }: PayloadAction<{ fields: FilterField[] } & Scope>
    ) => {
      state.fields[payload.scope] = payload.fields;
    },
    upsertCondition: (
      state,
      {
        payload: { condition, scope },
      }: PayloadAction<{ condition: FilterConditionConfig } & Scope>
    ) => {
      if (!state.conditions[scope]) {
        state.conditions[scope] = [];
      }
      const existingIndex = state.conditions[scope].findIndex(
        (item) => item.id === condition.id
      );
      if (existingIndex === -1) {
        state.conditions[scope] = [...state.conditions[scope], condition];
        return;
      }

      const conditions = [...state.conditions[scope]];
      conditions.splice(existingIndex, 1, condition);
      state.conditions[scope] = conditions;
    },
    removeCondition: (
      state,
      { payload: { id, scope } }: PayloadAction<{ id: string } & Scope>
    ) => {
      state.conditions[scope] =
        state.conditions[scope]?.filter((item) => item.id !== id) || [];
    },
  },
});

export const {
  setConditions,
  resetFilter,
  forceResetFilter,
  setFields,
  upsertCondition,
  removeCondition,
} = filterSlice.actions;
export default filterSlice.reducer;
