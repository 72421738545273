import React from 'react';

export default function OtherFastForward({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4 18L12.5 12L4 6V18ZM13 6V18L21.5 12L13 6Z"
        fill="currentColor"
      />
    </svg>
  );
}
