import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import { FormLayoutMethodsContext } from '@lib/ia/src/layouts/FormLayout';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { VariableSubType, VariableType } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

import { useGetAllVariablesFromType } from '../../../hooks';
import { TextComposerPanelKeys, TextComposerPanelParams } from '../../panel';

import { getUniqueVariableName } from './utils';

const styles = {
  root: {
    '& .ia-form-layout': {
      py: 2,
      px: { xs: 2.5, md: '12px' },
    },
  },
};

const useVariableData = ({ variableId }: { variableId?: string }) => {
  const { getRightParams } = useBaseRightPanel<TextComposerPanelParams>();

  const { data, variables } = useGetAllVariablesFromType(VariableType.Text);
  const otherVariableNames = useMemo(
    () =>
      variables?.filter((v) => v.id !== variableId).map((v) => v.name) || [],
    [variables, variableId]
  );

  const { defaultValue } = getRightParams(
    TextComposerPanelKeys.TextComposerVariable
  );

  const placeholderName = useMemo(
    () =>
      getUniqueVariableName(
        `Text${otherVariableNames.length + 1}`,
        otherVariableNames
      ),
    [otherVariableNames]
  );

  const placeholderContent = 'Value1, Value2, Value3';

  return {
    dataIsLoading: !data,
    defaultValue,
    placeholderName,
    placeholderContent,
    otherVariableNames,
  };
};

type TextVariableFormProps = {
  variableId?: string;
  createOrUpdateVariable: (
    params: AddCreatorQuestionVariableReq | UpdateCreatorQuestionVariableReq
  ) => void;
};

export default function TextVariableForm({
  variableId,
  createOrUpdateVariable,
}: TextVariableFormProps) {
  const { t } = useTranslation('editor');
  const { sectionId } = useCurrentIaClub();
  const {
    dataIsLoading,
    defaultValue,
    otherVariableNames,
    placeholderName,
    placeholderContent,
  } = useVariableData({ variableId });
  const { methods } = useContext(FormLayoutMethodsContext);

  const layouts = useMemo(() => {
    const hintLayout = {
      layout: 'hint-layout' as const,
      text: t('These text variables can be used anywhere in this editor'),
    };
    if (dataIsLoading)
      return [
        hintLayout,
        {
          layout: 'list-layout' as const,
          areas: [
            {
              key: 'loading',
              areaType: 'loading' as const,
            },
          ],
        },
      ];

    const disabled = !variableId;

    return [
      hintLayout,
      {
        layout: 'form-layout' as const,
        settings: {
          mode: 'all' as const,
          triggerSubmitMode: 'onChange' as const,
        },
        formAction: {
          type: 'event' as const,
          value: 'submitTextVariables',
        },
        defaultValues: defaultValue,
        mainItems: [
          {
            type: 'group' as const,
            gap: 1,
            items: [
              {
                type: 'text' as const,
                label: 'Variable Name',
                name: 'name',
                placeholder: placeholderName,
                helperText: 'Used to help identify different variables',
                disabled,
                actionMap: {
                  change: { value: 'removeSpaces' },
                },
                customRules: {
                  notInArray: {
                    values: otherVariableNames,
                    message: 'Variable Name should be unique',
                  },
                  regex: {
                    value: /^[a-zA-Z0-9]*$/,
                    message: '‘{value}’ is not a valid value',
                  },
                },
              },
              {
                type: 'textarea' as const,
                label: 'Value',
                name: 'content', // Text Variable
                placeholder: placeholderContent,
                helperText: 'Separate each value with a comma ( , )',
                disabled,
                minRows: 8,
              },
            ],
          },
        ],
      },
    ];
  }, [
    dataIsLoading,
    defaultValue,
    otherVariableNames,
    placeholderContent,
    placeholderName,
    t,
    variableId,
  ]);

  /**
   * when open a new text variable (variableId is undefined)
   * we should check whether the defaultValue is the same as the value in the form,
   * otherwise reset it
   */
  useEffect(() => {
    if (variableId || !sectionId) return;

    if (defaultValue?.content !== methods.getValues?.().content) {
      methods.reset?.(defaultValue);
    }
  }, [defaultValue, methods, sectionId, variableId]);

  /**
   * when open a new text variable (variableId is undefined)
   * we're going to create it
   */
  useEffect(() => {
    if (variableId || !sectionId) return;

    createOrUpdateVariable({
      sectionId,
      type: VariableType.Text,
      subType: VariableSubType.Text,
      name: defaultValue?.name || placeholderName,
      content: defaultValue?.content || placeholderContent,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId, variableId]);

  const availableActions = {
    submitTextVariables: {
      action: (
        params: Omit<
          UpdateCreatorQuestionVariableReq,
          'sectionId' | 'creatorQuestionId' | 'type'
        >
      ) => {
        if (!variableId) return;

        createOrUpdateVariable({
          ...params,
          id: variableId,
          name: params.name || placeholderName,
          content: params.content || placeholderContent,
        });
      },
    },
    removeSpaces: {
      action: ({ value }: { value: string }) => {
        if (/\s/g.test(value)) return value.replace(/\s/g, '');

        return undefined;
      },
    },
  };

  return (
    <Box sx={styles.root}>
      <IaActionContextProvider availableActions={availableActions}>
        <IaLayouts layouts={layouts} />
      </IaActionContextProvider>
    </Box>
  );
}
