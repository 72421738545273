import { useCallback, useContext } from 'react';

import IaRenderContext, {
  IaRenderContextValue,
  IaRenderUi,
} from './IaRenderContext';

export function useIaRender() {
  const renders = useContext<IaRenderContextValue>(IaRenderContext);

  const getIaRender = useCallback(
    function (renderKey?: string) {
      if (!renderKey) {
        return;
      }
      if (!(renderKey in renders)) {
        console.warn(
          `Unknown action : ${renderKey}, please provide it in IaActionProvider`
        );
        return;
      }
      return renders[renderKey] as IaRenderUi;
    },
    [renders]
  );
  return {
    getIaRender,
  };
}
