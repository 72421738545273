import React from 'react';

export default function ActionChevronUp({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18 13.4698L16.3215 15L12 11.0604L7.6785 15L6 13.4698L12 8L18 13.4698Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
