import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Checkbox } from '@front/ui';
import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';

const styles = {
  root: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    svg: {
      width: '16px',
      height: '16px',
    },
    p: 0.5,
  },
  rootEnable: {
    color: (theme: Theme) => theme.palette.text.primary,
  },
  label: {
    py: 0,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    '& p': {
      typography: 'caption',
    },
  },
};
export default function SendPubliclyCheckbox({
  disabled,
}: {
  disabled?: boolean;
}) {
  const { t } = useTranslation('thread');
  const {
    sendPubliclyCheckboxEnable,
    sendPubliclyDisabled,
    sendPublicly,
    setSendPublicly,
    selectedAction,
  } = useThreadComposer();

  if (!sendPubliclyCheckboxEnable) return;

  // When keyboard is opened, send publicly checkbox should be hidden. (TEST-6039)
  if (selectedAction === 'mathKeyboard') return;

  return (
    <Checkbox
      sx={[styles.root, sendPublicly && styles.rootEnable]}
      labelContainerSx={styles.label}
      label={t('composer.checkbox.sendPublicly')}
      checked={sendPublicly}
      disabled={disabled || sendPubliclyDisabled}
      onClick={() => setSendPublicly(!sendPublicly)}
    />
  );
}
