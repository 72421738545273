import React from 'react';

export default function EditorHighlight({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.8 3C3.80589 3 3 3.80589 3 4.8V19.2C3 20.1941 3.80589 21 4.8 21H19.2C20.1941 21 21 20.1941 21 19.2V4.8C21 3.80589 20.1941 3 19.2 3H4.8ZM18.0345 18.3C18.1618 18.3 18.2488 18.1716 18.2018 18.0534L13.3259 5.81339C13.2986 5.74492 13.2324 5.7 13.1587 5.7H10.8787C10.8051 5.7 10.739 5.74472 10.7116 5.81296L5.79915 18.053C5.75169 18.1712 5.83877 18.3 5.9662 18.3H8.02241C8.09807 18.3 8.16566 18.2527 8.19155 18.1816L9.27303 15.2113C9.29891 15.1402 9.3665 15.0929 9.44217 15.0929H14.5031C14.5779 15.0929 14.6449 15.1392 14.6714 15.2091L15.7981 18.1838C15.8246 18.2537 15.8916 18.3 15.9665 18.3H18.0345ZM13.9435 13.1454C13.9874 13.263 13.9004 13.3883 13.7749 13.3883H10.1583C10.033 13.3883 9.94607 13.2635 9.98945 13.146L11.7552 8.36234C11.8229 8.17265 11.8846 7.91527 11.9403 7.5902C11.9422 7.57946 11.9515 7.57155 11.9624 7.57155C11.9732 7.57155 11.9825 7.57944 11.9844 7.59014C12.046 7.94374 12.1046 8.20114 12.1601 8.36234L13.9435 13.1454Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
