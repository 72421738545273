import { ReactElement } from 'react';

import IaAlbumBaseItem from '../components/IaAlbumItem/IaAlbumBaseItem';

import { AlbumLayoutSimpleTooltipConfig } from './types';

type AlbumLayoutSimpleTooltipProps = {
  config: AlbumLayoutSimpleTooltipConfig['config'];
  children: ReactElement;
};
export default function AlbumLayoutSimpleTooltip({
  config,
  children,
}: AlbumLayoutSimpleTooltipProps) {
  return (
    <IaAlbumBaseItem.Tooltip followCursor content={config.content}>
      {children}
    </IaAlbumBaseItem.Tooltip>
  );
}
