import { useRouter } from 'next/router';
import { useAuth } from '@lib/web/apis';

import ClaimAccountBlocker from './components/ClaimAccountBlocker';
import LandscapeBlocker from './components/LandscapeBlocker';
import LoginBlocker from './components/LoginBlocker';
import OnboardingBlocker from './components/OnboardingBlocker';
import SignupRequiredBlocker from './components/SignupRequiredBlocker';
import useShouldBlockFeature from './hooks/useShouldBlockFeature';

export default function CommonBlocker() {
  const router = useRouter();
  const blocker = router.query.blocker;
  const { isLoggedOut, isLoading } = useAuth();

  const shouldBlockFeature = useShouldBlockFeature();

  if (blocker === 'onboarding') return <OnboardingBlocker />;
  if (blocker === 'claim-account') return <ClaimAccountBlocker />;

  if (isLoading) return null;
  if (shouldBlockFeature && !isLoggedOut && !blocker)
    return <SignupRequiredBlocker />;

  return (
    <>
      <LoginBlocker />
      <LandscapeBlocker />
    </>
  );
}
