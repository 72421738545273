import React from 'react';

export default function OtherMetamaskGrey({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.5825 3L12.8589 9.09786L14.2952 5.50419L20.5825 3Z"
        fill="#E17726"
      />
      <path
        d="M3.43555 3.00732L9.70608 5.50479L11.0699 9.14558L3.43555 3.00732Z"
        fill="#E27625"
      />
      <path
        d="M17.4746 15.9688L20.8883 16.0381L19.6953 20.3626L15.5298 19.1388L17.4746 15.9688Z"
        fill="#E27625"
      />
      <path
        d="M6.52534 15.9688L8.46296 19.1389L4.30444 20.3627L3.11865 16.0381L6.52534 15.9688Z"
        fill="#E27625"
      />
      <path
        d="M10.8841 8.21826L11.0236 13.026L6.84912 12.8234L8.03653 10.912L8.05156 10.8935L10.8841 8.21826Z"
        fill="#E27625"
      />
      <path
        d="M13.0725 8.16455L15.9483 10.8936L15.9632 10.9119L17.1506 12.8233L12.9771 13.0259L13.0725 8.16455Z"
        fill="#E27625"
      />
      <path
        d="M8.58521 15.9824L10.8646 17.8774L8.2168 19.2415L8.58521 15.9824Z"
        fill="#E27625"
      />
      <path
        d="M15.4149 15.9819L15.7757 19.2413L13.1353 17.8771L15.4149 15.9819Z"
        fill="#E27625"
      />
      <path
        d="M13.1938 17.6987L15.8732 19.0827L13.3809 20.3462L13.4067 19.5111L13.1938 17.6987Z"
        fill="#D5BFB2"
      />
      <path
        d="M10.8051 17.6992L10.6006 19.4973L10.6174 20.3451L8.11914 19.0825L10.8051 17.6992Z"
        fill="#D5BFB2"
      />
      <path
        d="M10.0318 13.6533L10.732 15.223L8.34814 14.4781L10.0318 13.6533Z"
        fill="#233447"
      />
      <path
        d="M13.9686 13.6538L15.6601 14.4784L13.2686 15.2231L13.9686 13.6538Z"
        fill="#233447"
      />
      <path
        d="M8.76752 15.9663L8.38217 19.3453L6.31689 16.0402L8.76752 15.9663Z"
        fill="#CC6228"
      />
      <path
        d="M15.2324 15.9663L17.6831 16.0402L15.6101 19.3454L15.2324 15.9663Z"
        fill="#CC6228"
      />
      <path
        d="M17.2109 12.6323L15.4274 14.5712L14.0524 13.9009L13.394 15.3773L12.9624 12.8385L17.2109 12.6323Z"
        fill="#CC6228"
      />
      <path
        d="M6.78809 12.6323L11.0374 12.8385L10.6058 15.3773L9.94728 13.9011L8.57945 14.5713L6.78809 12.6323Z"
        fill="#CC6228"
      />
      <path
        d="M6.66797 12.2344L8.68582 14.4191L8.75575 16.576L6.66797 12.2344Z"
        fill="#E27525"
      />
      <path
        d="M17.3342 12.23L15.2427 16.5793L15.3214 14.4186L17.3342 12.23Z"
        fill="#E27525"
      />
      <path
        d="M10.9387 12.3672L11.0199 12.9126L11.2206 14.2713L11.0916 18.4446L10.4816 15.0922L10.4814 15.0575L10.9387 12.3672Z"
        fill="#E27525"
      />
      <path
        d="M13.0604 12.3594L13.5189 15.0572L13.5187 15.0919L12.9072 18.4527L12.883 17.6121L12.7876 14.2463L13.0604 12.3594Z"
        fill="#E27525"
      />
      <path
        d="M15.5008 14.332L15.4325 16.206L13.3039 17.9756L12.8735 17.6512L13.3559 15.0002L15.5008 14.332Z"
        fill="#F5841F"
      />
      <path
        d="M8.50635 14.332L10.6438 15.0002L11.1262 17.6511L10.6959 17.9755L8.5671 16.2058L8.50635 14.332Z"
        fill="#F5841F"
      />
      <path
        d="M7.7124 18.6694L10.4357 20.0459L10.4242 19.4581L10.6521 19.2448H13.3474L13.5835 19.4574L13.5661 20.0447L16.2722 18.6729L14.9554 19.8336L13.3632 21.0002H10.6303L9.03902 19.8289L7.7124 18.6694Z"
        fill="#C0AC9D"
      />
      <path
        d="M12.9983 17.5156L13.3834 17.8058L13.609 19.7262L13.2825 19.4321H10.7178L10.3975 19.7321L10.6157 17.8059L11.0009 17.5156H12.9983Z"
        fill="#161616"
      />
      <path
        d="M20.0727 3.16846L20.9998 6.1362L20.4208 9.13687L20.8331 9.47626L20.2752 9.93044L20.6945 10.2759L20.1393 10.8155L20.4801 11.0789L19.5755 12.2062L15.8651 11.0535L15.833 11.0351L13.1592 8.62844L20.0727 3.16846Z"
        fill="#763E1A"
      />
      <path
        d="M3.92714 3.16846L10.8407 8.62844L8.16687 11.0351L8.13472 11.0535L4.4243 12.2062L3.51969 11.0789L3.86029 10.8157L3.30533 10.2759L3.72384 9.93081L3.15759 9.47534L3.58544 9.13575L3 6.13631L3.92714 3.16846Z"
        fill="#763E1A"
      />
      <path
        d="M15.6839 10.8018L19.6153 12.0231L20.8926 16.2233L17.523 16.2233L15.2012 16.2545L16.8897 12.7428L15.6839 10.8018Z"
        fill="#F5841F"
      />
      <path
        d="M8.3158 10.8018L7.10986 12.7428L8.79858 16.2545L6.47793 16.2233H3.11426L4.38434 12.0231L8.3158 10.8018Z"
        fill="#F5841F"
      />
      <path
        d="M14.4881 5.48389L13.3885 8.65279L13.1552 12.9337L13.0659 14.2755L13.0588 17.7031H10.9416L10.9347 14.2819L10.8451 12.9325L10.6117 8.65279L9.51221 5.48389H14.4881Z"
        fill="#F5841F"
      />
      <rect
        height="20"
        style={{ mixBlendMode: 'saturation' }}
        width="20"
        fill="black"
        x="2.25"
        y="2.25"
      />
    </svg>
  );
}
