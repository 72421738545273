import { MouseEvent, useCallback } from 'react';
import { reactionSuggestions } from '@front/config';

import { TableLayoutPlugin, TableLayoutReactionCell } from '../../types';

import useReactionActions from './IaTableReactionPlugin/useReactionAction';

export type GetPluginCellMap = {
  [TableLayoutPlugin.Reaction]: TableLayoutReactionCell;
};

export default function useIaTablePluginToolbarActions() {
  const reactionActions = useReactionActions();

  const handleHover = useCallback(
    (
      domEvent: MouseEvent,
      itemEvent: { plugin: TableLayoutPlugin; value: string },
      items: {
        id: string;
        cell: TableLayoutReactionCell;
      }[],
      triggerFrom: 'menu' | 'toolbar'
    ) => {
      if (itemEvent.plugin === TableLayoutPlugin.Reaction) {
        const target =
          triggerFrom === 'menu'
            ? (domEvent.target as Element).closest?.('.MuiButtonBase-root') ||
              (domEvent.target as Element)
            : (domEvent.target as Element);
        reactionActions.hover(target, itemEvent.value, items, {
          closeOnBlur: true,
          menuContainer:
            triggerFrom === 'menu'
              ? target.closest('.MuiModal-root')
              : undefined,
        });

        return;
      }
    },
    [reactionActions]
  );

  const handleClick = useCallback(
    (
      itemEvent: { plugin: TableLayoutPlugin; value: string },
      items: {
        id: string;
        cell: TableLayoutReactionCell;
      }[]
    ) => {
      if (itemEvent.plugin === TableLayoutPlugin.Reaction) {
        const reactionValues = items
          .map((item) => item.cell.value)
          .filter((value) => !!value);

        const updateValue =
          reactionValues.length > 0 ? null : reactionSuggestions[0];
        reactionActions.click(items, updateValue, {
          action: itemEvent.value,
        });
        return;
      }
    },
    [reactionActions]
  );

  return {
    handleHover,
    handleClick,
  };
}
