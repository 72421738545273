import { useEffect } from 'react';
import { alpha, useTheme } from '@mui/material';
import { BlockNoteEditor, BlockSchema } from '@blocknote/core';
import { composerConfig } from '@front/config';
import {
  dropCursor,
  DropFileEventDetail,
  DropFileEventKey,
} from '@lib/web/composer/TextComposer/hooks/plugins/dropcursor';

export const useFileDroppableDropCursor = <T extends BlockSchema>(
  editor: BlockNoteEditor<T>,
  validateDroppableFile: (editor: BlockNoteEditor<T>) => boolean = () => true
) => {
  const { palette } = useTheme();
  useEffect(() => {
    /**
     * DropCursor is the underline that when you drag and drop something, there is a line in there.
     * Ideally we want to disable DropCursor by following function,
     * because we want to support file drop in, so we have our customized DropCursor plugin.
     * But default DropCursor does not have its plugin key 'DropCursor',
     * this will make unregisterPlugin fail because no clear way to identify which plugin is DropCursor.
     * Instead of following function, we still let default DropCursor enable, but use css to hide it
     * .prosemirror-dropcursor-block,
     * .prosemirror-dropcursor-inline {
     *   display: none;
     * }
     * in styles.css
     */
    // editor._tiptapEditor.unregisterPlugin('DropCursor')

    editor._tiptapEditor.registerPlugin(
      dropCursor({ width: 4, color: alpha(palette.primary.light, 0.5) })
    );

    const handleDropFileEvent = (event: Event) => {
      const {
        detail: { insertPos, file },
      } = event as CustomEvent<DropFileEventDetail>;

      if (!insertPos || !file) {
        return;
      }

      const composerSupportImage = 'image' in editor.schema;
      const composerSupportFile = 'file' in editor.schema;
      const composerSupportAudio = 'audio' in editor.schema;

      const isSupportedImageTypes = composerConfig.supportedImageTypes.includes(
        file.type
      );
      const isSupportedFileTypes = composerConfig.supportedFileTypes.includes(
        file.type
      );
      const isSupportedAudioTypes = composerConfig.supportedAudioTypes.includes(
        file.type
      );

      let insertBlockType = null;

      if (composerSupportImage && isSupportedImageTypes) {
        insertBlockType = 'image';
      } else if (composerSupportFile && isSupportedFileTypes) {
        insertBlockType = 'file';
      } else if (composerSupportAudio && isSupportedAudioTypes) {
        insertBlockType = 'audio';
      } else if (composerSupportImage) {
        insertBlockType = 'image';
      } else if (composerSupportFile) {
        insertBlockType = 'file';
      } else if (composerSupportAudio) {
        insertBlockType = 'audio';
      }

      if (!insertBlockType || !validateDroppableFile(editor)) return;

      editor._tiptapEditor
        .chain()
        .focus()
        .insertContentAt(insertPos, [
          {
            type: insertBlockType,
            attrs: {
              status: 'added-by-file-drop',
              file,
            },
          },
        ])
        .run();
    };

    editor.domElement.addEventListener(DropFileEventKey, handleDropFileEvent);

    return () => {
      editor.domElement.removeEventListener(
        DropFileEventKey,
        handleDropFileEvent
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.domElement]);

  useEffect(() => {
    const events = ['dragenter', 'dragover', 'dragleave', 'drop'];
    const preventOpenFileInBrowser = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    events.forEach((eventName) => {
      editor.domElement.addEventListener(eventName, preventOpenFileInBrowser);
    });
    return () => {
      events.forEach((eventName) => {
        editor.domElement.removeEventListener(
          eventName,
          preventOpenFileInBrowser
        );
      });
    };
  }, [editor.domElement]);
};
