import api from './client';

const adminApi = {
  createInternalMember: (params: CreateInternalMemberReq) =>
    api.post<Response<CreateInternalMemberRes>>(
      'auth/admin/v1/internal-members',
      params
    ),
  deleteInternalMember: (params: DeleteInternalMemberReq) =>
    api.delete<Response<DeleteInternalMemberRes>>(
      'auth/admin/v1/internal-members',
      { data: params }
    ),
  updateInternalUserProfile: (params: UpdateInternalUserProfileReq) =>
    api.post<Response<UpdateInternalUserProfileRes>>(
      'v2/user/profile/internal',
      params
    ),
  getLocations: ({ country }: GetLocationsReq = {}) => {
    const params = new URLSearchParams();

    if (country) params.set('country', country);

    return api.get<Response<GetLocationsRes>>(
      `auth/admin/v1/account/locations?${params.toString()}`
    );
  },
  getSchools: ({ country, types }: GetSchoolsReq) => {
    const params = new URLSearchParams();

    if (country) params.set('country', country);
    if (types) {
      types.forEach((type) => params.append('types', type));
    }

    return api.get<Response<GetSchoolsRes>>(
      `auth/admin/v1/account/schools?${params.toString()}`
    );
  },
  getInternalUserPassword: ({ memberId }: GetInternalUserPasswordReq) =>
    api.get<Response<GetInternalUserPasswordRes>>(
      `/auth/admin/v1/internal-password?memberId=${memberId}`
    ),
  setInternalUserPassword: (params: SetInternalUserPasswordReq) =>
    api.put<Response<SetInternalUserPasswordRes>>(
      `/auth/admin/v1/internal-password?memberId=${params.memberId}`,
      params
    ),
  joinClub: (params: JoinClubReq) => api.post('v2/club/admin/join', params),
  leaveClub: (params: LeaveClubReq) => api.post('v2/club/admin/leave', params),
  requestJoinClub: (params: RequestJoinClubReq) =>
    api.post('v2/club/admin/join-request', params),
};

export default adminApi;
