import { useCallback, useContext } from 'react';
import Router from 'next/router';
import IaActionContext, {
  IaActionContextValue,
  IaAvailableAction,
  IaDisableAction,
} from '@lib/ia/src/core/IaAction/IaActionContext';

export function useIaAction() {
  const { availableActions, disableActions } =
    useContext<IaActionContextValue>(IaActionContext);

  const getIaAction = useCallback(
    function <T = never, Return = any>(actionKey?: string) {
      if (!actionKey) {
        return;
      }
      if (!(actionKey in availableActions)) {
        console.warn(
          `Unknown action : ${actionKey}, please provide it in IaActionProvider`
        );
        return;
      }
      return availableActions[actionKey] as IaAvailableAction<T, Return>;
    },
    [availableActions]
  );

  const getIaActionDisabled = useCallback(
    function <T = never>(actionKey?: string) {
      if (!actionKey || !disableActions) {
        return;
      }
      return disableActions[actionKey] as IaDisableAction<T>;
    },
    [disableActions]
  );

  const iaRoutingAction = useCallback(
    (value: string) => Router.push(value),
    []
  );

  return {
    getIaAction,
    getIaActionDisabled,
    iaRoutingAction,
  };
}
