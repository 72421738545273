import React, { ReactNode } from 'react';
import {
  alpha,
  ButtonBase,
  ButtonBaseProps,
  ButtonBaseTypeMap,
  Theme,
} from '@mui/material';

const styles = {
  root: {
    display: 'grid',
    gap: '6px',
    px: '2px',
    typography: 'body2',
    '& .internal-button-text': {
      textDecoration: 'underline',
      textDecorationColor: (theme: Theme) =>
        alpha(theme.palette.text.primary, 0.3),
      textUnderlineOffset: 3,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '@media (hover:hover)': {
      '&:hover': {
        '& .internal-button-text': {
          textDecorationColor: (theme: Theme) => theme.palette.text.primary,
        },
      },
    },
  },
};
export type InternalButtonProps<
  D extends React.ElementType = ButtonBaseTypeMap['defaultComponent'],
  P = {
    component?: D;
    prefixIcon?: ReactNode;
  }
> = ButtonBaseProps<D, P>;

function ButtonInner<D extends React.ElementType = 'button'>(
  { sx, prefixIcon, children, ...rest }: InternalButtonProps<D>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <ButtonBase
      ref={ref}
      sx={[
        styles.root,
        { gridTemplateColumns: prefixIcon ? '16px 1fr' : 'unset' },
        ...sxProps,
      ]}
      {...rest}
    >
      {prefixIcon}
      <span className="internal-button-text">{children}</span>
    </ButtonBase>
  );
}

const InternalButton = React.forwardRef(ButtonInner) as <
  D extends React.ElementType = ButtonBaseTypeMap['defaultComponent']
>(
  props: InternalButtonProps<D> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof ButtonInner>;

export default InternalButton;
