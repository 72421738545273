import { CardLayoutTooltipConfig } from '../types';

import CardLayoutListTooltip from './CardLayoutListTooltip';
import CardLayoutSimpleTooltip from './CardLayoutSimpleTooltip';
import {
  CardLayoutListTooltipConfig,
  CardLayoutSimpleTooltipConfig,
} from './types';

type CardLayoutTooltipProps = CardLayoutTooltipConfig;

export default function CardLayoutTooltip({
  type,
  config,
}: CardLayoutTooltipProps) {
  if (type === 'list') {
    const listTooltipConfig = config as CardLayoutListTooltipConfig['config'];
    return <CardLayoutListTooltip config={listTooltipConfig} />;
  }
  if (type === 'simple') {
    const simpleTooltipConfig =
      config as CardLayoutSimpleTooltipConfig['config'];
    return <CardLayoutSimpleTooltip config={simpleTooltipConfig} />;
  }
  console.warn(`Tooltip type = ${type} is not supported`);
  return null;
}
