import React, { KeyboardEvent, MouseEvent, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IconButton } from '@front/ui';
import { IaColorVariant } from '@lib/ia/src/core/types';

import Icon from '../../../../components/Icon';
import { useIaAction } from '../../../../core/IaAction/useIaAction';
import useTableSelectionContext from '../../hooks/useTableSelectionContext';
import { TableLayoutIconCell, TableLayoutRow } from '../../types';

export type IaIconCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutIconCell;
  columnKey: string;
};

const colorMap: Record<IaColorVariant, string> = {
  default: 'grey.500',
  success: 'success.light',
  error: 'error.dark',
  info: 'primary.light',
  warning: 'warning.light',
  text: 'text.primary',
  yellow: 'highlight.yellow',
  orange: 'highlight.orange',
  red: 'highlight.red',
  green: 'highlight.green',
  blue: 'highlight.blue',
};

const styles = {
  root: {
    height: '100%',
    display: 'grid',
    alignItems: 'center',
  },
  icon: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    display: 'grid',
    alignItems: 'center',
  },
  iconButton: {
    fontSize: 16,
  },
  disabled: {
    opacity: 0.5,
  },
};
export default function IaIconCell({
  row,
  cell: { icon, emoji, color, clickAction, disabled = false },
  columnKey,
}: IaIconCellProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  if (icon && emoji) {
    console.warn('Both icon and emoji are provided, only icon will be used');
  }

  const iconColor =
    color && color in colorMap ? colorMap[color as IaColorVariant] : color;
  const clickable = !!clickAction;

  const { getIaAction } = useIaAction();
  const handleClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (disabled) {
      return;
    }

    void getIaAction<{ id: string; anchorEl?: HTMLElement }>(
      clickAction
    )?.action({ id: row.id, anchorEl: ev.currentTarget });
  };

  const { getCellSelectedState } = useTableSelectionContext();
  const selectedState = getCellSelectedState(row.id, columnKey);

  useEffect(() => {
    if (selectedState === 'focused') {
      buttonRef.current?.focus({
        preventScroll: true,
      });
    } else {
      buttonRef.current?.blur();
    }
  }, [selectedState]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return;
    buttonRef.current?.click();
  };

  return (
    <Box
      sx={[styles.root, disabled && styles.disabled]}
      px={clickable ? 0.5 : 1}
    >
      <Typography
        variant="body2"
        sx={[styles.icon, !!color && { color: iconColor }]}
      >
        {clickable && (
          <IconButton
            customSize={24}
            onClick={handleClick}
            sx={styles.iconButton}
            ref={buttonRef}
            onKeyDown={handleKeyDown}
          >
            {icon ? <Icon name={icon} /> : emoji ? emoji : null}
          </IconButton>
        )}

        {!clickable &&
          (icon ? (
            <Icon name={icon} width={16} height={16} />
          ) : emoji ? (
            emoji
          ) : null)}
      </Typography>
    </Box>
  );
}
