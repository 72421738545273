import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';

import { HintLayoutConfig } from './types';

export type HintLayoutProps = HintLayoutConfig;

const styles = {
  root: {
    px: { xs: 2.5, md: '12px' },
  },
  hint: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
};
export default function HintLayout({ text }: HintLayoutProps) {
  return (
    <Box sx={styles.root} className="ia-hint-layout">
      <Typography variant="body1" sx={styles.hint}>
        {text}
      </Typography>
    </Box>
  );
}
