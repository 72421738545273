import React, { useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
  GlobalSortableContext,
  GlobalSortableValue,
} from './contexts/sortableGlobalContext';
import { SortableProps } from './types';

export default function Sortable<T extends { id: string }>({
  items,
  onDragOverOtherItem,
  children,
  onDraggableStart,
  ...rest
}: SortableProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1, // add a small distance for starting dragging, then we can make 6 dot icon clickable
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );

  const [value, setValue] = useState<GlobalSortableValue>({
    isDragging: false,
    draggingId: null,
  });

  const handleDragStart = (event: DragStartEvent) => {
    setValue({ isDragging: true, draggingId: event.active.id as string });

    onDraggableStart?.(items.find((item) => item.id === event.active.id) as T);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setValue({ isDragging: false, draggingId: null });

    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = items.findIndex((item) => item.id === active.id);
    const newIndex = items.findIndex((item) => item.id === over.id);

    if (oldIndex === -1 || newIndex === -1) {
      console.warn(
        'oldIndex or newIndex is not available, which should not happen'
      );
      return;
    }

    onDragOverOtherItem({
      activeItem: items[oldIndex],
      overItem: items[newIndex],
      newItems: arrayMove(items, oldIndex, newIndex),
    });
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      {...rest}
    >
      <GlobalSortableContext.Provider value={[value, setValue]}>
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {typeof children === 'function' ? children(value) : children}
        </SortableContext>
      </GlobalSortableContext.Provider>
    </DndContext>
  );
}
