import React from 'react';

export default function EditorBarChart({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        height="1.89474"
        width="18"
        fill="currentColor"
        rx="0.947368"
        x="3"
        y="19.1052"
      />
      <rect
        height="6.63158"
        width="4"
        fill="currentColor"
        rx="2"
        x="3"
        y="10.5789"
      />
      <rect
        height="10.4211"
        width="4"
        fill="currentColor"
        rx="2"
        x="10"
        y="6.78955"
      />
      <rect
        height="14.2105"
        width="4"
        fill="currentColor"
        rx="2"
        x="17"
        y="3"
      />
    </svg>
  );
}
