import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import { EditorAI as EditorAIIcon } from '@front/icon';
import { Button } from '@front/ui';
import { useTokenBalanceControl } from '@lib/web/thread/hooks/channel/useTokenBalanceControl';
import { trackEvent } from '@lib/web/utils';

const styles = {
  root: {
    display: 'flex',
    px: '8px',
    width: 0,
    minWidth: '100%',
    pb: 0.5,
  },
  section: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr max-content',
    gap: 2,
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    p: '4px',
    borderRadius: 1,
    width: '100%',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '16px 1fr',
    alignItems: 'center',
    gap: 1,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
  button: {
    background:
      'linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
    borderRadius: 1,
    border: 'none',
    color: (theme: Theme) => theme.palette.text.primary,
    px: 2,
    py: '7.5px',
    minWidth: 'unset',
  },
};

export default function UpgradeCtaButton() {
  const { t } = useTranslation('thread');
  const { runOutOfToken } = useTokenBalanceControl();

  const handleUpgradeClick = useCallback(() => {
    trackEvent('click', {
      elementName: 'upgrade-button',
      fromLocation: 'run-out-of-token',
    });
    void Router.push('/profile/my-plan');
  }, []);

  if (!runOutOfToken) {
    return null;
  }

  return (
    <Box sx={styles.root} className="thread-upgrade-bta-button">
      <Box sx={styles.section}>
        <Box sx={styles.content}>
          <EditorAIIcon width={16} height={16} />
          <Typography variant="caption">
            {t('composer.ai.runOutToken.message')}
          </Typography>
        </Box>
        <Button sx={styles.button} onClick={handleUpgradeClick}>
          {t('composer.ai.runOutToken.button')}
        </Button>
      </Box>
    </Box>
  );
}
