import React from 'react';

export default function CursorTouchOff({ ...rest }) {
  return (
    <svg
      height="52"
      width="52"
      fill="none"
      viewBox="0 0 52 52"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g>
        <circle cx="26" cy="23" fill="#F8F8F8" fillOpacity="0.8" r="22" />
        <circle cx="26" cy="23" r="21.5" stroke="white" />
      </g>
      <defs />
    </svg>
  );
}
