import katex from 'katex';

export const translateBreak = (tex: string) => {
  // 先將所有的斷行加上 <br />
  const breakPattern = /(\n\r|\n|\r)/gm;
  const breakText = tex.replace(
    breakPattern,
    (value: string) => `${value}<br />`
  );

  // 在 latex 裡的斷行移除
  const latexPattern = /([$])(\\\1|[^$])*\1/gm;
  const clearBreakLaTex = breakText.replace(latexPattern, (value: string) =>
    value.replace(/<br \/>/gm, '')
  );
  return clearBreakLaTex;
};

export const translateFormula = (tex: string) => {
  const pattern = /([$])(\\\1|[^$])*\1/gm;

  const passage = translateBreak(tex);
  const formulaTex = passage.replace(pattern, (value: string) => {
    const removed$ = value.replace(/(\\\$)|\$/g, (v) =>
      v.includes('\\') ? v : ''
    );

    return katex.renderToString(removed$, {
      throwOnError: false,
      output: 'html',
    });
  });

  return formulaTex;
};

export const translateBreakline = (tex: string, className = 'break-line') => {
  const pattern = /\[[[[0-9]+\]]/g;
  return tex.replace(pattern, (value: string) => {
    const number = value.replace('[[', '').replace(']]', '');
    return `<span class="${className}" data-no="${number}">[${number}]</span>`;
  });
};
