import { useCallback } from 'react';

const isJsonParsable = (tex: string) => {
  try {
    JSON.parse(tex);
  } catch (e) {
    if (!(e instanceof SyntaxError)) {
      console.warn('Unexpected error when calling isJsonParsable', e, tex);
    }
    return false;
  }
  return true;
};

export const isNewFormatQuestion = (tex: string | null) => {
  if (!tex) {
    return true;
  }

  /**
   * currently we don't know which question is new format, in the future,
   * backend api will provide this information, so maybe we don't need to use 'isJsonParsable'
   */
  return isJsonParsable(tex);
};

export default function useQuestionTextRender({
  newFormatRender,
}: {
  newFormatRender: (tex: string | null) => string;
}) {
  const questionRender = useCallback(
    (tex: string | null) => {
      if (isNewFormatQuestion(tex)) {
        return newFormatRender(tex);
      }

      return tex || '';
    },
    [newFormatRender]
  );

  return {
    questionRender,
    isNewFormatQuestion,
  };
}
