import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  alpha,
  Box,
  ButtonBase,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ActionArrowLeft as ActionArrowLeftIcon,
  NFTEmojiCryingFace as NFTEmojiCryingFaceIcon,
} from '@front/icon';
import { Logo } from '@front/ui';
import { GetHelp } from '@lib/web/ui';

const styles = {
  page: {
    position: 'fixed',
    zIndex: 5001,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    bgcolor: (theme: Theme) => alpha(theme.palette.background.darker, 0.75),
    backdropFilter: 'blur(25px)',
    pb: { xs: '48px', md: '64px' },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 1.5,
    px: { xs: '20px', md: 1.5 },
  },
  logo: {
    cursor: 'pointer',
    width: { xs: 20, md: 40 },
    height: { xs: 20, md: 40 },
  },
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    px: '20px',
  },
  content: {
    margin: 'auto',
    width: '100%',
    maxWidth: '769px',
  },
  title: {
    fontSize: { xs: 30 },
    fontWeight: 700,
    mt: '20px',
    mb: 1,
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
    opacity: 0.75,
    mb: 5,
  },
  bottomActions: {
    display: { xs: 'grid', md: 'block' },
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: { xs: 'center', md: 'flex-start' },
    px: 1,
    py: '6px',
  },
  link: {
    ml: 1,
    '&:hover': {
      color: 'primary.main',
    },
  },
};

export enum PaymentLinkErrorType {
  AlreadyBought = 1,
  Expired = 2,
}

function getErrorType(val: string) {
  if (val === PaymentLinkErrorType.Expired.toString()) {
    return PaymentLinkErrorType.Expired;
  }
  if (val === PaymentLinkErrorType.AlreadyBought.toString()) {
    return PaymentLinkErrorType.AlreadyBought;
  }
  return undefined;
}

function PaymentLinkErrorListener() {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const router = useRouter();
  const paymentLinkError = (router.query.paymentLinkError || '') as string;
  const returnTo = (router.query.returnTo || '') as string;

  if (!paymentLinkError) return null;

  const errorType = getErrorType(paymentLinkError);
  if (!errorType) return null;

  return (
    <Box sx={styles.page}>
      <Box sx={styles.header}>
        <ButtonBase sx={styles.logo} component={Link} href="/" disableRipple>
          <Logo />
        </ButtonBase>
        {!mdUp && <GetHelp />}
      </Box>
      <Box sx={styles.container}>
        <Box sx={styles.content}>
          <NFTEmojiCryingFaceIcon width={110} height={200} />
          <Typography variant="h6" sx={styles.title}>
            {errorType === PaymentLinkErrorType.Expired &&
              'Oops! This link has expired'}
            {errorType === PaymentLinkErrorType.AlreadyBought &&
              'Oops! Someone already bought this'}
          </Typography>
          <Typography variant="body1" sx={styles.description}>
            {errorType === PaymentLinkErrorType.Expired &&
              'The link you accessed has expired. Please request the sender to generate a new payment link. 😰'}
            {errorType === PaymentLinkErrorType.AlreadyBought &&
              'Please request the sender to browse and buy an available avatar in the marketplace.'}
          </Typography>
          <Box sx={styles.bottomActions}>
            {returnTo === 'marketplace' && (
              <Box sx={styles.linkWrapper}>
                <ButtonBase
                  component={Link}
                  disableRipple
                  href="/marketplace/available"
                >
                  <ActionArrowLeftIcon width={16} height={16} />
                  <Typography
                    sx={styles.link}
                    variant="body2"
                    fontWeight={700}
                    lineHeight={1}
                  >
                    Back to earnaha.com/marketplace
                  </Typography>
                </ButtonBase>
              </Box>
            )}
            {returnTo !== 'marketplace' && (
              <Box sx={styles.linkWrapper}>
                <ButtonBase
                  component="a"
                  disableRipple
                  href="https://www.earnaha.com/"
                  rel="noopener noreferrer"
                >
                  <ActionArrowLeftIcon width={16} height={16} />
                  <Typography
                    sx={styles.link}
                    variant="body2"
                    fontWeight={700}
                    lineHeight={1}
                  >
                    Back to earnaha.com
                  </Typography>
                </ButtonBase>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentLinkErrorListener;
