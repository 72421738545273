import { useMemo } from 'react';
import { appConfig } from '@front/config';
import {
  buildInfiniteHookMutate,
  MarkGroupEnum,
  MarkViewSlug,
  useIaMarkList,
} from '@lib/web/apis';

export default function useRecentMarks() {
  const { dataset, mutate } = useIaMarkList({
    viewSlug: MarkViewSlug.IconRhsIAMarkRecently,
    limit: appConfig.maxRecentMarksDisplay,
  });

  const mutateMarks = useMemo(() => buildInfiniteHookMutate(mutate), [mutate]);

  return {
    recentMarks: dataset,
    mutateRecentMarksWithNewMark: (
      newMark: Omit<GetIaMarkRhsRecentlyViewRes, 'lastUsedAt' | 'type'>
    ) => {
      const remainingMarks = (dataset || []).filter(
        (mark) => mark.id !== newMark.id
      );
      const newMarks = [
        {
          ...newMark,

          // these values are just make the type correct, it doesn't matter what value
          lastUsedAt: new Date().toISOString(),
          type: MarkGroupEnum.EmojiSymbols,
        },
        ...remainingMarks,
      ].slice(0, appConfig.maxRecentMarksDisplay);

      return mutateMarks(Promise.resolve(), {
        optimisticData: newMarks,
        revalidate: false,
      });
    },
  };
}
