import { Divider } from '@mui/material';

import { DividerItemConfig } from '../../types';

const styles = {
  root: {
    fontSize: 12,
    width: '100%',
  },
};

function getSizeStyles(size: 'sm' | 'md') {
  if (size === 'sm') {
    return {
      py: 1,
      px: '12px',
    };
  }
  return {
    py: 1,
    px: '20px',
  };
}

type DividerItemProps = DividerItemConfig & {
  size: 'sm' | 'md';
};

export default function DividerItem({
  content,
  textAlign = 'left',
  size,
}: DividerItemProps) {
  return (
    <Divider sx={[styles.root, getSizeStyles(size)]} textAlign={textAlign}>
      {content}
    </Divider>
  );
}
