export const enum QueryAnalyticsMistakeType {
  Wrong = 1,
  Overtime = 2,
}
// eslint-disable-next-line import/prefer-default-export
export enum QueryAnalyticsWeaknessType {
  Wrong = 1,
  Overtime = 2,
  OutOfPractice = 3,
}
