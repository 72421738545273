import { ReactNode } from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

import { base, exam } from '../../themes';

type ReverseThemeProps = {
  children: ReactNode;
};
export default function ReverseTheme({ children }: ReverseThemeProps) {
  const theme = useTheme();
  const updateTheme = createTheme(theme.palette.mode === 'dark' ? exam : base);

  return <ThemeProvider theme={updateTheme}>{children}</ThemeProvider>;
}
