import { FC } from 'react';
import {
  Block,
  BlockNoteEditor,
  BlockSchema,
  DefaultBlockSchema,
  SideMenuProsemirrorPlugin,
} from '@blocknote/core';
import { DragHandleMenuProps } from '@blocknote/react';

import AddBlockButton from './components/AddBlockButton';
import DragHandle from './components/DragHandle';

export type SideMenuProps<BSchema extends BlockSchema = DefaultBlockSchema> =
  Pick<
    SideMenuProsemirrorPlugin<BSchema>,
    | 'blockDragStart'
    | 'blockDragEnd'
    | 'addBlock'
    | 'freezeMenu'
    | 'unfreezeMenu'
  > & {
    block: Block<BSchema>;
    editor: BlockNoteEditor<BSchema>;
    dragHandleMenu?: FC<DragHandleMenuProps<BSchema>>;
  };

export default function CustomSideMenu<
  BSchema extends BlockSchema = DefaultBlockSchema
>(props: SideMenuProps<BSchema>) {
  return (
    <>
      <AddBlockButton {...props} />
      <DragHandle {...props} />
    </>
  );
}
