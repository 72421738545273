import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueueWorker } from '@front/helper';
import { BaseLayoutRightPanel, toast, useBaseRightPanel } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { FormLayoutMethodsProvider } from '@lib/ia/src/layouts/FormLayout';
import { VariableType } from '@lib/web/apis';
import { CreatorQuestionDetailContext } from '@lib/web/editor/contexts';
import { useCurrentQuestion } from '@lib/web/editor/hooks';
import { useCurrentIaClub } from '@lib/web/hooks';
import { call } from '@lib/web/utils';
import { TFunction } from 'i18next';

import { TextComposerPanelKeys, TextComposerPanelParams } from '../../panel';

import FormulaVariableForm from './FormulaVariableForm';
import NumberVariableForm from './NumberVariableForm';
import TextVariableForm from './TextVariableForm';
import { getUpdateValueByType } from './utils';

const styles = {
  scroll: {
    '& .simplebar-content': {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

const getPanelTitle = (t: TFunction, type: VariableType) => {
  if (type === VariableType.Number) {
    return t('Number Variable');
  }
  if (type === VariableType.Text) {
    return t('Text Variable');
  }
  if (type === VariableType.NumberFormula) {
    return t('Variable Formula');
  }
  return '';
};

export default function VariableForm() {
  const { t } = useTranslation('editor');
  const { getRightParams, setRightParams } =
    useBaseRightPanel<TextComposerPanelParams>();

  const rightParams = getRightParams(
    TextComposerPanelKeys.TextComposerVariable
  );

  const { variableId } = rightParams;

  const { addTask } = useQueueWorker();

  const { sectionId } = useCurrentIaClub();
  const { questionId } = useCurrentQuestion();

  const {
    addCreatorQuestionVariable,
    updateCreatorQuestionVariable,
    useCreatorQuestionVariable,
  } = useContext(CreatorQuestionDetailContext);

  const { mutate } = useCreatorQuestionVariable({
    sectionId,
    creatorQuestionId: questionId,
    type: rightParams.type,
  });

  const updateVariable = async (
    paramsGetter:
      | AddCreatorQuestionVariableReq
      | UpdateCreatorQuestionVariableReq
      | (() => AddCreatorQuestionVariableReq | UpdateCreatorQuestionVariableReq)
  ) => {
    try {
      const result = await new Promise<Response<GetCreatorQuestionVariableRes>>(
        (resolve, reject) => {
          addTask({
            scope: 'variableForm',
            taskKey: questionId,
            shouldInterrupt: true,
            debounceTime: 1000,
            task: async () => {
              const params =
                typeof paramsGetter === 'function'
                  ? paramsGetter()
                  : paramsGetter;

              const [res, err] = await call(
                'id' in params && params.id !== undefined
                  ? updateCreatorQuestionVariable(params)
                  : addCreatorQuestionVariable(
                      params as AddCreatorQuestionVariableReq
                    )
              );

              if (res) {
                resolve(res);
                return;
              }

              reject(err);
            },
          });
        }
      );

      void mutate();
      const newValues = getUpdateValueByType(result.data, rightParams.type);

      setRightParams<TextComposerPanelKeys.TextComposerVariable>((st) => ({
        ...st,
        isNewCreated: false,
        defaultValue: newValues,
        variableId: newValues.id,
      }));
      if (newValues.id) rightParams.onVariableCreated?.(newValues.id);
    } catch (err: any) {
      if (err.message) {
        toast.error(err.message, undefined, { id: 'error-message' });
      }
    }
  };

  return (
    <BaseLayoutRightPanel
      title={getPanelTitle(t, rightParams.type)}
      titleIcon={<Icon name="EditorTextVariable" width={16} height={16} />}
    >
      <BaseLayoutRightPanel.ScrollArea sx={styles.scroll}>
        <FormLayoutMethodsProvider>
          {rightParams.type === VariableType.NumberFormula && (
            <FormulaVariableForm
              variableId={variableId}
              createOrUpdateVariable={updateVariable}
            />
          )}
          {rightParams.type === VariableType.Number && (
            <NumberVariableForm
              variableId={variableId}
              createOrUpdateVariable={updateVariable}
            />
          )}
          {rightParams.type === VariableType.Text && (
            <TextVariableForm
              variableId={variableId}
              createOrUpdateVariable={updateVariable}
            />
          )}
        </FormLayoutMethodsProvider>
      </BaseLayoutRightPanel.ScrollArea>
    </BaseLayoutRightPanel>
  );
}
