import Router from 'next/router';
import {
  AnswerFormatType,
  apis,
  Difficulty,
  StructureType,
} from '@lib/web/apis';
import {
  useAddableAnswerFormat,
  useCreatorQuestionListData,
} from '@lib/web/editor';
import { useCurrentIaClub } from '@lib/web/hooks';
import { callWithToast } from '@lib/web/utils';

type AnchorQuestion = {
  addAboveOrBelow: 'above' | 'below';
  id: string;
  questionGroupId?: string;
};

export const useAddQuestion = ({
  getQuestionPath,
}: {
  getQuestionPath: (questionId: string) => string;
}) => {
  const { sectionId } = useCurrentIaClub();
  const { defaultAnswerFormatType } = useAddableAnswerFormat();

  const { reloadQuestions } = useCreatorQuestionListData();

  const addNewQuestionAndNavigateToEdit = async (
    param?: Omit<NewCreatorQuestionReq, 'sectionId'>
  ) => {
    // maybe we can add a skeleton question first
    if (!sectionId) {
      console.warn('sectionId is not available');
      return;
    }

    const [res] = await callWithToast(() =>
      apis.editor.newCreatorQuestion({
        sectionId,
        ...param,
      })
    );

    if (res) {
      void reloadQuestions();
      void Router.push(getQuestionPath(res.data.id));
      return res;
    }
  };

  /**
   * For create questions page, add new question row
   */
  const handleCreateNewClick = async () => {
    void addNewQuestionAndNavigateToEdit({
      answerFormatType: defaultAnswerFormatType,
      difficulty: Difficulty.Medium,
    });
  };

  const handleAddBelowClick = async (
    id: string,
    questionGroupId?: string,
    answerFormatType = defaultAnswerFormatType
  ) => {
    return addNewQuestionAndNavigateToEdit({
      afterQuestionId: id,
      questionGroupId,
      answerFormatType,
      structureType: questionGroupId ? StructureType.SubQuestion : undefined,
      difficulty: Difficulty.Medium,
    });
  };

  const handleAddAboveClick = async (
    id: string,
    questionGroupId?: string,
    answerFormatType = defaultAnswerFormatType
  ) => {
    return addNewQuestionAndNavigateToEdit({
      beforeQuestionId: id,
      questionGroupId,
      answerFormatType,
      structureType: questionGroupId ? StructureType.SubQuestion : undefined,
      difficulty: Difficulty.Medium,
    });
  };

  // XXX: better to organize all the 'add question method' to make it clear after we migrate 'review-questions' to new ia table
  const addAboveOrBelowQuestion = async (
    answerFormatType: AnswerFormatType,
    structureType?: StructureType,
    anchorQuestion?: AnchorQuestion
  ) => {
    if (!anchorQuestion) {
      return addNewQuestionAndNavigateToEdit({
        answerFormatType,
        structureType,
        difficulty: Difficulty.Medium,
      });
    }

    const { id, questionGroupId, addAboveOrBelow } = anchorQuestion;
    if (addAboveOrBelow === 'above') {
      return addNewQuestionAndNavigateToEdit({
        beforeQuestionId: id,
        questionGroupId,
        answerFormatType,
        structureType,
        difficulty: Difficulty.Medium,
      });
    }
    if (addAboveOrBelow === 'below') {
      return addNewQuestionAndNavigateToEdit({
        afterQuestionId: id,
        questionGroupId,
        answerFormatType,
        structureType,
        difficulty: Difficulty.Medium,
      });
    }
  };

  const handleAddNewQuestionClick = async (
    answerFormatType: AnswerFormatType,
    anchorQuestion?: AnchorQuestion
  ) => {
    const structureType = anchorQuestion?.questionGroupId
      ? StructureType.SubQuestion
      : answerFormatType === AnswerFormatType.QuestionGroup
      ? StructureType.QuestionGroup
      : StructureType.SingleQuestion;

    void addAboveOrBelowQuestion(
      answerFormatType,
      structureType,
      anchorQuestion
    );
  };

  const handleAddNewQuestionGroupClick = async (
    anchorQuestion?: AnchorQuestion
  ) => {
    if (!sectionId) {
      console.warn('sectionId is not available');
      return;
    }

    const res = await addAboveOrBelowQuestion(
      AnswerFormatType.QuestionGroup,
      StructureType.QuestionGroup,
      anchorQuestion
    );

    if (!res) {
      return;
    }

    const [subQuestionRes] = await callWithToast(() =>
      apis.editor.newCreatorQuestion({
        sectionId,
        structureType: StructureType.SubQuestion,
        questionGroupId: res.data.id,
      })
    );

    if (subQuestionRes) {
      void reloadQuestions();
    }
  };

  return {
    handleCreateNewClick,
    handleAddNewQuestionClick,
    handleAddNewQuestionGroupClick,
    handleAddBelowClick,
    handleAddAboveClick,
  };
};
