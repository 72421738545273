import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLatestValueRef, useUploadFile } from '@front/helper';
import { toast } from '@front/ui';

import { useIaAction } from '../../core/IaAction/useIaAction';

import { UploadImageConfig } from './types';
import { getImageUploadUrl } from './utils';

const SUPPORTED_IMAGE_TYPES = ['image/png', 'image/jpeg'];
const FILE_INPUT_ACCEPT = SUPPORTED_IMAGE_TYPES.join(',');
const FILE_SIZE_LIMIT_MB = 25;
const SUPPORTED_IMAGE_TYPES_MESSAGE = 'png, jpeg';

export type ImageUploaderHandler = {
  handleFileUpload: (file: File) => void;
  openFileSelector: () => void;
};

export type ImageUploaderProps = {
  config: UploadImageConfig;
  onImageKeyChange?: (newValue: string) => void;
  onFileChange?: () => void;
  onPreviewUrlChange?: (newValue: string) => void;
  onProgressChange?: (newValue: number | null) => void;
};

const ImageUploader = forwardRef<ImageUploaderHandler, ImageUploaderProps>(
  (
    {
      config,
      onImageKeyChange,
      onFileChange,
      onPreviewUrlChange,
      onProgressChange,
    },
    ref
  ) => {
    const { t } = useTranslation('club');
    const { getIaAction } = useIaAction();
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const { handleFileChange, handleFileUpload, progress } = useUploadFile({
      getUploadKeyAndUrl: async (file: File) => {
        const ext = file.name.split('.').pop()?.toLowerCase() || '';
        const { data } = await getImageUploadUrl({
          targetUrl: config.targetUrl,
          ext,
          resourceType: config.uploadResourceType || 'aha-image',
          scope: config.uploadScope || 'club',
          sub: config.uploadSub,
        });
        return data;
      },
      onFileChange: ({ previewUrl: url }) => {
        getIaAction<string>(
          config.uploadActionMap?.previewUrlChanged?.value
        )?.action(url);
        onPreviewUrlChange?.(url);
        onFileChange?.();
      },
      onSuccess: (key) => {
        onImageKeyChange?.(key);
        getIaAction<string>(
          config.uploadActionMap?.imageKeyChanged?.value
        )?.action(key);
      },
      onFail: (err) => {
        getIaAction<string>(
          config.uploadActionMap?.previewUrlChanged?.value
        )?.action(config.previewUrl || '');

        toast.error(t('Fail to upload image'), {
          anchorEl: document.querySelector(
            '[data-testid="bottom-cta-button"]'
          ) as Element,
        });
        console.warn(err);

        if (fileInputRef?.current) fileInputRef.current.value = '';

        onPreviewUrlChange?.(config.previewUrl || '');
        onImageKeyChange?.('');
      },
    });

    const validateFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0] as File | undefined;

        if (!file) return;

        if (!SUPPORTED_IMAGE_TYPES.includes(file.type)) {
          getIaAction<string>(
            config.uploadActionMap?.checkTypesFailed?.value
          )?.action(SUPPORTED_IMAGE_TYPES_MESSAGE);
          return;
        }

        const fileSize = file.size / 1024 / 1024;

        if (fileSize > FILE_SIZE_LIMIT_MB) {
          getIaAction<number>(
            config.uploadActionMap?.checkSizeFailed?.value
          )?.action(FILE_SIZE_LIMIT_MB);
          return;
        }
        handleFileChange(e);
      }
    };

    const onProgressChangeRef = useLatestValueRef(onProgressChange);

    useEffect(() => {
      onProgressChangeRef.current?.(progress);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress]);

    useImperativeHandle(
      ref,
      () => ({
        handleFileUpload,
        openFileSelector: () => fileInputRef.current?.click(),
      }),
      [handleFileUpload]
    );

    return (
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        onChange={validateFileChange}
        disabled={config.disabled}
        accept={FILE_INPUT_ACCEPT}
      />
    );
  }
);

ImageUploader.displayName = 'ImageUploader';

export default ImageUploader;
