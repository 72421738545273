import { createContext, ReactNode } from 'react';
import {
  apis,
  useCreatorQuestion,
  useCreatorQuestionAnalytics,
  useCreatorQuestionAnalyticsForReview,
  useCreatorQuestionCorrectAnswer,
  useCreatorQuestionCorrectAnswerForReview,
  useCreatorQuestionDetail,
  useCreatorQuestionDetailForReview,
  useCreatorQuestionForReview,
  useCreatorQuestionHashtags,
  useCreatorQuestionHashtagsForReview,
  useCreatorQuestionLinkedVariable,
  useCreatorQuestionLinkedVariableForReview,
  useCreatorQuestionVariable,
  useCreatorQuestionVariableForReview,
} from '@lib/web/apis';

const createQuestionContextValue = {
  useDetail: useCreatorQuestionDetail,
  useCorrectAnswer: useCreatorQuestionCorrectAnswer,
  useCreatorQuestion: useCreatorQuestion,
  useAnalytics: useCreatorQuestionAnalytics,
  useHashtags: useCreatorQuestionHashtags,
  addCreatorQuestionHashtags: apis.editor.addCreatorQuestionHashtags,
  updateCreatorQuestionDetail: apis.editor.updateCreatorQuestionDetail,
  removeCreatorQuestionHashtags: apis.editor.removeCreatorQuestionHashtags,
  getCreatorQuestionDetail: apis.editor.getCreatorQuestionDetail,
  setCorrectAnswer: apis.editor.setCreatorQuestionCorrectAnswer,
  updateCreatorQuestion: apis.editor.updateCreatorQuestion,
  deleteCreatorQuestion: apis.editor.deleteCreatorQuestion,
  useCreatorQuestionVariable,
  useCreatorQuestionLinkedVariable,
  addCreatorQuestionVariable: apis.editor.addCreatorQuestionVariable,
  duplicateCreatorQuestionVariable:
    apis.editor.duplicateCreatorQuestionVariable,
  updateCreatorQuestionVariable: apis.editor.updateCreatorQuestionVariable,
  deleteCreatorQuestionVariable: apis.editor.deleteCreatorQuestionVariable,
  getLinkedCreatorQuestionVariable:
    apis.editor.getLinkedCreatorQuestionVariable,
  linkCreatorQuestionVariable: apis.editor.linkCreatorQuestionVariable,
  unlinkCreatorQuestionVariable: apis.editor.unlinkCreatorQuestionVariable,
  getCreatorQuestion: apis.editor.getCreatorQuestion,
  getCreatorQuestionCorrectAnswer: apis.editor.getCreatorQuestionCorrectAnswer,
  publishCreatorQuestion: apis.editor.publishCreatorQuestion,
  getCreatorQuestionGroupItem: apis.editor.getCreatorQuestionGroupItem,
  addCreatorQuestionComponent: apis.editor.addCreatorQuestionComponent,
  removeCreatorQuestionComponent: apis.editor.removeCreatorQuestionComponent,
};

const reviewQuestionContextValue = {
  useDetail: useCreatorQuestionDetailForReview,
  useCorrectAnswer: useCreatorQuestionCorrectAnswerForReview,
  useCreatorQuestion: useCreatorQuestionForReview,
  useAnalytics: useCreatorQuestionAnalyticsForReview,
  useHashtags: useCreatorQuestionHashtagsForReview,
  addCreatorQuestionHashtags: apis.editor.addCreatorQuestionHashtagsForReview,
  removeCreatorQuestionHashtags:
    apis.editor.removeCreatorQuestionHashtagsForReview,
  updateCreatorQuestionDetail: apis.editor.updateCreatorQuestionDetailForReview,
  getCreatorQuestionDetail: apis.editor.getCreatorQuestionDetailForReview,
  setCorrectAnswer: apis.editor.setCreatorQuestionCorrectAnswerForReview,
  useCreatorQuestionVariable: useCreatorQuestionVariableForReview,
  useCreatorQuestionLinkedVariable: useCreatorQuestionLinkedVariableForReview,
  updateCreatorQuestion: apis.editor.updateCreatorQuestionForReview,
  deleteCreatorQuestion: apis.editor.deleteCreatorQuestionForReview,
  addCreatorQuestionVariable: apis.editor.addCreatorQuestionVariableForReview,
  duplicateCreatorQuestionVariable:
    apis.editor.duplicateCreatorQuestionVariableForReview,
  updateCreatorQuestionVariable:
    apis.editor.updateCreatorQuestionVariableForReview,
  deleteCreatorQuestionVariable:
    apis.editor.deleteCreatorQuestionVariableForReview,
  getLinkedCreatorQuestionVariable:
    apis.editor.getLinkedCreatorQuestionVariableForReview,
  linkCreatorQuestionVariable: apis.editor.linkCreatorQuestionVariableForReview,
  unlinkCreatorQuestionVariable:
    apis.editor.unlinkCreatorQuestionVariableForReview,
  getCreatorQuestion: apis.editor.getCreatorQuestionForReview,
  getCreatorQuestionCorrectAnswer:
    apis.editor.getCreatorQuestionCorrectAnswerForReview,
  publishCreatorQuestion: apis.editor.publishCreatorQuestionForReview,
  getCreatorQuestionGroupItem: apis.editor.getCreatorQuestionGroupItemForReview,
  addCreatorQuestionComponent: apis.editor.addCreatorQuestionComponentForReview,
  removeCreatorQuestionComponent:
    apis.editor.removeCreatorQuestionComponentForReview,
};

type CreatorQuestionDetailContextValue =
  | typeof createQuestionContextValue
  | typeof reviewQuestionContextValue;

const defaultValue = createQuestionContextValue;

export const CreatorQuestionDetailContext =
  createContext<CreatorQuestionDetailContextValue>(defaultValue);

type CreatorQuestionDetailProviderProps = {
  children: ReactNode;
  pointOfView: 'createQuestion' | 'reviewQuestion';
};

export function CreatorQuestionDetailProvider({
  pointOfView,
  children,
}: CreatorQuestionDetailProviderProps) {
  return (
    <CreatorQuestionDetailContext.Provider
      value={
        pointOfView === 'createQuestion'
          ? createQuestionContextValue
          : reviewQuestionContextValue
      }
    >
      {children}
    </CreatorQuestionDetailContext.Provider>
  );
}
