import api from './client';

const phoneApi = {
  sendVerifyCode: (params: SendVerifyCodeReq) =>
    api.post('v2/phone/verify/send', params),
  checkVerifyCode: (params: CheckVerifyCodeReq) =>
    api.post('v2/phone/verify/check-code', params),
};

export default phoneApi;
