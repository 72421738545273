import React, { ReactNode, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ActionChevronFilledDown as ActionChevronFilledDownIcon } from '@front/icon';

export type LeftMenuItem = {
  id?: string | number;
  display: string;
  disabled?: boolean;
  icon?: ReactNode;
  items?: LeftMenuItem[];
};

export type LeftMenuProps = Omit<BoxProps, 'children' | 'onClick'> & {
  items: LeftMenuItem[];
  onClick?: (item: LeftMenuItem) => void;
  checkItemSelected: (item: LeftMenuItem) => boolean;
};

export type LeftMenuItemButtonProps = Omit<BoxProps, 'children' | 'onClick'> & {
  item: LeftMenuItem;
  onClick: (item: LeftMenuItem) => void;
  checkItemSelected: (item: LeftMenuItem) => boolean;
};

type LeftMenuButtonProps = ButtonBaseProps & {
  display: string;
  selected?: boolean;
  icon?: ReactNode;
  toggleIcon?: ReactNode;
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    px: 2.5,
    height: 44,
    justifyContent: 'flex-start',
    typography: 'subtitle2',
    fontWeight: 400,
    '& > .left-menu-content': {
      opacity: 0.5,
      display: 'flex',
      gap: '10px',
      width: '100%',
      flex: 1,
    },
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.3),
        '& > .left-menu-content': {
          opacity: 1,
        },
      },
    },
    '&:disabled': {
      '& > .left-menu-content': {
        opacity: 0.25,
      },
    },
  },
  subButton: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
  selectedSubButton: {
    color: 'background.darker',
    backgroundColor: 'text.primary',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        color: 'text.primary',
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
  },
  groupItems: {
    display: 'flex',
    flexDirection: 'column',
  },
  selected: {
    '& > .left-menu-content': {
      opacity: 1,
    },
  },
  toggleIcon: {
    ml: 'auto',
    width: 24,
    height: 24,
    transitionDuration: '0.3s',
  },
  activeToggleIcon: {
    transform: 'rotate(-180deg)',
  },
};

function LeftMenuButton({
  sx,
  icon,
  toggleIcon,
  selected = false,
  display,
  ...rest
}: LeftMenuButtonProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <ButtonBase
      sx={[styles.button, selected && styles.selected, ...sxProps]}
      {...rest}
    >
      <div className="left-menu-content">
        {icon && <Box sx={styles.icon}>{icon}</Box>}
        <Typography variant="subtitle2" fontWeight={400}>
          {display}
        </Typography>
        {toggleIcon}
      </div>
    </ButtonBase>
  );
}
function LeftMenuItemButton({
  sx,
  item,
  checkItemSelected,
  onClick,
}: LeftMenuItemButtonProps) {
  if (item.items) {
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <LeftMenuItemButtonGroup
        item={item}
        onClick={onClick}
        checkItemSelected={checkItemSelected}
      />
    );
  }
  const selected = checkItemSelected(item);

  return (
    <LeftMenuButton
      sx={sx}
      icon={item.icon}
      display={item.display}
      disabled={item.disabled}
      selected={selected}
      onClick={() => onClick(item)}
    />
  );
}

function LeftMenuItemButtonGroup({
  item,
  onClick,
  checkItemSelected,
}: LeftMenuItemButtonProps) {
  const selected = checkItemSelected(item);
  const [open, setOpen] = useState(selected || false);
  if (!item.items) {
    return null;
  }
  return (
    <Box sx={styles.group}>
      <LeftMenuButton
        sx={[open && styles.selected]}
        icon={item.icon}
        display={item.display}
        disabled={item.disabled}
        toggleIcon={
          <Box sx={[styles.toggleIcon, open && styles.activeToggleIcon]}>
            <ActionChevronFilledDownIcon />
          </Box>
        }
        onClick={() => setOpen((st) => !st)}
      />
      <Collapse in={open}>
        <Box sx={styles.groupItems}>
          {item.items.map((subItem, i) => {
            const subSelected = checkItemSelected(subItem);
            return (
              <LeftMenuButton
                key={subItem.id || i}
                sx={[styles.subButton, subSelected && styles.selectedSubButton]}
                icon={subItem.icon}
                display={subItem.display}
                disabled={subItem.disabled}
                selected={subSelected}
                onClick={() => onClick(subItem)}
              />
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
}

export default function LeftMenu({
  sx,
  items,
  onClick,
  checkItemSelected,
  ...rest
}: LeftMenuProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const handleClick = (item: LeftMenuItem) => {
    onClick?.(item);
  };
  return (
    <Box sx={[styles.root, ...sxProps]} {...rest}>
      {items.map((item, i) => (
        <LeftMenuItemButton
          key={item.id || i}
          item={item}
          onClick={handleClick}
          checkItemSelected={checkItemSelected}
        />
      ))}
    </Box>
  );
}
