import combineSearch from '@lib/ia/src/utils/combineSearch';
import { ClubGroupViewSlug, useIaClubGroups } from '@lib/web/apis';

export default function useIaClubGroup(clubGroupId: string) {
  const { dataset, mutate } = useIaClubGroups(
    {
      viewSlug: ClubGroupViewSlug.CardCenterClubGroupDefault,
      ...combineSearch([`id:${clubGroupId}`, 'id:eq']),
    },
    {
      enable: !!clubGroupId,
    }
  );

  return {
    clubGroup: dataset.length > 0 ? dataset[0] : null,
    mutate,
  };
}
