import React from 'react';

export default function OtherAddFriend({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.7273 12.5C16.7364 12.5 18.3636 10.8219 18.3636 8.75C18.3636 6.67812 16.7364 5 14.7273 5C12.7182 5 11.0909 6.67812 11.0909 8.75C11.0909 10.8219 12.7182 12.5 14.7273 12.5ZM6.54545 10.625V7.8125H4.72727V10.625H2V12.5H4.72727V15.3125H6.54545V12.5H9.27273V10.625H6.54545ZM14.7273 14.375C12.3 14.375 7.45455 15.6313 7.45455 18.125V20H22V18.125C22 15.6313 17.1545 14.375 14.7273 14.375Z"
        fill="currentColor"
      />
    </svg>
  );
}
