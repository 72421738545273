import React from 'react';
import { alpha, Divider, Theme } from '@mui/material';

const styles = {
  root: {
    typography: 'caption',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    width: '100%',

    '::before': {
      width: '100%',
      borderTop: (theme: Theme) =>
        `thin solid ${alpha(theme.palette.text.primary, 0.1)}`,
    },

    px: 2.5,
    '.base-layout-right-panel &': {
      px: 1.5,
    },
    py: 0.5,
  },
};

export type DateDividerProps = {
  time?: string;
};

// XXX: this component might look like DividerItem, we might be able to consolidate these two components when apply notification layout
export default function DateDivider({ time }: DateDividerProps) {
  return (
    <Divider sx={styles.root} textAlign="center">
      {time}
    </Divider>
  );
}
