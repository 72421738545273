import { useMemo } from 'react';
import { Theme } from '@mui/material';
import { alpha, SxProps } from '@mui/system';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { IconListLayoutItem } from '@lib/ia/src/layouts/IconListLayout/types';
import { useClubSlug } from '@lib/web/hooks';
import { getAgentIconPath, getIndicators } from '@lib/web/utils';

import { useAskForHelpMembers } from './useAskForHelpMembers';

const agentApiDataToIconListLayoutItem = (agent: {
  agentId: string;
  agentName: string;
  agentUserName: string;
  agentPhoto: string;
}): IconListLayoutItem => ({
  id: `agent_${agent.agentId}`,
  title: agent.agentName,
  description: `@${agent.agentUserName}`,
  avatarUrl: getAgentIconPath(agent),
  indicators: [
    {
      icon: 'ProfileClubAgent',
      name: 'AI',
      sx: {
        display: 'flex',
        alignItems: 'center',
        color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
      } as SxProps,
    },
  ],
  suffix: {
    type: 'icon' as const,
    name: 'ThreadsDirectMessages',
  },
  actionMap: {
    select: {
      value: 'selectChanged',
      hideCheckbox: true,
    },
  },
});

const userApiDataToIconListLayoutItem = (user: {
  memberId: string;
  displayName?: string;
  distinctName: string;
  nftAvatar?: string;
  avatar?: string;
  indicator?: any;
}): IconListLayoutItem => ({
  id: user.memberId,
  title: user.displayName || user.distinctName,
  description: `@${user.distinctName}`,
  avatarUrl: user.nftAvatar || user.avatar,
  indicators: getIndicators(user.indicator),
  suffix: {
    type: 'icon' as const,
    name: 'ThreadsDirectMessages',
  },
  actionMap: {
    select: {
      value: 'selectChanged',
      hideCheckbox: true,
    },
  },
});

export default function useSuggestionMembersLayoutConfig(): IaLayoutConfig[] {
  const clubSlug = useClubSlug();
  const { clubAgents, suggestionMembers, helpBot } = useAskForHelpMembers({
    clubSlug,
  });

  return useMemo(() => {
    const helpBotItems = helpBot
      ? [
          agentApiDataToIconListLayoutItem({
            agentId: helpBot.id,
            agentName: helpBot.displayName,
            agentUserName: helpBot.userName,
            agentPhoto: helpBot.photoPath,
          }),
        ]
      : [];
    const agentItems = clubAgents.map(agentApiDataToIconListLayoutItem);
    const userItems = suggestionMembers.map(userApiDataToIconListLayoutItem);

    return [
      {
        layout: 'icon-list-layout',
        items: [...helpBotItems, ...agentItems, ...userItems],
      },
    ];
  }, [clubAgents, helpBot, suggestionMembers]);
}
