import React from 'react';

export default function EditorParaphrase({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 7.2C3 6.53726 3.50368 6 4.125 6H19.875C20.4963 6 21 6.53726 21 7.2C21 7.86274 20.4963 8.4 19.875 8.4H4.125C3.50368 8.4 3 7.86274 3 7.2ZM3 12C3 11.3373 3.50368 10.8 4.125 10.8H19.875C20.4963 10.8 21 11.3373 21 12C21 12.6627 20.4963 13.2 19.875 13.2H4.125C3.50368 13.2 3 12.6627 3 12ZM3 16.8C3 16.1373 3.50368 15.6 4.125 15.6H10.875C11.4963 15.6 12 16.1373 12 16.8C12 17.4627 11.4963 18 10.875 18H4.125C3.50368 18 3 17.4627 3 16.8Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
