import {
  format,
  formatDistanceToNow as dateFnsFormatDistanceToNow,
  startOfDay,
} from 'date-fns';

/**
 * provide our own wrapper here, make it easier to support locale in the future
 */
export const formatDistanceToNow = (
  date: Date,
  { addSuffix = true }: { addSuffix?: boolean } = {}
) => {
  return dateFnsFormatDistanceToNow(date, { addSuffix });
};

/**
 * provides swr hooks for date queries (for the same keys to avoid building new queries)
 */
export const formatDateString = (date: Date) =>
  format(startOfDay(date), 'yyyy-MM-dd');

export const getTimeZoneOffset = () => {
  const offset = new Date().getTimezoneOffset();
  const offsetHours = offset / 60;
  return `${offsetHours > 0 ? '-' : '+'}${Math.abs(offset / 60)
    .toString()
    .padStart(2, '0')}:00`;
};
