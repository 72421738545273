import {
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Popover,
  SxProps,
  TextField,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { Scrollbar } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import {
  DisplayTableLayoutRow,
  DisplayTableLayoutTextBasedEditableCell,
  IaCellValueChangedEvent,
} from '@lib/ia/src/layouts/DisplayTableLayout/types';

const styles = {
  scroll: {
    bgcolor: '#151A28',
    borderRadius: 1,
  },
  input: {
    '& .MuiInputBase-input': {
      typography: 'body2',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
    },
    px: 1,
    py: '5.5px',
    width: '100%',
    minHeight: '32px',
    bgcolor: '#151A28',
    borderRadius: 1,
  },
};

const MOBILE_WIDTH = 247;

type IaTextBasedEditableCellProps<V = string> =
  DisplayTableLayoutTextBasedEditableCell & {
    initValue?: string | number | null;
    containerRef: MutableRefObject<HTMLElement | undefined>;
    row: DisplayTableLayoutRow;
    inputSx?: SxProps;

    /**
     * Use to filter the raw input value before passing through the changeValue event
     */
    filterValue: (val: string) => V;
  };

export default function IaTextBasedEditableCell<V = string>({
  editable,
  actionMap,
  placeholder,
  initValue,
  containerRef,
  row,
  inputSx,
  filterValue,
}: IaTextBasedEditableCellProps<V>) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [inputValue, setInputValue] = useState(initValue || '');
  const [editOpen, setEditOpen] = useState(false);
  const scrollRef = useRef<HTMLDivElement>();

  const { getIaAction } = useIaAction();

  useEffect(() => {
    if (!containerRef.current) return;

    const containerElem = containerRef.current;

    const handleContainerClick = () => setEditOpen(true);

    containerElem.addEventListener('click', handleContainerClick);
    return () => {
      containerElem.removeEventListener('click', handleContainerClick);
    };
  }, [containerRef]);

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handlePopoverClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const handleValueChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (!actionMap || !editable) return;
    if (!actionMap?.valueChange) return;
    const action = getIaAction<IaCellValueChangedEvent<V>>(
      actionMap.valueChange
    );
    const value = filterValue(newValue);
    action?.action({ value, row });
  };

  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    // reset input field value
    setInputValue(initValue || '');

    // set the cursor to the end
    e.currentTarget.setSelectionRange(
      e.currentTarget.value.length,
      e.currentTarget.value.length
    );

    // scroll to the bottom
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    });
  };

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    // reset input field value
    setInputValue(initValue || '');

    const newValue = e.target.value;
    if (!actionMap || !editable) return;
    if (!actionMap?.inputBlur) return;

    const action = getIaAction<IaCellValueChangedEvent<V>>(actionMap.inputBlur);
    const value = filterValue(newValue);
    action?.action({ value, row });
  };

  const width = mdUp
    ? containerRef.current?.clientWidth
    : Math.min(MOBILE_WIDTH, containerRef.current?.clientWidth || 0);

  const inputSxProps = Array.isArray(inputSx) ? inputSx : [inputSx];

  return (
    <Popover
      open={editOpen}
      onClose={handleEditClose}
      anchorEl={containerRef.current}
      onClick={handlePopoverClick}
      transitionDuration={0}
      marginThreshold={0} // set threshold to 0 for the case editable cell in rhs, otherwise the popover will be shifted
    >
      <Scrollbar
        sx={[
          {
            maxHeight: 230,
            width,
          },
          styles.scroll,
        ]}
        scrollableNodeProps={{
          ref: scrollRef,
        }}
      >
        <TextField
          autoFocus
          sx={[styles.input, ...inputSxProps]}
          value={inputValue}
          multiline
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          onFocus={handleFocus}
          onChange={handleValueChange}
          onBlur={handleBlur}
          placeholder={placeholder}
        />
      </Scrollbar>
    </Popover>
  );
}
