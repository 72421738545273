import { Box, CircularProgress, Typography } from '@mui/material';
import { gradientTextColor } from '@app/web/src/utils/style';

interface CircularProgressProps {
  message?: string;
  /**
   * @default 0.9
   */
  opacity?: number;
}
function CircleLoading({
  message,
  opacity = 0.9,
  ...rest
}: CircularProgressProps) {
  return (
    <Box
      sx={(theme) => ({
        position: 'fixed',
        zIndex: theme.zIndex.tooltip,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        '& .MuiTypography-root': {
          position: 'relative',
          ...gradientTextColor(theme.palette.gradient.primary),
        },
      })}
      {...rest}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'background.paper',
          opacity,
        }}
      />
      <CircularProgress
        sx={{
          '& circle': {
            stroke: 'url(#primary-gradient-linear)',
            strokeLinecap: 'round',
          },
        }}
      />
      {!!message && (
        <Typography variant="subtitle1" data-transform="capitalize">
          {message}
        </Typography>
      )}
    </Box>
  );
}

export default CircleLoading;
