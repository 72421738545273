import React from 'react';

export default function PropertyTypeTag({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M3.36893 12.1853L11.1851 4.36913C11.3016 4.25169 11.4403 4.15858 11.5931 4.09521C11.7459 4.03184 11.9098 3.99948 12.0752 4.00001H19.3704C19.5373 4.00001 19.6975 4.06634 19.8156 4.18442C19.9337 4.3025 20 4.46265 20 4.62964V11.9248C20.0005 12.0902 19.9682 12.2541 19.9048 12.4069C19.8414 12.5597 19.7483 12.6984 19.6309 12.8149L11.8147 20.6311C11.6978 20.748 11.5589 20.8408 11.4061 20.9041C11.2533 20.9674 11.0896 21 10.9242 21C10.7588 21 10.595 20.9674 10.4422 20.9041C10.2894 20.8408 10.1506 20.748 10.0336 20.6311L3.36893 13.9664C3.25197 13.8494 3.15919 13.7106 3.09588 13.5578C3.03258 13.405 3 13.2412 3 13.0758C3 12.9104 3.03258 12.7467 3.09588 12.5939C3.15919 12.4411 3.25197 12.3022 3.36893 12.1853ZM15.9074 9.0371C16.0942 9.0371 16.2768 8.98171 16.4321 8.87793C16.5874 8.77415 16.7084 8.62665 16.7799 8.45407C16.8514 8.28149 16.8701 8.0916 16.8337 7.90839C16.7972 7.72518 16.7073 7.5569 16.5752 7.42481C16.4431 7.29273 16.2748 7.20278 16.0916 7.16634C15.9084 7.12989 15.7185 7.1486 15.5459 7.22008C15.3734 7.29156 15.2258 7.41262 15.1221 7.56793C15.0183 7.72325 14.9629 7.90585 14.9629 8.09264C14.9629 8.34313 15.0624 8.58335 15.2395 8.76047C15.4166 8.93759 15.6569 9.0371 15.9074 9.0371Z"
        fill="currentColor"
      />
    </svg>
  );
}
