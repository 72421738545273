import React, { ReactNode } from 'react';
import { Theme } from '@mui/material/styles';
import { ThemeProvider as CustomThemeProvider } from '@front/ui';

type ThemeProviderProps = {
  theme: Theme;
  children: ReactNode;
};

export default function ThemeProvider({ theme, children }: ThemeProviderProps) {
  return <CustomThemeProvider theme={theme}>{children}</CustomThemeProvider>;
}
