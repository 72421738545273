import MultipleChoices from './components/MultipleChoices';
import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export type EditorSolutionMultipleChoiceProps = {
  questionId: string;
  readonly?: boolean;
};

export default function EditorSolutionMultipleChoice({
  questionId,
  readonly,
}: EditorSolutionMultipleChoiceProps) {
  const { solutionValues, answerSolutionComponents } = useData(questionId);
  const { handleBlocksChange } = useEventHandle(questionId);

  return (
    <MultipleChoices
      questionId={questionId}
      solutionValues={solutionValues}
      readonly={readonly}
      handleBlocksChange={(id, blocks) => {
        const component = answerSolutionComponents.find((c) => c.id === id);
        if (component) {
          void handleBlocksChange(component, blocks);
        }
      }}
    />
  );
}
