import React from 'react';

export default function OtherStudent({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.1616 6.15595C15.1616 7.9021 13.7372 9.31188 11.9902 9.30693C10.2481 9.30199 8.83356 7.88726 8.83853 6.151C8.83853 4.40485 10.258 2.99507 12.01 3.00001C13.7471 3.00496 15.1666 4.41969 15.1616 6.15595Z"
        fill="currentColor"
      />
      <path
        d="M10.6358 21.7008C10.8921 21.7993 11.148 21.8976 11.4039 21.995C11.4138 21.995 11.4287 21.995 11.4387 21.9851C11.4387 19.2447 11.4387 16.4994 11.4436 13.754C11.4436 13.6056 11.399 13.5413 11.26 13.4918C10.3865 13.1752 9.5179 12.8488 8.64935 12.5223C8.38466 12.4234 8.11998 12.3239 7.85529 12.2244C7.32587 12.0254 6.79644 11.8265 6.26701 11.6319C5.90966 11.5033 5.53246 11.6863 5.50268 12.0425C5.47822 12.3525 5.48329 12.665 5.48843 12.9817C5.49059 13.1147 5.49276 13.2484 5.49276 13.383H5.65158C6.53503 13.3978 7.24476 13.9914 7.32417 14.862C7.37877 15.4804 7.37877 16.1135 7.32417 16.7319C7.25469 17.5134 6.674 18.0674 5.88981 18.1911C5.80123 18.2042 5.71264 18.2086 5.62549 18.213C5.58086 18.2152 5.53644 18.2174 5.49276 18.2208C5.49052 18.2342 5.48828 18.2445 5.4865 18.2528C5.48433 18.2628 5.48283 18.2698 5.48283 18.2752V19.2744C5.48283 19.6503 5.59202 19.8086 5.94937 19.9422L9.56754 21.2926C9.92499 21.4277 10.2808 21.5644 10.6358 21.7008Z"
        fill="currentColor"
      />
      <path
        d="M12.5557 21.9935V21.9951C12.5706 21.9951 12.5805 21.9951 12.5954 22C12.6301 21.9802 12.6599 21.9654 12.6946 21.9505C13.1513 21.7799 13.6091 21.608 14.067 21.4361C14.5248 21.2642 14.9827 21.0923 15.4393 20.9216C16.3178 20.5902 17.1963 20.2588 18.0797 19.9323C18.308 19.8482 18.4867 19.7048 18.5016 19.4624C18.5154 19.1762 18.5124 18.89 18.5093 18.5971C18.5079 18.4699 18.5065 18.3412 18.5065 18.2109H18.3279C17.4593 18.1961 16.7496 17.5975 16.6702 16.7368C16.6156 16.1185 16.6205 15.4853 16.6702 14.867C16.7248 14.1448 17.2608 13.5858 17.9556 13.4325C18.0832 13.4042 18.2133 13.3936 18.3477 13.3827C18.4015 13.3783 18.4562 13.3738 18.5115 13.3682V12.2552C18.5065 11.716 18.1641 11.4786 17.6578 11.6665C17.2678 11.8122 16.8776 11.958 16.4871 12.104L16.484 12.1052C15.2308 12.5737 13.9749 13.0431 12.7195 13.5067C12.5805 13.5561 12.5507 13.6303 12.5507 13.7639C12.5557 16.5038 12.5557 19.2487 12.5557 21.9935Z"
        fill="currentColor"
      />
      <path
        d="M14.3193 11.7253C14.9839 11.4781 15.6484 11.231 16.3477 10.969C15.7719 10.4546 15.1515 10.0787 14.4815 9.79175C14.3723 9.74723 14.2929 9.75713 14.1887 9.81648C12.7295 10.6327 11.2703 10.6277 9.80619 9.81154C9.7367 9.77197 9.62255 9.76207 9.55307 9.79175C8.89792 10.044 8.31227 10.4051 7.78121 10.8602C7.74865 10.888 7.72046 10.9202 7.68846 10.9567L7.68194 10.9641C7.69985 10.9717 7.7138 10.9794 7.72652 10.9864C7.73846 10.9929 7.74933 10.9989 7.76135 11.0037C9.13119 11.5181 10.506 12.0326 11.8808 12.5371C11.9652 12.5668 12.0793 12.5569 12.1637 12.5272C12.6194 12.3612 13.0732 12.1912 13.5274 12.021C13.7904 11.9225 14.054 11.8238 14.3177 11.7259L14.3193 11.7253Z"
        fill="currentColor"
      />
      <path
        d="M6.22754 15.807C6.22754 15.8843 6.22809 15.9622 6.22864 16.0402C6.22975 16.1968 6.23086 16.3542 6.22754 16.5094C6.22258 16.8606 5.99427 17.0882 5.64189 17.0931L5.61417 17.0935C5.30082 17.0985 4.98814 17.1034 4.69888 16.9496C4.17278 16.6776 3.89981 16.0889 4.03381 15.4953C4.16286 14.9364 4.6691 14.5209 5.2498 14.5011C5.3838 14.4961 5.52277 14.4961 5.65678 14.5011C5.98435 14.511 6.21762 14.7385 6.22258 15.065C6.23003 15.2505 6.22909 15.436 6.22816 15.6215C6.22785 15.6833 6.22754 15.7451 6.22754 15.807Z"
        fill="currentColor"
      />
      <path
        d="M17.7659 15.5503C17.7665 15.6305 17.767 15.7109 17.767 15.7919V16.529C17.772 16.8554 18.0053 17.083 18.3328 17.0929C18.4668 17.0978 18.5959 17.0978 18.7249 17.0929C19.39 17.0731 19.9409 16.5586 19.9955 15.9007C20.0501 15.2478 19.6034 14.6542 18.9532 14.5305C18.7767 14.4998 18.5924 14.5002 18.4106 14.5007C18.388 14.5008 18.3653 14.5009 18.3428 14.5009C18.0053 14.5009 17.772 14.7333 17.767 15.0697C17.7637 15.2311 17.7648 15.3903 17.7659 15.5503Z"
        fill="currentColor"
      />
    </svg>
  );
}
