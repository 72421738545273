export default function MathLettersSymbols({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.65661 15.1248H14.4186L15.7392 18.7817H18.1401L13.0731 5H11.0486L6 18.7817H8.41415L9.65661 15.1248ZM12.0312 8.32875L10.3978 13.0143H13.6792L12.0312 8.32875Z"
        fill="currentColor"
      />
    </svg>
  );
}
