import React from 'react';

export default function OtherFollowers({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.525 12C18.742 12 17.9905 12.3531 17.5 12.9068C17.0095 12.3531 16.258 12 15.475 12C14.089 12 13 13.0507 13 14.3978C13 16.0414 14.53 17.3886 16.8475 19.4245L17.5 20L18.1525 19.4245C20.47 17.3886 22 16.0414 22 14.3978C22 13.0507 20.911 12 19.525 12Z"
        fill="currentColor"
      />
      <path
        d="M14 8C14 10.21 12.21 12 10 12C7.79 12 6 10.21 6 8C6 5.79 7.79 4 10 4C12.21 4 14 5.79 14 8ZM2 18C2 15.34 7.33 14 10 14C10.3054 14 10.6455 14.0175 11.0085 14.0526C11.0029 14.1332 11 14.2147 11 14.297C11 16.0821 12.2728 17.9248 14.3305 20H2V18Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
