import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import { Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

import ThemeProvider from '../../../components/ThemeProvider';

const Blank = () => {
  return (
    <NodeViewWrapper
      style={{ display: 'inline-block' }}
      className="inline-blank"
    >
      <ThemeProvider mode="dark">
        {/* without using contentEditable: false, the user is able to delete each underscore */}
        <div contentEditable={false}>_____</div>
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export type InlineBlankOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineBlankOptions;
    node: ProseMirrorNode;
  }) => string;
};

export const InlineBlank = Node.create<InlineBlankOptions>({
  name: BasicBlockTypes.InlineBlank,
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  parseHTML() {
    return [
      {
        tag: 'inline-blank',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes, '_____'];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Blank);
  },
});
