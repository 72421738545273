import React from 'react';

export default function ChatPin({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.3673 3L21 10.6327L20.7062 10.9265C20.2771 11.3556 19.7902 11.655 19.2444 11.8257C18.6986 11.9964 18.1206 12.0335 17.5106 11.9413L14.488 14.9639C14.6696 15.4519 14.7771 15.9462 14.8139 16.4438C14.8495 16.9424 14.8207 17.4314 14.7273 17.9108C14.6349 18.3891 14.4682 18.8459 14.2291 19.2769C13.9901 19.71 13.6947 20.1079 13.3419 20.4716L12.8135 21L8.45193 16.6385L5.04681 20.0436L3.44478 20.5552L3.95642 18.9532L7.36155 15.5481L3 11.1865L3.52842 10.6581C3.88669 10.2998 4.28141 10.0076 4.7136 9.77823C5.14583 9.55099 5.60262 9.38425 6.08294 9.2812C7.05983 9.06952 8.08621 9.14972 9.03611 9.51196L12.0587 6.4894C11.9694 5.90828 12.0119 5.3186 12.1831 4.76433C12.3598 4.20823 12.6646 3.70483 13.0735 3.2938L13.3673 3Z"
        fill="currentColor"
      />
    </svg>
  );
}
