export const languageConfig =
  process.env.ENV_CODE === 'prod' || process.env.ENV_CODE === 'gamma'
    ? {
        defaultLng: 'en',
        options: [
          { value: 'en', i18nValue: 'en', name: 'English' },
          { value: 'hi', i18nValue: 'hi', name: 'Hindi' },
        ],
      }
    : {
        defaultLng: 'en',
        options: [
          { value: 'en', i18nValue: 'en', name: 'English' },
          {
            value: 'zh-tw',
            i18nValue: 'zh-hant',
            name: '繁體中文',
          },
          { value: 'hi', i18nValue: 'hi', name: 'Hindi' },
        ],
      };
