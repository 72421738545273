import React, { SVGAttributes } from 'react';

export default function EmojiHamburger({
  ...rest
}: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width="66"
      height="115"
      viewBox="0 0 66 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.13852 61.6346L19.8555 68.4922L19.8558 73.417L3.31733 66.3884L3.13852 61.6346Z"
        fill="#A63735"
      />
      <path
        d="M3.86589 95.2742L19.7598 102.857L19.7601 107.782L3.86627 100.259L3.86589 95.2742Z"
        fill="#613B23"
      />
      <path
        d="M64.0595 45.8048L60.8945 47.7695L56.2022 45.7616L59.3671 43.7969L64.0595 45.8048Z"
        fill="#E3AE36"
      />
      <path
        d="M64.0594 26.1271L60.8984 28.0977L56.2021 26.0983L59.3631 24.1278L64.0594 26.1271Z"
        fill="#914F22"
      />
      <path
        d="M60.9237 18.2053L57.7627 20.1758L53.0663 18.1765L56.2273 16.2059L60.9237 18.2053Z"
        fill="#A26B39"
      />
      <path
        d="M57.7201 15.2393L54.5532 17.2012L37.3963 9.84068L40.5632 7.87885L57.7201 15.2393Z"
        fill="#C59B71"
      />
      <path
        d="M54.5526 17.2022L51.3857 19.1641L46.6954 17.1518L49.8623 15.19L54.5526 17.2022Z"
        fill="#CB863A"
      />
      <path
        d="M51.3926 14.2446L48.2314 16.2148L31.6971 9.17399L34.8583 7.20377L51.3926 14.2446Z"
        fill="#DD9E5D"
      />
      <path
        d="M48.232 16.2134L45.0708 18.1836L28.5116 11.1322L31.6728 9.16194L48.232 16.2134Z"
        fill="#DB9C53"
      />
      <path
        d="M45.0704 18.1841L41.9092 20.1543L25.3862 13.1183L28.5474 11.148L45.0704 18.1841Z"
        fill="#D79D57"
      />
      <path
        d="M41.9097 20.1548L38.7485 22.125L22.2259 15.0891L25.3871 13.1189L41.9097 20.1548Z"
        fill="#BE9970"
      />
      <path
        d="M38.7476 22.1235L35.5864 24.0938L19.0533 17.0534L22.2145 15.0832L38.7476 22.1235Z"
        fill="#DCA760"
      />
      <path
        d="M35.5875 24.0942L32.4263 26.0645L15.8999 19.027L19.0611 17.0568L35.5875 24.0942Z"
        fill="#DDA25C"
      />
      <path
        d="M32.4249 26.0649L29.2637 28.0352L12.7699 21.0116L15.9312 19.0414L32.4249 26.0649Z"
        fill="#D39859"
      />
      <path
        d="M29.4419 32.8482L26.0977 34.9199L9.41836 27.7643L12.7626 25.6926L29.4419 32.8482Z"
        fill="#D28B3C"
      />
      <path
        d="M26.0971 34.921L22.9302 36.8828L6.23208 29.7192L9.39896 27.7573L26.0971 34.921Z"
        fill="#CAA075"
      />
      <path
        d="M19.7816 73.3282L16.6167 75.293L-0.000110385 68.1824L3.16481 66.2177L19.7816 73.3282Z"
        fill="#E3AE36"
      />
      <path
        d="M19.8338 53.6545L16.6729 55.625L0.124029 48.5799L3.28503 46.6094L19.8338 53.6545Z"
        fill="#9B5525"
      />
      <path
        d="M22.9437 41.8033L19.7891 43.7832L3.16065 36.7631L6.31528 34.7832L22.9437 41.8033Z"
        fill="#AF7540"
      />
      <path
        d="M12.8181 20.9493L29.2588 28.0332L29.2592 32.958L12.8181 25.6958L12.8181 20.9493Z"
        fill="#BD8850"
      />
      <path
        d="M6.32188 29.546L22.9531 36.8867L22.952 41.8115L6.32188 34.8941L6.32188 29.546Z"
        fill="#B8854F"
      />
      <path
        d="M3.22315 36.6762L19.7891 43.7969L19.7894 48.7216L3.22315 41.43L3.22315 36.6762Z"
        fill="#946235"
      />
      <path
        d="M3.22316 41.4307L19.7891 48.7148L19.7894 53.6396L3.22316 46.7788L3.22316 41.4307Z"
        fill="#9B5B27"
      />
      <path
        d="M0.20941 48.5612L16.6538 55.5977L16.6542 60.5224L0.20979 53.486L0.20941 48.5612Z"
        fill="#7D451E"
      />
      <path
        d="M0.220086 53.3153L16.6538 60.5234L16.6542 65.4482L0.220466 58.2401L0.220086 53.3153Z"
        fill="#7B441F"
      />
      <path
        d="M0.220085 58.0683L16.6538 65.4316L16.6542 70.3564L0.220465 62.9931L0.220085 58.0683Z"
        fill="#522A13"
      />
      <path
        d="M0.067708 68.1697L16.6216 75.2891L16.618 80.2138L0.064095 73.0945L0.067708 68.1697Z"
        fill="#B4892B"
      />
      <path
        d="M0.195992 82.3929L16.6089 90.041L16.6053 94.9658L0.192379 87.3177L0.195992 82.3929Z"
        fill="#689638"
      />
      <path
        d="M0.128216 72.9242L16.6177 80.207L16.6141 85.1318L0.124603 77.8489L0.128216 72.9242Z"
        fill="#18110E"
      />
      <path
        d="M0.212029 87.015L16.6069 94.959L16.6033 99.8838L0.208415 92.534L0.212029 87.015Z"
        fill="#764824"
      />
      <path
        d="M0.171437 77.7312L16.6128 85.123L16.6092 90.0478L0.167824 82.6559L0.171437 77.7312Z"
        fill="#52732E"
      />
      <path
        d="M0.254168 92.3618L16.603 99.875L16.5994 104.8L0.250555 97.2866L0.254168 92.3618Z"
        fill="#70513F"
      />
      <path
        d="M64.04 70.3931L60.8774 72.3613L60.8771 77.2861L64.0396 75.3179L64.04 70.3931Z"
        fill="#B07F63"
      />
      <path
        d="M64.04 65.4693L60.8774 67.4375L60.8771 72.3623L64.0396 70.3941L64.04 65.4693Z"
        fill="#AA6833"
      />
      <path
        d="M64.0405 60.5435L60.8779 62.5117L60.8775 67.4365L64.0401 65.4683L64.0405 60.5435Z"
        fill="#89C64A"
      />
      <path
        d="M64.041 55.6197L60.8784 57.5879L60.878 62.5127L64.0406 60.5444L64.041 55.6197Z"
        fill="#76A542"
      />
      <path
        d="M64.041 50.6939L60.8784 52.6621L60.878 57.5869L64.0406 55.6187L64.041 50.6939Z"
        fill="#2D1F19"
      />
      <path
        d="M64.042 45.7701L60.8794 47.7383L60.879 52.6631L64.0416 50.6948L64.042 45.7701Z"
        fill="#FDC13B"
      />
      <path
        d="M64.0424 35.9205L60.8799 37.8887L60.8795 42.8134L64.0421 40.8452L64.0424 35.9205Z"
        fill="#954D22"
      />
      <path
        d="M64.0424 30.9947L60.8799 32.9629L60.8795 37.8877L64.0421 35.9194L64.0424 30.9947Z"
        fill="#B2632D"
      />
      <path
        d="M64.0429 26.0708L60.8804 28.0391L60.88 32.9638L64.0426 30.9956L64.0429 26.0708Z"
        fill="#AC5E29"
      />
      <path
        d="M64.04 70.3931L60.8774 72.3613L60.8771 77.2861L64.0396 75.3179L64.04 70.3931Z"
        fill="#B07F63"
      />
      <path
        d="M64.04 65.4693L60.8774 67.4375L60.8771 72.3623L64.0396 70.3941L64.04 65.4693Z"
        fill="#AA6833"
      />
      <path
        d="M64.0405 60.5435L60.8779 62.5117L60.8775 67.4365L64.0401 65.4683L64.0405 60.5435Z"
        fill="#89C64A"
      />
      <path
        d="M64.041 55.6197L60.8784 57.5879L60.878 62.5127L64.0406 60.5444L64.041 55.6197Z"
        fill="#76A542"
      />
      <path
        d="M64.041 50.6939L60.8784 52.6621L60.878 57.5869L64.0406 55.6187L64.041 50.6939Z"
        fill="#2D1F19"
      />
      <path
        d="M64.042 45.7701L60.8794 47.7383L60.879 52.6631L64.0416 50.6948L64.042 45.7701Z"
        fill="#FDC13B"
      />
      <path
        d="M64.0424 35.9205L60.8799 37.8887L60.8795 42.8134L64.0421 40.8452L64.0424 35.9205Z"
        fill="#954D22"
      />
      <path
        d="M64.0424 30.9947L60.8799 32.9629L60.8795 37.8877L64.0421 35.9194L64.0424 30.9947Z"
        fill="#B2632D"
      />
      <path
        d="M64.0429 26.0708L60.8804 28.0391L60.88 32.9638L64.0426 30.9956L64.0429 26.0708Z"
        fill="#AC5E29"
      />
      <path
        d="M60.8769 72.3619L57.7144 74.3301L57.714 79.2549L60.8765 77.2866L60.8769 72.3619Z"
        fill="#BE6E32"
      />
      <path
        d="M60.8769 77.2857L57.7144 79.2539L57.714 84.1787L60.8765 82.2105L60.8769 77.2857Z"
        fill="#9B5E38"
      />
      <path
        d="M57.7148 74.3306L54.5522 76.2988L54.5519 81.2236L57.7144 79.2554L57.7148 74.3306Z"
        fill="#DD863F"
      />
      <path
        d="M57.7138 79.2544L54.5513 81.2227L54.5509 86.1474L57.7135 84.1792L57.7138 79.2544Z"
        fill="#B76930"
      />
      <path
        d="M54.5517 76.2974L51.3892 78.2656L51.3888 83.1904L54.5513 81.2222L54.5517 76.2974Z"
        fill="#EB9447"
      />
      <path
        d="M54.5517 81.2232L51.3892 83.1914L51.3888 88.1162L54.5513 86.148L54.5517 81.2232Z"
        fill="#CC7A3A"
      />
      <path
        d="M51.3896 78.2662L48.2271 80.2344L48.2267 85.1592L51.3892 83.1909L51.3896 78.2662Z"
        fill="#C5C944"
      />
      <path
        d="M51.3886 83.1919L48.2261 85.1602L48.2257 90.0849L51.3883 88.1167L51.3886 83.1919Z"
        fill="#DF8842"
      />
      <path
        d="M48.227 80.2349L45.0645 82.2031L45.0641 87.1279L48.2266 85.1597L48.227 80.2349Z"
        fill="#F8A962"
      />
      <path
        d="M48.2265 85.1587L45.064 87.127L45.0636 92.0517L48.2261 90.0835L48.2265 85.1587Z"
        fill="#EB9953"
      />
      <path
        d="M45.0644 82.2037L41.9019 84.1719L41.9015 89.0967L45.064 87.1284L45.0644 82.2037Z"
        fill="#F9AC69"
      />
      <path
        d="M45.0644 87.1275L41.9019 89.0957L41.9015 94.0205L45.064 92.0523L45.0644 87.1275Z"
        fill="#EDA15F"
      />
      <path
        d="M41.9018 84.1705L38.7393 86.1387L38.7389 91.0634L41.9014 89.0952L41.9018 84.1705Z"
        fill="#B8DC43"
      />
      <path
        d="M41.9013 89.0962L38.7388 91.0645L38.7384 95.9892L41.901 94.021L41.9013 89.0962Z"
        fill="#EDA76D"
      />
      <path
        d="M38.7392 86.1392L35.5767 88.1074L35.5763 93.0322L38.7388 91.064L38.7392 86.1392Z"
        fill="#F9AC69"
      />
      <path
        d="M38.7392 91.063L35.5767 93.0312L35.5763 97.956L38.7388 95.9878L38.7392 91.063Z"
        fill="#EDA15F"
      />
      <path
        d="M35.5766 88.108L32.4141 90.0762L32.4137 95.0009L35.5762 93.0327L35.5766 88.108Z"
        fill="#F8A962"
      />
      <path
        d="M35.5761 93.0318L32.4136 95L32.4132 99.9248L35.5758 97.9566L35.5761 93.0318Z"
        fill="#EB9953"
      />
      <path
        d="M32.414 90.0748L29.2515 92.043L29.2511 96.9677L32.4136 94.9995L32.414 90.0748Z"
        fill="#C5C944"
      />
      <path
        d="M32.414 95.0005L29.2515 96.9688L29.2511 101.894L32.4136 99.9253L32.414 95.0005Z"
        fill="#DF8842"
      />
      <path
        d="M29.2514 92.0435L26.0889 94.0117L26.0885 98.9365L29.2511 96.9683L29.2514 92.0435Z"
        fill="#EB9447"
      />
      <path
        d="M29.2509 96.9693L26.0884 98.9375L26.088 103.862L29.2506 101.894L29.2509 96.9693Z"
        fill="#CC7A3A"
      />
      <path
        d="M26.0888 94.0123L22.9263 95.9805L22.9259 100.905L26.0885 98.937L26.0888 94.0123Z"
        fill="#DD863F"
      />
      <path
        d="M26.0888 98.9361L22.9263 100.904L22.9259 105.829L26.0885 103.861L26.0888 98.9361Z"
        fill="#B76930"
      />
      <path
        d="M22.9262 95.981L19.7637 97.9492L19.7633 102.874L22.9259 100.906L22.9262 95.981Z"
        fill="#BE6E32"
      />
      <path
        d="M22.9257 100.905L19.7632 102.873L19.7628 107.798L22.9254 105.83L22.9257 100.905Z"
        fill="#9B5E38"
      />
      <path
        d="M19.7641 97.9478L16.6016 99.916L16.6012 104.841L19.7637 102.873L19.7641 97.9478Z"
        fill="#B07F63"
      />
      <path
        d="M60.8774 67.4361L57.7148 69.4043L57.7145 74.3291L60.877 72.3609L60.8774 67.4361Z"
        fill="#AAEF33"
      />
      <path
        d="M57.7153 69.4048L54.5527 71.373L54.5524 76.2978L57.7149 74.3296L57.7153 69.4048Z"
        fill="#98D823"
      />
      <path
        d="M54.5522 71.3736L51.3896 73.3418L51.3893 78.2666L54.5518 76.2984L54.5522 71.3736Z"
        fill="#99DF17"
      />
      <path
        d="M51.3901 73.3423L48.2275 75.3105L48.2272 80.2353L51.3897 78.2671L51.3901 73.3423Z"
        fill="#6EA40C"
      />
      <path
        d="M48.227 75.3091L45.0645 77.2773L45.0641 82.2021L48.2266 80.2339L48.227 75.3091Z"
        fill="#7BAF11"
      />
      <path
        d="M45.0649 77.2779L41.9023 79.2461L41.902 84.1709L45.0645 82.2027L45.0649 77.2779Z"
        fill="#6B9D09"
      />
      <path
        d="M41.9018 79.2466L38.7393 81.2148L38.7389 86.1396L41.9014 84.1714L41.9018 79.2466Z"
        fill="#508800"
      />
      <path
        d="M38.7397 81.2134L35.5771 83.1816L35.5768 88.1064L38.7393 86.1382L38.7397 81.2134Z"
        fill="#6B9D09"
      />
      <path
        d="M35.5766 83.1822L32.4141 85.1504L32.4137 90.0752L35.5762 88.1069L35.5766 83.1822Z"
        fill="#7BAF11"
      />
      <path
        d="M32.4145 85.1509L29.252 87.1191L29.2516 92.0439L32.4141 90.0757L32.4145 85.1509Z"
        fill="#6EA40C"
      />
      <path
        d="M29.2514 87.1197L26.0889 89.0879L26.0885 94.0127L29.2511 92.0444L29.2514 87.1197Z"
        fill="#99DF17"
      />
      <path
        d="M26.0893 89.0865L22.9268 91.0547L22.9264 95.9795L26.0889 94.0112L26.0893 89.0865Z"
        fill="#98D823"
      />
      <path
        d="M22.9272 91.0552L19.7646 93.0234L19.7643 97.9482L22.9268 95.98L22.9272 91.0552Z"
        fill="#AAEF33"
      />
      <path
        d="M19.7641 93.024L16.6016 94.9922L16.6012 99.917L19.7637 97.9487L19.7641 93.024Z"
        fill="#AA6833"
      />
      <path
        d="M60.8784 62.5123L57.7158 64.4805L57.7154 69.4052L60.878 67.437L60.8784 62.5123Z"
        fill="#424602"
      />
      <path
        d="M57.7153 64.481L54.5527 66.4492L54.5524 71.374L57.7149 69.4058L57.7153 64.481Z"
        fill="#341C10"
      />
      <path
        d="M54.5532 66.4478L51.3906 68.416L51.3902 73.3408L54.5528 71.3726L54.5532 66.4478Z"
        fill="#7C3E1F"
      />
      <path
        d="M51.3901 68.4165L48.2275 70.3848L48.2272 75.3095L51.3897 73.3413L51.3901 68.4165Z"
        fill="#733918"
      />
      <path
        d="M48.228 70.3853L45.0654 72.3535L45.065 77.2783L48.2276 75.3101L48.228 70.3853Z"
        fill="#512A12"
      />
      <path
        d="M45.0649 72.354L41.9023 74.3223L41.902 79.247L45.0645 77.2788L45.0649 72.354Z"
        fill="#49210F"
      />
      <path
        d="M41.9028 74.3208L38.7402 76.2891L38.7399 81.2138L41.9024 79.2456L41.9028 74.3208Z"
        fill="#FCC151"
      />
      <path
        d="M38.7397 76.2896L35.5771 78.2578L35.5768 83.1826L38.7393 81.2144L38.7397 76.2896Z"
        fill="#49210F"
      />
      <path
        d="M35.5776 78.2583L32.415 80.2266L32.4147 85.1513L35.5772 83.1831L35.5776 78.2583Z"
        fill="#512A12"
      />
      <path
        d="M32.4145 80.2251L29.252 82.1934L29.2516 87.1181L32.4141 85.1499L32.4145 80.2251Z"
        fill="#733918"
      />
      <path
        d="M29.2524 82.1939L26.0898 84.1621L26.0895 89.0869L29.252 87.1187L29.2524 82.1939Z"
        fill="#7C3E1F"
      />
      <path
        d="M26.0898 84.1626L22.9272 86.1309L22.9269 91.0556L26.0894 89.0874L26.0898 84.1626Z"
        fill="#341C10"
      />
      <path
        d="M22.9272 86.1314L19.7646 88.0996L19.7643 93.0244L22.9268 91.0562L22.9272 86.1314Z"
        fill="#424602"
      />
      <path
        d="M19.7646 88.0982L16.6021 90.0664L16.6017 94.9912L19.7642 93.023L19.7646 88.0982Z"
        fill="#89C64A"
      />
      <path
        d="M60.8784 57.5865L57.7158 59.5547L57.7154 64.4795L60.878 62.5112L60.8784 57.5865Z"
        fill="#382C1D"
      />
      <path
        d="M57.7158 59.5552L54.5532 61.5234L54.5528 66.4482L57.7154 64.48L57.7158 59.5552Z"
        fill="#743817"
      />
      <path
        d="M54.5532 61.524L51.3906 63.4922L51.3902 68.417L54.5528 66.4487L54.5532 61.524Z"
        fill="#462914"
      />
      <path
        d="M51.3906 63.4927L48.228 65.4609L48.2276 70.3857L51.3902 68.4175L51.3906 63.4927Z"
        fill="#2A1712"
      />
      <path
        d="M48.228 65.4595L45.0654 67.4277L45.065 72.3525L48.2276 70.3843L48.228 65.4595Z"
        fill="#FABB3A"
      />
      <path
        d="M45.0654 67.4283L41.9028 69.3965L41.9025 74.3213L45.065 72.353L45.0654 67.4283Z"
        fill="#FFC93A"
      />
      <path
        d="M41.9028 69.397L38.7402 71.3652L38.7399 76.29L41.9024 74.3218L41.9028 69.397Z"
        fill="#FFCE42"
      />
      <path
        d="M38.7402 71.3638L35.5776 73.332L35.5773 78.2568L38.7398 76.2886L38.7402 71.3638Z"
        fill="#FFC93A"
      />
      <path
        d="M35.5781 73.3326L32.4155 75.3008L32.4151 80.2256L35.5777 78.2573L35.5781 73.3326Z"
        fill="#FABB3A"
      />
      <path
        d="M32.415 75.3013L29.2524 77.2695L29.2521 82.1943L32.4146 80.2261L32.415 75.3013Z"
        fill="#2A1712"
      />
      <path
        d="M29.2529 77.2701L26.0903 79.2383L26.09 84.1631L29.2525 82.1948L29.2529 77.2701Z"
        fill="#462914"
      />
      <path
        d="M26.0898 79.2369L22.9272 81.2051L22.9269 86.1298L26.0894 84.1616L26.0898 79.2369Z"
        fill="#743817"
      />
      <path
        d="M22.9277 81.2056L19.7651 83.1738L19.7648 88.0986L22.9273 86.1304L22.9277 81.2056Z"
        fill="#382C1D"
      />
      <path
        d="M19.7646 83.1744L16.6021 85.1426L16.6017 90.0673L19.7642 88.0991L19.7646 83.1744Z"
        fill="#76A542"
      />
      <path
        d="M60.8789 52.6626L57.7163 54.6309L57.7159 59.5556L60.8785 57.5874L60.8789 52.6626Z"
        fill="#3F2414"
      />
      <path
        d="M57.7158 54.6314L54.5532 56.5996L54.5528 61.5244L57.7154 59.5562L57.7158 54.6314Z"
        fill="#F9BF39"
      />
      <path
        d="M54.5537 56.5982L51.3911 58.5664L51.3907 63.4912L54.5533 61.523L54.5537 56.5982Z"
        fill="#FEBF3F"
      />
      <path
        d="M51.3906 58.5669L48.228 60.5352L48.2276 65.4599L51.3902 63.4917L51.3906 58.5669Z"
        fill="#FFC02E"
      />
      <path
        d="M48.2285 60.5357L45.0659 62.5039L45.0655 67.4287L48.2281 65.4605L48.2285 60.5357Z"
        fill="#FFBD29"
      />
      <path
        d="M45.0654 62.5044L41.9028 64.4727L41.9025 69.3974L45.065 67.4292L45.0654 62.5044Z"
        fill="#FFC02A"
      />
      <path
        d="M41.9033 64.4712L38.7407 66.4395L38.7403 71.3642L41.9029 69.396L41.9033 64.4712Z"
        fill="#FFC52A"
      />
      <path
        d="M38.7412 66.44L35.5786 68.4082L35.5782 73.333L38.7408 71.3648L38.7412 66.44Z"
        fill="#FFC02A"
      />
      <path
        d="M35.5781 68.4087L32.4155 70.377L32.4151 75.3017L35.5777 73.3335L35.5781 68.4087Z"
        fill="#FFBD29"
      />
      <path
        d="M32.416 70.3755L29.2534 72.3438L29.253 77.2685L32.4156 75.3003L32.416 70.3755Z"
        fill="#FFC02E"
      />
      <path
        d="M29.2529 72.3443L26.0903 74.3125L26.09 79.2373L29.2525 77.2691L29.2529 72.3443Z"
        fill="#FEBF3F"
      />
      <path
        d="M26.0908 74.313L22.9282 76.2812L22.9278 81.206L26.0904 79.2378L26.0908 74.313Z"
        fill="#F9BF39"
      />
      <path
        d="M22.9277 76.2818L19.7651 78.25L19.7648 83.1748L22.9273 81.2066L22.9277 76.2818Z"
        fill="#3F2414"
      />
      <path
        d="M19.7656 78.2486L16.603 80.2168L16.6026 85.1416L19.7652 83.1734L19.7656 78.2486Z"
        fill="#2D1F19"
      />
      <path
        d="M60.8789 47.7369L57.7163 49.7051L57.7159 54.6298L60.8785 52.6616L60.8789 47.7369Z"
        fill="#FFB931"
      />
      <path
        d="M57.7168 49.7056L54.5542 51.6738L54.5538 56.5986L57.7164 54.6304L57.7168 49.7056Z"
        fill="#FFBE26"
      />
      <path
        d="M54.5537 51.6744L51.3911 53.6426L51.3907 58.5673L54.5533 56.5991L54.5537 51.6744Z"
        fill="#C07614"
      />
      <path
        d="M51.3916 53.6431L48.229 55.6113L48.2286 60.5361L51.3912 58.5679L51.3916 53.6431Z"
        fill="#CE5037"
      />
      <path
        d="M48.2285 55.6099L45.0659 57.5781L45.0655 62.5029L48.2281 60.5347L48.2285 55.6099Z"
        fill="#E54736"
      />
      <path
        d="M45.0664 57.5787L41.9038 59.5469L41.9034 64.4716L45.066 62.5034L45.0664 57.5787Z"
        fill="#F24F42"
      />
      <path
        d="M41.9038 59.5474L38.7412 61.5156L38.7408 66.4404L41.9034 64.4722L41.9038 59.5474Z"
        fill="#F55246"
      />
      <path
        d="M38.7412 61.5162L35.5786 63.4844L35.5782 68.4091L38.7408 66.4409L38.7412 61.5162Z"
        fill="#F24F42"
      />
      <path
        d="M35.5786 63.483L32.416 65.4512L32.4156 70.3759L35.5782 68.4077L35.5786 63.483Z"
        fill="#E54736"
      />
      <path
        d="M32.416 65.4517L29.2534 67.4199L29.253 72.3447L32.4156 70.3765L32.416 65.4517Z"
        fill="#CE5037"
      />
      <path
        d="M29.2534 67.4205L26.0908 69.3887L26.0904 74.3134L29.253 72.3452L29.2534 67.4205Z"
        fill="#C07614"
      />
      <path
        d="M26.0908 69.3873L22.9282 71.3555L22.9278 76.2802L26.0904 74.312L26.0908 69.3873Z"
        fill="#FFBE26"
      />
      <path
        d="M22.9282 71.356L19.7656 73.3242L19.7652 78.249L22.9278 76.2808L22.9282 71.356Z"
        fill="#FFB931"
      />
      <path
        d="M19.7656 73.3248L16.603 75.293L16.6026 80.2177L19.7652 78.2495L19.7656 73.3248Z"
        fill="#FDC13B"
      />
      <path
        d="M60.8794 42.813L57.7168 44.7812L57.7164 49.706L60.879 47.7378L60.8794 42.813Z"
        fill="#E04B48"
      />
      <path
        d="M57.7168 44.7818L54.5542 46.75L54.5538 51.6748L57.7164 49.7066L57.7168 44.7818Z"
        fill="#D74A3F"
      />
      <path
        d="M54.5542 46.7486L51.3916 48.7168L51.3912 53.6416L54.5538 51.6734L54.5542 46.7486Z"
        fill="#AE3623"
      />
      <path
        d="M51.3916 48.7173L48.229 50.6855L48.2286 55.6103L51.3912 53.6421L51.3916 48.7173Z"
        fill="#972C11"
      />
      <path
        d="M48.229 50.6861L45.0664 52.6543L45.066 57.5791L48.2286 55.6109L48.229 50.6861Z"
        fill="#8F3918"
      />
      <path
        d="M45.0669 52.6548L41.9043 54.623L41.9039 59.5478L45.0665 57.5796L45.0669 52.6548Z"
        fill="#964B1B"
      />
      <path
        d="M41.9038 54.6216L38.7412 56.5898L38.7408 61.5146L41.9034 59.5464L41.9038 54.6216Z"
        fill="#9A4E1A"
      />
      <path
        d="M38.7417 56.5904L35.5791 58.5586L35.5787 63.4834L38.7413 61.5151L38.7417 56.5904Z"
        fill="#964B1B"
      />
      <path
        d="M35.5786 58.5591L32.416 60.5273L32.4156 65.4521L35.5782 63.4839L35.5786 58.5591Z"
        fill="#8F3918"
      />
      <path
        d="M32.4165 60.5259L29.2539 62.4941L29.2535 67.4189L32.4161 65.4507L32.4165 60.5259Z"
        fill="#972C11"
      />
      <path
        d="M29.2534 62.4947L26.0908 64.4629L26.0904 69.3877L29.253 67.4194L29.2534 62.4947Z"
        fill="#AE3623"
      />
      <path
        d="M26.0913 64.4634L22.9287 66.4316L22.9283 71.3564L26.0909 69.3882L26.0913 64.4634Z"
        fill="#D74A3F"
      />
      <path
        d="M22.9282 66.4322L19.7656 68.4004L19.7652 73.3252L22.9278 71.3569L22.9282 66.4322Z"
        fill="#E04B48"
      />
      <path
        d="M60.8794 37.8873L57.7168 39.8555L57.7164 44.7802L60.879 42.812L60.8794 37.8873Z"
        fill="#AC5F28"
      />
      <path
        d="M57.7173 39.856L54.5547 41.8242L54.5543 46.749L57.7169 44.7808L57.7173 39.856Z"
        fill="#BF6F34"
      />
      <path
        d="M54.5542 41.8248L51.3916 43.793L51.3912 48.7177L54.5538 46.7495L54.5542 41.8248Z"
        fill="#D37E40"
      />
      <path
        d="M51.3921 43.7935L48.2295 45.7617L48.2291 50.6865L51.3917 48.7183L51.3921 43.7935Z"
        fill="#DB8B4A"
      />
      <path
        d="M48.2299 45.7603L45.0674 47.7285L45.067 52.6533L48.2296 50.6851L48.2299 45.7603Z"
        fill="#E39A5D"
      />
      <path
        d="M45.0669 47.729L41.9043 49.6973L41.9039 54.622L45.0665 52.6538L45.0669 47.729Z"
        fill="#E9A66E"
      />
      <path
        d="M41.9048 49.6978L38.7422 51.666L38.7418 56.5908L41.9044 54.6226L41.9048 49.6978Z"
        fill="#E9AC76"
      />
      <path
        d="M38.7417 51.6665L35.5791 53.6348L35.5787 58.5595L38.7413 56.5913L38.7417 51.6665Z"
        fill="#E9A66E"
      />
      <path
        d="M35.5796 53.6333L32.417 55.6016L32.4166 60.5263L35.5792 58.5581L35.5796 53.6333Z"
        fill="#E39A5D"
      />
      <path
        d="M32.4165 55.6021L29.2539 57.5703L29.2535 62.4951L32.4161 60.5269L32.4165 55.6021Z"
        fill="#DB8B4A"
      />
      <path
        d="M29.2544 57.5708L26.0918 59.5391L26.0914 64.4638L29.254 62.4956L29.2544 57.5708Z"
        fill="#D37E40"
      />
      <path
        d="M26.0913 59.5376L22.9287 61.5059L22.9283 66.4306L26.0909 64.4624L26.0913 59.5376Z"
        fill="#BF6F34"
      />
      <path
        d="M22.9292 61.5064L19.7666 63.4746L19.7662 68.3994L22.9288 66.4312L22.9292 61.5064Z"
        fill="#AC5F28"
      />
      <path
        d="M19.7666 63.4751L16.604 65.4434L16.6036 70.3681L19.7662 68.3999L19.7666 63.4751Z"
        fill="#954D22"
      />
      <path
        d="M60.8803 32.9634L57.7178 34.9316L57.7174 39.8564L60.88 37.8882L60.8803 32.9634Z"
        fill="#CD7734"
      />
      <path
        d="M57.7177 34.9322L54.5552 36.9004L54.5548 41.8252L57.7174 39.8569L57.7177 34.9322Z"
        fill="#DB823B"
      />
      <path
        d="M54.5551 36.899L51.3926 38.8672L51.3922 43.792L54.5548 41.8237L54.5551 36.899Z"
        fill="#E48D42"
      />
      <path
        d="M51.3925 38.8677L48.23 40.8359L48.2296 45.7607L51.3922 43.7925L51.3925 38.8677Z"
        fill="#EE9347"
      />
      <path
        d="M48.2299 40.8365L45.0674 42.8047L45.067 47.7295L48.2296 45.7612L48.2299 40.8365Z"
        fill="#F19F5A"
      />
      <path
        d="M45.0673 42.8052L41.9048 44.7734L41.9044 49.6982L45.067 47.73L45.0673 42.8052Z"
        fill="#F4AA66"
      />
      <path
        d="M41.9048 44.772L38.7422 46.7402L38.7418 51.665L41.9044 49.6968L41.9048 44.772Z"
        fill="#F8B070"
      />
      <path
        d="M38.7422 46.7408L35.5796 48.709L35.5792 53.6338L38.7418 51.6655L38.7422 46.7408Z"
        fill="#F4AA66"
      />
      <path
        d="M35.5796 48.7095L32.417 50.6777L32.4166 55.6025L35.5792 53.6343L35.5796 48.7095Z"
        fill="#F19F5A"
      />
      <path
        d="M32.417 50.6783L29.2544 52.6465L29.254 57.5713L32.4166 55.603L32.417 50.6783Z"
        fill="#EE9347"
      />
      <path
        d="M29.2544 52.6451L26.0918 54.6133L26.0914 59.5381L29.254 57.5698L29.2544 52.6451Z"
        fill="#E48D42"
      />
      <path
        d="M26.0918 54.6138L22.9292 56.582L22.9288 61.5068L26.0914 59.5386L26.0918 54.6138Z"
        fill="#DB823B"
      />
      <path
        d="M22.9297 56.5826L19.7671 58.5508L19.7667 63.4756L22.9293 61.5073L22.9297 56.5826Z"
        fill="#CD7734"
      />
      <path
        d="M19.7666 58.5494L16.604 60.5176L16.6036 65.4424L19.7662 63.4741L19.7666 58.5494Z"
        fill="#B2632D"
      />
      <path
        d="M60.8808 28.0376L57.7183 30.0059L57.7179 34.9306L60.8804 32.9624L60.8808 28.0376Z"
        fill="#D17730"
      />
      <path
        d="M57.7177 30.0064L54.5552 31.9746L54.5548 36.8994L57.7174 34.9312L57.7177 30.0064Z"
        fill="#DE7F31"
      />
      <path
        d="M54.5556 31.9751L51.3931 33.9434L51.3927 38.8681L54.5552 36.8999L54.5556 31.9751Z"
        fill="#E58534"
      />
      <path
        d="M51.3925 33.9439L48.23 35.9121L48.2296 40.8369L51.3922 38.8687L51.3925 33.9439Z"
        fill="#EE8F3A"
      />
      <path
        d="M48.2304 35.9107L45.0679 37.8789L45.0675 42.8037L48.2301 40.8355L48.2304 35.9107Z"
        fill="#F0953F"
      />
      <path
        d="M45.0673 37.8794L41.9048 39.8477L41.9044 44.7724L45.067 42.8042L45.0673 37.8794Z"
        fill="#F39A44"
      />
      <path
        d="M41.9052 39.8482L38.7427 41.8164L38.7423 46.7412L41.9049 44.773L41.9052 39.8482Z"
        fill="#F6A14F"
      />
      <path
        d="M38.7422 41.8169L35.5796 43.7852L35.5792 48.7099L38.7418 46.7417L38.7422 41.8169Z"
        fill="#F39A44"
      />
      <path
        d="M35.58 43.7837L32.4175 45.752L32.4171 50.6767L35.5797 48.7085L35.58 43.7837Z"
        fill="#F0953F"
      />
      <path
        d="M32.4179 45.7525L29.2554 47.7207L29.255 52.6455L32.4176 50.6773L32.4179 45.7525Z"
        fill="#EE8F3A"
      />
      <path
        d="M29.2548 47.7212L26.0923 49.6895L26.0919 54.6142L29.2545 52.646L29.2548 47.7212Z"
        fill="#E58534"
      />
      <path
        d="M26.0927 49.69L22.9302 51.6582L22.9298 56.583L26.0924 54.6148L26.0927 49.69Z"
        fill="#DE7F31"
      />
      <path
        d="M22.9297 51.6568L19.7671 53.625L19.7667 58.5498L22.9293 56.5816L22.9297 51.6568Z"
        fill="#D17730"
      />
      <path
        d="M19.7675 53.6255L16.605 55.5938L16.6046 60.5185L19.7672 58.5503L19.7675 53.6255Z"
        fill="#AC5E29"
      />
      <path
        d="M60.8808 23.1138L57.7183 25.082L57.7179 30.0068L60.8804 28.0386L60.8808 23.1138Z"
        fill="#CC7631"
      />
      <path
        d="M57.7187 25.0826L54.5562 27.0508L54.5558 31.9756L57.7183 30.0073L57.7187 25.0826Z"
        fill="#D87D2F"
      />
      <path
        d="M54.5556 27.0494L51.3931 29.0176L51.3927 33.9424L54.5552 31.9741L54.5556 27.0494Z"
        fill="#E2822E"
      />
      <path
        d="M51.3935 29.0181L48.231 30.9863L48.2306 35.9111L51.3931 33.9429L51.3935 29.0181Z"
        fill="#E8882D"
      />
      <path
        d="M48.2304 30.9869L45.0679 32.9551L45.0675 37.8799L48.2301 35.9116L48.2304 30.9869Z"
        fill="#F09334"
      />
      <path
        d="M45.0683 32.9556L41.9058 34.9238L41.9054 39.8486L45.0679 37.8804L45.0683 32.9556Z"
        fill="#F79C3E"
      />
      <path
        d="M41.9052 34.9224L38.7427 36.8906L38.7423 41.8154L41.9049 39.8472L41.9052 34.9224Z"
        fill="#F79E41"
      />
      <path
        d="M38.7431 36.8912L35.5806 38.8594L35.5802 43.7841L38.7428 41.8159L38.7431 36.8912Z"
        fill="#F79C3E"
      />
      <path
        d="M35.5805 38.8599L32.418 40.8281L32.4176 45.7529L35.5802 43.7847L35.5805 38.8599Z"
        fill="#F09334"
      />
      <path
        d="M32.4179 40.8287L29.2554 42.7969L29.255 47.7216L32.4176 45.7534L32.4179 40.8287Z"
        fill="#E8882D"
      />
      <path
        d="M29.2553 42.7955L26.0928 44.7637L26.0924 49.6884L29.255 47.7202L29.2553 42.7955Z"
        fill="#E2822E"
      />
      <path
        d="M26.0927 44.7642L22.9302 46.7324L22.9298 51.6572L26.0924 49.689L26.0927 44.7642Z"
        fill="#D87D2F"
      />
      <path
        d="M22.9301 46.733L19.7676 48.7012L19.7672 53.6259L22.9298 51.6577L22.9301 46.733Z"
        fill="#CC7631"
      />
      <path
        d="M60.8813 18.19L57.7188 20.1582L57.7184 25.083L60.8809 23.1148L60.8813 18.19Z"
        fill="#BA7B42"
      />
      <path
        d="M57.7187 20.1568L54.5562 22.125L54.5558 27.0498L57.7183 25.0816L57.7187 20.1568Z"
        fill="#DA8334"
      />
      <path
        d="M54.5561 22.1255L51.3936 24.0938L51.3932 29.0185L54.5557 27.0503L54.5561 22.1255Z"
        fill="#C89057"
      />
      <path
        d="M51.3935 24.0943L48.231 26.0625L48.2306 30.9873L51.3931 29.0191L51.3935 24.0943Z"
        fill="#ED9638"
      />
      <path
        d="M48.2309 26.0611L45.0684 28.0293L45.068 32.9541L48.2305 30.9859L48.2309 26.0611Z"
        fill="#F19C40"
      />
      <path
        d="M45.0683 28.0298L41.9058 29.998L41.9054 34.9228L45.0679 32.9546L45.0683 28.0298Z"
        fill="#F4A34D"
      />
      <path
        d="M41.9057 29.9986L38.7432 31.9668L38.7428 36.8916L41.9053 34.9234L41.9057 29.9986Z"
        fill="#E09445"
      />
      <path
        d="M38.7436 31.9673L35.5811 33.9355L35.5807 38.8603L38.7432 36.8921L38.7436 31.9673Z"
        fill="#F4A34D"
      />
      <path
        d="M35.5805 33.9341L32.418 35.9023L32.4176 40.8271L35.5802 38.8589L35.5805 33.9341Z"
        fill="#F19C40"
      />
      <path
        d="M32.4184 35.9029L29.2559 37.8711L29.2555 42.7959L32.418 40.8276L32.4184 35.9029Z"
        fill="#ED9638"
      />
      <path
        d="M29.2553 37.8716L26.0928 39.8398L26.0924 44.7646L29.255 42.7964L29.2553 37.8716Z"
        fill="#C89057"
      />
      <path
        d="M26.0932 39.8404L22.9307 41.8086L22.9303 46.7334L26.0928 44.7652L26.0932 39.8404Z"
        fill="#DA8334"
      />
      <path
        d="M22.9301 41.8072L19.7676 43.7754L19.7672 48.7002L22.9298 46.7319L22.9301 41.8072Z"
        fill="#BA7B42"
      />
      <path
        d="M57.7192 15.233L54.5566 17.2012L54.5563 22.1259L57.7188 20.1577L57.7192 15.233Z"
        fill="#DEAF7F"
      />
      <path
        d="M54.5561 17.1998L51.3936 19.168L51.3932 24.0927L54.5557 22.1245L54.5561 17.1998Z"
        fill="#E89942"
      />
      <path
        d="M51.394 19.1685L48.2314 21.1367L48.2311 26.0615L51.3936 24.0933L51.394 19.1685Z"
        fill="#ED9840"
      />
      <path
        d="M48.2309 21.1373L45.0684 23.1055L45.068 28.0302L48.2305 26.062L48.2309 21.1373Z"
        fill="#F0A551"
      />
      <path
        d="M45.0688 23.106L41.9062 25.0742L41.9059 29.999L45.0684 28.0308L45.0688 23.106Z"
        fill="#F6B367"
      />
      <path
        d="M41.9067 25.0728L38.7441 27.041L38.7438 31.9658L41.9063 29.9976L41.9067 25.0728Z"
        fill="#F7BA70"
      />
      <path
        d="M38.7436 27.0415L35.5811 29.0098L35.5807 33.9345L38.7432 31.9663L38.7436 27.0415Z"
        fill="#F6B367"
      />
      <path
        d="M35.5815 29.0103L32.4189 30.9785L32.4186 35.9033L35.5811 33.9351L35.5815 29.0103Z"
        fill="#F0A551"
      />
      <path
        d="M32.4184 30.979L29.2559 32.9473L29.2555 37.872L32.418 35.9038L32.4184 30.979Z"
        fill="#ED9840"
      />
      <path
        d="M29.2563 32.9458L26.0938 34.9141L26.0934 39.8388L29.2559 37.8706L29.2563 32.9458Z"
        fill="#E89942"
      />
      <path
        d="M26.0932 34.9146L22.9307 36.8828L22.9303 41.8076L26.0928 39.8394L26.0932 34.9146Z"
        fill="#DEAF7F"
      />
      <path
        d="M51.394 14.2447L48.2314 16.2129L48.2311 21.1377L51.3936 19.1694L51.394 14.2447Z"
        fill="#E9A762"
      />
      <path
        d="M48.2319 16.2115L45.0693 18.1797L45.069 23.1045L48.2315 21.1362L48.2319 16.2115Z"
        fill="#EFAB5B"
      />
      <path
        d="M45.0698 18.1802L41.9072 20.1484L41.9068 25.0732L45.0694 23.105L45.0698 18.1802Z"
        fill="#F2B162"
      />
      <path
        d="M41.9067 20.149L38.7441 22.1172L38.7438 27.042L41.9063 25.0737L41.9067 20.149Z"
        fill="#E0B482"
      />
      <path
        d="M38.7446 22.1177L35.582 24.0859L35.5817 29.0107L38.7442 27.0425L38.7446 22.1177Z"
        fill="#F3B768"
      />
      <path
        d="M35.5815 24.0845L32.4189 26.0527L32.4186 30.9775L35.5811 29.0093L35.5815 24.0845Z"
        fill="#F1B164"
      />
      <path
        d="M32.4194 26.0533L29.2568 28.0215L29.2565 32.9463L32.419 30.978L32.4194 26.0533Z"
        fill="#E9A762"
      />
    </svg>
  );
}
