import React from 'react';

export default function PropertyTypeOutput({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.316 20C19.8081 20 20.2292 19.8261 20.5794 19.4782C20.9295 19.1304 21.1049 18.7117 21.1055 18.2222V5.77778C21.1055 5.28889 20.9301 4.87052 20.5794 4.52267C20.2286 4.17481 19.8075 4.00059 19.316 4H6.78968C6.29757 4 5.87615 4.17422 5.52542 4.52267C5.17468 4.87111 4.99961 5.28948 5.00021 5.77778V7.55556H6.78968V5.77778H19.316V18.2222H6.78968V16.4444H5.00021V18.2222C5.00021 18.7111 5.17528 19.1298 5.52542 19.4782C5.87556 19.8267 6.29698 20.0006 6.78968 20H19.316Z"
        fill="currentColor"
      />
      <path
        d="M10.1579 16.4446L8.90526 15.2001L11.2092 12.889H3V11.1112H11.2092L8.90526 8.80011L10.1579 7.55566L14.6316 12.0001L10.1579 16.4446Z"
        fill="currentColor"
      />
    </svg>
  );
}
