import IaCarouselNext from './IaCarouselNext';
import IaCarouselPrev from './IaCarouselPrev';

export default function IaCarouselNavigation() {
  return (
    <>
      <IaCarouselPrev />
      <IaCarouselNext />
    </>
  );
}
