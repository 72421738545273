import React from 'react';

export default function AIEditorPlaylist({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#33DAFF"
        fillRule="evenodd"
      />
      <path
        d="M7.2 7.71429C7.2 7.3198 7.55817 7 8 7H19.2C19.6418 7 20 7.3198 20 7.71429C20 8.10877 19.6418 8.42857 19.2 8.42857H8C7.55817 8.42857 7.2 8.10877 7.2 7.71429Z"
        fill="#080808"
        fillRule="evenodd"
      />
      <path
        d="M5.6 7.71429C5.6 8.10877 5.24183 8.42857 4.8 8.42857C4.35817 8.42857 4 8.10877 4 7.71429C4 7.3198 4.35817 7 4.8 7C5.24183 7 5.6 7.3198 5.6 7.71429Z"
        fill="#080808"
      />
      <path
        d="M7.2 12C7.2 11.6055 7.55817 11.2857 8 11.2857H19.2C19.6418 11.2857 20 11.6055 20 12C20 12.3945 19.6418 12.7143 19.2 12.7143H8C7.55817 12.7143 7.2 12.3945 7.2 12Z"
        fill="#080808"
        fillRule="evenodd"
      />
      <path
        d="M5.6 12C5.6 12.3945 5.24183 12.7143 4.8 12.7143C4.35817 12.7143 4 12.3945 4 12C4 11.6055 4.35817 11.2857 4.8 11.2857C5.24183 11.2857 5.6 11.6055 5.6 12Z"
        fill="#080808"
      />
      <path
        d="M7.2 16.2857C7.2 15.8912 7.55817 15.5714 8 15.5714H19.2C19.6418 15.5714 20 15.8912 20 16.2857C20 16.6802 19.6418 17 19.2 17H8C7.55817 17 7.2 16.6802 7.2 16.2857Z"
        fill="#080808"
        fillRule="evenodd"
      />
      <path
        d="M5.6 16.2857C5.6 16.6802 5.24183 17 4.8 17C4.35817 17 4 16.6802 4 16.2857C4 15.8912 4.35817 15.5714 4.8 15.5714C5.24183 15.5714 5.6 15.8912 5.6 16.2857Z"
        fill="#080808"
      />
    </svg>
  );
}
