import { useRef, useState } from 'react';
import { SupportedActions } from '@lib/web/thread/ThreadComposer/components/Actions';
import { ErrorMessageValue } from '@lib/web/ui';

export type LatexActionState = ReturnType<typeof useLatexAction>;

export const useLatexAction = ({
  setSelectedAction,
}: {
  setSelectedAction: (action: SupportedActions) => void;
}) => {
  const [composingBlockId, setComposingBlockId] = useState<string>();
  const [latexBlocksMap, setLatexBlocksMap] = useState<{
    [blockId: string]: {
      errors: ErrorMessageValue[];
      setValue: (value: string) => void;
    };
  }>({});

  const latexComposerRef = useRef<{
    getElement: () => HTMLDivElement | undefined;
    setValue: (value: string) => void;
  }>();

  const openLatexComposer = ({
    composerDefaultValue,
    blockId,
  }: {
    composerDefaultValue: string;
    blockId: string;
  }) => {
    setSelectedAction('latex');
    setComposingBlockId(blockId);

    // use setTimeout to wait for component rendered by selected 'latex' action
    setTimeout(() => {
      const latextComposerElement = latexComposerRef.current?.getElement();

      if (!latexComposerRef.current || !latextComposerElement) {
        console.warn('latex composer element is not ready');
        return;
      }

      latexComposerRef.current.setValue(composerDefaultValue);

      const selectionObject = window.getSelection
        ? window.getSelection()
        : document.getSelection();

      if (latextComposerElement) {
        selectionObject?.selectAllChildren(latextComposerElement);
      }
    });
  };

  const closeLatexComposer = () => {
    setSelectedAction(undefined);
    setComposingBlockId(undefined);
  };

  return {
    latexComposerRef,
    latexBlocksMap,
    setLatexBlocksMap,
    openLatexComposer,
    closeLatexComposer,
    composingBlockId,
  };
};
