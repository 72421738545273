import {
  AsyncTaskStatus,
  CreatorQuestionReviewStatus,
  CreatorQuestionStatus,
} from '@lib/web/apis';
import { TFunction } from 'i18next';

export const getQuestionAsyncTaskStatusText = (
  t: TFunction,
  status: AsyncTaskStatus
) =>
  ({
    [AsyncTaskStatus.Unknown]: t('Unknown'),
    [AsyncTaskStatus.Created]: t('Waiting'),
    [AsyncTaskStatus.Pending]: t('Waiting'),
    [AsyncTaskStatus.Processing]: t('Processing'),
    [AsyncTaskStatus.Finished]: t('Finished'),
    [AsyncTaskStatus.Cancelled]: t('Cancelled'),
    [AsyncTaskStatus.Failed]: t('Failed'),
  }[status]);

export const getQuestionAsyncTaskTooltip = (
  t: TFunction,
  status: AsyncTaskStatus
) =>
  ({
    [AsyncTaskStatus.Unknown]: null,
    [AsyncTaskStatus.Created]: t('edit.tooltip.processing'),
    [AsyncTaskStatus.Pending]: t('edit.tooltip.processing'),
    [AsyncTaskStatus.Processing]: t('edit.tooltip.processing'),
    [AsyncTaskStatus.Finished]: null,
    [AsyncTaskStatus.Cancelled]: null,
    [AsyncTaskStatus.Failed]: null,
  }[status]);

export const getQuestionReviewStatusText = (
  t: TFunction,
  status: CreatorQuestionReviewStatus
) =>
  ({
    [CreatorQuestionReviewStatus.Approved]: t('Approved'),
    [CreatorQuestionReviewStatus.Rejected]: t('Rejected'),
    [CreatorQuestionReviewStatus.Unknown]: t('Unknown'),
    [CreatorQuestionReviewStatus.PendingReview]: t('Unreviewed'),
    [CreatorQuestionReviewStatus.PendingReapprove]: t('Unreviewed'),
    [CreatorQuestionReviewStatus.ReapproveReviewing]: t('Unreviewed'),
    [CreatorQuestionReviewStatus.Reviewing]: t('Unreviewed'),
    [CreatorQuestionReviewStatus.Removed]: t('Removed'),
  }[status]);

export const getQuestionPublishStatusText = (
  t: TFunction,
  status: CreatorQuestionStatus
) =>
  ({
    [CreatorQuestionStatus.Draft]: t('Draft'),
    [CreatorQuestionStatus.Published]: t('Published'),
    [CreatorQuestionStatus.Publishing]: t('Publishing'),
    [CreatorQuestionStatus.ChangeUnpublished]: t('Changed'),
    [CreatorQuestionStatus.Unknown]: t('Unknown'),
    [CreatorQuestionStatus.Archived]: t('Archived'),
  }[status]);
