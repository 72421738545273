import { useMemo, useState } from 'react';
import {
  apis,
  buildHookMutate,
  useAuth,
  useQuizResultSummary,
} from '@lib/web/apis';
import { call } from '@lib/web/utils';

const MAX_NAME_LENGTH = 100;

export default function usePlaylistRename(quizId: string) {
  const { data, mutate } = useQuizResultSummary({ quizId });
  const { quizOwnerId } = data?.data.quiz ?? {};
  const [value, setValue] = useState<string>();
  const { member } = useAuth();
  const disabled = !member || quizOwnerId !== member?.userId;
  const reloadSummary = useMemo(() => buildHookMutate(mutate), [mutate]);

  const onNameChange = (name: string) => {
    if (!data) return;
    if (name.length > MAX_NAME_LENGTH) return;
    setValue(name);
    reloadSummary(call(apis.quiz.updateQuizName({ name, quizId })), {
      optimisticData: {
        ...data.data,
        quiz: {
          ...data.data.quiz,
          quizCustomName: name,
        },
      },
      revalidate: false,
    });
  };

  return {
    value: value ?? data?.data.quiz.quizCustomName ?? '',
    disabled,
    onNameChange,
  };
}
