import React from 'react';

export default function ActionArrowRightUp({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6 6V8H14.59L5 17.59L6.41 19L16 9.41V18H18V6H6Z"
        fill="currentColor"
      />
    </svg>
  );
}
