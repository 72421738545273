import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { composerConfig } from '@front/config';
import { useUploadFile } from '@front/helper';
import {
  OtherAudio as OtherAudioIcon,
  OtherDelete as OtherDeleteIcon,
} from '@front/icon';
import { AudioPlayer, LoadingIcon, TipButton, toast } from '@front/ui';
import { apis, UploadResourceType } from '@lib/web/apis';
import { blockLevelProseMirrorNodeToComposerBlocks } from '@lib/web/composer';
import ThemeProvider from '@lib/web/composer/components/ThemeProvider';
import { notionLikeFileImageKeyboardShortcuts } from '@lib/web/composer/TextComposer/components/blocks/shared/notionLikeFileImageKeyboardShortcuts';
import { useGeneralBlockMoreActions } from '@lib/web/composer/TextComposer/components/SideMenu/hooks/useGeneralBlockMoreActions';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';
import { getBlockInfoFromPos } from '@lib/web/composer/utils/getBlockInfoFromPos';
import { useClubSlug } from '@lib/web/hooks';
import { ThreadComposerSchema } from '@lib/web/thread/ThreadTextComposer/config/threadComposerSchema';
import { VisuallyHiddenInput } from '@lib/web/ui';
import { call } from '@lib/web/utils';
import { mergeAttributes, Node } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { Node as ProseMirrorNode } from 'prosemirror-model';

import { ThreadBlockTypes } from '../../../config/threadBlockTypes';

const styles = {
  placeholder: {
    display: 'flex',
    width: '100%',
    height: '44px',
    padding: '8px 20px',
    gap: '8px',
    alignItems: 'center',
    borderRadius: '4px',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    typography: 'body1',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover': {
        background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
};

type BlockProps = {
  node: ProseMirrorNode;
  getPos: (() => number) | boolean;
  updateAttributes: (attributes: Record<string, any>) => void;
};

const FILE_INPUT_ACCEPT = composerConfig.supportedAudioTypes.join(',');

function Audio({ node, getPos, updateAttributes }: BlockProps) {
  const { t } = useTranslation('editor');
  const [inputKey, setInputKey] = useState(Date.now()); // to reset file input

  const fileInputRef = useRef<HTMLInputElement>();
  const [src, setSrc] = useState<string>('');
  const status = useRef<string>(node.attrs.status);
  const pos = typeof getPos === 'function' ? getPos() : null;
  const { editor } = useContext(TextComposerContext);
  const clubSlug = useClubSlug();
  const loading = !!node.attrs.key && !src;
  const blockContainerBlock = useMemo(() => {
    const blockContainerNode = pos
      ? getBlockInfoFromPos(editor._tiptapEditor.state.doc, pos).node
      : null;
    return blockContainerNode
      ? blockLevelProseMirrorNodeToComposerBlocks(blockContainerNode)[0]
      : null;
  }, [editor._tiptapEditor.state.doc, pos]);

  const { deleteBlock } = useGeneralBlockMoreActions<ThreadComposerSchema>({
    editor,
    block: blockContainerBlock,
  });

  const validateFile = useCallback(
    (file: File) => {
      if (!composerConfig.supportedAudioTypes.includes(file.type)) {
        toast.error(
          t('Supported file types: ##', {
            types: composerConfig.supportedAudioTypesMessage,
          })
        );
        return false;
      }

      const fileSizeInMb = file.size / (1024 * 1024);

      if (fileSizeInMb > composerConfig.fileSizeLimitMb) {
        toast.error(
          t('Audio should not be exceed ## Mb', {
            limit: composerConfig.fileSizeLimitMb,
          })
        );
        return false;
      }
      return true;
    },
    [t]
  );

  const updateStatus = useCallback(
    (newStatus: string) => {
      status.current = newStatus;
      // prevent "flushSync was called" error
      setTimeout(() => {
        updateAttributes({
          status: newStatus,
        });
      });
    },
    [updateAttributes]
  );

  const setErrorStatus = useCallback(() => {
    updateAttributes({
      status: 'upload-fail',
      filename: '',
      key: '',
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setInputKey(Date.now());
    }
  }, [updateAttributes]);

  const { handleFileChange, changeFile, uploading } = useUploadFile({
    getUploadKeyAndUrl: async (file: File) => {
      const ext = file.name.split('.').pop()?.toLowerCase() || '';
      const { data } = await apis.file.getAhaEditorAudioUploadUrl(
        'editor',
        clubSlug,
        ext
      );
      return data;
    },
    onFileVerify: ({ file }) => {
      if (!validateFile(file)) {
        updateStatus('upload-fail');
        return false;
      }

      return true;
    },
    onFileChange: ({ file }) => {
      updateAttributes({
        filename: file.name,
      });
    },
    onSuccess: async (key) => {
      updateAttributes({
        key,
        status: 'upload-success',
      });
    },
    onFail: (err) => {
      toast.error(t('Fail to upload file'));
      console.warn('upload fail', err);
      try {
        setErrorStatus();
      } catch (e) {
        // it's possible that user will leave this textComposer, make updateAttributes fail
        console.warn('updateAttributes fail', e);
      }
    },
  });

  useEffect(() => {
    if (status.current === 'added-by-insert') {
      fileInputRef.current?.click();
      updateStatus('ready-for-upload');
    }
  }, [updateStatus]);

  useEffect(() => {
    const fetchAudioSrc = async (key: string) => {
      const [res] = await call(
        apis.file.getSignedUrl(UploadResourceType.AhaFile, key)
      );

      if (res) {
        setSrc(res.data.signedUrl);
      } else {
        setErrorStatus();
      }
    };

    if (node.attrs.key) {
      fetchAudioSrc(node.attrs.key);
    }
  }, [node.attrs.key, setErrorStatus, updateStatus]);

  useEffect(() => {
    if (status.current === 'added-by-file-drop') {
      if (!validateFile(node.attrs.file)) {
        setErrorStatus();
        return;
      }

      // prevent "flushSync was called" error
      setTimeout(() => {
        changeFile(node.attrs.file);
      });
      updateStatus('ready-for-upload');
    }
  }, [
    changeFile,
    node.attrs.file,
    setErrorStatus,
    t,
    updateStatus,
    validateFile,
  ]);

  return (
    <NodeViewWrapper>
      <ThemeProvider mode="dark">
        <Box>
          {src ? (
            <AudioPlayer
              src={src}
              actionComponent={
                <TipButton
                  title="Delete"
                  customSize={24}
                  onClick={deleteBlock}
                  disabled={!editor.isEditable}
                >
                  <OtherDeleteIcon />
                </TipButton>
              }
            />
          ) : (
            <Box sx={styles.placeholder} component="label">
              {loading || uploading ? (
                <LoadingIcon sx={{ display: 'inline-block' }} />
              ) : (
                <OtherAudioIcon />
              )}
              {loading ? node.attrs.filename : t('Audio')}
              <VisuallyHiddenInput
                key={inputKey}
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                inputProps={{ accept: FILE_INPUT_ACCEPT }}
                disabled={loading || uploading}
              />
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </NodeViewWrapper>
  );
}

type AudioBlockOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: AudioBlockOptions;
    node: ProseMirrorNode;
  }) => string;
};

export const audioBlockDefaultProps = {
  key: {
    default: '',
  },
  filename: {
    default: '',
  },
  status: {
    default: '',
  },
  file: {
    default: '',
  },
};
export const AudioBlock = Node.create<AudioBlockOptions>({
  name: ThreadBlockTypes.Audio,

  group: 'blockContent',

  inline: false,

  selectable: false,

  atom: true,

  addAttributes() {
    return audioBlockDefaultProps;
  },

  addKeyboardShortcuts: notionLikeFileImageKeyboardShortcuts,

  parseHTML() {
    return [
      {
        tag: 'audio-block',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        'data-content-type': this.name,
        'data-render-ui': 'audioBlock',
        'data-metadata-filename': HTMLAttributes.filename || '',
        'data-metadata-file-key': HTMLAttributes.key || '',
      }),
      ['span', {}, HTMLAttributes.filename],
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Audio);
  },
});

export default AudioBlock;
