import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { SquareAvatar } from '@front/ui';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  avatar: {
    display: 'flex',
    mt: 1,
    gap: 2,
    alignItems: 'flex-end',
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },
};

export default function ProfileLayoutSkeleton() {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.avatar}>
        <SquareAvatar size={80} />
        <Box sx={styles.name}>
          <Skeleton width={150} />
          <Skeleton width={100} />
        </Box>
      </Box>
      <Skeleton width="100%" />
    </Box>
  );
}
