import React, { ReactNode, useEffect, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionChevronRightSmall as ActionChevronRightSmallIcon,
} from '@front/icon';

const styles = {
  header: {
    py: 0.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
};
export type AccordionProps = {
  title: string;
  titleIcon?: ReactNode;
  children?: ReactNode;
  defaultOpen?: boolean;
  sx?: BoxProps['sx'];
};

export default function Accordion({
  titleIcon,
  title,
  children,
  sx,
  defaultOpen = true,
}: AccordionProps) {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <Box sx={sx}>
      <Box
        sx={styles.header}
        className="accordion-header"
        onClick={() => setOpen((st) => !st)}
      >
        <Typography sx={styles.title} variant="body2">
          {titleIcon}
          {title}
        </Typography>

        {open ? (
          <ActionChevronDownIcon width={16} height={16} />
        ) : (
          <ActionChevronRightSmallIcon width={16} height={16} />
        )}
      </Box>
      <Collapse className="accordion-collapse" in={open}>
        {children}
      </Collapse>
    </Box>
  );
}
