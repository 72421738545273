import { useMemo } from 'react';
import {
  buildHookMutate,
  QueryThreadViewType,
  ThreadViewOrderBy,
  ThreadViewSortBy,
  ThreadViewType,
  useThreadViews,
} from '@lib/web/apis';
import { parseISO } from 'date-fns';

import { useDefaultClubAllThreadView } from '../defaultView/useDefaultClubAllThreadView';

// get threadViewUsers and transform data to virtual thread views
// [{ id: xxx, threadViewUsers: [{ id: yyy }, { id: zzz }] }] -> [{ id: yyy, threadViewUsers: [{ id: yyy }] }, { id: zzz, threadViewUsers: [{ id: zzz }] }]
const getClubThreadView = (views: GetThreadViewRes[]): GetThreadViewRes[] => {
  const result: GetThreadViewRes[] = [];

  for (const view of views) {
    if (view.type === ThreadViewType.Club) {
      view.threadViewUsers.forEach((user) => {
        const newView = {
          ...view,
          threadViewUsers: [...view.threadViewUsers].filter(
            (u) => u.id === user.id
          ),
          id: user.id,
        };
        result.push(newView);
      });
    }
  }

  return result;
};

export const useClubThreadViews = ({ clubId }: { clubId?: string }) => {
  const { data: allThreadViewRes, isLoading } = useThreadViews({
    type: QueryThreadViewType.ClubAllThread,
    clubId,
  });
  const { data: clubThreadViewRes, mutate } = useThreadViews(
    {
      type: QueryThreadViewType.Club,
      clubId,
      sortBy: ThreadViewSortBy.Desc,
      orderBy: ThreadViewOrderBy.CreatedAt,
    },
    {
      enable: !!clubId,
    }
  );

  const dataViews = useMemo(() => {
    // sort by first threadViewUsers (real thread view) createdAt
    const sortedData = getClubThreadView(clubThreadViewRes?.data ?? []).sort(
      (a, b) => {
        return (
          parseISO(b.threadViewUsers[0].createdAt).getTime() -
          parseISO(a.threadViewUsers[0].createdAt).getTime()
        );
      }
    );
    return sortedData;
  }, [clubThreadViewRes?.data]);

  const defaultAllThreadView = useDefaultClubAllThreadView({ clubId });

  const views = useMemo(() => {
    const dataAllThreadView = allThreadViewRes?.data[0];

    return dataAllThreadView
      ? [...dataViews, dataAllThreadView]
      : [...dataViews, defaultAllThreadView];
  }, [allThreadViewRes?.data, dataViews, defaultAllThreadView]);

  const reloadViews = useMemo(() => buildHookMutate(mutate), [mutate]);

  return {
    views,
    isLoading,
    reloadViews,
  };
};
