import React from 'react';

export default function EditorGreaterThan({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.06292 18.9999C7.74562 18.9999 7.43186 18.8503 7.22254 18.5651C6.86381 18.0758 6.94869 17.3721 7.41251 16.9936L13.5295 11.9997L7.41208 7.00631C6.94825 6.62786 6.86323 5.92407 7.22211 5.43474C7.58133 4.94541 8.24844 4.85571 8.71174 5.23432L17 11.9998L8.71217 18.7658C8.51866 18.9238 8.28979 19 8.06292 19V18.9999Z"
        fill="currentColor"
      />
    </svg>
  );
}
