import { useCallback } from 'react';
import { apis, useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { callWithToast } from '@lib/web/utils';
import { Channel } from 'stream-chat';

import { StreamChatGenerics } from '../../types';
import { useBuildMessageData } from '../message/useBuildMessageData';

export const useEditMessage = () => {
  const { member } = useAuth();
  const { buildMessageData } = useBuildMessageData();

  const myMemberId = member?.memberId || '';

  const editMessage = useCallback(
    async ({
      text,
      blocks,
      channel,
      messageId,
    }: {
      text: string;
      blocks: ComposerBlock[];
      channel: Channel<StreamChatGenerics>;
      messageId: string;
    }) => {
      if (!channel.data || !channel.id) {
        return;
      }

      const messageData = buildMessageData({
        text,
        blocks,
        disableExternalPayload: true, // back-end will handle the external payload, so we don't need to handle it here
      });

      if (!messageData.customBlocks || messageData.customBlocks.length === 0) {
        /**
         * when customBlocks is empty, it means the message is not updated,
         * so we don't need to call the back-end
         */
        return;
      }

      if (messageData.text === '') {
        /**
         * when text is empty, it means the message is deleted,
         * we need another UI to handle this like slask
         */
        return;
      }

      /**
       *  XXX: we don't wait for the response, but in the future,
       * it would be better to trigger an event that sends to useThreadChatMessage.ts to update the message
       */
      void callWithToast(
        apis.func.threadEditMessage({
          channelType: channel.type as ThreadEditMessage['channelType'],
          channelId: channel.id,
          messageId,
          requestUserId: myMemberId,
          content: text,
          customBlocks: messageData.customBlocks,
        })
      );
    },
    [buildMessageData, myMemberId]
  );

  return { editMessage };
};
