import { useCallback } from 'react';

import { useLineNumbers } from './useLineNumbers';

function getStyles(el: Element) {
  return window.getComputedStyle(el);
}

// All data-anchor-xx attributes come from editor block attributes
export function useLineNumberDisplay() {
  const { getLineNumberFromTop } = useLineNumbers();

  return useCallback(
    (
      targetId: string,
      {
        dataIdAttr = 'data-anchor-id',
        contentSelect = '.inline-anchor-content',
        parentElement,
      }: {
        dataIdAttr?: string;
        contentSelect?: string;
        parentElement?: HTMLDivElement | null;
      } = {}
    ) => {
      const portalTarget = parentElement || document;
      const target = portalTarget.querySelector(
        `[${dataIdAttr}="${targetId}"]`
      );
      const targetParent = target?.closest('[data-node-type="blockContainer"]')
        ? (target?.closest('[data-content-type=paragraph]') as HTMLDivElement)
        : null;
      const targetOffsetTop = targetParent ? targetParent.offsetTop : 0;

      const targetPaddingTop = targetParent
        ? parseInt(getStyles(targetParent).paddingTop)
        : 0;

      if (target) {
        const targetContentEl = target.querySelector(contentSelect) || target;
        const targetContent = targetContentEl?.textContent || '';
        const offsetTop =
          (target as HTMLElement).offsetTop +
          targetOffsetTop +
          targetPaddingTop;
        const offsetHeight = (target as HTMLElement).offsetHeight;
        const lineHeight = targetContentEl
          ? parseInt(getStyles(targetContentEl).lineHeight)
          : 0;
        const lineCount = Math.round(offsetHeight / lineHeight);

        const startLineNumber = getLineNumberFromTop(offsetTop);

        if (startLineNumber && lineCount) {
          const startLine = startLineNumber.num;
          const endLine = startLine + (lineCount - 1);
          return [
            startLine !== endLine ? `${startLine}-${endLine}` : startLine,
            targetContent,
          ];
        }
      }

      return [];
    },
    [getLineNumberFromTop]
  );
}
