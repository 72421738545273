const htmlContent = (type: string) =>
  `[data-content-type="${type}"]:not(.block-content)`;

export const generatedHtmlInlineHighlightAnchorStyle = () => ({
  [`${htmlContent('inline-highlight-anchor')}`]: {
    '.highlight-order': {
      typography: 'subtitle2',
      lineHeight: '18px',
      userSelect: 'none',
      pointerEvents: 'none',
      minWidth: 22,
    },
  },
});
