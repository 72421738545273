import { Fragment, MouseEvent, useCallback, useRef, useState } from 'react';
import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';
import {
  Button,
  compactNumberFormat,
  EmphasizeButton,
  Icon,
  ResponsiveDropdown,
  ResponsiveTooltip,
  UserAvatar,
} from '@front/ui';

import { useIaAction } from '../../../core/IaAction/useIaAction';
import { ButtonDropdownOption, ProfileLayoutAvatarArea } from '../types';

const styles = {
  root: {
    mt: 1,
  },
  avatar: {
    gap: 2,
    alignItems: 'flex-end',
    '& .MuiTypography-body1': {
      fontSize: 20,
      fontWeight: 700,
    },
    '& .MuiTypography-caption': {
      fontSize: 16,
      mt: 0.5,
      mb: 0.5,
    },
  },
  intro: {
    mt: 1,
    typography: 'appBody',
    color: 'alpha.lightA75',
  },
  propertyContainer: {
    display: 'flex',
    gap: 0,
    mt: 2,
  },
  property: {
    px: '4px',
    py: '2px',
    display: 'flex',
    gap: '5px',
    typography: 'body1',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    '& span': {
      color: (theme: Theme) => theme.palette.text.primary,
      fontWeight: 500,
    },
  },
  propertyButton: {
    borderRadius: 1,
    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
  buttonContainer: {
    mt: 3,
    display: 'flex',
    gap: 1,
  },
  button: {
    width: '100%',
    minWidth: 'unset',
  },
  buttonFixedWidth: {
    maxWidth: '149px',
    '& .popper-content': {
      minWidth: 'unset',
      width: '149px',
    },
  },
  popper: {
    zIndex: 1,
  },
  option: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  tooltipTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 0.5,
  },
  buttonWrapper: {
    display: 'contents',
  },
};

type RenderOptionProps = {
  display: string;
  iconName: string;
};

const RenderOption = ({
  display,
  iconName,
}: RenderOptionProps): JSX.Element => {
  return (
    <Box sx={styles.option}>
      <Icon name={iconName} size={16} />
      {display}
    </Box>
  );
};

export type AreaAvatarProps = ProfileLayoutAvatarArea;

export default function AreaAvatar({
  avatar,
  intro,
  properties,
  buttons,
}: AreaAvatarProps) {
  const { getIaAction } = useIaAction();
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );

  const handleDropdownClick = useCallback(
    (option: ButtonDropdownOption, e: MouseEvent): void => {
      const optionIaAction = getIaAction<MouseEvent<Element>>(option.value);
      optionIaAction?.action(e);
      setOpenDropdownIndex(null);
    },
    [getIaAction]
  );

  const handleDropdownClose = useCallback((): void => {
    setOpenDropdownIndex(null);
  }, []);

  return (
    <Box sx={styles.root}>
      <UserAvatar
        sx={styles.avatar}
        src={avatar.url}
        title={avatar.title}
        subTitle={avatar.subtitle}
        indicators={avatar.indicators}
        size="xl"
        showIndicator={avatar.showIndicator}
        status={avatar.status}
        statusInfo={avatar.statusInfo}
      />
      {intro && <Typography sx={styles.intro}>{intro}</Typography>}
      {properties && (
        <Box sx={styles.propertyContainer}>
          {properties.map(({ name, value, clickAction }) =>
            clickAction?.value ? (
              <ButtonBase
                key={name}
                sx={[styles.property, styles.propertyButton]}
                onClick={() => getIaAction(clickAction?.value)?.action()}
              >
                <span>{compactNumberFormat(value)}</span>
                {name}
              </ButtonBase>
            ) : (
              <Box key={name} sx={styles.property}>
                <span>{compactNumberFormat(value)}</span>
                {name}
              </Box>
            )
          )}
        </Box>
      )}
      {buttons && (
        <Box sx={styles.buttonContainer}>
          {buttons.map((button, index) => {
            const {
              key,
              icon,
              text,
              value,
              buttonVariant,
              buttonColor,
              fullWidth,
              emphasize = true,
              suffixIconName,
              tooltip,
              buttonDropdownOptions = [],
            } = button;

            const ButtonComponent = emphasize ? EmphasizeButton : Button;

            const iaAction = getIaAction<
              typeof button | MouseEvent<HTMLButtonElement>
            >(value);

            const buttonContent = (
              <ButtonComponent
                ref={(el) => {
                  buttonRefs.current[index] = el;
                }}
                sx={[styles.button, !fullWidth && styles.buttonFixedWidth]}
                prefixIcon={icon && <Icon name={icon} size={16} />}
                variant={buttonVariant}
                color={buttonColor}
                onClick={(ev: MouseEvent<HTMLButtonElement>) => {
                  if (!buttonDropdownOptions.length) {
                    iaAction?.action(ev);
                  } else {
                    setOpenDropdownIndex(index);
                  }
                }}
                loading={
                  typeof iaAction?.inProgress === 'function'
                    ? iaAction.inProgress(button)
                    : iaAction?.inProgress
                }
                suffixIcon={suffixIconName && <Icon name={suffixIconName} />}
              >
                {text}
              </ButtonComponent>
            );

            return (
              <Fragment key={key}>
                {tooltip ? (
                  <ResponsiveTooltip
                    title={
                      <Box sx={styles.tooltipTitle}>
                        {icon && <Icon name={icon} size={16} />}
                        {text}
                      </Box>
                    }
                    content={tooltip}
                    tooltipProps={{ followCursor: true }}
                  >
                    <Box component="span" sx={styles.buttonWrapper}>
                      {buttonContent}
                    </Box>
                  </ResponsiveTooltip>
                ) : (
                  buttonContent
                )}

                <ResponsiveDropdown
                  open={openDropdownIndex === index}
                  options={buttonDropdownOptions}
                  onClick={handleDropdownClick}
                  onClose={handleDropdownClose}
                  menuDropdownProps={{
                    anchorEl: buttonRefs.current[index],
                    popperProps: {
                      sx: [
                        styles.popper,
                        !fullWidth && styles.buttonFixedWidth,
                      ],
                    },
                  }}
                  renderOption={(ev) => (
                    <RenderOption display={ev.display} iconName={ev.iconName} />
                  )}
                />
              </Fragment>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
