import React, {
  forwardRef,
  HTMLAttributes,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { Icon, Scrollbar, TextField } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import {
  DisplayTableLayoutAutoCompleteCell,
  DisplayTableLayoutAutoCompleteOption,
  DisplayTableLayoutRow,
  IaCellValueChangedEvent,
} from '../../types';

export type IaDisplayTableAutoCompleteCellProps = {
  row: DisplayTableLayoutRow;
  cell: DisplayTableLayoutAutoCompleteCell;
};

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
  },
  value: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  autoComplete: {
    '& .MuiInputBase-input': {
      px: 0,
    },
    '& .textfield-main': {
      border: 'none !important',
      backgroundColor: 'transparent',
      outlineWidth: '0 !important',
      height: '32px',
    },
  },
  menu: {
    typography: 'body2',
    height: '28px',
    px: 1.5,
    py: '3.5px',
    gap: 1,
    display: 'flex',
    alignItems: 'center',
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '4px',
    px: '6px',
    py: '2px',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    borderRadius: '4px',
    typography: 'caption',
  },
};

/**
 * use this component to use our own Scrollbar, add ref to make keyboard navigation work correctly
 */
const ListboxComponent = forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children, ...props }, ref) => (
    <div ref={ref}>
      <Scrollbar {...props}>{children}</Scrollbar>
    </div>
  )
);

ListboxComponent.displayName = 'ListboxComponent';

export default function IaDisplayTableAutoCompleteCell({
  row,
  cell,
}: IaDisplayTableAutoCompleteCellProps) {
  const {
    value,
    options,
    isOptionsAsync,
    sx,
    placeholder,
    disabled,
    actionMap,
    display = 'default',
  } = cell;
  const { getIaAction } = useIaAction();
  const inputRef = useRef<HTMLInputElement>();
  const [open, setOpen] = useState(false);

  const sxProps = Array.isArray(sx) ? sx : [sx];

  const handleChange = (
    event: SyntheticEvent,
    newValue: DisplayTableLayoutAutoCompleteOption | null
  ) => {
    if (!actionMap?.valueChange) return;
    const action = getIaAction<
      IaCellValueChangedEvent<DisplayTableLayoutAutoCompleteOption | null>
    >(actionMap.valueChange);
    action?.action({ value: newValue, row });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      inputRef.current?.blur();
    }
    if (event.key === 'Enter') {
      inputRef.current?.blur();
    }
    if (event.key === 'Tab') {
      inputRef.current?.blur();
    }
  };
  const handleOptionClick =
    (onClick?: MouseEventHandler) => (event: MouseEvent) => {
      onClick?.(event);

      inputRef.current?.blur();
    };

  return (
    <Box sx={[styles.root, ...sxProps]}>
      {(display === 'default' || open) && (
        <Autocomplete<DisplayTableLayoutAutoCompleteOption>
          sx={styles.autoComplete}
          slotProps={{
            popper: {
              placement: 'bottom-start',
              sx: {
                width: '241px !important',
              },
            },
          }}
          onKeyDown={handleKeyDown}
          open={open}
          value={value}
          onClick={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={handleChange}
          isOptionEqualToValue={(option, optionValue) =>
            option.value === optionValue.value
          }
          disabled={disabled}
          getOptionLabel={(option) => option.label}
          options={options}
          filterOptions={isOptionsAsync ? (opts) => opts : undefined}
          clearOnBlur
          ListboxComponent={ListboxComponent}
          renderOption={(props, option) => {
            const { key, onClick, ...rest } =
              props as HTMLAttributes<HTMLLIElement> & {
                key: string;
              };
            return (
              <Box
                sx={styles.menu}
                component="li"
                key={key}
                onClick={handleOptionClick(onClick)}
                {...rest}
              >
                {option.icon && (
                  <Icon name={option.icon} width={16} height={16} />
                )}
                {option.label}
              </Box>
            );
          }}
          renderInput={({
            InputProps,
            inputProps,
            InputLabelProps,
            size,
            ...params
          }) => (
            <TextField
              inputRef={(ref) => {
                if (typeof InputProps.ref === 'function') InputProps.ref(ref);
                inputRef.current = ref;
              }}
              startAdornment={InputProps.startAdornment}
              {...params}
              inputProps={inputProps}
              placeholder={placeholder}
              onClick={() => setOpen(true)}
            />
          )}
        />
      )}
      {display === 'tag' && !open && (
        <Box
          sx={styles.tag}
          component="span"
          onClick={() => {
            setOpen(true);
            setTimeout(() => {
              inputRef.current?.select();
            }, 100); // XXX: add a small delay to wait for autocomplete component ready
          }}
        >
          {value?.label}
        </Box>
      )}
    </Box>
  );
}
