import React from 'react';

export default function EditorEqualGreaterThan({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.06292 16.9999C7.74562 16.9999 7.43186 16.8503 7.22254 16.5651C6.86381 16.0758 6.94869 15.3721 7.41251 14.9936L13.5295 9.99972L7.41208 5.00631C6.94825 4.62786 6.86323 3.92407 7.22211 3.43474C7.58133 2.94541 8.24844 2.85571 8.71174 3.23432L17 9.99985L8.71217 16.7658C8.51866 16.9238 8.28979 17 8.06292 17V16.9999Z"
        fill="currentColor"
      />
      <path
        d="M16.0052 19H7.99476C7.44538 19 7 19.4479 7 20C7 20.5521 7.44554 21 7.99476 21H16.0052C16.5546 21 17 20.5521 17 20C17 19.4479 16.5546 19 16.0052 19Z"
        fill="currentColor"
      />
    </svg>
  );
}
