import { useEffect } from 'react';
import { BlockNoteEditor, BlockSchema } from '@blocknote/core';

import { pastePlugin } from './plugins/pastePlugin';
import { replaceLineBreak } from './plugins/replaceLineBreak';

/**
 * blocknote has no way to configure it at beginning, so we use tiptapEditor to unregister PromseMirror Plugins
 * (another options is to clone a BlockNoteEditor and customize by ourselves)
 */
export const useCustomProseMirrorPlugins = <T extends BlockSchema>(
  editor: BlockNoteEditor<T>
) => {
  useEffect(() => {
    /**
     * blocknote has a default plugin called 'tailingNode', which will automatically add a new block at the end
     * currently this will cause our ui weird (because the rendered html will have 1 more line), so we want to disable it,
     */
    editor._tiptapEditor.unregisterPlugin('trailingNode');

    editor._tiptapEditor.registerPlugin(replaceLineBreak());

    /**
     * paste rich content need more logic to handle,
     * now we support plain text and copy by side menu, otherwise copy and paste very likely to crash the application
     */
    editor._tiptapEditor.registerPlugin(pastePlugin(editor));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
