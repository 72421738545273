import { useEffect } from 'react';

export default function useFloatingProfileClick(callback: () => void) {
  useEffect(() => {
    function handleClick(event: Event) {
      const clickedElement = event.target as HTMLElement;

      if (
        clickedElement.closest('.floating-profile-popper') ||
        clickedElement.closest('.profile-button-popper')
      ) {
        callback();
      }
    }

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
