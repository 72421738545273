import { forwardRef, ReactNode } from 'react';
import Box from '@mui/material/Box';

export type IaTableBodyProps = {
  children: ReactNode;
};

const IaTableBody = forwardRef<HTMLDivElement, IaTableBodyProps>(
  ({ children }, ref) => {
    return <Box ref={ref}>{children}</Box>;
  }
);

IaTableBody.displayName = 'IaTableBody';
export default IaTableBody;
