import React, { RefObject, useMemo, useRef } from 'react';
import { ClickAwayListener } from '@mui/material';
import MenuList, { MenuListProps } from '@mui/material/MenuList';
import Paper, { PaperProps } from '@mui/material/Paper';

import { ReverseTheme } from '../../components';

export interface Position {
  x: number;
  y: number;
}

export type ContextMenuProps = PaperProps & {
  active?: boolean;
  isRootMenu?: boolean;
  target?: RefObject<HTMLElement>;
  position?: Position;
  children?: MenuListProps['children'];
  onClose?: () => void;
};

const styles = {
  root: {
    position: 'fixed',
    minWidth: 265,
    maxWidth: '100%',
    visibility: 'hidden',
    zIndex: 5000,
    '& .MuiMenuItem-root': {
      minHeight: { xs: 45, md: 28 },
    },
    '& .MuiTypography-body1': {
      fontSize: { xs: 16, md: 14 },
    },
  },
  active: {
    visibility: 'visible',
  },
};

function ContextMenu({
  sx,
  active = false,
  isRootMenu = true,
  target,
  position,
  children,
  onClose,
  ...restProps
}: ContextMenuProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const contextMenuRef = useRef<HTMLDivElement>(null);

  const { left, top } = useMemo(() => {
    const menuWidth = contextMenuRef.current?.clientWidth || 0;
    const menuHeight = contextMenuRef.current?.clientHeight || 0;

    if (target?.current) {
      const {
        x: targetX,
        y: targetY,
        width: targetWidth,
        height: targetHeight,
      } = target.current.getBoundingClientRect();

      const x =
        targetX + targetWidth + menuWidth >= window.innerWidth
          ? targetX - menuWidth
          : targetX + targetWidth;
      const y =
        targetY + targetHeight / 2 + menuHeight / 2 >= window.innerHeight
          ? targetY + targetHeight / 2 - menuHeight
          : targetY + targetHeight / 2 - menuHeight / 2;

      return { left: x, top: y };
    }

    if (position) {
      const x =
        position.x + menuWidth >= window.innerWidth
          ? position.x - menuWidth
          : position.x;
      const y =
        position.y + menuHeight >= window.innerHeight
          ? position.y - menuHeight
          : position.y;

      return { left: x, top: y };
    }

    return { left: 0, top: 0 }; // return default value neither position nor target is given
    // XXX: we need to manually set 'active' and 'target.current' as dependencies to make sure the value is updated when they changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, target?.current, position]);

  const Wrap = isRootMenu ? ReverseTheme : React.Fragment;
  return (
    <Wrap>
      <ClickAwayListener
        onClickAway={() => {
          onClose?.();
        }}
      >
        <Paper
          sx={[styles.root, active && styles.active, { left, top }, ...sxProps]}
          ref={contextMenuRef}
          {...restProps}
        >
          <MenuList>{children}</MenuList>
        </Paper>
      </ClickAwayListener>
    </Wrap>
  );
}

export default ContextMenu;
