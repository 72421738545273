import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { BreadcrumbsValue } from '@front/ui';

import { getAllBreadcrumbsValues } from './breadcrumbsValues';

export const getBreadcrumbsValuesByRoute = (
  pathname: string,
  query: any,
  t: any
): BreadcrumbsValue[] => {
  const values = getAllBreadcrumbsValues(pathname, query, t);

  if (pathname.includes('settings')) {
    return [values.settings];
  }
  if (pathname.includes('clubs')) {
    return [values.clubs];
  }
  return [];
};

export default function useRouteBreadcrumbsValues(): BreadcrumbsValue[] {
  const { pathname, query } = useRouter();
  const { t } = useTranslation();

  return getBreadcrumbsValuesByRoute(pathname, query, t);
}
