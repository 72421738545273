import React from 'react';

export default function ActionShowNavigationSwitcher({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4C21.25 4 22 4.75 22 6V18C22 19.25 21.25 20 20 20H4C2.75 20 2 19.25 2 18V6C2 4.75 2.75 4 3.75 4H20ZM20 5C20.5523 5 21 5.44772 21 6V18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18V6C3 5.44772 3.44772 5 4 5H20Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5H3V19H12V5ZM9 7H5V9H9V7ZM5 11H9V13H5V11ZM9 15H5V17H9V15Z"
        fill="white"
      />
    </svg>
  );
}
