import useBaseLeftPanel from './useBaseLeftPanel';
import useBaseNavbar from './useBaseNavbar';
import useBaseRightPanel, { RightPanelParamObj } from './useBaseRightPanel';

export default function useBaseLayout<T extends RightPanelParamObj>() {
  const leftPanel = useBaseLeftPanel();
  const rightPanel = useBaseRightPanel<T>();
  const navbar = useBaseNavbar();

  return { ...leftPanel, ...rightPanel, ...navbar };
}
