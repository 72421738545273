import { alpha, Box } from '@mui/material';
import { Theme } from '@mui/material';
import { MaskIcon, SquareAvatar } from '@front/ui';

const styles = {
  root: (size: number) => ({
    width: `${size}px`,
    height: `${size}px`,
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  }),
  inner: (space: number) => ({
    display: 'grid',
    gridTemplate: '1fr 1fr / 1fr 1fr',
    gap: `${space}px`,
    justifyItems: 'center',
    alignItems: 'center',
  }),
  placeholder: {
    width: '100%',
    height: '100%',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  remainingCount: (size: number) => ({
    lineHeight: `${size}px`,
  }),
};

const AVATAR_RATIO = 0.3125; // avatarSize / size
const SPACE_RATIO = 0.0625; // space / size
const FONT_SIZE_RATIO = 0.15; // fontSize / size

export type AvatarGridFolderProps = {
  avatars: {
    avatarUrl: string;
    name: string;
  }[];
  avatarsCount?: number;
  size?: number;
  avatarSize?: number;
  space?: number;
  fontSize?: number;
  mask?: boolean;
};

export default function AvatarGridFolder({
  avatars = [],
  avatarsCount,
  size = 32,
  avatarSize = size * AVATAR_RATIO,
  space = size * SPACE_RATIO,
  mask = true,
  fontSize = size * FONT_SIZE_RATIO,
}: AvatarGridFolderProps) {
  let total = avatars.length;
  if (avatars.length >= 3 && avatarsCount && avatarsCount > avatars.length) {
    // should accept avatarsCount only when avatarsCount > avatars.length
    // and at least 3 avatars should provided
    total = avatarsCount;
  }
  let visibleCount = Math.min(total, 4);
  if (total > 4) {
    visibleCount -= 1; // the last slot shows remaining clubs count
  }
  const remainingCount = total - visibleCount;
  const visibleAvatars = avatars.slice(0, visibleCount);

  const gridAvatars = (
    <Box sx={styles.root(size)}>
      <Box sx={styles.inner(space)}>
        {visibleAvatars.map((avatar, index) => (
          <SquareAvatar key={index} src={avatar.avatarUrl} size={avatarSize}>
            {avatar.name}
          </SquareAvatar>
        ))}
        {remainingCount > 0 && (
          <SquareAvatar
            size={avatarSize}
            blackAndWhite
            sx={[styles.remainingCount(avatarSize), { fontSize }]}
          >
            +{remainingCount}
          </SquareAvatar>
        )}
        {total === 0 && (
          <>
            {[...Array(4)].map((item, index) => (
              <MaskIcon key={index} width={avatarSize} height={avatarSize}>
                <Box sx={styles.placeholder} />
              </MaskIcon>
            ))}
          </>
        )}
      </Box>
    </Box>
  );

  if (!mask) return gridAvatars;
  return <MaskIcon>{gridAvatars}</MaskIcon>;
}
