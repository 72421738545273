import { useState } from 'react';
import { Trans } from 'next-i18next';
import { Theme, Typography } from '@mui/material';
import { randNumber } from '@ngneat/falso';

import usePracticeQuestionResult from '../../../../../hooks/usePracticeQuestionResult';

const styles = {
  correct: {
    color: (theme: Theme) => `${theme.palette.success.light} !important`,
  },
  incorrect: {
    color: (theme: Theme) => `${theme.palette.error.dark} !important`,
  },
};
export default function AnswerHintSubmitted() {
  const { isCorrect, isStreak } = usePracticeQuestionResult();

  const [randomNum] = useState(randNumber({ min: 1, max: 15 }));
  const type = isCorrect ? 'correct' : 'incorrect';

  if (isStreak) {
    return (
      <Typography variant="caption" sx={styles.correct}>
        <Trans
          i18nKey={`quiz::practice.${type}.message.3`}
          components={{
            1: <span />,
          }}
          context="mobile"
        />
      </Typography>
    );
  }
  return (
    <Typography
      variant="caption"
      sx={isCorrect ? styles.correct : styles.incorrect}
    >
      <Trans
        i18nKey={`quiz::practice.${type}.message.${randomNum}`}
        components={{
          1: <span />,
        }}
        context="mobile"
      />
    </Typography>
  );
}
