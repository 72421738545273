export default function MathGreekSymbols({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0905 4.96066C6.23569 4.67834 6.53249 4.5 6.85715 4.5H17.1429C17.6162 4.5 18 4.8731 18 5.33333V7.83333C18 8.29357 17.6162 8.66667 17.1429 8.66667C16.6695 8.66667 16.2857 8.29357 16.2857 7.83333V6.16667H8.57143L12.6857 11.5C12.9143 11.7963 12.9143 12.2037 12.6857 12.5L8.57143 17.8333H16.2857V16.1667C16.2857 15.7064 16.6695 15.3333 17.1429 15.3333C17.6162 15.3333 18 15.7064 18 16.1667V18.6667C18 19.1269 17.6162 19.5 17.1429 19.5H6.85715C6.53249 19.5 6.23569 19.3217 6.0905 19.0393C5.9453 18.757 5.97664 18.4192 6.17143 18.1667L10.9286 12L6.17143 5.83333C5.97664 5.58082 5.9453 5.24298 6.0905 4.96066Z"
        fill="currentColor"
      />
    </svg>
  );
}
