export const getToastAnchor = (el: Element): [x: number, y: number] => {
  if (!el) return [0, 0];
  const rect = el.getBoundingClientRect();

  const offsetY = window.innerHeight - rect.top;
  const offsetX = window.innerWidth - rect.right;

  return [offsetX, offsetY];
};

export default { getToastAnchor };
