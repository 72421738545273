import { MouseEvent, useRef, useState } from 'react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useSortable } from '@dnd-kit/sortable';
import {
  OtherDrag as OtherDragIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import {
  FloatingMenu,
  floatingMenuStyles,
  Icon,
  ResponsiveMenu,
} from '@front/ui';

const styles = {
  tooltipInner: {
    display: 'flex',
    flexDirection: 'column',
  },
  draggableButton: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  menuItem: {
    color: 'error.dark',
    '&:hover': {
      color: (theme: Theme) => `${theme.palette.error.dark} !important`,
    },
  },
};

export type PromptFloatingMenuProps = {
  id: string | number;
  anchorEl: HTMLDivElement | null;
  active: boolean;
  onAddItem: (ev: MouseEvent<HTMLButtonElement>) => void;
  onDeleteItem?: () => void;
  onCloseMenu?: () => void;
  onOpenMenu?: () => void;
};

export default function PromptFloatingMenu({
  id,
  anchorEl,
  active,
  onCloseMenu,
  onOpenMenu,
  onAddItem,
  onDeleteItem,
}: PromptFloatingMenuProps) {
  const { listeners, setActivatorNodeRef, isDragging } = useSortable({
    id,
  });
  const [moreActionMenuOpen, setMoreActionMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleAddButtonClick = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    onAddItem(ev);
  };

  const handleMenuIconClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (moreActionMenuOpen) {
      setMoreActionMenuOpen(false);
      onCloseMenu?.();
    } else {
      setMoreActionMenuOpen(true);
      onOpenMenu?.();
    }
  };

  const menuIconButtonProps = {
    ref: setActivatorNodeRef,
    ...listeners,
    onClick: handleMenuIconClick,
  };

  if (!active && !isDragging && !moreActionMenuOpen) return null;

  return (
    <FloatingMenu
      ref={menuRef}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="left-start"
    >
      <Box sx={floatingMenuStyles.menu}>
        <FloatingMenu.TipButton
          title={
            <Typography sx={styles.tooltipInner} variant="caption">
              <span>
                <b>Click</b> to add below
              </span>
              <span>
                <b>Alt-click</b> to add above
              </span>
            </Typography>
          }
          onClick={handleAddButtonClick}
        >
          <TestAddIcon />
        </FloatingMenu.TipButton>

        <FloatingMenu.TipButton
          sx={styles.draggableButton}
          title={
            isDragging ? undefined : (
              <Typography sx={styles.tooltipInner} variant="caption">
                <span>
                  <b>Drag</b> to move
                </span>
                <span>
                  <b>Click</b> to open menu
                </span>
              </Typography>
            )
          }
          {...menuIconButtonProps}
          selected={moreActionMenuOpen}
        >
          <OtherDragIcon />
        </FloatingMenu.TipButton>
        {moreActionMenuOpen && (
          <ResponsiveMenu
            open={moreActionMenuOpen}
            onClose={() => setMoreActionMenuOpen(false)}
            menuProps={{
              anchorEl: menuRef.current,
              anchorOrigin: {
                vertical: 'center',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'center',
                horizontal: 0,
              },
            }}
            sheetProps={{
              fixedHeight: true,
            }}
          >
            <MenuItem sx={styles.menuItem} onClick={onDeleteItem}>
              <ListItemIcon>
                <Icon name="OtherDelete" width={16} height={16} />
              </ListItemIcon>
              <ListItemText>Delete Prompt</ListItemText>
            </MenuItem>
          </ResponsiveMenu>
        )}
      </Box>
    </FloatingMenu>
  );
}
