import type { FC, ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';

interface Settings {
  direction?: 'ltr' | 'rtl';
  responsiveFontSizes?: boolean;
  theme: 'light' | 'dark';
}

export interface SettingsContextValue {
  settings: Settings;
  saveSettings: (update: Settings) => void;
}

interface SettingsProviderProps {
  children?: ReactNode;
}

const initialSettings: Settings = {
  direction: 'ltr',
  responsiveFontSizes: false,
  theme: 'dark',
};

export const SettingsContext = createContext<SettingsContextValue>({
  settings: initialSettings,
  saveSettings: () => {},
});

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { children } = props;

  const [settings, setSettings] = useState<Settings>(initialSettings);

  const saveSettings = (updatedSettings: Settings): void => {
    setSettings(updatedSettings);
  };

  const memoizedValue = useMemo(() => {
    const value = {
      settings,
      saveSettings,
    };
    return value;
  }, [settings]);

  return (
    <SettingsContext.Provider value={memoizedValue}>
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;
