import React, { MouseEvent, ReactNode } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Box from '@mui/material/Box';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import { alpha, Theme } from '@mui/material/styles';
import {
  OtherCheckedCircleSolid as OtherCheckedCircleSolidIcon,
  OtherCircleOutline as OtherCircleOutlineIcon,
} from '@front/icon';

const styles = {
  wrap: {
    px: 0.5,
    py: '1.5px',
    width: '100%',
    position: 'relative',
  },
  processingWrap: {
    '& + .menu-step-wrap, & + div:has(.menu-step-group-wrap)': {
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 2,
        height: 8,
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
        top: 0,
        left: 19,
      },
    },
    '&:has(+ .menu-step-wrap), &:has(+ .menu-step-group-wrap-root)': {
      '&:after': {
        content: '""',
        position: 'absolute',
        width: 2,
        height: 8,
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
        bottom: 0,
        left: 19,
      },
    },
  },
  button: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    gap: 1,
    fontSize: 14,
    height: 27,
    px: 1,
    opacity: 0.64,
    borderRadius: 1,

    '&.Mui-disabled': {
      opacity: 0.3,
    },
    '&.is-active': {
      opacity: 1,
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    },
    '&:not(.is-active):hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
  buttonIcon: {
    flex: '0 0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      width: 16,
      height: 16,
    },
  },
  checkedIcon: {
    color: 'success.light',
  },
  alert: {
    position: 'absolute',
    right: 36,
    top: '50%',
    transform: 'translateY(-50%)',
    bgcolor: 'error.dark',
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
};

type MenuButtonProps = {
  icon?: ReactNode;
  active?: boolean;
  disabled?: boolean;
  alert?: boolean;
  children: ReactNode;
  href?: string;
  shallow?: boolean;
  onClick?: (ev: MouseEvent) => void;
  checked?: boolean;
} & Omit<ButtonBaseProps, 'prefix'>;

const MenuStepButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
  (
    {
      sx,
      children,
      icon,
      active = false,
      disabled = false,
      checked = false,
      alert = false,
      href,
      shallow,
      onClick,
      ...rest
    },
    ref
  ) => {
    const pathname = usePathname();
    const handleClick = (ev: MouseEvent) => {
      onClick?.(ev);
    };
    const sxProps = Array.isArray(sx) ? sx : [sx];

    const isActive = active || pathname === href;

    return (
      <Box
        className="menu-step-wrap"
        sx={[styles.wrap, !icon && styles.processingWrap]}
      >
        <ButtonBase
          ref={ref}
          sx={[styles.button, ...sxProps]}
          className={`menu-step-button ${isActive ? 'is-active' : ''}`}
          onClick={handleClick}
          disabled={disabled}
          {...(href ? { href, component: Link, shallow } : {})}
          {...rest}
        >
          {checked && !icon && (
            <Box
              sx={styles.checkedIcon}
              component={OtherCheckedCircleSolidIcon}
              width={16}
              height={16}
            />
          )}
          {!checked && !icon && (
            <OtherCircleOutlineIcon width={16} height={16} />
          )}

          {!!icon && <Box sx={styles.buttonIcon}>{icon}</Box>}

          <Box className="menu-button-inner">
            <span className="menu-button-content">{children}</span>
          </Box>
          {alert && <Box sx={styles.alert} />}
        </ButtonBase>
      </Box>
    );
  }
);

export default MenuStepButton;
