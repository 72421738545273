import { createContext } from 'react';

export type TableLayoutPaneContextValue = {
  horizontalScrolled: boolean;
};

export const tableLayoutPaneContextInitialValue: TableLayoutPaneContextValue = {
  horizontalScrolled: false,
};

const TableLayoutPaneContext = createContext<TableLayoutPaneContextValue>(
  tableLayoutPaneContextInitialValue
);

export default TableLayoutPaneContext;
