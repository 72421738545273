import React from 'react';
import Box from '@mui/material/Box';

import SkeletonItem from './components/ContentItem/SkeletonItem';
import { ChannelLayoutLoadingConfig } from './types';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export type ChannelLayoutSkeletonProps = Pick<
  ChannelLayoutLoadingConfig,
  'settings'
>;

export default function ChannelLayoutSkeleton({
  settings,
}: ChannelLayoutSkeletonProps) {
  return (
    <Box sx={styles.root} mb={2}>
      {[...Array(settings?.count || 2)].map((_, index) => (
        <SkeletonItem key={index} />
      ))}
    </Box>
  );
}
