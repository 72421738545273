import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@front/ui';

import { FormLayoutBaseItem } from '../types';

const styles = {
  colorContainer: {
    width: 12,
    height: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorIcon: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
};
export default function LabelIcon({
  icon,
}: {
  icon: FormLayoutBaseItem['icon'];
}) {
  if (!icon) return null;
  if (typeof icon === 'string')
    return <Icon name={icon} width={12} height={12} />;

  return (
    <Box sx={styles.colorContainer}>
      <Box sx={[styles.colorIcon, { bgcolor: icon.value }]} />
    </Box>
  );
}
