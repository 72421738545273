import { useCallback } from 'react';
import { useAppSelector } from '@app/web/src/hooks/redux';
import { Question } from '@app/web/src/reducers/quizReducer/types';
import {
  getHighlights,
  getLeftQuestionComponents,
  getLeftQuestionContentHtml,
  replaceContentFromSource,
} from '@lib/web/editor';

export default function useQuizContentEnricher() {
  const creatorQuestionGroups = useAppSelector(
    (st) => st.quiz.creatorQuestionGroups
  );

  const contentEnricher = useCallback(
    ({
      content,
      question,
    }: {
      content: string;
      question: Question | undefined;
    }) => {
      if (!question) return content;

      return replaceContentFromSource(content, {
        materials: question.creatorQuestionInfo?.materials,
        highlightIds: getHighlights(
          getLeftQuestionContentHtml(
            getLeftQuestionComponents(
              question.creatorQuestionInfo,
              creatorQuestionGroups
            )
          )
        ),
      });
    },
    [creatorQuestionGroups]
  );

  return {
    contentEnricher,
  };
}
