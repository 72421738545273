import { MouseEvent, ReactNode } from 'react';
import { Menu, MenuProps, Theme, useMediaQuery } from '@mui/material';

import BottomSheet from '../BottomSheet';

const styles = {
  main: {
    '& .MuiMenuItem-root': {
      minHeight: { xs: 45, md: 28 },
    },
    '& .MuiTypography-body1': {
      fontSize: { xs: 16, md: 14 },
    },
  },
  paper: {
    mx: 1,
    '& .MuiList-root': {
      minWidth: 128,
    },
    '& .MuiMenu-paper': {
      bgcolor: 'background.menu',
      boxShadow: 9,
      backdropFilter: 'blur(50px)',
      borderRadius: 1,
      border: (theme: Theme) =>
        theme.palette.mode === 'light'
          ? '1px solid rgba(8, 8, 8, 0.05)'
          : '1px solid rgba(255, 255, 255, 0.1)',
    },
  },
};

function DesktopMenu({ children, sx, slotProps, ...rest }: MenuProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Menu
      marginThreshold={12}
      sx={[styles.main, styles.paper, ...sxProps]}
      {...rest}
    >
      {children}
    </Menu>
  );
}

export type ResponsiveMenuProps = {
  open: boolean;
  onClose: (ev?: MouseEvent<Element> | object) => void;
  children: ReactNode;

  menuProps: Omit<MenuProps, 'open' | 'onClose' | 'children'>;

  sheetProps?: {
    defaultHeight?: number;
    defaultContentHeight?: number;
    fixedHeight?: boolean;
    disableDrag?: boolean;
  };
};
export default function ResponsiveMenu({
  open,
  onClose,
  children,
  menuProps,
  sheetProps,
}: ResponsiveMenuProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (mdUp)
    return (
      <DesktopMenu open={open} onClose={onClose} {...menuProps}>
        {children}
      </DesktopMenu>
    );
  return (
    <BottomSheet sx={styles.main} open={open} onClose={onClose} {...sheetProps}>
      {children}
    </BottomSheet>
  );
}
