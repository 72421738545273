import { Box } from '@mui/material';
import RichText from '@lib/ia/src/components/RichText';

import { CtaRichText } from '../../types';

const styles = {
  container: { mt: 1 },
};

type NotificationCtaButtonProps = CtaRichText;

export default function NotificationRichText({
  value,
}: NotificationCtaButtonProps) {
  return (
    <Box sx={styles.container}>
      <RichText text={value} />
    </Box>
  );
}
