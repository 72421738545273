import { Box, Typography } from '@mui/material';

import { AlbumLayoutTextItem } from '../../types';

import IaAlbumBaseItem from './IaAlbumBaseItem';

const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  content: {
    fontSize: 40,
  },
};

type IaAlbumTextItemProps = AlbumLayoutTextItem;

export default function IaAlbumTextItem(item: IaAlbumTextItemProps) {
  const { content, contentSx, ...rest } = item;
  const contentSxProps = Array.isArray(contentSx) ? contentSx : [contentSx];
  return (
    <IaAlbumBaseItem {...rest} item={item}>
      <Box sx={styles.root}>
        <Typography sx={[styles.content, ...contentSxProps]}>
          {content}
        </Typography>
      </Box>
    </IaAlbumBaseItem>
  );
}
