import React from 'react';

export default function EditorPreamble({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M2 11.9996L6.33659 7.70106L7.57536 8.92896L4.47755 11.9996L7.57536 15.0702L6.33659 16.2981L2 11.9996ZM9.18472 19.5788L13.1148 4L14.8144 4.42117L10.8852 20L9.1856 19.5788H9.18472ZM16.4238 15.0702L19.5216 11.9996L16.4238 8.92896L17.6634 7.70106L22 11.9996L17.6634 16.2981L16.4238 15.0702Z"
        fill="currentColor"
      />
    </svg>
  );
}
