import { useCallback, useRef, useState } from 'react';
import { toast } from '@front/ui';
import { useAuth } from '@lib/web/apis';
import { useAddWatchingEvent } from '@lib/web/thread/hooks/core/useAddWatchingEvent';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { queryAllChannels } from '@lib/web/thread/utils/channelUtils';
import { cloneDeep } from 'lodash';
import { Channel } from 'stream-chat';

/**
 * In this hook, we provide a list of channels which contains both parent and child channels,
 * then we use these channels' cid to find those additional child channels by using
 * channel.data.ancestorChannelCids
 * for example,
 *
 *  channel A (ancestorChannelCids: [])
 *     child channel B (ancestorChannelCids: [A])
 *         child channel C (ancestorChannelCids: [A, B])
 *
 * given channel A, we can get child channels B and C by filter  ancestorChannelCids has A
 */
export const useChannelsHierarchy = () => {
  const { chatClient } = useThread();
  /**
   * record parent -> child channels relationship so we can easily find child channels
   */
  const [parentCidToChildChannels, setParentCidToChildChannels] = useState<
    Record<string, Channel[]>
  >({});

  /**
   * record which channel cid we have checked, only when there is new channel comes in, we need to fetch its parent's descendants
   */
  const checkedCid = useRef(new Set<string>());

  const { member } = useAuth();
  const myMemberId = member?.memberId;

  const fetchChannelDescendants = useCallback(
    (channelCids: string[]) => {
      if (!chatClient || !myMemberId || channelCids.length === 0) {
        return [] as Channel[];
      }

      try {
        return queryAllChannels(chatClient, {
          $and: [
            /**
             * this condition is to make sure user have the permission to query these channels
             */
            {
              $or: [
                {
                  type: 'team',
                  members: { $in: [myMemberId] },
                },
                {
                  type: 'public',
                },
              ],
            },

            /**
             * to get all the descendants of selected channels, we need to find all the channels which has the ancestorCids of selected channels
             * for example, given
             * channel A (ancestorChannelCids: [])
             *     child channel B (ancestorChannelCids: [A])
             *         child channel C (ancestorChannelCids: [A, B])
             *            child channel D (ancestorChannelCids: [A, B, C])
             *
             * when using channelCids = B, we will build
             *  { ancestorChannelCids: { $eq: [B] }}
             *  and find C and D channel
             */
            channelCids.length === 1
              ? {
                  ancestorChannelCids: {
                    $eq: [channelCids[0]],
                  },
                }
              : ({
                  $or: channelCids.map((cid) => ({
                    ancestorChannelCids: { $eq: [cid] },
                  })),
                } as any), // XXX: $or type need at least 2 member, it's hard to make type right, so we use 'any'
          ],
        });
      } catch (e) {
        toast.error('cannot get child threads');
        console.error('cannot get child threads', e);
        return [] as Channel[];
      }
    },
    [chatClient, myMemberId]
  );

  const updateParentCidToChildChannelsMap = useCallback(
    (channels: Channel[]) => {
      if (channels.length === 0) return;

      const newParentIdToChildChannels = {} as Record<string, Channel[]>;

      for (const channel of channels) {
        const parentChannelCid = channel.data?.parentChannelCid as
          | string
          | undefined;
        if (!parentChannelCid) continue;

        if (!(parentChannelCid in newParentIdToChildChannels)) {
          newParentIdToChildChannels[parentChannelCid] = [];
        }

        newParentIdToChildChannels[parentChannelCid].push(channel);
      }

      setParentCidToChildChannels((prev) => ({
        ...prev,
        ...Object.keys(newParentIdToChildChannels).reduce((acc, parentCid) => {
          /**
           * if parentCid already have its child channels mapping, we need to merge the new result into it
           */
          if (parentCid in prev) {
            return {
              ...acc,
              [parentCid]: [
                ...prev[parentCid],
                ...newParentIdToChildChannels[parentCid].filter(
                  (channel) =>
                    !prev[parentCid].some((c) => c.cid === channel.cid)
                ),
              ],
            };
          }

          return {
            ...acc,
            [parentCid]: newParentIdToChildChannels[parentCid],
          };
        }, {}),
      }));
    },
    []
  );

  const checkChannelsHierarchy = useCallback(
    async (channels: Channel<StreamChatGenerics>[]) => {
      const toFetchCids: string[] = [];
      for (const channel of channels) {
        const parentChannelCid = channel.data?.parentChannelCid;

        if (!checkedCid.current.has(channel.cid)) {
          checkedCid.current.add(channel.cid);
          toFetchCids.push(channel.cid);
        }
        if (parentChannelCid && !checkedCid.current.has(parentChannelCid)) {
          checkedCid.current.add(parentChannelCid);
          toFetchCids.push(parentChannelCid);
        }
      }

      if (toFetchCids.length === 0) return;

      /**
       * before fetching channel descendants, we use input channels to build the map first,
       * so the ux will be better because some channels already have parent,child relationships
       */
      updateParentCidToChildChannelsMap(channels);

      const descendantChannels = await fetchChannelDescendants(toFetchCids);

      updateParentCidToChildChannelsMap(descendantChannels);
    },
    [fetchChannelDescendants, updateParentCidToChildChannelsMap]
  );

  const replaceChildChannel = useCallback((channel: Channel) => {
    setParentCidToChildChannels((prev) => {
      const newParentCidToChildChannels = cloneDeep(prev);
      Object.values(newParentCidToChildChannels).forEach((childChannels) => {
        childChannels.forEach((childChannel, index) => {
          if (childChannel.cid === channel.cid) {
            childChannels[index] = channel;
          }
        });
      });
      return newParentCidToChildChannels;
    });
  }, []);

  useAddWatchingEvent({
    scope: 'channelHierarchy',
    key: 'notification.added_to_channel',
    callback: async (event) => {
      if (event.type !== 'notification.added_to_channel') return;
      if (!chatClient || !event.channel_type || !event.channel_id) return;

      const newChannel = chatClient.channel(
        event.channel_type,
        event.channel_id
      );
      /**
       * XXX: this watch() function is necessary, but when there are many channels, it will cause 429 too many requests error
       * we might need to find a better way to handle this
       */
      await newChannel.watch();

      void checkChannelsHierarchy([newChannel]);
    },
  });

  useAddWatchingEvent({
    scope: 'channelHierarchy',
    key: 'message.new',
    callback: (event) => {
      if (event.type !== 'message.new') return;
      if (!chatClient || !event.channel_type || !event.channel_id) return;

      replaceChildChannel(
        chatClient.channel(event.channel_type, event.channel_id)
      );
    },
  });

  useAddWatchingEvent({
    scope: 'channelHierarchy',
    key: 'channel.updated',
    callback: (event) => {
      if (event.type !== 'channel.updated') return;
      if (!chatClient || !event.channel_type || !event.channel_id) return;

      replaceChildChannel(
        chatClient.channel(event.channel_type, event.channel_id)
      );
    },
  });

  useAddWatchingEvent({
    scope: 'channelHierarchy',
    key: 'notification.mark_read',
    callback: (event) => {
      if (event.type !== 'notification.mark_read') return;
      if (!chatClient || !event.channel_type || !event.channel_id) return;

      replaceChildChannel(
        chatClient.channel(event.channel_type, event.channel_id)
      );
    },
  });

  return {
    parentCidToChildChannels,
    checkChannelsHierarchy,
  };
};
