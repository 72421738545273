import React from 'react';

export default function OtherRotateDevice({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15 60H60V90H90V105H15V60Z"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 60C12.5 58.6193 13.6193 57.5 15 57.5H60C61.3807 57.5 62.5 58.6193 62.5 60V87.5H90C91.3807 87.5 92.5 88.6193 92.5 90V105C92.5 106.381 91.3807 107.5 90 107.5H15C13.6193 107.5 12.5 106.381 12.5 105V60ZM17.5 62.5V102.5H87.5V92.5H60C58.6193 92.5 57.5 91.3807 57.5 90V62.5H17.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60 92.5C58.6193 92.5 57.5 91.3807 57.5 90L57.5 15C57.5 13.6193 58.6193 12.5 60 12.5L105 12.5C106.381 12.5 107.5 13.6193 107.5 15L107.5 90C107.5 91.3807 106.381 92.5 105 92.5L60 92.5ZM62.5 87.5L102.5 87.5L102.5 17.5L62.5 17.5L62.5 87.5ZM82.5 83.75C79.7386 83.75 77.5 81.5114 77.5 78.75C77.5 75.9886 79.7386 73.75 82.5 73.75C85.2614 73.75 87.5 75.9886 87.5 78.75C87.5 81.5114 85.2614 83.75 82.5 83.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.8243 26.0213C36.4218 25.4235 30.7758 27.2241 26.6038 31.3962C22.2898 35.7102 20.5119 41.5989 21.298 47.1668C21.4518 48.2556 22.4591 49.0137 23.5479 48.86C24.6368 48.7062 25.3949 47.6989 25.2411 46.61C24.6245 42.2431 26.0171 37.6146 29.4196 34.212C32.7581 30.8735 37.2771 29.4699 41.57 30.0009C41.636 30.009 41.7023 30.0139 41.7688 30.0154L42.0571 30.022L39.4962 32.583C38.7186 33.3605 38.7186 34.6212 39.4962 35.3988C40.2738 36.1764 41.5344 36.1764 42.312 35.3988L48.1648 29.546C48.9424 28.7684 48.9424 27.5078 48.1648 26.7302L42.4051 20.9705C41.6276 20.1929 40.3669 20.1929 39.5893 20.9705C38.8117 21.7481 38.8117 23.0088 39.5893 23.7863L41.8243 26.0213Z"
        fill="currentColor"
      />
    </svg>
  );
}
