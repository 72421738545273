import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { ComposerBlock } from '@lib/web/composer';

import { McqChoiceValue } from '../types';

import Choice from './Choice';

const styles = {
  choiceContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  captionContainer: {
    mt: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  caption: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
  },
  captionNumber: {
    color: 'text.primary',
  },
};

type MultipleChoiceQuestionProps = {
  questionId: string;
  choiceValues: McqChoiceValue[];
  handleBlocksChange: (id: string, blocks: ComposerBlock[]) => void;
  onSelect: (id: string) => void;
  blankOptionEnabled?: boolean;
  readonly?: boolean;
};

export default function MultipleChoices({
  questionId,
  choiceValues,
  handleBlocksChange,
  onSelect,
  blankOptionEnabled,
  readonly,
}: MultipleChoiceQuestionProps) {
  const { t } = useTranslation('editor');

  return (
    <Box>
      <Box sx={styles.choiceContainer}>
        {choiceValues.map((choiceValue) => (
          <Choice
            key={choiceValue.id}
            questionId={questionId}
            value={choiceValue}
            onBlocksChange={handleBlocksChange}
            onSelect={onSelect}
            readonly={readonly}
          />
        ))}
        {blankOptionEnabled && <Choice.LeaveBlank content="Leave blank" />}
      </Box>
      {choiceValues.length > 0 && (
        <Box sx={styles.captionContainer}>
          <Typography variant="caption" sx={styles.caption}>
            {t('select')}{' '}
            <Typography variant="caption" sx={styles.captionNumber}>
              1
            </Typography>{' '}
            {t('correct answer')}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
