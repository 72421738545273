import React from 'react';

export default function NFTColor({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 4C7.58222 4 4 7.58222 4 12C4 16.4178 7.58222 20 12 20C12.7378 20 13.3333 19.4044 13.3333 18.6667C13.3333 18.32 13.2 18.0089 12.9867 17.7689C12.7822 17.5378 12.6489 17.2267 12.6489 16.8889C12.6489 16.1511 13.2444 15.5556 13.9822 15.5556H15.5556C18.0089 15.5556 20 13.5644 20 11.1111C20 7.18222 16.4178 4 12 4ZM7.11111 12C6.37333 12 5.77778 11.4044 5.77778 10.6667C5.77778 9.92889 6.37333 9.33333 7.11111 9.33333C7.84889 9.33333 8.44444 9.92889 8.44444 10.6667C8.44444 11.4044 7.84889 12 7.11111 12ZM9.77778 8.44444C9.04 8.44444 8.44444 7.84889 8.44444 7.11111C8.44444 6.37333 9.04 5.77778 9.77778 5.77778C10.5156 5.77778 11.1111 6.37333 11.1111 7.11111C11.1111 7.84889 10.5156 8.44444 9.77778 8.44444ZM14.2222 8.44444C13.4844 8.44444 12.8889 7.84889 12.8889 7.11111C12.8889 6.37333 13.4844 5.77778 14.2222 5.77778C14.96 5.77778 15.5556 6.37333 15.5556 7.11111C15.5556 7.84889 14.96 8.44444 14.2222 8.44444ZM16.8889 12C16.1511 12 15.5556 11.4044 15.5556 10.6667C15.5556 9.92889 16.1511 9.33333 16.8889 9.33333C17.6267 9.33333 18.2222 9.92889 18.2222 10.6667C18.2222 11.4044 17.6267 12 16.8889 12Z"
        fill="currentColor"
      />
    </svg>
  );
}
