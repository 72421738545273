import { FeedCtaItemConfig } from '../types';

import FeedCtaBigCardNumber from './FeedCtaBigCardNumber';
import FeedCtaButton from './FeedCtaButton';
import FeedCtaCustomize from './FeedCtaCustomize';
import FeedCtaRichText from './FeedCtaRichText';

type FeedCtaProps = FeedCtaItemConfig;

export default function FeedCta({ ...rest }: FeedCtaProps) {
  if (rest.type === 'button') {
    return <FeedCtaButton {...rest} />;
  }
  if (rest.type === 'bigCardNumber') {
    return <FeedCtaBigCardNumber {...rest} />;
  }
  if (rest.type === 'richText') {
    return <FeedCtaRichText {...rest} />;
  }
  if (rest.type === 'customize') {
    return <FeedCtaCustomize {...rest} />;
  }
  return null;
}
