import React from 'react';

export default function CryptoCurrencyUSDCAlt({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 24C18.65 24 24 18.65 24 12C24 5.34996 18.65 0 12 0C5.34996 0 0 5.34996 0 12C0 18.65 5.34996 24 12 24Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M15.3001 13.9001C15.3001 12.1501 14.2501 11.5501 12.1501 11.3002C10.6501 11.1001 10.3501 10.7002 10.3501 10.0001C10.3501 9.30002 10.8501 8.85014 11.8501 8.85014C12.7501 8.85014 13.2501 9.15014 13.5001 9.90014C13.5501 10.0501 13.7001 10.1501 13.8501 10.1501H14.65C14.8501 10.1501 15.0001 10.0001 15.0001 9.80018V9.75014C14.8 8.6501 13.9 7.80014 12.7501 7.70018V6.50018C12.7501 6.30014 12.6001 6.15014 12.3501 6.1001H11.6001C11.4001 6.1001 11.2501 6.2501 11.2 6.50018V7.65014C9.70002 7.85018 8.7501 8.85014 8.7501 10.1002C8.7501 11.7502 9.75006 12.4001 11.8501 12.6502C13.2501 12.9001 13.7001 13.2001 13.7001 14.0002C13.7001 14.8002 13 15.3502 12.0501 15.3502C10.75 15.3502 10.3 14.8001 10.15 14.0501C10.1001 13.8502 9.9501 13.7501 9.8001 13.7501H8.95002C8.7501 13.7501 8.6001 13.9001 8.6001 14.1001V14.1502C8.80002 15.4001 9.60006 16.3001 11.2501 16.5502V17.7502C11.2501 17.9501 11.4001 18.1001 11.65 18.1501H12.4C12.6001 18.1501 12.7501 18.0001 12.8001 17.7502V16.5502C14.3001 16.3001 15.3001 15.2501 15.3001 13.9001Z"
        fill="white"
      />
      <path
        d="M5.00004 9.54992C3.54996 13.4 5.55 17.75 9.45 19.1499C9.6 19.25 9.75 19.4499 9.75 19.5999V20.3C9.75 20.4 9.75 20.45 9.69996 20.4999C9.65004 20.7 9.45 20.7999 9.24996 20.7C6.45 19.8 4.29996 17.6499 3.39996 14.85C1.89996 10.1 4.5 5.04992 9.24996 3.54992C9.3 3.5 9.39996 3.5 9.45 3.5C9.65004 3.54992 9.75 3.69992 9.75 3.89996V4.59992C9.75 4.85 9.65004 5 9.45 5.09996C7.40004 5.84996 5.75004 7.44992 5.00004 9.54992ZM14.2998 3.74999C14.3498 3.54995 14.5498 3.45 14.7498 3.54995C17.4998 4.44995 19.6998 6.59999 20.5998 9.44999C22.0998 14.2 19.4998 19.25 14.7498 20.75C14.6998 20.8 14.5998 20.8 14.5498 20.8C14.3498 20.75 14.2498 20.6 14.2498 20.4V19.7C14.2498 19.45 14.3498 19.3 14.5498 19.2C16.5998 18.45 18.2498 16.85 18.9998 14.75C20.4498 10.9 18.4498 6.54995 14.5498 5.15003C14.3998 5.04995 14.2498 4.85003 14.2498 4.64999V3.95004C14.2498 3.84995 14.2498 3.80003 14.2998 3.74999Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
}
