import { useEffect, useState } from 'react';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { countWords } from '@front/helper';
import { TextEditorTypeText as TextEditorTypeTextIcon } from '@front/icon';
import { SquareAvatar } from '@front/ui';
import { AnswerFormatType } from '@lib/web/apis';

import usePracticeQuestion from '../../../hooks/usePracticeQuestion';
import usePracticeQuestionAnswers from '../../../hooks/usePracticeQuestionAnswers';

const styles = {
  textOnly: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
    color: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? theme.palette.alpha.lightA64
        : theme.palette.alpha.darkerA50,

    span: {
      color: 'text.primary',
      fontWeight: (theme: Theme) => (theme.palette.mode === 'dark' ? 600 : 400),
    },
  },
  error: {
    color: 'error.dark',
    span: {
      color: 'error.dark',
    },
  },

  root: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  avatar: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    '& svg': {
      color: (theme: Theme) => theme.palette.text.primary,
    },
  },
  content: {
    display: 'grid',
  },
  label: {
    fontSize: 12,
    color: 'grey.500',
  },
  wordCount: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
  },
};

type WordLimitInformationProps = {
  text: string;
  sx?: BoxProps['sx'];
  textOnly?: boolean;
};

export default function WordLimitInformation({
  text,
  sx,
  textOnly,
}: WordLimitInformationProps) {
  const { answers: currentAnswers = [] } = usePracticeQuestionAnswers();
  const { creatorQuestionInfo } = usePracticeQuestion();
  const [showError, setShowError] = useState(false);

  const sxProps = Array.isArray(sx) ? sx : [sx];

  const userValue = currentAnswers[0] || '';
  const wordCount = countWords(userValue);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const maybeShowErrorForThreeSeconds = () => {
      const userValueEvent = currentAnswers[1];

      if (userValueEvent === 'show-error') {
        setShowError(true);
        timer = setTimeout(() => {
          setShowError(false);
        }, 3000);
      } else {
        setShowError(false);
      }
    };

    maybeShowErrorForThreeSeconds();

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [currentAnswers]);

  if (
    creatorQuestionInfo?.answerFormatType !== AnswerFormatType.Essay &&
    creatorQuestionInfo?.answerFormatType !== AnswerFormatType.FreeResponse
  ) {
    return null;
  }

  const wordLimitMax = {
    [AnswerFormatType.Essay]:
      creatorQuestionInfo.isOfferWordLimit &&
      creatorQuestionInfo.essayAnswerWordLimitMax,
    [AnswerFormatType.FreeResponse]:
      creatorQuestionInfo.isOfferWordLimit &&
      creatorQuestionInfo.freeResponseAnswerWordLimitMax,
  }[creatorQuestionInfo.answerFormatType as number];

  if (textOnly) {
    return (
      <Typography sx={[styles.textOnly, showError && styles.error, ...sxProps]}>
        {text}:{' '}
        <span>
          {wordCount}
          {wordLimitMax ? `/${wordLimitMax}` : ''}
        </span>
      </Typography>
    );
  }

  return (
    <Box sx={[styles.root, showError && styles.error, ...sxProps]}>
      <SquareAvatar sx={styles.avatar}>
        <TextEditorTypeTextIcon />
      </SquareAvatar>
      <Box sx={styles.content}>
        <Typography variant="caption" sx={styles.label}>
          {text}
        </Typography>
        <Typography variant="caption" sx={styles.wordCount}>
          {wordCount} {wordLimitMax ? `/${wordLimitMax}` : ''}
        </Typography>
      </Box>
    </Box>
  );
}
