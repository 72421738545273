import { service } from '../constants';

import api from './client';

const notificationApi = {
  read: () => api.post('notification/v1/me/read', { service }),
  readTopic: (params: ReadTopicNotificationReq = {}) =>
    api.post('notification/v1/channel/topic/read/me', params),
  unsubscribe: (params: UnsubscribeTopicReq) =>
    api.post('notification/v1/channel/topic/unsubscribe', params),
  getThreadMessagePayload: (params: GetThreadMessagePayloadReq) =>
    api.post<Response<GetThreadMessageNotificationPayloadRes[]>>(
      'notification/v1/thread-message/payload',
      params
    ),
  setThreadMessageStaticPayload: (params: SetThreadMessageStaticPayloadReq) =>
    api.post<Response<SetThreadMessageStaticPayloadRes[]>>(
      'notification/v1/thread-message/static/payload/set',
      params
    ),
  getThreadMessageStaticPayload: (params: GetThreadMessageStaticPayloadReq) =>
    api.post<Response<GetThreadMessageStaticPayloadRes[]>>(
      'notification/v1/thread-message/static/payload',
      params
    ),
};

export default notificationApi;
