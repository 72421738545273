import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { useInView } from '@react-spring/web';

import Scrollbar, { ScrollbarProps } from './Scrollbar';

const styles = {
  root: {
    display: 'flex',
  },
  maskLeft: {
    maskImage: 'linear-gradient(-90deg, #000000 90%, transparent 100%)',
  },
  maskRight: {
    maskImage: 'linear-gradient(90deg, #000000 90%, transparent 100%)',
  },
  maskBoth: {
    maskImage:
      'linear-gradient(90deg, transparent, black 10%, black 90%, transparent)',
  },
};
export default function ScrollHorizontalContainer({
  children,
  ...rest
}: PropsWithChildren & ScrollbarProps) {
  const [leftRef, leftOnScreen] = useInView();
  const [rightRef, rightOnScreen] = useInView();
  return (
    <Scrollbar
      sx={[
        !leftOnScreen && rightOnScreen && styles.maskLeft,
        !rightOnScreen && leftOnScreen && styles.maskRight,
        !rightOnScreen && !leftOnScreen && styles.maskBoth,
      ]}
      {...rest}
    >
      <Box sx={styles.root}>
        <Box ref={leftRef} width="1px" />
        {children}
        <Box ref={rightRef} width="1px" />
      </Box>
    </Scrollbar>
  );
}
