import { ReactElement } from 'react';
import { alpha, Theme } from '@mui/material';

import SimpleTooltip from '../SimpleTooltip';

import { PayoffMessageProps } from './components/PayoffMessage';
import PayoffSteps from './components/PayoffSteps';

const styles = {
  tooltip: {
    '& .MuiTooltip-tooltip': {
      p: 0,
      maxWidth: '351px',
      overflow: 'hidden',
      background: '#151A28',
      borderRadius: 1,
      border: (theme: Theme) =>
        `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    },
  },
};

type PayoffTooltipProps = {
  steps: PayoffMessageProps[];
  children: ReactElement;
};

export default function PayoffTooltip({ steps, children }: PayoffTooltipProps) {
  return (
    <SimpleTooltip
      enterDelay={2000}
      slotProps={{
        popper: {
          sx: styles.tooltip,
        },
      }}
      title={<PayoffSteps steps={steps} />}
    >
      {children}
    </SimpleTooltip>
  );
}
