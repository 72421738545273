import React from 'react';
import Link from 'next/link';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { Button, Icon, MaskIcon } from '@front/ui';

const styles = {
  section: {
    display: 'flex',
    gap: 1,
    py: 1,
  },

  icon: {
    width: 32,
    height: 32,
    minWidth: 32,
    minHeight: 32,
    '& svg': {
      width: 20,
      height: 20,
    },
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },

  textSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },

  button: {
    width: '155px',
    px: 0,
  },
};

export type CtaButtonSectionProps = {
  icon: string;
  title: string;
  description: React.ReactNode;
  button: {
    href: string;
    prefixIcon: string;
    text: string;
  };
};

export default function CtaButtonSection({
  icon,
  title,
  description,
  button,
}: CtaButtonSectionProps) {
  return (
    <Box sx={styles.section}>
      <MaskIcon sx={styles.icon}>
        <Icon name={icon} />
      </MaskIcon>
      <Box sx={styles.content}>
        <Box sx={styles.textSection}>
          <Typography variant="chatHeaderBold">{title}</Typography>
          <Typography variant="chatBody">{description}</Typography>
        </Box>
        <Button
          prefixIcon={<Icon name={button.prefixIcon} />}
          sx={styles.button}
          component={Link}
          href={button.href}
        >
          {button.text}
        </Button>
      </Box>
    </Box>
  );
}
