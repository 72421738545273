import { useContext } from 'react';
import IaItemStatusContext, {
  IaItemStatusContextValue,
} from '@lib/ia/src/core/IaItemStatus/IaItemStatusContext';

export function useIaItemStatus<T extends Record<string, any>>() {
  const { getItemStatus, getItemStatusAsync } =
    useContext<IaItemStatusContextValue<T>>(IaItemStatusContext);

  return {
    getItemStatus,
    getItemStatusAsync,
  };
}
