import React from 'react';

export default function OtherPendingFriend({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.7268 12.5C16.7359 12.5 18.3632 10.8219 18.3632 8.75C18.3632 6.67812 16.7359 5 14.7268 5C12.7177 5 11.0905 6.67812 11.0905 8.75C11.0905 10.8219 12.7177 12.5 14.7268 12.5ZM14.7268 14.375C12.2996 14.375 7.4541 15.6313 7.4541 18.125V20H21.9996V18.125C21.9996 15.6313 17.1541 14.375 14.7268 14.375Z"
        fill="currentColor"
      />
      <path
        d="M5.74625 7.70703C3.67625 7.70703 2 9.38703 2 11.457C2 13.527 3.67625 15.207 5.74625 15.207C7.82 15.207 9.5 13.527 9.5 11.457C9.5 9.38703 7.82 7.70703 5.74625 7.70703ZM5.75 14.457C4.0925 14.457 2.75 13.1145 2.75 11.457C2.75 9.79953 4.0925 8.45703 5.75 8.45703C7.4075 8.45703 8.75 9.79953 8.75 11.457C8.75 13.1145 7.4075 14.457 5.75 14.457Z"
        fill="currentColor"
      />
      <path
        d="M5.9375 9.5822H5.375V11.8322L7.34375 13.0135L7.625 12.5522L5.9375 11.551V9.5822Z"
        fill="currentColor"
      />
    </svg>
  );
}
