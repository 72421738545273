export const enum ExamViewType {
  Math = 'MATH',
  Verbal = 'VERBAL',
  Writing = 'WRITING',
  Custom = 'CUSTOM',
}

export const enum ExamMode {
  Unknown = 0,
  Practice = 1,
  MockExam = 2,
  Challenge = 4,
}

export const enum ExamQuestionDetailType {
  SingleChoice = 1,
  TFNotGiven_T = 21,
  TFNotGiven_F = 22,
  TFNotGiven_NG = 23,
  Unscramble = 3,
  ShortAnswer = 4,
  GridIn = 5,
  Matching = 6,
  Equation = 7,
  MultipleChoice = 8,
}

export const enum ExamQuestionMaterialType {
  Instruction = 1,
  Header = 2,
  Passage = 3,
  TextVariable = 4,
  NumberVariable = 5,
  Image = 6,
  Table = 7,
  Chart = 8,
  MetaVariable = 9,
  QuestionTpl = 100,
  QuestionDetailTpl = 110,
}

export const enum ExamQuestionMaterialSubType {
  None = 0,
  ConstantText = 41,
  DynamicText = 42,
  Integer = 51,
  Decimal = 52,
  Factor = 53,
  Multiple = 54,
  PrimeNumber = 55,
  Enumeration = 56,
  BarChart = 81,
  LineChart = 82,
  AreaChart = 83,
  RadarChart = 84,
  MvEquation = 901,
  MvCheckSinglePlural = 921,
  MvTxtVarPlural = 922,
  MvCheckCoeff = 923,
  TplQuestion = 101,
  TplSolution = 102,
  TplDetailContent = 111,
  TplDetailSolution = 112,
}

export const enum PinnedQuestionMode {
  Minimization = 1,
  Vertical = 2,
  Horizontal = 3,
}
