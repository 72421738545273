import React from 'react';

export default function OtherIndicatorUp({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.0411 4.56565C11.4131 3.81145 12.5869 3.81145 12.9589 4.56565L19.9021 18.6415C20.3401 19.5294 19.2173 20.3789 18.3418 19.8221L12.6014 16.1712C12.24 15.9413 11.76 15.9413 11.3986 16.1712L5.65821 19.8221C4.78274 20.3789 3.65994 19.5294 4.09789 18.6415L11.0411 4.56565Z"
        fill="currentColor"
      />
    </svg>
  );
}
