import { useCreatorQuestionCorrectAnswerHandle } from '@lib/web/editor';

import { useData } from './useData';

export const useEventHandle = (questionId: string) => {
  const { componentId } = useData(questionId);

  const { handleCorrectAnswerChange } =
    useCreatorQuestionCorrectAnswerHandle(questionId);

  const handleSaveValue = async (value: string) => {
    if (!questionId) {
      console.warn('questionId is undefined when select choice');
      return;
    }

    void handleCorrectAnswerChange({
      params: {
        id: questionId,
        correctAnswers: [
          {
            componentId,
            value,
          },
        ],
      },
    });
  };

  return {
    handleSaveValue,
  };
};
