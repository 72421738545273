import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import IaActionProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import {
  ListLayoutItem,
  ListLayoutSortEvent,
} from '@lib/ia/src/layouts/ListLayout/types';
import { CUSTOM_BR } from '@lib/web/composer';

import { useEditorSelector } from '../../redux';
import { TextComposerPanelKeys } from '../panel';

export default function HighlightPanel() {
  const { t } = useTranslation('editor');

  const { getRightParams, setRightParams } = useBaseRightPanel();
  const rightParams = getRightParams(
    TextComposerPanelKeys.TextComposerHighlight
  );

  const availableActions = {
    clickSingleItem: {
      action: (payload: ListLayoutItem) => {
        setRightParams((st) => ({
          ...st,
          activeAnchorId: st?.activeOptionId ? st?.activeOptionId : payload.id,
          selectAnchorId: st?.activeOptionId ? payload.id : st?.selectAnchorId,
        }));
      },
    },
    hoverSingleItem: {
      action: (payload: ListLayoutItem) => {
        setRightParams((st) => ({
          ...st,
          hoverAnchorId: payload.id,
        }));
      },
    },
    blurSingleItem: {
      action: () => {
        setRightParams((st) => ({
          ...st,
          hoverAnchorId: null,
        }));
      },
    },
    editItem: {
      action: (payload: ListLayoutItem) => {
        setRightParams((st) => ({
          ...st,
          activeAnchorId: payload.id,
        }));
      },
    },
    deleteItem: {
      action: (payload: ListLayoutItem) => {
        setRightParams((st) => ({
          ...st,
          deleteAnchorId: payload.id,
        }));
      },
    },
    sortItem: {
      action: ({ overItem, activeItem }: ListLayoutSortEvent) => {
        setRightParams((st) => ({
          ...st,
          pendingUpdate: {
            [activeItem.id]: overItem.text,
            [overItem.id]: activeItem.text,
          },
        }));
      },
    },
  };

  const dropdownOptions = useEditorSelector(
    (st) => st.setting.highlight.options
  );

  const layouts = useMemo(() => {
    return [
      {
        layout: 'hint-layout' as const,
        text: t('These highlights can only be used in this question'),
      },
      {
        layout: 'list-layout' as const,
        areas: [
          {
            key: 'list',
            settings: {
              emptyText: 'No highlights',
              showOrder: true,
              itemEventHint: {
                moveItem: '**Drag** to move',
                openMenu: '**Click** to open menu',
              },
              sortableConfig: {
                dropAction: 'sortItem',
              },
            },
            areaType: 'list' as const,
            items: dropdownOptions.map((option) => ({
              id: option.id,
              text: option.display.replaceAll(CUSTOM_BR, ' '),
              draggable: true,
              actionMap: {
                click: {
                  type: 'event' as const,
                  value: 'clickSingleItem',
                },
                mouseEnter: {
                  type: 'event' as const,
                  value: 'hoverSingleItem',
                },
                mouseLeave: {
                  type: 'event' as const,
                  value: 'blurSingleItem',
                },
              },
              moreActions: [
                {
                  type: 'event' as const,
                  icon: 'OtherEdit',
                  value: 'editItem',
                  text: 'Edit',
                },
                {
                  type: 'event' as const,
                  icon: 'OtherDelete',
                  value: 'deleteItem',
                  text: 'Delete',
                },
              ],
            })),
          },
        ],
      },
    ];
  }, [t, dropdownOptions]);

  const getItemStatus = ({ id }: { id: string }) => {
    return {
      selected: id === rightParams?.optionId,
      disabled: false,
    };
  };
  return (
    <IaActionProvider availableActions={availableActions}>
      <IaItemStatusProvider getItemStatus={getItemStatus}>
        <BaseLayoutRightPanel
          title={t('Highlight')}
          titleIcon={<Icon name="EditorHighlight" width={16} height={16} />}
        >
          <BaseLayoutRightPanel.ScrollArea>
            <IaLayouts layouts={layouts} />
          </BaseLayoutRightPanel.ScrollArea>
        </BaseLayoutRightPanel>
      </IaItemStatusProvider>
    </IaActionProvider>
  );
}
