import React from 'react';

export default function EmojiUncommon({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.5395 10.8795L13.1285 3.46855C12.8318 3.1718 12.4335 3 12.004 3C11.5745 3 11.184 3.16399 10.8795 3.46855L3.46855 10.8795C3.1718 11.1762 3 11.5745 3 12.004C3 12.4335 3.16399 12.824 3.46855 13.1285L10.8795 20.5395C11.1918 20.8518 11.5979 21.0002 12.004 21.0002C12.4101 21.0002 12.8162 20.844 13.1285 20.5395L20.5395 13.1285C21.1564 12.5038 21.1564 11.4964 20.5395 10.8795ZM19.5477 12.1289L12.1368 19.5399C12.0665 19.6102 11.9493 19.6102 11.8791 19.5399L4.46813 12.1289C4.42127 12.0821 4.41346 12.0352 4.41346 11.9962C4.41346 11.9571 4.42127 11.9103 4.46813 11.8634L11.8791 4.45251C11.9181 4.41346 11.965 4.39785 12.0118 4.39785C12.0587 4.39785 12.1055 4.41346 12.1446 4.45251L19.5555 11.8634C19.618 11.9415 19.618 12.0587 19.5477 12.1289Z"
        fill="currentColor"
      />
      <path
        d="M12.7543 7.13097C12.5513 6.92793 12.2858 6.8186 11.9968 6.8186C11.7079 6.8186 11.4424 6.92793 11.2393 7.13097L7.12389 11.2464C6.92085 11.4495 6.81152 11.715 6.81152 12.0039C6.81152 12.2928 6.92085 12.5584 7.12389 12.7614L11.2393 16.8769C11.4424 17.0799 11.7079 17.1892 11.9968 17.1892C12.2858 17.1892 12.5513 17.0799 12.7543 16.8769L16.8698 12.7614C17.0728 12.5584 17.1821 12.2928 17.1821 12.0039C17.1821 11.715 17.0728 11.4495 16.8698 11.2464L12.7543 7.13097ZM12.0046 15.8617L8.1469 12.0039L12.0046 8.14617L15.8624 12.0039L12.0046 15.8617Z"
        fill="currentColor"
      />
    </svg>
  );
}
