import { useCallback, useEffect } from 'react';
import { useLocalStorage } from '@front/helper';
import { useMarkEmojis } from '@lib/web/apis';

type UseEmojiSourceReturn = {
  emojiSource: GetMarkEmojiRes[];
  getAvailableRandomEmoji: () => GetMarkEmojiRes;
};

const useEmojiSource = (): UseEmojiSourceReturn => {
  const { data } = useMarkEmojis();
  const [emojiSource, setEmojiSource] = useLocalStorage<GetMarkEmojiRes[]>(
    'emoji-source',
    []
  );

  const getAvailableRandomEmoji = useCallback((): GetMarkEmojiRes => {
    const randomIndex = Math.floor(Math.random() * emojiSource.length);
    return emojiSource[randomIndex];
  }, [emojiSource]);

  useEffect(() => {
    if (data) {
      setEmojiSource(data.data.items);
    }
  }, [data, setEmojiSource]);

  return { emojiSource, getAvailableRandomEmoji };
};

export default useEmojiSource;
