import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

export type BaseLayoutActionProps = BoxProps & {
  fullWidth?: boolean;
};

const styles = {
  root: {
    mt: 'auto',
    px: 2.5,
    py: '12px',
  },
};

export default function BaseLayoutAction({
  sx,
  children,
  fullWidth,
  className,
  ...rest
}: BaseLayoutActionProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  if (className) {
    console.warn('className is reserved for BaseLayoutAction');
  }

  return (
    <Box
      sx={[styles.root, ...sxProps]}
      className="base-layout-action"
      {...rest}
    >
      {children}
    </Box>
  );
}
