import { ReactElement, ReactNode } from 'react';

import InfoTooltip from '../InfoTooltip';

type FieldTooltipProps = {
  title: string;
  content: string;
  children: ReactElement;
  titleIcon?: ReactNode;
  offset?: number[];
  color?: 'error' | 'info';
  open?: boolean;
};

const styles = {
  info: {},
  error: {
    '& .info-tooltip-title': {
      color: 'error.dark',
    },
  },
};
export default function FieldErrorTooltip({
  title,
  titleIcon,
  content,
  children,
  open,
  offset = [-2, -8],
  color = 'info',
}: FieldTooltipProps) {
  return (
    <InfoTooltip
      open={open}
      title={title}
      titleIcon={titleIcon}
      content={content}
      slotProps={{
        popper: {
          sx: styles[color],
          modifiers: [
            {
              name: 'offset',
              options: {
                offset,
              },
            },
          ],
        },
      }}
    >
      {children}
    </InfoTooltip>
  );
}
