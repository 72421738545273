import React from 'react';

export default function OtherKey({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.3389 5.29168C16.7292 4.90277 17.3605 4.90277 17.7507 5.29168L20.7059 8.23671C21.0971 8.62656 21.0982 9.25973 20.7083 9.65092C20.3185 10.0421 19.6853 10.0432 19.2941 9.65335L17.0448 7.41179L15.8757 8.5769L17.0119 9.70923C17.4031 10.0991 17.4042 10.7322 17.0143 11.1234C16.6245 11.5146 15.9913 11.5157 15.6001 11.1259L14.459 9.98869L12.4822 11.9587C13.8354 13.9671 13.6214 16.7135 11.8403 18.4885C9.81799 20.5038 6.54054 20.5038 4.51819 18.4885C2.49394 16.4712 2.49394 13.199 4.51819 11.1817C6.29906 9.40699 9.05319 9.19508 11.0665 10.546L16.3389 5.29168ZM10.4286 12.5984C9.1867 11.3608 7.17183 11.3608 5.92997 12.5984C4.69001 13.8341 4.69001 15.8361 5.92997 17.0718C7.17183 18.3094 9.1867 18.3094 10.4286 17.0718C11.6685 15.8361 11.6685 13.8341 10.4286 12.5984Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
