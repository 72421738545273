import { replaceHighlights } from './replaceHighlights';
import { replaceVariables } from './replaceVariables';

export const replaceContentFromSource = (
  content: string,
  {
    materials,
    highlightIds,
  }: {
    materials?: {
      id: string;
      content?: NullableString;
      value?: NullableNumber;
      creatorQuestionVariableId: string;
      name: string;
    }[];
    highlightIds?: string[];
  }
) => {
  let txt = content;
  if (materials?.length) {
    txt = replaceVariables(txt, materials);
  }
  if (highlightIds) {
    txt = replaceHighlights(txt, highlightIds);
  }

  return txt;
};
