import { Theme } from '@mui/material';

export const textColorMap = {
  'aha-default': (theme: Theme) => theme.palette.text.primary,
  'aha-red': (theme: Theme) => theme.palette.error.dark,
  'aha-orange': (theme: Theme) => theme.palette.warning.light,
  'aha-yellow': (theme: Theme) => theme.palette.highlight.yellow,
  'aha-green': (theme: Theme) => theme.palette.success.light,
  'aha-blue': (theme: Theme) => theme.palette.primary.light,
  'aha-purple': (theme: Theme) => theme.palette.tutor.main,
  'aha-pink': (theme: Theme) => theme.palette.error.light,
};

export const textColorStyles = () => ({
  [`[data-text-color='aha-default']`]: {
    color: textColorMap['aha-default'],
  },
  [`[data-text-color='aha-red']`]: {
    color: textColorMap['aha-red'],
  },
  [`[data-text-color='aha-orange']`]: {
    color: textColorMap['aha-orange'],
  },
  [`[data-text-color='aha-yellow']`]: {
    color: textColorMap['aha-yellow'],
  },
  [`[data-text-color='aha-green']`]: {
    color: textColorMap['aha-green'],
  },
  [`[data-text-color='aha-blue']`]: {
    color: textColorMap['aha-blue'],
  },
  [`[data-text-color='aha-purple']`]: {
    color: textColorMap['aha-purple'],
  },
  [`[data-text-color='aha-pink']`]: {
    color: textColorMap['aha-pink'],
  },
});
