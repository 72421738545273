import { isDate } from '@front/helper';
import { parseISO } from 'date-fns';

// the date data from by stream sdk might be string or Date
export const streamDateToString = (date: string | Date | undefined) => {
  if (typeof date === 'string') return parseISO(date).toISOString();

  if (isDate(date)) return date.toISOString();

  return date ?? '';
};
