import { useContext } from 'react';
import { InnerThreadContext, ThreadContext } from '@lib/web/thread';

export const useThread = () => {
  const {
    contextProvided,
    chatClient,
    chatUser,
    watchingViewsRef,
    watchingEventsRef,
    getThreadUser,
    getThreadLocationDisplay,
    getThreadNotificationMessagePayload,
    getThreadMessageExternalPayload,
    unreadChannels,
    reloadUnreadChannels,
    resetThreadChannelSortFilter,
    resetThreadMessageSortFilter,
    threadTitleTextRef,
    typingEffectMessageIdsRef,
    openMentionMenuMap,
    insertContent,
  } = useContext(ThreadContext);

  const { parentCidToChildChannels, checkChannelsHierarchy } =
    useContext(InnerThreadContext);

  return {
    chatClient,
    chatUser,
    watchingViewsRef,
    watchingEventsRef,
    loadingOrNotSupported: !contextProvided,
    getThreadUser,
    getThreadLocationDisplay,
    getThreadNotificationMessagePayload,
    getThreadMessageExternalPayload,
    unreadChannels,
    reloadUnreadChannels,
    resetThreadChannelSortFilter,
    resetThreadMessageSortFilter,
    threadTitleTextRef,
    parentCidToChildChannels,
    checkChannelsHierarchy,
    typingEffectMessageIdsRef,
    openMentionMenuMap,
    insertContent,
  };
};
