import React from 'react';

export default function MainAchievement({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.6913 15.9984C15.5523 15.6615 16.905 13.2492 17.5252 10.6749C18.6699 10.6542 19.5048 9.93394 20.0491 9.09524C20.5967 8.2516 20.9004 7.22188 20.9924 6.40747C21.0691 5.72734 20.5551 5.23844 20.0218 5.23844H18.0069C17.983 4.47589 17.9326 4 17.9326 4H6.04897C6.04897 4 5.99864 4.47589 5.97469 5.23844H3.97822C3.44486 5.23844 2.93088 5.72734 3.00765 6.40747C3.09957 7.22188 3.40332 8.2516 3.95085 9.09524C4.49225 9.92942 5.32111 10.6464 6.45628 10.6745C7.09067 13.308 8.49161 15.7722 11.4897 16.0183V18.6912H8.23239C7.90059 18.6912 7.63162 18.9842 7.63162 19.3456C7.63162 19.707 7.90059 20 8.23239 20H15.9486C16.2804 20 16.5494 19.707 16.5494 19.3456C16.5494 18.9842 16.2804 18.6912 15.9486 18.6912H12.6913V15.9984ZM6.19588 9.33182C6.04603 8.3422 5.98465 7.3777 5.96761 6.5472H4.24461C4.3497 7.12529 4.57902 7.7959 4.93199 8.33974C5.27177 8.86328 5.691 9.22278 6.19588 9.33182ZM19.068 8.33974C18.724 8.86983 18.2985 9.23175 17.7851 9.3358C17.9354 8.34479 17.9969 7.37881 18.014 6.5472H19.7554C19.6503 7.12529 19.421 7.7959 19.068 8.33974Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
