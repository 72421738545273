import { Fade, Typography } from '@mui/material';
import Popper, { PopperProps } from '@mui/material/Popper';

const styles = {
  popper: {
    zIndex: 'tooltip',
    pointerEvents: 'none',
  },
  tooltip: {
    display: 'block',
    p: 1,
    my: 0.5,
    borderRadius: 1,
    color: 'background.darker',
    bgcolor: 'text.primary',
  },
};
export type ControlledTooltipProps = PopperProps;
export default function ControlledTooltip({
  title,
  sx,
  ...rest
}: ControlledTooltipProps) {
  if (!rest.anchorEl) return null;
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Popper
      sx={[styles.popper, ...sxProps]}
      transition
      placement="bottom-start"
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: false,
            rootBoundary: 'viewport',
          },
        },
      ]}
      {...rest}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Typography sx={styles.tooltip} variant="caption">
            {title}
          </Typography>
        </Fade>
      )}
    </Popper>
  );
}
