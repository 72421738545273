import React, { useContext, useMemo } from 'react';
import { AgentPromptViewSlug, useAgentPrompts, useAuth } from '@lib/web/apis';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';
import PromptItem from '@lib/web/thread/ThreadComposer/components/AiAction/PromptItem';
import { extractTextFromHtml } from '@lib/web/utils';

import { useThreadComposer } from '../../../hooks/core/useThreadComposer';

export default function SelectAgentPrompt() {
  const { aiActionState: state, text } = useThreadComposer();
  const { commands } = useContext(TextComposerContext);
  const { userId } = useAuth();
  const selectedAgentId = state.selectedAgent?.agent.agentId;

  const agentPrompts = useAgentPrompts(
    {
      userId,
      viewSlug: AgentPromptViewSlug.TableCenterPromptDefault,
      search: `agentId:${selectedAgentId}`,
      searchFields: 'agentId:eq',
      orderBy: 'order',
      sortBy: 'asc',
    },
    {
      enable: !!selectedAgentId,
    }
  );

  const { dataset: prompts } = agentPrompts;

  const keywordToFilter = useMemo(
    () => extractTextFromHtml(text).toUpperCase(),
    [text]
  );

  const filteredPrompts = useMemo(
    () =>
      prompts.filter((prompt) =>
        prompt.promptName.toUpperCase().includes(keywordToFilter)
      ),
    [keywordToFilter, prompts]
  );

  return (
    <>
      {filteredPrompts.map((prompt, index) => (
        <PromptItem
          key={index}
          icon={prompt.promptIcon}
          text={prompt.promptName}
          onClick={() => commands.insertContent(prompt.promptContent)}
        />
      ))}
    </>
  );
}
