import React from 'react';

export default function OtherPhone({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.2857 2.00909L7.71429 2C6.77143 2 6 2.81818 6 3.81818V20.1818C6 21.1818 6.77143 22 7.71429 22H16.2857C17.2286 22 18 21.1818 18 20.1818V3.81818C18 2.81818 17.2286 2.00909 16.2857 2.00909ZM16.2857 18.3636H7.71429V5.63636H16.2857V18.3636Z"
        fill="currentColor"
      />
    </svg>
  );
}
