import React from 'react';

export default function ActionChevronLeft({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18 3.78788L16.1799 2L6 12L16.1799 22L18 20.2121L9.6401 12L18 3.78788Z"
        fill="currentColor"
      />
    </svg>
  );
}
