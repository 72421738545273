import { ReactNode, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { BlockNoteEditor, BlockSchema, ToggledStyle } from '@blocknote/core';
import {
  useEditorContentChange,
  useEditorSelectionChange,
} from '@blocknote/react';
import { IconButton, ResponsiveTooltip } from '@front/ui';

const styles = {
  styleButtonTooltip: {
    '& .MuiTooltip-tooltip': {
      p: 1,
      color: 'text.primary',
      bgcolor: 'background.darker',
      whiteSpace: 'nowrap',
    },
  },
  styleButtonTooltipInner: {
    display: 'flex',
    flexDirection: 'column',
  },
  styleButtonTooltipInnerHint: {
    opacity: 0.64,
  },
};

interface ToggledStyleButtonProps<BSchema extends BlockSchema> {
  editor: BlockNoteEditor<BSchema>;
  toggledStyle: ToggledStyle;
  name: string;
  hints: string[];
  children: ReactNode;
}

export default function ToggledStyleButton<BSchema extends BlockSchema>(
  props: ToggledStyleButtonProps<BSchema>
) {
  const [active, setActive] = useState<boolean>(
    props.toggledStyle in props.editor.getActiveStyles()
  );

  useEditorContentChange(props.editor, () => {
    setActive(props.toggledStyle in props.editor.getActiveStyles());
  });

  useEditorSelectionChange(props.editor, () => {
    setActive(props.toggledStyle in props.editor.getActiveStyles());
  });

  const toggleStyle = (style: ToggledStyle) => {
    props.editor.focus();
    props.editor.toggleStyles({ [style]: true });
  };

  return (
    <ResponsiveTooltip
      title={
        <Box sx={styles.styleButtonTooltipInner}>
          <Typography variant="caption" mb="2px">
            {props.name}
          </Typography>
          {props.hints.map((hint, i) => (
            <Typography
              key={i}
              variant="numberCaption"
              sx={styles.styleButtonTooltipInnerHint}
            >
              {hint}
            </Typography>
          ))}
        </Box>
      }
      tooltipProps={{
        slotProps: {
          popper: {
            sx: styles.styleButtonTooltip,
          },
        },
      }}
    >
      <IconButton
        customSize={24}
        onClick={() => toggleStyle(props.toggledStyle)}
        selected={active}
      >
        {props.children}
      </IconButton>
    </ResponsiveTooltip>
  );
}
