import React from 'react';

export default function TestListening({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.3541 13.8977H20.3111V12.3273C20.3111 7.7358 16.5786 4 11.9997 4C7.42111 4 3.68844 7.7358 3.68844 12.3273V13.8977H3.64559C3.01395 13.8977 2.5 14.3715 2.5 15.0052L2.50241 17.9417C2.50241 18.5755 3.01636 19.1069 3.648 19.1069H3.72617C3.84371 19.7022 4.29881 19.9997 4.84173 19.9997H5.47065C6.1023 19.9997 6.65714 19.4083 6.65714 18.7743V14.1089C6.65714 13.466 6.09158 13.0046 5.47065 13.0046H5.32112V12.2076C5.32112 8.50435 8.30701 5.49126 12 5.49126C15.6927 5.49126 18.6787 8.50435 18.6787 12.2076V13.0046H18.5293C17.9084 13.0046 17.3429 13.466 17.3429 14.1089V18.7746C17.3429 19.4085 17.8975 20 18.5293 20H19.1583C19.7012 20 20.1561 19.7024 20.2738 19.1072H20.352C20.9839 19.1072 21.4976 18.5549 21.4976 17.9212L21.5 15.0237C21.4997 14.3901 20.9861 13.8977 20.3541 13.8977Z"
        fill="currentColor"
      />
    </svg>
  );
}
