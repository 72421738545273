import React from 'react';
import Box from '@mui/material/Box';

import { GapLayoutConfig } from './types';

export type GapLayoutProps = GapLayoutConfig;

export default function GapLayout({ mb }: GapLayoutProps) {
  return <Box mb={mb} className="ia-gap-layout" />;
}
