import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useRef,
} from 'react';
import { useLatestValueRef, useQueueWorker } from '@front/helper';
import { uniq } from 'lodash';

const MAXIMUM_WAIT_READY_TIMES = 10;
const WAIT_READY_DELAY_MS = 500;

export default function useAsyncQueryPool<T>({
  poolKey,
  queryApi,
  map,
  setMap,
  readyRef = { current: true },
}: {
  poolKey: string;
  queryApi: (keys: string[]) => Promise<T[] | undefined>;
  map: Record<string, T>;
  setMap: Dispatch<SetStateAction<Record<string, T>>>;
  readyRef?: MutableRefObject<boolean>;
}) {
  const { addTask } = useQueueWorker();
  const pendingQueryKeysRef = useRef<string[]>([]);
  const queryApiRef = useLatestValueRef(queryApi);

  const addTaskToWorker = useCallback(
    (waitReadyTimes = 0) => {
      if (!readyRef.current) {
        if (waitReadyTimes < MAXIMUM_WAIT_READY_TIMES) {
          setTimeout(
            () => addTaskToWorker(waitReadyTimes + 1),
            WAIT_READY_DELAY_MS
          );
        }
        return;
      }

      addTask({
        scope: `useAsyncQueryPool-${poolKey}`,
        taskKey: `useAsyncQueryPool-${poolKey}`,
        debounceTime: 500,
        task: async () => {
          if (pendingQueryKeysRef.current.length === 0) {
            return;
          }

          const toQueryKeys = uniq(pendingQueryKeysRef.current);

          const result = await queryApiRef.current(toQueryKeys);

          if (!result) return;

          setMap((prev) => {
            const newMap = toQueryKeys.reduce<Record<string, T>>(
              (acc, cur, index) => ({
                ...acc,
                [cur]: result[index],
              }),
              {}
            );

            return {
              ...prev,
              ...newMap,
            };
          });
          pendingQueryKeysRef.current = [];
        },
      });
    },
    [readyRef, addTask, poolKey, queryApiRef, setMap]
  );

  const asyncGet = useCallback(
    (key: string) => {
      if (key in map) {
        return map[key];
      }

      if (pendingQueryKeysRef.current.includes(key)) {
        return;
      }

      pendingQueryKeysRef.current.push(key);

      addTaskToWorker();
    },
    [addTaskToWorker, map]
  );

  return {
    asyncGet,
  };
}
