import matchAll from 'string.prototype.matchall';

export const translatePassage = (tex: string) => {
  const pattern = /(.+)(\r\n|\r|\n)?/gm;

  return tex.replace(pattern, (value: string) => `<p>${value}</p>`);
};

export const translateQuestionNumber = (
  tex: string,
  className = 'question-no'
) => {
  const pattern = /\[\?[0-9]+\]/g;
  return tex.replace(pattern, (value: string) => {
    const number = value.replace('[?', '').replace(']', '');
    return `<span class="${className}" data-no="${number}">${number}</span>`;
  });
};

export const translateRemoveQuestionNumber = (tex: string) => {
  const pattern = /\[\?([\d]+)\]/g;

  return tex ? tex.replace(pattern, '$1') : tex;
};

export const translateRemoveBreakNumber = (tex: string) => {
  const pattern = /\[\[([\d]+)\]\]/g;

  return tex ? tex.replace(pattern, '$1') : tex;
};

export const translateSolution = (content: string) => {
  const multipleLineRegex =
    /##(\d{1,2})[\s\S](((?!(\r\n|\r|\n){2})(\r\n|\r|\n|.))*)##(\d{1,2})(\r\n|\r|\n)?/gm;
  const singleLineRegex = /##(\d{0,2})[\s\S](.*)/gm;

  let txt = content;
  const multipleMatchArr = [...matchAll(txt, multipleLineRegex)];

  multipleMatchArr.forEach((match) => {
    if (match[1] === match[6]) {
      txt = txt.replace(
        match[0],
        `<div class="solution-step"><div><span class="solution-step-num">${match[1]}</span>${match[2]}</div></div>`
      );
    }
  });

  return txt.replace(
    singleLineRegex,
    '<div class="solution-step"><div><span class="solution-step-num">$1</span>$2</div></div>'
  );
};
