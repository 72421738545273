import React from 'react';
import { DndContextProps } from '@dnd-kit/core';

import { GlobalSortableValue } from './contexts/sortableGlobalContext';

export type SortableOnDragOverOtherItem<T> = ({
  activeItem,
  overItem,
  newItems,
}: {
  activeItem: T;
  overItem: T;
  newItems: T[];
}) => void;

export type SortableProps<T> = {
  items: T[];
  onDragOverOtherItem: SortableOnDragOverOtherItem<T>;
  children: React.ReactNode | ((props: GlobalSortableValue) => React.ReactNode);
  onDraggableStart?: (item: T) => void;
} & Omit<DndContextProps, 'children'>;
