export const wordSeparateRegex = /[\n\r\s]/;
export const countWords = (text: string) => {
  return text.split(wordSeparateRegex).filter((s) => s !== '').length;
};

export const splitWords = (text: string) => {
  return text.split(wordSeparateRegex).filter((s) => s !== '');
};

export function camelize(str: string) {
  return (str + '').replace(/-\D/g, function (match) {
    return match.charAt(1).toUpperCase();
  });
}

export const isTextExceedMaxWord = ({
  newText,
  max,
}: {
  newText: string;
  max: number | undefined | null;
}): boolean => {
  if (!max) {
    return false;
  }

  const enableMax = !!max; // max = 0 also means 'no wordLimitMax'
  const reachedMax = countWords(newText) === max;
  const overMax = countWords(newText) > max;
  const tryToTypeAnotherWord = wordSeparateRegex.test(
    newText[newText.length - 1]
  );
  return enableMax && (overMax || (reachedMax && tryToTypeAnotherWord));
};

export const truncateTextByWords = (text = '', limit = 100): string => {
  let result = '';
  let wordCount = 0;
  let currentWord = '';

  for (const char of text) {
    if (/[\u4e00-\u9fa5]/.test(char)) {
      // Chinese character
      if (currentWord) {
        if (wordCount < limit) {
          result += currentWord;
          wordCount++;
        } else {
          break;
        }
        currentWord = '';
      }
      if (wordCount < limit) {
        result += char;
        wordCount++;
      } else {
        break;
      }
    } else if (/\s/.test(char)) {
      // Whitespace character
      if (currentWord) {
        if (wordCount < limit) {
          result += currentWord;
          wordCount++;
        } else {
          break;
        }
        currentWord = '';
      }
      if (wordCount < limit) {
        result += char;
      } else {
        break;
      }
    } else {
      // Other characters (mainly English)
      currentWord += char;
    }
  }

  // Add the last word if it exists and we're still under the limit
  if (currentWord && wordCount < limit) {
    result += currentWord;
  }

  return result.trim(); // Trim any trailing whitespace
};
