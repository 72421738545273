import { ThemeOptions } from '@mui/material';

const size = {
  login: {
    xs: 74,
    md: 96,
  },
  topBarHeight: 48,
};
const baseThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 900, // currently we disable all iPad Size breakpoints, waiting for better ui design
      xl: 1920,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 20,
          paddingRight: 20,
        },
        maxWidthLg: {
          '@media (min-width: 768px)': {
            paddingLeft: 20,
            paddingRight: 20,
          },
          '@media (min-width: 900px)': {
            maxWidth: 836,
          },
          /**
           * currently we disable all iPad Size breakpoints, waiting for better ui design
           * keep the comment here in case we forget to add it back when ipad size breakpoint comes back
           */
          // '@media (min-width: 1024px)': {
          //   maxWidth: 836,
          // },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          overflow: 'visible',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        '&:focus': {
          outline: 'none',
        },
        '[data-table]': {
          padding: 10,
          textAlign: 'center',
          overflow: 'auto',
        },
        '.reference-table': {
          borderCollapse: 'collapse',
          maxWidth: '100%',
          textAlign: 'center',
          margin: '0 auto',
          '& td': { border: '1px solid currentColor', padding: '2px 5px' },
        },
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },
        '#__next': {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        },
        '#nprogress': {
          pointerEvents: 'none',
        },
        '#nprogress .bar': {
          backgroundColor: '#009DF5',
          height: 2,
          left: 0,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 2000,
        },
      },
    },
  },
  direction: 'ltr',
  size,
};

export default baseThemeOptions;
