import React from 'react';

export default function TestSolution({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.45455 18C5.93939 18 4.65151 17.4653 3.59091 16.3958C2.5303 15.3264 2 14.0278 2 12.5C2 10.9722 2.5303 9.67361 3.59091 8.60417C4.65151 7.53472 5.93939 7 7.45455 7C8.68182 7 9.75394 7.34742 10.6709 8.04225C11.5879 8.73708 12.2279 9.61189 12.5909 10.6667H20.1818C20.6818 10.6667 21.11 10.8463 21.4664 11.2057C21.8227 11.565 22.0006 11.9964 22 12.5C22 13.05 21.8106 13.4931 21.4318 13.8292C21.053 14.1653 20.6364 14.3333 20.1818 14.3333V16.1667C20.1818 16.6708 20.0036 17.1026 19.6473 17.4619C19.2909 17.8212 18.863 18.0006 18.3636 18C17.8636 18 17.4355 17.8203 17.0791 17.461C16.7227 17.1017 16.5448 16.6702 16.5455 16.1667V14.3333H12.5909C12.2273 15.3875 11.587 16.2623 10.67 16.9577C9.75303 17.6532 8.68121 18.0006 7.45455 18ZM7.45455 14.3333C7.95455 14.3333 8.38273 14.1537 8.73909 13.7943C9.09545 13.435 9.27333 13.0036 9.27273 12.5C9.27273 11.9958 9.09454 11.5641 8.73818 11.2047C8.38182 10.8454 7.95394 10.6661 7.45455 10.6667C6.95455 10.6667 6.52636 10.8463 6.17 11.2057C5.81364 11.565 5.63576 11.9964 5.63636 12.5C5.63636 13.0042 5.81454 13.4359 6.17091 13.7952C6.52727 14.1546 6.95515 14.3339 7.45455 14.3333Z"
        fill="currentColor"
      />
    </svg>
  );
}
