import React from 'react';

export default function MainExam({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.3166 7.02711C16.9028 6.44179 17.8526 6.44256 18.4379 7.02882C19.0232 7.61508 19.0224 8.56483 18.4362 9.15014L10.4124 17.161C9.83803 17.7344 8.91478 17.7453 8.32714 17.1942C8.22614 17.133 8.1308 17.0587 8.04354 16.9715L5.03942 13.9678C4.45359 13.3821 4.45352 12.4323 5.03926 11.8465C5.625 11.2607 6.57475 11.2606 7.16058 11.8463L9.32349 14.0089L16.3166 7.02711Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
