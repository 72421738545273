import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Popover, Theme, useMediaQuery } from '@mui/material';
import {
  BlockNoteEditor,
  BlockSchema,
  DefaultBlockSchema,
  SlashMenuProsemirrorPlugin,
} from '@blocknote/core';
import { appConfig } from '@front/config';
import { getScrollParent } from '@lib/web/utils';
import { Editor } from '@tiptap/core';

import ThemeProvider from '../../../components/ThemeProvider';
import { getBlockInfoFromPos } from '../../../utils';
import { BasicBlockTypes } from '../../config/basicBlockTypes';

import SuggestionMenu, {
  SuggestionMenuHandler,
  SuggestMenuItem,
} from './SuggestionMenu';
import {
  getFilteredItems,
  getMatchInfo,
  getSelectionBoundingBox,
} from './utils';

type SuggestionMenuControllerProps<
  BSchema extends BlockSchema = DefaultBlockSchema,
  M = Record<string, any>
> = {
  editor: BlockNoteEditor<BSchema>;
  mentionTrigger?: string;
  hashtagTrigger?: string;
  allowSpace?: boolean;
  items?: SuggestMenuItem<M>[];
  onAddBlock?: SlashMenuProsemirrorPlugin<BSchema, any>['itemCallback'];
  onQueryChange?: (text: string | null) => void;
};

function SuggestionMenuController<
  BSchema extends BlockSchema = DefaultBlockSchema,
  M = Record<string, any>
>(props: SuggestionMenuControllerProps<BSchema, M>) {
  const lastEditorRef = useRef<Editor | null>(null);
  const lastStartPosRef = useRef<number | null>(null);
  const cacheItemsRef = useRef<SuggestMenuItem<M>[]>([]);
  const [position, setPosition] = useState<DOMRect>();
  const [show, setShow] = useState<boolean>(false);
  const [queryText, setQueryText] = useState<string | null>(null);
  const menuRef = useRef<SuggestionMenuHandler<M>>(null);
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const handleUpdateQueryText = (text: string | null) => {
    setQueryText(text);
    props.onQueryChange?.(text);
  };

  const handleInsertMention = (item: SuggestMenuItem<M>) => {
    if (lastEditorRef.current !== null && lastStartPosRef.current !== null) {
      props.editor._tiptapEditor
        .chain()
        .deleteRange({
          from: lastStartPosRef.current,
          to: lastEditorRef.current?.view.state.selection.$head.pos,
        })
        .insertContentAt(lastStartPosRef.current, [
          {
            type: BasicBlockTypes.InlineMention,
            attrs: {
              value: item.value,
              icon: item.icon,
              display: item.display,
              type: item.type,
              metadata: item.metadata,
            },
          },
          {
            type: 'text',
            text: ' ',
          },
        ])
        .run();

      lastEditorRef.current = null;
      lastStartPosRef.current = null;
      cacheItemsRef.current = [];
      setShow(false);
      handleUpdateQueryText(null);
    }
  };

  useEffect(() => {
    props.editor.onEditorContentChange((({ editor }: { editor: Editor }) => {
      if (editor.isEditable) {
        const blockInfo = getBlockInfoFromPos(
          editor.view.state.doc,
          editor.view.state.selection.$from.pos
        );

        if (blockInfo.contentType.name === BasicBlockTypes.Paragraph) {
          const match = getMatchInfo(editor, {
            mentionTrigger: props.mentionTrigger,
            hashtagTrigger: props.hashtagTrigger,
            allowSpace: props.allowSpace,
          });

          if (match) {
            lastStartPosRef.current = match.range.from;
            lastEditorRef.current = editor;
            handleUpdateQueryText(match.queryText);
            setPosition(
              getSelectionBoundingBox(editor, lastStartPosRef.current)
            );
            setShow(true);
            if (match.queryText[match.queryText.length - 1] === ' ') {
              const filtered = cacheItemsRef.current.filter((item) =>
                getFilteredItems(item, match.queryText)
              );

              if (!filtered.length && cacheItemsRef.current[0]) {
                handleInsertMention(cacheItemsRef.current[0]);
              }
            }

            return;
          }
        }
      }

      lastEditorRef.current = null;
      lastStartPosRef.current = null;
      setShow(false);
      handleUpdateQueryText(null);
    }) as () => void);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const scrollEl = getScrollParent(props.editor._tiptapEditor.view.dom);
    const handleScroll = () => {
      if (lastEditorRef.current && lastStartPosRef.current) {
        setPosition(
          getSelectionBoundingBox(
            lastEditorRef.current,
            lastStartPosRef.current
          )
        );
      }
    };

    if (scrollEl) {
      scrollEl.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollEl) {
        scrollEl.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setShow(false);
    lastStartPosRef.current = null;
  };

  const filteredItems = useMemo(() => {
    if (queryText === null || !props.items?.length) {
      return [];
    }

    if (!queryText) return props.items;

    return props.items
      .filter((item) => getFilteredItems(item, queryText))
      .slice(0, appConfig.maxThreadMentionItemsDisplay);
  }, [props.items, queryText]);
  cacheItemsRef.current = filteredItems;

  if (!filteredItems.length) return null;

  const top = position ? position.top : 0;
  const left = position ? (mdDown ? 0 : position.left) : 0;

  return (
    <Popover
      key={filteredItems.length}
      open={show}
      anchorReference="anchorPosition"
      anchorPosition={{ top, left }}
      disableEnforceFocus
      disableAutoFocus
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: mdDown ? 'center' : 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: mdDown ? 'center' : 'left',
      }}
      marginThreshold={0}
      onClose={handleClose}
      slotProps={{
        paper: {
          sx: {
            backgroundColor: 'background.menu',
            maxWidth: 530,
            width: '100%',
          },
        },
      }}
    >
      <div>
        <SuggestionMenu
          ref={menuRef}
          editor={props.editor}
          items={filteredItems}
          onClick={handleInsertMention}
        />
      </div>
    </Popover>
  );
}

export default function SuggestionMenuControllerRoot<
  BSchema extends BlockSchema = DefaultBlockSchema,
  M = Record<string, any>
>(props: SuggestionMenuControllerProps<BSchema, M>) {
  return (
    <ThemeProvider>
      <SuggestionMenuController {...props} />
    </ThemeProvider>
  );
}
