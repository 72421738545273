import { createContext } from 'react';

export type IaItemStatus = {
  disabled?: boolean;
  selected?: boolean;
  loading?: boolean;
  focused?: boolean;
  disabledTooltips?: string[];
};

export type IaItemStatusContextValue<T> = {
  getItemStatus?: (ev: T) => IaItemStatus;
  getItemStatusAsync?: (ev: T) => Promise<IaItemStatus | null>;
};

const IaItemStatusContext = createContext<IaItemStatusContextValue<any>>({});

export default IaItemStatusContext;
