import React from 'react';

export default function ProfileBilling({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
        fill="currentColor"
      />
      <path
        d="M7.33333 16H6.66667C6.2985 16 6 15.7015 6 15.3333V14.6667C6 14.2985 6.2985 14 6.66667 14H7.33333C7.7015 14 8 14.2985 8 14.6667V15.3333C8 15.7015 7.7015 16 7.33333 16Z"
        fill="currentColor"
      />
    </svg>
  );
}
