import { LeagueTier } from '@lib/web/apis';

export const LEAGUE_TIERS = [
  'Prima',
  'Alpha',
  'Centurion',
  'Spectus',
  'Legatus',
  'Nocturnus',
  'Incendium',
  'Cosmus',
  'Maximus',
  'Commandus',
] as const;

const LEAGUE_BADGE_MAP = {
  Alpha: 'LeagueBadgeAlpha',
  Centurion: 'LeagueBadgeCenturion',
  Commandus: 'LeagueBadgeCommandus',
  Cosmus: 'LeagueBadgeCosmus',
  Incendium: 'LeagueBadgeIncendium',
  Legatus: 'LeagueBadgeLegatus',
  Maximus: 'LeagueBadgeMaximus',
  Nocturnus: 'LeagueBadgeNocturnus',
  Prima: 'LeagueBadgePrima',
  Spectus: 'LeagueBadgeSpectus',
  Locked: 'LeagueBadgeLocked',
};

export type LeagueName = keyof typeof LEAGUE_BADGE_MAP;

export const getLeagueName = (tier: LeagueTier) => {
  return LEAGUE_TIERS[tier - 1];
};

export const getLeagueBadgeIconName = (tier?: LeagueTier | LeagueName) => {
  if (typeof tier === 'string') return LEAGUE_BADGE_MAP[tier];
  return LEAGUE_BADGE_MAP[tier ? getLeagueName(tier) : 'Locked'];
};
