import { Box, Typography } from '@mui/material';

import { AlbumLayoutGroup } from '../types';

import IaAlbumItem from './IaAlbumItem/IaAlbumItem';

const styles = {
  root: {
    display: 'grid',
  },
  title: {
    py: 1,
    opacity: 0.5,
  },
  items: {
    display: 'grid',
    gap: 2,
  },
};

type IaAlbumGroupProps = AlbumLayoutGroup & {
  gridTemplateColumns:
    | string
    | {
        xs?: string;
        sm?: string;
        md?: string;
      };
};

export default function IaAlbumGroup({
  title,
  items,
  gridTemplateColumns,
}: IaAlbumGroupProps) {
  if (!items || items.length === 0) return null;

  return (
    <Box sx={styles.root}>
      {title && (
        <Typography sx={styles.title} variant="caption">
          {title}
        </Typography>
      )}
      <Box sx={[styles.items, { gridTemplateColumns }]}>
        {items?.map((item, index) => (
          <IaAlbumItem key={index} item={item} />
        ))}
      </Box>
    </Box>
  );
}
