import { alpha, Box, Divider as MuiDivider, Theme } from '@mui/material';

import { FeedLayoutDivider } from '../types';

const styles = {
  root: {
    px: '20px',
    pt: 1,
  },
  newBg: {
    background: 'rgba(255, 41, 81, 0.10)',
  },
  divider: {
    py: '4px',
    '&:before, &:after': {
      borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
    typography: 'caption',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
};

type DividerProps = Omit<FeedLayoutDivider, 'type'>;

export default function Divider({ text, isNew = false }: DividerProps) {
  return (
    <Box sx={[styles.root, isNew && styles.newBg]}>
      <MuiDivider sx={styles.divider}>{text}</MuiDivider>
    </Box>
  );
}
