import StepBreadcrumb from '@app/web/src/layouts/breadcrumbs/StepBreadcrumb';
import getClubGroupPageConfig from '@app/web/src/layouts/pageConfig/clubGroup';
import { ParsedUrlQuery } from 'querystring';

export const getAllBreadcrumbsValues = (
  pathname: string,
  query: ParsedUrlQuery,
  t: any
) => {
  const clubGroupId = query.groupId as string;
  const prefix = clubGroupId
    ? `/club-group/form/${clubGroupId}`
    : '/club-group/form';
  const pageConfig = getClubGroupPageConfig(t);
  const getStepBreadcrumb = (step: number) =>
    clubGroupId ? undefined : <StepBreadcrumb total="2" step={step} />;
  return {
    settings: {
      text: pageConfig.settings.title,
      icon: pageConfig.settings.icon,
      href: `${prefix}/settings`,
      customizeComponent: getStepBreadcrumb(1),
    },
    clubs: {
      text: pageConfig.clubs.title,
      icon: pageConfig.clubs.icon,
      href: `${prefix}/clubs`,
      customizeComponent: getStepBreadcrumb(2),
    },
  };
};
