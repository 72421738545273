import React from 'react';

export default function OtherContact({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20 4C20 3.44772 19.5523 3 19 3H6.88271C6.33042 3 5.88271 3.44771 5.88271 4V5.99981H4.88233C4.39503 5.99981 4 6.40276 4 6.89981C4 7.39687 4.39503 7.79981 4.88233 7.79981H6.64699C6.76902 7.79981 6.88526 7.77455 6.99095 7.72886C6.85733 8.05262 6.54338 8.27992 6.17739 8.27992H5.88271V10.7997H4.88233C4.39503 10.7997 4 11.2027 4 11.6997C4 12.1968 4.39503 12.5997 4.88233 12.5997H6.64699C6.76901 12.5997 6.88526 12.5745 6.99095 12.5288C6.85733 12.8525 6.54339 13.0798 6.17739 13.0798H5.88271V15.6002H4.88233C4.39503 15.6002 4 16.0031 4 16.5002C4 16.9973 4.39503 17.4002 4.88233 17.4002H6.64699C6.76891 17.4002 6.88505 17.375 6.99068 17.3294C6.8569 17.6528 6.54313 17.8798 6.17739 17.8798H5.88271V20C5.88271 20.5523 6.33042 21 6.88271 21H19C19.5523 21 20 20.5523 20 20V4ZM14.8973 10.0312C14.8973 8.94843 14.0573 8.0625 13.0307 8.0625C12.004 8.0625 11.164 8.94843 11.164 10.0312C11.164 11.1141 12.004 12 13.0307 12C14.0573 12 14.8973 11.1141 14.8973 10.0312ZM9.29734 15.3764V15.9375H16.764V15.3764C16.764 14.5791 16.316 13.8703 15.6253 13.5553C14.832 13.1911 13.9547 12.9844 13.0307 12.9844C12.1067 12.9844 11.2293 13.1911 10.436 13.5553C9.74534 13.8703 9.29734 14.5791 9.29734 15.3764Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
