import { isEmpty } from './common';

// Code logic comes from the backend
export function decodeData<D>(value = '') {
  let resVal = null;
  try {
    if (!isEmpty(value)) {
      const isEncoded = /%/i.test(value);
      const decodedTxt = isEncoded ? decodeURIComponent(value) : value;
      if (!isEmpty(decodedTxt)) {
        const bufferingTxt = Buffer.from(decodedTxt, 'base64');
        if (!isEmpty(bufferingTxt)) {
          const deCompressedTxt = bufferingTxt.toString('latin1');
          if (!isEmpty(deCompressedTxt)) {
            resVal = JSON.parse(deCompressedTxt) as D;
          }
        }
      }
    }
  } catch (e) {
    return null;
  }

  return resVal;
}

export function encodeData<D>(data: D) {
  let resVal = '';
  if (data !== null && data !== undefined) {
    const stringifyObj = JSON.stringify(data);
    const bufferingTxt = Buffer.from(stringifyObj, 'latin1');
    const compressedTxt = bufferingTxt.toString('base64');
    resVal = encodeURIComponent(compressedTxt);
  }
  return resVal;
}

export function getTimestampId(
  serNo: number,
  len: number,
  date: Date = new Date()
) {
  const yyyy = date.getUTCFullYear();
  const mm = (date.getUTCMonth() + 1).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const dd = date.getUTCDate().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const hh = date.getUTCHours().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const mi = date.getUTCMinutes().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const ss = date.getUTCSeconds().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const ms = date.getUTCMilliseconds().toLocaleString('en-US', {
    minimumIntegerDigits: 3,
    useGrouping: false,
  });

  // 20230516-1637-5204-5000-000000000000 -> UUIDv4 Failed
  // 20230516-1637-4000-A000-520450000000 -> UUIDv4 Pass
  // 8-4-4-4-12
  const resId = `${yyyy}${mm}${dd}-${hh}${mi}-4000-A000-${ss}${ms}0000000`;

  const digits: number = `${len}`.length;
  const trailing = serNo.toLocaleString('en-US', {
    minimumIntegerDigits: digits,
    useGrouping: false,
  });

  return `${resId.slice(0, 36 - digits)}${trailing}`;
}
