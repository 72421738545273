import { useState } from 'react';
import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';

import { IconButton, Scrollbar } from '../../atoms';
import { Icon } from '../../components';

import { keyboardConfig, KeyboardType } from './config';

export type MathKeyboardBottomProps = {
  onKeyDown?: (key: string) => void;
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  scroll: {
    height: 110,
  },
  typeButtons: {
    display: 'flex',
    gap: 1,
    py: 1,
    px: 2.5,
  },
  display: {
    px: 2.5,
    opacity: 0.5,
    lineHeight: '27px',
    display: 'block',
  },
  keyboard: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(auto-fit, minmax(32px, 1fr))',
      sm: 'repeat(auto-fit, 32px)',
    },
    gap: '6px',
    px: 2.5,
    pb: '6px',
    '& button': {
      height: 40,
      borderRadius: '10%',
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      fontSize: 20,
    },
  },
};

export default function MathKeyboardBottom({
  onKeyDown,
}: MathKeyboardBottomProps) {
  const [typeIndex, setTypeIndex] = useState(0);
  return (
    <Box sx={styles.root}>
      <Scrollbar>
        <Box sx={styles.typeButtons}>
          {(Object.keys(keyboardConfig) as unknown as KeyboardType[]).map(
            (configKey, index) => (
              <IconButton
                key={configKey}
                customSize={24}
                onMouseDown={(ev) => {
                  ev.preventDefault();
                  setTypeIndex(index);
                }}
                selected={index === typeIndex}
              >
                <Icon
                  name={keyboardConfig[configKey].icon}
                  width={16}
                  height={16}
                />
              </IconButton>
            )
          )}
        </Box>
      </Scrollbar>

      <Scrollbar sx={styles.scroll}>
        <Typography sx={styles.display} variant="caption">
          {Object.values(keyboardConfig)[typeIndex].display}
        </Typography>
        <Box
          sx={styles.keyboard}
          onMouseDown={(ev) => {
            ev.preventDefault();
          }}
        >
          {Object.values(keyboardConfig)[typeIndex].keys.map((keyboardKey) => (
            <ButtonBase
              key={`${typeIndex}-${keyboardKey}`}
              onMouseDown={(ev) => {
                onKeyDown?.(keyboardKey);
                ev.preventDefault();
              }}
            >
              {keyboardKey}
            </ButtonBase>
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );
}
