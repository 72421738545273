import React from 'react';

export default function CryptoCurrencyAhaAlt({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.2417 7.8622C17.2417 7.86221 17.2417 7.8622 17.2417 7.8622C17.9257 6.67746 17.5198 5.16253 16.335 4.47851C15.1503 3.7945 13.6353 4.20042 12.9513 5.38517C12.9477 5.3914 12.9441 5.39765 12.9406 5.4039L6.75879 16.1111L11.0491 18.5881L17.2417 7.86226L17.2417 7.8622Z"
        fill="#565656"
      />
      <ellipse
        cx="2.47703"
        cy="2.47703"
        fill="#080808"
        rx="2.47703"
        ry="2.47703"
        transform="matrix(0.866025 0.500001 -0.499999 0.866026 7.99707 13.9661)"
      />
    </svg>
  );
}
