import { Fragment } from 'react';
import {
  alpha,
  Box,
  GlobalStyles,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import { IndicatorGroup } from '@front/ui';
import { useAuth } from '@lib/web/apis';

import { ThreadUser } from '../types';

type ThreadMemberTitleProps = {
  members: ThreadUser[];
  typographyVariant?: TypographyProps['variant'];
  badgeSize?: number;
  showIndicators?: boolean;
};

const styles = {
  root: {
    display: 'grid',
    flex: 1,
    minWidth: 0,
    gridTemplateColumns: 'minmax(0, min-content) 1fr',
    whiteSpace: 'nowrap',
  },
  hasOtherItems: {
    gridTemplateColumns: 'minmax(0, min-content) minmax(24px, 1fr)',
  },
  firstItem: {
    minWidth: 0,
    display: 'flex',
    gap: 0.5,
    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  firstOtherItem: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: 0.5,
  },
  otherItems: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  meTag: {
    typography: 'body2',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    borderRadius: 1,
    px: 1,
  },
};

export default function ThreadMemberTitle({
  members,
  typographyVariant,
  badgeSize,
}: ThreadMemberTitleProps) {
  const [firstMember, ...otherMembers] = members;
  const { member: currentMember } = useAuth();
  const isMe = firstMember.id === currentMember?.memberId;

  return (
    <>
      <GlobalStyles styles={{ '.breadcrumbs-item': { flex: 1 } }} />
      <Box sx={[styles.root, !!otherMembers.length && styles.hasOtherItems]}>
        <Box sx={styles.firstItem}>
          <Typography variant={typographyVariant}>
            {firstMember.name}
          </Typography>
          <Box sx={styles.firstOtherItem}>
            {'indicators' in firstMember && (
              <IndicatorGroup
                indicators={firstMember.indicators}
                size={badgeSize}
              />
            )}
            {isMe && <Box sx={styles.meTag}>Me</Box>}
          </Box>
        </Box>
        {!!otherMembers.length && (
          <Box position="relative">
            <Box sx={styles.otherItems}>
              {otherMembers.map((member) => (
                <Fragment key={member.id}>
                  <Typography variant={typographyVariant} component="span">
                    , {member.name}
                  </Typography>
                  {'indicators' in member && (
                    <IndicatorGroup
                      indicators={member.indicators}
                      size={badgeSize}
                    />
                  )}
                </Fragment>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
