import React from 'react';

export default function ChatThumbsDownOutline({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.1995 4L13.4395 3.06C13.2808 3.02025 13.118 3.0001 12.9545 3H8.93646C8.56496 3.00001 8.20081 3.10349 7.88483 3.29884C7.56884 3.49419 7.31351 3.77369 7.14746 4.106L3.64746 11.106C3.49509 11.4109 3.42318 11.7497 3.43856 12.0902C3.45394 12.4308 3.5561 12.7617 3.73533 13.0516C3.91456 13.3416 4.16493 13.5809 4.46265 13.7469C4.76038 13.9129 5.09559 14 5.43646 14H10.2005H12.2005M17.1995 4L17.2005 13L13.8085 18.088C13.4115 18.683 13.2005 19.381 13.2005 20.096C13.2005 20.595 12.7955 21 12.2955 21H12.1995C11.669 21 11.1603 20.7893 10.7852 20.4142C10.4102 20.0391 10.1995 19.5304 10.1995 19V14M17.1995 4H19.2005C19.7309 4 20.2396 4.21071 20.6147 4.58579C20.9897 4.96086 21.2005 5.46957 21.2005 6V12C21.2005 12.5304 20.9897 13.0391 20.6147 13.4142C20.2396 13.7893 19.7309 14 19.2005 14H16.7005"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
