import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';
import {
  ClubGroupViewSlug,
  generateInfiniteHook,
  SwrHelperParams,
} from '@lib/web/apis';

type GetIaClubGroupResMap = {
  [ClubGroupViewSlug.CardCenterClubGroupDefault]: GetIaClubGroupCardCenterClubGroupDefaultRes;
  [ClubGroupViewSlug.ProfileCenterClubGroupSetting]: GetIaClubGroupProfileCenterClubGroupSettingRes;
};

export function useIaClubGroups<
  K = GetIaClubGroupDefaultRes,
  T extends ClubGroupViewSlug | undefined = undefined
>(
  query: Omit<IaSearchReq<T>, 'page' | 'pageSize'> = {},
  config: SwrHelperParams = {}
) {
  const limit = query.limit || 0;
  const display: string | undefined = query.display;
  let filter: string | undefined = query.filter;
  let expand: string | undefined = query.expand;

  if (query.viewSlug === ClubGroupViewSlug.ProfileCenterClubGroupSetting) {
    filter = 'creator';
    expand = 'creator';
  }

  return generateInfiniteHook<
    T extends ClubGroupViewSlug ? GetIaClubGroupResMap[T] : K
  >(
    (index) =>
      `v2/ia/club-groups?${getIaQueryString({
        ...query,
        page: index + 1,
        limit,
        display,
        filter,
        expand,
      })}`,
    limit,
    {
      auth: true,
      ...config,
    }
  );
}
