import React from 'react';

export default function EmojiFavorite({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 8.86815C3 6.12801 5.23565 4 8.05946 4C9.55995 4 11.0019 4.63045 12 5.63764C12.9981 4.63045 14.44 4 15.9405 4C18.7643 4 21 6.12801 21 8.86815C21 10.5358 20.2151 12.0326 18.8903 13.5896C17.5679 15.1439 15.6802 16.789 13.4303 18.7491L12 20L10.5697 18.7491C8.31982 16.7891 6.43204 15.1439 5.10969 13.5896C3.78492 12.0326 3 10.5358 3 8.86815ZM8.05946 6.16494C6.43513 6.16494 5.23784 7.32599 5.23784 8.86815C5.23784 9.99207 5.87391 11.1421 7.07171 12.4879C8.25339 13.8156 9.94253 15.2921 11.9957 17.0819C14.0532 15.2921 15.7445 13.8155 16.9273 12.4878C18.1261 11.1421 18.7622 9.99204 18.7622 8.86815C18.7622 7.32599 17.5649 6.16494 15.9405 6.16494C14.682 6.16494 13.4637 6.95464 13.04 7.99913C12.9077 8.32528 12.7846 8.64625 12.6763 8.93825C12.5616 9.2475 12.2664 9.38748 12 9.38748C11.7336 9.38748 11.4384 9.2475 11.3237 8.93825C11.2154 8.64625 11.0923 8.32528 10.96 7.99913C10.5363 6.95464 9.31803 6.16494 8.05946 6.16494Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
