import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { TruncatedTooltip } from '@front/ui';

import { TableLayoutFooterTextCell } from '../../types';

export type IaFooterTextCellProps = {
  cell: TableLayoutFooterTextCell;
};

const styles = {
  root: {
    px: 1,
    py: '5.5px',
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
    color: 'alpha.lightA75',
  },
  textNowrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
export default function IaFooterTextCell({ cell }: IaFooterTextCellProps) {
  const { value, sx } = cell;

  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={[styles.root, ...sxProps]}>
      <TruncatedTooltip tooltip={value}>
        {(props) => (
          <Typography {...props} sx={styles.textNowrap} variant="body2">
            {value}
          </Typography>
        )}
      </TruncatedTooltip>
    </Box>
  );
}
