import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  add,
  compareAsc,
  compareDesc,
  format,
  getWeeksInMonth,
  isSameDay,
  isSameMonth,
  isToday,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

import {
  checkInRage,
  getBaseDateValue,
  getValidDateValue,
} from '../datePicker.helper';
import { DatePickerTypes, DateValue } from '../types/dateTypes';

export type RenderDayButtonProps = {
  disabled: boolean | undefined;
  selected: boolean;
  inToday: boolean;
  inMonth: boolean;
  dateType: DatePickerTypes;
  onClick: () => void;
};

export type DayPickerProps = {
  value: DateValue;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  focusedDate?: Date;
  type?: DatePickerTypes;
  disabled?: boolean;
  initDate?: Date | string | number | null;
  minDate?: Date | string | number | null;
  maxDate?: Date | string | number | null;
  renderDayButton?: (day: Date, props: RenderDayButtonProps) => ReactNode;
  onChange?: (newValue: Date | Date[] | null) => void;
  shouldDisableDate?: (d: Date) => boolean;
};

type ValidValue = Date | Date[] | undefined;

const BASE_WIDTH = 100 / 7;
const BUTTON_SIZE = 36;

const checkSelected = (day: Date, value: ValidValue, type: DatePickerTypes) => {
  if (!value) return false;
  switch (type) {
    case 'range': {
      if (!Array.isArray(value)) return false;
      if (value.length < 2) return isSameDay(day, value[0]);
      return checkInRage(day, value[0], value[1]);
    }
    case 'multiple': {
      if (Array.isArray(value)) return value.some((v) => isSameDay(v, day));
      return isSameDay(value, day);
    }

    default:
      return Array.isArray(value)
        ? value.some((v) => isSameDay(v, day))
        : isSameDay(value, day);
  }
};

const styles = {
  dayButton: {
    width: BUTTON_SIZE,
    height: BUTTON_SIZE,
    fontSize: 14,
  },
  weekDay: {
    position: 'relative',
    display: 'flex',
    '& + div': {
      marginTop: 0.5,
    },
  },
};
const DayButtonWrap = styled('div')({
  display: 'flex',
  flex: `0 0 ${BASE_WIDTH}%`,
  justifyContent: 'center',
  alignItems: 'center',
});

const DayButton = styled(ButtonBase, {
  shouldForwardProp: (prop) =>
    prop !== 'selected' &&
    prop !== 'inToday' &&
    prop !== 'inMonth' &&
    prop !== 'dateType',
})<{
  selected?: boolean;
  inToday?: boolean;
  inMonth?: boolean;
  dateType?: DatePickerTypes;
}>(({ theme, selected, inToday, inMonth, dateType }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  fontSize: 14,
  fontFamily: theme.typography.body1.fontFamily,
  transitionDuration: dateType === 'range' ? 'unset' : '0.3s',
  backgroundColor: selected ? theme.palette.primary.main : '',
  opacity: inMonth ? 1 : 0.3,
  border:
    inToday && !selected ? `1px solid ${theme.palette.text.primary}` : 'unset',
  '@media (hover: hover)': {
    '&:not(:disabled):hover': {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.background.default,
    },
  },
  '&:disabled': {
    opacity: 0.2,
  },
}));

const RangeBox = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'start' &&
    prop !== 'num' &&
    prop !== 'hasBeforeDays' &&
    prop !== 'hasAfterDays',
})<{
  start?: number;
  num?: number;
  hasBeforeDays?: boolean;
  hasAfterDays?: boolean;
}>(({ theme, start, num, hasBeforeDays, hasAfterDays }) => {
  const baseWidth = BASE_WIDTH;
  return {
    position: 'absolute',
    left: start ? `${start * baseWidth}%` : 0,
    width: num ? `${baseWidth * num}%` : 0,
    height: BUTTON_SIZE,
    background: start === undefined ? 'none' : theme.palette.primary.main,
    borderRadius: BUTTON_SIZE / 2,
    borderTopLeftRadius: hasBeforeDays ? 0 : BUTTON_SIZE / 2,
    borderBottomLeftRadius: hasBeforeDays ? 0 : BUTTON_SIZE / 2,
    borderTopRightRadius: hasAfterDays ? 0 : BUTTON_SIZE / 2,
    borderBottomRightRadius: hasAfterDays ? 0 : BUTTON_SIZE / 2,
  };
});

const DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const getWeekDays = (start: number) => {
  const days = [];
  const { length } = DAYS;
  for (let i = 0; i < length; i += 1) {
    let num = start + i;
    if (num >= length) num -= length;
    days.push(DAYS[num]);
  }

  return days;
};

export default function DayPicker({
  value,
  focusedDate,
  weekStartsOn = 0,
  disabled = false,
  type = 'default',
  initDate,
  minDate,
  maxDate,
  renderDayButton,
  onChange,
  shouldDisableDate,
}: DayPickerProps) {
  const [validFocusedDate, setValidFocusedDate] = useState<Date>(
    focusedDate || getBaseDateValue(value || initDate, minDate, maxDate)
  );
  const weekDays = useMemo(() => {
    const dayWeekArr = [];
    const rows = Math.max(getWeeksInMonth(validFocusedDate), 6);
    const startDate = startOfWeek(startOfMonth(validFocusedDate), {
      weekStartsOn,
    });
    for (let i = 0; i < rows; i += 1) {
      const dayArr = [];
      for (let j = 0; j < 7; j += 1) {
        const date = add(startDate, { days: j + i * 7 });
        dayArr.push(date);
      }
      dayWeekArr.push(dayArr);
    }
    return dayWeekArr;
  }, [validFocusedDate, weekStartsOn]);

  const validValue: ValidValue = useMemo(() => {
    if (!value) return undefined;

    if (Array.isArray(value)) return value.map((v) => new Date(v));

    return new Date(value);
  }, [value]);

  const handleClick = (d: Date) => {
    const newValue = getValidDateValue(d, validValue, type);
    onChange?.(newValue);
  };

  const checkRangeProps = (days: Date[]) => {
    if (type !== 'range') return {};
    if (Array.isArray(validValue) && validValue[0] && validValue[1]) {
      const firstIndex = days.findIndex((d) =>
        checkInRage(d, validValue[0], validValue[1])
      );

      const num = days.filter((d) =>
        checkInRage(d, validValue[0], validValue[1])
      ).length;

      if (num !== 0 || firstIndex !== -1) {
        return {
          start: firstIndex,
          num,
          hasBeforeDays: compareAsc(validValue[0], days[0]) === -1,
          hasAfterDays:
            compareDesc(validValue[1], days[days.length - 1]) === -1,
        };
      }
    }

    return {};
  };

  useEffect(() => {
    if (focusedDate) {
      setValidFocusedDate(focusedDate);
    }
  }, [focusedDate]);

  const checkDisabled = (day: Date): boolean => {
    if (minDate && compareAsc(day, new Date(minDate)) < 0) return true;
    if (maxDate && compareDesc(day, new Date(maxDate)) === -1) return true;
    return false;
  };

  const daysString = getWeekDays(weekStartsOn);

  return (
    <div>
      <Box sx={styles.weekDay}>
        {daysString.map((d: string, i: number) => (
          <DayButtonWrap key={`${d}-${i}`}>
            <Typography variant="caption" color="grey.500" mb={1}>
              {d}
            </Typography>
          </DayButtonWrap>
        ))}
      </Box>
      {weekDays.map((days, i) => (
        <Box key={i} sx={styles.weekDay}>
          {type === 'range' && <RangeBox {...checkRangeProps(days)} />}
          {days.map((day, j) => {
            const props = {
              disabled:
                disabled || checkDisabled(day) || shouldDisableDate?.(day),
              selected: checkSelected(day, validValue, type),
              inToday: isToday(day),
              inMonth: isSameMonth(day, validFocusedDate),
              dateType: type,
              onClick: () => handleClick(day),
            };
            return (
              <DayButtonWrap key={`${i}-${j}`}>
                <Box sx={styles.dayButton}>
                  {renderDayButton ? (
                    renderDayButton(day, props)
                  ) : (
                    <DayButton {...props}>{format(day, 'd')}</DayButton>
                  )}
                </Box>
              </DayButtonWrap>
            );
          })}
        </Box>
      ))}
    </div>
  );
}
