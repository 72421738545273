import React from 'react';

export default function OtherPaste({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.375 4.72727C9.375 3.22104 10.5503 2 12 2C13.4497 2 14.625 3.22104 14.625 4.72727H16.375C17.8247 4.72727 19 5.94831 19 7.45455V19.2727C19 20.779 17.8247 22 16.375 22H7.625C6.17525 22 5 20.779 5 19.2727V7.45455C5 5.94831 6.17525 4.72727 7.625 4.72727H9.375ZM10.25 6.54545V7.45455H13.75V6.54545H10.25ZM8.5 6.54545H7.625C7.14175 6.54545 6.75 6.95247 6.75 7.45455V19.2727C6.75 19.7748 7.14175 20.1818 7.625 20.1818H16.375C16.8582 20.1818 17.25 19.7748 17.25 19.2727V7.45455C17.25 6.95247 16.8582 6.54545 16.375 6.54545H15.5V9.27273H8.5V6.54545ZM12.875 4.72727C12.875 4.2252 12.4832 3.81818 12 3.81818C11.5168 3.81818 11.125 4.2252 11.125 4.72727H12.875Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
