import React from 'react';

export default function ThreadsDirectMessages({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 3C7.03742 3 3.00002 6.82484 3.00002 11.5263C3.00002 13.1697 3.49382 14.7616 4.43042 16.1381C4.25312 18.2024 3.77792 19.7349 3.08792 20.4609C2.99672 20.5569 2.97392 20.7038 3.03152 20.8251C3.08252 20.9334 3.18752 21 3.30002 21C3.31382 21 3.32762 20.9991 3.34172 20.9968C3.46322 20.9788 6.28562 20.5513 8.32712 19.3108C9.48632 19.8032 10.7211 20.0526 12 20.0526C16.9626 20.0526 21 16.2278 21 11.5263C21 6.82484 16.9626 3 12 3Z"
        fill="currentColor"
      />
    </svg>
  );
}
