import React, { MouseEvent, ReactElement, ReactNode, TouchEvent } from 'react';
import Link from 'next/link';
import {
  alpha,
  Box,
  ButtonBase,
  ButtonBaseProps,
  createTheme,
  Skeleton,
  Theme,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLongPress } from '@front/helper';
import { OtherError as OtherErrorIcon } from '@front/icon';
import {
  base,
  exam,
  InfoTooltip,
  LoadingIcon,
  SimpleTooltip,
  SimpleTooltipProps,
  SquareAvatar,
  SquareAvatarProps,
  StatusTag,
  TextButton,
  useTruncated,
} from '@front/ui';
import { useInView } from '@react-spring/web';
import { isNil } from 'lodash';

import {
  CardLayoutGroupProperty,
  CardLayoutItemProperty,
  CardLayoutItemTag,
} from '../../layouts/CardLayout/types';
import AvatarGridFolder from '../AvatarGridFolder';
import Icon from '../Icon';
import RichText from '../RichText';

export type CardDefaultProps = Omit<ButtonBaseProps, 'type'> & {
  avatar?: AvatarProps;
  cornerComponent?: ReactNode;
  avatarSize?: number;
  avatarShowStacked?: boolean;
  avatarBlackAndWhite?: boolean;
  subAvatars?: {
    avatarUrl: string;
    name: string;
  }[];
  subAvatarsCount?: number;
  subAvatarSize?: number;
  maxSubAvatars?: number;
  title: string;
  titleSuffixIcon?: string;
  description?: string;
  descriptionLines?: number;
  link?: string;
  disabled?: boolean;
  selected?: boolean;
  type: 'default';
  properties?: (CardLayoutItemProperty | CardLayoutGroupProperty)[];
  tags?: CardLayoutItemTag[];
  onLongPress?: (
    ev: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>
  ) => void;
  optimizeRenderEnabled?: boolean;
  actionSettings?: never;
};

export type CardActionProps = Omit<
  CardDefaultProps,
  'type' | 'actionSettings'
> & {
  type: 'action';
  actionSettings: {
    state?: 'default' | 'loading' | 'error';
    errorMessage?: string;
    errorActions?: {
      icon: string;
      text: string;
      onClick?: () => void;
    }[];
  };
};

export type CardProps = CardDefaultProps | CardActionProps;

const styles = {
  button: {
    p: '12px',

    display: 'grid',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'flex-start',
    gridAutoRows: 'min-content',
    gap: 1,

    textAlign: 'left',
    borderRadius: 2,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),

    transitionDuration: '0.3s',

    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
  },
  actionButton: {
    gap: 0.5,
    minHeight: { xs: 214, md: 212 },
    border: '1px dashed',
    borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    borderRadius: 2,
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    '&:disabled': {
      opacity: 0.5,
    },
    p: '30px',
  },
  defaultButton: {
    '@media (hover:hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  pressedButton: {
    color: 'text.primary',
    bgcolor: 'background.darker',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  avatarGroup: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 1,
  },
  subAvatars: {
    display: 'flex',
    alignItems: 'center',
    ml: '2px',
  },
  subAvatar: {
    ml: '-2px',
  },
  subAvatarMask: {
    // use mask to allow space between avatars transparent
    mask: `url('data:image/svg+xml;utf8,<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3302 0.836908C12.347 2.37625 12 4.66758 12 8C12 11.3324 12.347 13.6237 13.3302 15.1631C12.1122 15.7767 10.3911 16 8 16C1.6 16 0 14.4 0 8C0 1.6 1.6 0 8 0C10.3911 0 12.1122 0.223341 13.3302 0.836908Z" fill="red"/></svg>') left center/contain no-repeat`,
  },
  avatarGroupMore: {
    ml: 0.5,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
  titleWrapper: {
    display: 'grid',
    alignItems: 'center',
  },
  titleWidthIcon: {
    gridTemplateColumns: 'minmax(0, max-content)  16px',
    gap: 0.5,
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  descriptionLines: (lines: number) => ({
    display: '-webkit-box',
    minHeight: lines * 16,
    maxHeight: lines * 16,
    mb: '4px',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  }),
  properties: {
    display: 'grid',
    gap: 1,
  },
  propertyGroup: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  property: {
    display: 'grid',
    gridTemplateColumns: '16px 1fr',
    gap: 0.5,
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
    alignItems: 'center',
  },
  tag: {
    whiteSpace: 'nowrap',
    gap: 0.5,
    height: 18,
    px: 1,
    '& .MuiTypography-root': {
      typography: 'caption',
    },
    '& .status-tag-tooltip-inner': {
      display: 'inline-flex',
    },
  },
  defaultAction: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    gap: 1,
  },
  defaultActionIcon: {
    height: 24,
    display: 'flex',
    alignItems: 'center',
  },
  actionLoading: {
    width: 40,
    height: 40,
    '& svg': {
      width: 40,
      height: 40,
    },
  },
  errorContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    p: '30px',
    borderRadius: 2,
    cursor: 'default',
  },
  errorContent: {
    display: 'grid',
    gap: 1,
  },
  actionError: {
    display: 'grid',
    gap: 0.5,
    color: 'error.dark',
    '& .MuiTypography-root': {
      textAlign: 'left',
    },
  },
  errorActions: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    '& .MuiButtonBase-root': {
      px: 0,
    },
  },
  skeleton: {
    borderRadius: 2,
  },
};

type DescriptionProps = {
  content?: string;
  contentOpacity: number;
  lines?: number;
};

function Description({ content, contentOpacity, lines }: DescriptionProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { containerRef, isTruncated } = useTruncated({ horizontal: true });

  const description = (truncated?: boolean) => (
    <Typography
      sx={[
        { opacity: contentOpacity },
        !!lines && styles.descriptionLines(lines),
      ]}
      variant="caption"
      // zIndex = 2 => higher card tooltip
      zIndex={truncated ? 2 : undefined}
      ref={containerRef}
    >
      {content}
    </Typography>
  );

  if (mdUp && isTruncated) {
    return (
      <SimpleTooltip title={content} followCursor>
        {description(true)}
      </SimpleTooltip>
    );
  }

  return <>{description(false)}</>;
}

type TitleProps = {
  title?: string;
  titleSuffixIcon?: string;
};

function Title({ title, titleSuffixIcon }: TitleProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { containerRef, isTruncated } = useTruncated();

  const titleContent = (truncated?: boolean) => (
    <Typography
      sx={styles.title}
      variant="subtitle2"
      // zIndex = 2 => higher card tooltip
      zIndex={truncated ? 2 : undefined}
      ref={containerRef}
    >
      {title}
    </Typography>
  );

  const titleContentTooltip = (
    <SimpleTooltip title={title} followCursor>
      {titleContent(true)}
    </SimpleTooltip>
  );

  return (
    <Box sx={[styles.titleWrapper, !!titleSuffixIcon && styles.titleWidthIcon]}>
      {mdUp && isTruncated ? titleContentTooltip : titleContent(false)}
      {titleSuffixIcon && (
        <Icon name={titleSuffixIcon} width={16} height={16} />
      )}
    </Box>
  );
}

type PropertyItemProps = {
  property: CardLayoutItemProperty;
};

function PropertyItem({ property }: PropertyItemProps) {
  return (
    <Box sx={styles.property}>
      {property.iconType === 'image' ? (
        <SquareAvatar src={property.icon} size={16}>
          {typeof property.text === 'string' && property.text}
        </SquareAvatar>
      ) : (
        <Icon name={property.icon} width={16} height={16} />
      )}
      <RichText
        text={property.text}
        variant="caption"
        gap={property.textConfig?.gap}
      />
    </Box>
  );
}

type PropertyGroupProps = {
  properties: CardLayoutGroupProperty;
};

function PropertyGroup({ properties }: PropertyGroupProps) {
  return (
    <Box sx={styles.propertyGroup}>
      {properties.map((property, i) => (
        <PropertyItem key={`${property.text}-${i}`} property={property} />
      ))}
    </Box>
  );
}

type SubAvatarsGroupProps = {
  subAvatars: {
    avatarUrl: string;
    name: string;
  }[];
  maxSubAvatars: number;
  subAvatarSize: number;
  subAvatarsCount?: number;
};

function SubAvatarsGroup({
  subAvatars,
  maxSubAvatars,
  subAvatarSize,
  subAvatarsCount,
}: SubAvatarsGroupProps) {
  let totalCount = subAvatars.length;
  if (
    subAvatarsCount &&
    subAvatars.length > 0 &&
    subAvatarsCount > subAvatars.length
  ) {
    totalCount = subAvatarsCount;
  }
  const maxVisible = Math.min(subAvatars.length, maxSubAvatars);
  let visibleCount = Math.min(totalCount, maxVisible);
  if (totalCount > maxSubAvatars) {
    visibleCount -= 1; // the last slot shows remaining count
  }
  const remainingCount = totalCount - visibleCount;
  const visibleAvatars = subAvatars.slice(0, visibleCount);
  return (
    <Box sx={styles.subAvatars}>
      {visibleAvatars.map((item, index) => (
        <SquareAvatar
          key={index}
          src={item.avatarUrl}
          size={subAvatarSize}
          sx={[
            styles.subAvatar,
            index < visibleCount - 1 && styles.subAvatarMask,
          ]}
        >
          {item.name}
        </SquareAvatar>
      ))}
      {remainingCount > 0 && (
        <Typography variant="caption" sx={styles.avatarGroupMore}>
          +{remainingCount}
        </Typography>
      )}
    </Box>
  );
}

type AvatarProps = Pick<
  SquareAvatarProps,
  'src' | 'showIndicator' | 'status' | 'statusInfo'
>;

type HeaderAvatarsProps = {
  title?: string;
  avatar?: AvatarProps;
  subAvatars?: {
    avatarUrl: string;
    name: string;
  }[];
  subAvatarsCount?: number;
  avatarSize?: number;
  subAvatarSize?: number;
  avatarBlackAndWhite?: boolean;
  avatarShowStacked?: boolean;
  maxSubAvatars?: number;
};

function HeaderAvatars({
  title,
  avatar,
  subAvatars,
  subAvatarsCount,
  avatarSize,
  avatarBlackAndWhite,
  avatarShowStacked,
  subAvatarSize = 16,
  maxSubAvatars = 4,
}: HeaderAvatarsProps) {
  const mainAvatar = (
    <SquareAvatar
      src={avatar?.src}
      size={avatarSize}
      blackAndWhite={avatarBlackAndWhite}
      showStacked={avatarShowStacked}
      showIndicator={avatar?.showIndicator}
      status={avatar?.status}
      statusInfo={avatar?.statusInfo}
    >
      {title}
    </SquareAvatar>
  );

  // if subAvatars is not provided => show main avatar as default
  if (isNil(subAvatars)) {
    return mainAvatar;
  }

  // if the main avatar is provided => should show it, sub avatars should show next to it
  if (avatar) {
    return (
      <Box sx={styles.avatarGroup}>
        {mainAvatar}
        <SubAvatarsGroup
          subAvatars={subAvatars}
          subAvatarSize={subAvatarSize}
          maxSubAvatars={maxSubAvatars}
          subAvatarsCount={subAvatarsCount}
        />
      </Box>
    );
  }

  // only sub avatars are shown
  return (
    <AvatarGridFolder
      avatars={subAvatars}
      avatarsCount={subAvatarsCount}
      size={avatarSize}
    />
  );
}

type TooltipProps = Omit<SimpleTooltipProps, 'title' | 'children'> & {
  children: ReactNode;
};

function Tooltip({ children, ...rest }: TooltipProps) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (mdDown) return null;

  if (!children) return null;

  return (
    <SimpleTooltip title={children} {...rest}>
      <Box
        sx={{ userSelect: 'none' }}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={1}
      />
    </SimpleTooltip>
  );
}

type CardWrapperProps = Omit<ButtonBaseProps, 'children'> & {
  optimizeRenderEnabled?: boolean;
  children: ReactNode;
};

function CardWrapper({
  optimizeRenderEnabled,
  children,
  ...rest
}: CardWrapperProps) {
  const [ref, inView] = useInView({
    rootMargin: '200px 0px',
  });
  if (!inView && optimizeRenderEnabled) {
    return (
      <Box ref={ref}>
        <Skeleton
          sx={[styles.skeleton, { height: 200 }]}
          variant="rectangular"
        />
      </Box>
    );
  }
  return (
    <ButtonBase {...rest} ref={ref}>
      {children}
    </ButtonBase>
  );
}

type TagTooltipProps = {
  tooltip?: CardLayoutItemTag['infoTooltip'];
  children: ReactElement;
};

function TagTooltip({ tooltip, children }: TagTooltipProps) {
  if (!tooltip) return children;
  return (
    <InfoTooltip
      title={tooltip.title}
      titleIcon={<Icon name={tooltip.icon} width={16} height={16} />}
      content={tooltip.content}
    >
      <Box sx={{ display: 'inline-flex' }}>{children}</Box>
    </InfoTooltip>
  );
}

export default function Card({
  sx,
  avatar,
  avatarSize,
  avatarBlackAndWhite,
  avatarShowStacked,
  cornerComponent,
  subAvatars,
  subAvatarsCount,
  subAvatarSize,
  maxSubAvatars,
  title,
  titleSuffixIcon,
  description,
  descriptionLines,
  properties,
  tags,
  link,
  selected,
  type,
  onLongPress,
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  onTouchStart,
  onTouchEnd,
  onClick,
  optimizeRenderEnabled,
  children,
  ...rest
}: CardProps) {
  const theme = useTheme();
  const updateTheme = createTheme(
    selected ? (theme.palette.mode === 'dark' ? exam : base) : theme
  );

  const contentOpacity = updateTheme.palette.mode === 'dark' ? 0.64 : 0.75;

  const backspaceLongPress = useLongPress(onLongPress, 500);

  const handleMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    onMouseDown?.(event);
    backspaceLongPress.onMouseDown(event);
  };
  const handleMouseUp = (event: MouseEvent<HTMLButtonElement>) => {
    onMouseUp?.(event);
    backspaceLongPress.onMouseUp(event);
  };
  const handleMouseLeave = (event: MouseEvent<HTMLButtonElement>) => {
    onMouseLeave?.(event);
    backspaceLongPress.onMouseLeave(event);
  };
  const handleTouchStart = (event: TouchEvent<HTMLButtonElement>) => {
    onTouchStart?.(event);
    backspaceLongPress.onTouchStart(event);
  };
  const handleTouchEnd = (event: TouchEvent<HTMLButtonElement>) => {
    onTouchEnd?.(event);
    backspaceLongPress.onTouchEnd(event);
  };

  const sxProps = Array.isArray(sx) ? sx : [sx];

  const cancelPopupMenu = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
  };

  const buttonActionProps = link
    ? {
        component: Link,
        href: link,
      }
    : null;

  const { actionSettings, ...otherRest } = rest;
  const buttonProps = {
    onClick,
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onMouseLeave: handleMouseLeave,
    onTouchStart: handleTouchStart,
    onTouchEnd: handleTouchEnd,
    onContextMenu: cancelPopupMenu,
    disableTouchRipple: true,
    disableRipple: true,
    ...buttonActionProps,
    ...otherRest,
  };

  if (type === 'action') {
    const {
      state = 'default',
      errorMessage,
      errorActions,
    } = actionSettings as CardActionProps['actionSettings'];
    return (
      <ThemeProvider theme={updateTheme}>
        <CardWrapper
          sx={[styles.actionButton, ...sxProps]}
          {...(state !== 'error'
            ? buttonProps
            : { disableTouchRipple: true, disableRipple: true })}
          optimizeRenderEnabled={optimizeRenderEnabled}
        >
          {state === 'default' && (
            <>
              <Box sx={styles.defaultAction}>
                <Box sx={styles.defaultActionIcon}>
                  <Icon name="TestAdd" width={16} height={16} />
                </Box>
                <Typography>{title}</Typography>
              </Box>
              {children}
            </>
          )}
          {state === 'loading' && <LoadingIcon sx={styles.actionLoading} />}
          {state === 'error' && (
            <Box sx={styles.errorContainer}>
              <Box sx={styles.errorContent}>
                {errorMessage && (
                  <Box sx={styles.actionError}>
                    <OtherErrorIcon width={16} height={16} />
                    {errorMessage && (
                      <Typography variant="body1">{errorMessage}</Typography>
                    )}
                  </Box>
                )}
                {errorActions && (
                  <Box sx={styles.errorActions}>
                    {errorActions.map((action, index) => (
                      <TextButton
                        key={index}
                        prefixIcon={<Icon name={action.icon} />}
                        onClick={action.onClick}
                      >
                        {action.text}
                      </TextButton>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </CardWrapper>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={updateTheme}>
      <CardWrapper
        sx={[
          styles.button,
          selected ? styles.pressedButton : styles.defaultButton,
          ...sxProps,
        ]}
        {...buttonProps}
        optimizeRenderEnabled={optimizeRenderEnabled}
      >
        <Box sx={styles.header}>
          <HeaderAvatars
            title={title}
            avatar={avatar}
            avatarSize={avatarSize}
            avatarBlackAndWhite={avatarBlackAndWhite}
            avatarShowStacked={avatarShowStacked}
            subAvatars={subAvatars}
            subAvatarsCount={subAvatarsCount}
            subAvatarSize={subAvatarSize}
            maxSubAvatars={maxSubAvatars}
          />
          {cornerComponent}
        </Box>
        <Title title={title} titleSuffixIcon={titleSuffixIcon} />
        <Description
          content={description}
          contentOpacity={contentOpacity}
          lines={descriptionLines}
        />

        {!!properties?.length && (
          <Box sx={[styles.properties, { opacity: contentOpacity }]}>
            {properties.map((item, i) => (
              <React.Fragment key={i}>
                {Array.isArray(item) ? (
                  <PropertyGroup key={i} properties={item} />
                ) : (
                  <PropertyItem key={`${item.text}-${i}`} property={item} />
                )}
              </React.Fragment>
            ))}
          </Box>
        )}

        {!!tags?.length && (
          <Box sx={styles.tags}>
            {tags.map((tag, i) => (
              <TagTooltip key={`${tag.text}-${i}`} tooltip={tag.infoTooltip}>
                <StatusTag
                  key={`${tag.text}-${i}`}
                  sx={[
                    styles.tag,
                    (!!tag.tip || !!tag.infoTooltip) && { zIndex: 2 },
                    ...(Array.isArray(tag.sx) ? tag.sx : [tag.sx]),
                  ]}
                  icon={
                    tag.icon && <Icon name={tag.icon} width={16} height={16} />
                  }
                  color={tag.color}
                  name={tag.text}
                  tip={tag.tip}
                  onClick={(ev) => {
                    ev.stopPropagation();
                  }}
                />
              </TagTooltip>
            ))}
          </Box>
        )}
        {children}
      </CardWrapper>
    </ThemeProvider>
  );
}

Card.Tooltip = Tooltip;
