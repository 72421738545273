import React from 'react';

export default function EditorAI({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.922 5.8429C19.0752 6.10536 18.5873 6.60527 18.3196 8.4605C18.3046 8.56467 18.1571 8.56437 18.1425 8.46012C17.8823 6.60693 17.3864 6.11608 15.5398 5.84664C15.4354 5.8314 15.4356 5.68228 15.5402 5.6674C17.3735 5.40633 17.8691 4.91056 18.1357 3.08197C18.1516 2.97259 18.3062 2.97272 18.3221 3.08211C18.5863 4.91119 19.0835 5.39691 20.9224 5.6647C21.0262 5.67979 21.0259 5.82815 20.922 5.8429Z"
        fill="currentColor"
      />
      <path
        d="M11.2297 5.8429C9.3829 6.10536 8.89502 6.60527 8.62727 8.4605C8.61227 8.56467 8.46482 8.56437 8.45019 8.46012C8.19002 6.60693 7.69412 6.11608 5.84747 5.84664C5.74307 5.8314 5.74328 5.68228 5.84781 5.6674C7.68129 5.40633 8.17682 4.91056 8.44337 3.08197C8.45927 2.97259 8.61392 2.97272 8.62974 3.08211C8.89397 4.91119 9.39122 5.39691 11.2301 5.6647C11.3339 5.67979 11.3336 5.82815 11.2297 5.8429Z"
        fill="currentColor"
      />
      <path
        d="M20.249 14.1323C18.8639 14.3291 18.4981 14.704 18.2973 16.0954C18.286 16.1735 18.1754 16.1733 18.1644 16.0952C17.9692 14.7052 17.5973 14.3371 16.2124 14.135C16.1341 14.1236 16.1343 14.0117 16.2126 14.0006C17.5876 13.8048 17.9593 13.433 18.1592 12.0614C18.1712 11.9795 18.2872 11.9795 18.299 12.0616C18.4972 13.4334 18.8701 13.7977 20.2492 13.9985C20.3272 14.0099 20.327 14.1212 20.249 14.1323Z"
        fill="currentColor"
      />
      <path
        d="M13.2756 8.78693L3.24836 18.8143C3.05223 19.0104 2.95523 19.2744 3.01997 19.4739C3.23245 20.1287 3.87156 20.7676 4.5263 20.9802C4.72573 21.045 4.98979 20.9478 5.18586 20.7518L15.2132 10.7245C15.4093 10.5283 15.5063 10.2643 15.4415 10.0648C15.3502 9.78322 15.1425 9.45593 14.8433 9.15683C14.5441 8.85758 14.2169 8.64998 13.9352 8.55855C13.7356 8.49371 13.4718 8.59082 13.2756 8.78693ZM13.8746 10.1256C13.7517 10.0028 13.63 9.9063 13.5203 9.834L11.6402 11.714C11.7125 11.8237 11.8091 11.9454 11.9318 12.0682C12.0547 12.191 12.1765 12.2875 12.2861 12.3598L14.1661 10.4798C14.0939 10.3702 13.9974 10.2484 13.8746 10.1256Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
