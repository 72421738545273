import React from 'react';

export default function EditorChangeTone({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.53768 19.5019C3.87391 18.8181 3.81793 17.7346 4.40752 16.9833L12.0753 7.21162C12.0753 7.21162 12.0787 5.35614 13.5696 4.05483C15.3869 2.46868 18.2433 2.7339 19.7409 4.43098C21.2383 6.12818 21.6443 8.89871 19.5971 10.8858C18.3226 12.1227 16.8876 12.1108 16.8876 12.1108L14.7563 13.8496V20.3941C14.7563 20.7287 14.4906 21 14.1629 21C13.8352 21 13.5696 20.7287 13.5696 20.3941V14.8421L7.29372 19.942C6.55289 20.544 5.48701 20.4799 4.8205 19.7933L4.53768 19.5019ZM16.2059 7.93137C15.1822 6.87801 14.7563 5.22006 14.7563 5.22006C14.7563 5.22006 16.6024 3.64766 18.4611 5.38657C20.3198 7.12549 18.8374 9.41928 18.8374 9.41928C18.8374 9.41928 17.2297 8.98484 16.2059 7.93137ZM14.1358 5.75038C14.1358 5.75038 12.6059 7.90552 14.4117 9.66687C16.2177 11.4283 18.273 9.9489 18.273 9.9489C18.273 9.9489 16.4242 9.51094 15.4211 8.49295C14.4179 7.47508 14.1358 5.75038 14.1358 5.75038ZM6.41543 18.5315C6.21559 18.6922 5.93031 18.6775 5.74726 18.4972C5.55768 18.3105 5.53894 18.0069 5.70406 17.7973L12.3675 9.34055C12.3675 9.34055 12.6869 10.1547 13.2395 10.7484C13.7921 11.3422 14.7136 11.8611 14.7136 11.8611L6.41543 18.5315Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
