import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import useMeasure from 'react-use-measure';
import Box, { BoxProps } from '@mui/material/Box';
import { ResizeObserver } from '@juggle/resize-observer';
import { animated, config, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';

import useBodyClass from '../../hooks/useBodyClass';

export type BottomSheetProps = {
  // This is the height that contains the padding and drag handler
  defaultHeight?: number;
  // This is the height without the padding and drag handler
  defaultContentHeight?: number;
  // Based on the height of the content
  fixedHeight?: boolean;
  // Used to increase additional height
  offsetHeight?: number;
  // Used to increase additional bottom spacing
  bottomSpacing?: number;
  maxHeight?: number;
  children: React.ReactNode;
  open: boolean;
  disableDrag?: boolean;
  disableBackdrop?: boolean;
  simpleBackdrop?: boolean;
  keepMounted?: boolean;
  sx?: BoxProps['sx'];
  onClose?: () => void;
  onExit?: () => void;
};

const SHEET_TOP = 40;

const styles = {
  root: {
    zIndex: 'modal',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'translateY(100%)',
    '& .bottom-sheet-bg': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transform: 'translateY(-100%)',
      backgroundColor: 'rgba(8, 8, 8, 0.75)',
    },

    '& .bottom-sheet-container': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      touchAction: 'none',
      userSelect: 'none',
      borderTopLeftRadius: '30px',
      borderTopRightRadius: '30px',
      color: 'text.primary',
      bgcolor: 'background.menu',
      backdropFilter: 'blur(50px)',
      boxShadow: 9,
    },
  },
  clearBackdrop: {
    '& .bottom-sheet-bg': {
      backdropFilter: 'unset',
    },
  },
  sheetTop: {
    height: SHEET_TOP,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:after': {
      content: '""',
      bgcolor: 'text.primary',
      height: 3,
      width: 36,
      borderRadius: 1.5,
    },
  },
};

function BottomSheet({
  sx,
  open,
  onClose,
  onExit,
  maxHeight,
  defaultHeight = 300,
  offsetHeight = 0,
  bottomSpacing = 12,
  defaultContentHeight,
  fixedHeight,
  disableDrag = false,
  disableBackdrop = false,
  simpleBackdrop = false,
  children,
}: Omit<BottomSheetProps, 'keepMounted'>) {
  const [portalTarget, setPortalTarget] = useState<HTMLElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [childrenContentRef, { height: childrenContentHeight }] = useMeasure({
    polyfill: ResizeObserver,
  });

  const updatedHeight =
    (fixedHeight
      ? childrenContentHeight + SHEET_TOP + bottomSpacing
      : defaultContentHeight
      ? defaultContentHeight + SHEET_TOP + bottomSpacing
      : defaultHeight) + offsetHeight;

  const positionYRef = useRef<number>(0);
  const velocityRef = useRef<number>(0);
  const propsRef = useRef({ open, onExit });
  propsRef.current = { open, onExit };
  const [{ y }, set] = useSpring(() => ({
    y: 0,
    onRest: () => {
      if (!propsRef.current.open) propsRef.current.onExit?.();
    },
  }));

  useBodyClass(open, 'bottom-sheet-lock');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bind: any = useDrag(
    ({ velocity: [, vy], movement: [, my], last, first, event }) => {
      const limitHeight = fixedHeight
        ? updatedHeight
        : maxHeight || window.innerHeight;

      if (containerRef?.current?.contains(event.target as Node)) return;
      if (first) {
        positionYRef.current = y.get();
      }
      const positionY = positionYRef.current - my;
      const adjustY = positionY > limitHeight ? limitHeight : positionY;

      if (adjustY <= limitHeight / 3) {
        onClose?.();
        return;
      }
      if (last) {
        if (vy > 0.5 && my > 0) {
          velocityRef.current = vy;

          if (adjustY < updatedHeight) {
            onClose?.();
          } else {
            set({ y: updatedHeight });
          }
        } else if (adjustY < updatedHeight) {
          set({ y: updatedHeight });
        } else if (vy > 0.2) {
          set({ y: limitHeight });
        }
        return;
      }
      set({ y: adjustY, immediate: true });
    }
  );

  React.useEffect(() => {
    setPortalTarget(document.body);
  }, []);

  const handleOpen = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ canceled }: any) => {
      set({
        y: updatedHeight,
        immediate: false,
        config: canceled ? config.wobbly : config.stiff,
      });
    },
    [set, updatedHeight]
  );

  const handleClose = React.useCallback(() => {
    set({
      y: 0,
      immediate: false,
      config: { ...config.stiff, velocity: velocityRef.current },
    });
  }, [set]);

  React.useEffect(() => {
    if (open) {
      handleOpen({ canceled: false });
    } else {
      velocityRef.current = 0;
      handleClose();
    }
  }, [open, handleOpen, handleClose]);

  React.useEffect(() => {
    if (open) {
      set({
        y: updatedHeight,
        immediate: false,
        config: config.stiff,
      });
    }
  }, [updatedHeight, open, set]);

  if (!portalTarget || !y) return null;

  const display = y.to((posY) => (posY > 0 ? 'block' : 'none'));
  const sxProps = Array.isArray(sx) ? sx : [sx];

  const handleBgClose = (ev: MouseEvent) => {
    ev.stopPropagation();
    onClose?.();
  };

  return ReactDOM.createPortal(
    <Box sx={[styles.root, simpleBackdrop && styles.clearBackdrop, ...sxProps]}>
      {disableBackdrop === false && (
        <animated.div
          className="bottom-sheet-bg"
          onClick={handleBgClose}
          style={{
            display,
            opacity: y.to({
              map: Math.floor,
              range: [50, updatedHeight],
              output: [0, 1],
              extrapolate: 'clamp',
            }),
          }}
        />
      )}
      <animated.div
        className="bottom-sheet-container"
        {...(!disableDrag && bind())}
        onScroll={(e) => e.stopPropagation()}
        style={{
          display,
          transform: y.to((posY) => `translate3d(0px, ${-posY}px, 0)`),
        }}
      >
        <Box sx={styles.sheetTop} />
        <animated.div
          ref={containerRef}
          onScroll={(e) => e.stopPropagation()}
          style={{
            height: y.to((posY) => `${posY - SHEET_TOP}px`) || 0,
          }}
        >
          <div
            ref={childrenContentRef}
            className="bottom-sheet-content-wrapper"
          >
            {children}
          </div>
        </animated.div>
      </animated.div>
    </Box>,
    portalTarget
  );
}

export default function BottomSheetContainer({
  keepMounted,
  open,
  onExit,
  ...rest
}: BottomSheetProps) {
  const [visible, setVisible] = useState(false);

  const handleExit = () => {
    if (onExit) onExit();
    if (!keepMounted) setVisible(false);
  };

  const isVisible = !keepMounted && open;

  useEffect(() => {
    if (isVisible) setVisible(true);
  }, [isVisible]);

  if (keepMounted || open || visible)
    return <BottomSheet open={open} onExit={handleExit} {...rest} />;

  return null;
}
