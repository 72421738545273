import React from 'react';
import { Box, Typography } from '@mui/material';
import { NFTEmojiAlien as NFTEmojiAlienIcon } from '@front/icon';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: 0.5, md: 1 },
  },
  emoji: {
    width: { xs: 64, md: 180 },
    height: { xs: 64, md: 180 },
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: { xs: 48, md: 110 },
      height: { xs: 86, md: 200 },
    },
  },
  title: {
    opacity: 0.5,
  },
};
export default function CardLayoutEmpty({ title }: { title: string }) {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.emoji}>
        <NFTEmojiAlienIcon />
      </Box>
      <Typography sx={styles.title}>{title}</Typography>
    </Box>
  );
}
