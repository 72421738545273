import { Box } from '@mui/material';
import {
  OtherPause as OtherPauseIcon,
  OtherPlay as OtherPlayIcon,
} from '@front/icon';

import { TipButton } from '../../../atoms';

const styles = {
  root: {
    flex: '0 0 24px',
  },
  disabled: {
    opacity: 0.5,
    cursor: 'default',
  },
};
export default function PlayControl({
  disabled,
  onlyOnce,
  played,
  isPlaying,
  onTogglePlay,
}: {
  disabled: boolean;
  onlyOnce: boolean;
  played: boolean;
  isPlaying: boolean;
  onTogglePlay: () => void;
}) {
  const controlDisabled = onlyOnce && played;

  return (
    <Box sx={styles.root}>
      {isPlaying ? (
        <TipButton
          sx={[controlDisabled && styles.disabled]}
          title={
            controlDisabled ? 'Can’t pause the audio on Mock mode' : 'Pause'
          }
          customSize={24}
          disabled={disabled}
          onClick={controlDisabled ? undefined : onTogglePlay}
          disableRipple={controlDisabled}
        >
          <OtherPauseIcon />
        </TipButton>
      ) : (
        <TipButton
          sx={[controlDisabled && styles.disabled]}
          title={onlyOnce ? 'You can only play this once' : 'Play'}
          customSize={24}
          disabled={disabled}
          onClick={controlDisabled ? undefined : onTogglePlay}
          disableRipple={controlDisabled}
        >
          <OtherPlayIcon />
        </TipButton>
      )}
    </Box>
  );
}
