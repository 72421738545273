import { createContext, Dispatch, ReactNode, SetStateAction } from 'react';
import { useLocalStorage } from '@front/helper';

type ShortIdFullIdMap = Record<string, string | null>;

type ShortIdPoolValue = {
  quizShortIdToFullId: ShortIdFullIdMap;
  setQuizShortIdToFullId: Dispatch<SetStateAction<ShortIdFullIdMap>>;
  dynamicSourceIdToFullId: ShortIdFullIdMap;
  setDynamicSourceIdToFullId: Dispatch<SetStateAction<ShortIdFullIdMap>>;
};

const initialValue: ShortIdPoolValue = {
  quizShortIdToFullId: {},
  setQuizShortIdToFullId: () => {},
  dynamicSourceIdToFullId: {},
  setDynamicSourceIdToFullId: () => {},
};

export const ShortIdPoolContext = createContext<ShortIdPoolValue>(initialValue);

export const ShortIdPoolContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [quizShortIdToFullId, setQuizShortIdToFullId] =
    useLocalStorage<ShortIdFullIdMap>('short-id-map-quiz', {});
  const [dynamicSourceIdToFullId, setDynamicSourceIdToFullId] =
    useLocalStorage<ShortIdFullIdMap>('short-id-map-dynamic-source', {});

  return (
    <ShortIdPoolContext.Provider
      value={{
        quizShortIdToFullId,
        setQuizShortIdToFullId,
        dynamicSourceIdToFullId,
        setDynamicSourceIdToFullId,
      }}
    >
      {children}
    </ShortIdPoolContext.Provider>
  );
};
