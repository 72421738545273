import { useEffect, useState } from 'react';
import { apis, UploadResourceType } from '@lib/web/apis';

/**
 * XXX: we need a way to store signedUrl, make it stable when rendering,
 * while it might be outdated if we save it in global variable like here,
 * but it should be safe because signedUrl is valid multiple days, which is enough for user to refresh the page
 */
const signedUrlMap: {
  [key in UploadResourceType]?: Record<string, string>;
} = {};

export const useSignedUrlMultiple = ({
  keys = [],
  resourceType,
}: {
  keys: string[];
  resourceType: UploadResourceType;
}) => {
  const [returnedUrls, setReturnedUrls] = useState(signedUrlMap[resourceType]);

  useEffect(() => {
    const updateNonExistSignedUrl = async () => {
      if (!signedUrlMap[resourceType]) {
        signedUrlMap[resourceType] = {};
      }

      const urls = signedUrlMap[resourceType] as Record<string, string>;

      for (const key of keys) {
        try {
          const res = await apis.file.getSignedUrl(resourceType, key);

          if (res) {
            urls[key] = res.data.data.signedUrl;
          }
        } catch (e) {
          console.warn('getSignedUrl fail', e);
        }
      }

      setReturnedUrls((prev) => ({ ...prev, ...urls }));
    };

    void updateNonExistSignedUrl();
  }, [keys, resourceType]);

  return returnedUrls;
};
