import { useCallback, useContext } from 'react';

import { AsyncTaskContext, WatchingConfig } from '../contexts';

export default function useWatchAsyncTask() {
  const { watchingConfigsRef } = useContext(AsyncTaskContext);

  const watchAsyncTask = useCallback(
    (config: WatchingConfig) => {
      if (watchingConfigsRef.current) {
        watchingConfigsRef.current.push(config);
      }
    },
    [watchingConfigsRef]
  );

  return {
    watchAsyncTask,
  };
}
