import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material/Checkbox';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  OtherCheckedSquareSolid as OtherCheckedSquareSolidIcon,
  OtherCheckSquareOutline as OtherCheckSquareOutlineIcon,
} from '@front/icon';

export type CheckboxProps = MuiCheckboxProps & {
  label?: string;
  subLabel?: string;
  labelPosition?: 'end' | 'start';
  labelContainerSx?: BoxProps['sx'];
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    px: 2.5,
    py: 1,
    '& .Mui-disabled + div': {
      opacity: 0.5,
    },
    '& p': {
      typography: 'body2',
    },
  },
  startLabel: {
    justifyContent: 'space-between',
  },
  enabled: {
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  subLabel: { mt: 0.5, opacity: 0.5 },
  checkbox: {
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  leftCheckbox: { ml: '-9px' },
  rightCheckbox: { mr: '-9px', order: 1 },
};

export default function Checkbox({
  label,
  subLabel,
  labelPosition = 'end',
  disabled,
  style,
  sx,
  labelContainerSx,
  className,
  ...rest
}: CheckboxProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const iconProps = { width: 20, height: 20 };

  const checkBoxItem = (
    <MuiCheckbox
      {...rest}
      color="default"
      icon={<OtherCheckSquareOutlineIcon {...iconProps} />}
      checkedIcon={<OtherCheckedSquareSolidIcon {...iconProps} />}
      sx={[
        styles.checkbox,
        labelPosition === 'end' ? styles.leftCheckbox : styles.rightCheckbox,
        ...sxProps,
      ]}
      disabled={disabled}
    />
  );

  if (label) {
    const labelContainerSxProps = Array.isArray(labelContainerSx)
      ? labelContainerSx
      : [labelContainerSx];

    return (
      <Box
        sx={[
          styles.container,
          labelPosition === 'start' && styles.startLabel,
          !disabled && styles.enabled,
          ...labelContainerSxProps,
        ]}
        component="label"
        className={className}
      >
        {checkBoxItem}
        <Box
          sx={{
            order: labelPosition === 'end' ? 1 : 0,
          }}
        >
          <Typography>{label}</Typography>
          {!!subLabel && (
            <Typography sx={styles.subLabel}>{subLabel}</Typography>
          )}
        </Box>
      </Box>
    );
  }
  return checkBoxItem;
}
