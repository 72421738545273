import React from 'react';
import { Box, BoxProps, Theme } from '@mui/material';

export type PopupActionsProps = BoxProps;
const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    gap: 2,
    pointerEvents: 'auto',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      '& .MuiButtonBase-root': {
        flex: 1,
      },
    },
  }),
};
export default function PopupActions({ sx, ...rest }: PopupActionsProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return <Box sx={[styles.root, ...sxProps]} {...rest} />;
}
