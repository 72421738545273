import {
  MouseEvent,
  TouchEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLatestValueRef } from '@front/helper';

export default function useLongPress<T>(
  callback?: (ev: MouseEvent<T> | TouchEvent<T>) => void,
  ms = 300
) {
  const [startLongPress, setStartLongPress] = useState(false);
  const eventRef = useRef<MouseEvent<T> | TouchEvent<T>>();
  const callbackRef = useLatestValueRef(callback);
  useEffect(() => {
    let timerId: NodeJS.Timer;

    if (startLongPress && callbackRef.current && eventRef.current) {
      const eventCallBack = () => {
        if (eventRef.current) {
          callbackRef.current?.(eventRef.current);
        }
        setStartLongPress(false);
      };

      timerId = setTimeout(eventCallBack, ms);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callbackRef, ms, startLongPress]);

  const handleMouseDown = useCallback((event: MouseEvent<T>): void => {
    eventRef.current = event;
    setStartLongPress(true);
  }, []);
  const handleMouseUp = useCallback((event: MouseEvent<T>): void => {
    eventRef.current = event;
    setStartLongPress(false);
  }, []);
  const handleMouseLeave = useCallback((event: MouseEvent<T>): void => {
    eventRef.current = event;
    setStartLongPress(false);
  }, []);
  const handleTouchStart = useCallback((event: TouchEvent<T>): void => {
    eventRef.current = event;
    setStartLongPress(true);
  }, []);
  const handleTouchEnd = useCallback((event: TouchEvent<T>): void => {
    eventRef.current = event;
    setStartLongPress(false);
  }, []);

  return {
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onMouseLeave: handleMouseLeave,
    onTouchStart: handleTouchStart,
    onTouchEnd: handleTouchEnd,
  };
}
