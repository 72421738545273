import { Trans } from 'react-i18next';
import { Box } from '@mui/material';

export default function StepBreadcrumb({
  total,
  step,
  page,
}: {
  total: number | string;
  step: number | string;
  page?: string;
}) {
  return (
    <Box>
      <Box sx={{ span: { opacity: 0.64 } }}>
        <Trans
          i18nKey="Step ## of ##"
          values={{ total, step, page }}
          context={page ? 'page' : undefined}
          components={{ 1: <span /> }}
        />
      </Box>
    </Box>
  );
}
