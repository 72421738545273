import { alpha, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SimpleTooltip, useTruncated } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';

import { DisplayTableLayoutTagCell } from '../../types';

export type IaDisplayTableTagCellProps = DisplayTableLayoutTagCell;

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    overflow: 'hidden',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '4px',
    px: '6px',
    py: '2px',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    borderRadius: '4px',
    overflow: 'hidden',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
export default function IaDisplayTableTagCell({
  icon,
  text,
}: IaDisplayTableTagCellProps) {
  const { containerRef, isTruncated } = useTruncated();
  return (
    <Box sx={styles.root}>
      <Box sx={styles.tag}>
        {icon && icon.type === 'icon' && (
          <Icon
            name={icon.value}
            width={icon.iconSize || 16}
            height={icon.iconSize || 16}
            text={icon.text}
          />
        )}
        {icon && icon.type === 'emoji' && (
          <Typography variant="body2">{icon.value}</Typography>
        )}
        <Typography ref={containerRef} variant="caption" sx={styles.text}>
          {isTruncated ? (
            <SimpleTooltip title={text}>
              <Box component="span">{text}</Box>
            </SimpleTooltip>
          ) : (
            text
          )}
        </Typography>
      </Box>
    </Box>
  );
}
