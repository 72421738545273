import React from 'react';

export default function OtherFix({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M20.7643 17.7124L13.3299 10.233C14.0652 8.34256 13.6567 6.1234 12.1045 4.56176C10.4706 2.91794 8.0197 2.58917 6.059 3.49328L9.57193 7.0275L7.12105 9.49323L3.52643 5.95901C2.54608 7.9316 2.95456 10.3973 4.58847 12.0412C6.1407 13.6028 8.34649 14.0137 10.2255 13.274L17.6598 20.7534C17.9866 21.0822 18.4768 21.0822 18.8036 20.7534L20.6826 18.863C21.0911 18.5343 21.0911 17.9589 20.7643 17.7124Z"
        fill="currentColor"
      />
    </svg>
  );
}
