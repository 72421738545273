import React from 'react';

export default function CryptoCurrencyBitcoinAlt({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M23.6412 14.9029C22.0385 21.3315 15.5274 25.2438 9.09796 23.6407C2.67126 22.0381 -1.24108 15.5265 0.362312 9.09837C1.96429 2.66903 8.47546 -1.24361 14.9029 0.359081C21.3318 1.96177 25.2439 8.47405 23.641 14.903L23.6412 14.9029H23.6412Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M17.293 10.2903C17.5318 8.6934 16.316 7.83498 14.6535 7.26233L15.1929 5.09914L13.8761 4.77103L13.351 6.87726C13.0049 6.79092 12.6493 6.70957 12.296 6.62892L12.8248 4.50878L11.5089 4.18066L10.9692 6.34315C10.6828 6.27793 10.4014 6.21347 10.1284 6.14554L10.1299 6.13874L8.31404 5.68528L7.96376 7.09171C7.96376 7.09171 8.94071 7.31565 8.92012 7.32944C9.45336 7.46252 9.54978 7.81551 9.53377 8.09529L8.91942 10.5597C8.95614 10.569 9.00377 10.5825 9.05632 10.6036L9.01805 10.5941L9.01802 10.594C8.98554 10.5859 8.95179 10.5775 8.91713 10.5692L8.05602 14.0214C7.99085 14.1834 7.82545 14.4266 7.45264 14.3342C7.46583 14.3534 6.49556 14.0954 6.49556 14.0954L5.8418 15.6027L7.5554 16.0299C7.74305 16.077 7.92849 16.1253 8.11204 16.1731L8.11206 16.1731C8.24033 16.2066 8.36768 16.2398 8.49423 16.2721L7.94933 18.4601L9.2646 18.7883L9.80423 16.6235C10.1635 16.721 10.5123 16.811 10.8536 16.8958L10.3158 19.0504L11.6327 19.3785L12.1775 17.1946C14.423 17.6196 16.1113 17.4482 16.822 15.4172C17.3946 13.7821 16.7935 12.8389 15.6122 12.2238C16.4726 12.0254 17.1207 11.4595 17.2935 10.2905L17.2931 10.2902L17.293 10.2903ZM14.2843 14.5092C13.911 16.0094 11.5627 15.3892 10.4883 15.1054C10.3917 15.0799 10.3053 15.0571 10.2316 15.0388L10.9547 12.14C11.0445 12.1625 11.1542 12.1871 11.2785 12.215C12.3899 12.4644 14.6669 12.9754 14.2844 14.5092H14.2843ZM11.5014 10.8696C12.3972 11.1086 14.3513 11.6301 14.6916 10.2666H14.6917C15.0392 8.87208 13.1402 8.45164 12.2127 8.2463C12.1083 8.2232 12.0163 8.20282 11.9411 8.18408L11.2855 10.8131C11.3474 10.8285 11.4201 10.8479 11.5014 10.8696Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
}
