import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@lib/web/apis';
import { ThreadCurrentLocationContext } from '@lib/web/thread';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import {
  defaultHeadThumbnailDetail,
  getHeadThumbnailChannelData,
} from '@lib/web/thread/utils/headThumbnailUtils';
import { ChannelData } from 'stream-chat';

import { getLocationChannelData } from '../../utils/locationUtils';

import {
  getChannelFilters,
  getViewChannelData,
  getViewIcon,
  getViewName,
  getViewNameMembers,
  parseViewDetail,
} from './utils';

export const useThreadViewDetails = () => {
  const { t } = useTranslation('thread');
  const { member } = useAuth();
  const { getThreadUser, getThreadLocationDisplay } = useThread();
  const { currentLocationDetail } = useContext(ThreadCurrentLocationContext);

  const getThreadViewDetail = useCallback(
    (view?: GetThreadViewRes) => {
      const currentHeadThumbnailDetail = defaultHeadThumbnailDetail;
      const currentLocationDisplay = getThreadLocationDisplay(
        currentLocationDetail
      );
      const viewDetail = parseViewDetail({
        view,
        myMemberId: member?.memberId,
      });

      const viewName = getViewName({
        ...viewDetail,
        getThreadUser,
        t,
      });
      const viewIcon = getViewIcon({
        ...viewDetail,
        locationDisplay: currentLocationDisplay,
        getThreadUser,
      });

      const viewNameMembers = getViewNameMembers({
        ...viewDetail,
        getThreadUser,
        t,
      });

      const channelFilters = getChannelFilters(
        viewDetail,
        currentLocationDetail
      );
      const channelData: ChannelData = {
        ...getViewChannelData(viewDetail),
        ...getLocationChannelData(currentLocationDetail),
        ...getHeadThumbnailChannelData(currentHeadThumbnailDetail),
      };

      return {
        ...viewDetail,
        viewName,
        viewIcon,
        viewNameMembers,
        channelFilters,
        channelData,
      };
    },
    [
      currentLocationDetail,
      getThreadLocationDisplay,
      getThreadUser,
      member?.memberId,
      t,
    ]
  );

  return {
    getThreadViewDetail,
  };
};
