import React from 'react';

export default function TestChinese({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 3C4.8 3 3 4.8 3 12C3 19.2 4.8 21 12 21C19.2 21 21 19.2 21 12C21 4.8 19.2 3 12 3Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M7.53955 8.30986V13.7855H8.84635V9.58426H15.1104V13.7315H16.4712V8.30986H7.53955ZM8.21995 11.9063V13.1807H15.8664V11.9063H8.21995ZM11.2872 6.43066V16.5719H12.6588V6.43066H11.2872Z"
        fill="#080808"
      />
    </svg>
  );
}
