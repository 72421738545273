import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import { Popper, Portal } from '@mui/material';
import Box from '@mui/material/Box';

export type StickyContainerProps = {
  anchorEl: HTMLDivElement | null;
  children: ReactNode;
  offset?: {
    top?: number;
    left?: number;
  };
};

/**
 * The element behaves just like position: sticky,
 * the position: sticky only support parent element has scroll bar, but our horizontal and vertical scroll is not the same parent
 */
export default function StickyContainer({
  anchorEl,
  offset,
  children,
}: StickyContainerProps) {
  const scrollEl = useMemo(() => {
    const horizontalScrollEl = anchorEl?.closest('.simplebar-content-wrapper');
    const verticalScrollEl = horizontalScrollEl?.parentElement?.closest(
      '.simplebar-content-wrapper'
    );
    return {
      horizontal: horizontalScrollEl,
      vertical: verticalScrollEl,
    };
  }, [anchorEl]);

  const calcStickyPosition = useCallback(() => {
    if (!anchorEl) return false;

    if (!scrollEl.vertical || !scrollEl.horizontal) {
      console.warn('StickyContainer: cannot find corresponding scroll bar');
      return false;
    }

    const anchorRect = anchorEl.getBoundingClientRect();
    const minimumTop = offset?.top ?? 0; // we don't use verticalScrollEl's top to handle the case in right panel
    const minimumLeft =
      scrollEl.horizontal.getBoundingClientRect().left + (offset?.left ?? 0);

    const shouldVerticalBeSticky = anchorRect.top < minimumTop;
    const shouldHorizontalBeSticky = anchorRect.left < minimumLeft;

    const shouldBeSticky = shouldVerticalBeSticky || shouldHorizontalBeSticky;

    if (shouldBeSticky) {
      return {
        top: shouldVerticalBeSticky ? minimumTop : anchorRect.top,
        left: shouldHorizontalBeSticky ? minimumLeft : anchorRect.left,
      };
    }
    return false;
  }, [
    anchorEl,
    offset?.left,
    offset?.top,
    scrollEl.horizontal,
    scrollEl.vertical,
  ]);

  const [stickyPosition, setStickyPosition] = useState<
    false | { top: number; left: number }
  >(calcStickyPosition());

  useEffect(() => {
    const refreshStickyPosition = () => {
      const newStickyPosition = calcStickyPosition();

      if (newStickyPosition && !stickyPosition) {
        flushSync(() => {
          // make it immediate render to prevent the element jumping
          setStickyPosition(newStickyPosition);
        });
      }
      if (!newStickyPosition && stickyPosition) {
        setStickyPosition(false);
      }
    };

    scrollEl.vertical?.addEventListener('scroll', refreshStickyPosition);
    scrollEl.horizontal?.addEventListener('scroll', refreshStickyPosition);
    return () => {
      scrollEl.vertical?.removeEventListener('scroll', refreshStickyPosition);
      scrollEl.horizontal?.removeEventListener('scroll', refreshStickyPosition);
    };
  }, [
    calcStickyPosition,
    scrollEl.horizontal,
    scrollEl.vertical,
    stickyPosition,
  ]);

  if (stickyPosition) {
    return (
      <Portal>
        <Box
          sx={{
            position: 'fixed',
            zIndex: 3000,
            top: stickyPosition.top,
            left: stickyPosition.left,
          }}
        >
          {children}
        </Box>
      </Portal>
    );
  }

  return (
    <Popper
      open
      anchorEl={anchorEl}
      sx={{ zIndex: 3000 }}
      popperOptions={{
        placement: 'top-start',
      }}
    >
      {children}
    </Popper>
  );
}
