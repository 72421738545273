export const reactionSuggestions = [
  {
    id: 'b1041fbe-be88-47ef-9b2e-44394a39fc24',
    type: 26,
    code: '❤️',
    name: 'Red Heart',
  },
  {
    id: 'da7644be-5f62-4860-9c58-c69415829bdf',
    type: 26,
    code: '⚠️',
    name: 'Warning',
  },
  {
    id: '708f409f-e7df-4ff7-9784-7a7c3604fb80',
    type: 20,
    code: '🤬',
    name: 'Face with Symbols on Mouth',
  },
  {
    id: 'cd1f983e-a63a-4a82-82bb-daf4dddaa122',
    type: 20,
    code: '🤔',
    name: 'Thinking Face',
  },
  {
    id: 'd711ffdb-cbd2-40c9-baf1-e1cc3503f2fb',
    type: 20,
    code: '😢',
    name: 'Crying Face',
  },
];
