import { useUser } from '@auth0/nextjs-auth0/client';
import { setClientToken, useAuth } from '@lib/web/apis';

export default function LoginListener() {
  const { user } = useUser();
  const { guest } = useAuth();

  const token = (user?.accessToken || guest.accessToken || '') as string;
  setClientToken(token);

  return null;
}
