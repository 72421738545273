import React from 'react';

export default function LeagueBadgeLocked({ ...rest }) {
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 76C60.7777 76 76 60.7777 76 42C76 23.2223 60.7777 8 42 8C23.2223 8 8 23.2223 8 42C8 60.7777 23.2223 76 42 76ZM42 80C62.9868 80 80 62.9868 80 42C80 21.0132 62.9868 4 42 4C21.0132 4 4 21.0132 4 42C4 62.9868 21.0132 80 42 80Z"
        fill="#565656"
      />
      <circle cx="42" cy="36" r="8" fill="#565656" />
      <rect x="38" y="36" width="8" height="20" fill="#565656" />
    </svg>
  );
}
