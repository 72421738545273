import QuestionContent from './components/QuestionContent';
import { QuestionContentSkeleton } from './components/QuestionContent/QuestionContent';
import QuestionFooter from './components/QuestionFooter';

export function PracticeSkeleton() {
  return <QuestionContentSkeleton />;
}

export default function Practice() {
  return (
    <>
      <QuestionContent />
      <QuestionFooter />
    </>
  );
}

export const PracticeQuestionContent = QuestionContent;
export const PracticeQuestionFooter = QuestionFooter;
