import { Fragment, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  alpha,
  Box,
  ButtonBase,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';
import {
  BlockNoteEditor,
  BlockSchema,
  DefaultBlockSchema,
} from '@blocknote/core';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionChevronUp as ActionChevronUpIcon,
  TextEditorTypeText as TextEditorTypeTextIcon,
} from '@front/icon';
import { ResponsiveMenu, SimpleTooltip } from '@front/ui';
import { ComposerToolbarMenuItem } from '@lib/web/composer';
import ThemeProvider from '@lib/web/composer/components/ThemeProvider';
import groupBy from 'lodash/groupBy';

const styles = {
  moreToolbar: {
    typography: 'body2',
    gap: 1,
    borderRadius: '2px 0 0 2px',
    px: 1.5,
    height: '28px',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        color: 'text.primary',
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
    borderRight: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
  },
  menu: {
    '& .MuiPaper-root': {
      width: '260px',
    },
  },
  hint: {
    flex: 'unset',
    ml: 'auto',

    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    fontFamily: 'Inconsolata',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 1.5,
  },
};

type TurnIntoOrTransformDropdownProps<
  BSchema extends BlockSchema = DefaultBlockSchema
> = {
  editor: BlockNoteEditor<BSchema>;
  toolbarItems: ComposerToolbarMenuItem<BSchema>[];
  onConvertBlockType?: (type: string) => void;
};

export default function TurnIntoOrTransformDropdown<T extends BlockSchema>({
  editor,
  toolbarItems,
  onConvertBlockType,
}: TurnIntoOrTransformDropdownProps<T>) {
  const { t } = useTranslation('editor');
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    editor.focus();
  };

  const toolbarMenuGroups = useMemo(
    () => groupBy(toolbarItems, (item) => item.type),
    [toolbarItems]
  );

  const currentBlock = useMemo(
    () => editor.getTextCursorPosition().block,
    [editor]
  );

  const selectedMenuItem = useMemo(
    () => toolbarItems.find((item) => item.isSelected(currentBlock)),
    [toolbarItems, currentBlock]
  );

  return (
    <ThemeProvider mode="dark">
      <SimpleTooltip
        title={t('composer.formattingToolbar.Turn into')}
        slotProps={{
          popper: {
            modifiers: [{ name: 'offset', options: { offset: [0, -8] } }],
          },
        }}
        placement="top-start"
      >
        <ButtonBase
          ref={buttonRef}
          sx={styles.moreToolbar}
          onClick={handleOpen}
        >
          <TextEditorTypeTextIcon width={16} height={16} />
          <Typography variant="body2">{selectedMenuItem?.display}</Typography>
          {open && <ActionChevronDownIcon width={16} height={16} />}
          {!open && <ActionChevronUpIcon width={16} height={16} />}
        </ButtonBase>
      </SimpleTooltip>

      <ResponsiveMenu
        open={open}
        onClose={handleClose}
        menuProps={{
          sx: styles.menu,
          anchorEl: buttonRef.current,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: -32,
            horizontal: 10,
          },
        }}
        sheetProps={{
          defaultContentHeight: toolbarItems.length * 45,
        }}
      >
        <Box>
          {[
            {
              type: 'turnInto',
              label: t('composer.formattingToolbar.Turn into'),
            },
            {
              type: 'transform',
              label: t('composer.formattingToolbar.Transform'),
            },
          ].map(({ type, label }) => {
            const group = toolbarMenuGroups[type];
            if (!group || group.length === 0) return null;

            return (
              <Fragment key={type}>
                <ListSubheader>{label}</ListSubheader>
                {group.map((item) => (
                  <MenuItem
                    key={item.nodeType}
                    value={item.nodeType}
                    onClick={() => item.onClick(editor, onConvertBlockType)}
                    selected={item.isSelected(currentBlock)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText>{item.display}</ListItemText>
                    <ListItemText sx={styles.hint}>{item.hint}</ListItemText>
                  </MenuItem>
                ))}
              </Fragment>
            );
          })}
        </Box>
      </ResponsiveMenu>
    </ThemeProvider>
  );
}
