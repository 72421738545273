import { alpha, Box, Theme } from '@mui/material';
import { AnswerFormatType } from '@lib/web/apis';

import usePracticeQuestion from '../../../../hooks/usePracticeQuestion';
import usePracticeQuestionStatus from '../../../../hooks/usePracticeQuestionStatus';

import AnswerHintCommon from './components/AnswerHintCommon';
import AnswerHintMultipleChoices from './components/AnswerHintMultipleChoices';
import AnswerHintMultipleResponse from './components/AnswerHintMultipleResponse';
import AnswerHintSubmitted from './components/AnswerHintSubmitted';
import AnswerHintTrueFalseNotGiven from './components/AnswerHintTrueFalseNotGiven';

const styles = {
  root: {
    '& .MuiTypography-root': {
      display: 'block',
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
      span: {
        color: 'text.primary',
      },
    },
  },
};
const getAnswerHint = (answerFormatType: AnswerFormatType) => {
  switch (answerFormatType) {
    case AnswerFormatType.MultipleChoice:
      return <AnswerHintMultipleChoices />;

    case AnswerFormatType.MultipleResponse:
      return <AnswerHintMultipleResponse />;

    case AnswerFormatType.GridIn:
      return <AnswerHintCommon answerFormat={answerFormatType} />;

    case AnswerFormatType.TrueFalseNotGiven:
      return <AnswerHintTrueFalseNotGiven />;

    case AnswerFormatType.FreeResponse:
      return <AnswerHintCommon answerFormat={answerFormatType} />;

    case AnswerFormatType.Unscramble:
      return <AnswerHintCommon answerFormat={answerFormatType} />;

    case AnswerFormatType.Essay:
      return <AnswerHintCommon answerFormat={answerFormatType} />;

    default:
      console.error('not supported answer format type', answerFormatType);
      return null;
  }
};

export type AnswerHintProps = {
  readonly?: boolean;
};

export default function AnswerHint({ readonly }: AnswerHintProps) {
  const { id, answerFormatType } = usePracticeQuestion();
  const { isSubmitted } = usePracticeQuestionStatus();
  const answerHint = isSubmitted ? (
    <AnswerHintSubmitted key={id} />
  ) : readonly ? null : (
    getAnswerHint(answerFormatType)
  );

  return <Box sx={styles.root}>{answerHint}</Box>;
}
