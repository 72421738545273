import { Theme, useMediaQuery } from '@mui/material';

import { getToolbarButtonConfig, reorderToolbarButtonItems } from '../configs';
import { ToolbarButtonItem } from '../types';

export default function useToolbarButtons(
  items: Array<boolean | ToolbarButtonItem>,
  {
    /**
     * XXX: design guideline doesn't cover how to handle the visibility in RHS,
     * currently we only know the position of share button in RHS should be visible, otherwise it should be in three dots,
     * if we have a clear rule in the future, we might move this logic into configurations
     */
    position = 'centre',
  }: { position?: 'centre' | 'rhs' } = {}
) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const validItems: ToolbarButtonItem[] = [];
  items.forEach((item) => {
    if (item && typeof item !== 'boolean') validItems.push(item);
  });

  reorderToolbarButtonItems(validItems, isMobile);

  const visibleButtons = validItems.filter((item) => {
    if (item.hiddenInMore) return false;
    const config = getToolbarButtonConfig(item.type, item.customConfig);
    return isMobile ? config.visible.mobile : config.visible.desktop;
  });

  const threeDotsButtons = validItems.filter((item) => {
    if (item.hiddenInMore) return true;
    const config = getToolbarButtonConfig(item.type, item.customConfig);
    return isMobile ? !config.visible.mobile : !config.visible.desktop;
  });

  if (position === 'rhs') {
    return {
      visibleButtons: validItems.filter((item) => item.type === 'Share'),
      threeDotsButtons: validItems.filter((item) => item.type !== 'Share'),
    };
  }

  return {
    visibleButtons,
    threeDotsButtons,
  };
}
