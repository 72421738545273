import Box from '@mui/material/Box';

import { TableLayoutHeadGroupCell } from '../../types';

import IaTableHeadSimpleCell from './IaTableHeadSimpleCell';

export type IaHeadDefaultCellProps = TableLayoutHeadGroupCell;

const styles = {
  root: {
    display: 'grid',
    alignItems: 'center',
    height: '100%',
  },
};
export default function IaGroupHeadCell({
  gridTemplateColumns,
  cells,
}: IaHeadDefaultCellProps) {
  return (
    <Box sx={[styles.root, { gridTemplateColumns }]}>
      {cells.map((item, index) => (
        <IaTableHeadSimpleCell {...item} key={index} />
      ))}
    </Box>
  );
}
