import React from 'react';

export default function TestWriting({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.6022 7.53973H8.1157C7.74726 7.53962 7.38771 7.65289 7.08587 7.86416C6.78403 8.07543 6.55453 8.37446 6.42852 8.72065L3.05416 18.0011C2.9348 18.3287 3.01646 18.6957 3.26236 18.9424L3.52531 19.2054L9.28865 13.4425C9.28775 13.4192 9.28147 13.3959 9.28147 13.3726C9.28147 13.1063 9.36042 12.8461 9.50834 12.6247C9.65626 12.4034 9.8665 12.2309 10.1125 12.129C10.3585 12.0271 10.6291 12.0004 10.8902 12.0524C11.1514 12.1043 11.3912 12.2325 11.5795 12.4208C11.7678 12.609 11.896 12.8488 11.9479 13.11C11.9999 13.3711 11.9732 13.6417 11.8713 13.8877C11.7694 14.1336 11.5969 14.3438 11.3755 14.4917C11.1541 14.6396 10.8939 14.7186 10.6276 14.7186C10.6043 14.7186 10.581 14.7123 10.5576 14.7114L4.79429 20.4742L5.05724 20.7372C5.1779 20.8581 5.33067 20.942 5.4975 20.9789C5.66432 21.0158 5.83823 21.0041 5.99865 20.9453L15.2799 17.5704C15.6262 17.4444 15.9252 17.2149 16.1365 16.9131C16.3478 16.6113 16.4611 16.2518 16.461 15.8834V12.3971L18.2549 10.6042L13.3962 5.74591L11.6022 7.53973Z"
        fill="currentColor"
      />
      <path
        d="M19.0974 9.39008L14.6103 4.90329L16.5128 3L21 7.48678L19.0974 9.39008Z"
        fill="currentColor"
      />
    </svg>
  );
}
