import { Box, BoxProps } from '@mui/material';

import { IndicatorGroup } from '../../components';
import SquareAvatar from '../SquareAvatar';

type MentionProps = {
  src?: string;
  alt?: string;
  display?: string;
  prefix?: string;
  indicators?:
    | {
        icon: string;
        name: string;
      }[]
    | undefined;
  onClick?: BoxProps['onClick'];
  onMouseEnter?: BoxProps['onMouseEnter'];
  className?: string;
};

const styles = {
  highlight: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    mt: '-3px', // XXX: use margin top -3px here to make it vertical align with the text nearby
    mx: 0.5,
    px: '2px',
    gap: '2px',
    borderRadius: '2px',
    backgroundColor: 'alpha.primaryLightA10',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'alpha.primaryLightA30',
    },
  },
  display: {
    color: 'primary.main',
    typography: 'chatBody',
  },
  proDisplay: {
    background:
      'linear-gradient(90deg, #B6D0F7 0%, #EEF4E1 15%, #FAFCFE 30%, #E2C1F9 45%, #BFD1F9 58%, #FAFCFE 75%, #BAE4E2 85%, #B6D0F7 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
  },
  unlimitedDisplay: {
    background:
      'linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
  },
};

export default function Mention({
  src,
  alt,
  display,
  indicators,
  prefix,
  onClick,
  onMouseEnter,
  className,
}: MentionProps) {
  const isPro = Boolean(
    indicators?.some((indicator) => indicator.icon === 'BadgeProPlan')
  );
  const isUnlimited = Boolean(
    indicators?.some((indicator) => indicator.icon === 'BadgeUnlimitedPlan')
  );
  return (
    <Box
      sx={styles.highlight}
      component="span"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      className={className}
    >
      <SquareAvatar size={16} src={src} inlineComponent>
        {alt || display}
      </SquareAvatar>

      <Box
        sx={[
          styles.display,
          isPro && styles.proDisplay,
          isUnlimited && styles.unlimitedDisplay,
        ]}
        component="span"
        className="mention-text"
      >
        {prefix}
        {display}
      </Box>

      {!!indicators?.length && (
        <IndicatorGroup sx={{ pr: 0 }} indicators={indicators} size={16} />
      )}
    </Box>
  );
}
