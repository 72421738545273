import React from 'react';

export default function ActionSlash({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.4786 2.81002C14.7698 2.12628 15.5456 1.81451 16.2113 2.11364C16.877 2.41277 17.1806 3.20954 16.8894 3.89328L9.52142 21.19C9.23017 21.8737 8.45439 22.1855 7.78868 21.8864C7.12296 21.5872 6.81939 20.7905 7.11064 20.1067L14.4786 2.81002Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
