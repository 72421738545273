import {
  BlockSpec,
  createTipTapBlock,
  defaultProps,
  mergeCSSClasses,
  PropSchema,
} from '@blocknote/core';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import { mergeAttributes } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

export const subtitlePropSchema = {
  ...defaultProps,
} satisfies PropSchema;

const Subtitle = createTipTapBlock<'subtitle', true>({
  name: BasicBlockTypes.Subtitle,
  content: 'inline*',

  addAttributes() {
    return {};
  },

  renderHTML({
    HTMLAttributes,
  }: {
    node: ProseMirrorNode;
    HTMLAttributes: Record<string, any>;
  }) {
    const blockContentDOMAttributes =
      this.options.domAttributes?.blockContent || {};
    const inlineContentDOMAttributes =
      this.options.domAttributes?.inlineContent || {};

    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        ...blockContentDOMAttributes,
        class: mergeCSSClasses(blockContentDOMAttributes.class),
        'data-content-type': this.name,
      }),
      [
        'p',
        {
          ...inlineContentDOMAttributes,
          class: mergeCSSClasses(inlineContentDOMAttributes.class),
        },
        0,
      ],
    ];
  },
});

export const SubtitleBlock = {
  node: Subtitle,
  propSchema: subtitlePropSchema,
} satisfies BlockSpec<'subtitle', typeof subtitlePropSchema, true>;

export default SubtitleBlock;
