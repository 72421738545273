import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDebounce } from '@front/helper';
import { useBaseRightPanel } from '@front/ui';

const SEARCH_MIN_CHAR = 2;

export default function useSearchStatus(defaultSearch = '') {
  const { rightPanelOpened } = useBaseRightPanel();

  const [search, setSearch] = useState<string>(defaultSearch);
  const [focused, setFocused] = useState(false);
  const debouncedSearch = useDebounce(search.trim());
  const timeoutRef = useRef<NodeJS.Timeout>();
  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSearch(ev.target.value);
  };
  const onBlur = () => {
    timeoutRef.current = setTimeout(() => {
      // blur > click
      setFocused(false);
    }, 200);
  };
  const onFocus = () => {
    clearTimeout(timeoutRef.current);
    setFocused(true);
  };

  const debouncedSearchVal =
    search && debouncedSearch.length >= SEARCH_MIN_CHAR ? debouncedSearch : '';

  useEffect(() => {
    if (!rightPanelOpened) setSearch('');
  }, [rightPanelOpened]);

  return {
    onChange,
    focused,
    setFocused,
    search,
    debouncedSearch: debouncedSearchVal,
    isSearching: !!debouncedSearchVal,
    setSearch,
    onBlur,
    onFocus,
  };
}
