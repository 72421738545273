import React from 'react';

export default function TextEditorItalic({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.79 16.5H13.5V18.5H6.5V16.5H8.79H9.12002L9.24975 16.1965L12.6698 8.19654L12.9675 7.5H12.21H10.5V5.5H17.5V7.5H15.21H14.88L14.7502 7.80346L11.3302 15.8035L11.0325 16.5H11.79Z"
        fill="currentColor"
      />
    </svg>
  );
}
