import React from 'react';

export default function OtherProbabilityHalf({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C9.2522 22 6.76325 20.8917 4.95577 19.0978C4.95033 19.0924 4.9449 19.087 4.93948 19.0816C4.93596 19.0781 4.93245 19.0746 4.92893 19.0711C4.92 19.0621 4.91109 19.0532 4.9022 19.0442C3.10827 17.2367 2 14.7478 2 12C2 9.25766 3.10387 6.77315 4.8915 4.96656C4.90394 4.95398 4.91642 4.94144 4.92893 4.92893C4.94144 4.91642 4.95399 4.90394 4.96656 4.8915C6.77315 3.10387 9.25767 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20V12V4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20Z"
        fill="currentColor"
      />
    </svg>
  );
}
