import { useContext, useMemo } from 'react';

import { QueueWorkerContext } from '../contexts/queueWorkerContext';

export default function useQueueWorkerInProgress() {
  const { queueMap, workerIsProgressMap } = useContext(QueueWorkerContext);

  return useMemo(() => {
    const getIsAnyTaskInsideAnyQueue = () =>
      queueMap?.current &&
      Object.values(queueMap?.current).some(
        (q) => q?.filter((item) => item.shouldInterrupt).length
      );

    const getIsAnyWorkerInProgress = () =>
      workerIsProgressMap?.current &&
      Object.values(workerIsProgressMap?.current).some(
        (item) => item?.shouldInterrupt
      );

    const getIsAnyTaskInProgress = () =>
      getIsAnyTaskInsideAnyQueue() || getIsAnyWorkerInProgress();

    return {
      getIsAnyTaskInsideAnyQueue,
      getIsAnyWorkerInProgress,
      getIsAnyTaskInProgress,
    };
  }, [queueMap, workerIsProgressMap]);
}
