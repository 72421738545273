import { createContext } from 'react';

export type IaAvailableAction<T = any, Return = any> = {
  action:
    | ((payload?: T) => void | Promise<void>)
    | ((payload?: T, ...params: any[]) => void | Promise<void>)
    | ((payload?: T) => Return | Promise<Return>);
  batchAction?: (payload?: T[]) => void | Promise<void>;
  inProgress?: ((payload?: T) => boolean) | boolean;
};

export type IaDisableAction<T = any> = {
  disabled: (payload?: T) => boolean;
};

export type IaActionContextValue = {
  availableActions: Record<string, IaAvailableAction>;
  disableActions?: Record<string, IaDisableAction>;
};

export const iaDataContextInitialValue: IaActionContextValue = {
  availableActions: {},
};

const IaActionContext = createContext<IaActionContextValue>(
  iaDataContextInitialValue
);

export default IaActionContext;
