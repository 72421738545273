import React from 'react';

export default function SocialMediaMedium({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.2853 11.6802C13.2853 14.815 10.7649 17.3605 7.64263 17.3605C4.52038 17.3605 2 14.815 2 11.6802C2 8.54545 4.52038 6 7.64263 6C10.7649 6 13.2853 8.54545 13.2853 11.6802ZM19.4671 11.6802C19.4671 14.627 18.2006 17.0219 16.6458 17.0219C15.0909 17.0219 13.8245 14.627 13.8245 11.6802C13.8245 8.73354 15.0909 6.33856 16.6458 6.33856C18.2006 6.33856 19.4671 8.721 19.4671 11.6802ZM22 11.6802C22 14.326 21.5611 16.4702 21.0094 16.4702C20.4577 16.4702 20.0188 14.326 20.0188 11.6802C20.0188 9.03448 20.4577 6.89028 21.0094 6.89028C21.5611 6.89028 22 9.03448 22 11.6802Z"
        fill="currentColor"
      />
    </svg>
  );
}
