export function reorderOptions(
  options: {
    id: string;
    key: string;
    value: string;
    selectedRatio?: number;
  }[],
  eliminatedAnswers: string[],
  disableSortedByKey?: boolean,
  disableSortedByEliminatedAnswers?: boolean
) {
  if (disableSortedByKey && disableSortedByEliminatedAnswers) {
    return options;
  }

  return [...options].sort((a, b) => {
    if (!disableSortedByEliminatedAnswers) {
      if (
        eliminatedAnswers.includes(a.id) &&
        !eliminatedAnswers.includes(b.id)
      ) {
        return 1;
      }
      if (
        !eliminatedAnswers.includes(a.id) &&
        eliminatedAnswers.includes(b.id)
      ) {
        return -1;
      }
    }

    if (!disableSortedByKey) {
      if (a.key > b.key) {
        return 1;
      }
      if (a.key < b.key) {
        return -1;
      }
    }
    return 0;
  });
}
