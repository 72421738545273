import { useRouter } from 'next/router';

import useIaClub from './useIaClub';

export default function useCurrentIaClub(clubSlug?: string) {
  const router = useRouter();
  const routerClubSlug = (router.query.clubSlug || router.query.slug) as string;

  return useIaClub(clubSlug || routerClubSlug);
}
