import React from 'react';

export default function OtherPlay({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.59092 19C6.4342 19 6.28389 18.9385 6.17308 18.8291C6.06226 18.7197 6 18.5714 6 18.4167V5.58324C6.00002 5.48187 6.02679 5.38226 6.07769 5.29422C6.12858 5.20618 6.20183 5.13275 6.29023 5.08117C6.37863 5.02959 6.47911 5.00164 6.58179 5.00007C6.68446 4.9985 6.78577 5.02338 6.87574 5.07223L18.6941 11.4889C18.7867 11.5393 18.864 11.6133 18.9178 11.7032C18.9716 11.7931 19 11.8955 19 12C19 12.1044 18.9716 12.2069 18.9178 12.2967C18.864 12.3866 18.7867 12.4606 18.6941 12.511L6.87574 18.9277C6.78849 18.9751 6.69052 19 6.59092 19Z"
        fill="currentColor"
      />
    </svg>
  );
}
