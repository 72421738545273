import { Box, BoxProps, Tooltip } from '@mui/material';

import Icon from '../Icon';

export type IndicatorProps = {
  size?: number;
  icon: string;
  name: string;
  yOffset?: number;
  sx?: BoxProps['sx'];
};

export default function Indicator({
  sx,
  name,
  icon,
  size = 24,
  yOffset = -4,
}: IndicatorProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Tooltip
      title={name}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, yOffset],
              },
            },
          ],
        },
      }}
    >
      <Box
        sx={[
          {
            display: 'inline-flex',
            alignItems: 'center',
            alignSelf: 'center',
            height: size,
            width: size,
          },
          ...sxProps,
        ]}
      >
        <Icon name={icon} width={size} height={size} />
      </Box>
    </Tooltip>
  );
}
