import { useMemo, useState } from 'react';
import { alpha, SxProps, Theme, Tooltip } from '@mui/material';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { isNil } from 'lodash';

import { getFontSize, stringAvatar } from './utils';

export enum SquareAvatarStatus {
  Online = 'online',
  Away = 'away',
  Busy = 'busy',
}

export type SquareAvatarProps = MuiAvatarProps & {
  size?: number;
  bgcolor?: string;
  textColor?: string;
  showIndicator?: boolean;
  showStacked?: boolean;
  status?: SquareAvatarStatus;
  statusInfo?: {
    [SquareAvatarStatus.Online]?: string;
    [SquareAvatarStatus.Away]?: string;
    [SquareAvatarStatus.Busy]?: string;
  };
  blackAndWhite?: boolean;
  maskUrl?: string;
  inlineComponent?: boolean;
};

const styles = {
  blackAndWhite: {
    bgcolor: 'text.primary',
    color: 'background.darker',
  },
  container: {
    position: 'relative',
    backgroundColor: 'transparent !important',
  },
  stackedAvatar: {
    position: 'absolute',
    zIndex: -1,
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.25),
  },
  badgeDot: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
  },
};

export default function SquareAvatar({
  sx,
  children,
  bgcolor,
  textColor,
  src,
  maskUrl = '/mask-avatar.svg',
  showIndicator = false,
  showStacked = false,
  status,
  statusInfo,
  blackAndWhite = false,
  inlineComponent = false,
  size = 40,
  slotProps = {},
  ...rest
}: SquareAvatarProps) {
  const [error, setError] = useState(false);

  const sxProps = Array.isArray(sx) ? sx : [sx];
  const fontSize = getFontSize(size);
  const theme = useTheme();
  const avatarProps =
    typeof children === 'string' && (!src || error)
      ? stringAvatar(
          children,
          blackAndWhite ? theme.palette.text.primary : bgcolor,
          blackAndWhite ? theme.palette.background.darker : textColor
        )
      : blackAndWhite
      ? { sx: styles.blackAndWhite, children }
      : null;

  const avatarBaseSxProp = {
    width: size,
    height: size,
    fontSize,
    borderRadius: 0,
    mask: `url(${maskUrl}) center center / contain no-repeat`,
  };

  const avatarStyles = [
    avatarBaseSxProp,
    avatarProps ? avatarProps.sx : { bgcolor, color: textColor },
    ...sxProps,
  ];

  const stackedGap = size / 16;
  const component = inlineComponent ? 'span' : 'div';
  const avatarRender = (
    <Box sx={styles.container} component={component}>
      <MuiAvatar
        sx={avatarStyles}
        component={component}
        src={src}
        {...rest}
        slotProps={{
          img: {
            referrerPolicy: 'no-referrer',
            onError: () => setError(true),
            onLoad: () => setError(false),
            ...(slotProps?.img || {}),
          },
        }}
      >
        {avatarProps ? avatarProps.children : children}
      </MuiAvatar>
      {showStacked && (
        <>
          <MuiAvatar
            sx={[
              avatarBaseSxProp,
              styles.stackedAvatar,
              {
                top: stackedGap,
              },
            ]}
            component={component}
          />
          <MuiAvatar
            sx={[
              avatarBaseSxProp,
              styles.stackedAvatar,
              {
                top: stackedGap * 2,
              },
            ]}
            component={component}
          />
        </>
      )}
    </Box>
  );

  const badgeSizeInfo = useMemo(() => {
    if (size >= 80) {
      return { badgeSize: 32, dotSize: 24, dotBorderSize: 5 };
    }
    if (size >= 64) {
      return { badgeSize: 24, dotSize: 18, dotBorderSize: 4 };
    }
    if (size >= 32) {
      return { badgeSize: 12, dotSize: 8, dotBorderSize: 2 };
    }

    return { badgeSize: 8, dotSize: 5, dotBorderSize: 1 };
  }, [size]);

  const dotBorderColor =
    status === 'online' ? '#01F89B' : status === 'busy' ? '#E62937' : '#B2B2B2';
  const dotBackgroundColor =
    status === 'online' ? '#01F89B' : status === 'busy' ? '#E62937' : '#080A0E';

  const badgeDotStyle: SxProps = {
    width: badgeSizeInfo.dotSize,
    height: badgeSizeInfo.dotSize,
    backgroundColor: dotBackgroundColor,
    border: `${badgeSizeInfo.dotBorderSize}px solid ${dotBorderColor}`,
  };

  const statusTitle = useMemo(() => {
    if (statusInfo && status && !isNil(statusInfo[status])) {
      return statusInfo[status];
    }

    return status === SquareAvatarStatus.Online
      ? 'Online'
      : status === SquareAvatarStatus.Busy
      ? 'Busy'
      : 'Away';
  }, [status, statusInfo]);

  if (showIndicator) {
    return (
      <Badge
        overlap="circular"
        sx={{
          '& .MuiBadge-badge': {
            padding: 0,
            width: badgeSizeInfo.badgeSize,
            minWidth: 'unset',
            height: badgeSizeInfo.badgeSize,
            borderRadius: '50%',
            backgroundColor: '#080A0E',
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Tooltip placement="bottom-start" title={statusTitle}>
            <Box sx={[styles.badgeDot, badgeDotStyle]} />
          </Tooltip>
        }
      >
        {avatarRender}
      </Badge>
    );
  }
  return avatarRender;
}
