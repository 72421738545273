import React from 'react';

export default function TestExamPracticed({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_2507_261857)">
        <path
          d="M23 12C23 3.34818 20.8735 1.07596 12.4389 1.00189C12.1991 0.999787 12 1.1944 12 1.43421C12 1.67402 12.1987 1.86818 12.4385 1.87048C20.1787 1.94454 22.1316 4.04292 22.1316 12C22.1316 20.1053 20.1053 22.1316 12 22.1316C4.04292 22.1316 1.94454 20.1787 1.87048 12.4385C1.86818 12.1987 1.67402 12 1.43421 12C1.1944 12 0.999787 12.1992 1.00189 12.4389C1.07596 20.8735 3.34818 23 12 23C20.8 23 23 20.8 23 12Z"
          fill="url(#paint0_linear_2507_261857)"
        />
        <g>
          <path
            d="M12 3C4.8 3 3 4.8 3 12C3 19.2 4.8 21 12 21C19.2 21 21 19.2 21 12C21 4.8 19.2 3 12 3Z"
            fill="#1B1B1B"
            fillRule="evenodd"
          />
          <path
            d="M4.09289 4.09289C5.7609 2.42489 8.40715 2 12 2C15.5929 2 18.2391 2.42489 19.9071 4.09289C21.5751 5.7609 22 8.40715 22 12C22 15.5929 21.5751 18.2391 19.9071 19.9071C18.2391 21.5751 15.5929 22 12 22C8.40715 22 5.7609 21.5751 4.09289 19.9071C2.42489 18.2391 2 15.5929 2 12C2 8.40715 2.42489 5.7609 4.09289 4.09289ZM3 12C3 4.8 4.8 3 12 3C19.2 3 21 4.8 21 12C21 19.2 19.2 21 12 21C4.8 21 3 19.2 3 12Z"
            fill="#242424"
            fillRule="evenodd"
          />
          <path
            d="M8.30367 13.2517C8.42419 13.2517 8.5228 13.2426 8.5995 13.2243C8.67985 13.2024 8.74376 13.175 8.79124 13.1421C8.83871 13.1056 8.87158 13.0636 8.88985 13.0161C8.90811 12.9687 8.91724 12.9157 8.91724 12.8573C8.91724 12.7331 8.8588 12.6308 8.74193 12.5505C8.62506 12.4665 8.42419 12.377 8.13932 12.2821C8.01515 12.2382 7.89098 12.1889 7.7668 12.1341C7.64263 12.0757 7.53124 12.0045 7.43263 11.9205C7.33402 11.8328 7.25367 11.7287 7.19158 11.6082C7.1295 11.4841 7.09845 11.3343 7.09845 11.159C7.09845 10.9837 7.13132 10.8267 7.19706 10.6879C7.2628 10.5454 7.35593 10.4249 7.47645 10.3263C7.59698 10.2277 7.74306 10.1528 7.91471 10.1017C8.08637 10.0469 8.27993 10.0195 8.49541 10.0195C8.75106 10.0195 8.97202 10.0469 9.15828 10.1017C9.34454 10.1565 9.49793 10.2167 9.61845 10.2825L9.37193 10.9563C9.26602 10.9015 9.14732 10.8541 9.01585 10.8139C8.88802 10.7701 8.7328 10.7481 8.55019 10.7481C8.34567 10.7481 8.19776 10.7774 8.10645 10.8358C8.0188 10.8906 7.97498 10.9764 7.97498 11.0933C7.97498 11.1627 7.99141 11.2211 8.02428 11.2686C8.05715 11.3161 8.1028 11.3599 8.16124 11.4001C8.22332 11.4366 8.29271 11.4713 8.36941 11.5041C8.44976 11.5334 8.53741 11.5644 8.63237 11.5973C8.82958 11.6703 9.00124 11.7434 9.14732 11.8164C9.29341 11.8858 9.41393 11.968 9.50889 12.0629C9.6075 12.1579 9.68054 12.2693 9.72802 12.3971C9.7755 12.5249 9.79924 12.6801 9.79924 12.8627C9.79924 13.217 9.67506 13.4927 9.42672 13.69C9.17837 13.8835 8.80402 13.9803 8.30367 13.9803C8.13567 13.9803 7.98411 13.9694 7.84898 13.9474C7.71385 13.9292 7.59332 13.9054 7.48741 13.8762C7.38515 13.847 7.29567 13.816 7.21898 13.7831C7.14593 13.7502 7.08385 13.7192 7.03271 13.69L7.27376 13.0107C7.38698 13.0727 7.52576 13.1294 7.69011 13.1805C7.85811 13.228 8.06263 13.2517 8.30367 13.2517Z"
            fill="currentColor"
          />
          <path
            d="M13.0965 13.9036C13.0564 13.7721 13.0107 13.637 12.9596 13.4982C12.9121 13.3594 12.8646 13.2207 12.8171 13.0819H11.338C11.2905 13.2207 11.2412 13.3594 11.1901 13.4982C11.1426 13.637 11.0988 13.7721 11.0586 13.9036H10.1711C10.3136 13.4946 10.4487 13.1166 10.5765 12.7696C10.7044 12.4227 10.8285 12.0958 10.9491 11.789C11.0732 11.4822 11.1938 11.1919 11.3106 10.918C11.4311 10.6404 11.5553 10.3701 11.6831 10.1072H12.4994C12.6236 10.3701 12.7459 10.6404 12.8665 10.918C12.987 11.1919 13.1075 11.4822 13.228 11.789C13.3522 12.0958 13.4782 12.4227 13.606 12.7696C13.7338 13.1166 13.869 13.4946 14.0114 13.9036H13.0965ZM12.0721 10.9673C12.0538 11.0221 12.0265 11.0969 11.9899 11.1919C11.9534 11.2868 11.9114 11.3964 11.8639 11.5206C11.8165 11.6447 11.7635 11.7817 11.7051 11.9314C11.6503 12.0812 11.5937 12.2382 11.5352 12.4026H12.6145C12.556 12.2382 12.4994 12.0812 12.4446 11.9314C12.3898 11.7817 12.3369 11.6447 12.2858 11.5206C12.2383 11.3964 12.1963 11.2868 12.1598 11.1919C12.1232 11.0969 12.094 11.0221 12.0721 10.9673Z"
            fill="currentColor"
          />
          <path
            d="M16.9671 10.1072V10.8358H15.8221V13.9036H14.9675V10.8358H13.8226V10.1072H16.9671Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2507_261857"
          gradientUnits="userSpaceOnUse"
          x1="23"
          x2="1"
          y1="11.8358"
          y2="11.8403"
        >
          <stop stopColor="#00FFFF" />
          <stop offset="1" stopColor="#00A4FF" />
        </linearGradient>
        <clipPath id="clip0_2507_261857">
          <rect height="24" width="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
