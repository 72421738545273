import React from 'react';

export default function FlagIndia({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_301_31642)">
        <g clipPath="url(#clip1_301_31642)">
          <path
            d="M-0.387695 2.80396H24.3882V8.68464H-0.387695V2.80396Z"
            fill="#FF9933"
          />
          <path
            d="M-0.387695 8.68457H24.3882V14.5653H-0.387695V8.68457Z"
            fill="white"
          />
          <path
            d="M-0.387695 14.5654H24.3882V20.4461H-0.387695V14.5654Z"
            fill="#128807"
          />
          <path
            d="M12 13.9773C13.3684 13.9773 14.4776 12.9241 14.4776 11.625C14.4776 10.3259 13.3684 9.27271 12 9.27271C10.6317 9.27271 9.52246 10.3259 9.52246 11.625C9.52246 12.9241 10.6317 13.9773 12 13.9773Z"
            fill="#000088"
          />
          <path
            d="M11.9999 13.6831C13.1972 13.6831 14.1678 12.7616 14.1678 11.6249C14.1678 10.4882 13.1972 9.56665 11.9999 9.56665C10.8026 9.56665 9.83203 10.4882 9.83203 11.6249C9.83203 12.7616 10.8026 13.6831 11.9999 13.6831Z"
            fill="white"
          />
          <path
            d="M12 12.0367C12.2394 12.0367 12.4336 11.8524 12.4336 11.625C12.4336 11.3977 12.2394 11.2134 12 11.2134C11.7605 11.2134 11.5664 11.3977 11.5664 11.625C11.5664 11.8524 11.7605 12.0367 12 12.0367Z"
            fill="#000088"
          />
          <path
            d="M14.1347 11.9993C14.1957 12.007 14.2517 11.9662 14.2597 11.9082C14.2678 11.8503 14.2248 11.7971 14.1638 11.7895C14.1027 11.7818 14.0467 11.8226 14.0387 11.8806C14.0306 11.9385 14.0736 11.9917 14.1347 11.9993Z"
            fill="#000088"
          />
          <path
            d="M12.0001 13.6831L12.0744 12.4482L12.0001 11.8601L11.9258 12.4482L12.0001 13.6831Z"
            fill="#000088"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_301_31642">
          <rect height="24" width="24" fill="white" rx="4" />
        </clipPath>
        <clipPath id="clip1_301_31642">
          <rect
            height="17.642"
            width="24.7759"
            fill="white"
            rx="4"
            x="-0.387695"
            y="2.80396"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
