import React from 'react';

export default function LoginSee({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 9.2C11.2767 9.2 10.583 9.495 10.0715 10.0201C9.56006 10.5452 9.27273 11.2574 9.27273 12C9.27273 12.7426 9.56006 13.4548 10.0715 13.9799C10.583 14.505 11.2767 14.8 12 14.8C12.7233 14.8 13.417 14.505 13.9285 13.9799C14.4399 13.4548 14.7273 12.7426 14.7273 12C14.7273 11.2574 14.4399 10.5452 13.9285 10.0201C13.417 9.495 12.7233 9.2 12 9.2ZM12 16.6667C10.7945 16.6667 9.63832 16.175 8.78588 15.2998C7.93344 14.4247 7.45455 13.2377 7.45455 12C7.45455 10.7623 7.93344 9.57534 8.78588 8.70017C9.63832 7.825 10.7945 7.33333 12 7.33333C13.2055 7.33333 14.3617 7.825 15.2141 8.70017C16.0666 9.57534 16.5455 10.7623 16.5455 12C16.5455 13.2377 16.0666 14.4247 15.2141 15.2998C14.3617 16.175 13.2055 16.6667 12 16.6667ZM12 5C7.45455 5 3.57273 7.90267 2 12C3.57273 16.0973 7.45455 19 12 19C16.5455 19 20.4273 16.0973 22 12C20.4273 7.90267 16.5455 5 12 5Z"
        fill="currentColor"
      />
    </svg>
  );
}
