import { createSelector } from '@reduxjs/toolkit';

import { SettingState } from './types';

const selectHighlightOptions = (state: SettingState) => state.highlight.options;

const selectOptionIndexById = createSelector(
  selectHighlightOptions,
  (options) => (id: string) =>
    id ? options.findIndex((option) => option.id === id) : -1
);

const selectAnswerFormatChanging = (state: SettingState) =>
  state.answerFormatChanging;

const selectReloadSetting = (state: SettingState) => state.reloadSetting;

export {
  selectAnswerFormatChanging,
  selectOptionIndexById,
  selectReloadSetting,
};
