import React from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Theme, Typography } from '@mui/material';
import { Icon } from '@front/ui';
import { TFunction } from 'i18next';

import PaidPlanLabelTooltip from './PaidPlanLabelTooltip';

const styles = {
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    typography: 'body2',
    gap: 0.5,
    px: 0.5,
    py: '1.5px',

    '& svg': {
      width: 16,
      height: 16,
    },

    '&:hover': {
      borderRadius: '4px',
      backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
};

const planConfigMap = (t: TFunction) => ({
  premium: {
    icon: 'BadgeBasicPlan',
    title: t('Premium'),
  },
  pro: {
    icon: 'BadgeProPlan',
    title: t('Pro'),
  },
  unlimited: {
    icon: 'BadgeUnlimitedPlan',
    title: t('Unlimited'),
  },
});

export type PaidPlanLabelProps = {
  plan: 'premium' | 'pro' | 'unlimited';
};

export default function PaidPlanLabel({ plan }: PaidPlanLabelProps) {
  const { t } = useTranslation('profile');
  const config = planConfigMap(t)[plan];

  if (!config) return null;

  return (
    <PaidPlanLabelTooltip plan={plan}>
      <Typography component="span" sx={styles.root}>
        <Icon name={config.icon} />
        {config.title}
      </Typography>
    </PaidPlanLabelTooltip>
  );
}
