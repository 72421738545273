import { useCallback, useEffect, useRef, useState } from 'react';
import { SquareAvatarStatus } from '@front/ui';
import { generateHook, UserOnlineStatusGet } from '@lib/web/apis';
import { useContextSelector } from 'use-context-selector';

import { UsersOnlineStatusContext } from '../contexts/usersOnlineStatusContext';

export const getUserStatus = (
  status?: UserOnlineStatusGet
): SquareAvatarStatus | undefined => {
  switch (status) {
    case UserOnlineStatusGet.ONLINE:
      return SquareAvatarStatus.Online;
    case UserOnlineStatusGet.AWAY:
      return SquareAvatarStatus.Away;
    case UserOnlineStatusGet.BUSY:
      return SquareAvatarStatus.Busy;
    default:
      return undefined;
  }
};

export const getAgentStatus = (
  status?: GetAgentStatusRes['status']['indicator']
): SquareAvatarStatus => {
  if (status === 'none' || status === 'minor') {
    return SquareAvatarStatus.Online;
  }
  return SquareAvatarStatus.Away;
};

const USER_POLLING_INTERVAL_MS = 60 * 1000; // polling interval in milliseconds (1 minute)
const AGENT_POLLING_INTERVAL_MS = 5 * 60 * 1000; // polling interval in milliseconds (5 minutes)

export const useUserOnlineStatus = () => {
  const [trackedUserIds, setTrackedUserIds] = useState(new Set<string>()); // set to track user IDs that need their online status fetched
  const usersOnlineStatusRef = useRef<Record<string, UserOnlineStatusGet>>({}); // cached users status

  const { data } = generateHook<GetIaUserApiInternalIaUserOnlineStatusRes>(
    trackedUserIds.size ? ['v2/ia/users', trackedUserIds.size] : undefined,
    {
      method: 'POST',
      data: {
        search: `id:${Array.from(trackedUserIds).join(',')}`,
        searchFields: 'id:in',
        viewSlug: 'api_internal_ia_user_online_status',
        limit: 0,
      },
      paginate: true,
      auth: true,
      refreshInterval: USER_POLLING_INTERVAL_MS,
    }
  );

  // update the cached online status map when new data is received
  useEffect(() => {
    if (data?.data.items) {
      usersOnlineStatusRef.current = data?.data.items.reduce(
        (prev, acc) => ({ ...prev, [acc.id]: acc.onlineStatus }),
        {}
      );
    }
  }, [data?.data.items]);

  const getUserOnlineStatus = useCallback(
    (userId?: string): UserOnlineStatusGet | undefined => {
      if (userId) {
        if (!trackedUserIds.has(userId)) {
          // Use setTimeout to delay state updates in UsersOnlineStatusProvider,
          // preventing "Cannot update a component while rendering a different component" warning.
          // This ensures the state update occurs after the current render cycle is completed.
          setTimeout(() => {
            setTrackedUserIds((prev) => new Set(prev.add(userId)));
          });
        }

        // return the cached status if available first
        return (
          usersOnlineStatusRef.current[userId] ||
          data?.data.items.find((item) => item.id === userId)?.onlineStatus
        );
      }
    },
    [data?.data.items, trackedUserIds]
  );

  return { getUserOnlineStatus };
};

export const useAgentOnlineStatus = () => {
  const { data } = generateHook<GetAgentStatusRes>('/api/thread/status/agent', {
    baseURL: '',
    method: 'GET',
    auth: true,
    refreshInterval: AGENT_POLLING_INTERVAL_MS,
  });

  const getAgentOnlineStatus = useCallback(():
    | GetAgentStatusRes['status']['indicator']
    | undefined => {
    return data?.data.status.indicator;
  }, [data?.data.status.indicator]);

  return { getAgentOnlineStatus };
};

export const useOnlineStatus = () => {
  const { getUserOnlineStatus, getAgentOnlineStatus } = useContextSelector(
    UsersOnlineStatusContext,
    (v) => v
  );

  return { getUserOnlineStatus, getAgentOnlineStatus };
};
