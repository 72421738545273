import React, { MouseEvent, MutableRefObject } from 'react';
import {
  alpha,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
} from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { Block, BlockNoteEditor, BlockSchema } from '@blocknote/core';
import { Icon, ResponsiveMenu } from '@front/ui';
import { useFileBlockMoreActionItems } from '@lib/web/composer/TextComposer/components/SideMenu/hooks/useFileBlockMoreActionItems';

import ThemeProvider from '../../../../components/ThemeProvider';
import { useGeneralBlockMoreActionItems } from '../hooks/useGeneralBlockMoreActionItems';

import BlockMoreActionKeyboardShortcut from './BlockMoreActionKeyboardShortcut';

const styles = {
  menu: {
    '& .MuiMenuItem-root': {
      height: 36,
      minWidth: 199,
    },
  },
  icon: {
    '& svg': {
      height: 16,
      width: 16,
    },
  },
  hint: {
    span: {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
      fontFamily: 'Inconsolata',
      textAlign: 'right',
    },
  },
};

export type BlockMoreActionDropdownItem = {
  label: string;
  hint?: string;
  icon: string;
  onClick: () => void;
  sx?: SxProps;
};

export type BlockMoreActionDropdownProps<BSchema extends BlockSchema> = {
  editor: BlockNoteEditor<BSchema>;
  block: Block<BSchema>;
  target: MutableRefObject<HTMLElement | null>;
  open: boolean;
  onClose?: () => void;
  onItemClick?: () => void;
};

export default function BlockMoreActionDropdown<BSchema extends BlockSchema>({
  editor,
  block,
  target,
  open,
  onClose,
  onItemClick,
}: BlockMoreActionDropdownProps<BSchema>) {
  const generalBlockMenuItems = useGeneralBlockMoreActionItems({
    editor,
    block,
  });
  const fileBlockMenuItems = useFileBlockMoreActionItems({ editor, block });
  const isFileBlock = block.type === 'file';

  const handleMenuClose = () => {
    onClose?.();
  };

  const handleMenuItemClick = (onClick: () => void) => {
    return (ev: MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      onItemClick?.();
      onClick();
    };
  };

  return (
    <>
      <ThemeProvider mode="dark">
        <ResponsiveMenu
          open={open}
          onClose={handleMenuClose}
          menuProps={{
            anchorEl: target.current,
            anchorOrigin: {
              vertical: 'center',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'center',
              horizontal: 0,
            },
            sx: styles.menu,
          }}
        >
          {(isFileBlock ? fileBlockMenuItems : generalBlockMenuItems)?.map(
            ({ label, hint, icon, onClick, sx }) => (
              <MenuItem
                key={label}
                value={label}
                onClick={handleMenuItemClick(onClick)}
                sx={sx}
              >
                <ListItemIcon sx={styles.icon}>
                  <Icon name={icon} />
                </ListItemIcon>
                <ListItemText>{label}</ListItemText>
                {hint && <ListItemText sx={styles.hint}>{hint}</ListItemText>}
              </MenuItem>
            )
          )}
        </ResponsiveMenu>
      </ThemeProvider>
      {open && (
        <BlockMoreActionKeyboardShortcut
          block={block}
          editor={editor}
          onItemClick={onItemClick}
        />
      )}
    </>
  );
}
