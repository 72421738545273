import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * There are some cases where multiple init processes occur simultaneously in different places.
 * For example:
 * - init default sort on page load
 * - init selected filter chip on quick filter chips
 * - init default search by user local in somewhere else
 * => The value can be:
 * isInitializing: {
 *  defaultSortInitializing: true,
 *  filterChipInitializing: true,
 *  userLocaleInitializing: true,
 * }
 */
type MultiInitialization = Record<string, boolean>;

const initialState: {
  isInitializing: Record<string, boolean | MultiInitialization>;
} = {
  isInitializing: { global: true },
};
type Scope = { scope: string };

const iaQueryHelperSlice = createSlice({
  name: 'iaQueryHelper',
  initialState,
  reducers: {
    setInitializing: (
      state,
      {
        payload: { isInitializing, scope },
      }: PayloadAction<
        { isInitializing: boolean | Record<string, boolean> } & Scope
      >
    ) => {
      if (typeof isInitializing === 'boolean') {
        state.isInitializing[scope] = isInitializing;
      } else {
        let currentValue = {};
        if (typeof state.isInitializing[scope] !== 'boolean') {
          currentValue = state.isInitializing[scope];
        }
        state.isInitializing[scope] = {
          ...currentValue,
          ...isInitializing,
        };
      }
    },
  },
});

export const { setInitializing } = iaQueryHelperSlice.actions;
export default iaQueryHelperSlice.reducer;
