import generateHook from './swr.helper';

export const useEmojiDetail = (param: GetEmojiDetailParam) => {
  const urlParam = new URLSearchParams();
  if (param.sectionId) urlParam.set('sectionId', param.sectionId);
  if (param.markId) urlParam.set('markId', param.markId);

  return generateHook<GetEmojiDetailRes>(
    `v2/mark/emoji/statistics/me?${urlParam.toString()}`
  );
};
