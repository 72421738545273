import React from 'react';

export default function AIEditorFile({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#D172F3"
        fillRule="evenodd"
      />
      <path
        d="M12.9761 5.67364C14.1448 4.44212 16.044 4.44212 17.2128 5.67364C18.3746 6.89776 18.3746 8.87572 17.2128 10.0998L12.1281 15.4575C11.3612 16.2655 10.1109 16.2655 9.34409 15.4575C8.58425 14.6569 8.58425 13.3626 9.34409 12.562L13.5624 8.11732C13.8687 7.79455 14.3831 7.79454 14.6894 8.11732C14.9739 8.41709 14.9739 8.88714 14.6894 9.18692L10.4332 13.6716C10.2584 13.8558 10.2584 14.1637 10.4332 14.3479C10.6009 14.5247 10.8712 14.5247 11.039 14.3479L16.1237 8.99027C16.7005 8.38259 16.7005 7.39089 16.1237 6.78321C15.554 6.18294 14.6348 6.18294 14.0651 6.78321L8.01184 13.1613C7.03318 14.1925 7.03318 15.868 8.01184 16.8992C8.98348 17.9229 10.5516 17.9229 11.5233 16.8992L16.7101 11.4339C17.0164 11.1112 17.5308 11.1112 17.8371 11.4339C18.1216 11.7337 18.1216 12.2038 17.8371 12.5035L12.6123 18.0087C11.0416 19.6638 8.49352 19.6638 6.92278 18.0087C5.35907 16.3611 5.35907 13.6994 6.92278 12.0518L12.9761 5.67364Z"
        fill="#080808"
        fillRule="evenodd"
      />
    </svg>
  );
}
