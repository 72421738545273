import React from 'react';

export default function MainPractice({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.5086 6.51972C18.8526 5.82755 17.7882 5.82665 17.1311 6.5177L9.29376 14.7608L6.86972 12.2075C6.21317 11.516 5.14875 11.5161 4.49229 12.2077C3.83583 12.8994 3.83591 14.0207 4.49247 14.7123L7.85928 18.2586C7.95708 18.3616 8.06393 18.4492 8.17713 18.5216C8.83571 19.1722 9.87043 19.1593 10.5141 18.4823L19.5067 9.02426C20.1637 8.33321 20.1646 7.21189 19.5086 6.51972Z"
        fill="currentColor"
      />
    </svg>
  );
}
