import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Fade } from '@mui/material';
import Box from '@mui/material/Box';
import {
  BlockNoteEditor,
  BlockSchema,
  DefaultBlockSchema,
} from '@blocknote/core';
import { DefaultFormattingToolbar } from '@blocknote/react';

type FormattingToolbarProps<BSchema extends BlockSchema = DefaultBlockSchema> =
  {
    editor: BlockNoteEditor<BSchema>;
    formattingToolbar?: FC<FormattingToolbarProps<BSchema>>;
    onConvertBlockType?: (type: string) => void;
  };

/**
 * Because we use position: fixed for toolbar, when user scroll, we still want the toolbar at the same position,
 * so we need to dynamically calculate toolbar position
 */
const useToolbarPosition = ({
  editorEl,
  toolbarShow,
  selectionPosition,
  toolbarRef,
}: {
  editorEl: HTMLDivElement;
  toolbarShow: boolean;
  selectionPosition?: DOMRect;
  toolbarRef: React.RefObject<HTMLDivElement>;
}) => {
  const TOP_PADDING = 40;

  const [toolbarPosition, setToolbarPosition] = useState({ left: 0, top: 0 });

  // when toolbar showed, store its scroll position
  const initialToolbarPosition = useMemo(() => {
    const scrollEl = editorEl.closest('.simplebar-content-wrapper');

    return toolbarShow && scrollEl
      ? {
          top: scrollEl.scrollTop,
          left: scrollEl.scrollLeft,
        }
      : null;
  }, [editorEl, toolbarShow]);

  // handle selectionPosition changed
  useEffect(() => {
    const scrollEl = editorEl.closest('.simplebar-content-wrapper');

    if (!selectionPosition || !scrollEl) return;

    // we use position fixed for toolbar, but it is not always based on viewport, in rhs, the translate(0%) make position:fixed based on base-right-panel-container
    const containerElPosition = editorEl
      .closest('.base-right-panel-container')
      ?.getBoundingClientRect() || { top: 0, left: 0 };

    // make it always within the scrollable area
    const maximumLeft =
      scrollEl.getBoundingClientRect().right -
      (toolbarRef.current?.getBoundingClientRect().width || 0);

    setToolbarPosition({
      top: selectionPosition.top - TOP_PADDING - containerElPosition.top,
      left: Math.min(
        maximumLeft,
        selectionPosition.left - containerElPosition.left
      ),
    });
  }, [editorEl, selectionPosition, toolbarRef]);

  // handle scroll when the toolbar showed
  useEffect(() => {
    const scrollEl = editorEl.closest('.simplebar-content-wrapper');

    if (!scrollEl || !initialToolbarPosition || !selectionPosition) return;

    // we use position fixed for toolbar, but it is not always based on viewport, in rhs, the translate(0%) make position:fixed based on base-right-panel-container
    const containerElPosition = editorEl
      .closest('.base-right-panel-container')
      ?.getBoundingClientRect() || { top: 0, left: 0 };

    // make it always within the scrollable area
    const maximumLeft =
      scrollEl.getBoundingClientRect().right -
      (toolbarRef.current?.getBoundingClientRect().width || 0);

    const handleScroll = () => {
      setToolbarPosition({
        top:
          selectionPosition.top -
          TOP_PADDING -
          scrollEl.scrollTop +
          initialToolbarPosition.top -
          containerElPosition.top,
        left: Math.min(
          maximumLeft,
          selectionPosition.left -
            scrollEl.scrollLeft +
            initialToolbarPosition.left -
            containerElPosition.left
        ),
      });
    };
    scrollEl?.addEventListener('scroll', handleScroll);
    return () => scrollEl?.removeEventListener('scroll', handleScroll);
  }, [editorEl, initialToolbarPosition, selectionPosition, toolbarRef]);

  return toolbarPosition;
};

export const FormattingToolbarPositioner = <
  BSchema extends BlockSchema = DefaultBlockSchema
>(
  props: FormattingToolbarProps<BSchema>
) => {
  const [show, setShow] = useState<boolean>(false);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [selectionPosition, setSelectionPosition] = useState<DOMRect>();
  const toolbarPosition = useToolbarPosition({
    editorEl: props.editor.domElement,
    toolbarShow: show,
    selectionPosition,
    toolbarRef,
  });

  useEffect(() => {
    return props.editor.formattingToolbar.onUpdate((state) => {
      setShow(state.show);
      setSelectionPosition(state.referencePos);
      if (!state.show) {
        // for clear tiptap selection (or will trigger range errors when hovering over inline block)
        props.editor._tiptapEditor.commands.setTextSelection(
          props.editor._tiptapEditor.view.state.selection.to
        );
      }
    });
  }, [props.editor]);

  const formattingToolbarElement = useMemo(() => {
    const FormattingToolbar =
      props.formattingToolbar || DefaultFormattingToolbar;

    return (
      <FormattingToolbar
        editor={props.editor}
        onConvertBlockType={props.onConvertBlockType}
      />
    );
  }, [props.editor, props.formattingToolbar, props.onConvertBlockType]);

  if (!show) return;

  return (
    <Fade in>
      <Box
        sx={{
          position: 'fixed', // we cannot use absolute because parent overflow: hidden
          top: toolbarPosition.top,
          left: toolbarPosition.left,
        }}
        ref={toolbarRef}
      >
        <div>{formattingToolbarElement}</div>
      </Box>
    </Fade>
  );
};

export default FormattingToolbarPositioner;
