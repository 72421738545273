import React from 'react';

export default function EditorRename({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.0049 5.99512H19.0049V7.99512H20.0049V15.9951H19.0049V17.9951H20.0049C21.1079 17.9951 22.0049 17.0981 22.0049 15.9951V7.99512C22.0049 6.89312 21.1069 5.99512 20.0049 5.99512Z"
        fill="currentColor"
      />
      <path
        d="M17.0049 4H18.9999C19.5522 4 19.9999 3.55228 19.9999 3C19.9999 2.44772 19.5522 2 18.9999 2H12.9999C12.4476 2 11.9999 2.44772 11.9999 3C11.9999 3.55228 12.4476 4 12.9999 4H15.0049V5.995H4.00488C2.90188 5.995 2.00488 6.892 2.00488 7.995V15.995C2.00488 17.098 2.90188 17.995 4.00488 17.995H15.0049V20H12.9999C12.4476 20 11.9999 20.4477 11.9999 21C11.9999 21.5523 12.4476 22 12.9999 22H18.9999C19.5522 22 19.9999 21.5523 19.9999 21C19.9999 20.4477 19.5522 20 18.9999 20H17.0049V4ZM4.00488 7.995V15.995H15.0049V14V7.995H4.00488Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
