import {
  useCreatorQuestionCorrectAnswerData,
  useCreatorQuestionCorrectAnswerHandle,
} from '@lib/web/editor';

export const useEventHandle = (questionId: string) => {
  const { correctAnswerIdSet } =
    useCreatorQuestionCorrectAnswerData(questionId);

  const { handleCorrectAnswerChange } =
    useCreatorQuestionCorrectAnswerHandle(questionId);

  const handleSelect = async (id: string) => {
    if (!questionId) {
      console.warn('questionId is undefined when select response');
      return;
    }

    const correctAnswers = correctAnswerIdSet.has(id) ? [] : [id];

    void handleCorrectAnswerChange({
      params: {
        id: questionId,
        correctAnswers:
          correctAnswers.length > 0
            ? correctAnswers.map((item) => ({
                componentId: item,
              }))
            : null,
      },
      optimisticData: correctAnswers.map((item) => ({
        id: '',
        componentId: item,
        value: null,
        order: 0,
      })),
    });
  };

  return {
    handleSelect,
  };
};
