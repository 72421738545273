import { MouseEvent } from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import { Sortable, SortableOnDragOverOtherItem } from '@front/helper';
import { TestAdd as TestAddIcon } from '@front/icon';

import PromptSortableItem from './PromptSortableItem';

const styles = {
  root: {
    display: 'grid',
    gap: 2,
  },
  addButton: {
    height: 56,
    width: '100%',
    gap: 1,
    borderRadius: '12px',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(255,255,255,1)' stroke-width='2' stroke-dasharray='4%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
};
export type PromptGroupItem = {
  id: string;
  value: string;
  title?: string;
  disabled?: boolean;
};
export type PromptGroupProps = {
  items: PromptGroupItem[];
  placeholder?: string;

  onChange: (value: PromptGroupItem) => void;
  onAddItem: (ev: MouseEvent<HTMLButtonElement>, index?: number) => void;
  onDeleteItem: (item: PromptGroupItem) => void;
  onReorder: SortableOnDragOverOtherItem<PromptGroupItem>;
};

export default function PromptGroup({
  items,
  placeholder,
  onChange,
  onAddItem,
  onDeleteItem,
  onReorder,
}: PromptGroupProps) {
  const handleItemChange = (target: PromptGroupItem, newValue: string) => {
    onChange?.({ ...target, value: newValue });
  };

  return (
    <Box sx={styles.root}>
      <Sortable<PromptGroupItem> items={items} onDragOverOtherItem={onReorder}>
        <>
          {items.map((item, index) => (
            <PromptSortableItem
              key={item.id}
              id={item.id}
              step={index + 1}
              value={item.value}
              onAddItem={(ev) => onAddItem(ev, index)}
              onChange={(newValue) => handleItemChange(item, newValue)}
              onDelete={() => onDeleteItem(item)}
              placeholder={placeholder}
              disabled={item.disabled}
            />
          ))}
        </>
      </Sortable>
      <ButtonBase sx={styles.addButton} onClick={onAddItem}>
        <TestAddIcon width={16} height={16} />
        <Typography>Add Prompt</Typography>
      </ButtonBase>
    </Box>
  );
}
