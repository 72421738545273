import { RefObject, useRef } from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import {
  ActionResize as ActionResizeIcon,
  OtherDrag as OtherDragIcon,
} from '@front/icon';
import { useDrag } from '@use-gesture/react';

import config from '../config';
import useBaseLayout from '../hooks/useBaseLayout';

const styles = {
  resizer: {
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '8px',
    cursor: 'col-resize',
    left: '-4px',
    zIndex: 1000,
    userSelect: 'none',
    touchAction: 'none',
  },
  resizerButton: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translate(calc(-50% + 4px), -50%)',
    cursor: 'col-resize',
    borderRadius: 1,
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'none',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    svg: {
      pointerEvents: 'none',
    },
  },
};

export type BaseLayoutRightPanelResizerProps = {
  panelContainerRef: RefObject<HTMLElement | null>;
};
export default function BaseLayoutRightPanelResizer({
  panelContainerRef,
}: BaseLayoutRightPanelResizerProps) {
  const {
    setRightPanelWidth,
    setResizing,
    isResizing,
    setRightPanelMaximizeWidth,
  } = useBaseLayout();

  const currentWidthRef = useRef(config.rightPanelMinWidth);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bind: any = useDrag(({ movement: [mx], last, first }) => {
    if (first) {
      // should disable maximizeWidth to able to resize the panel
      setRightPanelMaximizeWidth(false);
      setResizing(true);
      currentWidthRef.current =
        panelContainerRef.current?.offsetWidth || currentWidthRef.current;
    }
    if (last) {
      setResizing(false);
    }
    let width = currentWidthRef.current - mx;
    if (width > config.rightPanelMaxWidth) width = config.rightPanelMaxWidth;
    else if (width < config.rightPanelMinWidth)
      width = config.rightPanelMinWidth;

    setRightPanelWidth(width);
  });

  return (
    <Box sx={styles.resizer} {...bind()}>
      <Box sx={styles.resizerButton} className="resizer-btn">
        {isResizing ? (
          <ActionResizeIcon width={16} height={16} />
        ) : (
          <OtherDragIcon width={16} height={16} />
        )}
      </Box>
    </Box>
  );
}
