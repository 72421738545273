import React from 'react';

export default function ActionChildLevel({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8 1C8.55228 1 9 1.44772 9 2V9.99996C9 11.6568 10.3431 12.9999 12 13L20 13C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15L12 15C9.23856 14.9999 7 12.7614 7 9.99996V2C7 1.44772 7.44772 1 8 1Z"
        fill="currentColor"
        fillOpacity="0.5"
        fillRule="evenodd"
      />
    </svg>
  );
}
