import React from 'react';
import { v4 } from 'uuid';

export default function Award231({ ...rest }) {
  const id = v4();
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#clip0_${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42 76C60.7777 76 76 60.7777 76 42C76 23.2223 60.7777 8 42 8C23.2223 8 8 23.2223 8 42C8 60.7777 23.2223 76 42 76ZM42 80C62.9868 80 80 62.9868 80 42C80 21.0132 62.9868 4 42 4C21.0132 4 4 21.0132 4 42C4 62.9868 21.0132 80 42 80Z"
          fill={`url(#paint0_linear_${id})`}
        />
        <g style={{ mixBlendMode: 'screen' }}>
          <mask
            id={`mask0_${id}`}
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="4"
            y="4"
            width="76"
            height="76"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M42 76C60.7777 76 76 60.7777 76 42C76 23.2223 60.7777 8 42 8C23.2223 8 8 23.2223 8 42C8 60.7777 23.2223 76 42 76ZM42 80C62.9868 80 80 62.9868 80 42C80 21.0132 62.9868 4 42 4C21.0132 4 4 21.0132 4 42C4 62.9868 21.0132 80 42 80Z"
              fill={`url(#paint1_linear_${id})`}
            />
          </mask>
          <g mask={`url(#mask0_${id})`}>
            <g filter={`url(#filter0_f_${id})`}>
              <rect
                x="62.2083"
                y="-1.53613"
                width="31.4975"
                height="90.1483"
                transform="rotate(45 62.2083 -1.53613)"
                fill="#9D4A1A"
              />
            </g>
          </g>
        </g>
        <g clipPath={`url(#clip1_${id})`}>
          <rect
            x="46.5017"
            y="20.2393"
            width="13.7596"
            height="41.2789"
            rx="6.87981"
            transform="rotate(30 46.5017 20.2393)"
            fill="#762F15"
          />
          <ellipse
            cx="35.2604"
            cy="53.4698"
            rx="6.87981"
            ry="6.87982"
            transform="rotate(30 35.2604 53.4698)"
            fill="#E2844D"
          />
        </g>
      </g>
      <defs>
        <filter
          id={`filter0_f_${id}`}
          x="-5.53613"
          y="-5.53613"
          width="94.0166"
          height="94.0166"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result={`effect1_foregroundBlur_${id}`}
          />
        </filter>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="61.5"
          y1="9.5"
          x2="22.5"
          y2="72.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#762F15" />
          <stop offset="1" stopColor="#B46230" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_${id}`}
          x1="61.5"
          y1="9.5"
          x2="22.5"
          y2="72.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#762F15" />
          <stop offset="1" stopColor="#B46230" />
        </linearGradient>
        <clipPath id={`clip0_${id}`}>
          <rect width="84" height="84" fill="white" />
        </clipPath>
        <clipPath id={`clip1_${id}`}>
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(17 17)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
