export default function MathGeometry({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.5C12.299 4.5 12.5724 4.66895 12.7061 4.93641L19.4167 18.3575C19.539 18.6022 19.5259 18.8928 19.3821 19.1256C19.2382 19.3583 18.9841 19.5 18.7105 19.5H5.28947C5.01586 19.5 4.76176 19.3583 4.61791 19.1256C4.47406 18.8928 4.46098 18.6022 4.58335 18.3575L11.2939 4.93641C11.4276 4.66895 11.701 4.5 12 4.5ZM6.56687 17.9211H17.4331L12 7.05479L6.56687 17.9211Z"
        fill="currentColor"
      />
    </svg>
  );
}
