import { ReactNode } from 'react';
import flatten from 'lodash/flatten';
import isRegExp from 'lodash/isRegExp';
import isString from 'lodash/isString';
import matchAll from 'string.prototype.matchall';

function escapeRegExp(string: string) {
  const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
  const reHasRegExpChar = RegExp(reRegExpChar.source);

  return string && reHasRegExpChar.test(string)
    ? string.replace(reRegExpChar, '\\$&')
    : string;
}

function replaceString(
  str: string,
  match: RegExp | string,
  fn: (value: RegExpMatchArray, index: number, position: number) => ReactNode
) {
  if (str === '') {
    return str;
  }
  if (!str || !isString(str)) {
    throw new TypeError('First argument must be a string');
  }

  let re = match;

  if (!isRegExp(re)) {
    re = new RegExp(`(${escapeRegExp(re)})`, 'gi');
  }

  const multipleMatchArr = [...matchAll(str, re)];

  if (!multipleMatchArr.length) return [str];

  const result: (string | ReactNode)[] = [];

  let curStartIndex = 0;

  multipleMatchArr.forEach((matchValue, i) => {
    const prevText = str.slice(curStartIndex, matchValue.index);
    result.push(prevText);
    result.push(fn(matchValue, i, curStartIndex));
    curStartIndex += prevText.length + matchValue[0].length;

    if (i === multipleMatchArr.length - 1) {
      result.push(str.slice(curStartIndex));
    }
  });

  return result;
}

export default function replaceComponent(
  source: string | string[],
  match: RegExp | string,
  fn: (value: RegExpMatchArray, index: number, position: number) => ReactNode
) {
  const strArray = Array.isArray(source) ? source : [source];

  return flatten(
    strArray.map((x) => (isString(x) ? replaceString(x, match, fn) : x))
  );
}
