import React from 'react';

export default function TestScore({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <mask
        height="18"
        id="mask0_28_1365"
        style={{ maskType: 'alpha' }}
        width="18"
        x="3"
        y="3"
        maskUnits="userSpaceOnUse"
      >
        <path
          d="M13 9.5H18V7.5H13V9.5ZM13 16.5H18V14.5H13V16.5ZM19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21ZM6 11H11V6H6V11ZM7 7H10V10H7V7ZM6 18H11V13H6V18ZM7 14H10V17H7V14Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_28_1365)">
        <path
          d="M12 3C4.8 3 3 4.8 3 12C3 19.2 4.8 21 12 21C19.2 21 21 19.2 21 12C21 4.8 19.2 3 12 3Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
