import React, {
  forwardRef,
  FunctionComponent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Box, { BoxProps } from '@mui/material/Box';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps, useMediaQuery } from '@mui/system';
import { ActionChevronRight as ActionChevronRightIcon } from '@front/icon';
import {
  ActionChevronFilledUp as ActionChevronFilledUpIcon,
  ActionChevronRightSmall as ActionChevronRightSmallIcon,
  ActionDoubleLeftChevron as ActionDoubleLeftChevronIcon,
} from '@front/icon';
import {
  ActionChevronLeft as ActionChevronLeftIcon,
  OtherMenu as OtherMenuIcon,
} from '@front/icon';
import {
  IconButton,
  Scrollbar,
  ScrollbarProps,
  SimpleTooltip,
  TipButton,
  TitleBar,
  useBaseLeftPanel,
} from '@front/ui';

import MenuStepButton from './components/MenuStepButton';
import MenuStepGroup from './components/MenuStepGroup';

type MenuSubTitleProps = {
  title: ReactNode;
};

type MenuButtonProps = {
  icon?: ReactNode;
  active?: boolean;
  disabled?: boolean;
  children: ReactNode;
  tagsComponent?: ReactNode;
  href?: string;
  shallow?: boolean;
  alert?: boolean;
  badgeValue?: number;
  onClick?: (ev: MouseEvent) => void;
  tooltip?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  extraComponent?: ReactNode;
} & Omit<ButtonBaseProps, 'prefix'>;

type MenuSectionProps = {
  children: ReactNode;
  disabled?: boolean;
  tooltip?: string;
  sx?: BoxProps['sx'];
};

type MenuAppProps = {
  icon?: ReactNode;
  title?: ReactNode;
  defaultOpen?: boolean;
  children: ReactNode;
};

type MenuGroupProps = Omit<MenuAppProps, 'defaultOpen'> & {
  level?: number;
  defaultOpen?: boolean;
  disabled?: boolean;
  tooltip?: string;
  active?: boolean;
  href?: string;
  shallow?: boolean;
  extraComponent?: ReactNode;
};

type MenuGroupHandler = {
  open: () => void;
  close: () => void;
};

type MenuCompsProps = {
  title?: ReactNode;
  titleToolTip?: ReactNode;
  titleSuffix?: ReactNode;
  children?: ReactNode;
  sx?: BoxProps['sx'];
  scrollProps?: Omit<ScrollbarProps, 'children' | 'sx'>;
  toolComponent?: ReactNode;
  disabledCollapse?: boolean;
  onCollapse?: () => void;
};

const styles = {
  root: {
    bgcolor: '#151515',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    position: 'relative',
    flex: 1,
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  title: {
    px: { xs: 2.5, md: '12px' },
    width: '100%',
    '& > div': {
      minWidth: 0,
    },
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  subTitle: {
    px: { xs: 2.5, md: '12px' },
    height: 24,
    opacity: 0.5,
    typography: 'caption',
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrap: {
    px: 0.5,
    py: '1.5px',
    width: '100%',
  },
  button: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
    gap: 1,
    fontSize: 14,
    height: 27,
    px: 1,
    opacity: 0.64,
    borderRadius: 1,
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
    '& svg': {
      width: 16,
      height: 16,
      verticalAlign: 'text-bottom',
    },
    '& span:not(.MuiAvatar-root)': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
    },
    '& .menu-button-inner': {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      display: 'grid',
      alignItems: 'center',
      gap: 1,
      gridTemplateColumns: '1fr minmax(16px, max-content)',
    },

    '&.is-active': {
      color: 'text.primary',
      opacity: 1,
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    },
  },
  animateButton: {
    px: { xs: 0, md: 0 },
    '& > span': {
      height: '100%',
    },
  },
  animateButtonInner: {
    px: { xs: 2.5, md: '12px' },
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      overflow: 'visible',
      textOverflow: 'unset',
    },
    '& .menu-animate-button-content': {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: 1,
      transitionDuration: '2.3s',
    },

    '& .menu-animate-button-display': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },

    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '& .menu-animate-button-display': {
          overflow: 'visible',
          textOverflow: 'unset',
        },
      },
    },
  },
  animateButtonText: {
    flex: 1,
    lineHeight: '36px',
    overflow: 'hidden',
  },
  buttonIcon: {
    flex: '0 0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  appTitle: {
    typography: 'body1',
    '& svg:not(.chevron-icon)': {
      width: 10,
      height: 10,
    },
  },
  appOpened: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    '& .chevron-icon': {
      transform: 'rotate(180deg)',
    },
  },
  appContent: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    '& .menu-button': {
      typography: 'body1',
    },
  },
  groupOpened: {
    '& .chevron-icon': {
      transform: 'rotate(90deg)',
    },
  },
  group: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto auto',
    alignItems: 'center',
    textAlign: 'left',
  },
  groupTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    typography: 'body2',
  },
  groupContent: {
    '&.level-1 .menu-button, &.level-0 .menu-button': {
      borderRadius: 1,
      position: 'relative',
      pl: '32px',
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 2,
        left: 15,
        top: '-1.5px',
        bottom: '-1.5px',
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
      '&.is-active:before': {
        bgcolor: 'text.primary',
      },
    },
    '&.level-2 .menu-button': {
      pl: 8,
    },
  },
  chip: {
    px: 1,
    py: '2px',
    bgcolor: 'error.dark',
    color: 'white',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  menuTooltip: {
    '& .MuiTooltip-tooltip': {
      ml: '0px !important',
      mt: '0px !important',
      maxWidth: '200px',
    },
  },
  toolbar: {
    display: 'flex',
    gap: 1,
    ml: 'auto',
  },
  alert: {
    position: 'absolute',
    right: 36,
    top: '5.5px',
    bgcolor: 'error.dark',
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
  menuTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  menuTitleCollapse: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  menuTitleNav: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  menuTitleContent: {
    fontWeight: 500,
    color: '#E1E1E1',
    typography: {
      xs: 'body1',
      md: 'body2',
    },
  },
};

function MenuSubTitle({ title }: MenuSubTitleProps) {
  return <Box sx={styles.subTitle}>{title}</Box>;
}

const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
  (
    {
      sx,
      children,
      icon,
      active = false,
      disabled = false,
      alert = false,
      href,
      shallow,
      badgeValue,
      onClick,
      tooltip,
      prefix,
      suffix,
      extraComponent,
      ...rest
    },
    ref
  ) => {
    const pathname = usePathname();
    const handleClick = (ev: MouseEvent) => {
      onClick?.(ev);
    };
    const sxProps = Array.isArray(sx) ? sx : [sx];

    const isActive = active || pathname === href;
    const menuButtonContent = (
      <ButtonBase
        ref={ref}
        sx={[styles.button, ...sxProps]}
        className={`menu-button ${isActive ? 'is-active' : ''}`}
        onClick={handleClick}
        disabled={disabled}
        {...(href ? { href, component: Link, shallow } : {})}
        {...rest}
      >
        {prefix}

        {!!icon && <Box sx={styles.buttonIcon}>{icon}</Box>}

        <Box className="menu-button-inner">
          <span className="menu-button-content">{children}</span>
          {suffix}
        </Box>
        {badgeValue !== undefined && (
          <Box sx={styles.chip} className="menu-button-badge">
            <Typography variant="caption" fontWeight="500">
              {badgeValue > 99 ? '99+' : badgeValue}
            </Typography>
          </Box>
        )}

        {alert && <Box sx={styles.alert} />}

        {extraComponent}
      </ButtonBase>
    );

    if (tooltip) {
      return (
        <SimpleTooltip
          title={tooltip}
          slotProps={{
            popper: {
              sx: styles.menuTooltip,
            },
          }}
        >
          <Box sx={styles.buttonWrap}>{menuButtonContent}</Box>
        </SimpleTooltip>
      );
    }

    return <Box sx={styles.buttonWrap}>{menuButtonContent}</Box>;
  }
);

MenuButton.displayName = 'MenuButton';

function MenuAnimateButton({
  icon,
  children,
  tagsComponent,
  ...rest
}: MenuButtonProps) {
  const contentRef = useRef<HTMLSpanElement>(null);
  const prevWidth = useRef(0);
  const [offset, setOffset] = useState(0);

  const handleMouseEnter = () => {
    if (contentRef.current) {
      setOffset(contentRef.current.offsetWidth - prevWidth.current);
    }
  };
  const handleMouseLeave = () => {
    setOffset(0);
    if (contentRef.current) {
      prevWidth.current = contentRef.current.offsetWidth;
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      prevWidth.current = contentRef.current.offsetWidth;
    }
  }, []);

  return (
    <MenuButton sx={styles.animateButton} {...rest}>
      <Box sx={styles.animateButtonInner}>
        <Box
          sx={styles.animateButtonText}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className="menu-animate-button-content"
            style={{ transform: `translateX(-${offset}px)` }}
          >
            {!!icon && <Box sx={styles.buttonIcon}>{icon}</Box>}
            <span ref={contentRef} className="menu-animate-button-display">
              {children}
            </span>
          </div>
        </Box>
        {tagsComponent}
      </Box>
    </MenuButton>
  );
}

const MenuGroup = forwardRef<MenuGroupHandler, MenuGroupProps>(
  (
    {
      children,
      title,
      icon,
      defaultOpen = false,
      level = 1,
      disabled = false,
      active,
      extraComponent,
      href,
      shallow,
    },
    ref
  ) => {
    const [open, setOpen] = useState(defaultOpen);
    const [childLoaded, setChildLoaded] = useState(false);
    const pathname = usePathname();
    const isActive = active ?? pathname === href;

    const setOpenState = useCallback((_open: boolean) => {
      setOpen(_open);
      if (!_open) {
        // as long as the child has been rendered, we need to keep them on dom to make animation work
        setChildLoaded(false);
      }
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        open: () => setOpenState(true),
        close: () => setOpenState(false),
      }),
      [setOpenState]
    );

    const handleLinkClick = useCallback(
      (ev: MouseEvent<HTMLButtonElement>): void => {
        if (isActive) {
          ev.preventDefault();
        }
      },
      [isActive]
    );

    const handleButtonClick = useCallback((): void => {
      if (!open) {
        setOpenState(true);
      }

      if (open && !href) {
        // while href is set, we don't want to close the menu
        setOpenState(false);
      }
    }, [href, open, setOpenState]);

    const handleChevronIconClick = useCallback(
      (ev: MouseEvent<HTMLButtonElement>) => {
        setOpenState(!open);
        ev.preventDefault();
      },
      [open, setOpenState]
    );

    return (
      <Box>
        <Box sx={styles.buttonWrap}>
          <ButtonBase
            sx={[styles.button, styles.group, open && styles.groupOpened]}
            className={`menu-button`}
            disabled={disabled}
            {...(href
              ? { href, component: Link, shallow, onClick: handleLinkClick }
              : {
                  onClick: handleButtonClick,
                })}
          >
            <Box sx={styles.buttonIcon}>{icon}</Box>
            <Box sx={styles.groupTitle} className="menu-button-content">
              {title}
            </Box>
            <Box>{extraComponent}</Box>
            {href ? (
              <TipButton
                customSize={24}
                onClick={handleChevronIconClick}
                title={open ? 'Collapse' : 'Expand'}
              >
                <ActionChevronRightSmallIcon className="chevron-icon" />
              </TipButton>
            ) : (
              <ActionChevronRightSmallIcon className="chevron-icon" />
            )}
          </ButtonBase>
        </Box>
        <Collapse in={open}>
          {(childLoaded || open) && (
            <Box sx={styles.groupContent} className={`level-${level}`}>
              {React.Children.map(children, (child) => {
                if (!React.isValidElement(child)) {
                  return null;
                }

                if (typeof child?.type === 'function') {
                  if (
                    (child.type as FunctionComponent).displayName ===
                    'MenuGroup'
                  ) {
                    return React.cloneElement(child, {
                      ...child.props,
                      level: level + 1,
                      disabled: child.props.disabled || disabled,
                    });
                  }
                }
                return React.cloneElement(child, {
                  ...child.props,
                  disabled: child.props.disabled || disabled,
                });
              })}
            </Box>
          )}
        </Collapse>
      </Box>
    );
  }
);

function MenuApp({ children, title, icon, defaultOpen = false }: MenuAppProps) {
  const [open, setOpen] = useState(defaultOpen);
  return (
    <Box>
      <ButtonBase
        sx={[styles.button, styles.appTitle, open && styles.appOpened]}
        onClick={() => setOpen((st) => !st)}
      >
        <Box sx={styles.buttonIcon}>{icon}</Box>
        <span> {title}</span>

        <ActionChevronFilledUpIcon className="chevron-icon" />
      </ButtonBase>
      <Collapse in={open}>
        <Box sx={styles.appContent}>{children}</Box>
      </Collapse>
    </Box>
  );
}

function MenuSection({ children, sx, disabled, tooltip }: MenuSectionProps) {
  const menuSectionContent = (
    <Box sx={sx}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          disabled: child.props.disabled || disabled,
        });
      })}
    </Box>
  );

  if (tooltip) {
    return (
      <SimpleTooltip
        title={tooltip}
        followCursor
        slotProps={{
          popper: {
            sx: styles.menuTooltip,
          },
        }}
      >
        <Box>{menuSectionContent}</Box>
      </SimpleTooltip>
    );
  }

  return menuSectionContent;
}

type MenuResponsiveTitleProps = {
  title: string;
  titleIcon: ReactNode;
  onCollapse?: () => void;
  navigation?: {
    onBack?: () => void;
    onNext?: () => void;
  };
  sx?: SxProps;
};

function MenuResponsiveTitle({
  title,
  titleIcon,
  onCollapse,
  navigation,
  sx,
}: MenuResponsiveTitleProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={[styles.menuTitle, ...sxProps]}>
      {mdUp && navigation && (
        <Box sx={styles.menuTitleNav}>
          <IconButton
            customSize={24}
            onClick={navigation.onBack}
            disabled={!navigation.onBack}
          >
            <ActionChevronLeftIcon />
          </IconButton>
          <IconButton
            customSize={24}
            onClick={navigation.onNext}
            disabled={!navigation.onNext}
          >
            <ActionChevronRightIcon />
          </IconButton>
        </Box>
      )}
      {!mdUp && (
        <Box sx={styles.menuTitleCollapse}>
          <IconButton customSize={28} onClick={onCollapse} sx={{ mr: '10px' }}>
            <OtherMenuIcon width={20} height={20} />
          </IconButton>
          {titleIcon}
        </Box>
      )}
      <Typography sx={styles.menuTitleContent}>{title}</Typography>
    </Box>
  );
}

function MenuComps({
  title,
  titleSuffix,
  titleToolTip,
  children,
  sx,
  scrollProps = {},
  toolComponent,
  disabledCollapse,
  onCollapse,
  ...rest
}: MenuCompsProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const { disableLeftPanel } = useBaseLeftPanel();
  return (
    <Box sx={[styles.root, ...sxProps]} {...rest}>
      {title && (
        <TitleBar
          sx={styles.title}
          title={
            <SimpleTooltip
              title={titleToolTip !== false ? titleToolTip || title : ''}
            >
              <span>{title}</span>
            </SimpleTooltip>
          }
          titleSuffix={titleSuffix}
          toolComponent={
            disabledCollapse ? undefined : (
              <Box sx={styles.toolbar}>
                {toolComponent}
                <TipButton
                  customSize={24}
                  onClick={onCollapse || disableLeftPanel}
                  title="Collapse"
                >
                  <ActionDoubleLeftChevronIcon />
                </TipButton>
              </Box>
            )
          }
          className="menu-title-bar"
        />
      )}
      <Box sx={styles.wrapper}>
        <Scrollbar sx={styles.scroll} {...scrollProps}>
          <Box sx={styles.content} className="menu-content">
            {children}
          </Box>
        </Scrollbar>
      </Box>
    </Box>
  );
}

MenuComps.SubTitle = MenuSubTitle;
MenuComps.Button = MenuButton;
MenuComps.AnimateButton = MenuAnimateButton;
MenuComps.Section = MenuSection;
MenuComps.ResponsiveTitle = MenuResponsiveTitle;

MenuGroup.displayName = 'MenuGroup';
MenuComps.Group = MenuGroup;
MenuComps.App = MenuApp;
MenuComps.StepButton = MenuStepButton;
MenuComps.StepGroup = MenuStepGroup;

export default MenuComps;
