import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import { PositionalErrorMessages } from '@lib/web/ui';

interface ErrorMessageMapValue {
  errorMessagesMap: Record<string, PositionalErrorMessages>;
  fieldErrorMessagesMap: Record<string, PositionalErrorMessages>;
}

const defaultValue: ErrorMessageMapValue = {
  errorMessagesMap: {},
  fieldErrorMessagesMap: {},
};

type ErrorMessageContextValue = [
  ErrorMessageMapValue,
  Dispatch<SetStateAction<ErrorMessageMapValue>>
];

type ErrorMessageProviderProps = {
  children: ReactNode;
  initialValue?: ErrorMessageMapValue;
};

export const ErrorMessageContext = createContext<ErrorMessageContextValue>([
  defaultValue,
  () => {},
]);

export function ErrorMessageProvider({
  initialValue = defaultValue,
  children,
}: ErrorMessageProviderProps) {
  const providerValue = useState<ErrorMessageMapValue>(initialValue);

  return (
    <ErrorMessageContext.Provider value={providerValue}>
      {children}
    </ErrorMessageContext.Provider>
  );
}
