import React from 'react';

export default function OtherMute({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.63649 20.8865L20.6365 4.88649C20.988 4.53502 20.988 3.96517 20.6365 3.6137C20.285 3.26223 19.7152 3.26223 19.3637 3.6137L3.3637 19.6137C3.01223 19.9652 3.01223 20.535 3.3637 20.8865C3.71517 21.238 4.28502 21.238 4.63649 20.8865Z"
        fill="currentColor"
      />
      <path
        d="M9.41097 17.9509L13.261 20.7011L13.357 20.7631C13.5244 20.8581 13.7137 20.9075 13.9061 20.9064C14.0986 20.9053 14.2874 20.8538 14.4536 20.7569C14.6199 20.6601 14.7579 20.5213 14.8538 20.3545C14.9496 20.1876 15.0001 19.9985 15 19.8061V12.3619L9.41097 17.9509Z"
        fill="currentColor"
      />
      <path
        d="M15 6.13932V4.19407L14.994 4.08007C14.9739 3.88869 14.904 3.70591 14.7912 3.55C14.6785 3.39409 14.5267 3.27049 14.3512 3.19155C14.1758 3.11261 13.9826 3.08108 13.7911 3.10013C13.5996 3.11917 13.4165 3.18811 13.26 3.30007L6.68 8.00007H4L3.85 8.00507C3.34684 8.04291 2.87659 8.26954 2.5335 8.63952C2.19041 9.0095 1.99984 9.49549 2 10.0001V14.0001L2.005 14.1501C2.04284 14.6532 2.26947 15.1235 2.63945 15.4666C3.00943 15.8097 3.49542 16.0002 4 16.0001H5.13924L15 6.13932Z"
        fill="currentColor"
      />
      <path
        d="M18.7118 8.65003L20.1276 7.23422C20.6499 7.79529 21.0773 8.43962 21.3915 9.14206C21.7935 10.0412 22.0009 11.0152 22 12.0001C22.0009 12.985 21.7935 13.959 21.3915 14.8581C20.9894 15.7572 20.4017 16.5611 19.667 17.2171C19.5694 17.3061 19.4551 17.3749 19.3308 17.4195C19.2064 17.4641 19.0745 17.4837 18.9426 17.477C18.8106 17.4703 18.6813 17.4376 18.5621 17.3806C18.4429 17.3237 18.3362 17.2437 18.2481 17.1453C18.1599 17.0469 18.0922 16.932 18.0487 16.8072C18.0053 16.6825 17.987 16.5503 17.9949 16.4185C18.0028 16.2866 18.0367 16.1576 18.0948 16.0389C18.1528 15.9203 18.2338 15.8143 18.333 15.7271C18.8582 15.2587 19.2782 14.6844 19.5656 14.0421C19.8529 13.3997 20.0009 12.7038 20 12.0001C20 10.712 19.5137 9.53749 18.7118 8.65003Z"
        fill="currentColor"
      />
      <path
        d="M16.584 10.7778L18.0037 9.35812C18.2688 9.65904 18.4876 9.99888 18.652 10.3666C18.8818 10.8801 19.0003 11.4365 19 11.9991C19.0007 12.562 18.8823 13.1187 18.6525 13.6327C18.4228 14.1466 18.0869 14.6061 17.667 14.9811C17.4774 15.1495 17.2308 15.2396 16.9773 15.233C16.7237 15.2265 16.4821 15.1239 16.3014 14.946C16.1206 14.768 16.0143 14.5281 16.0038 14.2747C15.9933 14.0212 16.0796 13.7733 16.245 13.5811L16.333 13.4911C16.743 13.1231 17 12.5921 17 12.0001C17.001 11.5559 16.8535 11.1262 16.584 10.7778Z"
        fill="currentColor"
      />
    </svg>
  );
}
