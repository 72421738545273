import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  OtherCircleOutline as OtherCircleOutlineIcon,
  OtherCircleSolid as OtherCircleSolidIcon,
} from '@front/icon';

export type RadioProps = MuiRadioProps & {
  label?: string;
  subLabel?: string;
  labelPosition?: 'end' | 'start';
  labelContainerSx?: BoxProps['sx'];
  avatar?: ReactNode;
  cancelable?: boolean;
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    py: '2px',
    px: '12px',
    '& .Mui-disabled + div': {
      opacity: 0.5,
    },
  },
  enabled: {
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  subLabel: { mt: 0.5, opacity: 0.5 },
  radio: {
    p: '4px',
    '&.Mui-disabled': {
      opacity: 0.5,
      color: 'inherit',
    },
  },
  leftRadio: { ml: '-4px' },
  rightRadio: { mr: '-4px', order: 1 },
  avatar: {
    mr: '10px',
  },
  labelContent: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default function Radio({
  label,
  subLabel,
  labelPosition = 'end',
  disabled,
  style,
  sx,
  labelContainerSx,
  avatar,
  cancelable,
  ...rest
}: RadioProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const iconProps = { width: 16, height: 16 };
  const Comp = cancelable ? Checkbox : MuiRadio;
  if (label) {
    const labelContainerSxProps = Array.isArray(labelContainerSx)
      ? labelContainerSx
      : [labelContainerSx];

    return (
      <Box
        sx={[
          styles.container,
          !disabled && styles.enabled,
          ...labelContainerSxProps,
        ]}
        component="label"
      >
        <Comp
          sx={[
            styles.radio,
            labelPosition === 'end' ? styles.leftRadio : styles.rightRadio,
            ...sxProps,
          ]}
          {...rest}
          color="default"
          icon={<OtherCircleOutlineIcon {...iconProps} />}
          checkedIcon={<OtherCircleSolidIcon {...iconProps} />}
          disabled={disabled}
        />
        <Box
          sx={[
            styles.labelContent,
            {
              order: labelPosition === 'end' ? 1 : 0,
              mr: labelPosition === 'start' ? 'auto' : 0,
            },
          ]}
        >
          {!!avatar && <Box sx={styles.avatar}>{avatar}</Box>}
          <Box>
            <Typography variant="body2">{label}</Typography>
            {!!subLabel && (
              <Typography sx={styles.subLabel} variant="body2">
                {subLabel}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Comp
      {...rest}
      color="default"
      icon={<OtherCircleOutlineIcon {...iconProps} />}
      checkedIcon={<OtherCircleSolidIcon {...iconProps} />}
      sx={[
        styles.radio,
        labelPosition === 'end' ? styles.leftRadio : styles.rightRadio,
        ...sxProps,
      ]}
      disabled={disabled}
    />
  );
}
