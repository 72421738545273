import { useContext, useEffect, useState } from 'react';
import {
  FieldValues,
  FormProvider,
  FormState,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import Router from 'next/router';
import { Box, Divider, Typography } from '@mui/material';
import { useLatestValueRef, useWatchDifferent } from '@front/helper';
import { EmphasizeButton } from '@front/ui';

import Icon from '../../components/Icon';
import { useIaAction } from '../../core/IaAction/useIaAction';

import AutoCompleteInput from './components/AutoCompleteInput';
import AvatarInput from './components/AvatarInput';
import CheckboxInput from './components/CheckboxInput';
import ChipOptionGroup from './components/ChipOptionGroup';
import DateInput from './components/DateInput';
import FormActions from './components/FormActions';
import FormSettingContextProvider from './components/FormSettingProvider';
import FormulaInput from './components/FormulaInput';
import MultipleSelectInput from './components/MultipleSelectInput';
import RadioGroup from './components/RadioGroup';
import SelectInput from './components/SelectInput';
import SquareCheckboxGroup from './components/SquareCheckboxGroup';
import SwitchInput from './components/SwitchInput';
import TextInput from './components/TextInput';
import FormLayoutMethodsContext from './FormLayoutMethods/FormLayoutMethodsContext';
import { FormLayoutConfig, FormLayoutGroup, FormLayoutItem } from './types';

type FormLayoutProps = FormLayoutConfig;
type FormLayoutItemProps = {
  item: FormLayoutItem;
};
type FormLayoutGroupProps = {
  group: FormLayoutGroup;
};

const styles = {
  form: {
    display: 'block',
  },
  root: {
    display: 'grid',
    gap: 5,
  },
  section: {
    display: 'grid',
    gap: 3,
  },
  subSection: {
    order: { md: 2 },
  },
  group: {
    display: 'grid',
    gap: 2,
  },
  items: {
    display: 'grid',
    gap: 1,
  },
  groupTitle: { fontSize: 20, fontWeight: 'bold' },
  groupContent: { opacity: 0.5, display: 'block' },
  gap: {
    display: 'block',
  },
};

function FormLayoutInput({ item }: FormLayoutItemProps) {
  if (
    item.type === 'text' ||
    item.type === 'textarea' ||
    item.type === 'number' ||
    item.type === 'email'
  )
    return <TextInput item={item} />;
  if (item.type === 'date') return <DateInput item={item} />;
  if (item.type === 'autoComplete') return <AutoCompleteInput item={item} />;
  if (item.type === 'avatar') return <AvatarInput item={item} />;
  if (item.type === 'checkbox') return <CheckboxInput item={item} />;
  if (item.type === 'squareCheckboxGroup')
    return <SquareCheckboxGroup item={item} />;
  if (item.type === 'select') return <SelectInput item={item} />;
  if (item.type === 'switch') return <SwitchInput item={item} />;
  if (item.type === 'radioGroup') return <RadioGroup item={item} />;
  if (item.type === 'formula') return <FormulaInput item={item} />;
  if (item.type === 'chipOptionGroup') return <ChipOptionGroup item={item} />;
  if (item.type === 'multipleSelectInput')
    return <MultipleSelectInput item={item} />;

  return null;
}

function FormLayoutInputGroup({ group }: FormLayoutGroupProps) {
  const hasTitle = !!group.title || !!group.content;
  return (
    <Box sx={styles.group}>
      {hasTitle && (
        <Box>
          {!!group.title && (
            <Typography variant="subtitle2" sx={styles.groupTitle}>
              {group.title}
            </Typography>
          )}
          {!!group.content && (
            <Typography variant="caption" sx={styles.groupContent}>
              {group.content}
            </Typography>
          )}
        </Box>
      )}
      <Box
        sx={[styles.items, { gap: group.gap }]}
        className={`ia-form-layout_group ${group.className || ''}`}
      >
        {group.items.map((item, i) => {
          if (item.type === 'group') {
            return <FormLayoutInputGroup key={i} group={item} />;
          }

          if (item.type === 'gap') {
            return <Box sx={styles.gap} key={i} height={item.value} />;
          }

          if (item.type === 'divider') {
            if (item.textOnly) {
              return (
                <Typography key={i} sx={{ opacity: 0.5 }} variant="caption">
                  {item.text}
                </Typography>
              );
            }
            return (
              <Divider key={i} sx={{ py: '10px' }} textAlign="left">
                <Typography sx={{ opacity: 0.5 }} variant="caption">
                  {item.text}
                </Typography>
              </Divider>
            );
          }

          if (item.type === 'actions') {
            return <FormActions key={i} item={item} />;
          }

          return (
            <FormLayoutInput key={item.id || item.name || i} item={item} />
          );
        })}
      </Box>
    </Box>
  );
}

type WatchChangedProps = {
  value: string;
};
function WatchStateChanged({ value }: WatchChangedProps) {
  const { getIaAction } = useIaAction();
  const { formState } = useFormContext();

  useWatchDifferent({
    watchValue: formState,
    // to compare previous and current form state, we need to trigger formState getters
    differentBy: (state) => ({
      dirtyFields: state.dirtyFields,
      errors: state.errors,
      isDirty: state.isDirty,
      isLoading: state.isLoading,
      isSubmitSuccessful: state.isSubmitSuccessful,
      isSubmitted: state.isSubmitted,
      isSubmitting: state.isSubmitting,
      isValid: state.isValid,
      isValidating: state.isValidating,
      submitCount: state.submitCount,
      touchedFields: state.touchedFields,
    }),
    onDifferent: () => {
      getIaAction<FormState<Record<string, any>>>(value)?.action(formState);
    },
  });

  return null;
}
function WatchValueChanged({ value }: WatchChangedProps) {
  const { getIaAction } = useIaAction();

  const values = useWatch();

  useWatchDifferent({
    watchValue: values,
    onDifferent: () => {
      getIaAction<FieldValues>(value)?.action(values);
    },
  });

  return null;
}
export default function FormLayout({
  settings,
  formAction,
  mainItems,
  defaultValues,
  subItems,
  formStateChanged,
  formValuesChanged,
}: FormLayoutProps) {
  const [loading, setLoading] = useState(false);
  const { getIaAction } = useIaAction();
  const latestGetIaActionRef = useLatestValueRef(getIaAction);
  const { setMethods } = useContext(FormLayoutMethodsContext);

  const methods = useForm({
    mode: settings?.mode,
    reValidateMode: settings?.reValidateMode,
    defaultValues,
  });

  const { isDirty, isValid } = methods.formState;

  const onSubmit = async (data: any) => {
    if (!formAction) return;
    const submitAction = latestGetIaActionRef.current(formAction.value); // instead of getIaAction, we need to use latestGetIaActionRef because onSubmit function is used as callback in form methods

    if (submitAction) {
      setLoading(true);
      const newData = await submitAction.action(data);

      if (newData) {
        methods.reset(newData);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (settings?.disableTriggerWhenRouting) return;

    if (settings?.touchedFields) {
      if (typeof settings.touchedFields === 'string') {
        void methods.trigger();
      } else if (Object.keys(settings.touchedFields).length > 0) {
        void methods.trigger(Object.keys(settings.touchedFields));
      }
    }
    const routeChangeStart = () => {
      void methods.trigger();
    };

    if (settings?.triggerAllUnmounting) {
      Router.events.on('routeChangeStart', routeChangeStart);
    }
    return () => {
      Router.events.off('routeChangeStart', routeChangeStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMethods(methods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods]);
  return (
    <FormProvider {...methods}>
      <FormSettingContextProvider
        mode={settings?.mode}
        submit={methods.handleSubmit(onSubmit)}
        triggerSubmitMode={settings?.triggerSubmitMode}
      >
        <Box
          sx={styles.form}
          component="form"
          className="ia-form-layout"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Box
            sx={[
              styles.root,
              { gridTemplateColumns: settings?.gridTemplateColumns },
            ]}
          >
            {!!subItems && (
              <Box sx={[styles.section, styles.subSection]}>
                {subItems.map((group, i) => (
                  <FormLayoutInputGroup key={i} group={group} />
                ))}
              </Box>
            )}
            <Box sx={styles.section}>
              {mainItems.map((group, i) => (
                <FormLayoutInputGroup key={i} group={group} />
              ))}
            </Box>
          </Box>
          {!!settings?.showActionButton && !!formAction && (
            <Box>
              <EmphasizeButton
                type="submit"
                prefixIcon={
                  formAction.icon ? <Icon name={formAction.icon} /> : undefined
                }
                disabled={!isDirty || isValid}
                loading={loading}
              >
                {formAction.text}
              </EmphasizeButton>
            </Box>
          )}
        </Box>
        {!!formStateChanged && <WatchStateChanged value={formStateChanged} />}
        {!!formValuesChanged && <WatchValueChanged value={formValuesChanged} />}
      </FormSettingContextProvider>
    </FormProvider>
  );
}
