import React from 'react';

export default function TradingPortfolio({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.2968 2.29289C11.4843 2.10536 11.7387 2 12.0039 2C14.6552 2 17.1978 3.05367 19.0724 4.92908C20.947 6.80448 22 9.34797 22 12C22 12.2652 21.8947 12.5196 21.7071 12.7071C21.5196 12.8946 21.2653 13 21 13H12.0039C11.4516 13 11.0039 12.5523 11.0039 12V3C11.0039 2.73478 11.1093 2.48043 11.2968 2.29289ZM13.0039 4.06278V11H19.9374C19.7166 9.24689 18.9199 7.60561 17.6579 6.34299C16.396 5.08054 14.7558 4.28367 13.0039 4.06278Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M11.9902 21.9954C9.64019 21.9954 7.35019 21.1654 5.55019 19.6454C3.75019 18.1254 2.54019 16.0154 2.14019 13.6854C1.74019 11.3654 2.18019 8.96536 3.37019 6.93536C4.43019 5.13536 6.00019 3.72536 7.90019 2.87536C8.40019 2.64536 9.00019 2.87536 9.22019 3.37536C9.44019 3.87536 9.22019 4.47536 8.72019 4.69536C7.20019 5.37536 5.95019 6.50536 5.10019 7.94536C4.15019 9.57536 3.80019 11.4854 4.11019 13.3454C4.42019 15.2054 5.40019 16.8954 6.84019 18.1154C8.28019 19.3254 10.1102 19.9954 11.9902 19.9954C13.6502 19.9954 15.2502 19.4854 16.6102 18.5254C17.7802 17.6954 18.7002 16.5754 19.2902 15.2754C19.5202 14.7754 20.1102 14.5454 20.6102 14.7754C21.1102 15.0054 21.3402 15.5954 21.1102 16.0954C20.3802 17.7254 19.2202 19.1254 17.7602 20.1554C16.0602 21.3554 14.0702 21.9854 11.9902 21.9854V21.9954Z"
        fill="currentColor"
      />
    </svg>
  );
}
