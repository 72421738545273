import React from 'react';

export default function TradingDrawing({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.8488 3C16.0744 3 16.2907 3.09219 16.4489 3.25589L18.6944 5.57815C19.017 5.91175 19.017 6.44705 18.6944 6.78065L10.0867 15.6826C9.95775 15.816 9.78944 15.9028 9.6075 15.9297L6.98775 16.3167C6.72544 16.3554 6.46051 16.2662 6.27266 16.0759C6.08481 15.8855 5.99598 15.6163 6.03289 15.3491L6.40714 12.6398C6.43227 12.4578 6.51421 12.2889 6.64086 12.1579L15.2486 3.25589C15.4069 3.09219 15.6231 3 15.8488 3ZM8.03623 13.1587L7.85703 14.456L9.08513 14.2746L16.9127 6.1794L15.8488 5.07909L8.03623 13.1587ZM19.1579 21L4.84211 21C4.37702 21 4 20.6162 4 20.1429C4 19.6695 4.37702 19.2857 4.84211 19.2857L19.1579 19.2857C19.623 19.2857 20 19.6695 20 20.1429C20 20.6162 19.623 21 19.1579 21Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
