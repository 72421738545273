import { useRef } from 'react';
import { Box } from '@mui/material';
import { ChatPin as ChatPinIcon } from '@front/icon';
import BlockTag from '@lib/web/composer/BlockTag';
import ThemeProvider from '@lib/web/composer/components/ThemeProvider';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { v4 } from 'uuid';

import { ThreadBlockTypes } from '../../config/threadBlockTypes';

export type InlineLineMarkerOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineLineMarkerOptions;
    node: ProseMirrorNode;
  }) => string;
};

const LineMarkerStatusTag = () => {
  return (
    <BlockTag icon={<ChatPinIcon />} disabled>
      Line Marker
    </BlockTag>
  );
};

const LineMarker = () => {
  const anchorRef = useRef<HTMLElement | null>(null);

  return (
    <NodeViewWrapper
      style={{ display: 'inline-block', userSelect: 'none' }}
      className="inline-line-marker"
      contentEditable={false}
    >
      <ThemeProvider mode="dark">
        <Box ref={anchorRef}>
          <LineMarkerStatusTag />
        </Box>
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export const InlineLineMarker = Node.create<InlineLineMarkerOptions>({
  name: ThreadBlockTypes.InlineLineMarker,
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: v4,
        parseHTML: () => v4(), // when copy and paste, generate a new id
      },
      targetId: {
        default: '',
      },
      isNewCreated: {
        default: false,
      },
      displayText: {
        default: '',
      },
      displayLineText: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.name,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes({
        class: this.name,
        'data-render-target-id': HTMLAttributes.targetId,
        'data-content-type': this.name,
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(LineMarker);
  },
});
