import React from 'react';
import { Box } from '@mui/material';
import AreaList from '@lib/ia/src/layouts/ListLayout/components/AreaList';

import AreaListSkeleton from './components/AreaListSkeleton';
import { ListLayoutConfig } from './types';

type ListLayoutProps = Omit<ListLayoutConfig, 'layout'>;

const styles = {
  root: {
    py: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};
export default function ListLayout({ areas }: ListLayoutProps) {
  return (
    <Box sx={styles.root} className="ia-list-layout">
      {areas.map((area) => {
        const { key, ...rest } = area;
        if (rest.areaType === 'list') {
          return <AreaList key={key} {...rest} />;
        }
        if (rest.areaType === 'loading') {
          return <AreaListSkeleton key={key} {...rest} />;
        }

        return null;
      })}
    </Box>
  );
}
