import { SWRConfiguration } from 'swr';

import generateHook, { generateInfiniteHook } from './swr.helper';

type ScopeType = 'global' | 'friends';

const getMeApiKey = (examinationId: string | null, scope: ScopeType) => {
  if (!examinationId) return undefined;

  const scopeUrl = scope === 'global' ? '' : '/friend';

  return `/v2/ranking/exam/${examinationId}${scopeUrl}`;
};

const getAllApiKey = (
  examinationId: string | null,
  scope: ScopeType,
  page: number,
  pageSize: number
) => {
  if (!examinationId) return undefined;

  if (scope === 'friends')
    return `v2/ranking/list/exam/${examinationId}/friend?page=${page}&limit=${pageSize}`;

  return `v2/ranking/list/exam/${examinationId}?page=${page}&limit=${pageSize}`;
};

export const useMyRanking = (examinationId: string | null, scope: ScopeType) =>
  generateHook<GetRankingRes>(getMeApiKey(examinationId, scope), {
    auth: true,
    immutable: true,
  });

export const useRankingAll = (
  examinationId: string | null,
  scope: ScopeType,
  page = 1,
  pageSize = 10
) =>
  generateHook<GetMemberRankingRes>(
    getAllApiKey(examinationId, scope, page, pageSize),
    {
      auth: true,
      immutable: true,
      paginate: true,
    }
  );

export const useRankingAllList = (
  examinationId: string | null,
  scope: ScopeType,
  pageSize = 10,
  config: SWRConfiguration = {}
) =>
  generateInfiniteHook<GetMemberRankingRes>(
    (index) => getAllApiKey(examinationId, scope, index + 1, pageSize),
    pageSize,
    {
      auth: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    }
  );

export const useSectionRanking = ({
  sectionId,
  userId,
}: {
  sectionId: NullableString;
  userId?: string;
}) =>
  generateHook<GetSectionRankingRes>(
    sectionId && userId
      ? `/v2/ranking/section/${sectionId}?userId=${userId}`
      : '',
    {
      auth: true,
      immutable: true,
    }
  );

export const useSectionFriendsRanking = ({
  sectionId,
  userId,
}: {
  sectionId: NullableString;
  userId?: string;
}) =>
  generateHook<GetSectionFriendsRankingRes>(
    sectionId && userId
      ? `/v2/ranking/section/${sectionId}/friend?userId=${userId}`
      : '',
    {
      auth: true,
      immutable: true,
    }
  );

export const useSectionRankingList = ({
  sectionId,
  pageSize = 10,
  isFriends = false,
}: {
  sectionId: NullableString;
  pageSize?: number;
  isFriends?: boolean;
}) =>
  generateInfiniteHook<GetSectionRankingListRes>(
    (index) => {
      const path = isFriends
        ? `/v2/ranking/list/section/${sectionId}/friend`
        : `/v2/ranking/list/section/${sectionId}`;
      return sectionId ? `${path}?page=${index + 1}&limit=${pageSize}` : '';
    },
    pageSize,
    {
      auth: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
