import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { alpha, Theme, useTheme } from '@mui/material/styles';

export type ProgressBarProps = {
  ratio: number;
  sx?: BoxProps['sx'];
  color?: 'primary' | 'success' | 'error' | 'warning';
};

const styles = {
  bar: {
    width: '100%',
    height: 8,
    borderRadius: 1,
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
  barInner: {
    height: '100%',
    borderRadius: 1,
  },
};
export default function ProgressBar({
  sx,
  ratio,
  color = 'primary',
}: ProgressBarProps) {
  const theme = useTheme();
  const bgColor = theme.palette.gradient[color];
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={[styles.bar, ...sxProps]} className="progress-bar">
      <Box
        sx={[
          styles.barInner,
          { background: bgColor, width: `${ratio * 100}%` },
        ]}
      />
    </Box>
  );
}
