import React from 'react';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ActionSubcategoryArrow as ActionSubcategoryArrowIcon } from '@front/icon';

import { BreadcrumbsValue } from '../types';

import BreadcrumbsItem from './BreadcrumbsItem';

export type BreadcrumbListProps = {
  title: string;
  breadcrumbsValues: BreadcrumbsValue[];
  onLinkClick?: () => void;
};

const styles = {
  title: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    marginBottom: '8px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemLine: {
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '20px',
    paddingRight: '20px',
    height: '36px',

    '&:last-child': {
      backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    },
  },
  itemCell: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  itemCellWithSubcategory: {
    marginLeft: '4px',
  },
  itemRoot: {
    display: 'inline-grid',
  },
};

export default function BreadcrumbList({
  title,
  breadcrumbsValues,
  onLinkClick,
}: BreadcrumbListProps) {
  const handleLinkClick = () => {
    onLinkClick?.();
  };
  return (
    <Box>
      <Box sx={styles.title}>
        <Typography variant="caption">{title}</Typography>
      </Box>
      <Box sx={styles.body}>
        {breadcrumbsValues.map(
          ({ text, href, icon, customizeComponent }, index) => {
            const isFirst = index === 0;
            const isLast = index === breadcrumbsValues.length - 1;

            return (
              <Box key={`${text}-${href}`} sx={styles.itemLine}>
                <Box
                  {...(!isLast && href
                    ? { component: Link, href, onClick: handleLinkClick }
                    : {})}
                  sx={[
                    styles.itemCell,
                    !isFirst && styles.itemCellWithSubcategory,
                  ]}
                >
                  {!isFirst && <ActionSubcategoryArrowIcon />}
                  <BreadcrumbsItem
                    text={text}
                    icon={icon}
                    customizeComponent={customizeComponent}
                    sx={styles.itemRoot}
                  />
                </Box>
              </Box>
            );
          }
        )}
      </Box>
    </Box>
  );
}
