export const enum PinChatViewSlug {
  PinChatOverlayDefault = 'pin_overlay_ia_pinchat_default',
}

export const enum PinChatType {
  Unknown = 0,
  Onboarding = 1,
}
export const enum PinChatStatus {
  Unknown = 0,
  Enabled = 1,
  Disabled = -1,
}
export const enum PinChatUserStatus {
  NotChecked = 0,
  Checked = 1,
}
