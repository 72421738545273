import { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';

export type DetectUseInfiniteScrollProps = {
  loadMore: () => void;
};

export default function DetectUseInfiniteScroll({
  loadMore,
}: DetectUseInfiniteScrollProps) {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    let verticalScrollEl: HTMLDivElement | null = null;

    const handleScroll = (ev: Event) => {
      const target = ev.target as HTMLDivElement;
      if (target) {
        /**
         * 1 is a buffer, when user zoom in or zoom out, it will have some tolerance
         */
        const isBottom =
          target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight;

        if (isBottom) loadMore();
      }
    };

    if (ref.current) {
      const horizontalScrollEl = ref.current.closest(
        '.simplebar-content-wrapper'
      );

      verticalScrollEl = horizontalScrollEl?.parentElement?.closest(
        '.simplebar-content-wrapper'
      ) as HTMLDivElement;

      if (verticalScrollEl) {
        const scrollElHeight = verticalScrollEl.offsetHeight;
        const contentHeight =
          (verticalScrollEl.firstChild as HTMLDivElement)?.offsetHeight || 0;

        if (scrollElHeight >= contentHeight && contentHeight > 0) {
          loadMore();
        }

        verticalScrollEl.addEventListener('scroll', handleScroll);
      }
    }

    return () => {
      if (verticalScrollEl) {
        verticalScrollEl.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadMore]);

  return <Box ref={ref} />;
}
