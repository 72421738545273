import { createContext } from 'react';

export type FormSettingContextValue = {
  submit: () => Promise<void>;
  mode?: 'onBlur' | 'onChange' | 'onSubmit' | 'all' | 'onTouched';
  triggerSubmitMode?: 'onBlur' | 'onChange';
};

const FormSettingContext = createContext<FormSettingContextValue>({
  submit: async () => {},
});

export default FormSettingContext;
