import React from 'react';

export default function SocialMediaTwitter({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M22 5.89789C21.2645 6.21861 20.4744 6.43531 19.6438 6.53326C20.5008 6.0286 21.142 5.23432 21.4477 4.29863C20.6425 4.7693 19.7612 5.1006 18.8422 5.27812C18.2242 4.62877 17.4057 4.19836 16.5136 4.05374C15.6216 3.90911 14.706 4.05834 13.909 4.47828C13.1119 4.89821 12.4781 5.56535 12.1058 6.37611C11.7336 7.18688 11.6437 8.0959 11.8503 8.96206C10.2187 8.88144 8.62259 8.46412 7.16553 7.73718C5.70847 7.01023 4.42301 5.98991 3.39258 4.74244C3.04025 5.34053 2.83766 6.03398 2.83766 6.7725C2.83727 7.43734 3.00364 8.09199 3.32201 8.67838C3.64038 9.26477 4.10091 9.76476 4.66273 10.134C4.01117 10.1136 3.37397 9.94032 2.80419 9.62863V9.68064C2.80413 10.6131 3.13189 11.5169 3.73186 12.2386C4.33184 12.9603 5.16707 13.4555 6.09583 13.6402C5.4914 13.8012 4.85769 13.8249 4.24258 13.7096C4.50462 14.5119 5.01506 15.2135 5.70243 15.7162C6.3898 16.2188 7.21969 16.4974 8.07593 16.5128C6.62242 17.6357 4.82735 18.2448 2.97948 18.2421C2.65215 18.2422 2.32509 18.2234 2 18.1858C3.87569 19.3726 6.05914 20.0024 8.28909 20C15.8378 20 19.9644 13.8474 19.9644 8.51132C19.9644 8.33796 19.96 8.16286 19.9521 7.9895C20.7548 7.41825 21.4476 6.71087 21.9982 5.90049L22 5.89789Z"
        fill="currentColor"
      />
    </svg>
  );
}
