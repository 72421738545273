import { Plugin, PluginKey } from 'prosemirror-state';

const PLUGIN_KEY = new PluginKey('replace-line-break');

export function replaceLineBreak(): Plugin {
  return new Plugin({
    key: PLUGIN_KEY,
    props: {
      transformPastedHTML(html: string): string {
        return html.replaceAll('\n', '<br/>');
      },
    },
  });
}
