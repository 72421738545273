const htmlContent = (type: string) =>
  `[data-content-type="${type}"]:not(.block-content)`;

const oneLineStyle = {
  div: {
    display: 'none',
  },
  '&:after': {
    pr: '4px',
    fontStyle: 'italic',
    content: '"Image"',
  },
};

export const generatedHtmlLegacyMaterialBlockStyle = () => ({
  [`${htmlContent('legacy-material')}`]: {
    '.render-one-line&': oneLineStyle,
  },
  [`.render-one-line ${htmlContent('legacy-material')}`]: oneLineStyle,
});
