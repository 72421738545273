import React from 'react';

export default function CryptoCurrencyRipple({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M23.6383 14.9029C22.0356 21.3315 15.5244 25.2438 9.09503 23.6407C2.66833 22.0381 -1.24401 15.5265 0.359382 9.09837C1.96137 2.66903 8.47253 -1.24361 14.8999 0.359081C21.3289 1.96177 25.241 8.47405 23.6381 14.903L23.6382 14.9029H23.6383Z"
        fill="#23292F"
      />
      <path
        d="M17.9005 5H20.3221L15.2826 9.98976C13.4576 11.7958 10.4987 11.7958 8.67233 9.98976L3.63086 5H6.0544L9.88312 8.79043C11.0439 9.93443 12.9081 9.93443 14.0689 8.79043L17.9005 5Z"
        fill="white"
      />
      <path
        d="M6.02331 18.8751H3.6001L8.67233 13.8545C10.4973 12.0485 13.4563 12.0485 15.2826 13.8545L20.3548 18.8751H17.9332L14.0718 15.0539C12.9111 13.9099 11.0468 13.9099 9.88606 15.0539L6.02331 18.8751Z"
        fill="white"
      />
    </svg>
  );
}
