import React from 'react';
import { InputBase } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase/InputBase';

const styles = {
  visuallyHiddenInput: {
    opacity: 0,
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: '1px',
  },
};
const VisuallyHiddenInput = React.forwardRef((props: InputBaseProps, ref) => {
  return <InputBase sx={styles.visuallyHiddenInput} ref={ref} {...props} />;
});

export default VisuallyHiddenInput;
