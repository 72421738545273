import React from 'react';

export default function OtherFriendsPlaylist({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14 8C14 5.79 12.21 4 10 4C7.79 4 6 5.79 6 8C6 10.21 7.79 12 10 12C12.21 12 14 10.21 14 8ZM2 18V20H18V18C18 15.34 12.67 14 10 14C7.33 14 2 15.34 2 18Z"
        fill="currentColor"
      />
      <path
        d="M20 18H21.9993V16H20V18ZM16.4999 6V8H21.9993V6H16.4999ZM16.5 13H21.9993V11H16.5V13Z"
        fill="currentColor"
      />
    </svg>
  );
}
