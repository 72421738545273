import { ComposerRenderer } from '@lib/web/composer';
import { ComposerRendererProps } from '@lib/web/composer/TextComposer/ComposerRenderer';
import { editorThreadBlockHtmlStyles } from '@lib/web/editor/EditorThreadTextComposer/config/editorThreadComposerBlockStyles';

export type EditorComposerRendererProps = Omit<
  ComposerRendererProps,
  'htmlStyles'
>;

export default function EditorThreadComposerRenderer({
  sx,
  ...rest
}: EditorComposerRendererProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <ComposerRenderer
      sx={sxProps}
      htmlStyles={editorThreadBlockHtmlStyles}
      {...rest}
    />
  );
}
