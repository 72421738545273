import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormLayoutConfig,
  FormLayoutLoadingConfig,
} from '@lib/ia/src/layouts/FormLayout/types';
import { TableLayoutConfig } from '@lib/ia/src/layouts/TableLayout/types';

import { useEditorRubricData } from './useEditorRubricData';

const isCreating = (id: string) => id.startsWith('temp-');

export const useEditorRubricCriteriaLayoutConfig = ({
  questionId,
  criteriaId,
  containerWidth,
}: {
  questionId: string;
  criteriaId: string;
  containerWidth: number;
}) => {
  const { t } = useTranslation('editor');
  const { rubric } = useEditorRubricData(questionId);
  const criteria = useMemo(
    () => rubric?.criteria.find((c) => c.id === criteriaId),
    [rubric, criteriaId]
  );
  const isEnableDescriptor = rubric?.isEnableDescriptor ?? false;

  return useMemo(() => {
    if (!criteria) {
      return [
        {
          layout: 'form-layout-skeleton',
        } as FormLayoutLoadingConfig,
      ];
    }
    return [
      {
        layout: 'form-layout',
        settings: {
          mode: 'onSubmit',
        },
        mainItems: [
          {
            type: 'group',
            gap: 2,
            items: [
              {
                type: 'textarea',
                name: 'description',
                label: t('Criteria'),
                icon: 'EditorParaphrase',
                textFieldSize: 'rwd',
                defaultValue: criteria.description,
                minRows: 4,
                actionMap: {
                  blur: {
                    value: 'onCriteriaFormBlur',
                  },
                },
                placeholder: 'Untitled',
                disabled: isCreating(criteria.id),
              },
              !isEnableDescriptor && {
                type: 'number',
                name: 'score',
                label: t('Score'),
                icon: 'ChatHashtag',
                textFieldSize: 'rwd',
                defaultValue: criteria.score,
                actionMap: {
                  blur: {
                    value: 'onCriteriaFormBlur',
                  },
                },
                placeholder: '1',
                disabled: isCreating(criteria.id),
              },
            ],
          },
        ],
      } as FormLayoutConfig,
      ...(isEnableDescriptor
        ? [
            {
              layout: 'table-layout',
              table: {
                columnOrder: ['description', 'score'],
                gridTemplateColumns: '1fr 32px',
                head: {
                  cells: {
                    description: {
                      type: 'default',
                      label: t('Descriptor'),
                      icon: 'EditorParaphrase',
                    },
                    score: {
                      type: 'default',
                      label: '',
                      icon: 'ChatHashtag',
                    },
                  },
                },
                rows:
                  criteria.descriptors?.map((descriptor) => ({
                    id: descriptor.id,
                    cells: {
                      description: {
                        type: 'text',
                        text: descriptor.description,
                        editable: !isCreating(descriptor.id),
                        placeholder: 'Untitled',
                        actionMap: {
                          inputBlur: 'updateDescriptorDescription',
                        },
                      },
                      score: {
                        type: 'number',
                        value: descriptor.score,
                        editable: !isCreating(descriptor.id),
                        placeholder: '1',
                        actionMap: {
                          inputBlur: 'updateDescriptorScore',
                        },
                      },
                    },
                    draggable: true,
                    moreActions: [
                      {
                        type: 'event',
                        icon: 'OtherCopy',
                        value: 'duplicateDescriptor',
                        text: t('Duplicate'),
                      },
                      {
                        icon: 'OtherDelete',
                        iconSxProps: {
                          color: 'error.dark',
                        },
                        type: 'event',
                        value: 'deleteDescriptor',
                        text: t('Delete'),
                        textSxProps: {
                          color: 'error.dark',
                        },
                      },
                    ],
                  })) || [],
              },
              data: {
                pageSize: criteria.descriptors?.length || 0,
                totalCount: criteria.descriptors?.length || 0,
                state: !criteria.descriptors?.length ? 'empty' : 'reachedEnd',
                emptyRow: {
                  text: t('rubric.descriptor.empty'),
                },
              },
              settings: {
                layoutSetting: {
                  position: 'rhs',
                  showColumnBorder: true,
                  showRowBorder: true,
                  minWidth: containerWidth - 52,
                  maxWidth: containerWidth - 52,
                },
                bottomButtons: {
                  addNewRow: {
                    text: t('rubric.descriptor.create.new'),
                    action: 'addNewDescriptor',
                  },
                },
                wrap: true,
                sortableConfig: {
                  dropAction: 'sortItem',
                },
                sideFloatingMenuAddActions: [
                  {
                    value: 'addNewDescriptorBelow',
                    hint: '**Click** to add below',
                  },
                  {
                    value: 'addNewDescriptorAbove',
                    hint: '**Option-Click** to add above',
                    addKey: 'altKey',
                  },
                ],
              },
            } as TableLayoutConfig,
          ]
        : []),
    ];
  }, [criteria, t, isEnableDescriptor, containerWidth]);
};
