import React, { useRef } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IaTextBasedEditableCell from '@lib/ia/src/layouts/DisplayTableLayout/components/IaDisplayTableEditable/IaTextBasedEditableCell';

import { DisplayTableLayoutRow, DisplayTableLayoutTextCell } from '../../types';

export type IaDisplayTableTextCellProps = {
  row: DisplayTableLayoutRow;
  cell: DisplayTableLayoutTextCell;
};

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    height: '32px',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    br: {
      // make html element render on one line
      content: '" "',
      display: 'none',
    },
  },
};
export default function IaDisplayTableTextCell({
  row,
  cell: { text, html, textSxProps, editable, actionMap, placeholder },
}: IaDisplayTableTextCellProps) {
  const textSx = Array.isArray(textSxProps) ? textSxProps : [textSxProps];

  if (html && text) {
    console.warn('Both text and html are provided, only html will be used');
  }

  const ref = useRef<HTMLDivElement>();

  return (
    <>
      <Box sx={styles.root} ref={ref}>
        {html ? (
          <Typography
            variant="body2"
            sx={[styles.text, ...textSx]}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ) : (
          <Typography variant="body2" sx={[styles.text, ...textSx]}>
            {text}
          </Typography>
        )}
      </Box>
      {editable && (
        <IaTextBasedEditableCell
          containerRef={ref}
          actionMap={actionMap}
          editable={editable}
          placeholder={placeholder}
          row={row}
          initValue={text}
          filterValue={(val: string) => val}
        />
      )}
    </>
  );
}
