import { createContext } from 'react';

export const iaDataContextInitialValue = {
  token: '',
  sid: '',
  apiUrl: '',
};

export type IaDataContextValueType = {
  token?: string;
  sid?: string;
  apiUrl?: string;
};
type IaDataContextValue = {
  token?: string;
  sid?: string;
  apiUrl?: string;
};
const IaDataContext = createContext<IaDataContextValue>(
  iaDataContextInitialValue
);

export default IaDataContext;
