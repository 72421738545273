import { createContext, ReactNode, useMemo } from 'react';

import { PracticeQuestionEventContextValue } from '../types';

const initialValue: PracticeQuestionEventContextValue = {
  onChangeAnswers: () => {},
  onChangeElimination: () => {},
};

export const PracticeQuestionEventContext =
  createContext<PracticeQuestionEventContextValue>(initialValue);

export type PracticeQuestionEventContextProviderProps = {
  children: ReactNode;
} & { events: PracticeQuestionEventContextValue };

export const PracticeQuestionEventContextProvider = ({
  children,
  events,
}: PracticeQuestionEventContextProviderProps) => {
  const memoizedValue = useMemo(() => {
    return {
      ...events,
    };
  }, [events]);

  return (
    <PracticeQuestionEventContext.Provider value={memoizedValue}>
      {children}
    </PracticeQuestionEventContext.Provider>
  );
};
