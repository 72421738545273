import React from 'react';

export default function OtherCalendarMonth({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_7796_650585)">
        <path
          d="M18.2222 4.77778H17.3333V3H15.5556V4.77778H8.44444V3H6.66667V4.77778H5.77778C4.79111 4.77778 4.00889 5.57778 4.00889 6.55556L4 19C4 19.9778 4.79111 20.7778 5.77778 20.7778H18.2222C19.2 20.7778 20 19.9778 20 19V6.55556C20 5.57778 19.2 4.77778 18.2222 4.77778ZM18.2222 19H5.77778V10.1111H18.2222V19ZM9.33333 13.6667H7.55556V11.8889H9.33333V13.6667ZM12.8889 13.6667H11.1111V11.8889H12.8889V13.6667ZM16.4444 13.6667H14.6667V11.8889H16.4444V13.6667ZM9.33333 17.2222H7.55556V15.4444H9.33333V17.2222ZM12.8889 17.2222H11.1111V15.4444H12.8889V17.2222ZM16.4444 17.2222H14.6667V15.4444H16.4444V17.2222Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7796_650585">
          <rect height="24" width="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
