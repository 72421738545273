import { useContext } from 'react';

import { PassageElContext } from './passageElContext';

export function usePassageEl() {
  const [value, setValue] = useContext(PassageElContext);

  return {
    passageEl: value,
    setPassageEl: setValue,
  };
}
