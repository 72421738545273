import React from 'react';

export default function OtherEdit({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.1186 6.8361L13.9558 5H4.53354C3.64568 5 2.9375 5.71847 2.9375 6.59064V19.4094C2.9375 20.2815 3.64568 21 4.53354 21H17.3415C18.2293 21 18.9375 20.2815 18.9375 19.4094V8.45805L17.0895 10.305V19.1639H4.78553V6.8361L12.1186 6.8361Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M18.7626 3.21126C18.4809 2.92958 18.0242 2.92958 17.7425 3.21126L9.70427 11.2495L8.9375 14L11.688 13.2332L19.7262 5.19498C20.0079 4.91329 20.0079 4.45659 19.7262 4.1749L18.7626 3.21126Z"
        fill="currentColor"
      />
    </svg>
  );
}
