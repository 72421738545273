export type SearchQuery =
  | {
      search?: string;
      searchFields?: string;
      expandSearch?: string;
      expandSearchFields?: string;
    }
  | [string, string]
  | null
  | undefined
  | false;

export default function combineSearch(...addSearchQueries: SearchQuery[]) {
  if (!addSearchQueries.length) return null;

  const search: string[] = [];
  const searchFields: string[] = [];
  const expandSearch: string[] = [];
  const expandSearchFields: string[] = [];

  // e.g. expandSearchField=clubCreator:clubCreatorSourceId:123
  const isExpand = (value: string) => (value.match(/:/g) || []).length === 2;

  for (const addSearchQuery of addSearchQueries) {
    if (addSearchQuery && typeof addSearchQuery !== 'boolean') {
      if (Array.isArray(addSearchQuery)) {
        const [addSearchQuerySearch, addSearchQuerySearchFields] =
          addSearchQuery;

        if (isExpand(addSearchQuerySearch)) {
          expandSearch.push(addSearchQuerySearch);
          expandSearchFields.push(addSearchQuerySearchFields);
        } else {
          search.push(addSearchQuerySearch);
          searchFields.push(addSearchQuerySearchFields);
        }
        continue;
      }

      if (addSearchQuery.search) {
        search.push(addSearchQuery.search);
      }
      if (addSearchQuery.searchFields) {
        searchFields.push(addSearchQuery.searchFields);
      }
      if (addSearchQuery.expandSearch) {
        expandSearch.push(addSearchQuery.expandSearch);
      }
      if (addSearchQuery.expandSearchFields) {
        expandSearchFields.push(addSearchQuery.expandSearchFields);
      }
    }
  }

  return {
    search: search.join(';'),
    searchFields: searchFields.join(';'),
    expandSearch: expandSearch.join(';'),
    expandSearchFields: expandSearchFields.join(';'),
  };
}
