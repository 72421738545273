import React from 'react';

export default function MainRank({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.44 3C20.7493 3 21 3.26661 21 3.59549V6.69019C21 7.01907 20.7493 7.28568 20.44 7.28568H8.66297C8.35368 7.28568 8.10296 7.01907 8.10296 6.69019V3.59549C8.10296 3.26661 8.35368 3 8.66297 3L20.44 3Z"
        fill="currentColor"
      />
      <path
        d="M18.0224 9.85716C18.3317 9.85716 18.5824 10.1238 18.5824 10.4526V13.5474C18.5824 13.8762 18.3317 14.1428 18.0224 14.1428L8.66361 14.1428C8.35432 14.1428 8.1036 13.8762 8.1036 13.5474V10.4526C8.1036 10.1238 8.35432 9.85716 8.66361 9.85716L18.0224 9.85716Z"
        fill="currentColor"
      />
      <path
        d="M13.1856 16.7143C13.4948 16.7143 13.7456 16.9809 13.7456 17.3098V20.4045C13.7456 20.7334 13.4948 21 13.1856 21H8.66312C8.35383 21 8.10311 20.7334 8.10311 20.4045V17.3098C8.10311 16.9809 8.35383 16.7143 8.66312 16.7143L13.1856 16.7143Z"
        fill="currentColor"
      />
      <path
        d="M3.18304 4.54595C3.26347 4.51056 3.34667 4.47075 3.43265 4.42651C3.52139 4.37933 3.60737 4.32919 3.69057 4.27611C3.77377 4.22008 3.85281 4.16257 3.92769 4.10359C4.00535 4.04166 4.07468 3.97678 4.1357 3.90895H4.56834V6.97453H3.94849V4.76713C3.86529 4.82612 3.77238 4.88067 3.66977 4.93081C3.56715 4.97799 3.46731 5.01928 3.37025 5.05467L3.18304 4.54595Z"
        fill="currentColor"
      />
      <path
        d="M4.95523 11.5712C4.95523 11.6833 4.93443 11.7909 4.89283 11.8941C4.85123 11.9974 4.79715 12.0976 4.73059 12.1949C4.66402 12.2893 4.58914 12.3807 4.50594 12.4692C4.42274 12.5577 4.34093 12.6417 4.2605 12.7214C4.2189 12.7626 4.17314 12.8098 4.12322 12.8629C4.07607 12.913 4.03031 12.9647 3.98593 13.0177C3.94156 13.0708 3.90135 13.121 3.86529 13.1681C3.83201 13.2124 3.81121 13.2492 3.80289 13.2787H5.04259V13.8317H3.12064C3.1151 13.7992 3.11232 13.758 3.11232 13.7078C3.11232 13.6577 3.11232 13.6223 3.11232 13.6017C3.11232 13.4601 3.13312 13.3303 3.17472 13.2124C3.2191 13.0944 3.27595 12.9853 3.34529 12.885C3.41462 12.7818 3.49227 12.686 3.57825 12.5975C3.667 12.509 3.75436 12.4205 3.84033 12.3321C3.90689 12.2642 3.96929 12.2008 4.02753 12.1419C4.08578 12.0799 4.13708 12.0209 4.18146 11.9649C4.22583 11.9059 4.2605 11.8484 4.28546 11.7924C4.31042 11.7334 4.3229 11.6744 4.3229 11.6154C4.3229 11.4857 4.28823 11.3943 4.2189 11.3412C4.14956 11.2881 4.06359 11.2616 3.96097 11.2616C3.88609 11.2616 3.81537 11.2748 3.74881 11.3014C3.68502 11.325 3.6254 11.3545 3.56993 11.3898C3.51723 11.4223 3.47147 11.4562 3.43265 11.4916C3.39382 11.524 3.3647 11.5506 3.34529 11.5712L3.03744 11.1112C3.15947 10.9902 3.30091 10.8914 3.46177 10.8148C3.6254 10.7351 3.80012 10.6953 3.98593 10.6953C4.15511 10.6953 4.30071 10.716 4.42274 10.7573C4.54477 10.7985 4.64461 10.8575 4.72227 10.9342C4.80269 11.0079 4.86093 11.0994 4.89699 11.2085C4.93581 11.3146 4.95523 11.4355 4.95523 11.5712Z"
        fill="currentColor"
      />
      <path
        d="M3.84033 20.7552C3.76545 20.7552 3.68641 20.7493 3.60321 20.7375C3.52001 20.7287 3.43958 20.7154 3.36193 20.6977C3.28427 20.68 3.21355 20.6608 3.14976 20.6402C3.08597 20.6195 3.03605 20.6004 3 20.5827L3.12064 20.0341C3.19275 20.0666 3.28427 20.102 3.39521 20.1403C3.50891 20.1757 3.64897 20.1934 3.81537 20.1934C4.00673 20.1934 4.14679 20.1551 4.23554 20.0784C4.32429 20.0017 4.36866 19.8985 4.36866 19.7687C4.36866 19.6891 4.35202 19.6227 4.31874 19.5697C4.28823 19.5136 4.24524 19.4694 4.18978 19.437C4.13431 19.4016 4.06775 19.378 3.99009 19.3662C3.91521 19.3514 3.83479 19.3441 3.74881 19.3441H3.50753V18.8132H3.78209C3.84311 18.8132 3.90135 18.8073 3.95681 18.7955C4.01505 18.7837 4.06636 18.7646 4.11074 18.738C4.15511 18.7085 4.18978 18.6702 4.21474 18.623C4.24247 18.5729 4.25634 18.5109 4.25634 18.4372C4.25634 18.3812 4.24524 18.3325 4.22306 18.2912C4.20087 18.2499 4.17175 18.216 4.1357 18.1895C4.10242 18.1629 4.0622 18.1438 4.01505 18.132C3.97068 18.1172 3.92492 18.1099 3.87777 18.1099C3.75852 18.1099 3.64758 18.129 3.54497 18.1674C3.44513 18.2057 3.35361 18.2529 3.2704 18.3089L3.04992 17.8268C3.09429 17.7973 3.1456 17.7663 3.20384 17.7339C3.26486 17.7014 3.33142 17.6719 3.40353 17.6454C3.47563 17.6188 3.5519 17.5967 3.63233 17.579C3.71553 17.5613 3.80289 17.5525 3.89441 17.5525C4.06359 17.5525 4.20919 17.5746 4.33122 17.6188C4.45602 17.6601 4.55864 17.7206 4.63906 17.8002C4.71949 17.8769 4.77912 17.9683 4.81795 18.0745C4.85677 18.1777 4.87619 18.2912 4.87619 18.4151C4.87619 18.536 4.84429 18.654 4.78051 18.769C4.71672 18.8811 4.63074 18.9666 4.52258 19.0256C4.67234 19.0904 4.78744 19.1878 4.86787 19.3175C4.95107 19.4443 4.99267 19.5977 4.99267 19.7776C4.99267 19.9191 4.97048 20.0504 4.92611 20.1713C4.88173 20.2892 4.8124 20.3925 4.7181 20.4809C4.62381 20.5665 4.50317 20.6343 4.35618 20.6844C4.21196 20.7316 4.04002 20.7552 3.84033 20.7552Z"
        fill="currentColor"
      />
    </svg>
  );
}
