import { useCallback } from 'react';
import { useAuth } from '@lib/web/apis';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useMessageInformation } from '@lib/web/thread/hooks/message/useMessageInformation';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { isChannelMuted } from '@lib/web/thread/utils/channelUtils';
import { streamDateToString } from '@lib/web/thread/utils/streamUtils';
import { compareAsc } from 'date-fns';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

export const useReadState = () => {
  const { member } = useAuth();
  const { getChannelInformation } = useChannelInformation();
  const { getMessageInformation } = useMessageInformation();

  const myMemberId = member?.memberId;

  const getChannelReadState = useCallback(
    (channel: Channel) => {
      const channelReadState = channel.state.read;
      const channelMyLastReadTime =
        myMemberId &&
        streamDateToString(channelReadState[myMemberId]?.last_read);

      const {
        firstMessageCreatedAt,
        lastMessageCreator,
        lastMessageCreatedAt,
      } = getChannelInformation(channel);

      /**
       * although channel will be read when user send the message,
       * but it has some delay to show in the client side,
       * to prevent ui from flashing, we will use lastMessageCreatedAt to mark the message as read
       */
      const myLastReadTime =
        myMemberId === lastMessageCreator
          ? lastMessageCreatedAt
          : channelMyLastReadTime;

      // lastMessageCreatedAt > myLastReadTime
      const isUnread =
        !isChannelMuted(channel) &&
        !!lastMessageCreatedAt &&
        !!myLastReadTime &&
        compareAsc(new Date(lastMessageCreatedAt), new Date(myLastReadTime)) ===
          1;

      // firstMessageCreatedAt > myLastReadTime
      const isNewThread =
        !isChannelMuted(channel) &&
        !!firstMessageCreatedAt &&
        !!myLastReadTime &&
        compareAsc(
          new Date(firstMessageCreatedAt),
          new Date(myLastReadTime)
        ) === 1;

      return {
        myLastReadTime,
        isUnread,
        isNewThread,
      };
    },
    [getChannelInformation, myMemberId]
  );

  const getMessageReadState = useCallback(
    (message: StreamMessage<StreamChatGenerics>, channel: Channel) => {
      const { messageCreator, messageCreatedAt } =
        getMessageInformation(message);
      const channelReadState = channel.state.read;
      const channelMyLastReadTime =
        myMemberId &&
        streamDateToString(channelReadState[myMemberId]?.last_read);

      /**
       * although message will be read when user send the message,
       * but it has some delay to show in the client side,
       * to prevent ui from flashing, we will use messageCreatedAt to mark the message as read
       */
      const myLastReadTime =
        myMemberId === messageCreator
          ? messageCreatedAt
          : channelMyLastReadTime;

      // messageCreatedAt > myLastReadTime
      const isUnread =
        !isChannelMuted(channel) &&
        !!messageCreatedAt &&
        !!myLastReadTime &&
        compareAsc(new Date(messageCreatedAt), new Date(myLastReadTime)) === 1;

      return {
        myLastReadTime,
        isUnread,
        isNewThread: isUnread,
      };
    },
    [getMessageInformation, myMemberId]
  );

  return {
    getChannelReadState,
    getMessageReadState,
  };
};
