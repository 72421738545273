import { getBlockInfoFromPos } from '@lib/web/composer';
import ParagraphBlock from '@lib/web/composer/TextComposer/components/blocks/ParagraphBlock';
import { NodeConfig } from '@tiptap/core';

/**
 * follow the behavior of cursor moving for image/file block in notion
 */
export const notionLikeFileImageKeyboardShortcuts: NodeConfig['addKeyboardShortcuts'] =
  function () {
    return {
      ArrowUp: () => {
        const currentBlockPos = this.editor.state.selection.$from.pos;
        const currentBlockInfo = getBlockInfoFromPos(
          this.editor.state.doc,
          currentBlockPos
        );
        const previousBlockInfo = getBlockInfoFromPos(
          this.editor.state.doc,
          currentBlockInfo.startPos - 2
        );

        if (previousBlockInfo.contentType.name !== this.name) return false;

        // set cursor to skip image/file block
        let targetBlockInfo = getBlockInfoFromPos(
          this.editor.state.doc,
          currentBlockInfo.startPos - 2
        );

        // when there are multiple image/file blocks, jump all of them
        while (targetBlockInfo.contentType.name === this.name) {
          if (targetBlockInfo.startPos <= 2) return true; // at the start of document

          targetBlockInfo = getBlockInfoFromPos(
            this.editor.state.doc,
            targetBlockInfo.startPos - 2
          );
        }

        this.editor.commands.setTextSelection(targetBlockInfo.startPos + 1);

        return true;
      },
      ArrowDown: () => {
        const currentBlockPos = this.editor.state.selection.$from.pos;
        const currentBlockInfo = getBlockInfoFromPos(
          this.editor.state.doc,
          currentBlockPos
        );
        const nextBlockInfo = getBlockInfoFromPos(
          this.editor.state.doc,
          currentBlockInfo.endPos + 2
        );

        if (nextBlockInfo.contentType.name !== this.name) return false;

        // set cursor to skip image/file block
        let targetBlockInfo = getBlockInfoFromPos(
          this.editor.state.doc,
          currentBlockInfo.endPos + 2
        );

        // when there are multiple image/file blocks, jump all of them
        while (targetBlockInfo.contentType.name === this.name) {
          if (targetBlockInfo.endPos + 2 >= this.editor.state.doc.content.size)
            return true; // at the end of document

          targetBlockInfo = getBlockInfoFromPos(
            this.editor.state.doc,
            targetBlockInfo.endPos + 2
          );
        }

        this.editor.commands.setTextSelection(targetBlockInfo.startPos + 1);

        return true;
      },
      Backspace: () => {
        const currentBlockPos = this.editor.state.selection.$from.pos;
        const currentBlockInfo = getBlockInfoFromPos(
          this.editor.state.doc,
          currentBlockPos
        );

        if (currentBlockInfo.startPos !== currentBlockPos - 1) return false; // not the start of block

        const previousBlockInfo = getBlockInfoFromPos(
          this.editor.state.doc,
          currentBlockInfo.startPos - 2
        );

        if (previousBlockInfo.contentType.name !== this.name) return false;

        // if this is not paragraph, we use default behavior because default behavior will not delete the image/file
        if (currentBlockInfo.contentType.name !== ParagraphBlock.node.name)
          return false;

        // set cursor to skip image/file block
        let targetBlockInfo = getBlockInfoFromPos(
          this.editor.state.doc,
          previousBlockInfo.startPos - 2
        );

        // when there are multiple image/file blocks, jump all of them
        while (targetBlockInfo.contentType.name === this.name) {
          if (targetBlockInfo.startPos <= 2) return true; // at the start of document

          targetBlockInfo = getBlockInfoFromPos(
            this.editor.state.doc,
            targetBlockInfo.startPos - 2
          );
        }
        this.editor.commands.deleteRange({
          from: currentBlockInfo.startPos - 2,
          to: currentBlockInfo.endPos,
        });
        this.editor.commands.insertContentAt(
          targetBlockInfo.endPos - 1,
          currentBlockInfo.contentNode.content.toJSON()
        );

        this.editor.commands.setTextSelection(targetBlockInfo.endPos - 1);

        return true; // Prevent deletion of the image/file block
      },
    };
  };
