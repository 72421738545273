import { useEffect, useRef, useState } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';

const useDimensionEl = (el?: Element | null) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        const { width, height } = entry.contentRect;
        setDimensions({ width, height });
      });
    });
    if (el) resizeObserverRef.current?.observe(el);
    return () => {
      if (el) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserverRef.current?.unobserve(el);
      }
      resizeObserverRef.current?.disconnect();
    };
  }, [el]);
  return dimensions;
};

export default useDimensionEl;
