import { createContext, ReactNode } from 'react';

import { PracticeLayoutContextValue } from '../types';

const initialValue: PracticeLayoutContextValue = {
  showKeyboard: false,
  isOpenTimer: false,
  redirectPage: null,
};

export const PracticeLayoutContext =
  createContext<PracticeLayoutContextValue>(initialValue);

export type PracticeLayoutContextProviderProps = {
  children: ReactNode;
} & { layout: PracticeLayoutContextValue };

export const PracticeLayoutContextProvider = ({
  children,
  layout,
}: PracticeLayoutContextProviderProps) => {
  return (
    <PracticeLayoutContext.Provider value={layout}>
      {children}
    </PracticeLayoutContext.Provider>
  );
};
