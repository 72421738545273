import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';

interface CloudfrontContextValue {
  country: string | null;
  timezone: string | null;
}

interface CloudfrontProviderProps {
  children?: ReactNode;
}

const initialCloudfrontHeaders: CloudfrontContextValue = {
  country: null,
  timezone: null,
};

export const CloudfrontContext = createContext<CloudfrontContextValue>(
  initialCloudfrontHeaders
);

export async function fetchCloudfrontData() {
  let country = '';
  let timezone = '';

  try {
    const headers = await fetch(
      `${process.env.API_URL}/func/cloudfront/headers`
    );

    const data = await headers.json();

    country = data.data.headers['cloudfront-viewer-country'] as string;
    timezone = data.data.headers['cloudfront-viewer-time-zone'] as string;
  } catch (err) {
    // console.error(err);
  }

  return {
    country,
    timezone,
  };
}

export const CloudfrontProvider: FC<CloudfrontProviderProps> = (props) => {
  const { children } = props;
  const [headers, setHeaders] = useState<CloudfrontContextValue>(
    initialCloudfrontHeaders
  );

  useEffect(() => {
    async function getData() {
      const res = await fetchCloudfrontData();
      setHeaders(res);
    }

    getData();
  }, []);

  return (
    <CloudfrontContext.Provider value={headers}>
      {children}
    </CloudfrontContext.Provider>
  );
};
