import React, { MouseEvent, TouchEvent, useState } from 'react';
import {
  alpha,
  ButtonBase,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { ResponsiveMenu } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { useIaItemStatus } from '@lib/ia/src/core/IaItemStatus/useIaItemStatus';

import {
  DisplayTableLayoutRow,
  DisplayTableLayoutSelectCell,
  DisplayTableLayoutSelectCellStatus,
  DisplayTableLayoutSelectOption,
  IaSelectCellValueChangedEvent,
} from '../../types';

export type IaDisplayTableSelectCellProps = {
  row: DisplayTableLayoutRow;
  cell: DisplayTableLayoutSelectCell;
};

const styles = {
  root: {
    px: 1,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    gap: 1,
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '4px',
    px: '6px',
    py: '2px',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    borderRadius: '4px',
  },
  selectOpen: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
  },
  selectDisabled: {
    opacity: 0.5,
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  circleOrSquare: {
    width: '14px',
    height: '14px',
    color: 'background.darker',
    backgroundColor: 'text.primary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '8px',
    fontWeight: 500,
  },
  circle: {
    borderRadius: '50%',
  },
  square: {
    borderRadius: '4px',
  },
};
type Option = DisplayTableLayoutSelectOption;

function SelectIcon({ icon }: { icon: Option['icon'] }) {
  if (!icon) return null;

  if (icon.type === 'icon') {
    return <Icon name={icon.value} width={16} height={16} />;
  }
  if (icon.type === 'circle') {
    return <Box sx={[styles.circleOrSquare, styles.circle]}>{icon.value}</Box>;
  }
  if (icon.type === 'square') {
    return <Box sx={[styles.circleOrSquare, styles.square]}>{icon.value}</Box>;
  }
  return null;
}

function Select({
  options,
  selectedOption,
  onOptionClick,
  disabled,
}: {
  options: Option[];
  selectedOption: Option;
  onOptionClick: (option: Option) => void;
  disabled?: boolean;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

  const handleSelectedOptionClick = (event: MouseEvent | TouchEvent) => {
    setMenuAnchorEl(event.currentTarget as HTMLElement);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleOptionClick = (option: Option) => {
    onOptionClick(option);
    setMenuOpen(false);
  };

  return (
    <>
      <ButtonBase
        sx={[
          styles.select,
          menuOpen && styles.selectOpen,
          !!disabled && styles.selectDisabled,
        ]}
        onClick={handleSelectedOptionClick}
        disabled={disabled}
      >
        <SelectIcon icon={selectedOption.icon} />
        <Typography variant="caption" sx={styles.label}>
          {selectedOption.label}
        </Typography>
      </ButtonBase>
      <ResponsiveMenu
        open={menuOpen}
        onClose={handleMenuClose}
        menuProps={{
          anchorEl: menuAnchorEl,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: -8,
            horizontal: 8,
          },
        }}
        sheetProps={{
          defaultContentHeight: options.length * 45,
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleOptionClick(option)}
          >
            {option.icon && (
              <ListItemIcon>
                <SelectIcon icon={option.icon} />
              </ListItemIcon>
            )}
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </ResponsiveMenu>
    </>
  );
}
export default function IaDisplayTableSelectCell({
  row,
  cell,
}: IaDisplayTableSelectCellProps) {
  const { options, optionClickAction, subOptionClickAction, disabled } = cell;
  const { getIaAction } = useIaAction();
  const { getItemStatus } =
    useIaItemStatus<DisplayTableLayoutSelectCellStatus>();

  const selectedOption = options.find(
    (o) => getItemStatus?.({ option: o, row, cell }).selected
  );
  const selectedSubOption = selectedOption?.subOptions?.find(
    (o) => getItemStatus?.({ option: o, row, cell }).selected
  );

  const handleOptionClick = (option: Option) => {
    getIaAction<IaSelectCellValueChangedEvent>(optionClickAction)?.action({
      option,
      row,
      cell,
    });
  };

  const handleSubOptionClick = (option: Option) => {
    getIaAction<IaSelectCellValueChangedEvent>(subOptionClickAction)?.action({
      option,
      row,
      cell,
    });
  };

  return (
    <Box sx={styles.root}>
      {selectedOption && (
        <Select
          options={options}
          disabled={disabled}
          selectedOption={selectedOption}
          onOptionClick={handleOptionClick}
        />
      )}
      {selectedOption?.subOptions && selectedSubOption && (
        <Select
          options={selectedOption.subOptions}
          disabled={disabled}
          selectedOption={selectedSubOption}
          onOptionClick={handleSubOptionClick}
        />
      )}
    </Box>
  );
}
