import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { SuggestionKeyDownProps } from '@tiptap/suggestion';

import { VariableOption } from './type';

export type CommandsHandler = {
  onKeyDown: (ev: SuggestionKeyDownProps) => boolean;
};

const styles = {
  root: {
    bgcolor: (theme: Theme) => theme.palette.text.primary,
    color: 'background.darker',
    py: '6px',
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    px: '12px',
    py: 0,
    height: 28,
    typography: 'body2',
    bgcolor: 'transparent',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.background.darker, 0.1),
    },
  },
  hovered: {
    bgcolor: (theme: Theme) => alpha(theme.palette.background.darker, 0.1),
  },
  title: {
    opacity: 0.5,
    px: '12px',
    py: '5.5px',
  },
};
const CommandsList = forwardRef<
  CommandsHandler,
  { items: VariableOption[]; command: (item: VariableOption) => void }
>(({ items, command }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    setSelectedIndex(0);
  }, [items]);

  const selectItem = useCallback(
    (index: number) => {
      const item = items[index];

      if (item) {
        command(item);
      }
    },
    [command, items]
  );
  const onKeyDown = useCallback(
    ({ event }: SuggestionKeyDownProps) => {
      const handleUp = () => {
        setSelectedIndex((st) => (st + items.length - 1) % items.length);
      };
      const handleDown = () => {
        setSelectedIndex((st) => (st + 1) % items.length);
      };

      const handleEnter = () => {
        selectItem(selectedIndex);
      };
      if (event.key === 'ArrowUp') {
        handleUp();
        event.stopPropagation();
        return true;
      }

      if (event.key === 'ArrowDown') {
        handleDown();
        event.stopPropagation();
        return true;
      }

      if (event.key === 'Enter') {
        handleEnter();
        event.stopPropagation();
        return true;
      }

      return false;
    },
    [items.length, selectItem, selectedIndex]
  );

  useImperativeHandle(
    ref,
    () => ({
      onKeyDown,
    }),
    [onKeyDown]
  );

  return (
    <Box ref={ref} sx={styles.root}>
      <Typography sx={styles.title} variant="caption">
        Number variable
      </Typography>
      {items.map((item, index) => (
        <Box
          sx={[styles.button, selectedIndex === index && styles.hovered]}
          component="button"
          key={index}
          onClick={() => selectItem(index)}
          role="button"
        >
          {item.title}
        </Box>
      ))}
    </Box>
  );
});

CommandsList.displayName = 'CommandsList';

export default CommandsList;
