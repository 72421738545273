import React from 'react';

export default function EmojiFood1({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 9.66668C2.5 5.75949 5.3619 2.5 9 2.5H15C18.6381 2.5 21.5 5.75949 21.5 9.66668V10.1667H2.5V9.66668ZM3.51767 9.16668H20.4823C20.2541 5.95226 17.8436 3.5 15 3.5H9C6.15642 3.5 3.74594 5.95226 3.51767 9.16668ZM3.9 11.5C3.72734 11.5 3.5 11.673 3.5 12C3.5 12.327 3.72734 12.5 3.9 12.5H20.1C20.2727 12.5 20.5 12.327 20.5 12C20.5 11.673 20.2727 11.5 20.1 11.5H3.9ZM2.5 12C2.5 11.2225 3.07855 10.5 3.9 10.5H20.1C20.9214 10.5 21.5 11.2225 21.5 12C21.5 12.7776 20.9214 13.5 20.1 13.5H3.9C3.07855 13.5 2.5 12.7776 2.5 12ZM18.8534 15.1649C18.3205 14.8873 17.7091 14.8873 17.1762 15.1649L17.0561 15.2275C16.2756 15.6339 15.3653 15.6161 14.5994 15.181L14.5779 15.1687C14.1015 14.898 13.5432 14.898 13.0668 15.1687C12.2841 15.6135 11.3505 15.6135 10.5678 15.1687C10.0913 14.898 9.53311 14.898 9.0567 15.1687C8.27394 15.6135 7.34038 15.6135 6.55762 15.1687C6.08121 14.898 5.52298 14.898 5.04656 15.1687L4.04403 15.7384C3.80394 15.8748 3.49871 15.7907 3.3623 15.5506C3.22588 15.3105 3.30993 15.0053 3.55002 14.8689L4.55255 14.2993C5.33531 13.8545 6.26887 13.8545 7.05163 14.2993C7.52804 14.57 8.08627 14.57 8.56269 14.2993C9.34544 13.8545 10.279 13.8545 11.0618 14.2993C11.5382 14.57 12.0964 14.57 12.5728 14.2993C13.3556 13.8545 14.2891 13.8545 15.0719 14.2993L15.0934 14.3115C15.5653 14.5796 16.1158 14.5897 16.5941 14.3405L16.7142 14.278C17.5367 13.8496 18.4929 13.8496 19.3153 14.278L20.4331 14.8602C20.678 14.9877 20.7731 15.2897 20.6456 15.5346C20.518 15.7795 20.2161 15.8746 19.9711 15.7471L18.8534 15.1649ZM2.5 18C2.5 17.1716 3.17157 16.5 4 16.5H20C20.8284 16.5 21.5 17.1716 21.5 18V19.8608C21.5 20.7152 20.8653 21.5 19.9747 21.5H4.02532C3.13466 21.5 2.5 20.7152 2.5 19.8608V18ZM4 17.5C3.72386 17.5 3.5 17.7238 3.5 18V19.8608C3.5 20.2647 3.78344 20.5 4.02532 20.5H19.9747C20.2166 20.5 20.5 20.2647 20.5 19.8608V18C20.5 17.7238 20.2761 17.5 20 17.5H4Z"
        fill="currentColor"
      />
      <path
        d="M9.2 6.26675C9.2 6.59812 8.93137 6.86675 8.6 6.86675C8.26863 6.86675 8 6.59812 8 6.26675C8 5.93538 8.26863 5.66675 8.6 5.66675C8.93137 5.66675 9.2 5.93538 9.2 6.26675Z"
        fill="currentColor"
      />
      <path
        d="M12.8006 6.26675C12.8006 6.59812 12.532 6.86675 12.2006 6.86675C11.8692 6.86675 11.6006 6.59812 11.6006 6.26675C11.6006 5.93538 11.8692 5.66675 12.2006 5.66675C12.532 5.66675 12.8006 5.93538 12.8006 6.26675Z"
        fill="currentColor"
      />
      <path
        d="M16.3992 6.26675C16.3992 6.59812 16.1306 6.86675 15.7992 6.86675C15.4678 6.86675 15.1992 6.59812 15.1992 6.26675C15.1992 5.93538 15.4678 5.66675 15.7992 5.66675C16.1306 5.66675 16.3992 5.93538 16.3992 6.26675Z"
        fill="currentColor"
      />
    </svg>
  );
}
