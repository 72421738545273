import React, { useMemo } from 'react';
import useMeasure from 'react-use-measure';
import { Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { ResizeObserver } from '@juggle/resize-observer';

import HeatMapBody from './components/HeatMapBody';
import HeatMapHeader from './components/HeatMapHeader';
import {
  NODE_GAP,
  NODE_GAP_MD,
  NODE_GAP_XS,
  NODE_WIDTH_MD,
  NODE_WIDTH_XS,
} from './constants';
import { HeatMapNode } from './types';

const styles = {
  root: {
    width: '100%',
  },
  container: {
    gap: NODE_GAP,
  },
  header: {
    pb: 1,
  },
};

export type HeatMapProps = {
  nodes: HeatMapNode[];
  nodesPerRow?: number;
};

export default function HeatMap({
  nodes,
  nodesPerRow: _nodesPerRow,
}: HeatMapProps) {
  const [ref, { width }] = useMeasure({ polyfill: ResizeObserver });
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const nodesPerRow = useMemo(() => {
    if (_nodesPerRow !== undefined) return _nodesPerRow;
    if (!width) return 0;

    /**
     * let's say the node is 26.6px width, and gap is 16px, we need to make sure it will not exceed container width,
     * and we want to calculate how many nodes (n) can fit in the container
     *     width < 26.6 * n + 16 * (n - 1)
     *     26.6n + 16n - 16 = width
     *     n = (width + 16) / (26.6 + 16)
     */
    const nodeWidth = mdUp ? NODE_WIDTH_MD : NODE_WIDTH_XS;
    const nodeGap = mdUp ? NODE_GAP_MD : NODE_GAP_XS;
    return Math.floor((width + nodeGap) / (nodeWidth + nodeGap));
  }, [_nodesPerRow, mdUp, width]);

  const containerSxProps: SxProps = {
    display: 'grid',
    gridTemplateColumns: {
      xs: `repeat(${nodesPerRow}, ${NODE_WIDTH_XS}px)`,
      md: `repeat(${nodesPerRow}, ${NODE_WIDTH_MD}px)`,
    },
  };
  return (
    <Box sx={styles.root} ref={ref}>
      <Box sx={[containerSxProps, styles.container, styles.header]}>
        <HeatMapHeader nodesPerRow={nodesPerRow} />
      </Box>
      <Box sx={[containerSxProps, styles.container]}>
        <HeatMapBody nodes={nodes} nodesPerRow={nodesPerRow} />
      </Box>
    </Box>
  );
}
