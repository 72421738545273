import { createContext, ReactNode, useMemo, useState } from 'react';

export type QuizAudioSourceContextValue = {
  source: { [key: string]: { src: string; progress: number } };
  onlyOnce: boolean;
  getAudioByKey: (key: string) => {
    src: string;
    progress: number;
  };
};

export type QuizAudioActionContextValue = {
  setAudioSrc: (key: string, src: string) => void;
  syncAudioProgress: (key: string, progress: number) => void;
};

export const QuizAudioSourceContext =
  createContext<QuizAudioSourceContextValue>({
    source: {},
    onlyOnce: false,
    getAudioByKey: () => ({ src: '', progress: 0 }),
  });

export const QuizAudioActionContext =
  createContext<QuizAudioActionContextValue>({
    setAudioSrc: () => {},
    syncAudioProgress: () => {},
  });

type QuizAudioProviderProps = {
  children: ReactNode;
  onlyOnce?: boolean;
};

export function QuizAudioProvider({
  children,
  onlyOnce = false,
}: QuizAudioProviderProps) {
  const [source, setSource] = useState<QuizAudioSourceContextValue['source']>(
    {}
  );
  const memoizedActionValue = useMemo(() => {
    const value = {
      setAudioSrc: (key: string, src: string) => {
        setSource((prevSource) => ({
          ...prevSource,
          [key]: {
            src,
            progress: prevSource[key]?.progress || 0,
          },
        }));
      },

      syncAudioProgress: (key: string, progress: number) => {
        let validProgress = progress;
        if (progress < 0) validProgress = 0;
        else if (progress > 1) validProgress = 1;

        setSource((prevSource) => ({
          ...prevSource,
          [key]: { ...prevSource[key], progress: validProgress },
        }));
      },
    };
    return value;
  }, []);

  const memoizedSourceValue = useMemo(() => {
    return { onlyOnce, source, getAudioByKey: (key: string) => source[key] };
  }, [onlyOnce, source]);

  return (
    <QuizAudioActionContext.Provider value={memoizedActionValue}>
      <QuizAudioSourceContext.Provider value={memoizedSourceValue}>
        {children}
      </QuizAudioSourceContext.Provider>
    </QuizAudioActionContext.Provider>
  );
}
