import { useState } from 'react';
import { IaAvailableAction } from '@lib/ia/src/core/IaAction';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';

import useSuggestionMembersLayoutAction from './useSuggestionMembersLayoutAction';
import useSuggestionMembersLayoutConfig from './useSuggestionMembersLayoutConfig';

export default function useSuggestionMembersLayout(
  virtualRouter?: VirtualRouter
): [IaLayoutConfig[], Record<string, IaAvailableAction>] {
  const [stateMap, setStateMap] = useState<Record<string, 'loading' | 'sent'>>(
    {}
  );

  const setState = (id: string, state?: 'loading' | 'sent') => {
    setStateMap((prev) => {
      const { [id]: current, ...rest } = prev;
      if (state) {
        return {
          ...rest,
          [id]: state,
        };
      }
      return { ...rest };
    });
  };

  const layouts = useSuggestionMembersLayoutConfig(stateMap);
  const availableActions = useSuggestionMembersLayoutAction({
    virtualRouter,
    setState,
  });

  return [layouts, availableActions];
}
