import { BlockSchema } from '@blocknote/core';
import { TipTapNode } from '@blocknote/core/src/extensions/Blocks/api/blockTypes';
import { HeadingBlock } from '@lib/web/composer/TextComposer/components/blocks/HeadingBlock';
import {
  LegacyMaterialBlock,
  legacyMaterialBlockDefaultProps,
} from '@lib/web/composer/TextComposer/components/blocks/LegacyMaterialBlock';
import {
  LegacyParagraphBlock,
  legacyParagraphBlockDefaultProps,
} from '@lib/web/composer/TextComposer/components/blocks/LegacyParagraphBlock';
import ParagraphBlock from '@lib/web/composer/TextComposer/components/blocks/ParagraphBlock/ParagraphBlock';
import PatchBlockContainerDelete from '@lib/web/composer/TextComposer/components/blocks/PatchBlockContainerDelete';
import StepBlock from '@lib/web/composer/TextComposer/components/blocks/StepBlock';
import SubtitleBlock from '@lib/web/composer/TextComposer/components/blocks/SubtitleBlock/SubtitleBlock';
import {
  UnsupportedBlock,
  UnsupportedBlockDefaultProps,
} from '@lib/web/composer/TextComposer/components/blocks/UnsupportedBlock';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';

export const basicComposerSchema = {
  [BasicBlockTypes.Paragraph]: ParagraphBlock,
  [BasicBlockTypes.Heading]: HeadingBlock,
  [BasicBlockTypes.Subtitle]: SubtitleBlock,
  [BasicBlockTypes.Step]: StepBlock,
  [BasicBlockTypes.Unsupported]: {
    propSchema: UnsupportedBlockDefaultProps,
    node: UnsupportedBlock as TipTapNode<'unsupported', false>,
  },
  [BasicBlockTypes.LegacyParagraph]: {
    propSchema: legacyParagraphBlockDefaultProps,
    node: LegacyParagraphBlock as TipTapNode<'legacy-paragraph', false>,
  },
  [BasicBlockTypes.LegacyMaterial]: {
    propSchema: legacyMaterialBlockDefaultProps,
    node: LegacyMaterialBlock as TipTapNode<'legacy-material', false>,
  },
  patchBlockContainerDelete: {
    propSchema: {},
    node: PatchBlockContainerDelete as TipTapNode<
      'patch-block-container-delete',
      false
    >,
  },
} satisfies BlockSchema;

export type BasicComposerSchema = typeof basicComposerSchema;
