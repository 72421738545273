import React from 'react';

export default function TextEditorUnderline({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.5 11C8.5 12.93 10.07 14.5 12 14.5C13.93 14.5 15.5 12.93 15.5 11V3H18V11C18 14.31 15.31 17 12 17C8.69 17 6 14.31 6 11V3H8.5V11Z"
        fill="currentColor"
      />
      <path d="M19 21H5V19H19V21Z" fill="currentColor" />
    </svg>
  );
}
