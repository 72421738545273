import React from 'react';

export default function ActionSearch({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.78455 3.58998C8.7249 3.20047 9.73275 3 10.7506 3C11.7684 3 12.7762 3.20047 13.7166 3.58998C14.6569 3.97948 15.5113 4.55038 16.231 5.27009C16.9508 5.9898 17.5217 6.84421 17.9112 7.78455C18.3007 8.7249 18.5011 9.73275 18.5011 10.7506C18.5011 11.7684 18.3007 12.7762 17.9112 13.7166C17.6499 14.3473 17.307 14.9394 16.8923 15.4781L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L15.4782 16.8924C14.1304 17.9298 12.4699 18.5011 10.7506 18.5011C8.69499 18.5011 6.7236 17.6846 5.27009 16.231C3.81658 14.7775 3 12.8061 3 10.7506C3 8.69499 3.81658 6.7236 5.27009 5.27009C5.9898 4.55038 6.84421 3.97948 7.78455 3.58998ZM10.7506 5C9.99539 5 9.24761 5.14874 8.54992 5.43774C7.85223 5.72673 7.21829 6.15031 6.6843 6.6843C5.60586 7.76274 5 9.22542 5 10.7506C5 12.2757 5.60586 13.7384 6.6843 14.8168C7.76274 15.8953 9.22542 16.5011 10.7506 16.5011C12.2757 16.5011 13.7384 15.8953 14.8168 14.8168C15.3508 14.2828 15.7744 13.6489 16.0634 12.9512C16.3524 12.2535 16.5011 11.5057 16.5011 10.7506C16.5011 9.99539 16.3524 9.24761 16.0634 8.54992C15.7744 7.85223 15.3508 7.21829 14.8168 6.6843C14.2828 6.15031 13.6489 5.72673 12.9512 5.43774C12.2535 5.14874 11.5057 5 10.7506 5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
