import React from 'react';

export default function EmojiCommon({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.9971 21C11.591 21 11.185 20.8438 10.8727 20.5393L3.46264 13.1284C2.84579 12.5115 2.84579 11.5041 3.46264 10.8794L10.8727 3.46855C11.1694 3.1718 11.5676 3 11.9971 3C12.4265 3 12.8169 3.16399 13.1215 3.46855L20.5315 10.8794C20.8282 11.1761 21 11.5744 21 12.0039C21 12.4256 20.836 12.8239 20.5315 13.1284L13.1215 20.5393C12.8091 20.8516 12.4031 21 11.9971 21ZM4.45429 11.8712C4.38401 11.9414 4.38401 12.0586 4.45429 12.1289L11.8643 19.5397C11.9346 19.61 12.0517 19.61 12.122 19.5397L19.532 12.1289C19.5789 12.082 19.5867 12.0351 19.5867 11.9961C19.5867 11.9649 19.5789 11.9102 19.532 11.8633L12.122 4.45249C12.0752 4.40564 12.0283 4.39783 11.9893 4.39783C11.9502 4.39783 11.9034 4.40564 11.8565 4.45249L4.45429 11.8712Z"
        fill="currentColor"
      />
    </svg>
  );
}
