import { useContext, useMemo } from 'react';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import {
  ReactionTarget,
  TableLayoutPluginContext,
} from '../../../contexts/tableLayoutPluginContext';
import { TableLayoutReactionCell } from '../../../types';

import { IaTableReactionActionValue } from './types';

export default function useReactionActions() {
  const [, setPluginValue] = useContext(TableLayoutPluginContext);
  const { getIaAction } = useIaAction();

  return useMemo(
    () => ({
      click: (
        target: ReactionTarget[],
        value: {
          id: string;
          name: string;
          code: string;
        } | null,
        { action }: { action?: string; disableAutoClose?: boolean }
      ) => {
        if (!target.length) return;

        const isBatch = target.length > 1;
        const globalActionValue = action;
        const cellActionValue = target[0].cell?.changeAction;

        const batchAction =
          getIaAction<IaTableReactionActionValue>(globalActionValue)
            ?.batchAction ||
          getIaAction<IaTableReactionActionValue>(cellActionValue)?.batchAction;

        const singleAction =
          getIaAction<IaTableReactionActionValue>(globalActionValue)?.action ||
          getIaAction<IaTableReactionActionValue>(cellActionValue)?.action;

        const updatedTarget = target
          .filter((item) => item.id)
          .map((item) => ({
            ...item,
            id: item.id as string,
            cell: { ...item.cell, value } as TableLayoutReactionCell,
          }));

        if (isBatch && batchAction) {
          batchAction(updatedTarget.map((item) => ({ id: item.id, value })));
        } else if (singleAction) {
          for (const item of updatedTarget) {
            singleAction({ id: item.id, value });
          }
        }

        setPluginValue((st) => ({
          ...st,
          reaction: {
            ...st.reaction,
            target: updatedTarget,
          },
        }));
      },
      hover: (
        anchorEl: Element | null | undefined,
        value: string, // action value
        items: {
          id: string;
          cell: TableLayoutReactionCell;
        }[],
        options?: {
          menuContainer?: Element | null | undefined;
          closeOnBlur?: boolean;
          disableAutoClose?: boolean;
        }
      ) => {
        setPluginValue((st) => ({
          ...st,
          reaction: {
            anchorEl,
            open: true,
            target: items,
            action: value,
            options,
          },
        }));
      },
    }),
    [getIaAction, setPluginValue]
  );
}
