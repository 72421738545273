import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  OtherError as OtherErrorIcon,
  OtherWarning as OtherWarningIcon,
} from '@front/icon';

export type ErrorMessageItemProps = {
  type: 'error' | 'warning';
  message: string;
  messageOnly?: boolean;
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  iconTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
};

const byTypeValues: Record<ErrorMessageItemProps['type'], any> = {
  error: {
    styles: {
      color: 'error.dark',
    },
    icon: <OtherErrorIcon width={12} height={12} />,
    titleKey: 'Error',
  },
  warning: {
    styles: {
      color: 'warning.dark',
    },
    icon: <OtherWarningIcon width={12} height={12} />,
    titleKey: 'Warning',
  },
};

export default function ErrorMessageItem({
  type,
  message,
  messageOnly = false,
}: ErrorMessageItemProps) {
  const { t } = useTranslation('editor'); // XXX: for ui only component, we might need to inject the translation(or title) from outside
  const value = byTypeValues[type];

  return (
    <Box sx={[styles.root, value.styles]}>
      {!messageOnly && (
        <Box sx={styles.iconTitle}>
          {value.icon}
          <Typography variant="caption" fontWeight={700}>
            {t(value.titleKey)}
          </Typography>
        </Box>
      )}
      <Typography variant="caption">{message}</Typography>
    </Box>
  );
}
