import { useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { TestInfinity as TestInfinityIcon } from '@front/icon';
import { useAiTokenBalance } from '@lib/web/apis';

export default function useMyAiTokens() {
  const { data: aiTokenBalance, isLoading } = useAiTokenBalance();
  return useMemo(() => {
    const tokens = aiTokenBalance?.data.aiTokenTotalAmount || 0;
    const hasInfinityTokens = tokens === -1;
    const tokensLoading = isLoading;

    return {
      tokens,
      tokensLoading,
      hasInfinityTokens,
      renderDisplayTokens: tokensLoading ? (
        <Skeleton width={20} />
      ) : hasInfinityTokens ? (
        <TestInfinityIcon width={16} height={16} />
      ) : tokens > 999 ? (
        <span>999+</span>
      ) : (
        <span>{tokens}</span>
      ),
      hasEnoughTokens: (amount: number) =>
        hasInfinityTokens || tokens >= amount,
    };
  }, [aiTokenBalance, isLoading]);
}
