import React from 'react';

export default function OtherShoppingCart({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.4 17.4C7.41 17.4 6.609 18.21 6.609 19.2C6.609 20.19 7.41 21 8.4 21C9.39 21 10.2 20.19 10.2 19.2C10.2 18.21 9.39 17.4 8.4 17.4ZM3 3V4.8H4.8L8.04 11.631L6.825 13.836C6.681 14.088 6.6 14.385 6.6 14.7C6.6 15.69 7.41 16.5 8.4 16.5H19.2V14.7H8.778C8.652 14.7 8.553 14.601 8.553 14.475L8.58 14.367L9.39 12.9H16.095C16.77 12.9 17.364 12.531 17.67 11.973L20.892 6.132C20.964 6.006 21 5.853 21 5.7C21 5.205 20.595 4.8 20.1 4.8H6.789L5.943 3H3ZM17.4 17.4C16.41 17.4 15.609 18.21 15.609 19.2C15.609 20.19 16.41 21 17.4 21C18.39 21 19.2 20.19 19.2 19.2C19.2 18.21 18.39 17.4 17.4 17.4Z"
        fill="currentColor"
      />
    </svg>
  );
}
