import React from 'react';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export type SimpleTooltipProps = TooltipProps;

export default function SimpleTooltip({ ...rest }: SimpleTooltipProps) {
  return (
    <Tooltip
      placement="bottom-start"
      enterDelay={100}
      enterTouchDelay={0}
      slotProps={{
        popper: {
          sx: {
            pointerEvents: 'none',
            userSelect: 'none',
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              {
                marginTop: '8px',
              },
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
              {
                marginBottom: '8px',
              },
            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
              {
                marginLeft: '8px',
              },
            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
              {
                marginRight: '8px',
              },
          },
        },
      }}
      {...rest}
    />
  );
}
