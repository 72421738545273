import { ReactNode, useCallback, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useThreadLocationDisplay } from '@app/web/src/components/AppThreadProvider/hooks/useThreadLocationDisplay';
import { useAppSelector } from '@app/web/src/hooks/redux';
import useGetThreadComposerCustomProps from '@app/web/src/hooks/utils/useGetThreadComposerCustomProps';
import { selectCurrentQuestion } from '@app/web/src/reducers/quizReducer/selector';
import { SharedUserContext } from '@lib/web/contexts';
import {
  composerBlocksToHtml,
  isBlockHtmlEmpty,
} from '@lib/web/editor/EditorThreadTextComposer';
import { ThreadCurrentLocationProvider, ThreadProvider } from '@lib/web/thread';
import { ThreadComposerCustomContextProvider } from '@lib/web/thread/contexts/threadComposerCustomContext';
import { LocationDetail } from '@lib/web/thread/types';

import useQuizContentEnricher from '../../widgets/QuizPage/hooks/useQuizContentEnricher';

export type AppThreadProviderProps = {
  children: ReactNode;
};

export default function AppThreadProvider({
  children,
}: AppThreadProviderProps) {
  const { pathname, query } = useRouter();
  const { getThreadLocationDisplay } = useThreadLocationDisplay();
  const { getThreadComposerCustomProps } = useGetThreadComposerCustomProps();
  const currentQuestion = useAppSelector((st) =>
    selectCurrentQuestion(st.quiz)
  );
  const { contentEnricher } = useQuizContentEnricher();

  const sharerData = useContext(SharedUserContext);

  const currentLocationDetail: LocationDetail = useMemo(() => {
    return {
      location: pathname,
      locationOwnerId: sharerData.memberId,
      query,
    };
  }, [pathname, query, sharerData.memberId]);

  const currentQuestionContentEnricher = useCallback(
    (content: string) =>
      contentEnricher({ content, question: currentQuestion }),
    [contentEnricher, currentQuestion]
  );

  return (
    <ThreadProvider getThreadLocationDisplay={getThreadLocationDisplay}>
      <ThreadCurrentLocationProvider
        defaultLocationDetail={currentLocationDetail}
      >
        <ThreadComposerCustomContextProvider
          getThreadComposerCustomProps={getThreadComposerCustomProps}
          composerBlocksToHtml={composerBlocksToHtml}
          isBlockHtmlEmpty={isBlockHtmlEmpty}
          contentEnricher={currentQuestionContentEnricher}
        >
          {children}
        </ThreadComposerCustomContextProvider>
      </ThreadCurrentLocationProvider>
    </ThreadProvider>
  );
}
