import React from 'react';

export default function OtherCalendarDay({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.4444 12.9H12V17.4H16.4444V12.9ZM15.5556 3V4.8H8.44444V3H6.66667V4.8H5.77778C4.79111 4.8 4.00889 5.61 4.00889 6.6L4 19.2C4 20.19 4.79111 21 5.77778 21H18.2222C19.2 21 20 20.19 20 19.2V6.6C20 5.61 19.2 4.8 18.2222 4.8H17.3333V3H15.5556ZM18.2222 19.2H5.77778V9.3H18.2222V19.2Z"
        fill="currentColor"
      />
    </svg>
  );
}
