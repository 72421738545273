import React from 'react';

export default function OtherFastRewind({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20 18L11.5 12L20 6V18ZM11 6V18L2.5 12L11 6Z"
        fill="currentColor"
      />
    </svg>
  );
}
