import { useBaseRightPanel } from '@front/ui';

import HighlightPanel from './components/HighlightPanel';
import LineMarkerPanel from './components/LineMarkerPanel';
import VariablePanel from './components/VariablePanel';
import { TextComposerPanelKeys, TextComposerPanelParams } from './panel';

export default function TextComposerPanels() {
  const { rightPanelTarget } = useBaseRightPanel<TextComposerPanelParams>();
  return (
    <>
      {rightPanelTarget === TextComposerPanelKeys.TextComposerLineMarker && (
        <LineMarkerPanel />
      )}
      {rightPanelTarget === TextComposerPanelKeys.TextComposerHighlight && (
        <HighlightPanel />
      )}
      {rightPanelTarget === TextComposerPanelKeys.TextComposerVariable && (
        <VariablePanel />
      )}
    </>
  );
}
