import React from 'react';

export default function OtherGroupBuy({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 2C12.5837 2 13.1616 2.11701 13.7008 2.34435C14.24 2.5717 14.73 2.90492 15.1427 3.32499C15.5554 3.74507 15.8828 4.24377 16.1061 4.79262C16.3295 5.34148 16.4444 5.92973 16.4444 6.52381V8.33333H19.1111C19.3469 8.33333 19.573 8.42866 19.7397 8.59833C19.9064 8.76801 20 8.99814 20 9.2381V20.0952C20 20.3352 19.9064 20.5653 19.7397 20.735C19.573 20.9047 19.3469 21 19.1111 21H4.88889C4.65314 21 4.42705 20.9047 4.26035 20.735C4.09365 20.5653 4 20.3352 4 20.0952V9.2381C4 8.99814 4.09365 8.76801 4.26035 8.59833C4.42705 8.42866 4.65314 8.33333 4.88889 8.33333H7.55556V6.52381C7.55556 5.32402 8.02381 4.17337 8.8573 3.32499C9.6908 2.47661 10.8213 2 12 2ZM16.4444 11.0476H14.6667V11.9524C14.6671 12.1829 14.7541 12.4046 14.9097 12.5721C15.0653 12.7396 15.2778 12.8403 15.5039 12.8537C15.73 12.8671 15.9526 12.7922 16.1262 12.6441C16.2999 12.4961 16.4115 12.2863 16.4382 12.0573L16.4444 11.9515V11.0467V11.0476ZM9.33333 11.0476H7.55556V11.9524C7.55603 12.1829 7.64294 12.4046 7.79854 12.5721C7.95414 12.7396 8.16669 12.8403 8.39279 12.8537C8.6189 12.8671 8.84149 12.7922 9.01514 12.6441C9.18878 12.4961 9.30036 12.2863 9.32711 12.0573L9.33333 11.9524V11.0476ZM12 3.80952C11.32 3.80947 10.6656 4.07387 10.1707 4.54864C9.67586 5.02341 9.3779 5.67269 9.33778 6.36367L9.33333 6.52381V8.33333H14.6667V6.52381C14.6667 5.83148 14.4068 5.1653 13.9402 4.66157C13.4736 4.15785 12.8355 3.85466 12.1564 3.81405L12 3.80952Z"
        fill="currentColor"
      />
    </svg>
  );
}
