import { Box, BoxProps } from '@mui/material';
import { keyframes } from '@mui/material/styles';

const dotAnimation = keyframes`
  0% { background-color: grey; }
  20% { background-color: white; }
  40% { background-color: grey; }
  60% { background-color: grey; }
  80% { background-color: grey; }
  100% { background-color: grey; }
`;

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  dot: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    backgroundColor: 'grey',
    animation: `${dotAnimation} 2.5s infinite`,
    mx: 0.25,
  },
};

type DotLoadingIconProps = {
  dotCount?: number;
  sx?: BoxProps['sx'];
};

export default function DotLoadingIcon({
  dotCount = 3,
  sx,
}: DotLoadingIconProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <Box sx={[styles.root, ...sxProps]}>
      {Array.from({ length: dotCount }, (_, i) => i).map((i) => (
        <Box
          key={i}
          sx={[
            styles.dot,
            {
              animation: `${dotAnimation} ${dotCount * 500}ms infinite`,
              animationDelay: `${i * 500}ms`,
            },
          ]}
        />
      ))}
    </Box>
  );
}
