import { useContext, useMemo } from 'react';

import { BaseLayoutContext, BaseLayoutDispatchContext } from '../contexts';
import { BaseLayoutActionTypes } from '../contexts/type';

export default function useBaseNavbar() {
  const { mainNavOpened } = useContext(BaseLayoutContext);
  const dispatch = useContext(BaseLayoutDispatchContext);

  const memoizedFunction = useMemo(() => {
    const openMainNav = () => {
      dispatch({
        type: BaseLayoutActionTypes.OPEN_MAIN_NAV,
      });
    };

    const closeMainNav = () => {
      dispatch({
        type: BaseLayoutActionTypes.CLOSE_MAIN_NAV,
      });
    };

    return {
      openMainNav,
      closeMainNav,
    };
  }, [dispatch]);

  return {
    mainNavOpened,
    ...memoizedFunction,
  };
}
