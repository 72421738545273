import React from 'react';

export default function PropertyTypeCheckbox({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 6.6C3 4.61177 4.61177 3 6.6 3H17.4C19.3882 3 21 4.61177 21 6.6V17.4C21 19.3882 19.3882 21 17.4 21H6.6C4.61177 21 3 19.3882 3 17.4V6.6ZM16.7283 10.1816C17.0798 9.83014 17.0798 9.26029 16.7283 8.90882C16.3768 8.55735 15.807 8.55735 15.4555 8.90882L10.8245 13.5398L9.00249 11.414C8.67901 11.0367 8.11084 10.9929 7.73344 11.3164C7.35605 11.6399 7.31235 12.2081 7.63583 12.5855L10.0904 15.4491C10.2535 15.6394 10.4887 15.7531 10.7392 15.7627C10.9897 15.7724 11.2328 15.6771 11.4101 15.4998L16.7283 10.1816Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
