import React, { forwardRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

const LAYOUT_SIZE = {
  sm: 836,
  md: 876,
};

export type BaseLayoutContainerProps = BoxProps & {
  fullWidth?: boolean;
  size?: keyof typeof LAYOUT_SIZE;
};

const styles = {
  root: {
    px: 2.5,
    width: '100%',
  },
  getLayoutSx: (size = LAYOUT_SIZE.sm) => ({
    maxWidth: { md: size },
    mx: { md: 'auto' },
  }),
};

const BaseLayoutContainer = forwardRef(
  (
    {
      sx,
      children,
      fullWidth = false,
      size = 'sm',
      ...rest
    }: BaseLayoutContainerProps,
    ref
  ) => {
    const sxProps = Array.isArray(sx) ? sx : [sx];
    const layoutSx = styles.getLayoutSx(LAYOUT_SIZE[size]);
    return (
      <Box
        ref={ref}
        sx={[styles.root, !fullWidth && layoutSx, ...sxProps]}
        {...rest}
      >
        {children}
      </Box>
    );
  }
);

export default BaseLayoutContainer;
