import { KeyboardShortcutCommand, Node } from '@tiptap/core';

export const PreventPressTabSink = Node.create({
  name: 'PreventPressTabSink',
  addKeyboardShortcuts(): { [p: string]: KeyboardShortcutCommand } {
    /**
     * @blocknote/core/src/extensions/Blocks/nodes/BlockContainer.ts:543
     *      Tab: () => {
     *         this.editor.commands.sinkListItem("blockContainer");
     *         return true;
     *       },
     *
     * blocknote default has a behavior, which is to sink List item when press tab,
     * currently we don't have list item, so we use a customize extension to swallow this keyboard event
     * (return true will prevent continue processing other event)
     */
    return {
      Tab: () => true,
    };
  },
});
