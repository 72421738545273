import React from 'react';

export default function MainSetGoal({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.54428 12.0001C4.54428 16.2157 7.96175 19.6332 12.1774 19.6332C16.3931 19.6332 19.8105 16.2157 19.8105 12.0001C19.8105 7.78441 16.3931 4.36694 12.1774 4.36694C7.96175 4.36694 4.54428 7.78441 4.54428 12.0001ZM18.2839 12.0001C18.2839 15.3726 15.5499 18.1066 12.1774 18.1066C8.80488 18.1066 6.0709 15.3726 6.0709 12.0001C6.0709 8.62755 8.80488 5.89357 12.1774 5.89357C15.5499 5.89357 18.2839 8.62755 18.2839 12.0001Z"
        fill="currentColor"
      />
      <path
        d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
        fill="currentColor"
      />
      <path
        d="M6.97057 12.0592C6.97057 14.935 9.30186 17.2663 12.1777 17.2663C15.0535 17.2663 17.3848 14.935 17.3848 12.0592C17.3848 9.18335 15.0535 6.85205 12.1777 6.85205C9.30186 6.85205 6.97057 9.18335 6.97057 12.0592ZM15.8226 12.0592C15.8226 14.0722 14.1907 15.7041 12.1777 15.7041C10.1646 15.7041 8.5327 14.0722 8.5327 12.0592C8.5327 10.0461 10.1646 8.41418 12.1777 8.41418C14.1907 8.41418 15.8226 10.0461 15.8226 12.0592Z"
        fill="currentColor"
      />
    </svg>
  );
}
