import React from 'react';

export default function ActionEnter({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21 7.12742V13.8919C21 14.1909 20.8884 14.4777 20.6898 14.6891C20.4912 14.9005 20.2218 15.0193 19.9409 15.0193H6.60637L8.54458 17.0825C8.74185 17.2937 8.85257 17.5795 8.85257 17.8773C8.85257 18.1752 8.74185 18.4609 8.54458 18.6722C8.34614 18.8821 8.0777 19 7.79789 19C7.51808 19 7.24964 18.8821 7.0512 18.6722L3.30187 14.6924C3.20544 14.5852 3.12986 14.4587 3.07945 14.3203C2.97352 14.0458 2.97352 13.738 3.07945 13.4635C3.12986 13.3251 3.20544 13.1987 3.30187 13.0914L7.0512 9.11167C7.25382 8.92697 7.51444 8.83045 7.781 8.84141C8.04756 8.85237 8.30042 8.97 8.48904 9.17079C8.67767 9.37157 8.78817 9.64073 8.79847 9.92447C8.80876 10.2082 8.7181 10.4856 8.54458 10.7013L6.60637 12.7645H18.8817V7.12742C18.8817 6.82841 18.9933 6.54164 19.1919 6.33021C19.3906 6.11878 19.66 6 19.9409 6C20.2218 6 20.4912 6.11878 20.6898 6.33021C20.8884 6.54164 21 6.82841 21 7.12742Z"
        fill="currentColor"
      />
    </svg>
  );
}
