import { useContext, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { alpha, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import useAuthLinks from '@app/web/src/hooks/utils/useAuthLinks';
import { StateDataType } from '@app/web/src/types/claimAccount';
import {
  decodeData,
  encodeData,
  getTimestampId,
} from '@app/web/src/utils/backend';
import { GuestContext } from '@front/helper';
import {
  CryptoCurrencyAhaAlt as CryptoCurrencyAhaAltIcon,
  ProfileLogin as ProfileLoginIcon,
  TextEditorCheck as TextEditorCheckIcon,
} from '@front/icon';
import {
  EmphasizeButton,
  MaskIcon,
  PopupActions,
  PopupOverlay,
  PropertyType,
  SquareAvatar,
  TextField,
} from '@front/ui';
import { apis, setClientToken, useAuth } from '@lib/web/apis';
import { useClearRoute, useCurrentIaClub } from '@lib/web/hooks';
import { call, emailRegex, getClubIconPath } from '@lib/web/utils';

const styles = {
  main: {
    pt: 2,
    maxWidth: { sm: 388 },
    pointerEvents: 'auto',
  },
  note: {
    mt: 2,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    pointerEvents: 'auto',
    a: {
      color: 'primary.light',
    },
  },
};
function ClaimAccountBlocker({
  onContinue,
  club,
}: {
  onContinue: (ev: CreateGuestAccountRes['memberLogin']) => void;
  club?: GetIaClubProfileCenterDefaultViewRes | null;
}) {
  const router = useRouter();
  const state = router.query.state as string;
  const stateData = decodeData<StateDataType>(state);
  const { t } = useTranslation('profile');

  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = useState(stateData?.receiverEmail || '');

  const { loginUrl } = useAuthLinks();

  const checkValue = (text: string) => {
    const email = text.trim();

    if (!email) {
      setErrorMessage(t('claim.blocker.email.required'));
      return false;
    }

    if (!emailRegex.test(email)) {
      setErrorMessage(t('claim.blocker.email.format'));
      return;
    }

    return true;
  };

  const handleContinue = async () => {
    setIsSubmitted(true);
    setIsSubmitting(true);

    if (!checkValue(value)) {
      setIsSubmitting(false);
      return;
    }

    const newState = {
      ...stateData,
      requestId: getTimestampId(0, 0),
    };

    const params = {
      state: encodeData(newState),
      name: value.trim(),
    };

    const [res, err] = await call(apis.auth.loginByGuest(params));
    if (res && res.data) {
      onContinue(res.data.memberLogin);
    } else {
      if (err?.statusCode === 409) {
        setErrorMessage(t('claim.blocker.email.unique'));
      } else {
        setErrorMessage(t('claim.blocker.email.failed'));
      }
    }
    setIsSubmitting(false);
  };

  const handleChange = (text: string) => {
    setErrorMessage('');
    setValue(text);
  };

  const handleBlur = () => {
    if (isSubmitted) {
      checkValue(value);
    }
  };

  const title = club
    ? t('claim.blocker.title.withClub', { club: club.clubName })
    : t('claim.blocker.title.default');

  const size = mdDown ? 100 : 120;
  const avatar = club ? (
    <SquareAvatar
      src={club.clubLogo || getClubIconPath(club.clubName)}
      size={size}
    >
      {club.clubName}
    </SquareAvatar>
  ) : (
    <MaskIcon sx={{ bgcolor: 'white' }}>
      <CryptoCurrencyAhaAltIcon width={size} height={size} />
    </MaskIcon>
  );
  return (
    <PopupOverlay
      title={title}
      open
      iconComponent={avatar}
      modalProps={{
        disablePortal: true,
      }}
      actionComponent={
        <Box>
          <PopupActions>
            <EmphasizeButton
              data-testid="login-button"
              variant="outlined"
              prefixIcon={<ProfileLoginIcon />}
              component={Link}
              href={loginUrl}
            >
              {t('claim.blocker.btn')}
            </EmphasizeButton>
            <EmphasizeButton
              prefixIcon={<TextEditorCheckIcon />}
              loading={isSubmitting}
              onClick={handleContinue}
              disabled={isSubmitted && !!errorMessage}
            >
              {t('claim.blocker.cta')}
            </EmphasizeButton>
          </PopupActions>
          <Typography sx={styles.note} variant="caption" component="div">
            <Trans
              i18nKey="profile::claim.blocker.note"
              components={{
                1: (
                  <a
                    href="https://rootdomain.notion.site/Terms-of-Service-67483372ba82425da2c09543b5936b8c?pvs=4"
                    target="_blank"
                  />
                ),
                2: (
                  <a
                    href="https://rootdomain.notion.site/Privacy-Policy-a0385d28abef41c699d3b70f6c6978a4?pvs=4"
                    target="_blank"
                  />
                ),
              }}
            />
          </Typography>
        </Box>
      }
    >
      <Box sx={styles.main}>
        <TextField
          labelIcon={PropertyType.Email}
          label={t('claim.blocker.email.label')}
          placeholder={t('claim.blocker.email.placeholder')}
          requiredMark
          value={value}
          onChange={(ev) => handleChange(ev.target.value)}
          onBlur={handleBlur}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      </Box>
    </PopupOverlay>
  );
}

export default function ClaimAccountBlockerRoot() {
  const { isLogged } = useAuth();
  const { club } = useCurrentIaClub();
  const router = useRouter();
  const slug = router.query.slug as string;
  const { login } = useContext(GuestContext);
  const directingRef = useRef(false);
  const { clearRouteParams } = useClearRoute();

  useEffect(() => {
    if (
      isLogged &&
      !directingRef.current &&
      router.query.blocker === 'claim-account'
    ) {
      clearRouteParams(['blocker', 'state', 'target']);
    }
  }, [clearRouteParams, isLogged, router.query.blocker]);

  // Checking if the user is invited from club invitation
  if (slug && !club) return null;

  const handleContinue = (
    memberLogin: CreateGuestAccountRes['memberLogin']
  ) => {
    directingRef.current = true;
    login(memberLogin);
    setClientToken(memberLogin.accessToken);
    clearRouteParams(['state', 'target'], { blocker: 'onboarding' });
  };
  return <ClaimAccountBlocker club={club} onContinue={handleContinue} />;
}
