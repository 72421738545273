import React from 'react';

export default function ProfileProPlan({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.2827 14.4401L19.457 12.8353C19.5489 11.9788 19.6105 11.4141 19.5622 11.0583H19.5793C19.9035 11.0582 20.2181 10.9479 20.4706 10.7458C20.7231 10.5436 20.8984 10.2618 20.9674 9.94698C21.0365 9.63219 20.995 9.30341 20.8501 9.01523C20.7051 8.72705 20.4652 8.49679 20.1704 8.3627C19.8755 8.22861 19.5433 8.19874 19.229 8.27805C18.9147 8.35736 18.6372 8.54109 18.4425 8.79872C18.2477 9.05636 18.1476 9.3724 18.1586 9.69439C18.1696 10.0164 18.2911 10.3249 18.503 10.5689C18.1942 10.758 17.7906 11.1581 17.1833 11.7595C16.7143 12.2235 16.4803 12.4551 16.2188 12.4908C16.0739 12.5107 15.9263 12.4902 15.7925 12.4315C15.5518 12.3261 15.3908 12.04 15.0696 11.4668L13.3737 8.44638C13.1757 8.09341 13.0099 7.79693 12.8602 7.55974C13.2395 7.36777 13.5426 7.05455 13.7208 6.67056C13.899 6.28656 13.9418 5.85415 13.8425 5.443C13.7431 5.03185 13.5073 4.6659 13.1731 4.40413C12.8388 4.14236 12.4256 4 12 4C11.5744 4 11.1612 4.14236 10.8269 4.40413C10.4927 4.6659 10.2569 5.03185 10.1575 5.443C10.0582 5.85415 10.101 6.28656 10.2792 6.67056C10.4574 7.05455 10.7605 7.36777 11.1398 7.55974C10.9901 7.79787 10.8243 8.09341 10.6253 8.44638L8.9304 11.4668C8.60828 12.04 8.44722 12.3261 8.20658 12.4315C8.07275 12.4902 7.92514 12.5107 7.78025 12.4908C7.51971 12.4551 7.2857 12.2235 6.81673 11.7595C6.20944 11.1581 5.80585 10.758 5.49699 10.5689C5.70886 10.3249 5.83039 10.0164 5.84141 9.69439C5.85242 9.3724 5.75225 9.05636 5.55754 8.79872C5.36282 8.54109 5.08527 8.35736 4.77096 8.27805C4.45665 8.19874 4.12449 8.22861 3.82963 8.3627C3.53476 8.49679 3.29493 8.72705 3.14994 9.01523C3.00496 9.30341 2.96354 9.63219 3.03257 9.94698C3.1016 10.2618 3.27693 10.5436 3.52943 10.7458C3.78194 10.9479 4.09645 11.0582 4.42074 11.0583H4.43779C4.38948 11.4141 4.45106 11.9798 4.54296 12.8353L4.71728 14.4401C4.81391 15.3305 4.8935 16.1786 4.99203 16.941H19.008C19.1065 16.1786 19.1861 15.3315 19.2827 14.4401Z"
        fill="currentColor"
      />
      <path
        d="M17.6844 20C18.5643 20 18.679 18.8495 18.8443 18.1175H5.18153C5.32738 18.8535 5.33389 20 6.31546 20H17.6844Z"
        fill="currentColor"
      />
    </svg>
  );
}
