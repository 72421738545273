import React from 'react';

export default function EmojiObject({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        height="1.6"
        width="6"
        fill="currentColor"
        rx="0.8"
        x="9"
        y="18.3669"
      />
      <rect
        height="1.6"
        width="4"
        fill="currentColor"
        rx="0.8"
        x="10"
        y="20.3999"
      />
      <path
        d="M5 9.19577C5 5.23121 8.12468 2 12 2C15.8753 2 19 5.23121 19 9.19577C19 11.9614 17.4814 14.366 15.25 15.5704V16.08C15.25 17.1108 14.4471 18 13.3929 18H10.6071C9.55291 18 8.75 17.1108 8.75 16.08V15.5704C6.51863 14.366 5 11.9614 5 9.19577ZM12 4.03852C9.2479 4.03852 7 6.33794 7 9.19577C7 11.3645 8.29686 13.2166 10.1284 13.9798L10.75 14.2389V15.9615H13.25V14.2389L13.8716 13.9798C15.7031 13.2166 17 11.3645 17 9.19577C17 6.33794 14.7521 4.03852 12 4.03852Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
