import React from 'react';

export default function TestExport({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.0001 3C12.4971 3 12.9001 3.40294 12.9001 3.89999L12.9001 14.4271L16.1767 10.6046C16.5002 10.2272 17.0683 10.1835 17.4457 10.507C17.8231 10.8305 17.8668 11.3987 17.5433 11.776L12.0001 18.2427L6.45669 11.7761C6.1332 11.3987 6.17688 10.8305 6.55426 10.507C6.93164 10.1835 7.49981 10.2272 7.82331 10.6046L11.1001 14.4271L11.1001 3.89999C11.1001 3.40294 11.503 3 12.0001 3ZM20.1 21L3.9 21C3.40294 21 3 20.5971 3 20.1C3 19.603 3.40294 19.2 3.9 19.2L20.1 19.2C20.5971 19.2 21 19.603 21 20.1C21 20.5971 20.5971 21 20.1 21Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
