import React from 'react';

export default function EditorDataSource({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_10196_47154)">
        <path
          d="M20 6H12L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10196_47154">
          <rect height="24" width="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
