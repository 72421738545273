import React from 'react';

export default function ChallengeCompleted({ ...rest }) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="8.75" cy="8" r="6" fill="#6A6A6A" />
    </svg>
  );
}
