import { useCallback, useEffect, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLatestValueRef } from '@front/helper';

import { TableLayoutRow } from '../types';
import { isCellEditable } from '../utils';

import useTableSelectionContext from './useTableSelectionContext';

function extractId<T extends { id: string }>(item: T) {
  return item.id;
}

function flatRows(
  rows: TableLayoutRow[],
  childRows: Record<string, TableLayoutRow[]>,
  flattenRows: TableLayoutRow[]
) {
  for (const row of rows) {
    flattenRows.push(row);
    if (row.expanded) {
      const children = childRows[row.id];
      flatRows(children, childRows, flattenRows);
    }
  }
}

type CellPosition = {
  rowIndex: number;
  colIndex: number;
};

type Options = {
  displayRows: TableLayoutRow[];
  childRows?: Record<string, TableLayoutRow[]>;
  columnOrder: string[];
  bodyRef: React.RefObject<HTMLDivElement>;
  disableCellFocus?: boolean;
};

export default function useCellSelection({
  displayRows,
  childRows = {},
  columnOrder,
  bodyRef,
  disableCellFocus,
}: Options) {
  const {
    cellSelected,
    flattenRowIds,
    selectCell,
    setFlattenRowIds,
    setColumOrder,
    focusOutCell,
    setSelectedState,
    setDisableCellFocus,
  } = useTableSelectionContext();

  const lockRef = useLatestValueRef(
    !!cellSelected && cellSelected.state === 'active'
  );

  const flattenRows = useMemo(() => {
    const rows: TableLayoutRow[] = [];
    flatRows(displayRows, childRows, rows);
    return rows;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(childRows), JSON.stringify(displayRows)]);

  useEffect(() => {
    setFlattenRowIds(flattenRows.map(extractId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(flattenRows.map(extractId))]);

  useEffect(() => {
    setColumOrder(columnOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnOrder]);

  useEffect(() => {
    setDisableCellFocus(disableCellFocus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableCellFocus]);

  const updateSelectedCell = useCallback(
    (pos: CellPosition) => {
      selectCell(
        flattenRowIds[pos.rowIndex],
        columnOrder[pos.colIndex],
        'focused'
      );
    },
    [columnOrder, flattenRowIds, selectCell]
  );

  const totalRows = flattenRowIds.length;
  const totalCols = columnOrder.length;

  const getCell = useCallback(
    (rowId: string, colKey: string) => {
      const row = flattenRows.find((item) => item.id === rowId);
      if (!row) return;
      return row.cells[colKey];
    },
    [flattenRows]
  );

  const getRow = useCallback(
    (rowId: string) => {
      return flattenRows.find((item) => item.id === rowId);
    },
    [flattenRows]
  );

  const up = useCallback(() => {
    if (!cellSelected) return;
    const { pos } = cellSelected;

    if (pos.rowIndex > 0) {
      updateSelectedCell({
        ...pos,
        rowIndex: pos?.rowIndex - 1,
      });
    }
  }, [cellSelected, updateSelectedCell]);

  const down = useCallback(() => {
    if (!cellSelected) return;
    const { pos } = cellSelected;

    if (pos.rowIndex + 1 < totalRows) {
      updateSelectedCell({
        ...pos,
        rowIndex: pos.rowIndex + 1,
      });
    }
  }, [cellSelected, totalRows, updateSelectedCell]);

  const left = useCallback(() => {
    if (!cellSelected) return;
    const { pos } = cellSelected;

    if (pos.colIndex > 0) {
      updateSelectedCell({
        ...pos,
        colIndex: pos.colIndex - 1,
      });
    }
    if (pos.colIndex === 0 && pos.rowIndex > 0) {
      // jump to the previous row
      updateSelectedCell({
        colIndex: totalCols - 1,
        rowIndex: pos.rowIndex - 1,
      });
    }
  }, [cellSelected, updateSelectedCell, totalCols]);

  const right = useCallback(() => {
    if (!cellSelected) return;
    const { pos } = cellSelected;

    if (pos.colIndex + 1 < totalCols) {
      updateSelectedCell({
        ...pos,
        colIndex: pos.colIndex + 1,
      });
    }
    if (pos.colIndex === totalCols - 1 && pos.rowIndex + 1 < totalRows) {
      // jump to the next row
      updateSelectedCell({
        colIndex: 0,
        rowIndex: pos.rowIndex + 1,
      });
    }
  }, [cellSelected, totalCols, totalRows, updateSelectedCell]);

  useHotkeys(
    'down',
    (ev) => {
      if (!cellSelected || lockRef.current) return;

      ev.preventDefault();

      down();
    },
    {
      enableOnFormTags: ['input', 'select', 'textarea'],
    },
    [down]
  );

  useHotkeys(
    'up',
    (ev) => {
      if (!cellSelected || lockRef.current) return;

      ev.preventDefault();
      up();
    },
    {
      enableOnFormTags: ['input', 'select', 'textarea'],
    },
    [up]
  );

  useHotkeys(
    'right, tab',
    (ev) => {
      if (!cellSelected) return;
      if (lockRef.current && ev.key !== 'Tab') return;

      ev.preventDefault();
      right();
    },
    {
      enableOnFormTags: ['input', 'select', 'textarea'],
    },
    [right]
  );

  useHotkeys(
    'left, shift+tab',
    (ev) => {
      if (!cellSelected) return;
      if (lockRef.current && !(ev.shiftKey && ev.key === 'Tab')) return;

      ev.preventDefault();
      left();
    },
    {
      enableOnFormTags: ['input', 'select', 'textarea'],
    },
    [left]
  );

  useHotkeys(
    'enter',
    (ev) => {
      if (!cellSelected) return;

      ev.preventDefault();
      ev.stopPropagation();

      const { cellId, state } = cellSelected;
      if (state === 'active') {
        setSelectedState('focused');
        down();
        return;
      }

      const cell = getCell(cellId.rowId, cellId.colKey);

      if (isCellEditable(cell)) {
        setSelectedState('active');
      }
    },
    {
      enableOnFormTags: ['input', 'select', 'textarea'],
    },
    [cellSelected, flattenRowIds, columnOrder, down]
  );

  useHotkeys(
    'esc',
    (ev) => {
      if (!cellSelected) return;
      ev.preventDefault();

      const { state } = cellSelected;
      if (state === 'active') {
        setSelectedState('focused');
        return;
      }
      if (state === 'focused') {
        focusOutCell();
        return;
      }
    },
    {
      enableOnFormTags: ['input', 'select', 'textarea'],
    },
    [cellSelected]
  );

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      const elem = e.target as HTMLElement;
      if (!document.contains(elem)) return; // element is disappear from dom (e.g. in DateCell, text element is removed after clicking
      if (bodyRef?.current?.contains(elem)) return; // inside table body
      if (!cellSelected) return;

      if (cellSelected.state === 'active') {
        setSelectedState('focused');
      } else {
        focusOutCell();
      }
    }
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [cellSelected, bodyRef, focusOutCell, setSelectedState]);

  useEffect(() => {
    if (!cellSelected) return;
    const { cellId } = cellSelected;
    const element = document.getElementById(`${cellId.rowId}-${cellId.colKey}`);
    if (!element) return;
    const row = getRow(cellId.rowId);
    const showCheckbox = (row?.moreActions?.length || 0) > 0;
    if (showCheckbox) {
      element.style.scrollMargin = '31px'; // checkbox width
    }
    element.scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    });
  }, [cellSelected, getRow]);
}
