import { useCallback, useEffect, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { VariableSubType, VariableType } from '@lib/web/apis';
import { useFieldErrorMessage } from '@lib/web/editor/hooks/useFieldErrorMessage';
import { useCurrentIaClub } from '@lib/web/hooks';
import { v4 } from 'uuid';

import { useCurrentQuestion, useGetAllVariablesFromType } from '../../../hooks';
import { TextComposerPanelKeys, TextComposerPanelParams } from '../../panel';

import { getUniqueVariableName } from './utils';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& .ia-form-layout': {
      py: 2,
      px: { xs: 2.5, md: '12px' },
    },
  },
  form: {
    flex: 1,
  },
  note: {
    px: 2.5,
    py: '12px',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    span: {
      color: 'text.primary',
    },
  },
};

const useVariableData = ({ variableId }: { variableId?: string }) => {
  const { getRightParams } = useBaseRightPanel<TextComposerPanelParams>();

  const { data, variables } = useGetAllVariablesFromType(
    VariableType.NumberFormula
  );
  const otherVariableNames = useMemo(
    () =>
      variables?.filter((v) => v.id !== variableId).map((v) => v.name) || [],
    [variables, variableId]
  );

  const { defaultValue } = getRightParams(
    TextComposerPanelKeys.TextComposerVariable
  );
  const placeholderName = useMemo(
    () =>
      getUniqueVariableName(
        `Formula${otherVariableNames.length + 1}`,
        otherVariableNames
      ),
    [otherVariableNames]
  );

  const placeholderContent = 'Formula';

  return {
    dataIsLoading: !data,
    defaultValue,
    placeholderName,
    placeholderContent,
    otherVariableNames,
  };
};

type FormulaVariableFormProps = {
  variableId?: string;
  createOrUpdateVariable: (
    params: () =>
      | AddCreatorQuestionVariableReq
      | UpdateCreatorQuestionVariableReq
  ) => Promise<void>;
};

export default function FormulaVariableForm({
  variableId,
  createOrUpdateVariable,
}: FormulaVariableFormProps) {
  const { t } = useTranslation('editor');
  const { sectionId } = useCurrentIaClub();
  const { questionId } = useCurrentQuestion();

  const {
    dataIsLoading,
    defaultValue,
    otherVariableNames,
    placeholderName,
    placeholderContent,
  } = useVariableData({ variableId });

  const { getRightParams, setRightParams } =
    useBaseRightPanel<TextComposerPanelParams>();

  const { setFieldErrorMessage } = useFieldErrorMessage();

  const { data, variables } = useGetAllVariablesFromType(
    VariableType.NumberFormula
  );

  const { variables: numberVariables } = useGetAllVariablesFromType(
    VariableType.Number
  );

  const { hasError } = getRightParams(
    TextComposerPanelKeys.TextComposerVariable
  );

  const layouts = useMemo(() => {
    const hintLayout = {
      layout: 'hint-layout' as const,
      text: t('These text variables can be used anywhere in this editor'),
    };
    if (dataIsLoading)
      return [
        hintLayout,
        {
          layout: 'list-layout' as const,
          areas: [
            {
              key: 'loading',
              areaType: 'loading' as const,
            },
          ],
        },
      ];

    return [
      hintLayout,
      {
        layout: 'form-layout' as const,
        settings: {
          mode: 'all' as const,
          triggerSubmitMode: 'onChange' as const,
        },
        formStateChanged: 'stateChanged',
        formAction: {
          type: 'event' as const,
          value: 'submitVariablesFormula',
        },
        defaultValues: defaultValue,
        mainItems: [
          {
            type: 'group' as const,
            gap: 1,
            items: [
              {
                type: 'text' as const,
                label: 'Variable Formula Name',
                name: 'name',
                placeholder: placeholderName,
                helperText: 'Used to help identify different variables',
                actionMap: {
                  change: { value: 'removeSpaces' },
                },
                customRules: {
                  notInArray: {
                    values: otherVariableNames,
                    message: 'Variable Formula Name should be unique',
                  },
                  regex: {
                    value: /^[a-zA-Z0-9]*$/,
                    message: '‘{value}’ is not a valid value',
                  },
                },
              },
              {
                type: 'formula' as const,
                label: 'Formula',
                name: 'content', // Text Variable
                placeholder: placeholderContent,
                autofocus: true,
                helperText: 'Type ‘/’ to add number variables',
                options:
                  numberVariables?.map((variable) => ({
                    title: `${variable.name} (${variable.possibilities})`,
                    name: variable.name,
                    type: variable.type,
                    subType: variable.subType,
                  })) || [],
                rules: {
                  required: {
                    value: true,
                    hiddenRule: true,
                    message:
                      'formula must contain at least one number variable',
                  },
                },
                customRules: {
                  formula: {
                    message: 'formula error',
                  },
                  regex: {
                    value: new RegExp(
                      `(${numberVariables?.map((v) => v.name).join('|')})`
                    ),
                    message:
                      'formula must contain at least one number variable',
                  },
                  validate: {
                    handler: () =>
                      numberVariables?.length === 0
                        ? 'formula must contain at least one number variable'
                        : true,
                  },
                },
              },
            ],
          },
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, defaultValue, data, numberVariables, variables]);

  const clearErrorState = useCallback(() => {
    setFieldErrorMessage(questionId, 'panelArea', []);
    setRightParams<TextComposerPanelKeys.TextComposerVariable>((st) => ({
      ...st,
      hasError: false,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId, setFieldErrorMessage]);

  const availableActions = {
    submitVariablesFormula: {
      action: async (
        params: Omit<
          UpdateCreatorQuestionVariableReq,
          'sectionId' | 'creatorQuestionId' | 'type'
        >
      ) => {
        if (!sectionId || !questionId) return;

        await createOrUpdateVariable(() =>
          variableId
            ? {
                ...params,
                id: variableId,
                name: params.name || placeholderName,
                content: params.content,
              }
            : {
                sectionId,
                creatorQuestionId: questionId,
                type: VariableType.NumberFormula,
                subType: VariableSubType.NumberFormula,
                name: params.name || placeholderName,
                content: params.content,
              }
        );
      },
    },
    stateChanged: {
      action: ({ isDirty, errors }: FormState<Record<string, any>>) => {
        if (isDirty && Object.values(errors).length !== 0) {
          setFieldErrorMessage(
            questionId,
            'panelArea',
            Object.values(errors).map((error) => ({
              id: `variable-${error?.type || v4()}`,
              type: 'error',
              message: error?.message?.toString() || '',
            }))
          );
          setRightParams<TextComposerPanelKeys.TextComposerVariable>((st) => ({
            ...st,
            hasError: true,
          }));
        } else {
          clearErrorState();
        }
      },
    },
    removeSpaces: {
      action: ({ value }: { value: string }) => {
        if (/\s/g.test(value)) return value.replace(/\s/g, '');

        return undefined;
      },
    },
  };

  useEffect(() => {
    return () => {
      clearErrorState();
    };
  }, [clearErrorState]);

  const currentVariable =
    variables && defaultValue
      ? variables.find((variable) => variable.id === defaultValue.id)
      : undefined;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.form}>
        <IaActionContextProvider availableActions={availableActions}>
          <IaLayouts layouts={layouts} />
        </IaActionContextProvider>
      </Box>

      <Typography sx={styles.note}>
        {t('Total possibilities that can be generated based on specifications')}
        : <span>{hasError ? 0 : currentVariable?.possibilities || 0}</span>
      </Typography>
    </Box>
  );
}
