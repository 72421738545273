import React from 'react';

export default function OtherAdvanceFilter({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.17 17C6.3766 16.4145 6.75974 15.9074 7.2666 15.5488C7.77346 15.1902 8.37909 14.9976 9 14.9976C9.62091 14.9976 10.2265 15.1902 10.7334 15.5488C11.2403 15.9074 11.6234 16.4145 11.83 17H20V19H11.83C11.6234 19.5855 11.2403 20.0926 10.7334 20.4512C10.2265 20.8099 9.62091 21.0025 9 21.0025C8.37909 21.0025 7.77346 20.8099 7.2666 20.4512C6.75974 20.0926 6.3766 19.5855 6.17 19H4V17H6.17ZM8.29289 17.2929C8.48043 17.1054 8.73478 17 9 17C9.26522 17 9.51957 17.1054 9.70711 17.2929C9.89464 17.4804 10 17.7348 10 18C10 18.2652 9.89464 18.5196 9.70711 18.7071C9.51957 18.8947 9.26522 19 9 19C8.73478 19 8.48043 18.8947 8.29289 18.7071C8.10536 18.5196 8 18.2652 8 18C8 17.7348 8.10536 17.4804 8.29289 17.2929Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M12.17 11C12.3766 10.4145 12.7597 9.90744 13.2666 9.5488C13.7735 9.19015 14.3791 8.99756 15 8.99756C15.6209 8.99756 16.2265 9.19015 16.7334 9.5488C17.2403 9.90744 17.6234 10.4145 17.83 11H20V13H17.83C17.6234 13.5855 17.2403 14.0926 16.7334 14.4512C16.2265 14.8099 15.6209 15.0025 15 15.0025C14.3791 15.0025 13.7735 14.8099 13.2666 14.4512C12.7597 14.0926 12.3766 13.5855 12.17 13H4V11H12.17ZM14.2929 11.2929C14.4804 11.1054 14.7348 11 15 11C15.2652 11 15.5196 11.1054 15.7071 11.2929C15.8946 11.4804 16 11.7348 16 12C16 12.2652 15.8946 12.5196 15.7071 12.7071C15.5196 12.8947 15.2652 13 15 13C14.7348 13 14.4804 12.8947 14.2929 12.7071C14.1054 12.5196 14 12.2652 14 12C14 11.7348 14.1054 11.4804 14.2929 11.2929Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M6.17 5.00001C6.3766 4.41448 6.75974 3.90744 7.2666 3.5488C7.77346 3.19015 8.37909 2.99756 9 2.99756C9.62091 2.99756 10.2265 3.19015 10.7334 3.5488C11.2403 3.90744 11.6234 4.41448 11.83 5.00001H20V7.00001H11.83C11.6234 7.58554 11.2403 8.09258 10.7334 8.45122C10.2265 8.80986 9.62091 9.00246 9 9.00246C8.37909 9.00246 7.77346 8.80986 7.2666 8.45122C6.75974 8.09258 6.3766 7.58554 6.17 7.00001H4V5.00001H6.17ZM8.29289 5.2929C8.48043 5.10537 8.73478 5.00001 9 5.00001C9.26522 5.00001 9.51957 5.10537 9.70711 5.2929C9.89464 5.48044 10 5.73479 10 6.00001C10 6.26523 9.89464 6.51958 9.70711 6.70712C9.51957 6.89465 9.26522 7.00001 9 7.00001C8.73478 7.00001 8.48043 6.89465 8.29289 6.70712C8.10536 6.51958 8 6.26523 8 6.00001C8 5.73479 8.10536 5.48044 8.29289 5.2929Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
