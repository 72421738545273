import api from './client';

const funcApi = {
  updateFuncHighlights: (params: UpdateFuncHighlight) => {
    return api.patch('/api/func/highlight', params, { baseURL: '' });
  },
  threadRecompletion: (params: ThreadRecompletion) => {
    return api.post('/func/thread/recompletion', params);
  },
  threadUpdateUpgradeMessage: (params: ThreadUpdateUpgradeMessage) => {
    return api.post('/api/func/thread/update-upgrade-message', params, {
      baseURL: '',
    });
  },
  threadEditMessage: (params: ThreadEditMessage) => {
    return api.put('/func/thread/edit', params);
  },
};

export default funcApi;
