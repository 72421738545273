import React, { PropsWithChildren, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import FormLayoutMethodsContext from './FormLayoutMethodsContext';

const FormLayoutMethodsProvider = ({ children }: PropsWithChildren) => {
  const [methods, setMethods] = useState<UseFormReturn>({} as UseFormReturn);

  return (
    <FormLayoutMethodsContext.Provider value={{ methods, setMethods }}>
      {children}
    </FormLayoutMethodsContext.Provider>
  );
};

export default FormLayoutMethodsProvider;
