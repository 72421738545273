export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function nonBooleanAndNullable<T>(
  value: T
): value is Exclude<T, null | undefined | boolean> {
  return nonNullable(value) && typeof value !== 'boolean';
}

export function isDate(value: unknown): value is Date {
  return value instanceof Date;
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}
