import React from 'react';

export default function OtherOutOfPractice({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.54545 4H16.9091C17.5882 4 18.1691 4.4 18.4145 4.976L20.8855 10.616C20.9591 10.8 21 10.992 21 11.2V12.8C21 13.68 20.2636 14.4 19.3636 14.4H14.2009L14.9782 18.056L15.0027 18.312C15.0027 18.64 14.8636 18.944 14.6427 19.16L13.7755 20L8.38364 14.728C8.08909 14.44 7.90909 14.04 7.90909 13.6V5.6C7.90909 4.72 8.64545 4 9.54545 4ZM9.54545 13.6L13.0964 17.072L12 12.8H19.3636V11.2L16.9091 5.6H9.54545V13.6ZM6.27273 4H3V13.6H6.27273V4Z"
        fill="currentColor"
      />
    </svg>
  );
}
