export default function ActionCloseThick({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75346 5.25423C7.18229 5.2219 7.61032 5.37477 7.94991 5.71437L12.0014 9.76582L16.0528 5.71437C16.3924 5.37477 16.8204 5.2219 17.2493 5.25423C17.6576 5.28501 18.014 5.4784 18.2691 5.73359C18.5243 5.98878 18.7177 6.34517 18.7485 6.75346C18.7808 7.18229 18.628 7.61032 18.2884 7.94991L14.2369 12.0014L18.2884 16.0528C18.628 16.3924 18.7808 16.8204 18.7485 17.2493C18.7177 17.6576 18.5243 18.014 18.2691 18.2691C18.014 18.5243 17.6576 18.7177 17.2493 18.7485C16.8204 18.7808 16.3924 18.628 16.0528 18.2884L12.0014 14.2369L7.94991 18.2884C7.61032 18.628 7.18229 18.7808 6.75346 18.7485C6.34517 18.7177 5.98878 18.5243 5.73359 18.2691C5.4784 18.014 5.28501 17.6576 5.25423 17.2493C5.2219 16.8204 5.37477 16.3924 5.71437 16.0528L9.76582 12.0014L5.71437 7.94991C5.37477 7.61032 5.2219 7.18229 5.25423 6.75346C5.28501 6.34517 5.4784 5.98878 5.73359 5.73359C5.98878 5.4784 6.34517 5.28501 6.75346 5.25423Z"
        fill="currentColor"
      />
    </svg>
  );
}
