import React from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import useBaseLeftPanel from '../hooks/useBaseLeftPanel';

const styles = {
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bgcolor: 'background.darker',
    opacity: 0.75,
    zIndex: 1,
  },
};
export default function BaseLayoutCloseLeftPanel() {
  const { leftPanelOpened, hideLeftPanel, closeLeftPanel } = useBaseLeftPanel();

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (mdUp || !leftPanelOpened || hideLeftPanel) return null;

  return (
    <Box
      className="base-layout-close-left-panel"
      sx={styles.root}
      onClick={closeLeftPanel}
    />
  );
}
