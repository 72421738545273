import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useUser } from '@auth0/nextjs-auth0/client';

type SEOHeaderProps = {
  title?: string;
  keyword?: string;
  description?: string;
  imageSrc?: string;
};

export default function SEOHeader({
  title,
  keyword = 'Learn to Earn',
  description = 'Learn to Earn',
  imageSrc = 'https://aha-public.s3.ap-northeast-2.amazonaws.com/og/main-page.jpg',
}: SEOHeaderProps) {
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);
  const { user, isLoading } = useUser();

  useEffect(() => {
    if (isGoogleScriptLoaded && !isLoading && !user) {
      window.google.accounts.id.prompt();
    }
  }, [isGoogleScriptLoaded, isLoading, user]);

  return (
    <>
      <Head>
        <title>
          {title
            ? `${title} | Aha | Unlimited Exam Questions`
            : 'Aha | Unlimited Exam Questions'}
        </title>
        <meta name="description" content={description} />
        <meta name="keyword" content={keyword} />
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,viewport-fit=cover"
        />
        <meta property="og:image" content={imageSrc} />
      </Head>
      <Script
        src="https://accounts.google.com/gsi/client"
        strategy="lazyOnload"
        onLoad={() => {
          function handleCredentialResponse(response: any) {
            window.location.href = `/api/auth/google-login?credential=${response.credential}`;
          }

          window.google.accounts.id.initialize({
            client_id: process.env.ONE_TAP_CLIENT_ID || '',
            callback: handleCredentialResponse,
            scope: 'profile email',
          });

          setIsGoogleScriptLoaded(true);
        }}
      />
    </>
  );
}
