import React, { MouseEvent, useRef } from 'react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  PopperProps,
  Typography,
} from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { useSortable } from '@dnd-kit/sortable';
import { FloatingMenu, ResponsiveMenu } from '@front/ui';

import Hint from '../../components/Hint';
import Icon from '../../components/Icon';
import { useIaAction } from '../../core/IaAction/useIaAction';
import { IaHintKeyAction } from '../../core/types';

import {
  SideFloatingMenuEvent,
  SideFloatingMenuItem,
  SideFloatingMenuItemEventHint,
} from './types';

export type SideFloatingMenuProps = {
  item: SideFloatingMenuItem;
  anchorEl: HTMLDivElement | null;
  active: boolean;
  eventHint?: SideFloatingMenuItemEventHint;
  addActions?: IaHintKeyAction[];
  onOpenMenu?: () => void;
  onCloseMenu?: () => void;
  menuSx?: BoxProps['sx'];
  menuPopperOptions?: PopperProps['popperOptions'];
  onActionClick?: (
    itemEvent: SideFloatingMenuEvent,
    domEvent: MouseEvent
  ) => void;
  onActionHover?: (
    itemEvent: SideFloatingMenuEvent,
    domEvent: MouseEvent
  ) => void;
};

const styles = {
  menuTooltip: {
    minWidth: 170,
    '& .MuiTooltip-tooltip': {
      p: 1,
      color: 'background.darker',
      backgroundColor: 'text.primary',
      whiteSpace: 'nowrap',
    },
  },
  menuTooltipInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },
  draggableButton: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  subMenu: {
    mt: '-10px',
    '& .MuiPaper-root': {
      maxWidth: 265,
    },
  },
  subMenuItem: {
    '& .MuiListItemText-root': {
      py: '3.5px',
      display: 'grid',
      gap: 0.5,
    },
    '& .MuiListItemText-secondary': {
      typography: 'caption',
      whiteSpace: 'initial',
    },
  },
  hintInline: {
    '& .MuiListItemText-root': {
      gap: 2,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiListItemText-secondary': {
      typography: 'body2',
      color: 'text.primary',
    },
  },
};

export default function SideFloatingMenu({
  active,
  item,
  anchorEl,
  eventHint,
  addActions,
  onOpenMenu,
  onCloseMenu,
  menuSx,
  menuPopperOptions,
  onActionClick,
  onActionHover,
}: SideFloatingMenuProps) {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [showMenu, setShowMenu] = React.useState(false);
  const [subMenu, setSubMenu] = React.useState<{
    targetValue: string;
    targetEl: Element;
    targetItems: (SideFloatingMenuEvent & { hint?: string })[];
  } | null>(null);
  const { id, moreActions, draggable } = item;
  const { listeners, setActivatorNodeRef, isDragging } = useSortable({
    id,
  });
  const { getIaAction, iaRoutingAction } = useIaAction();

  if (!draggable && !moreActions?.length) return null;

  if (!active && !isDragging && !showMenu) return null;

  const closeMenu = () => {
    setShowMenu(false);
    setSubMenu(null);
    onCloseMenu?.();
  };

  const handleActionClick = (
    itemEvent: SideFloatingMenuEvent,
    domEvent: MouseEvent
  ) => {
    domEvent.stopPropagation();
    if (itemEvent.plugin) {
      onActionClick?.(itemEvent, domEvent);
      return;
    }

    if (itemEvent.subActions) {
      setSubMenu({
        targetValue: itemEvent.value,
        targetEl: domEvent.currentTarget,
        targetItems: itemEvent.subActions,
      });
      return;
    }

    if (itemEvent.type === 'link') {
      void iaRoutingAction(itemEvent.value);
    } else {
      const onClickAction = getIaAction<SideFloatingMenuItem>(itemEvent.value);
      onClickAction?.action?.(item);
    }

    if (showMenu) {
      closeMenu();
    }

    onActionClick?.(itemEvent, domEvent);
  };
  const handleActionHover = (
    itemEvent: SideFloatingMenuEvent,
    domEvent: MouseEvent
  ) => {
    onActionHover?.(itemEvent, domEvent);
  };

  const tooltipProps = {
    tooltipProps: {
      slotProps: {
        popper: {
          sx: styles.menuTooltip,
        },
      },
    },
    hideInDevices: true,
  };

  const handleAddClick = (event: MouseEvent) => {
    if (!addActions?.length) return;
    const currentAction =
      addActions.find((action) => action.addKey && event[action.addKey]) ||
      addActions.find((action) => action.addKey === undefined);

    if (currentAction) {
      getIaAction<SideFloatingMenuItem>(currentAction.value)?.action(item);
    }
  };

  const menuIconButtonProps = {
    ref: draggable ? setActivatorNodeRef : undefined,
    ...(draggable ? listeners : {}),
    onClick: () => {
      if (showMenu) {
        setShowMenu(false);
        onCloseMenu?.();
      } else {
        setShowMenu(true);
        onOpenMenu?.();
      }
    },
    selected: showMenu,
  };
  return (
    <>
      <FloatingMenu
        ref={menuRef}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={menuSx}
        popperOptions={menuPopperOptions}
        placement="left-start"
      >
        {!!addActions?.length && (
          <FloatingMenu.TipButton
            title={
              <Typography sx={styles.menuTooltipInner} variant="caption">
                {addActions.map((action) => (
                  <Hint key={action.value} hint={action.hint} />
                ))}
              </Typography>
            }
            tooltipProps={tooltipProps}
            onClick={handleAddClick}
          >
            <Icon name="TestAdd" />
          </FloatingMenu.TipButton>
        )}
        {!isDragging && !showMenu ? (
          <FloatingMenu.TipButton
            sx={styles.draggableButton}
            title={
              <Typography sx={styles.menuTooltipInner} variant="caption">
                {draggable && (
                  <Hint hint={eventHint?.moveItem || '**Drag** to move'} />
                )}
                {!!moreActions?.length && (
                  <Hint
                    hint={eventHint?.openMenu || '**Click** to open menu'}
                  />
                )}
              </Typography>
            }
            tooltipProps={tooltipProps}
            {...menuIconButtonProps}
          >
            <Icon name="OtherDrag" />
          </FloatingMenu.TipButton>
        ) : (
          <FloatingMenu.Button {...menuIconButtonProps}>
            <Icon name="OtherDrag" />
          </FloatingMenu.Button>
        )}
      </FloatingMenu>
      {!!moreActions?.length && showMenu && (
        <ResponsiveMenu
          open={showMenu}
          onClose={closeMenu}
          menuProps={{
            anchorEl: menuRef.current,
            anchorOrigin: {
              vertical: 'center',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'center',
              horizontal: 4,
            },
          }}
          sheetProps={{
            defaultContentHeight: moreActions.length * 45,
          }}
        >
          {moreActions?.map((event) => (
            <MenuItem
              key={`${event.type}-${event.value}`}
              value={event.value}
              selected={
                subMenu ? event.value === subMenu.targetValue : undefined
              }
              onClick={(ev) => handleActionClick(event, ev)}
              onMouseEnter={(ev) => handleActionHover(event, ev)}
            >
              {event.icon && (
                <ListItemIcon sx={event.iconSxProps}>
                  <Icon name={event.icon} width={16} height={16} />
                </ListItemIcon>
              )}
              <ListItemText sx={event.textSxProps}>{event.text}</ListItemText>

              {!!event.subActions?.length && (
                <Icon name="ActionChevronRightSmall" width={16} height={16} />
              )}
            </MenuItem>
          ))}
        </ResponsiveMenu>
      )}

      {!!subMenu?.targetItems.length && (
        <ResponsiveMenu
          open
          onClose={() => setSubMenu(null)}
          menuProps={{
            sx: styles.subMenu,
            anchorEl: subMenu.targetEl,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 4,
            },
          }}
          sheetProps={{
            fixedHeight: true,
          }}
        >
          {subMenu.targetItems.map((event) => (
            <MenuItem
              key={`${event.type}-${event.value}`}
              sx={[styles.subMenuItem, !!item.hintInline && styles.hintInline]}
              value={event.value}
              onClick={(ev) => handleActionClick(event, ev)}
            >
              <ListItemText primary={event.text} secondary={event.hint} />
            </MenuItem>
          ))}
        </ResponsiveMenu>
      )}
    </>
  );
}
