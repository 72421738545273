import { useCallback, useContext } from 'react';
import { useQueueWorker } from '@front/helper';
import {
  apis,
  CreatorQuestionStatus,
  QuestionDetailComponentType,
  StructureType,
  UpdateCreatorQuestionDetailAction,
} from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { CreatorQuestionDetailContext } from '@lib/web/editor/contexts';
import {
  composerBlockToUpdateApiParams,
  persistentStringToHtml,
} from '@lib/web/editor/EditorTextComposer';
import { useCreatorQuestionDetailData } from '@lib/web/editor/hooks/useCreatorQuestionDetailData';
import { callWithToast } from '@lib/web/utils';

import { useCreatorQuestionListData } from './useCreatorQuestionListData';

const MAX_POST_SIZE_IN_BYTES = 80000; // 100 kb (nodejs default payload limit) - 20kb buffer
const DEBOUNCE_TIME = 500;

const updateCreatorQuestionBlocksByChunk = async (
  component: QuestionDetailComponent,
  blocks: ComposerBlock[],
  updateCreatorQuestionDetail: typeof apis.editor.updateCreatorQuestionDetail
) => {
  const toProcessComponents = [
    /**
     * api has the supported action to update each block, but that will make logic more complex,
     * so we always clear all the blocks, then recreate them
     */
    {
      id: component.id,
      action: UpdateCreatorQuestionDetailAction.ClearAllBlock,
      data: {},
    },
    ...blocks.map((block) => ({
      id: component.id,
      action: UpdateCreatorQuestionDetailAction.CreateBlock,
      data: composerBlockToUpdateApiParams(block),
    })),
  ];

  const chunkedRes = [];

  while (toProcessComponents.length > 0) {
    let postSizeQuota = MAX_POST_SIZE_IN_BYTES;
    let i = 0;
    for (i = 0; i < toProcessComponents.length; i++) {
      postSizeQuota -= new Blob([JSON.stringify(toProcessComponents[i])]).size;
      if (postSizeQuota < 0) break;
    }

    const components = toProcessComponents.splice(0, i);
    const [res] = await callWithToast(
      () =>
        updateCreatorQuestionDetail({
          components,
        }),
      {
        showLoading: false,
      }
    );
    chunkedRes.push(res);
  }
  return chunkedRes;
};

const buildDetailOptimisticData = ({
  oldDetailData,
  targetComponentId,
  newBlocks,
}: {
  oldDetailData?: GetCreatorQuestionDetailRes;
  targetComponentId: string;
  newBlocks: ComposerBlock[];
}) => {
  if (!oldDetailData) {
    return null;
  }

  return Object.entries(oldDetailData).reduce(
    (acc, [key, components]) => ({
      ...acc,
      [key]: components.map((component) =>
        component.id === targetComponentId
          ? {
              ...component,
              /**
               *  CreatorQuestionDetailBlock is compatible with UpdateCreatorQuestionDetailBlock,
               *  so we can simply use editorBlockToUpdateApiParams to build optimistic Data
               */
              blocks: newBlocks.map(composerBlockToUpdateApiParams),
            }
          : component
      ),
    }),
    {}
  );
};

const getNewQuestionTitle = ({
  oldQuestion,
  newBlocks,
  targetComponent,
}: {
  oldQuestion: CreatorQuestionListItem;
  newBlocks: ComposerBlock[];
  targetComponent: QuestionDetailComponent;
}) => {
  if (oldQuestion.structureType === StructureType.SubQuestion) {
    return targetComponent.type ===
      QuestionDetailComponentType.RightQuestionArea
      ? JSON.stringify(newBlocks[0])
      : oldQuestion.question;
  } else {
    return targetComponent.type === QuestionDetailComponentType.LeftQuestionArea
      ? JSON.stringify(newBlocks[0])
      : oldQuestion.question;
  }
};

const getNewQuestion = <T extends CreatorQuestionListItem>({
  oldQuestion,
  newBlocks,
  targetComponent,
}: {
  oldQuestion: T;
  newBlocks: ComposerBlock[];
  targetComponent: QuestionDetailComponent;
}): { newQuestion: T; changed: boolean } => {
  const newQuestionTitle = getNewQuestionTitle({
    oldQuestion,
    newBlocks,
    targetComponent,
  });
  const newQuestionStatus =
    oldQuestion.status === CreatorQuestionStatus.Draft
      ? CreatorQuestionStatus.Draft
      : CreatorQuestionStatus.ChangeUnpublished;

  const changed =
    oldQuestion.status !== newQuestionStatus ||
    persistentStringToHtml(newQuestionTitle) !==
      persistentStringToHtml(oldQuestion.question);

  return {
    newQuestion: changed
      ? {
          ...oldQuestion,
          status: newQuestionStatus,
          question: newQuestionTitle,
        }
      : oldQuestion,
    changed,
  };
};

const buildQuestionsOptimisticData = ({
  questionId,
  questions,
  newBlocks,
  targetComponent,
}: {
  questionId: string;
  questions: GetCreatorQuestionListRes[];
  newBlocks: ComposerBlock[];
  targetComponent: QuestionDetailComponent;
}) => {
  let isChanged = false;

  const optimisticData = questions.map((question) => {
    // find the target on single question
    if (question.id === questionId) {
      const { newQuestion, changed } = getNewQuestion({
        oldQuestion: question,
        newBlocks,
        targetComponent,
      });
      isChanged = isChanged || changed;
      return newQuestion;
    }

    // find the target on sub questions
    if (
      question.subQuestions?.find(
        (subQuestion) => subQuestion.id === questionId
      )
    ) {
      const newQuestionStatus =
        question.status === CreatorQuestionStatus.Draft
          ? CreatorQuestionStatus.Draft
          : CreatorQuestionStatus.ChangeUnpublished;
      return {
        ...question,
        status: newQuestionStatus,
        subQuestions: question.subQuestions.map((subQuestion) => {
          if (subQuestion.id === questionId) {
            const { newQuestion, changed } = getNewQuestion({
              oldQuestion: subQuestion,
              newBlocks,
              targetComponent,
            });
            isChanged = isChanged || changed;
            return newQuestion;
          }
          return subQuestion;
        }),
      };
    }

    return question;
  });

  if (!isChanged) {
    return null;
  }

  return optimisticData;
};

export const useCreatorQuestionDetailHandle = (questionId: string) => {
  const { addTask } = useQueueWorker();
  const { questions, reloadQuestions } = useCreatorQuestionListData();

  const { detailData, reloadQuestionDetail } =
    useCreatorQuestionDetailData(questionId);

  const { updateCreatorQuestionDetail } = useContext(
    CreatorQuestionDetailContext
  );

  const maybeOptimisticUpdateDetail = useCallback(
    ({
      newBlocks,
      targetComponentId,
    }: {
      newBlocks: ComposerBlock[];
      targetComponentId: string;
    }) => {
      const detailOptimisticData = buildDetailOptimisticData({
        oldDetailData: detailData,
        newBlocks,
        targetComponentId,
      });

      if (!detailOptimisticData) {
        return;
      }

      void reloadQuestionDetail(undefined, {
        optimisticData: detailOptimisticData,
        revalidate: false,
      });
    },
    [detailData, reloadQuestionDetail]
  );

  const maybeOptimisticUpdateQuestions = useCallback(
    ({
      newBlocks,
      targetComponent,
    }: {
      oldQuestions: GetCreatorQuestionListRes[];
      newBlocks: ComposerBlock[];
      targetComponent: QuestionDetailComponent;
    }) => {
      const questionsOptimisticData = buildQuestionsOptimisticData({
        questionId,
        questions,
        newBlocks,
        targetComponent,
      });

      if (!questionsOptimisticData) {
        return;
      }

      void reloadQuestions(undefined, {
        optimisticData: questionsOptimisticData,
        revalidate: false,
      });
    },
    [questionId, questions, reloadQuestions]
  );

  const handleCreatorQuestionDetailChange = useCallback(
    async (
      component: QuestionDetailComponent | null,
      blocks: ComposerBlock[],
      onUpdated?: () => void
    ) => {
      if (!component) return;

      addTask({
        scope: 'updateCreatorQuestionDetail',
        taskKey: component.id,
        task: async () => {
          const chunkedRes = await updateCreatorQuestionBlocksByChunk(
            component,
            blocks,
            updateCreatorQuestionDetail
          );

          if (chunkedRes.every(Boolean)) {
            onUpdated?.();
            return;
          }
        },
        debounceTime: DEBOUNCE_TIME,
        shouldInterrupt: true,
      });

      addTask({
        scope: 'optimisticUpdateQuestions',
        taskKey: component.id,
        task: async () => {
          maybeOptimisticUpdateDetail({
            newBlocks: blocks,
            targetComponentId: component.id,
          });

          maybeOptimisticUpdateQuestions({
            oldQuestions: questions,
            newBlocks: blocks,
            targetComponent: component,
          });
        },
        debounceTime: DEBOUNCE_TIME,
        shouldInterrupt: false,
      });
    },
    [
      addTask,
      maybeOptimisticUpdateDetail,
      maybeOptimisticUpdateQuestions,
      questions,
      updateCreatorQuestionDetail,
    ]
  );

  return {
    handleCreatorQuestionDetailChange,
  };
};
