import React from 'react';

export default function OtherCoinsAlt({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.4597 10.7778C9.78389 10.1222 8.92338 9.44444 8.92338 8.38889C8.92338 7.17778 10.1139 6.33333 12.1061 6.33333C14.2043 6.33333 14.9823 7.27778 15.053 8.66667H17.6582C17.5756 6.75556 16.3379 5 13.8743 4.43333V2H10.3379V4.4C8.05108 4.86667 6.21218 6.26667 6.21218 8.41111C6.21218 10.9778 8.46365 12.2556 11.7525 13C14.6994 13.6667 15.2888 14.6444 15.2888 15.6778C15.2888 16.4444 14.7112 17.6667 12.1061 17.6667C9.6778 17.6667 8.72299 16.6444 8.59332 15.3333H6C6.14145 17.7667 8.07466 19.1333 10.3379 19.5889V22H13.8743V19.6111C16.1729 19.2 18 17.9444 18 15.6667C18 12.5111 15.1356 11.4333 12.4597 10.7778Z"
        fill="currentColor"
      />
    </svg>
  );
}
