import React from 'react';

export default function OtherCopy({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.42593 5C6.86504 5 6.5 5.41921 6.5 5.82857V15.8857C6.5 16.438 6.05228 16.8857 5.5 16.8857C4.94772 16.8857 4.5 16.438 4.5 15.8857V5.82857C4.5 4.21815 5.85949 3 7.42593 3H14.1667C14.719 3 15.1667 3.44772 15.1667 4C15.1667 4.55228 14.719 5 14.1667 5H7.42593ZM7.87037 8.11429C7.87037 7.00881 8.79873 6.2 9.83333 6.2H17.537C18.5716 6.2 19.5 7.00881 19.5 8.11429V19.0857C19.5 20.1912 18.5716 21 17.537 21H9.83333C8.79873 21 7.87037 20.1912 7.87037 19.0857V8.11429ZM9.87037 8.2V19H17.5V8.2H9.87037Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
