import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLatestValueRef } from '@front/helper';

import FormSettingContext from '../components/FormSettingContext';

export default function useSuccessState(name: string, isDirty: boolean) {
  const { mode } = useContext(FormSettingContext);
  const [success, setSuccess] = useState(false);

  const { trigger, formState } = useFormContext();

  const isDirtyRef = useLatestValueRef(isDirty);

  const handleBlur = async () => {
    const isRevalidating = Object.keys(formState.errors).length > 0;

    if (
      isDirtyRef.current &&
      (mode === 'onBlur' || mode === 'all' || isRevalidating)
    ) {
      if (await trigger(name)) {
        setSuccess(true);
      }
    }
  };

  const handleChange = async () => {
    const isRevalidating = Object.keys(formState.errors).length > 0;

    if (
      isDirtyRef.current &&
      (mode === 'onChange' || mode === 'all' || isRevalidating)
    ) {
      if (await trigger(name)) {
        setSuccess(true);
      }
    }
  };

  useEffect(() => {
    let it: NodeJS.Timeout;
    if (success) {
      it = setTimeout(() => {
        setSuccess(false);
      }, 1000);
    }
    return () => {
      clearTimeout(it);
    };
  }, [success]);

  return { success, handleBlur, handleChange };
}
