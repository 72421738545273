import { useContext } from 'react';
import { ThreadComposerContext } from '@lib/web/thread/contexts/threadComposerContext';

export const useThreadComposer = () => {
  const value = useContext(ThreadComposerContext);

  if (!value) {
    throw new Error(
      'useThreadComposer must be used within a ThreadComposerProvider'
    );
  }

  return value;
};
