import React from 'react';

export default function NFTBadge({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.5455 4L5 6.90909V11.2727C5 15.3091 7.79273 19.0836 11.5455 20C15.2982 19.0836 18.0909 15.3091 18.0909 11.2727V6.90909L11.5455 4ZM5.72727 8.36364H17.3636V10.5455H5.72727V8.36364Z"
        fill="currentColor"
      />
    </svg>
  );
}
