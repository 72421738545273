import Box from '@mui/material/Box';
import { Icon } from '@front/ui';

import { TableLayoutFooterIconCell } from '../../types';

export type IaFooterIconCellProps = {
  cell: TableLayoutFooterIconCell;
};

const styles = {
  root: {
    py: '5.5px',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    overflow: 'hidden',
  },
};
export default function IaFooterIconCell({ cell }: IaFooterIconCellProps) {
  const { icon, color } = cell;

  return (
    <Box sx={[styles.root, { color }]}>
      <Icon name={icon} width={16} height={16} />
    </Box>
  );
}
