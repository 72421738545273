import { useState } from 'react';
import { Box, Chip } from '@mui/material';
import {
  Icon,
  IndicatorGroup,
  LoadingIcon,
  ScrollHorizontalContainer,
  SquareAvatar,
} from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { MessageItemChip } from '../../../../types';

const styles = {
  root: {
    '& .simplebar-content': {
      width: '100%',
    },
  },
  chips: {
    mt: 0.5,
    pb: 1.5,
    display: 'flex',
    gap: 2,
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
};

export type MessageChipsProps = {
  chips: MessageItemChip[];
};

export default function MessageChips({ chips }: MessageChipsProps) {
  const { getIaAction } = useIaAction();
  const [chipIdxClickInProgress, setChipIdxClickInProgress] =
    useState<number>();

  const onClickChip = async (
    e: React.MouseEvent,
    chip: MessageItemChip,
    index: number
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setChipIdxClickInProgress(index);

    const onClickAction = getIaAction<Record<string, any>>(
      chip.eventMap?.click?.value
    );

    await onClickAction?.action?.(chip.metadata);

    setChipIdxClickInProgress(undefined);
  };

  return (
    <Box sx={styles.root}>
      <ScrollHorizontalContainer>
        <Box sx={styles.chips}>
          {chips.map((item, index) => {
            const isClickInProgress = chipIdxClickInProgress === index;

            const suffixIcon =
              isClickInProgress || item.isLoading ? (
                <LoadingIcon />
              ) : item.suffixIcon ? (
                <Icon name={item.suffixIcon} width={16} height={16} />
              ) : undefined;

            const disabled = item.disabled || isClickInProgress;

            if (item.label.type === 'text') {
              return (
                <Chip
                  key={index}
                  label={
                    <Box sx={styles.chipLabel}>
                      {item.label.value}
                      {suffixIcon}
                    </Box>
                  }
                  variant="outlined"
                  clickable={!!item.eventMap?.click}
                  onClick={(e) => onClickChip(e, item, index)}
                  disabled={disabled}
                />
              );
            }
            if (item.label.type === 'avatar') {
              return (
                <Chip
                  key={index}
                  label={
                    <Box sx={styles.chipLabel}>
                      {item.label.name}
                      {!!item.label.indicators && (
                        <IndicatorGroup
                          indicators={item.label.indicators}
                          size={16}
                        />
                      )}
                      {suffixIcon}
                    </Box>
                  }
                  variant="outlined"
                  icon={
                    <SquareAvatar size={16} src={item.label.avatarUrl}>
                      {item.label.name}
                    </SquareAvatar>
                  }
                  clickable={!!item.eventMap?.click}
                  onClick={(e) => onClickChip(e, item, index)}
                  disabled={disabled}
                />
              );
            }
            return null;
          })}
        </Box>
      </ScrollHorizontalContainer>
    </Box>
  );
}
