import { MouseEvent, useState } from 'react';
import { alpha, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { nonBooleanAndNullable } from '@front/helper';
import { Button, SimpleTooltip, SquareAvatar, useTruncated } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { sanitize } from 'dompurify';

import {
  SmallIconListLayoutDetailedButton,
  SmallIconListLayoutDetailedContent,
  SmallIconListLayoutDetailedIcon,
  SmallIconListLayoutDetailedImg,
  SmallIconListLayoutDetailedItem,
  SmallIconListLayoutDetailedLabel,
} from '../types';

const styles = {
  root: {
    px: { xs: 2.5, md: 1.5 },
    minHeight: 32,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns:
      '16px minmax(25%, 1fr) minmax(100px, min-content) auto',
    gap: 1,
  },
  hoverable: {
    '@media (hover:hover)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  icon: {
    flex: '0 0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiTypography-body2': {
      fontSize: 12,
    },
  },
  content: {
    flex: 1,
    '& p': {
      m: 0,
    },
  },
  properties: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttons: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  button: {
    py: 0,
    pl: 0,
    pr: { md: 0.5, xs: 0 },
    gap: 0.5,
    minWidth: { md: 66, xs: 24 },
    minHeight: 24,
    fontSize: 12,
  },
  label: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    borderRadius: 1,
    px: 1,
    fontSize: 16,
    lineHeight: '26px',
  },
  textWrap: {
    minWidth: '1px',
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    maxWidth: '200px',
  },
};
type DetailedIconListItemProps = { item: SmallIconListLayoutDetailedItem };

function ContentItem({
  item,
  row,
}: {
  item: SmallIconListLayoutDetailedContent;
  row?: SmallIconListLayoutDetailedItem;
}) {
  const { containerRef, isTruncated } = useTruncated();

  const { getIaAction } = useIaAction();

  const clickable = !!item.actionMap?.click;

  const handleClick = () => {
    if (!item.actionMap?.click || !row) return;
    getIaAction<SmallIconListLayoutDetailedItem>(
      item.actionMap.click.value
    )?.action(row);
  };

  const props = {
    ...(clickable && { onClick: handleClick }),
  };

  return (
    <Box sx={[styles.textWrap, clickable && styles.clickable]} {...props}>
      {item.html ? (
        <Typography
          variant="body2"
          sx={styles.text}
          dangerouslySetInnerHTML={{ __html: sanitize(item.html) }}
        />
      ) : (
        <Typography ref={containerRef} sx={styles.text} variant="body2">
          {isTruncated ? (
            <SimpleTooltip
              title={item.value}
              slotProps={{
                popper: {
                  sx: styles.tooltip,
                },
              }}
            >
              <span>{item.value}</span>
            </SimpleTooltip>
          ) : (
            item.value
          )}
        </Typography>
      )}
    </Box>
  );
}

function ImgItem({ item }: { item: SmallIconListLayoutDetailedImg }) {
  return (
    <SquareAvatar src={item.value} size={16}>
      {item.value}
    </SquareAvatar>
  );
}

function IconItem({ item }: { item: SmallIconListLayoutDetailedIcon }) {
  return <Icon name={item.value} width={16} height={16} />;
}

function LabelItem({ item }: { item: SmallIconListLayoutDetailedLabel }) {
  return <Box sx={styles.label}>{item.value}</Box>;
}

function ButtonItem({
  item,
  row,
}: {
  item: SmallIconListLayoutDetailedButton;
  row?: SmallIconListLayoutDetailedItem;
}) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { getIaAction } = useIaAction();
  const [loading, setLoading] = useState(false);

  const handleClick = async (ev: MouseEvent) => {
    ev.stopPropagation();
    setLoading(true);
    await getIaAction<SmallIconListLayoutDetailedItem>(item.value)?.action(row);
    setLoading(false);
  };

  const hideText = !mdUp && (item.prefixIcon || item.suffixIcon); // in mobile, if both icon and text are present, we only show the icon
  return (
    <Button
      sx={styles.button}
      onClick={handleClick}
      variant={item.variant}
      color={item.color}
      prefixIcon={item.prefixIcon ? <Icon name={item.prefixIcon} /> : undefined}
      suffixIcon={item.suffixIcon ? <Icon name={item.suffixIcon} /> : undefined}
      size="sm"
      loading={loading}
    >
      {!hideText && item.text}
    </Button>
  );
}

function Item({
  item,
  row,
}: {
  item:
    | SmallIconListLayoutDetailedContent
    | SmallIconListLayoutDetailedIcon
    | SmallIconListLayoutDetailedLabel
    | SmallIconListLayoutDetailedButton
    | SmallIconListLayoutDetailedImg;
  row?: SmallIconListLayoutDetailedItem;
}) {
  switch (item.type) {
    case 'icon':
      return <IconItem item={item} />;
    case 'img':
      return <ImgItem item={item} />;
    case 'label':
      return <LabelItem item={item} />;
    case 'button':
      return <ButtonItem row={row} item={item} />;
    default:
      return <ContentItem row={row} item={item} />;
  }
}

export default function DetailedIconListItem({
  item,
}: DetailedIconListItemProps) {
  const { getIaAction } = useIaAction();

  const hoverable = !!item.actionMap?.hover;
  const clickable = !!item.actionMap?.click;

  const handleClick = () => {
    if (!item.actionMap?.click) return;
    getIaAction<SmallIconListLayoutDetailedItem>(
      item.actionMap.click.value
    )?.action(item);
  };

  const handleMouseEnter = () => {
    if (!item.actionMap?.hover || !item.actionMap.hover?.value) return;
    getIaAction<SmallIconListLayoutDetailedItem>(
      item.actionMap.hover.value
    )?.action(item);
  };

  const props = {
    ...(clickable && { onClick: handleClick }),
    ...(hoverable && { onMouseEnter: handleMouseEnter }),
  };

  const properties = item.properties.filter(nonBooleanAndNullable);
  const showProperties = properties.length > 0;
  const rootGridTemplateColumns = showProperties
    ? {
        gridTemplateColumns:
          '16px minmax(25%, 1fr) minmax(100px, min-content) auto',
      }
    : {
        gridTemplateColumns: '16px minmax(30%, 1fr) auto',
      };

  return (
    <Box
      sx={[styles.root, hoverable && styles.hoverable, rootGridTemplateColumns]}
      {...props}
    >
      <Box sx={styles.icon}>
        <Item item={item.icon} />
      </Box>
      <Box sx={styles.content}>
        <Item item={item.content} row={item} />
      </Box>
      {properties.length > 0 && (
        <Box sx={styles.properties}>
          {properties.map((property, i) => (
            <Item key={i} item={property} />
          ))}
        </Box>
      )}
      <Box sx={styles.buttons}>
        {item.buttons.map((button, i) => (
          <Item key={i} row={item} item={button} />
        ))}
      </Box>
    </Box>
  );
}
