import React, { ReactNode } from 'react';
import ButtonBase, {
  ButtonBaseProps,
  ButtonBaseTypeMap,
} from '@mui/material/ButtonBase';
import { useTheme } from '@mui/material/styles';

import LoadingIcon from '../../../components/LoadingIcon';
import {
  ButtonColorType,
  ButtonSizeType,
  ButtonVariantType,
} from '../Button/types';

import {
  getActiveStyle,
  getDefaultStyle,
  getLoadingStyle,
  getSize,
  getTheme,
} from './utils';

export type EmphasizeButtonProps<
  D extends React.ElementType = ButtonBaseTypeMap['defaultComponent'],
  P = {
    color?: ButtonColorType;
    variant?: ButtonVariantType;
    loading?: boolean;
    suffixIcon?: ReactNode;
    prefixIcon?: ReactNode;
    icon?: boolean;
    size?: ButtonSizeType;
    component?: D;
  }
> = ButtonBaseProps<D, P>;

const styles = {
  root: {
    mr: '6px',
    mb: '6px',
    position: 'relative',
    minHeight: 30,
    '.base-layout-action &': {
      minHeight: { xs: 36, md: 30 },
    },
    minWidth: 30,
    typography: 'button',
    fontWeight: 700,
    alignItems: 'stretch',
    '& svg': {
      display: 'block',
      width: 16,
      height: 16,
    },
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '& .em-button-base': {
          transform: 'translate(4px, 4px)',
        },
      },
    },
    '&:not(:disabled):active': {
      '& .em-button-base': {
        transform: 'translate(4px, 4px)',
      },
    },
    '& .em-button-base': {
      position: 'relative',
      px: 2.5,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 0.5,
      borderRadius: 1,
      transitionDuration: '0.2s',
      transitionProperty: 'transform, color, background',
    },
    '& .em-button-base-text': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .em-button-shadow': {
      position: 'absolute',
      transform: 'translate(6px, 6px)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: '1px solid',
      borderRadius: 1,
    },
  },
  icon: {
    minWidth: 30,
    '& .em-button-base': {
      px: 0,
    },
  },
  loading: {
    '& .em-button-base': {
      transform: 'translate(4px, 4px)',
    },
  },
  disabled: {
    opacity: 0.5,
    '& .em-button-base': {
      transform: 'translate(4px, 4px)',
    },
  },
};

function EmphasizeButtonInner<D extends React.ElementType>(
  {
    sx,
    children,
    icon = false,
    color = 'default',
    variant = 'filled',
    size,
    disabled,
    loading,
    prefixIcon,
    suffixIcon,
    ...rest
  }: EmphasizeButtonProps<D>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const { palette } = useTheme();
  const buttonTheme = getTheme(palette, color, variant);

  const defaultStyle = getDefaultStyle(buttonTheme);
  const loadingStyle = loading ? getLoadingStyle(buttonTheme) : false;
  const activeStyle = disabled || loading ? false : getActiveStyle(buttonTheme);
  const minWidth = getSize(size);

  return (
    <ButtonBase
      ref={ref}
      sx={[
        styles.root,
        defaultStyle,
        activeStyle,
        loadingStyle,
        { minWidth },
        icon && styles.icon,
        loading && styles.loading,
        disabled && !loading && styles.disabled,
        ...sxProps,
      ]}
      disableTouchRipple
      disableRipple
      disabled={disabled || loading}
      {...rest}
    >
      <span className="em-button-shadow" />
      <div className="em-button-base">
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            {!!prefixIcon && prefixIcon}
            <span className="em-button-base-text">{children}</span>
            {!!suffixIcon && suffixIcon}
          </>
        )}
      </div>
    </ButtonBase>
  );
}

const EmphasizeButton = React.forwardRef(EmphasizeButtonInner) as <
  D extends React.ElementType = 'button'
>(
  props: EmphasizeButtonProps<D> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof EmphasizeButtonInner<D>>;

export default EmphasizeButton;
