import React from 'react';

export default function OtherRest({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.1111 6.6V8.4C10.0648 8.40004 9.04162 8.71169 8.16895 9.29614C7.29628 9.8806 6.61267 10.712 6.20325 11.687C5.79382 12.6619 5.67666 13.7372 5.86636 14.779C6.05605 15.8208 6.54422 16.7832 7.27008 17.5462C7.99594 18.3092 8.92743 18.8391 9.94857 19.0701C10.9697 19.3011 12.0354 19.2228 13.0129 18.8451C13.9905 18.4674 14.8367 17.8068 15.4462 16.9458C16.0557 16.0847 16.4017 15.0611 16.4409 14.0025L16.4444 13.8H18.2222C18.2222 15.7096 17.473 17.5409 16.1394 18.8912C14.8058 20.2414 12.9971 21 11.1111 21C9.22513 21 7.41639 20.2414 6.0828 18.8912C4.7492 17.5409 4 15.7096 4 13.8C4 9.8985 7.18222 6.6 11.1111 6.6ZM20 3V4.8L15.2649 10.2H20V12H12.8889V10.2L17.6231 4.8H12.8889V3H20Z"
        fill="currentColor"
      />
    </svg>
  );
}
