import { useEffect, useState } from 'react';
import { useLatestValueRef } from '@front/helper';

export default function useVisibilityChange({
  onVisibleChange,
}: {
  onVisibleChange?: (isVisible: boolean) => void;
} = {}) {
  const [isVisible, setIsVisible] = useState(
    document.visibilityState === 'visible'
  );

  const onVisibleChanelRef = useLatestValueRef(onVisibleChange);

  useEffect(() => {
    const onVisibilityChange = () => {
      const newIsVisible = document.visibilityState === 'visible';
      if (newIsVisible !== isVisible) {
        setIsVisible(newIsVisible);
        onVisibleChanelRef.current?.(newIsVisible);
      }
    };
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [isVisible, onVisibleChanelRef]);

  return { isVisible };
}
