import React from 'react';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
  IconButtonTypeMap,
} from '@mui/material/IconButton';
import { alpha, Theme } from '@mui/material/styles';

import LoadingIcon from '../../../components/LoadingIcon';

export type IconButtonProps<
  D extends React.ElementType = IconButtonTypeMap['defaultComponent'],
  P = {
    component?: D;
    customSize?: number;
    selected?: boolean;
    loading?: boolean;
  }
> = MuiIconButtonProps<D, P>;

const styles = {
  root: {
    p: 0,
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        color: 'text.primary',
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },

    '&:not(:disabled):active': {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.text.primary, 0.05),
    },
  },
  selected: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    '&:disabled': {
      backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
  loading: {
    '&:disabled': {
      backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      opacity: 1,
    },
  },
};

const getBorderRadius = (size: number) => {
  if (size < 32) return 1;
  if (size < 40) return '6px';
  return 2;
};

function IconButtonInner<D extends React.ElementType = 'button'>(
  {
    sx,
    children,
    customSize = 36,
    disabled,
    loading,
    selected = false,
    ...rest
  }: IconButtonProps<D>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const borderRadius = getBorderRadius(customSize);
  return (
    <MuiIconButton
      ref={ref}
      sx={[
        styles.root,
        selected && styles.selected,
        loading && styles.loading,
        {
          width: customSize,
          height: customSize,
          borderRadius,
          '& svg, & .loading-icon': {
            display: 'block',
            width: customSize - 8,
            height: customSize - 8,
          },
        },
        ...sxProps,
      ]}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? <LoadingIcon /> : children}
    </MuiIconButton>
  );
}

const IconButton = React.forwardRef(IconButtonInner) as <
  D extends React.ElementType = IconButtonTypeMap['defaultComponent']
>(
  props: IconButtonProps<D> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof IconButtonInner>;

export default IconButton;
