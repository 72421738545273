import React from 'react';
import { v4 } from 'uuid';

export default function LeagueBadgeCosmus({ ...rest }) {
  const id = v4();
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#clip0_${id})`}>
        <g filter={`url(#filter0_d_${id})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M73.9645 23.9725L60.0275 10.0355C59.2971 9.30508 58.3064 8.89474 57.2735 8.89474L26.7265 8.89474C25.6936 8.89474 24.7029 9.30507 23.9725 10.0355L10.0355 23.9725C9.30507 24.7029 8.89474 25.6936 8.89474 26.7265V57.2735C8.89474 58.3064 9.30507 59.2971 10.0355 60.0275L23.9725 73.9645C24.7029 74.6949 25.6936 75.1053 26.7265 75.1053H57.2735C58.3064 75.1053 59.2971 74.6949 60.0275 73.9645L73.9645 60.0275C74.6949 59.2971 75.1053 58.3064 75.1053 57.2735V26.7265C75.1053 25.6936 74.6949 24.7029 73.9645 23.9725ZM62.7815 7.28149C61.3207 5.82068 59.3394 5 57.2735 5H26.7265C24.6606 5 22.6793 5.82067 21.2185 7.28148L7.28148 21.2185C5.82068 22.6793 5 24.6606 5 26.7265V57.2735C5 59.3394 5.82067 61.3207 7.28148 62.7815L21.2185 76.7185C22.6793 78.1793 24.6606 79 26.7265 79H57.2735C59.3394 79 61.3207 78.1793 62.7815 76.7185L76.7185 62.7815C78.1793 61.3207 79 59.3394 79 57.2735V26.7265C79 24.6606 78.1793 22.6793 76.7185 21.2185L62.7815 7.28149Z"
            fill={`url(#paint0_linear_${id})`}
          />
        </g>
        <g style={{ mixBlendMode: 'screen' }}>
          <mask
            id={`mask0_${id}`}
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="5"
            y="5"
            width="74"
            height="74"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M73.9645 23.9725L60.0275 10.0355C59.2971 9.30508 58.3064 8.89474 57.2735 8.89474L26.7265 8.89474C25.6936 8.89474 24.7029 9.30507 23.9725 10.0355L10.0355 23.9725C9.30507 24.7029 8.89474 25.6936 8.89474 26.7265V57.2735C8.89474 58.3064 9.30507 59.2971 10.0355 60.0275L23.9725 73.9645C24.7029 74.6949 25.6936 75.1053 26.7265 75.1053H57.2735C58.3064 75.1053 59.2971 74.6949 60.0275 73.9645L73.9645 60.0275C74.6949 59.2971 75.1053 58.3064 75.1053 57.2735V26.7265C75.1053 25.6936 74.6949 24.7029 73.9645 23.9725ZM62.7815 7.28149C61.3207 5.82068 59.3394 5 57.2735 5H26.7265C24.6606 5 22.6793 5.82067 21.2185 7.28148L7.28148 21.2185C5.82068 22.6793 5 24.6606 5 26.7265V57.2735C5 59.3394 5.82067 61.3207 7.28148 62.7815L21.2185 76.7185C22.6793 78.1793 24.6606 79 26.7265 79H57.2735C59.3394 79 61.3207 78.1793 62.7815 76.7185L76.7185 62.7815C78.1793 61.3207 79 59.3394 79 57.2735V26.7265C79 24.6606 78.1793 22.6793 76.7185 21.2185L62.7815 7.28149Z"
              fill={`url(#paint1_linear_${id})`}
            />
          </mask>
          <g mask={`url(#mask0_${id})`}>
            <g filter={`url(#filter1_f_${id})`}>
              <rect
                x="61.6758"
                y="-0.390381"
                width="30.6687"
                height="87.7759"
                transform="rotate(45 61.6758 -0.390381)"
                fill="#FFA6B5"
              />
            </g>
          </g>
        </g>
        <g filter={`url(#filter2_d_${id})`}>
          <path
            d="M62 18C62.9338 20.8393 65.1607 23.0662 68 24C65.1607 24.9338 62.9338 27.1607 62 30C61.0662 27.1607 58.8393 24.9338 56 24C58.8393 23.0662 61.0662 20.8393 62 18Z"
            fill="#FF8298"
          />
        </g>
        <g filter={`url(#filter3_d_${id})`}>
          <path
            d="M20.5 50C21.2003 52.1295 22.8705 53.7997 25 54.5C22.8705 55.2003 21.2003 56.8705 20.5 59C19.7997 56.8705 18.1295 55.2003 16 54.5C18.1295 53.7997 19.7997 52.1295 20.5 50Z"
            fill="#FF8298"
          />
        </g>
        <g filter={`url(#filter4_d_${id})`}>
          <rect
            x="46.502"
            y="20.2393"
            width="13.7596"
            height="41.2789"
            rx="6.87981"
            transform="rotate(30 46.502 20.2393)"
            fill="#FF4B6C"
          />
          <ellipse
            cx="35.2604"
            cy="53.4695"
            rx="6.87981"
            ry="6.87982"
            transform="rotate(30 35.2604 53.4695)"
            fill="#FF8298"
          />
        </g>
      </g>
      <defs>
        <filter
          id={`filter0_d_${id}`}
          x="-1"
          y="-1"
          width="86"
          height="86"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.976471 0 0 0 0 0.32549 0 0 0 0 0.447059 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result={`effect1_dropShadow_${id}`}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={`effect1_dropShadow_${id}`}
            result="shape"
          />
        </filter>
        <filter
          id={`filter1_f_${id}`}
          x="-4.39062"
          y="-4.39038"
          width="91.752"
          height="91.7529"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result={`effect1_foregroundBlur_${id}`}
          />
        </filter>
        <filter
          id={`filter2_d_${id}`}
          x="51"
          y="13"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.976471 0 0 0 0 0.32549 0 0 0 0 0.447059 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result={`effect1_dropShadow_${id}`}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={`effect1_dropShadow_${id}`}
            result="shape"
          />
        </filter>
        <filter
          id={`filter3_d_${id}`}
          x="11"
          y="45"
          width="19"
          height="19"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.976471 0 0 0 0 0.32549 0 0 0 0 0.447059 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result={`effect1_dropShadow_${id}`}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={`effect1_dropShadow_${id}`}
            result="shape"
          />
        </filter>
        <filter
          id={`filter4_d_${id}`}
          x="2"
          y="2"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.976471 0 0 0 0 0.32549 0 0 0 0 0.447059 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result={`effect1_dropShadow_${id}`}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={`effect1_dropShadow_${id}`}
            result="shape"
          />
        </filter>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="59.5263"
          y1="6.46052"
          x2="24.9605"
          y2="71.6974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4769" />
          <stop offset="0.795399" stopColor="#FF8298" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_${id}`}
          x1="59.5263"
          y1="6.46052"
          x2="24.9605"
          y2="71.6974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4D00CA" />
          <stop offset="1" stopColor="#9D54FC" />
        </linearGradient>
        <clipPath id={`clip0_${id}`}>
          <rect width="84" height="84" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
