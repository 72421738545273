import { useIaRender } from '@lib/ia/src/core/IaRender/useIaRender';
import { CustomizeItemConfig } from '@lib/ia/src/layouts/ChannelLayout/types';

export default function CustomizeItem({
  value,
  metadata,
}: CustomizeItemConfig) {
  const { getIaRender } = useIaRender();

  const render = getIaRender(value);

  if (render) {
    return render(metadata || {});
  }

  return null;
}
