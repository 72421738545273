import React from 'react';

export default function OtherLoudspeaker({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.2778 3C20.1924 3 20.1046 3.01575 20.0169 3.05174L7.04949 8.26209H3.35996C3.16198 8.26209 3 8.42857 3 8.63555V15.3622C3 15.5692 3.16198 15.7357 3.35996 15.7357H5.64567C5.56243 15.9966 5.51969 16.2733 5.51969 16.5546C5.51969 18.0371 6.73003 19.243 8.21935 19.243C9.46569 19.243 10.5163 18.3971 10.8268 17.2542L20.0191 20.9483C20.1069 20.982 20.1946 21 20.2801 21C20.6603 21 21 20.6805 21 20.2531V3.74691C20.9977 3.31946 20.6603 3 20.2778 3ZM8.21935 17.6299C7.62317 17.6299 7.13948 17.1485 7.13948 16.5546C7.13948 16.3026 7.22722 16.0619 7.38695 15.8706L9.29696 16.6378C9.25197 17.1912 8.78628 17.6299 8.21935 17.6299Z"
        fill="currentColor"
      />
    </svg>
  );
}
