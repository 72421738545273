import React from 'react';

export default function TestScratch({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.8498 3.5L21 6.57182L7.66114 19.5789L3 20.5L4.51095 16.507L17.8498 3.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
