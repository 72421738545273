import React from 'react';
import { AnswerContentType, ExamMode } from '@lib/web/apis';
import { FreeResponseInput } from '@lib/web/ui';

import AnswerChoiceItems from '../../../../../../AnswerChoiceItems';
import usePracticeLayout from '../../../../../hooks/usePracticeLayout';
import usePracticeQuestion from '../../../../../hooks/usePracticeQuestion';
import usePracticeQuestionAnswers from '../../../../../hooks/usePracticeQuestionAnswers';
import usePracticeQuestionEvent from '../../../../../hooks/usePracticeQuestionEvent';
import usePracticeQuestionResult from '../../../../../hooks/usePracticeQuestionResult';
import usePracticeQuestionStatus from '../../../../../hooks/usePracticeQuestionStatus';
import usePracticeStaticData from '../../../../../hooks/usePracticeStaticData';

export type QuizAnswerFreeResponseProps = {
  readonly?: boolean;
};

export default function QuizAnswerFreeResponse({
  readonly,
}: QuizAnswerFreeResponseProps) {
  const { mode } = usePracticeStaticData();
  const { onChangeAnswers } = usePracticeQuestionEvent();
  const { creatorQuestionInfo } = usePracticeQuestion();
  const { answers: currentAnswers } = usePracticeQuestionAnswers();
  const { isSubmitted, startAt } = usePracticeQuestionStatus();
  const { isCorrect, correctAnswerValues } = usePracticeQuestionResult();
  const { showKeyboard } = usePracticeLayout();

  const userValue = currentAnswers[0] || '';
  const correctValue = correctAnswerValues[0];

  if (!isSubmitted) {
    return (
      <FreeResponseInput
        value={userValue}
        isMathematical={
          creatorQuestionInfo?.answerContentType ===
          AnswerContentType.Mathematical
        }
        wordLimitMax={{
          value: creatorQuestionInfo?.isOfferWordLimit
            ? creatorQuestionInfo.freeResponseAnswerWordLimitMax
            : 0,
          // XXX: when exceed max, we need to notify window bottom's word limit information,
          //      use the second value as an event is a little hack,
          //      another way it to use redux, but I'd like to reserve redux state for general cases, not this special use case
          onExceed: () =>
            mode === ExamMode.MockExam &&
            onChangeAnswers([userValue, 'show-error']),
          allowExceed: mode !== ExamMode.MockExam,
        }}
        onChange={(newValue: string) => onChangeAnswers([newValue])}
        disabled={!startAt}
        readonly={readonly}
        inputMode={showKeyboard ? 'none' : 'text'}
        autoFocus
      />
    );
  }

  if (isCorrect) {
    return (
      <AnswerChoiceItems>
        <AnswerChoiceItems.Item
          type="correct"
          valueTitle="Your Answer"
          value={correctValue}
        />
      </AnswerChoiceItems>
    );
  }

  return (
    <AnswerChoiceItems>
      <AnswerChoiceItems.Item
        type="incorrect"
        valueTitle="Your Answer"
        value={userValue}
      />
      <AnswerChoiceItems.Item
        type="answer"
        valueTitle="Correct Answer"
        value={correctValue}
      />
    </AnswerChoiceItems>
  );
}
