export const enum WorkflowViewSlug {
  ListCenterIaAiWorkflowDefault = 'list_center_ia_aiworkflow_default',
  ListCenterIaAiWorkflowSingle = 'list_center_ia_aiworkflow_single',
}

export enum WorkflowStatus {
  ACTIVE = 1,
  INACTIVE = -1,
  UNKNOWN = 0,
}

export enum WorkflowRecipeTriggerType {
  WORKFLOW_RUN = 0,
}

export enum WorkflowRecipeActionType {
  FINISH = 0,
  NEXT_RECIPE = 1,
}

export enum WorkflowRecipeStatus {
  ACTIVE = 1,
  INACTIVE = -1,
  UNKNOWN = 0,
}

export enum WorkflowPromptStatus {
  ACTIVE = 1,
  INACTIVE = -1,
  UNKNOWN = 0,
}

export enum WorkflowExternalResourceType {
  UNKNOWN = 0,
  RECIPE_EXTERNAL_SOURCE = 1,
  PROMPT_EXTERNAL_SOURCE = 2,
}

export enum WorkflowExternalResourceStatus {
  ACTIVE = 1,
  INACTIVE = -1,
  UNKNOWN = 0,
}
