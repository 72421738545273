import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { MaskIcon, SquareAvatar, SquareAvatarStatus } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { ThreadViewType } from '@lib/web/apis';
import { getAgentStatus, getUserStatus, useOnlineStatus } from '@lib/web/hooks';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';

import { useThread } from '../hooks/core/useThread';

const avatarSizeMap = {
  md: {
    singleAvatar: 40,
    multiAvatar: 30,
    multiAvatarBadge: 25,
    multiAvatarBadgeFontSize: 14,
  },
  sm: {
    singleAvatar: 16,
    multiAvatar: 12,
    multiAvatarBadge: 10,
    multiAvatarBadgeFontSize: 7,
  },
};

const iconSizeMap = {
  md: 40,
  sm: 16,
};

const SHOW_ONLINE_STATUS_VIEW_TYPES = [
  ThreadViewType.DmOneOnOne,
  ThreadViewType.DmGroup,
];

const styles = {
  root: {
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'text.primary',
    color: 'background.darker',
    typography: 'caption',
  },
};

export type DirectMessagesViewIconProps = {
  view: GetThreadViewRes;
  size: 'md' | 'sm';
  onClick?: (e: MouseEvent) => void;
};

export default function ThreadViewIcon({
  view,
  size,
  onClick,
}: DirectMessagesViewIconProps) {
  const { t } = useTranslation('common');
  const { viewIcon, viewMemberIds, viewAgentIds } = useThreadViewDetail(view);
  const { getThreadUser } = useThread();
  const { getUserOnlineStatus, getAgentOnlineStatus } = useOnlineStatus();

  if (!viewIcon) return null;

  if (viewIcon.type === 'icon' && viewIcon.icon) {
    const iconSize = iconSizeMap[size];
    return <Icon name={viewIcon.icon} width={iconSize} height={iconSize} />;
  }

  if (viewIcon.type === 'emoji') return viewIcon.emoji;

  if (viewIcon.type === 'avatar') {
    const avatarBadgeCount = viewIcon.avatarBadgeCount || 0;
    const isMultiAvatar = avatarBadgeCount > 1;
    const avatarSize = avatarSizeMap[size];

    const isSelfView = viewMemberIds.length === 1 && viewAgentIds.length === 0; // assume only me thread view has only 1 user
    const isAgentDMView =
      viewMemberIds.length === 2 && viewAgentIds.length === 1; // assume only me and 1 agent in view

    const user = getThreadUser(
      isSelfView ? viewMemberIds[0] : viewMemberIds[1]
    );
    const agent = getThreadUser(isAgentDMView ? viewMemberIds[1] : undefined);

    const userId = user?.type === 'user' ? user.userId : undefined;
    const status =
      user?.type === 'user'
        ? getUserStatus(getUserOnlineStatus(userId))
        : getAgentStatus(getAgentOnlineStatus());
    const statusInfo = {
      [SquareAvatarStatus.Away]:
        agent?.type === 'agent'
          ? t(
              'agent.status.away',
              '{{name}}’s AI Model is currently unavailable',
              { name: agent.name }
            )
          : undefined,
      [SquareAvatarStatus.Busy]:
        user?.type === 'user' ? t('user.status.busy') : undefined,
    };

    return (
      <Box
        sx={[
          styles.root,
          {
            mr: `${
              isMultiAvatar
                ? avatarSize.multiAvatarBadge - avatarSize.multiAvatar / 2
                : 0
            }px`,
          },
        ]}
        onClick={onClick}
      >
        <SquareAvatar
          size={
            isMultiAvatar ? avatarSize.multiAvatar : avatarSize.singleAvatar
          }
          src={viewIcon.avatar}
          title={viewIcon.avatarTitle}
          showIndicator={
            !isMultiAvatar && SHOW_ONLINE_STATUS_VIEW_TYPES.includes(view.type)
          }
          status={status}
          statusInfo={statusInfo}
        >
          {viewIcon.avatarTitle}
        </SquareAvatar>
        {isMultiAvatar && (
          <MaskIcon
            sx={[
              styles.badge,
              {
                width: avatarSize.multiAvatarBadge,
                height: avatarSize.multiAvatarBadge,
                top: avatarSize.multiAvatar / 2,
                left: avatarSize.multiAvatar / 2,
                fontSize: avatarSize.multiAvatarBadgeFontSize,
              },
            ]}
          >
            {avatarBadgeCount > 9 ? '9+' : avatarBadgeCount}
          </MaskIcon>
        )}
      </Box>
    );
  }

  return null;
}
