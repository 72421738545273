import React from 'react';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useBaseLayout } from '@front/ui';

import usePracticeFlowEvent from '../../../hooks/usePracticeFlowEvent';
import usePracticeLayout from '../../../hooks/usePracticeLayout';
import usePracticeQuestionAnswers from '../../../hooks/usePracticeQuestionAnswers';
import usePracticeQuestionStatus from '../../../hooks/usePracticeQuestionStatus';
import usePracticeStaticData from '../../../hooks/usePracticeStaticData';
import usePracticeStatus from '../../../hooks/usePracticeStatus';
import useQuizKeyboardShortcut from '../../../hooks/useQuizKeyboardShortcut';

const styles = {
  root: {
    mt: 1,
    px: 2.5,
    textAlign: 'right',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    span: {
      color: 'text.primary',
    },
  },
};

export default function Hint() {
  const { rightPanelOpened, rightPanelTarget } = useBaseLayout();
  const { isPaused, isQuestion } = usePracticeStatus();
  const { isOpenTimer } = usePracticeLayout();
  const { answers } = usePracticeQuestionAnswers();
  const hasAnswer = answers.length > 0;

  const { isPendingSubmit, isLoading, isSubmitted, startAt } =
    usePracticeQuestionStatus();

  const {
    onNextQuestion,
    onPreviousQuestion,
    onLazySubmitQuestion,
    onSubmitQuestion,
    onResumeQuestion,
    onPauseQuestion,
  } = usePracticeFlowEvent();
  const { panelKeys } = usePracticeStaticData();

  useQuizKeyboardShortcut({
    goPreviousQuestion: onPreviousQuestion,
    goNextQuestion: onNextQuestion,
    resumeQuiz: onResumeQuestion,
    pauseQuiz: onPauseQuestion,
    lazySubmit: onLazySubmitQuestion,
    directSubmit: onSubmitQuestion,
    isPaused,
    hasAnswer,
    isSubmitted,
    isLoading,
    isPendingSubmit,
    startAt,
    isOpenTimer,
  });

  const solutionOpened =
    rightPanelOpened && rightPanelTarget === panelKeys.solution;

  if (isQuestion) {
    return (
      <Box sx={styles.root}>
        <Typography variant="caption" component="p">
          Press <span>←</span> or <span>→</span> to skip, <span>Enter</span> to
          submit
        </Typography>
      </Box>
    );
  }

  if (solutionOpened) {
    return (
      <Box sx={styles.root}>
        <Typography variant="caption" component="p">
          Press <span>←</span> or <span>→</span> to navigate the question
        </Typography>
      </Box>
    );
  }

  if (isSubmitted) {
    return (
      <Box sx={styles.root}>
        <Typography variant="caption" component="p">
          Press <span>←</span> or <span>→</span> to navigate the question,{' '}
          <span>Ctrl + Shift + S</span> to open Solution
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={styles.root}>
      <Typography variant="caption" component="p">
        Press <span>←</span> or <span>→</span> to skip, <span>Enter</span> to
        submit
      </Typography>
    </Box>
  );
}
