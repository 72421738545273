import React from 'react';

export default function ProfileBecomeTutor({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.3125 6.81257V4H19.1875V6.81257L22 6.81257V8.68757H19.1875V11.5H17.3125V8.68757H14.5V6.81257H17.3125Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M13.25 6.47956L12.0491 6.03027C11.9413 5.98991 11.823 5.98991 11.7152 6.03027L2.32164 9.54476C1.90175 9.70186 1.89009 10.3085 2.30361 10.4825L11.6972 14.4363C11.8157 14.4862 11.9486 14.4862 12.0672 14.4363L16.565 12.5325C16.1161 12.4203 16.0625 11.9842 16.0625 11.5294V10.3971C16.0625 9.94231 16.0625 9.93476 16.0625 9.93476H14.25C13.6977 9.93476 13.25 9.4871 13.25 8.93481C13.25 8.47303 13.25 7.98727 13.25 7.75007V6.47956Z"
        fill="currentColor"
      />
      <path
        d="M11.6897 15.9375L5.72262 13.3063C5.40019 13.1642 5.04152 13.4075 5.04152 13.7684V16.6963C5.04152 16.8975 5.15801 17.0792 5.33767 17.1584L11.6897 19.9594C11.8126 20.0135 11.9517 20.0135 12.0746 19.9594L18.4267 17.1584C18.6063 17.0792 18.7228 16.8975 18.7228 16.6963V13.7684C18.7228 13.4075 18.3641 13.1642 18.0417 13.3063L12.0746 15.9375C11.9517 15.9917 11.8126 15.9917 11.6897 15.9375Z"
        fill="currentColor"
      />
      <path
        d="M21.6545 12.0005C21.6545 11.7229 21.4357 11.4978 21.1659 11.4978C20.896 11.4978 20.6773 11.7229 20.6773 12.0005V17.3223L20.5761 17.4265C20.2503 17.7616 20.2503 18.305 20.5761 18.6402C20.9018 18.9753 21.4299 18.9753 21.7557 18.6402C22.0814 18.305 22.0814 17.7616 21.7557 17.4265L21.6545 17.3223V12.0005Z"
        fill="currentColor"
      />
    </svg>
  );
}
