import nextId from 'react-id-generator';
export default function BadgeUnlimitedPlan({ ...rest }) {
  const id = nextId('badge-unlimited-plan');
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.5095 2.69631C11.7485 2.35703 12.2515 2.35703 12.4905 2.69631L13.6529 4.34633C13.8198 4.58333 14.1323 4.66706 14.3954 4.5453L16.227 3.69751C16.6037 3.52319 17.0393 3.77469 17.0766 4.18802L17.2583 6.19817C17.2843 6.48688 17.5131 6.71566 17.8018 6.74174L19.812 6.92336C20.2253 6.9607 20.4768 7.39633 20.3025 7.77295L19.4547 9.60459C19.3329 9.86767 19.4167 10.1802 19.6537 10.3471L21.3037 11.5095C21.643 11.7485 21.643 12.2515 21.3037 12.4905L19.6537 13.6529C19.4167 13.8198 19.3329 14.1323 19.4547 14.3954L20.3025 16.227C20.4768 16.6037 20.2253 17.0393 19.812 17.0766L17.8018 17.2583C17.5131 17.2843 17.2843 17.5131 17.2583 17.8018L17.0766 19.812C17.0393 20.2253 16.6037 20.4768 16.227 20.3025L14.3954 19.4547C14.1323 19.3329 13.8198 19.4167 13.6529 19.6537L12.4905 21.3037C12.2515 21.643 11.7485 21.643 11.5095 21.3037L10.3471 19.6537C10.1802 19.4167 9.86767 19.3329 9.60459 19.4547L7.77295 20.3025C7.39633 20.4768 6.9607 20.2253 6.92336 19.812L6.74174 17.8018C6.71566 17.5131 6.48688 17.2843 6.19817 17.2583L4.18802 17.0766C3.77469 17.0393 3.52319 16.6037 3.69751 16.227L4.5453 14.3954C4.66706 14.1323 4.58333 13.8198 4.34633 13.6529L2.69631 12.4905C2.35703 12.2515 2.35703 11.7485 2.69631 11.5095L4.34633 10.3471C4.58333 10.1802 4.66706 9.86767 4.5453 9.60459L3.69751 7.77295C3.52319 7.39633 3.77469 6.9607 4.18802 6.92336L6.19817 6.74174C6.48688 6.71566 6.71566 6.48688 6.74174 6.19817L6.92336 4.18802C6.9607 3.77469 7.39633 3.52319 7.77295 3.69751L9.60459 4.5453C9.86767 4.66706 10.1802 4.58333 10.3471 4.34633L11.5095 2.69631Z"
        fill={`url(#${id})`}
      />
      <rect
        x="13.2607"
        y="5.90674"
        width="3.8527"
        height="11.5581"
        rx="1.92635"
        transform="rotate(30 13.2607 5.90674)"
        fill="white"
        fillOpacity="0.5"
      />
      <ellipse
        cx="10.1128"
        cy="15.2115"
        rx="1.92635"
        ry="1.92635"
        transform="rotate(30 10.1128 15.2115)"
        fill="white"
      />
      <defs>
        <linearGradient
          id={id}
          x1="2"
          y1="2"
          x2="22.867"
          y2="2.94968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D1FF" />
          <stop offset="0.484375" stopColor="#0051F9" />
          <stop offset="1" stopColor="#7000FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
