import Box from '@mui/material/Box';

import { DisplayTableLayoutHeaderEmptyCell } from '../../types';

export type IaDisplayTableEmptyHeaderCellProps =
  DisplayTableLayoutHeaderEmptyCell;

export default function IaDisplayTableEmptyHeaderCell() {
  return <Box />;
}
