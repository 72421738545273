import React from 'react';

export default function TradingBackward({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 6V2L7 7L12 12V8C15.31 8 18 10.69 18 14C18 17.31 15.31 20 12 20C8.69 20 6 17.31 6 14H4C4 18.42 7.58 22 12 22C16.42 22 20 18.42 20 14C20 9.58 16.42 6 12 6Z"
        fill="currentColor"
      />
      <path
        d="M10.9 17H10.05V13.74L9.04 14.05V13.36L10.81 12.73H10.9V17Z"
        fill="currentColor"
      />
      <path
        d="M15.18 15.24C15.18 15.56 15.15 15.84 15.08 16.06C15.01 16.28 14.91 16.48 14.79 16.63C14.67 16.78 14.51 16.89 14.34 16.96C14.17 17.03 13.97 17.06 13.75 17.06C13.53 17.06 13.34 17.03 13.16 16.96C12.98 16.89 12.83 16.78 12.7 16.63C12.57 16.48 12.47 16.29 12.4 16.06C12.33 15.83 12.29 15.56 12.29 15.24V14.5C12.29 14.18 12.32 13.9 12.39 13.68C12.46 13.46 12.56 13.26 12.68 13.11C12.8 12.96 12.96 12.85 13.13 12.78C13.3 12.71 13.5 12.68 13.72 12.68C13.94 12.68 14.13 12.71 14.31 12.78C14.49 12.85 14.64 12.96 14.77 13.11C14.9 13.26 15 13.45 15.07 13.68C15.14 13.91 15.18 14.18 15.18 14.5V15.24ZM14.33 14.38C14.33 14.19 14.32 14.03 14.29 13.9C14.26 13.77 14.22 13.67 14.17 13.59C14.12 13.51 14.06 13.45 13.98 13.42C13.9 13.39 13.82 13.37 13.73 13.37C13.64 13.37 13.55 13.39 13.48 13.42C13.41 13.45 13.34 13.51 13.29 13.59C13.24 13.67 13.2 13.77 13.17 13.9C13.14 14.03 13.13 14.19 13.13 14.38V15.35C13.13 15.54 13.14 15.7 13.17 15.83C13.2 15.96 13.24 16.07 13.29 16.15C13.34 16.23 13.4 16.29 13.48 16.32C13.56 16.35 13.64 16.37 13.73 16.37C13.82 16.37 13.91 16.35 13.98 16.32C14.05 16.29 14.12 16.23 14.17 16.15C14.22 16.07 14.26 15.96 14.28 15.83C14.3 15.7 14.32 15.54 14.32 15.35V14.38H14.33Z"
        fill="currentColor"
      />
    </svg>
  );
}
