export const enum LeagueZone {
  Unknown = 0,
  Promotion = 1,
  Stay = 2,
  Demotion = 3,
}

export const enum LeagueTier {
  Alpha = 1,
  Prima = 2,
  Centurion = 3,
  Spectus = 4,
  Legatus = 5,
  Nocturnus = 6,
  Incendium = 7,
  Cosmus = 8,
  Maximus = 9,
  Commandus = 10,
}

export const enum LeagueStatus {
  Unknown = 0,
  Active = 1,
  Inactive = -1,
}
