import React from 'react';

export default function SocialMediaWhatsapp({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 3C7.02975 3 3 7.02975 3 12C3 13.6884 3.47426 15.2626 4.2832 16.6128L3.08057 21L7.56152 19.8237C8.87202 20.5688 10.3849 21 12 21C16.9703 21 21 16.9703 21 12C21 7.02975 16.9703 3 12 3ZM8.91943 7.80176C9.06568 7.80176 9.21595 7.80087 9.3457 7.80762C9.5062 7.81137 9.68089 7.82312 9.84814 8.19287C10.0469 8.63237 10.4797 9.73493 10.5352 9.84668C10.5907 9.95843 10.63 10.0901 10.5527 10.2363C10.4792 10.3863 10.441 10.4771 10.333 10.6099C10.2213 10.7389 10.0988 10.8991 9.99756 10.9966C9.88581 11.1083 9.77041 11.2309 9.89941 11.4536C10.0284 11.6764 10.4764 12.4066 11.1387 12.9961C11.9899 13.7566 12.7081 13.9903 12.9316 14.1021C13.1551 14.2138 13.2846 14.1964 13.4136 14.0464C13.5463 13.9001 13.9711 13.3983 14.1211 13.1748C14.2673 12.9513 14.4166 12.99 14.6191 13.0635C14.8246 13.137 15.9205 13.6768 16.144 13.7886C16.3675 13.9003 16.5141 13.9556 16.5703 14.0464C16.6281 14.1401 16.6281 14.5864 16.4429 15.1069C16.2576 15.6267 15.3479 16.1293 14.9399 16.1646C14.5282 16.2028 14.1439 16.3496 12.2637 15.6094C9.99567 14.7161 8.56536 12.3932 8.45361 12.2432C8.34186 12.0969 7.54541 11.0354 7.54541 9.94043C7.54541 8.84168 8.12149 8.30358 8.32324 8.08008C8.52874 7.85658 8.76943 7.80176 8.91943 7.80176Z"
        fill="currentColor"
      />
    </svg>
  );
}
