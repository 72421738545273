import React from 'react';

export default function TextEditorBlankSpace({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2 11.1467C2.44727 11.1467 2.77031 11.0213 2.9691 10.7707C3.16788 10.52 3.26728 10.1921 3.26728 9.78719V7.60331C3.26728 7.18871 3.32195 6.82231 3.43128 6.50413C3.54061 6.17631 3.71952 5.90152 3.96801 5.67975C4.22643 5.45799 4.56437 5.28926 4.98183 5.17355C5.40923 5.05785 5.93602 5 6.5622 5H6.86038V6.72107H6.48766C6.04038 6.72107 5.73723 6.81749 5.5782 7.01033C5.42911 7.20317 5.35456 7.50207 5.35456 7.90703V9.83058C5.35456 10.332 5.28498 10.7562 5.14583 11.1033C5.00668 11.4504 4.72838 11.7493 4.31092 12C4.72838 12.2507 5.00668 12.5496 5.14583 12.8967C5.28498 13.2438 5.35456 13.668 5.35456 14.1694V16.093C5.35456 16.4979 5.42911 16.7968 5.5782 16.9897C5.73723 17.1825 6.04038 17.2789 6.48766 17.2789H6.86038V19H6.5622C5.93602 19 5.40923 18.9421 4.98183 18.8264C4.56437 18.7107 4.22643 18.542 3.96801 18.3202C3.71952 18.0985 3.54061 17.8237 3.43128 17.4959C3.32195 17.1777 3.26728 16.8113 3.26728 16.3967V14.2128C3.26728 13.8079 3.16788 13.48 2.9691 13.2293C2.77031 12.9787 2.44727 12.8533 2 12.8533V11.1467Z"
        fill="currentColor"
      />
      <path
        d="M11.605 7.90703C11.2472 7.90703 10.9093 7.95041 10.5912 8.03719C10.2731 8.12397 9.92029 8.26377 9.53265 8.45661L8.90647 6.82231C9.2941 6.58127 9.75132 6.39807 10.2781 6.27273C10.8049 6.13774 11.3217 6.07025 11.8287 6.07025C12.4648 6.07025 12.9866 6.1522 13.3941 6.31612C13.8116 6.48003 14.1396 6.68733 14.3781 6.93802C14.6167 7.18871 14.7807 7.4635 14.8701 7.7624C14.9695 8.0613 15.0192 8.34573 15.0192 8.6157C15.0192 8.88568 14.9745 9.13636 14.885 9.36777C14.8055 9.58953 14.7012 9.79683 14.5719 9.98967C14.4427 10.1825 14.2936 10.3657 14.1247 10.5393C13.9557 10.7128 13.7917 10.8767 13.6327 11.031C13.5134 11.1467 13.3892 11.272 13.2599 11.407C13.1307 11.5324 13.0115 11.6674 12.9021 11.812C12.8027 11.9566 12.7182 12.1109 12.6487 12.2748C12.5791 12.4291 12.5443 12.5882 12.5443 12.7521C12.5443 12.7906 12.5443 12.8485 12.5443 12.9256C12.5443 13.0028 12.5493 13.0702 12.5592 13.1281H10.5912C10.5713 13.0317 10.5564 12.9256 10.5465 12.8099C10.5465 12.6846 10.5465 12.5785 10.5465 12.4917C10.5465 12.2218 10.5813 11.9807 10.6508 11.7686C10.7204 11.5468 10.8099 11.3444 10.9192 11.1612C11.0385 10.9683 11.1727 10.7899 11.3217 10.626C11.4708 10.4621 11.6249 10.303 11.7839 10.1488C12.0125 9.927 12.2213 9.71006 12.4101 9.49793C12.599 9.27617 12.6934 9.02548 12.6934 8.74587C12.6934 8.51446 12.599 8.3168 12.4101 8.15289C12.2312 7.98898 11.9628 7.90703 11.605 7.90703ZM12.9916 15.1818C12.9916 15.6061 12.8475 15.9339 12.5592 16.1653C12.2809 16.3967 11.9628 16.5124 11.605 16.5124C11.2472 16.5124 10.9242 16.3967 10.6359 16.1653C10.3576 15.9339 10.2185 15.6061 10.2185 15.1818C10.2185 14.7576 10.3576 14.4298 10.6359 14.1983C10.9242 13.9669 11.2472 13.8512 11.605 13.8512C11.9628 13.8512 12.2809 13.9669 12.5592 14.1983C12.8475 14.4298 12.9916 14.7576 12.9916 15.1818Z"
        fill="currentColor"
      />
      <path
        d="M22 12.8533C21.5527 12.8533 21.2297 12.9787 21.0309 13.2293C20.8321 13.48 20.7327 13.8079 20.7327 14.2128V16.3967C20.7327 16.8113 20.6781 17.1777 20.5687 17.4959C20.4594 17.8237 20.2755 18.0985 20.0171 18.3202C19.7686 18.542 19.4307 18.7107 19.0033 18.8264C18.5858 18.9421 18.064 19 17.4378 19H17.1396V17.2789H17.5123C17.9596 17.2789 18.2578 17.1825 18.4069 16.9897C18.5659 16.7968 18.6454 16.4979 18.6454 16.093V14.1694C18.6454 13.668 18.715 13.2438 18.8542 12.8967C18.9933 12.5496 19.2716 12.2507 19.6891 12C19.2716 11.7493 18.9933 11.4504 18.8542 11.1033C18.715 10.7562 18.6454 10.332 18.6454 9.83058V7.90703C18.6454 7.50207 18.5659 7.20317 18.4069 7.01033C18.2578 6.81749 17.9596 6.72107 17.5123 6.72107H17.1396V5H17.4378C18.064 5 18.5858 5.05785 19.0033 5.17355C19.4307 5.28926 19.7686 5.45799 20.0171 5.67975C20.2755 5.90152 20.4594 6.17631 20.5687 6.50413C20.6781 6.82231 20.7327 7.18871 20.7327 7.60331V9.78719C20.7327 10.1921 20.8321 10.52 21.0309 10.7707C21.2297 11.0213 21.5527 11.1467 22 11.1467V12.8533Z"
        fill="currentColor"
      />
    </svg>
  );
}
