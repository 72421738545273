import { useCallback, useState } from 'react';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>;
type ReadTextFn = () => Promise<string | null>;

function useCopyToClipboard(): [CopiedValue, CopyFn, ReadTextFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = useCallback(async (text) => {
    if (!navigator?.clipboard) {
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  }, []);

  const readText: ReadTextFn = useCallback(() => {
    return navigator ? navigator.clipboard.readText() : Promise.resolve(null);
  }, []);

  return [copiedText, copy, readText];
}

export default useCopyToClipboard;
