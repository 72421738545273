import { useCallback, useEffect, useRef, useState } from 'react';
import { alpha, Box, Theme } from '@mui/material';
import { useDimension } from '@front/helper';
import { animated, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';

const AnimatedBox = animated(Box);

const styles = {
  root: {
    position: 'relative',
    flex: 1,
  },
  bar: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    height: 8,
    borderRadius: 2,
    overflow: 'hidden',
    userSelect: 'none',
    touchAction: 'none',
    transitionDuration: '0.3s',
  },
  progress: {
    bgcolor: 'text.primary',
    width: '100%',
    height: 8,
    borderRadius: 2,
  },
  enabled: {
    cursor: 'pointer',
  },
  thumb: {
    position: 'absolute',
    width: 16,
    height: 16,
    borderRadius: '50%',
    bgcolor: 'text.primary',
    top: '50%',
    mt: -1,
    ml: -1,
  },
};

export type BarSliderProps = {
  value?: number;
  disabled?: boolean;
  showThumb?: boolean;
  onChange?: (value: number) => void;
  onChangeStart?: (value: number) => void;
  onChangeEnd?: (value: number) => void;
};
export default function BarSlider({
  value = 0,
  disabled,
  showThumb,
  onChange,
  onChangeStart,
  onChangeEnd,
}: BarSliderProps) {
  const boxRef = useRef<HTMLDivElement>();
  const [{ x }, api] = useSpring(() => ({ x: `${(value - 1) * 100}%` }));
  const [boxRect, setBoxRect] = useState({ width: 0, left: 0 });
  const { width: innerWidth } = useDimension(boxRef);
  const latestPct = useRef(value);
  const adjustProgressBar = useCallback(
    (pct: number) => {
      latestPct.current = pct;
      api.start({ x: `${(pct - 1) * 100}%`, immediate: true });
    },
    [api]
  );

  useEffect(() => {
    const handleSetBoxRect = () => {
      const rect = boxRef.current?.getBoundingClientRect();
      setBoxRect(rect || { width: 0, left: 0 });
    };

    handleSetBoxRect();
  }, [innerWidth]);

  const bind = useDrag(
    ({ first, last, xy: [clientX] }) => {
      let pct = (clientX - boxRect.left) / boxRect.width;
      if (pct < 0) pct = 0;
      else if (pct > 1) pct = 1;
      onChange?.(pct);

      if (!onChange) {
        adjustProgressBar(pct);
      }

      if (first) onChangeStart?.(pct);
      if (last) onChangeEnd?.(pct);
    },
    {
      enabled: !disabled,
    }
  );

  useEffect(() => {
    if (latestPct.current !== value) adjustProgressBar(value);
  }, [adjustProgressBar, value]);

  return (
    <Box ref={boxRef} sx={styles.root}>
      <Box {...bind()} sx={[styles.bar, !disabled && styles.enabled]}>
        <AnimatedBox
          style={{ x }}
          sx={[styles.progress, { opacity: boxRect.width ? 1 : 0 }]}
        />
        {showThumb && !disabled && (
          <AnimatedBox style={{ left: x, x: innerWidth }} sx={styles.thumb} />
        )}
      </Box>
    </Box>
  );
}
