import React, { useDeferredValue, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DateTextField } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import useTableSelectionContext from '@lib/ia/src/layouts/TableLayout/hooks/useTableSelectionContext';
import { format } from 'date-fns';

import {
  IaCellValueChangedEvent,
  TableLayoutDateCell,
  TableLayoutRow,
} from '../../types';

export type IaDateCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutDateCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
  },
  value: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  datePicker: {
    '& .MuiInputBase-input': {
      px: 0,
    },
    '& .textfield-main': {
      border: 'none !important',
      backgroundColor: 'transparent',
      outlineWidth: 0,
      height: '32px',
    },
  },
};
export default function IaDateCell({ row, columnKey, cell }: IaDateCellProps) {
  const {
    value,
    sx,
    dateFormat,
    placeholder,
    maxDate,
    minDate,
    disabled,
    editable,
    actionMap,
  } = cell;
  const { getCellSelectedState, setSelectedState } = useTableSelectionContext();
  const [date, setDate] = useState(new Date(value));
  const { getIaAction } = useIaAction();
  const selectedState = getCellSelectedState(row.id, columnKey);
  const dateTextFieldRef = useRef<HTMLInputElement>();
  const deferredSelectedState = useDeferredValue(selectedState);

  const sxProps = Array.isArray(sx) ? sx : [sx];

  /**
   * when selected state changed, we make it open automatically
   */
  useEffect(() => {
    if (
      !!editable &&
      deferredSelectedState !== 'active' &&
      selectedState === 'active'
    ) {
      dateTextFieldRef.current?.click();
    }
  }, [deferredSelectedState, editable, selectedState]);

  const handleBlur = () => {
    setSelectedState('focused');
  };

  const handleChange = (newValue: Date) => {
    setDate(newValue);
    if (!actionMap || !editable) return;
    if (!actionMap?.valueChange) return;
    const action = getIaAction<IaCellValueChangedEvent<Date>>(
      actionMap.valueChange
    );
    action?.action({ value: newValue, row, columnKey });
    setSelectedState('focused');
  };

  return (
    <Box sx={[styles.root, ...sxProps]}>
      {editable &&
      (selectedState === 'active' || selectedState === 'focused') ? (
        <DateTextField
          sx={styles.datePicker}
          placeholder={placeholder}
          size="sm"
          maxDate={maxDate}
          minDate={minDate}
          format={dateFormat}
          disabled={disabled}
          value={date}
          onChange={handleChange}
          onBlur={handleBlur}
          disabledPortal
          inputRef={dateTextFieldRef}
        />
      ) : (
        <Typography variant="body2" sx={styles.value}>
          {dateFormat ? format(date, dateFormat) : value}
        </Typography>
      )}
    </Box>
  );
}
