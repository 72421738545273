import { useAppSelector } from '@app/web/src/hooks/redux';

export default function useBlockerOffset() {
  // apps/web/src/layouts/MainLayout/components/Header.tsx
  const left = useAppSelector((st) => st.layout.blockerLeftOffset);
  const top = useAppSelector((st) => st.layout.blockerTopOffset);

  return {
    left,
    top,
  };
}
