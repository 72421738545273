import { TFunction } from 'next-i18next';
import { PropertyType } from '@front/ui';
import { isEqual } from 'lodash';

import DateFilterHelper from './dateFilter';
import { getFilterOperatorMap, propertyOperatorMap } from './defaultConfig';
import { FilterConditionConfig, FilterOperator, FilterValues } from './types';

export const getPropertyOperatorConfig = (property: PropertyType) => {
  return propertyOperatorMap[property];
};

export const getOperatorConfig = (t: TFunction, property: FilterOperator) => {
  return getFilterOperatorMap(t)[property];
};

const SHORT_OPERATORS: FilterOperator[] = [
  'Eq',
  'Neq',
  'Gt',
  'Lt',
  'Gte',
  'Lte',
];

/**
 * This function help to convert a filter condition to string.
 * E.g.:
 * - condition1 = { operator: 'Contains', values: 'ACT Math', ... }
 *   filterConditionToString(condition1) will return "ACT Math"
 *
 * - condition2 = { operator: 'DoesNotContains', values: 'ACT Math', ... }
 *   filterConditionToString(condition2) will return "Not ACT Math"
 *   ('DoesNotContains' is an negative operator)
 *
 * - condition3 = { operator: 'Eq', values: 'ACT Math', ... }
 *   filterConditionToString(condition3) will return "= ACT Math"
 *   ('Eq' is an short operator)
 */
export const filterConditionToString = (
  t: TFunction,
  condition: FilterConditionConfig
) => {
  const { operator, values } = condition;
  if (!operator) return '';

  const operatorConfig = getOperatorConfig(t, operator);
  if (operatorConfig.operandCount === 1) return operatorConfig.label;

  if (!values) return '';
  const valueArray = Array.isArray(values) ? values : [values];

  const negativePrefix = operatorConfig.isNegative ? 'Not ' : '';
  const prefix = SHORT_OPERATORS.includes(operator)
    ? `${operatorConfig.label} `
    : '';

  const value = valueArray
    .map((item) => {
      if (typeof item === 'string') return item;
      if (typeof item === 'number') return item + '';
      // complex type
      if ('dateType' in item) return DateFilterHelper.stringify(operator, item);
      // option types
      return item.label;
    })
    .join(', ');

  return negativePrefix + prefix + value;
};

/**
 * Convert filter values of a condition to string that used for search query in the IA APIs.
 * A filter value can be a string, number, array of string, array of number, or an array of object (select options, status options),...
 * Then will be convert to single string.
 * E.g.:
 * "SAT" => "SAT"
 * [1, 10] => "1,10"
 * [{label: 'Active', value: 1}, {label: 'Inactive', value: -1}] => "1,-1"
 * {label: 'Active', value: 1} => "1"
 */
export const filterValuesToString = (values?: FilterValues) => {
  if (!values) return '';
  const valueArray = Array.isArray(values) ? values : [values];

  return valueArray
    .map((item) => {
      if (typeof item === 'string') return item;
      if (typeof item === 'number') return item + '';
      // complex type
      if ('dateType' in item) return DateFilterHelper.queryStringify(item);
      return item.value;
    })
    .join(',');
};

/**
 * This function help to build a search query (with 2 query params: `search` and `searchFields`)
 * to use in the IA APIs based on filter conditions.
 *
 * For each condition this function will calculate to convert to `search` and `searchFields` params.
 * E.g.:
 * condition1 = { field: { type: 'ShortText', name: 'clubName' }, operator: 'Contains', values: 'ACT' }
 * => `search` = `clubName:ACT` and `searchFields` = `clubName:like`
 *
 * condition2 = { field: { type: 'Number', name: 'clubMemberCount' }, operator: 'Eq', values: 10 }
 * => `search` = `clubMemberCount:10` and `searchFields` = `clubMemberCount:=`
 *
 * condition3 = { field: { type: 'IsOn', name: 'isAllowMemberInvitation' }, operator: 'IsOn' }
 * => `search` = `isAllowMemberInvitation:true` and `searchFields` = `isAllowMemberInvitation:=`
 *
 * Then the function will join all `search` and `searchFields` to a query string.
 *
 * E.g.: conditions = [condition1, condition2, condition3]
 * => `search` = "clubName:ACT;clubMemberCount:10;isAllowMemberInvitation:true"
 * => `searchFields` = "clubName:like;clubMemberCount:=;isAllowMemberInvitation:="
 *
 * For more information [read this](https://www.notion.so/rootdomain/API-Spec-IA-Request-Format-bd9eb1f4d9f14c61b5299f807af89089)
 */
export const buildSearchQuery = (
  t: TFunction,
  conditions: FilterConditionConfig[] = []
) => {
  if (conditions.length === 0) return null;

  const searchFieldsQueries = [];
  const searchQueries = [];
  const expandSearchFieldsQueries = [];
  const expandSearchQueries = [];

  for (const condition of conditions) {
    if (!condition.operator || condition.viewOnly) continue;

    const operatorConfig = getOperatorConfig(t, condition.operator);
    if (operatorConfig.operandCount > 1 && !condition.values) continue;

    const matchType = operatorConfig.toMatchType(
      condition.field.type,
      condition.values
    );
    if (matchType) {
      const searchFieldQuery = `${condition.field.name}:${matchType.searchFields}`;

      // Priority is given to get the value of mathType first `matchType.search`
      // If there is no value, we will take the value of `searchQuery`.
      const searchQueryValue =
        matchType.search || filterValuesToString(condition.values);

      const searchQuery = `${condition.field.name}${
        searchQueryValue ? `:${searchQueryValue}` : ''
      }`;

      // e.g. expandSearchField=clubCreator:clubCreatorSourceId:123
      const isExpand = condition.field.name.includes(':');

      if (isExpand) {
        expandSearchFieldsQueries.push(searchFieldQuery);
        expandSearchQueries.push(searchQuery);
      } else {
        searchFieldsQueries.push(searchFieldQuery);
        searchQueries.push(searchQuery);
      }
    }
  }

  const searchQueryString = searchQueries.filter((item) => !!item).join(';');
  const searchFieldsQueryString = searchFieldsQueries
    .filter((item) => !!item)
    .join(';');

  const expandSearchQueriesString = expandSearchQueries
    .filter((item) => !!item)
    .join(';');
  const expandSearchFieldsQueryString = expandSearchFieldsQueries
    .filter((item) => !!item)
    .join(';');

  if (
    !searchQueryString &&
    !searchFieldsQueryString &&
    !expandSearchQueriesString &&
    !expandSearchFieldsQueryString
  ) {
    return null;
  }

  return {
    search: searchQueryString,
    searchFields: searchFieldsQueryString,
    expandSearch: expandSearchQueriesString,
    expandSearchFields: expandSearchFieldsQueryString,
  };
};

export const isSameValues = (value1?: FilterValues, value2?: FilterValues) => {
  if (typeof value1 !== typeof value2) return false;
  if (
    typeof value1 === 'object' &&
    !Array.isArray(value1) &&
    typeof value2 === 'object' &&
    !Array.isArray(value2)
  ) {
    return isEqual(value1?.value, value2?.value);
  }
  if (Array.isArray(value1) && Array.isArray(value2)) {
    const value1Values = value1.map((v) => {
      if (typeof v === 'object') return v?.value;
      return v;
    });
    const value2Values = value2.map((v) => {
      if (typeof v === 'object') return v?.value;
      return v;
    });
    return isEqual(value1Values.sort(), value2Values.sort());
  }
  return isEqual(value1, value2);
};

export const compareCondition = (
  t: TFunction,
  con1?: FilterConditionConfig,
  con2?: FilterConditionConfig
) => {
  if (!con1 || !con2) return false;
  if (!con1.operator || !con2.operator) return false;
  if (con1.operator !== con2.operator) return false;
  if (con1.operator !== con2.operator) return false;
  if (con1.field.name !== con2.field.name) return false;
  const operator1 = getOperatorConfig(t, con1.operator);
  const operator2 = getOperatorConfig(t, con2.operator);
  if (operator1.operandCount === 1 && operator2.operandCount === 1) return true; // don't care values
  return isSameValues(con1.values, con2.values);
};
