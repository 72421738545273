import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { TruncatedTooltip } from '@front/ui';

import useTableSettings from '../../../hooks/useTableSettings';
import { NumberGroupCell } from '../../../types';

export type IaNumberGroupCellProps = {
  cell: NumberGroupCell;
};

const styles = {
  root: {
    px: 1,
    py: '5.5px',
    display: 'grid',
    minHeight: '32px',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  nowrap: {
    height: '32px',
  },
  text: {
    textAlign: 'right',
    typography: 'numberBody3',
    br: {
      // make html element render on one line
      content: '" "',
      display: 'none',
    },
  },
  textWrap: {
    whiteSpace: 'pre-wrap',
  },
  textNowrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '& [data-content-type="paragraph"] > p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};
export default function IaNumberGroupCell({ cell }: IaNumberGroupCellProps) {
  const { wrap: tableWrap } = useTableSettings();
  const { value, wrap: cellWrap } = cell;
  const wrap = cellWrap ?? tableWrap;

  return (
    <Box sx={[styles.root, !wrap && styles.nowrap]} className="group-cell">
      <TruncatedTooltip tooltip={value}>
        {(props) => (
          <Typography
            {...props}
            variant="body2"
            sx={[styles.text, wrap ? styles.textWrap : styles.textNowrap]}
          >
            {value}
          </Typography>
        )}
      </TruncatedTooltip>
    </Box>
  );
}
