import { useMemo } from 'react';
import { Theme } from '@mui/material';
import { alpha } from '@mui/system';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { IconListLayoutItemSuffix } from '@lib/ia/src/layouts/IconListLayout/types';
import { useAuth, useSuggestionClubMembers } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { getIndicators } from '@lib/web/utils';

function getItemSuffix(state?: 'loading' | 'sent'): IconListLayoutItemSuffix {
  if (state === 'loading') {
    return {
      type: 'loading',
    };
  }
  if (state === 'sent') {
    return {
      type: 'text',
      content: 'Sent',
    };
  }
  return {
    type: 'icon',
    name: 'OtherShareToDirect',
  };
}

export default function useSuggestionMembersLayoutConfig(
  stateMap: Record<string, 'loading' | 'sent'> = {}
): IaLayoutConfig[] {
  const clubSlug = useClubSlug();
  const { data } = useSuggestionClubMembers(clubSlug);
  const { member } = useAuth();

  return useMemo(() => {
    const members = data?.data || [];
    const meItem = [];
    if (member) {
      meItem.push({
        id: member.userId,
        title: member.displayName || member.distinctName,
        label: {
          text: 'Me',
          color: 'default',
          sx: {
            color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
            display: 'flex',
            minWidth: 39,
          },
        },
        description: `@${member.distinctName}`,
        avatarUrl: member.avatarUrl || member.photoUrl,
        indicators: getIndicators(member.indicator),
        suffix: getItemSuffix(stateMap[member.userId]),
        actionMap: {
          select: {
            value: 'selectChanged',
            hideCheckbox: true,
          },
        },
      });
    }

    const items = members.map((item) => ({
      id: item.userId,
      title: item.displayName || item.distinctName,
      description: `@${item.distinctName}`,
      avatarUrl: item.nftAvatar || item.avatar,
      indicators: getIndicators(item.indicator),
      suffix: getItemSuffix(stateMap[item.userId]),
      actionMap: {
        select: {
          value: 'selectChanged',
          hideCheckbox: true,
        },
      },
    }));

    return [
      {
        layout: 'icon-list-layout',
        items: [...meItem, ...items],
      },
    ];
  }, [data?.data, member, stateMap]);
}
