import React from 'react';

export default function OtherRefresh({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.6485 6.35C16.1976 4.9 14.2064 4 11.995 4C7.57223 4 4 7.58 4 12C4 16.42 7.57223 20 11.995 20C15.7273 20 18.8393 17.45 19.7298 14H17.6485C16.828 16.33 14.6066 18 11.995 18C8.68293 18 5.99124 15.31 5.99124 12C5.99124 8.69 8.68293 6 11.995 6C13.656 6 15.137 6.69 16.2176 7.78L12.9956 11H20V4L17.6485 6.35Z"
        fill="currentColor"
      />
    </svg>
  );
}
