import React from 'react';

export default function OtherRaters({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14 8C14 10.21 12.21 12 10 12C7.79 12 6 10.21 6 8C6 5.79 7.79 4 10 4C12.21 4 14 5.79 14 8ZM9.12644 14.0402C6.31653 14.2814 2 15.6062 2 18V20H12.349L12.7736 17.7829C12.7993 17.6486 12.7547 17.5105 12.6552 17.4167L9.27568 14.2286C9.21429 14.1707 9.16479 14.1072 9.12644 14.0402Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M16.6592 10.2477C16.777 9.91744 17.223 9.91744 17.3408 10.2477L18.3754 13.1459C18.4265 13.2892 18.5545 13.3865 18.7003 13.3932L21.6514 13.5283C21.9877 13.5437 22.1254 13.9879 21.8621 14.2074L19.5504 16.1337C19.4361 16.2289 19.3873 16.3865 19.4263 16.5338L20.2156 19.5156C20.3055 19.8553 19.9447 20.1299 19.6641 19.9353L17.2008 18.2275C17.0791 18.1431 16.9209 18.1431 16.7992 18.2275L14.3359 19.9353C14.0553 20.1299 13.6945 19.8553 13.7844 19.5156L14.5737 16.5338C14.6127 16.3865 14.5639 16.2289 14.4496 16.1337L12.1379 14.2074C11.8746 13.9879 12.0123 13.5437 12.3486 13.5283L15.2997 13.3932C15.4455 13.3865 15.5735 13.2892 15.6246 13.1459L16.6592 10.2477Z"
        fill="currentColor"
      />
    </svg>
  );
}
