import React, { SVGAttributes } from 'react';

export default function EmojiCrown({ ...rest }: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width="61"
      height="112"
      viewBox="0 0 61 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M40.9028 44.4679L37.5421 46.3182L26.8946 39.0259L30.2407 37.1946L40.9028 44.4679Z"
        fill="#F9DC47"
      />
      <path
        d="M40.9028 44.4679L37.5421 46.3182L26.8946 39.0259L30.2407 37.1946L40.9028 44.4679Z"
        fill="black"
        fillOpacity="0.35"
      />
      <path
        d="M34.1558 48.158V42.6304L23.5002 35.3291V40.8679L34.1558 48.158Z"
        fill="#F9D645"
      />
      <path
        d="M34.1558 48.158V42.6304L23.5002 35.3291V40.8679L34.1558 48.158Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M30.833 39.0446L27.4722 40.8949L16.8248 33.6026L20.1709 31.7712L30.833 39.0446Z"
        fill="#B38C25"
      />
      <path
        d="M30.833 39.0446L27.4722 40.8949L16.8248 33.6026L20.1709 31.7712L30.833 39.0446Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M34.1768 48.1962L30.816 50.0465L20.1686 42.7542L23.5147 40.9229L34.1768 48.1962Z"
        fill="#E6BB3A"
      />
      <path
        d="M34.1768 48.1962L30.816 50.0465L20.1686 42.7542L23.5147 40.9229L34.1768 48.1962Z"
        fill="black"
        fillOpacity="0.35"
      />
      <path
        d="M40.8823 44.4295V38.9019L30.2268 31.6006V37.1394L40.8823 44.4295Z"
        fill="#BD900E"
      />
      <path
        d="M40.8823 44.4295V38.9019L30.2268 31.6006V37.1394L40.8823 44.4295Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M17.3213 107.429V101.901L6.66575 94.5999V100.139L17.3213 107.429Z"
        fill="#A27E16"
      />
      <path
        d="M17.3213 107.429V101.901L6.66575 94.5999V100.139L17.3213 107.429Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M57.6396 35.1602V29.6326L46.9841 22.3313V27.8701L57.6396 35.1602Z"
        fill="#BB9332"
      />
      <path
        d="M57.6396 35.1602V29.6326L46.9841 22.3313V27.8701L57.6396 35.1602Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M57.6396 40.701V35.1734L46.9841 27.8721V33.4108L57.6396 40.701Z"
        fill="#BF9836"
      />
      <path
        d="M57.6396 40.701V35.1734L46.9841 27.8721V33.4108L57.6396 40.701Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M30.8115 39.0062V33.4785L20.156 26.1772V31.716L30.8115 39.0062Z"
        fill="#F9DB47"
      />
      <path
        d="M30.8115 39.0062V33.4785L20.156 26.1772V31.716L30.8115 39.0062Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M24.0806 42.7366V37.209L13.425 29.9077V35.4465L24.0806 42.7366Z"
        fill="#B29028"
      />
      <path
        d="M24.0806 42.7366V37.209L13.425 29.9077V35.4465L24.0806 42.7366Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M20.6865 55.6629V50.1353L10.031 42.834V48.3728L20.6865 55.6629Z"
        fill="#B38725"
      />
      <path
        d="M20.6865 55.6629V50.1353L10.031 42.834V48.3728L20.6865 55.6629Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M20.6865 50.15V44.6223L10.031 37.321V42.8598L20.6865 50.15Z"
        fill="#B69223"
      />
      <path
        d="M20.6865 50.15V44.6223L10.031 37.321V42.8598L20.6865 50.15Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M17.355 63.0584V57.5308L6.69944 50.2295V55.7683L17.355 63.0584Z"
        fill="#BD9840"
      />
      <path
        d="M17.355 63.0584V57.5308L6.69944 50.2295V55.7683L17.355 63.0584Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M57.626 24.1292V18.6016L46.9704 11.3003V16.8391L57.626 24.1292Z"
        fill="#D8B542"
      />
      <path
        d="M57.626 24.1292V18.6016L46.9704 11.3003V16.8391L57.626 24.1292Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M54.2612 31.5506V26.023L43.6057 18.7217V24.2604L54.2612 31.5506Z"
        fill="#CEA83C"
      />
      <path
        d="M54.2612 31.5506V26.023L43.6057 18.7217V24.2604L54.2612 31.5506Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M60.9907 16.7169L57.6299 18.5673L46.9825 11.275L50.3286 9.4436L60.9907 16.7169Z"
        fill="#D8B542"
      />
      <path
        d="M60.9907 16.7169L57.6299 18.5673L46.9825 11.275L50.3286 9.4436L60.9907 16.7169Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M57.6606 40.7394L54.2999 42.5897L43.6525 35.2974L46.9986 33.4661L57.6606 40.7394Z"
        fill="#9F6F01"
      />
      <path
        d="M57.6606 40.7394L54.2999 42.5897L43.6525 35.2974L46.9986 33.4661L57.6606 40.7394Z"
        fill="black"
        fillOpacity="0.35"
      />
      <path
        d="M57.626 24.1383L54.2652 25.9887L43.6178 18.6964L46.9639 16.865L57.626 24.1383Z"
        fill="#CEA83C"
      />
      <path
        d="M57.626 24.1383L54.2652 25.9887L43.6178 18.6964L46.9639 16.865L57.626 24.1383Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M24.1016 42.7748L20.7408 44.6251L10.0934 37.3328L13.4395 35.5015L24.1016 42.7748Z"
        fill="#B69223"
      />
      <path
        d="M24.1016 42.7748L20.7408 44.6251L10.0934 37.3328L13.4395 35.5015L24.1016 42.7748Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M20.7075 55.7013L17.3467 57.5516L6.69933 50.2593L10.0454 48.428L20.7075 55.7013Z"
        fill="#BD9840"
      />
      <path
        d="M20.7075 55.7013L17.3467 57.5516L6.69933 50.2593L10.0454 48.428L20.7075 55.7013Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M17.3452 46.5038L13.9844 48.3541L3.33703 41.0618L6.68312 39.2305L17.3452 46.5038Z"
        fill="#A5872B"
      />
      <path
        d="M17.3452 46.5038L13.9844 48.3541L3.33703 41.0618L6.68312 39.2305L17.3452 46.5038Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M14.021 42.828L10.6602 44.6783L0.0128098 37.386L3.3589 35.5547L14.021 42.828Z"
        fill="#AA8D2F"
      />
      <path
        d="M14.021 42.828L10.6602 44.6783L0.0128098 37.386L3.3589 35.5547L14.021 42.828Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M17.376 63.0968L14.0152 64.9472L3.36779 57.6548L6.71389 55.8235L17.376 63.0968Z"
        fill="#915C11"
      />
      <path
        d="M17.376 63.0968L14.0152 64.9472L3.36779 57.6548L6.71389 55.8235L17.376 63.0968Z"
        fill="black"
        fillOpacity="0.35"
      />
      <path
        d="M54.293 37.1198L50.9322 38.9701L40.2848 31.6778L43.6309 29.8464L54.293 37.1198Z"
        fill="#BD9840"
      />
      <path
        d="M54.293 37.1198L50.9322 38.9701L40.2848 31.6778L43.6309 29.8464L54.293 37.1198Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M50.9028 27.8612L47.5421 29.7116L36.8946 22.4193L40.2407 20.5879L50.9028 27.8612Z"
        fill="#D2A21D"
      />
      <path
        d="M50.9028 27.8612L47.5421 29.7116L36.8946 22.4193L40.2407 20.5879L50.9028 27.8612Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M44.2183 31.6049L40.8575 33.4552L30.2101 26.1629L33.5562 24.3315L44.2183 31.6049Z"
        fill="#B98915"
      />
      <path
        d="M44.2183 31.6049L40.8575 33.4552L30.2101 26.1629L33.5562 24.3315L44.2183 31.6049Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M50.853 16.7155L47.4923 18.5658L36.8448 11.2735L40.1909 9.44214L50.853 16.7155Z"
        fill="#D5A822"
      />
      <path
        d="M50.853 16.7155L47.4923 18.5658L36.8448 11.2735L40.1909 9.44214L50.853 16.7155Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M30.3403 77.6973V72.1697L19.6848 64.8684V70.4072L30.3403 77.6973Z"
        fill="#FF0000"
      />
      <path
        d="M44.2456 20.5496V15.022L33.5901 7.7207V13.2595L44.2456 20.5496Z"
        fill="#CE9E1B"
      />
      <path
        d="M44.2456 20.5496V15.022L33.5901 7.7207V13.2595L44.2456 20.5496Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M47.6104 13.1373L44.2496 14.9877L33.6022 7.69538L36.9483 5.86401L47.6104 13.1373Z"
        fill="#CE9E1B"
      />
      <path
        d="M47.6104 13.1373L44.2496 14.9877L33.6022 7.69538L36.9483 5.86401L47.6104 13.1373Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M44.2378 31.6165V26.0889L33.5823 18.7876V24.3264L44.2378 31.6165Z"
        fill="#C59518"
      />
      <path
        d="M44.2378 31.6165V26.0889L33.5823 18.7876V24.3264L44.2378 31.6165Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M44.2061 20.515L40.8453 22.3654L30.1979 15.0731L33.544 13.2417L44.2061 20.515Z"
        fill="#D5A822"
      />
      <path
        d="M44.2061 20.515L40.8453 22.3654L30.1979 15.0731L33.544 13.2417L44.2061 20.515Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M40.8408 27.9273V22.3997L30.1853 15.0984V20.6372L40.8408 27.9273Z"
        fill="#D5A822"
      />
      <path
        d="M40.8408 27.9273V22.3997L30.1853 15.0984V20.6372L40.8408 27.9273Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M40.8589 27.9262L37.4981 29.7765L26.8507 22.4842L30.1968 20.6528L40.8589 27.9262Z"
        fill="#FAEA50"
      />
      <path
        d="M40.8589 27.9262L37.4981 29.7765L26.8507 22.4842L30.1968 20.6528L40.8589 27.9262Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M34.1313 31.6486L30.7706 33.4989L20.1232 26.2066L23.4693 24.3752L34.1313 31.6486Z"
        fill="#F9DB47"
      />
      <path
        d="M34.1313 31.6486L30.7706 33.4989L20.1232 26.2066L23.4693 24.3752L34.1313 31.6486Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M10.6553 55.7439V50.2163L-0.000263214 42.915V48.4538L10.6553 55.7439Z"
        fill="#AA8006"
      />
      <path
        d="M10.6553 55.7439V50.2163L-0.000263214 42.915V48.4538L10.6553 55.7439Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M10.6553 77.8128V72.2852L-0.000263214 64.9839V70.5227L10.6553 77.8128Z"
        fill="#B89C20"
      />
      <path
        d="M10.6553 77.8128V72.2852L-0.000263214 64.9839V70.5227L10.6553 77.8128Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M10.6553 99.8816V94.354L-0.000263214 87.0527V92.5915L10.6553 99.8816Z"
        fill="#987402"
      />
      <path
        d="M10.6553 99.8816V94.354L-0.000263214 87.0527V92.5915L10.6553 99.8816Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M10.6553 50.2308V44.7031L-0.000263214 37.4019V42.9406L10.6553 50.2308Z"
        fill="#AA8D2F"
      />
      <path
        d="M10.6553 50.2308V44.7031L-0.000263214 37.4019V42.9406L10.6553 50.2308Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M10.6553 72.2999V66.7722L-0.000263214 59.4709V65.0097L10.6553 72.2999Z"
        fill="#B29218"
      />
      <path
        d="M10.6553 72.2999V66.7722L-0.000263214 59.4709V65.0097L10.6553 72.2999Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M10.6553 94.3687V88.8411L-0.000263214 81.5398V87.0786L10.6553 94.3687Z"
        fill="#05866C"
      />
      <path
        d="M10.6553 94.3687V88.8411L-0.000263214 81.5398V87.0786L10.6553 94.3687Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M10.6553 66.7591V61.2315L-0.000263214 53.9302V59.4689L10.6553 66.7591Z"
        fill="#A06B01"
      />
      <path
        d="M10.6553 66.7591V61.2315L-0.000263214 53.9302V59.4689L10.6553 66.7591Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M10.6553 88.8279V83.3003L-0.000263214 75.999V81.5378L10.6553 88.8279Z"
        fill="#009770"
      />
      <path
        d="M10.6553 88.8279V83.3003L-0.000263214 75.999V81.5378L10.6553 88.8279Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M10.6553 61.2459V55.7183L-0.000263214 48.417V53.9558L10.6553 61.2459Z"
        fill="#956D23"
      />
      <path
        d="M10.6553 61.2459V55.7183L-0.000263214 48.417V53.9558L10.6553 61.2459Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M10.6553 83.315V77.7874L-0.000263214 70.4861V76.0248L10.6553 83.315Z"
        fill="#0F8D6D"
      />
      <path
        d="M10.6553 83.315V77.7874L-0.000263214 70.4861V76.0248L10.6553 83.315Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M10.6553 105.384V99.8562L-0.000263214 92.5549V98.0937L10.6553 105.384Z"
        fill="#AA8C18"
      />
      <path
        d="M10.6553 105.384V99.8562L-0.000263214 92.5549V98.0937L10.6553 105.384Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M20.73 39.105V33.5774L10.0744 26.2761V31.8149L20.73 39.105Z"
        fill="#C5AE85"
      />
      <path
        d="M20.73 39.105V33.5774L10.0744 26.2761V31.8149L20.73 39.105Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M24.0947 31.693L20.734 33.5433L10.0865 26.251L13.4326 24.4197L24.0947 31.693Z"
        fill="#C5AE85"
      />
      <path
        d="M24.0947 31.693L20.734 33.5433L10.0865 26.251L13.4326 24.4197L24.0947 31.693Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M34.1514 31.6468V26.1192L23.4958 18.8179V24.3566L34.1514 31.6468Z"
        fill="#F7EBAE"
      />
      <path
        d="M34.1514 31.6468V26.1192L23.4958 18.8179V24.3566L34.1514 31.6468Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M37.5156 24.2348L34.1549 26.0851L23.5074 18.7928L26.8535 16.9614L37.5156 24.2348Z"
        fill="#F7EBAE"
      />
      <path
        d="M37.5156 24.2348L34.1549 26.0851L23.5074 18.7928L26.8535 16.9614L37.5156 24.2348Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M30.7852 28.0021L27.4244 29.8524L16.777 22.5601L20.1231 20.7288L30.7852 28.0021Z"
        fill="#D4C179"
      />
      <path
        d="M30.7852 28.0021L27.4244 29.8524L16.777 22.5601L20.1231 20.7288L30.7852 28.0021Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M24.1353 31.7273V26.1997L13.4797 18.8984V24.4372L24.1353 31.7273Z"
        fill="#BB9B35"
      />
      <path
        d="M24.1353 31.7273V26.1997L13.4797 18.8984V24.4372L24.1353 31.7273Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M27.5 24.3153L24.1392 26.1657L13.4918 18.8734L16.8379 17.042L27.5 24.3153Z"
        fill="#BB9B35"
      />
      <path
        d="M27.5 24.3153L24.1392 26.1657L13.4918 18.8734L16.8379 17.042L27.5 24.3153Z"
        fill="black"
        fillOpacity="0.3"
      />
      <g clipPath="url(#clip0_3351_99088)">
        <path
          d="M14.0235 98.1919L17.3789 96.3289V101.866L14.0235 103.729V98.1919Z"
          fill="#946B01"
        />
        <path
          d="M17.3789 96.3289L20.7344 94.4658V100.003L17.3789 101.866V96.3289Z"
          fill="#B27F01"
        />
        <path
          d="M17.3789 101.866L20.7344 100.003V105.54L17.3789 107.403V101.866Z"
          fill="#A27E16"
        />
        <path
          d="M20.7344 94.4659L24.0898 92.6028V98.1397L20.7344 100.003V94.4659Z"
          fill="#9B6F02"
        />
        <path
          d="M20.7344 100.003L24.0898 98.1396V103.677L20.7344 105.54V100.003Z"
          fill="#A5831C"
        />
        <path
          d="M24.0904 92.6028L27.4458 90.7397V96.2767L24.0904 98.1397V92.6028Z"
          fill="#A68019"
        />
        <path
          d="M24.0904 98.1397L27.4458 96.2766V101.814L24.0904 103.677V98.1397Z"
          fill="#AD8C26"
        />
        <path
          d="M27.4458 90.7398L30.8013 88.8767V94.4136L27.4458 96.2767V90.7398Z"
          fill="#B99025"
        />
        <path
          d="M27.4458 96.2767L30.8013 94.4136V99.9505L27.4458 101.814V96.2767Z"
          fill="#BC972D"
        />
        <path
          d="M30.8013 88.8765L34.1567 87.0134V92.5503L30.8013 94.4134V88.8765Z"
          fill="#A7A544"
        />
        <path
          d="M30.8013 94.4136L34.1567 92.5505V98.0875L30.8013 99.9505V94.4136Z"
          fill="#C3A43D"
        />
        <path
          d="M34.1568 87.0135L37.5122 85.1504V90.6873L34.1568 92.5504V87.0135Z"
          fill="#9EA654"
        />
        <path
          d="M34.1568 92.5503L37.5122 90.6873V96.2242L34.1568 98.0873V92.5503Z"
          fill="#C7A33D"
        />
        <path
          d="M37.5122 85.1504L40.8677 83.2874V88.8243L37.5122 90.6873V85.1504Z"
          fill="#BDB359"
        />
        <path
          d="M37.5122 90.6873L40.8677 88.8242V94.3611L37.5122 96.2242V90.6873Z"
          fill="#D3AE40"
        />
        <path
          d="M40.8672 83.2874L44.2227 81.4243V86.9612L40.8672 88.8243V83.2874Z"
          fill="#E2BB3D"
        />
        <path
          d="M40.8672 88.8243L44.2227 86.9612V92.4981L40.8672 94.3612V88.8243Z"
          fill="#D6B03F"
        />
        <path
          d="M44.2227 81.4244L47.5781 79.5613V85.0982L44.2227 86.9613V81.4244Z"
          fill="#E1BA3D"
        />
        <path
          d="M44.2227 86.9612L47.5781 85.0981V90.6351L44.2227 92.4981V86.9612Z"
          fill="#DAB43F"
        />
        <path
          d="M47.5781 79.5613L50.9336 77.6982V83.2352L47.5781 85.0982V79.5613Z"
          fill="#D8AD31"
        />
        <path
          d="M47.5781 85.0982L50.9336 83.2351V88.772L47.5781 90.6351V85.0982Z"
          fill="#D8B23C"
        />
        <path
          d="M50.9336 77.698L54.2891 75.835V81.3719L50.9336 83.235V77.698Z"
          fill="#D2A21A"
        />
        <path
          d="M50.9336 83.2351L54.2891 81.3721V86.909L50.9336 88.7721V83.2351Z"
          fill="#CB9F1F"
        />
        <path
          d="M54.2891 75.835L57.6445 73.9719V79.5088L54.2891 81.3719V75.835Z"
          fill="#CD9E12"
        />
        <path
          d="M57.6446 73.972L61 72.1089V77.6458L57.6446 79.5089V73.972Z"
          fill="#B9920E"
        />
        <path
          d="M14.0235 92.6551L17.3789 90.792V96.3289L14.0235 98.192V92.6551Z"
          fill="#966301"
        />
        <path
          d="M17.3789 90.792L20.7344 88.929V94.4659L17.3789 96.329V90.792Z"
          fill="#6F331D"
        />
        <path
          d="M20.7344 88.929L24.0898 87.0659V92.6028L20.7344 94.4659V88.929Z"
          fill="#A0710C"
        />
        <path
          d="M24.0904 87.066L27.4458 85.2029V90.7398L24.0904 92.6029V87.066Z"
          fill="#AB8415"
        />
        <path
          d="M27.4458 85.2027L30.8013 83.3396V88.8765L27.4458 90.7396V85.2027Z"
          fill="#BA9327"
        />
        <path
          d="M30.8013 83.3396L34.1567 81.4766V87.0135L30.8013 88.8766V83.3396Z"
          fill="#0F8F64"
        />
        <path
          d="M34.1568 81.4766L37.5122 79.6135V85.1505L34.1568 87.0135V81.4766Z"
          fill="#009371"
        />
        <path
          d="M37.5122 79.6136L40.8677 77.7505V83.2874L37.5122 85.1505V79.6136Z"
          fill="#008F77"
        />
        <path
          d="M40.8672 77.7505L44.2227 75.8875V81.4244L40.8672 83.2875V77.7505Z"
          fill="#E3BD3C"
        />
        <path
          d="M44.2227 75.8872L47.5781 74.0242V79.5611L44.2227 81.4242V75.8872Z"
          fill="#E4BD3D"
        />
        <path
          d="M47.5781 74.0242L50.9336 72.1611V77.6981L47.5781 79.5611V74.0242Z"
          fill="#E1A942"
        />
        <path
          d="M50.9336 72.1612L54.2891 70.2981V75.835L50.9336 77.6981V72.1612Z"
          fill="#BB744D"
        />
        <path
          d="M54.2891 70.2981L57.6445 68.4351V73.972L54.2891 75.8351V70.2981Z"
          fill="#C99914"
        />
        <path
          d="M57.6446 68.4351L61 66.572V72.1089L57.6446 73.972V68.4351Z"
          fill="#AE8808"
        />
        <path
          d="M14.0235 87.1182L17.3789 85.2551V90.792L14.0235 92.6551V87.1182Z"
          fill="#986601"
        />
        <path
          d="M17.3789 85.2552L20.7344 83.3921V88.929L17.3789 90.7921V85.2552Z"
          fill="#CD3BAC"
        />
        <path
          d="M20.7344 83.3921L24.0898 81.5291V87.066L20.7344 88.9291V83.3921Z"
          fill="#BC4592"
        />
        <path
          d="M24.0904 81.5288L27.4458 79.6658V85.2027L24.0904 87.0658V81.5288Z"
          fill="#AC8519"
        />
        <path
          d="M27.4458 79.6658L30.8013 77.8027V83.3397L27.4458 85.2027V79.6658Z"
          fill="#C49728"
        />
        <path
          d="M30.8013 77.8028L34.1567 75.9397V81.4766L30.8013 83.3397V77.8028Z"
          fill="#008F74"
        />
        <path
          d="M34.1568 75.9397L37.5122 74.0767V79.6136L34.1568 81.4767V75.9397Z"
          fill="#0CA179"
        />
        <path
          d="M37.5122 74.0767L40.8677 72.2136V77.7505L37.5122 79.6136V74.0767Z"
          fill="#60BF8B"
        />
        <path
          d="M40.8672 72.2134L44.2227 70.3503V75.8873L40.8672 77.7503V72.2134Z"
          fill="#E4BD3F"
        />
        <path
          d="M44.2227 70.3504L47.5781 68.4873V74.0242L44.2227 75.8873V70.3504Z"
          fill="#E5BE3D"
        />
        <path
          d="M47.5781 68.4873L50.9336 66.6243V72.1612L47.5781 74.0243V68.4873Z"
          fill="#DAA635"
        />
        <path
          d="M50.9336 66.6243L54.2891 64.7612V70.2982L50.9336 72.1612V66.6243Z"
          fill="#D845A8"
        />
        <path
          d="M54.2891 64.7613L57.6445 62.8982V68.4351L54.2891 70.2982V64.7613Z"
          fill="#CF9A1B"
        />
        <path
          d="M57.6446 62.8982L61 61.0352V66.5721L57.6446 68.4352V62.8982Z"
          fill="#48A06B"
        />
        <path
          d="M14.0235 81.5811L17.3789 79.718V85.2549L14.0235 87.118V81.5811Z"
          fill="#9B6600"
        />
        <path
          d="M17.3789 79.7181L20.7344 77.855V83.3919L17.3789 85.255V79.7181Z"
          fill="#B05E47"
        />
        <path
          d="M20.7344 77.855L24.0898 75.9919V81.5289L20.7344 83.3919V77.855Z"
          fill="#9D7109"
        />
        <path
          d="M24.0904 75.992L27.4458 74.1289V79.6658L24.0904 81.5289V75.992Z"
          fill="#B18B1E"
        />
        <path
          d="M27.4458 74.1289L30.8013 72.2659V77.8028L27.4458 79.6659V74.1289Z"
          fill="#C89F2A"
        />
        <path
          d="M30.8013 72.2659L34.1567 70.4028V75.9398L30.8013 77.8028V72.2659Z"
          fill="#87C9B3"
        />
        <path
          d="M34.1568 70.4029L37.5122 68.5398V74.0767L34.1568 75.9398V70.4029Z"
          fill="#A9E7CF"
        />
        <path
          d="M37.5122 68.5396L40.8677 66.6765V72.2134L37.5122 74.0765V68.5396Z"
          fill="#74CD9C"
        />
        <path
          d="M40.8672 66.6766L44.2227 64.8135V70.3504L40.8672 72.2135V66.6766Z"
          fill="#EBC13F"
        />
        <path
          d="M44.2227 64.8135L47.5781 62.9504V68.4874L44.2227 70.3504V64.8135Z"
          fill="#E4BD3C"
        />
        <path
          d="M47.5781 62.9505L50.9336 61.0874V66.6243L47.5781 68.4874V62.9505Z"
          fill="#DAAE2D"
        />
        <path
          d="M50.9336 61.0874L54.2891 59.2244V64.7613L50.9336 66.6244V61.0874Z"
          fill="#D4805F"
        />
        <path
          d="M54.2891 59.2244L57.6445 57.3613V62.8983L54.2891 64.7613V59.2244Z"
          fill="#D09E12"
        />
        <path
          d="M57.6446 57.3611L61 55.498V61.035L57.6446 62.898V57.3611Z"
          fill="#429379"
        />
        <path
          d="M14.0235 76.0442L17.3789 74.1812V79.7181L14.0235 81.5812V76.0442Z"
          fill="#9B6700"
        />
        <path
          d="M17.3789 74.1812L20.7344 72.3181V77.855L17.3789 79.7181V74.1812Z"
          fill="#AB7713"
        />
        <path
          d="M20.7344 72.3182L24.0898 70.4551V75.992L20.7344 77.8551V72.3182Z"
          fill="#996518"
        />
        <path
          d="M24.0904 70.4551L27.4458 68.592V74.129L24.0904 75.992V70.4551Z"
          fill="#BB8E1F"
        />
        <path
          d="M27.4458 68.5921L30.8013 66.729V72.2659L27.4458 74.129V68.5921Z"
          fill="#CDA429"
        />
        <path
          d="M30.8013 66.729L34.1567 64.866V70.4029L30.8013 72.266V66.729Z"
          fill="#E1B63B"
        />
        <path
          d="M34.1568 64.8658L37.5122 63.0027V68.5396L34.1568 70.4027V64.8658Z"
          fill="#EAC23E"
        />
        <path
          d="M37.5122 63.0027L40.8677 61.1396V66.6766L37.5122 68.5396V63.0027Z"
          fill="#F3C845"
        />
        <path
          d="M40.8672 61.1397L44.2227 59.2766V64.8135L40.8672 66.6766V61.1397Z"
          fill="#F0C640"
        />
        <path
          d="M44.2227 59.2767L47.5781 57.4136V62.9505L44.2227 64.8136V59.2767Z"
          fill="#EABE3E"
        />
        <path
          d="M47.5781 57.4134L50.9336 55.5503V61.0872L47.5781 62.9503V57.4134Z"
          fill="#A47808"
        />
        <path
          d="M50.9336 55.5503L54.2891 53.6873V59.2242L50.9336 61.0873V55.5503Z"
          fill="#996D04"
        />
        <path
          d="M54.2891 53.6873L57.6445 51.8242V57.3611L54.2891 59.2242V53.6873Z"
          fill="#D2A00F"
        />
        <path
          d="M57.6446 51.8243L61 49.9612V55.4981L57.6446 57.3612V51.8243Z"
          fill="#6EC799"
        />
        <path
          d="M14.0235 70.5074L17.3789 68.6443V74.1812L14.0235 76.0443V70.5074Z"
          fill="#A6812D"
        />
        <path
          d="M17.3789 68.6443L20.7344 66.7812V72.3182L17.3789 74.1813V68.6443Z"
          fill="#936117"
        />
        <path
          d="M20.7344 66.7813L24.0898 64.9182V70.4551L20.7344 72.3182V66.7813Z"
          fill="#A6701F"
        />
        <path
          d="M24.0904 64.9183L27.4458 63.0552V68.5921L24.0904 70.4552V64.9183Z"
          fill="#A6701D"
        />
        <path
          d="M27.4458 63.0552L30.8013 61.1921V66.7291L27.4458 68.5921V63.0552Z"
          fill="#D7AA2D"
        />
        <path
          d="M30.8013 61.1919L34.1567 59.3289V64.8658L30.8013 66.7289V61.1919Z"
          fill="#E5BC3C"
        />
        <path
          d="M34.1568 59.3289L37.5122 57.4658V63.0027L34.1568 64.8658V59.3289Z"
          fill="#F1C73F"
        />
        <path
          d="M37.5122 57.4659L40.8677 55.6028V61.1397L37.5122 63.0028V57.4659Z"
          fill="#F9D046"
        />
        <path
          d="M40.8672 55.6028L44.2227 53.7397V59.2767L40.8672 61.1397V55.6028Z"
          fill="#F9CE41"
        />
        <path
          d="M44.2227 53.7395L47.5781 51.8765V57.4134L44.2227 59.2765V53.7395Z"
          fill="#B1810E"
        />
        <path
          d="M47.5781 51.8765L50.9336 50.0134V55.5504L47.5781 57.4134V51.8765Z"
          fill="#AB7C09"
        />
        <path
          d="M50.9336 50.0135L54.2891 48.1504V53.6873L50.9336 55.5504V50.0135Z"
          fill="#A47405"
        />
        <path
          d="M54.2891 48.1504L57.6445 46.2874V51.8243L54.2891 53.6874V48.1504Z"
          fill="#906300"
        />
        <path
          d="M57.6446 46.2874L61 44.4243V49.9612L57.6446 51.8243V46.2874Z"
          fill="#C59710"
        />
        <path
          d="M14.0235 64.9705L17.3789 63.1074V68.6443L14.0235 70.5074V64.9705Z"
          fill="#915C11"
        />
        <path
          d="M17.3789 63.1075L20.7344 61.2444V66.7813L17.3789 68.6444V63.1075Z"
          fill="#A26D1D"
        />
        <path
          d="M20.7344 61.2442L24.0898 59.3811V64.918L20.7344 66.7811V61.2442Z"
          fill="#B17D22"
        />
        <path
          d="M24.0904 59.3811L27.4458 57.5181V63.055L24.0904 64.9181V59.3811Z"
          fill="#B17D24"
        />
        <path
          d="M27.4458 57.5181L30.8013 55.655V61.192L27.4458 63.055V57.5181Z"
          fill="#AF7724"
        />
        <path
          d="M30.8013 55.6551L34.1567 53.792V59.3289L30.8013 61.192V55.6551Z"
          fill="#E4BD3C"
        />
        <path
          d="M34.1568 53.792L37.5122 51.929V57.4659L34.1568 59.329V53.792Z"
          fill="#F8CC42"
        />
        <path
          d="M37.5122 51.929L40.8677 50.0659V55.6028L37.5122 57.4659V51.929Z"
          fill="#F9D747"
        />
        <path
          d="M40.8672 50.0657L44.2227 48.2026V53.7396L40.8672 55.6026V50.0657Z"
          fill="#E8D03A"
        />
        <path
          d="M44.2227 48.2027L47.5781 46.3396V51.8765L44.2227 53.7396V48.2027Z"
          fill="#B4860C"
        />
        <path
          d="M47.5781 46.3396L50.9336 44.4766V50.0135L47.5781 51.8766V46.3396Z"
          fill="#B0820A"
        />
        <path
          d="M50.9336 44.4766L54.2891 42.6135V48.1504L50.9336 50.0135V44.4766Z"
          fill="#A97B04"
        />
        <path
          d="M54.2891 42.6136L57.6445 40.7505V46.2874L54.2891 48.1505V42.6136Z"
          fill="#9F6F01"
        />
        <path
          d="M57.6446 40.7503L61 38.8872V44.4241L57.6446 46.2872V40.7503Z"
          fill="#CC9A13"
        />
        <path
          d="M17.3789 57.5704L20.7344 55.7073V61.2442L17.3789 63.1073V57.5704Z"
          fill="#BD9840"
        />
        <path
          d="M20.7344 55.7073L24.0898 53.8442V59.3812L20.7344 61.2442V55.7073Z"
          fill="#B37F25"
        />
        <path
          d="M24.0904 53.8443L27.4458 51.9812V57.5181L24.0904 59.3812V53.8443Z"
          fill="#B37F23"
        />
        <path
          d="M27.4458 51.9812L30.8013 50.1182V55.6551L27.4458 57.5182V51.9812Z"
          fill="#B17E23"
        />
        <path
          d="M30.8013 50.1182L34.1567 48.2551V53.792L30.8013 55.6551V50.1182Z"
          fill="#E6BB3A"
        />
        <path
          d="M34.1568 48.2552L37.5122 46.3921V51.929L34.1568 53.7921V48.2552Z"
          fill="#F9D142"
        />
        <path
          d="M37.5122 46.3919L40.8677 44.5288V50.0657L37.5122 51.9288V46.3919Z"
          fill="#F9DC47"
        />
        <path
          d="M40.8672 44.5288L44.2227 42.6658V48.2027L40.8672 50.0658V44.5288Z"
          fill="#BC8C0F"
        />
        <path
          d="M44.2227 42.6658L47.5781 40.8027V46.3397L44.2227 48.2027V42.6658Z"
          fill="#BB890E"
        />
        <path
          d="M47.5781 40.8028L50.9336 38.9397V44.4766L47.5781 46.3397V40.8028Z"
          fill="#B68711"
        />
        <path
          d="M50.9336 38.9397L54.2891 37.0767V42.6136L50.9336 44.4767V38.9397Z"
          fill="#BD9840"
        />
        <path
          d="M57.6446 35.2134L61 33.3503V38.8873L57.6446 40.7503V35.2134Z"
          fill="#BF9836"
        />
        <path
          d="M20.7344 50.1704L24.0898 48.3074V53.8443L20.7344 55.7074V50.1704Z"
          fill="#B38725"
        />
        <path
          d="M24.0904 48.3074L27.4458 46.4443V51.9813L24.0904 53.8443V48.3074Z"
          fill="#B38425"
        />
        <path
          d="M27.4458 46.4444L30.8013 44.5813V50.1182L27.4458 51.9813V46.4444Z"
          fill="#B38626"
        />
        <path
          d="M34.1568 42.7181L37.5122 40.855V46.3919L34.1568 48.255V42.7181Z"
          fill="#F9D645"
        />
        <path
          d="M40.8672 38.992L44.2227 37.1289V42.6658L40.8672 44.5289V38.992Z"
          fill="#BD900E"
        />
        <path
          d="M44.2227 37.1289L47.5781 35.2659V40.8028L44.2227 42.6659V37.1289Z"
          fill="#BE9114"
        />
        <path
          d="M47.5781 35.2659L50.9336 33.4028V38.9398L47.5781 40.8028V35.2659Z"
          fill="#C29416"
        />
        <path
          d="M57.6446 29.6766L61 27.8135V33.3504L57.6446 35.2135V29.6766Z"
          fill="#BB9332"
        />
        <path
          d="M14.0235 48.3597L17.3789 46.4966V52.0335L14.0235 53.8966V48.3597Z"
          fill="#A5872B"
        />
        <path
          d="M20.7344 44.6336L24.0898 42.7705V48.3074L20.7344 50.1705V44.6336Z"
          fill="#B69223"
        />
        <path
          d="M24.0904 42.7705L27.4458 40.9075V46.4444L24.0904 48.3075V42.7705Z"
          fill="#B38924"
        />
        <path
          d="M27.4458 40.9073L30.8013 39.0442V44.5811L27.4458 46.4442V40.9073Z"
          fill="#B38C25"
        />
        <path
          d="M30.8013 39.0442L34.1567 37.1812V42.7181L30.8013 44.5812V39.0442Z"
          fill="#F9D346"
        />
        <path
          d="M34.1568 37.1812L37.5122 35.3181V40.855L34.1568 42.7181V37.1812Z"
          fill="#FAD845"
        />
        <path
          d="M37.5122 35.3182L40.8677 33.4551V38.992L37.5122 40.8551V35.3182Z"
          fill="#F9E64D"
        />
        <path
          d="M40.8672 33.4551L44.2227 31.592V37.129L40.8672 38.992V33.4551Z"
          fill="#B98915"
        />
        <path
          d="M44.2227 31.5921L47.5781 29.729V35.2659L44.2227 37.129V31.5921Z"
          fill="#C39314"
        />
        <path
          d="M47.5781 29.7288L50.9336 27.8657V33.4026L47.5781 35.2657V29.7288Z"
          fill="#D2A21D"
        />
        <path
          d="M54.2891 26.0027L57.6445 24.1396V29.6766L54.2891 31.5396V26.0027Z"
          fill="#CEA83C"
        />
        <path
          d="M57.6446 24.1397L61 22.2766V27.8135L57.6446 29.6766V24.1397Z"
          fill="#CDA93E"
        />
        <path
          d="M24.0904 37.2334L27.4458 35.3704V40.9073L24.0904 42.7704V37.2334Z"
          fill="#B29028"
        />
        <path
          d="M30.8013 33.5074L34.1567 31.6443V37.1812L30.8013 39.0443V33.5074Z"
          fill="#F9DB47"
        />
        <path
          d="M34.1568 31.6443L37.5122 29.7812V35.3182L34.1568 37.1813V31.6443Z"
          fill="#F9E34C"
        />
        <path
          d="M37.5122 29.7813L40.8677 27.9182V33.4551L37.5122 35.3182V29.7813Z"
          fill="#FAEA50"
        />
        <path
          d="M44.2227 26.055L47.5781 24.1919V29.7288L44.2227 31.5919V26.055Z"
          fill="#C59518"
        />
        <path
          d="M57.6446 18.6028L61 16.7397V22.2767L57.6446 24.1397V18.6028Z"
          fill="#D8B542"
        />
        <path
          d="M20.7344 33.5596L24.0898 31.6965V37.2335L20.7344 39.0965V33.5596Z"
          fill="#C5AE85"
        />
        <path
          d="M24.0904 31.6966L27.4458 29.8335V35.3704L24.0904 37.2335V31.6966Z"
          fill="#B39227"
        />
        <path
          d="M27.4458 29.8335L30.8013 27.9705V33.5074L27.4458 35.3705V29.8335Z"
          fill="#D4C179"
        />
        <path
          d="M34.1568 26.1075L37.5122 24.2444V29.7813L34.1568 31.6444V26.1075Z"
          fill="#F7EBAE"
        />
        <path
          d="M40.8672 22.3811L44.2227 20.5181V26.055L40.8672 27.9181V22.3811Z"
          fill="#D5A822"
        />
        <path
          d="M44.2227 20.5181L47.5781 18.655V24.192L44.2227 26.055V20.5181Z"
          fill="#CA981A"
        />
        <path
          d="M47.5781 18.6551L50.9336 16.792V22.3289L47.5781 24.192V18.6551Z"
          fill="#D5A822"
        />
        <path
          d="M24.0904 26.1597L27.4458 24.2966V29.8336L24.0904 31.6966V26.1597Z"
          fill="#BB9B35"
        />
        <path
          d="M44.2227 14.9812L47.5781 13.1182V18.6551L44.2227 20.5182V14.9812Z"
          fill="#CE9E1B"
        />
        <path
          d="M10.668 100.055L14.0234 98.1919V103.729L10.668 105.592V100.055Z"
          fill="#AA8C18"
        />
        <path
          d="M10.668 94.5181L14.0234 92.655V98.192L10.668 100.055V94.5181Z"
          fill="#987402"
        />
        <path
          d="M10.668 88.9812L14.0234 87.1182V92.6551L10.668 94.5182V88.9812Z"
          fill="#05866C"
        />
        <path
          d="M10.668 83.4444L14.0234 81.5813V87.1182L10.668 88.9813V83.4444Z"
          fill="#009770"
        />
        <path
          d="M10.668 77.9073L14.0234 76.0442V81.5811L10.668 83.4442V77.9073Z"
          fill="#0F8D6D"
        />
        <path
          d="M10.668 72.3704L14.0234 70.5073V76.0442L10.668 77.9073V72.3704Z"
          fill="#B89C20"
        />
        <path
          d="M10.668 66.8335L14.0234 64.9705V70.5074L10.668 72.3705V66.8335Z"
          fill="#B29218"
        />
        <path
          d="M10.668 61.2967L14.0234 59.4336V64.9705L10.668 66.8336V61.2967Z"
          fill="#A06B01"
        />
        <path
          d="M10.668 55.7598L14.0234 53.8967V59.4337L10.668 61.2967V55.7598Z"
          fill="#956D23"
        />
        <path
          d="M10.668 50.2227L14.0234 48.3596V53.8965L10.668 55.7596V50.2227Z"
          fill="#AA8006"
        />
        <path
          d="M10.668 44.6858L14.0234 42.8228V48.3597L10.668 50.2228V44.6858Z"
          fill="#AA8D2F"
        />
      </g>
      <defs>
        <clipPath id="clip0_3351_99088">
          <rect
            width="57.5697"
            height="83.0538"
            fill="white"
            transform="matrix(-0.874274 0.485432 0 1 61 0.128906)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
