import { UserOnlineStatusUpdate } from '../types/enums';

import api from './client';

const userApi = {
  sharePromoCode: (params: ShareUserPromoCodeReq) =>
    api.post<Response<ShareUserPromoCodeShareRes>>(
      'v2/user/promo-code/share',
      params
    ),
  updateUserStatus: (status: UserOnlineStatusUpdate) =>
    api.put<Response<UpdateUserStatusRes>>(`/v2/user/online?status=${status}`),
};

export default userApi;
