import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { TestFormula as TestFormulaIcon } from '@front/icon';
import { LabelIcon, OtherMessage } from '@front/ui';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/react';
import matchAll from 'string.prototype.matchall';

import useInputEvent from '../../hooks/useInputEvent';
import useSuccessState from '../../hooks/useSuccessState';
import { FormLayoutFormulaItem, FormLayoutItemStatus } from '../../types';
import { getCustomValidate } from '../../utils';

import renderItems from './renderItems';
import SlashMenuExtension from './SlashMenuExtension';
import { VariableOption } from './type';
import useVariableItems from './useVariableItems';
import VariableExtension from './VariableExtension';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const formulaRegex = /\[\[(.*?)\]\]/g;

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    '& .tiptap': {
      position: 'relative',
      display: 'flex',
      minHeight: 86,
      borderRadius: 1,
      border: (theme: Theme) =>
        `1px solid ${alpha(theme.palette.text.primary, 0.05)}`,
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      outlineColor: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? alpha(theme.palette.primary.light, 0.3)
          : alpha(theme.palette.primary.dark, 0.3),
      outlineStyle: 'solid',
      outlineWidth: 0,

      px: { xs: '8px', md: '10px' },
      py: { xs: '10px', md: '4px' },
      fontSize: { xs: 16, md: 14 },

      '& p': {
        m: 0,
      },

      '& p.is-editor-empty:first-of-type::before': {
        color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
        fontSize: { xs: 16, md: 14 },
        content: 'attr(data-placeholder)',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
      },
    },
  },
  disabledLabel: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
  focused: {
    '& .tiptap': {
      borderColor: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? alpha(theme.palette.primary.light, 0.6)
          : alpha(theme.palette.primary.dark, 0.6),
      outlineWidth: '2px',
    },
  },
  disabled: {
    '& .tiptap': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      border: '1px solid transparent',
    },
  },
  error: {
    '& .tiptap': {
      borderColor: 'error.dark',
      outlineColor: (theme: Theme) => alpha(theme.palette.error.dark, 0.3),
    },
    '& .textfield-message': {
      color: 'error.dark',
    },
  },
  success: {
    '& .tiptap': {
      borderColor: (theme: Theme) =>
        theme.palette.mode === 'dark' ? 'success.light' : 'success.dark',
      outlineColor: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? alpha(theme.palette.success.light, 0.3)
          : alpha(theme.palette.success.dark, 0.3),
    },
  },
};

const getFormulaInputHtml = (text: string, options: VariableOption[]) => {
  const multipleMatchArr = [...matchAll(text, formulaRegex)];

  if (!multipleMatchArr.length) return text;

  let updatedText = text;
  multipleMatchArr.forEach((match) => {
    const currentOption = options.find((option) => option.name === match[1]);
    updatedText = updatedText.replace(
      match[0],
      currentOption
        ? `<variable-component name="${currentOption.name}" type="${currentOption.type}" subType="${currentOption.subType}" ></variable-component>`
        : ''
    );
  });
  return updatedText;
};
type FormulaTiptapProps = {
  options: VariableOption[];
  disabled: boolean;
  placeholder?: string;
  autofocus?: boolean;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  onFocus: () => void;
};
const FormulaTiptap = ({
  options,
  autofocus,
  disabled,
  placeholder,
  value,
  onChange,
  onBlur,
  onFocus,
}: FormulaTiptapProps) => {
  const getSuggestionItems = useVariableItems(options);

  const formulaEditor = useEditor({
    editable: !disabled,
    autofocus: autofocus ? 'end' : undefined,
    extensions: [
      Document,
      Paragraph,
      Text,
      VariableExtension,
      Placeholder.configure({
        placeholder,
      }),
      SlashMenuExtension.configure({
        suggestion: {
          items: getSuggestionItems,
          render: renderItems,
        },
      }),
    ],
    content: getFormulaInputHtml(value, options),
    onUpdate: ({ editor }) => {
      onChange?.(editor.getText());
    },
    onBlur,
    onFocus,
  });

  return <EditorContent editor={formulaEditor} />;
};

type FormulaInputProps = {
  item: FormLayoutFormulaItem;
};

export default function FormulaInput({ item }: FormulaInputProps) {
  const { control, getValues } = useFormContext();

  const validate = item.customRules
    ? getCustomValidate(item.customRules, { getValues })
    : undefined;

  const [active, setActive] = useState(false);

  const {
    field: { onChange, onBlur, value },
    fieldState: { isDirty, error },
  } = useController({
    control,
    name: item.name,
    rules: { ...item.rules, validate },
  });

  const successState = useSuccessState(item.name, isDirty);

  const { handleInputBlur, handleInputChange } = useInputEvent({
    name: item.name,
    customRules: item.customRules,
    actionMap: item.actionMap,
    onChange,
    onBlur,
  });

  const handleBlur = () => {
    handleInputBlur();
    void successState.handleBlur();
  };

  const handleChange = (newValue: string) => {
    void handleInputChange(newValue);
    void successState.handleChange();
  };

  const disabled =
    item.status === FormLayoutItemStatus.Disabled || !!item.disabled;

  const handleTiptapBlur = () => {
    setActive(false);
    handleBlur?.();
  };

  const handleTiptapFocus = () => {
    setActive(true);
  };

  return (
    <Box
      sx={[
        styles.root,
        disabled && styles.disabled,
        active && styles.focused,
        !!error && styles.error,
        successState.success && styles.success,
      ]}
    >
      <Typography
        className="textfield-label"
        variant="caption"
        sx={[disabled && styles.disabledLabel]}
      >
        <LabelIcon
          label={item.label}
          icon={<TestFormulaIcon width={12} height={12} />}
          required={
            !!item.rules?.required &&
            (typeof item.rules.required === 'string' ||
              !item.rules.required.hiddenRule)
          }
        />
      </Typography>
      <FormulaTiptap
        options={item.options}
        autofocus={item.autofocus}
        placeholder={item.placeholder}
        onChange={handleChange}
        onBlur={handleTiptapBlur}
        onFocus={handleTiptapFocus}
        disabled={disabled}
        value={value ?? item.defaultValue ?? ''}
      />
      <OtherMessage
        inputFocused={active}
        error={!!error}
        helperText={error?.message ?? item.helperText}
      />
    </Box>
  );
}
