import React from 'react';

export default function OtherTrigonometry({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.5 16.6499V19.5H7.55026V16.6968C7.55026 16.6709 7.52926 16.6499 7.50337 16.6499H4.5ZM9.05026 19.5V16.6968C9.05026 15.8425 8.35769 15.1499 7.50337 15.1499H4.5V5.49594L18.5041 19.5H9.05026ZM20.2015 21C20.9115 21 21.267 20.1416 20.765 19.6396L4.36038 3.235C3.85836 2.73299 3 3.08853 3 3.79849V20.2031C3 20.6432 3.35678 21 3.79689 21H20.2015Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
