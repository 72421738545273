import React from 'react';

export default function FlagUK({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_476_210921)">
        <g clipPath="url(#clip1_476_210921)">
          <path d="M-0.75 3H24.0259V20.642H-0.75V3Z" fill="#012169" />
          <path
            d="M2.15342 3L11.5992 9.65252L21.0063 3H24.0259V5.27876L14.7349 11.8578L24.0259 18.4V20.642H20.9289L11.6379 14.063L2.3857 20.642H-0.75V18.4368L8.50224 11.8945L-0.75 5.35227V3H2.15342Z"
            fill="white"
          />
          <path
            d="M15.664 13.3279L24.0259 19.1719V20.642L13.5348 13.3279H15.664ZM8.54095 14.063L8.77322 15.3494L1.34046 20.642H-0.75L8.54095 14.063ZM24.0259 3V3.11026L14.3865 10.0201L14.4639 8.40288L22.0902 3H24.0259ZM-0.75 3L8.50224 9.46875H6.1795L-0.75 4.54368V3Z"
            fill="#C8102E"
          />
          <path
            d="M8.57966 3V20.642H14.7736V3H8.57966ZM-0.75 8.88068V14.7614H24.0259V8.88068H-0.75Z"
            fill="white"
          />
          <path
            d="M-0.75 10.0936V13.622H24.0259V10.0936H-0.75ZM9.81845 3V20.642H13.5348V3H9.81845Z"
            fill="#C8102E"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_476_210921">
          <rect height="24" width="24" fill="white" rx="4" />
        </clipPath>
        <clipPath id="clip1_476_210921">
          <rect
            height="17.642"
            width="24.7759"
            fill="white"
            rx="4"
            x="-0.75"
            y="3"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
