import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { alpha, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { currencyFormat, numberFormat } from '../../../utils/format';

export type ProgressTextAboveBarProps = {
  value: number;
  total: number;
  ratio?: number;
  description?: string;
  sx?: BoxProps['sx'];
  color?: 'primary' | 'success' | 'error' | 'warning';
};

const styles = {
  number: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  desc: { ml: '10px' },
  total: { opacity: 0.5 },
  bar: {
    width: '100%',
    height: 8,
    borderRadius: 1,
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
  barInner: {
    height: '100%',
    borderRadius: 1,
  },
};

export default function ProgressTextAboveBar({
  value,
  total,
  description,
  sx,
  ratio,
  color = 'primary',
}: ProgressTextAboveBarProps) {
  const theme = useTheme();
  const bgColor = theme.palette.gradient[color];
  const pct = (ratio === undefined ? value / total : ratio) * 100;

  return (
    <Box sx={sx}>
      <Box sx={styles.number}>
        <Typography variant="h3" fontWeight={700}>
          {numberFormat(pct)}%
          {!!description && (
            <Typography
              sx={styles.desc}
              variant="subtitle2"
              component="span"
              color="grey.600"
              fontWeight={400}
            >
              {description}
            </Typography>
          )}
        </Typography>
        <Box>
          <Typography variant="subtitle2">
            {currencyFormat(value)}
            <Typography
              sx={styles.total}
              variant="subtitle2"
              component="span"
              fontWeight={400}
            >
              &nbsp;/&nbsp;{currencyFormat(total)}
            </Typography>
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.bar}>
        <Box
          sx={[styles.barInner, { background: bgColor, width: `${pct}%` }]}
        />
      </Box>
    </Box>
  );
}
