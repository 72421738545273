import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ActionChildLevel as ActionChildLevelIcon } from '@front/icon';
import { SimpleTooltip, useTruncated } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';

import { DisplayTableLayoutIconTextCell } from '../../types';

export type IaDisplayTableIconTextCellProps = DisplayTableLayoutIconTextCell;

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    overflow: 'hidden',
    gap: 1,
  },
  rootDefault: {
    gridTemplateColumns: '16px 1fr',
  },
  rootChild: {
    gridTemplateColumns: '32px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    br: {
      // make html element render on one line
      content: '" "',
      display: 'none',
    },
  },
};

export default function IaDisplayTableIconTextCell({
  icon,
  text,
  html,
  isChild,
}: IaDisplayTableIconTextCellProps) {
  const { containerRef, isTruncated } = useTruncated();

  if (html && text) {
    console.warn('Both text and html are provided, only html will be used');
  }

  return (
    <Box sx={[styles.root, isChild ? styles.rootChild : styles.rootDefault]}>
      <Box sx={styles.icon}>
        {isChild && <ActionChildLevelIcon width={16} height={16} />}
        {icon && icon.type === 'icon' && (
          <Icon name={icon.value} width={16} height={16} />
        )}
        {icon && icon.type === 'emoji' && (
          <Typography variant="body2">{icon.value}</Typography>
        )}
      </Box>
      {html ? (
        <Typography
          variant="body2"
          sx={styles.text}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      ) : (
        <SimpleTooltip title={isTruncated ? text : ''}>
          <Typography variant="body2" sx={styles.text} ref={containerRef}>
            {text}
          </Typography>
        </SimpleTooltip>
      )}
    </Box>
  );
}
