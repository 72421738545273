import Icon from '@lib/ia/src/components/Icon';
import { VariableType } from '@lib/web/apis';
import BlockTag from '@lib/web/composer/BlockTag';
import ThemeProvider from '@lib/web/composer/components/ThemeProvider';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { v4 } from 'uuid';

import { ThreadBlockTypes } from '../../config/threadBlockTypes';

export type InlineVariableOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineVariableOptions;
    node: ProseMirrorNode;
  }) => string;
};

type BlockTabButtonProps = {
  node: ProseMirrorNode;
  updateAttributes: (attributes: Record<string, any>) => void;
};

type VariableProps = BlockTabButtonProps;

const VARIABLE_TYPE_MAPPING = {
  [VariableType.Text]: {
    title: 'Text Variable',
    icon: 'EditorTextVariable',
  },
  [VariableType.Number]: {
    title: 'Number Variable',
    icon: 'EditorNumberVariable',
  },
  [VariableType.NumberFormula]: {
    title: 'Variable Formula',
    icon: 'TestFormula',
  },
};

const BlockTabButton = ({ node }: BlockTabButtonProps) => {
  return (
    <BlockTag
      icon={
        <Icon
          name={VARIABLE_TYPE_MAPPING[node.attrs.type as VariableType].icon}
        />
      }
      disabled
    >
      {node.attrs.name ||
        VARIABLE_TYPE_MAPPING[node.attrs.type as VariableType].title}
    </BlockTag>
  );
};
const Variable = ({ node, updateAttributes }: VariableProps) => {
  return (
    <NodeViewWrapper
      style={{ display: 'inline-block', maxHeight: 18 }}
      className="inline-variable"
      contentEditable={false}
    >
      <ThemeProvider mode="dark">
        <BlockTabButton node={node} updateAttributes={updateAttributes} />
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export const InlineVariable = Node.create<InlineVariableOptions>({
  name: ThreadBlockTypes.InlineVariable,

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: v4,
        parseHTML: () => v4(), // when copy and paste, generate a new id
      },
      variableId: {
        default: '',
      },
      type: {
        default: VariableType.Text,
      },
      isNewCreated: {
        default: false,
      },
      defaultName: {
        default: '',
      },
      defaultContent: {
        default: '',
      },
      name: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.name,
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      'span',
      mergeAttributes({
        ...HTMLAttributes,
        class: 'inline-variable',
        'data-content-type': this.name,
        style:
          'color: var(--inline-variable-color); background-color: var(--inline-variable-bgcolor); padding: 4px; border-radius: 4px;',
      }),
      node.attrs.name ||
        VARIABLE_TYPE_MAPPING[node.attrs.type as VariableType].title,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Variable);
  },
});
