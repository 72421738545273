import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import Icon from '@lib/ia/src/components/Icon';

import { CarouselLayoutItem } from '../types';

const styles = {
  root: {
    background:
      'linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
    borderRadius: 2,
    pl: { xs: '20px', md: 9 },
    pr: { xs: '20px', md: 7.25 },
    minHeight: { md: '200px', xs: '134px' },
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    zIndex: 10,
  },
  content: {
    position: 'relative',
    zIndex: 10,
  },
  heading: {
    display: 'flex',
    flexDirection: { xs: 'row', md: 'column-reverse' },
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: { xs: 1, md: 0.25 },
    zIndex: 10,
  },
  headingContent: {
    whiteSpace: 'nowrap',
    fontSize: { xs: 24, md: 48 },
  },
  subHeadingContent: {
    letterSpacing: '0.4px',
    fontSize: { xs: 12, md: 16 },
    whiteSpace: { xs: 'normal', md: 'pre-line' },
    fontWeight: 400,
  },
  icons: {
    position: 'absolute',
    right: { xs: '20px', md: '58px' },
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: { xs: 'center', md: 'flex-end' },
    gap: { xs: 0.5, md: 1 },
    '& .emoji-hamburger': {
      mb: 1.5,
    },
    zIndex: 1,
    opacity: { xs: 0.4, md: 1 },
  },
  tag: {
    bgcolor: 'text.primary',
    borderRadius: 1,
    fontSize: { xs: 12, md: 16 },
    color: 'background.darker',
    px: { xs: 1, md: 1.5 },
    py: { md: '2px' },
  },
};

type IaCarouselItemProps = CarouselLayoutItem;

export default function IaCarouselItem({
  headingHtml,
  subHeadingHtml,
  tagContent,
  icons = [],
  sx,
  iconContainerSx,
}: IaCarouselItemProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const sxProps = Array.isArray(sx) ? sx : [sx];
  const sxIconContainerProps = Array.isArray(iconContainerSx)
    ? iconContainerSx
    : [iconContainerSx];

  return (
    <Box sx={[styles.root, ...sxProps]}>
      <Box sx={styles.container}>
        <Box sx={styles.content}>
          <Box sx={[styles.heading]}>
            <Typography
              variant="h3"
              sx={[styles.headingContent]}
              dangerouslySetInnerHTML={{
                __html: headingHtml || '',
              }}
            />
            {tagContent && (
              <Typography variant="body1" sx={styles.tag}>
                {tagContent}
              </Typography>
            )}
          </Box>
          <Typography
            variant="body1"
            sx={styles.subHeadingContent}
            dangerouslySetInnerHTML={{
              __html: subHeadingHtml || '',
            }}
          />
        </Box>
        <Box sx={[styles.icons, ...sxIconContainerProps]}>
          {icons.map((icon, index) => (
            <Icon
              key={index}
              name={icon.name}
              width={icon.width}
              height={icon.height}
              style={mdUp ? icon.style : icon.mobileStyle}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
