import api from './client';

const agentApi = {
  duplicateAgent: (params: DuplicateAgentReq) =>
    api.post<Response<{ id: string }>>('/v2/agent/duplicate', params),
  updateMyAgent: (params: UpdateMyAgentReq) => api.put('/v2/agent/me', params),
  addMyAgent: (params: AddMyAgentReq) => api.post('/v2/agent/me', params),
  removeMyAgent: (params: RemoveMyAgentReq) =>
    api.delete('/v2/agent/me', { data: params }),
  reorderMyAgent: (params: ReorderMyAgentReq) =>
    api.post('/v2/agent/me/order', params),
  createAgent: (params: CreateAgentReq) =>
    api.post<Response<CreateAgentRes>>('/v2/agent', params),
  updateAgent: (params: UpdateAgentReq) => api.put('/v2/agent', params),
  deleteAgent: (params: DeleteAgentReq) =>
    api.delete('/v2/agent', { data: params }),
  savePromptList: (params: SaveAgentPromptListReq) =>
    api.post('/v2/agent/prompt/list', params),
  saveDataSourceList: (params: SaveAgentDataSourceListReq) =>
    api.post('/v2/agent/datasource/list', params),
  updateDataSource: (params: UpdateAgentDataSourceReq) =>
    api.put<Response<UpdateDataSourceRes>>('/v2/agent/datasource', params),
  deleteDataSource: (params: DeleteAgentDataSourceReq) =>
    api.delete<Response<DeleteDataSourceRes>>('/v2/agent/datasource', {
      data: params,
    }),
  getDataSourceFile: (agentId: string) =>
    api.get<Response<GetDataSourceFileRes[]>>(
      `/v2/agent/datasource/file?agentId=${agentId}`
    ),
  createDataSourceFile: (params: CreateAgentDataSourceFileReq) =>
    api.post<Response<CreateDataSourceFileRes>>(
      '/v2/agent/datasource/file',
      params
    ),
  getDataSourceLink: (agentId: string) =>
    api.get<Response<GetDataSourceLinkRes[]>>(
      `/v2/agent/datasource/link?agentId=${agentId}`
    ),
  createDataSourceLink: (params: CreateAgentDataSourceLinkReq) =>
    api.post<Response<CreateDataSourceLinkRes>>(
      '/v2/agent/datasource/link',
      params
    ),
};

export default agentApi;
