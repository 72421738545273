import { HeadThumbnailDetail } from '@lib/web/thread/types';
import { isNil, omitBy } from 'lodash';
import { ChannelData } from 'stream-chat';

export const defaultHeadThumbnailDetail: HeadThumbnailDetail = {
  selectedOption: 'created_by',
  options: [
    {
      label: 'Created By',
      icon: {
        type: 'icon',
        value: 'MainProfileSolid',
      },
      value: 'created_by',
    },
  ],
};

export const headThumbnailChannelDataKeys = [
  'selectedHeadThumbnailOption',
  'selectedHeadThumbnailSubOption',
  'headThumbnailOptions',
];

// store information to channel
export const getHeadThumbnailChannelData = (
  headThumbnailDetail: HeadThumbnailDetail
): ChannelData => {
  return omitBy(
    {
      selectedHeadThumbnailOption: headThumbnailDetail.selectedOption,
      selectedHeadThumbnailSubOption: headThumbnailDetail.selectedSubOption,
      headThumbnailOptions: headThumbnailDetail.options,
    },
    isNil
  );
};

export const getChannelHeadThumbnailDetail = (
  channelData: ChannelData
): HeadThumbnailDetail => {
  return channelData.selectedHeadThumbnailOption
    ? ({
        selectedOption: channelData.selectedHeadThumbnailOption,
        selectedSubOption: channelData.selectedHeadThumbnailSubOption,
        options: channelData.headThumbnailOptions,
      } as HeadThumbnailDetail)
    : defaultHeadThumbnailDetail;
};
