import React, { SVGAttributes } from 'react';

export default function NFTEmojiRocket({
  ...rest
}: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width="57"
      height="100"
      viewBox="0 0 57 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.2416 76.1105V71.4082L26.6502 65.1972V69.9089L16.2416 76.1105Z"
        fill="#E6E9F0"
      />
      <path
        d="M16.2416 76.1105V71.4082L26.6502 65.1972V69.9089L16.2416 76.1105Z"
        fill="#84604C"
      />
      <path
        d="M16.2416 76.1105V71.4082L26.6502 65.1972V69.9089L16.2416 76.1105Z"
        fill="#5CCBF9"
      />
      <path
        d="M16.2416 76.1105V71.4082L26.6502 65.1972V69.9089L16.2416 76.1105Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M29.2958 77.8455V73.1433L39.7044 66.9323V71.644L29.2958 77.8455Z"
        fill="#F5070C"
      />
      <path
        d="M29.2958 77.8455V73.1433L39.7044 66.9323V71.644L29.2958 77.8455Z"
        fill="black"
        fillOpacity="0.35"
      />
      <path
        d="M32.554 74.7607V70.0585L42.9626 63.8475V68.5592L32.554 74.7607Z"
        fill="#F5070C"
      />
      <path
        d="M32.554 74.7607V70.0585L42.9626 63.8475V68.5592L32.554 74.7607Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M32.554 70.0702V65.368L42.9626 59.1569V63.8686L32.554 70.0702Z"
        fill="#FF2A00"
      />
      <path
        d="M32.554 70.0702V65.368L42.9626 59.1569V63.8686L32.554 70.0702Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M19.4995 73.0256V68.3234L29.9081 62.1123V66.824L19.4995 73.0256Z"
        fill="#E6E9F0"
      />
      <path
        d="M19.4995 73.0256V68.3234L29.9081 62.1123V66.824L19.4995 73.0256Z"
        fill="#84604C"
      />
      <path
        d="M19.4995 73.0256V68.3234L29.9081 62.1123V66.824L19.4995 73.0256Z"
        fill="#5CCBF9"
      />
      <path
        d="M19.4995 73.0256V68.3234L29.9081 62.1123V66.824L19.4995 73.0256Z"
        fill="black"
        fillOpacity="0.35"
      />
      <path
        d="M22.7843 69.8747V65.1725L33.1929 58.9614V63.6731L22.7843 69.8747Z"
        fill="#E6E9F0"
      />
      <path
        d="M22.7843 69.8747V65.1725L33.1929 58.9614V63.6731L22.7843 69.8747Z"
        fill="#84604C"
      />
      <path
        d="M22.7843 69.8747V65.1725L33.1929 58.9614V63.6731L22.7843 69.8747Z"
        fill="#5CCBF9"
      />
      <path
        d="M22.7843 69.8747V65.1725L33.1929 58.9614V63.6731L22.7843 69.8747Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M35.8114 66.9688V62.2666L46.22 56.0555V60.7672L35.8114 66.9688Z"
        fill="#E6E9F0"
      />
      <path
        d="M35.8114 66.9688V62.2666L46.22 56.0555V60.7672L35.8114 66.9688Z"
        fill="#84604C"
      />
      <path
        d="M35.8114 66.9688V62.2666L46.22 56.0555V60.7672L35.8114 66.9688Z"
        fill="#E1E0F0"
      />
      <path
        d="M35.8114 66.9688V62.2666L46.22 56.0555V60.7672L35.8114 66.9688Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M42.3265 56.0765V51.3743L52.7351 45.1632V49.8749L42.3265 56.0765Z"
        fill="#E6E9F0"
      />
      <path
        d="M42.3265 56.0765V51.3743L52.7351 45.1632V49.8749L42.3265 56.0765Z"
        fill="#84604C"
      />
      <path
        d="M42.3265 56.0765V51.3743L52.7351 45.1632V49.8749L42.3265 56.0765Z"
        fill="#E1E0F0"
      />
      <path
        d="M42.3265 56.0765V51.3743L52.7351 45.1632V49.8749L42.3265 56.0765Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M42.3265 60.7661V56.0639L52.7351 49.8528V54.5645L42.3265 60.7661Z"
        fill="#E6E9F0"
      />
      <path
        d="M42.3265 60.7661V56.0639L52.7351 49.8528V54.5645L42.3265 60.7661Z"
        fill="#84604C"
      />
      <path
        d="M42.3265 60.7661V56.0639L52.7351 49.8528V54.5645L42.3265 60.7661Z"
        fill="#E1E0F0"
      />
      <path
        d="M42.3265 60.7661V56.0639L52.7351 49.8528V54.5645L42.3265 60.7661Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M39.0689 63.8675V59.1653L49.4775 52.9542V57.6659L39.0689 63.8675Z"
        fill="#E6E9F0"
      />
      <path
        d="M39.0689 63.8675V59.1653L49.4775 52.9542V57.6659L39.0689 63.8675Z"
        fill="#84604C"
      />
      <path
        d="M39.0689 63.8675V59.1653L49.4775 52.9542V57.6659L39.0689 63.8675Z"
        fill="#E1E0F0"
      />
      <path
        d="M39.0689 63.8675V59.1653L49.4775 52.9542V57.6659L39.0689 63.8675Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M9.76923 35.1443L6.50623 33.5188L16.8993 27.327L20.1558 28.9587L9.76923 35.1443Z"
        fill="#D2B4DB"
      />
      <path
        d="M9.76923 35.1443L6.50623 33.5188L16.8993 27.327L20.1558 28.9587L9.76923 35.1443Z"
        fill="#84604C"
      />
      <path
        d="M9.76923 35.1443L6.50623 33.5188L16.8993 27.327L20.1558 28.9587L9.76923 35.1443Z"
        fill="#FF2A00"
      />
      <path
        d="M9.76923 35.1443L6.50623 33.5188L16.8993 27.327L20.1558 28.9587L9.76923 35.1443Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M13.0363 46.1805L9.77332 44.5549L20.1664 38.3632L23.4229 39.9948L13.0363 46.1805Z"
        fill="#D2B4DB"
      />
      <path
        d="M13.0363 46.1805L9.77332 44.5549L20.1664 38.3632L23.4229 39.9948L13.0363 46.1805Z"
        fill="#84604C"
      />
      <path
        d="M13.0363 46.1805L9.77332 44.5549L20.1664 38.3632L23.4229 39.9948L13.0363 46.1805Z"
        fill="#5CCBF9"
      />
      <path
        d="M13.0363 46.1805L9.77332 44.5549L20.1664 38.3632L23.4229 39.9948L13.0363 46.1805Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M9.77234 49.268L6.50934 47.6425L16.9024 41.4507L20.1589 43.0824L9.77234 49.268Z"
        fill="#D2B4DB"
      />
      <path
        d="M9.77234 49.268L6.50934 47.6425L16.9024 41.4507L20.1589 43.0824L9.77234 49.268Z"
        fill="#84604C"
      />
      <path
        d="M9.77234 49.268L6.50934 47.6425L16.9024 41.4507L20.1589 43.0824L9.77234 49.268Z"
        fill="#5CCBF9"
      />
      <path
        d="M9.77234 49.268L6.50934 47.6425L16.9024 41.4507L20.1589 43.0824L9.77234 49.268Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M6.51642 52.3503L3.25342 50.7247L13.6465 44.533L16.903 46.1646L6.51642 52.3503Z"
        fill="#D2B4DB"
      />
      <path
        d="M6.51642 52.3503L3.25342 50.7247L13.6465 44.533L16.903 46.1646L6.51642 52.3503Z"
        fill="#84604C"
      />
      <path
        d="M6.51642 52.3503L3.25342 50.7247L13.6465 44.533L16.903 46.1646L6.51642 52.3503Z"
        fill="#5CCBF9"
      />
      <path
        d="M6.51642 52.3503L3.25342 50.7247L13.6465 44.533L16.903 46.1646L6.51642 52.3503Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M16.2939 33.6824L13.0309 32.0568L23.424 25.8651L26.6946 27.479L16.2939 33.6824Z"
        fill="#D2B4DB"
      />
      <path
        d="M16.2939 33.6824L13.0309 32.0568L23.424 25.8651L26.6946 27.479L16.2939 33.6824Z"
        fill="#84604C"
      />
      <path
        d="M16.2939 33.6824L13.0309 32.0568L23.424 25.8651L26.6946 27.479L16.2939 33.6824Z"
        fill="#FF2A00"
      />
      <path
        d="M13.033 32.053L9.76996 30.4274L20.163 24.2357L23.4195 25.8673L13.033 32.053Z"
        fill="#D2B4DB"
      />
      <path
        d="M13.033 32.053L9.76996 30.4274L20.163 24.2357L23.4195 25.8673L13.033 32.053Z"
        fill="#84604C"
      />
      <path
        d="M13.033 32.053L9.76996 30.4274L20.163 24.2357L23.4195 25.8673L13.033 32.053Z"
        fill="#FF2A00"
      />
      <path
        d="M13.033 32.053L9.76996 30.4274L20.163 24.2357L23.4195 25.8673L13.033 32.053Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M26.0626 24.4156L22.7996 22.7901L33.1926 16.5983L36.4491 18.2299L26.0626 24.4156Z"
        fill="#D2B4DB"
      />
      <path
        d="M26.0626 24.4156L22.7996 22.7901L33.1926 16.5983L36.4491 18.2299L26.0626 24.4156Z"
        fill="#84604C"
      />
      <path
        d="M26.0626 24.4156L22.7996 22.7901L33.1926 16.5983L36.4491 18.2299L26.0626 24.4156Z"
        fill="white"
      />
      <path
        d="M26.0626 24.4156L22.7996 22.7901L33.1926 16.5983L36.4491 18.2299L26.0626 24.4156Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M22.7894 27.511L19.5264 25.8854L29.9195 19.6937L33.176 21.3253L22.7894 27.511Z"
        fill="#D2B4DB"
      />
      <path
        d="M22.7894 27.511L19.5264 25.8854L29.9195 19.6937L33.176 21.3253L22.7894 27.511Z"
        fill="#84604C"
      />
      <path
        d="M22.7894 27.511L19.5264 25.8854L29.9195 19.6937L33.176 21.3253L22.7894 27.511Z"
        fill="white"
      />
      <path
        d="M22.7894 27.511L19.5264 25.8854L29.9195 19.6937L33.176 21.3253L22.7894 27.511Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M19.5318 30.594L16.2688 28.9684L26.6619 22.7767L29.9183 24.4083L19.5318 30.594Z"
        fill="#D2B4DB"
      />
      <path
        d="M19.5318 30.594L16.2688 28.9684L26.6619 22.7767L29.9183 24.4083L19.5318 30.594Z"
        fill="#84604C"
      />
      <path
        d="M19.5318 30.594L16.2688 28.9684L26.6619 22.7767L29.9183 24.4083L19.5318 30.594Z"
        fill="white"
      />
      <path
        d="M19.5318 30.594L16.2688 28.9684L26.6619 22.7767L29.9183 24.4083L19.5318 30.594Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M29.3234 26.045L26.0604 24.4195L36.4535 18.2277L39.7241 19.8416L29.3234 26.045Z"
        fill="#D2B4DB"
      />
      <path
        d="M29.3234 26.045L26.0604 24.4195L36.4535 18.2277L39.7241 19.8416L29.3234 26.045Z"
        fill="#84604C"
      />
      <path
        d="M29.3234 26.045L26.0604 24.4195L36.4535 18.2277L39.7241 19.8416L29.3234 26.045Z"
        fill="white"
      />
      <path
        d="M45.5915 34.1408V29.4386L56.0001 23.2276V27.9393L45.5915 34.1408Z"
        fill="#E6E9F0"
      />
      <path
        d="M45.5915 34.1408V29.4386L56.0001 23.2276V27.9393L45.5915 34.1408Z"
        fill="#84604C"
      />
      <path
        d="M45.5915 34.1408V29.4386L56.0001 23.2276V27.9393L45.5915 34.1408Z"
        fill="#F5070C"
      />
      <path
        d="M45.5915 34.1408V29.4386L56.0001 23.2276V27.9393L45.5915 34.1408Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M45.5915 38.843V34.1408L56.0001 27.9297V32.6415L45.5915 38.843Z"
        fill="#E6E9F0"
      />
      <path
        d="M45.5915 38.843V34.1408L56.0001 27.9297V32.6415L45.5915 38.843Z"
        fill="#84604C"
      />
      <path
        d="M45.5915 38.843V34.1408L56.0001 27.9297V32.6415L45.5915 38.843Z"
        fill="#F5070C"
      />
      <path
        d="M45.5915 38.843V34.1408L56.0001 27.9297V32.6415L45.5915 38.843Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M45.5915 43.5358V38.8336L56.0001 32.6225V37.3342L45.5915 43.5358Z"
        fill="#E6E9F0"
      />
      <path
        d="M45.5915 43.5358V38.8336L56.0001 32.6225V37.3342L45.5915 43.5358Z"
        fill="#84604C"
      />
      <path
        d="M45.5915 43.5358V38.8336L56.0001 32.6225V37.3342L45.5915 43.5358Z"
        fill="#F5070C"
      />
      <path
        d="M45.5915 43.5358V38.8336L56.0001 32.6225V37.3342L45.5915 43.5358Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M45.5915 52.9307V48.2285L56.0001 42.0174V46.7291L45.5915 52.9307Z"
        fill="#E6E9F0"
      />
      <path
        d="M45.5915 52.9307V48.2285L56.0001 42.0174V46.7291L45.5915 52.9307Z"
        fill="#84604C"
      />
      <path
        d="M45.5915 52.9307V48.2285L56.0001 42.0174V46.7291L45.5915 52.9307Z"
        fill="#E1E0F0"
      />
      <path
        d="M45.5915 52.9307V48.2285L56.0001 42.0174V46.7291L45.5915 52.9307Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M45.5915 48.238V43.5357L56.0001 37.3247V42.0364L45.5915 48.238Z"
        fill="#E6E9F0"
      />
      <path
        d="M45.5915 48.238V43.5357L56.0001 37.3247V42.0364L45.5915 48.238Z"
        fill="#84604C"
      />
      <path
        d="M45.5915 48.238V43.5357L56.0001 37.3247V42.0364L45.5915 48.238Z"
        fill="#E1E0F0"
      />
      <path
        d="M45.5915 48.238V43.5357L56.0001 37.3247V42.0364L45.5915 48.238Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M45.5973 29.4457L42.3343 27.8202L52.7274 21.6284L55.998 23.2423L45.5973 29.4457Z"
        fill="#D2B4DB"
      />
      <path
        d="M45.5973 29.4457L42.3343 27.8202L52.7274 21.6284L55.998 23.2423L45.5973 29.4457Z"
        fill="#84604C"
      />
      <path
        d="M45.5973 29.4457L42.3343 27.8202L52.7274 21.6284L55.998 23.2423L45.5973 29.4457Z"
        fill="#F5070C"
      />
      <path
        d="M45.5973 29.4457L42.3343 27.8202L52.7274 21.6284L55.998 23.2423L45.5973 29.4457Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M42.3439 27.82L39.0809 26.1945L49.474 20.0027L52.7446 21.6166L42.3439 27.82Z"
        fill="#D2B4DB"
      />
      <path
        d="M42.3439 27.82L39.0809 26.1945L49.474 20.0027L52.7446 21.6166L42.3439 27.82Z"
        fill="#84604C"
      />
      <path
        d="M42.3439 27.82L39.0809 26.1945L49.474 20.0027L52.7446 21.6166L42.3439 27.82Z"
        fill="#F5070C"
      />
      <path
        d="M42.3439 27.82L39.0809 26.1945L49.474 20.0027L52.7446 21.6166L42.3439 27.82Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M39.0851 26.2084L35.8221 24.5828L46.2152 18.3911L49.4858 20.005L39.0851 26.2084Z"
        fill="#D2B4DB"
      />
      <path
        d="M39.0851 26.2084L35.8221 24.5828L46.2152 18.3911L49.4858 20.005L39.0851 26.2084Z"
        fill="#84604C"
      />
      <path
        d="M39.0851 26.2084L35.8221 24.5828L46.2152 18.3911L49.4858 20.005L39.0851 26.2084Z"
        fill="#F5070C"
      />
      <path
        d="M39.0851 26.2084L35.8221 24.5828L46.2152 18.3911L49.4858 20.005L39.0851 26.2084Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M35.8319 24.5827L32.5689 22.9571L42.962 16.7654L46.2326 18.3793L35.8319 24.5827Z"
        fill="#D2B4DB"
      />
      <path
        d="M35.8319 24.5827L32.5689 22.9571L42.962 16.7654L46.2326 18.3793L35.8319 24.5827Z"
        fill="#84604C"
      />
      <path
        d="M35.8319 24.5827L32.5689 22.9571L42.962 16.7654L46.2326 18.3793L35.8319 24.5827Z"
        fill="white"
      />
      <path
        d="M35.8319 24.5827L32.5689 22.9571L42.962 16.7654L46.2326 18.3793L35.8319 24.5827Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M32.5711 22.9533L29.3081 21.3277L39.7012 15.136L42.9576 16.7676L32.5711 22.9533Z"
        fill="#D2B4DB"
      />
      <path
        d="M32.5711 22.9533L29.3081 21.3277L39.7012 15.136L42.9576 16.7676L32.5711 22.9533Z"
        fill="#84604C"
      />
      <path
        d="M32.5711 22.9533L29.3081 21.3277L39.7012 15.136L42.9576 16.7676L32.5711 22.9533Z"
        fill="white"
      />
      <path
        d="M32.5711 22.9533L29.3081 21.3277L39.7012 15.136L42.9576 16.7676L32.5711 22.9533Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M16.2826 71.3457L13.0261 69.7222V74.4321L16.2826 76.0555V71.3457Z"
        fill="#5CCBF9"
      />
      <path
        d="M13.0261 69.7222L9.76959 68.0988V72.8086L13.0261 74.4321V69.7222Z"
        fill="#5CCBF9"
      />
      <path
        d="M9.76958 68.0987L6.51306 66.4753V71.1852L9.76958 72.8086V68.0987Z"
        fill="#5CCBF9"
      />
      <path
        d="M6.51305 66.4753L3.25653 64.8518V69.5617L6.51305 71.1852V66.4753Z"
        fill="#5CCBF9"
      />
      <path
        d="M29.3087 73.1296L26.0522 71.5062V76.216L29.3087 77.8395V73.1296Z"
        fill="#F5070C"
      />
      <path
        d="M19.5391 68.2592L16.2826 66.6358V71.3456L19.5391 72.9691V68.2592Z"
        fill="#5CCBF9"
      />
      <path
        d="M16.2826 66.6358L13.0261 65.0123V69.7222L16.2826 71.3457V66.6358Z"
        fill="#95F9FB"
      />
      <path
        d="M13.0261 65.0123L9.76959 63.3889V68.0987L13.0261 69.7222V65.0123Z"
        fill="#95F9FB"
      />
      <path
        d="M9.76958 63.3889L6.51306 61.7654V66.4753L9.76958 68.0987V63.3889Z"
        fill="#95F9FB"
      />
      <path
        d="M6.51305 61.7654L3.25653 60.1419V64.8518L6.51305 66.4753V61.7654Z"
        fill="#5CCBF9"
      />
      <path
        d="M32.5652 70.0432L29.3087 68.4197V73.1296L32.5652 74.7531V70.0432Z"
        fill="#F5070C"
      />
      <path
        d="M29.3087 68.4198L26.0522 66.7963V71.5062L29.3087 73.1296V68.4198Z"
        fill="#FF2A00"
      />
      <path
        d="M22.7956 65.1728L19.5391 63.5494V68.2593L22.7956 69.8827V65.1728Z"
        fill="#5CCBF9"
      />
      <path
        d="M19.5391 63.5494L16.2826 61.9259V66.6358L19.5391 68.2592V63.5494Z"
        fill="#95F9FB"
      />
      <path
        d="M16.2826 61.9259L13.0261 60.3025V65.0123L16.2826 66.6358V61.9259Z"
        fill="#DFFFFE"
      />
      <path
        d="M13.0261 60.3024L9.76959 58.679V63.3889L13.0261 65.0123V60.3024Z"
        fill="#DFFFFE"
      />
      <path
        d="M9.76958 58.679L6.51306 57.0555V61.7654L9.76958 63.3889V58.679Z"
        fill="#95F9FB"
      />
      <path
        d="M6.51305 57.0555L3.25653 55.4321V60.1419L6.51305 61.7654V57.0555Z"
        fill="#5CCBF9"
      />
      <path
        d="M32.5652 65.3333L29.3087 63.7099V68.4197L32.5652 70.0432V65.3333Z"
        fill="#FF2A00"
      />
      <path
        d="M29.3087 63.7099L26.0522 62.0864V66.7963L29.3087 68.4198V63.7099Z"
        fill="#FF2A00"
      />
      <path
        d="M26.0522 62.0864L22.7957 60.463V65.1728L26.0522 66.7963V62.0864Z"
        fill="white"
      />
      <path
        d="M22.7956 60.463L19.5391 58.8395V63.5494L22.7956 65.1728V60.463Z"
        fill="#617087"
      />
      <path
        d="M19.5391 58.8395L16.2826 57.216V61.9259L19.5391 63.5494V58.8395Z"
        fill="#DFFFFE"
      />
      <path
        d="M16.2826 57.216L13.0261 55.5926V60.3025L16.2826 61.9259V57.216Z"
        fill="#DFFFFE"
      />
      <path
        d="M13.0261 55.5926L9.76959 53.9691V58.679L13.0261 60.3024V55.5926Z"
        fill="#DFFFFE"
      />
      <path
        d="M9.76958 53.9691L6.51306 52.3457V57.0555L9.76958 58.679V53.9691Z"
        fill="#95F9FB"
      />
      <path
        d="M6.51305 52.3457L3.25653 50.7222V55.4321L6.51305 57.0555V52.3457Z"
        fill="#5CCBF9"
      />
      <path
        d="M35.8218 62.2469L32.5652 60.6235V65.3333L35.8218 66.9568V62.2469Z"
        fill="#E1E0F0"
      />
      <path
        d="M32.5652 60.6235L29.3087 59V63.7099L32.5652 65.3333V60.6235Z"
        fill="#FF2A00"
      />
      <path
        d="M29.3087 59L26.0522 57.3766V62.0864L29.3087 63.7099V59Z"
        fill="white"
      />
      <path
        d="M26.0522 57.3765L22.7957 55.7531V60.463L26.0522 62.0864V57.3765Z"
        fill="white"
      />
      <path
        d="M22.7956 55.7531L19.5391 54.1296V58.8395L22.7956 60.4629V55.7531Z"
        fill="white"
      />
      <path
        d="M19.5391 54.1296L16.2826 52.5062V57.216L19.5391 58.8395V54.1296Z"
        fill="#F5070C"
      />
      <path
        d="M16.2826 52.5061L13.0261 50.8827V55.5926L16.2826 57.216V52.5061Z"
        fill="#DFFFFE"
      />
      <path
        d="M13.0261 50.8827L9.76959 49.2592V53.9691L13.0261 55.5926V50.8827Z"
        fill="#95F9FB"
      />
      <path
        d="M9.76958 49.2592L6.51306 47.6358V52.3456L9.76958 53.9691V49.2592Z"
        fill="#5CCBF9"
      />
      <path
        d="M39.0783 59.1605L35.8218 57.537V62.2469L39.0783 63.8704V59.1605Z"
        fill="#E1E0F0"
      />
      <path
        d="M35.8218 57.537L32.5652 55.9136V60.6234L35.8218 62.2469V57.537Z"
        fill="#E1E0F0"
      />
      <path
        d="M32.5652 55.9136L29.3087 54.2901V59L32.5652 60.6235V55.9136Z"
        fill="white"
      />
      <path
        d="M29.3087 54.2901L26.0522 52.6667V57.3765L29.3087 59V54.2901Z"
        fill="white"
      />
      <path
        d="M26.0522 52.6667L22.7957 51.0432V55.7531L26.0522 57.3765V52.6667Z"
        fill="white"
      />
      <path
        d="M22.7956 51.0432L19.5391 49.4197V54.1296L22.7956 55.7531V51.0432Z"
        fill="#F5070C"
      />
      <path
        d="M19.5391 49.4198L16.2826 47.7963V52.5062L19.5391 54.1296V49.4198Z"
        fill="white"
      />
      <path
        d="M16.2826 47.7963L13.0261 46.1728V50.8827L16.2826 52.5062V47.7963Z"
        fill="#617087"
      />
      <path
        d="M13.0261 46.1728L9.76959 44.5494V49.2593L13.0261 50.8827V46.1728Z"
        fill="#5CCBF9"
      />
      <path
        d="M42.3348 56.0741L39.0782 54.4506V59.1605L42.3348 60.784V56.0741Z"
        fill="#E1E0F0"
      />
      <path
        d="M39.0783 54.4506L35.8218 52.8272V57.5371L39.0783 59.1605V54.4506Z"
        fill="#E1E0F0"
      />
      <path
        d="M35.8218 52.8272L32.5652 51.2037V55.9136L35.8218 57.537V52.8272Z"
        fill="white"
      />
      <path
        d="M32.5652 51.2037L29.3087 49.5803V54.2901L32.5652 55.9136V51.2037Z"
        fill="white"
      />
      <path
        d="M29.3087 49.5802L26.0522 47.9568V52.6667L29.3087 54.2901V49.5802Z"
        fill="white"
      />
      <path
        d="M26.0522 47.9568L22.7957 46.3333V51.0432L26.0522 52.6667V47.9568Z"
        fill="#F5070C"
      />
      <path
        d="M22.7956 46.3333L19.5391 44.7099V49.4197L22.7956 51.0432V46.3333Z"
        fill="white"
      />
      <path
        d="M19.5391 44.7099L16.2826 43.0864V47.7963L19.5391 49.4198V44.7099Z"
        fill="white"
      />
      <path
        d="M16.2826 43.0864L13.0261 41.463V46.1728L16.2826 47.7963V43.0864Z"
        fill="white"
      />
      <path
        d="M42.3348 51.3642L39.0782 49.7408V54.4506L42.3348 56.0741V51.3642Z"
        fill="#E1E0F0"
      />
      <path
        d="M39.0783 49.7408L35.8218 48.1173V52.8272L39.0783 54.4506V49.7408Z"
        fill="white"
      />
      <path
        d="M35.8218 48.1173L32.5652 46.4938V51.2037L35.8218 52.8272V48.1173Z"
        fill="#F5070C"
      />
      <path
        d="M32.5652 46.4939L29.3087 44.8704V49.5803L32.5652 51.2037V46.4939Z"
        fill="#F5070C"
      />
      <path
        d="M29.3087 44.8704L26.0522 43.2469V47.9568L29.3087 49.5802V44.8704Z"
        fill="white"
      />
      <path
        d="M26.0522 43.2469L22.7957 41.6235V46.3333L26.0522 47.9568V43.2469Z"
        fill="white"
      />
      <path
        d="M22.7956 41.6235L19.5391 40V44.7099L22.7956 46.3333V41.6235Z"
        fill="white"
      />
      <path
        d="M19.5391 40L16.2826 38.3765V43.0864L19.5391 44.7099V40Z"
        fill="white"
      />
      <path
        d="M16.2826 38.3765L13.0261 36.7531V41.463L16.2826 43.0864V38.3765Z"
        fill="#F5070C"
      />
      <path
        d="M13.0261 36.7531L9.76959 35.1296V39.8395L13.0261 41.4629V36.7531Z"
        fill="#FF2A00"
      />
      <path
        d="M9.76958 35.1296L6.51306 33.5062V38.216L9.76958 39.8395V35.1296Z"
        fill="#FF2A00"
      />
      <path
        d="M45.5913 48.2778L42.3348 46.6544V51.3642L45.5913 52.9877V48.2778Z"
        fill="#E1E0F0"
      />
      <path
        d="M42.3348 46.6543L39.0782 45.0309V49.7408L42.3348 51.3642V46.6543Z"
        fill="white"
      />
      <path
        d="M39.0783 45.0309L35.8218 43.4074V48.1173L39.0783 49.7407V45.0309Z"
        fill="#F5070C"
      />
      <path
        d="M35.8218 43.4074L32.5652 41.784V46.4938L35.8218 48.1173V43.4074Z"
        fill="#76C5D2"
      />
      <path
        d="M32.5652 41.784L29.3087 40.1605V44.8704L32.5652 46.4938V41.784Z"
        fill="#76C5D2"
      />
      <path
        d="M29.3087 40.1605L26.0522 38.537V43.2469L29.3087 44.8704V40.1605Z"
        fill="#F5070C"
      />
      <path
        d="M26.0522 38.537L22.7957 36.9136V41.6234L26.0522 43.2469V38.537Z"
        fill="white"
      />
      <path
        d="M22.7956 36.9136L19.5391 35.2901V40L22.7956 41.6235V36.9136Z"
        fill="white"
      />
      <path
        d="M19.5391 35.2901L16.2826 33.6667V38.3765L19.5391 40V35.2901Z"
        fill="#F5070C"
      />
      <path
        d="M16.2826 33.6667L13.0261 32.0432V36.7531L16.2826 38.3765V33.6667Z"
        fill="#FF2A00"
      />
      <path
        d="M13.0261 32.0432L9.76959 30.4197V35.1296L13.0261 36.7531V32.0432Z"
        fill="#FF2A00"
      />
      <path
        d="M45.5913 43.5679L42.3348 41.9445V46.6543L45.5913 48.2778V43.5679Z"
        fill="#E1E0F0"
      />
      <path
        d="M42.3348 41.9445L39.0782 40.321V45.0309L42.3348 46.6543V41.9445Z"
        fill="white"
      />
      <path
        d="M39.0783 40.321L35.8218 38.6975V43.4074L39.0783 45.0309V40.321Z"
        fill="#F5070C"
      />
      <path
        d="M35.8218 38.6976L32.5652 37.0741V41.784L35.8218 43.4074V38.6976Z"
        fill="#76C5D2"
      />
      <path
        d="M32.5652 37.0741L29.3087 35.4506V40.1605L32.5652 41.784V37.0741Z"
        fill="#B3E3EB"
      />
      <path
        d="M29.3087 35.4506L26.0522 33.8272V38.5371L29.3087 40.1605V35.4506Z"
        fill="#F5070C"
      />
      <path
        d="M26.0522 33.8272L22.7957 32.2037V36.9136L26.0522 38.537V33.8272Z"
        fill="white"
      />
      <path
        d="M22.7956 32.2037L19.5391 30.5803V35.2901L22.7956 36.9136V32.2037Z"
        fill="white"
      />
      <path
        d="M19.5391 30.5802L16.2826 28.9568V33.6667L19.5391 35.2901V30.5802Z"
        fill="white"
      />
      <path
        d="M45.5913 38.858L42.3348 37.2346V41.9445L45.5913 43.5679V38.858Z"
        fill="#F5070C"
      />
      <path
        d="M42.3348 37.2346L39.0782 35.6111V40.321L42.3348 41.9445V37.2346Z"
        fill="white"
      />
      <path
        d="M39.0783 35.6111L35.8218 33.9877V38.6975L39.0783 40.321V35.6111Z"
        fill="white"
      />
      <path
        d="M35.8218 33.9877L32.5652 32.3642V37.0741L35.8218 38.6976V33.9877Z"
        fill="#F5070C"
      />
      <path
        d="M32.5652 32.3642L29.3087 30.7408V35.4506L32.5652 37.0741V32.3642Z"
        fill="#F5070C"
      />
      <path
        d="M29.3087 30.7408L26.0522 29.1173V33.8272L29.3087 35.4506V30.7408Z"
        fill="white"
      />
      <path
        d="M26.0522 29.1173L22.7957 27.4938V32.2037L26.0522 33.8272V29.1173Z"
        fill="white"
      />
      <path
        d="M22.7956 27.4939L19.5391 25.8704V30.5803L22.7956 32.2037V27.4939Z"
        fill="white"
      />
      <path
        d="M45.5913 34.1482L42.3348 32.5247V37.2346L45.5913 38.8581V34.1482Z"
        fill="#F5070C"
      />
      <path
        d="M42.3348 32.5247L39.0782 30.9013V35.6111L42.3348 37.2346V32.5247Z"
        fill="#FF2A00"
      />
      <path
        d="M39.0783 30.9013L35.8218 29.2778V33.9877L39.0783 35.6111V30.9013Z"
        fill="white"
      />
      <path
        d="M35.8218 29.2778L32.5652 27.6544V32.3642L35.8218 33.9877V29.2778Z"
        fill="white"
      />
      <path
        d="M32.5652 27.6543L29.3087 26.0309V30.7408L32.5652 32.3642V27.6543Z"
        fill="white"
      />
      <path
        d="M29.3087 26.0309L26.0522 24.4074V29.1173L29.3087 30.7407V26.0309Z"
        fill="white"
      />
      <path
        d="M26.0522 24.4074L22.7957 22.784V27.4938L26.0522 29.1173V24.4074Z"
        fill="white"
      />
      <path
        d="M45.5913 29.4383L42.3348 27.8148V32.5247L45.5913 34.1482V29.4383Z"
        fill="#F5070C"
      />
      <path
        d="M42.3348 27.8148L39.0782 26.1914V30.9012L42.3348 32.5247V27.8148Z"
        fill="#FF2A00"
      />
      <path
        d="M39.0783 26.1914L35.8218 24.5679V29.2778L39.0783 30.9013V26.1914Z"
        fill="#FF2A00"
      />
      <path
        d="M35.8218 24.5679L32.5652 22.9445V27.6543L35.8218 29.2778V24.5679Z"
        fill="white"
      />
      <path
        d="M32.5652 22.9445L29.3087 21.321V26.0309L32.5652 27.6543V22.9445Z"
        fill="white"
      />
    </svg>
  );
}
