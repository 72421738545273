import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Modal, { ModalProps } from '@mui/material/Modal';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import BottomSheet, { BottomSheetProps } from '../../../molecules/BottomSheet';
import TitleBar from '../../../molecules/TitleBar';

const styles = {
  root: {
    width: '80%',
    maxWidth: 570,
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.05)
        : alpha(theme.palette.background.default, 0.75),
    boxShadow: 9,
    backdropFilter: 'blur(50px)',
    borderRadius: 4,
  },
  content: {
    p: 2.5,
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: 3, md: '30px' },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  emoji: {
    width: { xs: 70, md: 100 },
    height: { xs: 70, md: 100 },
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
    },
  },
  action: (theme: Theme) => ({
    p: 2.5,
    pt: 0,
    [theme.breakpoints.down('md')]: {
      px: 2.5,
    },
  }),
};
export type PopupDialogProps = BoxProps & {
  onClose: () => void;
  header?: string;
  title?: string;
  body?: string;
  actionComponent?: ReactNode;
  emojiComponent?: ReactNode;
  open: boolean;
  modalProps?: Omit<ModalProps, 'open' | 'onClose' | 'children'>;
  bottomSheetProps?: Omit<BottomSheetProps, 'open' | 'onClose' | 'children'>;
};
export default function PopupDialog({
  sx,
  header,
  title,
  body,
  children,
  emojiComponent,
  actionComponent,
  open,
  onClose,
  bottomSheetProps = {},
  modalProps = {},
  ...rest
}: PopupDialogProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const content =
    emojiComponent || title || body ? (
      <Box sx={styles.content}>
        {!!emojiComponent && <Box sx={styles.emoji}>{emojiComponent}</Box>}
        <Box sx={styles.body}>
          {!!title && <Typography variant="h6">{title}</Typography>}
          {!!body && (
            <Typography variant="body1" component="p">
              {body}
            </Typography>
          )}
        </Box>
      </Box>
    ) : null;

  if (mdUp)
    return (
      <Modal open={open} onClose={onClose} {...modalProps}>
        <Box sx={[styles.root, ...sxProps]} {...rest}>
          <TitleBar backIcon="close" title={header} onBackClick={onClose} />
          {content}
          {children}
          {!!actionComponent && <Box sx={styles.action}>{actionComponent}</Box>}
        </Box>
      </Modal>
    );

  return (
    <BottomSheet open={open} onClose={onClose} {...bottomSheetProps}>
      <Box sx={sxProps} {...rest}>
        {content}
        {children}
        {!!actionComponent && <Box sx={styles.action}>{actionComponent}</Box>}
      </Box>
    </BottomSheet>
  );
}
