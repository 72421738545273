import React from 'react';

export default function TestLikeOutline({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.3636 9.6H14.2009L14.9782 5.944L15.0027 5.688C15.0027 5.36 14.8636 5.056 14.6427 4.84L13.7755 4L8.39182 9.272C8.08909 9.56 7.90909 9.96 7.90909 10.4V18.4C7.90909 19.28 8.64545 20 9.54545 20H16.9091C17.5882 20 18.1691 19.6 18.4145 19.024L20.8855 13.384C20.9591 13.2 21 13.008 21 12.8V11.2C21 10.32 20.2636 9.6 19.3636 9.6ZM19.3636 12.8L16.9091 18.4H9.54545V10.4L13.0964 6.928L12.1882 11.2H19.3636V12.8ZM3 10.4H6.27273V20H3V10.4Z"
        fill="currentColor"
      />
    </svg>
  );
}
