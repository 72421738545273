import api from './client';

const challengeApi = {
  syncChallenge: (params: SyncChallengeReq) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    api.post<Response<any>>('v2/challenge', params),
  challengeByTag: (params: ChallengeByTagReq) =>
    api.post<Response<GetChallengeByTagRes>>('v2/challenge/by-tag', params),
  challengeByQuiz: (params: ChallengeByQuizReq) =>
    api.post<Response<GetChallengeByQuizRes>>('v2/challenge/by-quiz', params),
  acceptChallenge: (params: AcceptChallengeReq) =>
    api.post('v2/challenge/accept', params),
  joinChallenge: (params: JoinChallengeReq) =>
    api.post('/v2/challenge/join', params),
  sentChallengeInvitation: (params: SentChallengeInvitationRes) =>
    api.put('v2/challenge/member', params),
  getRank: (challengeId: string) =>
    api.get<PageResponse<GetChallengeRankingRes>>(
      `v2/challenge/${challengeId}/rank`
    ),

  generateQuizLink: (params: GenerateQuizLinkReq) =>
    api.post<Response<PostGenerateQuizLinkRes>>(
      'v2/challenge/share/link',
      params
    ),
  shareQuizLink: (params: ShareQuizLinkReq) =>
    api.post<Response<PostShareLinkReq>>('v2/challenge/share', params),
};

export default challengeApi;
