import React from 'react';
import Box from '@mui/material/Box';

import { NODE_WIDTH } from '../constants';

const styles = {
  headerNode: {
    typography: 'caption',
    color: 'text.primary',
    width: NODE_WIDTH,
    height: '16px',
    textAlign: 'center',
  },
};

export type HeatMapHeaderProps = {
  nodesPerRow?: number;
};

export default function HeatMapHeader({ nodesPerRow }: HeatMapHeaderProps) {
  const displayInterval = 5;
  return (
    <>
      {[...new Array(nodesPerRow)].map((_, index) => (
        <Box key={index} sx={[styles.headerNode]}>
          {index === 0 || (index + 1) % displayInterval === 0
            ? index + 1
            : null}
        </Box>
      ))}
    </>
  );
}
