import React from 'react';

export default function SocialMediaGoogleColor({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.9999 12.2301C21.9999 11.5504 21.9437 10.867 21.8237 10.1982H12.2012V14.0489H17.7115C17.4828 15.2908 16.7482 16.3895 15.6723 17.0875V19.586H18.9598C20.8904 17.8444 21.9999 15.2725 21.9999 12.2301Z"
        fill="#4285F4"
      />
      <path
        d="M12.202 22.0002C14.9534 22.0002 17.2738 21.1147 18.9643 19.5861L15.6769 17.0876C14.7622 17.6976 13.5814 18.043 12.2057 18.043C9.54422 18.043 7.28759 16.2829 6.4779 13.9167H3.08545V16.4924C4.81729 19.8692 8.34469 22.0002 12.202 22.0002Z"
        fill="#34A853"
      />
      <path
        d="M6.4739 13.9163C6.04656 12.6744 6.04656 11.3296 6.4739 10.0877V7.51196H3.08521C1.63826 10.3375 1.63826 13.6664 3.08521 16.4919L6.4739 13.9163Z"
        fill="#FBBC04"
      />
      <path
        d="M12.202 5.9579C13.6564 5.93586 15.0621 6.4723 16.1154 7.45702L19.0281 4.60208C17.1838 2.90455 14.736 1.97128 12.202 2.00067C8.34467 2.00067 4.81729 4.13177 3.08545 7.51213L6.47415 10.0878C7.28008 7.71789 9.54047 5.9579 12.202 5.9579Z"
        fill="#EA4335"
      />
    </svg>
  );
}
