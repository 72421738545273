import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

type PassageElContextValue = [
  HTMLDivElement | null,
  Dispatch<SetStateAction<HTMLDivElement | null>>
];

type PassageElContextProviderProps = {
  children: ReactNode;
};

export const PassageElContext = createContext<PassageElContextValue>([
  null,
  () => {},
]);

export function PassageElContextProvider({
  children,
}: PassageElContextProviderProps) {
  const providerValue = useState<HTMLDivElement | null>(null);

  return (
    <PassageElContext.Provider value={providerValue}>
      {children}
    </PassageElContext.Provider>
  );
}
