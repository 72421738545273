import type { MutableRefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';

const useDimension = (
  ref?: MutableRefObject<Element | HTMLElement | undefined | null>
) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        const { width, height } = entry.contentRect;
        setDimensions({ width, height });
      });
    });
    if (ref?.current) resizeObserverRef.current?.observe(ref.current);
    return () => {
      if (ref?.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserverRef.current?.unobserve(ref?.current);
      }
      resizeObserverRef.current?.disconnect();
    };
  }, [ref]);
  return dimensions;
};

export default useDimension;
