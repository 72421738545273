/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { getRangeRandom, isNumberic } from '@lib/web/utils';
import { VictoryScatter } from 'victory';

import ChartContainer from './ChartContainer';

// TODO: 等新版的 editor 在制定 chart 格式。
export default function AreaChart({
  data,
  valueAttr = 'y',
  indexAttr = 'x',
  ...rest
}: {
  data: any;
  valueAttr?: string;
  indexAttr?: string;
  width?: number;
  height?: number;
  mode?: string;
}) {
  const allDataset = useMemo(
    () =>
      data?.dataset?.map((d: any) => {
        const dataset =
          d.data.map((v: any, j: number) => {
            const y =
              isNumberic(v[valueAttr]) && v[valueAttr] !== ''
                ? +v[valueAttr]
                : null;
            const x =
              isNumberic(v[indexAttr]) && v[indexAttr] !== ''
                ? +v[indexAttr]
                : j;
            return {
              ...v,
              [valueAttr]: y,
              [indexAttr]: x,
            };
          }) || [];

        return dataset;
      }) || [],
    [data?.dataset, indexAttr, valueAttr]
  );
  const randomDataset = useMemo(
    () =>
      allDataset.map((d: any) => {
        const dataset: { y: any; x: any; size: any }[] = [];
        d.filter(
          (v: any) =>
            v[valueAttr] &&
            v[indexAttr] &&
            isNumberic(v.randomCount) &&
            v.randomCount !== '0' &&
            +v.randomMinDist <= +v.randomMaxDist
        ).forEach((v: any) => {
          [...new Array(+v.randomCount)].forEach(() => {
            const min = +v.randomMinDist * 1000;
            const max = +v.randomMaxDist * 1000;
            const xSep = getRangeRandom(min, max) / 1000;
            const xPlusOrMinus = Math.random() > 0.5 ? 1 : -1;
            const ySep = getRangeRandom(min, max) / 1000;
            const yPlusOrMinus = Math.random() > 0.5 ? 1 : -1;
            dataset.push({
              y: v[valueAttr] + xSep * xPlusOrMinus,
              x: v[indexAttr] + ySep * yPlusOrMinus,
              size: v.size,
            });
          });
        });

        return dataset;
      }) || [],
    [allDataset, indexAttr, valueAttr]
  );
  return (
    <ChartContainer
      data={data}
      indexAttr={indexAttr}
      valueAttr={valueAttr}
      {...rest}
    >
      {allDataset.map((d: any, i: number) => (
        <VictoryScatter
          key={i}
          data={d}
          y={valueAttr}
          x={indexAttr}
          size={data?.size ? +data.size : 5}
        />
      ))}
      {randomDataset.map((d: any, i: number) => (
        <VictoryScatter key={i} data={d} size={data?.size ? +data.size : 5} />
      ))}
    </ChartContainer>
  );
}
