import React from 'react';

export default function CryptoCurrencyDogeAlt({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="12" cy="12" fill="white" fillOpacity="0.1" r="12" />
      <path
        d="M18.6001 9.04854C17.3866 5.91459 14.3865 5.33398 14.3865 5.33398H5.82728L5.8603 8.52618H7.56488V15.6544H5.82715V18.8408H14.1496C16.1553 18.8408 17.7631 16.8252 17.7631 16.8252C20.3379 13.1709 18.6 9.04854 18.6 9.04854H18.6001ZM14.1943 14.6776C14.1943 14.6776 13.5415 15.6194 12.8312 15.6194H11.4104L11.3767 8.53399H13.2001C13.2001 8.53399 14.0449 8.71063 14.6351 10.3824C14.6351 10.3824 15.4175 12.776 14.1943 14.6776Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
}
