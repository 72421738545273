import React from 'react';

export default function CryptoCurrencyCardano({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="12" cy="12" fill="#EDF0F4" r="12" />
      <path
        d="M8.38607 11.9391C8.35194 12.5322 8.80845 13.0399 9.40149 13.074C9.42283 13.074 9.44416 13.074 9.46549 13.074C10.0628 13.074 10.5449 12.5919 10.5406 11.9946C10.5406 11.3973 10.0585 10.9152 9.46123 10.9195C8.88952 10.9195 8.41594 11.3674 8.38607 11.9391Z"
        fill="#0033AD"
      />
      <path
        d="M4.36727 11.6619C4.17528 11.6491 4.00889 11.7984 4.00036 11.9904C3.99182 12.1824 4.13689 12.3488 4.32888 12.3573C4.52087 12.3701 4.68299 12.2208 4.69579 12.0288C4.70859 11.8368 4.55927 11.6747 4.36727 11.6619Z"
        fill="#0033AD"
      />
      <path
        d="M8.31806 5.6843C8.48872 5.59897 8.55698 5.38565 8.47165 5.21499C8.38632 5.04433 8.173 4.97606 8.00234 5.06139C7.83168 5.14672 7.76342 5.35578 7.84875 5.52644C7.93408 5.70137 8.14313 5.7739 8.31806 5.6843C8.31806 5.6843 8.31379 5.6843 8.31806 5.6843Z"
        fill="#0033AD"
      />
      <path
        d="M9.41012 7.59143C9.67464 7.45917 9.78131 7.13492 9.64905 6.87039C9.51679 6.60587 9.19253 6.49921 8.92801 6.63147C8.66349 6.76373 8.55683 7.08798 8.68909 7.35251C8.82135 7.61703 9.1456 7.72369 9.41012 7.59143Z"
        fill="#0033AD"
      />
      <path
        d="M5.7323 8.90143C5.93709 9.03369 6.21015 8.97823 6.34668 8.77344C6.47894 8.56865 6.42347 8.29559 6.21868 8.15907C6.01389 8.02681 5.74084 8.08227 5.60431 8.28706C5.46778 8.49185 5.52751 8.76917 5.7323 8.90143Z"
        fill="#0033AD"
      />
      <path
        d="M6.38524 11.4697C6.08659 11.4526 5.83487 11.6787 5.8178 11.9774C5.80073 12.276 6.02686 12.5277 6.32551 12.5448C6.62416 12.5619 6.87589 12.3358 6.89295 12.0371C6.91002 11.7427 6.68389 11.4867 6.38524 11.4697Z"
        fill="#0033AD"
      />
      <path
        d="M5.79228 15.0836C5.57469 15.1945 5.4851 15.459 5.59603 15.6809C5.70695 15.8985 5.97148 15.9881 6.19333 15.8771C6.41092 15.7662 6.50052 15.5017 6.38959 15.2798C6.27866 15.0622 6.00987 14.9726 5.79228 15.0836Z"
        fill="#0033AD"
      />
      <path
        d="M7.88268 10.3562C8.17706 10.5482 8.56958 10.4671 8.7573 10.1727C8.9493 9.87834 8.86823 9.48582 8.57385 9.2981C8.27946 9.1061 7.88694 9.18717 7.69922 9.48155C7.50723 9.77167 7.58829 10.1642 7.88268 10.3562Z"
        fill="#0033AD"
      />
      <path
        d="M14.517 7.54871C14.7644 7.71084 15.1015 7.64257 15.2636 7.39512C15.4257 7.14766 15.3575 6.81061 15.11 6.64849C14.8626 6.48636 14.5255 6.55462 14.3634 6.80208C14.197 7.0538 14.2652 7.38659 14.517 7.54871Z"
        fill="#0033AD"
      />
      <path
        d="M15.6181 5.65029C15.7803 5.75695 15.9936 5.71002 16.1003 5.54789C16.2069 5.38577 16.16 5.17245 15.9979 5.06578C15.8357 4.95912 15.6224 5.00605 15.5157 5.16391C15.4134 5.32604 15.4603 5.54363 15.6181 5.65029Z"
        fill="#0033AD"
      />
      <path
        d="M14.5986 10.911C14.0056 10.8768 13.4936 11.3334 13.4637 11.9264C13.4296 12.5194 13.8861 13.0314 14.4791 13.0613C14.5005 13.0613 14.5218 13.0613 14.5389 13.0613C15.1319 13.0613 15.614 12.5792 15.614 11.9819C15.6183 11.4144 15.1703 10.9408 14.5986 10.911Z"
        fill="#0033AD"
      />
      <path
        d="M9.76413 10.2794C9.94758 10.6421 10.3188 10.8725 10.7283 10.8725C11.3214 10.8725 11.8035 10.3904 11.8035 9.79305C11.8035 9.62665 11.7651 9.46026 11.6883 9.30667C11.4195 8.77336 10.771 8.56003 10.242 8.82882C9.70866 9.10188 9.49534 9.75038 9.76413 10.2794Z"
        fill="#0033AD"
      />
      <path
        d="M18.2074 8.90135C18.425 8.79042 18.5104 8.52164 18.3994 8.30405C18.2885 8.08646 18.0197 8.00113 17.8021 8.11206C17.5845 8.22298 17.4992 8.48751 17.6059 8.7051C17.7211 8.92269 17.9856 9.01228 18.2074 8.90135Z"
        fill="#0033AD"
      />
      <path
        d="M15.4772 9.2426C15.1658 9.40046 15.0378 9.78018 15.1956 10.0916C15.3535 10.4031 15.7332 10.5311 16.0447 10.3732C16.3561 10.2154 16.4841 9.83564 16.3263 9.52419C16.1684 9.21274 15.7887 9.08901 15.4772 9.2426Z"
        fill="#0033AD"
      />
      <path
        d="M11.9573 5.48816C12.2005 5.50095 12.4096 5.31323 12.4266 5.07004C12.4437 4.82685 12.2517 4.61779 12.0085 4.60073C11.7653 4.58793 11.5563 4.77139 11.5392 5.01458C11.5264 5.26203 11.7141 5.47109 11.9573 5.48816Z"
        fill="#0033AD"
      />
      <path
        d="M11.9531 8.27407C12.3029 8.29541 12.6016 8.02662 12.6186 7.67677C12.64 7.32692 12.3712 7.02826 12.0213 7.0112C11.6715 6.98986 11.3728 7.25865 11.3558 7.6085C11.3387 7.95835 11.6032 8.25701 11.9531 8.27407Z"
        fill="#0033AD"
      />
      <path
        d="M8.52319 14.7423C8.83464 14.5844 8.96264 14.2047 8.80478 13.8932C8.64692 13.5818 8.2672 13.4538 7.95575 13.6117C7.6443 13.7695 7.5163 14.1492 7.67416 14.4607C7.83202 14.7721 8.21174 14.9001 8.52319 14.7423Z"
        fill="#0033AD"
      />
      <path
        d="M12.3627 9.2C12.0384 9.69918 12.175 10.3647 12.6741 10.689C13.1733 11.0133 13.8389 10.8767 14.1631 10.3775C14.4874 9.87837 14.3509 9.2128 13.8517 8.88855C13.6768 8.77335 13.472 8.71362 13.2629 8.71362C12.9003 8.71362 12.5632 8.89708 12.3627 9.2Z"
        fill="#0033AD"
      />
      <path
        d="M14.2357 13.7053C13.9669 13.172 13.3184 12.9587 12.7893 13.2275C12.256 13.4963 12.0427 14.1448 12.3115 14.6738C12.5803 15.2071 13.2288 15.4205 13.7578 15.1517C14.2869 14.8872 14.5045 14.2472 14.2399 13.7139C14.2399 13.7096 14.2399 13.7096 14.2357 13.7053Z"
        fill="#0033AD"
      />
      <path
        d="M16.1173 13.6287C15.8229 13.4367 15.4304 13.5177 15.2427 13.8121C15.0507 14.1065 15.1317 14.499 15.4261 14.6867C15.7205 14.8787 16.113 14.7977 16.3008 14.5033C16.4928 14.2132 16.4117 13.8207 16.1173 13.6287Z"
        fill="#0033AD"
      />
      <path
        d="M18.1825 12.0117C18.1996 11.7131 17.9734 11.4613 17.6748 11.4443C17.3761 11.4272 17.1244 11.6533 17.1074 11.952C17.0903 12.2506 17.3164 12.5024 17.6151 12.5194C17.9094 12.5365 18.1654 12.3061 18.1825 12.0117Z"
        fill="#0033AD"
      />
      <path
        d="M19.671 11.6277C19.479 11.6149 19.3126 11.7642 19.3041 11.9562C19.2955 12.1482 19.4406 12.3146 19.6326 12.3232C19.8246 12.336 19.9867 12.1866 19.9995 11.9946C20.008 11.8026 19.863 11.6405 19.671 11.6277Z"
        fill="#0033AD"
      />
      <path
        d="M18.2671 15.0834C18.0623 14.9511 17.7892 15.0066 17.6527 15.2114C17.5205 15.4162 17.5759 15.6892 17.7807 15.8257C17.9855 15.958 18.2586 15.9025 18.3951 15.6978C18.5316 15.493 18.4719 15.2156 18.2671 15.0834Z"
        fill="#0033AD"
      />
      <path
        d="M8.37797 18.3346C8.21585 18.2279 8.00252 18.2749 7.89586 18.4327C7.7892 18.5948 7.83613 18.8082 7.99399 18.9148C8.15612 19.0215 8.36944 18.9746 8.4761 18.8167C8.58703 18.6588 8.5401 18.4412 8.37797 18.3346Z"
        fill="#0033AD"
      />
      <path
        d="M15.682 18.3002C15.5114 18.3856 15.4431 18.5989 15.5284 18.7695C15.6138 18.9402 15.8271 19.0085 15.9977 18.9231C16.1684 18.8378 16.2367 18.6287 16.1513 18.4581C16.066 18.2832 15.857 18.2106 15.682 18.3002C15.6863 18.3002 15.682 18.3002 15.682 18.3002Z"
        fill="#0033AD"
      />
      <path
        d="M11.6375 14.7849C11.9618 14.2857 11.8253 13.6202 11.3261 13.2916C10.8269 12.9674 10.1613 13.1039 9.83282 13.6031C9.50431 14.1023 9.6451 14.7678 10.1443 15.0964C10.3192 15.2115 10.524 15.2713 10.7331 15.2713C11.1 15.2755 11.437 15.0921 11.6375 14.7849Z"
        fill="#0033AD"
      />
      <path
        d="M9.48252 16.4358C9.23507 16.2737 8.89801 16.342 8.73589 16.5894C8.57376 16.8369 8.64203 17.1739 8.88948 17.3361C9.13694 17.4982 9.47399 17.4299 9.63611 17.1825C9.80251 16.935 9.73424 16.598 9.48252 16.4358Z"
        fill="#0033AD"
      />
      <path
        d="M11.9958 18.4967C11.7526 18.4839 11.5435 18.6716 11.5264 18.9148C11.5094 19.158 11.7014 19.3671 11.9446 19.3841C12.1878 19.3969 12.3968 19.2135 12.4139 18.9703C12.4267 18.7228 12.2389 18.5138 11.9958 18.4967Z"
        fill="#0033AD"
      />
      <path
        d="M11.9998 15.7107C11.6499 15.6893 11.3513 15.9581 11.3342 16.308C11.3129 16.6578 11.5817 16.9565 11.9315 16.9735C12.2814 16.9906 12.58 16.7261 12.5971 16.3762C12.6184 16.0306 12.3496 15.7277 11.9998 15.7107Z"
        fill="#0033AD"
      />
      <path
        d="M14.5901 16.3934C14.3256 16.5299 14.2189 16.8541 14.3554 17.1187C14.492 17.3832 14.8162 17.4898 15.0807 17.3533C15.3452 17.2211 15.4519 16.8968 15.3196 16.6323C15.1831 16.3678 14.8589 16.2568 14.5901 16.3934Z"
        fill="#0033AD"
      />
    </svg>
  );
}
