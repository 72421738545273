import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { ProfileLayoutIntroArea } from '../types';

const styles = {
  root: {},
  title: {
    fontWeight: 700,
    mb: 2,
  },
  text: {
    typography: 'body1',
    lineHeight: '24px',
    color: 'alpha.lightA75',
  },
};

export type AreaIntroProps = ProfileLayoutIntroArea;

export default function AreaIntro({
  title,
  titleTypography,
  text,
  textColor,
}: AreaIntroProps) {
  return (
    <Box sx={styles.root}>
      {title && (
        <Typography sx={[styles.title, { typography: titleTypography }]}>
          {title}
        </Typography>
      )}
      <Typography sx={[styles.text, !!textColor && { color: textColor }]}>
        {text}
      </Typography>
    </Box>
  );
}
