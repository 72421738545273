import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  ExamQuestionMaterialSubType,
  ExamQuestionMaterialType,
} from '@lib/web/apis';
import { ChartRenderBox } from '@lib/web/quiz';
import {
  translateBreakline,
  translateFigure,
  translateFormula,
  translatePassage,
} from '@lib/web/utils';

import ThemeProvider from '../../../../components/ThemeProvider';

const BREAK_LINES_CLASS = 'break-line';

const styles = {
  passageContent: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
    '& > p': {
      mt: 0,
      mb: 3,
      textIndent: '40px',
      '&:last-child': {
        mb: 0,
      },
    },
    '& .break-line': {
      opacity: 0,
      visibility: 'hidden',
      width: 0,
      height: 0,
      display: 'inline-block',
      overflow: 'hidden',
    },
  },
  passageInnerContent: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
  },

  chartContent: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
    '& > p': {
      mt: 0,
      mb: 3,
      textIndent: '40px',
      '&:last-child': {
        mb: 0,
      },
    },
    '& .break-line': {
      opacity: 0,
      visibility: 'hidden',
      width: 0,
      height: 0,
      display: 'inline-block',
      overflow: 'hidden',
    },
  },
  chartInnerContent: {
    '& .chart-render-inner': {
      p: 0,
    },
  },
};

export type LegacyMaterialRendererProps = {
  content: string;
  type: ExamQuestionMaterialType;
  subType: ExamQuestionMaterialSubType;
};

export default function LegacyMaterialRender(
  props: LegacyMaterialRendererProps
) {
  const { content, type } = props;
  return (
    <ThemeProvider mode="dark">
      {type === ExamQuestionMaterialType.Passage && (
        <Box sx={styles.passageContent} pl={3}>
          <Typography
            sx={styles.passageInnerContent}
            dangerouslySetInnerHTML={{
              __html: translateBreakline(
                translatePassage(content),
                BREAK_LINES_CLASS
              ),
            }}
          />
        </Box>
      )}

      {type !== ExamQuestionMaterialType.Passage && (
        <Box sx={styles.chartContent}>
          <ChartRenderBox
            sx={styles.chartInnerContent}
            mappedMaterials={{ material: props as GetQuizMaterialRes }}
            tex={translateFormula(
              translateFigure('{%material%}', {
                material: props as GetQuizMaterialRes,
              })
            )}
          />
        </Box>
      )}
    </ThemeProvider>
  );
}
