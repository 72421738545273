import React from 'react';
import { v4 } from 'uuid';

export default function Award2310Locked({ ...rest }) {
  const id = v4();
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#clip0_${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.8247 12.5159L42 6.02564L34.1753 12.5159C32.7827 13.671 31.0445 14.3124 29.2454 14.335L19.1364 14.4624L17.2572 24.5334C16.9228 26.3258 15.9979 27.9499 14.6341 29.1397L6.9709 35.8251L11.9166 44.7646C12.7968 46.3556 13.118 48.2024 12.8276 50.0027L11.1959 60.1179L20.6523 63.7429C22.3353 64.3881 23.7523 65.5935 24.6712 67.1618L29.8345 75.9739L39.3768 72.5883C41.0751 71.9857 42.9249 71.9857 44.6232 72.5883L54.1655 75.9739L59.3288 67.1618C60.2477 65.5935 61.6647 64.3881 63.3477 63.7429L72.8041 60.1179L71.1724 50.0027C70.882 48.2024 71.2032 46.3556 72.0834 44.7646L77.0291 35.8251L69.3659 29.1397C68.0021 27.9499 67.0772 26.3258 66.7428 24.5334L64.8636 14.4624L54.7546 14.335C52.9555 14.3124 51.2173 13.671 49.8247 12.5159ZM44.5143 2.9099C43.0517 1.6967 40.9483 1.6967 39.4857 2.9099L31.661 9.40013C30.9647 9.97768 30.0956 10.2984 29.196 10.3097L19.087 10.4371C17.1974 10.4609 15.5861 11.8316 15.2349 13.7142L13.3557 23.7852C13.1885 24.6814 12.7261 25.4934 12.0441 26.0883L4.38097 32.7737C2.94852 34.0234 2.58327 36.1234 3.50775 37.7945L8.45343 46.7339C8.89353 47.5294 9.05414 48.4528 8.90894 49.353L7.27727 59.4682C6.97227 61.359 8.02395 63.2058 9.79159 63.8834L19.248 67.5084C20.0894 67.8309 20.798 68.4337 21.2574 69.2178L26.4207 78.0299C27.3859 79.6771 29.3624 80.4064 31.1461 79.7736L40.6884 76.388C41.5376 76.0867 42.4624 76.0867 43.3116 76.388L52.8539 79.7736C54.6376 80.4064 56.6141 79.6771 57.5793 78.0299L62.7426 69.2178C63.202 68.4337 63.9105 67.8309 64.752 67.5084L74.2084 63.8834C75.9761 63.2058 77.0277 61.359 76.7227 59.4682L75.0911 49.353C74.9459 48.4528 75.1065 47.5294 75.5466 46.7339L80.4922 37.7945C81.4167 36.1234 81.0515 34.0234 79.619 32.7737L71.9559 26.0883C71.2739 25.4934 70.8115 24.6814 70.6443 23.7852L68.7651 13.7142C68.4139 11.8316 66.8026 10.4609 64.913 10.4371L54.804 10.3097C53.9044 10.2984 53.0353 9.97768 52.339 9.40013L44.5143 2.9099Z"
          fill="#565656"
        />
        <path
          d="M62 18C62.9338 20.8393 65.1607 23.0662 68 24C65.1607 24.9338 62.9338 27.1607 62 30C61.0662 27.1607 58.8393 24.9338 56 24C58.8393 23.0662 61.0662 20.8393 62 18Z"
          fill="#565656"
        />
        <path
          d="M20.5 50C21.2003 52.1295 22.8705 53.7997 25 54.5C22.8705 55.2003 21.2003 56.8705 20.5 59C19.7997 56.8705 18.1295 55.2003 16 54.5C18.1295 53.7997 19.7997 52.1295 20.5 50Z"
          fill="#565656"
        />
        <rect
          x="46.502"
          y="20.2393"
          width="13.7596"
          height="41.2789"
          rx="6.87981"
          transform="rotate(30 46.502 20.2393)"
          fill="#565656"
        />
        <ellipse
          cx="35.2604"
          cy="53.4698"
          rx="6.87981"
          ry="6.87982"
          transform="rotate(30 35.2604 53.4698)"
          fill="#B2B2B2"
        />
        <path
          d="M48.1706 9.24101C48.2547 9.47959 48.3014 9.73308 48.3014 9.99851C48.3014 11.3197 47.1837 12.3933 45.8014 12.3933C44.4191 12.3933 43.3014 11.3226 43.3014 9.99851C43.3014 8.88015 44.1046 7.94371 45.1849 7.68127L42 2L38.8151 7.68127C39.8985 7.94371 40.6986 8.88015 40.6986 9.99851C40.6986 11.3197 39.5809 12.3933 38.1986 12.3933C36.8163 12.3933 35.6986 11.3226 35.6986 9.99851C35.6986 9.73308 35.7453 9.47959 35.8294 9.24101L32 7.94669L34.033 18H49.967L52 7.94669L48.1706 9.24101Z"
          fill="#565656"
        />
      </g>
      <defs>
        <clipPath id={`clip0_${id}`}>
          <rect width="84" height="84" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
