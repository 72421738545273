import React from 'react';

export default function ProfileVisa({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.5388 9.24577C16.074 9.07997 15.5827 8.99678 15.088 9.0001C13.4883 9.0001 12.3584 9.78899 12.3504 10.9214C12.3404 11.7534 13.1533 12.2222 13.7682 12.5002C14.3991 12.786 14.6101 12.9661 14.6081 13.2216C14.6041 13.6101 14.1042 13.7863 13.6393 13.7863C13.0003 13.7863 12.6514 13.6992 12.1145 13.4809L11.9155 13.3899L11.6885 14.6936C12.0775 14.8522 12.7784 14.9883 13.5023 15C15.203 15 16.3149 14.216 16.3279 13.0111C16.3418 12.3465 15.9019 11.8444 14.9761 11.4294C14.4131 11.1603 14.0642 10.9802 14.0642 10.7071C14.0642 10.4653 14.3631 10.2069 14.9881 10.2069C15.4039 10.1971 15.8173 10.2735 16.2009 10.4311L16.3508 10.4966L16.5778 9.23696L16.5388 9.24577ZM20.6902 9.1058H19.4404C19.0514 9.1058 18.7585 9.21053 18.5885 9.58834L16.1849 14.9188H17.8856L18.2256 14.0447L20.3013 14.0467C20.3502 14.2512 20.5002 14.9188 20.5002 14.9188H22L20.6902 9.1058ZM10.0498 9.05686H11.6705L10.6567 14.8728H9.03794L10.0498 9.05491V9.05686ZM5.93541 12.2624L6.10338 13.0699L7.68715 9.1058H9.40389L6.85327 14.9109H5.14053L3.74074 9.99551C3.71807 9.91446 3.66482 9.84485 3.59176 9.80074C3.08724 9.5456 2.55266 9.35199 2 9.22423L2.022 9.10189H4.63061C4.98455 9.11559 5.26951 9.22423 5.3645 9.59421L5.93441 12.2653L5.93541 12.2624ZM18.6905 12.8555L19.3364 11.2288C19.3284 11.2464 19.4694 10.8931 19.5514 10.6748L19.6624 11.1769L20.0373 12.8545H18.6895L18.6905 12.8555Z"
        fill="currentColor"
      />
    </svg>
  );
}
