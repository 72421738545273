import React from 'react';

export default function OtherFlash({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.7956 22H9.59553L10.7956 14.2222H6.59546C5.89945 14.2222 5.91145 13.8667 6.13945 13.4889C6.36746 13.1111 6.19945 13.4 6.22345 13.3556C7.77149 10.8222 10.0995 7.04444 13.1956 2H14.3956L13.1956 9.77778H17.3957C17.9837 9.77778 18.0677 10.1444 17.9597 10.3444L17.8757 10.5111C13.1476 18.1667 10.7956 22 10.7956 22Z"
        fill="currentColor"
      />
    </svg>
  );
}
