import { QuestionDetailComponentType } from '@lib/web/apis';

import api from './client';

const editorApi = {
  getCreatorQuestion: (id: string) => {
    const params = new URLSearchParams();
    params.set('id', id);
    return api.get<Response<GetCreatorQuestionRes>>(
      `v2/creator-question?${params.toString()}`
    );
  },
  getCreatorQuestionForReview: (id: string) => {
    const params = new URLSearchParams();
    params.set('id', id);
    return api.get<Response<GetCreatorQuestionRes>>(
      `v2/creator-question/review/info?${params.toString()}`
    );
  },
  getCreatorQuestionDetail: (
    id: string,
    componentType?: QuestionDetailComponentType
  ) => {
    const params = new URLSearchParams();
    params.set('id', id);
    if (componentType) params.set('componentType', `${componentType}`);

    return api.get<Response<GetCreatorQuestionDetailRes>>(
      `v2/creator-question/detail?${params.toString()}`
    );
  },
  getCreatorQuestionDetailForReview: (
    id: string,
    componentType?: QuestionDetailComponentType
  ) => {
    const params = new URLSearchParams();
    params.set('id', id);
    if (componentType) params.set('componentType', `${componentType}`);

    return api.get<Response<GetCreatorQuestionDetailRes>>(
      `v2/creator-question/review/detail?${params.toString()}`
    );
  },
  getCreatorQuestionGroupItem: (questionGroupId: string) => {
    const pageSize = 100; // TODO: discuss whether we should support sub question exceed 100
    const params = new URLSearchParams();
    params.set('questionGroupId', questionGroupId);
    params.set('limit', `${pageSize}`);
    return api.get<PageResponse<GetCreatorQuestionGroupItemRes>>(
      `v2/creator-question/group-item?${params.toString()}`
    );
  },
  getCreatorQuestionGroupItemForReview: (questionGroupId: string) => {
    const pageSize = 100; // TODO: discuss whether we should support sub question exceed 100
    const params = new URLSearchParams();
    params.set('questionGroupId', questionGroupId);
    params.set('limit', `${pageSize}`);
    return api.get<PageResponse<GetCreatorQuestionGroupItemRes>>(
      `v2/creator-question/review/group-item?${params.toString()}`
    );
  },
  getCreatorQuestionCorrectAnswer: (id: string) => {
    const params = new URLSearchParams();
    params.set('id', id);

    return api.get<Response<GetCreatorQuestionCorrectAnswerRes>>(
      `v2/creator-question/correct-answer?${params.toString()}`
    );
  },
  getCreatorQuestionCorrectAnswerForReview: (id: string) => {
    const params = new URLSearchParams();
    params.set('id', id);

    return api.get<Response<GetCreatorQuestionCorrectAnswerRes>>(
      `v2/creator-question/review/correct-answer?${params.toString()}`
    );
  },
  getCreatorQuestionPreference: (sectionId: string | null) => {
    const params = new URLSearchParams();
    if (sectionId) params.set('sectionId', sectionId);

    return api.get<Response<GetCreatorQuestionPreferenceRes>>(
      `v2/creator-question/preference?${params.toString()}`
    );
  },
  newCreatorQuestion: (params: NewCreatorQuestionReq) =>
    api.post<Response<PostNewCreatorQuestionRes>>(
      '/v2/creator-question',
      params
    ),
  updateCreatorQuestion: (params: UpdateCreatorQuestionReq) =>
    api.put<Response<UpdateCreatorQuestionRes>>('/v2/creator-question', params),
  updateCreatorQuestionForReview: (params: UpdateCreatorQuestionReq) =>
    api.put<Response<UpdateCreatorQuestionRes>>(
      '/v2/creator-question/review/info',
      params
    ),
  // API 13x / 2.4 Update Creator Question Detail
  updateCreatorQuestionDetail: (params: UpdateCreatorQuestionDetailReq) =>
    api.post<Response<PostUpdateCreatorQuestionDetailRes>>(
      '/v2/creator-question/detail',
      params
    ),
  updateCreatorQuestionDetailForReview: (
    params: UpdateCreatorQuestionDetailReq
  ) =>
    api.post<Response<PostUpdateCreatorQuestionDetailRes>>(
      '/v2/creator-question/review/detail',
      params
    ),
  setCreatorQuestionCorrectAnswer: (
    params: SetCreatorQuestionCorrectAnswerReq
  ) =>
    api.post<Response<PostSetCreatorQuestionCorrectAnswerRes>>(
      '/v2/creator-question/correct-answer',
      params
    ),
  setCreatorQuestionCorrectAnswerForReview: (
    params: SetCreatorQuestionCorrectAnswerReq
  ) =>
    api.post<Response<PostSetCreatorQuestionCorrectAnswerRes>>(
      '/v2/creator-question/review/correct-answer',
      params
    ),
  deleteCreatorQuestion: (params: DeleteCreatorQuestionReq) =>
    api.delete<Response<PostDeleteCreatorQuestionRes>>(`v2/creator-question`, {
      data: params,
    }),
  deleteCreatorQuestionForReview: (params: DeleteCreatorQuestionForReviewReq) =>
    api.delete<Response<PostDeleteCreatorQuestionForReviewRes>>(
      '/v2/creator-question/review/delete',
      {
        data: params,
      }
    ),
  reorderCreatorQuestion: (params: ReorderCreatorQuestionReq) =>
    api.post<Response<PostReorderCreatorQuestionRes>>(
      '/v2/creator-question/order',
      params
    ),
  duplicateCreatorQuestion: (params: DuplicateCreatorQuestionReq) =>
    api.post<Response<PostDuplicateCreatorQuestionRes>>(
      '/v2/creator-question/duplicate',
      params
    ),
  deleteAllCreatorQuestion: (params: DeleteAllCreatorQuestionReq) =>
    api.delete<Response<PostDeleteAllCreatorQuestionRes>>(
      '/v2/creator-question/all',
      {
        data: params,
      }
    ),
  publishCreatorQuestion: <T extends PublishCreatorQuestionReq>(params: T) =>
    api.post<Response<CreateAsyncTaskRes<T, PostPublishCreatorQuestionRes>>>(
      '/v2/creator-question/publish',
      params
    ),
  publishCreatorQuestionForReview: <T extends PublishCreatorQuestionReq>(
    params: T
  ) =>
    api.post<Response<CreateAsyncTaskRes<T, PostPublishCreatorQuestionRes>>>(
      '/v2/creator-question/review/publish',
      params
    ),
  approveCreatorQuestion: <T extends ApproveCreatorQuestionReq>(params: T) =>
    api.post<Response<CreateAsyncTaskRes<T, PostApproveCreatorQuestionRes>>>(
      '/v2/creator-question/review/approve',
      params
    ),
  rejectCreatorQuestion: (params: RejectCreatorQuestionReq) =>
    api.post<Response<PostRejectCreatorQuestionRes>>(
      '/v2/creator-question/review/reject',
      params
    ),
  addCreatorQuestionHashtags: (params: AddCreatorQuestionHashtagsReq) =>
    api.post('/v2/creator-question/hashtags', params),
  addCreatorQuestionHashtagsForReview: (
    params: AddCreatorQuestionHashtagsReq
  ) => api.post('/v2/creator-question/review/hashtags', params),
  removeCreatorQuestionHashtags: (params: RemoveCreatorQuestionHashtagsReq) =>
    api.delete('/v2/creator-question/hashtags', { data: params }),
  removeCreatorQuestionHashtagsForReview: (
    params: RemoveCreatorQuestionHashtagsReq
  ) => api.delete('/v2/creator-question/review/hashtags', { data: params }),
  copyCreatorQuestionToClubs: (params: CopyCreatorQuestionToClubsReq) =>
    api.post('/v2/creator-question/copy-to', params),
  addCreatorQuestionVariable: (params: AddCreatorQuestionVariableReq) =>
    api.post<Response<GetCreatorQuestionVariableRes>>(
      '/v2/creator-question/variable',
      params
    ),
  addCreatorQuestionVariableForReview: (
    params: AddCreatorQuestionVariableReq
  ) =>
    api.post<Response<GetCreatorQuestionVariableRes>>(
      '/v2/creator-question/review/variable',
      params
    ),
  duplicateCreatorQuestionVariable: (
    params: DuplicateCreatorQuestionVariableReq
  ) =>
    api.post<Response<GetCreatorQuestionVariableRes>>(
      '/v2/creator-question/variable/duplicate',
      params
    ),
  duplicateCreatorQuestionVariableForReview: (
    params: DuplicateCreatorQuestionVariableReq
  ) =>
    api.post<Response<GetCreatorQuestionVariableRes>>(
      '/v2/creator-question/review/variable/duplicate',
      params
    ),
  updateCreatorQuestionVariable: (params: UpdateCreatorQuestionVariableReq) =>
    api.put<Response<GetCreatorQuestionVariableRes>>(
      '/v2/creator-question/variable',
      params
    ),
  updateCreatorQuestionVariableForReview: (
    params: UpdateCreatorQuestionVariableReq
  ) =>
    api.put<Response<GetCreatorQuestionVariableRes>>(
      '/v2/creator-question/review/variable',
      params
    ),
  deleteCreatorQuestionVariable: (params: DeleteCreatorQuestionVariableReq) =>
    api.delete<Response<DeleteCreatorQuestionVariableRes>>(
      '/v2/creator-question/variable',
      { data: params }
    ),
  deleteCreatorQuestionVariableForReview: (
    params: DeleteCreatorQuestionVariableReq
  ) =>
    api.delete<Response<DeleteCreatorQuestionVariableRes>>(
      '/v2/creator-question/review/variable',
      { data: params }
    ),
  getLinkedCreatorQuestionVariable: (questionId: string) =>
    api.get<Response<GetCreatorQuestionLinkedVariableRes[]>>(
      `/v2/creator-question/variable/link?creatorQuestionId=${questionId}`
    ),
  getLinkedCreatorQuestionVariableForReview: (questionId: string) =>
    api.get<Response<GetCreatorQuestionLinkedVariableRes[]>>(
      `/v2/creator-question/review/variable/link?creatorQuestionId=${questionId}`
    ),
  linkCreatorQuestionVariable: (params: LinkCreatorQuestionVariableReq) =>
    api.post<Response<DeleteCreatorQuestionVariableRes>>(
      '/v2/creator-question/variable/link',
      params
    ),
  linkCreatorQuestionVariableForReview: (
    params: LinkCreatorQuestionVariableReq
  ) =>
    api.post<Response<DeleteCreatorQuestionVariableRes>>(
      '/v2/creator-question/review/variable/link',
      params
    ),
  unlinkCreatorQuestionVariable: (params: UnlinkCreatorQuestionVariableReq) =>
    api.delete<Response<UnlinkCreatorQuestionVariableRes>>(
      '/v2/creator-question/variable/link',
      { data: params }
    ),
  unlinkCreatorQuestionVariableForReview: (
    params: UnlinkCreatorQuestionVariableReq
  ) =>
    api.delete<Response<UnlinkCreatorQuestionVariableRes>>(
      '/v2/creator-question/review/variable/link',
      { data: params }
    ),
  addCreatorQuestionComponent: (params: AddCreatorQuestionComponentReq) =>
    api.post<Response<AddCreatorQuestionComponentRes>>(
      '/v2/creator-question/component',
      params
    ),
  addCreatorQuestionComponentForReview: (
    params: AddCreatorQuestionComponentReq
  ) =>
    api.post<Response<AddCreatorQuestionComponentRes>>(
      '/v2/creator-question/review/component',
      params
    ),
  removeCreatorQuestionComponent: (params: RemoveCreatorQuestionComponentReq) =>
    api.delete<Response<RemoveCreatorQuestionComponentRes>>(
      '/v2/creator-question/component',
      { data: params }
    ),
  removeCreatorQuestionComponentForReview: (
    params: RemoveCreatorQuestionComponentReq
  ) =>
    api.delete<Response<RemoveCreatorQuestionComponentRes>>(
      '/v2/creator-question/review/component',
      { data: params }
    ),
  addCreatorQuestionCriteria: (params: AddCreatorQuestionCriteriaReq) =>
    api.post<Response<AddCreatorQuestionCriteriaRes>>(
      '/v2/creator-question/criteria',
      params
    ),
  updateCreatorQuestionCriteria: (params: UpdateCreatorQuestionCriteriaReq) =>
    api.put<Response<UpdateCreatorQuestionCriteriaRes>>(
      '/v2/creator-question/criteria',
      params
    ),
  deleteCreatorQuestionCriteria: (ids: string[]) =>
    api.delete<Response<{ count: number }>>('/v2/creator-question/criteria', {
      data: { ids },
    }),
  duplicateCreatorQuestionCriteria: (
    params: DuplicateCreatorQuestionCriteriaReq
  ) =>
    api.post<Response<DuplicateCreatorQuestionCriteriaRes>>(
      '/v2/creator-question/criteria/duplicate',
      params
    ),
  updateCreatorQuestionCriteriaOrder: (
    params: UpdateCreatorQuestionCriteriaOrderReq
  ) =>
    api.post<Response<UpdateCreatorQuestionCriteriaOrderRes>>(
      '/v2/creator-question/criteria/order',
      params
    ),
  addCreatorQuestionDescriptor: (params: AddCreatorQuestionDescriptorReq) =>
    api.post<Response<AddCreatorQuestionDescriptorRes>>(
      '/v2/creator-question/descriptor',
      params
    ),
  updateCreatorQuestionDescriptor: (
    params: UpdateCreatorQuestionDescriptorReq
  ) =>
    api.put<Response<UpdateCreatorQuestionDescriptorRes>>(
      '/v2/creator-question/descriptor',
      params
    ),
  deleteCreatorQuestionDescriptor: (ids: string[]) =>
    api.delete<Response<{ count: number }>>('/v2/creator-question/descriptor', {
      data: { ids },
    }),
  duplicateCreatorQuestionDescriptor: (
    params: DuplicateCreatorQuestionDescriptorReq
  ) =>
    api.post<Response<DuplicateCreatorQuestionDescriptorRes>>(
      '/v2/creator-question/descriptor/duplicate',
      params
    ),
  updateCreatorQuestionDescriptorOrder: (
    params: UpdateCreatorQuestionDescriptorOrderReq
  ) =>
    api.post<Response<UpdateCreatorQuestionDescriptorOrderRes>>(
      '/v2/creator-question/descriptor/order',
      params
    ),
  updateCreatorQuestionRubric: (params: UpdateCreatorQuestionRubricReq) =>
    api.put<Response<UpdateCreatorQuestionRubricRes>>(
      '/v2/creator-question/rubric',
      params
    ),
};

export default editorApi;
