import { ReactNode } from 'react';
import { Box } from '@mui/material';

const styles = {
  root: {
    cursor: 'default',
  },
};

export type IaDisplayTableBodyProps = {
  children: ReactNode;
};

export default function IaDisplayTableBody({
  children,
}: IaDisplayTableBodyProps) {
  return (
    <Box
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      className="display-table_body"
      sx={styles.root}
    >
      {children}
    </Box>
  );
}
