import React from 'react';

export default function ActionChevronFilledRight({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M10 17L15 12L10 7V17Z" fill="currentColor" />
    </svg>
  );
}
