import React from 'react';

export default function TestReport({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21 11.9996C21 16.9699 16.9933 20.9992 12.0507 20.9992C10.0889 20.9992 8.27449 20.3644 6.79957 19.2878L3 20.4529L4.23363 16.3843C3.5125 15.0869 3.10148 13.5915 3.10148 11.9996C3.10148 7.02926 7.1082 3 12.0507 3C16.9933 3 21 7.02926 21 11.9996ZM19.2001 11.9996C19.2001 15.9854 15.9897 19.1993 12.0507 19.1993C8.11178 19.1993 4.9014 15.9854 4.9014 11.9996C4.9014 8.01379 8.11178 4.79992 12.0507 4.79992C15.9897 4.79992 19.2001 8.01379 19.2001 11.9996Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M12.0495 6.99929C11.5004 6.99929 11.0552 7.44444 11.0552 7.99356V13.004C11.0552 13.5531 11.5004 13.9982 12.0495 13.9982C12.5986 13.9982 13.0438 13.5531 13.0438 13.004V7.99356C13.0438 7.44444 12.5986 6.99929 12.0495 6.99929Z"
        fill="currentColor"
      />
      <path
        d="M12.0495 17.0005C12.5986 17.0005 13.0438 16.5528 13.0438 16.0005C13.0438 15.4482 12.5986 15.0005 12.0495 15.0005C11.5004 15.0005 11.0552 15.4482 11.0552 16.0005C11.0552 16.5528 11.5004 17.0005 12.0495 17.0005Z"
        fill="currentColor"
      />
    </svg>
  );
}
