import React from 'react';

export default function AnalyticsAcceptance({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.75 3C18.1642 3 18.5 3.33579 18.5 3.75V5.5H20.25C20.6642 5.5 21 5.83579 21 6.25C21 6.66421 20.6642 7 20.25 7L18.5 7L18.5 20.25C18.5 20.6642 18.1642 21 17.75 21C17.3358 21 17 20.6642 17 20.25L17 7L3.75 7C3.33579 7 3 6.66421 3 6.25C3 5.83578 3.33579 5.5 3.75 5.5L17 5.5V3.75C17 3.33579 17.3358 3 17.75 3ZM15.0046 11.25C15.0072 11.6642 14.6735 12.0021 14.2593 12.0046L4.26012 12.0666C3.84592 12.0692 3.50805 11.7355 3.50549 11.3213C3.50292 10.9071 3.83662 10.5692 4.25082 10.5666L14.25 10.5046C14.6642 10.5021 15.0021 10.8358 15.0046 11.25ZM3.5 15.25C3.5 14.8358 3.83579 14.5 4.25 14.5H12C12.4142 14.5 12.75 14.8358 12.75 15.25C12.75 15.6642 12.4142 16 12 16H4.25C3.83579 16 3.5 15.6642 3.5 15.25ZM3.5 19.25C3.5 18.8358 3.83579 18.5 4.25 18.5H9C9.41421 18.5 9.75 18.8358 9.75 19.25C9.75 19.6642 9.41421 20 9 20H4.25C3.83579 20 3.5 19.6642 3.5 19.25Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
