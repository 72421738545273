import React from 'react';

export default function LoginHideAlternative({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.57035 10.253C3.9798 9.89043 4.61218 9.92122 4.98281 10.3218C8.90619 14.5618 15.0785 14.6303 19.6843 10.2749C20.0814 9.89941 20.7145 9.9099 21.0983 10.2983C21.4822 10.6868 21.4715 11.3061 21.0744 11.6816C20.5 12.2247 19.9005 12.7101 19.282 13.1374L21.6118 14.8999C22.0487 15.2304 22.129 15.8448 21.7911 16.2722C21.4532 16.6996 20.8252 16.7781 20.3883 16.4476L17.4371 14.215C16.9525 14.4495 16.4607 14.6516 15.9641 14.821L16.9799 17.7026C17.1599 18.2134 16.8826 18.7702 16.3605 18.9463C15.8384 19.1224 15.2692 18.8511 15.0892 18.3404L14.0222 15.3137C12.8706 15.5074 11.7115 15.5302 10.575 15.3805L9.53163 18.3404C9.35159 18.8511 8.78238 19.1224 8.26026 18.9463C7.73815 18.7702 7.46084 18.2134 7.64088 17.7026L8.61406 14.9418C7.79714 14.6807 7.00243 14.3238 6.24233 13.8706L3.6118 15.8606C3.17491 16.1911 2.54685 16.1126 2.20899 15.6852C1.87114 15.2579 1.95141 14.6435 2.3883 14.313L4.56769 12.6643C4.19899 12.348 3.8425 12.0048 3.50004 11.6347C3.12942 11.2342 3.16089 10.6156 3.57035 10.253Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
