import React from 'react';

interface ArrowProps {
  className?: string;
  width?: number;
  height?: number;
}

const Arrow = ({ ...rest }: ArrowProps) => (
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M4.57711 0.63635C4.95461 -0.0651414 5.96079 -0.0651405 6.33829 0.636351L10.2336 7.87476C10.712 8.76377 9.72152 9.72101 8.84935 9.21255L5.96134 7.52891C5.6501 7.34746 5.26531 7.34746 4.95406 7.52891L2.06606 9.21255C1.19388 9.72101 0.203409 8.76377 0.681821 7.87476L4.57711 0.63635Z"
      fill="currentColor"
    />
  </svg>
);

export default Arrow;
