import React from 'react';

export default function TestReading({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.42159 17.3694H19.3755C19.4151 17.3694 19.4536 17.364 19.4905 17.3557C19.4934 17.3557 19.4959 17.3561 19.4988 17.3561C19.7753 17.3561 20 17.1437 20 16.8818V3.47438C20 3.21252 19.7753 3 19.4988 3H19.3765H7.22315H6.4223C5.0883 3 4.00321 4.02728 4.00321 5.28958C4.00321 5.30651 4.00673 5.32258 4.00847 5.33892C4.00673 6.50497 3.99145 17.2195 4.00673 18.628C4.00578 18.6556 4.00221 18.6825 4.00221 18.7103C4.00221 19.9241 5.00632 20.9177 6.26931 20.9928C6.29465 20.9965 6.31949 21 6.34578 21H19.4988C19.7753 21 20 20.7876 20 20.5256V18.74C20 18.4782 19.7753 18.2656 19.4988 18.2656C19.2223 18.2656 18.9976 18.4783 18.9976 18.74V20.0513H6.42159C5.65999 20.0513 5.03828 19.4789 5.00773 18.7652C5.00847 18.7525 5.01091 18.7405 5.01066 18.7275C5.01017 18.7067 5.00942 18.6742 5.00893 18.6311C5.05273 17.9287 5.66868 17.3694 6.42159 17.3694ZM6.76333 5.28958C6.76333 5.02772 6.98786 4.81521 7.26451 4.81521C7.54117 4.81521 7.7657 5.02772 7.7657 5.28958V14.9254C7.7657 15.1872 7.54117 15.3998 7.26451 15.3998C6.98786 15.3998 6.76333 15.1871 6.76333 14.9254V5.28958Z"
        fill="currentColor"
      />
    </svg>
  );
}
