export default function MathArithmeticAndAlgebra({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19 10.998H13V16.998H11V10.998H5V8.99805H11V2.99805H13V8.99805H19V10.998ZM19 20.998H5V18.998H19V20.998Z"
        fill="currentColor"
      />
    </svg>
  );
}
