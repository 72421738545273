import { useEffect, useRef, useState } from 'react';
import {
  resendEmail,
  resetResendEmail,
} from '@app/web/src/actions/layoutActions';
import { useAppDispatch, useAppSelector } from '@app/web/src/hooks/redux';
import { appConfig } from '@front/config';
import { apis } from '@lib/web/apis';
import { useCountdown } from '@lib/web/hooks';
import { call } from '@lib/web/utils';

export default function useVerifyEmailButton({
  countdownDelay = 0,
}: {
  countdownDelay?: number;
} = {}) {
  const sent = useAppSelector((st) => st.layout.isEmailSending);
  const [startCountdown, setStartCountdown] = useState(false);
  const dispatch = useAppDispatch();
  const { seconds, finished } = useCountdown(
    startCountdown
      ? countdownDelay
        ? appConfig.resendVerifyEmailInterval - countdownDelay / 1000
        : appConfig.resendVerifyEmailInterval
      : 0
  );
  const it = useRef<NodeJS.Timeout>();

  const verifyEmail = async () => {
    dispatch(resendEmail());
    clearTimeout(it.current);
    call(() => apis.auth.sendVerifyEmail({ redirectTo: window.location.href }));
  };

  useEffect(() => {
    clearTimeout(it.current);
  }, []);

  useEffect(() => {
    if (finished) {
      dispatch(resetResendEmail());
    }
  }, [finished, dispatch]);

  useEffect(() => {
    if (it.current) clearTimeout(it.current);

    if (sent) {
      it.current = setTimeout(() => {
        setStartCountdown(true);
      }, countdownDelay);
    } else {
      setStartCountdown(false);
    }
  }, [countdownDelay, sent]);

  return {
    seconds,
    sent,
    verifyEmail,
  };
}
