import { useCallback, useContext, useMemo } from 'react';
import { buildInfiniteHookMutate, StructureType } from '@lib/web/apis';
import { keyBy } from 'lodash';

import { CreatorQuestionListContext } from '../contexts';

const useQuestionListData = () => {
  const {
    listData: {
      dataset,
      mutate,
      isEmpty,
      isLoading,
      isLoadingInitialData,
      setSize,
      size,
      isReachingEnd,
      isLoadingMore,
      totalCount,
      pageCount,
      error,
    },
  } = useContext(CreatorQuestionListContext);

  const reloadQuestions = useMemo(
    () => buildInfiniteHookMutate(mutate),
    [mutate]
  );

  const questionMap = useMemo(() => keyBy(dataset, (q) => q.id), [dataset]);

  const selectSingleQuestionOrQuestionGroup = useCallback(
    (id?: string) => (id ? questionMap[id] : undefined),
    [questionMap]
  );

  return {
    questions: dataset,
    totalCount,
    pageCount,
    reloadQuestions,
    questionsIsEmpty: isEmpty,
    isInitializingOrLoading: isLoadingInitialData || isLoading,
    isReachingEnd,
    isLoadingMore,
    setQuestionsSize: setSize,
    questionSize: size,
    selectSingleQuestionOrQuestionGroup,
    questionsError: error,
  };
};

const useSubQuestionData = (questions: GetCreatorQuestionListRes[]) => {
  const questionGroups = useMemo(
    () =>
      questions.filter((q) => q.structureType === StructureType.QuestionGroup),
    [questions]
  );

  // id -> subQuestion map
  const subQuestionMap = useMemo(
    () => keyBy(questionGroups.map((q) => q.subQuestions).flat(), (q) => q?.id),
    [questionGroups]
  );

  // groupId -> subQuestions map
  const groupToSubQuestionsMap = useMemo(() => {
    const result: Record<string, CreatorQuestionListItem[]> = {};
    questionGroups.forEach((group) => {
      result[group.id] = group.subQuestions;
    });
    return result;
  }, [questionGroups]);

  // subQuestionId -> group map
  const subQuestionToGroupMap = useMemo(() => {
    const result: Record<string, GetCreatorQuestionListRes> = {};
    questionGroups.forEach((group) => {
      group.subQuestions.forEach((subQuestion) => {
        result[subQuestion.id] = group;
      });
    });
    return result;
  }, [questionGroups]);

  const selectSubQuestion = useCallback(
    (id?: string) => (id ? subQuestionMap[id] : undefined),
    [subQuestionMap]
  );

  const selectGroupIdFromSubQuestion = useCallback(
    (id: string) => subQuestionToGroupMap[id]?.id,
    [subQuestionToGroupMap]
  );

  const selectGroupFromSubQuestion = useCallback(
    (id: string) => subQuestionToGroupMap[id],
    [subQuestionToGroupMap]
  );

  const questionGroupIds = useMemo(
    () => questionGroups.map((q) => q.id),
    [questionGroups]
  );

  const selectSubQuestionsFromGroup = useCallback(
    (id?: string) => (id ? groupToSubQuestionsMap[id] || [] : []),
    [groupToSubQuestionsMap]
  );

  return {
    questionGroupIds,
    selectSubQuestionsFromGroup,
    selectSubQuestion,
    selectGroupIdFromSubQuestion,
    selectGroupFromSubQuestion,
  };
};

export const useCreatorQuestionListData = () => {
  const questionListData = useQuestionListData();
  const subQuestionListData = useSubQuestionData(questionListData.questions);

  const selectQuestion = useCallback(
    (id?: string): CreatorQuestionListItem | undefined | null => {
      if (!id) {
        return null;
      }
      return (
        questionListData.selectSingleQuestionOrQuestionGroup(id) ||
        subQuestionListData.selectSubQuestion(id)
      );
    },
    [questionListData, subQuestionListData]
  );

  /**
   * Flatten the question list to a single array, including subQuestions
   */
  const flattenQuestionList = useMemo(
    () =>
      questionListData.questions
        .map((question) => [question, ...(question.subQuestions || [])])
        .flat(),
    [questionListData.questions]
  );

  const selectPreviousQuestion = useCallback(
    (
      id: string,
      {
        loopBackToLast,
        skipSubQuestion,
      }: { loopBackToLast?: boolean; skipSubQuestion?: boolean } = {}
    ): CreatorQuestionListItem | undefined => {
      const list = skipSubQuestion
        ? questionListData.questions
        : flattenQuestionList;

      const index = list.findIndex((question) => question.id === id);
      if (index === -1) return undefined;

      const previousIndex = (index - 1 + list.length) % list.length;

      if (previousIndex === index) return undefined;

      if (previousIndex === list.length - 1 && !loopBackToLast) {
        return undefined;
      }

      return list[previousIndex];
    },
    [questionListData, flattenQuestionList]
  );

  const selectNextQuestion = useCallback(
    (
      id: string,
      {
        loopBackToFirst,
        skipSubQuestion,
      }: { loopBackToFirst?: boolean; skipSubQuestion?: boolean } = {}
    ): CreatorQuestionListItem | undefined => {
      const list = skipSubQuestion
        ? questionListData.questions
        : flattenQuestionList;

      const index = list.findIndex((question) => question.id === id);
      if (index === -1) return undefined;

      const nextIndex = (index + 1) % list.length;

      if (nextIndex === index) return undefined;

      if (nextIndex === 0 && !loopBackToFirst) return undefined;

      return list[nextIndex];
    },
    [questionListData, flattenQuestionList]
  );

  const selectQuestionOrder = useCallback(
    (id: string): number => {
      const question = questionListData.selectSingleQuestionOrQuestionGroup(id);
      if (question) {
        return question.order;
      }

      const questionGroup = subQuestionListData.selectGroupFromSubQuestion(id);
      const subQuestion = subQuestionListData.selectSubQuestion(id);
      if (questionGroup && subQuestion) {
        return Number(`${questionGroup.order}.${subQuestion.order}`);
      }
      return 0;
    },
    [questionListData, subQuestionListData]
  );

  return {
    ...questionListData,
    ...subQuestionListData,
    selectQuestionOrder,
    selectQuestion,
    selectPreviousQuestion,
    selectNextQuestion,
  };
};
