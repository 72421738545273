import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

const styles = {
  root: {
    mask: 'url(/mask-avatar.svg) center center/contain no-repeat',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export type MaskIconProps = BoxProps;

export default function MaskIcon({ sx, ...rest }: MaskIconProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return <Box sx={[styles.root, ...sxProps]} {...rest} />;
}
