import React, { MouseEvent, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import { isString } from '@front/helper';
import {
  PrivacyPrivate as PrivacyPrivateIcon,
  ProfileUserPlaceholder as ProfileUserPlaceholderIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanelPortal,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import { HtmlRenderer } from '@lib/ia/src/components/HtmlRenderer';
import RichText from '@lib/ia/src/components/RichText';
import UploadAvatarPanel from '@lib/ia/src/components/RightPanels/UploadAvatarPanel';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { useIaMemberNftAvatar } from '@lib/ia/src/hooks/useMemberNftAvatar';
import IaTextBasedEditableCell from '@lib/ia/src/layouts/TableLayout/components/IaTableEditable/IaTextBasedEditableCell';
import useTableSelectionContext from '@lib/ia/src/layouts/TableLayout/hooks/useTableSelectionContext';
import { IaPanelKeys, IaPanelParams } from '@lib/ia/src/types/panels';
import { sanitize } from 'dompurify';

import {
  IaCellValueChangedEvent,
  TableLayoutAvatarTextCell,
  TableLayoutRow,
} from '../../types';

export type IaAvatarTextCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutAvatarTextCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
  },
  rootDefault: {
    gridTemplateColumns: '20px 1fr',
  },
  icon: {
    width: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarPrivateBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: 'background.darker',
      px: 0,
      minWidth: 'unset',
      width: 8,
      height: 8,
    },
  },
  text: {
    pl: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '& span.suffix': {
      opacity: 0.5,
    },

    br: {
      // make html element render on one line
      content: '" "',
      width: '2px',
      display: 'inline-block',
    },

    div: {
      display: 'inline-flex',
      alignItems: 'center',
    },

    '& [data-content-type="paragraph"] > p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};

export default function IaAvatarTextCell({
  row,
  columnKey,
  cell: {
    avatar,
    avatarCount = 1,
    avatarBadge,
    avatarName,
    text,
    textVariant,
    html,
    textSuffix,
    userId,
    memberId,
    className,
    editable,
    actionMap,
    placeholder,
    avatarUploadConfig,
  },
}: IaAvatarTextCellProps) {
  if (html && text) {
    console.warn('Both text and html are provided, only html will be used');
  }

  const { avatar: displayAvatar, name: displayName } = useIaMemberNftAvatar({
    userId,
    memberId,
    avatar,
    name: isString(text) ? text : '',
  });

  const { rightPanelTarget, openRightPanel } =
    useBaseRightPanel<IaPanelParams>();
  const { getIaAction } = useIaAction();
  const { focusOutCell } = useTableSelectionContext();

  const avatarBackgroundCount = Math.min(avatarCount - 1, 3);
  const ref = useRef<HTMLDivElement>();

  const handleAvatarClick = (event: MouseEvent) => {
    event.stopPropagation();

    if (avatarUploadConfig) {
      getIaAction<IaCellValueChangedEvent<string>>(
        actionMap?.avatarUploadPanelOpen
      )?.action({
        value: row.id,
        row,
        columnKey,
      });

      openRightPanel(IaPanelKeys.UploadAvatar, {
        config: avatarUploadConfig,
        onImageKeyChange: (imageKey) => {
          getIaAction<IaCellValueChangedEvent<string>>(
            actionMap?.avatarKeyChange
          )?.action({
            value: imageKey,
            row,
            columnKey,
          });
        },
      });

      focusOutCell();
    }
  };

  return (
    <>
      <Box
        sx={[styles.root, styles.rootDefault]}
        className={className}
        ref={ref}
      >
        {avatarBackgroundCount > 0 &&
          new Array(avatarBackgroundCount).fill(null).map((_, index) => (
            <Box
              key={index}
              sx={[
                styles.icon,
                { position: 'absolute', left: 8 - (index + 1) * 2 }, // padding left 8px , each background 2px spacing
              ]}
            >
              <ProfileUserPlaceholderIcon width={16} height={16} />
            </Box>
          ))}
        <Box
          sx={[styles.icon, !!avatarUploadConfig && { cursor: 'pointer' }]}
          onClick={handleAvatarClick}
        >
          {avatarBadge === 'private' ? (
            <Badge
              overlap="circular"
              sx={styles.avatarPrivateBadge}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={<PrivacyPrivateIcon width={6} height={6} />}
            >
              <SquareAvatar src={displayAvatar} size={16}>
                {avatarName || displayName}
              </SquareAvatar>
            </Badge>
          ) : (
            <SquareAvatar src={displayAvatar} size={16}>
              {avatarName || displayName}
            </SquareAvatar>
          )}
        </Box>
        <Typography variant="body2" sx={styles.text} component="div">
          {text && <RichText text={text} variant={textVariant} />}
          {html && !displayName ? (
            <HtmlRenderer htmlString={sanitize(html)} />
          ) : !text ? (
            <span>{displayName}</span>
          ) : null}
          {textSuffix && <span className="suffix"> {textSuffix}</span>}
        </Typography>
      </Box>
      {editable && (
        <IaTextBasedEditableCell
          containerRef={ref}
          actionMap={actionMap}
          editable={editable}
          placeholder={placeholder}
          columnKey={columnKey}
          row={row}
          initValue={text as string}
          filterValue={(val: string) => val}
        />
      )}
      {editable && rightPanelTarget === IaPanelKeys.UploadAvatar && (
        <BaseLayoutRightPanelPortal>
          <UploadAvatarPanel />
        </BaseLayoutRightPanelPortal>
      )}
    </>
  );
}
