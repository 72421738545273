import { useContext, useMemo } from 'react';
import {
  ThreadViewStatus,
  ThreadViewType,
  ThreadViewUserStatus,
  useAuth,
} from '@lib/web/apis';
import { useClubSlug, useIaClub } from '@lib/web/hooks';
import { ThreadCurrentLocationContext } from '@lib/web/thread';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { hashLocationKey } from '@lib/web/thread/hooks/view/utils';
import { v4 } from 'uuid';

import { generateLocationViewConfig } from '../../utils/viewUtils';

export const useDefaultLocationThreadView = (): GetThreadViewRes => {
  const { member } = useAuth();
  const clubSlug = useClubSlug();
  const { club } = useIaClub(clubSlug);
  const { currentLocationDetail } = useContext(ThreadCurrentLocationContext);
  const { getThreadLocationDisplay } = useThread();

  return useMemo(() => {
    const myMemberId = member?.memberId || '';
    const now = new Date().toISOString();
    const { filterConfig, sortConfig } = generateLocationViewConfig({
      clubId: club?.clubId,
      selfMemberId: myMemberId,
    });

    return {
      type: ThreadViewType.Location,
      id: `${myMemberId}-${club?.clubId || ''}-${hashLocationKey(
        currentLocationDetail
      )}`,
      status: ThreadViewStatus.Active,
      createdAt: now,
      updatedAt: now,
      referenceId: null,
      name: getThreadLocationDisplay(currentLocationDetail)?.name || '',
      emoji: null,
      sourceId: null,
      clubId: club?.clubId || null,
      hideAt: null,
      threadViewUsers: [
        {
          id: v4(),
          createdAt: now,
          customEmoji: null,
          customName: null,
          filterConfig: filterConfig || '',
          sortConfig: sortConfig || '',
          memberId: myMemberId,
          status: ThreadViewUserStatus.Active,
          updatedAt: now,
        },
      ],
    };
  }, [
    club?.clubId,
    currentLocationDetail,
    getThreadLocationDisplay,
    member?.memberId,
  ]);
};
