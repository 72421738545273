import generateHook from './swr.helper';

export const useSubscriptionPlan = (status = 1) =>
  generateHook<GetSubscriptionPlanRes>(
    `marketplace/v1/subscription/plan?status=${status}`,
    {
      auth: true,
      immutable: true,
    }
  );

export const useSubscriptionOrderInfo = (orderId: string) =>
  generateHook<GetSubscriptionOrderInfoRes>(
    `marketplace/v1/subscription/order?orderId=${orderId}`,
    {
      auth: true,
      immutable: true,
    }
  );
