import React from 'react';

export default function OtherCheckSquareDash({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect height="3" width="12" fill="currentColor" rx="0.8" x="6" y="10.5" />
      <path
        d="M5.86066 5.86066C4.98767 6.73365 4.5 8.38928 4.5 12C4.5 15.6107 4.98767 17.2664 5.86066 18.1393C6.73365 19.0123 8.38928 19.5 12 19.5C15.6107 19.5 17.2664 19.0123 18.1393 18.1393C19.0123 17.2664 19.5 15.6107 19.5 12C19.5 8.38928 19.0123 6.73365 18.1393 5.86066C17.2664 4.98767 15.6107 4.5 12 4.5C8.38928 4.5 6.73365 4.98767 5.86066 5.86066ZM3 12C3 4.8 4.8 3 12 3C19.2 3 21 4.8 21 12C21 19.2 19.2 21 12 21C4.8 21 3 19.2 3 12Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
