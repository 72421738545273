import { FC, useEffect, useMemo, useState } from 'react';
import { Popover } from '@mui/material';
import {
  BlockNoteEditor,
  BlockSchema,
  DefaultBlockSchema,
  SlashMenuProsemirrorPlugin,
  SuggestionsMenuState,
} from '@blocknote/core';
import { DefaultSlashMenu, ReactSlashMenuItem } from '@blocknote/react';
import { ComposerSlashMenuItem } from '@lib/web/composer';

import ThemeProvider from '../../components/ThemeProvider';

export type SlashMenuProps<BSchema extends BlockSchema = DefaultBlockSchema> =
  Pick<SlashMenuProsemirrorPlugin<BSchema, any>, 'itemCallback'> &
    Pick<
      SuggestionsMenuState<ReactSlashMenuItem<BSchema>>,
      'keyboardHoveredItemIndex'
    > & {
      filteredItems: ComposerSlashMenuItem<BSchema>[];
    };

const SlashMenuPositioner = <
  BSchema extends BlockSchema = DefaultBlockSchema
>(props: {
  editor: BlockNoteEditor<BSchema>;
  slashMenu?: FC<SlashMenuProps<BSchema>>;
  onAddBlock?: SlashMenuProsemirrorPlugin<BSchema, any>['itemCallback'];
  onShowHide?: (show: boolean) => void;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [filteredItems, setFilteredItems] =
    useState<ComposerSlashMenuItem<BSchema>[]>();
  const [keyboardHoveredItemIndex, setKeyboardHoveredItemIndex] =
    useState<number>();
  const [position, setPosition] = useState<DOMRect>();

  useEffect(() => {
    return props.editor.slashMenu.onUpdate((slashMenuState) => {
      setShow(slashMenuState.show);
      setFilteredItems(slashMenuState.filteredItems);
      setKeyboardHoveredItemIndex(slashMenuState.keyboardHoveredItemIndex);

      setPosition(slashMenuState.referencePos);
      props.onShowHide?.(slashMenuState.show);
    });
  }, [props]);

  const slashMenuElement = useMemo(() => {
    if (!filteredItems || keyboardHoveredItemIndex === undefined) {
      return null;
    }

    const SlashMenu = props.slashMenu || DefaultSlashMenu;

    return (
      <SlashMenu
        filteredItems={filteredItems}
        itemCallback={(item) => {
          props.onAddBlock?.(item);
          props.editor.slashMenu.itemCallback(item);
        }}
        keyboardHoveredItemIndex={keyboardHoveredItemIndex}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredItems,
    keyboardHoveredItemIndex,
    props.editor.slashMenu,
    props.slashMenu,
  ]);

  const top = position ? position.top + position.height : 0;
  const left = position ? position.left : 0;

  return (
    <ThemeProvider>
      <Popover
        open={show}
        anchorReference="anchorPosition"
        anchorPosition={{ top, left }}
        hideBackdrop
        disableEnforceFocus
        disableAutoFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: { backgroundColor: 'background.menu' },
          },
        }}
      >
        <div>{slashMenuElement}</div>
      </Popover>
    </ThemeProvider>
  );
};
export default SlashMenuPositioner;
