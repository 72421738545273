import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { EditorParaphrase as EditorParaphraseIcon } from '@front/icon';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { apis } from '@lib/web/apis';
import { callWithToast } from '@lib/web/utils';

import { useEditorRubricActions } from './hooks/useEditorRubricActions';
import { useEditorRubricData } from './hooks/useEditorRubricData';
import { useEditorRubricLayoutConfig } from './hooks/useEditorRubricLayoutConfig';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  switchInput: {
    display: 'grid',
    gridTemplateColumns: '16px 1fr 28px',
    gap: 1,
    alignItems: 'center',
  },
  switch: {
    width: 28,
    height: 16,
    '& .MuiSwitch-switchBase': {
      width: 16,
      height: 16,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
      },
    },
    '& .MuiSwitch-thumb': {
      width: 14,
      height: 14,
    },
  },
};

export type EditorRubricProps = {
  questionId: string;
  readonly?: boolean;
  onCriteriaSidePeelClick?: (id: string) => void;
};

export default function EditorRubric({
  questionId,
  readonly,
  onCriteriaSidePeelClick,
}: EditorRubricProps) {
  const { t } = useTranslation('editor');

  const { rubric, mutateRubric } = useEditorRubricData(questionId);
  const availableActions = useEditorRubricActions({
    questionId,
    readonly,
    onCriteriaSidePeelClick,
  });
  const isEnableDescriptor = rubric?.isEnableDescriptor ?? false;

  const handleEnableDescriptorChange = async (checked: boolean) => {
    if (!rubric) return;

    mutateRubric(
      callWithToast(
        apis.editor.updateCreatorQuestionRubric({
          id: rubric?.id ?? '',
          isEnableDescriptor: checked,
        }),
        {
          showLoading: false,
        }
      ),
      {
        optimisticData: {
          ...rubric,
          isEnableDescriptor: checked,
        },
      }
    );
  };

  const config = useEditorRubricLayoutConfig({
    rubric,
    readonly,
  });

  return (
    <Box sx={styles.root}>
      <Box sx={styles.switchInput}>
        <EditorParaphraseIcon width={16} height={16} />
        <Typography variant="caption">{t('Enable Descriptor')}</Typography>
        <Switch
          sx={styles.switch}
          checked={isEnableDescriptor}
          onChange={(e) => handleEnableDescriptorChange(e.target.checked)}
          disabled={readonly}
        />
      </Box>
      <IaActionContextProvider availableActions={availableActions}>
        <IaLayouts layouts={config} />
      </IaActionContextProvider>
    </Box>
  );
}
