import { DisplayTableLayoutFooterCell } from '../../types';

import IaDisplayTableButtonFooterCell from './IaDisplayTableButtonFooterCell';
import IaDisplayTableTextButtonFooterCell from './IaDisplayTableTextButtonFooterCell';
import IaDisplayTableTextFooterCell from './IaDisplayTableTextFooterCell';

type IaDisplayTableFooterCellProps = {
  cell: DisplayTableLayoutFooterCell;
};

export default function IaDisplayTableFooterCell({
  cell,
}: IaDisplayTableFooterCellProps) {
  switch (cell.type) {
    case 'text':
      return <IaDisplayTableTextFooterCell {...cell} />;
    case 'button':
      return <IaDisplayTableButtonFooterCell {...cell} />;
    case 'textButton':
      return <IaDisplayTableTextButtonFooterCell {...cell} />;
    default:
      console.warn('Unknown footer cell type:', cell);
      return null;
  }
}
