import { useCallback, useMemo } from 'react';
import { useAuth } from '@lib/web/apis';
import { Channel } from 'stream-chat';

export const useJoinChannel = () => {
  const { member } = useAuth();
  const myMemberId = member?.memberId;

  const joinChannelIfNotAMember = useCallback(
    (channel: Channel) => {
      if (!myMemberId) return;

      const channelMemberIds = Object.keys(channel.state.members);

      if (channelMemberIds.includes(myMemberId)) return;

      return channel.addMembers([myMemberId]);
    },
    [myMemberId]
  );

  return useMemo(
    () => ({
      joinChannelIfNotAMember,
    }),
    [joinChannelIfNotAMember]
  );
};
