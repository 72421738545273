import React from 'react';

export default function PropertyTypeButtonCTA({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.79006 9.24V5.5C8.79006 4.12 9.91006 3 11.2901 3C12.6701 3 13.7901 4.12 13.7901 5.5V9.24C15.0001 8.43 15.7901 7.06 15.7901 5.5C15.7901 3.01 13.7801 1 11.2901 1C8.80006 1 6.79006 3.01 6.79006 5.5C6.79006 7.06 7.58006 8.43 8.79006 9.24ZM14.2901 11.71C14.0101 11.57 13.7101 11.5 13.4001 11.5H12.7901V5.5C12.7901 4.67 12.1201 4 11.2901 4C10.4601 4 9.79006 4.67 9.79006 5.5V16.24L6.35006 15.52C5.98006 15.44 5.59006 15.56 5.32006 15.83C4.89006 16.27 4.89006 16.97 5.32006 17.41L9.33006 21.42C9.71006 21.79 10.2201 22 10.7501 22H16.8501C17.8501 22 18.6901 21.27 18.8301 20.28L19.4601 15.81C19.5801 14.96 19.1401 14.12 18.3701 13.74L14.2901 11.71Z"
        fill="currentColor"
      />
    </svg>
  );
}
