import Card from '@lib/ia/src/components/Card';

import { CardLayoutSimpleTooltipConfig } from './types';

type CardLayoutSimpleTooltipProps = {
  config: CardLayoutSimpleTooltipConfig['config'];
};
export default function CardLayoutSimpleTooltip({
  config,
}: CardLayoutSimpleTooltipProps) {
  return <Card.Tooltip followCursor>{config.content}</Card.Tooltip>;
}
