import React, { useContext, useState } from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import { DisplayTableLayoutSelectOption } from '@lib/ia/src/layouts/DisplayTableLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { ThreadCurrentLocationContext } from '@lib/web/thread';
import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';
import { useThreadChildChannelPropertiesLayoutConfig } from '@lib/web/thread/ThreadProperties/hooks/useThreadChildChannelPropertiesLayoutConfig';
import ThreadEmbeddedQuestion from '@lib/web/thread/ThreadProperties/ThreadEmbeddedQuestion';
import { Channel } from 'stream-chat';

const styles = {
  root: {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    background: (theme: Theme) => theme.palette.background.body,
    backgroundPosition: 'right center',
    backgroundAttachment: 'fixed',
    backgroundSize: '100vw 100vh',

    px: 1.5,

    '.base-layout-right-panel &': {
      px: 0.5,
    },
  },
  rootExpandableFull: {
    borderBottom: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
  },
};

export type ThreadChildChannelPropertiesProps = {
  parentChannel: Channel;
  mode: 'expandableBrief' | 'expandableFull' | 'full';
};

export default function ThreadChildChannelProperties({
  parentChannel,
  mode: initialMode,
}: ThreadChildChannelPropertiesProps) {
  const [mode, setMode] = useState(initialMode);
  const { sendPublicly } = useThreadComposer();
  const config = useThreadChildChannelPropertiesLayoutConfig({
    parentChannel,
    mode,
    sendPublicly,
  });
  const { currentLocationDetail } = useContext(ThreadCurrentLocationContext);

  const availableActions = {
    show: {
      action: () => {
        setMode('expandableFull');
      },
    },
    hide: {
      action: () => {
        setMode('expandableBrief');
      },
    },
  };

  const getItemStatus = ({
    option,
  }: {
    option: DisplayTableLayoutSelectOption;
  }) => {
    return {
      selected: option?.value === 'created_by', // support different option in PRF-2570
    };
  };

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <IaItemStatusProvider getItemStatus={getItemStatus}>
        <Box
          sx={[
            styles.root,
            mode === 'expandableFull' && styles.rootExpandableFull,
          ]}
          className="thread-properties-root"
        >
          <IaLayouts layouts={config} />
          <ThreadEmbeddedQuestion locationDetail={currentLocationDetail} />
        </Box>
      </IaItemStatusProvider>
    </IaActionContextProvider>
  );
}
