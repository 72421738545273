import React from 'react';

export default function EditorMergeCells({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.4286 18.4286V14.5714H21V21H12.6429V18.4286H18.4286ZM11.3571 21H3V14.5714H5.57143V18.4286H11.3571V21ZM15.2143 13.2857V14.5714L12.6429 12L15.2143 9.42857V10.7143H21V13.2857H15.2143ZM8.78571 10.7143V9.42857L11.3571 12L8.78571 14.5714V13.2857H3V10.7143H8.78571ZM18.4286 5.57143H12.6429V3H21V9.42857H18.4286V5.57143ZM3 3H11.3571V5.57143H5.57143V9.42857H3V3Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
