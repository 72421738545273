import { MouseEvent } from 'react';
import { alpha, Box, InputBase, Theme, Typography } from '@mui/material';
import { OtherDelete as OtherDeleteIcon } from '@front/icon';

import { TipButton } from '../../../atoms';
import { MaskIcon } from '../../../components';

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '32px 1fr 20px',
    gap: 1,
    p: '12px',
    borderRadius: '12px',
    border: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    '&:hover': {
      borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
    '&:focus-within:has(*:not(button):focus)': {
      borderColor: (theme: Theme) => alpha(theme.palette.primary.light, 0.5),
      bgcolor: (theme: Theme) => alpha(theme.palette.primary.light, 0.1),
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  step: {
    bgcolor: 'text.primary',
    color: 'background.darker',
    fontWeight: 700,
    width: 32,
    height: 32,
  },
  title: {
    fontWeight: 700,
    lineHeight: 1,
  },
  input: {
    input: {
      p: 0,
      fontSize: 14,
      lintHeight: '16.8px',
    },
  },
  button: {
    p: 0,
    svg: {
      width: 16,
      height: 16,
    },
  },
};
export type PromptCardProps = {
  step: number;
  value: string;
  title?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onDelete?: () => void;

  deleteTip?: string;
  disabled?: boolean;
};

export default function PromptCard({
  step,
  title,
  value,
  placeholder,
  onChange,
  onDelete,
  deleteTip = 'Delete prompt',
  disabled,
}: PromptCardProps) {
  const handleDelete = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();

    onDelete?.();
  };
  return (
    <Box sx={styles.root}>
      <Box>
        <MaskIcon sx={styles.step}>{step}</MaskIcon>
      </Box>
      <Box sx={styles.container}>
        <Typography sx={styles.title}>{title || `Prompt ${step}`}</Typography>
        <InputBase
          sx={styles.input}
          value={value}
          onChange={(ev) => onChange(ev.target.value)}
          placeholder={placeholder}
          multiline
          disabled={disabled}
        />
      </Box>
      <Box>
        <TipButton
          sx={styles.button}
          title={deleteTip}
          customSize={20}
          onClick={handleDelete}
          disabled={disabled}
        >
          <OtherDeleteIcon />
        </TipButton>
      </Box>
    </Box>
  );
}
