import { PropsWithChildren, ReactNode } from 'react';
import { Box } from '@mui/material';

export default function TooltipList({
  content,
  children,
}: PropsWithChildren<{ content?: ReactNode }>) {
  return (
    <Box display="grid" gap={2}>
      {!!content && <Box>{content}</Box>}
      <Box display="grid" gap={1} minWidth={0}>
        {children}
      </Box>
    </Box>
  );
}
