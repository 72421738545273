import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';

type BackgroundColorType = 'darker' | 'default' | 'paper' | 'body';

export type MaskShadowProps = BoxProps & {
  start?: string;
  end?: string;
  backgroundColor?: BackgroundColorType | string;
};

const styles = {
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    userSelect: 'none',
  },
};

const getMaskDirection = (start: string, end: string) => ({
  maskImage: `-webkit-gradient(linear, ${start}, ${end}, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))`,
});

const getBackground = (background: BackgroundColorType | string) => {
  if (['default', 'body', 'darker', 'paper'].includes(background)) {
    return {
      background: (theme: Theme) =>
        theme.palette.background[background as BackgroundColorType],
      backgroundSize: '100vw',
      backgroundAttachment: 'fixed',
    };
  }
  return { background };
};

export default function MaskShadow({
  sx,
  start = 'right center',
  end = 'left center',
  backgroundColor = 'body',
  ...rest
}: MaskShadowProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box
      sx={[
        styles.root,
        getMaskDirection(start, end),
        getBackground(backgroundColor),
        ...sxProps,
      ]}
      {...rest}
    />
  );
}
