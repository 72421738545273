import React from 'react';

export default function OtherWithdraw({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 3.55176L16.2234 7.97623C16.6047 8.37572 16.59 9.00872 16.1905 9.39006C15.791 9.7714 15.158 9.75668 14.7766 9.35718L13 7.49593V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V7.49593L9.22336 9.35718C8.84202 9.75668 8.20902 9.7714 7.80952 9.39006C7.41003 9.00872 7.39531 8.37572 7.77664 7.97623L12 3.55176ZM4 15C4.55228 15 5 15.4478 5 16V18C5 18.5523 5.44771 19 6 19H18C18.5523 19 19 18.5523 19 18V16C19 15.4478 19.4477 15 20 15C20.5523 15 21 15.4478 21 16V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V16C3 15.4478 3.44772 15 4 15Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
