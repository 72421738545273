import React from 'react';

export default function EditorAreaChart({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect height="2" width="18" fill="currentColor" rx="1" x="3" y="19" />
      <path
        d="M20 17H4C3.44772 17 3 16.556 3 16.0082V13.4643C3 13.1939 3.11135 12.9352 3.30828 12.7481L6.88623 9.349C7.24013 9.01279 7.78755 8.98048 8.17916 9.2727L12.1435 12.2308C12.6066 12.5764 13.268 12.4604 13.5835 11.9782L19.1612 3.4537C19.7032 2.62521 21 3.00595 21 3.99361V16.0082C21 16.556 20.5523 17 20 17Z"
        fill="currentColor"
      />
    </svg>
  );
}
