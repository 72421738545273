import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { keyframes } from '@mui/material/styles';
import { OtherLoading as OtherLoadingIcon } from '@front/icon';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const styles = {
  icon: {
    display: 'block',
    width: 16,
    height: 16,
    animation: `${rotate} 1s infinite ease`,

    svg: {
      display: 'block',
      width: 16,
      height: 16,
    },
  },
};

type LoadingIconProps = {
  sx?: BoxProps['sx'];
};
export default function LoadingIcon({ sx }: LoadingIconProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box
      sx={[styles.icon, ...sxProps]}
      className="loading-icon"
      component="span"
    >
      <OtherLoadingIcon />
    </Box>
  );
}
