import React from 'react';

export default function NFTFavoriteSolid({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.05 5C14.484 5 12.981 5.70627 12 6.81362C11.019 5.70627 9.516 5 7.95 5C5.178 5 3 7.10136 3 9.79564C3 13.0828 6.06 15.7771 10.695 19.849L12 21L13.305 19.849C17.94 15.7771 21 13.0828 21 9.79564C21 7.10136 18.822 5 16.05 5Z"
        fill="currentColor"
      />
    </svg>
  );
}
