import { ChangeEvent, useCallback, useRef, useState } from 'react';
import axios from 'axios';

import { useLatestValueRef } from './index';

export default function useUploadFile(props: {
  getUploadKeyAndUrl: (
    file: File
  ) => Promise<{ objectKey: string; uploadUrl: string }>;
  onFileVerify?: ({ file }: { file: File }) => boolean;
  onFileChange?: ({
    file,
    previewUrl,
  }: {
    file: File;
    previewUrl: string;
  }) => void;
  onSuccess?: (key: string) => void;
  onFail?: (err: Error) => void;
  onLoadPreviewImg?: ({
    size,
  }: {
    size: { naturalHeight: number; naturalWidth: number };
  }) => void;
}) {
  const propsRef = useLatestValueRef(props);

  const [progress, setProgress] = useState<number | null>(null);
  const abortControllerRef = useRef<AbortController>();

  const uploadFile = useCallback(
    async (file: File) => {
      setProgress(0);

      try {
        const { objectKey, uploadUrl } =
          await propsRef.current.getUploadKeyAndUrl(file);
        abortControllerRef.current = new AbortController();

        await axios.put(uploadUrl, file, {
          signal: abortControllerRef.current.signal,
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              setProgress(
                Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              );
            }
          },
        });
        propsRef.current.onSuccess?.(objectKey);
        setProgress(100);
      } catch (e) {
        propsRef.current.onFail?.(e as Error);
        setProgress(null);
      }
    },
    [propsRef]
  );

  const maybeLoadPreviewImg = useCallback(
    (previewUrl: string) => {
      if (propsRef.current.onLoadPreviewImg) {
        const img = new Image();
        img.onload = function () {
          propsRef.current.onLoadPreviewImg?.({
            size: {
              naturalHeight: img.naturalHeight,
              naturalWidth: img.naturalWidth,
            },
          });
        };
        img.src = previewUrl;
      }
    },
    [propsRef]
  );

  const handleFileUpload = useCallback(
    (file: File) => {
      if (propsRef.current.onFileVerify?.({ file }) === false) {
        return;
      }

      const previewUrl = URL.createObjectURL(file);
      propsRef.current.onFileChange?.({ file, previewUrl });
      void uploadFile(file);
      maybeLoadPreviewImg(previewUrl);
    },
    [maybeLoadPreviewImg, propsRef, uploadFile]
  );

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        handleFileUpload(e.target.files[0]);
      }
    },
    [handleFileUpload]
  );

  const changeFile = useCallback(
    (file: File) => {
      if (propsRef.current.onFileVerify?.({ file }) === false) {
        return;
      }
      const previewUrl = URL.createObjectURL(file);
      propsRef.current.onFileChange?.({ file, previewUrl });
      void uploadFile(file);
      maybeLoadPreviewImg(previewUrl);
    },
    [maybeLoadPreviewImg, propsRef, uploadFile]
  );

  const abortUpload = useCallback(() => {
    abortControllerRef.current?.abort();
  }, []);

  return {
    changeFile,
    abortUpload,
    handleFileChange,
    handleFileUpload,
    progress,
    uploading: progress !== null && progress !== 100,
  };
}
