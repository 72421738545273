import { ErrorMessageValue } from '@lib/web/ui';

import { rules } from '../settings/grid-in';

export const getGridErrorMessage = (
  answer: string
): ErrorMessageValue | null => {
  const errorIndex = rules.findIndex((rule) => {
    if (rule.required) {
      return answer === '';
    }
    if (rule.regex) {
      return rule.regex.test(answer);
    }
    return false;
  });

  if (errorIndex > -1) {
    return {
      id: rules[errorIndex].display,
      type: 'error' as const,
      message: rules[errorIndex].display,
    };
  }

  return null;
};
