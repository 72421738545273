import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { alpha, Box, Skeleton, Theme, Typography } from '@mui/material';
import HorizontalTextAnimation from '@app/web/src/components/HorizontalTextAnimation';
import usePlaylistRename from '@app/web/src/widgets/PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistRename';
import { OtherEdit as OtherEditIcon } from '@front/icon';
import { ResponsiveTooltip, TipButton } from '@front/ui';
import { useAuth, useQuizResultSummary } from '@lib/web/apis';

const styles = {
  root: {
    height: 43,
    ml: '-38px',
  },
  wrap: {
    width: '100%',
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  name: {
    px: '4px',
    py: '2px',
    height: 42,
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  nameDefault: {
    pl: '38px',
    textOverflow: 'ellipsis',
  },
  nameHovered: {
    pl: '0px',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    borderBottom: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.5)}`,
  },
  iconButton: {
    bgcolor: 'background.menu',
    minWidth: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 1,
    boxShadow: (theme: Theme) =>
      `inset 0 0 0 1px ${
        theme.palette.mode === 'light'
          ? 'rgba(8, 8, 8, 0.05)'
          : 'rgba(255, 255, 255, 0.1)'
      }`,
  },
  inputWrap: {
    pl: '34px',
  },
  h1: (theme: Theme) => ({
    p: 0,
    display: 'block',
    color: 'text.primary',
    width: '100%',
    fontWeight: 'bold',
    ...theme.typography.appH1,
    fontFamily: theme.typography.fontFamily,
    '&:disabled': {
      color: 'text.primary',
    },
  }),

  default: {
    px: '4px',
    py: '2px',
  },
  input: {
    width: '100%',
    px: '4px',
    py: '2px',
    border: 'none',
    bgcolor: 'transparent',
    cursor: 'text',
    height: 43,
  },

  hide: {
    position: 'absolute',
    opacity: 0,
    visibility: 'hidden',
  },
  note: {
    opacity: 0.64,
    display: 'block',
  },
};

function PlaylistNameInput({
  quizId,
  onBlur,
}: {
  quizId: string;
  onBlur: () => void;
}) {
  const inputRef = useRef<HTMLInputElement>();
  const { value, disabled, onNameChange } = usePlaylistRename(quizId);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onNameChange(ev.target.value || '');
  };
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    });
  }, []);
  return (
    <Box sx={styles.inputWrap}>
      <Box
        ref={inputRef}
        sx={[styles.h1, styles.input]}
        component="input"
        value={value || ''}
        placeholder="Untitled"
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
      />
    </Box>
  );
}

function PlaylistHeader({
  quizId,
  playlistName,
}: {
  quizId: string;
  playlistName: string;
}) {
  const [hovered, setHovered] = useState(false);
  const [editable, setEditable] = useState(false);
  const { t } = useTranslation();

  if (editable) {
    return (
      <PlaylistNameInput quizId={quizId} onBlur={() => setEditable(false)} />
    );
  }
  return (
    <Box
      sx={styles.wrap}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        setEditable(true);
        setHovered(false);
      }}
    >
      {hovered && !editable && (
        <Box sx={styles.iconButton}>
          <TipButton
            customSize={24}
            title={t('toolbar.Rename Playlist Title')}
            tooltipProps={{ followCursor: true }}
          >
            <OtherEditIcon />
          </TipButton>
        </Box>
      )}

      <Box sx={[styles.name, styles.nameDefault, hovered && styles.hide]}>
        {playlistName}
      </Box>

      {hovered && (
        <ResponsiveTooltip
          content={
            <Box display="grid" gap="2px">
              {playlistName}
              <Typography variant="caption" sx={styles.note}>
                click to rename
              </Typography>
            </Box>
          }
          tooltipProps={{ followCursor: true }}
        >
          <Box minWidth={0}>
            <HorizontalTextAnimation sx={[styles.name, styles.nameHovered]}>
              {playlistName}
            </HorizontalTextAnimation>
          </Box>
        </ResponsiveTooltip>
      )}
    </Box>
  );
}

export default function PlaylistHeaderRoot({ quizId }: { quizId: string }) {
  const { data } = useQuizResultSummary({ quizId });
  const sid = useRouter().query.sid as string;
  const { member } = useAuth();
  const disabled = !member || data?.data.quiz.quizOwnerId !== member?.userId;

  if (!data) {
    return (
      <>
        {/* empty icon */}
        <Box />
        <Skeleton width={100} />
      </>
    );
  }

  if (sid || disabled) {
    return (
      <>
        {/* empty icon */}
        <Box />
        <Box>{data.data.quiz.quizCustomName}</Box>
      </>
    );
  }
  return (
    <>
      {/* empty icon */}
      <Box />
      <Box sx={styles.root}>
        <PlaylistHeader
          quizId={quizId}
          playlistName={data.data.quiz.quizCustomName}
        />
      </Box>
    </>
  );
}
