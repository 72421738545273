import { useEffect, useRef } from 'react';
import { useOnScreen, useVisibilityChange } from '@front/helper';
import { useAddWatchingEvent } from '@lib/web/thread/hooks/core/useAddWatchingEvent';
import { useReadState } from '@lib/web/thread/hooks/message/useReadState';
import { Channel, Event } from 'stream-chat';

export const useMaybeMarkThreadChatRead = ({
  channel,
}: {
  channel: Channel;
}) => {
  const ref = useRef<HTMLElement>(null);
  const onScreen = useOnScreen(ref);
  const { getChannelReadState } = useReadState();

  // when new message comes
  useAddWatchingEvent({
    scope: 'chat-mark-read',
    key: channel.cid,
    callback: (event: Event) => {
      const isThisChannel = channel && event.cid === channel.cid;
      const isDocumentVisible = document.visibilityState === 'visible';

      if (
        event.type === 'message.new' &&
        isThisChannel &&
        isDocumentVisible &&
        onScreen &&
        channel.state.unreadCount > 0
      ) {
        void channel.markRead();
      }
    },
  });

  // when user re focus on browser
  useVisibilityChange({
    onVisibleChange: (isVisible: boolean) => {
      if (isVisible && onScreen && channel.state.unreadCount > 0) {
        void channel.markRead();
      }
    },
  });

  // when first seen the chat
  useEffect(() => {
    if (onScreen && channel.state.unreadCount > 0) {
      /**
       * use 500 ms delay to make it read, otherwise it will make the read status wrong
       * (user will not see unread message status because we too soon to make is read)
       */
      setTimeout(() => {
        void channel.markRead();
      }, 500);
    }
  }, [channel, getChannelReadState, onScreen]);

  return {
    ref,
  };
};
