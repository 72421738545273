import { Box, Typography } from '@mui/material';
import {
  OtherCheckedSquareSolid as OtherCheckedSquareSolidIcon,
  OtherCheckSquareDash as OtherCheckSquareDashIcon,
  OtherCheckSquareOutline as OtherCheckSquareOutlineIcon,
} from '@front/icon';
import { Icon } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { TableLayoutRow } from '../../types';
type IaTableHeadCheckboxProps = {
  rows: TableLayoutRow[];
  icon: string;
  text: string;
  value?: string;
  disable?: boolean;
};
const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 32px',
    height: 32,
    cursor: 'pointer',
    userSelect: 'none',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 0.5,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const getSelectedState = (row: TableLayoutRow) => {
  return row.cells.action.type === 'checkbox'
    ? row.cells.action.value
    : undefined;
};
export default function IaTableHeadCheckbox({
  rows,
  icon,
  text,
  value,
  disable,
}: IaTableHeadCheckboxProps) {
  const { getIaAction } = useIaAction();

  const checkedItems = rows.filter(
    (row) => getSelectedState(row) === 'checked'
  );

  const uncheckedItems = rows.filter(
    (row) => getSelectedState(row) !== 'checked'
  );

  const checked = checkedItems.length === rows.length && rows.length > 1;
  const unchecked = uncheckedItems.length === rows.length;
  const partial = !checked && !unchecked;

  const handleSelect = () => {
    if (!disable) {
      getIaAction(value)?.action?.();
    }
  };
  return (
    <Box sx={styles.root} onClick={handleSelect}>
      <Box sx={styles.text}>
        <Icon name={icon} width={16} height={16} />
        <Typography variant="body2">{text}</Typography>
      </Box>
      <Box sx={styles.icon}>
        {checked && <OtherCheckedSquareSolidIcon width={16} height={16} />}
        {partial && <OtherCheckSquareDashIcon width={16} height={16} />}
        {unchecked && <OtherCheckSquareOutlineIcon width={16} height={16} />}
      </Box>
    </Box>
  );
}
