import { useCallback, useMemo, useState } from 'react';
import { appConfig } from '@front/config';
import combineSearch from '@lib/ia/src/utils/combineSearch';
import {
  AgentUserViewSlug,
  useAgentUsers,
  useAuth,
  useThreadMentionMembers,
} from '@lib/web/apis';
import { SuggestMenuItem } from '@lib/web/composer/TextComposer/components/SuggestionMenu/SuggestionMenu';
import { useCurrentIaClub } from '@lib/web/hooks';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { isAgentThreadUser } from '@lib/web/thread/typeGuard';
import { ThreadUser } from '@lib/web/thread/types';
import { getAgentIconPath } from '@lib/web/utils';

const MAX_MENTION_ITEMS = appConfig.maxThreadMentionItemsDisplay - 1; // - 1 for mention everyone

type ThreadMentionItem = SuggestMenuItem<{
  isInThread: boolean;
  memberId: string;
  userId?: string;
  agentId?: string;
  isFollower?: boolean;
  isFollowing?: boolean;
  isJoinedClub?: boolean;
}>;

const mentionEveryoneItem: ThreadMentionItem = {
  value: 'thread',
  display: 'Everyone in thread',
  icon: {
    type: 'icon' as const,
    value: 'OtherLoudspeaker',
  },
  metadata: {
    isInThread: true,
    memberId: '',
    userId: '',
  },
};

const useMentionUsers = ({
  keyword,
  clubId,
  inThreadUserMemberIds,
}: {
  keyword: string;
  clubId?: string;
  inThreadUserMemberIds: string[];
}) => {
  const { data: usersData } = useThreadMentionMembers({
    keyword,
    clubId,
    threadMemberIds: inThreadUserMemberIds,
    limit: MAX_MENTION_ITEMS,
  });

  return useMemo(
    () =>
      usersData?.data.items.map(
        (item) =>
          ({
            display: item.displayName || item.distinctName,
            value: item.distinctName,
            icon: {
              type: 'image',
              value: item.nftAvatar || item.avatar,
            },
            description: !item.isInThread
              ? 'Not in thread'
              : !item.isJoinedClub && !!clubId
              ? 'Not in club'
              : undefined,
            type: 'profile',
            metadata: {
              isInThread: item.isInThread,
              memberId: item.memberId,
              userId: item.userId,
              isFollower: item.isFollower,
              isFollowing: item.isFollowing,
              isJoinedClub: item.isJoinedClub,
            },
          } as ThreadMentionItem)
      ) || ([] as ThreadMentionItem[]),
    [clubId, usersData?.data.items]
  );
};

const useMentionAgents = ({
  keyword,
  clubId,
  inThreadAgentMemberIds,
}: {
  keyword: string;
  clubId?: string;
  inThreadAgentMemberIds: string[];
}) => {
  const { userId: myUserId } = useAuth();
  const { getThreadUser } = useThread();

  const notInThreadAgentsData = useAgentUsers(
    {
      viewSlug: AgentUserViewSlug.ThreadMentionAgentDefault,
      ...combineSearch(
        [`userId:${myUserId}`, 'userId:eq'],
        ['visibility:1', 'visibility:eq'],
        inThreadAgentMemberIds.length > 0 && [
          `agent:agentId:${inThreadAgentMemberIds
            .map((id) => id.replace('agent_', ''))
            .join(',')}`,
          'agent:agentId:nin',
        ]
      ),
      keyword,
      orderBy: 'order',
      sortBy: 'asc',
      limit: MAX_MENTION_ITEMS,
    },
    {
      method: 'POST',
      enable: !!myUserId,
      dedupingInterval: 60000, // prevent query everytime re-mount, so we set 60s cache time for the same key
    }
  );

  const filterAgentWithKeyword = useCallback(
    (agent: ThreadUser) => {
      const k = keyword.toUpperCase();
      return (
        (agent.name || '')?.toUpperCase().includes(k) ||
        (agent.distinctName || '')?.toUpperCase().includes(k)
      );
    },
    [keyword]
  );

  return useMemo(
    () =>
      inThreadAgentMemberIds
        .map((id) => getThreadUser(id))
        .filter(isAgentThreadUser)
        .filter(filterAgentWithKeyword)
        .map(
          (agent) =>
            ({
              display: agent.name || agent.distinctName || '',
              value: agent.distinctName || '',
              icon: {
                type: 'image',
                value: agent.image || '',
              },
              suffixIcon: {
                type: 'icon',
                value: 'ProfileClubAgent',
              },
              type: 'profile',
              description:
                agent.agentClub === clubId ? undefined : 'Not in club',
              metadata: {
                isInThread: true,
                memberId: agent.id,
                agentId: agent.agentId,
              },
            } as ThreadMentionItem)
        )
        .concat(
          notInThreadAgentsData.dataset.map(
            (item) =>
              ({
                display: item.agent.agentName,
                value: item.agent.agentUserName,
                icon: {
                  type: 'image',
                  value: getAgentIconPath(item.agent),
                },
                suffixIcon: {
                  type: 'icon',
                  value: 'ProfileClubAgent',
                },
                type: 'profile',
                description: 'Not in thread',
                metadata: {
                  isInThread: false,
                  memberId: `agent_${item.agent.agentId}`,
                  agentId: item.agent.agentId,
                },
              } as ThreadMentionItem)
          )
        ),
    [
      clubId,
      filterAgentWithKeyword,
      getThreadUser,
      inThreadAgentMemberIds,
      notInThreadAgentsData.dataset,
    ]
  );
};

export default function useThreadMentionItems(threadMemberIds?: string[]) {
  const [queryText, setQueryText] = useState<string | null>(null);
  const { club } = useCurrentIaClub();

  const [inThreadUserMemberIds, inThreadAgentMemberIds] = useMemo(
    () => [
      threadMemberIds?.filter((id) => !id.startsWith('agent_')) || [],
      threadMemberIds?.filter((id) => id.startsWith('agent_')) || [],
    ],
    [threadMemberIds]
  );

  const keyword = queryText || '';

  const mentionUsers = useMentionUsers({
    keyword,
    clubId: club?.clubId,
    inThreadUserMemberIds,
  });
  const mentionAgents = useMentionAgents({
    keyword,
    clubId: club?.clubId,
    inThreadAgentMemberIds,
  });

  return useMemo(() => {
    const buckets: ThreadMentionItem[][] = Array.from({ length: 8 }, () => []);
    [...mentionUsers, ...mentionAgents].forEach((item) => {
      /**
       * 0. In thread followings
       * 1. In thread agent
       * 2. In thread followers
       * 3. In thread others
       * 4. Not in thread followings
       * 5. Not in thread added agent
       * 6. Not in thread followers
       * 7. Not in thread others
       */
      const metadata = item.metadata;
      let bucketIndex = metadata?.isInThread ? 0 : 4;
      if (metadata?.isFollowing) bucketIndex += 0;
      else if (metadata?.agentId) bucketIndex += 1;
      else if (metadata?.isFollower) bucketIndex += 2;
      else bucketIndex += 3;
      buckets[bucketIndex].push(item);
    });

    return {
      dataset: [mentionEveryoneItem, ...buckets.flat()],
      setQueryText,
    };
  }, [mentionAgents, mentionUsers]);
}
