import {
  memo,
  MouseEvent,
  useContext,
  useDeferredValue,
  useEffect,
} from 'react';
import { alpha, Box, PopperProps, Theme, useMediaQuery } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { useSortable } from '@dnd-kit/sortable';
import { Hoverable } from '@front/helper';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionChevronUp as ActionChevronUpIcon,
} from '@front/icon';
import { IconButton } from '@front/ui';
import SideFloatingCheckbox, {
  SideFloatingCheckboxProps,
} from '@lib/ia/src/components/SideFloatingCheckbox';
import SideFloatingMenu, {
  SideFloatingMenuEvent,
} from '@lib/ia/src/components/SideFloatingMenu';
import { SideFloatingMenuProps } from '@lib/ia/src/components/SideFloatingMenu/SideFloatingMenu';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { IaTableCheckboxCellBorder } from '@lib/ia/src/layouts/TableLayout/components/IaTableCellBorder/IaTableCellBorder';
import { IaTableRow } from '@lib/ia/src/layouts/TableLayout/components/IaTableRow';
import {
  IaTableCell,
  IaTableCellBorder,
  IaTableTooltip,
} from '@lib/ia/src/layouts/TableLayout/components/index';
import TableLayoutPaneContext from '@lib/ia/src/layouts/TableLayout/contexts/tableLayoutPaneContext';
import {
  IaRowExpandedEvent,
  IaTableExpandableSettings,
  IaTableSidePeekSettings,
  TableLayoutConfig,
  TableLayoutRow,
} from '@lib/ia/src/layouts/TableLayout/types';

import useIaTablePluginActions, {
  GetPluginCellMap,
} from './IaTablePlugin/useIaTablePluginActions';
import IaSidePeekButton from './IaSidePeekButton';

function getIndentWidth(nestedLevel: number) {
  return nestedLevel * 20 + (nestedLevel - 1) * 8;
}

const styles = {
  menuSx: {
    pr: '8px',
  },
  expandableCell: (nestedLevel: number, isDeepest: boolean, enabled: boolean) =>
    enabled
      ? {
          display: 'grid',
          gridTemplateColumns:
            `${nestedLevel > 0 ? `${getIndentWidth(nestedLevel)}px` : ''}` +
            ' 1fr 24px',
          pr: 1,
          pl: nestedLevel > 0 ? 1 : 0,
        }
      : {},
  nestedRowIndent: {
    display: 'flex',
    gap: 1,
    justifyContent: 'space-between',
  },
  nestedRowIndentItem: {
    width: '20px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '9px',
      width: '2px',
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
  expandWrap: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};

const sideFloatingMenuPopperOptions: PopperProps['popperOptions'] = {
  modifiers: [
    {
      name: 'offset',
      options: {
        /**
         * checkbox is 24px,
         * we use offset 16px for checkbox to click,
         * use menuSx pr 8px to keep this row hover-able,
         * if we use offset 24px, the row is not hover-able when table layout is inside rhs
         */
        offset: [0, 16],
      },
    },
  ],
};

/**
 * When the item is dragged, the hovered event will be swallowed by the sortable event, so there is no way to reset the hovered
 * we need to do this by ourselves
 */
function ResetHoveredWhenDragEnd({
  id,
  resetHovered,
}: {
  id: string;
  resetHovered: () => void;
}) {
  const { isDragging } = useSortable({ id });
  const previousIsDragging = useDeferredValue(isDragging);

  useEffect(() => {
    if (previousIsDragging && !isDragging) {
      resetHovered?.();
    }
  }, [isDragging, previousIsDragging, resetHovered]);
  return null;
}

const IaTableRowContainer = memo(function IaTableRowContainer({
  container,
  checkbox,
  menu,
  row,
  layoutSetting,
  sidePeekSettings,
  expandableSettings,
  isDeepest = false,
  nestedLevel = 0,
}: {
  container: {
    anchorEl: HTMLDivElement | null;
  };
  checkbox: {
    state: SideFloatingCheckboxProps['state'];
    onSelect: () => void;
  };
  menu: {
    anyMenuOpened: boolean;
    onOpen: () => void;
    onClose: () => void;
    onActionClick?: () => void;
    addActions: SideFloatingMenuProps['addActions'];
    isGhostEl?: boolean;
  };
  row: {
    sx?: BoxProps['sx'];
    row: TableLayoutRow;
    columnOrder: string[];
    columnsTooltip?: TableLayoutConfig['table']['columnsTooltip'];
  };
  layoutSetting?: TableLayoutConfig['settings']['layoutSetting'];
  sidePeekSettings?: IaTableSidePeekSettings;
  expandableSettings?: IaTableExpandableSettings;
  isDeepest?: boolean;
  nestedLevel?: number;
}) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const rowSxProps = [
    ...(Array.isArray(row.sx) ? row.sx : [row.sx]),
    ...(Array.isArray(row.row.sx) ? row.row.sx : [row.row.sx]),
  ];

  const hasActions = (row.row.moreActions?.length || 0) > 0;
  const rowClickable = !!row.row.clickAction;
  const rowHoverable = hasActions || rowClickable;
  const showCheckbox = hasActions;

  const horizontalScrolled = useContext(
    TableLayoutPaneContext
  ).horizontalScrolled;

  const { getIaAction } = useIaAction();

  const onExpandAction = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!expandableSettings) return;
    const expandAction = getIaAction<IaRowExpandedEvent>(
      expandableSettings.expandAction
    );
    expandAction?.action?.({ value: !row.row.expanded, row: row.row });
  };
  const { handleHover: handleTriggerPlugin, handleClick: handleExecutePlugin } =
    useIaTablePluginActions();

  const expandableEnabled = !!expandableSettings;

  const handleHover = (
    itemEvent: SideFloatingMenuEvent,
    domEvent: MouseEvent
  ) => {
    if (itemEvent.plugin && domEvent.target) {
      domEvent.stopPropagation();
      const selectedCell = Object.values(row.row.cells).find(
        (c) => c.type === itemEvent.plugin
      ) as GetPluginCellMap[typeof itemEvent.plugin];

      if (selectedCell) {
        handleTriggerPlugin(
          domEvent,
          { plugin: itemEvent.plugin, value: itemEvent.value },
          [{ id: row.row.id, cell: selectedCell }],
          'menu'
        );
      }
    }
  };

  const handlePluginClick = (
    itemEvent: SideFloatingMenuEvent,
    domEvent: MouseEvent
  ) => {
    if (itemEvent.plugin && domEvent.target) {
      domEvent.stopPropagation();
      domEvent.preventDefault();
      const selectedCell = Object.values(row.row.cells).find(
        (c) => c.type === itemEvent.plugin
      ) as GetPluginCellMap[typeof itemEvent.plugin];

      if (selectedCell) {
        handleExecutePlugin(
          { plugin: itemEvent.plugin, value: itemEvent.value },
          [{ id: row.row.id, cell: selectedCell }]
        );
      }
    }
  };
  return (
    <Hoverable>
      {({ hovered, hoveredListeners, resetHovered }) => (
        <Box {...hoveredListeners}>
          {showCheckbox && (
            <SideFloatingCheckbox
              active={hovered && !menu.anyMenuOpened}
              state={checkbox.state}
              onSelect={checkbox.onSelect}
              layoutSetting={layoutSetting}
            />
          )}
          {(hovered || !mdUp) && (
            <IaSidePeekButton
              row={row.row}
              sidePeekSettings={sidePeekSettings}
              layoutSetting={layoutSetting}
            />
          )}
          {!menu.isGhostEl && ( // if we don't skip ghost element, it will break the drag behavior after dragged one time
            <>
              <ResetHoveredWhenDragEnd
                id={row.row.id}
                resetHovered={resetHovered}
              />
              <SideFloatingMenu
                item={row.row}
                anchorEl={container.anchorEl}
                active={hovered && !menu.anyMenuOpened && !horizontalScrolled}
                onOpenMenu={menu.onOpen}
                onCloseMenu={() => {
                  resetHovered();
                  menu.onClose();
                }}
                addActions={menu.addActions}
                menuSx={styles.menuSx}
                menuPopperOptions={sideFloatingMenuPopperOptions}
                onActionClick={(itemEvent, domEvent) =>
                  itemEvent.plugin
                    ? handlePluginClick(itemEvent, domEvent)
                    : menu.onActionClick
                }
                onActionHover={handleHover}
              />
            </>
          )}
          <IaTableRow
            key={row.row.id}
            sx={[...rowSxProps]}
            hovered={rowHoverable && hovered && !menu.anyMenuOpened}
            active={['checked', 'partial'].includes(checkbox.state)}
            row={row.row}
            showCheckboxBackground={showCheckbox}
            sidePeekEnabled={sidePeekSettings?.enabled}
          >
            {showCheckbox && (
              <IaTableCheckboxCellBorder layoutSetting={layoutSetting} />
            )}

            {row.columnOrder.map((columnKey, index) => {
              const expandIconVisible =
                expandableEnabled && index === 0 && !isDeepest;
              const shouldIndent = expandableEnabled && nestedLevel > 0;
              return (
                <IaTableCellBorder
                  key={columnKey}
                  columnOrder={row.columnOrder}
                  cells={row.row.cells}
                  columnKey={columnKey}
                  layoutSetting={layoutSetting}
                  rowId={row.row.id}
                  sx={[
                    index === 0 &&
                      styles.expandableCell(
                        nestedLevel,
                        isDeepest,
                        expandableEnabled
                      ),
                  ]}
                >
                  <IaTableTooltip
                    tooltip={
                      row.row.cells[columnKey].tooltip ||
                      row.columnsTooltip?.[columnKey]
                    }
                  >
                    {shouldIndent && (
                      <Box sx={styles.nestedRowIndent}>
                        {[...Array(nestedLevel)].map((_, i) => (
                          <Box key={i} sx={styles.nestedRowIndentItem} />
                        ))}
                      </Box>
                    )}
                    <IaTableCell
                      row={row.row}
                      columnKey={columnKey}
                      cell={row.row.cells[columnKey]}
                    />
                    {expandIconVisible && (
                      <Box sx={styles.expandWrap}>
                        <IconButton onClick={onExpandAction} customSize={24}>
                          {row.row.expanded && (
                            <ActionChevronDownIcon width={16} height={16} />
                          )}
                          {!row.row.expanded && (
                            <ActionChevronUpIcon width={16} height={16} />
                          )}
                        </IconButton>
                      </Box>
                    )}
                  </IaTableTooltip>
                </IaTableCellBorder>
              );
            })}
          </IaTableRow>
        </Box>
      )}
    </Hoverable>
  );
});

export default IaTableRowContainer;
