import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  alpha,
  ButtonBase,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { ResponsiveMenu } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { useIaItemStatus } from '@lib/ia/src/core/IaItemStatus/useIaItemStatus';
import useTableSelectionContext from '@lib/ia/src/layouts/TableLayout/hooks/useTableSelectionContext';

import {
  IaSelectCellValueChangedEvent,
  TableLayoutRow,
  TableLayoutSelectCell,
  TableLayoutSelectCellStatus,
  TableLayoutSelectOption,
} from '../../types';

export type IaSelectCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutSelectCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    gap: 1,
    height: '100%',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '4px',
    px: '8px',
    py: '1px',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectOpen: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
  },
  selectDisabled: {
    opacity: 0.5,
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  circleOrSquare: {
    width: '14px',
    height: '14px',
    color: 'background.darker',
    backgroundColor: 'text.primary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '8px',
    fontWeight: 500,
  },
  circle: {
    borderRadius: '50%',
  },
  square: {
    borderRadius: '4px',
  },
};
type Option = TableLayoutSelectOption;

function SelectIcon({ icon }: { icon: Option['icon'] }) {
  if (!icon) return null;

  if (icon.type === 'icon') {
    return <Icon name={icon.value} width={16} height={16} />;
  }
  if (icon.type === 'circle') {
    return <Box sx={[styles.circleOrSquare, styles.circle]}>{icon.value}</Box>;
  }
  if (icon.type === 'square') {
    return <Box sx={[styles.circleOrSquare, styles.square]}>{icon.value}</Box>;
  }
  return null;
}

function Select({
  row,
  columnKey,
  options,
  selectedOption,
  onOptionClick,
  disabled,
}: {
  row: TableLayoutRow;
  columnKey: string;
  options: Option[];
  selectedOption: Option;
  onOptionClick: (option: Option) => void;
  disabled?: boolean;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const { getCellSelectedState } = useTableSelectionContext();
  const selectedState = getCellSelectedState(row.id, columnKey);

  useEffect(() => {
    const open = selectedState === 'active';
    if (open !== menuOpen) {
      setMenuOpen(open);
    }
  }, [selectedState, menuOpen]);

  const handleOptionClick = (option: Option) => {
    onOptionClick(option);
    setMenuOpen(false);
  };

  return (
    <>
      <ButtonBase
        ref={ref}
        sx={[
          styles.select,
          menuOpen && styles.selectOpen,
          !!disabled && styles.selectDisabled,
        ]}
        onClick={() => setMenuOpen(true)}
        disabled={disabled}
      >
        <SelectIcon icon={selectedOption.icon} />
        <Typography variant="body1" sx={styles.label}>
          {selectedOption.label}
        </Typography>
      </ButtonBase>
      <ResponsiveMenu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        menuProps={{
          anchorEl: ref.current,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: -8,
            horizontal: 8,
          },
        }}
        sheetProps={{
          defaultContentHeight: options.length * 45,
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => handleOptionClick(option)}
          >
            {option.icon && (
              <ListItemIcon>
                <SelectIcon icon={option.icon} />
              </ListItemIcon>
            )}
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </ResponsiveMenu>
    </>
  );
}
export default function IaSelectCell({
  row,
  cell,
  columnKey,
}: IaSelectCellProps) {
  const { options, optionClickAction, subOptionClickAction, disabled } = cell;
  const { getIaAction } = useIaAction();
  const { getItemStatus } = useIaItemStatus<TableLayoutSelectCellStatus>();

  const selectedOption = options.find(
    (o) => getItemStatus?.({ option: o, row, cell, columnKey }).selected
  );
  const selectedSubOption = selectedOption?.subOptions?.find(
    (o) => getItemStatus?.({ option: o, row, cell, columnKey }).selected
  );

  const selectableOptions = useMemo(
    () => options.filter(({ selectable = true }) => selectable),
    [options]
  );

  const handleOptionClick = (option: Option) => {
    getIaAction<IaSelectCellValueChangedEvent>(optionClickAction)?.action({
      option,
      row,
      cell,
      columnKey,
    });
  };

  const handleSubOptionClick = (option: Option) => {
    getIaAction<IaSelectCellValueChangedEvent>(subOptionClickAction)?.action({
      option,
      row,
      cell,
      columnKey,
    });
  };

  return (
    <Box sx={styles.root}>
      {selectedOption && (
        <Select
          row={row}
          columnKey={columnKey}
          options={selectableOptions}
          disabled={disabled}
          selectedOption={selectedOption}
          onOptionClick={handleOptionClick}
        />
      )}
      {selectedOption?.subOptions && selectedSubOption && (
        <Select
          row={row}
          columnKey={columnKey}
          options={selectedOption.subOptions}
          disabled={disabled}
          selectedOption={selectedSubOption}
          onOptionClick={handleSubOptionClick}
        />
      )}
    </Box>
  );
}
