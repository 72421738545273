import { createContext, ReactNode, useMemo } from 'react';
import { AnswerFormatType } from '@lib/web/apis';

import { PracticeQuestionContextValue } from '../types';

const initialValue: PracticeQuestionContextValue = {
  id: '',
  question: '',
  tpq: 0,
  setId: 0,
  details: [],
  answerFormatType: AnswerFormatType.MultipleChoice,
  creatorId: '',
  isDynamicDifficulty: false,
  staticDifficulty: null,
  dynamicDifficulty: null,
  creatorQuestionInfo: {} as CreatorQuestionInfo,
  contentEnricher: (content) => content,
};

export const PracticeQuestionContext =
  createContext<PracticeQuestionContextValue>(initialValue);

export type PracticeQuestionContextProviderProps = {
  children: ReactNode;
} & { question: PracticeQuestionContextValue };

export const PracticeQuestionContextProvider = ({
  children,
  question,
}: PracticeQuestionContextProviderProps) => {
  const memoizedValue = useMemo(() => {
    return {
      ...question,
    };
  }, [question]);

  return (
    <PracticeQuestionContext.Provider value={memoizedValue}>
      {children}
    </PracticeQuestionContext.Provider>
  );
};
