import React from 'react';

export default function ProfileOfficial({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4529 2.52128C12.2137 2.24598 11.7863 2.24598 11.5471 2.52128L9.65311 4.7011C9.51734 4.85736 9.31063 4.9326 9.10619 4.90017L6.25416 4.44775C5.89397 4.39061 5.56652 4.66537 5.56024 5.03001L5.51055 7.91727C5.50698 8.12424 5.39699 8.31475 5.21953 8.42132L2.74394 9.908C2.43129 10.0958 2.35706 10.5167 2.58664 10.8001L4.40447 13.0438C4.53477 13.2046 4.57297 13.4213 4.50554 13.617L3.56474 16.3471C3.44592 16.6919 3.65964 17.0621 4.01766 17.1316L6.85243 17.6819C7.05562 17.7214 7.22415 17.8628 7.29828 18.056L8.33249 20.7522C8.4631 21.0927 8.86477 21.2389 9.18369 21.062L11.709 19.6614C11.89 19.561 12.11 19.561 12.291 19.6614L14.8163 21.062C15.1352 21.2389 15.5369 21.0927 15.6675 20.7522L16.7017 18.056C16.7759 17.8628 16.9444 17.7214 17.1476 17.6819L19.9824 17.1316C20.3404 17.0621 20.5541 16.6919 20.4353 16.3471L19.4945 13.617C19.427 13.4213 19.4652 13.2046 19.5955 13.0438L21.4134 10.8001C21.6429 10.5167 21.5687 10.0958 21.2561 9.908L18.7805 8.42132C18.603 8.31475 18.493 8.12424 18.4895 7.91727L18.4398 5.03001C18.4335 4.66537 18.106 4.39061 17.7458 4.44775L14.8938 4.90017C14.6894 4.9326 14.4827 4.85736 14.3469 4.7011L12.4529 2.52128ZM15.2075 8.57356C15.6181 8.14165 16.2834 8.14222 16.6933 8.57482C17.1033 9.00743 17.1028 9.70825 16.6921 10.1402L11.0718 16.0514C10.6695 16.4746 10.0228 16.4826 9.61119 16.076C9.54044 16.0308 9.47366 15.976 9.41254 15.9116L7.30828 13.6952C6.89793 13.2629 6.89788 12.5621 7.30817 12.1298C7.71846 11.6975 8.38372 11.6975 8.79407 12.1297L10.3091 13.7255L15.2075 8.57356Z"
        fill="currentColor"
      />
    </svg>
  );
}
