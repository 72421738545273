import { useEffect } from 'react';
import { useBaseLayout } from '@front/ui';

export default function useAudioQuizPanelControl(panelKey: string) {
  const { isTargetPanelOpened } = useBaseLayout();

  const opened = isTargetPanelOpened(panelKey);

  useEffect(() => {
    if (!opened) {
      const allAudios = document.querySelectorAll(
        '.base-layout-right-panel audio'
      );

      allAudios.forEach((audio) => {
        (audio as HTMLAudioElement).pause();
      });
    }
  }, [opened]);
}
