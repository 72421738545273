export const enum VariableType {
  Text = 1,
  Number = 2,
  NumberFormula = 3,
}

export const enum VariableSubType {
  Unknown = 0,
  Text = 11,
  NumberInteger = 21,
  NumberDecimal = 22,
  NumberPrime = 23,
  NumberFactor = 24,
  NumberFree = 25,
  NumberFormula = 31,
  NestedNumberFormula = 32,
}

export type VariableOption = {
  title: string;
  name: string;
  type: VariableType;
  subType: VariableSubType;
};
