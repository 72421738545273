import { useHotkeys } from 'react-hotkeys-hook';
import { Block, BlockNoteEditor, BlockSchema } from '@blocknote/core';

import { useGeneralBlockMoreActions } from '../hooks/useGeneralBlockMoreActions';

export type BlockMoreActionKeyboardShortcutProps<BSchema extends BlockSchema> =
  {
    editor: BlockNoteEditor<BSchema>;
    block: Block<BSchema>;
    onItemClick?: () => void;
  };

export default function BlockMoreActionKeyboardShortcut<
  BSchema extends BlockSchema
>({
  editor,
  block,
  onItemClick,
}: BlockMoreActionKeyboardShortcutProps<BSchema>) {
  const {
    cutBlock,
    copyBlockToClipboard,
    pasteClipboardBlockToBelow,
    duplicateBlock,
    deleteBlock,
  } = useGeneralBlockMoreActions({ editor, block });

  useHotkeys('meta+x', (ev) => {
    ev.preventDefault();
    onItemClick?.();
    cutBlock();
  });

  useHotkeys('meta+c', (ev) => {
    ev.preventDefault();
    onItemClick?.();
    copyBlockToClipboard();
  });

  useHotkeys('meta+v', (ev) => {
    ev.preventDefault();
    onItemClick?.();
    void pasteClipboardBlockToBelow();
  });

  useHotkeys('meta+d', (ev) => {
    ev.preventDefault();
    onItemClick?.();
    duplicateBlock();
  });

  useHotkeys('delete', (ev) => {
    ev.preventDefault();
    onItemClick?.();
    deleteBlock();
  });

  return null;
}
