import React from 'react';

export default function AnalyticsMyPath({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.6709 6.10157C21.9981 6.29765 22.0981 6.71194 21.8945 7.02691C19.0566 11.4164 16.3698 14.605 13.4849 16.5067L13.4778 16.5113L13.4707 16.5158C13.418 16.5487 13.3654 16.5812 13.3126 16.6134C15.8134 15.9258 18.1487 14.8353 20.9314 13.1541C21.2578 12.9569 21.6884 13.0519 21.8932 13.3661C22.098 13.6804 21.9994 14.095 21.673 14.2922C18.5748 16.164 15.9464 17.3644 13.0134 18.0826C10.3576 18.7328 7.48376 18.9797 3.8303 18.9987C3.70114 18.9998 3.57155 19.0002 3.44153 18.9999C3.19553 19.0001 2.94603 18.9993 2.69284 18.9976C2.30811 18.9951 1.99828 18.6931 2.00001 18.3229C2.00001 18.3223 2.00001 18.3218 2.00002 18.3212C2.00269 17.9502 2.3172 17.6515 2.70251 17.6541C2.70608 17.6541 2.70965 17.6542 2.71322 17.6542C2.95995 17.6558 3.20278 17.6565 3.44189 17.6563C3.56996 17.6562 3.69697 17.6558 3.82293 17.6552C7.12404 17.6262 9.97898 17.0946 12.7037 15.3934C15.3428 13.6514 17.9037 10.6573 20.71 6.31684C20.9136 6.00187 21.3438 5.90549 21.6709 6.10157Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
