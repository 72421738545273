import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { Key } from 'swr';
import { getSentryTraceId } from '@front/helper';
import { v4 } from 'uuid';

import { authKey } from '../hooks';

declare module 'axios' {
  export interface AxiosRequestConfig {
    _withPublicAccessKey?: boolean;
    _publicAccessKey?: string;
    _token?: string;
    _sid?: string;
  }

  export interface AxiosHeaders {
    PublicAccessKey?: string;
    sid?: string;
    coreSid?: string;
    'Aha-Request-Id'?: string;
    'Aha-Trace-Id'?: string;
  }
}

const client = { token: '', publicAccessKey: '', sid: '' };

const instance = axios.create({
  baseURL: process.env.API_URL,
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  const { token, sid, publicAccessKey } = client;
  const urlSid = sid || config._sid;
  const requestId = v4();
  const traceId = getSentryTraceId();

  if (urlSid && config.url !== authKey) {
    const url = config.url as string;

    // The beginning of the url string of 'v1', 'v2'... => sid
    // The beginning of the url string of 'notification', 'auth'... => coreSid
    const sidQueryName = /^\/?v[0-9]+(?:\/[a-zA-Z0-9-]+)*(?:\?.*)?$/.test(url)
      ? 'sid'
      : 'coreSid';

    config.headers = {
      ...config.headers,
      [sidQueryName]: urlSid,
      'Aha-Request-Id': requestId,
      'Aha-Trace-Id': traceId,
    } as AxiosRequestHeaders;
  }

  if (token || config._token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token || config._token}`,
      'Aha-Request-Id': requestId,
      'Aha-Trace-Id': traceId,
    } as AxiosRequestHeaders;
  }

  if (publicAccessKey && config._withPublicAccessKey) {
    config.headers = {
      ...config.headers,
      PublicAccessKey: publicAccessKey,
      'Aha-Request-Id': requestId,
      'Aha-Trace-Id': traceId,
    } as AxiosRequestHeaders;
  }

  if (config._publicAccessKey) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      PublicAccessKey: config._publicAccessKey,
      'Aha-Request-Id': requestId,
      'Aha-Trace-Id': traceId,
    } as AxiosRequestHeaders;
  }

  return config;
});

export const fetcher = (key: Key, config?: AxiosRequestConfig) => {
  const url = Array.isArray(key) ? key[0] : key;

  if (config?.method === 'POST') {
    const [urlString, queryString] = url.split('?');
    const body =
      config?.data ??
      JSON.parse(
        '{"' +
          decodeURIComponent(queryString)
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );

    return instance
      .post(urlString, body, config)
      .then((response) => response.data);
  }
  return instance.get(url, config).then((response) => response.data);
};

export const setClientToken = (token: string) => {
  client.token = token || client.token;
};

export const setPublicAccessKey = (publicAccessKey: string) => {
  client.publicAccessKey = publicAccessKey;
};

export const setSid = (sid: string) => {
  client.sid = sid;
};

export default instance;
