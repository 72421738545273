import { ReactNode } from 'react';
import { alpha, BoxProps, ButtonBase, Theme } from '@mui/material';

type BlockTagProps = {
  icon: ReactNode;
  children?: ReactNode;
  active?: boolean;
  error?: boolean;
  disabled?: boolean;
  onClick?: BoxProps['onClick'];
  onMouseEnter?: BoxProps['onMouseEnter'];
  onMouseLeave?: BoxProps['onMouseLeave'];
};
const styles = {
  root: {
    typography: 'body1',
    color: (theme: Theme) => alpha(theme.palette.background.darker, 0.5),
    bgcolor: (theme: Theme) => alpha(theme.palette.background.darker, 0.1),
    display: 'inline-flex',
    gap: 0.5,
    height: 22,
    alignItems: 'center',
    px: 0.5,
    borderRadius: 1,
    cursor: 'pointer',
    '& svg': {
      width: 16,
      height: 16,
    },
    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.background.darker, 0.3),
    },
  },
  active: {
    bgcolor: (theme: Theme) => alpha(theme.palette.background.darker, 0.3),
  },
  error: {
    color: 'error.dark',
    bgcolor: (theme: Theme) => alpha(theme.palette.error.dark, 0.3),
  },
};

export default function BlockTag({
  icon,
  children,
  active = false,
  error = false,
  disabled = false,
  ...rest
}: BlockTagProps) {
  return (
    <ButtonBase
      sx={[styles.root, active && styles.active, error && styles.error]}
      disabled={disabled}
      component="div"
      disableRipple
      disableTouchRipple
      {...rest}
    >
      {icon}
      {children}
    </ButtonBase>
  );
}
