import { ComposerRenderer } from '@lib/web/composer';
import { ComposerRendererProps } from '@lib/web/composer/TextComposer/ComposerRenderer';
import { editorBlockHtmlStyles } from '@lib/web/editor/EditorTextComposer/config/editorComposerBlockStyles';

export type EditorComposerRendererProps = Omit<
  ComposerRendererProps,
  'htmlStyles'
>;

export default function EditorComposerRenderer({
  sx,
  ...rest
}: EditorComposerRendererProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <ComposerRenderer
      sx={sxProps}
      htmlStyles={editorBlockHtmlStyles}
      {...rest}
    />
  );
}
