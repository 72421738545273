import { useCallback, useContext } from 'react';
import { useQueueWorker } from '@front/helper';
import {
  CreatorQuestionDetailContext,
  useCreatorQuestionCorrectAnswerData,
  useCreatorQuestionListData,
} from '@lib/web/editor';
import { callWithToast } from '@lib/web/utils';

const DEBOUNCE_TIME = 500;

export const useCreatorQuestionCorrectAnswerHandle = (questionId: string) => {
  const { reloadCorrectAnswer } =
    useCreatorQuestionCorrectAnswerData(questionId);

  const { reloadQuestions } = useCreatorQuestionListData();

  const { addTask } = useQueueWorker();
  const reloadDataInEditorPage = useCallback(() => {
    // in editor page, we have many data relies on question list data, so when data update, we need to reload them
    void reloadQuestions();
  }, [reloadQuestions]);

  const { setCorrectAnswer } = useContext(CreatorQuestionDetailContext);

  const handleCorrectAnswerChange = useCallback(
    async ({
      params,
      optimisticData,
      debounce = false,
    }: {
      params: SetCreatorQuestionCorrectAnswerReq;
      optimisticData?: GetCreatorQuestionCorrectAnswerRes;
      debounce?: boolean;
    }) => {
      const options = optimisticData ? { optimisticData } : {};

      let finishTaskPromise = null;

      if (debounce) {
        finishTaskPromise = new Promise<void>((resolve, reject) => {
          addTask({
            scope: 'handleCorrectAnswerChange',
            taskKey: params.id,
            task: async () => {
              const [res] = await callWithToast(
                () => setCorrectAnswer(params),
                {
                  showLoading: false,
                }
              );

              if (res) {
                resolve();
                reloadDataInEditorPage();
                return;
              }

              reject();
            },
            debounceTime: DEBOUNCE_TIME,
            shouldInterrupt: true,
          });
        });
        void reloadCorrectAnswer(finishTaskPromise, options);
      } else {
        await reloadCorrectAnswer(
          callWithToast(() => setCorrectAnswer(params), {
            showLoading: false,
          }),
          options
        );
        reloadDataInEditorPage();
      }
    },
    [addTask, reloadCorrectAnswer, reloadDataInEditorPage, setCorrectAnswer]
  );

  return {
    handleCorrectAnswerChange,
  };
};
