export default function MathSuperscriptAndSubscript({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40661 15.125H12.1686L13.4892 18.7819H15.8901L10.8231 5.00024H8.79865L3.75 18.7819H6.16415L7.40661 15.125ZM9.7812 8.32899L8.14784 13.0145H11.4292L9.7812 8.32899Z"
        fill="currentColor"
      />
      <path
        d="M20.25 3L18.4295 5.17969L20.2392 7.5H18.2787L17.4816 6.28711C17.417 6.19043 17.3398 6.0542 17.25 5.87842H17.2285C17.1746 6.00146 17.101 6.1333 17.0076 6.27393L16.2051 7.5H14.25L16.1136 5.25879L14.3631 3H16.3344L17.1207 4.26123C17.2249 4.42822 17.2985 4.56885 17.3416 4.68311H17.3631C17.4206 4.54541 17.4978 4.40186 17.5947 4.25244L18.3972 3H20.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
