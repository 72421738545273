import { ReactNode, useState } from 'react';

import LineNumberWrapContext, {
  LineNumberWrapValue,
} from './LineNumberWrapContext';

type LineNumberWrapProviderProps = {
  children: ReactNode;
  initialValue?: LineNumberWrapValue;
};
export default function LineNumberWrapProvider({
  initialValue = [],
  children,
}: LineNumberWrapProviderProps) {
  const providerValue = useState<LineNumberWrapValue>(initialValue);

  return (
    <LineNumberWrapContext.Provider value={providerValue}>
      {children}
    </LineNumberWrapContext.Provider>
  );
}
