import { alpha, Box, BoxProps, useTheme } from '@mui/material';

export type ProgressLoadingProps = {
  size?: number;
  progress?: number;
  strokeWidth?: number;
  sx?: BoxProps['sx'];
  onAnimated?: (progress: number) => void;
};
export default function ProgressLoading({
  size = 24,
  progress = 0,
  strokeWidth = 4,
  onAnimated,
  sx,
}: ProgressLoadingProps) {
  const theme = useTheme();
  const ratio = progress ? progress / 100 : 0;
  const circleSize = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * circleSize;
  const circleStyle = {
    strokeDasharray: circumference.toFixed(3),
    strokeDashoffset: `${((1 - ratio) * circumference).toFixed(3)}px`,
  };

  const handleTransitionEnd = () => {
    onAnimated?.(progress);
  };

  return (
    <Box
      sx={sx}
      component="svg"
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      height={size}
    >
      <g style={{ transform: 'rotate(-90deg)', transformOrigin: 'center' }}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={circleSize}
          stroke={alpha(theme.palette.text.primary, 0.05)}
          fill="none"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={circleSize}
          stroke={alpha(theme.palette.text.primary, 0.3)}
          {...circleStyle}
          style={{
            transition: progress ? 'stroke-dashoffset 1s ease-out' : 'unset',
          }}
          fill="none"
          strokeWidth={strokeWidth}
          onTransitionEnd={handleTransitionEnd}
        />
      </g>
    </Box>
  );
}
