import { useRef } from 'react';

/**
 * This hook use useRef to store the latest value
 * most cases you don't need to use this hook
 * this is for solve the problem of "EffectEvent"
 * currently this "EffectEvent" feature haven't released yet
 * after it released, we might not need this hook
 */
export default function useLatestValueRef<T>(value: T) {
  const ref = useRef<T>(value);
  ref.current = value;
  return ref;
}
