export const enum MemberSearchCategory {
  NoSelect = 0,
  Followers = 1,
  Followings = 2,
  SameDreamSchool = 3,
  SameTargetScore = 4,
  SameCountry = 5,
  SameHighSchool = 6,
  SameTotalScore = 7,
  TopScore = 8,
}
