import { Box, Skeleton as MuiSkeleton } from '@mui/material';
import { MaskIcon } from '@front/ui';

const styles = {
  root: {
    px: '20px',
    py: 1,
    display: 'grid',
    gridTemplateColumns: '24px 1fr 36px',
    gap: 1,
  },
  avatar: {
    width: 24,
    height: 24,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
};

export default function Skeleton() {
  return (
    <Box sx={styles.root}>
      <MaskIcon sx={styles.avatar}>
        <MuiSkeleton width={24} height={24} variant="rounded" />
      </MaskIcon>
      <Box sx={styles.content}>
        <MuiSkeleton width="100%" height={17} variant="rounded" />
        <MuiSkeleton width="50%" height={17} variant="rounded" />
      </Box>
      <MuiSkeleton width={36} height={17} variant="rounded" />
    </Box>
  );
}
