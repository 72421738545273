import { useMemo } from 'react';
import { apis } from '@lib/web/apis';
import { callWithToast } from '@lib/web/utils';
import { v4 } from 'uuid';

import { useEditorRubricData } from './useEditorRubricData';

const generateTempId = () => `temp-${v4()}`;

export const useEditorRubricCriteriaApis = (questionId: string) => {
  const { rubric, mutateRubric } = useEditorRubricData(questionId);

  return useMemo(() => {
    return {
      addNewCriteria: ({
        position,
        anchorId,
      }: {
        position?: 'above' | 'below';
        anchorId?: string;
      } = {}) => {
        if (!rubric) return;

        const newCriteria = {
          description: 'Untitled',
          score: 1,
        };
        mutateRubric(
          callWithToast(
            apis.editor.addCreatorQuestionCriteria({
              rubricId: rubric.id,
              ...newCriteria,
              ...(position === 'above' && anchorId
                ? {
                    beforeCriteriaId: anchorId,
                  }
                : {}),
              ...(position === 'below' && anchorId
                ? {
                    afterCriteriaId: anchorId,
                  }
                : {}),
            }),
            {
              showLoading: false,
            }
          ),
          {
            optimisticData: {
              ...rubric,
              criteria:
                position && anchorId
                  ? rubric.criteria.reduce((acc, c) => {
                      if (c.id === anchorId) {
                        const newCrit = {
                          id: generateTempId(),
                          ...newCriteria,
                          order:
                            position === 'above'
                              ? c.order - 0.001
                              : c.order + 0.001,
                          descriptors: [],
                        };
                        return [
                          ...acc,
                          ...(position === 'above'
                            ? [newCrit, c]
                            : [c, newCrit]),
                        ];
                      }
                      acc.push(c);
                      return acc;
                    }, [] as typeof rubric.criteria)
                  : [
                      ...rubric.criteria,
                      {
                        id: generateTempId(),
                        ...newCriteria,
                        order: rubric.criteria.length,
                        descriptors: [],
                      },
                    ],
            },
          }
        );
      },
      updateCriteriaDescription: ({
        criteriaId,
        value,
      }: {
        criteriaId?: string;
        value: string;
      }) => {
        if (!rubric) return;
        const criteria = rubric.criteria.find((c) => c.id === criteriaId);
        if (!criteria || criteria.description === value) return;

        return mutateRubric(
          callWithToast(
            apis.editor.updateCreatorQuestionCriteria({
              id: criteria.id,
              description: value || 'Untitled',
            }),
            {
              showLoading: false,
            }
          ),
          {
            optimisticData: {
              ...rubric,
              criteria: rubric.criteria.map((c) =>
                c.id === criteria.id
                  ? {
                      ...c,
                      description: value,
                    }
                  : c
              ),
            },
          }
        );
      },
      updateCriteriaScore: ({
        criteriaId,
        value,
      }: {
        criteriaId?: string;
        value: number;
      }) => {
        if (!rubric) return;
        const criteria = rubric.criteria.find((c) => c.id === criteriaId);
        if (!criteria || criteria.score === value) return;

        mutateRubric(
          callWithToast(
            apis.editor.updateCreatorQuestionCriteria({
              id: criteria.id,
              score: value,
            }),
            {
              showLoading: false,
            }
          ),
          {
            optimisticData: {
              ...rubric,
              criteria: rubric.criteria.map((c) =>
                c.id === criteria.id
                  ? {
                      ...c,
                      score: value,
                    }
                  : c
              ),
            },
          }
        );
      },
      deleteCriteria: (ids: string[]) => {
        if (!rubric || ids.length === 0) return;

        return mutateRubric(
          callWithToast(apis.editor.deleteCreatorQuestionCriteria(ids), {
            showLoading: false,
          }),
          {
            optimisticData: {
              ...rubric,
              criteria: rubric.criteria.filter((c) => !ids.includes(c.id)),
            },
          }
        );
      },
      duplicateCriteria: (id: string) => {
        if (!rubric) return;

        const criteria = rubric.criteria.find((c) => c.id === id);
        if (!criteria) return;

        return mutateRubric(
          callWithToast(
            apis.editor.duplicateCreatorQuestionCriteria({
              id: criteria.id,
              rubricId: rubric.id,
            }),
            {
              showLoading: false,
            }
          ),
          {
            optimisticData: {
              ...rubric,
              criteria: [
                ...rubric.criteria,
                {
                  ...criteria,
                  id: generateTempId(),
                  order: rubric.criteria.length,
                },
              ],
            },
          }
        );
      },
    };
  }, [rubric, mutateRubric]);
};
