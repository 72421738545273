import { useCallback, useContext } from 'react';
import { PassageLineNumType } from '@lib/web/apis';
import { call } from '@lib/web/utils';

import { EditorBlockTypes } from '../configs';
import { CreatorQuestionDetailContext } from '../contexts';

import { useCreatorQuestionInfoData } from './useCreatorQuestionInfoData';
import useDisableEditAnswerFormat from './useDisableEditAnswerFormat';

export default function useConvertBlockType(questionId: string) {
  const { updateCreatorQuestion } = useContext(CreatorQuestionDetailContext);
  const { infoData, reloadInfo } = useCreatorQuestionInfoData(questionId);
  const isShowPassageLineNum = infoData?.isShowPassageLineNum;

  const { disableEditAnswerFormat } = useDisableEditAnswerFormat(questionId);

  const enablePassage = useCallback(async () => {
    await call(
      updateCreatorQuestion({
        id: questionId,
        isShowPassageLineNum: true,
        passageLineNumType: PassageLineNumType.EveryLine,
      })
    );
    await reloadInfo();
  }, [questionId, reloadInfo, updateCreatorQuestion]);
  return useCallback(
    (type: string) => {
      if (
        type === EditorBlockTypes.InlineLineAnchor &&
        isShowPassageLineNum === false &&
        !disableEditAnswerFormat
      ) {
        enablePassage();
      }
    },
    [isShowPassageLineNum, disableEditAnswerFormat, enablePassage]
  );
}
