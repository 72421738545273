import { createContext, ReactNode, useMemo } from 'react';

import { PracticeQuestionAnswerContextValue } from '../types';

const initialValue: PracticeQuestionAnswerContextValue = {
  answers: [],
  eliminatedAnswers: [],
};
export const PracticeQuestionAnswerContext =
  createContext<PracticeQuestionAnswerContextValue>(initialValue);

export type PracticeQuestionAnswerContextProviderProps = {
  children: ReactNode;
} & PracticeQuestionAnswerContextValue;

export const PracticeQuestionAnswerContextProvider = ({
  children,
  answers,
  eliminatedAnswers,
}: PracticeQuestionAnswerContextProviderProps) => {
  const memoizedValue = useMemo(() => {
    return { answers, eliminatedAnswers };
  }, [answers, eliminatedAnswers]);

  return (
    <PracticeQuestionAnswerContext.Provider value={memoizedValue}>
      {children}
    </PracticeQuestionAnswerContext.Provider>
  );
};
