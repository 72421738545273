import React, { forwardRef, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    padding: '3.5px 12px 3.5px 12px',

    '.simplebar-scrollable-y &': {
      mr: '12px',
    },

    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  selectedRoot: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    svg: { width: 16, height: 16 },
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  suffix: {
    ml: 'auto',
    svg: { width: 12, height: 12 },
  },
};

export type PromptItemProps = {
  icon: ReactNode;
  text: string;
  suffix?: ReactNode;
  onClick?: (text: string) => void;
  selected?: boolean;
};

const PromptItem = forwardRef(
  ({ icon, text, suffix, onClick, selected }: PromptItemProps, ref) => {
    return (
      <Box
        sx={[styles.root, !!selected && styles.selectedRoot]}
        onClick={() => onClick?.(text)}
        ref={ref}
        className="thread-prompt-item"
      >
        <Typography variant="body2" sx={styles.icon}>
          {icon}
        </Typography>
        <Typography variant="body2" sx={styles.ellipsis}>
          {text}
        </Typography>
        <Typography variant="body2" sx={styles.suffix}>
          {suffix}
        </Typography>
      </Box>
    );
  }
);

export default PromptItem;
