import {
  ChallengeCategoryStatus,
  ChallengeFilter,
  ExamMode,
} from '@lib/web/apis';
import { format } from 'date-fns';

import generateHook, { generateInfiniteHook } from './swr.helper';

export const useChallenge = (challengeId: NullableString) =>
  generateHook<GetChallengeRes>(
    challengeId ? `/v2/challenge/${challengeId}` : null,
    {
      auth: true,
      revalidateOnFocus: false,
    }
  );

export const useChallengers = (challengeId?: NullableString) =>
  generateHook<string>(challengeId ? `/v2/challenge/${challengeId}/id` : null, {
    auth: true,
    revalidateOnFocus: false,
    paginate: true,
  });

export const getChallengeRankKey = (challengeId?: NullableString) => {
  return challengeId ? `v2/challenge/${challengeId}/rank` : null;
};

export const useChallengeRank = (challengeId?: NullableString) =>
  generateHook<GetChallengeRankingRes>(getChallengeRankKey(challengeId), {
    auth: true,
    paginate: true,
  });

export const useChallengeDetail = (challengeId: NullableString) =>
  generateHook<GetChallengeDetailRes>(
    challengeId ? `/v2/challenge/${challengeId}/detail` : null,
    {
      auth: true,
      paginate: true,
      revalidateOnFocus: false,
    }
  );

const getChallengeListApiKey = (
  sectionId: NullableString,
  status: ChallengeCategoryStatus,
  mode: ExamMode,
  filter: ChallengeFilter,
  customDate?: Date,
  page = 1,
  pageSize = 10
) => {
  if (!sectionId) return undefined;
  const params = new URLSearchParams();

  params.set('sectionId', sectionId);
  params.set('status', `${status}`);
  params.set('mode', `${mode}`);
  params.set('filter', `${filter}`);
  params.set('page', `${page}`);
  params.set('limit', `${pageSize}`);
  if (customDate) {
    params.set('customDate', format(customDate, 'yyyy-MM-dd'));
  }
  return `/v2/challenge/me?${params.toString()}`;
};

type ChallengeAllListParams = {
  sectionId: NullableString;
  status: ChallengeCategoryStatus;
  mode: ExamMode;
  filter: ChallengeFilter;
  customDate?: Date;
  pageSize?: number;
};

export const useChallengeAllList = ({
  sectionId,
  status,
  mode,
  filter,
  customDate,
  pageSize = 10,
}: ChallengeAllListParams) =>
  generateInfiniteHook<GetMemberChallengeRes>(
    (index) =>
      getChallengeListApiKey(
        sectionId,
        status,
        mode,
        filter,
        customDate,
        index + 1,
        pageSize
      ),
    pageSize,
    {
      auth: true,
    }
  );

export const useChallengeAll = (
  sectionId: NullableString,
  status: ChallengeCategoryStatus,
  page = 1,
  pageSize = 10
) =>
  generateHook<GetChallengeDetailRes>(
    sectionId
      ? `/v2/challenge/me?sectionId=${sectionId}&status=${status}&page=${page}&pageSize=${pageSize}`
      : null,
    {
      auth: true,
      paginate: true,
    }
  );

export const useChallengePracticeQuizId = (challengeId: NullableString) =>
  generateHook<GetChallengePracticeQuizIdRes>(
    challengeId ? `v2/challenge/${challengeId}/practice-quiz` : null,
    {
      auth: true,
      immutable: true,
    }
  );

export const useChallengeInsight = (challengeId: NullableString) =>
  generateHook<GetChallengeInsightRes>(
    challengeId ? `/v2/challenge/${challengeId}/insight` : null,
    {
      auth: true,
    }
  );

export const useChallengeResults = (challengeId: NullableString) =>
  generateHook<GetChallengeResultRes[]>(
    challengeId ? `/v2/challenge/${challengeId}/result` : null,
    {
      auth: true,
    }
  );
