import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Icon from '@lib/ia/src/components/Icon';

import { DisplayTableLayoutHeaderDefaultCell } from '../../types';

export type IaDisplayTableDefaultHeaderCellProps =
  DisplayTableLayoutHeaderDefaultCell;

const styles = {
  root: {
    px: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    height: '100%',
    '& svg': {
      minWidth: '16px',
    },
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
export default function IaDisplayTableDefaultHeaderCell({
  icon,
  label,
}: IaDisplayTableDefaultHeaderCellProps) {
  return (
    <Box sx={styles.root}>
      {icon && <Icon name={icon} width={16} height={16} />}
      {label && (
        <Typography variant="body2" sx={styles.label}>
          {label}
        </Typography>
      )}
    </Box>
  );
}
