import {
  BlockSpec,
  createTipTapBlock,
  defaultProps,
  mergeCSSClasses,
  PropSchema,
} from '@blocknote/core';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import { InputRule, mergeAttributes } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

export const headingPropSchema = {
  ...defaultProps,
  level: { default: 1, values: [1, 2, 3] as const },
} satisfies PropSchema;

/**
 * This Block is reference from
 * node_modules/@blocknote/core/src/extensions/Blocks/nodes/BlockContent/HeadingBlockContent/HeadingBlockContent.ts
 * We only need h3 for now, but we reserve the logic of h1 and h2
 */
const Heading = createTipTapBlock<'heading', true>({
  name: BasicBlockTypes.Heading,
  content: 'inline*',

  addAttributes() {
    return {
      level: {
        default: 3,
        // instead of "level" attributes, use "data-level"
        parseHTML: (element: HTMLElement) => element.getAttribute('data-level'),
        renderHTML: (attributes: Record<string, any>) => {
          return {
            'data-level': (attributes.level as number).toString(),
          };
        },
      },
    };
  },

  addInputRules() {
    return [
      ...[3].map((level) => {
        // Creates a heading of appropriate level when starting with "#", "##", or "###".
        return new InputRule({
          find: new RegExp(`^(#{${level}})\\s$`),
          handler: ({ state, chain, range }) => {
            chain()
              .BNUpdateBlock<{
                heading: BlockSpec<'heading', typeof headingPropSchema, true>;
              }>(state.selection.from, {
                type: 'heading',
                props: {
                  level: level as 1 | 2 | 3,
                },
              })
              // Removes the "#" character(s) used to set the heading.
              .deleteRange({ from: range.from, to: range.to });
          },
        });
      }),
    ];
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-3': () =>
        this.editor.commands.BNUpdateBlock<{
          heading: BlockSpec<'heading', typeof headingPropSchema, true>;
        }>(this.editor.state.selection.anchor, {
          type: 'heading',
          props: {
            level: 3,
          },
        }),
    };
  },

  renderHTML({
    node,
    HTMLAttributes,
  }: {
    node: ProseMirrorNode;
    HTMLAttributes: Record<string, any>;
  }) {
    const blockContentDOMAttributes =
      this.options.domAttributes?.blockContent || {};
    const inlineContentDOMAttributes =
      this.options.domAttributes?.inlineContent || {};

    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        ...blockContentDOMAttributes,
        class: mergeCSSClasses(blockContentDOMAttributes.class),
        'data-content-type': this.name,
      }),
      [
        `h${node.attrs.level}`,
        {
          ...inlineContentDOMAttributes,
          class: mergeCSSClasses(inlineContentDOMAttributes.class),
        },
        0,
      ],
    ];
  },
});

export const HeadingBlock = {
  node: Heading,
  propSchema: headingPropSchema,
} satisfies BlockSpec<'heading', typeof headingPropSchema, true>;

export default HeadingBlock;
