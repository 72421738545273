import React from 'react';

export default function OtherPlayCircle({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM15.2165 12.154L10.3415 15.7009C10.3148 15.7201 10.2833 15.7316 10.2505 15.734C10.2177 15.7365 10.1848 15.7299 10.1555 15.7149C10.1262 15.6999 10.1016 15.6771 10.0844 15.649C10.0672 15.621 10.0581 15.5887 10.058 15.5558V8.46652C10.0579 8.43354 10.067 8.40118 10.0841 8.37302C10.1013 8.34486 10.1259 8.322 10.1552 8.30697C10.1846 8.29195 10.2175 8.28534 10.2504 8.2879C10.2833 8.29045 10.3148 8.30205 10.3415 8.32143L15.2165 11.8661C15.2395 11.8824 15.2583 11.9039 15.2713 11.929C15.2843 11.954 15.291 11.9818 15.291 12.01C15.291 12.0383 15.2843 12.0661 15.2713 12.0911C15.2583 12.1162 15.2395 12.1377 15.2165 12.154Z"
        fill="currentColor"
      />
    </svg>
  );
}
