import React from 'react';

export default function EditorCopyTo({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5 5.82857C5 5.41921 5.36504 5 5.92593 5H12.6667C13.219 5 13.6667 4.55228 13.6667 4C13.6667 3.44772 13.219 3 12.6667 3H5.92593C4.35949 3 3 4.21815 3 5.82857V15.8857C3 16.438 3.44772 16.8857 4 16.8857C4.55228 16.8857 5 16.438 5 15.8857V5.82857ZM8.33333 6.2C7.29873 6.2 6.37037 7.00881 6.37037 8.11429V19.0857C6.37037 20.1912 7.29873 21 8.33333 21H14.4651C14.3032 20.7136 14.2081 20.3753 14.2081 19.9982V19H8.37037V8.2H16V9.0079C16.5023 8.96201 17.0301 9.11403 17.4676 9.51678L18 10.007V8.11429C18 7.00881 17.0716 6.2 16.037 6.2H8.33333Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M15.4444 20.2809C15.4444 20.5474 15.7661 20.6816 15.9555 20.494L20.7847 15.7132C20.9032 15.5958 20.9032 15.4042 20.7847 15.2868L17.6667 12.2L15.9555 10.506C15.7661 10.3184 15.4444 10.4526 15.4444 10.7191V13.6667H11.3C11.1343 13.6667 11 13.801 11 13.9667V17.0333C11 17.199 11.1343 17.3333 11.3 17.3333H15.4444V20.2809Z"
        fill="currentColor"
      />
    </svg>
  );
}
