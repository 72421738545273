export default function ProfileMyAgent({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.692 5.25L17.7229 3L18.7456 5.25L20.9956 6.27273L18.7456 7.30364L17.7229 9.54545L16.692 7.30364L14.4502 6.27273L16.692 5.25Z"
        fill="currentColor"
      />
      <path
        d="M16.692 16.6964L17.7229 14.4545L18.7456 16.6964L20.9956 17.7273L18.7456 18.75L17.7229 21L16.692 18.75L14.4502 17.7273L16.692 16.6964Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54346 5.45654L11.5883 9.95517L16.0869 12L11.5883 14.0448L9.54346 18.5435L7.49863 14.0448L3 12L7.49863 9.95517L9.54346 5.45654ZM9.54346 8.29451L8.38549 10.842L5.83797 12L8.38549 13.158L9.54346 15.7055L10.7014 13.158L13.2489 12L10.7014 10.842L9.54346 8.29451Z"
        fill="currentColor"
      />
    </svg>
  );
}
