import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

import LegacyMaterialRender, {
  LegacyMaterialRendererProps,
} from './LegacyMaterialRender';

type BlockProps = {
  node: ProseMirrorNode;
  updateAttributes: (attributes: Record<string, any>) => void;
};

export const legacyMaterialBlockDefaultProps = {
  content: {
    default: '',
  },
  type: {
    default: '',
  },
  subType: {
    default: '',
  },
};

const LegacyMaterial = ({ node }: BlockProps) => {
  return (
    <NodeViewWrapper
      data-content-type={node.type.name}
      className="blockContent"
    >
      <LegacyMaterialRender {...(node.attrs as LegacyMaterialRendererProps)} />
    </NodeViewWrapper>
  );
};

export type LegacyMaterialOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: LegacyMaterialOptions;
    node: ProseMirrorNode;
  }) => string;
};

export const LegacyMaterialBlock = Node.create<LegacyMaterialOptions>({
  name: BasicBlockTypes.LegacyMaterial,
  group: 'blockContent',
  inline: false,
  selectable: false,
  atom: true,

  addAttributes() {
    return legacyMaterialBlockDefaultProps;
  },

  parseHTML() {
    return [
      {
        tag: 'legacy-material',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        'data-content-type': this.name,
        'data-render-ui': 'legacyMaterialBlock',
        'data-metadata-content': HTMLAttributes.content || '',
        'data-metadata-type': HTMLAttributes.type || '',
        'data-metadata-sub-type': HTMLAttributes.subType || '',
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(LegacyMaterial);
  },
});

export default LegacyMaterialBlock;
