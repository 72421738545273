import { ReactNode, useCallback, useState } from 'react';
import { Box, SxProps } from '@mui/material';

export default function Hoverable({
  sx,
  children,
}: {
  sx?: SxProps;
  children: ({
    hovered,
    hoveredListeners,
  }: {
    hovered: boolean;
    resetHovered: () => void;
    hoveredListeners: {
      onMouseEnter: () => void;
      onMouseLeave: () => void;
      onBlur: () => void;
    };
  }) => ReactNode;
}) {
  const [hovered, setHovered] = useState(false);

  const sxProps = Array.isArray(sx) ? sx : [sx];

  const resetHovered = useCallback(() => setHovered(false), []);
  const setIsHovered = useCallback(() => setHovered(true), []);

  const hoveredListeners = {
    onMouseEnter: setIsHovered,
    onMouseLeave: resetHovered,
    onBlur: resetHovered,
  };

  return (
    <Box sx={sxProps}>
      {children({
        hovered,
        resetHovered,
        hoveredListeners,
      })}
    </Box>
  );
}
