import React from 'react';

export default function ChallengeOngoing({ ...rest }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="8" cy="8" r="6" fill="#01F89B" />
    </svg>
  );
}
