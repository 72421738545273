import React from 'react';

export default function SocialMediaYoutube({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.91449 5.2594C3.36613 5.38104 2.19581 6.64009 2.12043 8.19138C2.05833 9.46953 2 10.9404 2 11.9557C2 12.9826 2.05968 14.4756 2.12257 15.7638C2.19733 17.2953 3.34087 18.5459 4.86764 18.6869C6.55776 18.843 9.00316 19 12 19C14.989 19 17.4295 18.8438 19.1192 18.6881C20.6518 18.5469 21.7976 17.2882 21.8714 15.7507C21.9364 14.3944 22 12.8425 22 11.9557C22 11.0784 21.9378 9.5502 21.8735 8.20436C21.7991 6.64707 20.6269 5.3802 19.0726 5.25838C17.409 5.128 15.0095 5 12 5C8.98273 5 6.57858 5.12867 4.91449 5.2594ZM10 8.98733L15.1556 11.9557L10 14.924V8.98733Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
