import { useCallback, useState } from 'react';
import Router from 'next/router';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import { useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import {
  DisplayTableLayoutCellBase,
  DisplayTableLayoutCellEvent,
  DisplayTableLayoutSelectOption,
} from '@lib/ia/src/layouts/DisplayTableLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import ThreadEmbeddedQuestion from '@lib/web/thread/ThreadProperties/ThreadEmbeddedQuestion';
import { getChannelLocationDetail } from '@lib/web/thread/utils/locationUtils';
import { FloatingProfile } from '@lib/web/ui';
import { Channel, ChannelData } from 'stream-chat';

import { useThreadViewDetails } from '../hooks/view/useThreadViewDetails';
import { useDirectMessageViews } from '../hooks/views/useDirectMessageViews';

import { useThreadChannelPropertiesLayoutConfig } from './hooks/useThreadChannelPropertiesLayoutConfig';

// XXX: find a better way to not duplicate it from apps/web global panels
const enum GlobalPanelKeys {
  GlobalThreadMembers = 'GlobalThreadMembers',
  GlobalThreadActiveMembers = 'GlobalThreadActiveMembers',
  GlobalProfile = 'GlobalProfile',
  GlobalThreadChat = 'GlobalThreadChat',
  GlobalThreadProperties = 'GlobalThreadProperties',
  GlobalStartChallenge = 'GlobalChallenge',
}

type GlobalPanelParams = {
  [GlobalPanelKeys.GlobalThreadMembers]: {
    channel: Channel;
  };
  [GlobalPanelKeys.GlobalThreadActiveMembers]: {
    channel: Channel;
  };
  [GlobalPanelKeys.GlobalProfile]: {
    userId: string;
  };
  [GlobalPanelKeys.GlobalThreadChat]: {
    channelCid: string;
    filterOrSortOpened?: 'filter' | 'sort' | null;
  };
  [GlobalPanelKeys.GlobalThreadProperties]: {
    channelCid: string;
  };
  [GlobalPanelKeys.GlobalStartChallenge]: {
    challengerSource: any[];
  };
};

const styles = {
  root: {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    background: (theme: Theme) => theme.palette.background.body,
    backgroundPosition: 'right center',
    backgroundAttachment: 'fixed',
    backgroundSize: '100vw 100vh',

    px: 1.5,
    '.base-layout-right-panel &': {
      px: 0,
    },

    '& .display-table-layout': {
      pb: '12px',
      px: 0.5,
    },
  },
  rootExpandableFull: {
    borderBottom: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
  },
};

export type ThreadPropertiesProps = {
  channel: Channel;
  mode: 'expandableBrief' | 'expandableFull' | 'full';
};

export default function ThreadChannelProperties({
  channel,
  mode: initialMode,
}: ThreadPropertiesProps) {
  const [mode, setMode] = useState(initialMode);
  const config = useThreadChannelPropertiesLayoutConfig({ channel, mode });
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  const [profileAnchorEl, setProfileAnchorEl] = useState<Element | null>();
  const [currentItem, setCurrentItem] = useState<DisplayTableLayoutCellBase>();

  const availableActions = {
    show: {
      action: () => {
        setMode('expandableFull');
      },
    },
    hide: {
      action: () => {
        setMode('expandableBrief');
      },
    },
    openMembers: {
      action: () => {
        openRightPanel(GlobalPanelKeys.GlobalThreadMembers, { channel });
      },
    },
    openActiveMembers: {
      action: () => {
        openRightPanel(GlobalPanelKeys.GlobalThreadActiveMembers, { channel });
      },
    },
    openMemberProfile: {
      action: (cell: DisplayTableLayoutCellBase<{ userId: string }>): void => {
        openRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: cell.metadata?.userId ?? '',
        });
      },
    },
    openClub: {
      action: (cell: DisplayTableLayoutCellBase<{ clubSlug: string }>) => {
        void Router.push(`/club/${cell.metadata?.clubSlug}`);
      },
    },
    openChannel: {
      action: (cell: DisplayTableLayoutCellBase<{ channelCid: string }>) => {
        if (!cell.metadata?.channelCid) return;

        openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
          channelCid: cell.metadata.channelCid,
        });
      },
    },
    seeAllChildThreads: {
      action: () => {
        openRightPanel(GlobalPanelKeys.GlobalThreadProperties, {
          channelCid: channel.cid,
        });
      },
    },
    handleProfileMouseEnter: {
      action: (
        event: DisplayTableLayoutCellEvent<{ userId: string }>
      ): void => {
        if (!event.target.metadata?.userId) return;
        setProfileAnchorEl(event.anchorEl);
        setCurrentItem(event.target);
      },
    },
  };

  const getItemStatus = ({
    option,
  }: {
    option?: DisplayTableLayoutSelectOption;
  }) => {
    return {
      selected: option?.value === 'created_by', // support different option in PRF-2570
    };
  };

  const { views } = useDirectMessageViews();
  const { getThreadViewDetail } = useThreadViewDetails();

  const prepareChallengeSource = useCallback((user: UserProfileObj) => {
    return [
      {
        id: user.userId,
        display: user.displayName || user.distinctName || '',
        avatar: user.nftAvatar || user.avatar || '',
        memberInfo: user,
      },
    ];
  }, []);

  const handleMessageClick = useCallback(
    (userProfile: UserProfileObj) => {
      const memberId = userProfile.memberId;
      const existingView = views.find((view) => {
        const memberIds = getThreadViewDetail(view).viewMemberIds;
        return memberIds.length === 2 && memberIds.includes(memberId);
      });

      if (existingView) {
        void Router.push(`/direct-messages/view/${existingView.id}`);
      } else {
        void Router.push(`/direct-messages/view/${memberId}`);
      }
    },
    [getThreadViewDetail, views]
  );

  const handleChallengeClick = useCallback(
    (userProfile: UserProfileObj) => {
      const challengerSource = prepareChallengeSource(userProfile);
      openRightPanel(GlobalPanelKeys.GlobalStartChallenge, {
        challengerSource,
      });
    },
    [prepareChallengeSource, openRightPanel]
  );
  const locationDetail =
    channel.data && getChannelLocationDetail(channel.data as ChannelData);

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <IaItemStatusProvider getItemStatus={getItemStatus}>
        <Box
          sx={[
            styles.root,
            mode === 'expandableFull' && styles.rootExpandableFull,
          ]}
          className="thread-properties-root"
        >
          <IaLayouts layouts={config} />
          <ThreadEmbeddedQuestion locationDetail={locationDetail} />
        </Box>
        <FloatingProfile
          anchorEl={profileAnchorEl}
          onClose={() => setProfileAnchorEl(null)}
          type="userId"
          userId={currentItem?.metadata?.userId}
          onMessageClick={handleMessageClick}
          onChallengeClick={handleChallengeClick}
        />
      </IaItemStatusProvider>
    </IaActionContextProvider>
  );
}
