import { ReactNode } from 'react';
import { useTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import createTheme from './index';

type ThemeProps = {
  children: ReactNode;
  mode?: 'light' | 'dark';
};
export default function CustomThemeProvider({ children, mode }: ThemeProps) {
  const theme = useTheme();
  const updatedTheme = createTheme({
    mode: mode || theme.palette.mode,
  });

  return <ThemeProvider theme={updatedTheme}>{children}</ThemeProvider>;
}
