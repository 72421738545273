import React from 'react';

export default function TestPhysics({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.2778 10.725C14.9315 10.3747 14.3686 10.3747 14.0224 10.725L9.85672 14.941C9.34436 15.4595 8.51077 15.4595 7.99818 14.9409C7.48587 14.4222 7.48587 13.5785 7.99822 13.0599L12.1638 8.84405C12.5099 8.49362 12.5099 7.92388 12.1638 7.57336L10.1552 5.54048C9.80918 5.19028 9.24596 5.19028 8.89989 5.54048L4.73406 9.75652C2.42192 12.0964 2.42201 15.9042 4.73415 18.2444C7.04639 20.5844 10.8086 20.5845 13.1207 18.2444L17.2864 14.0283C17.6325 13.6781 17.6325 13.1081 17.2864 12.7578L15.2778 10.725ZM13.7648 16.0678L12.0072 14.2891L14.65 11.6143L16.4075 13.393L13.7648 16.0678Z"
        fill="currentColor"
      />
      <path
        d="M20.9845 4.11212C20.9483 4.02795 20.8571 3.98287 20.7693 4.00562L15.2809 5.41986C15.2164 5.43653 15.1657 5.48684 15.148 5.55191C15.1304 5.61675 15.1487 5.68611 15.1956 5.73371L16.4093 6.96306L13.6021 8.8035C13.5246 8.85423 13.496 8.95484 13.5348 9.03958C13.5441 9.05999 13.5568 9.07797 13.5718 9.09311C13.6189 9.14071 13.6895 9.16042 13.7562 9.13996L19.0912 7.50324C19.1534 7.48436 19.2011 7.43368 19.2171 7.36992C19.233 7.30621 19.2146 7.23876 19.169 7.19218L18.1513 6.15435L20.9156 4.34423C20.9915 4.29439 21.0208 4.19616 20.9845 4.11212Z"
        fill="currentColor"
      />
    </svg>
  );
}
