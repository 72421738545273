import { useMemo } from 'react';
import { useAuth } from '@lib/web/apis';

export default function useGetHelpUrl() {
  const { member } = useAuth();

  return useMemo(() => {
    const params = new URLSearchParams();

    params.set('member_id', member?.memberId || '');
    params.set('name', member?.displayName || member?.distinctName || '');
    params.set('email', member?.email || '');
    params.set('page_url', window.location.href);
    params.set('environment', process.env.ENV_CODE || '');

    return `${process.env.GET_HELP_URL}?${params.toString()}`;
  }, [member]);
}
