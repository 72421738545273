import { BlockNoteEditor, BlockSchema, PartialBlock } from '@blocknote/core';
import { nonNullable } from '@front/helper';
import {
  cloneComposerBlock,
  insertOrUpdateBlock,
} from '@lib/web/composer/utils';
import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { v4 } from 'uuid';

const getPlainTextFromClipboardEvent = (event: ClipboardEvent): string => {
  if (!event.clipboardData) return '';

  const plainText = event.clipboardData.getData('text/plain');

  if (plainText) return plainText;

  const html = event.clipboardData.getData('text/html');

  if (!html) return '';

  const domDiv = document.createElement('div');
  domDiv.innerHTML = html;

  return domDiv.textContent || domDiv.innerText;
};

export const pastePlugin = <T extends BlockSchema>(
  editor: BlockNoteEditor<T>
): Plugin => {
  return new Plugin({
    key: new PluginKey('handleHeadingPastePlugin'),
    props: {
      handlePaste: (view: EditorView, event: ClipboardEvent): boolean => {
        // Prevent the default paste handling
        event.preventDefault();

        const plainText = getPlainTextFromClipboardEvent(event);

        let node;

        try {
          node = JSON.parse(plainText);
        } catch (e) {
          return false;
        }

        if (
          Array.isArray(node) &&
          node.length > 0 &&
          node.every((item) => 'type' in item)
        ) {
          const newBlock = node.map(cloneComposerBlock).filter(nonNullable);

          const isInlineContent =
            view.state.schema.nodes[newBlock[0].type].inlineContent; // ex. heading, subtitle, and step
          if (isInlineContent) {
            insertOrUpdateBlock(editor, newBlock as PartialBlock<T>[]);
          } else {
            // add new line
            const paragraphBlock = {
              id: v4(),
              type: 'paragraph' as const,
            };
            const currentBlock = editor.getTextCursorPosition().block;
            editor.insertBlocks([paragraphBlock], currentBlock, 'after');
            editor.setTextCursorPosition(paragraphBlock.id, 'start');

            // insert new content
            editor._tiptapEditor
              .chain()
              .insertContentAt(
                editor._tiptapEditor.state.selection.head,
                newBlock
              )
              .setTextSelection(editor._tiptapEditor.state.selection.to)
              .run();
          }

          return true;
        }

        return false;
      },
    },
  });
};
