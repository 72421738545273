import React from 'react';

export default function TestEnglish({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 3C4.8 3 3 4.8 3 12C3 19.2 4.8 21 12 21C19.2 21 21 19.2 21 12C21 4.8 19.2 3 12 3Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M8.20906 14.4048C8.15626 14.232 8.09626 14.0544 8.02906 13.872C7.96666 13.6896 7.90426 13.5072 7.84186 13.3248H5.89786C5.83546 13.5072 5.77066 13.6896 5.70346 13.872C5.64106 14.0544 5.58346 14.232 5.53066 14.4048H4.36426C4.55146 13.8672 4.72906 13.3704 4.89706 12.9144C5.06506 12.4584 5.22826 12.0288 5.38666 11.6256C5.54986 11.2224 5.70826 10.8408 5.86186 10.4808C6.02026 10.116 6.18346 9.76085 6.35146 9.41525H7.42426C7.58746 9.76085 7.74826 10.116 7.90666 10.4808C8.06506 10.8408 8.22346 11.2224 8.38186 11.6256C8.54506 12.0288 8.71066 12.4584 8.87866 12.9144C9.04666 13.3704 9.22426 13.8672 9.41146 14.4048H8.20906ZM6.86266 10.5456C6.83866 10.6176 6.80266 10.716 6.75466 10.8408C6.70666 10.9656 6.65146 11.1096 6.58906 11.2728C6.52666 11.436 6.45706 11.616 6.38026 11.8128C6.30826 12.0096 6.23386 12.216 6.15706 12.432H7.57546C7.49866 12.216 7.42426 12.0096 7.35226 11.8128C7.28026 11.616 7.21066 11.436 7.14346 11.2728C7.08106 11.1096 7.02586 10.9656 6.97786 10.8408C6.92986 10.716 6.89146 10.6176 6.86266 10.5456Z"
        fill="#080808"
      />
      <path
        d="M12.0805 14.4696C11.8069 14.4696 11.5333 14.46 11.2597 14.4408C10.9909 14.4264 10.7101 14.388 10.4173 14.3256V9.48725C10.6477 9.44405 10.8997 9.41285 11.1733 9.39365C11.4469 9.36965 11.7013 9.35765 11.9365 9.35765C12.2533 9.35765 12.5437 9.38165 12.8077 9.42965C13.0765 9.47285 13.3069 9.54965 13.4989 9.66005C13.6909 9.77045 13.8397 9.91685 13.9453 10.0992C14.0557 10.2768 14.1109 10.4976 14.1109 10.7616C14.1109 11.16 13.9189 11.4744 13.5349 11.7048C13.8517 11.8248 14.0677 11.988 14.1829 12.1944C14.2981 12.4008 14.3557 12.6336 14.3557 12.8928C14.3557 13.416 14.1637 13.8096 13.7797 14.0736C13.4005 14.3376 12.8341 14.4696 12.0805 14.4696ZM11.5117 12.2304V13.5192C11.5933 13.5288 11.6821 13.536 11.7781 13.5408C11.8741 13.5456 11.9797 13.548 12.0949 13.548C12.4309 13.548 12.7021 13.5 12.9085 13.404C13.1149 13.308 13.2181 13.1304 13.2181 12.8712C13.2181 12.6408 13.1317 12.4776 12.9589 12.3816C12.7861 12.2808 12.5389 12.2304 12.2173 12.2304H11.5117ZM11.5117 11.3736H12.0589C12.4045 11.3736 12.6517 11.3304 12.8005 11.244C12.9493 11.1528 13.0237 11.0088 13.0237 10.812C13.0237 10.6104 12.9469 10.4688 12.7933 10.3872C12.6397 10.3056 12.4141 10.2648 12.1165 10.2648C12.0205 10.2648 11.9173 10.2672 11.8069 10.272C11.6965 10.272 11.5981 10.2768 11.5117 10.2864V11.3736Z"
        fill="#080808"
      />
      <path
        d="M17.89 14.5056C17.0788 14.5056 16.4596 14.28 16.0324 13.8288C15.61 13.3776 15.3988 12.7368 15.3988 11.9064C15.3988 11.4936 15.4636 11.1264 15.5932 10.8048C15.7228 10.4784 15.9004 10.2048 16.126 9.98405C16.3516 9.75845 16.6204 9.58805 16.9324 9.47285C17.2444 9.35765 17.5828 9.30005 17.9476 9.30005C18.1588 9.30005 18.3508 9.31685 18.5236 9.35045C18.6964 9.37925 18.8476 9.41525 18.9772 9.45845C19.1068 9.49685 19.2148 9.53765 19.3012 9.58085C19.3876 9.62405 19.45 9.65765 19.4884 9.68165L19.1644 10.5888C19.0108 10.5072 18.8308 10.4376 18.6244 10.38C18.4228 10.3224 18.1924 10.2936 17.9332 10.2936C17.7604 10.2936 17.59 10.3224 17.422 10.38C17.2588 10.4376 17.1124 10.5312 16.9828 10.6608C16.858 10.7856 16.7572 10.9488 16.6804 11.1504C16.6036 11.352 16.5652 11.5968 16.5652 11.8848C16.5652 12.1152 16.5892 12.3312 16.6372 12.5328C16.69 12.7296 16.7716 12.9 16.882 13.044C16.9972 13.188 17.146 13.3032 17.3284 13.3896C17.5108 13.4712 17.7316 13.512 17.9908 13.512C18.154 13.512 18.3004 13.5024 18.43 13.4832C18.5596 13.464 18.6748 13.4424 18.7756 13.4184C18.8764 13.3896 18.9652 13.3584 19.042 13.3248C19.1188 13.2912 19.1884 13.26 19.2508 13.2312L19.5604 14.1312C19.402 14.2272 19.1788 14.3136 18.8908 14.3904C18.6028 14.4672 18.2692 14.5056 17.89 14.5056Z"
        fill="#080808"
      />
    </svg>
  );
}
