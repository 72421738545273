import matchAll from 'string.prototype.matchall';

const HIGHLIGH_REGEX =
  /<span [^>]*?class="highlight-order"*.?data-target-id="([^"]+)"[^>]*?>([^<]+)<\/span>/gm;
export const replaceHighlights = (content: string, highlights?: string[]) => {
  if (!highlights?.length) return content;

  let txt = content;
  const multipleMatchArr = [...matchAll(txt, HIGHLIGH_REGEX)];
  multipleMatchArr.forEach((match) => {
    const targetId = match[1];

    const currentIndex = highlights.findIndex(
      (highlight) => highlight === targetId
    );

    if (currentIndex > -1) {
      txt = txt.replace(
        match[0],
        match[0].replace(match[2], `${currentIndex + 1}`)
      );
    }
  });

  return txt;
};
