import React, { ReactElement } from 'react';
import { Box } from '@mui/material';

import {
  DisplayTableLayoutFooterCell,
  DisplayTableLayoutFooterRow,
} from '../types';

import IaDisplayTableFooterCell from './IaDisplayTableFooterCell';

const styles = {
  root: {
    display: 'grid',
    width: '100%',
    textAlign: 'left',
  },
};

const ROW_HEIGH_DEFAULT = '45px';

type IaDisplayTableFooterRowProps = {
  row?: DisplayTableLayoutFooterRow;
  children: (
    cell: DisplayTableLayoutFooterCell
  ) => ReactElement<typeof IaDisplayTableFooterCell>;
};

export default function IaDisplayTableFooterRow({
  row,
  children,
}: IaDisplayTableFooterRowProps) {
  if (!row || row.cells.length === 0) return null;

  return (
    <Box
      sx={[
        styles.root,
        {
          gridTemplateColumns: row.gridTemplateColumns,
          gridAutoRows: row.rowHeight || ROW_HEIGH_DEFAULT,
        },
      ]}
    >
      {row.cells.map((cell, index) => (
        <React.Fragment key={index}>{children(cell)}</React.Fragment>
      ))}
    </Box>
  );
}
