import { useEffect, useRef } from 'react';
import { Button, toast } from '@front/ui';

import { fileTask } from './fileTask';
import { useFiles } from './store';
import { ExportTask } from './types';

function directDownload(url: string, filename?: string) {
  const urlName = url.match(/([^/]+.[^/]+)?/);

  const element = document.createElement('a');
  element.style.display = 'none';
  element.href = url;
  element.download =
    filename || (urlName?.length && urlName[1]) || 'download.pdf';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

const getDownloadLink = (
  processExporting: () => Promise<boolean>,
  processDownloading: () => Promise<string | null>,
  maxAttempts = 5,
  retryInterval = 10000
) => {
  let attempt = 0;
  let shouldContinue = true;

  const attemptDownload = (
    resolve: (url: string) => void,
    reject: (error: Error) => void
  ) => {
    attempt++;
    processDownloading()
      .then((response) => {
        if (response === null) {
          throw new Error('DownloadLinkFailed');
        } else {
          resolve(response);
        }
      })
      .catch(() => {
        if (attempt < maxAttempts && shouldContinue) {
          setTimeout(() => attemptDownload(resolve, reject), retryInterval);
        } else {
          reject(new Error('AttemptLimitReached'));
        }
      });
  };

  const exportingFiles = (
    resolve: (url: string) => void,
    reject: (error: Error) => void
  ) => {
    processExporting()
      .then((response) => {
        if (response === false) {
          throw new Error('ExportingFailed');
        } else {
          attemptDownload(resolve, reject);
        }
      })
      .catch(() => {
        reject(new Error('ExportingFailed'));
      });
  };

  const promise = () => new Promise(exportingFiles);

  return {
    processDownloading: promise,
    cancelDownload: () => {
      shouldContinue = false;
    },
  };
};

function Task({ task }: { task: ExportTask }) {
  const isProcessing = useRef(false);
  const isMounted = useRef(true);

  useEffect(() => {
    const { processDownloading, cancelDownload } = getDownloadLink(
      task.processExport,
      task.processDownloading,
      task.retry,
      task.retryInterval
    );

    const handleCancel = () => {
      task.onCancel?.();
      toast.dismiss(task.id);
      fileTask.cancel(task.id);
    };

    const processTask = async () => {
      isProcessing.current = true;

      toast.loading(
        task.loadingMessage || 'Your file is downloading...',
        {
          actionComponent: (
            <Button onClick={handleCancel} size="sm">
              {task.cancelText || 'Cancel'}
            </Button>
          ),
        },
        { duration: Infinity, id: task.id }
      );

      try {
        const link = await processDownloading();
        await directDownload(link);

        if (isMounted.current) {
          task.onSuccess?.();
          toast.dismiss(task.id);
          fileTask.remove(task.id);
        }
      } catch (err) {
        if (isMounted.current) {
          task.onError?.();
          toast.error(
            task.errorMessage || 'Download failed. Please try again.',
            {
              actionComponent: (
                <Button onClick={() => fileTask.add(task)} size="sm">
                  {task.tryAgainText || 'Try again'}
                </Button>
              ),
            },
            { duration: 30000, id: task.id }
          );
          fileTask.remove(task.id);
        }
      }

      isProcessing.current = false;
    };

    if (!isProcessing.current) {
      processTask();
    }

    return () => {
      isMounted.current = false;
      if (isProcessing.current) {
        task.onCancel?.();
        toast.dismiss(task.id);
        cancelDownload();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
export default function FileTasks() {
  const { tasks } = useFiles();

  return (
    <>
      {tasks.map((t) => (
        <Task key={t.id} task={t} />
      ))}
    </>
  );
}
