import React from 'react';

export default function OtherSkip({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.59092 19C3.4342 19 3.28389 18.9385 3.17308 18.8291C3.06226 18.7197 3 18.5714 3 18.4167V5.58324C3.00002 5.48187 3.02679 5.38226 3.07769 5.29422C3.12858 5.20618 3.20183 5.13275 3.29023 5.08117C3.37863 5.02959 3.47911 5.00164 3.58179 5.00007C3.68446 4.9985 3.78577 5.02338 3.87574 5.07223L15.6941 11.4889C15.7867 11.5393 15.864 11.6133 15.9178 11.7032C15.9716 11.7931 16 11.8955 16 12C16 12.1044 15.9716 12.2069 15.9178 12.2967C15.864 12.3866 15.7867 12.4606 15.6941 12.511L3.87574 18.9277C3.78849 18.9751 3.69052 19 3.59092 19Z"
        fill="currentColor"
      />
      <rect x="18" y="4" width="3" height="16" rx="0.5" fill="currentColor" />
    </svg>
  );
}
