import nextId from 'react-id-generator';

export default function ProfileAiTokensUsed({ ...rest }) {
  const pathId = nextId('profile-ai-tokens-used-radial');

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M17.7273 3L16.6964 5.25L14.4545 6.27273L16.6964 7.30364L17.7273 9.54545L18.75 7.30364L21 6.27273L18.75 5.25M9.54545 5.45455L7.5 9.95455L3 12L7.5 14.0455L9.54545 18.5455L11.5909 14.0455L16.0909 12L11.5909 9.95455M17.7273 14.4545L16.6964 16.6964L14.4545 17.7273L16.6964 18.75L17.7273 21L18.75 18.75L21 17.7273L18.75 16.6964"
        fill={`url(#${pathId})`}
      />
      <defs>
        <linearGradient
          id={pathId}
          x1="3"
          y1="3"
          x2="21.7803"
          y2="3.85471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D1FF" />
          <stop offset="0.484375" stopColor="#0051F9" />
          <stop offset="1" stopColor="#7000FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
