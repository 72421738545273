import React from 'react';

export default function ActionApplyModifier({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.5001 8.47363H15.097L12.334 11.4978L11.002 10.2935L14.0332 6.97676C14.1175 6.88456 14.2204 6.8109 14.3351 6.76052C14.4498 6.71014 14.5738 6.68414 14.6992 6.68418H16.5001V4L21.0001 7.63438L16.5001 11.1578V8.47363Z"
        fill="currentColor"
      />
      <path
        d="M15.0969 16.5266H16.5V13.8424L21 17.4213L16.5 21.0002V18.316H14.6991C14.5737 18.3161 14.4497 18.2901 14.335 18.2397C14.2203 18.1893 14.1175 18.1156 14.0331 18.0234L10.2 13.8308L6.3669 18.0243C6.28251 18.1165 6.17968 18.1902 6.06499 18.2406C5.9503 18.291 5.82629 18.317 5.7009 18.3169H3V16.5275H5.3031L8.9841 12.5003L5.3031 8.47402H3V6.68457H5.7009C5.82629 6.68453 5.9503 6.71053 6.06499 6.76091C6.17968 6.8113 6.28251 6.88495 6.3669 6.97715L15.0969 16.5266Z"
        fill="currentColor"
      />
    </svg>
  );
}
