import { useRef, useState } from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';

import { Spotlight } from '../../atoms';

import PinMessage, { PinMessageProps } from './components/PinMessage';
import PinStep, { PinStepProps } from './components/PinStep';

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    opacity: 0,
    userSelect: 'none',
    transitionDuration: '0.3s',
    transitionProperty: 'opacity',
  },
  coveredBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(8, 8, 8, 0.75)',
  },
};
export type PinChatProps = PinStepProps &
  Omit<PinMessageProps, 'progress'> & {
    x?: number;
    y?: number;
    arrowOffsetX?: number;
    arrowOffsetY?: number;
  };

export const getPinChatVariant = (step: number, currentStep: number) => {
  if (step === currentStep) {
    return 'active';
  }
  if (step < currentStep) {
    return 'viewed';
  }
  return 'new';
};

export default function PinChat({
  variant,
  step,
  totalStep,
  message,
  arrowDirection = 'left-bottom',
  x,
  y,
  arrowOffsetX = 0,
  arrowOffsetY = 0,
  hideText,
  nextText,
  doneText,
  onHide,
  onNext,
  onFinish,
}: PinChatProps) {
  const [clicked, setClicked] = useState(false); // mobile only
  const progress = step && totalStep ? step / totalStep : 0;
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const show = x !== undefined && y !== undefined;
  const arrowRef = useRef<HTMLDivElement>(null);
  const updatedVariant =
    variant === 'active' && mdDown && !clicked ? 'new' : variant;
  const isActive = variant === 'active';
  const showMessageBox = updatedVariant === 'active';

  return (
    <>
      {showMessageBox && mdDown && <Box sx={styles.coveredBackground} />}
      {isActive && (
        <Spotlight disablePortal x={x} y={y} onClick={() => setClicked(true)} />
      )}
      <Box
        sx={[
          styles.root,
          {
            opacity: show ? 1 : 0,
            transform: show
              ? `translate(${x + arrowOffsetX}px, ${y + arrowOffsetY}px)`
              : undefined,
            zIndex: isActive ? 'tooltip' : 'modal',
          },
        ]}
      >
        <PinStep
          ref={arrowRef}
          variant={updatedVariant}
          arrowDirection={arrowDirection}
          step={step}
          onClick={() => setClicked(true)}
        />

        {isActive && (
          <PinMessage
            arrowRef={arrowRef}
            open={showMessageBox}
            progress={progress}
            arrowDirection={arrowDirection}
            step={step}
            totalStep={totalStep}
            message={message}
            onFinish={onFinish}
            onHide={onHide}
            onNext={onNext}
            onRevert={() => setClicked(false)}
            hideText={hideText}
            nextText={nextText}
            doneText={doneText}
          />
        )}
      </Box>
    </>
  );
}
