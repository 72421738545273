import { MouseEvent, useState } from 'react';
import { Box } from '@mui/material';
import { SortableItem } from '@front/helper';

import PromptCard, { PromptCardProps } from '../PromptCard';

import PromptFloatingMenu from './PromptFloatingMenu';

const styles = {
  cardContainer: {},
};

export default function PromptSortableItem({
  id,
  onAddItem,
  onDelete,
  ...rest
}: {
  id: string;
  onAddItem: (ev: MouseEvent<HTMLButtonElement>) => void;
} & PromptCardProps) {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <SortableItem id={id}>
      {({ sortableItemEl, isGhostEl }) => (
        <Box
          sx={styles.cardContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {!isGhostEl && (
            <PromptFloatingMenu
              id={id}
              active={hovered}
              onAddItem={onAddItem}
              onDeleteItem={onDelete}
              onCloseMenu={handleMouseLeave}
              anchorEl={sortableItemEl}
            />
          )}
          <PromptCard onDelete={onDelete} {...rest} />
        </Box>
      )}
    </SortableItem>
  );
}
