import api from './client';

const toolApi = {
  getToolShortIdUuid: (params: GetToolShortIdUuidReq) =>
    api.post<Response<GetToolShortIdUuidRes>>('/v2/tool/shortId/uuid', params),
  getToolDynamicSourceId: (params: GetToolDynamicSourceIdReq) =>
    api.post<Response<GetToolDynamicSourceIdRes>>(
      '/v2/tool/dynamic/sourceId',
      params
    ),
};

export default toolApi;
