import { MouseEvent, useEffect, useRef, useState } from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { reactionSuggestions } from '@front/config';
import { useLatestValueRef } from '@front/helper';
import {
  NFTFavorite as NFTFavoriteIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import { uniqBy } from 'lodash';
import { v4 } from 'uuid';

import {
  ControlledTooltip,
  IconButton,
  useControlledTooltip,
} from '../../atoms';
import {
  BaseLayoutRightPanel,
  BaseLayoutRightPanelPortal,
  useBaseRightPanel,
} from '../../layouts';
import { ResponsivePopper } from '../../molecules';
import { ResponsivePopperProps } from '../../molecules/ResponsivePopper/ResponsivePopper';

import ReactionPanelInner from './ReactionPanelInner';
import { EmojiValue, ReactionValue } from './types';

const styles = {
  emoji: {
    fontSize: { xs: 26, md: 16 },
  },
  wrap: {
    display: 'flex',
    px: { xs: '12px', md: '2px' },
    py: { md: '1.5px' },
    gap: { xs: 2, md: '2px' },
  },
  menu: {
    zIndex: 3000,
    my: '8px !important',
    '& .MuiList-root': {
      p: 0,
    },
  },
  title: {
    display: 'flex',
    gap: 0.5,
    span: {
      flex: '0 0 16px',
      fontSize: 16,
    },
  },
};

const PANEL_NAME = 'reaction-right-panel';
type ReactionPickerProps = {
  anchorEl?: Element | null;
  selected?: ReactionValue[];
  container?: ResponsivePopperProps['menuProps']['container'];
  open: boolean;
  onClose: () => void;
  onBlurClose?: () => void;
  onMoreClick?: (e: MouseEvent) => void;
  customMoreLabel?: string;
  onChange: (value: ReactionValue | null, e: MouseEvent) => void;
  recentEmojis?: EmojiValue[];
};
export default function ReactionPicker({
  anchorEl,
  container,
  open,
  selected,
  onClose,
  onBlurClose,
  onMoreClick,
  customMoreLabel,
  onChange,
  recentEmojis = [],
}: ReactionPickerProps) {
  const [panelId] = useState(v4());
  const panelName = `${PANEL_NAME}-${panelId}`;
  const wrapRef = useRef<HTMLDivElement>();
  const timerId = useRef<NodeJS.Timeout>();

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { rightPanelTarget, isTargetPanelOpened, openRightPanel } =
    useBaseRightPanel();
  const { tooltipState, pointerEnter, pointerLeave, clearTooltipState } =
    useControlledTooltip();
  const onCloseRef = useLatestValueRef(onClose);

  useEffect(() => {
    if (!open) clearTooltipState();

    const startAutoClose = () => {
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        onCloseRef.current();
      }, 500);
    };

    const stopAutoClose = () => {
      clearTimeout(timerId.current);
      timerId.current = undefined;
    };

    const handleMouseMove = (event: Event) => {
      const hoveredElement = event.target as Element;
      const hoverInside =
        (anchorEl && anchorEl.contains(hoveredElement)) ||
        (wrapRef.current && wrapRef.current.contains(hoveredElement));
      if (hoverInside) {
        stopAutoClose();
      } else if (!timerId.current) {
        startAutoClose();
      }
    };

    if (open && mdUp) {
      document.addEventListener('mousemove', handleMouseMove);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      stopAutoClose();
    };
  }, [open, mdUp, clearTooltipState, onCloseRef, anchorEl]);

  if (!anchorEl && mdUp) return null;

  const displayReaction = selected?.[0]?.code;
  const handleMoreClick = (e: MouseEvent) => {
    openRightPanel(panelName);
    onMoreClick?.(e);
    if (!mdUp) onClose();
  };

  return (
    <>
      <ResponsivePopper
        open={open}
        onClose={onClose}
        menuProps={{
          sx: styles.menu,
          anchorEl,
          placement: 'bottom-start',
          container,
        }}
        sheetProps={{ fixedHeight: true }}
      >
        <Box ref={wrapRef} sx={styles.wrap} onPointerLeave={onBlurClose}>
          {reactionSuggestions.map((reaction) => (
            <IconButton
              key={reaction.code}
              sx={styles.emoji}
              customSize={mdUp ? 28 : 40}
              selected={selected?.some((s) => s.code === reaction.code)}
              onClick={(e) => onChange(reaction, e)}
              onPointerEnter={(ev) => pointerEnter(ev, reaction.name)}
              onPointerLeave={pointerLeave}
            >
              {reaction.code}
            </IconButton>
          ))}
          <IconButton
            customSize={mdUp ? 28 : 40}
            onClick={handleMoreClick}
            selected={isTargetPanelOpened(panelName)}
            onPointerEnter={(ev) =>
              pointerEnter(ev, customMoreLabel || 'Add Reaction')
            }
            onPointerLeave={pointerLeave}
          >
            <TestAddIcon />
          </IconButton>
        </Box>
      </ResponsivePopper>

      {open && (
        <ControlledTooltip
          open={!!tooltipState.open && !!tooltipState.anchorEl}
          anchorEl={tooltipState.anchorEl}
          title={tooltipState.title}
        />
      )}

      {rightPanelTarget === panelName && (
        <BaseLayoutRightPanelPortal>
          <BaseLayoutRightPanel
            titleIcon={
              !displayReaction && <NFTFavoriteIcon width="16" height="16" />
            }
            title={
              displayReaction ? (
                <Box sx={styles.title}>
                  <span>{displayReaction}</span> Add Reaction
                </Box>
              ) : (
                'Add Reaction'
              )
            }
          >
            <ReactionPanelInner
              selected={selected ? uniqBy(selected, 'id') : undefined}
              onReactionClick={onChange}
              recentEmojis={recentEmojis}
            />
          </BaseLayoutRightPanel>
        </BaseLayoutRightPanelPortal>
      )}
    </>
  );
}
