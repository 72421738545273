type SortQuery =
  | {
      orderBy?: string;
      sortBy?: string;
      expandOrderBy?: string;
      expandSortBy?: string;
      expandGlobalOrderBy?: string;
      expandGlobalSortBy?: string;
    }
  | [string, string]
  | null
  | undefined
  | false;

export default function combineSort(...addSortQueries: SortQuery[]) {
  if (!addSortQueries.length) return null;

  const orderBy: string[] = [];
  const sortBy: string[] = [];
  const expandOrderBy: string[] = [];
  const expandSortBy: string[] = [];
  const expandGlobalOrderBy: string[] = [];
  const expandGlobalSortBy: string[] = [];

  // e.g. expandOrderBy=clubCreator:clubCreatorSourceId&expandSortBy=asc
  const isExpand = (value: string) => value.includes(':');

  for (const addSortQuery of addSortQueries) {
    if (addSortQuery && typeof addSortQuery !== 'boolean') {
      if (Array.isArray(addSortQuery)) {
        const [addSortQueryOrderBy, addSortQuerySortBy] = addSortQuery;

        if (isExpand(addSortQueryOrderBy)) {
          expandOrderBy.push(addSortQueryOrderBy);
          expandSortBy.push(addSortQuerySortBy);
        } else {
          orderBy.push(addSortQueryOrderBy);
          sortBy.push(addSortQuerySortBy);
        }
        continue;
      }

      if (addSortQuery.orderBy) {
        orderBy.push(addSortQuery.orderBy);
      }
      if (addSortQuery.sortBy) {
        sortBy.push(addSortQuery.sortBy);
      }
      if (addSortQuery.expandOrderBy) {
        expandOrderBy.push(addSortQuery.expandOrderBy);
      }
      if (addSortQuery.expandSortBy) {
        expandSortBy.push(addSortQuery.expandSortBy);
      }
      if (addSortQuery.expandGlobalSortBy) {
        expandGlobalSortBy.push(addSortQuery.expandGlobalSortBy);
      }
      if (addSortQuery.expandGlobalOrderBy) {
        expandGlobalOrderBy.push(addSortQuery.expandGlobalOrderBy);
      }
    }
  }

  return {
    sortBy: sortBy.join(';'),
    orderBy: orderBy.join(';'),
    expandSortBy: expandSortBy.join(';'),
    expandOrderBy: expandOrderBy.join(';'),
    expandGlobalSortBy: expandGlobalSortBy.join(';'),
    expandGlobalOrderBy: expandGlobalOrderBy.join(';'),
  };
}
