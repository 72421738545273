import useSWRImmutable from 'swr/immutable';
import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';
import { format } from 'date-fns';

import { ViewSlugInfiniteHookResponse } from '../../types/base/view';
import {
  CampaignDiscountCodeViewSlug,
  PromoCodeQueryType,
  WalletActionType,
} from '../../types/enums';

import generateHook, {
  generateInfiniteHook,
  SwrHelperParams,
} from './swr.helper';

export const useMyPromoCode = (type?: PromoCodeQueryType) =>
  generateHook<GetMyPromoCodeRes[]>(
    `marketplace/v1/promo-code/me${type ? `?type=${type}` : ''}`,
    {
      auth: true,
      immutable: true,
    }
  );
export const useMyAvatar = (memberId?: NullableString) =>
  generateHook<GetUserAvatarRes | null>(
    memberId ? `marketplace/v1/user/product/nft/select/${memberId}` : null,
    {
      auth: true,
      immutable: true,
    }
  );
export const useMyCurrency = () =>
  generateHook<GetUserCurrencyRes>('marketplace/v1/user/currency/me', {
    auth: true,
    immutable: true,
  });
export const useDiscountAmount = (currency: string, amount: number) =>
  generateHook<GetDiscountAmountRes>(
    `marketplace/v1/reward/discount-amount?currency=${currency}&amount=${amount}`,
    {
      auth: true,
      immutable: true,
    }
  );
export const useDiscountCodeActivationList = (pageSize = 10) =>
  generateInfiniteHook<GetDiscountCodeActivationRes>(
    (index) =>
      `/marketplace/v1/promo-code/activation?page=${
        index + 1
      }&limit=${pageSize}`,
    pageSize,
    {
      auth: true,
    }
  );

export const useMyOwnProducts = (limit = 100) =>
  generateHook<GetMyOwnProductRes>(
    `/marketplace/v1/user/product/owner/me?limit=${limit}`,
    {
      auth: true,
      immutable: true,
      paginate: true,
    }
  );
export const useMyFavoriteProductInfo = (productId?: string) =>
  generateHook<GetMyFavoriteProductInfoRes>(
    productId ? `/marketplace/v1/product/${productId}/favorite/me` : null,
    {
      auth: true,
      immutable: true,
    }
  );
export const useMyFavoriteProducts = (limit = 100) =>
  generateHook<GetMyFavoriteProductRes>(
    `/marketplace/v1/user/product/favorite/me?limit=${limit}`,
    {
      auth: true,
      immutable: true,
      paginate: true,
    }
  );
export const useProductOwner = (productId?: string) =>
  generateHook<GetProductOwnerRes>(
    productId ? `/marketplace/v1/product/${productId}/owner` : null,
    {
      auth: true,
      immutable: true,
    }
  );

const getProductOrderInfoApiKey = (productId?: string, orderId?: string) => {
  if (productId) return `/marketplace/v1/product/order?productId=${productId}`;
  if (orderId) return `/marketplace/v1/product/order?orderId=${orderId}`;
};
export const useProductOrderInfo = (productId?: string, orderId?: string) =>
  generateHook<GetProductOrderInfoRes>(
    getProductOrderInfoApiKey(productId, orderId),
    {
      auth: true,
      immutable: true,
    }
  );

export const useSharedUserProfile = (sid?: string) =>
  generateHook<GetSharedUserProfileRes>(
    sid ? `/marketplace/v1/shared/user/profile?sid=${sid}` : null,
    {
      immutable: true,
    }
  );
export const useSharedProductOrderInfo = (sid?: string) =>
  generateHook<GetProductOrderInfoRes>(
    sid ? `/marketplace/v1/shared/product/order?sid=${sid}` : null,
    {
      immutable: true,
    }
  );
export const useSharedSubscriptionOrderInfo = (sid?: string) =>
  generateHook<GetSubscriptionOrderInfoRes>(
    sid ? `/marketplace/v1/shared/subscription/order?sid=${sid}` : null,
    {
      immutable: true,
    }
  );

export const useAiTokenBalance = ({
  customDate,
}: {
  customDate?: Date;
} = {}) => {
  const params = new URLSearchParams();
  if (customDate) {
    params.set('customDate', format(customDate, 'yyyy-MM-dd'));
  }
  return generateHook<GetAiTokenBalanceRes>(
    `marketplace/v1/ai-token/balance?${params.toString()}`,
    {
      auth: true,
    }
  );
};

export const useWalletActivity = ({
  customDate,
  actionType = 0,
  pageSize = 20,
}: {
  actionType?: WalletActionType | 0;
  customDate?: Date;
  pageSize?: number;
} = {}) =>
  generateInfiniteHook<GetWalletActivityRes>(
    (index) => {
      const params = new URLSearchParams();

      params.set('page', String(index + 1));
      params.set('limit', String(pageSize));
      params.set('actionType', String(actionType));

      if (customDate) {
        params.set('customDate', format(customDate, 'yyyy-MM-dd'));
      }
      return `marketplace/v1/wallet/activity?${params.toString()}`;
    },
    pageSize,
    {
      auth: true,
    }
  );

interface MarketplaceResponse<T> {
  data: T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const res = await fetch(input, init);
  if (!res.ok) {
    const error = await res.json();
    throw error;
  }

  return res.json();
}

export const useProducts = () =>
  useSWRImmutable<MarketplaceResponse<GetSanityProductRes[]>>(
    '/api/marketplace/products',
    fetcher
  );

export const useProduct = (productId: string) =>
  useSWRImmutable<MarketplaceResponse<GetSanityProductRes>>(
    productId ? `/api/marketplace/products/${productId}` : null,
    fetcher
  );

export function useIaCampaignDiscountCodeList(
  query: IaSearchReq<CampaignDiscountCodeViewSlug.TableCenterIaCampaignDiscountCodeDefault>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaCampaignDiscountCodeTableCenterDefaultViewRes>;
export function useIaCampaignDiscountCodeList(
  query: Omit<IaSearchReq<CampaignDiscountCodeViewSlug>, 'page'> = {},
  config: SwrHelperParams = {}
): ViewSlugInfiniteHookResponse<GetIaCampaignDiscountCodeTableCenterDefaultViewRes> {
  const limit = query.limit || 0;

  return generateInfiniteHook(
    (index) =>
      `/marketplace/v1/ia/campaign-discount-code?${getIaQueryString({
        ...query,
        page: index + 1,
        limit,
      })}`,
    limit,
    { auth: true, ...config }
  );
}
