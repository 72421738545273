import { useBaseRightPanel } from '@front/ui';

import { TextComposerPanelKeys, TextComposerPanelParams } from '../../panel';

import VariableForm from './VariableForm';
import VariableList from './VariableList';

export default function VariablePanel() {
  const { getRightParams } = useBaseRightPanel<TextComposerPanelParams>();

  const rightParams = getRightParams(
    TextComposerPanelKeys.TextComposerVariable
  );

  const { page } = rightParams;
  if (page === 'form') return <VariableForm />;

  return <VariableList />;
}
