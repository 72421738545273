import React from 'react';

export default function EditorScatterChart({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect height="2" width="18" fill="currentColor" rx="1" x="3" y="19" />
      <circle cx="9.5" cy="10.5" fill="currentColor" r="3.5" />
      <circle cx="16" cy="11" fill="currentColor" r="2" />
      <circle cx="15.5" cy="6.5" fill="currentColor" r="1.5" />
      <circle cx="7.5" cy="16.5" fill="currentColor" r="1.5" />
      <circle cx="19.5" cy="7.5" fill="currentColor" r="1.5" />
      <circle cx="20" cy="4" fill="currentColor" r="1" />
      <circle cx="4" cy="17" fill="currentColor" r="1" />
    </svg>
  );
}
