import React from 'react';

export default function ProfileUpgrade({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.0002 7.245L16.8347 12L18.9851 9.885L12.0002 3L5 9.885L7.16563 12L12.0002 7.245Z"
        fill="currentColor"
      />
      <path
        d="M7.16528 21L11.9998 16.245L16.8344 21L19 18.885L11.9998 12L5.01489 18.885L7.16528 21Z"
        fill="currentColor"
      />
    </svg>
  );
}
