import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridInput, GridInputProps } from '@lib/web/ui';

const styles = {
  answer: {
    px: 2,
    mb: 2,
    height: 52,
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  successAnswer: {
    bgcolor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'success.light' : 'success.dark',
    color: 'background.default',
  },
  errorAnswer: {
    bgcolor: 'error.dark',
    color: 'common.white',
  },
  correctAnswer: {
    bgcolor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.success.light, 0.3)
        : alpha(theme.palette.success.dark, 0.3),
  },
};

type CorrectGridInProps = {
  value: string;
};

type IncorrectGridInProps = {
  answer: string;
  value: string;
};

type GridInOptionProps = GridInputProps & {
  answer: string;
  isSubmitted: boolean;
};

const CorrectGridIn = ({ value }: CorrectGridInProps) => (
  <>
    <Box sx={[styles.answer, styles.successAnswer]} mt={2.5}>
      <Typography fontWeight={700}>Your Answer:</Typography>
    </Box>
    <GridInput value={value} success disabled />
  </>
);

const IncorrectGridIn = ({ value, answer }: IncorrectGridInProps) => (
  <>
    <Box sx={[styles.answer, styles.errorAnswer]}>
      <Typography fontWeight={700}>Your Answer:</Typography>
    </Box>
    <GridInput value={value} error disabled />
    <Box sx={[styles.answer, styles.correctAnswer]} mt={2.5}>
      <Typography fontWeight={700}>Correct Answer:</Typography>
    </Box>
    <GridInput value={answer} correctAnswer disabled />
  </>
);

export default function GridInOption({
  isSubmitted,
  error,
  success,
  answer,
  value,
  ...rest
}: GridInOptionProps) {
  if (isSubmitted)
    return (
      <>
        {error && <IncorrectGridIn value={value} answer={answer} />}
        {success && <CorrectGridIn value={value} />}
      </>
    );

  return <GridInput value={value} inputCount={4} {...rest} />;
}
