/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  differenceInDays,
  differenceInSeconds,
  format,
  formatDistanceToNowStrict,
  isFuture,
  isPast,
  isSameYear,
  isToday,
  isTomorrow,
  isValid,
  isYesterday,
} from 'date-fns';

const isEmpty = (value: any) =>
  typeof value === 'undefined' || value === null || value === false;

export const DEFAULT_DATE_FORMAT = 'MMM dd, ‘yy';
export const DEFAULT_TODAY_FORMAT = 'eee, MMM dd';
export const DEFAULT_DATE_SAME_YEAR_FORMAT = 'MMM dd';

export const DEFAULT_DATE_DIVIDER_SAME_YEAR_FORMAT = 'EEEE, MMMM do';
export const DEFAULT_DATE_DIVIDER_DIFFERENT_YEAR_FORMAT = 'PPP';

export const DEFAULT_DATE_TABLE_FORMAT = 'MMM dd, yyyy';

export const DEFAULT_MESSAGE_TIME_FORMAT = 'h:mm a';
export const DEFAULT_MESSAGE_DATETIME_SAME_YEAR_FORMAT = 'MMM dd, h:mm a';
export const DEFAULT_MESSAGE_DATETIME_DIFFERENT_TEAR_FORMAT =
  'MMM dd, yyyy, h:mm a';

export const DEFAULT_DATETIME_FORMAT = "MMM dd, ‘yy 'at' hh:mm a";
export const DEFAULT_DATETIME_SAME_YEAR_FORMAT = "MMM d 'at' hh:mm a";

export default function useDateFormat() {
  const { t } = useTranslation('common');
  const weekDateFormat = useCallback((date: any) => {
    const dateObj = new Date(date);
    return format(dateObj, DEFAULT_TODAY_FORMAT);
  }, []);
  const dateFormat = useCallback(
    <I = string, F = string>(
      date: any,
      {
        initial,
        fallback,
        showToday = false,
        hideSameYear = false,
      }: {
        initial?: I;
        fallback?: F;
        showToday?: boolean;
        hideSameYear?: boolean;
      } = {}
    ) => {
      if (isEmpty(date)) return initial ?? '';
      const dateObj = new Date(date);

      if (!isValid(dateObj)) {
        return fallback === undefined ? initial ?? '' : fallback ?? '';
      }
      if (showToday && isToday(dateObj)) {
        return t('Today');
      }
      return format(
        dateObj,
        hideSameYear && isSameYear(dateObj, new Date())
          ? DEFAULT_DATE_SAME_YEAR_FORMAT
          : DEFAULT_DATE_FORMAT
      );
    },
    [t]
  );

  const dateTableFormat = useCallback(
    <I = string, F = string>(
      date: any,
      {
        initial,
        fallback,
        hideSameYear = false,
      }: { initial?: I; fallback?: F; hideSameYear?: boolean } = {}
    ) => {
      if (isEmpty(date)) return initial ?? '';
      const dateObj = new Date(date);

      if (!isValid(dateObj)) {
        return fallback === undefined ? initial ?? '' : fallback ?? '';
      }
      return format(
        dateObj,
        hideSameYear && isSameYear(dateObj, new Date())
          ? DEFAULT_DATE_SAME_YEAR_FORMAT
          : DEFAULT_DATE_TABLE_FORMAT
      );
    },
    []
  );

  const displayDateFormat = useCallback(
    <I = string, F = string>(
      date: any,
      {
        addSuffix = true,
        addPrefix = true,
        showToday = false,
        initial,
        fallback,
      }: {
        addPrefix?: boolean;
        addSuffix?: boolean;
        initial?: I;
        fallback?: F;
        showToday?: boolean;
      } = {}
    ) => {
      if (isEmpty(date)) return initial || '';
      const dateObj = new Date(date);
      if (!isValid(dateObj)) {
        return fallback === undefined ? initial || '' : fallback || '';
      }

      if (isTomorrow(dateObj)) return t('Tomorrow');

      // Student should see date and time formatting for yesterday is ‘Yesterday’
      if (isYesterday(dateObj)) return t('Yesterday');

      const today = new Date();

      if (showToday && isToday(dateObj)) {
        return t('Today');
      } else if (isPast(dateObj) || isToday(dateObj)) {
        if (differenceInSeconds(today, dateObj) < 60) {
          return t('Less than a minute');
        }
        if (differenceInDays(today, dateObj) < 7) {
          // Student should see date and time formatting for within current year and is within a week ago is ‘# day ago’
          // Student should see date and time formatting for today is ‘X hours ago’
          return formatDistanceToNowStrict(dateObj, { addSuffix });
        }
      } else if (isFuture(dateObj)) {
        return formatDistanceToNowStrict(dateObj, { addSuffix });
      }

      // Student should see date and time formatting for within current year and more than a week ago is ‘MMM DD’
      if (isSameYear(dateObj, today))
        return addPrefix
          ? t('on date', {
              date: format(dateObj, DEFAULT_DATE_SAME_YEAR_FORMAT),
            })
          : format(dateObj, DEFAULT_DATE_SAME_YEAR_FORMAT);

      // Student should see date formatting that is used for DISPLAY from initially MMM DD, YYYY to MMM DD, ‘YY (Only for display, only for dates in previous years)
      return addPrefix
        ? t('on date', { date: format(dateObj, DEFAULT_DATE_FORMAT) })
        : format(dateObj, DEFAULT_DATE_FORMAT);
    },
    [t]
  );

  const displaySpecificDateFormat = useCallback(
    <I = string, F = string>(
      date: any,
      {
        initial,
        fallback,
        hideSameYear,
      }: {
        initial?: I;
        fallback?: F;
        hideSameYear?: boolean;
      } = {}
    ) => {
      if (isEmpty(date)) return initial || '';
      const dateObj = new Date(date);
      if (!isValid(dateObj)) {
        return fallback === undefined ? initial || '' : fallback || '';
      }

      if (isTomorrow(dateObj)) return t('Tomorrow');
      if (isYesterday(dateObj)) return t('Yesterday');
      if (isToday(dateObj)) return t('Today');

      return format(
        dateObj,
        hideSameYear && isSameYear(dateObj, new Date())
          ? DEFAULT_DATE_SAME_YEAR_FORMAT
          : DEFAULT_DATE_TABLE_FORMAT
      );
    },
    [t]
  );

  const displayMessageTimeFormat = useCallback(
    <I = string, F = string>(
      date: any,
      {
        initial,
        fallback,
      }: {
        initial?: I;
        fallback?: F;
      } = {}
    ) => {
      if (isEmpty(date)) return initial || '';
      const dateObj = new Date(date);
      if (!isValid(dateObj)) {
        return fallback === undefined ? initial || '' : fallback || '';
      }

      if (isYesterday(dateObj))
        return `${t('Yesterday at')} ${format(
          dateObj,
          DEFAULT_MESSAGE_TIME_FORMAT
        )}`;
      if (isToday(dateObj))
        return `${t('Today at')} ${format(
          dateObj,
          DEFAULT_MESSAGE_TIME_FORMAT
        )}`;

      const today = new Date();

      if (isSameYear(dateObj, today))
        return format(dateObj, DEFAULT_MESSAGE_DATETIME_SAME_YEAR_FORMAT);

      return format(dateObj, DEFAULT_MESSAGE_DATETIME_DIFFERENT_TEAR_FORMAT);
    },
    [t]
  );

  const displayDatetimeFormat = useCallback(
    <I = string, F = string>(
      date: any,
      {
        initial,
        fallback,
      }: {
        initial?: I;
        fallback?: F;
      } = {}
    ) => {
      if (isEmpty(date)) return initial || '';
      const dateObj = new Date(date);
      if (!isValid(dateObj)) {
        return fallback === undefined ? initial || '' : fallback || '';
      }

      const today = new Date();

      if (isSameYear(dateObj, today))
        return format(dateObj, DEFAULT_DATETIME_SAME_YEAR_FORMAT);

      return format(dateObj, DEFAULT_DATETIME_FORMAT);
    },
    []
  );

  const displayDateDivider = useCallback(
    <I = string, F = string>(
      date: any,
      {
        initial,
        fallback,
      }: {
        initial?: I;
        fallback?: F;
      } = {}
    ) => {
      if (isEmpty(date)) return initial || '';
      const dateObj = new Date(date);
      if (!isValid(dateObj)) {
        return fallback === undefined ? initial || '' : fallback || '';
      }

      if (isToday(dateObj)) return t('Today');

      if (isYesterday(dateObj)) return t('Yesterday');

      const today = new Date();

      if (isSameYear(dateObj, today))
        return format(dateObj, DEFAULT_DATE_DIVIDER_SAME_YEAR_FORMAT);

      return format(dateObj, DEFAULT_DATE_DIVIDER_DIFFERENT_YEAR_FORMAT);
    },
    [t]
  );

  return {
    format: DEFAULT_DATE_FORMAT,
    inWeek: (dateObj: Date) => differenceInDays(dateObj, new Date()) < 7,
    weekDateFormat,
    dateFormat,
    dateTableFormat,
    displayDateFormat,
    displayMessageTimeFormat,
    displayDatetimeFormat,
    displayDateDivider,
    displaySpecificDateFormat,
  };
}
