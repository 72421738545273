interface RetryOptions {
  maxRetries: number;
  baseDelayMs: number;
  maxDelayMs: number;
  isRetryableError: (error: Error) => boolean;
}

const DEFAULT_OPTIONS: Omit<RetryOptions, 'isRetryableError'> = {
  maxRetries: 5,
  baseDelayMs: 1000, // 1 second
  maxDelayMs: 60000, // 1 minute
};

/**
 * Applies jitter to a delay value
 * @param delay - The original delay in milliseconds
 * @returns A "jittered" delay between 50% and 100% of the original delay
 *
 * For example, if delay is 1000ms:
 * Minimum jittered value: 500ms
 * Maximum jittered value: 1000ms
 */
function jitter(delay: number): number {
  return delay * (0.5 + Math.random() * 0.5);
}

function wait(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Executes an asynchronous operation with exponential backoff retry logic
 * @param operation - The async function to retry
 * @param options - Retry options
 * @returns The result of the operation
 *
 * @example
 * const result = await retryWithBackoff(
 *   async () => { // Your async operation here },
 *   {
 *     maxRetries: 3,
 *     baseDelay: 2000,
 *     maxDelay: 10000,
 *     isRetryableError: (error) => error.response?.status === 429
 *   }
 * );
 */
export const retryWithBackoff = async <T>(
  operation: () => Promise<T>,

  // only isRetryableError is required
  options: Partial<Omit<RetryOptions, 'isRetryableError'>> &
    Pick<RetryOptions, 'isRetryableError'>
): Promise<T> => {
  const config: RetryOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  let retries = 0;

  const executeWithRetry = async (): Promise<T> => {
    try {
      return await operation();
    } catch (error) {
      if (
        retries >= config.maxRetries ||
        !config.isRetryableError(error as Error)
      ) {
        throw error; // Rethrow if max retries reached or error is not retryable
      }

      retries++;
      // Calculate delay with exponential backoff
      // Example delays before jitter:
      // Retry 1: 2000ms (2s)
      // Retry 2: 4000ms (4s)
      // Retry 3: 8000ms (8s)
      // Retry 4: 16000ms (16s)
      // Retry 5: 32000ms (32s)
      const delay = Math.min(
        config.baseDelayMs * 2 ** retries,
        config.maxDelayMs
      );

      // Apply jitter to spread out the retry attempts
      const jitteredDelay = jitter(delay);

      await wait(jitteredDelay);
      return executeWithRetry(); // Recursive call for next attempt
    }
  };

  return executeWithRetry();
};
