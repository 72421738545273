import React, { Fragment } from 'react';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { ActionSlash as ActionSlashIcon } from '@front/icon';

import BreadcrumbsItem from '../components/BreadcrumbsItem';
import { BreadcrumbsValue } from '../types';

export type DesktopBreadcrumbsProps = {
  breadcrumbsValues: BreadcrumbsValue[];
};

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    },
    '&:active': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    },
    borderRadius: '4px',
  },
};

export default function DesktopBreadcrumbs({
  breadcrumbsValues,
}: DesktopBreadcrumbsProps) {
  return (
    <Box sx={styles.root}>
      {breadcrumbsValues.map(
        ({ text, icon, customizeComponent, href }, index) => {
          const isFirst = index === 0;
          const isLast = index === breadcrumbsValues.length - 1;

          return (
            <Fragment key={`${text}-${href}`}>
              {!isFirst && <ActionSlashIcon height={20} width={24} />}
              {!isLast && href ? (
                <Link href={href}>
                  <BreadcrumbsItem
                    text={text}
                    icon={icon}
                    customizeComponent={customizeComponent}
                    sx={styles.item}
                  />
                </Link>
              ) : (
                <BreadcrumbsItem
                  text={text}
                  icon={icon}
                  customizeComponent={customizeComponent}
                />
              )}
            </Fragment>
          );
        }
      )}
    </Box>
  );
}
