import { useMemo } from 'react';
import { apis, useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { getNewMentionMemberIdsFromBlocks } from '@lib/web/thread/ThreadTextComposer';
import { streamDateToString } from '@lib/web/thread/utils/streamUtils';
import { call } from '@lib/web/utils';
import { uniq } from 'lodash';
import { Channel, ChannelData } from 'stream-chat';
import { v4 } from 'uuid';

import { AgentMaterial, StreamChatGenerics } from '../../types';
import { useThread } from '../core/useThread';
import { useBuildMessageData } from '../message/useBuildMessageData';
import { useThreadMessageExternalPayload } from '../message/useThreadMessageExternalPayload';

import { useChannelSystemMessage } from './useChannelSystemMessage';

export const useCreateNewChannelWithMessage = () => {
  const { chatClient } = useThread();
  const { member } = useAuth();
  const { buildMessageData } = useBuildMessageData();
  const { maybeSendAgentStatusErrorMessage } = useChannelSystemMessage();
  const { setThreadMessageExternalPayload } = useThreadMessageExternalPayload();

  return useMemo(() => {
    return {
      createNewChannelWithMessage: async ({
        channelId,
        message,
        blocks,
        channelData,
        sendPublicly,
        agentId,
        agentMaterials,
      }: {
        channelId?: string;
        message: string;
        blocks: ComposerBlock[];
        channelData: ChannelData;
        sendPublicly: boolean;
        agentId?: string;
        agentMaterials?: AgentMaterial[];
      }) => {
        if (!chatClient || !member) return;

        const channelType = sendPublicly ? 'public' : 'team';
        const newMentionMemberIds = getNewMentionMemberIdsFromBlocks(blocks);
        const newChannelMemberIds = uniq([
          ...(channelData.members || []),
          ...newMentionMemberIds,
          ...(agentId ? [`agent_${agentId}`] : []),
        ]);
        const id = channelId || v4();

        const myMemberId = member.memberId;

        try {
          const newChannel = chatClient.channel(channelType, id, {
            ...channelData,
            /**
             * instead of using channelData.members, we only include self member id
             * because some user may not be created yet, so we omit them, leave the invite task for backend
             */
            members: [myMemberId],
          }) as Channel<StreamChatGenerics>;

          await newChannel.create();

          /**
           * we must follow this order :
           * 1. invite member
           * 2. send first message
           * 3. send system message
           *
           * to ensure
           * 1. the first message is 'unread' for invited members
           * 2. the system message is followed by the first message
           */
          let invitedMemberResult: InviteMemberRes | null = null;
          if (newChannelMemberIds.length > 1) {
            // Only invite members when there are multiple members
            const invitedMemberResp = await call(
              apis.thread.inviteMember({
                channelType,
                channelId: id,
                memberIds: newChannelMemberIds,
              })
            );
            invitedMemberResult = invitedMemberResp?.[0]?.data || null;
          }

          const messageData = buildMessageData({
            text: message,
            blocks,
            agentMaterials,
          });

          const newMessageResult = await newChannel.sendMessage(messageData);

          if (messageData.externalPayloadId) {
            void setThreadMessageExternalPayload({
              payloadId: messageData.externalPayloadId,
              payload: { customBlocks: blocks },
              threadMemberId: member.memberId,
              channelCid: newChannel.cid,
            });
          }

          void newChannel.updatePartial({
            set: {
              firstMessageId: newMessageResult.message.id,
              firstMessageText: messageData.text,
              firstMessageCustomBlocks: messageData.customBlocks,
              firstMessageAgentMaterials: messageData.agentMaterials,
              firstMessageExternalPayloadId: messageData.externalPayloadId,
              firstMessageCreatedById: member.memberId,
              firstMessageCreatedAt: streamDateToString(
                newMessageResult.message.created_at
              ),
            },
          });

          if (newChannelMemberIds.length > 1) {
            // Only send system message for member invitation when there are multiple members
            await call(
              apis.thread.systemMessageMemberInvited({
                channelType,
                channelId: id,
                memberIds: newChannelMemberIds,
              })
            );
          }

          maybeSendAgentStatusErrorMessage(newChannel);

          await newChannel.watch();
          void newChannel.markRead();

          return {
            newChannel,
            newChannelMemberIds,
            viewAfterId: invitedMemberResult?.afterInvitedDmViewId || null,
          };
        } catch (e) {
          console.error('createNewChannelWithMessage failed', e);
        }
      },
    };
  }, [
    buildMessageData,
    chatClient,
    maybeSendAgentStatusErrorMessage,
    member,
    setThreadMessageExternalPayload,
  ]);
};
