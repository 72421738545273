export const enum AwardBadgeCategory {
  Unknown = 0,
  Challenges = 1,
  CloseYourRings = 2,
  LimitedEdition = 3,
  OnFire = 4,
  SocialButterfly = 5,
}

export const enum AwardBadgeLevelUpType {
  NoLevel = 0,
  MultiLevel = 1,
}

export const enum AwardBadgeProgressType {
  NoProgress = 0,
  Progress = 1,
}

export const enum AwardBadgeApplyType {
  Global = 0,
  Club = 1,
  Template = 2,
}

export const enum AwardBadgeValidateType {
  Unknown = 0,
  Assert = 1,
  InternalCheck = 2,
}

export const enum AwardBadgeAwardType {
  Unknown = 0,
  Practice = 1,
  Challenge = 2,
  League = 3,
  GoalRing = 4,
  SpecialDate = 5,
  Streak = 6,
  Social = 7,
  Profile = 8,
}

/**
 * Details [here](https://www.notion.so/rootdomain/ENV-OP-PRF-354-1-1-Award-Badge-Content-d373c8bd6eea4b3cb8cc30a4756a1bbe)
 */
export const enum AwardBadgeSubType {
  challenger__challenge_start_count = 201,
  champion__league_rank_1 = 311,
  legend__league_advanced_tier = 301,
  participant__challenge_taken_count = 202,
  sharpshooter__no_mistake_practice_taken_count = 112,
  unrivaled__league_commandus_rank_1 = 312,
  winner__challenge_taken_rank_1_count = 203,
  club_mock_master__taken_count = 431,
  bulls_eye__goal_ring_complete_day_count = 401,
  first_club_action__mock = 441,
  first_club_action_practice = 442,
  perfect_week_all__goal_ring_perfect_week_all_count = 411,
  perfect_week_mock__goal_ring_perfect_week_mock_count = 412,
  perfect_week_practice__goal_ring_perfect_week_practice_count = 413,
  perfect_week_problems__goal_ring_perfect_week_question_count = 414,
  practice_goal_2x__goal_ring_goal_multiply_2 = 421,
  practice_goal_3x__goal_ring_goal_multiply_3 = 422,
  scholar__practice_question_taken_count = 111,
  cupids_study_session__special_date_practice = 501,
  eco_friendly_expert__special_date_practice = 502,
  egg_cellent_student__special_date_practice = 503,
  jolly_study_session__special_date_practice = 504,
  mothers_day__special_date_practice = 505,
  new_years_nerd__special_date_practice = 506,
  party_and_practice__special_date_practice = 507,
  photogenic__add_profile_picture = 801,
  pot_o_knowledge__special_date_practice = 508,
  slice_of_study_pie__special_date_practice = 509,
  spooky_scholar__special_date_practice = 510,
  stars_stripes_and_study__special_date_practice = 511,
  longest_practice_streak__streak_new_record = 601,
  wild_fire__streak_count = 602,
  friendly__social_friend_count = 702,
  influencer__social_refer_count = 703,
  new_user__user_signed_up = 701,
}

export const enum AwardBadgeGoalType {
  Unknown = 0,
  CountMatch = 1,
  DateMatch = 2,
  ValueMatch = 3,
}

export const enum AwardBadgeStatus {
  Unknown = 0,
  Enable = 1,
  Disable = -1,
}

export const enum AwardBadgeLevelStatus {
  Unknown = 0,
  Enable = 1,
  Disable = -1,
}

export const enum AwardBadgeLevelUserStatus {
  Unknown = 0,
  Progress = 1,
  Earned = 2,
  Archived = 8,
}

export const enum AwardBadgeViewSlug {
  CardCenterIaBadgeDefault = 'card_center_ia_badge_default',
  ProfileCenterIaBadgePracticeResult = 'profile_center_ia_badge_practice_result',
  ProfileCenterIaBadgeAlmostThere = 'profile_center_ia_badge_almost_there',
  ProfileCenterIaBadgeCategory = 'profile_center_ia_badge_category',
}
