import { forwardRef, useEffect, useState } from 'react';
import { alpha, Box, Theme, Typography } from '@mui/material';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionChevronUp as ActionChevronUpIcon,
  ChatQuestionList as ChatQuestionListIcon,
} from '@front/icon';
import { Scrollbar } from '@front/ui';
import { AnswerFormatType, PinnedQuestionMode } from '@lib/web/apis';

import usePracticeQuestion from '../../../../hooks/usePracticeQuestion';
import usePracticeQuestionAnswers from '../../../../hooks/usePracticeQuestionAnswers';
import usePracticeStatus from '../../../../hooks/usePracticeStatus';
import QuizAnswerOptions, {
  AnswerOptionsContextProvider,
} from '../QuizAnswerOptions';
import QuizQuestion from '../QuizQuestion/QuizQuestion';

const styles = {
  pinnedWrapper: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  pinnedInner: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  pinnedAccordion: {
    px: '20px',
    py: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pinnedQuestionNo: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  pinnedQuestionScrollbar: {
    pt: 1,
    pb: 2,
    position: 'absolute',
    top: '40px',
    left: 0,
    right: 0,
    bottom: 0,
  },
  pinnedQuizQuestion: {
    px: '20px',
    '&:not(:empty)': {
      mb: 2,
    },
  },
  pinnedQuizOptions: {
    display: 'grid',
    px: '20px',
    '& .selection-hint': {
      px: '20px',
      mt: 1,
    },
  },
  pinnedQuizOptionsHorizontal: {
    px: '0',
  },
  pinnedQuizOptionsContainer: {
    overflow: 'hidden',
  },
  currentOption: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    fontSize: 12,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

type QuizPinnedQuestionProps = {
  viewMode: PinnedQuestionMode;
  onViewModeChange: (viewMode: PinnedQuestionMode) => void;
  scrollableNodeProps?: {
    ref?: any;
    className?: string;
    [key: string]: any;
  };
};

const ORDERED_VIEW_MODES = [
  PinnedQuestionMode.Minimization,
  PinnedQuestionMode.Vertical,
  PinnedQuestionMode.Horizontal,
];

const HORIZONTAL_QUESTION_TYPES = [
  AnswerFormatType.MultipleChoice,
  AnswerFormatType.MultipleResponse,
  AnswerFormatType.TrueFalseNotGiven,
];

const QuizPinnedQuestion = forwardRef<HTMLDivElement, QuizPinnedQuestionProps>(
  ({ viewMode, onViewModeChange, scrollableNodeProps }, ref) => {
    const { creatorQuestionInfo } = usePracticeQuestion();
    const { questionIndex } = usePracticeStatus();

    const [currentOption, setCurrentOption] = useState<string>();
    const { eliminatedAnswers } = usePracticeQuestionAnswers();

    useEffect(() => {
      const multipleChoicesWrapper = document.querySelector(
        '.quiz-options .multiple-choices-wrapper'
      );
      if (multipleChoicesWrapper) {
        multipleChoicesWrapper.scrollTo(0, 0);
      }
    }, []);

    useEffect(() => {
      const optionElements = document.querySelectorAll(
        '.quiz-options .multiple-choices-item'
      );
      let options: any = [];
      if (optionElements) {
        options = [...Array.from(optionElements)].map((item) => {
          const itemDiv = item as HTMLDivElement;
          return {
            offsetLeft: itemDiv.offsetLeft,
            offsetWidth: itemDiv.offsetWidth,
            symbol: itemDiv.getAttribute('data-symbol'),
          };
        });
      }

      const multipleChoicesWrapper = document.querySelector(
        '.quiz-options .multiple-choices-wrapper'
      );
      const handleScroll = () => {
        if (options && options.length > 0 && multipleChoicesWrapper) {
          if (multipleChoicesWrapper.scrollLeft === 0) {
            setCurrentOption(options[0].symbol);
          } else {
            for (const option of options) {
              if (
                multipleChoicesWrapper.scrollLeft >= option.offsetLeft &&
                multipleChoicesWrapper.scrollLeft <=
                  option.offsetLeft + option.offsetWidth
              ) {
                setCurrentOption(option.symbol);
              }
            }
          }
        } else {
          setCurrentOption(undefined);
        }
      };
      handleScroll();
      if (multipleChoicesWrapper) {
        multipleChoicesWrapper.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (multipleChoicesWrapper)
          multipleChoicesWrapper.removeEventListener('scroll', handleScroll);
      };
    }, [viewMode, eliminatedAnswers]);

    const handleChangeViewMode = () => {
      if (
        !HORIZONTAL_QUESTION_TYPES.includes(
          creatorQuestionInfo?.answerFormatType
        )
      ) {
        // not accept Horizontal view
        if (viewMode === PinnedQuestionMode.Vertical) {
          onViewModeChange(PinnedQuestionMode.Minimization);
        } else {
          onViewModeChange(PinnedQuestionMode.Vertical);
        }
        return;
      }

      const nextViewModeIndex =
        (ORDERED_VIEW_MODES.indexOf(viewMode) + 1) % ORDERED_VIEW_MODES.length;

      onViewModeChange(ORDERED_VIEW_MODES[nextViewModeIndex]);
    };

    const horizontal = viewMode === PinnedQuestionMode.Horizontal;

    return (
      <Box sx={[styles.pinnedWrapper]} ref={ref}>
        <Box sx={styles.pinnedInner}>
          <Box sx={styles.pinnedAccordion} onClick={handleChangeViewMode}>
            <Box sx={styles.pinnedQuestionNo}>
              <ChatQuestionListIcon width={16} height={16} />
              <Typography variant="body1">
                Question {questionIndex + 1}
              </Typography>
              {viewMode === PinnedQuestionMode.Horizontal && currentOption && (
                <Typography sx={styles.currentOption}>
                  {currentOption}
                </Typography>
              )}
            </Box>
            {viewMode !== PinnedQuestionMode.Minimization && (
              <ActionChevronDownIcon width={13} height={13} />
            )}
            {viewMode === PinnedQuestionMode.Minimization && (
              <ActionChevronUpIcon width={13} height={13} />
            )}
          </Box>
          <Scrollbar
            sx={styles.pinnedQuestionScrollbar}
            scrollableNodeProps={scrollableNodeProps}
          >
            <Box sx={[styles.pinnedQuizQuestion]}>
              <QuizQuestion />
            </Box>
            <Box
              sx={[
                styles.pinnedQuizOptions,
                horizontal && styles.pinnedQuizOptionsHorizontal,
              ]}
              className="quiz-options"
            >
              <Box sx={styles.pinnedQuizOptionsContainer}>
                <AnswerOptionsContextProvider horizontal={horizontal} pinned>
                  <QuizAnswerOptions />
                </AnswerOptionsContextProvider>
              </Box>
            </Box>
          </Scrollbar>
        </Box>
      </Box>
    );
  }
);

QuizPinnedQuestion.displayName = 'QuizPinnedQuestion';
export default QuizPinnedQuestion;
