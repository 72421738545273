import { useCallback, useContext } from 'react';

import { QueueWorkerContext } from '../contexts';

const getQueueKey = (scope: string, taskKey: string) => `${scope}:${taskKey}`;

export default function useQueueWorker() {
  const { timerMap, queueMap, workerIsProgressMap } =
    useContext(QueueWorkerContext);

  if (timerMap === null || queueMap === null || workerIsProgressMap === null) {
    throw new Error(
      'useQueueWorker must be used within QueueWorkerContextProvider'
    );
  }

  const debounceCheckQueue = useCallback(
    async (debounceTime: number, maxDebounceTime?: number) => {
      for (const queueKey in queueMap.current) {
        const queue = queueMap.current[queueKey];

        if (!queue || queue.length === 0) {
          continue;
        }

        clearTimeout(timerMap.current?.[queueKey]);

        const oldestQueueItem = queue[0];
        const latestQueueItem = queue[queue.length - 1];

        const withinDebounceTime =
          new Date().getTime() - latestQueueItem.timestamp.getTime() <
          debounceTime;
        const exceedMaxDebounceTime =
          maxDebounceTime &&
          new Date().getTime() - oldestQueueItem.timestamp.getTime() >
            maxDebounceTime;

        const workerInProgress = workerIsProgressMap.current[queueKey];

        if (
          workerInProgress ||
          (withinDebounceTime && !exceedMaxDebounceTime)
        ) {
          timerMap.current[queueKey] = setTimeout(
            () => debounceCheckQueue(debounceTime, maxDebounceTime),
            debounceTime
          );
          continue;
        }

        queueMap.current[queueKey] = [];
        workerIsProgressMap.current[queueKey] = latestQueueItem;
        await latestQueueItem.task();
        workerIsProgressMap.current[queueKey] = undefined;
      }
    },
    [queueMap, timerMap, workerIsProgressMap]
  );

  const sequentialCheckQueue = useCallback(async () => {
    for (const queueKey in queueMap.current) {
      while ((queueMap.current[queueKey]?.length || 0) > 0) {
        const queue = queueMap.current[queueKey];
        const workingItem = workerIsProgressMap.current[queueKey];

        if (!queue || queue.length === 0 || !!workingItem) {
          break;
        }

        const item = queue.shift();

        if (!item) break;

        workerIsProgressMap.current[queueKey] = item;
        await item.task();
        workerIsProgressMap.current[queueKey] = undefined;
      }
    }
  }, [queueMap, workerIsProgressMap]);

  const addTask = useCallback(
    ({
      scope,
      taskKey,
      task,
      debounceTime,
      maxDebounceTime = debounceTime ? debounceTime * 3 : undefined,
      shouldInterrupt,
    }: {
      scope: string;
      taskKey: string;
      task: () => void | Promise<void>;
      debounceTime?: number;
      maxDebounceTime?: number;
      shouldInterrupt?: boolean;
    }) => {
      queueMap.current[getQueueKey(scope, taskKey)] = [
        ...(queueMap.current[getQueueKey(scope, taskKey)] || []),
        { timestamp: new Date(), task, shouldInterrupt },
      ];

      if (debounceTime !== undefined) {
        void debounceCheckQueue(debounceTime, maxDebounceTime);
      } else {
        void sequentialCheckQueue();
      }
    },
    [debounceCheckQueue, queueMap, sequentialCheckQueue]
  );

  return {
    addTask,
  };
}
