import React from 'react';

export default function EditorUserDetails({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M12 12C13.8647 12 15.375 10.4897 15.375 8.625C15.375 6.76031 13.8647 5.25 12 5.25C10.1353 5.25 8.625 6.76031 8.625 8.625C8.625 10.4897 10.1353 12 12 12ZM12 13.6875C9.74718 13.6875 5.25 14.8181 5.25 17.0625V18.75H18.75V17.0625C18.75 14.8181 14.2528 13.6875 12 13.6875Z"
        fill="#080808"
      />
    </svg>
  );
}
