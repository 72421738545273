import type { Direction } from '@mui/material';
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import { base, exam } from '@front/ui';

import baseThemeOptions from './theme.base';

export interface Gradient {
  [key: string]: string[];
}

export interface Size {
  login: {
    xs: number;
    md: number;
  };
  topBarHeight: number;
}

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  mode: 'light' | 'dark';
}

const createTheme = (config: ThemeConfig) => {
  const oriTheme = config.mode === 'dark' ? base : exam;
  let theme = createMuiTheme(
    {
      ...oriTheme,
      breakpoints: baseThemeOptions.breakpoints,
    },
    baseThemeOptions,
    {
      direction: config.direction,
    }
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

export default createTheme;
