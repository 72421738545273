import React from 'react';

export default function MenuThumbsDownOutline({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...rest}
    >
      <path
        d="M4.36364 0.666748H9.27273C9.72546 0.666748 10.1127 0.933415 10.2764 1.31741L11.9236 5.07741C11.9727 5.20008 12 5.32808 12 5.46675V6.53341C12 7.12008 11.5091 7.60008 10.9091 7.60008H7.46727L7.98545 10.0374L8.00182 10.2081C8.00182 10.4267 7.90909 10.6294 7.76182 10.7734L7.18364 11.3334L3.58909 7.81875C3.39273 7.62675 3.27273 7.36008 3.27273 7.06675V1.73341C3.27273 1.14675 3.76364 0.666748 4.36364 0.666748ZM4.36364 7.06675L6.73091 9.38141L6 6.53341H10.9091V5.46675L9.27273 1.73341H4.36364V7.06675ZM2.18182 0.666748H0V7.06675H2.18182V0.666748Z"
        fill="currentColor"
      />
    </svg>
  );
}
