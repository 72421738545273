import React from 'react';

export default function ChatExplore({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.4443 20.3147C8.08879 21.4135 10.0222 22 12 22C14.6512 21.997 17.193 20.9424 19.0677 19.0677C20.9424 17.193 21.997 14.6512 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51809 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92894 4.92894C3.53041 6.32746 2.578 8.10929 2.19215 10.0491C1.8063 11.9889 2.00433 13.9996 2.76121 15.8268C3.51809 17.6541 4.79981 19.2159 6.4443 20.3147ZM12 3.42858C10.3047 3.42858 8.64754 3.93128 7.23797 4.87312C5.82841 5.81496 4.72979 7.15364 4.08104 8.71986C3.43229 10.2861 3.26254 12.0095 3.59327 13.6722C3.924 15.3349 4.74035 16.8622 5.93909 18.0609C7.13783 19.2597 8.66511 20.076 10.3278 20.4067C11.9905 20.7375 13.7139 20.5677 15.2801 19.919C16.8464 19.2702 18.185 18.1716 19.1269 16.762C20.0687 15.3525 20.5714 13.6953 20.5714 12C20.5688 9.72753 19.6649 7.54889 18.058 5.94201C16.4511 4.33513 14.2725 3.43122 12 3.42858ZM16.4499 7.0191C16.579 7.04963 16.697 7.11548 16.7907 7.20929C16.8845 7.30304 16.9504 7.42104 16.9809 7.5501C17.0114 7.67916 17.0054 7.81417 16.9636 7.94L14.8207 14.3686C14.7856 14.4739 14.7265 14.5695 14.648 14.648C14.5695 14.7265 14.4739 14.7856 14.3686 14.8207L7.94 16.9636C7.81415 17.0055 7.67909 17.0116 7.54997 16.9812C7.42085 16.9507 7.30277 16.8849 7.20896 16.7911C7.11515 16.6972 7.04931 16.5792 7.01884 16.45C6.98837 16.3209 6.99446 16.1859 7.03643 16.06L9.17929 9.63143C9.21439 9.52615 9.27352 9.43048 9.352 9.352C9.43048 9.27352 9.52615 9.21439 9.63143 9.17929L16.06 7.03643C16.1858 6.99457 16.3209 6.98857 16.4499 7.0191ZM10.4221 10.4229L8.84358 15.1571L13.5786 13.5793L10.4221 10.4229Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
