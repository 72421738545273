import React from 'react';
import { Box } from '@mui/material';
import { Icon } from '@front/ui';

const styles = {
  root: {
    width: 16,
    height: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'error.dark',
  },
};
export default function ErrorIcon({ icon }: { icon?: string }) {
  if (!icon) return null;
  return (
    <Box sx={styles.root}>
      <Icon name={icon} width={16} height={16} />
    </Box>
  );
}
