import { useEffect, useMemo, useRef, useState } from 'react';

/**
 * Find the first non nullable value and make it as promise
 * the normal use case is to make swr return value as promise
 * @param value
 */
export default function useFirstNonNullablePromise<T>(
  value: T
): Promise<Exclude<T, null | undefined>> {
  const [resolved, setResolved] = useState(false);
  const promiseResolve =
    useRef<(value: Exclude<T, null | undefined>) => void>();

  useEffect(() => {
    if (
      resolved ||
      value === null ||
      value === undefined ||
      !promiseResolve.current
    ) {
      return;
    }

    promiseResolve.current(value as Exclude<T, null | undefined>);
    setResolved(true);
  }, [resolved, value]);

  return useMemo(
    () =>
      new Promise<Exclude<T, null | undefined>>((resolve) => {
        promiseResolve.current = resolve;
      }),
    []
  );
}
