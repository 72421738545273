import { Box, useTheme } from '@mui/material';
import { OtherIndicatorUp as OtherIndicatorUpIcon } from '@front/icon';

import { numberFormat } from '../../utils';

interface DeltaArrowProps {
  value: number | string;
  reverseColor?: boolean;
  reverseArrow?: boolean;
  color?: string;
  arrowSize?: number;
}

const getColor = (
  value: number,
  reverseColor?: boolean,
  color?: string,
  isDark?: boolean
) => {
  if (color) return color;

  if (value === 0) return 'grey.700';

  const positiveColor = isDark ? 'success.light' : 'success.dark';
  const negativeColor = isDark ? 'error.dark' : 'error.dark';

  if (reverseColor) return value > 0 ? negativeColor : positiveColor;
  return value > 0 ? positiveColor : negativeColor;
};

const getTransform = (value: number, reverseArrow?: boolean) => {
  if (value === 0) return 'rotate(90deg)';

  // assume our arrow is up ↑
  const upArrowTransform = '';
  const downArrowTransform = 'rotate(180deg)';

  if (reverseArrow) return value > 0 ? downArrowTransform : upArrowTransform;
  return value > 0 ? upArrowTransform : downArrowTransform;
};

const styles = {
  arrow: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    transformOrigin: '50% 50%',
  },
};

export default function DeltaArrow({
  value,
  reverseColor,
  reverseArrow,
  color,
  arrowSize,
}: DeltaArrowProps) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const checkValue = numberFormat(+value) as number;
  const mainColor: string = getColor(checkValue, reverseColor, color, isDark);
  const transform: string = getTransform(checkValue, reverseArrow);

  return (
    <Box sx={[styles.arrow, { transform, color: mainColor }]}>
      <OtherIndicatorUpIcon width={arrowSize} height={arrowSize} />
    </Box>
  );
}
