import React from 'react';

export default function OtherLoading({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 4C8.95537 4 6.30628 5.70064 4.95366 8.20859L3.19336 7.25921C4.88102 4.13002 8.19109 2 12.0002 2C17.523 2 22.0002 6.47715 22.0002 12C22.0002 17.5228 17.523 22 12.0002 22C8.24161 22 4.96883 19.926 3.26139 16.8648L5.00806 15.8906C6.37661 18.3442 8.9957 20 12.0002 20C16.4184 20 20.0002 16.4183 20.0002 12C20.0002 7.58172 16.4184 4 12.0002 4Z"
        fill="currentColor"
      />
    </svg>
  );
}
