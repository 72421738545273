import { useMemo } from 'react';
import { useBaseRightPanel } from '@front/ui';

enum GlobalPanelKeys {
  GlobalThreadCreatorFromAskForHelpPanel = 'GlobalThreadCreatorFromAskForHelpPanel',
}

type GlobalPanelParams = {
  [GlobalPanelKeys.GlobalThreadCreatorFromAskForHelpPanel]: {
    askForHelpMemberIds: string[];
  };
};

export default function useSuggestionMembersLayoutAction({
  onClose,
}: {
  onClose: () => void;
}) {
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  return useMemo(
    () => ({
      selectChanged: {
        action: async ({ id }: { id: string }) => {
          openRightPanel(
            GlobalPanelKeys.GlobalThreadCreatorFromAskForHelpPanel,
            {
              askForHelpMemberIds: [id],
            }
          );
          onClose();
        },
      },
    }),
    [onClose, openRightPanel]
  );
}
