export const enum MarkGroupEnum {
  PerformanceMistake = 10,
  PerformanceOvertime = 11,
  PerformanceOutOfPractice = 12,

  EmojiSmileysAndPeople = 20,
  EmojiAnimalsAndNature = 21,
  EmojiFoodAndDrink = 22,
  EmojiActivity = 23,
  EmojiTravelAndPlaces = 24,
  EmojiObjects = 25,
  EmojiSymbols = 26,
  EmojiFlags = 27,

  BookmarkQuiz = 30,
  BookmarkQuizQuestionSet = 31,
  BookmarkQuizQuestion = 32,
}

export type EmojiValue = {
  id: string;
  name: string;
  code: string;
  type?: MarkGroupEnum;
};

export type ReactionValue = {
  id: string;
  name: string;
  code: string;
};

export type ReactionSource = ReactionValue & {
  type?: MarkGroupEnum;
};
