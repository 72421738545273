import rem from '../utils/rem';

const typography = {
  fontFamily: [
    'Ubuntu',
    '-apple-system',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1: {
    fontSize: rem(96),
    lineHeight: 1.5,
    letterSpacing: -0.15,
    '@media (max-width: 899.99px)': {
      fontSize: rem(60),
    },
  },
  h2: {
    fontSize: rem(60),
    lineHeight: 1.5,
    letterSpacing: -0.15,
    '@media (max-width: 899.99px)': {
      fontSize: rem(48),
    },
  },
  h3: {
    fontSize: rem(48),
    lineHeight: 1.5,
    letterSpacing: -0.15,
    '@media (max-width: 899.99px)': {
      fontSize: rem(36),
    },
  },
  h4: {
    fontSize: rem(36),
    lineHeight: 1.5,
    letterSpacing: -0.15,
    '@media (max-width: 899.99px)': {
      fontSize: rem(30),
    },
  },
  h5: {
    fontSize: rem(30),
    lineHeight: 1.5,
    letterSpacing: -0.15,
    '@media (max-width: 899.99px)': {
      fontSize: rem(24),
    },
  },
  h6: {
    fontSize: rem(24),
    lineHeight: 1.5,
    letterSpacing: -0.15,
    '@media (max-width: 899.99px)': {
      fontSize: rem(20),
    },
  },
  subtitle1: {
    fontSize: rem(20),
    lineHeight: 1.5,
    letterSpacing: -0.15,
    '@media (max-width: 899.99px)': {
      fontSize: rem(18),
    },
  },
  subtitle2: {
    fontSize: rem(16),
    lineHeight: 1.5,
    letterSpacing: 0.15,
  },
  body1: {
    fontSize: rem(16),
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  body2: {
    fontSize: rem(14),
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  button: {
    fontSize: rem(14),
    lineHeight: 1.5,
    letterSpacing: 0,
    textTransform: 'capitalize' as const,
  },
  caption: {
    fontSize: rem(12),
    lineHeight: '16px',
    letterSpacing: 0,
  },
  note: {
    fontFamily: "'Arial'",
    fontSize: rem(16),
    lineHeight: 1.5,
    letterSpacing: 0.25,
  },
  examSubtitle1: {
    fontFamily: "'Georgia'",
    fontSize: rem(30),
    lineHeight: 1.5,
    letterSpacing: 0.15,
  },
  examSubtitle2: {
    fontFamily: "'Georgia'",
    fontSize: rem(24),
    lineHeight: 1.5,
    letterSpacing: 0.15,
  },
  examHeader3: {
    fontFamily: "'Georgia'",
    fontSize: rem(20),
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  examBody: {
    fontFamily: "'Georgia'",
    fontSize: rem(16),
    lineHeight: 1.5,
    letterSpacing: 0.15,
  },
  examButton: {
    fontFamily: "'Georgia'",
    fontSize: rem(14),
    lineHeight: 1.5,
    letterSpacing: 0.15,
  },
  appH1: {
    fontSize: rem(30),
    lineHeight: 1.3,
    letterSpacing: 0,
    '@media (max-width: 899.99px)': {
      fontSize: rem(24),
    },
  },
  appH2: {
    fontSize: rem(24),
    lineHeight: 1.3,
    letterSpacing: 0,
    '@media (max-width: 899.99px)': {
      fontSize: rem(20),
    },
  },
  appH3: {
    fontSize: rem(20),
    lineHeight: 1.3,
    letterSpacing: 0,
    '@media (max-width: 899.99px)': {
      fontSize: rem(16),
    },
  },
  appBody: {
    fontSize: rem(16),
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  chatHeaderRegular: {
    fontSize: rem(16),
    lineHeight: 1,
    letterSpacing: 0,
  },
  chatHeaderBold: {
    fontSize: rem(16),
    lineHeight: 0.8,
    letterSpacing: 0,
    fontWeight: 700,
  },
  chatBody: {
    fontSize: rem(14),
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  numberH1: {
    fontFamily: "'Inconsolata', monospace",
    fontSize: rem(96),
    lineHeight: 1.5,
    letterSpacing: -2.5,
    '@media (max-width: 899.99px)': {
      fontSize: rem(60),
    },
  },
  numberH2: {
    fontFamily: "'Inconsolata', monospace",
    fontSize: rem(60),
    lineHeight: 1.5,
    letterSpacing: -2.5,
    '@media (max-width: 899.99px)': {
      fontSize: rem(48),
    },
  },
  numberH3: {
    fontFamily: "'Inconsolata', monospace",
    fontSize: rem(48),
    lineHeight: 1.5,
    letterSpacing: -2.5,
    '@media (max-width: 899.99px)': {
      fontSize: rem(36),
    },
  },
  numberH4: {
    fontFamily: "'Inconsolata', monospace",
    fontSize: rem(30),
    lineHeight: 1.5,
    letterSpacing: -2.5,
    '@media (max-width: 899.99px)': {
      fontSize: rem(24),
    },
  },
  numberH5: {
    fontFamily: "'Inconsolata', monospace",
    fontSize: rem(24),
    lineHeight: 1.5,
    letterSpacing: -2.5,
    '@media (max-width: 899.99px)': {
      fontSize: rem(20),
    },
  },
  numberBody1: {
    fontFamily: "'Inconsolata', monospace",
    fontSize: rem(20),
    lineHeight: 1.5,
    letterSpacing: -1.5,
  },
  numberBody2: {
    fontFamily: "'Inconsolata', monospace",
    fontSize: rem(16),
    lineHeight: 1.5,
    letterSpacing: -1.5,
  },
  numberBody3: {
    fontFamily: "'Inconsolata', monospace",
    fontSize: rem(14),
    lineHeight: 1.5,
    letterSpacing: -1,
  },
  numberCaption: {
    fontFamily: "'Inconsolata', monospace",
    fontSize: rem(12),
    lineHeight: 1.5,
    letterSpacing: 0,
  },
};

export default typography;
