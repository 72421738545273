import React from 'react';
import Box from '@mui/material/Box';
import { IaColorVariant } from '@lib/ia/src/core/types';

import { DisplayTableLayoutIndicatorCell } from '../../types';

export type IaDisplayTableIndicatorCellProps = DisplayTableLayoutIndicatorCell;

const colorMap: Record<IaColorVariant, string> = {
  default: 'grey.500',
  success: 'success.light',
  error: 'error.dark',
  info: 'primary.light',
  warning: 'warning.light',
  text: 'text.primary',
  yellow: 'highlight.yellow',
  orange: 'highlight.orange',
  red: 'highlight.red',
  green: 'highlight.green',
  blue: 'highlight.blue',
};

const styles = {
  root: {
    height: '100%',
  },
  indicator: {
    width: '4px',
    height: '100%',
  },
};
export default function IaDisplayTableIndicatorCell({
  value,
}: IaDisplayTableIndicatorCellProps) {
  const color =
    value && value in colorMap ? colorMap[value as IaColorVariant] : value;

  return (
    <Box sx={styles.root}>
      <Box sx={[styles.indicator, { backgroundColor: color }]} />
    </Box>
  );
}
