import { BlockSchema, TipTapNode } from '@blocknote/core';
import { BasicComposerSchema, basicComposerSchema } from '@lib/web/composer';
import ImageBlock, {
  imageBlockDefaultProps,
} from '@lib/web/thread/ThreadTextComposer/components/blocks/ImageBlock';

import AudioBlock, {
  audioBlockDefaultProps,
} from '../../EditorTextComposer/components/blocks/AudioBlock';

export const editorThreadComposerSchema = {
  ...basicComposerSchema,
  image: {
    propSchema: imageBlockDefaultProps,
    node: ImageBlock as TipTapNode<'image', false>,
  },
  audio: {
    propSchema: audioBlockDefaultProps,
    node: AudioBlock as TipTapNode<'audio', false>,
  },
} satisfies BlockSchema;

export type EditorThreadComposerSchema =
  | typeof editorThreadComposerSchema
  | BasicComposerSchema;
