import combineSearch from '@lib/ia/src/utils/combineSearch';
import {
  ThreadViewStatus,
  ThreadViewType,
  ThreadViewUserStatus,
} from '@lib/web/apis';
import { hashDmViewReferenceId } from '@lib/web/thread/hooks/view/utils';
import { uniq } from 'lodash';
import { v4 } from 'uuid';

export const parseFilterConfig = (filterConfig: string | null | undefined) => {
  const memberIds =
    filterConfig?.match(/memberId:(.*?)([&;+]|$)/)?.[1].split(',') || [];
  const clubId = filterConfig?.match(/clubId:(.*?)([&;+]|$)/)?.[1] || null;
  const tag = filterConfig?.match(/tag:(.*?)([&;+]|$)/)?.[1] || null;

  return {
    /**
     * XXX: Ideally, duplicate member IDs should not occur.
     * However, to handle such a corner-case error, ensure that each ID is unique.
     */
    memberIds: uniq(memberIds),
    clubId,
    tag,
  };
};

export const generateDmViewConfig = ({
  memberIds,
}: {
  memberIds: string[];
}): SetThreadViewReq => {
  const onlyUserTypeMemberIds = memberIds.filter(
    (m) => !m.startsWith('agent_')
  );

  const filterConfig = [
    `search=memberId:${memberIds.join(',')}`,
    'searchFields=memberId:eq',
  ].join('&');

  const sortConfig = ['orderBy=createdAt', 'sortBy=desc'].join('&');

  return {
    type:
      memberIds.length > 2 ? ThreadViewType.DmGroup : ThreadViewType.DmOneOnOne,
    memberIds: onlyUserTypeMemberIds, // only user type member need a view
    filterConfig,
    sortConfig,
    referenceId: hashDmViewReferenceId(memberIds),
  };
};

export const generateClubViewConfig = ({
  type,
  clubId,
  selfMemberId,
}: {
  type: ThreadViewType.ClubAllThread | ThreadViewType.Club;
  clubId: string;
  selfMemberId: string;
}): SetThreadViewReq => {
  const filterConfig = [
    `search=clubId:${clubId}`,
    'searchFields=clubId:eq',
  ].join('&');

  const sortConfig = ['orderBy=createdAt', 'sortBy=desc'].join('&');

  return {
    type,
    filterConfig,
    sortConfig,
    memberIds: [selfMemberId],
  };
};

export const generateLocationViewConfig = ({
  clubId,
  selfMemberId,
}: {
  clubId?: string;
  selfMemberId: string;
}): SetThreadViewReq => {
  const search = combineSearch([`clubId:${clubId}`, 'clubId:eq']);

  const filterConfig = clubId
    ? [`search=${search?.search}`, `searchFields=${search?.searchFields}`].join(
        '&'
      )
    : '';

  const sortConfig = ['orderBy=createdAt', 'sortBy=desc'].join('&');

  return {
    type: ThreadViewType.Location,
    filterConfig,
    sortConfig,
    memberIds: [selfMemberId],
  };
};

export const generateSolutionViewConfig = ({
  clubId,
  selfMemberId,
}: {
  clubId?: string;
  selfMemberId: string;
}): SetThreadViewReq => {
  const search = combineSearch(
    [`clubId:${clubId}`, 'clubId:eq'],
    ['tag:solution', 'tag:eq']
  );

  const filterConfig = clubId
    ? [`search=${search?.search}`, `searchFields=${search?.searchFields}`].join(
        '&'
      )
    : '';

  const sortConfig = ['orderBy=createdAt', 'sortBy=desc'].join('&');

  return {
    type: ThreadViewType.Location,
    filterConfig,
    sortConfig,
    memberIds: [selfMemberId],
  };
};

export const generateEmptyDmView = ({
  myMemberId,
  memberId,
}: {
  myMemberId: string;
  memberId: string;
}) => {
  const now = new Date().toISOString();
  const { filterConfig, sortConfig } = generateDmViewConfig({
    memberIds: [myMemberId, memberId],
  });

  return {
    type: ThreadViewType.DmOneOnOne,
    id: memberId,
    status: ThreadViewStatus.Active,
    createdAt: now,
    updatedAt: now,
    referenceId: null,
    name: null,
    emoji: null,
    sourceId: null,
    clubId: null,
    hideAt: null,
    threadViewUsers: [
      {
        id: v4(),
        createdAt: now,
        customEmoji: null,
        customName: null,
        filterConfig: filterConfig || '',
        sortConfig: sortConfig || '',
        memberId: myMemberId,
        status: ThreadViewUserStatus.Active,
        updatedAt: now,
      },
    ],
  };
};
