import { useEffect, useState } from 'react';

/**
 * When the container has a scrollbar and the side menu is out of view,
 * the default browser scrollbar will appear.
 * To prevent this, we need to hide the side menu when it is out of view.
 */
const useToggleSideMenu = (el: HTMLDivElement | null) => {
  const [show, setShow] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (!el) return;

    const scrollElement = el.closest('.simplebar-content-wrapper');

    const handleScroll = () => {
      setScrolled(true);
    };
    const handleMousemove = () => {
      /**
       * Use setTimeout to delay the state update because, in the case of TextComposer,
       * we need to wait for the side menu block to be updated to the newly hovered one.
       * Otherwise, the side menu will momentarily appear in the wrong position.
       */
      setTimeout(() => {
        /**
         * Using 'mousemove' instead of 'scrollend' event because, in the case of TextComposer,
         * we don't want the side menu to appear immediately after scrolling ends.
         * Otherwise, the side menu will be out of view before the mouse moves.
         */
        setScrolled(false);
      });
    };

    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
      scrollElement.addEventListener('mousemove', handleMousemove);
    }

    return () => {
      scrollElement?.removeEventListener('scroll', handleScroll);
      scrollElement?.removeEventListener('mousemove', handleMousemove);
    };
  }, [show, el]);

  return {
    setShowSideMenu: setShow,
    showSideMenu: show && !scrolled,
  };
};

export default useToggleSideMenu;
