import React, { MouseEvent, useState } from 'react';
import Router from 'next/router';
import {
  alpha,
  Box,
  ButtonBase,
  Skeleton,
  Theme,
  Typography,
} from '@mui/material';
import { SortableItem } from '@front/helper';
import {
  MaskIcon,
  SimpleTooltip,
  SquareAvatar,
  TipButton,
  useTruncated,
} from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import SideFloatingMenu from '@lib/ia/src/components/SideFloatingMenu';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { useIaItemStatus } from '@lib/ia/src/core/IaItemStatus/useIaItemStatus';

import {
  ListLayoutAreaAction,
  ListLayoutItem,
  ListLayoutItemEvent,
  ListLayoutItemEventHint,
} from '../types';

const styles = {
  root: {
    py: '2px',
    px: { xs: 2.5, md: 1.5 },
    minHeight: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'left',
    gap: 1,
    borderRadius: 0,
    width: '100%',
  },
  clickable: {
    '@media (hover: hover)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  selected: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  order: {
    flex: '0 0 24px',
  },
  icon: {
    flex: '0 0 24px',
  },
  main: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      ml: '-25px !important',
    },
    maxWidth: '200px',
  },
  itemEvents: {
    display: 'flex',
    gap: 0.5,
  },
  hidden: {
    '&. text': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    },
    '& .icon, & .order, & .text': {
      opacity: 0.5,
    },
  },
  highlight: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
};

type ListItemProps = {
  item: ListLayoutItem;
  index: number;
  areaHintActions?: ListLayoutAreaAction[];
  itemEventHint?: ListLayoutItemEventHint;
  order?: number;
  onEventClick?: (item: ListLayoutItem, event: ListLayoutItemEvent) => void;
};

export default function ListItem({
  item,
  areaHintActions,
  itemEventHint,
  order,
  onEventClick,
}: ListItemProps) {
  const { containerRef, isTruncated } = useTruncated();
  const { getIaAction } = useIaAction();
  const { getItemStatus } = useIaItemStatus<ListLayoutItem>();
  const [hovered, setHovered] = useState(false);

  const { disabled = false, selected = false } = getItemStatus
    ? getItemStatus(item)
    : {};

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleCloseMenu = () => {
    setHovered(false);
  };
  const handleEventClick = (
    itemEvent: ListLayoutItemEvent,
    mouseEvent: MouseEvent
  ) => {
    onEventClick?.(item, itemEvent);
    setHovered(false);

    if (mouseEvent) {
      mouseEvent.stopPropagation();
    }
  };

  const handleItemEvent = (type: 'click' | 'mouseEnter' | 'mouseLeave') => {
    const action = item.actionMap?.[type];
    if (!action) return;

    if (action.type === 'link') {
      Router.push(action.value);
    } else {
      getIaAction<ListLayoutItem>(action.value)?.action(item);
    }
  };

  if (item.loading) {
    return (
      <Box sx={styles.root}>
        {item.icon && (
          <MaskIcon>
            <Skeleton width={16} height={16} variant="rectangular" />
          </MaskIcon>
        )}
        <Box flex={1}>
          <Skeleton width="100%" />
        </Box>
      </Box>
    );
  }

  return (
    <SortableItem id={item.id}>
      {({ sortableItemEl, isGhostEl }) => (
        <Box
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          data-item-id={item.id}
        >
          {!isGhostEl && (
            <SideFloatingMenu
              item={item}
              anchorEl={sortableItemEl}
              active={hovered}
              eventHint={itemEventHint}
              onCloseMenu={handleCloseMenu}
              addActions={areaHintActions}
            />
          )}
          <ButtonBase
            sx={[
              styles.root,
              !disabled && styles.clickable,
              selected && styles.selected,
              !!item.hidden && styles.hidden,
              !!item.highlight && styles.highlight,
            ]}
            disableTouchRipple
            onClick={() => handleItemEvent('click')}
            onMouseEnter={() => handleItemEvent('mouseEnter')}
            onMouseLeave={() => handleItemEvent('mouseLeave')}
            component="div"
          >
            {order !== undefined && (
              <Typography className="order" sx={styles.order} variant="body2">
                {order}
              </Typography>
            )}
            {item.icon !== undefined && (
              <Box className="icon">
                {item.icon.type === 'image' && (
                  <SquareAvatar src={item.icon.value} size={16} />
                )}
                {item.icon.type === 'icon' && (
                  <Icon name={item.icon.value} width={16} height={16} />
                )}
              </Box>
            )}
            <Typography
              sx={styles.main}
              variant="body2"
              className="text"
              ref={containerRef}
            >
              {isTruncated ? (
                <SimpleTooltip
                  title={item.text}
                  slotProps={{
                    popper: {
                      sx: styles.tooltip,
                    },
                  }}
                >
                  <span>{item.text}</span>
                </SimpleTooltip>
              ) : (
                item.text
              )}
            </Typography>

            {!!item.moreActions?.length && hovered && (
              <Box sx={styles.itemEvents}>
                {item.moreActions
                  .filter((action) => !action.hideOnItem)
                  .map((event) => (
                    <TipButton
                      customSize={24}
                      title={event.text}
                      key={`${event.icon}-${event.value}`}
                      onClick={(ev) => handleEventClick(event, ev)}
                    >
                      <Icon name={event.icon} width={16} height={16} />
                    </TipButton>
                  ))}
              </Box>
            )}
          </ButtonBase>
        </Box>
      )}
    </SortableItem>
  );
}
