import React from 'react';

export default function MainRank2({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.3 11.7434C9.3 11.2456 9.70219 10.8435 10.2 10.8435H13.8C14.2978 10.8435 14.7 11.2456 14.7 11.7434V20.1001C14.7 20.5978 14.2978 21 13.8 21H10.2C9.70219 21 9.3 20.5978 9.3 20.1001V11.7434ZM3 13.5433C3 13.0455 3.40219 12.6433 3.9 12.6433H7.5C7.99781 12.6433 8.4 13.0455 8.4 13.5433V20.1001C8.4 20.5978 7.99781 21 7.5 21H3.9C3.40219 21 3 20.5978 3 20.1001V13.5433ZM15.6 20.1001V15.3431C15.6 14.8453 16.0022 14.4432 16.5 14.4432H20.1C20.5978 14.4432 21 14.8453 21 15.3431V20.1001C21 20.5978 20.5978 21 20.1 21H16.5C16.0022 21 15.6 20.5978 15.6 20.1001Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M12.9506 4.52252L12.2869 3.17824C12.1772 2.94482 11.8341 2.93639 11.7131 3.17824L11.0494 4.52252L9.57844 4.73344C9.31687 4.77281 9.20437 5.09623 9.39844 5.29027L10.4672 6.33082L10.2141 7.79602C10.1747 8.05757 10.4447 8.26005 10.6866 8.13912L12.0056 7.44168L13.3162 8.12788C13.5581 8.2488 13.8309 8.04632 13.7887 7.78478L13.5356 6.31957L14.6044 5.29027C14.7956 5.09904 14.6859 4.77562 14.4244 4.73344L12.9534 4.52252H12.9506Z"
        fill="currentColor"
      />
    </svg>
  );
}
