import React from 'react';

export default function OtherCheckedSquareSolid({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 12C3 4.8 4.8 3 12 3C19.2 3 21 4.8 21 12C21 19.2 19.2 21 12 21C4.8 21 3 19.2 3 12ZM17.062 9.12375C17.4224 9.478 17.4224 10.0389 17.062 10.3784V10.3636L11.4996 15.6035C11.3115 15.7806 11.0608 15.8691 10.7945 15.8691C10.5437 15.8544 10.293 15.7511 10.1207 15.5592L7.55097 12.74C7.22192 12.3562 7.26893 11.8101 7.66066 11.4854C8.06805 11.1754 8.6478 11.2197 8.99252 11.5887L10.8885 13.6846L15.7302 9.12375C16.1062 8.78427 16.7017 8.78427 17.062 9.12375Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
