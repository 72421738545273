import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ActionChevronFilledDown as ActionChevronFilledDownIcon } from '@front/icon';

export type DropdownFieldProps = Omit<BoxProps, 'children'> & {
  label: ReactNode;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  active?: boolean;
  prefixIcon?: ReactNode;
};
const styles = {
  label: {
    typography: 'body2',
  },
  inner: {
    height: 40,
    px: 2.5,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    border: '1px solid',
    borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    borderRadius: 2,
    transitionDuration: '0.3s',
    transitionProperty: 'background',
  },
  iconInner: {
    pl: '12px',
  },
  activeInner: {
    backgroundColor: 'background.darker',
    borderColor: 'background.darker',
  },
  errorInner: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.error.dark, 0.15),
    borderColor: 'error.dark',
  },
  hoverable: {
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
      },
    },
    '&:active': {
      backgroundColor: 'background.darker',
      borderColor: 'background.darker',
    },
  },
  disabled: {
    opacity: 0.5,
  },
  helperText: {
    mt: '2px',
    pl: '14px',
  },
  errorText: {
    color: 'error.dark',
  },
  defaultText: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
  toggleIcon: {
    ml: 'auto',
    width: 24,
    height: 24,
    transitionDuration: '0.3s',
  },
  activeToggleIcon: {
    transform: 'rotate(-180deg)',
  },
};
const DropdownField = React.forwardRef<HTMLDivElement, DropdownFieldProps>(
  (
    {
      helperText,
      label,
      prefixIcon,
      error = false,
      disabled = false,
      active = false,
      ...rest
    },
    ref
  ) => (
    <Box ref={ref} {...rest}>
      <Box
        sx={[
          styles.inner,
          disabled ? styles.disabled : styles.hoverable,
          error && styles.errorInner,
          active && styles.activeInner,
          !!prefixIcon && styles.iconInner,
        ]}
      >
        {prefixIcon}
        <Box sx={styles.label}>{label}</Box>
        <Box sx={[styles.toggleIcon, active && styles.activeToggleIcon]}>
          <ActionChevronFilledDownIcon />
        </Box>
      </Box>
      {!!helperText && (
        <Typography
          sx={[
            styles.helperText,
            error ? styles.errorText : styles.defaultText,
          ]}
          variant="caption"
        >
          {helperText}
        </Typography>
      )}
    </Box>
  )
);

export default DropdownField;
