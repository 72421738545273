import { TFunction } from 'i18next';

const getMainPageConfig = (t: TFunction) => ({
  allClubs: {
    title: t('main.allClubs.title'),
    icon: 'MainApps',
    tooltip: {
      icon: 'MainApps',
      title: t('main.allClubs.title'),
      content: t('main.allClubs.tooltip'),
    },
  },
  clubGroups: {
    title: t('main.clubGroups.title'),
    icon: 'MainClubGroup',
    tooltip: {
      icon: 'MainClubGroup',
      title: t('main.clubGroups.title'),
      content: t('main.clubGroups.tooltip'),
    },
  },
  myCreatedClubs: {
    title: t('main.myCreatedClubs.title'),
    icon: 'PropertyTypePerson',
    tooltip: {
      icon: 'PropertyTypePerson',
      title: t('main.myCreatedClubs.title'),
      content: t('main.myCreatedClubs.tooltip'),
    },
  },
  joinedClubs: {
    title: t('main.joinedClubs.title'),
    icon: 'TextEditorCheck',
    tooltip: {
      icon: 'TextEditorCheck',
      title: t('main.joinedClubs.title'),
      content: t('main.joinedClubs.tooltip'),
    },
  },
  agents: {
    title: t('main.agents.title'),
    icon: 'ProfileClubAgent',
    tooltip: {
      icon: 'ProfileClubAgent',
      title: t('main.agents.title'),
      content: t('main.agents.tooltip'),
    },
  },
  directMessages: {
    title: t('main.directMessages.title'),
    icon: 'ThreadsDirectMessages',
  },
  directMessagesNewThread: {
    title: t('main.directMessages_newThread.title'),
    icon: 'TestAdd',
  },
  qBankReporter: {
    title: t('main.qBankReporter.title'),
    icon: 'TestReport',
  },
  extendTrial: {
    title: t('main.extendTrial.title'),
    icon: 'OtherAddFriend',
  },
});

export default getMainPageConfig;
