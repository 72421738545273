import React from 'react';

export default function TestSolidStar({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.4037 3.43335C11.6099 2.85555 12.3901 2.85555 12.5963 3.43335L14.4718 8.68797C14.5613 8.93863 14.7851 9.109 15.0403 9.12068L20.3901 9.36558C20.9784 9.39251 21.2195 10.1698 20.7587 10.5538L16.568 14.0463C16.3681 14.2129 16.2826 14.4885 16.3508 14.7464L17.7817 20.1524C17.939 20.7468 17.3079 21.2272 16.8169 20.8868L12.3514 17.7906C12.1383 17.6429 11.8617 17.6429 11.6486 17.7906L7.18315 20.8867C6.69212 21.2272 6.06098 20.7468 6.21831 20.1524L7.64917 14.7464C7.71743 14.4885 7.63194 14.2129 7.43202 14.0463L3.24133 10.5538C2.78052 10.1698 3.0216 9.39251 3.60987 9.36558L8.95968 9.12068C9.21489 9.109 9.43872 8.93864 9.52819 8.68797L11.4037 3.43335Z"
        fill="currentColor"
      />
    </svg>
  );
}
