type PaidIndicator =
  | null
  | 'PaidIndicatorEnabledPro'
  | 'PaidIndicatorEnabledBasic'
  | 'PaidIndicatorEnabledUnlimited';

type AmbassadorIndicator = null | 'AmbassadorIndicatorEnabled';

export type Indicator =
  | (PaidIndicator & AmbassadorIndicator)
  | {
      paid: PaidIndicator;
      ambassador: AmbassadorIndicator;
    };
export function getIndicators(indicator?: Indicator) {
  if (!indicator) return undefined;
  const output: { icon: string; name: string }[] = [];

  const indicatorArr =
    typeof indicator === 'string'
      ? [indicator]
      : [indicator.paid, indicator.ambassador];

  indicatorArr.forEach((indicatorName) => {
    switch (indicatorName) {
      case 'AmbassadorIndicatorEnabled':
        output.push({
          icon: 'BadgeStudentAmbassador',
          name: 'Student Ambassador',
        });
        break;
      case 'PaidIndicatorEnabledBasic':
        output.push({ icon: 'BadgeBasicPlan', name: 'Premium User' });
        break;
      case 'PaidIndicatorEnabledPro':
        output.push({ icon: 'BadgeProPlan', name: 'Pro User' });
        break;
      case 'PaidIndicatorEnabledUnlimited':
        output.push({ icon: 'BadgeUnlimitedPlan', name: 'Unlimited User' });
        break;
      default:
        break;
    }
  });
  return output;
}
