import React from 'react';

export default function PinNewIcon({ ...rest }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 14.0351C0 6.28372 6.28372 0 14.0351 0H16C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32H0V14.0351Z"
        fill="#FF2951"
      />
      <path
        d="M16 2C11.2143 2 7.4218 2.54977 4.98579 4.98579C2.54977 7.4218 2 11.2143 2 16C2 20.7857 2.54977 24.5782 4.98579 27.0142C7.4218 29.4502 11.2143 30 16 30C20.7857 30 24.5782 29.4502 27.0142 27.0142C29.4502 24.5782 30 20.7857 30 16C30 11.2143 29.4502 7.4218 27.0142 4.98579C24.5782 2.54977 20.7857 2 16 2Z"
        fill="white"
        stroke="#FF2951"
        strokeWidth="4"
      />
    </svg>
  );
}
