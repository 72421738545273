import React, {
  KeyboardEvent,
  MouseEvent,
  PointerEvent,
  useContext,
  useEffect,
  useRef,
} from 'react';
import Box from '@mui/material/Box';
import { reactionSuggestions } from '@front/config';
import { IconButton } from '@front/ui';

import Icon from '../../../../components/Icon';
import { useIaAction } from '../../../../core/IaAction/useIaAction';
import { TableLayoutPluginContext } from '../../contexts/tableLayoutPluginContext';
import useTableSelectionContext from '../../hooks/useTableSelectionContext';
import { TableLayoutReactionCell, TableLayoutRow } from '../../types';

export type IaReactionCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutReactionCell;
  columnKey: string;
};

const styles = {
  root: {
    height: '100%',
    display: 'grid',
    alignItems: 'center',
  },
  icon: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    display: 'grid',
    alignItems: 'center',
  },
  iconButton: {
    fontSize: 16,
  },
  disabled: {
    opacity: 0.5,
  },
};
export default function IaIconCell({
  row,
  cell,
  columnKey,
}: IaReactionCellProps) {
  const { value, changeAction, disabled = false } = cell;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const pressTimer = useRef<NodeJS.Timeout | null>(null);
  const [, setPluginValue] = useContext(TableLayoutPluginContext);
  const clickable = !!changeAction;

  const { getIaAction } = useIaAction();

  const handleClick = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    ev.preventDefault();

    const onChange = getIaAction<{
      id: string;
      value: TableLayoutReactionCell['value'] | null;
    }>(changeAction);

    if (!onChange?.action) return;

    if (value) {
      void onChange.action({
        id: row.id,
        value: null,
      });
    } else {
      void onChange.action({
        id: row.id,
        value: reactionSuggestions[0],
      });
    }
    setPluginValue((st) => ({
      ...st,
      reaction: {
        ...st.reaction,
        target: [
          {
            id: row.id,
            cell: { ...cell, value: value ? null : reactionSuggestions[0] },
          },
        ],
      },
    }));
  };

  const handlePointerEnter = (ev: PointerEvent<HTMLButtonElement>) => {
    if (ev.pointerType === 'mouse') {
      setPluginValue((st) => ({
        ...st,
        reaction: {
          anchorEl: ev.target as HTMLButtonElement,
          open: true,
          target: [
            {
              id: row.id,
              cell,
            },
          ],
          options: {
            closeOnBlur: true,
          },
        },
      }));
    }
  };

  const handleTouchStart = () => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
    }
    pressTimer.current = setTimeout(() => {
      setPluginValue((st) => ({
        ...st,
        reaction: {
          open: true,
          target: [
            {
              id: row.id,
              cell,
            },
          ],
        },
      }));
    }, 500);
  };

  const handleTouchEnd = () => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
      pressTimer.current = null;
    }
  };

  const { getCellSelectedState } = useTableSelectionContext();
  const selectedState = getCellSelectedState(row.id, columnKey);

  useEffect(() => {
    if (selectedState === 'focused') {
      buttonRef.current?.focus({
        preventScroll: true,
      });
    } else {
      buttonRef.current?.blur();
    }
  }, [selectedState]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return;
    buttonRef.current?.click();
  };

  return (
    <Box
      sx={[styles.root, disabled && styles.disabled]}
      px={clickable ? 0.5 : 1}
    >
      {clickable && (
        <IconButton
          customSize={24}
          onClick={disabled ? undefined : handleClick}
          onPointerEnter={disabled ? undefined : handlePointerEnter}
          onTouchStart={disabled ? undefined : handleTouchStart}
          onTouchEnd={disabled ? undefined : handleTouchEnd}
          onTouchMove={disabled ? undefined : handleTouchEnd}
          sx={styles.iconButton}
          ref={buttonRef}
          disableTouchRipple
          onKeyDown={handleKeyDown}
          onContextMenu={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            return false;
          }}
        >
          {value?.code || <Icon name="EmojiFavorite" />}
        </IconButton>
      )}

      {!clickable &&
        (value?.code || <Icon name="EmojiFavorite" width={16} height={16} />)}
    </Box>
  );
}
