import { useMemo } from 'react';
import { isMacOs } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Block, BlockNoteEditor, BlockSchema } from '@blocknote/core';
import { BlockMoreActionDropdownItem } from '@lib/web/composer/TextComposer/components/SideMenu/components/BlockMoreActionDropdown';
import { useGeneralBlockMoreActions } from '@lib/web/composer/TextComposer/components/SideMenu/hooks/useGeneralBlockMoreActions';

export const useGeneralBlockMoreActionItems = <BSchema extends BlockSchema>({
  editor,
  block,
}: {
  editor: BlockNoteEditor<BSchema>;
  block: Block<BSchema>;
}): BlockMoreActionDropdownItem[] => {
  const { t } = useTranslation('editor');
  const {
    cutBlock,
    copyBlockToClipboard,
    pasteClipboardBlockToBelow,
    duplicateBlock,
    deleteBlock,
  } = useGeneralBlockMoreActions({ editor, block });

  const metaKeyName = isMacOs ? 'Cmd' : 'Ctrl';

  return useMemo(
    () => [
      {
        label: t('Cut'),
        hint: `${metaKeyName} + X`,
        icon: 'EditorCut',
        onClick: cutBlock,
      },
      {
        label: t('Copy'),
        hint: `${metaKeyName} + C`,
        icon: 'OtherCopy',
        onClick: copyBlockToClipboard,
      },
      {
        label: t('Paste'),
        hint: `${metaKeyName} + V`,
        icon: 'OtherPaste',
        onClick: pasteClipboardBlockToBelow,
      },
      {
        label: t('Duplicate'),
        hint: `${metaKeyName} + D`,
        icon: 'OtherCopy',
        onClick: duplicateBlock,
      },
      {
        label: t('Delete'),
        hint: 'Delete',
        icon: 'OtherDelete',
        onClick: deleteBlock,
      },
    ],
    [
      copyBlockToClipboard,
      cutBlock,
      deleteBlock,
      duplicateBlock,
      metaKeyName,
      pasteClipboardBlockToBelow,
      t,
    ]
  );
};
