import {
  ChatPin as ChatPinIcon,
  EditorHighlight as EditorHighlightIcon,
  EditorVariable as EditorVariableIcon,
  ProfileClubAgent as ProfileClubAgentIcon,
} from '@front/icon';
import { VariableType } from '@lib/web/apis';
import {
  ComposerToolbarMenuItem,
  getLastSelectionPos,
  getSelectedTextWithLineBreak,
} from '@lib/web/composer';
import { EditorBlockTypes } from '@lib/web/editor';
import { EditorComposerSchema } from '@lib/web/editor/EditorTextComposer/config/editorComposerSchema';
import { v4 } from 'uuid';

const clearSelection = () => {
  // XXX: when select multiple lines inside a block, after insert, the selection is not removed (not really sure the root cause)
  (window.getSelection
    ? window.getSelection()
    : document.getSelection()
  )?.removeAllRanges();
};

const turnIntoAI: ComposerToolbarMenuItem<EditorComposerSchema> = {
  nodeType: EditorBlockTypes.InlineAi,
  type: 'turnInto',
  icon: <ProfileClubAgentIcon width={16} height={16} />,
  display: 'AI',
  hint: '/ai',
  onClick: (editor, onConvertBlockType) => {
    onConvertBlockType?.(EditorBlockTypes.InlineAi);

    const [startPos, endPos] = getLastSelectionPos(editor);
    const selectedText = getSelectedTextWithLineBreak(editor, {
      lineBreak: '<br />',
    });

    editor._tiptapEditor
      .chain()
      .deleteRange({ from: startPos, to: endPos })
      .insertContentAt(startPos, [
        {
          type: EditorBlockTypes.InlineAi,
          attrs: {
            id: v4(),
            text: selectedText,
            isMagicWand: true,
          },
        },
      ])
      .run();

    clearSelection();
  },
  isSelected: () => false,
};

const transformToLineAnchor: ComposerToolbarMenuItem<EditorComposerSchema> = {
  nodeType: EditorBlockTypes.InlineLineAnchor,
  type: 'transform',
  icon: <ChatPinIcon width={16} height={16} />,
  display: 'Line Marker',
  hint: '/linemarker',
  onClick: (editor, onConvertBlockType) => {
    onConvertBlockType?.(EditorBlockTypes.InlineLineAnchor);

    const [startPos, endPos] = getLastSelectionPos(editor);
    const text = getSelectedTextWithLineBreak(editor);

    editor._tiptapEditor
      .chain()
      .deleteRange({ from: startPos, to: endPos })
      .insertContentAt(startPos, [
        {
          type: 'text',
          text: ' ',
        },
        {
          type: EditorBlockTypes.InlineLineAnchor,
          attrs: {
            id: v4(),
            value: text,
          },
        },
        {
          type: 'text',
          text: ' ',
        },
      ])
      .run();

    clearSelection();
  },
  isSelected: () => false,
};

const transformToHighlightAnchor: ComposerToolbarMenuItem<EditorComposerSchema> =
  {
    nodeType: EditorBlockTypes.InlineHighlightAnchor,
    type: 'transform',
    icon: <EditorHighlightIcon width={16} height={16} />,
    display: 'Highlight',
    hint: '/highlight',
    onClick: (editor, onConvertBlockType) => {
      onConvertBlockType?.(EditorBlockTypes.InlineHighlightAnchor);

      const [startPos, endPos] = getLastSelectionPos(editor);
      const text = getSelectedTextWithLineBreak(editor);

      editor._tiptapEditor
        .chain()
        .deleteRange({ from: startPos, to: endPos })
        .insertContentAt(startPos, [
          {
            type: 'text',
            text: ' ',
          },
          {
            type: EditorBlockTypes.InlineHighlightAnchor,
            attrs: {
              id: v4(),
              value: text,
            },
          },
          {
            type: 'text',
            text: ' ',
          },
        ])
        .run();

      clearSelection();
    },
    isSelected: () => false,
  };

const transformToVariable: ComposerToolbarMenuItem<EditorComposerSchema> = {
  nodeType: EditorBlockTypes.InlineVariable,
  type: 'transform',
  icon: <EditorVariableIcon width={16} height={16} />,
  display: 'Variable',
  hint: '/variable',
  onClick: (editor, onConvertBlockType) => {
    onConvertBlockType?.(EditorBlockTypes.InlineVariable);

    const [startPos, endPos] = getLastSelectionPos(editor);
    const text = getSelectedTextWithLineBreak(editor, { lineBreak: '\n' });

    const id = v4();
    editor._tiptapEditor
      .chain()
      .deleteRange({ from: startPos, to: endPos })
      .insertContentAt(startPos, [
        {
          type: 'text',
          text: ' ',
        },
        {
          type: EditorBlockTypes.InlineVariable,
          attrs: {
            id,
            type: VariableType.Text,
            defaultContent: text,
            isNewCreated: true,
          },
        },
        {
          type: 'text',
          text: ' ',
        },
      ])
      .run();

    clearSelection();
  },
  isSelected: () => false,
};
export const editorToolbarMenu = {
  turnIntoAI,
  transformToLineAnchor,
  transformToHighlightAnchor,
  transformToVariable,
};
