import { ChannelContentItemConfig } from '../../types';

import CustomizeItem from './CustomizeItem';
import DividerItem from './DividerItem';
import MessageItem from './MessageItem';
import NotificationItem from './NotificationItem';
import SkeletonItem from './SkeletonItem';

type ContentItemProps = ChannelContentItemConfig & {
  size: 'sm' | 'md';
  hoverDisabled?: boolean;
};

export default function ContentItem({
  hoverDisabled,
  ...rest
}: ContentItemProps) {
  if (rest.type === 'notification') {
    return <NotificationItem hoverDisabled={hoverDisabled} {...rest} />;
  }
  if (rest.type === 'message') {
    return <MessageItem {...rest} />;
  }
  if (rest.type === 'divider') {
    return <DividerItem {...rest} />;
  }
  if (rest.type === 'customize') {
    return <CustomizeItem {...rest} />;
  }
  if (rest.type === 'skeleton') {
    return <SkeletonItem />;
  }
  return null;
}
