export const enum KeyboardType {
  ArithmeticAndAlgebra = 'ArithmeticAndAlgebra',
  SuperscriptAndSubscript = 'SuperscriptAndSubscript',
  Fractions = 'Fractions',
  Statistics = 'Statistics',
  Measurements = 'Measurements',
  Calculus = 'Calculus',
  GreekSymbols = 'GreekSymbols',
  LettersSymbols = 'LettersSymbols',
  LogicAndTheory = 'LogicAndTheory',
  Geometry = 'Geometry',
  EquivalenceAndProportionOperators = 'EquivalenceAndProportionOperators',
  OtherSymbols = 'OtherSymbols',
}
export const keyboardConfig = {
  [KeyboardType.ArithmeticAndAlgebra]: {
    display: 'Arithmetic & Algebra',
    icon: 'MathArithmeticAndAlgebra',
    keys: [
      '+',
      '−',
      '×',
      '÷',
      '=',
      '±',
      '≠',
      '(',
      ')',
      '<',
      '>',
      '≤',
      '≥',
      '⁄',
      '√',
      '∛',
      '∜',
      '∞',
      'ℵ',
      'ƒ',
      '′',
      '″',
      '‴',
      '⋅',
      '〈',
      '〉',
      '⌈',
      '⌉',
      '⌊',
      '⌋',
      '⊕',
      '⊗',
    ],
  },
  [KeyboardType.SuperscriptAndSubscript]: {
    display: 'Superscript & Subscript',
    icon: 'MathSuperscriptAndSubscript',
    keys: [
      '⁰',
      '¹',
      '²',
      '³',
      '⁴',
      '⁵',
      '⁶',
      '⁷',
      '⁸',
      '⁹',
      '⁺',
      '⁻',
      '⁼',
      '⁽',
      '⁾',
      'ⁱ',
      'ⁿ',
      '₀',
      '₁',
      '₂',
      '₃',
      '₄',
      '₅',
      '₆',
      '₇',
      '₈',
      '₉',
      '₊',
      '₋',
      '₌',
      '₍',
      '₎',
      'ₐ',
      'ₑ',
      'ₒ',
      'ᵢ',
      'ᵣ',
      'ᵤ',
      'ᵥ',
      'ₓ',
      'ᵦ',
      'ᵧ',
      'ᵨ',
      'ᵩ',
      'ᵪ',
    ],
  },
  [KeyboardType.Fractions]: {
    display: 'Fractions',
    icon: 'MathFractions',
    keys: [
      '¼',
      '½',
      '⅓',
      '⅔',
      '⅕',
      '⅖',
      '⅗',
      '⅘',
      '⅙',
      '⅚',
      '⅛',
      '⅜',
      '⅝',
      '⅞',
    ],
  },
  [KeyboardType.Statistics]: {
    display: 'Statistics',
    icon: 'MathStatistics',
    keys: ['μ', 'σ', 'χ', '∑', '∐', 'x̄', 'p̂'],
  },
  [KeyboardType.Measurements]: {
    display: 'Measurements',
    icon: 'MathMeasurements',
    keys: ['°', 'µ', '′', '″', '‰'],
  },
  [KeyboardType.Calculus]: {
    display: 'Calculus',
    icon: 'MathCalculus',
    keys: [
      '∂',
      '∆',
      '∇',
      '∫',
      '∬',
      '∭',
      '⨌',
      '∮',
      '∯',
      '∰',
      '∱',
      '⨑',
      '∲',
      '∳',
    ],
  },
  [KeyboardType.GreekSymbols]: {
    display: 'Greek Symbols',
    icon: 'MathGreekSymbols',
    keys: [
      'α',
      'β',
      'γ',
      'δ',
      'ε',
      'ζ',
      'η',
      'θ',
      'ι',
      'κ',
      'λ',
      'μ',
      'ν',
      'ξ',
      'ο',
      'π',
      'ρ',
      'σ',
      'τ',
      'υ',
      'φ',
      'χ',
      'ψ',
      'ω',
    ],
  },
  [KeyboardType.LettersSymbols]: {
    display: 'Letters Symbols',
    icon: 'MathLettersSymbols',
    keys: [
      '℘',
      'ℑ',
      'ℜ',
      'ℝ',
      'ℂ',
      'ℕ',
      'ℙ',
      'ℚ',
      'ℤ',
      'ℷ',
      'ℸ',
      'ℹ',
      '℺',
      '℻',
      'ℼ',
      'ℽ',
      'ℾ',
      'ℿ',
      '⅀',
    ],
  },
  [KeyboardType.LogicAndTheory]: {
    display: 'Logic & Theory',
    icon: 'MathLogicAndTheory',
    keys: [
      '∀',
      '∁',
      '∃',
      '∄',
      '∅',
      '¬',
      '˜',
      '∧',
      '∨',
      '⊻',
      '⊼',
      '⊽',
      '∩',
      '∪',
      '∈',
      '∉',
      '∊',
      '∋',
      '∌',
      '∍',
      '∖',
      '⊂',
      '⊃',
      '⊄',
      '⊅',
      '⊆',
      '⊇',
      '⊈',
      '⊉',
      '⊊',
      '⊋',
      '⋄',
      '≃',
      '≄',
      '⊌',
      '⊍',
      '⊎',
      '⋐',
      '⋑',
      '⋒',
      '⋓',
      '⋀',
      '⋁',
      '⋂',
      '⋃',
      '⋎',
      '⋏',
      '⊕',
      '⊗',
      '⊖',
      '⊘',
      '⋲',
      '⋳',
      '⋴',
      '⋵',
      '⋶',
      '⋷',
      '⋸',
      '⋹',
      '⋺',
      '⋻',
      '⋼',
      '⋽',
      '⋾',
      '⋿',
    ],
  },
  [KeyboardType.Geometry]: {
    display: 'Geometry',
    icon: 'MathGeometry',
    keys: [
      '∟',
      '∠',
      '∡',
      '∢',
      '∣',
      '∤',
      '∥',
      '∦',
      '⊾',
      '⊿',
      '⊥',
      '⊢',
      '⊣',
      '⊤',
      '∴',
      '∵',
      '∝',
      '∎',
    ],
  },
  [KeyboardType.EquivalenceAndProportionOperators]: {
    display: 'Equivalence & Proportion Operators',
    icon: 'MathEquivalenceAndProportionOperators',
    keys: [
      '≠',
      '≅',
      '≈',
      '≉',
      '∼',
      '≡',
      '≢',
      '≣',
      '≦',
      '≧',
      '≨',
      '≩',
      '≪',
      '≫',
      '≬',
      '≭',
      '≮',
      '≯',
      '≰',
      '≱',
      '≲',
      '≳',
      '≴',
      '≵',
      '≶',
      '≷',
      '≸',
      '≹',
      '≁',
      '≂',
      '≃',
      '≄',
      '≆',
      '≇',
      '≊',
      '≋',
      '≌',
    ],
  },
  [KeyboardType.OtherSymbols]: {
    display: 'Other Symbols',
    icon: 'MathOtherSymbols',

    keys: [
      '∓',
      '∔',
      '∗',
      '∘',
      '∙',
      '∝',
      '∶',
      '∷',
      '∸',
      '∹',
      '∺',
      '∻',
      '∼',
      '∽',
      '∾',
      '∿',
      '≀',
      '≍',
      '≎',
      '≏',
      '≐',
      '≑',
      '≒',
      '≓',
      '≔',
      '≕',
      '≖',
      '≗',
      '≘',
      '≙',
      '≚',
      '≛',
      '≜',
      '≝',
      '≞',
      '≟',
      '≺',
      '≻',
      '≼',
      '≽',
      '≾',
      '≿',
      '⊀',
      '⊁',
      '⊏',
      '⊐',
      '⊑',
      '⊒',
      '⊓',
      '⊔',
      '⊙',
      '⊚',
      '⊛',
      '⊜',
      '⊝',
      '⊞',
      '⊟',
      '⊠',
      '⊦',
      '⊧',
      '⊨',
      '⊩',
      '⊪',
      '⊫',
      '⊬',
      '⊭',
      '⊮',
      '⊯',
      '⊰',
      '⊱',
      '⊲',
      '⊳',
      '⊴',
      '⊵',
      '⊶',
      '⊷',
      '⊸',
      '⊹',
      '⊺',
      '⋄',
      '⋆',
      '⋇',
      '⋈',
      '⋉',
      '⋊',
      '⋋',
      '⋌',
      '⋍',
      '⋔',
      '⋕',
      '⋖',
      '⋗',
      '⋘',
      '⋙',
      '⋚',
      '⋛',
      '⋜',
      '⋝',
      '⋞',
      '⋟',
      '⋠',
      '⋡',
      '⋢',
      '⋣',
      '⋤',
      '⋥',
      '⋦',
      '⋧',
      '⋨',
      '⋩',
      '⋪',
      '⋫',
      '⋬',
      '⋭',
      '⋮',
      '⋯',
      '⋰',
      '⋱',
    ],
  },
};
