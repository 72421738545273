import React from 'react';

export default function SocialMediaApple({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_6903_571237)">
        <path
          d="M17.4479 12.6051C17.4759 15.626 20.098 16.6313 20.127 16.6441C20.1048 16.715 19.7081 18.0767 18.7456 19.4833C17.9136 20.6993 17.0501 21.9109 15.6898 21.936C14.3532 21.9606 13.9234 21.1434 12.3952 21.1434C10.8676 21.1434 10.3901 21.9109 9.1248 21.9606C7.81178 22.0103 6.81193 20.6456 5.97301 19.434C4.25878 16.9557 2.94875 12.4309 4.70779 9.37654C5.58164 7.85974 7.14328 6.89924 8.83829 6.87461C10.1276 6.85002 11.3446 7.74204 12.1328 7.74204C12.9205 7.74204 14.3994 6.6693 15.9542 6.82685C16.605 6.85394 18.432 7.08976 19.6052 8.80694C19.5106 8.86554 17.4252 10.0796 17.4479 12.6051ZM14.9359 5.18722C15.6329 4.34342 16.1021 3.16877 15.9741 2C14.9693 2.04038 13.7543 2.66957 13.0336 3.51291C12.3877 4.25973 11.822 5.45505 11.9746 6.60069C13.0946 6.68734 14.2387 6.03156 14.9359 5.18722Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6903_571237">
          <rect
            height="20"
            width="16.254"
            fill="currentColor"
            transform="translate(3.87305 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
