import { useEffect, useState } from 'react';

import { ConfirmPopupSettings } from './types';

export enum ActionType {
  OPEN,
  CANCEL,
  CONFIRM,
}

type Action =
  | {
      type: ActionType.OPEN;
      settings: ConfirmPopupSettings;
    }
  | {
      type: ActionType.CANCEL;
    }
  | {
      type: ActionType.CONFIRM;
    };

interface State {
  settings: Partial<ConfirmPopupSettings>;
  open: boolean;
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.OPEN:
      return {
        ...state,
        settings: action.settings,
        open: true,
      };
    case ActionType.CANCEL:
      return {
        ...state,
        open: false,
      };
    case ActionType.CONFIRM:
      return {
        ...state,
        open: false,
      };
  }
};

const listeners: Array<(state: State) => void> = [];

let memoryState: State = { settings: {}, open: false };

export const dispatch = (action: Action) => {
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState);
  });
};

export const usePopup = (): State => {
  const [state, setState] = useState<State>(memoryState);
  useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  return state;
};
