import { ComposerRenderer } from '@lib/web/composer';
import { ComposerRendererProps } from '@lib/web/composer/TextComposer/ComposerRenderer';
import { threadBlockHtmlStyles } from '@lib/web/thread/ThreadTextComposer/config/threadComposerBlockStyles';

export type ThreadComposerRendererProps = Omit<
  ComposerRendererProps,
  'htmlStyles'
>;

/**
 * We no longer use this Renderer.
 * Instead, we now have the EditorThreadComposerRenderer for displaying threaded editor solutions in various locations.
 * It is recommended to always utilize the EditorThreadComposerRenderer over the ThreadComposerRenderer.
 * However, we are keeping this component for potential future use cases that do not require editor functionality.
 */
export default function ThreadComposerRenderer({
  sx,
  ...rest
}: ThreadComposerRendererProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <ComposerRenderer
      sx={sxProps}
      htmlStyles={threadBlockHtmlStyles}
      {...rest}
    />
  );
}
