import React, { MouseEvent, useContext } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { ResponsiveMenu } from '@front/ui';

import useQuestionSideMenu from '../QuestionSideMenu/hooks/useQuestionSideMenu';

import { SideMenuMoreActionDropdownContext } from './contexts';

export default function SideMenuMoreActionDropdown() {
  const { openedItemId, closeMenu, refMap } = useQuestionSideMenu();
  const { items } = useContext(SideMenuMoreActionDropdownContext);

  const handleMenuClose = () => {
    closeMenu();
  };

  const handleMenuItemClick = (onClick: (itemId: string) => void) => {
    return (ev: MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      closeMenu();
      if (openedItemId) {
        onClick(openedItemId);
      }
    };
  };

  const targetEl = openedItemId ? refMap?.[openedItemId] : null;
  if (!targetEl) {
    return null;
  }

  return (
    <ResponsiveMenu
      open={!!openedItemId}
      onClose={handleMenuClose}
      menuProps={{
        anchorEl: targetEl,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 4,
        },
      }}
    >
      {items?.map(({ label, icon, onClick }) => (
        <MenuItem
          key={label}
          value={label}
          onClick={handleMenuItemClick(onClick)}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      ))}
    </ResponsiveMenu>
  );
}
