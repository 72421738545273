import React from 'react';
import Box from '@mui/material/Box';
import { SquareAvatarGroup } from '@front/ui';

import { DisplayTableLayoutAvatarGroupCell } from '../../types';

export type IaDisplayTableAvatarGroupCellProps =
  DisplayTableLayoutAvatarGroupCell;

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
  },
};
export default function IaDisplayTableAvatarGroupCell({
  avatars,
  maxDisplayNumber,
}: IaDisplayTableAvatarGroupCellProps) {
  return (
    <Box sx={styles.root}>
      <SquareAvatarGroup
        items={avatars}
        max={maxDisplayNumber}
        size={16}
        spacing="-4px"
      />
    </Box>
  );
}
