import {
  MouseEvent,
  useCallback,
  useContext,
  useDeferredValue,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
import { Hoverable, useLatestValueRef } from '@front/helper';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import {
  IaTableHeadCellBorder,
  IaTableHeadCheckboxCellBorder,
  IaTableHeadSidePeekCellBorder,
} from '@lib/ia/src/layouts/TableLayout/components/IaTableCellBorder/IaTableCellBorder';
import { usePageControl } from '@lib/ia/src/layouts/TableLayout/hooks/usePageControl';
import useTableSelectionContext from '@lib/ia/src/layouts/TableLayout/hooks/useTableSelectionContext';
import { useTableToolbarActions } from '@lib/ia/src/layouts/TableLayout/hooks/useTableToolbarActions';
import { TableLayoutActionsContext } from '@lib/ia/src/layouts/TableLayout/TableLayoutActions';

import SideFloatingCheckbox from '../../components/SideFloatingCheckbox';

import IaTableActionRows from './components/IaTableActionRows';
import IaTableFooter from './components/IaTableFooter/IaTableFooter';
import IaTableHeadAction from './components/IaTableHeadAction';
import IaTableHeadCheckbox from './components/IaTableHeadCheckbox';
import IaTableRowRecursive from './components/IaTableRowRecursive';
import { TableLayoutPluginContextProvider } from './contexts/tableLayoutPluginContext';
import { TableLayoutContextProvider } from './contexts/tableLayoutSelectionContext';
import { TableLayoutSettingsContextProvider } from './contexts/tableLayoutSettingsContext';
import useCellSelection from './hooks/useCellSelection';
import { useTableCheckbox } from './hooks/useTableCheckbox';
import useTableSettings from './hooks/useTableSettings';
import { useTableVirtualList } from './hooks/useTableVirtualList';
import {
  IaTableBody,
  IaTableCentrePane,
  IaTableHead,
  IaTableHeadCell,
  IaTableLoadingRow,
  IaTableRightPane,
  IaTableRow,
  IaTableRowContainer,
  IaTableToolbar,
} from './components';
import { TableLayoutConfig, TableLayoutRow } from './types';

const INNER_MAX_WIDTH = 796; // base width from the figma

function getCheckableGroupIdToChildIds(
  checkableRowId: string,
  childRows: Record<string, TableLayoutRow[]>,
  isCheckable: (row: TableLayoutRow) => boolean,
  result: Record<string, string[]>
) {
  const rows = childRows[checkableRowId] || [];
  for (const row of rows) {
    if (isCheckable(row)) {
      if (!result[checkableRowId]) result[checkableRowId] = [];
      result[checkableRowId].push(row.id);
      getCheckableGroupIdToChildIds(row.id, childRows, isCheckable, result);
    }
  }
}

type TableLayoutProps = TableLayoutConfig;

function TableLayout({
  table: {
    gridTemplateColumns,
    columnOrder,
    head,
    rows,
    footer,
    childRows = {},
    columnsTooltip,
    emptyStateToolbarActions,
  },
  data,
  settings,
  events,
  controlState,
}: TableLayoutProps) {
  const columnWidths = {
    gridTemplateColumns,
    minWidth: settings.layoutSetting?.minWidth || INNER_MAX_WIDTH,
  };
  const { numberOfRowsDisplay, loadMore, hasMore } = usePageControl({
    data,
    rows,
  });
  const displayRows = useMemo(
    () => rows.slice(0, numberOfRowsDisplay),
    [rows, numberOfRowsDisplay]
  );
  const isCheckable = (row: TableLayoutRow) =>
    (row.moreActions?.length || 0) > 0;
  const checkableRowIds = useMemo(
    () => displayRows.filter(isCheckable).map((row) => row.id),
    [displayRows]
  );
  const checkableGroupIdToChildIds = useMemo(() => {
    const result: Record<string, string[]> = {};
    for (const id of checkableRowIds) {
      getCheckableGroupIdToChildIds(id, childRows, isCheckable, result);
    }
    return result;
  }, [childRows, checkableRowIds]);

  const {
    toggle,
    toggleMultiple,
    toggleAll,
    clearAllCheckbox,
    onlySelectOne,
    numberOfSelected,
    getSelectedState,
    getSelectedAllState,
    selectedIds,
  } = useTableCheckbox({
    ids: checkableRowIds,
    groupIdToChildIds: checkableGroupIdToChildIds,
    controlState,
  });
  const [sideMenuOpened, setSideMenuOpened] = useState(false);
  const tableHeadRef = useRef<HTMLDivElement>(null);
  const toolbarActions = useTableToolbarActions({
    selectedIds,
    rows: displayRows,
    childRows,
    emptyStateToolbarActions,
    toolbarSettings: settings.toolbar,
  });
  const { getIaAction } = useIaAction();
  const latestSelectedIdsRef = useLatestValueRef(selectedIds);
  const latestToolbarActionsRef = useLatestValueRef(toolbarActions);

  const onCheckboxToggled = useCallback(
    (toggleId?: string) => {
      if (!events?.onCheckboxToggledAction) return;

      // to get the latest value of selectedIds, we need to wait until next render
      setTimeout(() => {
        getIaAction<{
          toggleId?: string;
          selectedIds: Set<string>;
          toolbarActions: {
            value: string;
            onClick: (ev: MouseEvent) => Promise<void>;
          }[];
        }>(events.onCheckboxToggledAction)?.action({
          toggleId,
          selectedIds: latestSelectedIdsRef.current,
          toolbarActions: latestToolbarActionsRef.current,
        });
      });
    },
    [
      getIaAction,
      events?.onCheckboxToggledAction,
      latestSelectedIdsRef,
      latestToolbarActionsRef,
    ]
  );

  const { wrap } = useTableSettings();

  const showHeadCheckbox = !head.hideCheckbox && checkableRowIds.length > 0;

  const bodyRef = useRef<HTMLDivElement>(null);
  useCellSelection({
    displayRows,
    childRows,
    columnOrder,
    bodyRef,
    disableCellFocus: settings.disableCellFocus,
  });

  const Wrap =
    settings.layoutSetting.position === 'centre'
      ? IaTableCentrePane
      : settings.layoutSetting.position === 'rhs'
      ? IaTableRightPane
      : Box;

  const { showRow: virtualShowRow } = useTableVirtualList({
    tableBodyRef: bodyRef,
    rows: displayRows,
    childRows,
    rowHeight: 32,
  });

  // TODO Debt: for now, when table content is wrapped => VirtualList can not work correctly => temporary force showRow = true, fix it when have time
  const showRow = !wrap ? virtualShowRow : () => true;

  const { actionsRef } = useContext(TableLayoutActionsContext);
  useImperativeHandle(
    actionsRef,
    () => ({
      toggleCheckbox: (rowIds: string[]) => {
        toggleMultiple(rowIds);
        rowIds.forEach((rowId) => onCheckboxToggled(rowId));
      },
      clearAllCheckbox,
    }),
    [onCheckboxToggled, toggleMultiple, clearAllCheckbox]
  );

  const { cellSelected } = useTableSelectionContext();
  const deferredCellSelected = useDeferredValue(cellSelected);

  useEffect(() => {
    const deferredIsActive = deferredCellSelected?.state === 'active';
    const isActive = cellSelected?.state === 'active';
    if (deferredIsActive === isActive) return;
    getIaAction<boolean>(events?.cellActiveChangeAction)?.action?.(isActive);
  }, [
    cellSelected?.state,
    deferredCellSelected?.state,
    events?.cellActiveChangeAction,
    getIaAction,
  ]);

  return (
    <Wrap
      layoutSetting={settings.layoutSetting}
      className="ia-table-layout"
      sx={settings.layoutSetting.sx}
    >
      {!!numberOfSelected && (
        <IaTableToolbar
          anchorEl={tableHeadRef.current}
          numberOfSelected={numberOfSelected}
          actions={toolbarActions}
        />
      )}
      {!!head?.action && <IaTableHeadAction {...head.action} />}
      {!!head?.checkbox && (
        <IaTableHeadCheckbox rows={displayRows} {...head.checkbox} />
      )}
      <IaTableHead ref={tableHeadRef}>
        <Hoverable>
          {({ hovered, hoveredListeners }) => (
            <Box {...hoveredListeners}>
              {showHeadCheckbox && (
                <SideFloatingCheckbox
                  active={hovered && !sideMenuOpened}
                  state={getSelectedAllState()}
                  onSelect={() => {
                    toggleAll();
                    onCheckboxToggled();
                  }}
                  disableBackgroundStyle
                  layoutSetting={settings.layoutSetting}
                />
              )}
              <IaTableRow
                sx={columnWidths}
                showCheckboxBackground={showHeadCheckbox}
                sidePeekEnabled={settings.sidePeek?.enabled}
                isEmpty={data.state === 'empty'}
              >
                {showHeadCheckbox && (
                  <IaTableHeadCheckboxCellBorder
                    layoutSetting={settings.layoutSetting}
                  />
                )}
                {settings.sidePeek?.enabled && (
                  <IaTableHeadSidePeekCellBorder
                    showCheckboxBackground={showHeadCheckbox}
                    layoutSetting={settings.layoutSetting}
                    isEmpty={data.state === 'empty'}
                  />
                )}
                {columnOrder.map((columnKey) => (
                  <IaTableHeadCellBorder
                    key={columnKey}
                    layoutSetting={settings.layoutSetting}
                  >
                    <IaTableHeadCell {...head.cells[columnKey]} />
                  </IaTableHeadCellBorder>
                ))}
              </IaTableRow>
            </Box>
          )}
        </Hoverable>
      </IaTableHead>
      <IaTableBody ref={bodyRef}>
        {data.showLoadingRow === 'top' && (
          <IaTableRow sx={columnWidths}>
            <IaTableLoadingRow
              columnOrder={columnOrder}
              layoutSetting={settings.layoutSetting}
            />
          </IaTableRow>
        )}
        <IaTableRowRecursive
          rows={displayRows}
          childRows={childRows}
          settings={settings}
          showRow={showRow}
          renderRow={({
            row,
            isDeepest,
            nestedLevel,
            sortableItemEl,
            isGhostEl,
          }) => (
            <IaTableRowContainer
              container={{ anchorEl: sortableItemEl }}
              checkbox={{
                state: getSelectedState(row.id),
                onSelect: () => {
                  toggle(row.id);
                  onCheckboxToggled(row.id);
                },
              }}
              menu={{
                anyMenuOpened: sideMenuOpened,
                addActions: settings.sideFloatingMenuAddActions,
                onOpen: () => {
                  onlySelectOne(row.id);
                  onCheckboxToggled(row.id);
                  setSideMenuOpened(true);
                },
                onClose: () => {
                  setSideMenuOpened(false);
                },
                isGhostEl,
              }}
              row={{
                row,
                columnsTooltip,
                columnOrder,
                sx: columnWidths,
              }}
              layoutSetting={settings.layoutSetting}
              sidePeekSettings={settings.sidePeek}
              expandableSettings={settings.expandable}
              isDeepest={isDeepest}
              nestedLevel={nestedLevel}
            />
          )}
          renderLoadingRow={() => (
            <IaTableRow sx={columnWidths}>
              <IaTableLoadingRow
                columnOrder={columnOrder}
                layoutSetting={settings.layoutSetting}
              />
            </IaTableRow>
          )}
        />
        {data.state === 'loading' && (
          <IaTableRow sx={columnWidths}>
            <IaTableLoadingRow
              columnOrder={columnOrder}
              layoutSetting={settings.layoutSetting}
            />
          </IaTableRow>
        )}
      </IaTableBody>

      <IaTableActionRows
        data={data}
        settings={settings}
        hasMore={hasMore}
        loadMore={loadMore}
      />

      <IaTableFooter
        footer={footer}
        hasBottomButtons={!!settings.bottomButtons}
      />
    </Wrap>
  );
}

export default function TableLayoutWrap({
  plugins,
  ...rest
}: TableLayoutProps) {
  return (
    <TableLayoutSettingsContextProvider
      value={{
        wrap: rest.settings.wrap,
        layoutSetting: rest.settings.layoutSetting,
      }}
    >
      <TableLayoutPluginContextProvider plugins={plugins}>
        <TableLayoutContextProvider>
          <TableLayout {...rest} />
        </TableLayoutContextProvider>
      </TableLayoutPluginContextProvider>
    </TableLayoutSettingsContextProvider>
  );
}
