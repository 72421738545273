import React from 'react';

export default function TestFormula({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.4527 18.6121C6.53367 19.5406 5.06311 19.4209 4.06591 18.5769L3.2574 17.8927C3.09447 17.7548 3.00198 17.5511 3.0054 17.3376C3.00998 17.0518 3.18678 16.7995 3.44273 16.6721C4.41326 16.1889 5.35424 15.4111 6.26566 14.3387C7.31102 13.1087 8.17927 11.3985 8.87041 9.20809C8.95405 8.93831 8.75242 8.6647 8.46997 8.6647H7.74298C6.97408 8.6647 6.35637 8.82898 5.88985 9.15754C5.64582 9.33264 5.41406 9.54245 5.19457 9.78695C4.84514 10.1762 4.2938 10.3529 3.81597 10.1401C3.34423 9.93005 3.08531 9.40874 3.24997 8.91929C3.65861 7.70459 4.22323 6.79412 4.94384 6.18787C5.82505 5.43808 7.10799 5.05897 8.79266 5.05055L20.0634 5.00132C20.258 5.00047 20.4464 5.06961 20.5942 5.19611C20.8404 5.40683 20.9376 5.74392 20.8412 6.05335L20.471 7.24236C20.2075 8.08837 19.4245 8.6647 18.5384 8.6647C17.4521 8.6647 16.5595 9.52216 16.5159 10.6076L16.3477 14.7936C16.3132 15.4507 16.3693 15.952 16.5162 16.2974C16.6631 16.6428 16.9482 16.8155 17.3715 16.8155C17.6307 16.8155 17.9719 16.7607 18.3952 16.6512C18.4789 16.63 18.562 16.6075 18.6443 16.5838C19.1972 16.4244 19.8112 16.6744 20.0153 17.2123C20.1568 17.5852 20.0679 18.0125 19.7529 18.2572C19.5829 18.3893 19.4025 18.5153 19.2117 18.6352C18.8229 18.8795 18.4168 19.0901 17.9935 19.2671C17.5788 19.444 17.1512 19.5788 16.7106 19.6714C16.27 19.7725 15.851 19.8231 15.4536 19.8231C14.581 19.8231 13.9071 19.5788 13.432 19.0901C12.9568 18.5931 12.7192 17.8433 12.7192 16.8408C12.7192 16.4617 12.7495 15.9815 12.8099 15.4002C12.8791 14.8104 12.9438 14.2755 13.0043 13.7953L13.4944 9.99092C13.585 9.28761 13.0372 8.6647 12.328 8.6647C11.765 8.6647 11.281 9.06375 11.1737 9.61643L10.8013 11.5333C10.4125 13.4625 9.82073 15.1264 9.02592 16.5249C8.59406 17.2931 8.06965 17.9888 7.4527 18.6121Z"
        fill="currentColor"
      />
    </svg>
  );
}
