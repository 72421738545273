import React from 'react';

export default function Logo({ ...rest }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C4 0 0 4 0 20C0 36 4 40 20 40C36 40 40 36 40 20C40 4 36 0 20 0Z"
        fill="#080808"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49718 4.49718C1.72078 7.27359 0.703125 11.995 0.703125 20C0.703125 28.005 1.72078 32.7264 4.49718 35.5028C7.27359 38.2792 11.995 39.2969 20 39.2969C28.005 39.2969 32.7264 38.2792 35.5028 35.5028C38.2792 32.7264 39.2969 28.005 39.2969 20C39.2969 11.995 38.2792 7.27359 35.5028 4.49718C32.7264 1.72078 28.005 0.703125 20 0.703125C11.995 0.703125 7.27359 1.72078 4.49718 4.49718ZM0 20C0 4 4 0 20 0C36 0 40 4 40 20C40 36 36 40 20 40C4 40 0 36 0 20Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99437 4.99437C2.44157 7.54717 1.40625 11.99 1.40625 20C1.40625 28.01 2.44157 32.4528 4.99437 35.0056C7.54717 37.5584 11.99 38.5938 20 38.5938C28.01 38.5938 32.4528 37.5584 35.0056 35.0056C37.5584 32.4528 38.5938 28.01 38.5938 20C38.5938 11.99 37.5584 7.54717 35.0056 4.99437C32.4528 2.44157 28.01 1.40625 20 1.40625C11.99 1.40625 7.54717 2.44157 4.99437 4.99437ZM0 20C0 4 4 0 20 0C36 0 40 4 40 20C40 36 36 40 20 40C4 40 0 36 0 20Z"
        fill="white"
        fillOpacity="0.1"
      />
      <g clipPath="url(#clip0_1958_77857)">
        <rect
          x="23.7147"
          y="1.8472"
          width="11.7188"
          height="35.1562"
          rx="5.85938"
          transform="rotate(30 23.7147 1.8472)"
          fill="#565656"
        />
        <circle
          cx="14.1406"
          cy="30.1487"
          r="5.85938"
          transform="rotate(30 14.1406 30.1487)"
          fill="white"
        />
      </g>
    </svg>
  );
}
