import { useEffect, useState } from 'react';
import nextId from 'react-id-generator';

import { AddExportTask, ExportTask } from './types';

export enum ActionType {
  ADD,
  CANCEL,
  REMOVE,
}

type Action =
  | {
      type: ActionType.ADD;
      task: AddExportTask;
    }
  | {
      type: ActionType.CANCEL;
      id: string;
    }
  | {
      type: ActionType.REMOVE;
      id: string;
    };

interface State {
  tasks: ExportTask[];
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ADD: {
      const taskId = action.task.id || nextId();
      if (state.tasks.some((t) => t.id === taskId)) {
        return {
          ...state,
          tasks: [
            ...state.tasks.filter((t) => t.id === taskId),
            { ...action.task, status: 'exporting', id: taskId },
          ],
        };
      }
      return {
        ...state,
        tasks: [
          ...state.tasks,
          { ...action.task, status: 'exporting', id: taskId },
        ],
      };
    }
    case ActionType.REMOVE:
    case ActionType.CANCEL:
      return {
        ...state,
        tasks: [...state.tasks.filter((t) => t.id !== action.id)],
      };
  }
};

const listeners: Array<(state: State) => void> = [];

let memoryState: State = { tasks: [] };

export const dispatch = (action: Action) => {
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState);
  });
};

export const useFiles = (): State => {
  const [state, setState] = useState<State>(memoryState);
  useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  return state;
};
