import React from 'react';

export default function ProfileHomeworkDashboard({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 11H11V21H3V11ZM3 3H11V9H3V3ZM13 21V15H21V21H13Z"
        fill="currentColor"
      />
      <path
        d="M15.8656 7.94757C15.9298 7.73311 16.0421 7.52938 16.2026 7.33636C16.3631 7.14335 16.5858 6.95034 16.8706 6.75732C17.1434 6.57289 17.344 6.41419 17.4724 6.28122C17.6583 6.08255 17.7854 5.85407 17.7854 5.56064C17.7854 5.3805 17.7091 5.23895 17.5567 5.13601C17.4042 5.02878 17.2056 4.97517 16.9609 4.97517C16.656 4.97517 16.325 5.03307 15.9679 5.14888C15.6148 5.26469 15.2377 5.43626 14.8365 5.66358L14 3.9136C14.4534 3.6348 14.9368 3.41391 15.4504 3.25092C15.9639 3.08364 16.5236 3 17.1294 3C18.0401 3 18.7462 3.2166 19.2477 3.64981C19.7492 4.07873 20 4.6449 20 5.34833C20 5.68288 19.9519 5.98956 19.8556 6.26836C19.7593 6.54715 19.6068 6.80879 19.3982 7.05328C19.1896 7.29776 18.9147 7.53795 18.5737 7.77386C18.333 7.93685 18.1424 8.07625 18.002 8.19205C17.8656 8.30786 17.7673 8.41938 17.7071 8.52661C17.5969 8.73705 17.659 9.6456 17.659 9.87931H15.805C15.805 9.45348 15.75 8.35953 15.8656 7.94757Z"
        fill="currentColor"
      />
      <path
        d="M15.5165 11.7711C15.5165 11.3165 15.6389 10.997 15.8837 10.8125C16.1324 10.6281 16.4333 10.5359 16.7864 10.5359C17.1234 10.5359 17.4142 10.6281 17.659 10.8125C17.9077 10.997 18.0321 11.3165 18.0321 11.7711C18.0321 12.2044 17.9077 12.5175 17.659 12.7105C17.4142 12.9035 17.1234 13 16.7864 13C16.4333 13 16.1324 12.9035 15.8837 12.7105C15.6389 12.5175 15.5165 12.2044 15.5165 11.7711Z"
        fill="currentColor"
      />
    </svg>
  );
}
