import React from 'react';

export default function ActionDoubleRightChevron({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.496 4L22 12L13.496 20L11.6678 18.2802L18.3272 12.0154L11.6492 5.73517L13.496 4Z"
        fill="currentColor"
      />
      <path
        d="M3.84677 4L12.3508 12L3.84677 20L2.01857 18.2802L8.67804 12.0154L2 5.73517L3.84677 4Z"
        fill="currentColor"
      />
    </svg>
  );
}
