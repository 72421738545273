import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';
import { TestScratch as TestScratchIcon } from '@front/icon';
import {
  Button,
  Icon,
  SquareAvatarStatus,
  useBaseLayout,
  UserAvatar,
} from '@front/ui';
import {
  getIndicators,
  getLeagueBadgeIconName,
  getLeagueName,
} from '@lib/web/utils';

import useCurrentUserId from '../../../hooks/useCurrentUserId';
import { getUserStatus, useOnlineStatus } from '../../../hooks/useOnlineStatus';
import ProfileButton from '../../ProfileButtons';

import { FloatingProfileExtension } from './../types';
import ProfileExtension from './ProfileExtension';

const styles = {
  popper: {
    zIndex: 20,
    width: 344,
    '& .popper-content': {
      p: '12px',
    },
  },
  spacing: (spacing: number) => ({
    pt: `${spacing}px`,
  }),
  profileContainer: {
    display: 'grid',
    overflow: 'hidden',
  },
  avatar: {
    gap: 2,
    alignItems: 'flex-end',
    '& .MuiTypography-body1': {
      fontSize: 20,
      fontWeight: 700,
    },
    '& .MuiTypography-caption': {
      fontSize: 16,
      mt: 0.5,
      mb: 0.5,
    },
  },
  userData: {
    display: 'flex',
    gap: '4px',
    mt: 2,
  },
  userDataItem: {
    px: '4px',
    py: '2px',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    '& span': {
      fontWeight: 500,
      mr: '5px',
      color: (theme: Theme) => theme.palette.text.primary,
    },
  },
  userDataButton: {
    px: '4px',
    py: '2px',
    borderRadius: 1,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    display: 'flex',
    gap: '5px',
    fontSize: 16,
    lineHeight: 1.5,
    '& .infoItemValue': {
      color: (theme: Theme) => theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 2,
    mt: 2,
    '& .MuiButtonBase-root': {
      minWidth: 'unset',
    },
  },
  bioContainer: {
    mt: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  creatorContainer: {
    mt: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  creator: {
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
  },
  league: {
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
  },
};

export enum GlobalPanelKeys {
  GlobalClubs = 'GlobalClubs',
  GlobalFollowers = 'GlobalFollowers',
  GlobalFollowing = 'GlobalFollowing',
}

interface UserDataProps {
  value: number;
  label: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

function UserData({ value, label, onClick }: UserDataProps) {
  return (
    <ButtonBase sx={styles.userDataButton} onClick={onClick}>
      <Typography variant="body1" className="infoItemValue">
        {value}
      </Typography>
      <Typography variant="body1">{label}</Typography>
    </ButtonBase>
  );
}

type UserProfileProps = {
  userProfile: UserProfileObj;
  extension?: FloatingProfileExtension;
  disableCta?: boolean;
  onToggleFollow?: (userId: string, data: Promise<any>) => void;
  onMessageClick?: (userProfile: UserProfileObj) => void;
  onChallengeClick?: (userProfile: UserProfileObj) => void;
};

export default function UserProfile({
  userProfile,
  extension,
  disableCta,
  onToggleFollow,
  onMessageClick,
  onChallengeClick,
}: UserProfileProps) {
  const { t } = useTranslation();
  const userId = useCurrentUserId();
  const { openRightPanel } = useBaseLayout();

  const isMe = userProfile.userId === userId;

  const { getUserOnlineStatus } = useOnlineStatus();
  const getStatus = useCallback(
    (id: string) => {
      const statusCode = getUserOnlineStatus(id);
      return getUserStatus(statusCode);
    },
    [getUserOnlineStatus]
  );

  const handleMessageClick = useCallback(() => {
    onMessageClick?.(userProfile);
  }, [onMessageClick, userProfile]);

  const handleChallengeClick = useCallback(() => {
    onChallengeClick?.(userProfile);
  }, [onChallengeClick, userProfile]);

  return (
    <Box sx={styles.profileContainer}>
      <Box>
        <UserAvatar
          sx={styles.avatar}
          src={userProfile.nftAvatar || userProfile.avatar}
          title={userProfile.displayName || userProfile.distinctName}
          subTitle={`@${userProfile.distinctName}`}
          indicators={getIndicators(userProfile.indicator)}
          size="xl"
          showIndicator
          status={getStatus(userProfile.userId)}
          statusInfo={{
            [SquareAvatarStatus.Busy]: t('common::user.status.busy'),
          }}
        />
      </Box>
      <Box sx={styles.userData}>
        <UserData
          onClick={() =>
            openRightPanel(GlobalPanelKeys.GlobalClubs, {
              userId: userProfile.userId,
            })
          }
          value={userProfile.clubCount}
          label={t('profile::Clubs')}
        />
        <UserData
          onClick={() =>
            openRightPanel(GlobalPanelKeys.GlobalFollowers, {
              userId: userProfile.userId,
            })
          }
          value={userProfile.followerCount}
          label={t('profile::Followers')}
        />
        <UserData
          onClick={() =>
            openRightPanel(GlobalPanelKeys.GlobalFollowing, {
              userId: userProfile.userId,
            })
          }
          value={userProfile.followingCount}
          label={t('profile::Following')}
        />
      </Box>
      <Box sx={[styles.buttons, isMe && { gridTemplateColumns: '1fr' }]}>
        {!isMe ? (
          <>
            <ProfileButton.Follow
              userId={userProfile.userId}
              isFollower={userProfile.isFollower}
              isFollowing={userProfile.isFollowing}
              onToggleFollow={onToggleFollow}
              autoFetchSocialData
            />
            <ProfileButton.Send
              onChallengeClick={handleChallengeClick}
              onMessageClick={handleMessageClick}
            />
          </>
        ) : (
          <Button
            prefixIcon={<TestScratchIcon />}
            component={Link}
            href="/profile/account/edit"
            disabled={disableCta}
            sx={{ maxWidth: '100%!important' }}
          >
            Edit Profile
          </Button>
        )}
      </Box>
      <Box sx={styles.bioContainer}>
        {userProfile.locationCity && userProfile.locationCity && (
          <Typography variant="body1">
            <span role="img" aria-label="location">
              📍
            </span>{' '}
            {userProfile.locationCity}, {userProfile.locationCity}
          </Typography>
        )}

        {userProfile.highSchoolGraduation && (
          <Typography variant="body1">
            <span role="img" aria-label="graduation">
              🎓
            </span>{' '}
            Graduating in {userProfile.highSchoolGraduation}
          </Typography>
        )}

        {userProfile.dreamCollege && (
          <Typography variant="body1">
            <span role="img" aria-label="dream school">
              🤞
            </span>{' '}
            {userProfile.dreamCollege.name}
          </Typography>
        )}

        {userProfile.leagueInfo && (
          <Box sx={styles.league}>
            <Icon
              name={getLeagueBadgeIconName(userProfile.leagueInfo.leagueTier)}
              size={20}
            />
            <Typography variant="body1">
              {t('club::profile.league.rank', {
                count: userProfile.leagueInfo.rank,
                ordinal: true,
                name: getLeagueName(userProfile.leagueInfo.leagueTier),
              })}
            </Typography>
          </Box>
        )}
      </Box>
      <ProfileExtension extension={extension} />
    </Box>
  );
}
