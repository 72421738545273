import { useCallback, useState } from 'react';

export default function useCollectUniqueKeys<T>(): [
  T[],
  (key: T | undefined) => void
] {
  const [keys, setKeys] = useState<T[]>([]);

  const collectKey = useCallback(
    (key: T | undefined) => {
      if (key === undefined) return;

      if (!keys.includes(key)) {
        /**
         * use setTimeout to delay the state update, because getter might be called at any time,
         * without setTimeout, the re-render will be trigger too much time
         * with setTimeout, React will do batch process for us
         */
        setTimeout(() => {
          setKeys((prev) => (prev.includes(key) ? prev : [...prev, key]));
        });
      }
    },
    [keys]
  );

  return [keys, collectKey];
}
