import React, { SVGAttributes } from 'react';

export default function EmojiRocket({ ...rest }: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width="102"
      height="169"
      viewBox="0 0 102 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M29.376 127.994V120.095L47.9222 109.66V117.576L29.376 127.994Z"
        fill="#E6E9F0"
      />
      <path
        d="M29.376 127.994V120.095L47.9222 109.66V117.576L29.376 127.994Z"
        fill="#84604C"
      />
      <path
        d="M29.376 127.994V120.095L47.9222 109.66V117.576L29.376 127.994Z"
        fill="#5CCBF9"
      />
      <path
        d="M29.376 127.994V120.095L47.9222 109.66V117.576L29.376 127.994Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M52.6357 130.909V123.01L71.182 112.575V120.491L52.6357 130.909Z"
        fill="#F5070C"
      />
      <path
        d="M52.6357 130.909V123.01L71.182 112.575V120.491L52.6357 130.909Z"
        fill="black"
        fillOpacity="0.35"
      />
      <path
        d="M58.4414 125.727V117.827L76.9876 107.393V115.308L58.4414 125.727Z"
        fill="#F5070C"
      />
      <path
        d="M58.4414 125.727V117.827L76.9876 107.393V115.308L58.4414 125.727Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M58.4414 117.847V109.947L76.9876 99.5125V107.428L58.4414 117.847Z"
        fill="#FF2A00"
      />
      <path
        d="M58.4414 117.847V109.947L76.9876 99.5125V107.428L58.4414 117.847Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M35.1812 122.812V114.912L53.7274 104.478V112.393L35.1812 122.812Z"
        fill="#E6E9F0"
      />
      <path
        d="M35.1812 122.812V114.912L53.7274 104.478V112.393L35.1812 122.812Z"
        fill="#84604C"
      />
      <path
        d="M35.1812 122.812V114.912L53.7274 104.478V112.393L35.1812 122.812Z"
        fill="#5CCBF9"
      />
      <path
        d="M35.1812 122.812V114.912L53.7274 104.478V112.393L35.1812 122.812Z"
        fill="black"
        fillOpacity="0.35"
      />
      <path
        d="M41.0332 117.518V109.619L59.5794 99.1841V107.1L41.0332 117.518Z"
        fill="#E6E9F0"
      />
      <path
        d="M41.0332 117.518V109.619L59.5794 99.1841V107.1L41.0332 117.518Z"
        fill="#84604C"
      />
      <path
        d="M41.0332 117.518V109.619L59.5794 99.1841V107.1L41.0332 117.518Z"
        fill="#5CCBF9"
      />
      <path
        d="M41.0332 117.518V109.619L59.5794 99.1841V107.1L41.0332 117.518Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M64.2456 112.637V104.737L82.7918 94.3022V102.218L64.2456 112.637Z"
        fill="#E6E9F0"
      />
      <path
        d="M64.2456 112.637V104.737L82.7918 94.3022V102.218L64.2456 112.637Z"
        fill="#84604C"
      />
      <path
        d="M64.2456 112.637V104.737L82.7918 94.3022V102.218L64.2456 112.637Z"
        fill="#E1E0F0"
      />
      <path
        d="M64.2456 112.637V104.737L82.7918 94.3022V102.218L64.2456 112.637Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M75.854 94.3375V86.4377L94.4002 76.0032V83.9188L75.854 94.3375Z"
        fill="#E6E9F0"
      />
      <path
        d="M75.854 94.3375V86.4377L94.4002 76.0032V83.9188L75.854 94.3375Z"
        fill="#84604C"
      />
      <path
        d="M75.854 94.3375V86.4377L94.4002 76.0032V83.9188L75.854 94.3375Z"
        fill="#E1E0F0"
      />
      <path
        d="M75.854 94.3375V86.4377L94.4002 76.0032V83.9188L75.854 94.3375Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M75.854 102.216V94.3161L94.4002 83.8816V91.7973L75.854 102.216Z"
        fill="#E6E9F0"
      />
      <path
        d="M75.854 102.216V94.3161L94.4002 83.8816V91.7973L75.854 102.216Z"
        fill="#84604C"
      />
      <path
        d="M75.854 102.216V94.3161L94.4002 83.8816V91.7973L75.854 102.216Z"
        fill="#E1E0F0"
      />
      <path
        d="M75.854 102.216V94.3161L94.4002 83.8816V91.7973L75.854 102.216Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M70.0498 107.426V99.5266L88.596 89.092V97.0077L70.0498 107.426Z"
        fill="#E6E9F0"
      />
      <path
        d="M70.0498 107.426V99.5266L88.596 89.092V97.0077L70.0498 107.426Z"
        fill="#84604C"
      />
      <path
        d="M70.0498 107.426V99.5266L88.596 89.092V97.0077L70.0498 107.426Z"
        fill="#E1E0F0"
      />
      <path
        d="M70.0498 107.426V99.5266L88.596 89.092V97.0077L70.0498 107.426Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M17.8434 59.1714L12.0293 56.4405L30.5478 46.0383L36.3503 48.7795L17.8434 59.1714Z"
        fill="#D2B4DB"
      />
      <path
        d="M17.8434 59.1714L12.0293 56.4405L30.5478 46.0383L36.3503 48.7795L17.8434 59.1714Z"
        fill="#84604C"
      />
      <path
        d="M17.8434 59.1714L12.0293 56.4405L30.5478 46.0383L36.3503 48.7795L17.8434 59.1714Z"
        fill="#FF2A00"
      />
      <path
        d="M17.8434 59.1714L12.0293 56.4405L30.5478 46.0383L36.3503 48.7795L17.8434 59.1714Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M23.6647 77.7122L17.8506 74.9812L36.3691 64.5791L42.1716 67.3202L23.6647 77.7122Z"
        fill="#D2B4DB"
      />
      <path
        d="M23.6647 77.7122L17.8506 74.9812L36.3691 64.5791L42.1716 67.3202L23.6647 77.7122Z"
        fill="#84604C"
      />
      <path
        d="M23.6647 77.7122L17.8506 74.9812L36.3691 64.5791L42.1716 67.3202L23.6647 77.7122Z"
        fill="#5CCBF9"
      />
      <path
        d="M23.6647 77.7122L17.8506 74.9812L36.3691 64.5791L42.1716 67.3202L23.6647 77.7122Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M17.8487 82.8992L12.0347 80.1683L30.5532 69.7661L36.3557 72.5072L17.8487 82.8992Z"
        fill="#D2B4DB"
      />
      <path
        d="M17.8487 82.8992L12.0347 80.1683L30.5532 69.7661L36.3557 72.5072L17.8487 82.8992Z"
        fill="#84604C"
      />
      <path
        d="M17.8487 82.8992L12.0347 80.1683L30.5532 69.7661L36.3557 72.5072L17.8487 82.8992Z"
        fill="#5CCBF9"
      />
      <path
        d="M17.8487 82.8992L12.0347 80.1683L30.5532 69.7661L36.3557 72.5072L17.8487 82.8992Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M12.0475 88.0774L6.2334 85.3465L24.7519 74.9443L30.5544 77.6855L12.0475 88.0774Z"
        fill="#D2B4DB"
      />
      <path
        d="M12.0475 88.0774L6.2334 85.3465L24.7519 74.9443L30.5544 77.6855L12.0475 88.0774Z"
        fill="#84604C"
      />
      <path
        d="M12.0475 88.0774L6.2334 85.3465L24.7519 74.9443L30.5544 77.6855L12.0475 88.0774Z"
        fill="#5CCBF9"
      />
      <path
        d="M12.0475 88.0774L6.2334 85.3465L24.7519 74.9443L30.5544 77.6855L12.0475 88.0774Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M29.4689 56.7153L23.6548 53.9844L42.1733 43.5823L48.001 46.2936L29.4689 56.7153Z"
        fill="#D2B4DB"
      />
      <path
        d="M29.4689 56.7153L23.6548 53.9844L42.1733 43.5823L48.001 46.2936L29.4689 56.7153Z"
        fill="#84604C"
      />
      <path
        d="M29.4689 56.7153L23.6548 53.9844L42.1733 43.5823L48.001 46.2936L29.4689 56.7153Z"
        fill="#FF2A00"
      />
      <path
        d="M23.6583 53.9778L17.8442 51.2469L36.3628 40.8447L42.1652 43.5859L23.6583 53.9778Z"
        fill="#D2B4DB"
      />
      <path
        d="M23.6583 53.9778L17.8442 51.2469L36.3628 40.8447L42.1652 43.5859L23.6583 53.9778Z"
        fill="#84604C"
      />
      <path
        d="M23.6583 53.9778L17.8442 51.2469L36.3628 40.8447L42.1652 43.5859L23.6583 53.9778Z"
        fill="#FF2A00"
      />
      <path
        d="M23.6583 53.9778L17.8442 51.2469L36.3628 40.8447L42.1652 43.5859L23.6583 53.9778Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M46.8746 41.1472L41.0605 38.4163L59.5791 28.0142L65.3815 30.7553L46.8746 41.1472Z"
        fill="#D2B4DB"
      />
      <path
        d="M46.8746 41.1472L41.0605 38.4163L59.5791 28.0142L65.3815 30.7553L46.8746 41.1472Z"
        fill="#84604C"
      />
      <path
        d="M46.8746 41.1472L41.0605 38.4163L59.5791 28.0142L65.3815 30.7553L46.8746 41.1472Z"
        fill="white"
      />
      <path
        d="M46.8746 41.1472L41.0605 38.4163L59.5791 28.0142L65.3815 30.7553L46.8746 41.1472Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M41.0431 46.3474L35.229 43.6165L53.7476 33.2144L59.55 35.9555L41.0431 46.3474Z"
        fill="#D2B4DB"
      />
      <path
        d="M41.0431 46.3474L35.229 43.6165L53.7476 33.2144L59.55 35.9555L41.0431 46.3474Z"
        fill="#84604C"
      />
      <path
        d="M41.0431 46.3474L35.229 43.6165L53.7476 33.2144L59.55 35.9555L41.0431 46.3474Z"
        fill="white"
      />
      <path
        d="M41.0431 46.3474L35.229 43.6165L53.7476 33.2144L59.55 35.9555L41.0431 46.3474Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M35.2384 51.5269L29.4243 48.7959L47.9429 38.3938L53.7453 41.1349L35.2384 51.5269Z"
        fill="#D2B4DB"
      />
      <path
        d="M35.2384 51.5269L29.4243 48.7959L47.9429 38.3938L53.7453 41.1349L35.2384 51.5269Z"
        fill="#84604C"
      />
      <path
        d="M35.2384 51.5269L29.4243 48.7959L47.9429 38.3938L53.7453 41.1349L35.2384 51.5269Z"
        fill="white"
      />
      <path
        d="M35.2384 51.5269L29.4243 48.7959L47.9429 38.3938L53.7453 41.1349L35.2384 51.5269Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M52.6852 43.8845L46.8711 41.1536L65.3896 30.7515L71.2173 33.4628L52.6852 43.8845Z"
        fill="#D2B4DB"
      />
      <path
        d="M52.6852 43.8845L46.8711 41.1536L65.3896 30.7515L71.2173 33.4628L52.6852 43.8845Z"
        fill="#84604C"
      />
      <path
        d="M52.6852 43.8845L46.8711 41.1536L65.3896 30.7515L71.2173 33.4628L52.6852 43.8845Z"
        fill="white"
      />
      <path
        d="M81.6719 57.4854V49.5857L100.218 39.1511V47.0668L81.6719 57.4854Z"
        fill="#E6E9F0"
      />
      <path
        d="M81.6719 57.4854V49.5857L100.218 39.1511V47.0668L81.6719 57.4854Z"
        fill="#84604C"
      />
      <path
        d="M81.6719 57.4854V49.5857L100.218 39.1511V47.0668L81.6719 57.4854Z"
        fill="#F5070C"
      />
      <path
        d="M81.6719 57.4854V49.5857L100.218 39.1511V47.0668L81.6719 57.4854Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M81.6719 65.3851V57.4853L100.218 47.0508V54.9664L81.6719 65.3851Z"
        fill="#E6E9F0"
      />
      <path
        d="M81.6719 65.3851V57.4853L100.218 47.0508V54.9664L81.6719 65.3851Z"
        fill="#84604C"
      />
      <path
        d="M81.6719 65.3851V57.4853L100.218 47.0508V54.9664L81.6719 65.3851Z"
        fill="#F5070C"
      />
      <path
        d="M81.6719 65.3851V57.4853L100.218 47.0508V54.9664L81.6719 65.3851Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M81.6719 73.2691V65.3694L100.218 54.9348V62.8505L81.6719 73.2691Z"
        fill="#E6E9F0"
      />
      <path
        d="M81.6719 73.2691V65.3694L100.218 54.9348V62.8505L81.6719 73.2691Z"
        fill="#84604C"
      />
      <path
        d="M81.6719 73.2691V65.3694L100.218 54.9348V62.8505L81.6719 73.2691Z"
        fill="#F5070C"
      />
      <path
        d="M81.6719 73.2691V65.3694L100.218 54.9348V62.8505L81.6719 73.2691Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M81.6719 89.0525V81.1528L100.218 70.7183V78.6339L81.6719 89.0525Z"
        fill="#E6E9F0"
      />
      <path
        d="M81.6719 89.0525V81.1528L100.218 70.7183V78.6339L81.6719 89.0525Z"
        fill="#84604C"
      />
      <path
        d="M81.6719 89.0525V81.1528L100.218 70.7183V78.6339L81.6719 89.0525Z"
        fill="#E1E0F0"
      />
      <path
        d="M81.6719 89.0525V81.1528L100.218 70.7183V78.6339L81.6719 89.0525Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M81.6719 81.1688V73.269L100.218 62.8345V70.7501L81.6719 81.1688Z"
        fill="#E6E9F0"
      />
      <path
        d="M81.6719 81.1688V73.269L100.218 62.8345V70.7501L81.6719 81.1688Z"
        fill="#84604C"
      />
      <path
        d="M81.6719 81.1688V73.269L100.218 62.8345V70.7501L81.6719 81.1688Z"
        fill="#E1E0F0"
      />
      <path
        d="M81.6719 81.1688V73.269L100.218 62.8345V70.7501L81.6719 81.1688Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M81.6822 49.5977L75.8682 46.8667L94.3867 36.4646L100.214 39.176L81.6822 49.5977Z"
        fill="#D2B4DB"
      />
      <path
        d="M81.6822 49.5977L75.8682 46.8667L94.3867 36.4646L100.214 39.176L81.6822 49.5977Z"
        fill="#84604C"
      />
      <path
        d="M81.6822 49.5977L75.8682 46.8667L94.3867 36.4646L100.214 39.176L81.6822 49.5977Z"
        fill="#F5070C"
      />
      <path
        d="M81.6822 49.5977L75.8682 46.8667L94.3867 36.4646L100.214 39.176L81.6822 49.5977Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M75.8859 46.8665L70.0718 44.1355L88.5903 33.7334L94.418 36.4448L75.8859 46.8665Z"
        fill="#D2B4DB"
      />
      <path
        d="M75.8859 46.8665L70.0718 44.1355L88.5903 33.7334L94.418 36.4448L75.8859 46.8665Z"
        fill="#84604C"
      />
      <path
        d="M75.8859 46.8665L70.0718 44.1355L88.5903 33.7334L94.418 36.4448L75.8859 46.8665Z"
        fill="#F5070C"
      />
      <path
        d="M75.8859 46.8665L70.0718 44.1355L88.5903 33.7334L94.418 36.4448L75.8859 46.8665Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M70.0792 44.1589L64.2651 41.428L82.7837 31.0259L88.6113 33.7372L70.0792 44.1589Z"
        fill="#D2B4DB"
      />
      <path
        d="M70.0792 44.1589L64.2651 41.428L82.7837 31.0259L88.6113 33.7372L70.0792 44.1589Z"
        fill="#84604C"
      />
      <path
        d="M70.0792 44.1589L64.2651 41.428L82.7837 31.0259L88.6113 33.7372L70.0792 44.1589Z"
        fill="#F5070C"
      />
      <path
        d="M70.0792 44.1589L64.2651 41.428L82.7837 31.0259L88.6113 33.7372L70.0792 44.1589Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M64.2823 41.4277L58.4683 38.6968L76.9868 28.2947L82.8144 31.006L64.2823 41.4277Z"
        fill="#D2B4DB"
      />
      <path
        d="M64.2823 41.4277L58.4683 38.6968L76.9868 28.2947L82.8144 31.006L64.2823 41.4277Z"
        fill="#84604C"
      />
      <path
        d="M64.2823 41.4277L58.4683 38.6968L76.9868 28.2947L82.8144 31.006L64.2823 41.4277Z"
        fill="white"
      />
      <path
        d="M64.2823 41.4277L58.4683 38.6968L76.9868 28.2947L82.8144 31.006L64.2823 41.4277Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M58.4718 38.6904L52.6577 35.9595L71.1763 25.5574L76.9787 28.2985L58.4718 38.6904Z"
        fill="#D2B4DB"
      />
      <path
        d="M58.4718 38.6904L52.6577 35.9595L71.1763 25.5574L76.9787 28.2985L58.4718 38.6904Z"
        fill="#84604C"
      />
      <path
        d="M58.4718 38.6904L52.6577 35.9595L71.1763 25.5574L76.9787 28.2985L58.4718 38.6904Z"
        fill="white"
      />
      <path
        d="M58.4718 38.6904L52.6577 35.9595L71.1763 25.5574L76.9787 28.2985L58.4718 38.6904Z"
        fill="black"
        fillOpacity="0.3"
      />
      <g clipPath="url(#clip0_3351_98830)">
        <path
          d="M29.4495 119.99L23.647 117.262V125.175L29.4495 127.902V119.99Z"
          fill="#5CCBF9"
        />
        <path
          d="M23.6468 117.262L17.8442 114.535V122.447L23.6468 125.175V117.262Z"
          fill="#5CCBF9"
        />
        <path
          d="M17.8445 114.535L12.042 111.807V119.72L17.8445 122.447V114.535Z"
          fill="#5CCBF9"
        />
        <path
          d="M12.0418 111.807L6.23926 109.08V116.993L12.0418 119.72V111.807Z"
          fill="#5CCBF9"
        />
        <path
          d="M52.659 122.987L46.8564 120.259V128.172L52.659 130.899V122.987Z"
          fill="#F5070C"
        />
        <path
          d="M35.2522 114.805L29.4497 112.077V119.99L35.2522 122.717V114.805Z"
          fill="#5CCBF9"
        />
        <path
          d="M29.4495 112.077L23.647 109.35V117.262L29.4495 119.99V112.077Z"
          fill="#95F9FB"
        />
        <path
          d="M23.6468 109.35L17.8442 106.622V114.535L23.6468 117.262V109.35Z"
          fill="#95F9FB"
        />
        <path
          d="M17.8445 106.622L12.042 103.895V111.808L17.8445 114.535V106.622Z"
          fill="#95F9FB"
        />
        <path
          d="M12.0418 103.895L6.23926 101.167V109.08L12.0418 111.807V103.895Z"
          fill="#5CCBF9"
        />
        <path
          d="M58.4617 117.802L52.6592 115.074V122.987L58.4617 125.714V117.802Z"
          fill="#F5070C"
        />
        <path
          d="M52.659 115.074L46.8564 112.347V120.259L52.659 122.987V115.074Z"
          fill="#FF2A00"
        />
        <path
          d="M41.0545 109.619L35.252 106.892V114.804L41.0545 117.532V109.619Z"
          fill="#5CCBF9"
        />
        <path
          d="M35.2522 106.892L29.4497 104.165V112.077L35.2522 114.805V106.892Z"
          fill="#95F9FB"
        />
        <path
          d="M29.4495 104.165L23.647 101.437V109.35L29.4495 112.077V104.165Z"
          fill="#DFFFFE"
        />
        <path
          d="M23.6468 101.437L17.8442 98.7097V106.622L23.6468 109.35V101.437Z"
          fill="#DFFFFE"
        />
        <path
          d="M17.8445 98.7096L12.042 95.9822V103.895L17.8445 106.622V98.7096Z"
          fill="#95F9FB"
        />
        <path
          d="M12.0418 95.9823L6.23926 93.2549V101.167L12.0418 103.895V95.9823Z"
          fill="#5CCBF9"
        />
        <path
          d="M58.4617 109.889L52.6592 107.162V115.074L58.4617 117.802V109.889Z"
          fill="#FF2A00"
        />
        <path
          d="M52.659 107.162L46.8564 104.434V112.347L52.659 115.074V107.162Z"
          fill="#FF2A00"
        />
        <path
          d="M46.8572 104.434L41.0547 101.707V109.619L46.8572 112.347V104.434Z"
          fill="white"
        />
        <path
          d="M41.0545 101.707L35.252 98.9795V106.892L41.0545 109.619V101.707Z"
          fill="#617087"
        />
        <path
          d="M35.2522 98.9794L29.4497 96.252V104.165L35.2522 106.892V98.9794Z"
          fill="#DFFFFE"
        />
        <path
          d="M29.4495 96.2518L23.647 93.5244V101.437L29.4495 104.164V96.2518Z"
          fill="#DFFFFE"
        />
        <path
          d="M23.6468 93.5245L17.8442 90.7971V98.7097L23.6468 101.437V93.5245Z"
          fill="#DFFFFE"
        />
        <path
          d="M17.8445 90.7972L12.042 88.0698V95.9824L17.8445 98.7098V90.7972Z"
          fill="#95F9FB"
        />
        <path
          d="M12.0418 88.0697L6.23926 85.3423V93.2549L12.0418 95.9823V88.0697Z"
          fill="#5CCBF9"
        />
        <path
          d="M64.2645 104.704L58.4619 101.977V109.889L64.2645 112.617V104.704Z"
          fill="#E1E0F0"
        />
        <path
          d="M58.4617 101.976L52.6592 99.249V107.162L58.4617 109.889V101.976Z"
          fill="#FF2A00"
        />
        <path
          d="M52.659 99.2489L46.8564 96.5215V104.434L52.659 107.161V99.2489Z"
          fill="white"
        />
        <path
          d="M46.8572 96.5216L41.0547 93.7942V101.707L46.8572 104.434V96.5216Z"
          fill="white"
        />
        <path
          d="M41.0545 93.7943L35.252 91.0669V98.9795L41.0545 101.707V93.7943Z"
          fill="white"
        />
        <path
          d="M35.2522 91.0668L29.4497 88.3394V96.2519L35.2522 98.9794V91.0668Z"
          fill="#F5070C"
        />
        <path
          d="M29.4495 88.3395L23.647 85.6121V93.5247L29.4495 96.2521V88.3395Z"
          fill="#DFFFFE"
        />
        <path
          d="M23.6468 85.6119L17.8442 82.8845V90.7971L23.6468 93.5245V85.6119Z"
          fill="#95F9FB"
        />
        <path
          d="M17.8445 82.8846L12.042 80.1572V88.0698L17.8445 90.7972V82.8846Z"
          fill="#5CCBF9"
        />
        <path
          d="M70.0667 99.5187L64.2642 96.7913V104.704L70.0667 107.431V99.5187Z"
          fill="#E1E0F0"
        />
        <path
          d="M64.2645 96.7911L58.4619 94.0637V101.976L64.2645 104.704V96.7911Z"
          fill="#E1E0F0"
        />
        <path
          d="M58.4617 94.0638L52.6592 91.3364V99.249L58.4617 101.976V94.0638Z"
          fill="white"
        />
        <path
          d="M52.659 91.3365L46.8564 88.6091V96.5217L52.659 99.2491V91.3365Z"
          fill="white"
        />
        <path
          d="M46.8572 88.609L41.0547 85.8816V93.7942L46.8572 96.5216V88.609Z"
          fill="white"
        />
        <path
          d="M41.0545 85.8817L35.252 83.1543V91.0669L41.0545 93.7943V85.8817Z"
          fill="#F5070C"
        />
        <path
          d="M35.2522 83.1542L29.4497 80.4268V88.3393L35.2522 91.0668V83.1542Z"
          fill="white"
        />
        <path
          d="M29.4495 80.4269L23.647 77.6995V85.612L29.4495 88.3395V80.4269Z"
          fill="#617087"
        />
        <path
          d="M23.6468 77.6993L17.8442 74.9719V82.8845L23.6468 85.6119V77.6993Z"
          fill="#5CCBF9"
        />
        <path
          d="M75.8694 94.3334L70.0669 91.606V99.5186L75.8694 102.246V94.3334Z"
          fill="#E1E0F0"
        />
        <path
          d="M70.0667 91.6061L64.2642 88.8787V96.7913L70.0667 99.5187V91.6061Z"
          fill="#E1E0F0"
        />
        <path
          d="M64.2645 88.8788L58.4619 86.1514V94.064L64.2645 96.7914V88.8788Z"
          fill="white"
        />
        <path
          d="M58.4617 86.1512L52.6592 83.4238V91.3364L58.4617 94.0638V86.1512Z"
          fill="white"
        />
        <path
          d="M52.659 83.4239L46.8564 80.6965V88.6091L52.659 91.3365V83.4239Z"
          fill="white"
        />
        <path
          d="M46.8572 80.6964L41.0547 77.969V85.8816L46.8572 88.609V80.6964Z"
          fill="#F5070C"
        />
        <path
          d="M41.0545 77.9691L35.252 75.2417V83.1543L41.0545 85.8817V77.9691Z"
          fill="white"
        />
        <path
          d="M35.2522 75.2416L29.4497 72.5142V80.4268L35.2522 83.1542V75.2416Z"
          fill="white"
        />
        <path
          d="M29.4495 72.5143L23.647 69.7869V77.6995L29.4495 80.4269V72.5143Z"
          fill="white"
        />
        <path
          d="M75.8694 86.421L70.0669 83.6936V91.6062L75.8694 94.3336V86.421Z"
          fill="#E1E0F0"
        />
        <path
          d="M70.0667 83.6935L64.2642 80.9661V88.8786L70.0667 91.6061V83.6935Z"
          fill="white"
        />
        <path
          d="M64.2645 80.9659L58.4619 78.2385V86.1511L64.2645 88.8785V80.9659Z"
          fill="#F5070C"
        />
        <path
          d="M58.4617 78.2386L52.6592 75.5112V83.4238L58.4617 86.1512V78.2386Z"
          fill="#F5070C"
        />
        <path
          d="M52.659 75.5113L46.8564 72.7839V80.6965L52.659 83.4239V75.5113Z"
          fill="white"
        />
        <path
          d="M46.8572 72.7838L41.0547 70.0564V77.969L46.8572 80.6964V72.7838Z"
          fill="white"
        />
        <path
          d="M41.0545 70.0565L35.252 67.3291V75.2417L41.0545 77.9691V70.0565Z"
          fill="white"
        />
        <path
          d="M35.2522 67.329L29.4497 64.6016V72.5141L35.2522 75.2416V67.329Z"
          fill="white"
        />
        <path
          d="M29.4495 64.6017L23.647 61.8743V69.7869L29.4495 72.5143V64.6017Z"
          fill="#F5070C"
        />
        <path
          d="M23.6468 61.8741L17.8442 59.1467V67.0593L23.6468 69.7867V61.8741Z"
          fill="#FF2A00"
        />
        <path
          d="M17.8445 59.1468L12.042 56.4194V64.332L17.8445 67.0594V59.1468Z"
          fill="#FF2A00"
        />
        <path
          d="M81.6722 81.2357L75.8696 78.5083V86.4209L81.6722 89.1483V81.2357Z"
          fill="#E1E0F0"
        />
        <path
          d="M75.8694 78.5084L70.0669 75.781V83.6936L75.8694 86.421V78.5084Z"
          fill="white"
        />
        <path
          d="M70.0667 75.7809L64.2642 73.0535V80.9661L70.0667 83.6935V75.7809Z"
          fill="#F5070C"
        />
        <path
          d="M64.2645 73.0536L58.4619 70.3262V78.2388L64.2645 80.9662V73.0536Z"
          fill="#76C5D2"
        />
        <path
          d="M58.4617 70.326L52.6592 67.5986V75.5112L58.4617 78.2386V70.326Z"
          fill="#76C5D2"
        />
        <path
          d="M52.659 67.5987L46.8564 64.8713V72.7839L52.659 75.5113V67.5987Z"
          fill="#F5070C"
        />
        <path
          d="M46.8572 64.8712L41.0547 62.1438V70.0564L46.8572 72.7838V64.8712Z"
          fill="white"
        />
        <path
          d="M41.0545 62.1439L35.252 59.4165V67.3291L41.0545 70.0565V62.1439Z"
          fill="white"
        />
        <path
          d="M35.2522 59.4164L29.4497 56.689V64.6016L35.2522 67.329V59.4164Z"
          fill="#F5070C"
        />
        <path
          d="M29.4495 56.6891L23.647 53.9617V61.8743L29.4495 64.6017V56.6891Z"
          fill="#FF2A00"
        />
        <path
          d="M23.6468 53.9615L17.8442 51.2341V59.1467L23.6468 61.8741V53.9615Z"
          fill="#FF2A00"
        />
        <path
          d="M81.6722 73.3231L75.8696 70.5957V78.5083L81.6722 81.2357V73.3231Z"
          fill="#E1E0F0"
        />
        <path
          d="M75.8694 70.5958L70.0669 67.8684V75.781L75.8694 78.5084V70.5958Z"
          fill="white"
        />
        <path
          d="M70.0667 67.8683L64.2642 65.1409V73.0535L70.0667 75.7809V67.8683Z"
          fill="#F5070C"
        />
        <path
          d="M64.2645 65.141L58.4619 62.4136V70.3262L64.2645 73.0536V65.141Z"
          fill="#76C5D2"
        />
        <path
          d="M58.4617 62.4134L52.6592 59.686V67.5986L58.4617 70.326V62.4134Z"
          fill="#B3E3EB"
        />
        <path
          d="M52.659 59.6862L46.8564 56.9587V64.8713L52.659 67.5987V59.6862Z"
          fill="#F5070C"
        />
        <path
          d="M46.8572 56.9586L41.0547 54.2312V62.1438L46.8572 64.8712V56.9586Z"
          fill="white"
        />
        <path
          d="M41.0545 54.2313L35.252 51.5039V59.4165L41.0545 62.1439V54.2313Z"
          fill="white"
        />
        <path
          d="M35.2522 51.5038L29.4497 48.7764V56.689L35.2522 59.4164V51.5038Z"
          fill="white"
        />
        <path
          d="M81.6722 65.4105L75.8696 62.6831V70.5957L81.6722 73.3231V65.4105Z"
          fill="#F5070C"
        />
        <path
          d="M75.8694 62.6832L70.0669 59.9558V67.8684L75.8694 70.5958V62.6832Z"
          fill="white"
        />
        <path
          d="M70.0667 59.9557L64.2642 57.2283V65.1409L70.0667 67.8683V59.9557Z"
          fill="white"
        />
        <path
          d="M64.2645 57.2284L58.4619 54.501V62.4136L64.2645 65.141V57.2284Z"
          fill="#F5070C"
        />
        <path
          d="M58.4617 54.5009L52.6592 51.7734V59.686L58.4617 62.4134V54.5009Z"
          fill="#F5070C"
        />
        <path
          d="M52.659 51.7736L46.8564 49.0461V56.9587L52.659 59.6861V51.7736Z"
          fill="white"
        />
        <path
          d="M46.8572 49.046L41.0547 46.3186V54.2312L46.8572 56.9586V49.046Z"
          fill="white"
        />
        <path
          d="M41.0545 46.3187L35.252 43.5913V51.5039L41.0545 54.2313V46.3187Z"
          fill="white"
        />
        <path
          d="M81.6722 57.4979L75.8696 54.7705V62.6831L81.6722 65.4105V57.4979Z"
          fill="#F5070C"
        />
        <path
          d="M75.8694 54.7706L70.0669 52.0432V59.9558L75.8694 62.6832V54.7706Z"
          fill="#FF2A00"
        />
        <path
          d="M70.0667 52.0431L64.2642 49.3157V57.2283L70.0667 59.9557V52.0431Z"
          fill="white"
        />
        <path
          d="M64.2645 49.3158L58.4619 46.5884V54.501L64.2645 57.2284V49.3158Z"
          fill="white"
        />
        <path
          d="M58.4617 46.5883L52.6592 43.8608V51.7734L58.4617 54.5008V46.5883Z"
          fill="white"
        />
        <path
          d="M52.659 43.861L46.8564 41.1335V49.0461L52.659 51.7735V43.861Z"
          fill="white"
        />
        <path
          d="M46.8572 41.1334L41.0547 38.406V46.3186L46.8572 49.046V41.1334Z"
          fill="white"
        />
        <path
          d="M81.6722 49.5853L75.8696 46.8579V54.7705L81.6722 57.4979V49.5853Z"
          fill="#F5070C"
        />
        <path
          d="M75.8694 46.858L70.0669 44.1306V52.0432L75.8694 54.7706V46.858Z"
          fill="#FF2A00"
        />
        <path
          d="M70.0667 44.1305L64.2642 41.4031V49.3157L70.0667 52.0431V44.1305Z"
          fill="#FF2A00"
        />
        <path
          d="M64.2645 41.4032L58.4619 38.6758V46.5884L64.2645 49.3158V41.4032Z"
          fill="white"
        />
        <path
          d="M58.4617 38.6757L52.6592 35.9482V43.8608L58.4617 46.5882V38.6757Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3351_98830">
          <rect
            width="96.1735"
            height="118.689"
            fill="white"
            transform="matrix(0.90501 0.42539 0 1 0.436523 3.48901)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
