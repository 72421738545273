import api from './client';

const goalApi = {
  saveGoal: (params: QuizGoalReq) => api.post('v2/goal/section/me', params),
  saveGoalDaily: (params: QuizGoalDailyReq) =>
    api.post('v2/goal/section/daily/me', params),
  getGoal: (sectionId: string) => {
    return api.get<Response<GetGoalRes>>(`v2/goal/section/${sectionId}/me`);
  },
};

export default goalApi;
