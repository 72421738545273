import { PropsWithChildren, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

const styles = {
  root: {
    display: 'grid',
    gap: 1,
  },
  text: {
    opacity: 0.64,
  },
};
export default function EmptySection({
  children,
  actionComponent,
}: PropsWithChildren<{ actionComponent?: ReactNode }>) {
  return (
    <Box sx={styles.root}>
      <Typography sx={styles.text} variant="body2" component="div">
        {children}
      </Typography>

      {!!actionComponent && <Box>{actionComponent}</Box>}
    </Box>
  );
}
