import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { LocationDetail } from '@lib/web/thread/types';

export type ThreadLocationContextValue = {
  currentLocationDetail: LocationDetail;
  setCurrentLocationDetail: Dispatch<SetStateAction<LocationDetail>>;
  defaultSendPublicly: boolean;
  setDefaultSendPublicly: Dispatch<SetStateAction<boolean>>;
};

export const ThreadCurrentLocationContext =
  createContext<ThreadLocationContextValue>({
    currentLocationDetail: {},
    setCurrentLocationDetail: () => {},
    defaultSendPublicly: false,
    setDefaultSendPublicly: () => {},
  });

type ThreadLocationProviderProps = {
  children: ReactNode;
  defaultLocationDetail: LocationDetail;
};

export function ThreadCurrentLocationProvider({
  children,
  defaultLocationDetail,
}: ThreadLocationProviderProps) {
  const [currentLocationDetail, setCurrentLocationDetail] =
    useState<LocationDetail>({});
  const [defaultSendPublicly, setDefaultSendPublicly] = useState(false);

  const context = useMemo(
    () => ({
      currentLocationDetail: {
        ...defaultLocationDetail,
        ...currentLocationDetail,
      },
      setCurrentLocationDetail,
      defaultSendPublicly,
      setDefaultSendPublicly,
    }),
    [currentLocationDetail, defaultLocationDetail, defaultSendPublicly]
  );

  return (
    <ThreadCurrentLocationContext.Provider value={context}>
      {children}
    </ThreadCurrentLocationContext.Provider>
  );
}
