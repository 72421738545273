import React from 'react';

export default function OtherStop({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M6.66667 4H17.3333C18.8 4 20 5.2 20 6.66667V17.3333C20 18.8 18.8 20 17.3333 20H6.66667C5.2 20 4 18.8 4 17.3333V6.66667C4 5.2 5.2 4 6.66667 4Z"
        fill="currentColor"
      />
    </svg>
  );
}
