import React from 'react';

export default function EditorUnscramble({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#EB5F3B"
        fillRule="evenodd"
      />
      <path
        d="M4.37402 4.52127C4.37402 4.23338 4.57682 4 4.82697 4H19.2364C19.4866 4 19.6894 4.23338 19.6894 4.52127V8.11703C19.6894 8.40492 19.4866 8.6383 19.2364 8.6383H4.82697C4.57681 8.6383 4.37402 8.40492 4.37402 8.11703V4.52127Z"
        fill="#080808"
      />
      <path
        d="M17.5326 7.29667C17.5326 7.47586 17.4052 7.62246 17.2495 7.62246C17.0938 7.62246 16.9664 7.47586 16.9664 7.29667C16.9664 7.11748 17.0938 6.97087 17.2495 6.97087C17.4052 6.97087 17.5326 7.11748 17.5326 7.29667ZM17.2495 5.99348C17.0938 5.99348 16.9664 6.14009 16.9664 6.31928C16.9664 6.49847 17.0938 6.64508 17.2495 6.64508C17.4052 6.64508 17.5326 6.49847 17.5326 6.31928C17.5326 6.14009 17.4052 5.99348 17.2495 5.99348ZM17.2495 5.0161C17.0938 5.0161 16.9664 5.16271 16.9664 5.34189C16.9664 5.52108 17.0938 5.66769 17.2495 5.66769C17.4052 5.66769 17.5326 5.52108 17.5326 5.34189C17.5326 5.16271 17.4052 5.0161 17.2495 5.0161ZM18.0988 5.66769C18.2545 5.66769 18.3819 5.52108 18.3819 5.34189C18.3819 5.16271 18.2545 5.0161 18.0988 5.0161C17.9431 5.0161 17.8157 5.16271 17.8157 5.34189C17.8157 5.52108 17.9431 5.66769 18.0988 5.66769ZM18.0988 5.99348C17.9431 5.99348 17.8157 6.14009 17.8157 6.31928C17.8157 6.49847 17.9431 6.64508 18.0988 6.64508C18.2545 6.64508 18.3819 6.49847 18.3819 6.31928C18.3819 6.14009 18.2545 5.99348 18.0988 5.99348ZM18.0988 6.97087C17.9431 6.97087 17.8157 7.11748 17.8157 7.29667C17.8157 7.47586 17.9431 7.62246 18.0988 7.62246C18.2545 7.62246 18.3819 7.47586 18.3819 7.29667C18.3819 7.11748 18.2545 6.97087 18.0988 6.97087Z"
        fill="#EB5F3B"
      />
      <path
        d="M4.37402 10.2021C4.37402 9.91423 4.57682 9.68085 4.82697 9.68085H19.2364C19.4866 9.68085 19.6894 9.91423 19.6894 10.2021V13.7979C19.6894 14.0858 19.4866 14.3192 19.2364 14.3192H4.82697C4.57681 14.3192 4.37402 14.0858 4.37402 13.7979V10.2021Z"
        fill="#080808"
      />
      <path
        d="M17.5327 12.9775C17.5327 13.1567 17.4053 13.3033 17.2496 13.3033C17.0939 13.3033 16.9665 13.1567 16.9665 12.9775C16.9665 12.7983 17.0939 12.6517 17.2496 12.6517C17.4053 12.6517 17.5327 12.7983 17.5327 12.9775ZM17.2496 11.6743C17.0939 11.6743 16.9665 11.8209 16.9665 12.0001C16.9665 12.1793 17.0939 12.3259 17.2496 12.3259C17.4053 12.3259 17.5327 12.1793 17.5327 12.0001C17.5327 11.8209 17.4053 11.6743 17.2496 11.6743ZM17.2496 10.6969C17.0939 10.6969 16.9665 10.8436 16.9665 11.0227C16.9665 11.2019 17.0939 11.3485 17.2496 11.3485C17.4053 11.3485 17.5327 11.2019 17.5327 11.0227C17.5327 10.8436 17.4053 10.6969 17.2496 10.6969ZM18.0989 11.3485C18.2546 11.3485 18.382 11.2019 18.382 11.0227C18.382 10.8436 18.2546 10.6969 18.0989 10.6969C17.9432 10.6969 17.8158 10.8436 17.8158 11.0227C17.8158 11.2019 17.9432 11.3485 18.0989 11.3485ZM18.0989 11.6743C17.9432 11.6743 17.8158 11.8209 17.8158 12.0001C17.8158 12.1793 17.9432 12.3259 18.0989 12.3259C18.2546 12.3259 18.382 12.1793 18.382 12.0001C18.382 11.8209 18.2546 11.6743 18.0989 11.6743ZM18.0989 12.6517C17.9432 12.6517 17.8158 12.7983 17.8158 12.9775C17.8158 13.1567 17.9432 13.3033 18.0989 13.3033C18.2546 13.3033 18.382 13.1567 18.382 12.9775C18.382 12.7983 18.2546 12.6517 18.0989 12.6517Z"
        fill="#EB5F3B"
      />
      <path
        d="M4.37402 15.883C4.37402 15.5951 4.57682 15.3617 4.82697 15.3617H19.2364C19.4866 15.3617 19.6894 15.5951 19.6894 15.883V19.4787C19.6894 19.7666 19.4866 20 19.2364 20H4.82697C4.57681 20 4.37402 19.7666 4.37402 19.4787V15.883Z"
        fill="#080808"
      />
      <path
        d="M17.5326 18.658C17.5326 18.8372 17.4052 18.9838 17.2495 18.9838C17.0938 18.9838 16.9664 18.8372 16.9664 18.658C16.9664 18.4788 17.0938 18.3322 17.2495 18.3322C17.4052 18.3322 17.5326 18.4788 17.5326 18.658ZM17.2495 17.3548C17.0938 17.3548 16.9664 17.5014 16.9664 17.6806C16.9664 17.8598 17.0938 18.0064 17.2495 18.0064C17.4052 18.0064 17.5326 17.8598 17.5326 17.6806C17.5326 17.5014 17.4052 17.3548 17.2495 17.3548ZM17.2495 16.3774C17.0938 16.3774 16.9664 16.524 16.9664 16.7032C16.9664 16.8824 17.0938 17.029 17.2495 17.029C17.4052 17.029 17.5326 16.8824 17.5326 16.7032C17.5326 16.524 17.4052 16.3774 17.2495 16.3774ZM18.0988 17.029C18.2545 17.029 18.3819 16.8824 18.3819 16.7032C18.3819 16.524 18.2545 16.3774 18.0988 16.3774C17.9431 16.3774 17.8157 16.524 17.8157 16.7032C17.8157 16.8824 17.9431 17.029 18.0988 17.029ZM18.0988 17.3548C17.9431 17.3548 17.8157 17.5014 17.8157 17.6806C17.8157 17.8598 17.9431 18.0064 18.0988 18.0064C18.2545 18.0064 18.3819 17.8598 18.3819 17.6806C18.3819 17.5014 18.2545 17.3548 18.0988 17.3548ZM18.0988 18.3322C17.9431 18.3322 17.8157 18.4788 17.8157 18.658C17.8157 18.8372 17.9431 18.9838 18.0988 18.9838C18.2545 18.9838 18.3819 18.8372 18.3819 18.658C18.3819 18.4788 18.2545 18.3322 18.0988 18.3322Z"
        fill="#EB5F3B"
      />
    </svg>
  );
}
