import React from 'react';

export default function ProfileSeekingTutor({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.831 4.03055L2.32547 7.57765C1.90058 7.7362 1.88878 8.34844 2.30723 8.52411L11.8128 12.5146C11.9328 12.565 12.0672 12.565 12.1872 12.5146L14.6268 11.4904C14.8425 10.9625 15.3774 10.6439 15.9166 10.6494H16.572L16.5996 10.6508C16.6075 10.6513 16.6155 10.6517 16.6234 10.6523L21.6928 8.52411C22.1112 8.34844 22.0994 7.7362 21.6745 7.57765L12.169 4.03055C12.0598 3.98982 11.9402 3.98982 11.831 4.03055Z"
        fill="currentColor"
      />
      <path
        d="M11.8941 14.059L11.8892 14.081C11.7855 14.5552 11.9687 14.9711 12.2009 15.2314C12.4325 15.4911 12.8001 15.6998 13.2438 15.6958H13.694L13.6289 15.9925H12.5795C11.9705 15.9874 11.3683 16.3964 11.2252 17.0494L11.095 17.6431C11.0858 17.6849 11.0789 17.7264 11.074 17.7673L5.37747 15.262C5.19567 15.182 5.07779 14.9986 5.07779 14.7956V11.8405C5.07779 11.4762 5.44074 11.2306 5.76701 11.3741L11.8052 14.0297C11.8341 14.0424 11.8638 14.0522 11.8941 14.059Z"
        fill="currentColor"
      />
      <path
        d="M21.8889 12.5705C21.7028 12.4791 21.4862 12.424 21.2489 12.4305H20.9V10.0562C20.9 9.776 21.1213 9.54883 21.3944 9.54883C21.6675 9.54883 21.8889 9.776 21.8889 10.0562V12.5705Z"
        fill="currentColor"
      />
      <path
        d="M21.2683 13.4694H19.5051L19.8228 12.0357C19.854 11.8667 19.6981 11.691 19.5146 11.6884H18.8792C18.7323 11.685 18.5938 11.786 18.5615 11.9199L18.2374 13.4694H16.5218L16.8395 12.0357C16.8703 11.8705 16.7232 11.6976 16.5441 11.6884H15.9087C15.7618 11.685 15.6233 11.786 15.591 11.9199L15.2733 13.4694H13.3671C13.2184 13.4666 13.0791 13.5708 13.0494 13.7069L12.9191 14.3006C12.881 14.4751 13.0462 14.6604 13.2368 14.6568H15.0001L14.479 17.0315H12.5728C12.4242 17.0287 12.2848 17.1329 12.2551 17.269L12.1249 17.8627C12.0867 18.0372 12.252 18.2225 12.4426 18.2189H14.2185L13.9008 19.6526C13.8691 19.8246 14.0318 20.0025 14.2185 19.9999H14.8539C14.9954 19.9965 15.1247 19.8973 15.1557 19.7684L15.4861 18.2189H17.2017L16.884 19.6526C16.8533 19.8178 17.0003 19.9907 17.1795 19.9999H17.8149C17.9618 20.0033 18.1003 19.9023 18.1326 19.7684L18.4662 18.2189H20.3724C20.521 18.2217 20.6604 18.1175 20.6901 17.9814L20.8172 17.3877C20.8553 17.2132 20.6901 17.0279 20.4995 17.0315H18.7267L19.2445 14.6568H21.1507C21.2994 14.6596 21.4387 14.5554 21.4684 14.4193L21.5987 13.8256C21.6381 13.6471 21.4631 13.4584 21.2683 13.4694ZM17.4559 17.0315H15.7403L16.2613 14.6568H17.9769L17.4559 17.0315Z"
        fill="currentColor"
      />
    </svg>
  );
}
