import React from 'react';
import { alpha, Divider, Theme } from '@mui/material';

const styles = {
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: -7.5,

    typography: 'caption',
    color: (theme: Theme) => theme.palette.error.dark,
    width: '100%',

    '::before': {
      width: '100%',
      borderTop: (theme: Theme) =>
        `thin solid ${alpha(theme.palette.error.dark, 0.3)}`,
    },

    px: 2.5,
    '.base-layout-right-panel &': {
      px: 1.5,
    },
  },
};

export default function MessageItemNewThreadDivider() {
  return (
    <Divider sx={styles.root} textAlign="right">
      New
    </Divider>
  );
}
