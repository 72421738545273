import { TFunction } from 'i18next';

const getAnalyticsPageConfig = (t: TFunction) => ({
  summary: {
    title: t('analytics.summary.title'),
    icon: 'AnalyticsProgress',
    tooltip: {
      icon: 'AnalyticsProgress',
      title: t('analytics.summary.title'),
      content: t('analytics.summary.tooltip'),
    },
  },
  summaryWallet: {
    title: t('analytics.summary_wallet.title'),
    icon: 'MainWallet',
  },
  summaryChallenges: {
    title: t('analytics.summary_challenges.title'),
    icon: 'MainChallenge',
  },
  summaryPlaylists: {
    title: t('analytics.summary_playlists.title'),
    icon: 'ProfilePlaylist',
  },
  summaryAwards: {
    title: t('analytics.summary_awards.title'),
    icon: 'ProfileAwards',
  },
  dailyGoals: {
    title: t('analytics.goals.title'),
    icon: 'MainSetGoal',
    tooltip: {
      icon: 'MainSetGoal',
      title: t('analytics.goals.title'),
      content: t('analytics.goals.tooltip'),
    },
  },
  comparePath: {
    title: t('analytics.path.title'),
    icon: 'AnalyticsMyPath',
    tooltip: {
      icon: 'AnalyticsMyPath',
      title: t('analytics.path.title'),
      content: t('analytics.path.tooltip'),
    },
  },
  weaknessMistakes: {
    title: t('analytics.weakness_mistakes.title'),
    icon: 'ActionCloseThick',
    tooltip: {
      icon: 'ActionCloseThick',
      title: t('analytics.weakness_mistakes.tooltip_title'),
      content: t('analytics.weakness_mistakes.tooltip_body'),
    },
  },
  weaknessOvertime: {
    title: t('analytics.weakness_overtime.title'),
    icon: 'TestClockSolid',
    tooltip: {
      icon: 'TestClockSolid',
      title: t('analytics.weakness_overtime.tooltip_title'),
      content: t('analytics.weakness_overtime.tooltip_body'),
    },
  },
  weaknessNotPracticed: {
    title: t('analytics.weakness_notPracticed.title'),
    icon: 'MenuThumbsDownOutline',
    tooltip: {
      icon: 'MenuThumbsDownOutline',
      title: t('analytics.weakness_notPracticed.tooltip_title'),
      content: t('analytics.weakness_notPracticed.tooltip_body'),
    },
  },
  overallMistakes: {
    title: t('analytics.overall_mistakes.title'),
    icon: 'ActionCloseThick',
    tooltip: {
      icon: 'ActionCloseThick',
      title: t('analytics.overall_mistakes.tooltip_title'),
      content: t('analytics.overall_mistakes.tooltip_body'),
    },
  },
  overallOvertime: {
    title: t('analytics.overall_overtime.title'),
    icon: 'TestClockSolid',
    tooltip: {
      icon: 'TestClockSolid',
      title: t('analytics.overall_overtime.tooltip_title'),
      content: t('analytics.overall_overtime.tooltip_body'),
    },
  },
});

export default getAnalyticsPageConfig;
