import { MouseEvent, ReactElement, ReactNode, useState } from 'react';
import Link from 'next/link';
import {
  Box,
  Card,
  CardActionArea,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ReverseTheme,
  Scrollbar,
  SimpleTooltip,
  SimpleTooltipProps,
  useTruncated,
} from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { sanitize } from 'dompurify';
import parse from 'html-react-parser';

import Icon from '../../../../components/Icon';
import AlbumLayoutExtension from '../../AlbumLayoutExtension/AlbumLayoutExtension';
import { AlbumLayoutExtensionConfig } from '../../AlbumLayoutExtension/types';
import AlbumLayoutTooltip from '../../AlbumLayoutTooltip';
import {
  AlbumLayoutBaseItem,
  AlbumLayoutItem,
  AlbumLayoutTooltipConfig,
  IaAlbumContextMenuEvent,
} from '../../types';

const styles = {
  root: {
    width: '100%',
    display: 'grid',
    gap: 1,
    position: 'relative',
    gridTemplateRows: 'auto 1fr',
  },
  rootDisabled: {
    opacity: 0.5,
  },
  indicatorIcon: {
    position: 'absolute',
    width: '16px',
    height: '16px',
    top: '-4px',
    right: '-4px',
    zIndex: 10,
  },
  card: {
    borderRadius: '10px',
    aspectRatio: '1 / 1',
  },
  cardHover: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        outline: '3px solid',
        outlineOffset: '-3px',
      },
    },
  },
  cardActive: {
    '&:active': {
      bgcolor: 'text.primary',
      color: 'background.darker',
    },
  },
  cardAction: {
    width: '100%',
    aspectRatio: '1 / 1',
    borderRadius: '10px',
    p: 1,
    position: 'relative',
  },
  cardArt: (imageUrl: string) => ({
    background: `url(${imageUrl}) center center`,
    backgroundSize: 'cover',
  }),
  noAction: {
    cursor: 'default',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    width: '100%',
    overflow: 'hidden',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  htmlTitle: (lines: number) => ({
    display: '-webkit-box',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    '& [data-content-type="paragraph"]': {
      ' > p:first-of-type': {
        mt: 0,
      },
      ' > p:last-of-type': {
        mb: 0,
      },
    },
    '& .katex-mathml': {
      // WebkitLineClamp does not work correctly when existing nested `mathml` elements with position absolute,
      // It takes space although the lines are truncated
      display: 'none',
    },
  }),
  htmlTitleTooltip: {
    '& .MuiTooltip-tooltip': {
      maxWidth: '250px',
      p: 0,
    },
  },
  htmlTitleTooltipContent: {
    width: '250px',
    p: 1,
    borderRadius: 1,
    '& [data-content-type="paragraph"]': {
      ' > p:first-of-type': {
        mt: 0,
      },
      ' > p:last-of-type': {
        mb: 0,
      },
    },
  },
  properties: {
    display: 'flex',
    gap: 1,
  },
  property: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  revertBgcolor: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 3,
      left: 3,
      right: 3,
      bottom: 3,
      borderRadius: '7px',
      bgcolor: 'background.darker',
    },
  },
  cardSkeleton: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  skeleton: {
    width: '100%',
    height: 'auto',
    aspectRatio: '1 / 1',
    borderRadius: '10px',
  },
};

const bgcolorMap: Record<string, (theme: Theme) => string> = {
  success: (theme: Theme) => theme.palette.gradient.success,
  error: (theme: Theme) => theme.palette.gradient.error,
  warning: (theme: Theme) => theme.palette.gradient.warning,
};

function parseHtml(html: string) {
  // by default the lib permit HTML, SVG and MathML, but this case we only need HTML
  // ref: https://github.com/cure53/DOMPurify?tab=readme-ov-file#how-do-i-use-it
  return parse(sanitize(html, { USE_PROFILES: { html: true } }));
}

type HtmlTitleProps = {
  htmlTitle: AlbumLayoutBaseItem['htmlTitle'];
};
function HtmlTitle({ htmlTitle }: HtmlTitleProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (!htmlTitle) return null;
  const { sx, content, lines = 1, className, showTooltip } = htmlTitle;
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <SimpleTooltip
      title={
        showTooltip && mdUp ? (
          <Box sx={styles.htmlTitleTooltipContent}>
            <Scrollbar sx={{ maxHeight: 300 }}>
              <Box>{parseHtml(content)}</Box>
            </Scrollbar>
          </Box>
        ) : (
          ''
        )
      }
      slotProps={{
        popper: {
          sx: styles.htmlTitleTooltip,
        },
      }}
    >
      <Box sx={[styles.htmlTitle(lines), ...sxProps]} className={className}>
        {parseHtml(content)}
      </Box>
    </SimpleTooltip>
  );
}

type TitleProps = {
  title: string;
};
function Title({ title }: TitleProps) {
  const { isTruncated, containerRef } = useTruncated();
  return (
    <SimpleTooltip title={isTruncated ? title : ''}>
      <Typography sx={styles.title} variant="body1" ref={containerRef}>
        {title}
      </Typography>
    </SimpleTooltip>
  );
}

type TooltipProps = Omit<SimpleTooltipProps, 'title' | 'content'> & {
  content: ReactNode;
};

function Tooltip({ content, children, ...rest }: TooltipProps) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (mdDown || !content) return children;

  return (
    <SimpleTooltip title={content} {...rest}>
      {children}
    </SimpleTooltip>
  );
}

type ExtensionsProps = {
  extensions?: (AlbumLayoutExtensionConfig | false)[];
  active?: boolean;
};
function Extensions({ extensions = [], active }: ExtensionsProps) {
  const validExtensions = extensions.filter(
    (item) => item !== false
  ) as AlbumLayoutExtensionConfig[];

  const children = (
    <>
      {validExtensions &&
        validExtensions.map((extension, index) => (
          <AlbumLayoutExtension key={index} {...extension} />
        ))}
    </>
  );

  if (active) {
    return <ReverseTheme>{children}</ReverseTheme>;
  }

  return children;
}

type CardTooltipWrapperProps = {
  tooltip?: AlbumLayoutTooltipConfig;
  children: ReactElement;
};
function CardTooltipWrapper({ tooltip, children }: CardTooltipWrapperProps) {
  if (!tooltip) return children;
  return <AlbumLayoutTooltip {...tooltip}>{children}</AlbumLayoutTooltip>;
}

function IaAlbumItemSkeleton() {
  return (
    <Box sx={styles.cardSkeleton}>
      <Skeleton sx={styles.skeleton} variant="rectangular" />
    </Box>
  );
}

type IaAlbumBaseItemProps = AlbumLayoutBaseItem & {
  loading?: boolean;
  children?: ReactNode;
  item?: AlbumLayoutItem;
};

export default function IaAlbumBaseItem({
  title,
  htmlTitle,
  properties,
  bgcolor,
  revertBgcolor = false,
  bgImage,
  disabled = false,
  indicatorIcon,
  loading = false,
  action,
  item,
  tooltip,
  extensions = [],
  contextMenuDisabled,
  children,
}: IaAlbumBaseItemProps) {
  const [clicked, setClicked] = useState(false);
  const { getIaAction } = useIaAction();
  const onContextMenu = !contextMenuDisabled
    ? getIaAction<IaAlbumContextMenuEvent<AlbumLayoutItem>>('onContextMenu')
    : undefined;

  const background =
    bgcolor && bgcolor in bgcolorMap ? bgcolorMap[bgcolor] : bgcolor;

  const isDisabled = disabled || loading;

  const linkProps =
    action?.type === 'link' ? { LinkComponent: Link, href: action.value } : {};

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    onContextMenu?.action({
      position: {
        x: e.clientX,
        y: e.clientY,
      },
      payload: item,
    });
  };

  return (
    <Box sx={[styles.root, isDisabled && styles.rootDisabled]}>
      {indicatorIcon && (
        <Box sx={styles.indicatorIcon}>
          <Icon name={indicatorIcon} width={16} height={16} />
        </Box>
      )}
      <Card
        sx={[
          styles.card,
          !isDisabled && styles.cardHover,
          { background },
          !!bgImage && styles.cardArt(bgImage),
          revertBgcolor && styles.revertBgcolor,
        ]}
      >
        <CardTooltipWrapper tooltip={tooltip}>
          <CardActionArea
            sx={[
              styles.cardAction,
              !isDisabled && styles.cardActive,
              !action && styles.noAction,
            ]}
            disabled={isDisabled}
            onContextMenu={handleContextMenu}
            disableRipple
            onMouseDown={() => setClicked(true)}
            onMouseUp={() => setClicked(false)}
            {...linkProps}
          >
            {/* {icon && <Icon name={icon} width={32} height={32} />} */}

            <Extensions extensions={extensions} active={clicked} />

            {children}
          </CardActionArea>
        </CardTooltipWrapper>
      </Card>
      {!loading && (
        <Box sx={styles.infoWrapper}>
          {title && <Title title={title} />}
          {htmlTitle && <HtmlTitle htmlTitle={htmlTitle} />}
          <Box sx={styles.properties}>
            {properties?.map((p, index) => (
              <Typography
                key={index}
                sx={styles.property}
                variant="caption"
                color="alpha.lightA75"
              >
                <Icon name={p.icon} width={12} height={12} />
                {p.content}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
      {loading && (
        <Box sx={styles.infoWrapper}>
          <Skeleton width="100%" height={24} />
        </Box>
      )}
    </Box>
  );
}

IaAlbumBaseItem.Tooltip = Tooltip;
IaAlbumBaseItem.Skeleton = IaAlbumItemSkeleton;
