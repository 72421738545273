import { Icon, NumberCard } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { CtaBigCardNumber } from '../../types';

const styles = {
  card: { flex: 1, maxWidth: 255 },
};

type NotificationCtaBigCardNumberProps = CtaBigCardNumber;

export default function NotificationCtaBigCardNumber({
  type,
  action,
  metadata,
  tooltip,
  value,
  description,
  ...rest
}: NotificationCtaBigCardNumberProps) {
  const { getIaAction, iaRoutingAction } = useIaAction();

  const handleClick = () => {
    if (!action) return;

    if (action.type === 'link') {
      void iaRoutingAction(action.value);
    } else {
      const onClickAction = getIaAction<typeof metadata>(action.value);
      onClickAction?.action?.(metadata);
    }
  };

  return (
    <NumberCard
      width="100%"
      maxWidth={256}
      minHeight={160}
      sx={styles.card}
      onClick={action ? handleClick : undefined}
      {...rest}
    >
      {!!tooltip && <NumberCard.Tooltip {...tooltip} />}
      {!!value && (
        <NumberCard.Value
          prefix={
            value.prefixType === 'icon' && value.prefix ? (
              <Icon name={value.prefix} width={28} height={28} />
            ) : (
              value.prefix
            )
          }
          suffix={
            value.suffixType === 'icon' && value.suffix ? (
              <Icon name={value.suffix} width={28} height={28} />
            ) : (
              value.suffix
            )
          }
          variant={value.variant}
        >
          {value.content}
        </NumberCard.Value>
      )}
      {!!description && (
        <NumberCard.Description>{description.content}</NumberCard.Description>
      )}
    </NumberCard>
  );
}
