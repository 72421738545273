import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { ComposerBlock } from '@lib/web/composer';

import { MrqResponseValue } from '../types';

import Response from './Response';

const styles = {
  responseContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  captionContainer: {
    mt: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  caption: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
  },
  captionNumber: {
    color: 'text.primary',
  },
  skeleton: {
    display: 'inline-block',
  },
};

type MultipleResponseProps = {
  questionId: string;
  responseValues: MrqResponseValue[];
  handleBlocksChange: (id: string, blocks: ComposerBlock[]) => void;
  onSelect: (id: string) => void;
  mrqMinCorrectAnswerNum?: number;
  mrqMaxCorrectAnswerNum?: number;
  readonly?: boolean;
};

export default function MultipleResponse({
  questionId,
  responseValues,
  handleBlocksChange,
  onSelect,
  mrqMinCorrectAnswerNum,
  mrqMaxCorrectAnswerNum,
  readonly,
}: MultipleResponseProps) {
  const { t } = useTranslation('editor');

  const selectionHint = useMemo(() => {
    if (
      mrqMinCorrectAnswerNum === undefined ||
      mrqMaxCorrectAnswerNum === undefined
    ) {
      return <Skeleton sx={styles.skeleton} width={15} />;
    }
    return mrqMinCorrectAnswerNum === mrqMaxCorrectAnswerNum
      ? `${mrqMinCorrectAnswerNum}`
      : `${mrqMinCorrectAnswerNum}-${mrqMaxCorrectAnswerNum}`;
  }, [mrqMaxCorrectAnswerNum, mrqMinCorrectAnswerNum]);

  const selectedAnswerNumber = useMemo(() => {
    return responseValues.filter((responseValue) => responseValue.selected)
      .length;
  }, [responseValues]);

  return (
    <Box>
      <Box sx={styles.responseContainer}>
        {responseValues.map((responseValue) => (
          <Response
            key={responseValue.id}
            questionId={questionId}
            value={responseValue}
            onBlocksChange={handleBlocksChange}
            onSelect={onSelect}
            disableSelect={
              !responseValue.selected &&
              selectedAnswerNumber === mrqMaxCorrectAnswerNum
            }
            readonly={readonly}
          />
        ))}
      </Box>
      {responseValues.length > 0 && (
        <Box sx={styles.captionContainer}>
          <Typography variant="caption" sx={styles.caption}>
            {t('select')}{' '}
            <Typography variant="caption" sx={styles.captionNumber}>
              {selectionHint}
            </Typography>{' '}
            {t('correct answers')}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
