import { SortCriteriaConfig } from './types';

function getDirection(criteria: SortCriteriaConfig) {
  if (!criteria.field.customDirectionOptions) {
    return criteria.direction;
  }
  const customDirectionOption = criteria.field.customDirectionOptions.find(
    (option) => option.value === criteria.direction
  );
  if (!customDirectionOption) return null;
  if (customDirectionOption.type === 'rawQuery') {
    return customDirectionOption.rawQueryParams;
  }
  return customDirectionOption.direction;
}

function mergeRawQueryParams(
  root?: Record<string, string>,
  params?: Record<string, string>
) {
  if (!params) return root;
  params = params || {};
  const result = root || {};
  for (const key of Object.keys(result)) {
    if (params[key]) {
      const { [key]: param, ...rest } = params || {};
      result[key] = result[key] + ';' + param;
      params = rest;
    }
  }
  return { ...result, ...params };
}

export const buildSortQuery = (
  criteria: SortCriteriaConfig[] = []
): {
  orderBy: string;
  sortBy: string;
  expandOrderBy: string;
  expandSortBy: string;
  rawQueryParams?: Record<string, string>;
} | null => {
  if (criteria.length === 0) return null;

  const orderByQueries = [];
  const sortByQueries = [];
  const expandOrderByQueries = [];
  const expandSortByQueries = [];
  let rawQueryParams: Record<string, string> | undefined = undefined;
  for (const item of criteria) {
    const direction = getDirection(item);
    if (!item.field || item.viewOnly || !direction) continue;

    if (typeof direction === 'object') {
      // case: custom sort direction options
      const { orderBy, sortBy, expandOrderBy, expandSortBy, ...params } =
        direction as Record<string, string>;
      if (orderBy) orderByQueries.push(orderBy);
      if (sortBy) sortByQueries.push(sortBy);
      if (expandOrderBy) expandSortByQueries.push(expandOrderBy);
      if (expandSortBy) expandSortByQueries.push(expandSortBy);
      rawQueryParams = mergeRawQueryParams(rawQueryParams, params);
    } else {
      // normal case, direction = asc/desc
      // e.g. expandOrderBy=creator:fullName&expandSortBy=desc
      const isExpand = item.field.name.includes(':');
      if (isExpand) {
        expandOrderByQueries.push(item.field.name);
        expandSortByQueries.push(direction);
      } else {
        orderByQueries.push(item.field.name);
        sortByQueries.push(direction);
      }
    }
  }

  const orderByQueryString = orderByQueries.join(';');
  const sortByQueryString = sortByQueries.join(';');
  const expandOrderByQueryString = expandOrderByQueries.join(';');
  const expandSortByQueryString = expandSortByQueries.join(';');

  if (
    !orderByQueryString &&
    !sortByQueryString &&
    !expandOrderByQueryString &&
    !expandSortByQueryString &&
    !rawQueryParams
  ) {
    return null;
  }

  return {
    orderBy: orderByQueryString,
    sortBy: sortByQueryString,
    expandOrderBy: expandOrderByQueryString,
    expandSortBy: expandSortByQueryString,
    rawQueryParams,
  };
};
