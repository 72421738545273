import React from 'react';

export default function OtherIndicatorNeutral({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.4114 11.048C20.1962 11.4402 20.1962 12.5598 19.4114 12.952L5.54691 19.882C4.58422 20.3632 3.5968 19.2757 4.16885 18.3643L7.80835 12.5657C8.02547 12.2198 8.02547 11.7802 7.80835 11.4343L4.16885 5.63571C3.5968 4.7243 4.58423 3.63679 5.54692 4.11797L19.4114 11.048Z"
        fill="#565656"
      />
    </svg>
  );
}
