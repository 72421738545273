import { useCallback } from 'react';
import { useLineNumberDisplay, usePassageEl } from '@lib/web/ui';

export function useGetLineMarkerContent() {
  const getLineNumberDisplay = useLineNumberDisplay();
  const { passageEl } = usePassageEl();

  const getComposerOrRenderLineNumberDisplay = useCallback(
    (targetId: string | null) => {
      if (!targetId) return [];

      // for composer
      const display = getLineNumberDisplay(targetId);
      if (display.length > 0) return display;

      // for rendering
      return getLineNumberDisplay(targetId, {
        dataIdAttr: 'data-render-id',
        contentSelect: '.render-content',
        parentElement: passageEl,
      });
    },
    [getLineNumberDisplay, passageEl]
  );

  const getLineMarkerContent = useCallback(
    (targetId: string | null) => {
      const [line, display] = getComposerOrRenderLineNumberDisplay(targetId);

      if (typeof display === 'string') {
        const words = display.split(' ');
        if (words.length < 3) {
          return `Line ${line} (“${display}”)`;
        } else {
          const firstWord = words[0];
          const lastWord = words[words.length - 1];

          return `Line ${line} (“${firstWord}...${lastWord}”)`;
        }
      } else if (display) {
        return `Line ${line} (“${display}”)`;
      }
    },
    [getComposerOrRenderLineNumberDisplay]
  );

  return {
    getLineMarkerContent,
  };
}
