import React from 'react';

export default function ActionTryAgain({ ...rest }) {
  return (
    <svg
      height="22"
      width="22"
      fill="none"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.65308 4.22427C7.95736 4.52604 7.96052 5.0185 7.66014 5.32419L5.62634 7.39394H13.7792C15.1671 7.39394 16.4961 7.95512 17.4744 8.95072C18.4523 9.94598 19 11.2937 19 12.697C19 14.1002 18.4523 15.448 17.4744 16.4432C16.4961 17.4388 15.1671 18 13.7792 18H8.22084C7.79327 18 7.44665 17.6518 7.44665 17.2222C7.44665 16.7927 7.79327 16.4444 8.22084 16.4444H13.7792C14.7499 16.4444 15.6829 16.0521 16.3725 15.3504C17.0623 14.6484 17.4516 13.6941 17.4516 12.697C17.4516 11.6999 17.0623 10.7456 16.3725 10.0435C15.6829 9.34185 14.7499 8.94949 13.7792 8.94949H5.62634L7.66014 11.0192C7.96052 11.3249 7.95736 11.8174 7.65308 12.1192C7.34879 12.4209 6.85861 12.4178 6.55822 12.1121L3.22324 8.71813C2.92559 8.41522 2.92559 7.92821 3.22324 7.6253L6.55822 4.23137C6.85861 3.92567 7.34879 3.92249 7.65308 4.22427Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
