import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';

import TitleBar from '../../../molecules/TitleBar';
import { modalPaperStyle } from '../styles';

const styles = {
  root: {
    ...modalPaperStyle,
    maxWidth: { md: 860 },
    maxHeight: { md: 647 },
    flexDirection: { xs: 'column', md: 'row' },
  },
  content: {
    flex: 1,
    position: 'relative',
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  right: {
    flex: 1,
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.05)
        : 'grey.100',
  },
};
export type ModalColumnContentProps = BoxProps & {
  onClose?: () => void;
  title?: string;
  disableHeader?: boolean;
  rightComponent?: ReactNode;
};
const ModalColumnContent = React.forwardRef<
  HTMLDivElement,
  ModalColumnContentProps
>(
  (
    { sx, title, children, rightComponent, onClose, disableHeader, ...rest },
    ref
  ) => {
    const sxProps = Array.isArray(sx) ? sx : [sx];
    return (
      <Box ref={ref} sx={[styles.root, ...sxProps]} {...rest}>
        <Box sx={styles.left}>
          {!disableHeader && (
            <TitleBar backIcon="close" title={title} onBackClick={onClose} />
          )}
          <Box sx={styles.content}>{children}</Box>
        </Box>
        <Box sx={styles.right}>
          <Box sx={styles.content}>{rightComponent}</Box>
        </Box>
      </Box>
    );
  }
);

export default ModalColumnContent;
