import api from './client';

const referApi = {
  checkEmail: (email: string) =>
    api.get<Response<GetReferEmailCheckRes>>(`v2/refer/email-check/${email}`),

  sentInvitation: (params: SentReferInvitationReq) =>
    api.post<Response<SentReferInvitationRes>>(
      'v2/refer/invitation/external',
      params
    ),
};

export default referApi;
