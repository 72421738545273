import { useCallback, useEffect, useState } from 'react';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import { apis } from '@lib/web/apis';
import { useEmojiSource } from '@lib/web/hooks';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { useClubThreadViews } from '@lib/web/thread/hooks/views/useClubThreadViews';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import { callWithToast } from '@lib/web/utils';

type UseThreadViewChangeEmojiProps = {
  view: GetThreadViewRes;
  clubId?: string;
};

type UseThreadViewChangeEmojiReturn = {
  emoji?: GetMarkEmojiRes | null;
  handleOpenEmojiPanel: () => void;
  handleChangeEmoji: ({
    changedEmoji,
  }: {
    changedEmoji?: GetMarkEmojiRes | null;
  }) => void;
};

const useThreadViewChangeEmoji = ({
  view,
  clubId,
}: UseThreadViewChangeEmojiProps): UseThreadViewChangeEmojiReturn => {
  const { emojiSource } = useEmojiSource();
  const { viewIcon } = useThreadViewDetail(view);
  const [currEmoji, setCurrEmoji] = useState<GetMarkEmojiRes | null>();

  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  const { reloadViews: reloadClubViews } = useClubThreadViews({ clubId });
  const { reloadViews: reloadDmViews } = useDirectMessageViews();

  const onSuccess = useCallback((): void => {
    if (clubId) {
      void reloadClubViews();
    } else {
      void reloadDmViews();
    }
  }, [clubId, reloadClubViews, reloadDmViews]);

  const handleChangeEmoji = useCallback(
    async ({
      changedEmoji,
    }: {
      changedEmoji?: GetMarkEmojiRes | null;
    }): Promise<void> => {
      const payload: SetThreadViewReq = {
        type: view.type,
        threadViewUserId: view.threadViewUsers[0].id,
        emoji: changedEmoji?.code,
      };
      await callWithToast(apis.thread.setThreadView(payload), {
        showLoading: false,
      });
      setCurrEmoji(changedEmoji);
      onSuccess();
    },
    [onSuccess, view.threadViewUsers, view.type]
  );

  const handleOpenEmojiPanel = useCallback((): void => {
    const targetEmoji = emojiSource.find(
      (emoji) => emoji.code === view.threadViewUsers[0].customEmoji
    );
    openRightPanel(GlobalPanelKeys.GlobalThreadEmoji, {
      emojiId: targetEmoji?.id ?? '',
      emojiCode: targetEmoji?.code ?? '',
      emojiName: targetEmoji?.name ?? '',
      onChange: handleChangeEmoji,
    });
  }, [emojiSource, handleChangeEmoji, openRightPanel, view.threadViewUsers]);

  useEffect(() => {
    setCurrEmoji(emojiSource.find((e) => e.code === viewIcon?.emoji));
  }, [emojiSource, viewIcon]);

  return { emoji: currEmoji, handleOpenEmojiPanel, handleChangeEmoji };
};

export default useThreadViewChangeEmoji;
