import { useCallback, useContext, useMemo } from 'react';
import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import { useGlobalSortable } from '@front/helper';

import { QuestionSideMenuContext } from '../contexts';

const ALWAYS_SHOW_CLASS_NAME = 'side-menu-always-show';
const ALWAYS_HIDE_CLASS_NAME = 'side-menu-always-hide';
const ONLY_SHOW_ON_HOVER_CLASS_NAME = 'side-menu-only-show-on-hover';

const hoverContainerStyles:
  | SystemStyleObject<Theme>
  | ((theme: Theme) => SystemStyleObject<Theme>) = {
  [`& .${ALWAYS_SHOW_CLASS_NAME}`]: {
    visibility: 'visible',
  },
  [`& .${ALWAYS_HIDE_CLASS_NAME}`]: {
    visibility: 'hidden',
  },
  [`& .${ONLY_SHOW_ON_HOVER_CLASS_NAME}`]: {
    visibility: 'hidden',
  },
  [`&:hover .${ONLY_SHOW_ON_HOVER_CLASS_NAME}`]: {
    visibility: 'visible',
  },
};

export default function useQuestionSideMenu({
  id,
  frozen,
}: {
  id?: string;
  frozen?: boolean;
} = {}) {
  const [value, setValue] = useContext(QuestionSideMenuContext);
  const { isDragging, draggingId } = useGlobalSortable();

  const openMenu = (openId: string) => {
    setValue((prev) => ({
      ...prev,
      openedItemId: openId,
    }));
  };

  const closeMenu = () => {
    setValue((prev) => ({
      ...prev,
      openedItemId: undefined,
    }));
  };

  const setRefMap = useCallback(
    (itemId: string, el: HTMLElement) => {
      setValue((prev) => ({
        ...prev,
        refMap: {
          ...prev.refMap,
          [itemId]: el,
        },
      }));
    },
    [setValue]
  );

  const sideMenuShowHideClassName = useMemo(() => {
    const isDraggingSelf = draggingId === id;
    const isDraggingOthers = isDragging && !isDraggingSelf;
    const isSelfSideMenuOpened = value.openedItemId === id;
    const isOthersSideMenuOpened = value.openedItemId && !isSelfSideMenuOpened;

    if (frozen) return ALWAYS_SHOW_CLASS_NAME;
    if (isSelfSideMenuOpened) return ALWAYS_SHOW_CLASS_NAME;
    if (isOthersSideMenuOpened) return ALWAYS_HIDE_CLASS_NAME;
    if (isDraggingSelf) return ALWAYS_SHOW_CLASS_NAME;
    if (isDraggingOthers) return ALWAYS_HIDE_CLASS_NAME;

    return ONLY_SHOW_ON_HOVER_CLASS_NAME;
  }, [draggingId, frozen, id, isDragging, value.openedItemId]);

  return {
    ...value,
    openMenu,
    closeMenu,
    hoverContainerStyles,
    sideMenuShowHideClassName,
    setRefMap,
  };
}
