import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { StatusTag } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaClub, useIaClubStatus } from '@lib/web/hooks';

import useIaClubTags from '../../ia/club/useIaClubTags';

const styles = {
  tags: {
    display: 'flex',
    gap: 1,
  },
};

type StatusTagsProps = {
  slug: string;
};

export const BetaTag = ({ slug }: StatusTagsProps) => {
  const { beta } = useIaClubStatus(slug);
  const { t } = useTranslation();

  return (
    beta && (
      <StatusTag
        color="warning"
        name={t('tag.beta.text', 'Beta')}
        tip={t(
          'tag.beta.tip',
          'Get early access to a new club, which may have fewer questions.'
        )}
      />
    )
  );
};

type ClubStatusTagsProps = {
  slug: string;
};

export default function ClubStatusTags({ slug }: ClubStatusTagsProps) {
  const { club } = useIaClub(slug);
  const { getIaClubTags } = useIaClubTags();

  if (!club) return null;

  const tags = getIaClubTags(club);

  if (!tags.length) return null;
  return (
    <Box sx={styles.tags}>
      {tags.map((tag) => (
        <StatusTag
          key={tag.text}
          icon={
            tag.icon ? (
              <Icon name={tag.icon} width={16} height={16} />
            ) : undefined
          }
          color={tag.color}
          name={tag.text}
          tip={tag.tip}
        />
      ))}
    </Box>
  );
}
