import React from 'react';

export default function OtherMultiselect({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.657 3.85714C7.4262 3.85714 6.42843 4.85491 6.42843 6.08571V15.3429C6.42843 16.5737 7.4262 17.5714 8.657 17.5714H17.9141C19.145 17.5714 20.1427 16.5737 20.1427 15.3429V6.08571C20.1427 4.85491 19.145 3.85714 17.9141 3.85714H8.657ZM8.657 3C6.95281 3 5.57129 4.38152 5.57129 6.08571V15.3429C5.57129 17.0471 6.95281 18.4286 8.657 18.4286H17.9141C19.6183 18.4286 20.9999 17.0471 20.9999 15.3429V6.08571C20.9999 4.38152 19.6183 3 17.9141 3H8.657Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M3 8.657C3 6.95281 4.38152 5.57129 6.08571 5.57129H15.3429C17.047 5.57129 18.4286 6.95281 18.4286 8.657V17.9141C18.4286 19.6183 17.047 20.9999 15.3429 20.9999H6.08571C4.38152 20.9999 3 19.6183 3 17.9141V8.657ZM14.7671 11.727C15.0684 11.4257 15.0684 10.9373 14.7671 10.636C14.4658 10.3347 13.9774 10.3347 13.6761 10.636L9.70675 14.6054L8.14499 12.7833C7.86772 12.4599 7.38072 12.4224 7.05724 12.6997C6.73376 12.9769 6.6963 13.4639 6.97357 13.7874L9.07746 16.242C9.2173 16.4051 9.41885 16.5025 9.63357 16.5108C9.84829 16.519 10.0567 16.4373 10.2087 16.2854L14.7671 11.727Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
