import { MouseEvent } from 'react';
import { Box, ButtonBase, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { SortableActivator } from '@front/helper';
import { OtherDrag as OtherDragIcon } from '@front/icon';
import { Scrollbar } from '@front/ui';
import EditorComposerRenderer from '@lib/web/editor/EditorTextComposer/EditorComposerRenderer';

import { PracticeUnscrambleValue } from '../types';

const styles = {
  root: (theme: Theme) => ({
    background:
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.05)
        : 'transparent',
    borderRadius: '12px',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    justifyContent: 'flex-start',
    boxShadow: `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.1)}`,
  }),
  innerLayer: {
    position: 'absolute',
    top: 3,
    left: 3,
    right: 3,
    bottom: 3,
    background: 'rgba(255, 255, 255, 0.1)',
  },
  innerContent: {
    display: 'grid',
    gridTemplateColumns: '52px 1fr',
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    userSelect: 'none',
  },
  scroll: {
    maxHeight: 236, // as spec define, max 8 rows
    maxWidth: '100%',
  },
  symbolContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  symbol: (theme: Theme) => ({
    mt: '12px',
    ml: 1.5,
    height: 32,
    width: 32,
    border: `1px solid ${theme.palette.text.primary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
  }),
  hover: (theme: Theme) => ({
    '@media (hover: hover)': {
      '&:hover': {
        boxShadow: `inset 0 0 0 1px ${alpha(
          theme.palette.text.primary,
          0.3
        )}, 0px 8px 16px ${alpha(theme.palette.text.primary, 0.15)}`,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? alpha(theme.palette.text.primary, 0.1)
            : 'grey.100',
      },
    },
  }),
  active: {
    boxShadow: (theme: Theme) =>
      `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.5)}`,
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.1)
        : 'grey.100',
  },

  text: {
    minHeight: 56,
    textAlign: 'left',
    typography: 'body2',
    fontWeight: 400,

    paddingTop: '13px',
    paddingBottom: '13px',
  },

  dragIcon: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    pr: 1.5,
    touchAction: 'none',
  },
};

export type ChoiceProps = {
  value: PracticeUnscrambleValue;
  symbol: string;
  active?: boolean;
  onClick?: (ev: MouseEvent<Element>) => void;
  disableSort?: boolean;
  readonly?: boolean;
};

export function ChoiceText({ text }: { text: string }) {
  return (
    <Scrollbar sx={styles.scroll}>
      <EditorComposerRenderer sx={styles.text} html={text} />
    </Scrollbar>
  );
}
export default function Choice({
  value,
  symbol,
  active,
  onClick,
  disableSort,
  readonly,
}: ChoiceProps) {
  return (
    <ButtonBase
      disableRipple
      sx={[
        styles.root,
        !readonly && styles.hover,
        active ? styles.active : null,
      ]}
      type="button"
      onClick={onClick}
      disabled={readonly}
    >
      <Box sx={styles.innerContent}>
        <Box sx={styles.symbolContainer}>
          <Box sx={styles.symbol}>{symbol}</Box>
        </Box>
        <ChoiceText text={value.text} />
      </Box>
      {readonly ? null : disableSort ? ( // if we don't skip ghost element, it will break the drag behavior after dragged one time
        <Box sx={styles.dragIcon}>
          <OtherDragIcon width={16} height={16} />
        </Box>
      ) : (
        <SortableActivator id={value.id}>
          {(listeners, setActivatorNodeRef) => (
            <Box ref={setActivatorNodeRef} {...listeners} sx={styles.dragIcon}>
              <OtherDragIcon width={16} height={16} />
            </Box>
          )}
        </SortableActivator>
      )}
    </ButtonBase>
  );
}
