import { SquareAvatarProps, StatusTagProps } from '@front/ui';

import { IaAction, IaRichText, IaRichTextConfig } from '../../core/types';

import {
  CardLayoutListTooltipConfig,
  CardLayoutSimpleTooltipConfig,
} from './CardLayoutTooltip/types';

export type CardLayoutItemEvent = IaAction;

export type CardCornerAvatarEvent = {
  target: CardLayoutItem;
  anchorEl: Element;
};

export type CardLayoutItemTag = {
  color: StatusTagProps['color'];
  text: StatusTagProps['name'];
  icon?: string;
  tip?: string;
  infoTooltip?: {
    title: string;
    icon: string;
    content: string;
  };
  sx?: StatusTagProps['sx'];
};

export type CardLayoutItemProperty = {
  text: IaRichText;
  textConfig?: IaRichTextConfig;
  icon: string;
  iconType?: 'image' | 'icon';
};

export type CardLayoutGroupProperty = CardLayoutItemProperty[];

export enum CardLayoutItemStatus {
  Disabled = -1,
  Enabled = 1,
}

export type CardLayoutTooltipConfig =
  | CardLayoutListTooltipConfig
  | CardLayoutSimpleTooltipConfig;

type AvatarProps = Pick<
  SquareAvatarProps,
  'src' | 'showIndicator' | 'status' | 'statusInfo'
>;

export interface CardLayoutDefaultItem {
  id: string;
  title: string;
  titleSuffixIcon?: string;
  avatar?: AvatarProps;
  subAvatars?: {
    avatarUrl: string;
    name: string;
  }[];
  /**
   * if provided the total sub avatars will be this value instead of `subAvatars.length`
   */
  subAvatarsCount?: number;
  cornerAvatar?: string;
  cornerAvatarText?: string;
  cornerAvatarEventMap?: {
    onMouseEnter?: Pick<IaAction, 'value'>;
    onMouseLeave?: Pick<IaAction, 'value'>;
    onBlur?: Pick<IaAction, 'value'>;
  };
  description?: string;
  status?: CardLayoutItemStatus;
  properties?: (CardLayoutItemProperty | CardLayoutGroupProperty)[];
  tags?: CardLayoutItemTag[];
  events?: CardLayoutItemEvent[];
  action?: CardLayoutItemEvent;
  metadata?: Record<string, any>;
  selected?: boolean;
  type?: 'default';
  tooltip?: CardLayoutTooltipConfig;
}

export type CardLayoutActionItem = Omit<CardLayoutDefaultItem, 'type'> & {
  type: 'action';
  actionSettings?: {
    state?: 'default' | 'loading' | 'error';
    errorMessage?: string;
    errorActions?: CardLayoutItemEvent[];
  };
};

export type CardLayoutItem = CardLayoutDefaultItem | CardLayoutActionItem;

export interface CardLayoutGroup {
  id: string;
  items: CardLayoutItem[];
  title: string;
}

export interface CardLayoutLoadingConfig {
  layout: 'card-layout-skeleton';
  settings: {
    columns: number[];
    cardHeight?: number;
    cardWidth?: number;
    cardCount?: number;
  };
}

export interface CardLayoutConfig {
  layout: 'card-layout';
  settings: {
    columns: number[];
    cardWidth?: number;
    avatarSize?: number;
    avatarShowStacked?: boolean;
    avatarBlackAndWhite?: boolean;
    descriptionLines?: number;
    emptyText?: string;
    emptyIcon?: string;
    maxSubAvatars?: number;
    subAvatarSize?: number;
    /**
     * when off-screen the card will be rendered as an empty box
     * to reduce DOM size
     */
    optimizeRenderEnabled?: boolean;
  };
  items?: CardLayoutItem[];
  groups?: CardLayoutGroup[];
}

export interface CardLayoutEmptyConfig {
  layout: 'card-layout-empty';
  title: string;
}
