import { useContext } from 'react';
import { useRouter } from 'next/router';

import { SharedUserContext } from '../contexts';

export default function useShareLink() {
  const router = useRouter();
  const { sid, userShareSid } = useContext(SharedUserContext);

  if (sid) return router.asPath;
  if (userShareSid)
    return `${process.env.APP_URL}/shared/${userShareSid}${router.asPath}`;

  return '';
}
