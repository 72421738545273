import { MouseEvent, ReactNode } from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  EditorAI as EditorAIIcon,
  OtherSend as OtherSendIcon,
  OtherSendActive as OtherSendActiveIcon,
} from '@front/icon';
import {
  Button,
  IconButton,
  Scrollbar,
  TextField,
  TextFieldProps,
} from '@front/ui';

const styles = {
  root: {
    borderTop: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    background: (theme: Theme) => theme.palette.background.body,
    position: 'relative',
    backgroundPosition: 'right center',
    backgroundAttachment: 'fixed',
    backgroundSize: '100vw 100vh',
    width: 0,
    minWidth: '100%',
  },
  messageBox: {
    px: '12px',
    py: '8px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    '& .textfield-label': {
      display: 'none',
    },
    '& .textfield-message': {
      display: 'none',
    },
  },
  textContainer: {
    position: 'relative',
    width: '100%',
  },
  textScroll: {
    maxHeight: '416px', // figma: Maximum composer height = 416 px (half of center pane’s height, excluding top bar)
  },
  messageField: {
    marginBottom: '1rem',
    flex: 1,
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '& .textfield-main': {
          border: 'none',
        },
      },
    },
    '& .textfield-main': {
      border: 'none',
      borderRadius: 0,
      outline: 'none',
      background: 'none',
      '& .MuiInputBase-input': {
        py: '1px',
        px: 0,
      },
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    width: '100%',
  },
  sendButton: {
    ml: 'auto',
  },
  extra: {
    display: 'flex',
    px: '7px',
    py: '8px',
    width: 0,
    minWidth: '100%',
    borderTop: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
  },
  upgradePlanSection: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr max-content',
    gap: 2,
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    p: '4px',
    borderRadius: 1,
    width: '100%',
  },
  upgradePlanContent: {
    display: 'grid',
    gridTemplateColumns: '16px 1fr',
    alignItems: 'center',
    gap: 1,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
  upgradeButton: {
    background:
      'linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
    borderRadius: 1,
    border: 'none',
    color: (theme: Theme) => theme.palette.text.primary,
    px: 2,
    py: '7.5px',
    minWidth: 'unset',
  },
};

export type MessageActionAreaProps = {
  sx?: SxProps;
  inputProps?: TextFieldProps;
  additionalIcons?: ReactNode;
  customSize?: number;
  onSendButtonClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
  showUpgrade?: boolean;
  onUpgradeClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
};

export default function MessageActionArea({
  sx,
  inputProps = {},
  additionalIcons = null,
  onSendButtonClick,
  showUpgrade = false,
  onUpgradeClick,
}: MessageActionAreaProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  const { sx: inputSx, ...rest } = inputProps;
  const inputSxProps = Array.isArray(inputSx) ? inputSx : [inputSx];

  return (
    <Box sx={[styles.root, ...sxProps]}>
      <Box sx={styles.messageBox}>
        <Box sx={styles.textContainer}>
          <Scrollbar sx={styles.textScroll}>
            <TextField
              multiline
              labelIcon={null}
              sx={[styles.messageField, ...inputSxProps]}
              {...rest}
            />
          </Scrollbar>
        </Box>

        <Box sx={styles.actionContainer}>
          {/* PRF-1477, PRF-1478*/}
          {/*<IconButton customSize={24}>*/}
          {/*  <EditorAIIcon />*/}
          {/*</IconButton>*/}

          {/** PRF-77: add selected agent icon */}
          {additionalIcons}

          {/*<IconButton customSize={24}>*/}
          {/*  <TestAddIcon />*/}
          {/*</IconButton>*/}
          {/*<IconButton customSize={24}>*/}
          {/*  <TestEmojiIcon />*/}
          {/*</IconButton>*/}
          <Box sx={styles.sendButton}>
            {rest.value ? (
              <IconButton customSize={24} onClick={onSendButtonClick}>
                <OtherSendActiveIcon />
              </IconButton>
            ) : (
              <IconButton customSize={24} disabled>
                <OtherSendIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      {showUpgrade && (
        <Box sx={[styles.extra]}>
          <Box sx={styles.upgradePlanSection}>
            <Box sx={styles.upgradePlanContent}>
              <EditorAIIcon width={16} height={16} />
              <Typography fontSize={12}>
                Looks like you&apos;re all out of tokens! Please upgrade plan to
                get more AI responses.
              </Typography>
            </Box>
            <Button sx={styles.upgradeButton} onClick={onUpgradeClick}>
              Upgrade
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
