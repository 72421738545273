import React from 'react';
import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { BlockSchema, DefaultBlockSchema } from '@blocknote/core';
import { SlashMenuProps as BlockSlashMenuProps } from '@blocknote/react';
import { ComposerSlashMenuItem } from '@lib/web/composer';
import groupBy from 'lodash/groupBy';

const styles = {
  root: {
    width: 260,
    py: 1.25,
  },
  item: {
    px: '12px',
    width: '100%',
    height: 28,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  itemLabel: {
    color: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'alpha.lightA50' : 'alpha.darkerA50',
  },
  itemButton: {
    width: '100%',
    textAlign: 'left',

    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
  active: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  hint: {
    ml: 'auto',
    typography: 'numberBody3',
    color: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'alpha.lightA75' : 'alpha.darkerA75',
  },
};

type SlashMenuProps<BSchema extends BlockSchema = DefaultBlockSchema> = Omit<
  BlockSlashMenuProps<BSchema>,
  'filteredItems'
> & {
  filteredItems: ComposerSlashMenuItem<any>[]; // XXX: dont use 'any'
  sx?: BoxProps['sx'];
};

export default function SlashMenu<
  BSchema extends BlockSchema = DefaultBlockSchema
>({
  keyboardHoveredItemIndex,
  filteredItems,
  itemCallback,
  sx,
}: SlashMenuProps<BSchema>) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const groups = groupBy(filteredItems, (i) => i.group);
  const orderedGroupKey: string[] = ['Advanced Text', 'Elements'].filter(
    (key) => groups[key]?.length > 0
  );

  let index = -1;

  return (
    <Box sx={[styles.root, ...sxProps]} className="composer-slash-menu">
      {filteredItems.length === 0 && (
        <Typography sx={[styles.item, styles.itemLabel]} variant="body2">
          No results
        </Typography>
      )}

      {orderedGroupKey.map((key) => (
        <React.Fragment key={key}>
          <Typography sx={[styles.item, styles.itemLabel]} variant="caption">
            {key}
          </Typography>

          {groups[key]?.map((item) => {
            index += 1;
            return (
              <ButtonBase
                className="composer-slash-menu-item"
                sx={[
                  styles.item,
                  styles.itemButton,
                  keyboardHoveredItemIndex === index && styles.active,
                ]}
                key={item.name}
                onClick={() => itemCallback(item)}
              >
                {item.icon}
                <Typography variant="body2">{item.name}</Typography>

                <Typography sx={styles.hint}>{item.hint}</Typography>
              </ButtonBase>
            );
          })}
        </React.Fragment>
      ))}
    </Box>
  );
}
