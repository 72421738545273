import { usePathname } from 'next/navigation';
import { useAuth } from '@lib/web/apis';

/**
 * https://www.figma.com/design/L4qNdvrQKp9Y9385yIasOv/Sprint-3.1-2024-[Eng-Handoff]?node-id=4973-383611&t=NJotzqQbdCEXW79y-0
 * Will see Blocker for:
 * Rankings
 * Practice
 * Analytics
 * Playlists
 * Agents (guest)
 *
 * Special Blocker for:
 * Student should be able to open all pages in Profile Skinny Nav, but several pages will have blocker: Invite Friends, Wallet, Discount, Phone
 */
const UNVERIFIED_BLOCK_PAGES = [
  '/rankings',
  '/start',
  '/analytics',
  '/summary',
  '/playlists',
  '/challenges',
  '/create-questions',
  '/profile/invite-friends',
  '/profile/wallet',
  '/profile/discount-code',
  '/profile/parent-phone-number',
];

const GUEST_BLOCK_PAGES = [...UNVERIFIED_BLOCK_PAGES, '/agents'];

export default function useShouldBlockFeature() {
  const { isGuest, isBlocking } = useAuth();
  const pathname = usePathname();

  if (!isBlocking) return null;
  if (isGuest)
    return GUEST_BLOCK_PAGES.some((page) => pathname?.includes(page));
  return UNVERIFIED_BLOCK_PAGES.some((page) => pathname?.includes(page));
}
