import { LocationDetail } from '@lib/web/thread/types';
import { isNil, omitBy } from 'lodash';
import { ChannelData } from 'stream-chat';

export const locationChannelDataKeys = [
  'location',
  'locationQuery',
  'locationOwnerId',
  'questionId',
  'shortId',
  'quizId',
  'clubId',
  'questionNo',
];

export const getLocationChannelData = (
  locationDetail: LocationDetail
): ChannelData => {
  return omitBy(
    {
      location: locationDetail.location,
      locationQuery: locationDetail.query,
      locationOwnerId: locationDetail.locationOwnerId,
      questionId: locationDetail.questionId,
      shortId: locationDetail.shortId,
      quizId: locationDetail.quizId,
      clubId: locationDetail.clubId,
      questionNo: locationDetail.questionNo,
    },
    isNil
  );
};

export const getChannelLocationDetail = (
  channelData: ChannelData
): LocationDetail => {
  return {
    location: channelData.location,
    query: channelData.locationQuery || {},
    locationOwnerId: channelData.locationOwnerId,
    questionId: channelData.questionId,
    shortId: channelData.shortId,
    quizId: channelData.quizId,
    clubId: channelData.clubId,
    questionNo: channelData.questionNo,
  } as LocationDetail;
};
