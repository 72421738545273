import React from 'react';

export default function EditorTextVariable({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.8908 20.726C20.9432 20.8573 20.8464 21 20.705 21H17.6269C17.5438 21 17.4693 20.9486 17.4399 20.8708L15.8023 16.5476C15.7728 16.4698 15.6984 16.4184 15.6153 16.4184H8.30594C8.22187 16.4184 8.14678 16.471 8.11801 16.55L6.54566 20.8684C6.5169 20.9474 6.4418 21 6.35773 21H3.29578C3.15418 21 3.05743 20.8569 3.11017 20.7255L10.1738 3.12551C10.2043 3.04969 10.2778 3 10.3595 3H13.694C13.7759 3 13.8495 3.04991 13.8798 3.12599L20.8908 20.726ZM14.6178 13.9833C14.7573 13.9833 14.8539 13.8441 14.8052 13.7134L12.2287 6.80335C12.1494 6.57306 12.0657 6.20534 11.9777 5.7002C11.975 5.68491 11.9618 5.67364 11.9462 5.67364C11.9307 5.67364 11.9174 5.68494 11.9147 5.70028C11.8352 6.16467 11.747 6.53236 11.6502 6.80335L9.09939 13.714C9.05118 13.8446 9.14781 13.9833 9.28701 13.9833H14.6178Z"
        fill="currentColor"
      />
    </svg>
  );
}
