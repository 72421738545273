import { useCallback, useContext } from 'react';
import { ErrorMessageContext } from '@lib/web/editor';
import { ErrorMessageValue, PositionalErrorMessages } from '@lib/web/ui';

export const useErrorMessage = () => {
  const [value, setValue] = useContext(ErrorMessageContext);

  const selectErrorMessageByPosition = useCallback(
    (questionId: string, position: keyof PositionalErrorMessages) => {
      return value.errorMessagesMap[questionId]?.[position];
    },
    [value.errorMessagesMap]
  );

  const selectErrorMessage = useCallback(
    (questionId: string) => {
      return value.errorMessagesMap[questionId];
    },
    [value.errorMessagesMap]
  );

  const setErrorMessage = useCallback(
    (
      questionId: string,
      position: keyof PositionalErrorMessages,
      errorMessageValues: ErrorMessageValue[]
    ) => {
      setValue((prev) => ({
        ...prev,
        errorMessagesMap: {
          ...prev.errorMessagesMap,
          [questionId]: {
            ...prev.errorMessagesMap[questionId],
            [position]: errorMessageValues,
          },
        },
      }));
    },
    [setValue]
  );

  const resetAllErrorMessages = useCallback(
    (questionId: string) => {
      setValue((prev) => ({
        ...prev,
        errorMessagesMap: {
          ...prev.errorMessagesMap,
          [questionId]: {
            leftQuestionArea: [],
            rightQuestionArea: [],
            answerArea: [],
            solutionArea: [],
            panelArea: [],
          },
        },
      }));
    },
    [setValue]
  );

  const hasError = useCallback(
    (questionId: string, positionToCheck?: keyof PositionalErrorMessages) => {
      for (const position in value.errorMessagesMap?.[questionId]) {
        if (positionToCheck && position !== positionToCheck) {
          continue;
        }

        const errorMessages =
          value.errorMessagesMap[questionId][
            position as keyof PositionalErrorMessages
          ];
        for (const errorMessage of errorMessages) {
          if (errorMessage.type === 'error') {
            return true;
          }
        }
      }
      return false;
    },
    [value.errorMessagesMap]
  );

  const hasWarning = useCallback(
    (questionId: string, positionToCheck?: keyof PositionalErrorMessages) => {
      for (const position in value.errorMessagesMap?.[questionId]) {
        if (positionToCheck && position !== positionToCheck) {
          continue;
        }

        const errorMessages =
          value.errorMessagesMap[questionId][
            position as keyof PositionalErrorMessages
          ];
        for (const errorMessage of errorMessages) {
          if (errorMessage.type === 'warning') {
            return true;
          }
        }
      }
      return false;
    },
    [value.errorMessagesMap]
  );

  const hasErrorOrWarning = useCallback(
    (questionId: string, positionToCheck?: keyof PositionalErrorMessages) =>
      hasError(questionId, positionToCheck) ||
      hasWarning(questionId, positionToCheck),
    [hasError, hasWarning]
  );

  return {
    value,
    selectErrorMessage,
    selectErrorMessageByPosition,
    setErrorMessage,
    resetAllErrorMessages,
    hasError,
    hasWarning,
    hasErrorOrWarning,
  };
};
