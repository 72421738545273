import { useRef } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import useTableSettings from '../../hooks/useTableSettings';
import { TableLayoutRow, TableLayoutTextCell } from '../../types';
import IaTextBasedEditableCell from '../IaTableEditable/IaTextBasedEditableCell';

export type IaTextCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutTextCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    py: '5.5px',
    display: 'grid',
    alignItems: 'flex-start',
    minHeight: '32px',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  nowrap: {
    height: '32px',
    py: 'unset',
    alignItems: 'center',
  },
  text: {
    br: {
      // make html element render on one line
      content: '" "',
      display: 'none',
    },
  },
  textWrap: {
    whiteSpace: 'pre-wrap',
  },
  textNowrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '& [data-content-type="paragraph"] > p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};
export default function IaTextCell({ row, columnKey, cell }: IaTextCellProps) {
  const {
    text,
    html,
    sx,
    editable,
    actionMap,
    placeholder,
    emptyText,
    className,
  } = cell;
  if (html && text) {
    console.warn('Both text and html are provided, only html will be used');
  }
  const ref = useRef<HTMLDivElement>();
  const { wrap } = useTableSettings();

  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <>
      <Box
        sx={[styles.root, !wrap && styles.nowrap, ...sxProps]}
        ref={ref}
        className={className}
      >
        {html ? (
          <Typography
            variant="body2"
            sx={styles.text}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ) : (
          <Typography
            variant="body2"
            sx={[
              styles.text,
              wrap ? styles.textWrap : styles.textNowrap,
              !text && { color: 'alpha.lightA50' },
            ]}
          >
            {text || emptyText}
          </Typography>
        )}
      </Box>
      <IaTextBasedEditableCell
        containerRef={ref}
        actionMap={actionMap}
        editable={editable}
        placeholder={placeholder}
        columnKey={columnKey}
        row={row}
        initValue={text}
        filterValue={(val: string) => val}
        inputSx={{
          '& .MuiInputBase-input': {
            textAlign: sxProps[0]?.textAlign,
          },
        }}
      />
    </>
  );
}
