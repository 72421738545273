import { useTranslation } from 'react-i18next';
import { ExamMode } from '@lib/web/apis';
import { EssayAnswerInput, EssayAnswerResult } from '@lib/web/ui';

import usePracticeLayout from '../../../../../hooks/usePracticeLayout';
import usePracticeQuestion from '../../../../../hooks/usePracticeQuestion';
import usePracticeQuestionAnswers from '../../../../../hooks/usePracticeQuestionAnswers';
import usePracticeQuestionEvent from '../../../../../hooks/usePracticeQuestionEvent';
import usePracticeQuestionResult from '../../../../../hooks/usePracticeQuestionResult';
import usePracticeQuestionStatus from '../../../../../hooks/usePracticeQuestionStatus';
import usePracticeStaticData from '../../../../../hooks/usePracticeStaticData';

export type QuizAnswerEssayProps = {
  readonly?: boolean;
};

export default function QuizAnswerEssay({ readonly }: QuizAnswerEssayProps) {
  const { t } = useTranslation('quiz');
  const { mode } = usePracticeStaticData();
  const { onChangeAnswers } = usePracticeQuestionEvent();
  const { creatorQuestionInfo } = usePracticeQuestion();
  const { isSubmitted } = usePracticeQuestionStatus();
  const { answers: currentAnswers } = usePracticeQuestionAnswers();
  const { isCorrect } = usePracticeQuestionResult();
  const { showKeyboard } = usePracticeLayout();

  const userValue = currentAnswers[0] || '';

  if (!isSubmitted) {
    return (
      <EssayAnswerInput
        value={userValue}
        onChange={(newValue: string) => onChangeAnswers([newValue])}
        placeholder={t('Enter your answer here')}
        wordLimitMax={{
          value: creatorQuestionInfo?.isOfferWordLimit
            ? creatorQuestionInfo.essayAnswerWordLimitMax
            : 0,
          // XXX: when exceed max, we need to notify window bottom's word limit information,
          //      use the second value as an event is a little hack,
          //      another way it to use redux, but I'd like to reserve redux state for general cases, not this special use case
          onExceed: () =>
            mode === ExamMode.MockExam &&
            onChangeAnswers([userValue, 'show-error']),
          allowExceed: mode !== ExamMode.MockExam,
        }}
        enableHoveredStyle
        inputMode={showKeyboard ? 'none' : 'text'}
        readonly={readonly}
        autoFocus={!readonly}
      />
    );
  }
  return (
    <EssayAnswerResult
      variant={isCorrect ? 'correct' : 'incorrect'}
      title={t('Your Answer')}
      value={userValue}
    />
  );
}
