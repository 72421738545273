import React, { useContext } from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import { TestAdd as TestAddIcon } from '@front/icon';
import { Scrollbar, TipButton } from '@front/ui';
import { ComposerSlashMenuItem } from '@lib/web/composer';
import SlashMenu from '@lib/web/composer/TextComposer/components/SlashMenu';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';

import { useThreadComposer } from '../../hooks/core/useThreadComposer';
import { threadSlashMenuItems } from '../../ThreadTextComposer/config/threadSlashMenuItems';

const styles = {
  divider: {
    backgroundColor: 'alpha.lightA10',
  },
  root: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
  scroll: {
    maxHeight: '151px',
  },
  menu: {
    width: '100%',
    pt: 0.5,
    pb: 1,

    '.composer-slash-menu-item': {
      px: 2.5,
    },
  },
};

export type AddElementActionButtonProps = {
  disabled?: boolean;
};

export function AddElementActionButton({
  disabled,
}: AddElementActionButtonProps) {
  const { selectedAction, setSelectedAction } = useThreadComposer();

  const selected = selectedAction === 'addElement';
  const handleClick = () => {
    setSelectedAction(selected ? undefined : 'addElement');
  };

  return (
    <TipButton
      customSize={24}
      selected={selected}
      onClick={handleClick}
      disabled={disabled}
      title="Add"
    >
      <TestAddIcon />
    </TipButton>
  );
}

export type AddElementActionContentProps = {
  items?: ComposerSlashMenuItem<any>[];
};

export function AddElementActionContent({
  items = threadSlashMenuItems,
}: AddElementActionContentProps) {
  const { selectedAction } = useThreadComposer();
  const { editor } = useContext(TextComposerContext);

  if (selectedAction !== 'addElement') return;

  return (
    <>
      <Divider sx={styles.divider} />

      <Box sx={styles.root}>
        <Scrollbar sx={styles.scroll}>
          <SlashMenu
            sx={styles.menu}
            filteredItems={items}
            itemCallback={(item) => {
              item.execute(editor);
              editor.focus();
            }}
            keyboardHoveredItemIndex={-1} // currently we don't need keyboard support
          />
        </Scrollbar>
      </Box>
    </>
  );
}
