import React from 'react';

export default function EditorDifficulty({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C8.78571 9.07688 4.8 11.1025 3 11.9871V14.3204C6.92143 12.5256 10.6114 9.9102 12 8.48714C13.3886 9.9102 17.0786 12.5256 21 14.3204V11.9871C19.2 11.1025 15.2143 9.07688 12 5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.8334C8.55429 14.1795 4.8 15.8847 3 16.6667V19C6.85714 17.2564 8.14286 16.6667 12 14.3334C15.8571 16.6667 17.1429 17.2436 21 19V16.6667C19.2 15.8847 15.4457 14.1924 12 10.8334Z"
        fill="currentColor"
      />
    </svg>
  );
}
