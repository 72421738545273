import React from 'react';

export default function ActionResize({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.46982 18L9 16.3215L5.06037 12L9 7.6785L7.46982 6L2 12L7.46982 18Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M16.5302 18L15 16.3215L18.9396 12L15 7.6785L16.5302 6L22 12L16.5302 18Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
