import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  ActionCloseSmall as ActionCloseSmallIcon,
  OtherCheckedCircleOutline as OtherCheckedCircleOutlineIcon,
  OtherError as OtherErrorIcon,
  OtherInfoOutline as OtherInfoOutlineIcon,
  OtherWarning as OtherWarningIcon,
} from '@front/icon';

import { TipButton } from '../../../atoms';
import { ReverseTheme } from '../../../components';

export type BaseSnackbarType = 'primary' | 'error' | 'warning' | 'success';

export type BaseSnackbarProps = {
  sx?: BoxProps['sx'];
  message: ReactNode;
  title?: string;
  type?: BaseSnackbarType;
  customizeIcon?: ReactNode;
  actionComponent?: ReactNode;
  onClose?: () => void;
};

const styles = {
  root: {
    position: 'relative',
    px: '12px',
    py: 1,
    borderRadius: 1,
    filter: 'drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.15))',
    bgcolor: 'background.menu',
    color: 'text.primary',
    overflow: 'hidden',
    maxWidth: { md: 335 },
    '&:before': {
      position: 'absolute',
      content: "''",
      top: 0,
      left: 0,
      bottom: 0,
      width: 4,
      backgroundColor: 'currentColor',
    },
  },
  inner: {
    display: 'flex',
    gap: 1,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    height: 16,
  },
  message: {
    flex: 1,
    display: 'flex',
    gap: 2,
    color: 'text.primary',
  },
  columnMessage: {
    flexDirection: 'column',
    gap: 1,
  },
  close: {
    p: 0,
    ml: 'auto',
    mr: -0.5,
    color: 'text.primary',
    display: 'flex',
    alignItems: 'center',
    height: 16,
    '& svg': {
      display: 'block',
      width: 16,
      height: 16,
    },
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    '& > .MuiButtonBase-root': {
      flex: 1,
      maxWidth: { sm: 155 },
    },
  },
};

const getIcon = (type: BaseSnackbarType): React.ReactNode => {
  if (type === 'error') return <OtherErrorIcon width={16} height={16} />;
  if (type === 'warning') return <OtherWarningIcon width={16} height={16} />;
  if (type === 'success')
    return <OtherCheckedCircleOutlineIcon width={16} height={16} />;
  return <OtherInfoOutlineIcon width={16} height={16} />;
};

const getMainColor = (type: BaseSnackbarType) => {
  if (type === 'error') return 'error.dark';
  if (type === 'warning') return 'warning.light';
  if (type === 'success') return 'success.dark';
  return 'primary.dark';
};

export default function BaseSnackbar({
  sx,
  type = 'primary',
  message,
  title,
  onClose,
  actionComponent,
  customizeIcon,
  ...rest
}: BaseSnackbarProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const icon = customizeIcon || getIcon(type);
  const mainColor = getMainColor(type);

  const closeComponent = onClose ? (
    <TipButton
      sx={styles.close}
      customSize={24}
      onClick={onClose}
      title="Close"
    >
      <ActionCloseSmallIcon />
    </TipButton>
  ) : undefined;

  return (
    <ReverseTheme>
      <Box sx={[styles.root, { color: mainColor }, ...sxProps]} {...rest}>
        <Box sx={styles.inner}>
          <Box sx={styles.icon}>{icon}</Box>
          <Box sx={[styles.message, !!actionComponent && styles.columnMessage]}>
            <Box flex="1" display="grid" gap={0.5}>
              {!!title && (
                <Typography display="block" variant="caption" fontWeight="bold">
                  {title}
                </Typography>
              )}
              <Box typography="caption">{message}</Box>
            </Box>
            {!!actionComponent && (
              <Box sx={styles.action}>{actionComponent}</Box>
            )}
          </Box>
          {closeComponent}
        </Box>
      </Box>
    </ReverseTheme>
  );
}
