import React from 'react';

export default function TextEditorTypeHeader3({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M14.3563 16.1093L14.4685 16.1628C14.7082 16.2769 15.06 16.4098 15.5262 16.5614C16.2951 16.7919 17.3642 16.9006 18.2349 16.6843C18.6695 16.5763 19.0478 16.3892 19.3168 16.1029C19.584 15.8186 19.7504 15.4293 19.7504 14.9012C19.7504 14.4306 19.6171 14.0822 19.4015 13.8208C19.1845 13.5579 18.8777 13.3753 18.5186 13.2496C17.7971 12.9968 16.8923 12.9822 16.1636 12.9822H16.0636V11.0617H16.1636C16.2579 11.0617 16.3538 11.0626 16.4508 11.0635H16.4508C16.8923 11.0676 17.3555 11.0719 17.7867 10.9933C18.3081 10.8983 18.7657 10.6845 19.0636 10.2129C19.1905 10.0119 19.2608 9.74968 19.2692 9.4806C19.2777 9.21156 19.2241 8.9439 19.1124 8.73301C18.7366 8.02311 18.0248 7.82487 17.2401 7.90093C16.4543 7.9771 15.6215 8.32836 15.0581 8.68306L14.9639 8.74238L14.1034 6.98235L14.1783 6.93424C15.6626 5.98001 17.6392 5.57896 19.3553 6.151C20.2334 6.4437 21.0146 7.06907 21.3481 7.96504C21.9455 9.418 21.4245 11.0666 20.0743 11.8333C21.185 12.3107 21.8581 13.3305 22.047 14.4332C22.2473 15.6025 21.9054 16.8775 20.9523 17.7151L20.951 17.7163C20.0859 18.4502 18.8154 18.8181 17.5295 18.8748C16.2429 18.9314 14.9246 18.6773 13.9562 18.1492L13.8895 18.1127L14.3563 16.1093Z"
        fill="currentColor"
      />
      <path
        d="M9.39651 13.1579H4.31201V18.5477H1.8999V6.18107H4.31201V11.0442H9.39651V6.18107H11.8086V18.5477H9.39651V13.1579Z"
        fill="currentColor"
      />
    </svg>
  );
}
