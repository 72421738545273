import React from 'react';

export default function EditorLineChart({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect height="2" width="18" fill="currentColor" rx="1" x="3" y="19" />
      <path
        d="M20.2256 3.18741C20.9507 3.58811 21.2136 4.50073 20.8129 5.22581L16.87 12.3607C16.1347 13.6912 14.392 14.063 13.1777 13.1484L9.78365 10.5918L5.68901 15.9148C5.18391 16.5715 4.24214 16.6943 3.58551 16.1892C2.92888 15.6841 2.80604 14.7423 3.31114 14.0857L7.70757 8.37035C8.54338 7.28379 10.0983 7.07297 11.1933 7.89775L14.5228 10.4057L18.1872 3.77475C18.5879 3.04967 19.5005 2.78672 20.2256 3.18741Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
