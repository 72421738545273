import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';
import {
  AgentPromptViewSlug,
  AgentViewSlug,
  AiModelViewSlug,
  ClubGroupViewSlug,
  ClubViewSlug,
  GetIaAgentPromptResMap,
  GetIaAgentResMap,
  GetIaAiModelResMap,
  GetIaClubResMap,
  GetIaQuizResMap,
  patchIaAgentQuery,
  patchIaAiModelQuery,
  QuizViewSlug,
} from '@lib/web/apis';

import api from './client';

const iaApi = {
  clubs: <
    K = GetIaClubDefaultRes,
    T extends ClubViewSlug | undefined = undefined
  >(
    query: IaSearchReq<T> = {}
  ) =>
    api.get<PageResponse<T extends ClubViewSlug ? GetIaClubResMap[T] : K>>(
      `v2/ia/clubs?${getIaQueryString(query)}`
    ),
  clubGroups: <
    K = GetIaClubGroupDefaultRes,
    T extends ClubGroupViewSlug | undefined = undefined
  >(
    query: IaSearchReq<T> = {}
  ) =>
    api.get<PageResponse<T extends ClubViewSlug ? GetIaClubResMap[T] : K>>(
      `v2/ia/club-groups?${getIaQueryString(query)}`
    ),
  aiModels: <
    K = GetIaAiModelDefaultRes,
    T extends AiModelViewSlug | undefined = undefined
  >(
    query: IaSearchReq<T> = {}
  ) =>
    api.get<
      PageResponse<T extends AiModelViewSlug ? GetIaAiModelResMap[T] : K>
    >(`v2/ia/ai-models?${getIaQueryString(patchIaAiModelQuery(query))}`),
  agents: <
    K = GetIaAgentDefaultRes,
    T extends AgentViewSlug | undefined = undefined
  >(
    query: IaSearchReq<T> = {}
  ) =>
    api.get<PageResponse<T extends AgentViewSlug ? GetIaAgentResMap[T] : K>>(
      `v2/ia/agents?${getIaQueryString(patchIaAgentQuery(query))}`
    ),
  agentPrompts: <
    K = GetIaAgentPromptDefaultRes,
    T extends AgentPromptViewSlug | undefined = undefined
  >(
    query: IaSearchReq<T> = {}
  ) =>
    api.get<
      PageResponse<
        T extends AgentPromptViewSlug ? GetIaAgentPromptResMap[T] : K
      >
    >(`v2/ia/agent-prompts?${getIaQueryString(query)}`),
  quizzes: <
    E extends keyof IaQuizExtensionAttributes,
    K = GetIaQuizSmallIconsCenterQuizDefaultRes<E>,
    T extends QuizViewSlug | undefined = undefined
  >(
    query: IaSearchReq<T> = {}
  ) =>
    api.get<PageResponse<T extends QuizViewSlug ? GetIaQuizResMap<E>[T] : K>>(
      `v2/ia/quizzes?${getIaQueryString(query)}`
    ),
  dataSourceQuestions: (query: IaSearchReq<undefined> = {}) =>
    api.get<PageResponse<GetQuizDataSourceQuestionRes>>(
      `v2/ia/datasource/questions?${getIaQueryString(query)}`
    ),
  asyncTasks: <K = GetIaAsyncTaskDefaultRes>(
    query: IaSearchReq<undefined> = {}
  ) => api.get<PageResponse<K>>(`v2/ia/async-tasks?${getIaQueryString(query)}`),
};

export default iaApi;
