import { useTranslation } from 'next-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useAuth } from '@lib/web/apis';

const Logo = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="6" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3411 13.4982C28.3411 13.4982 28.3411 13.4981 28.3411 13.4981C29.4253 11.6203 28.7819 9.2191 26.904 8.13493C25.0262 7.05077 22.625 7.69416 21.5408 9.57199C21.5331 9.58547 21.5254 9.59897 21.5178 9.6125L11.7258 26.5728L18.526 30.4989L28.3413 13.4983L28.3411 13.4982Z"
      fill="#464646"
    />
    <circle
      cx="15.1255"
      cy="28.536"
      r="3.92612"
      transform="rotate(30 15.1255 28.536)"
      fill="white"
    />
  </svg>
);

const getColor = (theme: Theme, dark = '#181818', light = 'white') =>
  theme.palette.mode === 'dark' ? dark : light;

export default function LoginPopup() {
  const { isLoggedOut } = useAuth();
  const { t } = useTranslation();
  if (!isLoggedOut) return null;
  return (
    <Box
      sx={(theme) => ({
        background: getColor(theme, 'rgba(255, 255, 255, 0.9)', '#181818'),
        backdropFilter: 'blur(4px)',
        height: theme.size.login.xs,
        display: 'flex',
        alignItems: 'center',
        padding: 2.5,
        zIndex: 5000,
        position: 'sticky',
        top: 'var(--paywall-banner-h)',
        [theme.breakpoints.up('md')]: {
          height: theme.size.login.md,
        },
      })}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: 1240,
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          component="a"
          href="https://www.earnaha.com/"
          rel="noopener noreferrer"
        >
          <Logo />
          <Typography
            sx={(theme) => ({
              color: getColor(theme),
              ml: 1,
              fontWeight: 'bold',
              [theme.breakpoints.up('sm')]: {
                display: 'none',
              },
            })}
            variant="subtitle1"
          >
            aha AI
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            ml: 2,
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          })}
        >
          <Typography
            sx={(theme) => ({
              color: getColor(theme),
              fontWeight: 'bold',
            })}
            variant="subtitle1"
          >
            {t('login.popup.title', 'Log into Aha AI')}
          </Typography>
          <Typography
            sx={(theme) => ({
              color: getColor(theme),
              mt: 0.25,
            })}
            variant="body2"
          >
            {t(
              'login.popup.content',
              'Log in to practice unlimited questions, find your weakness, and compare progress.'
            )}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            ml: 'auto',
            '& .MuiButtonBase-root': {
              fontSize: 14,
              lineHeight: 1,
              fontWeight: 700,
              padding: ['4px 16px', '9px 16px'],
              borderRadius: 1,

              border: '4px solid',
              borderColor: getColor(theme),
              transitionDuration: '0.3s',

              '&:disabled': {
                opacity: 0.8,
              },
            },
          })}
        >
          <ButtonBase
            component="a"
            href="https://www.earnaha.com/"
            rel="noopener noreferrer"
            sx={(theme) => ({
              '&.MuiButtonBase-root': {
                color: getColor(theme, 'white', '#181818'),
                backgroundColor: getColor(theme),
                '&:not(:disabled):hover': {
                  color: getColor(theme),
                  backgroundColor: 'transparent',
                },
                '@media (hover: none)': {
                  '&:hover': {
                    color: getColor(theme, 'white', '#181818'),
                    backgroundColor: getColor(theme),
                  },
                },
              },
            })}
          >
            earnaha.com
            <ArrowForwardIcon sx={{ ml: 1 }} />
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}
