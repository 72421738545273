import React from 'react';

export default function ChatDirectMessage({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.00002 12.0526C3.00002 7.61235 7.03742 4 12 4C16.9626 4 21 7.61235 21 12.0526C21 16.4929 16.9626 20.1053 12 20.1053C10.7211 20.1053 9.48632 19.8696 8.32712 19.4047C6.28562 20.5762 3.46322 20.98 3.34172 20.997C3.32762 20.9991 3.31382 21 3.30002 21C3.18752 21 3.08252 20.9371 3.03152 20.8348C2.97392 20.7202 2.99672 20.5816 3.08792 20.4909C3.77792 19.8052 4.25312 18.3578 4.43042 16.4082C3.49382 15.1082 3.00002 13.6047 3.00002 12.0526ZM13.8 10.2632C13.8 11.2518 12.9945 12.0526 12 12.0526C11.0055 12.0526 10.2 11.2518 10.2 10.2632C10.2 9.27447 11.0055 8.47368 12 8.47368C12.9945 8.47368 13.8 9.27447 13.8 10.2632ZM8.39999 14.7368C8.39999 13.5468 10.7985 12.9474 12 12.9474C13.2015 12.9474 15.6 13.5468 15.6 14.7368V15.6316H8.39999V14.7368Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
