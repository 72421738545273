import React from 'react';

export default function OtherAmbassadorship({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.8611 5.40856C11.8611 5.03498 12.0148 4.67671 12.2882 4.41256C12.5617 4.1484 12.9327 4 13.3194 4C13.7062 4 14.0772 4.1484 14.3506 4.41256C14.6241 4.67671 14.7778 5.03498 14.7778 5.40856V15.738C14.7778 16.1116 14.6241 16.4698 14.3506 16.734C14.0772 16.9981 13.7062 17.1465 13.3194 17.1465C12.9327 17.1465 12.5617 16.9981 12.2882 16.734C12.0148 16.4698 11.8611 16.1116 11.8611 15.738V5.40856ZM10.8889 6.08842C8.87931 6.98051 9.25375 7.47913 6.86111 7.64346V13.5002C7.21426 13.5195 7.56693 13.5461 7.91889 13.5801C9.91486 13.7716 9.06694 14.2073 10.8889 15.045V6.08842ZM5.88889 13.4514V7.69417C5.22778 7.71952 4.52875 7.73455 3.93667 7.74394C3.42172 7.75083 2.93018 7.95282 2.56794 8.30638C2.20569 8.65995 2.00174 9.13681 2 9.63422V11.5123C2 12.5509 2.87111 13.3866 3.93861 13.3988C4.09936 13.4008 4.2601 13.4033 4.42083 13.4063C4.91035 13.4158 5.39974 13.4308 5.88889 13.4514ZM7.24028 14.4665C7.51736 14.4862 7.79347 14.5106 8.06667 14.5398L8.31264 18.8854C8.33941 19.0212 8.33479 19.161 8.29914 19.2949C8.26348 19.4288 8.19765 19.5534 8.10636 19.6599C8.01507 19.7664 7.90056 19.8521 7.77101 19.9109C7.64147 19.9697 7.50008 20.0001 7.35694 20H6.82417C6.63519 20 6.45031 19.9468 6.29215 19.8469C6.13398 19.747 6.00939 19.6047 5.93361 19.4375L4.65611 14.3501C5.20469 14.3633 5.75307 14.3833 6.30111 14.4102C6.61903 14.4261 6.93306 14.4449 7.24028 14.4665Z"
        fill="currentColor"
      />
      <path
        d="M19.2249 6.42545C19.6024 6.10192 20.1737 6.14223 20.501 6.51549C21.4721 7.62322 22 9.10147 22 10.6207C22 12.1399 21.4721 13.6181 20.501 14.7259C20.1737 15.0991 19.6024 15.1394 19.2249 14.8159C18.8473 14.4924 18.8066 13.9275 19.1338 13.5542C19.7993 12.7952 20.1908 11.7415 20.1908 10.6207C20.1908 9.49985 19.7993 8.44619 19.1338 7.68712C18.8066 7.31386 18.8473 6.74899 19.2249 6.42545ZM16.7566 9.24095C17.1341 8.91741 17.7054 8.95773 18.0327 9.33099C18.3491 9.692 18.5093 10.1575 18.5093 10.6207C18.5093 11.0839 18.3491 11.5494 18.0327 11.9104C17.7054 12.2836 17.1341 12.3239 16.7566 12.0004C16.379 11.6769 16.3383 11.112 16.6655 10.7387C16.6763 10.7264 16.7001 10.6855 16.7001 10.6207C16.7001 10.5559 16.6763 10.515 16.6655 10.5026C16.3383 10.1294 16.379 9.56449 16.7566 9.24095Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
