import { TFunction } from 'i18next';

const getClubGroupPageConfig = (t: TFunction) => ({
  settings: {
    title: t('clubGroup.settings.title'),
    icon: 'MainClubGroup',
  },
  clubs: {
    title: t('clubGroup.clubs.title'),
    icon: 'MainApps',
  },
});

export default getClubGroupPageConfig;
