import React from 'react';

export default function MainNotificationOffOutline({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.6364 3.61369C20.9879 3.96516 20.9879 4.53501 20.6364 4.88648L4.6364 20.8865C4.28492 21.238 3.71508 21.238 3.3636 20.8865C3.01213 20.535 3.01213 19.9652 3.3636 19.6137L19.3636 3.61369C19.7151 3.26222 20.2849 3.26222 20.6364 3.61369Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M15.5183 5.48C15.2112 5.29287 14.8734 5.12646 14.5026 4.97921C14.4896 4.97226 14.4781 4.96315 14.4684 4.95229C14.1067 3.78309 13.1167 3 12.0001 3C10.8835 3 9.89396 3.78309 9.53219 4.95109C9.52253 4.96155 9.51113 4.97037 9.49846 4.9772C7.15128 5.90976 6.01057 7.69892 6.01057 10.4472C6.01057 13.475 5.45729 14.1203 4.54056 15.1883L4.51556 15.2174C4.45699 15.2855 4.39747 15.3548 4.33698 15.4265C4.15847 15.6343 4.04536 15.8871 4.01106 16.155C3.97701 16.4208 4.02198 16.6905 4.14067 16.9325L7.35529 13.6967C7.60392 12.8296 7.70469 11.7928 7.70469 10.4472C7.70469 9.308 7.94012 8.51687 8.30005 7.95239C8.65293 7.39896 9.21421 6.92344 10.1204 6.56339L10.2119 6.52706L10.2985 6.48034C10.4736 6.3859 10.6345 6.26209 10.7731 6.11199L11.035 5.82834L11.1495 5.45866C11.3176 4.91606 11.7117 4.70527 12.0001 4.70527C12.2884 4.70527 12.6828 4.91594 12.851 5.45939L12.9602 5.81258L13.2051 6.08845C13.3493 6.25087 13.5192 6.38394 13.7053 6.48385L13.7908 6.52974L13.8809 6.56552C14.0192 6.62042 14.1486 6.67747 14.2699 6.7366L15.5183 5.48Z"
        fill="currentColor"
      />
      <path
        d="M11.3772 16.0794H17.9858C17.5786 15.5989 17.1171 15.0088 16.7965 14.1548C16.4896 13.3374 16.3418 12.3732 16.3052 11.1189L17.9363 9.47706C17.9721 9.78756 17.9901 10.1115 17.9901 10.4484C17.9901 13.4735 18.5436 14.1196 19.4586 15.1878L19.4601 15.1895C19.492 15.2266 19.5241 15.2644 19.5566 15.3025L19.5597 15.3062C19.5941 15.3465 19.6287 15.3871 19.6636 15.4277C19.8422 15.6359 19.9551 15.889 19.9892 16.1572C20.0232 16.4254 19.9768 16.6973 19.8556 16.9409C19.5979 17.4608 19.0542 17.7846 18.4322 17.7846H9.68306L11.3772 16.0794Z"
        fill="currentColor"
      />
      <path
        d="M9.21011 19.5473C9.19721 19.5283 9.18451 19.5092 9.17202 19.4899C9.13406 19.4313 9.09799 19.3715 9.0639 19.3104C9.05045 19.286 9.04382 19.2585 9.04465 19.2308C9.04547 19.2032 9.05373 19.1761 9.06861 19.1524C9.08349 19.1287 9.10449 19.1091 9.12956 19.0956C9.15464 19.082 9.18293 19.0749 9.21169 19.075H14.7812C14.8099 19.075 14.8381 19.0821 14.8631 19.0957C14.8882 19.1093 14.9091 19.1289 14.9239 19.1526C14.9388 19.1763 14.947 19.2033 14.9478 19.2309C14.9486 19.2586 14.942 19.286 14.9285 19.3104C14.8944 19.3715 14.8584 19.4313 14.8204 19.4899C14.8077 19.5095 14.7943 19.5297 14.7812 19.549C14.6537 19.7364 14.5065 19.91 14.3422 20.0672C14.191 20.2119 14.0252 20.3427 13.847 20.4576C13.8002 20.4878 13.7526 20.5168 13.7041 20.5448C13.5442 20.6369 13.3773 20.7156 13.205 20.7803C12.8212 20.9246 12.4113 20.9997 11.996 21C11.5809 20.9996 11.1711 20.9246 10.7874 20.7803C10.6151 20.7155 10.4481 20.6368 10.2881 20.5447C10.2397 20.5168 10.1921 20.4877 10.1454 20.4576C9.96569 20.3418 9.79852 20.2096 9.64626 20.0634C9.48372 19.9074 9.33801 19.7352 9.21169 19.5496L9.21011 19.5473Z"
        fill="currentColor"
      />
    </svg>
  );
}
