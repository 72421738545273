import { useMemo } from 'react';
import { useAiTokenBalance } from '@lib/web/apis';

const isRunOutOfToken = (tokenBalance?: GetAiTokenBalanceRes) => {
  return tokenBalance && tokenBalance.aiTokenTotalAmount === 0;
};

export const useTokenBalanceControl = () => {
  const { data } = useAiTokenBalance();

  const runOutOfToken = useMemo(
    () => isRunOutOfToken(data?.data),
    [data?.data]
  );

  return {
    runOutOfToken,
  };
};
