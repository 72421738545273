import React from 'react';

export default function EditorFillInTheBlank({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#FF4E8E"
        fillRule="evenodd"
      />
      <path
        d="M11.6748 7.33596C11.4124 7.33596 11.1645 7.36877 10.9312 7.43438C10.698 7.49998 10.4392 7.60568 10.1549 7.75148L9.69562 6.51587C9.97992 6.33362 10.3152 6.19512 10.7016 6.10035C11.088 5.99829 11.467 5.94727 11.8388 5.94727C12.3053 5.94727 12.6881 6.00923 12.9869 6.13315C13.2931 6.25708 13.5337 6.41381 13.7086 6.60334C13.8836 6.79288 14.0039 7.00064 14.0695 7.22662C14.1424 7.4526 14.1788 7.66765 14.1788 7.87176C14.1788 8.07587 14.146 8.26541 14.0804 8.44036C14.0221 8.60803 13.9455 8.76476 13.8508 8.91055C13.756 9.05635 13.6467 9.19485 13.5227 9.32607C13.3988 9.45728 13.2785 9.58121 13.1619 9.69784C13.0744 9.78532 12.9833 9.88009 12.8885 9.98214C12.7938 10.0769 12.7063 10.179 12.6261 10.2883C12.5532 10.3977 12.4912 10.5143 12.4402 10.6382C12.3892 10.7549 12.3637 10.8751 12.3637 10.9991C12.3637 11.0282 12.3637 11.072 12.3637 11.1303C12.3637 11.1886 12.3673 11.2396 12.3746 11.2834H10.9312C10.9167 11.2105 10.9057 11.1303 10.8984 11.0428C10.8984 10.948 10.8984 10.8678 10.8984 10.8022C10.8984 10.5981 10.9239 10.4159 10.975 10.2555C11.026 10.0878 11.0916 9.93476 11.1718 9.79625C11.2593 9.65046 11.3577 9.5156 11.467 9.39167C11.5764 9.26775 11.6894 9.14747 11.806 9.03083C11.9737 8.86317 12.1268 8.69915 12.2653 8.53877C12.4038 8.37111 12.473 8.18157 12.473 7.97017C12.473 7.79522 12.4038 7.64578 12.2653 7.52185C12.134 7.39793 11.9372 7.33596 11.6748 7.33596ZM12.6917 12.8361C12.6917 13.1568 12.586 13.4047 12.3746 13.5796C12.1705 13.7546 11.9372 13.8421 11.6748 13.8421C11.4124 13.8421 11.1754 13.7546 10.964 13.5796C10.7599 13.4047 10.6579 13.1568 10.6579 12.8361C10.6579 12.5153 10.7599 12.2675 10.964 12.0925C11.1754 11.9176 11.4124 11.8301 11.6748 11.8301C11.9372 11.8301 12.1705 11.9176 12.3746 12.0925C12.586 12.2675 12.6917 12.5153 12.6917 12.8361Z"
        fill="#080808"
      />
      <path
        d="M1.93701 16.4736C1.93701 16.1829 2.17265 15.9473 2.46333 15.9473H21.4109C21.7015 15.9473 21.9372 16.1829 21.9372 16.4736V17.5263C21.9372 17.8169 21.7015 18.0526 21.4109 18.0526H2.46333C2.17265 18.0526 1.93701 17.8169 1.93701 17.5263V16.4736Z"
        fill="#080808"
      />
    </svg>
  );
}
