import React from 'react';

export default function MainProfileRound({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.2 8.91667C10.2 7.95017 10.9835 7.16667 11.95 7.16667C12.9165 7.16667 13.7 7.95017 13.7 8.91667C13.7 9.88316 12.9165 10.6667 11.95 10.6667C10.9835 10.6667 10.2 9.88316 10.2 8.91667ZM11.95 6C10.3392 6 9.03333 7.30584 9.03333 8.91667C9.03333 10.5275 10.3392 11.8333 11.95 11.8333C13.5608 11.8333 14.8667 10.5275 14.8667 8.91667C14.8667 7.30584 13.5608 6 11.95 6ZM9.61667 13C8.84312 13 8.10125 13.3073 7.55427 13.8543C7.00729 14.4013 6.7 15.1431 6.7 15.9167V17.0833C6.7 17.4055 6.96117 17.6667 7.28333 17.6667C7.6055 17.6667 7.86667 17.4055 7.86667 17.0833V15.9167C7.86667 15.4525 8.05104 15.0074 8.37923 14.6792C8.70742 14.351 9.15254 14.1667 9.61667 14.1667H14.2833C14.7475 14.1667 15.1926 14.351 15.5208 14.6792C15.849 15.0074 16.0333 15.4525 16.0333 15.9167V17.0833C16.0333 17.4055 16.2945 17.6667 16.6167 17.6667C16.9388 17.6667 17.2 17.4055 17.2 17.0833V15.9167C17.2 15.1431 16.8927 14.4013 16.3457 13.8543C15.7987 13.3073 15.0569 13 14.2833 13H9.61667Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
