import {
  alpha,
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
  Box,
  Chip,
  ChipTypeMap,
  Popper,
  Theme,
  useAutocomplete,
} from '@mui/material';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionCloseSmall as ActionCloseSmallIcon,
} from '@front/icon';

import { TipButton } from '../../atoms';

const styles = {
  root: {
    '& .autocomplete-input input': {
      pr: '68px',
    },

    '&:hover': {
      '& .MuiAutocomplete-clearIndicator': {
        visibility: 'visible',
      },
    },
  },
  multiple: {
    '& .MuiInputBase-input': {
      width: 0,
      minWidth: '30%',
      textOverflow: 'ellipsis',
      flex: 1,
    },
    '& .MuiInputBase-root': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 0.5,
      pr: '50px',
    },
  },
  list: {
    p: '6px 0',
    bgcolor: 'background.menu',
    color: 'text.primary',
    m: 0,
    maxHeight: '40vh',
    overflow: 'auto',
    width: '100%',
    listStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      borderRadius: 1,
      '&:hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.2),
      },
    },
    li: {
      padding: '3px 12px',
      fontSize: 14,
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      '@media (hover: hover)': {
        '&:not(:disabled):hover': {
          backgroundColor: (theme: Theme) =>
            alpha(theme.palette.text.primary, 0.05),
        },
      },
      '@media (max-width: 767.99px)': {
        minHeight: '45px !important',
        fontSize: 16,
      },
      '&.Mui-focused': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.05),
      },
      '&.Mui-focusVisible': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  selectedList: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  endAdornment: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: 8,
    gap: 0.5,
  },
  clearIndicator: {
    visibility: 'hidden',
  },
};
function defaultGetOptionLabel<Value>(option: Value) {
  return (
    typeof option === 'object' && option !== null
      ? 'label' in option
        ? option.label
        : 'title' in option
        ? option.title
        : ''
      : typeof option === 'string'
      ? option
      : ''
  ) as string;
}

function getOptionKey<Value>(option: Value, index: number) {
  return typeof option === 'object' && option !== null
    ? 'value' in option && typeof option.value === 'string'
      ? option.value
      : `${index}`
    : typeof option === 'string'
    ? option
    : `${index}`;
}

export default function AutoComplete<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>(
  props: AutocompleteProps<
    Value,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  >
) {
  const {
    options,
    disableClearable = false,
    disabled = false,
    disablePortal = false,
    size = 'medium',
    readOnly,
    freeSolo = false,
    fullWidth = false,
    loading = false,
    multiple = false,
    clearText = 'Clear',
    closeText = 'Close',
    openText = 'Open',
    noOptionsText = 'No options',
    loadingText = 'Loading…',
    limitTags = -1,
    componentsProps,
    popupIcon,
    forcePopupIcon,
    renderInput,
    renderTags,
    renderOption: renderOptionProp,
    getOptionLabel: getOptionLabelProp,
    getLimitTagsText = (more) => `+${more}`,
  } = props;
  const {
    value,
    dirty,
    expanded,
    inputValue,
    focused,
    focusedTag,
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    getPopupIndicatorProps,
    getTagProps,
    groupedOptions,
    anchorEl,
    setAnchorEl,
    id,
    popupOpen,
  } = useAutocomplete({
    ...props,
  });

  const hasClearIcon = !disableClearable && !disabled && dirty && !readOnly;
  const hasPopupIcon =
    (!freeSolo || forcePopupIcon === true) && forcePopupIcon !== false;

  const clearProps = getClearProps();
  const popupIndicatorProps = getPopupIndicatorProps();
  const inputProps = getInputProps();
  const listboxProps = getListboxProps();
  const rootProps = getRootProps();
  const getOptionLabel = getOptionLabelProp || defaultGetOptionLabel;

  const defaultRenderOption = (
    props2: React.HTMLAttributes<HTMLLIElement> & { key: string },
    option: Value,
    state: AutocompleteRenderOptionState
  ) => {
    const { key, ...otherProps } = props2;
    return (
      <Box
        key={key}
        sx={[state.selected && styles.selectedList]}
        {...otherProps}
        component="li"
      >
        {getOptionLabel(option)}
      </Box>
    );
  };

  const renderOption = renderOptionProp || defaultRenderOption;

  const ownerState = {
    ...props,
    disablePortal,
    expanded,
    focused,
    fullWidth,
    getOptionLabel,
    hasClearIcon,
    hasPopupIcon,
    inputFocused: focusedTag === -1,
    popupOpen,
    size,
  };

  const hasMultipleValue = multiple && Array.isArray(value) && value.length > 0;
  let startAdornment;

  if (hasMultipleValue) {
    const getCustomizedTagProps: AutocompleteRenderGetTagProps = (params) => ({
      disabled,
      className: '',
      ...getTagProps(params),
    });

    if (renderTags) {
      startAdornment = renderTags(value, getCustomizedTagProps, ownerState);
    } else {
      startAdornment = value.map((option, index) => {
        const { key, ...customTagProps } = getCustomizedTagProps({ index });
        return (
          <Chip
            key={key}
            label={getOptionLabel(option)}
            size={size}
            {...customTagProps}
          />
        );
      });
    }
  }

  const endAdornment = (
    <Box sx={styles.endAdornment} className="MuiAutocomplete-endAdornment">
      {hasClearIcon ? (
        <TipButton
          sx={styles.clearIndicator}
          onClick={clearProps.onClick}
          title={clearText}
          customSize={24}
          className="MuiAutocomplete-clearIndicator"
        >
          <ActionCloseSmallIcon />
        </TipButton>
      ) : null}

      {hasPopupIcon ? (
        <TipButton
          onClick={popupIndicatorProps.onClick}
          disabled={disabled}
          aria-label={popupOpen ? closeText : openText}
          title={popupOpen ? closeText : openText}
          className="MuiAutocomplete-popupIndicator"
        >
          {popupIcon || <ActionChevronDownIcon />}
        </TipButton>
      ) : null}
    </Box>
  );

  const popperSxProps = Array.isArray(componentsProps?.popper?.sx)
    ? componentsProps?.popper?.sx
    : [componentsProps?.popper?.sx];

  let autocompletePopper = null;

  if (groupedOptions.length > 0) {
    autocompletePopper = (
      <>
        {(groupedOptions as typeof options).map((option, index) => {
          const optionProps = getOptionProps({ option, index });
          return renderOption(
            {
              ...optionProps,
              key: getOptionKey(option, index),
            },
            option,
            {
              selected: Boolean(optionProps['aria-selected']),
              index,
              inputValue,
            },
            ownerState
          );
        })}
      </>
    );
  } else if (loading) {
    autocompletePopper = <Box component="li">{loadingText}</Box>;
  } else if (!freeSolo && !loading) {
    autocompletePopper = (
      <Box
        component="li"
        role="presentation"
        onMouseDown={(event) => {
          event.preventDefault();
        }}
      >
        {noOptionsText}
      </Box>
    );
  }

  if (limitTags > -1 && Array.isArray(startAdornment)) {
    const more = startAdornment.length - limitTags;
    if (!focused && more > 0) {
      startAdornment = startAdornment.splice(0, limitTags);
      startAdornment.push(
        <span key={startAdornment.length}>{getLimitTagsText(more)}</span>
      );
    }
  }

  return (
    <Box sx={[styles.root, hasMultipleValue && styles.multiple]}>
      <Box {...rootProps}>
        {renderInput({
          id,
          disabled,
          fullWidth: true,
          size: size === 'small' ? 'small' : undefined,
          InputLabelProps: getInputLabelProps(),
          InputProps: {
            ref: setAnchorEl,
            endAdornment,
            startAdornment,
            className: 'autocomplete-input',
          },
          inputProps: {
            disabled,
            readOnly,
            ...inputProps,
          },
        })}
      </Box>

      {anchorEl ? (
        <Popper
          open={popupOpen}
          anchorEl={anchorEl}
          disablePortal={disablePortal}
          {...componentsProps?.popper}
          sx={[{ zIndex: 1000 }, ...popperSxProps]}
        >
          <Box
            sx={[styles.list, { width: anchorEl.clientWidth }]}
            component="ul"
            {...listboxProps}
          >
            {autocompletePopper}
          </Box>
        </Popper>
      ) : null}
    </Box>
  );
}
