import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import {
  translateBreakline,
  translatePassage,
  translateQuestionNumber,
} from '@lib/web/utils';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

import ThemeProvider from '../../../../components/ThemeProvider';

const BREAK_LINES_CLASS = 'break-line';

type BlockProps = {
  node: ProseMirrorNode;
  updateAttributes: (attributes: Record<string, any>) => void;
};

export const legacyParagraphBlockDefaultProps = {
  content: {
    default: '',
  },
};

const translateContent = (content: string) => {
  return translateQuestionNumber(
    translateBreakline(translatePassage(content), BREAK_LINES_CLASS)
  );
};

const LegacyParagraph = ({ node }: BlockProps) => {
  return (
    <NodeViewWrapper
      data-content-type={node.type.name}
      className="blockContent"
    >
      <ThemeProvider mode="dark">
        <div
          dangerouslySetInnerHTML={{
            __html: translateContent(node.attrs.content),
          }}
        ></div>
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export type LegacyParagraphOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: LegacyParagraphOptions;
    node: ProseMirrorNode;
  }) => string;
};

export const LegacyParagraphBlock = Node.create<LegacyParagraphOptions>({
  name: BasicBlockTypes.LegacyParagraph,
  group: 'blockContent',
  inline: false,
  selectable: false,
  atom: true,

  addAttributes() {
    return legacyParagraphBlockDefaultProps;
  },

  parseHTML() {
    return [
      {
        tag: 'legacy-paragraph',
      },
    ];
  },

  renderHTML({ HTMLAttributes: { content, ...rest } }) {
    const parser = new DOMParser();
    const htmlContent = translateContent(content);

    return [
      'div',
      mergeAttributes(rest, {
        'data-content-type': this.name,
      }),
      parser.parseFromString(htmlContent, 'text/html').body.firstChild,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(LegacyParagraph);
  },
});

export default LegacyParagraphBlock;
