import { ReactNode, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  OtherCheckedSquareSolid as OtherCheckedSquareSolidIcon,
  OtherCheckSquareOutline as OtherCheckSquareOutlineIcon,
} from '@front/icon';

import { Button } from '../../../atoms';
import BaseSnackbar, { BaseSnackbarType } from '../BaseSnackbar';

export type MaybeDontShowAgainSnackbarProps = {
  sx?: BoxProps['sx'];
  message: ReactNode;
  type?: BaseSnackbarType;
  onClose?: () => void;
  confirmText?: string;
  onConfirm?: (val: boolean) => void;
};

const styles = {
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mt: 0.5,
    cursor: 'pointer',
    typography: 'caption',
  },
};

type CheckboxProps = { checked: boolean };
function Checkbox({ checked }: CheckboxProps) {
  return checked ? (
    <OtherCheckedSquareSolidIcon width={16} height={16} />
  ) : (
    <OtherCheckSquareOutlineIcon width={16} height={16} />
  );
}

export default function MaybeDontShowAgainSnackbar({
  sx,
  type = 'primary',
  message,
  confirmText = 'Confirm',
  onConfirm,
  ...rest
}: MaybeDontShowAgainSnackbarProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const [checkboxVal, setCheckboxVal] = useState(false);

  const toggleCheckbox = () => {
    setCheckboxVal(!checkboxVal);
  };

  const handleConfirm = () => {
    onConfirm?.(checkboxVal);
  };

  return (
    <BaseSnackbar
      sx={sxProps}
      type={type}
      message={
        <>
          {message}
          <Box sx={styles.checkboxWrapper} onClick={toggleCheckbox}>
            <Checkbox checked={checkboxVal} />
            <Typography variant="caption">Don’t show again</Typography>
          </Box>
        </>
      }
      {...rest}
      actionComponent={<Button onClick={handleConfirm}>{confirmText}</Button>}
    />
  );
}
