import { useCallback, useRef } from 'react';
import { useLatestValueRef } from '@front/helper';
import { InfiniteHookResponse } from '@lib/web/apis';

import { useBottomScrolled } from './index';

const EMPTY_ARRAY: any[] = [];
export default function useInfiniteScroll({
  infiniteHookResponse,
  enabled = true,
  scrollEl = null,
  dependencies = EMPTY_ARRAY,
}: {
  infiniteHookResponse: Pick<
    InfiniteHookResponse<unknown>,
    'isLoadingMore' | 'isReachingEnd' | 'setSize' | 'error'
  >;
  enabled?: boolean;
  scrollEl?: HTMLDivElement | null;
  dependencies?: any[];
}) {
  const { isLoadingMore, isReachingEnd, setSize, error } = infiniteHookResponse;

  const disableLoadMore = useLatestValueRef(
    isLoadingMore || isReachingEnd || !!error || enabled === false
  );

  const setSizeRef = useLatestValueRef(setSize);

  const scrollRef = useRef<HTMLDivElement | null>(scrollEl);
  const loaded = !isLoadingMore;

  const handleScrollBottom = useCallback(() => {
    if (!disableLoadMore.current) {
      void setSizeRef.current((s) => s + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, ...dependencies]);

  useBottomScrolled({
    scrollRef,
    onScrolledBottom: handleScrollBottom,
  });

  return {
    scrollRef,
  };
}
