export default function MainSetGoalQuestionsAnswered({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.97021 12.0592C6.97021 14.935 9.30151 17.2663 12.1773 17.2663C15.0531 17.2663 17.3844 14.935 17.3844 12.0592C17.3844 9.18335 15.0531 6.85205 12.1773 6.85205C9.30151 6.85205 6.97021 9.18335 6.97021 12.0592ZM15.8223 12.0592C15.8223 14.0722 14.1904 15.7041 12.1773 15.7041C10.1643 15.7041 8.53234 14.0722 8.53234 12.0592C8.53234 10.0461 10.1643 8.41418 12.1773 8.41418C14.1904 8.41418 15.8223 10.0461 15.8223 12.0592Z"
        fill="currentColor"
        fillOpacity="0.3"
      />
      <path
        d="M4.54443 12.0001C4.54443 16.2157 7.9619 19.6332 12.1776 19.6332C16.3932 19.6332 19.8107 16.2157 19.8107 12.0001C19.8107 7.78442 16.3932 4.36694 12.1776 4.36694C7.9619 4.36694 4.54443 7.78442 4.54443 12.0001ZM18.2841 12.0001C18.2841 15.3726 15.5501 18.1066 12.1776 18.1066C8.80504 18.1066 6.07106 15.3726 6.07106 12.0001C6.07106 8.62755 8.80504 5.89357 12.1776 5.89357C15.5501 5.89357 18.2841 8.62755 18.2841 12.0001Z"
        fill="currentColor"
        fillOpacity="0.3"
      />
      <path
        d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
        fill="#0072DD"
      />
    </svg>
  );
}
