import React from 'react';

export default function ChatThread({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.00002 12.0526C3.00002 7.61235 7.03742 4 12 4C16.9626 4 21 7.61235 21 12.0526C21 16.4929 16.9626 20.1053 12 20.1053C10.7211 20.1053 9.48632 19.8696 8.32712 19.4047C6.28562 20.5762 3.46322 20.98 3.34172 20.997C3.32762 20.9991 3.31382 21 3.30002 21C3.18752 21 3.08252 20.9371 3.03152 20.8348C2.97392 20.7202 2.99672 20.5816 3.08792 20.4909C3.77792 19.8052 4.25312 18.3578 4.43042 16.4082C3.49382 15.1082 3.00002 13.6047 3.00002 12.0526ZM8.17499 8.92105C7.8022 8.92105 7.49999 9.22149 7.49999 9.59211C7.49999 9.96272 7.8022 10.2632 8.17499 10.2632H15.825C16.1978 10.2632 16.5 9.96272 16.5 9.59211C16.5 9.22149 16.1978 8.92105 15.825 8.92105H8.17499ZM8.17499 11.6053C7.8022 11.6053 7.49999 11.9057 7.49999 12.2763C7.49999 12.6469 7.8022 12.9474 8.17499 12.9474H15.825C16.1978 12.9474 16.5 12.6469 16.5 12.2763C16.5 11.9057 16.1978 11.6053 15.825 11.6053H8.17499ZM8.17499 14.2895C7.8022 14.2895 7.49999 14.5899 7.49999 14.9605C7.49999 15.3311 7.8022 15.6316 8.17499 15.6316H12.675C13.0478 15.6316 13.35 15.3311 13.35 14.9605C13.35 14.5899 13.0478 14.2895 12.675 14.2895H8.17499Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
