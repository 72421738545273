import { getLuminance } from '@mui/material/styles';

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function getContrast(bgcolor: string) {
  const luminance = getLuminance(bgcolor);
  return luminance > 0.5 ? '#080808' : 'white';
}

export function getNameAbbr(name: string) {
  const nameArr = name.split(' ');
  return (
    nameArr.length < 2
      ? nameArr[0].slice(0, 1)
      : `${nameArr[0][0]}${nameArr[1]?.[0] || ''}`
  ).toUpperCase();
}

export function stringAvatar(
  name: string,
  bgcolor?: string,
  textColor?: string
) {
  const backgroundColor = bgcolor || stringToColor(name);
  const color = textColor || getContrast(backgroundColor);
  return {
    sx: {
      backgroundColor,
      color,
    },
    children: getNameAbbr(name),
  };
}

export function getFontSize(size: number) {
  if (size > 100) return 45;
  if (size > 79) return 30;
  if (size > 63) return 24;
  if (size > 39) return 20;
  if (size > 31) return 14;
  if (size > 16) return 12;
  return size / 2;
}

export function getBorderWidth(size: number) {
  if (size > 79) return 3;
  if (size > 63) return 2;
  if (size > 31) return 1.5;
  return 1;
}
