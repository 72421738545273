import React from 'react';
import { Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { SmallIconListLayoutDetailedIconList } from '@lib/ia/src/layouts/SmallIconListLayout/types';

import DetailedIconListItem from './DetailedIconListItem';

export type AreaDetailedIconListProps = SmallIconListLayoutDetailedIconList;

const styles = {
  title: {
    px: { xs: 2.5, md: 1.5 },
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
  loading: {
    px: { xs: 2.5, md: 1.5 },
  },
};

export default function AreaDetailedIconList({
  title,
  items,
  isLoading,
}: AreaDetailedIconListProps) {
  return (
    <Box>
      <Typography variant="caption" sx={styles.title}>
        {title}
      </Typography>

      {isLoading ? (
        <Box sx={styles.loading}>
          <Skeleton height={32} />
          <Skeleton height={32} />
          <Skeleton height={32} width="70%" />
        </Box>
      ) : (
        items.map((item) => <DetailedIconListItem key={item.id} item={item} />)
      )}
    </Box>
  );
}
