import { useLayoutEffect } from 'react';

export default function useBodyClass(isAdd: any, className = '') {
  useLayoutEffect(() => {
    if (isAdd) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
    return () => {
      document.body.classList.remove(className);
    };
  }, [isAdd, className]);
}
