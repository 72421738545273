import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { Box, BoxProps } from '@mui/material';
import { useDebounce, useDimension } from '@front/helper';
import { LineNumberWrapValue, useLineNumbers } from '@lib/web/ui';

function getStyles(el: Element) {
  return window.getComputedStyle(el);
}

const styles = {
  lines: {
    position: 'absolute',
    left: 0,
    width: 35,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  content: {
    ml: '35px',
  },
  line: {
    position: 'absolute',
    typography: 'body1',
    opacity: 0.5,
    width: 24,
    textAlign: 'right',
  },
};

function estimateLines(element: HTMLDivElement) {
  const eleStyles = getStyles(element);
  const fontSize = parseInt(eleStyles.fontSize);
  const lineHeight =
    eleStyles.lineHeight === 'auto'
      ? fontSize * 1.5
      : parseInt(eleStyles.lineHeight);
  const paddingTop = parseInt(eleStyles.paddingTop);
  const paddingBottom = parseInt(eleStyles.paddingBottom);

  const containerHeight = element.offsetHeight - paddingTop - paddingBottom;
  const lines = Math.ceil(containerHeight / lineHeight);

  return { lines, lineHeight };
}

type LineNumberWrapProps = {
  children: ReactNode;
  lineNumberGroup: number | string;
  targetSelector: string;
  labelSx?: BoxProps['sx'];
  isShowPassageLineNum?: boolean;
};
export default function LineNumberWrap({
  children,
  targetSelector,
  isShowPassageLineNum,
  lineNumberGroup,
  labelSx,
}: LineNumberWrapProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { height } = useDimension(contentRef);
  const { setLineNumbers } = useLineNumbers();
  const debounceHeight = useDebounce(height);
  const lineNumberSettings = useMemo(() => {
    let num = 0;
    const numbersValues: LineNumberWrapValue = [];

    if (contentRef.current) {
      const allTarget =
        contentRef.current.querySelectorAll<HTMLDivElement>(targetSelector);

      allTarget.forEach((target) => {
        const { lines, lineHeight } = estimateLines(target);

        for (let i = 0; i < lines; i++) {
          num += 1;
          numbersValues.push({
            top:
              i * lineHeight +
              parseInt(getStyles(target).paddingTop) +
              target.offsetTop,
            lineHeight,
            num,
          });
        }
      });
    }

    return numbersValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceHeight, targetSelector]);

  const labelSxProps = Array.isArray(labelSx) ? labelSx : [labelSx];

  useEffect(() => {
    setLineNumbers(lineNumberSettings);
  }, [lineNumberSettings, setLineNumbers]);

  return (
    <Box position="relative">
      {isShowPassageLineNum && (
        <Box sx={styles.lines}>
          {lineNumberSettings.map((setting) => {
            return (
              <Box
                key={setting.num}
                className="line-number"
                sx={[
                  styles.line,
                  {
                    top: setting.top,
                    lineHeight: `${setting.lineHeight}px`,
                    opacity:
                      +lineNumberGroup === 1 ||
                      setting.num % +lineNumberGroup === 0
                        ? 0.5
                        : 0,
                  },
                  ...labelSxProps,
                ]}
              >
                {setting.num}
              </Box>
            );
          })}
        </Box>
      )}
      <Box
        ref={contentRef}
        sx={[isShowPassageLineNum === true && styles.content]}
      >
        {children}
      </Box>
    </Box>
  );
}
