import { Box } from '@mui/material';
import { Icon } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { styles } from '../styles';
import { WorkflowItem, WorkflowLayoutConfig } from '../types';

import WorkflowCard from './WorkflowCard';

export default function WorkflowViewMode({
  items = [],
  settings,
}: WorkflowLayoutConfig) {
  const { getIaAction } = useIaAction();

  const handleSelect = (item: WorkflowItem): void => {
    const action = getIaAction<{ item: WorkflowItem }>(
      settings.actionMap?.select?.value
    );
    if (!action) return;
    action.action({ item });
  };

  return (
    <Box sx={styles.root}>
      {items.map((item, index) => (
        <Box key={item.id} sx={styles.workflowItem}>
          <WorkflowCard
            item={item}
            index={index}
            mode="view"
            settings={settings}
            onSelect={() => handleSelect(item)}
          />
          {item.id !== items[items.length - 1].id && (
            <Box sx={styles.arrow}>
              <Icon name="ActionArrowDown" />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
