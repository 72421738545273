import React from 'react';

export default function EditorInsertBelow({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.5 8V12H17.67L14.09 8.41L15.5 7L21.5 13L15.5 19L14.09 17.59L17.67 14H2.5V8H4.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
