import React from 'react';

export default function EditorSummarize({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.0015 15.2273L5.2288 20L4 18.7712L8.77274 13.9985H5.30952V12.2607H11.7393V18.6905H10.0015V15.2273ZM15.2273 10.0015H18.6905V11.7393H12.2607V5.30952H13.9985V8.77274L18.7712 4L20 5.2288L15.2273 10.0015Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
