import { useRouter } from 'next/router';
import useCloudfront from '@app/web/src/hooks/utils/useCloudfront';

export default function useAuthLinks({
  loginReturnTo,
  signUpQueries,
  loginQueries,
}: {
  loginReturnTo?: string;
  signUpQueries?: string[];
  loginQueries?: string[];
} = {}) {
  const { country, timezone } = useCloudfront();
  const { query } = useRouter();
  const clubSlug = query.clubSlug || query.slug;
  const authQuery = [
    country ? `country=${country}` : '',
    timezone ? `timezone=${timezone}` : '',
    clubSlug ? `clubSlug=${clubSlug}` : '',
  ];

  // Signup "returnTo" will be defined in the callback function.
  const signUpQueryString = authQuery
    .concat([
      query.refer ? `refer=${query.refer}` : '',
      ...(signUpQueries || []),
    ])
    .filter(Boolean)
    .join('&');

  const loginQueryString = authQuery
    .concat([
      loginReturnTo ? `returnTo=${encodeURIComponent(loginReturnTo)}` : '', // The default will redirect to the last core page stored in the cache.
      ...(loginQueries || []),
    ])
    .filter(Boolean)
    .join('&');

  return {
    loginUrl: `/api/auth/login?${loginQueryString}`,
    signUpUrl: `/api/auth/signup?${signUpQueryString}`,
  };
}
