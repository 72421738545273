import React from 'react';

export default function ProfileBasicPlan({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.75 21C6.26875 21 5.85663 20.832 5.51363 20.496C5.17063 20.16 4.99942 19.7566 5 19.2857V10.7143C5 10.2429 5.1715 9.83914 5.5145 9.50314C5.8575 9.16714 6.26933 8.99943 6.75 9H7.625V7.28571C7.625 6.1 8.05171 5.08914 8.90513 4.25314C9.75854 3.41714 10.7902 2.99943 12 3C13.2104 3 14.2423 3.418 15.0957 4.254C15.9492 5.09 16.3756 6.10057 16.375 7.28571V9H17.25C17.7312 9 18.1434 9.168 18.4864 9.504C18.8294 9.84 19.0006 10.2434 19 10.7143V19.2857C19 19.7571 18.8285 20.1609 18.4855 20.4969C18.1425 20.8329 17.7307 21.0006 17.25 21H6.75ZM12 16.7143C12.4813 16.7143 12.8934 16.5463 13.2364 16.2103C13.5794 15.8743 13.7506 15.4709 13.75 15C13.75 14.5286 13.5785 14.1249 13.2355 13.7889C12.8925 13.4529 12.4807 13.2851 12 13.2857C11.5188 13.2857 11.1066 13.4537 10.7636 13.7897C10.4206 14.1257 10.2494 14.5291 10.25 15C10.25 15.4714 10.4215 15.8751 10.7645 16.2111C11.1075 16.5471 11.5193 16.7149 12 16.7143ZM9.375 9H14.625V7.28571C14.625 6.57143 14.3698 5.96429 13.8594 5.46429C13.349 4.96429 12.7292 4.71429 12 4.71429C11.2708 4.71429 10.651 4.96429 10.1406 5.46429C9.63021 5.96429 9.375 6.57143 9.375 7.28571V9Z"
        fill="currentColor"
      />
    </svg>
  );
}
