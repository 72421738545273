import React from 'react';

export default function EditorMakeLonger({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.9651 5.77778H12.7901V4H20V11.2099H18.2222V7.03486L7.03486 18.2222H11.2099V20H4V12.7901H5.77778V16.9651L16.9651 5.77778Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
