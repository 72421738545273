import { Box, Theme } from '@mui/material';
import { Icon, MaskIcon } from '@front/ui';

import { AlbumLayoutMaskIconExtensionConfig } from './types';

const styles = {
  root: (outline: boolean) => ({
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
    p: outline ? '2px' : 0,
    background: (theme: Theme) => theme.palette.background.darker,
  }),
};

type AlbumLayoutMaskIconExtensionProps = {
  config: AlbumLayoutMaskIconExtensionConfig['config'];
};
export default function AlbumLayoutMaskIconExtension({
  config,
}: AlbumLayoutMaskIconExtensionProps) {
  const { icon, outline = false, size = 32 } = config;
  return (
    <MaskIcon>
      <Box sx={styles.root(outline)}>
        <Icon name={icon} width={size} height={size} />
      </Box>
    </MaskIcon>
  );
}
