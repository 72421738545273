import React from 'react';
import { LightbulbCard } from '@front/ui';

export type EditorSolutionLightbulbProps = {
  title?: string;
  description: string;
};

export default function EditorSolutionLightbulb({
  title,
  description,
}: EditorSolutionLightbulbProps) {
  return <LightbulbCard title={title}>{description}</LightbulbCard>;
}
