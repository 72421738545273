import { forwardRef } from 'react';
import { IconButtonTypeMap } from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconButton, IconButtonProps, SimpleTooltip } from '@front/ui';

const styles = {
  hideTooltip: {
    display: 'none',
  },
};

type TipButtonProps<
  D extends React.ElementType = IconButtonTypeMap['defaultComponent']
> = IconButtonProps<
  D,
  {
    title: string;
    customSize?: number;
    selected?: boolean;
    loading?: boolean;
    hideTooltip?: boolean;
  }
>;

function TipButtonInner<D extends IconButtonTypeMap['defaultComponent']>(
  { sx, title, children, disabled, hideTooltip, ...rest }: TipButtonProps<D>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const button = (
    <IconButton
      ref={ref}
      sx={sx}
      customSize={mdDown ? 28 : 24}
      disabled={disabled}
      {...rest}
    >
      {children}
    </IconButton>
  );

  if (mdDown || disabled) return button;
  return (
    <SimpleTooltip
      title={title}
      slotProps={{
        popper: {
          sx: [!!hideTooltip && styles.hideTooltip],
        },
      }}
    >
      {button}
    </SimpleTooltip>
  );
}
const TipButton = forwardRef<HTMLButtonElement, TipButtonProps>(
  TipButtonInner
) as <D extends React.ElementType = IconButtonTypeMap['defaultComponent']>(
  props: TipButtonProps<D> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof TipButtonInner>;

export default TipButton;
