import { useMemo } from 'react';
import {
  ThreadViewStatus,
  ThreadViewType,
  ThreadViewUserStatus,
  useAuth,
} from '@lib/web/apis';

import { generateClubViewConfig } from '../../utils/viewUtils';
import { getDefaultThreadViewUsersId } from '../view/utils';

export const useDefaultClubAllThreadView = ({
  clubId,
}: {
  clubId?: string;
}): GetThreadViewRes => {
  const { member } = useAuth();

  return useMemo(() => {
    const myMemberId = member?.memberId || '';
    const now = new Date().toISOString();
    const { filterConfig, sortConfig } = generateClubViewConfig({
      type: ThreadViewType.ClubAllThread,
      clubId: clubId || '',
      selfMemberId: myMemberId,
    });

    return {
      type: ThreadViewType.ClubAllThread,
      id: `all-thread-${clubId}`,
      status: ThreadViewStatus.Active,
      createdAt: now,
      updatedAt: now,
      referenceId: null,
      name: null,
      emoji: null,
      sourceId: null,
      clubId: clubId || null,
      hideAt: null,
      threadViewUsers: [
        {
          id: getDefaultThreadViewUsersId(clubId),
          createdAt: now,
          customEmoji: null,
          customName: null,
          filterConfig: filterConfig || '',
          sortConfig: sortConfig || '',
          memberId: myMemberId,
          status: ThreadViewUserStatus.Active,
          updatedAt: now,
        },
      ],
    };
  }, [clubId, member?.memberId]);
};
