import { useExamBySection } from '@lib/web/apis';

import { useCurrentIaClub } from './club';

export default function useCurrentExam(clubSlug?: string) {
  const { sectionId } = useCurrentIaClub(clubSlug);
  const { data, isLoading } = useExamBySection(sectionId);

  return {
    isLoading,
    sectionId,
    examinationId: data?.data.examinationId ?? null,
    exam: data?.data,
    sections: data?.data.sections,
    section: data?.data.sections.find(
      (section) => section.sectionExamId === sectionId
    ),
  };
}
