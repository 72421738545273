import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PropertyType } from '@front/ui';
import { FilterFieldsConfig } from '@lib/ia/src/filter/types';
import {
  ChallengeCategoryStatus,
  ClubJoinedStatus,
  ClubOfficialType,
  ClubStatus,
} from '@lib/web/apis';
import { answerFormatConfig } from '@lib/web/editor/configs/answerFormats';
import { TFunction } from 'i18next';
import { sortBy } from 'lodash';

import { FilterType } from '../../../../types/filter';

const getGlobalFilterConfigs: (
  t: TFunction
) => Record<FilterType, FilterFieldsConfig | null> = (t: TFunction) => ({
  club: {
    fields: [
      {
        label: t('filter.recommended.label'),
        name: 'isRecommended',
        type: PropertyType.Boolean,
      },
      {
        label: t('filter.clubName.label'),
        name: 'clubName',
        type: PropertyType.ShortText,
        placeholder: t('filter.clubName.placeholder'),
      },
      {
        label: t('filter.clubStatus.label'),
        name: 'clubStatus',
        type: PropertyType.Status,
        placeholder: t('filter.clubStatus.placeholder'),
        options: [
          {
            label: t('filter.clubStatus.options.active'),
            value: ClubStatus.Active,
            color: 'success',
          },
          {
            label: t('filter.clubStatus.options.comingSoon'),
            value: ClubStatus.ComingSoon,
            color: 'default',
          },
          {
            label: t('filter.clubStatus.options.beta'),
            value: ClubStatus.Beta,
            color: 'warning',
          },
        ],
      },
      {
        label: t('filter.joinStatus.label'),
        name: 'joinStatus',
        type: PropertyType.Status,
        placeholder: t('filter.joinStatus.placeholder'),
        options: [
          {
            label: t('filter.joinStatus.options.joined'),
            value: ClubJoinedStatus.Joined,
            color: 'success',
          },
          {
            label: t('filter.joinStatus.options.notJoined'),
            value: ClubJoinedStatus.NotJoined,
            color: 'default',
          },
        ],
      },
      {
        label: t('filter.answerFormatType.label'),
        name: 'answerFormatType',
        type: PropertyType.MultiSelect,
        excludeOperators: ['IsEmpty'],
        placeholder: t('filter.answerFormatType.placeholder'),
        options: sortBy(
          Object.entries(answerFormatConfig).map(([key, value]) => ({
            label: value.text,
            value: Number(key),
          })),
          (option) => option.label
        ),
      },
      {
        label: t('filter.clubCreator.label'),
        name: 'clubCreator:clubCreatorSourceId',
        type: PropertyType.Person,
        placeholder: t('filter.clubCreator.placeholder'),
      },
      {
        label: t('filter.clubType.label'),
        name: 'officialType',
        type: PropertyType.Select,
        customIcon: 'PropertyTypeStatus',
        options: [
          {
            label: t('filter.clubType.options.official'),
            value: ClubOfficialType.Official,
          },
          {
            label: t('filter.clubType.options.nonOfficial'),
            value: ClubOfficialType.NonOfficial,
          },
        ],
      },
    ],
  },
  clubGroup: {
    fields: [
      {
        label: t('filter.recommended.label'),
        name: 'isRecommended',
        type: PropertyType.Boolean,
      },
      {
        label: t('filter.clubGroupName.label'),
        name: 'name',
        type: PropertyType.ShortText,
      },
      {
        label: t('filter.joinStatus.label'),
        name: 'joinedStatus',
        type: PropertyType.Status,
        placeholder: t('filter.joinStatus.placeholder'),
        options: [
          {
            label: t('filter.joinStatus.options.joined'),
            value: ClubJoinedStatus.Joined,
            color: 'success',
          },
          {
            label: t('filter.joinStatus.options.notJoined'),
            value: ClubJoinedStatus.NotJoined,
            color: 'default',
          },
        ],
      },
    ],
  },
  agent: {
    fields: [
      {
        label: t('filter.agentName.label'),
        name: 'agentName',
        type: PropertyType.ShortText,
        placeholder: t('filter.agentName.placeholder'),
      },
      {
        label: t('filter.agentUserName.label'),
        name: 'agentUserName',
        type: PropertyType.ShortText,
        placeholder: t('filter.agentUserName.placeholder'),
      },
      {
        label: t('filter.shortDesc.label'),
        name: 'shortDesc',
        type: PropertyType.ShortText,
        placeholder: t('filter.shortDesc.placeholder'),
      },
      {
        label: t('filter.longDesc.label'),
        name: 'longDesc',
        type: PropertyType.LongText,
        placeholder: t('filter.longDesc.placeholder'),
      },
      {
        label: t('filter.userCount.label'),
        name: 'userCount',
        type: PropertyType.Number,
      },
      {
        label: t('filter.threadCount.label'),
        name: 'threadCount',
        type: PropertyType.Number,
      },
      {
        label: t('filter.agentCreator.label'),
        name: 'agentCreator',
        type: PropertyType.Person,
        placeholder: t('filter.agentCreator.placeholder'),
      },
    ],
  },
  playlist: {
    fields: [
      {
        label: t('filter.answerResult.label'),
        name: 'practiceResult',
        type: PropertyType.Select,
        customIcon: 'TextEditorCheck',
        options: [
          {
            label: t('filter.answerResult.options.skipped'),
            value: 0,
          },
          {
            label: t('filter.answerResult.options.correct'),
            value: 1,
          },
          {
            label: t('filter.answerResult.options.mistake'),
            value: -1,
          },
        ],
      },
      {
        label: t('filter.overtimeStatus.label'),
        name: 'overtimeStatus',
        type: PropertyType.Status,
        options: [
          {
            label: t('filter.overtimeStatus.options.overtime'),
            value: -1,
            color: 'warning',
          },
          {
            label: t('filter.overtimeStatus.options.notOvertime'),
            value: 1,
            color: 'default',
          },
        ],
      },
      {
        label: t('filter.questionNumber.label'),
        name: 'questionNumber',
        type: PropertyType.Number,
      },
      {
        label: t('filter.questionContent.label'),
        name: 'questionContent',
        type: PropertyType.LongText,
      },
      {
        label: t('filter.reaction.label'),
        name: 'emojiCode',
        type: PropertyType.Icon,
      },
    ],
  },
  playlistDetailRhs: {
    fields: [
      {
        label: t('filter.answerResult.label'),
        name: 'practiceResult',
        type: PropertyType.Select,
        customIcon: 'TextEditorCheck',
        options: [
          {
            label: t('filter.answerResult.options.skipped'),
            value: 0,
          },
          {
            label: t('filter.answerResult.options.correct'),
            value: 1,
          },
          {
            label: t('filter.answerResult.options.mistake'),
            value: -1,
          },
        ],
      },
      {
        label: t('filter.overtimeStatus.label'),
        name: 'overtimeStatus',
        type: PropertyType.Status,
        options: [
          {
            label: t('filter.overtimeStatus.options.overtime'),
            value: -1,
            color: 'warning',
          },
          {
            label: t('filter.overtimeStatus.options.notOvertime'),
            value: 1,
            color: 'default',
          },
        ],
      },
      {
        label: t('filter.questionNumber.label'),
        name: 'questionNumber',
        type: PropertyType.Number,
      },
      {
        label: t('filter.questionContent.label'),
        name: 'questionContent',
        type: PropertyType.LongText,
      },
    ],
  },
  marketplace: {
    fields: [
      {
        label: t('filter.emoji.label'),
        name: 'emojiCode',
        type: PropertyType.Icon,
      },
      {
        label: t('filter.price.label'),
        name: 'price',
        type: PropertyType.Number,
        customIcon: 'OtherDollar',
      },
    ],
  },
  quiz: {
    fields: [
      {
        label: t('filter.quizName.label'),
        name: 'name',
        type: PropertyType.ShortText,
        placeholder: t('filter.quizName.placeholder'),
      },
      {
        label: t('filter.quizContent.label'),
        name: 'content',
        type: PropertyType.LongText,
      },
      {
        label: t('filter.quizTokenNum.label'),
        name: 'tokenNum',
        type: PropertyType.Number,
      },
      {
        label: t('filter.joinedStatus.label'),
        name: 'joinedStatus',
        type: PropertyType.Status,
        placeholder: t('filter.joinedStatus.placeholder'),
        options: [
          {
            label: t('filter.joinedStatus.options.joined'),
            value: 1,
            color: 'success',
          },
          {
            label: t('filter.joinedStatus.options.notJoined'),
            value: -1,
            color: 'default',
          },
        ],
      },
    ],
  },
  creatorQuiz: {
    fields: [
      {
        label: t('filter.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('filter.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('filter.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
    ],
  },
  emojiQuiz: {
    fields: [
      {
        label: t('filter.playlistReaction.label'),
        name: 'emoji',
        type: PropertyType.Icon,
        customIcon: 'EmojiFavorite',
        excludeOperators: [
          'Contains',
          'DoesNotContains',
          'IsEmpty',
          'IsNotEmpty',
        ],
        extensionOperators: ['Eq'],
      },
      {
        label: t('filter.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('filter.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('filter.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
    ],
  },
  hashtagQuiz: {
    fields: [
      {
        label: t('filter.playlistName.label'),
        name: 'hashtag',
        type: PropertyType.ShortText,
      },
      {
        label: t('filter.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('filter.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('filter.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
    ],
  },
  followingQuiz: {
    fields: [
      {
        label: t('filter.playlistName.label'),
        name: 'name',
        type: PropertyType.ShortText,
      },
      {
        label: t('filter.createdBy.label'),
        name: 'owner',
        type: PropertyType.Person,
      },
      {
        label: t('filter.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.score.label'),
        name: 'name',
        type: PropertyType.Number,
        customIcon: 'TestScoreTally',
      },
      {
        label: t('filter.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('filter.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('filter.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
      {
        label: t('filter.challengeStatus.label'),
        name: 'challengeInfo:category',
        type: PropertyType.Status,
        excludeOperators: ['Is', 'IsNot'],
        options: [],
      },
    ],
  },
  historyQuiz: {
    fields: [
      {
        label: t('filter.playlistName.label'),
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: t('filter.createdBy.label'),
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: t('filter.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.mode.label'),
        name: 'mode',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: t('filter.mode.options.practice') },
          { value: 2, label: t('filter.mode.options.mockExam') },
        ],
      },
      {
        label: t('filter.playlistType.label'),
        name: 'type',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        excludeOperators: [
          'IsEmpty',
          'IsNotEmpty',
          'IsSelectEqual',
          'IsSelectNotEqual',
          'IsSelectIn',
        ],
        extensionOperators: ['Eq'],
        options: [
          {
            value: 10,
            label: t('filter.playlistType.options.notChallenge'),
          },
          { value: 4, label: t('filter.playlistType.options.challenge') },
        ],
      },
      {
        label: t('filter.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('filter.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('filter.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
      {
        label: t('filter.savedStatus.label'),
        name: 'savedStatus',
        type: PropertyType.Status,
        options: [
          {
            value: 1,
            label: t('filter.savedStatus.options.saved'),
            color: 'blue',
          },
          {
            value: -1,
            label: t('filter.savedStatus.options.unsaved'),
            color: 'default',
          },
        ],
      },
      {
        label: t('filter.challengeStatus.label'),
        name: 'challengeInfo:category',
        type: PropertyType.Status,
        options: [
          {
            value: 2,
            label: t('filter.challengeStatus.options.ongoing'),
            color: 'green',
          },
          {
            value: -1,
            label: t('filter.challengeStatus.options.expired'),
            color: 'default',
          },
        ],
      },
      {
        label: t('filter.challengeAttempts.label'),
        name: 'challengeInfo:attempts',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
          { value: 5, label: '5' },
          {
            value: -1,
            label: t('filter.challengeAttempts.options.unlimited'),
          },
        ],
      },
      {
        label: t('filter.challengeEndDate.label'),
        name: 'challengeInfo:endDate',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: t('filter.challengers.label'),
        name: 'challengeInfo:challengers',
        type: PropertyType.Person,
      },
      {
        label: t('filter.challengeRank.label'),
        name: 'challengeInfo:rank',
        type: PropertyType.Number,
        customIcon: 'TestTrophy',
      },
      {
        label: t('filter.isCompleted.label'),
        name: 'isCompleted',
        type: PropertyType.Boolean,
      },
    ],
  },
  challengeQuiz: {
    fields: [
      {
        label: t('filter.playlistName.label'),
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: t('filter.createdBy.label'),
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: t('filter.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.mode.label'),
        name: 'mode',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: t('filter.mode.options.practice') },
          { value: 2, label: t('filter.mode.options.mockExam') },
        ],
      },
      {
        label: t('filter.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('filter.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('filter.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
      {
        label: t('filter.savedStatus.label'),
        name: 'savedStatus',
        type: PropertyType.Status,
        options: [
          {
            value: 1,
            label: t('filter.savedStatus.options.saved'),
            color: 'blue',
          },
          {
            value: -1,
            label: t('filter.savedStatus.options.unsaved'),
            color: 'default',
          },
        ],
      },
      {
        label: t('filter.challengeAttempts.label'),
        name: 'challengeInfo:attempts',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
          { value: 5, label: '5' },
          {
            value: -1,
            label: t('filter.challengeAttempts.options.unlimited'),
          },
        ],
      },
      {
        label: t('filter.challengeEndDate.label'),
        name: 'endDate',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: t('filter.challengers.label'),
        name: 'challengers',
        type: PropertyType.Person,
      },
      {
        label: t('filter.challengeRank.label'),
        name: 'rank',
        type: PropertyType.Number,
        customIcon: 'TestTrophy',
      },
      {
        label: t('filter.challengeStatus.label'),
        name: 'category',
        type: PropertyType.Select,
        options: [
          {
            value: ChallengeCategoryStatus.OpenChallenge,
            label: t('filter.challengeStatus.options.open'),
          },
          {
            value: ChallengeCategoryStatus.Invited,
            label: t('filter.challengeStatus.options.invited'),
          },
          {
            value: ChallengeCategoryStatus.Ongoing,
            label: t('filter.challengeStatus.options.accepted'),
          },
          {
            value: ChallengeCategoryStatus.Expired,
            label: t('filter.challengeStatus.options.expired'),
          },
        ],
      },
    ],
  },
  bookmarkQuiz: {
    fields: [
      {
        label: t('filter.playlistName.label'),
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: t('filter.createdBy.label'),
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: t('filter.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('filter.mode.label'),
        name: 'mode',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: t('filter.mode.options.practice') },
          { value: 2, label: t('filter.mode.options.mockExam') },
        ],
      },
      {
        label: t('filter.playlistType.label'),
        name: 'type',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        excludeOperators: [
          'IsEmpty',
          'IsNotEmpty',
          'IsSelectEqual',
          'IsSelectNotEqual',
          'IsSelectIn',
        ],
        extensionOperators: ['Eq'],
        options: [
          {
            value: 10,
            label: t('filter.playlistType.options.notChallenge'),
          },
          { value: 4, label: t('filter.playlistType.options.challenge') },
        ],
      },
      {
        label: t('filter.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('filter.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('filter.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
      {
        label: t('filter.savedStatus.label'),
        name: 'savedStatus',
        type: PropertyType.Status,
        options: [
          {
            value: 1,
            label: t('filter.savedStatus.options.saved'),
            color: 'blue',
          },
          {
            value: -1,
            label: t('filter.savedStatus.options.unsaved'),
            color: 'default',
          },
        ],
      },
      {
        label: t('filter.challengeStatus.label'),
        name: 'challengeInfo:status',
        type: PropertyType.Status,
        options: [
          {
            value: 2,
            label: t('filter.challengeStatus.options.ongoing'),
            color: 'green',
          },
          {
            value: -1,
            label: t('filter.challengeStatus.options.expired'),
            color: 'default',
          },
        ],
      },
      {
        label: t('filter.challengeAttempts.label'),
        name: 'challengeInfo:attempts',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
          { value: 5, label: '5' },
          {
            value: -1,
            label: t('filter.challengeAttempts.options.unlimited'),
          },
        ],
      },
      {
        label: t('filter.challengeEndDate.label'),
        name: 'challengeInfo:endDate',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: t('filter.challengers.label'),
        name: 'challengeInfo:challengers',
        type: PropertyType.Person,
      },
      {
        label: t('filter.challengeRank.label'),
        name: 'challengeInfo:rank',
        type: PropertyType.Number,
        customIcon: 'TestTrophy',
      },
    ],
  },
  playlistQuiz: {
    fields: [
      {
        label: t('filter.playlistName.label'),
        name: 'playlistName',
        type: PropertyType.ShortText,
        placeholder: t('filter.playlistName.placeholder'),
      },
      {
        label: t('filter.questionTokens.label'),
        name: 'questionTokenNum',
        type: PropertyType.Number,
      },
      {
        label: t('filter.joinedStatus.label'),
        name: 'joinedStatus',
        type: PropertyType.Status,
        placeholder: t('filter.joinedStatus.placeholder'),
        options: [
          {
            label: t('filter.joinedStatus.options.joined'),
            value: 1,
            color: 'success',
          },
          {
            label: t('filter.joinedStatus.options.notJoined'),
            value: -1,
            color: 'default',
          },
        ],
      },
    ],
  },
  discountCodeManagement: {
    fields: [
      {
        label: t('filter.discountCode.label'),
        name: 'code',
        type: PropertyType.ShortText,
      },
      {
        label: t('filter.discountType.label'),
        name: 'discountType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: t('filter.discountType.options.percentOff') },
          { value: 2, label: t('filter.discountType.options.amountOff') },
        ],
      },
      {
        label: t('filter.value.label'),
        name: 'discountVal',
        type: PropertyType.Number,
      },
      {
        label: t('filter.usageType.label'),
        name: 'usageType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          {
            value: -1,
            label: t('filter.usageType.options.unlimited'),
          },
          { value: 1, label: t('filter.usageType.options.limited') },
        ],
      },
      {
        label: t('filter.usageLimit.label'),
        name: 'redeemLimit',
        type: PropertyType.Number,
      },
      {
        label: t('filter.purchaseType.label'),
        name: 'purchaseType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 0, label: t('filter.purchaseType.options.all') },
          {
            value: 1,
            label: t('filter.purchaseType.options.avatarOnly'),
          },
          {
            value: 2,
            label: t('filter.purchaseType.options.subscriptionOnly'),
          },
        ],
      },
      {
        label: t('filter.startDate.label'),
        name: 'startedAt',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: t('filter.expirationDate.label'),
        name: 'expiredAt',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: t('filter.createdBy.label'),
        name: 'creator:memberId',
        type: PropertyType.Person,
        useMemberId: true,
      },
    ],
  },
  threadChannel: {
    fields: [
      {
        label: t('filter.createdBy.label'),
        name: 'creator',
        type: PropertyType.Person,
        useMemberId: true,
        excludeOperators: [
          'Contains',
          'DoesNotContains',
          'IsEmpty',
          'IsNotEmpty',
        ],
        extensionOperators: ['Is'],
      },
      {
        label: t('filter.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
        excludeOperators: [
          'IsBetweenTime',
          'IsRelativeToToday',
          'IsEmpty',
          'IsNotEmpty',
          'IsEqualTime',
        ],
      },
      {
        label: t('filter.visibility.label'),
        name: 'visibility',
        type: PropertyType.Select,
        customIcon: 'LoginSee',
        options: [
          {
            value: 'public',
            label: t('filter.visibility.options.public'),
          },
          {
            value: 'team',
            label: t('filter.visibility.options.private'),
          },
        ],
        excludeOperators: [
          'IsEmpty',
          'IsNotEmpty',
          'IsSelectEqual',
          'IsSelectNotEqual',
          'IsSelectIn',
        ],
        extensionOperators: ['Is'],
      },
      {
        label: t('filter.members.label'),
        name: 'members',
        type: PropertyType.Person,
        useMemberId: true,
        excludeOperators: ['DoesNotContains', 'IsEmpty', 'IsNotEmpty'],
        customIcon: 'PrivacyFriends',
      },
      {
        label: t('filter.activeMembers.label'),
        name: 'customActiveMembers',
        type: PropertyType.Person,
        useMemberId: true,
        excludeOperators: ['DoesNotContains', 'IsEmpty', 'IsNotEmpty'],
        customIcon: 'PrivacyFriends',
      },
      {
        label: t('filter.memberCount.label'),
        name: 'memberCount',
        type: PropertyType.Number,
        excludeOperators: ['Neq', 'IsEmpty', 'IsNotEmpty', 'Between', 'IsIn'],
        customIcon: 'PrivacyFriends',
      },
      {
        label: t('filter.replies.label'),
        name: 'customReplyCount',
        type: PropertyType.Number,
        excludeOperators: [
          'Neq',
          'Gt',
          'Lt',
          'Gte',
          'Lte',
          'IsEmpty',
          'IsNotEmpty',
          'Between',
          'IsIn',
        ],
        customIcon: 'ThreadsThread',
      },
      {
        label: t('filter.lastActivityTime.label'),
        name: 'lastActivityTime',
        type: PropertyType.DateOrTime,
        excludeOperators: [
          'IsBetweenTime',
          'IsRelativeToToday',
          'IsEmpty',
          'IsNotEmpty',
          'IsEqualTime',
        ],
      },
      {
        label: t('filter.threadTag.label'),
        name: 'tag',
        type: PropertyType.Select,
        options: [
          {
            label: t('filter.threadTag.options.solution'),
            value: 'solution',
          },
        ],
      },
    ],
  },
  threadMessage: {
    fields: [
      {
        label: t('filter.messageText.label'),
        name: 'messageText',
        type: PropertyType.ShortText,
        excludeOperators: [
          'Contains',
          'DoesNotContains',
          'IsNot',
          'StartsWith',
          'EndsWith',
          'IsEmpty',
          'IsNotEmpty',
        ],
      },
      {
        label: t('filter.replyCount.label'),
        name: 'replyCount',
        type: PropertyType.Number,
        excludeOperators: ['Neq', 'IsEmpty', 'IsNotEmpty', 'Between', 'IsIn'],
      },
      {
        label: t('filter.user.label'),
        name: 'userId',
        type: PropertyType.Person,
        useMemberId: true,
        excludeOperators: [
          'Contains',
          'DoesNotContains',
          'IsEmpty',
          'IsNotEmpty',
        ],
        extensionOperators: ['Is'],
      },
      {
        label: t('filter.createdTime.label'),
        name: 'createdAt',
        type: PropertyType.DateOrTime,
        excludeOperators: [
          'IsBetweenTime',
          'IsRelativeToToday',
          'IsEmpty',
          'IsNotEmpty',
          'IsEqualTime',
        ],
      },
      {
        label: t('filter.updatedTime.label'),
        name: 'updatedAt',
        type: PropertyType.DateOrTime,
        excludeOperators: [
          'IsBetweenTime',
          'IsRelativeToToday',
          'IsEmpty',
          'IsNotEmpty',
          'IsEqualTime',
        ],
      },
    ],
  },
});

export default function useGlobalFilterConfigs() {
  // NOTE: in the future we can use other hooks here to get dynamic options from API,...
  const { t } = useTranslation();
  return useMemo(() => getGlobalFilterConfigs(t), [t]);
}
