import React from 'react';

export default function ChatThumbsUpSolid({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.60059 12.5999C2.60059 12.3635 2.64714 12.1295 2.7376 11.9111C2.82806 11.6927 2.96065 11.4943 3.12779 11.3271C3.29494 11.16 3.49337 11.0274 3.71176 10.9369C3.93014 10.8465 4.16421 10.7999 4.40059 10.7999C4.63697 10.7999 4.87103 10.8465 5.08942 10.9369C5.3078 11.0274 5.50623 11.16 5.67338 11.3271C5.84052 11.4943 5.97311 11.6927 6.06357 11.9111C6.15403 12.1295 6.20059 12.3635 6.20059 12.5999V19.7999C6.20059 20.2773 6.01094 20.7351 5.67338 21.0727C5.33581 21.4103 4.87798 21.5999 4.40059 21.5999C3.9232 21.5999 3.46536 21.4103 3.12779 21.0727C2.79023 20.7351 2.60059 20.2773 2.60059 19.7999V12.5999ZM7.40059 12.3995V18.9155C7.40038 19.3615 7.52445 19.7987 7.75888 20.1781C7.99331 20.5576 8.32883 20.8641 8.72779 21.0635L8.78779 21.0935C9.45365 21.4263 10.1878 21.5997 10.9322 21.5999H17.4314C17.9864 21.6001 18.5244 21.408 18.9537 21.0561C19.3829 20.7043 19.677 20.2146 19.7858 19.6703L21.2258 12.4703C21.2954 12.1221 21.2868 11.7629 21.2008 11.4184C21.1148 11.074 20.9534 10.7529 20.7283 10.4783C20.5033 10.2037 20.22 9.98254 19.8991 9.83065C19.5782 9.67875 19.2276 9.59994 18.8726 9.5999H14.6006V4.7999C14.6006 4.16338 14.3477 3.55293 13.8976 3.10285C13.4476 2.65276 12.8371 2.3999 12.2006 2.3999C11.8823 2.3999 11.5771 2.52633 11.3521 2.75137C11.127 2.97642 11.0006 3.28164 11.0006 3.5999V4.4003C11.0006 5.43888 10.6637 6.44944 10.0406 7.2803L8.36059 9.5195C7.73744 10.3504 7.40059 11.3609 7.40059 12.3995Z"
        fill="currentColor"
      />
    </svg>
  );
}
