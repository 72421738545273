import { useCreatorQuestionCorrectAnswerData } from '@lib/web/editor';

export const useData = (questionId: string) => {
  const { correctAnswers, isLoadingInitialData, reloadCorrectAnswer } =
    useCreatorQuestionCorrectAnswerData(questionId);

  return {
    isLoadingInitialData,
    componentId: correctAnswers[0]?.componentId || '',
    correctAnswerValue: correctAnswers[0]?.value || '',
    reloadCorrectAnswer,
  };
};
