import { createContext, ReactNode } from 'react';

import { MessageItemConfig } from '../types';

export type ChannelLayoutContextValue = {
  pinMessageItems: MessageItemConfig[];
};

export const ChannelLayoutContext = createContext<ChannelLayoutContextValue>({
  pinMessageItems: [],
});

type ChannelLayoutContextProviderProps = ChannelLayoutContextValue & {
  children: ReactNode;
};

export default function ChannelLayoutContextProvider({
  children,
  ...value
}: ChannelLayoutContextProviderProps) {
  return (
    <ChannelLayoutContext.Provider value={value}>
      {children}
    </ChannelLayoutContext.Provider>
  );
}
