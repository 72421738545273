import React, {
  Dispatch,
  MouseEvent,
  MutableRefObject,
  SetStateAction,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { OtherDelete as OtherDeleteIcon } from '@front/icon';
import { ResponsiveMenu } from '@front/ui';

import { useCreatorQuestionListHandle } from '../../hooks';

export type ToolbarMoreActionDropdownProps = {
  target: MutableRefObject<HTMLElement | null>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export default function ToolbarMoreActionDropdown({
  target,
  open,
  setOpen,
}: ToolbarMoreActionDropdownProps) {
  const { t } = useTranslation('editor');
  const { handleDeletePlaylistClick } = useCreatorQuestionListHandle();

  const menuItems = useMemo(
    () => [
      {
        label: t('Delete Playlist'),
        icon: <OtherDeleteIcon width={16} height={16} />,
        onClick: handleDeletePlaylistClick,
      },
    ],
    [handleDeletePlaylistClick, t]
  );

  const handleMenuClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (onClick: () => void) => {
    return (ev: MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      setOpen(false);
      onClick();
    };
  };

  return (
    <ResponsiveMenu
      open={open}
      onClose={handleMenuClose}
      menuProps={{
        anchorEl: target.current,
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 4,
        },
      }}
    >
      {menuItems?.map(({ label, icon, onClick }) => (
        <MenuItem
          key={label}
          value={label}
          onClick={handleMenuItemClick(onClick)}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      ))}
    </ResponsiveMenu>
  );
}
