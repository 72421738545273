import { ReactNode } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

import DesktopFooter from './components/DesktopFooter';
import DifficultyLevel from './components/DifficultyLevel';
import FooterActions from './components/FooterActions';
import Hint from './components/Hint';
import MobileFooter from './components/MobileFooter';
import Timer from './components/Timer';
import WordLimitInformation from './components/WordLimitInformation';

type QuizFooterProps = {
  desktopExtraContent?: ReactNode;
};

export default function QuizFooter({ desktopExtraContent }: QuizFooterProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (mdUp) return <DesktopFooter extraContent={desktopExtraContent} />;
  return <MobileFooter />;
}

QuizFooter.Timer = Timer;
QuizFooter.Hint = Hint;
QuizFooter.FooterActions = FooterActions;
QuizFooter.WordLimitInformation = WordLimitInformation;
QuizFooter.DifficultyLevel = DifficultyLevel;
