import React from 'react';
import {
  EditorEssay as EditorEssayIcon,
  EditorFillInTheBlank as EditorFillInTheBlankIcon,
  EditorFreeResponse as EditorFreeResponseIcon,
  EditorGridIn as EditorGridInIcon,
  EditorMCQ as EditorMCQIcon,
  EditorMRQ as EditorMRQIcon,
  EditorQuestionGroup as EditorQuestionGroupIcon,
  EditorTFNG as EditorTFNGIcon,
  EditorUnscramble as EditorUnscrambleIcon,
} from '@front/icon';
import { AnswerFormatType } from '@lib/web/apis';

export type AnswerFormatIconProps = {
  answerFormatType: AnswerFormatType;
  width?: number;
  height?: number;
};

export default function AnswerFormatIcon({
  answerFormatType,
  ...props
}: AnswerFormatIconProps) {
  const Icon = {
    [AnswerFormatType.Unknown]: EditorMCQIcon,
    [AnswerFormatType.MultipleChoice]: EditorMCQIcon,
    [AnswerFormatType.TrueFalseNotGiven]: EditorTFNGIcon,
    [AnswerFormatType.Unscramble]: EditorUnscrambleIcon,
    [AnswerFormatType.GridIn]: EditorGridInIcon,
    [AnswerFormatType.FillInTheBlank]: EditorFillInTheBlankIcon,
    [AnswerFormatType.FreeResponse]: EditorFreeResponseIcon,
    [AnswerFormatType.MultipleResponse]: EditorMRQIcon,
    [AnswerFormatType.Essay]: EditorEssayIcon,
    [AnswerFormatType.QuestionGroup]: EditorQuestionGroupIcon,
  }[answerFormatType];

  if (!Icon) {
    console.warn(`not supported answer format icon : ${answerFormatType}`);
    return null;
  }

  return <Icon {...props} />;
}
