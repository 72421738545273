import { MouseEvent } from 'react';
import { Box } from '@mui/material';
import { TextButton } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { DisplayTableLayoutTextButtonFooterCell } from '../../types';

export type IaDisplayTableButtonFooterCellProps =
  DisplayTableLayoutTextButtonFooterCell;

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  button: {
    ml: 1,
  },
  fullWidth: {
    width: '100%',
    height: '100%',
  },
};
export default function IaDisplayTableTextButtonFooterCell({
  text,
  action,
  suffixIcon,
  icon,
  sx,
  containerSx,
}: IaDisplayTableButtonFooterCellProps) {
  const { getIaAction } = useIaAction();

  const eventActionProps = {
    onClick: (e: MouseEvent) => {
      e.stopPropagation();
      getIaAction(action)?.action();
    },
  };

  const sxProps = Array.isArray(sx) ? sx : [sx];
  const containerSxProps = Array.isArray(containerSx)
    ? containerSx
    : [containerSx];
  return (
    <Box sx={[styles.root, ...containerSxProps]}>
      <TextButton
        sx={[styles.button, ...sxProps]}
        {...eventActionProps}
        prefixIcon={
          icon ? <Icon name={icon} width={16} height={16} /> : undefined
        }
        suffixIcon={
          suffixIcon ? (
            <Icon name={suffixIcon} width={16} height={16} />
          ) : undefined
        }
        onMouseDown={(e) => e.stopPropagation()}
      >
        {text}
      </TextButton>
    </Box>
  );
}
