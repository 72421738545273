import axios from 'axios';
import { useRouter } from 'next/router';
import useSWRImmutable from 'swr/immutable';

import useToken from '../useToken';

export const useFuncHighlights = (examQuestionId: string) => {
  const sid = useRouter().query.sid as string;
  const token = useToken();

  const params = new URLSearchParams();
  params.set('examQuestionId', examQuestionId);

  if (sid) {
    params.set('sid', sid);
  }
  const key =
    examQuestionId && !!(token || sid)
      ? `/api/func/highlight?${params.toString()}`
      : null;

  return useSWRImmutable<Response<GetFunctionHighlight>>(key, {
    fetcher: async () => {
      if (!key) return null;
      return axios.get(key).then((response) => response.data);
    },
  });
};
