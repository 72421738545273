import { useHotkeys } from 'react-hotkeys-hook';

const useKeyboardShortcutSelect = ({
  options,
  onSelect,
  activeIndex,
  setActiveIndex,
  disabled,
}: {
  options: { id: string; key: string }[];
  onSelect: (id: string) => void;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  disabled: boolean;
}) => {
  const keys = options.map((option) => option.key);
  const numberKeys = options.map((_, index) => `${index + 1}`);
  const updateAnswer = (newIndex: number) => {
    if (options[newIndex]) {
      setActiveIndex(newIndex);
      onSelect(options[newIndex].id);
    }
  };
  useHotkeys(
    keys,
    (ev) => {
      ev.preventDefault();
      const newIndex = options.findIndex(
        (option) => option.key.toLowerCase() === ev.key.toLowerCase()
      );
      updateAnswer(newIndex);
    },
    { enabled: !disabled }
  );
  useHotkeys(
    numberKeys,
    (ev) => {
      ev.preventDefault();
      const newIndex = parseInt(ev.key, 10) - 1;
      updateAnswer(newIndex);
    },
    { enabled: !disabled }
  );
  useHotkeys(
    'up',
    (ev) => {
      ev.preventDefault();
      updateAnswer(activeIndex - 1);
    },
    { enabled: !disabled }
  );
  useHotkeys(
    'down',
    (ev) => {
      ev.preventDefault();
      updateAnswer(activeIndex + 1);
    },
    { enabled: !disabled }
  );
};

export default useKeyboardShortcutSelect;
