import React from 'react';
import { alpha, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ActionMore as ActionMoreIcon } from '@front/icon';

export type IaTableLoadMoreRowProps = {
  text: string;
  onClick: () => void;
};

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'max-content', // for position:sticky

    '@media (hover: hover)': {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  content: {
    position: 'sticky',
    left: '24px',
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    gap: 1,
    px: 1,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
  },
  icon: {
    width: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
export default function IaTableLoadMoreRow({
  text,
  onClick,
}: IaTableLoadMoreRowProps) {
  return (
    <Box sx={styles.root} onClick={onClick}>
      <Box sx={styles.content}>
        <Box sx={styles.icon}>
          <ActionMoreIcon width={16} height={16} />
        </Box>
        <Typography variant="body2">{text}</Typography>
      </Box>
    </Box>
  );
}
