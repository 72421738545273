import { MouseEvent, useMemo, useState } from 'react';

export default function useFollowMouseAnchor() {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [anchor, setAnchor] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleMouseMove = (ev: MouseEvent, index: number) => {
    setAnchor({
      top: ev.clientY || 0,
      left: ev.clientX || 0,
    });
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
    setAnchor(null);
  };

  const virtualReference = useMemo(
    () => ({
      getBoundingClientRect(): DOMRect {
        return {
          ...anchor,
          width: 1,
          height: 1,
        } as DOMRect;
      },
    }),
    [anchor]
  );

  return {
    hoveredIndex,
    anchor,
    virtualReference,
    handleMouseMove,
    handleMouseLeave,
  };
}
