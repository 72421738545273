import React from 'react';

export default function OtherDocument({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 8V2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V10H14C13.4696 10 12.9609 9.78929 12.5858 9.41421C12.2107 9.03914 12 8.53043 12 8ZM7 12.25C7 12.0511 7.07902 11.8603 7.21967 11.7197C7.36032 11.579 7.55109 11.5 7.75 11.5H16.25C16.4489 11.5 16.6397 11.579 16.7803 11.7197C16.921 11.8603 17 12.0511 17 12.25C17 12.4489 16.921 12.6397 16.7803 12.7803C16.6397 12.921 16.4489 13 16.25 13H7.75C7.55109 13 7.36032 12.921 7.21967 12.7803C7.07902 12.6397 7 12.4489 7 12.25ZM7 15.25C7 15.0511 7.07902 14.8603 7.21967 14.7197C7.36032 14.579 7.55109 14.5 7.75 14.5H16.25C16.4489 14.5 16.6397 14.579 16.7803 14.7197C16.921 14.8603 17 15.0511 17 15.25C17 15.4489 16.921 15.6397 16.7803 15.7803C16.6397 15.921 16.4489 16 16.25 16H7.75C7.55109 16 7.36032 15.921 7.21967 15.7803C7.07902 15.6397 7 15.4489 7 15.25ZM7 18.25C7 18.0511 7.07902 17.8603 7.21967 17.7197C7.36032 17.579 7.55109 17.5 7.75 17.5H16.25C16.4489 17.5 16.6397 17.579 16.7803 17.7197C16.921 17.8603 17 18.0511 17 18.25C17 18.4489 16.921 18.6397 16.7803 18.7803C16.6397 18.921 16.4489 19 16.25 19H7.75C7.55109 19 7.36032 18.921 7.21967 18.7803C7.07902 18.6397 7 18.4489 7 18.25ZM13.5 8V2.5L19.5 8.5H14C13.8674 8.5 13.7402 8.44732 13.6464 8.35355C13.5527 8.25979 13.5 8.13261 13.5 8Z"
        fill="currentColor"
      />
    </svg>
  );
}
