import { createContext, ReactNode } from 'react';
import { InfiniteHookResponse } from '@lib/web/apis';

export interface CreatorQuestionListValue {
  listData: InfiniteHookResponse<GetCreatorQuestionListRes>;
}

const defaultValue: CreatorQuestionListValue = {
  listData: {} as InfiniteHookResponse<GetCreatorQuestionListRes>,
};

type CreatorQuestionListContextValue = CreatorQuestionListValue;

type CreatorQuestionListProviderProps = {
  listData: CreatorQuestionListValue['listData'];
  children: ReactNode;
};

export const CreatorQuestionListContext =
  createContext<CreatorQuestionListContextValue>(defaultValue);

export function CreatorQuestionListProvider({
  listData,
  children,
}: CreatorQuestionListProviderProps) {
  return (
    <CreatorQuestionListContext.Provider
      value={{
        listData,
      }}
    >
      {children}
    </CreatorQuestionListContext.Provider>
  );
}
