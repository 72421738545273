import React from 'react';

export default function MainNotificationOn({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.5566 15.3024C19.5919 15.3439 19.6276 15.3858 19.6636 15.4277C19.8422 15.6358 19.9551 15.889 19.9892 16.1571C20.0232 16.4253 19.9768 16.6973 19.8556 16.9408C19.5979 17.4607 19.0542 17.7846 18.4322 17.7846H5.57261C4.94772 17.7846 4.40026 17.4603 4.1434 16.938C4.02267 16.6945 3.97675 16.4228 4.01106 16.1549C4.04536 15.887 4.15847 15.6343 4.33698 15.4265C4.39756 15.3546 4.45718 15.2853 4.51583 15.217C4.52409 15.2074 4.53234 15.1978 4.54056 15.1882C5.45729 14.1203 6.01057 13.475 6.01057 10.4471C6.01057 7.6989 7.15128 5.90974 9.49846 4.9772C9.51113 4.97036 9.52253 4.96154 9.53219 4.95108C9.89396 3.78308 10.8835 3 12.0001 3C13.1167 3 14.1067 3.78308 14.4684 4.95228C14.4781 4.96314 14.4896 4.97226 14.5026 4.9792C15.5084 5.37858 16.2715 5.91898 16.836 6.63135C17.6016 7.59564 17.9901 8.88136 17.9901 10.4483C17.9901 13.4734 18.5436 14.1196 19.4586 15.1878L19.4601 15.1894C19.492 15.2266 19.5241 15.2643 19.5566 15.3024Z"
        fill="currentColor"
      />
      <path
        d="M13.7071 20.5439C13.1908 20.8419 12.6006 20.9995 11.999 21C11.3974 20.9995 10.8073 20.8418 10.2911 20.5438C9.77486 20.2458 9.35184 19.8185 9.06686 19.3073C9.05341 19.2827 9.04678 19.2553 9.04761 19.2275C9.04843 19.1998 9.05669 19.1727 9.07157 19.149C9.08645 19.1252 9.10745 19.1056 9.13252 19.092C9.1576 19.0784 9.18589 19.0713 9.21465 19.0714H14.7841C14.8128 19.0714 14.8411 19.0785 14.8661 19.0922C14.8911 19.1058 14.9121 19.1254 14.9269 19.1491C14.9417 19.1729 14.9499 19.1999 14.9507 19.2276C14.9516 19.2553 14.9449 19.2828 14.9315 19.3073C14.6465 19.8186 14.2234 20.2459 13.7071 20.5439Z"
        fill="currentColor"
      />
    </svg>
  );
}
