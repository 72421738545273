import { useRef, useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Icon, LoadingIcon } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import useTableSelectionContext from '@lib/ia/src/layouts/TableLayout/hooks/useTableSelectionContext';

import { TableLayoutPasswordCell, TableLayoutRow } from '../../types';
import IaTextBasedEditableCell from '../IaTableEditable/IaTextBasedEditableCell';

export type IaPasswordCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutPasswordCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
    gridTemplateColumns: '1fr 16px',

    svg: {
      cursor: 'pointer',
    },
  },
};
export default function IaPasswordCell({
  row,
  columnKey,
  cell,
}: IaPasswordCellProps) {
  const { getIaAction } = useIaAction();
  const { setSelectedState } = useTableSelectionContext();
  const [revealingPassword, setRevealingPassword] = useState(false);
  const [password, setPassword] = useState('');
  const { sx, actionMap, placeholder } = cell;
  const ref = useRef<HTMLDivElement>();

  const sxProps = Array.isArray(sx) ? sx : [sx];

  const handleRevealClick = async () => {
    setRevealingPassword(true);
    const revealPassword = getIaAction<TableLayoutRow, string>(
      cell.actionMap?.revealPassword
    )?.action;

    const revealedPassword = await revealPassword?.(row);
    setRevealingPassword(false);

    if (!revealedPassword) return;
    setPassword(revealedPassword);
    setSelectedState('active');
  };

  return (
    <>
      <Box sx={[styles.root, ...sxProps]} ref={ref}>
        <Typography variant="body2">••••••••••••••••</Typography>
        {revealingPassword ? (
          <LoadingIcon />
        ) : (
          <Icon
            name="LoginSee"
            width={16}
            height={16}
            onClick={handleRevealClick}
          />
        )}
      </Box>
      {password && (
        <IaTextBasedEditableCell
          containerRef={ref}
          actionMap={actionMap}
          editable
          placeholder={placeholder}
          columnKey={columnKey}
          row={row}
          initValue={password}
          filterValue={(val: string) => val}
          inputSx={{
            '& .MuiInputBase-input': {
              textAlign: sxProps[0]?.textAlign,
            },
          }}
        />
      )}
    </>
  );
}
