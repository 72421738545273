import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBaseProps } from '@mui/material/ButtonBase';
import { MainGetHelp as MainGetHelpIcon } from '@front/icon';
import { TextButton } from '@front/ui';
import { useGetHelpUrl } from '@lib/web/hooks';

import TipButton from '../TipButton';

export default function GetHelp({
  text,
  sx = {},
  onClick,
}: {
  text?: string;
  sx?: ButtonBaseProps['sx'];
  onClick?: ButtonBaseProps['onClick'];
}) {
  const { t } = useTranslation();
  const getHelpUrl = useGetHelpUrl();

  const handleGetHelpClicked = (ev: MouseEvent<HTMLButtonElement>) => {
    window.open(getHelpUrl, '_blank', 'noreferrer');
    onClick?.(ev);
  };

  if (text) {
    return (
      <TextButton
        sx={sx}
        size="sm"
        onClick={handleGetHelpClicked}
        prefixIcon={<MainGetHelpIcon />}
      >
        {text}
      </TextButton>
    );
  }

  return (
    <TipButton
      title={t('toolbar.Get Help')}
      sx={sx}
      onClick={handleGetHelpClicked}
    >
      <MainGetHelpIcon />
    </TipButton>
  );
}
