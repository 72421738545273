import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function useResponsivePageTitle() {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return {
    isPageTitleShow: mdUp,
  };
}
