import React from 'react';

export default function ChatRequestCoin({ ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.25 16C3.25 16.4142 3.58579 16.75 4 16.75H10.75C11.1642 16.75 11.5 16.4142 11.5 16C11.5 15.5858 11.1642 15.25 10.75 15.25H4.75V9.25C4.75 8.83579 4.41421 8.5 4 8.5C3.58579 8.5 3.25 8.83579 3.25 9.25V16ZM16.5303 4.53033C16.8232 4.23744 16.8232 3.76256 16.5303 3.46967C16.2374 3.17678 15.7626 3.17678 15.4697 3.46967L16.5303 4.53033ZM4.53033 16.5303L16.5303 4.53033L15.4697 3.46967L3.46967 15.4697L4.53033 16.5303Z"
        fill="currentColor"
      />
    </svg>
  );
}
