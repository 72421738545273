import { Theme } from '@mui/material';

export const styles = {
  root: {
    display: 'grid',
    width: '100%',
    maxWidth: 300,
    margin: '0 auto',
  },
  workflowItem: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.3,
  },
  addNewCard: {
    // dashed border by https://kovart.github.io/dashed-border-generator/
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(255,255,255,0.5)' stroke-width='2' stroke-dasharray='4%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    height: '98px',
    color: 'grey.500',
    p: 2,
    gap: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  addNewCardHover: (theme: Theme) => ({
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.text.primary,
        // dashed border by https://kovart.github.io/dashed-border-generator/
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(255,255,255,1)' stroke-width='2' stroke-dasharray='4%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      },
    },
  }),
};
