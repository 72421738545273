import { useEffect, useMemo, useRef, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import { useQueueWorker } from '@front/helper';
import { InfiniteHookResponse, useInfiniteState } from '@lib/web/apis';
import { STREAM_MAX_CHANNELS_PER_REQUEST } from '@lib/web/thread/config/constants';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { safeQueryChannels } from '@lib/web/thread/utils/channelUtils';
import {
  Channel,
  ChannelFilters,
  ChannelOptions,
  ChannelSort,
} from 'stream-chat';

import { useAddWatchingEvent } from '../core/useAddWatchingEvent';
import { useAddWatchingView } from '../core/useAddWatchingView';
import { useThread } from '../core/useThread';

const useInfiniteChannels = ({
  scope,
  key,
  filters,
  sort,
  options,
  enable = true,
}: {
  scope: string;
  key: string;
  filters: ChannelFilters;
  sort?: ChannelSort;
  options?: ChannelOptions;
  enable?: boolean;
}): InfiniteHookResponse<Channel<StreamChatGenerics>> => {
  const isMountedRef = useRef(true);
  const { chatClient } = useThread();

  const { data, error, isLoading, isValidating, mutate, size, setSize } =
    useSWRInfinite<PageResponse<Channel>>(
      (index) =>
        enable && chatClient && key
          ? [index, '/stream/channels/', scope, key]
          : null,
      {
        fetcher: async ([index]) => {
          if (!chatClient) {
            return {
              data: {
                items: [] as Channel[],
                limit: 1,
                page: 1,
                pageCount: 0,
                totalCount: 0,
              },
            } as PageResponse<Channel>;
          }

          const limit = STREAM_MAX_CHANNELS_PER_REQUEST;

          let channels: Channel[] = [];
          const getIsOutdated = () => !isMountedRef.current;
          channels = await safeQueryChannels(
            chatClient,
            filters,
            sort,
            {
              limit,
              offset: index * STREAM_MAX_CHANNELS_PER_REQUEST,
              ...options,
            },
            getIsOutdated
          );

          return {
            data: {
              items: channels,
              limit,
              page: index + 1,
              pageCount: channels.length,
              totalCount: channels.length,
            },
          } as PageResponse<Channel>;
        },
        /**
         * Deduplicate requests with the same key within 60 seconds.
         * Reason: The stream SDK has rate limits per minute.
         * Ideally, 'revalidateOnMount = false' should be used,
         * but there are issues with certain parameters (see https://github.com/vercel/swr/issues/943).
         * As a workaround, we set dedupingInterval to 60 seconds to simulate similar behavior.
         */
        dedupingInterval: 60000,
      }
    );

  const infiniteState = useInfiniteState<Channel>({
    data,
    error,
    size,
    pageSize: STREAM_MAX_CHANNELS_PER_REQUEST,
  });

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return useMemo(() => {
    const lastPageIsTheEnd =
      !!data &&
      data[data.length - 1].data.items.length < STREAM_MAX_CHANNELS_PER_REQUEST;

    return {
      data,
      size,
      error,
      isLoading,
      isValidating,
      setSize,
      mutate,
      ...infiniteState,
      totalCount: infiniteState.dataset.length, // ideally this should be how many channels we have, not the channels we fetched, but stream sdk doesn't give us this information
      isReachingEnd: lastPageIsTheEnd, // because stream sdk doesn't give us the information of total count, we need to maintain this by ourselves
    };
  }, [
    data,
    error,
    infiniteState,
    isLoading,
    isValidating,
    mutate,
    setSize,
    size,
  ]);
};

export const useFilteredChannels = ({
  scope,
  key,
  filters,
  sort,
  options,
  enable = true,
}: {
  scope: string;
  key: string;
  filters: ChannelFilters;
  sort?: ChannelSort;
  options?: ChannelOptions;
  enable?: boolean;
}) => {
  // we will maintain channels by ourselves instead of directly using infiniteHooksResponse.dataset, because it would be easier for combining with stream sdk
  const [channels, setChannels] = useState<Channel<StreamChatGenerics>[]>([]);
  const rawChannelsData = useInfiniteChannels({
    scope,
    key,
    filters,
    sort,
    options,
    enable,
  });
  const [channelsData, setChannelsData] = useState(rawChannelsData);
  const { addTask } = useQueueWorker();

  useAddWatchingView(
    useMemo(
      () => ({
        scope,
        key,
        filters,
        channels,
        setChannels,
        reloadChannels: () => {
          addTask({
            scope: 'useFilteredChannels',
            taskKey: key,
            task: async () => {
              await channelsData.mutate();
            },
            debounceTime: 2000,
          });
        },
      }),
      [addTask, channels, channelsData, filters, key, scope]
    )
  );

  useAddWatchingEvent({
    scope,
    key: 'all',
    callback: (event) => {
      if (event.type === 'notification.channel_deleted') {
        addTask({
          scope,
          taskKey: 'threadReloadDeleted',
          debounceTime: 2000,
          task: () => {
            void channelsData.mutate();
          },
        });
      }
    },
  });

  /**
   * when channelsData changed (from query stream sdk), we will update to 'channels'
   */
  useEffect(() => {
    setChannels([...rawChannelsData.dataset]);
    setChannelsData(rawChannelsData);
  }, [rawChannelsData]);

  /**
   * when channels changes (mostly from event), we will update back to channelsData
   */
  useEffect(() => {
    setChannelsData((prevState) => {
      /**
       * when channelsData is still loading, let us skip this step because finally it will be updated by above hook
       */
      if (prevState.isLoading || prevState.isLoadingMore) return prevState;

      return {
        ...prevState,
        dataset: channels,
        totalCount: channels.length,
        isEmpty: channels.length === 0,
      };
    });
  }, [channels]);

  return channelsData;
};
