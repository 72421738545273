import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export function getStorageItem<T>(key: string, initialValue: T) {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
}

function setStorageItem<T>(key: string, value: T) {
  const storedValue = getStorageItem(key, value);
  try {
    const valueToStore = (
      value instanceof Function ? value(storedValue) : value
    ) as T;

    window.localStorage.setItem(key, JSON.stringify(valueToStore));
    return valueToStore;
  } catch (error) {
    // console.log(error);
  }
  return storedValue;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function useLocalStorage<T = any>(
  key: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(
    getStorageItem(key, initialValue)
  );

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value) => {
      const valueToStore = setStorageItem(key, value);
      setStoredValue(valueToStore);
    },
    [key]
  );

  return [storedValue, setValue];
}
