import api from './client';

const summaryApi = {
  generateSummaryLink: (params: GenerateSummaryLinkReq) =>
    api.post<Response<{ url: string }>>('v2/summary/share/link', params),
  shareSummaryLink: (params: ShareSummaryLinkReq) =>
    api.post<Response<ShareSummaryLinkRes>>('v2/summary/share', params),
};

export default summaryApi;
