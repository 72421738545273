import { useCallback, useContext } from 'react';
import { ErrorMessageContext } from '@lib/web/editor';
import { ErrorMessageValue, PositionalErrorMessages } from '@lib/web/ui';

export const useFieldErrorMessage = () => {
  const [value, setValue] = useContext(ErrorMessageContext);

  const selectFieldErrorMessageByPosition = useCallback(
    (questionId: string, position: keyof PositionalErrorMessages) => {
      return value.fieldErrorMessagesMap[questionId]?.[position];
    },
    [value.fieldErrorMessagesMap]
  );

  const selectFieldErrorMessage = useCallback(
    (questionId: string) => {
      return value.fieldErrorMessagesMap[questionId];
    },
    [value.fieldErrorMessagesMap]
  );

  const setFieldErrorMessage = useCallback(
    (
      questionId: string,
      position: keyof PositionalErrorMessages,
      errorMessageValues: ErrorMessageValue[]
    ) => {
      setValue((prev) => ({
        ...prev,
        fieldErrorMessagesMap: {
          ...prev.fieldErrorMessagesMap,
          [questionId]: {
            ...prev.fieldErrorMessagesMap[questionId],
            [position]: errorMessageValues,
          },
        },
      }));
    },
    [setValue]
  );

  const resetAllFieldErrorMessages = useCallback(
    (questionId: string) => {
      setValue((prev) => ({
        ...prev,
        fieldErrorMessagesMap: {
          ...prev.fieldErrorMessagesMap,
          [questionId]: {
            leftQuestionArea: [],
            rightQuestionArea: [],
            answerArea: [],
            solutionArea: [],
            panelArea: [],
          },
        },
      }));
    },
    [setValue]
  );

  const hasFieldError = useCallback(
    (questionId: string, positionToCheck?: keyof PositionalErrorMessages) => {
      for (const position in value.fieldErrorMessagesMap?.[questionId]) {
        if (positionToCheck && position !== positionToCheck) {
          continue;
        }

        const errorMessages =
          value.fieldErrorMessagesMap[questionId][
            position as keyof PositionalErrorMessages
          ];
        for (const errorMessage of errorMessages) {
          if (errorMessage.type === 'error') {
            return true;
          }
        }
      }
      return false;
    },
    [value.fieldErrorMessagesMap]
  );

  const hasFieldWarning = useCallback(
    (questionId: string, positionToCheck?: keyof PositionalErrorMessages) => {
      for (const position in value.fieldErrorMessagesMap?.[questionId]) {
        if (positionToCheck && position !== positionToCheck) {
          continue;
        }

        const errorMessages =
          value.fieldErrorMessagesMap[questionId][
            position as keyof PositionalErrorMessages
          ];
        for (const errorMessage of errorMessages) {
          if (errorMessage.type === 'warning') {
            return true;
          }
        }
      }
      return false;
    },
    [value.fieldErrorMessagesMap]
  );

  const hasFieldErrorOrWarning = useCallback(
    (questionId: string, positionToCheck?: keyof PositionalErrorMessages) =>
      hasFieldError(questionId, positionToCheck) ||
      hasFieldWarning(questionId, positionToCheck),
    [hasFieldError, hasFieldWarning]
  );

  return {
    value,
    selectFieldErrorMessage,
    selectFieldErrorMessageByPosition,
    setFieldErrorMessage,
    resetAllFieldErrorMessages,
    hasFieldError,
    hasFieldWarning,
    hasFieldErrorOrWarning,
  };
};
