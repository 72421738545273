import React from 'react';

export default function OtherLaTex({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.49304 8V9.50495H6.12885V15.8416H4.36419V9.50495H2V8H8.49304Z"
        fill="currentColor"
      />
      <path
        d="M8.38063 12.9109C8.38063 12.3828 8.45981 11.9227 8.61818 11.5304C8.78409 11.1306 8.99901 10.7987 9.26296 10.5347C9.5269 10.2706 9.82855 10.0707 10.1679 9.93494C10.5148 9.79915 10.8693 9.73126 11.2312 9.73126C12.0759 9.73126 12.7433 9.99151 13.2334 10.512C13.7236 11.025 13.9687 11.7831 13.9687 12.7864C13.9687 12.8845 13.9649 12.9939 13.9574 13.1146C13.9499 13.2277 13.9423 13.3296 13.9348 13.4201H10.1114C10.1491 13.7671 10.3112 14.0424 10.5978 14.2461C10.8843 14.4498 11.2689 14.5516 11.7516 14.5516C12.0608 14.5516 12.3624 14.5252 12.6565 14.4724C12.9582 14.4121 13.2033 14.3404 13.3918 14.2574L13.618 15.6266C13.5276 15.6719 13.4069 15.7171 13.2561 15.7624C13.1052 15.8076 12.9356 15.8454 12.747 15.8755C12.566 15.9132 12.37 15.9434 12.1588 15.9661C11.9477 15.9887 11.7365 16 11.5253 16C10.9899 16 10.5224 15.9208 10.1227 15.7624C9.73052 15.604 9.40247 15.389 9.13853 15.1174C8.88212 14.8383 8.68982 14.5101 8.56162 14.133C8.44096 13.7558 8.38063 13.3484 8.38063 12.9109ZM12.3398 12.2659C12.3323 12.1226 12.3059 11.983 12.2606 11.8472C12.2229 11.7115 12.1588 11.5908 12.0683 11.4851C11.9854 11.3795 11.876 11.2928 11.7403 11.2249C11.6121 11.157 11.4499 11.1231 11.2539 11.1231C11.0653 11.1231 10.9032 11.157 10.7674 11.2249C10.6317 11.2852 10.5186 11.3682 10.4281 11.4738C10.3376 11.5794 10.2659 11.7039 10.2132 11.8472C10.1679 11.983 10.134 12.1226 10.1114 12.2659H12.3398Z"
        fill="currentColor"
      />
      <path
        d="M19.8847 15.8416C19.666 15.4041 19.4247 14.9628 19.1607 14.5177C18.9043 14.0651 18.6064 13.6049 18.2671 13.1372C18.1615 13.273 18.0257 13.4616 17.8598 13.703C17.7015 13.9444 17.5393 14.2008 17.3734 14.4724C17.2151 14.744 17.0642 15.0042 16.9209 15.2532C16.7777 15.5021 16.6721 15.6983 16.6042 15.8416H14.5907C14.9225 15.2306 15.3071 14.5893 15.7445 13.918C16.1894 13.2466 16.6834 12.5337 17.2264 11.7793L14.7038 8H16.8418L18.3689 10.4441L19.862 8H21.8756L19.3869 11.802C20.0204 12.6242 20.5521 13.3861 20.9819 14.0877C21.4118 14.7817 21.7511 15.3663 22 15.8416H19.8847Z"
        fill="currentColor"
      />
    </svg>
  );
}
