import React, { ReactElement, ReactNode, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLongPress } from '@front/helper';
import { OtherInfoSolid as OtherInfoSolidIcon } from '@front/icon';

import BottomSheet, { BottomSheetProps } from '../../../molecules/BottomSheet';
import InfoTooltip, { InfoTooltipProps } from '../InfoTooltip';

export type ResponsiveTooltipProps = {
  title?: ReactNode;
  titleIcon?: ReactNode;
  titleSx?: BoxProps['sx'];
  content?: ReactNode;
  open?: boolean;
  hideInDevices?: boolean;
  children?: ReactElement;
  tooltipProps?: Omit<
    InfoTooltipProps,
    'open' | 'title' | 'content' | 'children' | 'onClose' | 'onOpen'
  >;
  bottomSheetProps?: Omit<BottomSheetProps, 'open' | 'onClose' | 'children'>;
  mobileTriggerDelay?: number;
  onClose?: () => void;
  onOpen?: () => void;
};

const styles = {
  simpleSheet: {
    '& .bottom-sheet-bg': {
      background: 'none',
    },
  },
  sheetContent: {
    px: 2.5,
    typography: 'caption',
  },
  title: {
    mb: 1,
    alignItems: 'center',
    display: 'flex',
    gap: 0.5,
    fontWeight: 500,
    typography: 'caption',
  },
  info: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

type MobileTooltipProps = {
  title?: ReactNode;
  titleIcon?: ReactNode;
  titleSx?: BoxProps['sx'];
  content?: ReactNode;
  customContent?: ReactNode;
  iconSx?: BoxProps['sx'];
  sheetSx?: BoxProps['sx'];
  open?: boolean;
  simpleBackdrop?: boolean;
  children?: ReactElement;
  triggerDelay?: number;
  onClose?: () => void;
  onOpen?: () => void;
};
export function MobileTooltip({
  title,
  titleIcon,
  titleSx,
  content,
  customContent,
  iconSx,
  sheetSx,
  children,
  simpleBackdrop = true,
  triggerDelay = 500,
  open,
  onClose,
  onOpen,
  ...rest
}: MobileTooltipProps) {
  const iconSxProps = Array.isArray(iconSx) ? iconSx : [iconSx];
  const sheetSxProps = Array.isArray(sheetSx) ? sheetSx : [sheetSx];
  const titleSxProps = Array.isArray(titleSx) ? titleSx : [titleSx];
  const [visible, setVisible] = useState(false);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isControlled = open !== undefined;
  const handleTooltipClose = () => {
    if (isControlled) onClose?.();
    else setVisible(false);
  };
  const hasContent = !!(title || content || customContent);
  const handleTooltipOpen = () => {
    if (isControlled) onOpen?.();
    else setVisible(true);
  };

  const backspaceLongPress = useLongPress(handleTooltipOpen, triggerDelay);

  const inner = React.Children.only(
    children || (
      <Box sx={[styles.info, ...iconSxProps]}>
        <OtherInfoSolidIcon />
      </Box>
    )
  );

  if (mdUp) return children;

  return (
    <>
      {React.cloneElement(inner, {
        ...inner.props,
        ...backspaceLongPress,
        onContextMenu: (ev: MouseEvent) => {
          ev.preventDefault();
          ev.stopPropagation();
          return false;
        },
      })}
      <BottomSheet
        sx={[simpleBackdrop && styles.simpleSheet, ...sheetSxProps]}
        open={(isControlled ? open : visible) && hasContent}
        onClose={handleTooltipClose}
        simpleBackdrop={simpleBackdrop}
        fixedHeight
        {...rest}
      >
        <Box sx={styles.sheetContent}>
          {!!(title || titleIcon) && (
            <Box sx={[styles.title, ...titleSxProps]}>
              {titleIcon}
              {title}
            </Box>
          )}
          {!!content && <Box>{content}</Box>}
          {customContent}
        </Box>
      </BottomSheet>
    </>
  );
}

export default function ResponsiveTooltip({
  children,
  title,
  titleIcon,
  titleSx,
  content,
  open,
  mobileTriggerDelay,
  onClose,
  onOpen,
  tooltipProps = {},
  bottomSheetProps = {},
  hideInDevices = false,
}: ResponsiveTooltipProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  if (!mdUp && hideInDevices) {
    return children;
  }

  if (mdUp) {
    return (
      <InfoTooltip
        title={title}
        titleIcon={titleIcon}
        titleSx={titleSx}
        content={content}
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        {...tooltipProps}
      >
        {children}
      </InfoTooltip>
    );
  }

  return (
    <MobileTooltip
      title={title}
      titleIcon={titleIcon}
      titleSx={titleSx}
      content={content}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      triggerDelay={mobileTriggerDelay}
      {...bottomSheetProps}
    >
      {children}
    </MobileTooltip>
  );
}
