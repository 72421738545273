import React, { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {
  BadgeBasicPlan as BadgeBasicPlanIcon,
  BadgeProPlan as BadgeProPlanIcon,
  BadgeUnlimitedPlan as BadgeUnlimitedPlanIcon,
} from '@front/icon';
import { Icon, ResponsiveTooltip } from '@front/ui';
import { PlanProductName } from '@lib/web/apis';
import { useSubscriptionPlans } from '@lib/web/hooks';
import { TFunction } from 'i18next';

const styles = {
  title: {
    fontWeight: 700,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    pt: 1,
  },
  priceItem: {
    display: 'grid',
    gridTemplateColumns: '20px 1fr min-content',
    alignItems: 'flex-start',

    br: {
      display: { xs: 'none', md: 'inline' }, // break line only need on md up
    },
  },
};

const planConfigMap = (t: TFunction) => ({
  premium: {
    icon: <BadgeBasicPlanIcon width={16} height={16} reverseColor />,
    title: t('Premium'),
    description: t('plan.premium.description'),
    priceMonthly: PlanProductName.BasicMonthly,
    priceYearly: PlanProductName.BasicYearly,
  },
  pro: {
    icon: <BadgeProPlanIcon width={16} height={16} />,
    title: t('Pro'),
    description: t('plan.pro.description'),
    priceMonthly: PlanProductName.ProMonthly,
    priceYearly: PlanProductName.ProYearly,
  },
  unlimited: {
    icon: <BadgeUnlimitedPlanIcon width={16} height={16} />,
    title: t('Unlimited'),
    description: t('plan.unlimited.description'),
    priceMonthly: PlanProductName.UnlimitedMonthly,
    priceYearly: PlanProductName.UnlimitedYearly,
  },
});

export type PaidPlanLabelTooltipProps = PropsWithChildren & {
  plan: 'premium' | 'pro' | 'unlimited';
};

export default function PaidPlanLabelTooltip({
  children,
  plan,
}: PaidPlanLabelTooltipProps) {
  const { t } = useTranslation('profile');
  const config = planConfigMap(t)[plan];
  const { plansDisplayAmountByMonth } = useSubscriptionPlans();

  if (!config) return null;

  return (
    <ResponsiveTooltip
      title={config.title}
      titleIcon={config.icon}
      titleSx={styles.title}
      content={
        <Box sx={styles.content}>
          <Typography variant="caption">{config.description}</Typography>
          <Box sx={styles.priceItem}>
            <Icon name="PropertyTypeTag" width={16} height={16} />
            <Typography variant="caption">
              <Trans
                i18nKey="plan.price.monthly.title"
                components={{ br: <br /> }}
              />
            </Typography>
            <Typography variant="caption">
              {t('plan.price.monthly.perMonth', {
                price: plansDisplayAmountByMonth[config.priceMonthly],
              })}
            </Typography>
          </Box>
          <Box sx={styles.priceItem}>
            <Icon name="PropertyTypeTag" width={16} height={16} />
            <Typography variant="caption">
              <Trans
                i18nKey="plan.price.yearly.title"
                components={{ br: <br /> }}
              />
            </Typography>
            <Typography variant="caption">
              {t('plan.price.yearly.perMonth', {
                price: plansDisplayAmountByMonth[config.priceYearly],
              })}
            </Typography>
          </Box>
          <Typography variant="caption">
            *{t('plan.price.note', { currency: 'USD' })}
          </Typography>
        </Box>
      }
    >
      <span>{children}</span>
    </ResponsiveTooltip>
  );
}
