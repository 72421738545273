import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { PropertyType } from '@front/ui';
import { SortFieldsConfig } from '@lib/ia/src/sort/types';
import { useLocalesInfo } from '@lib/web/apis';
import { TFunction } from 'i18next';

import { SortType } from '../types';

type AdditionalData = {
  locales: LocaleInfo[];
};

const getGlobalSortConfigs = (
  t: TFunction,
  data: AdditionalData
): Record<SortType, SortFieldsConfig | null> => ({
  club: {
    fields: [
      {
        label: t('sort.userLocale.label'),
        name: 'priorityLocale',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        customDirectionOptions: data.locales.map((locale) => ({
          type: 'rawQuery',
          value: `customDir_${locale.id}`,
          label: locale.name,
          rawQueryParams: {
            search: `priorityLocale:${locale.id}`,
            searchFields: 'priorityLocale:in',
          },
        })),
      },
      {
        label: t('sort.clubType.label'),
        name: 'officialType',
        type: PropertyType.Status,
        customDirectionOptions: [
          {
            value: 'customDir_official',
            label: t('sort.clubType.options.official'),
            direction: 'desc',
          },
          {
            value: 'customDir_nonOfficial',
            label: t('sort.clubType.options.nonOfficial'),
            direction: 'asc',
          },
        ],
      },
      {
        label: t('sort.clubName.label'),
        name: 'clubName',
        type: PropertyType.ShortText,
      },
      {
        label: t('sort.clubMembers.label'),
        name: 'clubMemberCount',
        type: PropertyType.Number,
      },
      {
        label: t('sort.joinedDate.label'),
        name: 'joinedAt',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
    ],
  },
  clubGroup: {
    fields: [
      {
        label: t('sort.clubGroupName.label'),
        name: 'name',
        type: PropertyType.ShortText,
      },
      {
        label: t('sort.clubGroupType.label'),
        name: 'officialType',
        type: PropertyType.Status,
        customDirectionOptions: [
          {
            value: 'customDir_official',
            label: t('sort.clubGroupType.options.official'),
            direction: 'desc',
          },
          {
            value: 'customDir_nonOfficial',
            label: t('sort.clubGroupType.options.nonOfficial'),
            direction: 'asc',
          },
        ],
      },
    ],
  },
  agent: {
    fields: [
      {
        label: t('sort.AIName.label'),
        name: 'agentName',
        type: PropertyType.ShortText,
      },
    ],
  },
  playlist: {
    fields: [
      {
        label: t('sort.answerResult.label'),
        name: 'practiceResult',
        type: PropertyType.Select,
        customIcon: 'TextEditorCheck',
      },
      {
        label: t('sort.overtimeStatus.label'),
        name: 'overtimeStatus',
        type: PropertyType.Status,
      },
      {
        label: t('sort.questionNumber.label'),
        name: 'questionNumber',
        type: PropertyType.Number,
      },
      {
        label: t('sort.questionContent.label'),
        name: 'questionContent',
        type: PropertyType.LongText,
      },
      {
        label: t('sort.reaction.label'),
        name: 'emoji',
        type: PropertyType.Icon,
      },
    ],
  },
  playlistDetailRhs: {
    fields: [
      {
        label: t('sort.answerResult.label'),
        name: 'practiceResult',
        type: PropertyType.Select,
        customIcon: 'TextEditorCheck',
      },
      {
        label: t('sort.overtimeStatus.label'),
        name: 'overtimeStatus',
        type: PropertyType.Status,
      },
      {
        label: t('sort.questionNumber.label'),
        name: 'questionNumber',
        type: PropertyType.Number,
      },
      {
        label: t('sort.questionContent.label'),
        name: 'questionContent',
        type: PropertyType.LongText,
      },
      {
        label: t('sort.reaction.label'),
        name: 'emoji',
        type: PropertyType.Icon,
      },
    ],
  },
  marketplace: {
    fields: [
      {
        label: t('sort.dateListed.label'),
        name: 'dateListed',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: t('sort.price.label'),
        name: 'price',
        type: PropertyType.Number,
        customIcon: 'OtherDollar',
      },
    ],
  },
  quiz: {
    fields: [
      {
        label: t('sort.quizName.label'),
        name: 'name',
        type: PropertyType.ShortText,
      },
      {
        label: t('sort.quizQuestionCount.label'),
        name: 'questionCount',
        type: PropertyType.Number,
      },
      {
        label: t('sort.quizTokens.label'),
        name: 'questionCount',
        type: PropertyType.Number,
      },
    ],
  },
  creatorQuiz: {
    fields: [
      {
        label: t('sort.createdBy.label'),
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: t('sort.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('sort.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('sort.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
    ],
  },
  emojiQuiz: {
    fields: [
      {
        label: t('sort.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('sort.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('sort.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
    ],
  },
  hashtagQuiz: {
    fields: [
      {
        label: t('sort.playlistName.label'),
        name: 'hashtag',
        type: PropertyType.ShortText,
      },
      {
        label: t('sort.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('sort.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('sort.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
    ],
  },
  followingQuiz: {
    fields: [
      {
        label: t('sort.playlistName.label'),
        name: 'hashtag',
        type: PropertyType.ShortText,
      },
      {
        label: t('sort.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('sort.score.label'),
        name: 'name',
        type: PropertyType.Number,
        customIcon: 'TestScoreTally',
      },
      {
        label: t('sort.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('sort.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
    ],
  },
  historyQuiz: {
    fields: [
      {
        label: t('sort.playlistName.label'),
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: t('sort.createdBy.label'),
        name: 'creator:displayName',
        type: PropertyType.Person,
      },
      {
        label: t('sort.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('sort.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('sort.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
      {
        label: t('sort.savedStatus.label'),
        name: 'savedStatus',
        type: PropertyType.Status,
      },
    ],
  },
  challengeQuiz: {
    fields: [
      {
        label: t('sort.playlistName.label'),
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: t('sort.createdBy.label'),
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: t('sort.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('sort.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('sort.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
      {
        label: t('sort.savedStatus.label'),
        name: 'savedStatus',
        type: PropertyType.Status,
      },
      {
        label: t('sort.challengeAttempts.label'),
        name: 'attempts',
        type: PropertyType.Number,
        customIcon: 'MainChallenge',
      },
      {
        label: t('sort.challengeEndDate.label'),
        name: 'endDate',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: t('sort.challengers.label'),
        name: 'challengers',
        type: PropertyType.Person,
      },
      {
        label: t('sort.challengeRank.label'),
        name: 'rank',
        type: PropertyType.Number,
        customIcon: 'TestTrophy',
      },
    ],
  },
  bookmarkQuiz: {
    fields: [
      {
        label: t('sort.playlistName.label'),
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: t('sort.createdBy.label'),
        name: 'creator:displayName',
        type: PropertyType.Person,
      },
      {
        label: t('sort.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.lastUpdatedTime.label'),
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.totalQuestions.label'),
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: t('sort.mistakeRate.label'),
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'ActionCloseThick',
      },
      {
        label: t('sort.overtimeRate.label'),
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClockSolid',
      },
      {
        label: t('sort.savedStatus.label'),
        name: 'savedStatus',
        type: PropertyType.Status,
      },
    ],
  },
  playlistQuiz: {
    fields: [
      {
        label: t('sort.playlistName.label'),
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: t('sort.practiceCount.label'),
        name: 'practiceCount',
        type: PropertyType.Number,
      },
      {
        label: t('sort.questionTokens.label'),
        name: 'questionTokenNum',
        type: PropertyType.Number,
      },
    ],
  },
  discountCodeManagement: {
    fields: [
      {
        label: t('sort.discountCode.label'),
        name: 'code',
        type: PropertyType.ShortText,
      },
      {
        label: t('sort.discountType.label'),
        name: 'discountType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
      },
      {
        label: t('sort.value.label'),
        name: 'discountVal',
        type: PropertyType.Number,
      },
      {
        label: t('sort.usageType.label'),
        name: 'usageType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
      },
      {
        label: t('sort.usageLimit.label'),
        name: 'redeemLimit',
        type: PropertyType.Number,
      },
      {
        label: t('sort.purchaseType.label'),
        name: 'purchaseType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
      },
      {
        label: t('sort.startDate.label'),
        name: 'startedAt',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: t('sort.expirationDate.label'),
        name: 'expiredAt',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: t('sort.createdBy.label'),
        name: 'creator',
        type: PropertyType.Person,
      },
    ],
  },
  threadChannel: {
    fields: [
      {
        label: t('sort.createdBy.label'),
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: t('sort.createdTime.label'),
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.visibility.label'),
        name: 'visibility',
        type: PropertyType.Select,
        customIcon: 'LoginSee',
      },
      {
        label: t('sort.memberCount.label'),
        name: 'memberCount',
        type: PropertyType.Number,
        customIcon: 'PrivacyFriends',
      },
      {
        label: t('sort.lastActivityTime.label'),
        name: 'lastActivityTime',
        type: PropertyType.DateOrTime,
      },
    ],
  },
  threadMessage: {
    fields: [
      {
        label: t('sort.messageText.label'),
        name: 'messageText',
        type: PropertyType.ShortText,
      },
      {
        label: t('sort.replyCount.label'),
        name: 'replyCount',
        type: PropertyType.Number,
      },
      {
        label: t('sort.user.label'),
        name: 'userId',
        type: PropertyType.Person,
      },
      {
        label: t('sort.createdTime.label'),
        name: 'createdAt',
        type: PropertyType.DateOrTime,
      },
      {
        label: t('sort.updatedTime.label'),
        name: 'updatedAt',
        type: PropertyType.DateOrTime,
      },
    ],
  },
});

export default function useGlobalSortConfigs() {
  const { data: localeData } = useLocalesInfo();
  const { t } = useTranslation();
  return useMemo(
    () => getGlobalSortConfigs(t, { locales: localeData?.data || [] }),
    [localeData?.data, t]
  );
}
