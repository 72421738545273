import React from 'react';

export default function EditorAIAlt2({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.2016 6.74617C13.014 6.55863 12.9087 6.30428 12.9087 6.03906V3.03906C12.9087 2.77385 13.014 2.51949 13.2016 2.33196C13.3891 2.14442 13.6435 2.03906 13.9087 2.03906C14.1739 2.03906 14.4283 2.14442 14.6158 2.33196C14.8033 2.51949 14.9087 2.77385 14.9087 3.03906V6.03906C14.9087 6.30428 14.8033 6.55863 14.6158 6.74617C14.4283 6.93371 14.1739 7.03906 13.9087 7.03906C13.6435 7.03906 13.3891 6.93371 13.2016 6.74617Z"
        fill="currentColor"
      />
      <path
        d="M10.3727 7.91706C10.5602 8.1047 10.8146 8.21017 11.0798 8.21026C11.3451 8.21036 11.5996 8.10507 11.7872 7.91756C11.9748 7.73005 12.0803 7.47569 12.0804 7.21042C12.0805 6.94515 11.9752 6.6907 11.7877 6.50306L9.66569 4.38206C9.57344 4.28655 9.4631 4.21037 9.3411 4.15796C9.21909 4.10555 9.08787 4.07797 8.95509 4.07681C8.82231 4.07566 8.69063 4.10096 8.56774 4.15124C8.44484 4.20152 8.33319 4.27577 8.2393 4.36967C8.1454 4.46356 8.07115 4.57521 8.02087 4.69811C7.97059 4.821 7.94529 4.95268 7.94644 5.08546C7.9476 5.21824 7.97518 5.34946 8.02759 5.47147C8.08 5.59347 8.15618 5.70382 8.25169 5.79606L10.3727 7.91706Z"
        fill="currentColor"
      />
      <path
        d="M19.8464 5.09266C19.8486 5.35486 19.7478 5.60746 19.5657 5.79606L19.5637 5.79706L17.4437 7.91706C17.2562 8.1047 17.0018 8.21017 16.7365 8.21026C16.4713 8.21036 16.2168 8.10507 16.0292 7.91756C15.8415 7.73005 15.7361 7.47569 15.736 7.21042C15.7359 6.94515 15.8412 6.6907 16.0287 6.50306L18.1517 4.38206C18.3403 4.1999 18.5929 4.09911 18.8551 4.10139C19.1173 4.10367 19.3681 4.20884 19.5535 4.39424C19.7389 4.57965 19.8441 4.83046 19.8464 5.09266Z"
        fill="currentColor"
      />
      <path
        d="M18.8587 15.9888C19.1239 15.9888 19.3782 15.8835 19.5657 15.6961H19.5637C19.6567 15.6032 19.7304 15.4929 19.7808 15.3715C19.8311 15.2501 19.857 15.12 19.857 14.9886C19.857 14.8571 19.8311 14.727 19.7808 14.6056C19.7304 14.4842 19.6567 14.3739 19.5637 14.2811L17.4437 12.1601C17.2562 11.9724 17.0018 11.867 16.7365 11.8669C16.4713 11.8668 16.2168 11.9721 16.0292 12.1596C15.9363 12.2524 15.8626 12.3626 15.8123 12.484C15.762 12.6053 15.736 12.7354 15.736 12.8667C15.7359 12.9981 15.7618 13.1281 15.812 13.2495C15.8622 13.3709 15.9358 13.4812 16.0287 13.5741L18.1517 15.6961C18.3392 15.8835 18.5935 15.9888 18.8587 15.9888Z"
        fill="currentColor"
      />
      <path
        d="M17.9087 9.03906C17.6435 9.03906 17.3891 9.14442 17.2016 9.33196C17.014 9.51949 16.9087 9.77385 16.9087 10.0391C16.9087 10.3043 17.014 10.5586 17.2016 10.7462C17.3891 10.9337 17.6435 11.0391 17.9087 11.0391H20.9087C21.1739 11.0391 21.4283 10.9337 21.6158 10.7462C21.8033 10.5586 21.9087 10.3043 21.9087 10.0391C21.9087 9.77385 21.8033 9.51949 21.6158 9.33196C21.4283 9.14442 21.1739 9.03906 20.9087 9.03906H17.9087Z"
        fill="currentColor"
      />
      <path
        d="M3.24836 18.8143L13.2756 8.78696C13.4718 8.59085 13.7356 8.49374 13.9352 8.55858C14.2169 8.65001 14.5441 8.85761 14.8433 9.15686C15.1425 9.45596 15.3502 9.78326 15.4415 10.0649C15.5063 10.2643 15.4093 10.5284 15.2132 10.7245L5.18586 20.7518C4.98979 20.9479 4.72573 21.045 4.5263 20.9802C3.87156 20.7677 3.23245 20.1287 3.01997 19.4739C2.95523 19.2744 3.05223 19.0104 3.24836 18.8143ZM13.5203 9.83403C13.63 9.90633 13.7517 10.0029 13.8746 10.1256C13.9974 10.2484 14.0939 10.3702 14.1661 10.4799L12.2861 12.3598C12.1765 12.2875 12.0547 12.1911 11.9318 12.0683C11.8091 11.9454 11.7125 11.8237 11.6402 11.7141L13.5203 9.83403Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
