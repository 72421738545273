import React, { MouseEvent } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { FormLayoutActionsItem } from '../types';

type FormActionsProps = {
  item: FormLayoutActionsItem;
};

export default function FormActions({ item }: FormActionsProps) {
  const { getIaAction } = useIaAction();

  return (
    <Box>
      {item.items.map((button) => (
        <Button
          key={button.value}
          variant={button.variant}
          color={button.color}
          disabled={button.disabled}
          prefixIcon={button.icon ? <Icon name={button.icon} /> : undefined}
          onClick={(ev) =>
            getIaAction<MouseEvent<HTMLButtonElement>>(button.value)?.action(ev)
          }
        >
          {button.text}
        </Button>
      ))}
    </Box>
  );
}
