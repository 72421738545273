import { ReactNode } from 'react';
import {
  ClickAwayListener,
  MenuList,
  Popper,
  PopperProps,
  Theme,
  useMediaQuery,
} from '@mui/material';

import BottomSheet, { BottomSheetProps } from '../BottomSheet';

const styles = {
  main: {
    '& .MuiMenuItem-root': {
      minHeight: { xs: 45, md: 28 },
    },
    '& .MuiTypography-body1': {
      fontSize: { xs: 16, md: 14 },
    },
  },
  paper: {
    mx: 1,
    zIndex: 1,
    bgcolor: 'background.menu',
    boxShadow: 9,
    backdropFilter: 'blur(50px)',
    borderRadius: 1,
    border: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? '1px solid rgba(8, 8, 8, 0.05)'
        : '1px solid rgba(255, 255, 255, 0.1)',
    '& .MuiList-root': {
      minWidth: 128,
    },
  },
};

function DesktopMenu({
  sx,
  children,
  onClose,
  slotProps,
  ...rest
}: Omit<PopperProps, 'children'> & {
  onClose: () => void;
  children: ReactNode;
}) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Popper
      sx={[styles.main, styles.paper, ...sxProps]}
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            tether: false,
            rootBoundary: 'viewport',
          },
        },
      ]}
      {...rest}
    >
      <ClickAwayListener onClickAway={onClose}>
        <MenuList>{children}</MenuList>
      </ClickAwayListener>
    </Popper>
  );
}

export type ResponsivePopperProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;

  menuProps: Omit<PopperProps, 'open' | 'onClose' | 'children'>;

  sheetProps?: Omit<BottomSheetProps, 'open' | 'onClose' | 'children'>;
};
export default function ResponsivePopper({
  open,
  onClose,
  children,
  menuProps,
  sheetProps,
}: ResponsivePopperProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (mdUp)
    return (
      <DesktopMenu open={open} onClose={onClose} {...menuProps}>
        {children}
      </DesktopMenu>
    );
  return (
    <BottomSheet sx={styles.main} open={open} onClose={onClose} {...sheetProps}>
      {children}
    </BottomSheet>
  );
}
