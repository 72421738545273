import { ReactElement } from 'react';

import { AlbumLayoutTooltipConfig } from '../types';

import AlbumLayoutListTooltip from './AlbumLayoutListTooltip';
import AlbumLayoutSimpleTooltip from './AlbumLayoutSimpleTooltip';
import {
  AlbumLayoutListTooltipConfig,
  AlbumLayoutSimpleTooltipConfig,
} from './types';

type AlbumLayoutTooltipProps = AlbumLayoutTooltipConfig & {
  children: ReactElement;
};

export default function AlbumLayoutTooltip({
  type,
  config,
  children,
}: AlbumLayoutTooltipProps) {
  if (type === 'list') {
    const listTooltipConfig = config as AlbumLayoutListTooltipConfig['config'];
    return (
      <AlbumLayoutListTooltip config={listTooltipConfig}>
        {children}
      </AlbumLayoutListTooltip>
    );
  }
  if (type === 'simple') {
    const simpleTooltipConfig =
      config as AlbumLayoutSimpleTooltipConfig['config'];
    return (
      <AlbumLayoutSimpleTooltip config={simpleTooltipConfig}>
        {children}
      </AlbumLayoutSimpleTooltip>
    );
  }
  console.warn(`Tooltip type = ${type} is not supported`);
  return null;
}
