import { useState } from 'react';
import { alpha, Box, ButtonBase, Tab, Tabs, Theme } from '@mui/material';
import {
  ActionArrowLeft as ActionArrowLeftIcon,
  ActionArrowRight as ActionArrowRightIcon,
} from '@front/icon';

import { IconButton, Scrollbar } from '../../atoms';
import { Icon } from '../../components';

import { keyboardConfig, KeyboardType } from './config';

export type MathKeyboardProps = {
  onKeyDown?: (key: string) => void;
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1.25,
  },
  keyboard: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(40px, 1fr))',
    gap: '6px',
    '& button': {
      height: 40,
      borderRadius: '10%',
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      fontSize: 20,
    },
  },
  scroll: {
    maxHeight: { xs: 258, sm: 270 },
  },
  tabs: {
    position: 'relative',

    '& .keyboard-tab-scroll-btn': {
      transitionDuration: '0.3s',
      opacity: 0,
    },

    '&:hover .keyboard-tab-scroll-btn': {
      opacity: 1,
    },
  },
  scrollButton: {
    position: 'absolute',
    width: 48,
    top: 0,
    bottom: 0,
    zIndex: 1,
    display: { xs: 'none', md: 'flex' },
    alignItems: 'center',
    pointerEvents: 'none',
    '& button': {
      pointerEvents: 'auto',
    },
  },
  leftButton: {
    left: 0,
    background: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? 'background: linear-gradient(270deg, rgba(248, 248, 248, 0) 0%, #F8F8F8 100%)'
        : 'linear-gradient(270deg, rgba(21, 26, 40, 0) 0%, #151A28 100%)',
  },
  rightButton: {
    right: 0,
    justifyContent: 'flex-end',
    background: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? 'background: linear-gradient(90deg, rgba(248, 248, 248, 0) 0%, #F8F8F8 100%)'
        : 'linear-gradient(90deg, rgba(21, 26, 40, 0) 0%, #151A28 100%)',
  },
};

export default function MathKeyboard({ onKeyDown }: MathKeyboardProps) {
  const [typeIndex, setTypeIndex] = useState(0);
  return (
    <Box sx={styles.root}>
      <Tabs
        value={typeIndex}
        sx={styles.tabs}
        onChange={(ev, newV) => setTypeIndex(newV)}
        variant="scrollable"
        scrollButtons="auto"
        textColor="inherit"
        ScrollButtonComponent={(props) => {
          if (props.direction === 'left' && !props.disabled) {
            return (
              <Box
                className="keyboard-tab-scroll-btn"
                sx={[styles.scrollButton, styles.leftButton]}
              >
                <IconButton
                  customSize={24}
                  onClick={props.onClick}
                  onMouseDown={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <ActionArrowLeftIcon />
                </IconButton>
              </Box>
            );
          } else if (props.direction === 'right' && !props.disabled) {
            return (
              <Box
                className="keyboard-tab-scroll-btn"
                sx={[styles.scrollButton, styles.rightButton]}
              >
                <IconButton
                  customSize={24}
                  onClick={props.onClick}
                  onMouseDown={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <ActionArrowRightIcon />
                </IconButton>
              </Box>
            );
          } else {
            return null;
          }
        }}
      >
        {(Object.keys(keyboardConfig) as unknown as KeyboardType[]).map(
          (configKey) => (
            <Tab
              key={configKey}
              iconPosition="start"
              icon={
                <Icon
                  name={keyboardConfig[configKey].icon}
                  width={16}
                  height={16}
                />
              }
              label={keyboardConfig[configKey].display}
              onMouseDown={(ev) => {
                ev.preventDefault();
              }}
            />
          )
        )}
      </Tabs>

      <Scrollbar sx={styles.scroll}>
        <Box
          sx={styles.keyboard}
          onMouseDown={(ev) => {
            ev.preventDefault();
          }}
        >
          {Object.values(keyboardConfig)[typeIndex].keys.map((keyboardKey) => (
            <ButtonBase
              key={`${typeIndex}-${keyboardKey}`}
              onMouseDown={(ev) => {
                onKeyDown?.(keyboardKey);
                ev.preventDefault();
              }}
            >
              {keyboardKey}
            </ButtonBase>
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );
}
