import React, { ReactNode, useState } from 'react';
import { Box } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const styles = {
  root: {
    position: 'relative',
    width: '100%',
  },
  dragOverBorderBottom: {
    boxShadow: (theme: Theme) =>
      `0 -4px 0 0 ${alpha(theme.palette.primary.light, 0.5)}`,
  },
  dragOverBorderTop: {
    boxShadow: (theme: Theme) =>
      `0 4px 0 0 ${alpha(theme.palette.primary.light, 0.5)}`,
  },
  ghost: {
    width: '100%',
    position: 'absolute',
    opacity: 0.5,
    top: 0,
  },
  itemInner: {
    outline: 'none',
  },
};

type SortableItemProps = {
  id: string;
  children: ({
    sortableItemEl,
  }: {
    sortableItemEl: HTMLDivElement | null;
    isGhostEl?: boolean;
  }) => ReactNode;
};

/**
 *
 * @param id
 * @param children
 * @param width for dragging shadow, which is position absolute so we need to know the width of item
 * @constructor
 */
export default function SortableItem({ id, children }: SortableItemProps) {
  const {
    attributes,
    setNodeRef,
    transform,
    transition,
    isOver,
    isDragging,
    index,
    activeIndex,
  } = useSortable({
    id,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  if (transform) {
    transform.scaleY = 1; // if we don't do this, the height of element will be strange
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box sx={styles.root}>
      <Box
        ref={(el: HTMLDivElement) => {
          setAnchorEl(el);
          setNodeRef(el);
        }}
        {...attributes}
        sx={[
          isOver && activeIndex < index && styles.dragOverBorderTop,
          isOver && activeIndex > index && styles.dragOverBorderBottom,
          styles.itemInner,
        ]}
      >
        {children({
          sortableItemEl: anchorEl,
        })}
      </Box>
      {isDragging && (
        <Box sx={[styles.ghost]} style={style} className="ghost-element">
          {children({
            sortableItemEl: null,
            isGhostEl: true,
          })}
        </Box>
      )}
    </Box>
  );
}
