import { ActionType, dispatch } from './store';
import { AddExportTask } from './types';

const fileTask = {
  add: (task: AddExportTask) => {
    dispatch({
      type: ActionType.ADD,
      task,
    });
  },
  cancel: (id: string) => {
    dispatch({
      type: ActionType.CANCEL,
      id,
    });
  },
  remove: (id: string) => {
    dispatch({
      type: ActionType.REMOVE,
      id,
    });
  },
};

export { fileTask };
