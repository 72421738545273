import { ReactNode } from 'react';
import { alpha, Theme, Typography } from '@mui/material';

import { LabelIcon } from '../../components';

const styles = {
  disabledLabel: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
};

type TextFieldLabelProps = {
  children: ReactNode;
  disabled?: boolean;
  required?: boolean;
  icon?: ReactNode;
  suffixIcon?: ReactNode;
  suffix?: ReactNode;
  labelTooltip?: string;
};
export default function TextFieldLabel({
  children,
  disabled = false,
  icon,
  suffixIcon,
  required,
  suffix,
  labelTooltip,
}: TextFieldLabelProps) {
  return (
    <Typography
      className="textfield-label"
      variant="caption"
      sx={[disabled && styles.disabledLabel]}
    >
      <LabelIcon
        label={children}
        icon={icon}
        suffixIcon={suffixIcon}
        required={required}
        suffix={suffix}
        labelTooltip={labelTooltip}
      />
    </Typography>
  );
}
