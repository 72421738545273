import type { ReactElement, ReactNode, RefObject } from 'react';
import { useCallback, useState } from 'react';

import { useTruncated } from '../../../hooks';
import SimpleTooltip from '../SimpleTooltip';

export interface TruncatedTooltipProps {
  tooltip: ReactNode;
  children: (props: {
    ref: RefObject<HTMLDivElement>;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  }) => ReactElement;
}

export default function TruncatedTooltip({
  tooltip,
  children,
}: TruncatedTooltipProps) {
  const [isHovered, setIsHovered] = useState(false);
  const { containerRef, isTruncated } = useTruncated<HTMLDivElement>({
    enabled: isHovered,
  });

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  if (!isTruncated || !isHovered) {
    return children({
      ref: containerRef,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    });
  }

  return (
    <SimpleTooltip title={tooltip}>
      {children({
        ref: containerRef,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })}
    </SimpleTooltip>
  );
}
