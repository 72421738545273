import React from 'react';
import { v4 } from 'uuid';

export default function EditorMCQ({ ...rest }) {
  const maskId = v4();
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <mask
        height="24"
        id={maskId}
        style={{ maskType: 'alpha' }}
        width="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          d="M12 0C2.4 0 0 2.4 0 12C0 21.6 2.4 24 12 24C21.6 24 24 21.6 24 12C24 2.4 21.6 0 12 0Z"
          fill="white"
          fillRule="evenodd"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect height="24" width="24" fill="#9FC7FF" rx="3" />
        <path
          d="M11.9998 20.3334C16.6022 20.3334 20.3332 16.6025 20.3332 12.0001C20.3332 7.39771 16.6022 3.66675 11.9998 3.66675C7.39746 3.66675 3.6665 7.39771 3.6665 12.0001C3.6665 16.6025 7.39746 20.3334 11.9998 20.3334ZM16.3779 10.3164C16.7033 9.99095 16.7033 9.46331 16.3779 9.13788C16.0524 8.81244 15.5248 8.81244 15.1994 9.13788L10.9114 13.4258L9.22436 11.4575C8.92484 11.1081 8.39876 11.0676 8.04932 11.3671C7.69988 11.6667 7.65942 12.1927 7.95894 12.5422L10.2317 15.1937C10.3827 15.3699 10.6004 15.4752 10.8324 15.4841C11.0643 15.493 11.2895 15.4048 11.4536 15.2406L16.3779 10.3164Z"
          fill="#080808"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
