import { Box, Theme } from '@mui/material';
import { alpha, useMediaQuery } from '@mui/system';
import { ActionOpenInRightPane as ActionOpenInRightPaneIcon } from '@front/icon';
import { Button } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import useTableSelectionContext from '../hooks/useTableSelectionContext';
import { IaTableSidePeekSettings, TableLayoutRow } from '../types';

const styles = {
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '32px',
    minWidth: '67px',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    minWidth: '67px',
    minHeight: 0,
    zIndex: 100,
    px: 1,
    py: 0.5,
    lineHeight: '16px',
    border: 0,
    fontSize: '12px',
    letterSpacing: 'unset',
  },
  rowBorder: {
    boxShadow: (theme: Theme) =>
      `inset 0 -1px 0 0 ${alpha(theme.palette.text.primary, 0.1)}`,
  },
};

type LayoutSetting = {
  showRowBorder: boolean;
  showColumnBorder: boolean;
};

type IaSidePeekButtonProps = {
  row?: TableLayoutRow;
  sidePeekSettings?: IaTableSidePeekSettings;
  layoutSetting?: LayoutSetting;
};

export default function IaSidePeekButton({
  row,
  sidePeekSettings: settings,
  layoutSetting,
}: IaSidePeekButtonProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { getIaAction } = useIaAction();

  const { cellSelected, focusOutCell } = useTableSelectionContext();
  if (mdUp && cellSelected && cellSelected.state === 'active') return null;

  const handleClick = () => {
    if (!settings?.clickAction) return;
    const action = getIaAction<TableLayoutRow>(settings.clickAction.value);
    action?.action(row);
    focusOutCell();
  };

  if (!settings?.enabled) return null;

  return (
    <Box sx={[styles.root, !!layoutSetting?.showRowBorder && styles.rowBorder]}>
      <Button
        prefixIcon={<ActionOpenInRightPaneIcon width={16} height={16} />}
        size="sm"
        sx={styles.button}
        onClick={handleClick}
      >
        Open
      </Button>
    </Box>
  );
}
