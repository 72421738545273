import React, { ReactNode } from 'react';
import { isDesktop } from 'react-device-detect';
import { Box, useMediaQuery } from '@mui/material';
import { alpha, SxProps, Theme } from '@mui/material/styles';
import { TableLayoutCell } from '@lib/ia/src/layouts/TableLayout/types';

import useTableSelectionContext from '../../hooks/useTableSelectionContext';
import { isCellEditable } from '../../utils';

type LayoutSetting = {
  showRowBorder: boolean;
  showColumnBorder: boolean;
};

const styles = {
  root: {
    height: '100%',
    width: '100%',
    minWidth: 0,
  },
  checkbox: {
    position: 'absolute',
    height: '100%',
    width: '24px',
  },
  sidePeek: {
    position: 'absolute',
    height: '100%',
    width: '67px',
  },
  headBorder: {
    boxShadow: (theme: Theme) =>
      `inset 0 -1px 0 0 ${alpha(theme.palette.text.primary, 0.1)}`,
  },
  bodyColumnBorder: {
    boxShadow: (theme: Theme) =>
      `inset 1px 0 0 0 ${alpha(theme.palette.text.primary, 0.1)}`,
  },
  bodyRowBorder: {
    boxShadow: (theme: Theme) =>
      `inset 0 -1px 0 0 ${alpha(theme.palette.text.primary, 0.1)}`,
  },
  bodyRowAndColumnBorder: {
    boxShadow: (theme: Theme) =>
      `inset 1px -1px 0 0 ${alpha(theme.palette.text.primary, 0.1)}`,
  },
  focusedCellBorder: {
    outline: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.primary.light, 0.6)}`,
    boxShadow: (theme: Theme) =>
      `0 0 0 3px ${alpha(theme.palette.primary.light, 0.3)}`,
  },
};

export type IaTableCellBorderProps = {
  children: ReactNode;
  cells?: Record<string, TableLayoutCell>;
  columnOrder: string[];
  columnKey: string;
  layoutSetting?: LayoutSetting;
  rowId: string;
  sx?: SxProps;
};
export default function IaTableCellBorder({
  cells,
  columnOrder,
  columnKey,
  layoutSetting,
  children,
  rowId,
  sx,
}: IaTableCellBorderProps) {
  const cell = cells?.[columnKey];
  /**
   * Indicator is a special case
   * 1. we don't want left border on it
   * 2. the leftmost column should not have left border, but we need to ignore indicator column to find the leftmost column
   */
  const isIndicator = cell?.type === 'indicator';
  const columnOrderWithoutIndicator = columnOrder.filter(
    (key) => cells?.[key].type !== 'indicator'
  );
  const isFirstColumn = columnOrderWithoutIndicator[0] === columnKey;
  const showColumnBorder =
    !!layoutSetting?.showColumnBorder && !isIndicator && !isFirstColumn;
  const showRowBorder = !!layoutSetting?.showRowBorder;

  const { getCellSelectedState, selectCell } = useTableSelectionContext();
  const selectedState = getCellSelectedState(rowId, columnKey);

  const handleClick = () => {
    if (cell?.focusable === false) return;

    const state = isCellEditable(cell) ? 'active' : 'focused';
    /**
     * Even though it may seem that this condition can be ignored, always allow selectCell to execute.
     * However, in DateCell, when the Popover closes, it will automatically re-focus on this cell,
     * which will trigger handleClick again.
     * To prevent this behavior from disrupting our state, we check if state === selectedState.
     */
    if (state === selectedState) return;
    selectCell(rowId, columnKey, state);
  };

  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box
      sx={[
        styles.root,
        showColumnBorder && showRowBorder && styles.bodyRowAndColumnBorder,
        showColumnBorder && !showRowBorder && styles.bodyColumnBorder,
        !showColumnBorder && showRowBorder && styles.bodyRowBorder,
        selectedState === 'focused' && styles.focusedCellBorder,
        ...sxProps,
      ]}
      onClick={handleClick}
      id={`${rowId}-${columnKey}`}
    >
      {children}
    </Box>
  );
}

export function IaTableHeadCellBorder({
  layoutSetting,
  children,
}: {
  layoutSetting?: LayoutSetting;
  children: ReactNode;
}) {
  return (
    <Box
      sx={[styles.root, !!layoutSetting?.showRowBorder && styles.headBorder]}
    >
      {children}
    </Box>
  );
}

export function IaTableCheckboxCellBorder({
  layoutSetting,
}: {
  layoutSetting?: LayoutSetting;
}) {
  if (!isDesktop) {
    return null;
  }

  return (
    <Box
      sx={[
        styles.checkbox,
        !!layoutSetting?.showRowBorder && styles.bodyRowBorder,
      ]}
    />
  );
}

export function IaTableHeadCheckboxCellBorder({
  layoutSetting,
}: {
  layoutSetting?: LayoutSetting;
}) {
  if (!isDesktop) {
    return null;
  }

  return (
    <Box
      sx={[
        styles.checkbox,
        !!layoutSetting?.showRowBorder && styles.headBorder,
      ]}
    />
  );
}

export function IaTableButtonsRowBorder({
  layoutSetting,
  children,
}: {
  layoutSetting?: LayoutSetting;
  children: ReactNode;
}) {
  return (
    <Box
      sx={[styles.root, !!layoutSetting?.showRowBorder && styles.headBorder]}
    >
      {children}
    </Box>
  );
}

export function IaTableHeadSidePeekCellBorder({
  layoutSetting,
  showCheckboxBackground,
  isEmpty,
}: {
  layoutSetting?: LayoutSetting;
  showCheckboxBackground?: boolean;
  isEmpty?: boolean;
}) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (mdUp || isEmpty) {
    return null;
  }

  return (
    <Box
      sx={[
        styles.sidePeek,
        !!layoutSetting?.showRowBorder && styles.headBorder,
        !!showCheckboxBackground && { left: '24px' },
      ]}
    />
  );
}
