import { useMemo } from 'react';
import { nonNullable } from '@front/helper';
import { PassageLineNumType } from '@lib/web/apis';
import {
  basicSlashMenu,
  basicToolbarMenu,
  TextComposer,
} from '@lib/web/composer';
import { TextComposerProps } from '@lib/web/composer/TextComposer/TextComposer';
import { EditorBlockTypes, useCreatorQuestionInfoData } from '@lib/web/editor';
import { editorBlockEditingStyles } from '@lib/web/editor/EditorTextComposer/config/editorComposerBlockStyles';
import { featureEnable } from '@lib/web/thread/config/constants';

import HighlightPassage from './components/HighlightPassage';
import LineNumberPassage from './components/LineNumberPassage';
import {
  EditorComposerSchema,
  editorComposerSchema,
} from './config/editorComposerSchema';
import { editorSlashMenu } from './config/editorSlashMenuItems';
import { editorTiptapExtensions } from './config/editorTiptapExtensions';
import { editorToolbarMenu } from './config/editorToolbarMenuItem';
import { useEnableBlockType } from './hooks/useEnableBlockType';

export type EditorTextComposerProps =
  TextComposerProps<EditorComposerSchema> & {
    questionId: string;
    isPassageEditor?: boolean;
  };

export default function EditorTextComposer({
  questionId,
  isPassageEditor,
  defaultBlocks,
  onBlocksChange,
  ...rest
}: EditorTextComposerProps) {
  const { infoData } = useCreatorQuestionInfoData(questionId);
  const {
    isEnableLineMarker,
    isEnableHighlightAnchor,
    isEnableLineAnchor,
    isEnableHighlight,
    isEnableLatex,
  } = useEnableBlockType(isPassageEditor);

  const slashMenuItems = useMemo(() => {
    return [
      featureEnable.threadInsertAi ? editorSlashMenu.insertAI : null,
      isEnableLatex ? editorSlashMenu.insertLatex : null,
      basicSlashMenu.insertText,
      basicSlashMenu.insertHeader3,
      basicSlashMenu.insertSubtitle,
      isEnableHighlight ? editorSlashMenu.insertHighlight : null,
      isEnableLineMarker ? editorSlashMenu.insertLineMarker : null,
      basicSlashMenu.insertStep,
      editorSlashMenu.insertImage,
      editorSlashMenu.insertAudio,
      basicSlashMenu.insertBlank,
      editorSlashMenu.insertVariable,
    ].filter(nonNullable);
  }, [isEnableHighlight, isEnableLatex, isEnableLineMarker]);

  const toolbarItems = useMemo(() => {
    return [
      basicToolbarMenu.turnIntoText,
      editorToolbarMenu.turnIntoAI,
      basicToolbarMenu.turnIntoHeader,
      basicToolbarMenu.turnIntoSubtitle,
      basicToolbarMenu.turnIntoStep,
      isEnableLineAnchor ? editorToolbarMenu.transformToLineAnchor : null,
      isEnableHighlightAnchor
        ? editorToolbarMenu.transformToHighlightAnchor
        : null,
      editorToolbarMenu.transformToVariable,
    ].filter(nonNullable);
  }, [isEnableHighlightAnchor, isEnableLineAnchor]);

  const disabledBlockTypes = useMemo(() => {
    return [
      isEnableHighlight ? null : EditorBlockTypes.InlineHighlight,
      isEnableHighlightAnchor ? null : EditorBlockTypes.InlineHighlightAnchor,
      isEnableLineMarker ? null : EditorBlockTypes.InlineLineMarker,
      isEnableLineAnchor ? null : EditorBlockTypes.InlineLineAnchor,
      isEnableLatex ? null : EditorBlockTypes.InlineLatex,
      featureEnable.threadInsertAi ? null : EditorBlockTypes.InlineAi,
    ].filter(nonNullable);
  }, [
    isEnableHighlight,
    isEnableHighlightAnchor,
    isEnableLatex,
    isEnableLineAnchor,
    isEnableLineMarker,
  ]);

  // make sure when the supported block type changed, the editor will refresh
  const key = useMemo(
    () =>
      [
        ...slashMenuItems.map((item) => item.nodeType),
        ...toolbarItems.map((item) => item.nodeType),
      ].join('-'),
    [slashMenuItems, toolbarItems]
  );

  return (
    <LineNumberPassage
      enable={!!infoData && isEnableLineAnchor}
      passageLineNumType={
        infoData?.isShowPassageLineNum
          ? infoData.passageLineNumType
          : PassageLineNumType.EveryLine
      }
      isShowPassageLineNum={!!infoData?.isShowPassageLineNum}
      defaultBlocks={defaultBlocks}
    >
      {(lineNumberPassage) => (
        <HighlightPassage
          enable={isEnableHighlightAnchor}
          defaultBlocks={defaultBlocks}
        >
          {(highlightPassage) => (
            <TextComposer
              key={key}
              sx={editorBlockEditingStyles}
              blockSchema={editorComposerSchema}
              slashMenuItems={slashMenuItems}
              toolbarItems={toolbarItems}
              defaultBlocks={defaultBlocks}
              disabledBlockTypes={disabledBlockTypes}
              customTiptapExtensions={editorTiptapExtensions}
              onBlocksChange={(params) => {
                lineNumberPassage.handleBlocksChange(params);
                highlightPassage.handleBlocksChange(params);
                onBlocksChange(params);
              }}
              {...rest}
            />
          )}
        </HighlightPassage>
      )}
    </LineNumberPassage>
  );
}
