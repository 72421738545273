import { useContext } from 'react';
import { useAuth } from '@lib/web/apis';

import { SharedUserContext } from '../contexts';

export default function useCurrentUserId() {
  const { userId } = useAuth();
  const sharerData = useContext(SharedUserContext);

  return sharerData.userId || userId;
}
