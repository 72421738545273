import { useBaseLeftPanel } from '@front/ui';

export default function useToggleLeftPanel() {
  const { hideLeftPanel, enableLeftPanel, openLeftPanel, toggleLeftPanel } =
    useBaseLeftPanel();

  const handleToggleLeftPanel = () => {
    if (hideLeftPanel) {
      enableLeftPanel();
      openLeftPanel();
    } else {
      toggleLeftPanel();
    }
  };

  return handleToggleLeftPanel;
}
