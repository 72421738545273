import {
  QueryAnalyticsMistakeType,
  QueryAnalyticsWeaknessType,
} from '@lib/web/apis';

import generateHook, { SwrHelperParams } from './swr.helper';

const getAPIKey = (page: string, sectionId: string) => {
  return `v2/analytics/${page}/me?sectionId=${sectionId}`;
};

export const useAnalyticsSummary = (sectionId: NullableString) =>
  generateHook<GetAnalyticsSummaryRes>(
    sectionId ? getAPIKey('summary', sectionId) : undefined,
    {
      auth: true,
    }
  );

export const useAnalyticsProgress = (examId: NullableString) =>
  generateHook<GetAnalyticsProgressRes>(
    examId ? getAPIKey('progress', examId) : undefined,
    {
      auth: true,
    }
  );

export const useAnalyticsMistake = (
  examId: NullableString,
  type: QueryAnalyticsMistakeType = QueryAnalyticsMistakeType.Wrong
) =>
  generateHook<GetAnalyticsMistakeRes>(
    examId && type ? `${getAPIKey('mistake', examId)}&type=${type}` : undefined,
    {
      auth: true,
    }
  );

export const useAnalyticsWeakness = (
  sectionId: NullableString,
  type: QueryAnalyticsWeaknessType = QueryAnalyticsWeaknessType.Wrong
) =>
  generateHook<GetAnalyticsWeaknessRes>(
    sectionId && type
      ? `${getAPIKey('weakness', sectionId)}&type=${type}`
      : undefined,
    {
      auth: true,
    }
  );

export type MyPathVariant = 'goal' | 'avg-friend' | 'friends' | 'current';

type MyPathAPIKeyParams<TFriendUserIds extends string[] | undefined> = {
  clubSlug: string;
  variant: MyPathVariant;
  friendUserIds?: TFriendUserIds;
};
const getMyPathAPIKey = <TFriendUserIds extends string[] | undefined>({
  clubSlug,
  variant,
  friendUserIds,
}: MyPathAPIKeyParams<TFriendUserIds>) => {
  const urlParam = new URLSearchParams();
  urlParam.set('clubSlug', clubSlug);

  if (variant === 'friends') {
    if (!friendUserIds?.length) return undefined;
    friendUserIds.forEach((id) => urlParam.append('friendUserId', id));

    return `v2/analytics/overall/compare-path/${variant}?${urlParam.toString()}`;
  }

  return `v2/analytics/overall/compare-path/${variant}?${urlParam.toString()}`;
};

export const useAnalyticsOverallPath = <
  TFriendUserIds extends string[] | undefined
>(
  {
    clubSlug,
    variant,
    friendUserIds = undefined,
  }: MyPathAPIKeyParams<TFriendUserIds>,
  config?: SwrHelperParams
) =>
  generateHook<
    TFriendUserIds extends undefined
      ? GetAnalyticsOverallGoalPathRes
      : GetAnalyticsOverallGoalPathRes[]
  >(
    clubSlug
      ? getMyPathAPIKey({ clubSlug, variant, friendUserIds })
      : undefined,
    {
      auth: true,
      ...config,
    }
  );

const getOverallMistakeAPIKey = (clubSlug: string) => {
  return `v2/analytics/overall/mistake/me?clubSlug=${clubSlug}`;
};

export const useAnalyticsOverallMistake = (
  clubSlug: NullableString,
  type: QueryAnalyticsMistakeType = QueryAnalyticsMistakeType.Wrong,
  config?: SwrHelperParams
) =>
  generateHook<GetAnalyticsOverallMistakeRes>(
    clubSlug && type
      ? `${getOverallMistakeAPIKey(clubSlug)}&type=${type}`
      : undefined,
    {
      auth: true,
      ...config,
    }
  );

const getOverallPracticeCountAPIKey = (clubSlug: string) => {
  return `v2/analytics/overall/practice-count?clubSlug=${clubSlug}`;
};

export const useAnalyticsOverallPracticeCount = (clubSlug: NullableString) =>
  generateHook<GetAnalyticsOverallPracticeCountRes>(
    clubSlug ? getOverallPracticeCountAPIKey(clubSlug) : null,
    {
      auth: true,
    }
  );

export const useAnalyticsWeaknessTopic = (
  sectionId?: NullableString,
  config?: SwrHelperParams
) =>
  generateHook<GetAnalyticsWeaknessTopicRes>(
    sectionId
      ? `v2/analytics/weakness/by-topic?sectionId=${sectionId}`
      : undefined,
    {
      auth: true,
      ...config,
    }
  );
