import { createContext, ReactNode, useMemo } from 'react';

import { PracticeQuestionResultContextValue } from '../types';

const initialValue: PracticeQuestionResultContextValue = {
  isCorrect: false,
  isOvertime: false,
  isStreak: false,
  correctAnswerValues: [],
  correctAnswerIds: [],
};

export const PracticeQuestionResultContext =
  createContext<PracticeQuestionResultContextValue>(initialValue);

export type PracticeQuestionResultContextProviderProps = {
  children: ReactNode;
} & {
  result: Omit<PracticeQuestionResultContextValue, 'statistic'>;
  statistic?: GetQuizQuestionOptionStatisticRes[];
};

export const PracticeQuestionResultContextProvider = ({
  children,
  statistic,
  result,
}: PracticeQuestionResultContextProviderProps) => {
  const memoizedValue = useMemo(() => {
    return { ...result, statistic };
  }, [result, statistic]);

  return (
    <PracticeQuestionResultContext.Provider value={memoizedValue}>
      {children}
    </PracticeQuestionResultContext.Provider>
  );
};
