import { ComposerBlock } from '@lib/web/composer';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';

export function getNewMentionMemberIdsFromBlocks(blocks: ComposerBlock[]) {
  let result: string[] = [];

  blocks.forEach((block) => {
    if (
      block.type === BasicBlockTypes.InlineMention &&
      (block.attrs.metadata as any)?.memberId &&
      !(block.attrs.metadata as any)?.isInThread
    ) {
      result.push((block.attrs.metadata as any).memberId);
    } else if (block.content?.length) {
      result = result.concat(getNewMentionMemberIdsFromBlocks(block.content));
    }
  });

  return result;
}

export function getMentionMemberIdsFromBlocks(blocks: ComposerBlock[]) {
  let result: string[] = [];

  blocks.forEach((block) => {
    if (
      block.type === BasicBlockTypes.InlineMention &&
      (block.attrs.metadata as any)?.memberId
    ) {
      result.push((block.attrs.metadata as any).memberId);
    } else if (block.content?.length) {
      result = result.concat(getMentionMemberIdsFromBlocks(block.content));
    }
  });

  return result;
}
