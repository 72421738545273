import React from 'react';

export default function TradingComment({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 5C7.52363 5 4 8.40061 4 12.4696C4 14.3146 4.71594 16.0088 5.91697 17.3204C6.27291 17.7091 6.42714 18.3058 6.20385 18.8626C6.04228 19.2655 5.84306 19.6418 5.60389 19.9917C6.30185 19.9577 7.08162 19.8123 7.80461 19.4427C8.15354 19.2644 8.5709 19.2314 8.95714 19.3799C9.89308 19.7398 10.92 19.9392 12 19.9392C16.4764 19.9392 20 16.5386 20 12.4696C20 8.40061 16.4764 5 12 5ZM2 12.4696C2 7.18333 6.53524 3 12 3C17.4648 3 22 7.18333 22 12.4696C22 17.7559 17.4648 21.9392 12 21.9392C10.7644 21.9392 9.57845 21.7266 8.48234 21.3366C6.90359 22.0655 5.26829 22.0534 4.26363 21.947C3.54992 21.8715 3.15482 21.3215 3.0545 20.8287C2.95826 20.3559 3.0873 19.8081 3.48742 19.4166C3.78722 19.1233 4.02728 18.7931 4.21514 18.4144C2.83454 16.7948 2 14.7269 2 12.4696Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
