import { createContext, ReactNode, useMemo } from 'react';

import { PracticeQuestionStatusContextValue } from '../types';

const initialValue: PracticeQuestionStatusContextValue = {
  isPendingSubmit: false,
  isLoading: false,
  isSubmitted: false,
  isSkipped: false,
  startAt: null,
  timeSpent: 0,
};

export const PracticeQuestionStatusContext =
  createContext<PracticeQuestionStatusContextValue>(initialValue);

export type PracticeQuestionStatusContextProviderProps = {
  children: ReactNode;
} & { status: PracticeQuestionStatusContextValue };

export const PracticeQuestionStatusContextProvider = ({
  children,
  status,
}: PracticeQuestionStatusContextProviderProps) => {
  const memoizedValue = useMemo(() => {
    return { ...status };
  }, [status]);

  return (
    <PracticeQuestionStatusContext.Provider value={memoizedValue}>
      {children}
    </PracticeQuestionStatusContext.Provider>
  );
};
