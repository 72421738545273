import { Box, Typography } from '@mui/material';
import { Icon } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { styles } from '../styles';
import { WorkflowItem, WorkflowLayoutConfig } from '../types';

import WorkflowCard from './WorkflowCard';

export default function WorkflowEditMode({
  items = [],
  settings,
}: WorkflowLayoutConfig) {
  const { getIaAction } = useIaAction();

  const handleDelete = (itemToRemove: WorkflowItem): void => {
    getIaAction<{ item: WorkflowItem }>(
      settings.actionMap?.delete?.value
    )?.action({ item: itemToRemove });
  };

  const handleAdd = (): void => {
    getIaAction(settings.actionMap?.add?.value)?.action();
  };

  const handleSelect = (item: WorkflowItem): void => {
    getIaAction<{ item: WorkflowItem }>(
      settings.actionMap?.select?.value
    )?.action({ item });
  };

  return (
    <Box sx={styles.root}>
      {items.map((item, index) => (
        <Box key={item.id} sx={styles.workflowItem}>
          <WorkflowCard
            item={item}
            index={index}
            mode="edit"
            settings={settings}
            onDelete={() => handleDelete(item)}
            onSelect={() => handleSelect(item)}
          />
          <Box sx={styles.arrow}>
            <Icon name="ActionArrowDown" />
          </Box>
        </Box>
      ))}

      <Box sx={[styles.addNewCard, styles.addNewCardHover]} onClick={handleAdd}>
        {settings?.addNewCardIcon && (
          <Icon name={settings.addNewCardIcon} width={16} height={16} />
        )}
        {settings.addNewCardText && (
          <Typography>{settings.addNewCardText}</Typography>
        )}
      </Box>
    </Box>
  );
}
