import { useMemo } from 'react';
import toast from 'react-hot-toast';
import Box, { BoxProps } from '@mui/material/Box';
import { keyframes } from '@emotion/react';

import BaseSnackbar, { BaseSnackbarProps } from '../../Snackbar/BaseSnackbar';
import MaybeDontShowAgainSnackbar, {
  MaybeDontShowAgainSnackbarProps,
} from '../../Snackbar/MaybeDontShowAgainSnackbar';
import ProcessSnackbar, {
  ProcessSnackbarProps,
} from '../../Snackbar/ProcessSnackbar';
import { getToastAnchor } from '../utils';

export type AllSnackbarType =
  | (BaseSnackbarProps & { variant: 'base' })
  | (ProcessSnackbarProps & { variant: 'loading' })
  | (MaybeDontShowAgainSnackbarProps & { variant: 'maybe-dont-show-again' });

export type SnackbarStyleType = {
  sx?: BoxProps['sx'];
  mobileSx?: BoxProps['sx'];
  desktopSx?: BoxProps['sx'];
};

export type ToastSnackbarChildProps = AllSnackbarType &
  SnackbarStyleType & {
    onClose?: () => void;
  };

export type ToastSnackbarProps = ToastSnackbarChildProps & {
  id?: string;
  anchorEl?: Element | null;
  anchorYGap?: number;
  anchorXGap?: number;
  visible?: boolean;
  disableClose?: boolean;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`;

function SnackbarItem({
  variant = 'base',
  onClose,
  ...rest
}: ToastSnackbarChildProps) {
  if (variant === 'loading')
    return <ProcessSnackbar {...(rest as ProcessSnackbarProps)} />;
  if (variant === 'maybe-dont-show-again')
    return (
      <MaybeDontShowAgainSnackbar
        {...(rest as MaybeDontShowAgainSnackbarProps)}
      />
    );
  return <BaseSnackbar onClose={onClose} {...(rest as BaseSnackbarProps)} />;
}

export default function ToastSnackbar({
  id,
  anchorEl,
  anchorYGap = 20,
  anchorXGap = 0,
  visible,
  sx,
  mobileSx = {},
  desktopSx = {},
  disableClose,
  onClose,
  ...rest
}: ToastSnackbarProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const [offsetX = 0, offsetY = 0] = useMemo(
    () =>
      getToastAnchor(
        anchorEl || (document.querySelector('[data-toast-anchor]') as Element)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClose = () => {
    if (onClose) onClose();
    toast.dismiss(id);
  };

  const adjustOffsetY = offsetY ? offsetY + anchorYGap : 0;
  const adjustOffsetX = offsetX ? offsetX + anchorXGap : 0;

  const translateX = offsetX
    ? `calc(${-adjustOffsetX}px + var(--toaster-container-inset-x))`
    : '0px';
  const translateY = offsetY
    ? `calc(${-adjustOffsetY}px + var(--toaster-container-inset-y))`
    : '0px';

  return (
    <Box
      className="toast-snackbar"
      sx={[
        (theme) => ({
          opacity: 0,
          visibility: 'hidden',
          animation: `${visible ? fadeIn : fadeOut} 0.3s ease-in-out forwards`,
          transform: `translate(${translateX}, ${translateY})`,
          [theme.breakpoints.down('md')]: {
            transform: `translate(0px, ${translateY})`,
            width: '100%',
            ...mobileSx,
          },
          [theme.breakpoints.up('md')]: {
            ...desktopSx,
          },
        }),
        ...sxProps,
      ]}
    >
      <SnackbarItem
        onClose={disableClose ? undefined : handleClose}
        {...(rest as ToastSnackbarChildProps)}
      />
    </Box>
  );
}
