import { DiscountCodePurchaseType } from '../types/enums';

import api from './client';

const marketplaceApi = {
  addPromoCode: (
    discountCode: string,
    purchaseType?: DiscountCodePurchaseType
  ) =>
    api.post<Response<GetMyPromoCodeRes>>('marketplace/v1/promo-code/me', {
      discountCode,
      purchaseType,
    }),
  createSubscription: (params: CreateSubscriptionReq) =>
    api.post<Response<CreateSubscriptionRes>>(
      '/marketplace/v1/subscription',
      params
    ),
  createBillingPortalSession: (params: CreateBillingPortalSessionReq) =>
    api.post('/marketplace/v1/stripe/portal/session', params),
  updateSubscription: (params: UpdateSubscriptionReq) =>
    api.put<Response<UpdateSubscriptionRes>>(
      '/marketplace/v1/subscription',
      params
    ),
  cancelSubscription: (params: CancelSubscriptionReq) =>
    api.post<Response<CancelSubscriptionRes>>(
      '/marketplace/v1/subscription/cancel',
      params
    ),
  addMyProductFavorite: (productId: string) =>
    api.post(`/marketplace/v1/product/${productId}/favorite/me`),
  removeMyProductFavorite: (productId: string) =>
    api.delete(`/marketplace/v1/product/${productId}/favorite/me`),
  getProductStock: (productId: string) =>
    api.get(`/api/marketplace/products/${productId}/stock`, { baseURL: '' }),
  selectMyActiveNft: (params: SelectMyActiveNftReq) =>
    api.post('/marketplace/v1/user/product/nft/select/me', params),
  getProduct: (productId: string) =>
    api.get(`/api/marketplace/products/${productId}`, { baseURL: '' }),
  getSubscriptionOrderInfo: (orderId: string) =>
    api.get<Response<GetSubscriptionOrderInfoRes>>(
      `/marketplace/v1/subscription/order?orderId=${orderId}`
    ),
  cancelSubscriptionOrder: (params: CancelSubscriptionOrderReq) =>
    api.post<Response<CancelSubscriptionOrderRes>>(
      '/marketplace/v1/subscription/order/cancel',
      params
    ),
  getProductOrderInfo: (orderId: string) =>
    api.get<Response<GetProductOrderInfoRes>>(
      `/marketplace/v1/product/order?orderId=${orderId}`
    ),
  shareProductOrderInfo: (params: ShareProductOrderInfoReq) =>
    api.post<Response<ShareProductOrderInfoRes>>(
      '/marketplace/v1/product/order/share',
      params
    ),
  shareSubscriptionOrderInfo: (params: ShareSubscriptionOrderInfoReq) =>
    api.post<Response<ShareSubscriptionOrderInfoRes>>(
      '/marketplace/v1/subscription/order/share',
      params
    ),
  shareAvatar: (params: ShareAvatarReq) =>
    api.post<Response<ShareAvatarRes>>('/marketplace/v1/product/share', params),
  addDiscountCode: (params: AddDiscountCodeReq) =>
    api.post<Response<GetIaCampaignDiscountCode>>(
      'marketplace/v1/campaign-discount-code',
      params
    ),
  deleteDiscountCodes: (ids: string[]) =>
    api.delete(`marketplace/v1/campaign-discount-code`, { data: { ids } }),
};

export default marketplaceApi;
