import Box from '@mui/material/Box';
import { keyframes, useTheme } from '@mui/material/styles';
import {
  OtherProcessDark as OtherProcessDarkIcon,
  OtherProcessLight as OtherProcessLightIcon,
} from '@front/icon';

import BaseSnackbar, { BaseSnackbarProps } from '../BaseSnackbar';

export type ProcessSnackbarProps = Omit<BaseSnackbarProps, 'type'>;
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const styles = {
  icon: {
    width: 16,
    height: 16,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    animation: `${rotate} 1s infinite ease`,
    color: 'text.primary',
  },
};

export default function ProcessSnackbar({
  sx,
  message,
  ...rest
}: ProcessSnackbarProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  return (
    <BaseSnackbar
      sx={sxProps}
      customizeIcon={
        <Box sx={styles.icon}>
          {isDark ? (
            <OtherProcessDarkIcon width={16} height={16} />
          ) : (
            <OtherProcessLightIcon width={16} height={16} />
          )}
        </Box>
      }
      message={message}
      {...rest}
    />
  );
}
