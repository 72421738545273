import { ClubBotType } from '../types/enums';

import api from './client';

const clubApi = {
  getClubDetail: (clubSlug: string) =>
    api.get<Response<GetClubDetailRes>>(`v2/club/${clubSlug}/detail`),
  getClubAnswerFormatPreference: (clubSlug: string) =>
    api.get<Response<GetClubAnswerFormatPreferenceRes[]>>(
      `v2/club/answer-format/preference?clubSlug=${clubSlug}`
    ),
  getClubSetting: (clubSlug: string) =>
    api.get<Response<GetClubSettingRes>>(
      `/v2/club/setting?clubSlug=${clubSlug}`
    ),
  joinClub: (clubSlug: string) => api.post('v2/club/join', { clubSlug }),
  leaveClub: (clubSlug: string) => api.post('v2/club/leave', { clubSlug }),
  requestJoinClub: (clubSlug: string) =>
    api.post('v2/club/join-request', { clubSlug }),
  cancelJoinClub: (clubSlug: string) =>
    api.post('v2/club/join-request/cancel', { clubSlug }),
  acceptJoinRequest: (clubSlug: string, userId: string) =>
    api.post('v2/club/join-request/accept', { clubSlug, userId }),
  rejectJoinRequest: (clubSlug: string, userId: string) =>
    api.post('v2/club/join-request/reject', { clubSlug, userId }),
  reportOnlineMember: (params: ReportClubOnlineMemberReq) =>
    api.post('v2/club/online-member/activity', params),
  sendClubInvitation: (params: SendClubInvitationReq) =>
    api.post<Response<PostSendClubInvitation>>('/v2/club/invitation', params),
  acceptClubInvitation: (params: AcceptClubInvitationReq) =>
    api.post<Response<PostAcceptClubInvitation>>(
      '/v2/club/invitation/accept',
      params
    ),
  createClub: (params: CreateClubReq) =>
    api.post<Response<CreateClubRes>>('/v2/club', params),
  updateClub: (params: UpdateClubReq) =>
    api.put<Response<CreateClubRes>>('/v2/club', params),
  deleteClub: (clubSlug: string) =>
    api.delete<Response<{ count: number }>>('/v2/club', { data: { clubSlug } }),
  updateClubAnswerFormats: (params: UpdateClubAnswerFormatsReq) =>
    api.post<Response<GetClubAnswerFormatPreferenceRes[]>>(
      '/v2/club/answer-format/preference',
      params
    ),
  updateClubSetting: (params: UpdateClubSettingReq) =>
    api.put<Response<GetClubSettingRes>>('/v2/club/setting', params),
  addClubMembers: (params: AddClubMemberReq) =>
    api.post<Response<AddClubMemberRes>>('/v2/club/member', params),
  updateClubMembers: (params: AddClubMemberReq) =>
    api.put<Response<AddClubMemberRes>>('/v2/club/member', params),
  deleteClubMembers: (params: RemoveClubMemberReq) =>
    api.delete<Response<{ count: number }>>('/v2/club/member', {
      data: params,
    }),
  getClubBots: (clubSlug: string, agentType: ClubBotType, pageSize = 100) =>
    api.get<Response<GetClubBotsRes>>(
      `/v2/club/${clubSlug}/agent?agentType=${agentType}&limit=${pageSize}`
    ),
  createClubBots: (params: CreateClubBotsReq) =>
    api.post<Response<CreateClubBotsRes>>('v2/club/agent', params),
};

export default clubApi;
