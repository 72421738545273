import React from 'react';

export default function OtherIncognito({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1293 8.27765L4.73302 9.88235H2.69767C2.51264 9.88235 2.33518 9.95672 2.20434 10.0891C2.0735 10.2215 2 10.401 2 10.5882C2 10.7754 2.0735 10.955 2.20434 11.0874C2.33518 11.2197 2.51264 11.2941 2.69767 11.2941H21.3023C21.4874 11.2941 21.6648 11.2197 21.7957 11.0874C21.9265 10.955 22 10.7754 22 10.5882C22 10.401 21.9265 10.2215 21.7957 10.0891C21.6648 9.95672 21.4874 9.88235 21.3023 9.88235H19.2679L18.8707 8.27765C18.3628 6.22306 18.1098 5.19624 17.3526 4.59765C16.5953 4 15.5488 4 13.4558 4H10.5442C8.45116 4 7.40465 4 6.64744 4.59765C5.89023 5.19624 5.63628 6.22306 5.1293 8.27765ZM6.88372 20C7.60212 20 8.30036 19.7596 8.86971 19.3163C9.43905 18.8731 9.84756 18.2518 10.0316 17.5492L11.0642 17.0268C11.3548 16.8799 11.6751 16.8034 12 16.8034C12.3249 16.8034 12.6452 16.8799 12.9358 17.0268L13.9684 17.5492C14.1718 18.3216 14.6458 18.9929 15.3021 19.438C15.9584 19.8831 16.7522 20.0716 17.5357 19.9684C18.3192 19.8652 19.0389 19.4774 19.5607 18.8772C20.0825 18.277 20.3709 17.5053 20.3721 16.7059C20.3733 15.8965 20.08 15.1149 19.548 14.5104C19.0161 13.9058 18.2827 13.5206 17.488 13.4282C16.6932 13.3358 15.8926 13.5427 15.239 14.0094C14.5853 14.4761 14.1244 15.17 13.9442 15.9586L13.56 15.7647C13.0756 15.5197 12.5415 15.3921 12 15.3921C11.4585 15.3921 10.9244 15.5197 10.44 15.7647L10.0558 15.9586C9.87389 15.1716 9.41242 14.4797 8.75918 14.0145C8.10594 13.5493 7.30649 13.3432 6.51288 13.4355C5.71926 13.5277 4.98685 13.9119 4.45492 14.5149C3.92299 15.1179 3.62866 15.8976 3.62791 16.7059C3.62791 17.5795 3.97093 18.4174 4.58151 19.0352C5.1921 19.6529 6.02023 20 6.88372 20Z"
        fill="currentColor"
      />
    </svg>
  );
}
