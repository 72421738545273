import React from 'react';

export default function OtherTransferHorizontal({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5 16.4444L8.5 12.8889M8.5 7.55556H19H8.5ZM19 7.55556L15.5 4L19 7.55556ZM19 7.55556L15.5 11.1111L19 7.55556ZM15.5 16.4444H5H15.5ZM5 16.4444L8.5 20L5 16.4444Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}
