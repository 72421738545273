import React from 'react';

export default function MainProfileOutline({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 4.8C10.5272 4.8 9.33333 6.00883 9.33333 7.5C9.33333 8.99117 10.5272 10.2 12 10.2C13.4728 10.2 14.6667 8.99117 14.6667 7.5C14.6667 6.00883 13.4728 4.8 12 4.8ZM7.55556 7.5C7.55556 5.01472 9.5454 3 12 3C14.4546 3 16.4444 5.01472 16.4444 7.5C16.4444 9.98528 14.4546 12 12 12C9.5454 12 7.55556 9.98528 7.55556 7.5ZM5.30175 15.118C6.13524 14.2741 7.2657 13.8 8.44444 13.8H15.5556C16.7343 13.8 17.8648 14.2741 18.6983 15.118C19.5317 15.9619 20 17.1065 20 18.3V20.1C20 20.5971 19.602 21 19.1111 21C18.6202 21 18.2222 20.5971 18.2222 20.1V18.3C18.2222 17.5839 17.9413 16.8972 17.4412 16.3908C16.9411 15.8845 16.2628 15.6 15.5556 15.6H8.44444C7.7372 15.6 7.05892 15.8845 6.55883 16.3908C6.05873 16.8972 5.77778 17.5839 5.77778 18.3V20.1C5.77778 20.5971 5.37981 21 4.88889 21C4.39797 21 4 20.5971 4 20.1V18.3C4 17.1065 4.46825 15.9619 5.30175 15.118Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
