import React from 'react';

export default function OtherCheckedCircleSolid({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM17.2536 9.97957C17.6442 9.58904 17.6442 8.95588 17.2536 8.56535C16.8631 8.17483 16.23 8.17483 15.8394 8.56535L10.6939 13.7109L8.66943 11.3489C8.31001 10.9296 7.67871 10.8811 7.25938 11.2405C6.84006 11.5999 6.7915 12.2312 7.15092 12.6505L9.87819 15.8323C10.0595 16.0438 10.3207 16.1701 10.5991 16.1808C10.8774 16.1915 11.1476 16.0856 11.3446 15.8887L17.2536 9.97957Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
