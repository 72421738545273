import { Box } from '@mui/material';
import { AnimateBox } from '@front/ui';
import { AnswerFormatType } from '@lib/web/apis';

import usePracticeQuestion from '../../../../hooks/usePracticeQuestion';
import AnswerHint from '../AnswerHint';

import QuizAnswerEssay from './components/QuizAnswerEssay';
import QuizAnswerFreeResponse from './components/QuizAnswerFreeResponse';
import QuizAnswerGridIn from './components/QuizAnswerGridIn';
import QuizAnswerMultipleChoices from './components/QuizAnswerMultipleChoices';
import QuizAnswerTrueFalseNotGiven from './components/QuizAnswerTrueFalseNotGiven';
import QuizAnswerUnscramble from './components/QuizAnswerUnscramble';

const getCreatorOptions = ({
  answerFormatType,
  readonly,
}: {
  answerFormatType: AnswerFormatType;
  readonly?: boolean;
}) => {
  switch (answerFormatType) {
    case AnswerFormatType.MultipleChoice:
      return (
        <QuizAnswerMultipleChoices selectionType="single" readonly={readonly} />
      );

    case AnswerFormatType.MultipleResponse:
      return (
        <QuizAnswerMultipleChoices
          selectionType="multiple"
          readonly={readonly}
        />
      );

    case AnswerFormatType.GridIn:
      return <QuizAnswerGridIn readonly={readonly} />;

    case AnswerFormatType.TrueFalseNotGiven:
      return <QuizAnswerTrueFalseNotGiven readonly={readonly} />;

    case AnswerFormatType.FreeResponse:
      return <QuizAnswerFreeResponse readonly={readonly} />;

    case AnswerFormatType.Unscramble:
      return <QuizAnswerUnscramble readonly={readonly} />;

    case AnswerFormatType.Essay:
      return <QuizAnswerEssay readonly={readonly} />;

    default:
      console.error('not supported answer format type', answerFormatType);
      return null;
  }
};

export type QuizAnswerOptionsProps = {
  readonly?: boolean;
};

export default function QuizAnswerOptions({
  readonly,
}: QuizAnswerOptionsProps) {
  const { id, answerFormatType } = usePracticeQuestion();

  const creatorOptions = getCreatorOptions({ answerFormatType, readonly });

  return (
    <AnimateBox variable={id}>
      <AnswerHint readonly={readonly} />
      <Box
        height={
          [AnswerFormatType.FreeResponse, AnswerFormatType.Essay].includes(
            answerFormatType
          )
            ? 8
            : 16
        }
      />
      {creatorOptions}
    </AnimateBox>
  );
}
