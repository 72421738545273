import React from 'react';
import { useSortable } from '@dnd-kit/sortable';

export type SortableActivatorProps = {
  id: string;
  children: (
    listeners: ReturnType<typeof useSortable>['listeners'],
    setActivatorNodeRef: ReturnType<typeof useSortable>['setActivatorNodeRef']
  ) => React.ReactNode;
};

export default function SortableActivator({
  id,
  children,
}: SortableActivatorProps) {
  const { listeners, setActivatorNodeRef } = useSortable({
    id,
  });

  return children(listeners, setActivatorNodeRef);
}
