import { ReactNode } from 'react';
import { isBrowser } from 'react-device-detect';
import Box from '@mui/material/Box';

import FooterActions from './FooterActions';
import Hint from './Hint';
import Timer from './Timer';

const styles = {
  wrapper: {
    pt: '12px',
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: 85,
  },
  buttons: {
    py: 0,
    flex: 1,
    justifyContent: 'flex-end',
  },
};

type DesktopFooterProps = {
  extraContent?: ReactNode;
};

export default function DesktopFooter({ extraContent }: DesktopFooterProps) {
  return (
    <>
      <Box sx={styles.wrapper}>
        {extraContent}
        <Box flex={1}>
          <FooterActions sx={styles.buttons} />
          {isBrowser && <Hint />}
        </Box>
      </Box>
      <Timer />
    </>
  );
}
