import {
  forwardRef,
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
} from 'react';
import { Box, Fade, Popper, PopperProps, Theme } from '@mui/material';
import { BoxProps } from '@mui/material/Box';

import {
  IconButton,
  IconButtonProps,
  ResponsiveTooltip,
  ResponsiveTooltipProps,
} from '../../atoms';

export const floatingMenuStyles = {
  root: {
    pr: 1,
  },
  menu: {
    display: 'flex',
    p: '1.5px',
    bgcolor: 'background.menu',
    borderRadius: 1,
    boxShadow: (theme: Theme) =>
      `inset 0 0 0 1px ${
        theme.palette.mode === 'light'
          ? 'rgba(8, 8, 8, 0.05)'
          : 'rgba(255, 255, 255, 0.1)'
      }`,
  },
  popper: {
    zIndex: 3000,
  },
  tooltip: {
    minWidth: 170,
    '& .MuiTooltip-tooltip': {
      p: 1,
    },
  },
  tooltipWhenEmpty: {
    '& .MuiTooltip-tooltip': {
      p: 0,
    },
  },
};
export type FloatingMenuProps = PopperProps & {
  open: PopperProps['open'];
  sx?: BoxProps['sx'];
  children: ReactNode;
};

const FloatingMenuButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ ...rest }, ref) => {
    return <IconButton ref={ref} customSize={24} {...rest} />;
  }
);

const FloatingMenuTipButton = forwardRef<
  HTMLButtonElement,
  Omit<IconButtonProps, 'title'> & {
    title: ReactNode;
    tooltipProps?: ResponsiveTooltipProps;
  }
>(({ title, tooltipProps, ...rest }, ref) => {
  return (
    <ResponsiveTooltip
      title={title}
      tooltipProps={{
        slotProps: {
          popper: {
            sx: title
              ? floatingMenuStyles.tooltip
              : floatingMenuStyles.tooltipWhenEmpty,
          },
        },
        disableInteractive: true,
      }}
      hideInDevices
      {...tooltipProps}
    >
      <Box display="inline-flex">
        <IconButton ref={ref} customSize={24} {...rest} />
      </Box>
    </ResponsiveTooltip>
  );
});

const FloatingMenu = forwardRef(
  (
    {
      open,
      children,
      anchorEl,
      sx,
      popperOptions,
      placement = 'left',
      className = '',
      transition = true,
      ...rest
    }: FloatingMenuProps,
    ref
  ) => {
    const sxProps = Array.isArray(sx) ? sx : [sx];

    const popperContent = (
      <Box
        ref={ref}
        sx={floatingMenuStyles.root}
        className={`floating-menu-root ${className}`}
      >
        <Box sx={floatingMenuStyles.menu}>{children}</Box>
      </Box>
    );

    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition={transition}
        placement={placement}
        sx={[floatingMenuStyles.popper, ...sxProps]}
        popperOptions={popperOptions}
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              tether: false,
              rootBoundary: 'viewport',
            },
          },
        ]}
        {...rest}
      >
        {({ TransitionProps }) =>
          transition ? (
            <Fade {...TransitionProps}>{popperContent}</Fade>
          ) : (
            popperContent
          )
        }
      </Popper>
    );
  }
) as FloatingMenuExtends;

type FloatingMenuExtends = ForwardRefExoticComponent<
  Omit<FloatingMenuProps, 'ref'> & RefAttributes<unknown>
> & {
  Button: typeof FloatingMenuButton;
  TipButton: typeof FloatingMenuTipButton;
};

FloatingMenu.Button = FloatingMenuButton;
FloatingMenu.TipButton = FloatingMenuTipButton;
export default FloatingMenu;
