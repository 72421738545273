import React, { SVGAttributes } from 'react';

export default function EMojiCoins({ ...rest }: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width="78"
      height="130"
      viewBox="0 0 78 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M29.7432 43.8233V37.3993L15.8744 28.9141V35.351L29.7432 43.8233Z"
        fill="#FFF497"
      />
      <path
        d="M29.7432 43.8233V37.3993L15.8744 28.9141V35.351L29.7432 43.8233Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M25.4009 52.4889V46.0649L11.5321 37.5796V44.0165L25.4009 52.4889Z"
        fill="#FFF176"
      />
      <path
        d="M25.4009 52.4889V46.0649L11.5321 37.5796V44.0165L25.4009 52.4889Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M21.0278 61.0655V54.6415L7.15908 46.1562V52.5932L21.0278 61.0655Z"
        fill="#FFF176"
      />
      <path
        d="M21.0278 61.0655V54.6415L7.15908 46.1562V52.5932L21.0278 61.0655Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M21.0278 67.4986V61.0746L7.15908 52.5894V59.0263L21.0278 67.4986Z"
        fill="#F2BC1A"
      />
      <path
        d="M21.0278 67.4986V61.0746L7.15908 52.5894V59.0263L21.0278 67.4986Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M16.6646 76.0636V69.6396L2.7958 61.1543V67.5912L16.6646 76.0636Z"
        fill="#FFF176"
      />
      <path
        d="M16.6646 76.0636V69.6396L2.7958 61.1543V67.5912L16.6646 76.0636Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M16.6646 82.4688V76.0448L2.7958 67.5596V73.9965L16.6646 82.4688Z"
        fill="#FFF176"
      />
      <path
        d="M16.6646 82.4688V76.0448L2.7958 67.5596V73.9965L16.6646 82.4688Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M16.6646 88.8746V82.4506L2.7958 73.9653V80.4023L16.6646 88.8746Z"
        fill="#FFF176"
      />
      <path
        d="M16.6646 88.8746V82.4506L2.7958 73.9653V80.4023L16.6646 88.8746Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M29.7725 114.659V108.235L15.9037 99.75V106.187L29.7725 114.659Z"
        fill="#E8C89B"
      />
      <path
        d="M29.7725 114.659V108.235L15.9037 99.75V106.187L29.7725 114.659Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M25.3789 110.418V103.994L11.5102 95.5083V101.945L25.3789 110.418Z"
        fill="#D58E2F"
      />
      <path
        d="M25.3789 110.418V103.994L11.5102 95.5083V101.945L25.3789 110.418Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M21.0361 106.111V99.6869L7.16738 91.2017V97.6386L21.0361 106.111Z"
        fill="#CE8521"
      />
      <path
        d="M21.0361 106.111V99.6869L7.16738 91.2017V97.6386L21.0361 106.111Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M21.0361 99.7501V93.3261L7.16738 84.8408V91.2778L21.0361 99.7501Z"
        fill="#F9D748"
      />
      <path
        d="M21.0361 99.7501V93.3261L7.16738 84.8408V91.2778L21.0361 99.7501Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M69.062 43.6891L64.6878 45.8395L50.8296 37.3647L55.1847 35.2363L69.062 43.6891Z"
        fill="#FFF176"
      />
      <path
        d="M69.062 43.6891L64.6878 45.8395L50.8296 37.3647L55.1847 35.2363L69.062 43.6891Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M64.686 32.9801L60.3118 35.1305L46.4536 26.6557L50.8088 24.5273L64.686 32.9801Z"
        fill="#FFF176"
      />
      <path
        d="M64.686 32.9801L60.3118 35.1305L46.4536 26.6557L50.8088 24.5273L64.686 32.9801Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M60.3081 28.7384L55.9339 30.8888L42.0757 22.414L46.4308 20.2856L60.3081 28.7384Z"
        fill="#FFF176"
      />
      <path
        d="M60.3081 28.7384L55.9339 30.8888L42.0757 22.414L46.4308 20.2856L60.3081 28.7384Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M51.5981 26.6095L47.2239 28.7599L33.3657 20.2851L37.7209 18.1567L51.5981 26.6095Z"
        fill="#FFF176"
      />
      <path
        d="M51.5981 26.6095L47.2239 28.7599L33.3657 20.2851L37.7209 18.1567L51.5981 26.6095Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M47.2446 28.7736L42.8704 30.924L29.0122 22.4491L33.3673 20.3208L47.2446 28.7736Z"
        fill="#E19103"
      />
      <path
        d="M47.2446 28.7736L42.8704 30.924L29.0122 22.4491L33.3673 20.3208L47.2446 28.7736Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M42.8906 30.9381L38.5164 33.0885L24.6582 24.6137L29.0133 22.4854L42.8906 30.9381Z"
        fill="#E19305"
      />
      <path
        d="M42.8906 30.9381L38.5164 33.0885L24.6582 24.6137L29.0133 22.4854L42.8906 30.9381Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M38.5371 33.1027L34.1629 35.2531L20.3047 26.7782L24.6598 24.6499L38.5371 33.1027Z"
        fill="#FFF176"
      />
      <path
        d="M38.5371 33.1027L34.1629 35.2531L20.3047 26.7782L24.6598 24.6499L38.5371 33.1027Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M34.1836 35.2672L29.8094 37.4176L15.9512 28.9428L20.3063 26.8145L34.1836 35.2672Z"
        fill="#FFF497"
      />
      <path
        d="M34.1836 35.2672L29.8094 37.4176L15.9512 28.9428L20.3063 26.8145L34.1836 35.2672Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M29.7432 43.8595L25.3689 46.0099L11.5108 37.5351L15.8659 35.4067L29.7432 43.8595Z"
        fill="#FFF176"
      />
      <path
        d="M29.7432 43.8595L25.3689 46.0099L11.5108 37.5351L15.8659 35.4067L29.7432 43.8595Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M25.4009 52.4699L21.0267 54.6203L7.16848 46.1454L11.5236 44.0171L25.4009 52.4699Z"
        fill="#FFF176"
      />
      <path
        d="M25.4009 52.4699L21.0267 54.6203L7.16848 46.1454L11.5236 44.0171L25.4009 52.4699Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M21.0278 67.4967L16.6536 69.6471L2.79543 61.1723L7.15055 59.0439L21.0278 67.4967Z"
        fill="#FFF176"
      />
      <path
        d="M21.0278 67.4967L16.6536 69.6471L2.79543 61.1723L7.15055 59.0439L21.0278 67.4967Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M29.7558 37.4269L34.123 35.2617V41.6965L29.7558 43.8617V37.4269Z"
        fill="#FFF497"
      />
      <path
        d="M34.1225 35.2619L38.4897 33.0967V39.5315L34.1225 41.6967V35.2619Z"
        fill="#FFF176"
      />
      <path
        d="M38.4901 33.0968L42.8574 30.9316V37.3664L38.4901 39.5316V33.0968Z"
        fill="#E19305"
      />
      <path
        d="M47.2245 28.7663L51.5918 26.6011V33.0359L47.2245 35.2011V28.7663Z"
        fill="#FFF176"
      />
      <path
        d="M42.8578 30.9313L47.2251 28.7661V35.2009L42.8578 37.3661V30.9313Z"
        fill="#E19103"
      />
      <path
        d="M51.5922 26.6012L55.9595 24.436V30.8708L51.5922 33.036V26.6012Z"
        fill="#FFF27F"
      />
      <path
        d="M55.9589 30.8708L60.3262 28.7056V35.1404L55.9589 37.3056V30.8708Z"
        fill="#FFF176"
      />
      <path
        d="M55.9589 50.175L60.3262 48.0098V54.4446L55.9589 56.6098V50.175Z"
        fill="#F2BC1A"
      />
      <path
        d="M55.9589 69.4797L60.3262 67.3145V73.7493L55.9589 75.9145V69.4797Z"
        fill="#F2BC1A"
      />
      <path
        d="M55.9589 88.7839L60.3262 86.6187V93.0535L55.9589 95.2187V88.7839Z"
        fill="#D69031"
      />
      <path
        d="M60.3266 48.0099L64.6938 45.8447V52.2795L60.3266 54.4447V48.0099Z"
        fill="#F1CC4E"
      />
      <path
        d="M60.3266 67.3141L64.6938 65.1489V71.5837L60.3266 73.7489V67.3141Z"
        fill="#F3D659"
      />
      <path
        d="M64.6942 45.8444L69.0615 43.6792V50.114L64.6942 52.2792V45.8444Z"
        fill="#FFF176"
      />
      <path
        d="M64.6942 65.1491L69.0615 62.9839V69.4187L64.6942 71.5839V65.1491Z"
        fill="#FEEF75"
      />
      <path
        d="M29.7558 43.862L34.123 41.6968V48.1316L29.7558 50.2968V43.862Z"
        fill="#F2BC1C"
      />
      <path
        d="M29.7558 63.1662L34.123 61.001V67.4358L29.7558 69.601V63.1662Z"
        fill="#FFF176"
      />
      <path
        d="M29.7558 82.4709L34.123 80.3057V86.7405L29.7558 88.9057V82.4709Z"
        fill="#FFF176"
      />
      <path
        d="M29.7558 101.775L34.123 99.6099V106.045L29.7558 108.21V101.775Z"
        fill="#FFF176"
      />
      <path
        d="M34.1225 41.6965L38.4897 39.5312V45.9661L34.1225 48.1313V41.6965Z"
        fill="#F2BC1A"
      />
      <path
        d="M34.1225 61.0011L38.4897 58.8359V65.2707L34.1225 67.4359V61.0011Z"
        fill="#FFF176"
      />
      <path
        d="M34.1225 80.3053L38.4897 78.1401V84.5749L34.1225 86.7401V80.3053Z"
        fill="#F2BC1A"
      />
      <path
        d="M34.1225 99.61L38.4897 97.4448V103.88L34.1225 106.045V99.61Z"
        fill="#F2BC1A"
      />
      <path
        d="M38.4901 39.5314L42.8574 37.3662V43.801L38.4901 45.9662V39.5314Z"
        fill="#F2BC1A"
      />
      <path
        d="M38.4901 58.8356L42.8574 56.6704V63.1052L38.4901 65.2704V58.8356Z"
        fill="#FFF176"
      />
      <path
        d="M38.4901 78.1403L42.8574 75.9751V82.4099L38.4901 84.5751V78.1403Z"
        fill="#FFF176"
      />
      <path
        d="M38.4901 97.4445L42.8574 95.2793V101.714L38.4901 103.879V97.4445Z"
        fill="#F2BC1A"
      />
      <path
        d="M21.0209 67.4967L25.3882 65.3315V71.7663L21.0209 73.9315V67.4967Z"
        fill="#E8B535"
      />
      <path
        d="M21.0209 86.8009L25.3882 84.6357V91.0705L21.0209 93.2357V86.8009Z"
        fill="#F9E66A"
      />
      <path
        d="M47.2245 35.2008L51.5918 33.0356V39.4704L47.2245 41.6356V35.2008Z"
        fill="#F2BC1A"
      />
      <path
        d="M47.2245 54.5055L51.5918 52.3403V58.7751L47.2245 60.9403V54.5055Z"
        fill="#FFF176"
      />
      <path
        d="M47.2245 73.8097L51.5918 71.6445V78.0793L47.2245 80.2445V73.8097Z"
        fill="#F2BC1A"
      />
      <path
        d="M47.2245 93.1144L51.5918 90.9492V97.384L47.2245 99.5492V93.1144Z"
        fill="#F2BC1A"
      />
      <path
        d="M16.6537 69.6618L21.021 67.4966V73.9314L16.6537 76.0966V69.6618Z"
        fill="#FFF176"
      />
      <path
        d="M16.6537 88.9665L21.021 86.8013V93.2361L16.6537 95.4013V88.9665Z"
        fill="#F2D260"
      />
      <path
        d="M42.8578 37.3664L47.2251 35.2012V41.636L42.8578 43.8012V37.3664Z"
        fill="#F2BC1A"
      />
      <path
        d="M42.8578 56.6706L47.2251 54.5054V60.9402L42.8578 63.1054V56.6706Z"
        fill="#FFF176"
      />
      <path
        d="M42.8578 75.9753L47.2251 73.8101V80.2449L42.8578 82.4101V75.9753Z"
        fill="#FFF176"
      />
      <path
        d="M42.8578 95.2795L47.2251 93.1143V99.5491L42.8578 101.714V95.2795Z"
        fill="#F2BC1A"
      />
      <path
        d="M51.5922 33.0358L55.9595 30.8706V37.3054L51.5922 39.4706V33.0358Z"
        fill="#F2BC1A"
      />
      <path
        d="M51.5922 52.34L55.9595 50.1748V56.6096L51.5922 58.7748V52.34Z"
        fill="#FFF176"
      />
      <path
        d="M51.5922 71.6447L55.9595 69.4795V75.9143L51.5922 78.0795V71.6447Z"
        fill="#FFF176"
      />
      <path
        d="M51.5922 90.9489L55.9595 88.7837V95.2185L51.5922 97.3837V90.9489Z"
        fill="#FFF277"
      />
      <path
        d="M25.3881 46.027L29.7554 43.8618V50.2966L25.3881 52.4618V46.027Z"
        fill="#FFF176"
      />
      <path
        d="M25.3881 65.3317L29.7554 63.1665V69.6013L25.3881 71.7665V65.3317Z"
        fill="#F2BC1A"
      />
      <path
        d="M25.3881 84.6359L29.7554 82.4707V88.9055L25.3881 91.0707V84.6359Z"
        fill="#F2BC1A"
      />
      <path
        d="M25.3881 103.94L29.7554 101.775V108.21L25.3881 110.375V103.94Z"
        fill="#D58E2F"
      />
      <path
        d="M55.9589 37.3053L60.3262 35.1401V41.5749L55.9589 43.7401V37.3053Z"
        fill="#F2BC1A"
      />
      <path
        d="M55.9589 56.61L60.3262 54.4448V60.8796L55.9589 63.0448V56.61Z"
        fill="#F2BC1A"
      />
      <path
        d="M55.9589 75.9142L60.3262 73.749V80.1838L55.9589 82.349V75.9142Z"
        fill="#D68908"
      />
      <path
        d="M60.3266 35.1403L64.6938 32.9751V39.4099L60.3266 41.5751V35.1403Z"
        fill="#FFF176"
      />
      <path
        d="M60.3266 54.4445L64.6938 52.2793V58.7141L60.3266 60.8793V54.4445Z"
        fill="#F2BC1A"
      />
      <path
        d="M60.3266 73.7492L64.6938 71.584V78.0188L60.3266 80.184V73.7492Z"
        fill="#F7CD37"
      />
      <path
        d="M64.6942 52.2795L69.0615 50.1143V56.5491L64.6942 58.7143V52.2795Z"
        fill="#FFF176"
      />
      <path
        d="M29.7558 50.2965L34.123 48.1313V54.5661L29.7558 56.7313V50.2965Z"
        fill="#E09A0C"
      />
      <path
        d="M29.7558 69.6012L34.123 67.436V73.8708L29.7558 76.036V69.6012Z"
        fill="#FFF176"
      />
      <path
        d="M29.7558 88.9054L34.123 86.7402V93.175L29.7558 95.3402V88.9054Z"
        fill="#D68908"
      />
      <path
        d="M29.7558 108.21L34.123 106.045V112.48L29.7558 114.645V108.21Z"
        fill="#E8C89B"
      />
      <path
        d="M34.1225 48.1315L38.4897 45.9663V52.4011L34.1225 54.5663V48.1315Z"
        fill="#F2BC1A"
      />
      <path
        d="M34.1225 67.4357L38.4897 65.2705V71.7053L34.1225 73.8705V67.4357Z"
        fill="#F2BC1A"
      />
      <path
        d="M34.1225 86.7404L38.4897 84.5752V91.01L34.1225 93.1752V86.7404Z"
        fill="#D68908"
      />
      <path
        d="M34.1225 106.045L38.4897 103.879V110.314L34.1225 112.479V106.045Z"
        fill="#BC6F02"
      />
      <path
        d="M38.4901 45.9665L42.8574 43.8013V50.2361L38.4901 52.4013V45.9665Z"
        fill="#F9DA4D"
      />
      <path
        d="M38.4901 65.2707L42.8574 63.1055V69.5403L38.4901 71.7055V65.2707Z"
        fill="#F5C931"
      />
      <path
        d="M38.4901 84.5749L42.8574 82.4097V88.8445L38.4901 91.0097V84.5749Z"
        fill="#D68908"
      />
      <path
        d="M38.4901 103.88L42.8574 101.714V108.149L38.4901 110.314V103.88Z"
        fill="#F0CE5D"
      />
      <path
        d="M21.0209 54.6271L25.3882 52.4619V58.8967L21.0209 61.0619V54.6271Z"
        fill="#FFF176"
      />
      <path
        d="M21.0209 73.9313L25.3882 71.7661V78.2009L21.0209 80.3661V73.9313Z"
        fill="#F2BC1A"
      />
      <path
        d="M21.0209 93.236L25.3882 91.0708V97.5056L21.0209 99.6708V93.236Z"
        fill="#F9D748"
      />
      <path
        d="M47.2245 41.6359L51.5918 39.4707V45.9055L47.2245 48.0707V41.6359Z"
        fill="#F2BC1A"
      />
      <path
        d="M47.2245 60.9401L51.5918 58.7749V65.2097L47.2245 67.3749V60.9401Z"
        fill="#F2BC1A"
      />
      <path
        d="M47.2245 80.2448L51.5918 78.0796V84.5144L47.2245 86.6796V80.2448Z"
        fill="#D68908"
      />
      <path
        d="M47.2245 99.549L51.5918 97.3838V103.819L47.2245 105.984V99.549Z"
        fill="#BB6E00"
      />
      <path
        d="M16.6537 76.0968L21.021 73.9316V80.3664L16.6537 82.5316V76.0968Z"
        fill="#FFF176"
      />
      <path
        d="M42.8578 43.8009L47.2251 41.6357V48.0705L42.8578 50.2357V43.8009Z"
        fill="#FCE561"
      />
      <path
        d="M42.8578 63.1056L47.2251 60.9404V67.3752L42.8578 69.5404V63.1056Z"
        fill="#FCE45F"
      />
      <path
        d="M42.8578 82.4098L47.2251 80.2446V86.6794L42.8578 88.8446V82.4098Z"
        fill="#D68908"
      />
      <path
        d="M42.8578 101.715L47.2251 99.5493V105.984L42.8578 108.149V101.715Z"
        fill="#F0CE5D"
      />
      <path
        d="M51.5922 39.4709L55.9595 37.3057V43.7405L51.5922 45.9057V39.4709Z"
        fill="#D88B05"
      />
      <path
        d="M51.5922 58.7751L55.9595 56.6099V63.0447L51.5922 65.2099V58.7751Z"
        fill="#FFF277"
      />
      <path
        d="M51.5922 78.0793L55.9595 75.9141V82.3489L51.5922 84.5141V78.0793Z"
        fill="#D68908"
      />
      <path
        d="M51.5922 97.384L55.9595 95.2188V101.654L51.5922 103.819V97.384Z"
        fill="#E2B77C"
      />
      <path
        d="M25.3881 52.4621L29.7554 50.2969V56.7317L25.3881 58.8969V52.4621Z"
        fill="#F2BC1A"
      />
      <path
        d="M25.3881 71.7663L29.7554 69.6011V76.0359L25.3881 78.2011V71.7663Z"
        fill="#F2BC1A"
      />
      <path
        d="M25.3881 91.0705L29.7554 88.9053V95.3401L25.3881 97.5053V91.0705Z"
        fill="#DB930A"
      />
      <path
        d="M55.9589 43.7404L60.3262 41.5752V48.01L55.9589 50.1752V43.7404Z"
        fill="#F2BC1A"
      />
      <path
        d="M55.9589 63.0446L60.3262 60.8794V67.3142L55.9589 69.4794V63.0446Z"
        fill="#F2BC1A"
      />
      <path
        d="M55.9589 82.3493L60.3262 80.1841V86.6189L55.9589 88.7841V82.3493Z"
        fill="#F2BC1A"
      />
      <path
        d="M60.3266 41.5749L64.6938 39.4097V45.8445L60.3266 48.0097V41.5749Z"
        fill="#F2BC1A"
      />
      <path
        d="M60.3266 60.8796L64.6938 58.7144V65.1492L60.3266 67.3144V60.8796Z"
        fill="#F2BC1A"
      />
      <path
        d="M60.3266 80.1838L64.6938 78.0186V84.4534L60.3266 86.6186V80.1838Z"
        fill="#D5962D"
      />
      <path
        d="M64.6942 58.714L69.0615 56.5488V62.9836L64.6942 65.1488V58.714Z"
        fill="#FFF176"
      />
      <path
        d="M29.7558 56.7316L34.123 54.5664V61.0012L29.7558 63.1664V56.7316Z"
        fill="#F2BB19"
      />
      <path
        d="M29.7558 76.0358L34.123 73.8706V80.3054L29.7558 82.4706V76.0358Z"
        fill="#FFF176"
      />
      <path
        d="M29.7558 95.3405L34.123 93.1753V99.6101L29.7558 101.775V95.3405Z"
        fill="#FFF176"
      />
      <path
        d="M34.1225 54.5661L38.4897 52.4009V58.8357L34.1225 61.0009V54.5661Z"
        fill="#F5C529"
      />
      <path
        d="M34.1225 73.8708L38.4897 71.7056V78.1404L34.1225 80.3056V73.8708Z"
        fill="#F2BC1A"
      />
      <path
        d="M34.1225 93.175L38.4897 91.0098V97.4446L34.1225 99.6098V93.175Z"
        fill="#F2BC1A"
      />
      <path
        d="M38.4901 52.401L42.8574 50.2358V56.6706L38.4901 58.8358V52.401Z"
        fill="#FFF176"
      />
      <path
        d="M38.4901 71.7052L42.8574 69.54V75.9748L38.4901 78.14V71.7052Z"
        fill="#F5C931"
      />
      <path
        d="M38.4901 91.0099L42.8574 88.8447V95.2795L38.4901 97.4447V91.0099Z"
        fill="#F2BC1A"
      />
      <path
        d="M21.0209 61.0617L25.3882 58.8965V65.3313L21.0209 67.4965V61.0617Z"
        fill="#F2BC1A"
      />
      <path
        d="M21.0209 80.3664L25.3882 78.2012V84.636L21.0209 86.8012V80.3664Z"
        fill="#F2BC1A"
      />
      <path
        d="M21.0209 99.6706L25.3882 97.5054V103.94L21.0209 106.105V99.6706Z"
        fill="#CE8521"
      />
      <path
        d="M47.2245 48.0705L51.5918 45.9053V52.3401L47.2245 54.5053V48.0705Z"
        fill="#F3C022"
      />
      <path
        d="M47.2245 67.3752L51.5918 65.21V71.6448L47.2245 73.81V67.3752Z"
        fill="#F2BC1A"
      />
      <path
        d="M47.2245 86.6794L51.5918 84.5142V90.949L47.2245 93.1142V86.6794Z"
        fill="#F2BC1A"
      />
      <path
        d="M16.6537 82.5314L21.021 80.3662V86.801L16.6537 88.9662V82.5314Z"
        fill="#FFF176"
      />
      <path
        d="M42.8578 50.236L47.2251 48.0708V54.5056L42.8578 56.6708V50.236Z"
        fill="#FFF176"
      />
      <path
        d="M42.8578 69.5402L47.2251 67.375V73.8098L42.8578 75.975V69.5402Z"
        fill="#FCE35F"
      />
      <path
        d="M42.8578 88.8449L47.2251 86.6797V93.1145L42.8578 95.2797V88.8449Z"
        fill="#F2BC1A"
      />
      <path
        d="M51.5922 45.9054L55.9595 43.7402V50.175L51.5922 52.3402V45.9054Z"
        fill="#F2BB19"
      />
      <path
        d="M51.5922 65.2096L55.9595 63.0444V69.4792L51.5922 71.6444V65.2096Z"
        fill="#FFF277"
      />
      <path
        d="M51.5922 84.5143L55.9595 82.3491V88.7839L51.5922 90.9491V84.5143Z"
        fill="#FFF277"
      />
      <path
        d="M25.3881 58.8966L29.7554 56.7314V63.1662L25.3881 65.3314V58.8966Z"
        fill="#F2BC1A"
      />
      <path
        d="M25.3881 78.2008L29.7554 76.0356V82.4704L25.3881 84.6356V78.2008Z"
        fill="#F2BC1A"
      />
      <path
        d="M25.3881 97.5055L29.7554 95.3403V101.775L25.3881 103.94V97.5055Z"
        fill="#F2BC1A"
      />
      <path
        d="M55.9517 24.445L51.5774 26.5954L37.7193 18.1205L42.0744 15.9922L55.9517 24.445Z"
        fill="#FFF27F"
      />
      <path
        d="M55.9517 24.445L51.5774 26.5954L37.7193 18.1205L42.0744 15.9922L55.9517 24.445Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M16.6646 95.2804V88.8564L2.7958 80.3711V86.808L16.6646 95.2804Z"
        fill="#F2D260"
      />
      <path
        d="M16.6646 95.2804V88.8564L2.7958 80.3711V86.808L16.6646 95.2804Z"
        fill="black"
        fillOpacity="0.4"
      />
    </svg>
  );
}
