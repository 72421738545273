/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import {
  answerFormatChanged,
  answerFormatChanging,
  reloadSetting,
  reloadSettingFinish,
  syncHighlightOptions,
  syncLineMarkerOptions,
} from '../actions/settingActions';

import { SettingState } from './types';

const initialState: SettingState = {
  lineMarker: {
    options: [],
  },
  highlight: {
    options: [],
  },
  answerFormatChanging: false,
  reloadSetting: false,
};

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(syncLineMarkerOptions, (state, action) => {
        state.lineMarker.options = action.payload;
      })
      .addCase(syncHighlightOptions, (state, action) => {
        state.highlight.options = action.payload;
      })
      .addCase(answerFormatChanging, (state) => {
        state.answerFormatChanging = true;
      })
      .addCase(answerFormatChanged, (state) => {
        state.answerFormatChanging = false;
      })
      .addCase(reloadSetting, (state) => {
        state.reloadSetting = true;
      })
      .addCase(reloadSettingFinish, (state) => {
        state.reloadSetting = false;
      });
  },
});

export default settingSlice.reducer;
