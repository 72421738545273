import { Icon } from '@front/ui';

import { AlbumLayoutIconExtensionConfig } from './types';

type AlbumLayoutIconExtensionProps = {
  config: AlbumLayoutIconExtensionConfig['config'];
};
export default function AlbumLayoutIconExtension({
  config,
}: AlbumLayoutIconExtensionProps) {
  const { value, size = 32 } = config;
  return <Icon name={value} width={size} height={size} />;
}
