import React, { Fragment, useMemo } from 'react';
import Link from 'next/link';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { HeatMapNode } from '@front/ui';

import { NODE_WIDTH } from '../constants';

const styles = {
  node: {
    display: 'block',
    width: NODE_WIDTH,
    height: NODE_WIDTH,
    borderRadius: 1,

    '&:active': {
      border: 'none !important',
      outline: 'none !important',
    },
  },
  nodeHoverable: {
    '@media (hover: hover)': {
      '&:hover': {
        border: (theme: Theme) =>
          `1px solid ${theme.palette.background.darker}`,
        outline: (theme: Theme) => `2px solid ${theme.palette.text.primary}`,
      },
    },
  },
  nodeClickable: {
    cursor: 'pointer',
  },
};

type NodeProps = HeatMapNode;

function Node({ color, outlineColor, href, hoverable = true }: NodeProps) {
  return (
    <Box
      sx={[
        styles.node,
        { backgroundColor: color },
        !!hoverable && styles.nodeHoverable,
        !!href && styles.nodeClickable,
        !!outlineColor && {
          border: (theme: Theme) =>
            `1px solid ${theme.palette.background.darker}`,
          outline: `2px solid ${outlineColor}`,
        },
      ]}
      {...(href ? { href, component: Link } : {})}
    />
  );
}

export type HeatMapBodyProps = {
  nodes: NodeProps[];
  nodesPerRow: number;
};

export default function HeatMapBody({ nodes, nodesPerRow }: HeatMapBodyProps) {
  const theme = useTheme();

  const paddedNodes = useMemo(() => {
    const remainingSlots = nodesPerRow - (nodes.length % nodesPerRow);

    return remainingSlots > 0
      ? nodes.concat(
          new Array(remainingSlots).fill({
            color: {
              dark: 'alpha.lightA10',
              light: 'grey.100', // design doesn't give this value, but we add this to make it not like a bug (because dark color will make this disappear)
            }[theme.palette.mode],
            hoverable: false,
          })
        )
      : nodes;
  }, [nodes, nodesPerRow, theme.palette.mode]);

  return (
    <>
      {paddedNodes.map((node, index) =>
        node.renderTooltip ? (
          <Fragment key={index}>
            {node.renderTooltip(index, <Node {...node} />)}
          </Fragment>
        ) : (
          <Node key={index} {...node} />
        )
      )}
    </>
  );
}
