import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    opacity: 0.64,
  },
};
export default function IaDisplayTableEmptyTextCell() {
  return (
    <Box sx={styles.root}>
      <Typography variant="body2" sx={styles.text}>
        Empty
      </Typography>
    </Box>
  );
}
