import {
  AgentThreadUser,
  HumanThreadUser,
  ThreadUser,
} from '@lib/web/thread/types';

export function isAgentThreadUser(
  user?: ThreadUser | null
): user is AgentThreadUser {
  return user?.type === 'agent';
}

export function isHumanThreadUser(
  user?: ThreadUser | null
): user is HumanThreadUser {
  return user?.type === 'user';
}
