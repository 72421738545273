import { useCallback, useState } from 'react';
import { useLatestValueRef } from '@front/helper';

export default function useUndoableAction({
  undoDurationMs = 3000,
}: {
  undoDurationMs?: number;
} = {}) {
  const [inProgressIds, setInProgressIds] = useState<string[]>([]);
  const inProgressIdsRef = useLatestValueRef(inProgressIds);

  const undoableAction = useCallback(
    (id: string, callback: () => void | Promise<void>) => {
      // this id is already in progress
      if (inProgressIdsRef.current.includes(id)) {
        return;
      }

      setInProgressIds([...inProgressIdsRef.current, id]);
      setTimeout(async () => {
        if (!inProgressIdsRef.current.includes(id)) {
          // this id has been canceled
          return;
        }
        await callback();
        setInProgressIds(inProgressIdsRef.current.filter((i) => i !== id));
      }, undoDurationMs);
    },
    [inProgressIdsRef, undoDurationMs]
  );

  const undoAction = useCallback((id: string) => {
    setInProgressIds((prev) => prev.filter((i) => i !== id));
  }, []);

  const isUndoableActionInProgress = useCallback(
    (id: string) => inProgressIds.includes(id),
    [inProgressIds]
  );

  return {
    undoableAction,
    undoAction,
    isUndoableActionInProgress,
    isAnyActionInProgress: inProgressIds.length > 0,
  };
}
