import React from 'react';

export default function MainChat({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 3C7.03742 3 3.00002 6.61235 3.00002 11.0526C3.00002 12.6047 3.49382 14.1082 4.43042 15.4082C4.25312 17.3578 3.77792 18.8052 3.08792 19.4909C2.99672 19.5816 2.97392 19.7202 3.03152 19.8348C3.08252 19.9371 3.18752 20 3.30002 20C3.31382 20 3.32762 19.9991 3.34172 19.997C3.46322 19.98 6.28562 19.5762 8.32712 18.4047C9.48632 18.8696 10.7211 19.1053 12 19.1053C16.9626 19.1053 21 15.4929 21 11.0526C21 6.61235 16.9626 3 12 3ZM7.80002 12.2456C7.13822 12.2456 6.60002 11.7106 6.60002 11.0526C6.60002 10.3947 7.13822 9.85965 7.80002 9.85965C8.46182 9.85965 9.00002 10.3947 9.00002 11.0526C9.00002 11.7106 8.46182 12.2456 7.80002 12.2456ZM12 12.2456C11.3382 12.2456 10.8 11.7106 10.8 11.0526C10.8 10.3947 11.3382 9.85965 12 9.85965C12.6618 9.85965 13.2 10.3947 13.2 11.0526C13.2 11.7106 12.6618 12.2456 12 12.2456ZM16.2 12.2456C15.5382 12.2456 15 11.7106 15 11.0526C15 10.3947 15.5382 9.85965 16.2 9.85965C16.8618 9.85965 17.4 10.3947 17.4 11.0526C17.4 11.7106 16.8618 12.2456 16.2 12.2456Z"
        fill="currentColor"
      />
    </svg>
  );
}
