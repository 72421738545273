import React from 'react';

export default function OtherSortAscending({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.83565 19.7464C7.21453 20.0832 7.78549 20.0832 8.16438 19.7464L12.6644 15.7466C13.0772 15.3797 13.1143 14.7477 12.7474 14.3349C12.3805 13.9221 11.7484 13.8849 11.3356 14.2518L8.50001 16.7723V4.99963C8.50001 4.44737 8.0523 3.99967 7.50001 3.99967C6.94773 3.99967 6.50001 4.44737 6.50001 4.99963V16.7723L3.66437 14.2518C3.25159 13.8849 2.61952 13.9221 2.2526 14.3349C1.88568 14.7477 1.92286 15.3797 2.33565 15.7466L6.83565 19.7464Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M17.1644 4.25308C16.7855 3.91629 16.2146 3.91629 15.8357 4.25308L11.3357 8.25304C10.923 8.61995 10.8858 9.25202 11.2527 9.6648C11.6196 10.0776 12.2517 10.1148 12.6644 9.74784L15.5001 7.2273L15.5001 19.0003C15.5001 19.5526 15.9478 20.0003 16.5 20.0003C17.0523 20.0003 17.5 19.5526 17.5 19.0003V7.2273L20.3356 9.74784C20.7484 10.1148 21.3805 10.0776 21.7474 9.6648C22.1143 9.25202 22.0771 8.61995 21.6644 8.25304L17.1644 4.25308Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
