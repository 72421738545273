import React from 'react';

export default function ActionCloseSmall({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.24333 6.24333C6.56776 5.91889 7.09378 5.91889 7.41821 6.24333L11.6736 10.4988C11.7602 10.5853 11.8776 10.6339 12 10.6339C12.1224 10.6339 12.2398 10.5853 12.3264 10.4988L16.5818 6.24333C16.9062 5.91889 17.4322 5.91889 17.7567 6.24333C18.0811 6.56776 18.0811 7.09378 17.7567 7.41821L13.5012 11.6736C13.4147 11.7602 13.3661 11.8776 13.3661 12C13.3661 12.1224 13.4147 12.2398 13.5012 12.3264L17.7567 16.5818C18.0811 16.9062 18.0811 17.4322 17.7567 17.7567C17.4322 18.0811 16.9062 18.0811 16.5818 17.7567L12.3264 13.5012C12.2398 13.4147 12.1224 13.3661 12 13.3661C11.8776 13.3661 11.7602 13.4147 11.6736 13.5012L7.41821 17.7567C7.09378 18.0811 6.56776 18.0811 6.24333 17.7567C5.91889 17.4322 5.91889 16.9062 6.24333 16.5818L10.4988 12.3264C10.5853 12.2398 10.6339 12.1224 10.6339 12C10.6339 11.8776 10.5853 11.7602 10.4988 11.6736L6.24333 7.41821C5.91889 7.09378 5.91889 6.56776 6.24333 6.24333Z"
        fill="currentColor"
      />
    </svg>
  );
}
