import React, {
  forwardRef,
  FunctionComponent,
  MouseEvent,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import { alpha, Theme } from '@mui/material/styles';
import { ActionChevronRightSmall as ActionChevronRightSmallIcon } from '@front/icon';
import {
  OtherCheckedCircleSolid as OtherCheckedCircleSolidIcon,
  OtherCircleOutline as OtherCircleOutlineIcon,
} from '@front/icon';
import { TipButton } from '@front/ui';

const styles = {
  root: {
    position: 'relative',
    '& .menu-button-badge': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      px: 0.5,
      py: 0.25,
      borderRadius: 1,
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
      fontSize: 12,
      lineHeight: 1,
      mr: '20px',
    },
  },
  processingRoot: {
    '& + .menu-step-wrap': {
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 2,
        height: 8,
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
        top: 0,
        left: 19,
      },
    },
    '&:has(+ .menu-step-wrap)': {
      '&:after': {
        content: '""',
        position: 'absolute',
        width: 2,
        height: 8,
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
        top: 22,
        left: 19,
      },
    },
  },
  wrap: {
    px: 0.5,
    py: '1.5px',
    width: '100%',
    position: 'relative',
  },
  processingWrap: {
    '& + .menu-step-wrap': {
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 2,
        height: 8,
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
        top: 0,
        left: 19,
      },
    },
    '&:has(+ .menu-step-wrap)': {
      '&:after': {
        content: '""',
        position: 'absolute',
        width: 2,
        height: 8,
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
        bottom: 0,
        left: 19,
      },
    },
  },
  checkedIcon: {
    color: 'success.light',
  },
  subTitle: {
    px: { xs: 2.5, md: '12px' },
    height: 24,
    opacity: 0.5,
    typography: 'caption',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    typography: 'body2',
    gap: 1,
    height: 27,
    px: 1,
    opacity: 0.64,
    borderRadius: 1,

    '&.Mui-disabled': {
      opacity: 0.3,
    },
    '&.is-active': {
      opacity: 1,
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    },
    '&:not(.is-active):hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
  buttonIcon: {
    flex: '0 0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  groupOpened: {
    '& .chevron-icon': {
      transform: 'rotate(90deg)',
    },
  },
  group: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    textAlign: 'left',
  },
  groupTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    typography: 'body2',
  },
  groupContent: {
    '&.level-1 .menu-button, &.level-0 .menu-button': {
      height: 30,
      borderRadius: 1,
      position: 'relative',
      pl: '36px',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 1.5,
        left: 4,
        right: 4,
        bottom: 1.5,
        borderRadius: 0.5,
        zIndex: -1,
      },
      '&:hover': {
        bgcolor: 'unset',
        '&:after': {
          bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
        },
      },
      '&:before': {
        left: '19px',
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
      },
      '&.is-active:before': {
        bgcolor: 'text.primary',
      },
      '&.is-active:after': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
      },
      '&.is-active .menu-button-content': {
        fontWeight: 400,
      },
    },
    '&.level-2 .menu-button': {
      pl: 8,
    },
  },
};

type MenuAppProps = {
  icon?: ReactNode;
  title?: ReactNode;
  defaultOpen?: boolean;
  children: ReactNode;
};

type MenuStepGroupProps = Omit<MenuAppProps, 'defaultOpen'> & {
  level?: number;
  defaultOpen?: boolean;
  disabled?: boolean;
  tooltip?: string;
  active?: boolean;
  href?: string;
  shallow?: boolean;
  checked?: boolean;
};

export type MenuStepGroupHandler = {
  open: () => void;
  close: () => void;
};

const MenuStepGroup = forwardRef<MenuStepGroupHandler, MenuStepGroupProps>(
  (
    {
      children,
      title,
      icon,
      defaultOpen = false,
      level = 1,
      disabled = false,
      checked = false,
      active,
      href,
      shallow,
    },
    ref
  ) => {
    const [open, setOpen] = useState(defaultOpen);
    const [childLoaded, setChildLoaded] = useState(false);
    const pathname = usePathname();
    const isActive = active ?? pathname === href;

    const setOpenState = useCallback((_open: boolean) => {
      setOpen(_open);
      if (!_open) {
        // as long as the child has been rendered, we need to keep them on dom to make animation work
        setChildLoaded(false);
      }
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        open: () => setOpenState(true),
        close: () => setOpenState(false),
      }),
      [setOpenState]
    );

    const handleLinkClick = useCallback(
      (ev: MouseEvent<HTMLButtonElement>): void => {
        if (isActive) {
          ev.preventDefault();
        }
      },
      [isActive]
    );

    const handleButtonClick = useCallback((): void => {
      if (!open) {
        setOpenState(true);
      }

      if (open && !href) {
        // while href is set, we don't want to close the menu
        setOpenState(false);
      }
    }, [href, open, setOpenState]);

    const handleChevronIconClick = useCallback(
      (ev: MouseEvent<HTMLButtonElement>) => {
        setOpenState(!open);
        ev.preventDefault();
      },
      [open, setOpenState]
    );

    return (
      <Box
        sx={[styles.root, !icon && styles.processingRoot]}
        className="menu-step-group-wrap-root"
      >
        <Box sx={styles.wrap} className="menu-step-group-wrap">
          <ButtonBase
            sx={[styles.button, styles.group, open && styles.groupOpened]}
            className={`menu-step-button ${isActive ? 'is-active' : ''}`}
            disabled={disabled}
            {...(href
              ? { href, component: Link, shallow, onClick: handleLinkClick }
              : {
                  onClick: handleButtonClick,
                })}
          >
            {checked && !icon && (
              <Box
                sx={styles.checkedIcon}
                component={OtherCheckedCircleSolidIcon}
                width={16}
                height={16}
              />
            )}
            {!checked && !icon && (
              <OtherCircleOutlineIcon width={16} height={16} />
            )}

            {!!icon && <Box sx={styles.buttonIcon}>{icon}</Box>}

            <Box sx={styles.groupTitle} className="menu-button-content">
              {title}
            </Box>
            {href ? (
              <TipButton
                customSize={24}
                onClick={handleChevronIconClick}
                title={open ? 'Collapse' : 'Expand'}
              >
                <ActionChevronRightSmallIcon className="chevron-icon" />
              </TipButton>
            ) : (
              <ActionChevronRightSmallIcon className="chevron-icon" />
            )}
          </ButtonBase>
        </Box>
        <Collapse in={open}>
          {(childLoaded || open) && (
            <Box sx={styles.groupContent} className={`level-${level}`}>
              {React.Children.map(children, (child) => {
                if (!React.isValidElement(child)) {
                  return null;
                }

                if (typeof child?.type === 'function') {
                  if (
                    (child.type as FunctionComponent).displayName ===
                    'MenuGroup'
                  ) {
                    return React.cloneElement(child, {
                      ...child.props,
                      level: level + 1,
                      disabled: child.props.disabled || disabled,
                    });
                  }
                }
                return React.cloneElement(child, {
                  ...child.props,
                  disabled: child.props.disabled || disabled,
                });
              })}
            </Box>
          )}
        </Collapse>
      </Box>
    );
  }
);

export default MenuStepGroup;
