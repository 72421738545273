import React from 'react';

export default function ProfileStatistic({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.9 3C4.39706 3 4.8 3.40294 4.8 3.9V18.3C4.8 18.7971 5.20294 19.2 5.7 19.2H20.1C20.5971 19.2 21 19.6029 21 20.1C21 20.5971 20.5971 21 20.1 21H5.7C4.20883 21 3 19.7912 3 18.3V3.9C3 3.40294 3.40294 3 3.9 3Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M14.7 7.5C14.7 7.00294 15.1029 6.6 15.6 6.6H20.1V11.4787C20.1 11.9758 19.6971 12.3787 19.2 12.3787C18.7029 12.3787 18.3 11.9758 18.3 11.4787V9.93293L15.1158 13.6478C14.4335 14.4439 13.2178 14.4906 12.4764 13.7492L11.1746 12.4474L8.20278 16.1622C7.89227 16.5504 7.32591 16.6133 6.93777 16.3028C6.54964 15.9923 6.48671 15.4259 6.79722 15.0378L9.76906 11.323C10.4392 10.4852 11.6888 10.416 12.4474 11.1746L13.7492 12.4764L17.2432 8.4H15.6C15.1029 8.4 14.7 7.99706 14.7 7.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
