import { nonBooleanAndNullable } from '@front/helper';
import {
  FormLayoutItem,
  FormLayoutItemStatus,
} from '@lib/ia/src/layouts/FormLayout/types';
import {
  AnswerContentType,
  AnswerFormatType,
  PassageLineNumType,
} from '@lib/web/apis';
import {
  ESSAY_WORD_LIMIT_MAX_DEFAULT,
  ESSAY_WORD_LIMIT_MIN_DEFAULT,
  FREE_RESPONSE_WORD_LIMIT_MAX_DEFAULT,
  FREE_RESPONSE_WORD_LIMIT_MIN_DEFAULT,
  MCQ_ANSWER_NUM_MAX,
  MCQ_ANSWER_NUM_MIN,
  MRQ_ANSWER_NUM_DEFAULT,
  MRQ_ANSWER_NUM_MAX,
  MRQ_ANSWER_NUM_MIN,
  MRQ_CORRECT_ANSWER_NUM_DEFAULT,
  MRQ_CORRECT_ANSWER_NUM_MAX,
  MRQ_CORRECT_ANSWER_NUM_MIN,
  UNSCRAMBLE_ANSWER_NUM_MAX,
  UNSCRAMBLE_ANSWER_NUM_MIN,
} from '@lib/web/editor/configs/answerFormats';
import { DEFAULT_LOCALE } from '@lib/web/utils';
import { TFunction } from 'i18next';

type MinMaxRuleParams = {
  fieldNameKey: string;
  minKey: string;
  minValue: number;
  maxValue: number;
  maxKey: string;
  regexKey?: string;
  regexValue?: RegExp;
  minValueWithFieldKey?: string;
  minValueWithFieldName?: string;
  minValueWithFieldDefault?: number;
  maxValueWithFieldKey?: string;
  maxValueWithFieldName?: string;
  maxValueWithFieldDefault?: number;
  requiredKey?: string;
};

const getMinMaxRule = (
  t: TFunction,
  {
    minKey,
    minValue,
    maxKey,
    maxValue,
    requiredKey,
    regexKey,
    regexValue = /^[0-9]+$/,
    minValueWithFieldKey,
    minValueWithFieldName,
    minValueWithFieldDefault,
    maxValueWithFieldKey,
    maxValueWithFieldName,
    maxValueWithFieldDefault,
  }: MinMaxRuleParams
) => {
  const formatter = new Intl.NumberFormat(DEFAULT_LOCALE);

  return {
    rules: {
      min: {
        value: minValue,
        message: t(minKey, {
          value: formatter.format(minValue),
        }),
      },
      max: {
        value: maxValue,
        message: t(maxKey, {
          value: formatter.format(maxValue),
        }),
      },
      ...(requiredKey
        ? {
            required: {
              value: true,
              message: t(requiredKey),
            },
          }
        : null),
    },
    customRules: {
      ...(regexKey
        ? {
            regex: {
              value: regexValue,
              message: t(regexKey),
            },
          }
        : null),
      ...(minValueWithFieldKey && minValueWithFieldName
        ? {
            minValueWithField: {
              field: minValueWithFieldName,
              message: t(minValueWithFieldKey),
              defaultValue: minValueWithFieldDefault,
            },
          }
        : null),
      ...(maxValueWithFieldKey && maxValueWithFieldName
        ? {
            maxValueWithField: {
              field: maxValueWithFieldName,
              message: t(maxValueWithFieldKey),
              defaultValue: maxValueWithFieldDefault,
            },
          }
        : null),
    },
  };
};
const getFieldSetting = (
  t: TFunction,
  field: string,
  fieldName?: string,
  minmaxRule?: MinMaxRuleParams
): FormLayoutItem => {
  switch (field) {
    case 'answerContentType': {
      return {
        type: 'radioGroup',
        name: fieldName || field,
        label: t('edit.RHS.contentType.label'),
        options: [
          {
            label: t('edit.RHS.contentType.textual.label'),
            hint: t('edit.RHS.contentType.textual.hint'),
            value: AnswerContentType.Textual,
          },
          {
            label: t('edit.RHS.contentType.math.label'),
            hint: t('edit.RHS.contentType.math.hint'),
            value: AnswerContentType.Mathematical,
          },
        ],
      };
    }
    case 'isShowPassageLineNum': {
      return {
        type: 'switch',
        name: fieldName || field,
        icon: 'TextEditorNumbering',
        label: t('edit.RHS.lines.label'),
        helperText: t('edit.RHS.lines.hint'),
      };
    }
    case 'passageLineNumType': {
      return {
        type: 'radioGroup',
        name: fieldName || field,
        label: '',
        options: [
          {
            label: t('edit.RHS.lines.everyLines'),
            value: PassageLineNumType.EveryLine,
          },
          {
            label: t('edit.RHS.lines.everyFifthLines'),
            value: PassageLineNumType.EveryFifthLine,
          },
          {
            label: t('edit.RHS.lines.everyTenLines'),
            value: PassageLineNumType.EveryTenLine,
          },
        ],
      };
    }
    case 'isOfferFormula': {
      return {
        type: 'switch',
        name: fieldName || field,
        icon: 'TestFormula',
        label: t('edit.RHS.formula.label'),
        helperText: t('edit.RHS.formula.hint'),
      };
    }
    case 'isOfferCalculator': {
      return {
        type: 'switch',
        name: fieldName || field,
        icon: 'TestCalculator',
        label: t('edit.RHS.calculator.label'),
        helperText: t('edit.RHS.calculator.hint'),
      };
    }
    case 'isOfferWordLimit': {
      return {
        type: 'switch',
        name: fieldName || field,
        icon: 'TextEditorTypeText',
        label: t('edit.RHS.word.limit.label'),
        helperText: t('edit.RHS.word.limit.hint'),
      };
    }
    case 'isOfferKeyboard': {
      return {
        type: 'switch',
        name: fieldName || field,
        icon: 'OtherKeyboard',
        label: t('edit.RHS.keyboard.label'),
        helperText: t('edit.RHS.keyboard.hint'),
      };
    }
    case 'minimumWord': {
      return {
        type: 'number',
        name: fieldName || field,
        label: t('edit.RHS.word.min.label'),
        placeholder: t('edit.RHS.word.min.hint'),
        helperText: t('edit.RHS.minmax.blankable'),
        ...(minmaxRule ? getMinMaxRule(t, minmaxRule) : null),
      };
    }
    case 'maximumWord': {
      const validateRules = minmaxRule ? getMinMaxRule(t, minmaxRule) : null;
      return {
        type: 'number',
        name: fieldName || field,
        label: t('edit.RHS.word.max.label'),
        placeholder: t('edit.RHS.word.max.hint'),
        rules: {
          ...(validateRules?.rules || null),
        },
        customRules: {
          ...(validateRules?.customRules || null),
          differentAs: {
            value: 0,
            message: t('edit.RHS.word.max.differentAs', { value: 0 }),
          },
        },
      };
    }
    case 'gridInBoxNum': {
      return {
        type: 'number',
        name: fieldName || field,
        label: t('edit.RHS.gridBoxes.label'),
        status: FormLayoutItemStatus.Disabled,
      };
    }
    case 'answerOptions': {
      return {
        type: 'number',
        name: fieldName || field,
        label: t('edit.RHS.answerOptions.label'),
        helperText: t('edit.RHS.minmax.range', {
          min: minmaxRule?.minValue || 2,
          max: minmaxRule?.maxValue || '#',
        }),
        ...(minmaxRule ? getMinMaxRule(t, minmaxRule) : null),
      };
    }
    case 'correctAnswers': {
      return {
        type: 'number',
        name: fieldName || field,
        label: t('edit.RHS.correctAnswers.label'),
        helperText: t('edit.RHS.minmax.range', {
          min: minmaxRule?.minValue || 2,
          max: minmaxRule?.maxValue || '#',
        }),
        ...(minmaxRule ? getMinMaxRule(t, minmaxRule) : null),
      };
    }
    case 'isOfferBlankOption': {
      return {
        type: 'switch',
        name: fieldName || field,
        icon: 'OtherBlock',
        label: t('edit.RHS.blankOption.label'),
        helperText: t('edit.RHS.blankOption.hint'),
      };
    }
    default: {
      console.warn('Unknown field name: ' + fieldName);
      return { type: 'gap', value: 0 };
    }
  }
};
export const getValuesByAnswerFormat = (
  t: TFunction,
  settings: GetCreatorQuestionRes,
  clubSetting?: GetClubSettingRes
): FormLayoutItem[] => {
  switch (settings.answerFormatType) {
    case AnswerFormatType.Essay: {
      return [
        getFieldSetting(t, 'isShowPassageLineNum'),
        settings.isShowPassageLineNum &&
          getFieldSetting(t, 'passageLineNumType'),
        getFieldSetting(t, 'isOfferFormula'),
        getFieldSetting(t, 'isOfferWordLimit'),
        ...(settings.isOfferWordLimit
          ? [
              {
                ...getFieldSetting(
                  t,
                  'minimumWord',
                  'essayAnswerWordLimitMin',
                  {
                    fieldNameKey: 'edit.RHS.word.min.label',
                    minValue: 0,
                    minKey: 'edit.RHS.word.min.min',
                    maxValue: 10000000,
                    maxKey: 'edit.RHS.word.min.max',
                    maxValueWithFieldKey: 'edit.RHS.word.min.maxValueWithField',
                    maxValueWithFieldName: 'essayAnswerWordLimitMax',
                    regexKey: 'edit.RHS.word.min.regex',
                  }
                ),
                placeholder: String(ESSAY_WORD_LIMIT_MIN_DEFAULT),
              },
              {
                ...getFieldSetting(
                  t,
                  'maximumWord',
                  'essayAnswerWordLimitMax',
                  {
                    fieldNameKey: 'edit.RHS.word.max.label',
                    minValue: 0,
                    minKey: 'edit.RHS.word.max.min',
                    maxValue: 10000000,
                    maxKey: 'edit.RHS.word.max.max',
                    minValueWithFieldKey: 'edit.RHS.word.max.minValueWithField',
                    minValueWithFieldName: 'essayAnswerWordLimitMin',
                    regexKey: 'edit.RHS.word.max.regex',
                  }
                ),
                placeholder: String(ESSAY_WORD_LIMIT_MAX_DEFAULT),
              },
            ]
          : []),
        getFieldSetting(t, 'isOfferKeyboard'),
      ].filter(nonBooleanAndNullable);
    }

    case AnswerFormatType.FreeResponse: {
      return [
        getFieldSetting(t, 'answerContentType'),
        getFieldSetting(t, 'isShowPassageLineNum'),
        settings.isShowPassageLineNum &&
          getFieldSetting(t, 'passageLineNumType'),
        getFieldSetting(t, 'isOfferFormula'),
        getFieldSetting(t, 'isOfferWordLimit'),
        ...(settings.isOfferWordLimit
          ? [
              {
                ...getFieldSetting(
                  t,
                  'minimumWord',
                  'freeResponseAnswerWordLimitMin',
                  {
                    fieldNameKey: 'edit.RHS.word.min.label',
                    minValue: 0,
                    minKey: 'edit.RHS.word.min.min',
                    maxValue: 10000000,
                    maxKey: 'edit.RHS.word.min.max',
                    maxValueWithFieldKey: 'edit.RHS.word.min.maxValueWithField',
                    maxValueWithFieldName: 'freeResponseAnswerWordLimitMax',
                    regexKey: 'edit.RHS.word.min.regex',
                  }
                ),
                placeholder: String(FREE_RESPONSE_WORD_LIMIT_MIN_DEFAULT),
              },
              {
                ...getFieldSetting(
                  t,
                  'maximumWord',
                  'freeResponseAnswerWordLimitMax',
                  {
                    fieldNameKey: 'edit.RHS.word.max.label',
                    minValue: 0,
                    minKey: 'edit.RHS.word.max.min',
                    maxValue: 10000000,
                    maxKey: 'edit.RHS.word.max.max',
                    minValueWithFieldKey: 'edit.RHS.word.max.minValueWithField',
                    minValueWithFieldName: 'freeResponseAnswerWordLimitMax',
                    regexKey: 'edit.RHS.word.max.regex',
                  }
                ),
                placeholder: String(FREE_RESPONSE_WORD_LIMIT_MAX_DEFAULT),
              },
            ]
          : []),
        getFieldSetting(t, 'isOfferKeyboard'),
      ].filter(nonBooleanAndNullable);
    }

    case AnswerFormatType.GridIn: {
      return [
        getFieldSetting(t, 'gridInBoxNum'),
        getFieldSetting(t, 'isShowPassageLineNum'),
        settings.isShowPassageLineNum &&
          getFieldSetting(t, 'passageLineNumType'),
        getFieldSetting(t, 'isOfferFormula'),
      ].filter(nonBooleanAndNullable);
    }

    case AnswerFormatType.MultipleChoice: {
      return [
        getFieldSetting(t, 'answerOptions', 'mcqMaxAnswerNum', {
          fieldNameKey: 'edit.RHS.answerOptions.label',
          requiredKey: 'edit.RHS.answerOptions.required',
          minValue: MCQ_ANSWER_NUM_MIN,
          minKey: 'edit.RHS.answerOptions.min',
          maxValue: MCQ_ANSWER_NUM_MAX,
          maxKey: 'edit.RHS.answerOptions.max',
          regexKey: 'edit.RHS.word.min.regex',
        }),
        // Blank Option toggle only show when Negative Scoring is on
        ...(clubSetting?.isEnableNegativeScoring
          ? [
              { type: 'divider' as const, text: 'Input', textOnly: true },
              getFieldSetting(t, 'isOfferBlankOption'),
            ]
          : []),
        { type: 'divider' as const, text: 'View', textOnly: true },
        getFieldSetting(t, 'isShowPassageLineNum'),
        settings.isShowPassageLineNum &&
          getFieldSetting(t, 'passageLineNumType'),
        getFieldSetting(t, 'isOfferFormula'),
      ].filter(nonBooleanAndNullable);
    }

    case AnswerFormatType.MultipleResponse: {
      return [
        getFieldSetting(t, 'answerOptions', 'mrqMaxAnswerNum', {
          fieldNameKey: 'edit.RHS.answerOptions.label',
          requiredKey: 'edit.RHS.answerOptions.required',
          minValue: MRQ_ANSWER_NUM_MIN,
          minKey: 'edit.RHS.answerOptions.min',
          maxValue: MRQ_ANSWER_NUM_MAX,
          maxKey: 'edit.RHS.answerOptions.max',
          minValueWithFieldKey: 'edit.RHS.answerOptions.minValueWithField',
          minValueWithFieldName: 'mrqMaxCorrectAnswerNum',
          minValueWithFieldDefault: MRQ_CORRECT_ANSWER_NUM_DEFAULT,
        }),
        getFieldSetting(t, 'correctAnswers', 'mrqMaxCorrectAnswerNum', {
          fieldNameKey: 'edit.RHS.correctAnswers.label',
          requiredKey: 'edit.RHS.correctAnswers.required',
          minValue: MRQ_CORRECT_ANSWER_NUM_MIN,
          minKey: 'edit.RHS.correctAnswers.min',
          maxValue: MRQ_CORRECT_ANSWER_NUM_MAX,
          maxKey: 'edit.RHS.correctAnswers.max',
          maxValueWithFieldKey: 'edit.RHS.correctAnswers.maxValueWithField',
          maxValueWithFieldName: 'mrqMaxAnswerNum',
          maxValueWithFieldDefault: MRQ_ANSWER_NUM_DEFAULT,
        }),
        getFieldSetting(t, 'isShowPassageLineNum'),
        settings.isShowPassageLineNum &&
          getFieldSetting(t, 'passageLineNumType'),
        getFieldSetting(t, 'isOfferFormula'),
      ].filter(nonBooleanAndNullable);
    }

    case AnswerFormatType.TrueFalseNotGiven: {
      return [
        getFieldSetting(t, 'isShowPassageLineNum'),
        settings.isShowPassageLineNum &&
          getFieldSetting(t, 'passageLineNumType'),
        getFieldSetting(t, 'isOfferFormula'),
      ].filter(nonBooleanAndNullable);
    }

    case AnswerFormatType.Unscramble: {
      return [
        getFieldSetting(t, 'answerOptions', 'unscrambleMaxAnswerNum', {
          fieldNameKey: 'edit.RHS.answerOptions.label',
          requiredKey: 'edit.RHS.answerOptions.required',
          minValue: UNSCRAMBLE_ANSWER_NUM_MIN,
          minKey: 'edit.RHS.answerOptions.min',
          maxValue: UNSCRAMBLE_ANSWER_NUM_MAX,
          maxKey: 'edit.RHS.answerOptions.max',
        }),
        getFieldSetting(t, 'isShowPassageLineNum'),
        settings.isShowPassageLineNum &&
          getFieldSetting(t, 'passageLineNumType'),
        getFieldSetting(t, 'isOfferFormula'),
      ].filter(nonBooleanAndNullable);
    }

    default: {
      console.warn('Unknown answer format: ', settings.answerFormatType);
      return [];
    }
  }
};
