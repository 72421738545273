import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Scrollbar } from '@front/ui';

import TableLayoutPaneContext from '../../contexts/tableLayoutPaneContext';

const INNER_MAX_WIDTH = 796; // base width from the figma
const CHECKBOX_WIDTH = 20;

const styles = {
  root: {
    pl: '20px', // a padding to the edge (corresponding to pr: 20px)
    width: '100%',
    minWidth: 0,
    mx: { md: 'auto' },
  },
  scroll: {
    pl: '24px', // scrollbar will hide floating checkbox, so we add a padding for it
    ml: '-24px', // according to above padding, we reverse it back
  },
  scrollContent: {
    pr: '20px',
    pb: '20px',
  },
};

export type TableLayoutCentrePaneProps = {
  children: ReactNode;
  layoutSetting: {
    minWidth?: number | string;
    maxWidth?: number | string;
  };
  sx?: BoxProps['sx'];
  className?: string;
};
export default function IaTableCentrePane({
  children,
  sx,
  layoutSetting,
  className,
}: TableLayoutCentrePaneProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const scrollRef = useRef<HTMLDivElement>(null);
  const [horizontalScrolled, setHorizontalScrolled] = useState(false);

  useEffect(() => {
    const scrollEl = scrollRef.current;

    if (!scrollEl) return;

    const handleScroll = () => {
      setHorizontalScrolled(scrollEl.scrollLeft > 0);
    };

    scrollEl.addEventListener('scroll', handleScroll);
    return () => {
      scrollEl.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollContentWidth = layoutSetting.minWidth || INNER_MAX_WIDTH;
  const contentMaxWidth = layoutSetting.maxWidth || INNER_MAX_WIDTH;
  return (
    <TableLayoutPaneContext.Provider value={{ horizontalScrolled }}>
      <Box
        sx={[
          styles.root,
          {
            maxWidth: {
              md:
                typeof contentMaxWidth === 'number'
                  ? contentMaxWidth + 40
                  : contentMaxWidth,
            },
          },
          ...sxProps,
        ]}
        className={className}
      >
        <Scrollbar sx={styles.scroll} scrollableNodeProps={{ ref: scrollRef }}>
          <Box
            sx={[
              styles.scrollContent,
              {
                width:
                  typeof scrollContentWidth === 'number'
                    ? scrollContentWidth + CHECKBOX_WIDTH
                    : scrollContentWidth,
              },
            ]}
            className="table-layout-inner"
          >
            {children}
          </Box>
        </Scrollbar>
      </Box>
    </TableLayoutPaneContext.Provider>
  );
}
