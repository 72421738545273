import React, { useCallback, useEffect, useRef, useState } from 'react';
import { alpha, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { OtherLaTex as OtherLaTexIcon } from '@front/icon';
import BlockTag from '@lib/web/composer/BlockTag';
import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';
import { ErrorMessageValue } from '@lib/web/ui';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import katex, { KatexOptions } from 'katex';
import { v4 } from 'uuid';

import ThemeProvider from '../../../theme/ThemeProvider';
import { ThreadBlockTypes } from '../../config/threadBlockTypes';

export type InlineLatexOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineLatexOptions;
    node: ProseMirrorNode;
  }) => string;
};

const styles = {
  preambleContent: {
    borderTop: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? '1px solid rgba(8, 8, 8, 0.05)'
        : '1px solid rgba(255, 255, 255, 0.1)',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    py: 1,
    px: '12px',
    display: 'grid',
    gap: 1,
    pre: {
      m: 0,
      typography: 'body1',
      '& .xml': {
        color: 'primary.light',
      },
    },
    code: {
      typography: 'body1',
    },
  },
  display: {
    whiteSpace: 'pre-wrap',
    '&:hover': {
      borderRadius: 1,
      cursor: 'pointer',
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    },
  },
  content: {
    typography: 'numberBody3',
    '&:empty::before': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },
  },
  scroll: {
    maxHeight: 159,
  },
};

const LATEX_DEFAULT_OPTIONS: KatexOptions = {
  leqno: true,
  fleqn: true,
  macros: { '\\f': '#1f(#2)' },
};

type LatexProps = {
  node: ProseMirrorNode;
  updateAttributes: (attributes: Record<string, any>) => void;
};

const Latex = ({ node, updateAttributes }: LatexProps) => {
  const { setSelectedAction, latexActionState } = useThreadComposer();
  const [show, setShow] = useState(!!node.attrs.defaultShow);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [errors, setErrors] = useState<ErrorMessageValue[]>([]);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const displayRef = useRef<HTMLDivElement | null>(null);

  const isContentEmpty = !node.attrs.value || node.attrs.value === '\n';

  useEffect(() => {
    latexActionState.setLatexBlocksMap((prev) => ({
      ...prev,
      [node.attrs.id]: {
        setValue: (value: string) => {
          updateAttributes({
            value,
          });
        },
        errors,
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, node.attrs.id, updateAttributes]);

  useEffect(() => {
    if (displayRef.current) {
      try {
        katex.render(node.attrs.value, displayRef.current, {
          ...LATEX_DEFAULT_OPTIONS,
          throwOnError: true,
        });

        setErrors([]);
      } catch (err) {
        setErrors([
          {
            id: '',
            type: 'error',
            message:
              err instanceof katex.ParseError ? err.message : 'Error in LaTeX',
          },
        ]);
      }
    }
  }, [node.attrs.value]);

  const openCurrentBlockLatextComposer = useCallback(() => {
    latexActionState.openLatexComposer({
      composerDefaultValue: node.attrs.value,
      blockId: node.attrs.id,
    });
  }, [latexActionState, node.attrs.id, node.attrs.value]);

  const handleTagClick = () => {
    if (show && latexActionState.composingBlockId === node.attrs.id) {
      setShow(false);
      latexActionState.closeLatexComposer();
    } else {
      setShow(true);
      openCurrentBlockLatextComposer();
    }
  };

  useEffect(() => {
    if (!firstLoaded && displayRef.current) {
      katex.render(node.attrs.value, displayRef.current, {
        ...LATEX_DEFAULT_OPTIONS,
        throwOnError: false,
      });

      setFirstLoaded(true);
    }
  }, [node.attrs.value, firstLoaded]);

  useEffect(() => {
    if (node.attrs.defaultShow) {
      openCurrentBlockLatextComposer();

      setTimeout(() => {
        updateAttributes({
          defaultShow: false,
        });
      });
    }
  }, [
    latexActionState,
    node.attrs.defaultShow,
    node.attrs.id,
    node.attrs.value,
    openCurrentBlockLatextComposer,
    setSelectedAction,
    updateAttributes,
  ]);

  return (
    <NodeViewWrapper
      style={{ display: 'inline-block' }}
      className={ThreadBlockTypes.InlineLatex}
    >
      <ThemeProvider>
        <div ref={anchorRef} contentEditable={false}>
          <Box
            ref={displayRef}
            sx={styles.display}
            display={
              isContentEmpty || errors.length > 0 ? 'none' : 'inline-flex'
            }
            onClick={handleTagClick}
          />
          {(isContentEmpty || errors.length > 0) && (
            <BlockTag
              active={show}
              error={errors.length > 0}
              icon={<OtherLaTexIcon />}
              onClick={handleTagClick}
            >
              LaTeX
            </BlockTag>
          )}
        </div>
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export const InlineLatex = Node.create<InlineLatexOptions>({
  name: ThreadBlockTypes.InlineLatex,

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: v4,
        parseHTML: () => v4(), // when copy and paste, generate a new id
      },
      value: {
        default: '',
      },
      defaultShow: {
        default: true,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.name,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const elem = document.createElement('span');
    katex.render(HTMLAttributes.value, elem, {
      ...LATEX_DEFAULT_OPTIONS,
      throwOnError: false,
    });
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        'data-content-type': this.name,
      }),
      elem,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Latex);
  },
});
