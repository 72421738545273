export const isEmpty = (value: any) =>
  typeof value === 'undefined' || value === null || value === false;

export const isNumberic = (value: any) =>
  !isEmpty(value) && !Number.isNaN(Number(value));

export const numberic = (value: any, defaultValue = 0): number => {
  if (!isNumberic(value)) return defaultValue;

  return +value as number;
};

export function toDecimal(val: number | string, pos = 2) {
  if (!isNumberic(val)) {
    return null;
  }
  const floatVal = parseFloat(`${val}`);
  const pow = 10 ** pos;

  const f = Math.round(floatVal * pow) / pow;
  return f;
}
export const getRangeRandom = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

/**
 * Decimal adjustment of a number.
 *
 * @param {String}  type  The type of adjustment.
 * @param {Number}  value The number.
 * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number} The adjusted value.
 */
export function decimalAdjust(
  type: 'floor' | 'ceil' | 'round',
  value: number,
  exp?: number
) {
  // If the exp is undefined or zero...
  if (!exp) {
    return Math[type](value);
  }
  let newValue = value;
  const newExp = exp;
  // If the value is not a number or the exp is not an integer...
  // Shift
  const valueStartArr = newValue.toString().split('e');
  newValue = Math[type](
    +`${valueStartArr[0]}e${
      valueStartArr[1] ? +valueStartArr[1] - newExp : -newExp
    }`
  );
  // Shift back
  const valueBackArr = newValue.toString().split('e');
  return +`${valueBackArr[0]}e${
    valueBackArr[1] ? +valueBackArr[1] + newExp : newExp
  }`;
}
