import React from 'react';

export default function EmojiSolid({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.1163 5.83659C14.22 3.67034 12.3519 2.2607 12.2734 2.20174L12.0042 2L11.732 2.19776C11.6534 2.25495 9.78308 3.62333 7.88521 5.7694C5.30717 8.68455 4 11.5005 4 14.139C4 18.4736 7.58873 22 12 22C16.4112 22 20 18.4736 20 14.139C20 11.5737 18.6933 8.78033 16.1163 5.83659Z"
        fill="currentColor"
      />
    </svg>
  );
}
