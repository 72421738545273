import admin from './admin';
import agent from './agent';
import analytics from './analytics';
import auth from './auth';
import challenge from './challenge';
import club from './club';
import clubGroup from './clubGroup';
import editor from './editor';
import exams from './exams';
import file from './file';
import func from './func';
import goal from './goal';
import hashtag from './hashtag';
import ia from './ia';
import marketplace from './marketplace';
import member from './member';
import notification from './notification';
import phone from './phone';
import pinChat from './pinChat';
import product from './product';
import qbank from './qbank';
import quiz from './quiz';
import refer from './refer';
import remoteRender from './remoteRender';
import shared from './shared';
import summary from './summary';
import thread from './thread';
import tool from './tool';
import user from './user';
import workflow from './workflow';

const apis = {
  analytics,
  auth,
  member,
  admin,
  marketplace,
  notification,
  challenge,
  exams,
  refer,
  phone,
  product,
  quiz,
  qbank,
  goal,
  user,
  club,
  clubGroup,
  editor,
  hashtag,
  file,
  agent,
  thread,
  remoteRender,
  summary,
  ia,
  tool,
  pinChat,
  shared,
  func,
  workflow,
};

export default apis;
