import React from 'react';

export default function TradingSignal({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.0002 19V5L15.7061 9.29515V19H21.0002Z"
        fill="currentColor"
        opacity="0.4"
      />
      <path
        d="M14.6471 10.3845V18.9999H9.35303V14.4319L14.6471 10.3845Z"
        fill="currentColor"
        opacity="0.7"
      />
      <path
        d="M8.29412 18.9998V14.6921L3 18.9998H8.29412Z"
        fill="currentColor"
      />
    </svg>
  );
}
