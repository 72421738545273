import { ClubMemberRole, useClubAnswerFormatPreference } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

import { useCreatorQuestionInfoData } from './useCreatorQuestionInfoData';

export default function useDisableEditAnswerFormat(questionId: string) {
  const { club } = useCurrentIaClub();
  const { data: clubAnswerFormatData } = useClubAnswerFormatPreference(
    club?.clubId || ''
  );
  const { infoData } = useCreatorQuestionInfoData(questionId);

  const isAllowUserEdit =
    clubAnswerFormatData?.data.find(
      (clubAnswerFormat) =>
        clubAnswerFormat.answerFormatType === infoData?.answerFormatType
    )?.isAllowUserEdit ?? false;

  const hasEditAuth =
    club?.role === ClubMemberRole.Admin ||
    (isAllowUserEdit && club?.role === ClubMemberRole.Editor);

  const disableEditAnswerFormat = !hasEditAuth;

  return {
    disableEditAnswerFormat,
    disableStatus: {
      noAllowUserEdit: !isAllowUserEdit,
      noPermission: !hasEditAuth,
    },
  };
}
