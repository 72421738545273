import React, { ReactNode } from 'react';
import toast, { ToastOptions } from 'react-hot-toast';

import Button from '../../atoms/Button/Button';
import { BaseSnackbarProps } from '../Snackbar/BaseSnackbar/BaseSnackbar';
import { MaybeDontShowAgainSnackbarProps } from '../Snackbar/MaybeDontShowAgainSnackbar';
import { ProcessSnackbarProps } from '../Snackbar/ProcessSnackbar';

import ToastSnackbar, {
  SnackbarStyleType,
  ToastSnackbarProps,
} from './ToastSnackbar';

type OptionalAlertOption<T, V> = Partial<
  T & {
    variant: V;
    anchorEl?: Element | null;
    disableClose?: boolean;
    anchorYGap?: number;
    anchorXGap?: number;
  }
> &
  SnackbarStyleType;

export type BaseOptions = OptionalAlertOption<BaseSnackbarProps, 'base'>;
export type ProcessOptions = OptionalAlertOption<
  ProcessSnackbarProps,
  'loading'
>;

export type MaybeDontShowAgainOptions = OptionalAlertOption<
  MaybeDontShowAgainSnackbarProps,
  'maybe-dont-show-again'
>;

type AlertOption = ToastSnackbarProps;

const alert = (options: AlertOption, toastOptions: ToastOptions) =>
  toast.custom(
    (t) => (
      <ToastSnackbar
        {...options}
        id={toastOptions.id || t.id}
        visible={t.visible}
      />
    ),
    toastOptions
  );

const text = (
  message: ReactNode,
  options: BaseOptions = {},
  toastOptions: ToastOptions = {}
) =>
  alert(
    { variant: 'base', type: 'primary', ...options, message },
    toastOptions
  );

const info = (
  message: ReactNode,
  options: BaseOptions = {},
  toastOptions: ToastOptions = {}
) =>
  alert(
    { variant: 'base', type: 'primary', ...options, message },
    toastOptions
  );

const error = (
  message: ReactNode,
  options: BaseOptions = {},
  toastOptions: ToastOptions = {}
) =>
  alert({ variant: 'base', type: 'error', ...options, message }, toastOptions);

const warning = (
  message: ReactNode,
  options: BaseOptions = {},
  toastOptions: ToastOptions = {}
) =>
  alert(
    { variant: 'base', type: 'warning', ...options, message },
    toastOptions
  );

const success = (
  message: ReactNode,
  options: BaseOptions = {},
  toastOptions: ToastOptions = {}
) =>
  alert(
    { variant: 'base', type: 'success', ...options, message },
    toastOptions
  );

const loading = (
  message: ReactNode,
  options: ProcessOptions = {},
  toastOptions: ToastOptions = {}
) =>
  toast.custom(
    (t) => (
      <ToastSnackbar
        message={message}
        variant="loading"
        {...options}
        id={t.id}
        visible={t.visible}
      />
    ),
    {
      duration: Infinity,
      id: toastOptions.id,
      ...toastOptions,
    }
  );

const confirm = (
  message: ReactNode,
  options: BaseOptions & {
    confirmText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
  } = {
    confirmText: 'OK',
  },
  toastOptions: ToastOptions = {
    duration: 300000,
  }
) =>
  new Promise<string | null>((resolve) => {
    const { confirmText, onConfirm, onCancel, ...restOptions } = options;

    let confirmToast = '';

    const handleConfirm = () => {
      toast.dismiss(confirmToast);
      if (onConfirm) onConfirm();
      resolve('OK');
    };
    const handleClose = () => {
      toast.dismiss(confirmToast);
      if (onCancel) onCancel();
      resolve(null);
    };

    confirmToast = alert(
      {
        variant: 'base',
        type: 'primary',
        onClose: handleClose,
        actionComponent: (
          <Button sx={{ whiteSpace: 'nowrap' }} onClick={handleConfirm}>
            {confirmText}
          </Button>
        ),
        ...restOptions,
        message,
      },
      toastOptions
    );
  });

const maybeDontShowAgain = (
  message: ReactNode,
  options: MaybeDontShowAgainOptions,
  toastOptions: ToastOptions = {}
) =>
  new Promise<string | null>((resolve) => {
    const { confirmText, onConfirm, ...restOptions } = options;

    let confirmToast = '';

    const handleConfirm = (val: boolean) => {
      toast.dismiss(confirmToast);
      if (onConfirm) onConfirm(val);
      resolve('OK');
    };
    const handleClose = () => {
      toast.dismiss(confirmToast);
      resolve(null);
    };

    confirmToast = alert(
      {
        variant: 'maybe-dont-show-again',
        type: 'primary',
        onClose: handleClose,
        onConfirm: handleConfirm,
        ...restOptions,
        message,
      },
      toastOptions
    );
  });

export default {
  alert,
  text,
  info,
  error,
  success,
  warning,
  confirm,
  loading,
  maybeDontShowAgain,
  dismiss: toast.dismiss,
  remove: toast.remove,
};
