import { useCallback } from 'react';
import { useDateFormat } from '@front/helper';
import { useNotificationMessageContent } from '@lib/web/thread/hooks/message/useNotificationMessageContent';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { streamDateToString } from '@lib/web/thread/utils/streamUtils';
import { extractTextFromHtml } from '@lib/web/utils';
import { escape, isNil } from 'lodash';
import { StreamMessage } from 'stream-chat-react';

import { useThread } from '../core/useThread';

import { useRenderThreadMessageToHtml } from './useRenderThreadMessageToHtml';

export const useMessageInformation = () => {
  const { displayDateDivider } = useDateFormat();
  const { renderThreadMessageToHtml } = useRenderThreadMessageToHtml();
  const { getNotificationMessageContent } = useNotificationMessageContent();
  const { getThreadMessageExternalPayload } = useThread();

  const getMessageInformation = useCallback(
    (
      message: StreamMessage<StreamChatGenerics>,
      currentMessagePageNum?: number
    ) => {
      const messageExternalPayload = getThreadMessageExternalPayload(
        message.externalPayloadId
      );
      const messageText = message.text;
      const customBlocks =
        messageExternalPayload?.customBlocks || message.customBlocks;

      const messageCreator = message.user?.id;
      const messageCreatedAt = message.parentMessageCreatedAt
        ? streamDateToString(message.parentMessageCreatedAt)
        : streamDateToString(message.created_at);

      const dateDividerTime = displayDateDivider(messageCreatedAt);

      const currentPageMessageText =
        !isNil(currentMessagePageNum) &&
        escape(message.previousText?.[currentMessagePageNum]);
      const currentText = currentPageMessageText || messageText;
      const content = renderThreadMessageToHtml(currentText, customBlocks);
      const notificationMessageContent = getNotificationMessageContent({
        notificationSenderId: messageCreator,
        notificationId: message.customNotificationId,
      });
      const isAgentMessage = !!messageCreator?.startsWith('agent_');
      const agentMessage = `
        <span
          data-render-ui="agentMessageContent"
          data-metadata-message-id=${message.id}
          data-metadata-content="${escape(content)}"
          data-metadata-agent-composing-status="${message.agentComposingStatus}"
          data-metadata-agent-material-process-status="${
            message.agentMaterialProcessStatus
          }"
          data-metadata-agent-material-file-name="${
            message.agentMaterialFileName
          }"
        />`;

      return {
        isAgentMessage,
        content: isAgentMessage
          ? agentMessage
          : content || notificationMessageContent.text,
        messageCreator,
        messageCreatedAt,
        dateDividerTime,
        cta: notificationMessageContent.cta,
        plainText: extractTextFromHtml(content),
        customBlocks,
      };
    },
    [
      displayDateDivider,
      getNotificationMessageContent,
      getThreadMessageExternalPayload,
      renderThreadMessageToHtml,
    ]
  );

  return {
    getMessageInformation,
  };
};
