import React from 'react';

export default function PropertyTypeMultiSelect({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.657 3.85714C7.4262 3.85714 6.42843 4.85491 6.42843 6.08571V15.3429C6.42843 16.5737 7.4262 17.5714 8.657 17.5714H17.9141C19.145 17.5714 20.1427 16.5737 20.1427 15.3429V6.08571C20.1427 4.85491 19.145 3.85714 17.9141 3.85714H8.657ZM8.657 3C6.95281 3 5.57129 4.38152 5.57129 6.08571V15.3429C5.57129 17.0471 6.95281 18.4286 8.657 18.4286H17.9141C19.6183 18.4286 20.9999 17.0471 20.9999 15.3429V6.08571C20.9999 4.38152 19.6183 3 17.9141 3H8.657Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M3 8.65725C3 6.95305 4.38152 5.57153 6.08571 5.57153H15.3429C17.047 5.57153 18.4286 6.95305 18.4286 8.65725V17.9144C18.4286 19.6186 17.047 21.0001 15.3429 21.0001H6.08571C4.38152 21.0001 3 19.6186 3 17.9144V8.65725ZM14.7671 11.7272C15.0684 11.4259 15.0684 10.9375 14.7671 10.6362C14.4658 10.335 13.9774 10.335 13.6761 10.6362L9.70675 14.6056L8.14499 12.7836C7.86772 12.4601 7.38072 12.4226 7.05724 12.6999C6.73376 12.9772 6.6963 13.4642 6.97357 13.7877L9.07746 16.2422C9.2173 16.4053 9.41885 16.5028 9.63357 16.511C9.84829 16.5193 10.0567 16.4376 10.2087 16.2856L14.7671 11.7272Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
