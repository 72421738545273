import React from 'react';
import { isTextExceedMaxWord } from '@front/helper';

export type WordLimitMax = {
  value: number;
  onExceed?: () => void;
  allowExceed?: boolean;
};

export default function useWordLimit({
  wordLimitMax,
  onChange,
}: {
  wordLimitMax?: WordLimitMax;
  onChange?: (value: string) => void;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    const isExceedMax = isTextExceedMaxWord({
      newText: newValue,
      max: wordLimitMax?.value,
    });

    if (!isExceedMax || wordLimitMax?.allowExceed) {
      onChange?.(newValue);
    }

    if (isExceedMax && wordLimitMax?.onExceed) {
      wordLimitMax.onExceed();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    const pastedText = event.clipboardData?.getData('text');
    const currentValue = inputElement.value;
    const newValue = currentValue + pastedText;

    const isExceedMax = isTextExceedMaxWord({
      newText: newValue,
      max: wordLimitMax?.value,
    });

    if (isExceedMax && !wordLimitMax?.allowExceed) {
      let truncatedValue = '';

      for (let i = 0; i < newValue.length; i++) {
        if (
          isTextExceedMaxWord({
            newText: newValue.slice(0, i + 1),
            max: wordLimitMax?.value,
          })
        ) {
          break;
        }
        truncatedValue += newValue[i];
      }

      onChange?.(truncatedValue);
      return;
    }
  };

  return {
    handleChange,
    handlePaste,
  };
}
