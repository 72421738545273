import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { AnswerFormatType } from '@lib/web/apis';
import { useCreatorQuestionInfoData } from '@lib/web/editor';

import { EditorAnswerEssay } from './components/EditorAnswerEssay';
import { EditorAnswerFreeResponse } from './components/EditorAnswerFreeResponse';
import { EditorAnswerGridIn } from './components/EditorAnswerGridIn';
import { EditorAnswerMultipleChoice } from './components/EditorAnswerMultipleChoice';
import { EditorAnswerMultipleResponse } from './components/EditorAnswerMultipleResponse';
import { EditorAnswerTrueFalseNotGiven } from './components/EditorAnswerTrueFalseNotGiven';
import { EditorAnswerUnscramble } from './components/EditorAnswerUnscramble';

export type EditorAnswerProps = {
  questionId: string;
  readonly?: boolean;
};

export default function EditorAnswer({
  questionId,
  readonly,
}: EditorAnswerProps) {
  const { infoData, isLoading } = useCreatorQuestionInfoData(questionId);

  if (isLoading || !infoData) {
    return (
      <Box>
        <Skeleton width="100%" height={36} />
        <Skeleton width="100%" height={36} />
        <Skeleton width="60%" height={36} />
      </Box>
    );
  }

  switch (infoData.answerFormatType) {
    case AnswerFormatType.MultipleChoice:
      return (
        <EditorAnswerMultipleChoice
          questionId={questionId}
          readonly={readonly}
        />
      );

    case AnswerFormatType.MultipleResponse:
      return (
        <EditorAnswerMultipleResponse
          questionId={questionId}
          readonly={readonly}
        />
      );

    case AnswerFormatType.TrueFalseNotGiven:
      return (
        <EditorAnswerTrueFalseNotGiven
          questionId={questionId}
          readonly={readonly}
        />
      );

    case AnswerFormatType.GridIn:
      return <EditorAnswerGridIn questionId={questionId} readonly={readonly} />;

    case AnswerFormatType.FreeResponse:
      return (
        <EditorAnswerFreeResponse questionId={questionId} readonly={readonly} />
      );

    case AnswerFormatType.Unscramble:
      return (
        <EditorAnswerUnscramble questionId={questionId} readonly={readonly} />
      );

    case AnswerFormatType.Essay:
      return <EditorAnswerEssay questionId={questionId} readonly={readonly} />;

    default:
      console.error(
        'not supported answer format type',
        infoData.answerFormatType
      );
      return null;
  }
}
