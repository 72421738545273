import React from 'react';

export default function TextEditorCheck({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.7203 16.0455L5.18897 12.4428C4.78454 12.0302 4.12047 12.029 3.71458 12.4402C3.31804 12.8419 3.3169 13.4874 3.71201 13.8905L8.00615 18.2714C8.39823 18.6714 9.04236 18.6714 9.43444 18.2714L20.2928 7.1935C20.6857 6.79273 20.6857 6.15134 20.2928 5.75057C19.8889 5.33844 19.2252 5.33828 18.8211 5.75023L8.7203 16.0455Z"
        fill="currentColor"
      />
    </svg>
  );
}
