import nextId from 'react-id-generator';

export default function BadgeStudentAmbassador({ ...rest }) {
  const id = nextId('badge-student-ambassador');

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="12" cy="12" r="9" fill={`url(#${id})`} />
      <path
        d="M11.8337 7.93416L6.35413 9.98428C6.10919 10.0759 6.10239 10.4298 6.34361 10.5313L11.8232 12.8377C11.8923 12.8668 11.9699 12.8668 12.039 12.8377L17.5186 10.5313C17.7598 10.4298 17.753 10.0759 17.5081 9.98428L12.0285 7.93416C11.9656 7.91062 11.8966 7.91062 11.8337 7.93416Z"
        fill="#080808"
      />
      <path
        d="M11.8188 13.7134L8.33803 12.1785C8.14995 12.0956 7.94073 12.2375 7.94073 12.4481V14.156C7.94073 14.2734 8.00868 14.3794 8.11348 14.4256L11.8188 16.0595C11.8905 16.0911 11.9717 16.0911 12.0434 16.0595L15.7487 14.4256C15.8535 14.3794 15.9215 14.2734 15.9215 14.156V12.4481C15.9215 12.2375 15.7122 12.0956 15.5242 12.1785L12.0434 13.7134C11.9717 13.745 11.8905 13.745 11.8188 13.7134Z"
        fill="#080808"
      />
      <path
        d="M17.3466 11.1236C17.504 11.1236 17.6316 11.2549 17.6316 11.4168V14.5212L17.6907 14.5819C17.8807 14.7774 17.8807 15.0944 17.6907 15.2899C17.5006 15.4854 17.1926 15.4854 17.0025 15.2899C16.8125 15.0944 16.8125 14.7774 17.0025 14.5819L17.0616 14.5212V11.4168C17.0616 11.2549 17.1892 11.1236 17.3466 11.1236Z"
        fill="#080808"
      />
      <defs>
        <linearGradient
          id={id}
          x1="21"
          y1="11.8656"
          x2="3"
          y2="11.8693"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFFF" />
          <stop offset="1" stopColor="#00A4FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
