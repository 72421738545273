import Router from 'next/router';

export const clearRouteParams = (
  clearParams: string[],
  replaceQueries = {}
) => {
  const { pathname, replace, query } = Router;

  const hashIndex = Router.asPath.indexOf('#');
  const currentHash =
    hashIndex !== -1 ? Router.asPath.substring(hashIndex) : undefined;

  const newQuery = Object.keys(query).reduce((acc, key) => {
    if (!clearParams.includes(key)) {
      acc[key] = query[key] as string;
    }
    return acc;
  }, {} as Record<string, string>);

  return replace(
    { pathname, hash: currentHash, query: { ...newQuery, ...replaceQueries } },
    undefined,
    {
      shallow: true,
    }
  );
};

export const forceRoute = async (url: string) => {
  await Router.push(`${url}${url.includes('?') ? '&' : '?'}forceRoute=true`);
  void clearRouteParams(['forceRoute']);
};
