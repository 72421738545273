import { DisplayTableLayoutHeaderCell } from '../../types';

import IaDisplayTableDefaultHeaderCell from './IaDisplayTableDefaultHeaderCell';
import IaDisplayTableEmptyHeaderCell from './IaDisplayTableEmptyHeaderCell';
import IaDisplayTableIconTextHeaderCell from './IaDisplayTableIconTextHeaderCell';

type IaDisplayTableHeaderCellProps = {
  cell: DisplayTableLayoutHeaderCell;
};

export default function IaDisplayTableHeaderCell({
  cell,
}: IaDisplayTableHeaderCellProps) {
  switch (cell.type) {
    case 'default':
      return <IaDisplayTableDefaultHeaderCell {...cell} />;
    case 'iconText':
      return <IaDisplayTableIconTextHeaderCell {...cell} />;
    case 'empty':
      return <IaDisplayTableEmptyHeaderCell />;
    default:
      console.warn('Unknown header cell type:', cell);
      return null;
  }
}
