import React from 'react';

export default function TestTrophy({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.4 3C16.59 3 15.6 3.9 15.6 4.8H8.4C8.4 3.9 7.41 3 6.6 3H3V11.1C3 12 3.9 12.9 4.8 12.9H6.78C7.14 14.7 8.31 16.23 11.1 16.5V18.372C8.4 18.786 8.4 21 8.4 21H15.6C15.6 21 15.6 18.786 12.9 18.372V16.5C15.69 16.23 16.86 14.7 17.22 12.9H19.2C20.1 12.9 21 12 21 11.1V3H17.4ZM6.6 11.1H4.8V4.8H6.6V11.1ZM19.2 11.1H17.4V4.8H19.2V11.1Z"
        fill="currentColor"
      />
    </svg>
  );
}
