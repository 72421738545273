import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import ButtonBase, {
  ButtonBaseProps,
  ButtonBaseTypeMap,
} from '@mui/material/ButtonBase';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ActionArrowRightUp } from '@front/icon';

export type CardButtonVariantType = 'default' | 'simple';

export type CardButtonProps<
  C extends React.ElementType = ButtonBaseTypeMap['defaultComponent']
> = Omit<ButtonBaseProps<C>, 'component'> & {
  component?: C;
  variant?: 'default' | 'simple';
  icon?: ReactNode;
  ellipsis?: boolean;
  borderColor?: string;
  description?: string;
  descriptionLines?: number;
};

const styles = {
  root: {
    borderRadius: 2,
    overflow: 'hidden',
    textAlign: 'left',
    typography: 'body1',
    fontWeight: 500,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    background: (theme: Theme) => theme.palette.gradient.primary,

    '&:before, &:after': {
      content: '""',
      borderRadius: 2,
      position: 'absolute',
      top: 2,
      left: 2,
      right: 2,
      bottom: 2,
    },
    '&:before': {
      background: (theme: Theme) => theme.palette.background.body,
      backgroundSize: '100vw 100vh',
      backgroundAttachment: 'fixed',
    },
    '&:after': {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.text.primary, 0.05),
    },
  },

  defaultButton: {
    py: 2.5,
    px: '12px',
    gap: 2.5,
  },
  simpleButton: {
    p: 2,
    gap: 0.5,
  },
  description: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
};

const getLineStyles = (lines: number) => {
  return {
    display: '-webkit-box',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  };
};

function CardButtonInner<D extends React.ElementType>(
  {
    sx,
    children,
    icon,
    description,
    descriptionLines,
    borderColor,
    ellipsis = false,
    variant = 'default',
    ...rest
  }: CardButtonProps<D>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const lineStyles = descriptionLines ? getLineStyles(descriptionLines) : false;
  return (
    <ButtonBase
      ref={ref}
      sx={[styles.root, styles[`${variant}Button`], ...sxProps]}
      {...rest}
    >
      <Box
        component="span"
        sx={[styles.background, !!borderColor && { background: borderColor }]}
      />
      <Box sx={styles.icon}>
        {icon} {variant === 'default' && <ActionArrowRightUp />}
      </Box>
      <Box sx={styles.content}>
        <Box sx={[ellipsis && styles.ellipsis]}>{children}</Box>
        {!!description && (
          <Typography sx={[styles.description, lineStyles]} variant="caption">
            {description}
          </Typography>
        )}
      </Box>
    </ButtonBase>
  );
}

const CardButton = React.forwardRef(CardButtonInner) as <
  D extends React.ElementType = 'button'
>(
  props: CardButtonProps<D> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof CardButtonInner<D>>;

export default CardButton;
