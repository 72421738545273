import { useCallback, useEffect, useRef, useState } from 'react';
import { differenceInMilliseconds } from 'date-fns';

export default function useTimer(
  timestamp?: number | null,
  onFrame?: ((time: number) => void) | null
) {
  const requestRef = useRef<number | null>(null);
  const frameRef = useRef<((time: number) => void) | null | undefined>(null);
  const startDate = useRef(timestamp);
  const [seconds, setSeconds] = useState(0);

  frameRef.current = onFrame;

  const animate = useCallback(() => {
    if (!startDate.current && requestRef.current) {
      cancelAnimationFrame(requestRef.current);
      return;
    }
    if (startDate.current) {
      const totalMilliseconds = differenceInMilliseconds(
        Date.now(),
        startDate.current
      );

      setSeconds(Math.floor(totalMilliseconds / 1000));

      requestRef.current = requestAnimationFrame(animate);

      if (frameRef.current) {
        frameRef.current(totalMilliseconds);
      }
    }
  }, []);

  useEffect(() => {
    if (requestRef.current) cancelAnimationFrame(requestRef.current);
    if (timestamp) {
      startDate.current = timestamp;
      requestRef.current = requestAnimationFrame(animate);
    } else {
      startDate.current = null;
      setSeconds(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  useEffect(
    () => () => {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
    },
    []
  );
  return seconds;
}
