import {
  useCreatorQuestionDetailData,
  useCreatorQuestionInfoData,
} from '@lib/web/editor';

export const useData = (questionId: string) => {
  const { infoData, isInitializingOrLoading: isInfoDataInitializingOrLoading } =
    useCreatorQuestionInfoData(questionId);

  const { answerComponents, isInitializingOrLoading } =
    useCreatorQuestionDetailData(questionId);

  return {
    answerContentType: infoData?.answerContentType,
    isInitializingOrLoading:
      isInitializingOrLoading || isInfoDataInitializingOrLoading,
    component: answerComponents.length > 0 ? answerComponents[0] : null,
    limitMin: infoData?.isOfferWordLimit
      ? infoData.freeResponseAnswerWordLimitMin
      : 0,
    limitMax: infoData?.isOfferWordLimit
      ? infoData.freeResponseAnswerWordLimitMax
      : 0,
  };
};
