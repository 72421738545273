import StepBreadcrumb from '@app/web/src/layouts/breadcrumbs/StepBreadcrumb';
import getClubPageConfig from '@app/web/src/layouts/pageConfig/club';
import { ParsedUrlQuery } from 'querystring';

export const getAllBreadcrumbsValues = (
  pathname: string,
  query: ParsedUrlQuery,
  t: any
) => {
  const clubSlug = query.clubSlug as string;
  const prefix = clubSlug ? `/club/form/${clubSlug}` : '/club/form';
  const clubConfig = getClubPageConfig(t);
  const getStepBreadcrumb = (step: number) =>
    clubSlug ? undefined : <StepBreadcrumb total="4" step={step} />;
  return {
    overview: {
      text: clubConfig.settingsClub.title,
      icon: clubConfig.settingsClub.icon,
      href: `${prefix}/overview`,
      customizeComponent: getStepBreadcrumb(1),
    },
    examSettings: {
      text: clubConfig.settingsExamSettings.title,
      icon: clubConfig.settingsExamSettings.icon,
      href: `${prefix}/exam-settings`,
      customizeComponent: getStepBreadcrumb(2),
    },
    members: {
      text: clubConfig.settingsMembers.title,
      icon: clubConfig.settingsMembers.icon,
      href: `${prefix}/members`,
      customizeComponent: getStepBreadcrumb(4),
    },
    manageAI: {
      text: clubConfig.settingsManageAI.title,
      icon: clubConfig.settingsManageAI.icon,
      href: `${prefix}/manage-ai`,
      customizeComponent: getStepBreadcrumb(3),
    },
    requests: {
      text: clubConfig.settingsRequests.title,
      icon: clubConfig.settingsRequests.icon,
      href: `${prefix}/requests`,
    },
  };
};
