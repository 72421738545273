export const enum ClubGroupViewSlug {
  CardCenterClubGroupDefault = 'card_center_club_group_default',
  ProfileCenterClubGroupSetting = 'profile_center_club_group_setting',
}

export const enum ClubGroupCreatorType {
  Unknown = 0,
  Organization = 1,
  User = 2,
}

export const enum ClubGroupVisibility {
  Unknown = 0,
  Visible = 1,
  Hidden = 2,
}

export const enum ClubGroupStatus {
  Unknown = 0,
  Active = 1,
  Inactive = -1,
}

export const enum ClubGroupJoinedStatus {
  NotJoined = -1,
  Joined = 1,
}

export const enum ClubGroupOfficialType {
  Official = 1,
  NonOfficial = -1,
}

export const enum ClubGroupMemberRole {
  Member = 1,
  Editor = 2,
  Admin = 3,
}
