import React, { useRef } from 'react';
import nextId from 'react-id-generator';
import {
  alpha,
  Box,
  BoxProps,
  keyframes,
  Portal,
  useTheme,
} from '@mui/material';
import { useDimension } from '@front/helper';

export type SpotlightProps = {
  sx?: BoxProps['sx'];
  x?: number;
  y?: number;
  isRadio?: boolean;
  disablePortal?: boolean;
  onClick?: () => void;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 'modal',
    svg: {
      opacity: 0,
      animation: `${fadeIn} 0.3s ease forwards`,
    },
  },
};
function Spotlight({
  sx,
  x,
  y,
  isRadio,
  onClick,
}: Omit<SpotlightProps, 'disablePortal'>) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const boxRef = useRef();
  const { width, height } = useDimension(boxRef);
  const theme = useTheme();

  const positionX = isRadio && x ? x * width : x;
  const positionY = isRadio && y ? y * height : y;

  const maskId = nextId('spotlight-mask');
  return (
    <Box ref={boxRef} sx={[styles.root, ...sxProps]} onClick={onClick}>
      {typeof x === 'number' &&
        typeof y === 'number' &&
        width > 0 &&
        height > 0 && (
          <svg width={width} height={height}>
            <mask id={maskId}>
              <rect x={0} y={0} width={width} height={height} fill="white" />
              <circle cx={positionX} cy={positionY} r={120} fill="black" />
            </mask>
            <rect
              mask={`url(#${maskId})`}
              x={0}
              y={0}
              width={width}
              height={height}
              fill={alpha(theme.palette.background.darker, 0.5)}
            />
            <circle
              cx={positionX}
              cy={positionY}
              r={120}
              fill="transparent"
              stroke={alpha(theme.palette.text.primary, 0.1)}
              strokeWidth={1}
            />
          </svg>
        )}
    </Box>
  );
}
export default function SpotlightRoot({
  disablePortal,
  ...rest
}: SpotlightProps) {
  if (disablePortal) {
    return <Spotlight {...rest} />;
  }
  return (
    <Portal>
      <Spotlight {...rest} />
    </Portal>
  );
}
