import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  hash: string;
} = {
  hash: '',
};

const hashSectionSlice = createSlice({
  name: 'hashSection',
  initialState,
  reducers: {
    updateHash: (state, action) => {
      state.hash = action.payload;
    },
  },
});

export const { updateHash } = hashSectionSlice.actions;
export default hashSectionSlice.reducer;
