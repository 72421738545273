import React from 'react';

export default function ProfileSearchTutor({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.78455 3.58998C8.7249 3.20047 9.73275 3 10.7506 3C11.7684 3 12.7762 3.20047 13.7166 3.58998C14.6569 3.97948 15.5113 4.55038 16.231 5.27009C16.9508 5.9898 17.5217 6.84421 17.9112 7.78455C18.3007 8.7249 18.5011 9.73275 18.5011 10.7506C18.5011 11.7684 18.3007 12.7762 17.9112 13.7166C17.6499 14.3473 17.307 14.9394 16.8923 15.4781L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L15.4782 16.8924C14.1304 17.9298 12.4699 18.5011 10.7506 18.5011C8.69499 18.5011 6.7236 17.6846 5.27009 16.231C3.81658 14.7775 3 12.8061 3 10.7506C3 8.69499 3.81658 6.7236 5.27009 5.27009C5.9898 4.55038 6.84421 3.97948 7.78455 3.58998ZM10.7506 5C9.99539 5 9.24761 5.14874 8.54992 5.43774C7.85223 5.72673 7.21829 6.15031 6.6843 6.6843C5.60586 7.76274 5 9.22542 5 10.7506C5 12.2757 5.60586 13.7384 6.6843 14.8168C7.76274 15.8953 9.22542 16.5011 10.7506 16.5011C12.2757 16.5011 13.7384 15.8953 14.8168 14.8168C15.3508 14.2828 15.7744 13.6489 16.0634 12.9512C16.3524 12.2535 16.5011 11.5057 16.5011 10.7506C16.5011 9.99539 16.3524 9.24761 16.0634 8.54992C15.7744 7.85223 15.3508 7.21829 14.8168 6.6843C14.2828 6.15031 13.6489 5.72673 12.9512 5.43774C12.2535 5.14874 11.5057 5 10.7506 5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M10.4799 7.77428L6.35557 9.31736C6.17122 9.38633 6.1661 9.65267 6.34766 9.72909L10.472 11.4651C10.5241 11.487 10.5824 11.487 10.6345 11.4651L14.7588 9.72909C14.9404 9.65267 14.9353 9.38633 14.7509 9.31736L10.6266 7.77428C10.5792 7.75656 10.5273 7.75656 10.4799 7.77428Z"
        fill="currentColor"
      />
      <path
        d="M10.4687 12.1242L7.84882 10.9689C7.70725 10.9065 7.54977 11.0133 7.54977 11.1718V12.4574C7.54977 12.5457 7.60092 12.6255 7.6798 12.6602L10.4687 13.89C10.5227 13.9138 10.5838 13.9138 10.6378 13.89L13.4267 12.6602C13.5056 12.6255 13.5567 12.5457 13.5567 12.4574V11.1718C13.5567 11.0133 13.3992 10.9065 13.2577 10.9689L10.6378 12.1242C10.5838 12.148 10.5227 12.148 10.4687 12.1242Z"
        fill="currentColor"
      />
      <path
        d="M14.6294 10.1749C14.7479 10.1749 14.8439 10.2737 14.8439 10.3956V12.7322L14.8883 12.7779C15.0314 12.9251 15.0314 13.1637 14.8883 13.3108C14.7453 13.458 14.5134 13.458 14.3704 13.3108C14.2274 13.1637 14.2274 12.9251 14.3704 12.7779L14.4148 12.7322V10.3956C14.4148 10.2737 14.5109 10.1749 14.6294 10.1749Z"
        fill="currentColor"
      />
    </svg>
  );
}
