import React from 'react';

export default function OtherSave({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M17.8196 3H5.25C4.01138 3 3 4.0125 3 5.25V18.75C3 19.9875 4.01138 21 5.25 21H18.75C19.9886 21 21 19.9875 21 18.75V6.51787L17.8196 3ZM16.5 9.75C16.5 10.3676 15.9937 10.875 15.375 10.875H7.625C7.00625 10.875 6.5 10.3676 6.5 9.75V4.125H16.5V9.75ZM15.375 5.25H13.125V9.75H15.375V5.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
