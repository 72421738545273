import React from 'react';

export default function AgentRecipeNoSteps({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C4.8 3 3 4.8 3 12C3 19.2 4.8 21 12 21C19.2 21 21 19.2 21 12C21 4.8 19.2 3 12 3Z"
        fill="currentColor"
      />
      <path
        d="M12 6C10.8821 6 9.89684 6.72293 9.58105 7.77333C9.24632 7.62504 8.89263 7.54472 8.52632 7.54472C7.8563 7.54472 7.21372 7.80511 6.73994 8.26861C6.26616 8.73212 6 9.36077 6 10.0163C6.0015 10.5636 6.18821 11.0951 6.53098 11.5277C6.87375 11.9602 7.3533 12.2696 7.89474 12.4075V16.1463H16.1053V12.4075C17.2168 12.1233 18 11.1408 18 10.0163C18 9.36077 17.7338 8.73212 17.2601 8.26861C16.7863 7.80511 16.1437 7.54472 15.4737 7.54472C15.1074 7.54472 14.7537 7.62504 14.4189 7.77333C14.1032 6.72293 13.1179 6 12 6ZM7.89474 16.7642V17.3821C7.89474 17.546 7.96128 17.7031 8.07972 17.819C8.19817 17.9349 8.35881 18 8.52632 18H15.4737C15.6412 18 15.8018 17.9349 15.9203 17.819C16.0387 17.7031 16.1053 17.546 16.1053 17.3821V16.7642H7.89474Z"
        fill="#080808"
      />
    </svg>
  );
}
