import React, { forwardRef, ReactNode } from 'react';
import { alpha, Theme } from '@mui/material';
import Box from '@mui/material/Box';

export type IaTableHeadProps = {
  children: ReactNode;
};

const styles = {
  root: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
  },
};

const IaTableHead = forwardRef<HTMLDivElement, IaTableHeadProps>(
  ({ children }, ref) => {
    return (
      <Box ref={ref} sx={styles.root}>
        {children}
      </Box>
    );
  }
);

IaTableHead.displayName = 'IaTableHead';

export default IaTableHead;
