import React from 'react';

export default function AIEditorText({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 12C0 2.4 2.4 0 12 0C21.6 0 24 2.4 24 12C24 21.6 21.6 24 12 24C2.4 24 0 21.6 0 12Z"
        fill="#F37299"
        fillRule="evenodd"
      />
      <path
        d="M13.2 18H7.2C6.54 18 6 17.46 6 16.8C6 16.14 6.54 15.6 7.2 15.6H13.2C13.86 15.6 14.4 16.14 14.4 16.8C14.4 17.46 13.86 18 13.2 18ZM16.8 13.2H7.2C6.54 13.2 6 12.66 6 12C6 11.34 6.54 10.8 7.2 10.8H16.8C17.46 10.8 18 11.34 18 12C18 12.66 17.46 13.2 16.8 13.2ZM16.8 8.4H7.2C6.54 8.4 6 7.86 6 7.2C6 6.54 6.54 6 7.2 6H16.8C17.46 6 18 6.54 18 7.2C18 7.86 17.46 8.4 16.8 8.4Z"
        fill="#080808"
        fillRule="evenodd"
      />
    </svg>
  );
}
