import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { FormLayoutBaseItem } from '../types';
type ActionPayload = {
  name: string;
  value?: any;
};

export default function useInputStateIndicator({
  name,
  stateIndicator,
}: {
  name: string;
  stateIndicator?: FormLayoutBaseItem['stateIndicator'];
}) {
  const { getValues } = useFormContext();
  const { getIaAction } = useIaAction();
  return useMemo(() => {
    if (!stateIndicator) return undefined;
    let onClick = undefined;
    if (stateIndicator.onClickAction) {
      const action = getIaAction<ActionPayload>(
        stateIndicator.onClickAction.value
      );
      onClick = () => action?.action?.({ name, value: getValues(name) });
    }
    return {
      state: stateIndicator.state,
      successMessage: stateIndicator.successMessage,
      errorMessage: stateIndicator.errorMessage,
      onClick,
    };
  }, [getIaAction, getValues, name, stateIndicator]);
}
