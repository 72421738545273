import { useCallback, useMemo, useState } from 'react';
import { useAsyncQueryPool } from '@front/helper';
import { apis } from '@lib/web/apis';
import { ThreadNotificationMessagePayloadGetter } from '@lib/web/thread/types';
import { call } from '@lib/web/utils';

export const useThreadNotificationMessagePayloads = () => {
  const [notificationMap, setNotificationMap] = useState<
    Record<string, GetThreadMessageNotificationPayloadRes | null>
  >({});

  const { asyncGet } = useAsyncQueryPool({
    poolKey: 'useThreadNotificationMessagePayloads',
    map: notificationMap,
    setMap: setNotificationMap,
    queryApi: async (keys) => {
      const [res] = await call(
        apis.notification.getThreadMessagePayload({ notificationIds: keys })
      );
      return keys.map(
        (key) => res?.data?.find((d) => d.notificationId === key) || null
      );
    },
  });

  const getThreadNotificationMessagePayload: ThreadNotificationMessagePayloadGetter =
    useCallback(
      (notificationId) => {
        if (!notificationId) return null;
        return asyncGet(notificationId);
      },
      [asyncGet]
    );

  return useMemo(
    () => ({
      getThreadNotificationMessagePayload,
    }),
    [getThreadNotificationMessagePayload]
  );
};
