import cryptoUtils from 'crypto';

const ENC = 'bf3c199c2470cb477d907b1e0917c17b';
const IV = '5183666c72eec9e4';
const ALGO = 'aes-256-cbc';

export function encrypt(value: string) {
  const cipher = cryptoUtils.createCipheriv(ALGO, ENC, IV);
  let encrypted = cipher.update(value, 'utf8', 'hex');
  encrypted += cipher.final('hex');
  return encrypted;
}

export function decrypt(value: string) {
  const decipher = cryptoUtils.createDecipheriv(ALGO, ENC, IV);
  let decrypted = decipher.update(value, 'hex', 'utf8');
  decrypted += decipher.final('utf8');
  return decrypted;
}
