import type { Direction } from '@mui/material';
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import { base, exam } from '@front/ui';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  mode: 'light' | 'dark';
}

const createTheme = (config: ThemeConfig) => {
  const updatedTheme = config.mode === 'dark' ? base : exam;
  let theme = createMuiTheme(updatedTheme);

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

export default createTheme;
