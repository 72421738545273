import React from 'react';

export default function OtherShareToDirect({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.4982 6.77455L11.858 13.4148L14.408 18.9832L18.4982 6.77455ZM10.5852 12.142L17.2254 5.50177L5.01683 9.59199L10.5852 12.142ZM10.1003 13.8997L3.99798 11.1052C3.38405 10.824 2.98417 10.201 3.00048 9.51728C3.01698 8.82567 3.45889 8.21562 4.12115 7.99375L18.7696 3.08613C19.3841 2.88026 20.0525 3.05325 20.4996 3.50038C20.9467 3.94751 21.1197 4.61591 20.9139 5.23041L16.0062 19.8789C15.7844 20.5411 15.1743 20.983 14.4827 20.9995C13.799 21.0158 13.176 20.616 12.8948 20.002L10.1003 13.8997Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
