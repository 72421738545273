import React, { createContext, ReactNode } from 'react';

type SideMenuMoreActionDropdownContextValue = {
  items: {
    label: string;
    icon: React.ReactNode;
    onClick: (itemId: string) => void;
  }[];
};

const defaultValue: SideMenuMoreActionDropdownContextValue = {
  items: [],
};

export const SideMenuMoreActionDropdownContext =
  createContext<SideMenuMoreActionDropdownContextValue>(defaultValue);

type OverviewDataProviderProps = {
  children: ReactNode;
  value?: SideMenuMoreActionDropdownContextValue;
};

export default function SideMenuMoreActionDropdownProvider({
  value = defaultValue,
  children,
}: OverviewDataProviderProps) {
  return (
    <SideMenuMoreActionDropdownContext.Provider value={value}>
      {children}
    </SideMenuMoreActionDropdownContext.Provider>
  );
}
