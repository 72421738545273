import React from 'react';

export default function OtherImport({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.5 3C13.0523 3 13.5 3.44772 13.5 4V12.5041L15.2766 10.6429C15.658 10.2434 16.291 10.2286 16.6905 10.61C17.09 10.9913 17.1047 11.6243 16.7234 12.0238L12.5 16.4483L8.27664 12.0238C7.89531 11.6243 7.91003 10.9913 8.30952 10.61C8.70902 10.2286 9.34202 10.2434 9.72336 10.6429L11.5 12.5041V4C11.5 3.44772 11.9477 3 12.5 3ZM4 15C4.55228 15 5 15.4477 5 16V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V16C19 15.4477 19.4477 15 20 15C20.5523 15 21 15.4477 21 16V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V16C3 15.4477 3.44772 15 4 15Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
