import React from 'react';
import Box from '@mui/material/Box';
import MuiSlider, {
  SliderProps as MuiSliderProps,
  SliderThumb,
} from '@mui/material/Slider';

const styles = {
  root: {
    height: 58,
  },
};

type SliderThumbProps = React.HTMLAttributes<unknown> & {
  style: {
    left: string;
  };
};
function Thumb({ ...rest }: SliderThumbProps) {
  const left = rest.style.left;

  return (
    <SliderThumb
      {...rest}
      style={{ ...rest.style, backgroundPosition: `${left}` }}
    />
  );
}
export default function Slider({ sx, ...rest }: MuiSliderProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={styles.root}>
      <MuiSlider
        slots={{
          thumb: Thumb,
        }}
        sx={[
          {
            '& .MuiSlider-markLabel': {
              typography: 'body1',
            },
            '& .MuiSlider-valueLabel': { typography: 'body1', display: 'none' },
            '& .Mui-active': {
              '& .MuiSlider-valueLabel': { display: 'block' },
            },
            '&.MuiSlider-dragging': {
              '& .MuiSlider-markLabel': { display: 'none' },
            },
            '& .MuiSlider-rail': {
              left: '-10px',
              width: 'calc(100% + 20px)',
            },
            '& .MuiSlider-track': {
              left: '-10px !important',
            },
            '&.Mui-disabled': {
              opacity: 0.5,
            },
          },
          ...sxProps,
        ]}
        {...rest}
      />
    </Box>
  );
}
