import { Editor, isNodeSelection, posToDOMRect } from '@tiptap/core';

import { SuggestMenuItem } from './SuggestionMenu';

export const getStartPos = (editor: Editor) => {
  const { state } = editor.view;
  const { selection } = state;
  const { $head } = selection;

  return $head.pos;
};

export const getSelectionBoundingBox = (editor: Editor, startPos: number) => {
  const { state } = editor.view;
  const { selection } = state;

  if (isNodeSelection(selection)) {
    const node = editor.view.nodeDOM(startPos) as HTMLElement;

    if (node) {
      return node.getBoundingClientRect();
    }
  } else {
    return posToDOMRect(editor.view, startPos, startPos);
  }
};

function getRegexp(
  mentionTrigger = '@',
  hashtagTrigger = '#',
  allowSpace = true
) {
  const mention = allowSpace
    ? new RegExp('(^|\\s)' + mentionTrigger + '([\\w-\\+]+\\s?[\\w-\\+]*)?$')
    : new RegExp('(^|\\s)' + mentionTrigger + '([\\w-\\+]+)?$');

  // hashtags should never allow spaces. I mean, what's the point of allowing spaces in hashtags?
  const tag = new RegExp('(^|\\s)' + hashtagTrigger + '([\\w-]+)$');

  return {
    mention: mention,
    tag: tag,
  };
}

export const getMatchInfo = (
  editor: Editor,
  {
    mentionTrigger,
    hashtagTrigger,
    allowSpace,
  }: {
    mentionTrigger?: string;
    hashtagTrigger?: string;
    allowSpace?: boolean;
  } = {}
) => {
  const regex = getRegexp(mentionTrigger, hashtagTrigger, allowSpace);

  const position = editor.view.state.selection.$from;
  const parastart = position.before();

  const text = editor.view.state.doc.textBetween(
    parastart,
    position.pos,
    '\n',
    '\0'
  );

  const mentionMatch = text.match(regex.mention);
  const tagMatch = text.match(regex.tag);

  const match = mentionMatch || tagMatch;

  let type;
  if (mentionMatch) {
    type = 'mention';
  } else if (tagMatch) {
    type = 'tag';
  }

  if (match) {
    // adjust match.index to remove the matched extra space
    match.index = match[0].startsWith(' ')
      ? (match.index as number) + 1
      : match.index;
    match[0] = match[0].startsWith(' ')
      ? match[0].substring(1, match[0].length)
      : match[0];

    // The absolute position of the match in the document
    const from = position.start() + (match.index as number);
    const to = from + match[0].length;

    const queryText = match[2];

    return {
      range: { from: from, to: to },
      queryText: queryText || '',
      type,
    };
  }

  return undefined;
};

export const getFilteredItems = <M = Record<string, any>>(
  item: SuggestMenuItem<M>,
  keyword: string
) =>
  item.display.toLowerCase().includes(keyword.toLowerCase()) ||
  item.value.toLowerCase().includes(keyword.toLowerCase());
