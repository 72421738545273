import React from 'react';
import { useClubSetting } from '@lib/web/apis';
import { useCreatorQuestionInfoData } from '@lib/web/editor';
import { useClubSlug } from '@lib/web/hooks';

import MultipleChoices from './components/MultipleChoices';
import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export type EditorAnswerMultipleChoiceProps = {
  questionId: string;
  readonly?: boolean;
};

export default function EditorAnswerMultipleChoice({
  questionId,
  readonly,
}: EditorAnswerMultipleChoiceProps) {
  const { choiceValues, answerComponents } = useData(questionId);
  const { handleSelect, handleBlocksChange } = useEventHandle(questionId);
  const { infoData } = useCreatorQuestionInfoData(questionId);
  const clubSlug = useClubSlug();
  const { data: clubSettingData } = useClubSetting(clubSlug);
  const clubSetting = clubSettingData?.data;

  return (
    <MultipleChoices
      questionId={questionId}
      choiceValues={choiceValues}
      onSelect={handleSelect}
      handleBlocksChange={(id, blocks) => {
        const component = answerComponents.find((c) => c.id === id);
        if (component) {
          void handleBlocksChange(component, blocks);
        }
      }}
      blankOptionEnabled={
        clubSetting?.isEnableNegativeScoring && infoData?.isOfferBlankOption
      }
      readonly={readonly}
    />
  );
}
